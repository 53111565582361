import React, { Component } from 'react';
import Table from '../../components/table/Table';
import { initialize, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  TableNote,
  BaseForm
} from '../../styles/BasicStyles';
import {
  SendButtonContainer
} from './OrderStyles';
import {
  GetGenericOrderWeddings,
  GetFlowersOrderWeddings,
  GetStaffOrderWeddings,
  SendOrderRequest,
  DownloadOrder,
  GetCakeOrderWeddings,
  GetGenericOrderArchives,
  GetFlowersOrderArchives,
  GetStaffOrderArchives,
  GetCakeOrderArchive
} from '../../infra/requests/ReportsRequests';
import {
  GetAllSupplierCategories,
  GetSupplierCategory,
  GetSupplier
} from '../../infra/requests/SupplierRequests';
import {
  GetFlowersList
} from '../../infra/requests/FlowersRequests';
import moment from 'moment';
import CurrencyComponent from '../../components/currency/CurrencyComponent';
import { BaseButton } from '../../components/buttons/BaseButton';
import OrdersFilters from './OrdersFilters';
import {
  getWeddingName,
  getLocal
} from '../../infra/services/wedding/weddingUtils';
import SendOrderModal from './SendOrderModal';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import { notification } from 'antd';
import GenericOrderForm from './GenericOrderForm';
import WeddingFlowersDetail from './components/WeddingFlowersDetail';
import TotalFlowersList from './components/TotalFlowersList';
import WeddingStaffDetail from './components/WeddingStaffDetail';
import OthersCategoriesDetail from './components/OthersCategoriesDetail';
import WeddingCakeDetail from './components/WeddingCakeDetail';

const categoryFlowers = '5f18328fa0bd615c9ef3ff9e';
const categoryStaff = '5f1832a9a0bd615c9ef3ff9f';
const categoryFire = '5fad06d03e074944514fb9a7';
const categorySushi = '5fad068d3e0749249e4fb9a6';
const categoryBalloons = '60002e2917918045b36dfc69';
const categoryCoche = '60002e2d17918045b36dfc6a';
const categoryLights = '60002e3517918045b36dfc6b';
const categoryBoats = '602b809a3b421e6c5c3fc0fd';
const categoryLeitao = '602b809e3b421e0f213fc0fe';
const categoryRoyalDrinks = '602b80ac3b421e4e233fc0ff';
const categoryCake = '610bf27a10fcb82af28781de';
const categoryCarocha = '60af7b993fba31f2d2f97e11';
const categoryEtiquette = '60b2b02fc346cb81801f3e51';

class OrdersPage extends Component {
  state = {
    loading: false,
    generating: false,
    sending: false,
    filters: JSON.stringify({
      dates: [moment().utc(true), moment().utc(true).add(1, 'week')]
    }),
    weddings: [],
    total: 0,
    categories: [],
    openModal: false,
    suppliers: [],
    supplierEmails: undefined,
    category: undefined,
    inputFile: undefined,
    ingredients: [],
    options: [],
    foodRestrictions: [],
    orderValues: undefined,
    flowersColors: [],
    columns: [
      {
        title: 'Data',
        dataIndex: 'date',
        render: value => moment.utc(value).format('DD-MM-YYYY')
      },
      {
        title: 'Local',
        render: data => getLocal(data)
      },
      {
        title: 'Noivos',
        render: data => data.name ? data.name : getWeddingName(data)
      },
      {
        title: 'Preço por PAX',
        dataIndex: 'price',
        render: value => <CurrencyComponent value={value} />
      },
      {
        title: 'PAX',
        render: data => (data.pax >= 0) ? data.pax : '-'
      },
      {
        title: 'Minimo Pessoas',
        dataIndex: 'min_people'
      }
    ]
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  updateDataTable = async () => {
    const { dispatch } = this.props;
    const { filters } = this.state;
    const filter = filters ? JSON.parse(filters) : {};

    this.setState({ loading: true });
    try {
      const supplierCategories = await GetAllSupplierCategories();

      let result = {}, flowersColors = {};
      if (filter?.dates && filter?.category) {
        //Flowers
        if (filter.category === categoryFlowers) {
          result = filter.archived ? await GetFlowersOrderArchives(filters)
            : await GetFlowersOrderWeddings(filters);
          flowersColors = await GetFlowersList();

          const weddingsForm = [];
          if (result?.data?.weddings?.length > 0) {
            const flowers = [];
            if (flowersColors?.data?.length > 0) {
              flowersColors.data.forEach(color => {
                if (color?.flowers?.length > 0) {
                  color.flowers
                    .sort(function (a, b) {
                      if (a < b) { return -1; }
                      if (a > b) { return 1; }
                      return 0;
                    })
                    .forEach(flower => {
                      flowers.push({
                        hex: color.hex.substring(1),
                        color: color.name,
                        flower,
                        quantity: 0,
                        type: 'uni.'
                      });
                    });
                }
              });
            }

            result.data.weddings.forEach(wedding => {
              weddingsForm.push({
                id: wedding._id,
                date: moment.utc(wedding.date).format('DD-MM-YYYY'),
                place: getLocal(wedding),
                flowers
              })
            });
          }

          dispatch(initialize('manage_order_form', { weddings: weddingsForm }));
        }
        //Staff
        else if (filter.category === categoryStaff) {
          result = filter.archived ? await GetStaffOrderArchives(filters)
            : await GetStaffOrderWeddings(filters);

          const weddingsForm = [];
          if (result?.data?.weddings?.length > 0) {
            result.data.weddings.forEach(wedding => {
              weddingsForm.push({
                id: wedding._id,
                date: moment.utc(wedding.date).format('DD-MM-YYYY'),
                place: getLocal(wedding),
                staff_table: wedding.staff_table
              })
            });
          }

          dispatch(initialize('manage_order_form', { weddings: weddingsForm }));
        }
        //Bolo dos Noivos
        else if (filter.category === categoryCake) {
          result = filter.archived ? await GetCakeOrderArchive(filters)
            : await GetCakeOrderWeddings(filters);

          const weddingsForm = [];
          if (result?.data?.weddings?.length > 0) {
            result.data.weddings.forEach(wedding => {
              weddingsForm.push({
                id: wedding._id,
                date: moment.utc(wedding.date).format('DD-MM-YYYY'),
                place: wedding.wedding_place,
                cake: wedding.cake,
                baptism: wedding.baptism,
                cake_upload: wedding.cake_upload,
                filling: wedding.filling,
                ingredients: wedding.ingredients
              })
            });
          }

          dispatch(initialize('manage_order_form', { weddings: weddingsForm }));
        }
        //Generic
        else {
          result = filter.archived ? await GetGenericOrderArchives(filters)
            : await GetGenericOrderWeddings(filters);

          dispatch(initialize('manage_order_form', { 
            ingredients: result?.data?.ingredients || [], 
            options: result?.data?.options || [],
            foodRestrictions: result?.data?.restrictions || []
           }));
        }
      }

      this.setState({
        weddings: result?.data?.weddings || [],
        ingredients: result?.data?.ingredients ? result.data.ingredients : [],
        options: result?.data?.options ? result.data.options : [],
        foodRestrictions: result?.data?.restrictions ? result.data.restrictions : [],
        loading: false,
        categories: supplierCategories?.data || [],
        flowersColors: flowersColors?.data ? flowersColors.data : []
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = filters => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  openModal = async (values) => {
    const { dispatch } = this.props;
    const { filters, inputFile } = this.state;
    const filter = filters ? JSON.parse(filters) : {};

    const category = await GetSupplierCategory(filter?.category);
    const filterPlace = parseInt(filter?.wedding_place);

    let subject = 'Pedido de encomenda';
    if (category?.data?.name) subject = `Pedido de encomenda - ${category.data.name}`;

    //If there's a default supplier for this category AND we're filtering for a specific place, load that supplier
    let supplierEmails = undefined;
    if ((filterPlace === 1 && category?.data?.default_solar) || (filterPlace === 2 && category?.data?.default_lago)) {
      const supplierID = filterPlace === 1 ? category?.data?.default_solar : category?.data?.default_lago;
      const supplier = await GetSupplier(supplierID);

      supplierEmails = {
        email: supplier?.data?.email || undefined,
        extra_emails: category?.data?.extra_emails || []
      };

      dispatch(initialize('manage_send_order_form', { subject, supplier: supplierID, file: inputFile, ...supplierEmails }));
    }
    else {
      dispatch(initialize('manage_send_order_form', { subject }));
    }

    this.setState({
      openModal: true,
      category: category?.data,
      suppliers: category?.data?.suppliers || [],
      supplierEmails,
      orderValues: values
    });
  };

  closeModal = () => {
    const { dispatch } = this.props;
    dispatch(initialize('manage_send_order_form', {}));
    this.setState({
      openModal: false,
      supplierEmails: undefined,
      suppliers: [],
      inputFile: undefined
    });
  };

  onModalSubmit = async values => {
    try {
      this.setState({ sending: true });
      const { filters, orderValues } = this.state;

      const payload = FlattenToFormData({ ...values, filters, orderValues });
      const result = await SendOrderRequest(payload);

      if (result?.success) {
        notification.success({
          message: 'Sucesso!',
          description: 'O email foi enviado com sucesso.'
        });
      }

      this.setState({ sending: false });
      this.closeModal();
    } catch (e) {
      console.error(e);
      this.setState({ sending: false });
      this.closeModal();
    }
  };

  downloadReport = async () => {
    try {
      const { weddings, filters, orderValues } = this.state;

      if (weddings && weddings.length > 0) {
        const filtersObj = JSON.parse(filters);
        const result = await DownloadOrder({ filters: filtersObj, order: orderValues });

        if (result) {
          //Default is XLSX format, but for Wedding Cake is PDF
          let filename = 'Encomenda.xlsx';
          if (filtersObj?.category === '610bf27a10fcb82af28781de') filename = 'Encomenda.pdf';

          if (result.headers) {
            const contentDisposition = result.headers.get('content-disposition');
            filename = contentDisposition.split('filename="')[1].split('"')[0];
          }

          //Create blob link to download
          const url = window.URL.createObjectURL(new Blob([result])); //result.blob
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);

          //Append to html link element page
          document.body.appendChild(link);

          //Start download
          link.click();

          //Clean up and remove the link
          link.parentNode.removeChild(link);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  loadSupplierEmail = async (supplierID) => {
    const { dispatch, emailSubject } = this.props;
    const { category, inputFile } = this.state;

    let supplierEmails = undefined;
    if (supplierID) {
      const supplier = await GetSupplier(supplierID);
      supplierEmails = {
        email: supplier?.data?.email || undefined,
        extra_emails: category?.extra_emails || []
      };
    }

    this.setState({
      supplierEmails
    });

    dispatch(initialize('manage_send_order_form', { subject: emailSubject, supplier: supplierID, file: inputFile, ...supplierEmails }));
  }

  handleInputFile = (inputFile) => {
    this.setState({
      inputFile
    });
  }

  render() {
    const { handleSubmit, totalFlowersList } = this.props;
    const {
      columns,
      weddings,
      total,
      loading,
      currentPage,
      generating,
      categories,
      openModal,
      sending,
      suppliers,
      supplierEmails,
      filters,
      category,
      ingredients,
      options,
      foodRestrictions,
      flowersColors
    } = this.state;

    const filter = filters ? JSON.parse(filters) : {};

    let sendButtonDisabled = true;
    if (filter?.category === categoryFlowers && weddings.length > 0 && flowersColors?.length > 0) sendButtonDisabled = false;
    else if (filter?.category === categoryStaff && weddings.length > 0) sendButtonDisabled = false;
    else if (weddings.length > 0 && (ingredients?.length > 0 || options?.length > 0)) sendButtonDisabled = false;
    else if (filter?.category == categoryCake) sendButtonDisabled = false;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Encomendas</PageTitle>
            Relatórios de Encomendas
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer buttons={0}>
          <TableNote><span>Nota: </span>Deverá selecionar uma categoria e um intervalo de datas!</TableNote>
          <OrdersFilters
            queryChange={this.handleFilterChange}
            categories={categories}
          />
          <BaseForm onSubmit={handleSubmit(this.openModal)}>
            {
              filter?.category === categoryFlowers ?
                <React.Fragment>
                  <Table
                    columns={columns}
                    currentPage={currentPage}
                    loading={loading}
                    rows={weddings}
                    showHeader={true}
                    emptyIcon="file-text"
                    emptyText={'Não existem casamentos para a pesquisa atual!'}
                    total={total}
                    rowKey={'_id'}
                    expandedRowRender={(data, index) => <WeddingFlowersDetail index={index} wedding={data} flowersColors={flowersColors} />}
                  />
                  {
                    !loading && totalFlowersList?.length > 0 && <TotalFlowersList list={totalFlowersList} />
                  }
                </React.Fragment>
                :
                filter?.category === categoryStaff ?
                  <React.Fragment>
                    <Table
                      columns={columns}
                      currentPage={currentPage}
                      loading={loading}
                      rows={weddings}
                      showHeader={true}
                      emptyIcon="file-text"
                      emptyText={'Não existem casamentos para a pesquisa atual!'}
                      total={total}
                      rowKey={'_id'}
                      expandedRowRender={(data, index) => <WeddingStaffDetail index={index} wedding={data} />}
                    />
                  </React.Fragment>
                  :
                  filter?.category === categoryCake ?
                    <React.Fragment>
                      <Table
                        columns={columns}
                        currentPage={currentPage}
                        loading={loading}
                        rows={weddings}
                        showHeader={true}
                        emptyIcon="file-text"
                        emptyText={'Não existem casamentos para a pesquisa atual!'}
                        total={total}
                        rowKey={'_id'}
                        expandedRowRender={(data, index) => <WeddingCakeDetail index={index} wedding={data} />}
                      />
                    </React.Fragment>
                    :
                    (
                      filter?.category === categoryFire ||
                      filter?.category === categorySushi ||
                      filter?.category === categoryBalloons ||
                      filter?.category === categoryCoche ||
                      filter?.category === categoryLights ||
                      filter?.category === categoryBoats ||
                      filter?.category === categoryLeitao ||
                      filter?.category === categoryRoyalDrinks ||
                      filter?.category === categoryCarocha ||
                      filter?.category === categoryEtiquette
                    ) ?
                      <React.Fragment>
                        <Table
                          columns={columns}
                          currentPage={currentPage}
                          loading={loading}
                          rows={weddings}
                          showHeader={true}
                          emptyIcon="file-text"
                          emptyText={'Não existem casamentos para a pesquisa atual!'}
                          total={total}
                          rowKey={'_id'}
                          expandedRowRender={(data) => <OthersCategoriesDetail wedding={data} />}
                        />
                        {
                          !loading &&
                          <GenericOrderForm
                            ingredients={ingredients}
                            options={options}
                            setIngredientQuantity={this.setIngredientQuantity}
                          />
                        }
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <Table
                          columns={columns}
                          currentPage={currentPage}
                          loading={loading}
                          rows={weddings}
                          showHeader={true}
                          emptyIcon="file-text"
                          emptyText={'Não existem casamentos para a pesquisa atual!'}
                          total={total}
                          rowKey={'_id'}
                        />
                        {
                          !loading &&
                          <GenericOrderForm
                            ingredients={ingredients}
                            options={options}
                            setIngredientQuantity={this.setIngredientQuantity}
                            category={filter?.category}
                            foodRestrictions={foodRestrictions}
                          />
                        }
                      </React.Fragment>
            }
          </BaseForm>
        </PageContainer>
        <SendOrderModal
          open={openModal}
          loading={sending}
          onSubmit={this.onModalSubmit}
          closeModal={this.closeModal}
          suppliers={suppliers}
          loadSupplierEmail={this.loadSupplierEmail}
          supplierEmails={supplierEmails}
          downloadReport={this.downloadReport}
          filters={filters}
          category={category}
          handleInputFile={this.handleInputFile}
        />
        <SendButtonContainer>
          <BaseButton
            type={'primary'}
            icon={'mail'}
            block
            loading={generating}
            text="Enviar relatório"
            onClick={handleSubmit(this.openModal)}
            disabled={sendButtonDisabled}
          />
        </SendButtonContainer>
      </React.Fragment>
    );
  }
}

OrdersPage = reduxForm({
  form: 'manage_order_form'
})(OrdersPage);

const selector = formValueSelector('manage_order_form');
const selector2 = formValueSelector('manage_send_order_form');
OrdersPage = connect(state => {
  const weddings = selector(state, 'weddings');

  const totalFlowersList = [];
  if (weddings?.length > 0) {
    weddings.forEach((wedding) => {
      if (wedding.flowers && wedding.flowers.length > 0) {
        wedding.flowers.forEach((flower) => {
          const index = totalFlowersList.findIndex(elem => elem.hex === flower.hex && elem.color === flower.color && elem.flower === flower.flower);

          if (index > -1) {
            totalFlowersList[index].quantity += parseFloat(flower.quantity);
          }
          else {
            totalFlowersList.push({
              hex: flower.hex,
              color: flower.color,
              flower: flower.flower,
              quantity: parseFloat(flower.quantity),
              type: flower.type
            });
          }
        });
      }
    });
  }

  const emailSubject = selector2(state, 'subject');

  return { totalFlowersList, emailSubject };
})(OrdersPage)

export default OrdersPage;