import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import DateInput from '../../components/inputs/DateInput';
import { getOrganizers } from '../../infra/requests/AdminsRequests';
import XpertGoRangePickerInput from '../../components/inputs/XpertGoRangePickerInput';
import XpertGoDateInput from '../../components/inputs/XpertGoDateInput';
import moment from 'moment';

const printedOptions = [
  {
    _id: 1,
    name: 'Sim',
    value: true
  },
  {
    _id: 2,
    name: 'Não',
    value: false
  }
];

const weddingStatus = [
  {
    _id: 1,
    name: 'Arquivado',
    value: 'A'
  },
  {
    _id: 2,
    name: 'Confirmado',
    value: 'C'
  },
  // {
  //   _id: 3,
  //   name: 'Reserva ',
  //   value: 'RS'
  // },
  // {
  //   _id: 4,
  //   name: 'Reserva (Em Sinalização)',
  //   value: 'RS'
  // },
  // {
  //   _id: 4,
  //   name: 'Eliminado',
  //   value: 'D'
  // },
];

class WeddingsPaymentsFilters extends Component {
  timeout = 0;
  state = {
    search: '',
    wedding_place: '',
    date: null,
    startDate: moment.utc().subtract(7, 'days'),
    endDate: null,
    dates: [],
    organizers: [],
    printed: '',
    status: null,
    paymentsCompleted: 'false',
    loading: false
  };

  componentDidMount = async () => {
    // const { data } = await getOrganizers();
    // this.setState({ organizers: data || [] });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map(key => {
      if (key !== 'organizers' && key !== 'loading' && this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    // queryChange(JSON.stringify(query));
    queryChange(query);
  };

  render() {
    const { locals } = this.props;
    const { search, organizer, organizers, paymentsCompleted, wedding_place, date, dates, printed, status, loading, startDate, endDate } = this.state;
    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={6} lg={6} cl={6}>
            <TextInput
              input={{
                value: search,
                onChange: event =>
                  this.onInputChange('search', event.target.value, 300)
              }}
              meta={{ valid: true }}
              type="text"
              label="Pesquisar"
              placeholder="Procurar por nomes ou emails"
            />
          </Col>
          <Col xs={24} sm={12} md={5} lg={4} xl={3}>
            <XpertGoDateInput
              label="Data"
              disabled={loading}
              placeholder="Escolher data"
              input={{
                value: date,
                onChange: value => {
                  this.onInputChange('date', value);
                  this.onInputChange('dates', []);
                  this.onInputChange('startDate', null);
                  this.onInputChange('endDate', null);
                }
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} sm={12} md={5} lg={4} xl={3}>
            <XpertGoDateInput
              label="Data Inicial"
              disabled={loading}
              placeholder="Escolher data"
              input={{
                value: startDate,
                onChange: value => {
                  this.onInputChange('startDate', value);
                  this.onInputChange('date', null);
                  this.onInputChange('dates', []);
                }
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} sm={12} md={5} lg={4} xl={3}>
            <XpertGoDateInput
              label="Data Final"
              disabled={loading}
              placeholder="Escolher data"
              input={{
                value: endDate,
                onChange: value => {
                  this.onInputChange('endDate', value);
                  this.onInputChange('date', null);
                  this.onInputChange('dates', []);
                }
              }}
              meta={{ valid: true }}
            />
          </Col>
          {/* <Col xs={24} md={6}>
            <XpertGoRangePickerInput
              label="Intervalo de datas"
              allowClear={true}
              placeholder="Escolher datas"
              input={{
                value: dates,
                onChange: value => {
                  this.onInputChange('dates', value);
                  this.onInputChange('date', null);
                  this.onInputChange('startDate', null);
                  this.onInputChange('endDate', null);
                }
              }}
              meta={{ valid: true }}
              disabled={loading}
            />
          </Col> */}
          <Col xs={24} sm={12} md={5} lg={4} xl={4}>
            <SelectInput
              label="Local"
              disabled={loading}
              allowClear
              placeholder="Escolher local"
              data={locals}
              input={{
                value: wedding_place,
                onChange: value => this.onInputChange('wedding_place', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
          {/* <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <SelectInput
              label="Organizador"
              disabled={loading}
              allowClear
              placeholder="Escolher organizador"
              data={organizers}
              input={{
                value: organizer,
                onChange: value => this.onInputChange('organizer', value)
              }}
              meta={{ valid: true }}
            />
          </Col> */}
          {/* <Col xs={24} sm={12} md={4} lg={3} xl={3}>
            <SelectInput
              label="Impresso"
              disabled={loading}
              allowClear
              placeholder="Escolher opção"
              data={printedOptions}
              dataKey='value'
              input={{
                value: printed,
                onChange: value => this.onInputChange('printed', value)
              }}
              meta={{ valid: true }}
            />
          </Col> */}
          <Col xs={24} sm={12} md={4} lg={4} xl={4}>
            <SelectInput
              label="Estado"
              disabled={loading}
              placeholder="Escolher estado"
              allowClear={true}
              data={weddingStatus}
              dataKey='value'
              input={{
                value: status,
                onChange: value => this.onInputChange('status', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} sm={12} md={4} lg={4} xl={4}>
            <SelectInput
              label="Pagamentos à equipa"
              disabled={loading}
              placeholder="Escolher estado"
              allowClear={true}
              data={[
                { _id: 'true', name: 'Completos' },
                { _id: 'false', name: 'Incompletos' }
              ]}
              input={{
                value: paymentsCompleted,
                onChange: value => this.onInputChange('paymentsCompleted', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

const mapStateToProps = state => ({
  locals: state.locals
});

export default connect(mapStateToProps)(WeddingsPaymentsFilters);
