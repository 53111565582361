import React, { Fragment, Component } from 'react';
import { initialize } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  SpinLoading,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import { OurDayPageContainer } from '../ChoicesPage/SectionStyles';
/*import {
  SectionInformation,
  InformationContainer
} from '../dashboard/ProcessStyles'; */
import { SaveNotes } from '../../redux/wedding/wedding.actions';
import { SaveSectionNotes } from '../../infra/requests/WeddingProcessRequests';
import {
  GetOurDay,
  AddMoment,
  EditMoment,
  DeleteMoment
} from '../../infra/requests/OurDayRequests';
import { BaseButton } from '../../components/buttons/BaseButton';
//import CommentsDrawer from '../../components/comments/CommentsDrawer';
import Timeline from '../../components/timeline/Timeline';
import AddMomentModal from './AddMomentModal';
import { categories, checkMomentExists, addNewMoment, findMoment, getNamesPT } from "./Categories";
import { findMomentByID } from '../../infra/services/ourDay/PageOurDay';
import Alert from "../../components/alert/Alert";
import NotesConfirmModal from '../../components/notesModal/NotesModal';
import { findPageByTag } from '../../infra/services/sections/Sections';
import { hasNotes } from '../../infra/services/notes/FindNotes';
import PrintedBar from '../dashboard/components/PrintedBar';
import CommentsDrawerFixed from '../../components/comments/CommentsDrawerFixed';
import { Icon, Modal, Tooltip } from 'antd';
import styledComponents from 'styled-components';
import { getMomentPrintedConfirmation, getPrintedConfirmation } from '../../infra/services/wedding/printedUtils';

const { confirm } = Modal;

export const RowWarning = styledComponents.div`
  position: absolute; 
  top: ${({printed}) => printed ? '60px' : '30px'}; 
  right: ${({ notesOpen }) => notesOpen ? '315px' : '15px'};

  .anticon {
    font-size: 30px;
    color: #fb635b;
  }
`;

class DecorationPage extends Component {
  state = {
    loading: true,
    loadNotes: false,
    openDrawer: false,
    openNotesConfirm: false,
    timelineList: [],
    categoriesList: [],
    openModal: false,
    editMoment: false,
    showNew: false,
    requiredError: false,
    dayInformation: undefined
  };

  componentDidMount() {
    this.getInformation();
  }

  getInformation = async () => {
    const { wedding } = this.props;

    const { data, success } = await GetOurDay(wedding.wedding._id);
    const dayInformation = findPageByTag(wedding.structure, 'DAY');
    let timelineList = [], categoriesList = [...categories];

    if (success) {
      timelineList = data;

      //Convert all moment names to PT (just in case)
      timelineList = getNamesPT(timelineList);

      if (timelineList.length > 0) {
        let index = -1, moment = undefined;

        for (let i = 0; i < timelineList.length; i++) {
          moment = findMoment(timelineList[i].name);

          //Remove base "Moments" that were already selected
          if (moment) {
            index = categoriesList.indexOf(moment);

            categoriesList.splice(index, 1);
          }
          else {
            //Add "Moments" that don't exist in the defined base Moments
            if (timelineList[i].name && timelineList[i].name !== 'Criar Momento' && !checkMomentExists(categoriesList, timelineList[i].name)) {
              categoriesList = addNewMoment(categoriesList, timelineList[i].name);
            }
          }
        }
      }
    }

    this.setState({
      loading: false,
      timelineList,
      categoriesList,
      showNew: false,
      requiredError: false,
      dayInformation
    });
  };

  checkTimelineInvalidMoments = () => {
    const { timelineList } = this.state;
    let invalid = false;

    timelineList.forEach((timeline) => {
      if (timeline && timeline?.time) {
        const timeSplit = timeline?.time.split(':');
        const hour = parseInt(timeSplit[0]);
        const minute = parseInt(timeSplit[1]);

        if (!(hour > 12 && hour <= 24 || hour >= 0 && hour < 4 || hour === 4 && minute === 0)) {
          invalid = true;
          return;
        }
      }
    });

    return invalid;
  }

  openModal = () => {
    this.setState({
      openModal: true
    });
  };

  closeModal = () => {
    const { dispatch } = this.props;

    //Clear the form (don't use "reset", as it will lose the Form Validations)
    dispatch(initialize('manage_our_day_form'));

    this.setState({
      openModal: false,
      editMoment: undefined,
      showNew: false,
      requiredError: false
    });
  };

  openEditModal = (e, id) => {
    const { dispatch } = this.props;
    const { timelineList } = this.state;

    e.preventDefault();
    e.stopPropagation();

    //Clear the form (don't use "reset", as it will lose the Form Validations)
    dispatch(initialize('manage_our_day_form'));

    //Find the Moment we want to edit
    const editMoment = findMomentByID(timelineList, id);

    this.setState({
      openModal: true,
      editMoment
    });
  };

  setShowNew = (value) => {
    this.setState({
      showNew: value
    });
  }

  onModalSubmit = async values => {
    const { wedding } = this.props;

    if (wedding?.wedding?.printed) {
      confirm({
        ...getMomentPrintedConfirmation(),
        onOk: () => { this.saveMoment(values); },
        onCancel: () => { this.closeNotes(); },
      });
    } else this.saveMoment(values);
  }

  saveMoment = async values => {
    try {
      const { wedding, dispatch } = this.props;
      const { editMoment, categoriesList } = this.state;

      //If we're creating a New Moment
      if (values.name === 'Criar Momento') {
        //It's required
        if (!values.newCategory) {
          this.setState({
            requiredError: true
          })

          return false;
        }

        //Check if it already exists
        if (checkMomentExists(categoriesList, values.newCategory)) {
          Alert.new({
            type: "error",
            title: "Erro!",
            text: "Já existe um momento com o mesmo nome!"
          });

          return false;
        }

        //Save the New Moment
        values.name = values.newCategory;
      }

      //Double-check the selected Hour (must be the format hh:mm)
      const parts = values.time.split(':');
      let hour = parseInt(parts[0]);
      let minute = parseInt(parts[1]);

      if (!values.time.includes(':') || hour > 23 || hour < 0 || minute > 59 || minute < 0) {
        Alert.new({
          type: "error",
          title: "Erro!",
          text: 'Devem introduzir a hora com um formato válido entre "00:00" e "23:59"'
        });

        return false;
      }

      //FIX: Hours and Minutes with 2 digits
      if (hour.toString().length < 2) {
        hour = `0${hour}`;
      }
      if (minute.toString().length < 2) {
        minute = `0${minute}`;
      }

      values.time = `${hour}:${minute}`;

      this.setState({
        // loading: true,
        openModal: false
      });

      //Edit a Moment
      if (editMoment) {
        await EditMoment(editMoment._id, { ...values, newCategory: undefined, wedding: wedding.wedding._id });
      }
      //Save a new Moment
      else {
        await AddMoment({ ...values, newCategory: undefined, wedding: wedding.wedding._id });
      }

      //Clear the form (don't use "reset", as it will lose the Form Validations)
      dispatch(initialize('manage_our_day_form'));

      this.closeModal();
      await this.getInformation();
    } catch (e) {
      console.error(e);
    }
  };

  deleteMoment = async id => {
    try {
      const { success } = await DeleteMoment(id);

      if (success) {
        await this.getInformation();
      }
    } catch (e) {
      console.error(e);
    }
  };

  openNotes = () => {
    const { wedding, dispatch } = this.props;
    const { openDrawer } = this.state;
    if (!openDrawer) {
      const notes = wedding.notes.find(note => note.section === 'DAY');
      dispatch(initialize('wedding_notes_section_form', notes));
      this.setState({ openDrawer: true });
    }
  };

  closeNotes = () => {
    const { wedding, dispatch } = this.props;
    const notes = wedding.notes.find(note => note.section === 'DAY');
    dispatch(initialize('wedding_notes_section_form', notes));
    this.setState({ openDrawer: false, openNotesConfirm: false });
  };

  checkBeforeCloseNotes = (values) => {
    const { wedding, dispatch } = this.props;
    const notes = wedding.notes.find(note => note.section === 'DAY');

    //If theres any change to the notes, ask before closing if we want to save the changes
    if (values && (notes?.notes !== values.notes || notes?.private !== values.private)) {
      dispatch(initialize('notes_modal_confirm_form', values));
      this.setState({ openNotesConfirm: true });
    }
    //Otherwise, just the close the notes
    else {
      this.setState({ openDrawer: false });
    }
  }

  saveNotes = async values => {
    const { wedding } = this.props;

    if (wedding?.wedding?.printed) {
      confirm({
        ...getPrintedConfirmation(),
        onOk: () => { this.updateNotes(values); },
        onCancel: () => { this.closeNotes(); },
      });
    } else this.updateNotes(values);
  };

  updateNotes = async values => {
    const { wedding, saveNotesOnReducer, dispatch } = this.props;

    this.setState({ loadNotes: true });

    const { data } = await SaveSectionNotes(wedding.wedding._id, {
      ...values,
      section: 'DAY'
    });

    dispatch(initialize('wedding_notes_section_form', data));
    saveNotesOnReducer(data);

    const { openNotesConfirm } = this.state;
    if (openNotesConfirm) {
      return this.setState({
        loadNotes: false,
        openDrawer: false,
        openNotesConfirm: false
      });
    }
    return this.setState({ loadNotes: false });
  };

  render() {
    const { wedding } = this.props;
    const {
      loading,
      openDrawer,
      loadNotes,
      timelineList,
      categoriesList,
      openModal,
      editMoment,
      showNew,
      requiredError,
      openNotesConfirm,
      //dayInformation
    } = this.state;

    if (loading) return <SpinLoading />;

    return (
      <Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>O nosso dia</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Novo Momento'}
              onClick={() => this.openModal()}
            />
            <BaseButton
              type={'default'}
              icon={'read'}
              text={'Notas'}
              onClick={this.openNotes}
              notes={hasNotes(wedding.notes, 'DAY')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        {
          wedding?.wedding?.printed && <PrintedBar small={true} />
        }
        <OurDayPageContainer printed={wedding?.wedding?.printed}>
          {
            /*Client does not want this Our Day text to appear in the our day page
            dayInformation?.information && 
            <InformationContainer>
              <SectionInformation dangerouslySetInnerHTML={{__html: dayInformation.information.pt}}/>
            </InformationContainer>*/
          }
          {this.checkTimelineInvalidMoments() && <RowWarning notesOpen={openDrawer} printed={wedding?.wedding?.printed}>
            <Tooltip title='Momentos antes das 13:00 e/ou depois das 04:00.' visible={true} autoAdjustOverflow={false} placement='left' overlayClassName='warningTooltip'>
              <Icon type='warning' />
            </Tooltip>
          </RowWarning>}
          <Timeline
            loading={loading}
            list={timelineList}
            deleteMoment={this.deleteMoment}
            openEditModal={this.openEditModal}
            notesOpen={openDrawer}
            printed={wedding?.wedding?.printed}
          />
          <CommentsDrawerFixed
            open={openDrawer}
            loading={loadNotes}
            onClose={this.checkBeforeCloseNotes}
            saveNotes={this.saveNotes}
            printed={wedding?.wedding?.printed}
          />
        </OurDayPageContainer>
        <AddMomentModal
          open={openModal}
          onSubmit={this.onModalSubmit}
          closeModal={this.closeModal}
          categories={categoriesList}
          editMoment={editMoment}
          showNew={showNew}
          setShowNew={this.setShowNew}
          requiredError={requiredError}
        />
        {/* <CommentsDrawer
          title="Notas De O Nosso Dia"
          open={openDrawer}
          loading={loadNotes}
          onClose={this.checkBeforeCloseNotes}
          saveNotes={this.saveNotes}
        /> */}
        <NotesConfirmModal
          open={openNotesConfirm}
          loading={loadNotes}
          onSubmit={this.saveNotes}
          closeModal={() => { this.setState({ openNotesConfirm: false }) }}
          exitWithoutSaving={this.closeNotes}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  wedding: state.wedding
});

const mapActionToProps = dispatch =>
  bindActionCreators({ saveNotesOnReducer: SaveNotes, dispatch }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(DecorationPage);
