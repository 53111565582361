import client from '../config/AxiosConfig';

export const GetConfigs = async () => client.get('/configs');
export const GetConfig = async id => client.get(`/configs/${id}`);
export const GetConfigByPlace = async (place) => client.get(`/configs/findByPlace/${place}`);

export const GetConfigByWedding = async weddingId => client.get(`/configs/wedding/${weddingId}`);
export const GetConfigByArchived = async archivedId => client.get(`/configs/archived/${archivedId}`);

export const UpdateConfigType = async (id, data) =>
  client.put(`/configs/${id}`, data);

export const UploadRoomPlan = async (id, data) =>
  client.put(`/configs/${id}/room-plan`, data);

// export const UploadBackgroundRoomPlan = async (id, data) =>
//   client.put(`/configs/${id}/bgRoomPlan`, data);
