export const dateTimeFormat = 'YYYY-MM-DD HH:mm';
export const dateFormat = 'YYYY-MM-DD';
export const isoDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.sssZ';
export const timeFormat = 'HH:mm';

export const isEmployeeOrChief = () => {
  try {
    if (JSON.parse(localStorage.user).employee == true || JSON.parse(localStorage.user).chief == true)
      return true
    else
      return false
  }
  catch { return false }
}

export const isCurrentUserHumanResources = () => {
  try {
    if (JSON.parse(localStorage.user).humanResources == true)
      return true
    else
      return false
  } catch { return false }
}
export const isCurrentUserAdmin = () => {
  try {
    if (JSON.parse(localStorage.user).admin == true)
      return true
    else
      return false
  } catch { return false }
}

export const isCurrentUserChief = () => {
  try {
    if (JSON.parse(localStorage.user).chief == true)
      return true
    else
      return false
  } catch { return false }
}

export const isCurrentUserEmployee = () => {
  try {
    if (JSON.parse(localStorage.user).employee == true)
      return true
    else
      return false
  } catch { return false }
}

export const isCurrentUserEmployeeTrainnee = () => {
  try {
    if (JSON.parse(localStorage.user).employeeTrainee == true)
      return true
    else
      return false
  } catch { return false }
}

export const isCurrentUserEmployeeAttending = () => {
  try {
    if (JSON.parse(localStorage.user).employeeAttending == true)
      return true
    else
      return false
  } catch { return false }
}

export const isCurrentUserOrganizer = () => {
  try {
    if (JSON.parse(localStorage.user).organizer == true)
      return true
    else
      return false
  } catch { return false }
}

export const getUserId = () => {
  try {
    return JSON.parse(localStorage.user)._id;
  } catch { return false }
}

export const getCurrentUser = () => {
  try {
    const user = JSON.parse(localStorage.user);
    if(user?.permissions) delete user?.permissions;
    return user;
  } catch { return false }
}

export const hasPermissions = (requiredPermissions = []) => {
  try {
    // If route has specified permissions
    if (requiredPermissions && requiredPermissions?.length > 0) {
      const currentUser = JSON.parse(localStorage.user);
      if (!isCurrentUserAdmin()) return true;

      // If current user doesn't have specified permissions
      if (!currentUser?.permissions
        || currentUser?.permissions?.length == 0) {
        return false;
      }

      // Check all permissions required by route
      for (let requiredPermission of requiredPermissions) {
        if (!currentUser?.permissions?.includes(requiredPermission)) {
          return false;
        }
      }

      return true;
    } else {
      return true;
    }
  } catch {
    return false;
  }
}


export const isUserAllowed = (route) => {
  const requiredPermissions = route?.permissions || [];

  if (isCurrentUserAdmin() && !route?.onlyEmployeeAttending && !route?.onlyEmployee) {
    return hasPermissions(requiredPermissions) ? true : false;
  }
  if (isCurrentUserChief()) {
    // return route?.chiefAllowed && hasPermissions(requiredPermissions) ? true : false;
    return route?.chiefAllowed;
  }
  if (isCurrentUserHumanResources()) {
    // return route?.humanResoursesAllowed && hasPermissions(requiredPermissions) ? true : false;
    return route?.humanResoursesAllowed;
  }
  if (isCurrentUserEmployeeAttending()) {
    // return route?.employeeAttendingAllowed && hasPermissions(requiredPermissions) ? true : false;
    return route?.employeeAttendingAllowed;
  }
  if (isCurrentUserEmployee()) {
    // return route?.employeeAllowed && hasPermissions(requiredPermissions) ? true : false;
    return route?.employeeAllowed;
  }
  if (route?.employeeAllowed == undefined || route?.chiefAllowed == undefined
    || route?.humanResoursesAllowed == undefined || route?.employeeAttendingAllowed == undefined) {
    return true;
  }
  return false;
}

export const inTest = () => {
  return process.env.REACT_APP_TEST == 'true' ? true : false;
}

export const inRoomPlanTest = () => {
  return process.env.REACT_APP_ROOM_PLAN_TEST == 'true' ? true : false;
}