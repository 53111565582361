import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { InputDiv, InputLabelDiv, InputField } from './InputStyles';

const TextInput = ({
  input,
  meta,
  prefix,
  size,
  suffix,
  type,
  small,
  onPressEnter,
  label,
  first,
  placeholder,
  disabled,
  noLabel,
  requiredError,
  maxLength,
  tooltip,
  tooltipMsg,
  search = false,
  restrictionOrder = false,
}) => {
  const { invalid, submitFailed } = meta;
  let showError = invalid && submitFailed;

  if (requiredError) {
    showError = true;
  }

  return (
    <InputDiv restrictionOrder={restrictionOrder} first={first || noLabel} search={search}>
      {
        !noLabel && <InputLabelDiv small={small}>{label}</InputLabelDiv>
      }
      {
        tooltip ?
          <Tooltip title={tooltipMsg}>
            <InputField
              disabled={disabled}
              prefix={prefix}
              size={size}
              suffix={suffix}
              type={type}
              value={input.value}
              onChange={input.onChange}
              onPressEnter={onPressEnter}
              placeholder={placeholder}
              error={showError ? 1 : 0}
              maxLength={maxLength}
              restrictionOrder={restrictionOrder}
            />
          </Tooltip> :
          <InputField
            disabled={disabled}
            prefix={prefix}
            size={size}
            suffix={suffix}
            type={type}
            value={input.value}
            onChange={input.onChange}
            onPressEnter={onPressEnter}
            placeholder={placeholder}
            error={showError ? 1 : 0}
            maxLength={maxLength}
            restrictionOrder={restrictionOrder}
          />
      }
    </InputDiv>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  onPressEnter: PropTypes.func
};

export default TextInput;
