import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import SectionsID from '../../infra/services/sections/SectionID';
import RangePickerInput from '../../components/inputs/RangePickerInput';
import moment from 'moment';

class LogsFilters extends Component {
  state = {
    search: '',
    section: '',
    chapter: '',
    question: '',
    user: '',
    dates: [moment().subtract(1, 'week'), moment()],
    sections: []
  };

  componentDidMount = () => {
    //Check if the elements were not already added to the array
    const index = SectionsID.findIndex(elem => elem.id === 'upgrade');
    if (index === -1) {
      SectionsID.push({
        id: 'upgrade',
        name: 'Upgrade'
      });
      SectionsID.push({
        id: 'budget',
        name: 'Orçamento'
      });
      SectionsID.push({
        id: 'process',
        name: 'Processo'
      });
      SectionsID.push({
        id: 'orders',
        name: 'Encomendas'
      });
      SectionsID.push({
        id: 'room_plan_template',
        name: 'Planos base'
      });
    }

    this.setState({
      sections: SectionsID
    });
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange, wedding } = this.props;
    const query = {};
    Object.keys(this.state).map(key => {
      if (key !== 'sections' && this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });

    if (wedding) query['wedding'] = wedding;
    
    queryChange(JSON.stringify(query));
  };

  changeSection = (value) => {
    const { checkSection } = this.props;

    this.setState({
      chapter: '',
      question: ''
    });

    checkSection(value);
  }

  changeChapter = (value) => {
    const { checkChapter } = this.props;
    
    this.setState({
      question: ''
    });

    checkChapter(value);
  }

  render() {
    const { users, chapters, questions } = this.props;
    const { search, user, section, chapter, question, sections, dates } = this.state;

    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={6}>
            <TextInput
              input={{
                value: search,
                onChange: event =>
                  this.onInputChange('search', event.target.value, 1000)
              }}
              meta={{ valid: true }}
              type="text"
              label="Pesquisar"
              placeholder="Procurar na descrição"
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label="Utilizador"
              allowClear
              placeholder="Escolher utilizador"
              data={users}
              dataKey='id'
              dataValue='name'
              input={{
                value: user,
                onChange: value => this.onInputChange('user', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} md={6}>
            <RangePickerInput
              label="Intervalo de datas"
              allowClear={true}
              placeholder="Escolher datas"
              input={{
                value: dates,
                onChange: value => this.onInputChange('dates', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label="Secção"
              allowClear
              placeholder="Escolher secção"
              data={sections}
              dataKey='id'
              dataValue='name'
              input={{
                value: section,
                onChange: value => this.onInputChange('section', value)
              }}
              meta={{ valid: true }}
              execAfterChange={this.changeSection}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={8}>
            <SelectInput
              label="Capítulo"
              allowClear
              placeholder="Escolher capítulo"
              data={chapters}
              dataKey='id'
              dataValue='name'
              input={{
                value: chapter,
                onChange: value => this.onInputChange('chapter', value)
              }}
              meta={{ valid: true }}
              execAfterChange={this.changeChapter}
            />
          </Col>
          <Col xs={24} md={16}>
            <SelectInput
              label="Pergunta"
              allowClear
              placeholder="Escolher pergunta"
              data={questions}
              dataKey='id'
              dataValue='name'
              input={{
                value: question,
                onChange: value => this.onInputChange('question', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

const mapStateToProps = state => ({
  locals: state.locals
});

export default connect(mapStateToProps)(LogsFilters);