import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Icon, Row, Col, Steps } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import FormValidator from '../../infra/services/validations/FormValidator';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import SelectInput from '../../components/inputs/SelectInput';
import DraftInput from '../../components/inputs/DraftInput';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import TextInput from '../../components/inputs/TextInput';
import { PrevButton, StyledStep, StyledSteps, TableContent, Title } from '../reservesAttending/ReserveInfoModalStyles';
import { BaseForm, SpinLoading } from '../../styles/BasicStyles';
import PropTypes from 'prop-types';
import { CreateSetting, GetSettingsByType } from '../../infra/requests/SettingRequests';
import BaseButton from '../../components/buttons/BaseButton';
import { InputField, InputGroupField, InputLabelDiv } from '../../components/inputs/InputStyles';
import SelectCompactInput from '../../components/inputs/SelectCompactInput';
import { GetAttendingEmployees, GetOrganizerList } from '../../infra/requests/UsersRequests';
import { getOrganizers } from '../../infra/requests/AdminsRequests';

const validations = (values) => {
    let errors = {};

    const validate = {};

    if (values?.mechanicalNumberSolar && values?.mechanicalNumberSolar != '') {
        validate['mechanicalNumberSolar'] = 'min:1';
    }
    if (values?.mechanicalNumberLago && values?.mechanicalNumberLago != '') {
        validate['mechanicalNumberLago'] = 'min:1';
    }

    errors = FormValidator.make(validate)(values);

    if (values?.mechanicalNumberSolar && values?.mechanicalNumberSolar != ''
        && values?.mechanicalNumberLago && values?.mechanicalNumberLago != ''
        && values?.mechanicalNumberSolar == values?.mechanicalNumberLago) {
        errors.mechanicalNumberSolar = 'Nº mecanográficos iguais.'
        errors.mechanicalNumberLago = 'Nº mecanográficos iguais.'
    }

    return errors;
};

let MechanicalModal = ({
    isOpen,
    saving,
    handleSubmit,
    initialValues,
    onSubmit,
    onClose,
    mechanicalForm
}) => {


    return (
        <Modal
            visible={isOpen}
            title='Nº mecanográficos'
            maskClosable
            onCancel={onClose}
            footer={[
                <Button
                    key='cancel'
                    type='default'
                    onClick={onClose}>
                    Cancelar
                </Button>,
                <Button
                    key='submit'
                    type='primary'
                    loading={saving}
                    onClick={handleSubmit(onSubmit)}>
                    Gravar
                </Button>
            ]}>

            <BaseForm onSubmit={handleSubmit(onSubmit)}>
                <Field
                    component={TextInput}
                    name="mechanicalNumberSolar"
                    label={`Nº mecanográfico SLE`}
                    type="number"
                />
                <Field
                    component={TextInput}
                    name="mechanicalNumberLago"
                    label={`Nº mecanográfico QLC`}
                    type="number"
                />
            </BaseForm>
        </Modal>
    );
};

MechanicalModal = reduxForm({
    form: 'mechanical_form',
    enableReinitialize: true,
    validate: validations,
})(MechanicalModal);

const selector = formValueSelector('mechanical_form');

const mapStateToProps = state => ({
    mechanicalForm: {
        mechanicalNumberSolar: selector(state, 'mechanicalNumberSolar'),
        mechanicalNumberLago: selector(state, 'mechanicalNumberLago'),
    }
});

MechanicalModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(MechanicalModal)