import React from 'react';
import { Modal, Button, Row, Icon } from 'antd';

let ModalTeamMng = ({ isOpen, onSubmit, onClose, initialValues }) => {
    const footer = [
        <Button
            key='cancel'
            onClick={() => onClose()}>
            Cancelar
        </Button>,
        <Button
            key='submit'
            type='primary'
            onClick={onSubmit}>
            {initialValues.createPayments ? 'Gerar Pagamentos' : initialValues.publish || initialValues.publishRH ? 'Publicar' : 'Gravar'}
        </Button>
    ];
    return (
        <Modal
            visible={isOpen}
            title={'Confirmar'}
            centered={true}
            width={300}
            onCancel={onClose}
            footer={footer}
        >
            <Row style={{ display: 'inline-flex' }}>
                <Icon type='warning' style={{ color: 'orange', fontSize: '2rem', margin: 'auto 10px auto 0' }}></Icon>
                {initialValues.createPayments ? <div style={{ margin: 'auto' }}>
                    <p style={{ marginBottom: 0 }}>Tem a certeza que pretende gerar os pagamentos?</p>
                </div> : initialValues.publishRH ? <div style={{ margin: 'auto' }}>
                    <p style={{ marginBottom: 0 }}>A encomenda não foi publicada! Deseja publicar a encomenda e a equipa?</p>
                </div> : initialValues.publish ?
                    <div style={{ margin: 'auto' }}>
                        <p style={{ marginBottom: 0 }}>Tem a certeza que pretende publicar a equipa?</p>
                        {/* {initialValues.teamComplete && initialValues.teamComplete.length > 0 && initialValues.teamComplete.filter(f => f.confirmed).length !== initialValues.teamComplete.length ?
                            <p style={{ marginBottom: 0 }}>A equipa não está completamente confirmada. Deseja continuar?</p> :
                            <p style={{ marginBottom: 0 }}>Tem a certeza que pretende publicar a equipa?</p>} */}
                    </div> :
                    <div style={{ margin: 'auto' }}>
                        {initialValues.updatedBooking && initialValues.weddingTeam.length > 0 ?
                            <p style={{ lineHeight: '1.5rem', marginBottom: 10 }}>
                                A alteração na encomenda poderá alterar a equipa do casamento.
                            </p> : null}

                        {initialValues.updatedTeam ? <p style={{ lineHeight: '1.5rem', marginBottom: 10 }}>
                            A alteração da equipa poderá alterar a encomenda do casamento.
                        </p> : null}

                        <p style={{ marginBottom: 0 }}>Pretende gravar as alterações?</p>
                    </div>}
            </Row>
        </Modal>
    );
};

export default ModalTeamMng;