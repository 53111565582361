import { createGlobalStyle } from 'styled-components';

export const ResponsiveStyles = createGlobalStyle`

  @import url('https://fonts.googleapis.com/css?family=Abel&display=swap');

  @import 'cropperjs/dist/cropper.css';

  @media screen and (min-width: 1024px) {
    .mobile {
      display: none !important;
    }

    .desktop {
      display: block;
    }

    .display-flex {
      display: inline-flex;
    }

    .desktop-width-80 {
      width: 100%;
      text-align: left;
    } 

    .desktop-width-80 .fBFhBL {
      text-align: left !important;
    }

    .ant-row-col .ant-col, .ant-row-col div, .ant-row-margin .ant-col {
      padding: 0 5px;
    }

    .ant-row-margin .ant-col .ant-btn {
      margin-top: 20px;
    }

    .payment-table-container {
      position: relative;
    }

    .wedding-team-header {
      display: inline-flex;
      width: 100%;
      position: relative;
      margin-bottom: 1rem;
    }

    .wedding-team-header .wedding-team-btns {
      width: fit-content;
      margin-bottom: 10px;
      right: 0;
      position: absolute;
      display: inline-flex;
      justify-content: end;
    }

    .wedding-team-header .wedding-team-btns .ant-btn {
      margin: 0 5px;
    }

    .booking-btn { 
      margin: 0 0 10px;
      position: absolute; 
      top: -55px;
      right: 0
    }

    .employee-center span {
      margin-left: 5px;
    }

    .setting-col-btn {
      height: 66px;
      position: relative;
    }

    .setting-col-btn .ant-btn, 
    .subcategory-col-btn .ant-btn {
      position: absolute;
      bottom: 0;
      right: 15px;
    }

    .graphic-col-btn {
      height: 66px;
      position: relative;
    }

    .graphic-col-btn div {
      position: absolute;
      bottom: 0;
      right: 15px;
    }

    .subcategory-col-btn {
      height: 35px;
      position: relative;
    }

    .hshroI {
      display: inline-flex;
      position: absolute;
      right: 15px;
      justify-content: end;
    }

    #pageContainerCalendarAdmin {
      margin-top: 70px;
    }

    .employee-home-btn-container .kSUGPl {
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
    }

    .employee-home-btn-container .home-btn-content {
      position: absolute; 
      top: 0;
      right: 0;
    }

    .employee-home-btn-container .home-btn-content .ant-btn {
      margin: 0 5px;
    }

    .btn-rest-days {
      display: flex !important;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: flex-end;
    }

    .profile-atention-bar {
      margin-top: 73px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .mobile {
      display: none !important;
    }

    .desktop {
      display: block;
    }

    .display-flex {
      display: inline-flex !important;
    }

    .bZBMGZ, .hshroI, .cMRtpp {
      display: inline-flex !important;
      justify-content: end;
    }
    
    .hshroI .sc-cSHVUG, .bZBMGZ .sc-cSHVUG {
      width: fit-content;
      margin: 0 5px;
    }

    .ant-table-content {
      overflow-y: hidden;
      overflow-x: scroll;
    }

    .ant-row-col .ant-col, .ant-row-col div, .ant-row-margin .ant-col {
      padding: 5px;
    }

    .ant-row-margin {
      text-align: left;
    }

    .ant-row-margin .ant-col .ant-btn {
      margin-top: 20px;
    }

    .wedding-team-header {
      display: inline-flex;
      width: 100%;
      position: relative;
      margin-bottom: 3rem;
    }

    .wedding-team-header .wedding-team-btns {
      position: absolute;
      right: 0;
      display: inline-flex;
      justify-content: end;
    }

    .wedding-team-header .wedding-team-btns .ant-btn {
      width: fit-content !important;
      margin: 0 5px;
    }

    .employees-charge-content .ant-row .ant-col .ant-btn, .booking-btn .ant-btn {
      height: 32px !important;
      margin: auto 0 !important;
      justify-content: center;
    }

    .booking-btn {
      position: relative;
      width: 100%;
      height: 32px;
      margin-bottom: 10px;
      justify-content: end;
      display: inline-grid;
    }

    .btn-rest-days {
      display: flex !important;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: flex-end;
    }

    .setting-col-btn {
      height: 66px;
      position: relative;
    }

    .setting-col-btn .ant-btn, .subcategory-col-btn .ant-btn {
      position: absolute;
      bottom: 0;
      right: 15px;
    }

    .graphic-col-btn {
      height: 66px;
      position: relative;
    }

    .graphic-col-btn div {
      position: absolute;
      bottom: 0;
      right: 15px;
    }

    .subcategory-col-btn {
      height: 35px;
      position: relative;
    }

    .btn-payment-container {
      position: absolute;
      top: 32px;
      right: 0;
      z-index: 49;
    }

    .btn-payment-container .ant-btn {
      height: 32px !important;
      justify-content: center;
      margin: auto 0;
    }

    .payment-table-container {
      position: relative;
      overflow-y: hidden;
      overflow-x: scroll;
    }

    .payment-table-container .ant-table-content {
      overflow: unset;
    }

    .employee-home-btn-container .home-btn-content {
      position: absolute;
      bottom: 10px;
      right: 0;
    }

    .employee-home-btn-container .home-btn-content .ant-btn {
      margin: 0 5px;
    }

    .profile-atention-bar {
      margin-top: 73px;
    }
  }

  @media screen and (max-width: 768px) {
    .mobile {
      display: block;
    }

    .desktop {
      display: none !important;
    }

    .display-flex {
      display: inline-flex;
    }

    .mobile-full-width {
      width: 100%;
      text-align: left;
    }

    .mobile-full-width .fBFhBL {
      text-align: left !important;
    }

    .mobile-grid {
      width: calc(100% - 40px);
    }

    .ant-row-col .ant-col, .ant-row-col div, .ant-row-margin .ant-col {
      padding: 5px;
    }

    .ant-row-margin .ant-col .ant-btn {
      margin-top: 20px;
    }

    .ihzUgD{
      width: 40px !important;
    }

    .cvfhOV {
      padding: 0 !important;
    }

    .ant-layout-sider-collapsed {
      max-width: 40px !important;
      min-width: 40px !important;
      width: 40px !important;
    }

    .ant-layout-sider-collapsed .ant-layout-sider-trigger {
      width: 40px !important;
    }

    .ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
      padding: 0 10px !important;
    }

    .fjvAcA {
      padding-left: 40px !important;
      position: relative;
    }

    .isPsJY {
      width: calc(100% - 40px);
      position: fixed !important;
    }

    .hBhDVl {
      height: inherit;
      padding-right: 0;
    }

    .lhUujJ {
      right: 20px !important;
      padding: 5px 5px;
    }

    .cAbpya {
      width: calc(100% - 40px) !important;
      position: absolute !important;
      left: 40px;
    }

    .bZBMGZ, .hshroI, .cMRtpp {
      display: inline-flex !important;
      justify-content: end;
    }
    
    .hshroI .sc-cSHVUG, .bZBMGZ .sc-cSHVUG {
      width: fit-content;
      margin: 0 5px;
    }

    .cMRtpp {
      margin-top: 1rem;
    }

    .cMRtpp .sc-cSHVUG {
      margin: 0;
    }

    .cDrpYr {
      margin-top: 140px;
    }

    .ant-btn {
      min-height: 32px;
      height: auto !important;
      display: inline-flex !important;
      padding: 0 5px !important;
      justify-content: center;
    }

    .ant-btn i {
      margin: auto 0;
    }

    .ant-btn span { 
      white-space: break-spaces;
      margin: auto 0;
    }

    .ant-table-content {
      overflow-y: hidden;
      overflow-x: scroll;
    }

    .cEfuAQ {
      margin: 142px 0 0 0 !important;
    }

    .ant-tabs-tab {
      padding: 5px 5px 5px 0 !important;
    }

    .ant-tabs-tab .tab-name {
      display: none;
    }

    .ant-tabs-tab i {
      font-size: 20px;
    }

    .ant-tabs .ant-tabs-left-content {
      padding-left: 10px !important;
    }

    .tab-content-btn {
      padding-let: 0;
      padding-right: 0;
    }

    .btn-payment-container {
      position: absolute;
      right: 0;
      top: 32px;
      z-index: 49;
    }

    .btn-payment-container .ant-btn {
      height: 32px !important;
      justify-content: center;
      margin: auto 0;
    }

    .payment-table-container {
      position: relative;
      overflow-y: hidden;
      overflow-x: scroll;
    }

    .btn-rest-days {
      display: flex !important;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: flex-end;
    }

    .payment-table-container .ant-table-content {
      overflow: unset;
    }

    .dashboard-header-container .sc-hwwEjo {
      margin: 5px 0;
      width: 100%;
    }

    .adminWeddings {
      overflow-y: hidden;
      overflow-x: scroll;
    }

    .wedding-team-header {
      display: inline-flex;
      width: 100%;
      position: relative;
      margin-bottom: 3rem;
    }

    .wedding-team-header .wedding-team-btns {
      position: absolute;
      right: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: end;
      max-width: calc(100% - 125px);
    }

    .wedding-team-header .wedding-team-btns .ant-btn {
      width: fit-content !important;
      margin-inline-start: 5px;
      margin-block-start: 5px;
    }

    .employees-charge-content {
      text-align: left;
      width: 100%;
    }

    .employees-charge-content .ant-row .ant-col .ant-btn, .booking-btn .ant-btn {
      height: 32px !important;
      margin: auto 0 !important;
      justify-content: center;
    }

    .booking-btn {
      position: relative;
      width: 100%;
      height: 32px;
      margin-bottom: 10px;
      justify-content: end;
      display: inline-grid;
    }

    .employee-center {
      text-align: center;
    }

    .setting-col-btn {
      height: 66px;
      position: relative;
    }

    .setting-col-btn .ant-btn, .subcategory-col-btn .ant-btn {
      position: absolute;
      bottom: 0;
      right: 15px;
    }

    .graphic-col-btn {
      height: 66px;
      position: relative;
    }

    .graphic-col-btn div {
      position: absolute;
      bottom: 0;
      right: 15px;
    }

    .subcategory-col-btn {
      height: 35px;
      position: relative;
    }

    .payment-detail .ant-col {
      margin: 5px;
    }

    .employee-home-btn-container .home-btn-content {
      position: absolute;
      bottom: 10px;
      right: 0;
    }

    .employee-home-btn-container .home-btn-content .ant-btn {
      margin: 0 5px;
    }

    .employee-atention-bar {
      margin-top: 90px;
    }

    .profile-atention-bar {
      margin-top: 75px;
    }

    .fc .fc-toolbar-title {
      font-size: 1.75em;
      margin: 0 5px;
      text-align: center;
    }
  }

  @media screen and (max-width: 575px) {
    .mobile {
      display: block;
    }

    .desktop {
      display: none !important;
    }

    .mobile-sm-full-width {
      width: calc(100% - 30px);
      text-align: left;
    }

    .mobile-grid {
      display: inline-grid;
      width: calc(100% - 40px);
    }

    .mobile-content {
      margin-top: 140px;
      margin-bottom: 345px;
    }

    .fZHRRO {
      width: auto !important;
    }

    .fZHRRO .sc-cSHVUG {
      margin-left: 5px !important;
      margin-top: 5px;
      float: unset !important;
    }

    .bZBMGZ, .hshroI, .cMRtpp {
      display: inline-flex !important;
      margin: 1rem -5px 0 -5px;
      width: calc(100% + 10px);
      justify-content: start;
    }

    .gcPLIO {
      display: inline-block;
      text-align: left;
    }

    .hZejIP {
      display: flex;
      justify-content: flex-end;
    }

    .wedding-team-header {
      display: block;
      width: 100%;
      position: relative;
      min-height: 210px;
      margin-bottom: 1rem;
    }

    .wedding-team-header .kSUGPl {
      width: 100% !important;
    }

    .wedding-team-header .wedding-team-btns {
      display: inline-grid;
      width: 100%;
      justify-content: unset;
      max-width: 100%;
    }

    .wedding-team-header .wedding-team-btns .ant-btn {
      width: 100% !important;
      margin: 5px 0;
    }

    .ant-row-margin .ant-col .ant-btn {
      margin-top: 5px;
    }

    .employees-charge-content .ant-row .ant-col .ant-btn, .booking-btn .ant-btn {
      height: 32px !important;
      margin: auto 0 !important;
      justify-content: center;
    }

    .booking-btn {
      position: relative;
      width: 100%;
      height: 32px;
      margin-bottom: 10px;
    }

    .fc .fc-toolbar {
      margin-top: 1rem;
      display: inline !important;
    }

    .fc-direction-ltr {
      text-align: center !important;
    }

    .setting-col-btn {
      position: relative;
      width: 100%;
      margin-top: 10px;
    }

    .setting-col-btn .ant-btn, .subcategory-col-btn .ant-btn {
      position: relative;
      width: 100%;
      right: 0;
    }

    .graphic-col-btn {
      position: relative;
      width: 100%;
      margin-top: 10px;
    }

    .graphic-col-btn div {
      position: relative;
      width: 100%;
      right: 0;
    }

    .employee-atention-bar {
      margin-top: 132px;
    }

    .profile-atention-bar {
      margin-top: 90px;
    }

    .btn-payment-container {
      position: relative;
      top: 0;
      width: 100% !important;
    }

    .btn-payment-container .ant-btn {
      height: 32px !important;
      width: 100%;
      justify-content: center;
      margin: auto 0;
    }

    .employee-home-btn-container .kSUGPl, .employee-home-btn-container .home-btn-content {
      position: relative;
      top: 0;
    }

    .employee-home-btn-container .home-btn-content .ant-btn {
      margin: 0 5px;
    }
  }
`;

//margin: auto 0 10px auto !important;