import InitialState from '../InitialState';
import {SAVE_INFO, UPDATE_PLAN_ROOM} from '../ActionsType';

const UpdateRoomPlan = (state, id, new_config) => {
  const result = {...state};
  result.configs = result.configs.map(config => {
    if (config._id === id) {
      return new_config;
    }
    return config;
  });
  return result;
};

export default function(state = InitialState.info, action) {
  switch (action.type) {
    case SAVE_INFO:
      return {...action.info, ready: true};

    case UPDATE_PLAN_ROOM:
      return UpdateRoomPlan(state, action.id, action.config);

    default:
      return state;
  }
}
