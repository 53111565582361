import React, { Component } from 'react';
import moment from 'moment';
import { Breadcrumb } from 'antd';
import {
  SpinLoading,
  HeaderContainer,
  HeaderTitle,
  PageTitle,
  PageContainer,
  SectionTitle,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import { GetWedding, GetWeddingInfo, UpdateWedding } from '../../infra/requests/WeddingRequests';
import { GetEmployeeByUser } from '../../infra/requests/EmployeeRequests';
import WeddingInfo from './WeddingInfo';
import { GetOurDayByWedding } from '../../infra/requests/OurDayRequests';
import { CreateTeamBooking, DeleteTeamBooking, GetTeamBookingByWedding, UpdateTeamBooking } from '../../infra/requests/TeamBookingRequests';
import { CreateWeddingTeam, DeleteWeddingTeam, GetWeddingTeamByWedding, GetWeddingTeamByWeddingAndEmployee, NotifyWeddingTeam, PublishWeddingTeam, SaveTeamInfo, UpdateWeddingTeam } from '../../infra/requests/WeddingTeamRequests';
import Alert from "../../components/alert/Alert";
import AddEditWeddingTeam from './AddEditWeddingTeam';
import { GetSubcategoriesByType, GetSubcategoriesInCharge } from '../../infra/requests/SubcategoryRequests';
import { getScheduler, getWeddingTeamAndEmployeesInCharge } from '../../infra/services/wedding/weddingUtils';
import AddEditEmployeesInCharge from '../adminTeamMng/AddEditEmployeesInCharge';
import { GetColorSystemByType } from '../../infra/requests/ColorSystemRequests';
import ModalTeamMng from '../adminTeamMng/ModalTeamMng';
import { T } from 'antd/lib/upload/utils';
import ModalTeamNotification from '../adminTeamMng/ModalTeamNotification';
import { getCurrentUser, isCurrentUserChief, isCurrentUserHumanResources } from '../../infra/helpers/Helpers';

class WeddingPage extends Component {
  state = {
    pageSize: 15,
    currentPage: 1,
    loading: true,
    currentUser: null,
    currentEmployee: null,
    wedding: null,
    ourDay: null,
    scheduler: null,
    teamBooking: null,
    teamComplete: null,
    employeesInCharge: [],
    weddingTeam: null,
    payments: null,
    totalRowsBooking: null,
    colors: {
      colorSys1: null,
      colorSys2: null
    },
    subcategories: null,

    weddingTeamAction: { addEdit: [], delete: [] },
    employeesInChargeAction: { addEdit: [], delete: [] },
    teamBookingAction: { addEdit: [], delete: [], deleteInCharge: [], editInCharge: [] },

    rowsTeam: [],
    rowsInCharge: [],

    openModal: false,
    openPublishModal: false,
    publishModalValues: { publish: true },
    openNotificationModal: false,
    notificationValues: { team: [], allTeamNotified: false }
  };

  componentDidMount = async () => {
    const localUser = getCurrentUser();
    this.setState({ currentUser: localUser });
    this.getEmployee(localUser);
    this.getLocalColors();
  }

  getLocalColors = async () => {
    const resColorSys1 = await GetColorSystemByType(4);
    const colorSys1 = resColorSys1.success && resColorSys1.data ? resColorSys1.data.color : '#f7b5d6';
    const resColorSys2 = await GetColorSystemByType(5);
    const colorSys2 = resColorSys2.success && resColorSys2.data ? resColorSys2.data.color : '#b6d7a9';
    this.setState({ colors: { colorSys1, colorSys2 } });
  }

  getEmployee = async (user) => {
    let resEmployee;
    if (!user?.admin) {
      resEmployee = await GetEmployeeByUser(user._id);
    }

    this.setState({
      currentEmployee: !user?.admin ? resEmployee.data : null,
    }, () => this.getWedding());
  }

  getWedding = async () => {
    const { history } = this.props;
    const { currentEmployee } = this.state;

    const weddingId = this.props.match.params.id;
    const result = await GetWeddingInfo(weddingId);
    // console.warn('Result', result);

    const resultSub = await GetSubcategoriesInCharge();
    const subcategories = resultSub.data;
    const team = getWeddingTeamAndEmployeesInCharge({ data: result.data.weddingTeam }, subcategories);

    if (!isCurrentUserHumanResources() && isCurrentUserChief() && !team?.employeesInCharge?.chief?.find(f => f?.employee?.id === currentEmployee?.id)) {
      return history.push('/404');
    }

    this.setState({
      wedding: result.data.wedding,
      ourDay: result.data.ourDay,
      scheduler: getScheduler(result.data.ourDay),
      teamBooking: result.data.teamBooking,
      teamComplete: result.data.weddingTeam,
      weddingTeam: team.weddingTeam,
      employeesInCharge: team.employeesInCharge,
      payments: result.data.payments,
      subcategories,
      loading: false
    });
  }

  save = async (toExit, notify) => {
    let { currentUser, wedding, teamComplete } = this.state;
    let { weddingTeamAction, employeesInChargeAction, teamBookingAction } = this.state;
    let { rowsTeam, rowsInCharge } = this.state;
    const { history } = this.props;

    // console.warn('teamBookingAction', teamBookingAction);
    // console.warn('employeesInChargeAction', employeesInChargeAction);
    // console.warn('weddingTeamAction', weddingTeamAction);
    // console.warn('rowsTeam', rowsTeam);
    // console.warn('rowsInCharge', rowsInCharge);

    rowsInCharge = rowsInCharge.filter(f => !(f.extraToBooking && (f.weddingTeam.employee === '' ||
      f.weddingTeam.employee === null || f.weddingTeam.employee === undefined ||
      f.weddingTeam.startTime === null)));
    let rowsAllTeam = [...rowsTeam, ...rowsInCharge].map(m => m.weddingTeam);

    //Validate selected employees in charge to employees selected on wedding team
    let errorEmployeesChargeAllRows = false, errorEmployeesChargeMessageAllRows = '';
    rowsInCharge.forEach(row => {
      const rowsTeamBySub = rowsAllTeam.filter(f => f.employee && row.weddingTeam.employee && f.employee.id === row.weddingTeam.employee.id);

      if (row.weddingTeam.subcategory && (row.weddingTeam.subcategory.repeat && rowsTeamBySub.length > 2 || !row.weddingTeam.subcategory.repeat && rowsTeamBySub.length > 1)) {
        errorEmployeesChargeAllRows = true;
        errorEmployeesChargeMessageAllRows = `O ${row.weddingTeam.subcategory.name} ${row.weddingTeam.employee.name} encontra-se selecionado na equipa!`;
      }
    });

    if (errorEmployeesChargeAllRows) {
      return Alert.new({
        type: "error",
        title: "Erro!",
        text: errorEmployeesChargeMessageAllRows
      });
    }

    //Team Booking In Charge of the wedding
    if (teamBookingAction.deleteInCharge.length > 0) {
      teamBookingAction.deleteInCharge.forEach(booking => {
        const index = teamBookingAction.delete.findIndex(f => f.id === booking.id);
        if (index === -1) teamBookingAction.delete.push(booking);
        else teamBookingAction.delete[index] = booking;

        const rowAllTeam = rowsAllTeam.find(f => f.subcategory.id === booking.subcategory.id);
        if (rowAllTeam) rowsAllTeam = rowsAllTeam.filter(f => f.id !== rowAllTeam.id);
      });
    }

    if (teamBookingAction.editInCharge.length > 0) {
      teamBookingAction.editInCharge.forEach(booking => {
        const index = teamBookingAction.addEdit.findIndex(f => f.id === booking.id);
        if (index === -1) teamBookingAction.addEdit.push(booking);
        else teamBookingAction.addEdit[index] = booking;
      });
    }

    //Employees In Charge of the wedding
    let errorEmployeesCharge = false, errorEmployeesChargeMessage = '';
    if (employeesInChargeAction.addEdit.length > 0) {
      employeesInChargeAction.addEdit.forEach(weddingTeam => {
        if (weddingTeam.employee === '' || weddingTeam.employee === null || weddingTeam.employee === undefined ||
          weddingTeam.startTime === null) {
          errorEmployeesCharge = true;
          errorEmployeesChargeMessage = 'Faltam dados nos funcionários responsáveis!';
        }

        // const editTeamBySub = editWeddingTeam.filter(f => f.employee && weddingTeam.employee && f.employee.id === weddingTeam.employee.id);
        const rowsTeamBySub = rowsAllTeam.filter(f => f.employee && weddingTeam.employee && f.employee.id === weddingTeam.employee.id);
        if (weddingTeam.subcategory && (weddingTeam.subcategory.repeat && rowsTeamBySub.length > 2 || !weddingTeam.subcategory.repeat && rowsTeamBySub.length > 1)) {
          errorEmployeesCharge = true;
          errorEmployeesChargeMessage = `O ${weddingTeam.subcategory.name} ${weddingTeam.employee.name} encontra-se selecionado na equipa!`;
        }
      });

      if (errorEmployeesCharge) {
        return Alert.new({
          type: "error",
          title: "Erro!",
          text: errorEmployeesChargeMessage
        });
      }


      employeesInChargeAction.addEdit.forEach(weddingTeam => {
        const index = weddingTeamAction.addEdit.findIndex(f => f.id === weddingTeam.id);
        if (index === -1) weddingTeamAction.addEdit.push(weddingTeam);
        else weddingTeamAction.addEdit[index] = weddingTeam;
      });

      // for (let index = 0; index < employeesInChargeAction.addEdit.length; index++) {
      //   const weddingTeam = employeesInChargeAction.addEdit[index];
      //   //if (teamComplete && teamComplete.length > 0 ) weddingTeam.published = teamComplete[0].published;
      //   weddingTeam.published = weddingTeam && weddingTeam.length > 0 && weddingTeam.filter(t => t.published).length > 0 ? true : false;

      //   if (weddingTeam.employee === '' && weddingTeam.employee === null && weddingTeam.employee === undefined) {
      //     Alert.new({
      //       type: "error",
      //       title: "Erro!",
      //       text: 'Selecione um funcionário.'
      //     });
      //     return;
      //   }

      //   if (weddingTeam.startTime === null) {
      //     Alert.new({
      //       type: "error",
      //       title: "Erro!",
      //       text: 'Indique o horário de entrada.'
      //     });
      //     return;
      //   }

      //   // if (weddingTeam.presence && weddingTeam.endTime === null) {
      //   //   Alert.new({
      //   //     type: "error",
      //   //     title: "Erro!",
      //   //     text: 'Indique um horário de saída'
      //   //   });
      //   //   return;
      //   // }

      //   // const startTime = moment(moment(weddingTeam.startTime).format('HH:mm'), 'HH:mm');
      //   // const endTime = moment(moment(weddingTeam.endTime).format('HH:mm'), 'HH:mm');
      //   // if (weddingTeam.endTime && endTime.isSameOrBefore(startTime)) {
      //   //   Alert.new({
      //   //     type: "error",
      //   //     title: "Erro!",
      //   //     text: 'Indique um horário de saída após o horário de entrada'
      //   //   });
      //   //   return;
      //   // }

      //   const result = weddingTeam.id.includes('new#FFSDD') ? await CreateWeddingTeam(weddingTeam) : await UpdateWeddingTeam(weddingTeam);
      //   if (!result.success) console.error(result.message);
      // }
    }

    if (employeesInChargeAction.delete.length > 0) {
      employeesInChargeAction.delete.forEach(weddingTeam => {
        const index = weddingTeamAction.delete.findIndex(f => f.id === weddingTeam.id);
        if (index === -1) weddingTeamAction.delete.push(weddingTeam);
        else weddingTeamAction.delete[index] = weddingTeam;
      });
    }

    //Wedding Team
    let errorWeddingTeam = false;
    if (weddingTeamAction.addEdit.length > 0) {
      weddingTeamAction.addEdit.forEach(weddingTeam => {
        if (weddingTeam.employee === '' || weddingTeam.employee === null || weddingTeam.employee === undefined ||
          weddingTeam.startTime === null) {
          errorWeddingTeam = true;
        }
      });

      if (errorWeddingTeam) {
        return Alert.new({
          type: "error",
          title: "Erro!",
          text: 'Faltam dados na equipa do casamento!'
        });
      }
    }

    const data = {
      currentUser: currentUser,
      wedding: wedding,
      teamBookingAction: { addEdit: teamBookingAction.addEdit, delete: teamBookingAction.delete },
      weddingTeamAction: weddingTeamAction,
      allRowsTeam: rowsAllTeam
    };

    const result = await SaveTeamInfo(data);
    //console.warn('Result', result);

    if (toExit) {
      if (result.success) {
        this.setState({
          weddingTeamAction: { addEdit: [], delete: [] },
          employeesInChargeAction: { addEdit: [], delete: [] },
          teamBookingAction: { addEdit: [], delete: [], deleteInCharge: [], editInCharge: [] }
        }, () => {
          Alert.new({
            type: "success",
            title: "Sucesso!",
            text: 'Alterações gravadas com sucesso!'
          });
          // history.goBack();
          history.push('/employee-weddings');
        });
      } else {
        Alert.new({
          type: "error",
          title: "Erro!",
          text: 'Erro ao gravar!'
        });
      }

    } else if (!toExit && notify) {
      this.setNotificationTeam();
    } else {
      this.setState({
        weddingTeamAction: { addEdit: [], delete: [] },
        employeesInChargeAction: { addEdit: [], delete: [] },
        teamBookingAction: { addEdit: [], delete: [], deleteInCharge: [], editInCharge: [] }
      }, () => {
        this.showPublishModal();
        //this.publishTeam();
      });
    }
  }

  /**
   * @description Publish wedding team
   */
  publishTeam = async (remove: boolean) => {
    let { wedding, teamComplete, teamBooking } = this.state;
    const { history } = this.props;

    const resTeam = await GetWeddingTeamByWedding(wedding.id);
    teamComplete = resTeam.success && resTeam.data.length > 0 ? resTeam.data : [];

    if (teamComplete && teamComplete.length > 0) {
      if (remove) {
        for (let index = 0; index < teamComplete.length; index++) {
          teamComplete[index].published = false;
          const result = await UpdateWeddingTeam(teamComplete[index]);
          if (!result.success) console.error(result.message);
        }
        alert('FDone');
      } else {
        const result = await PublishWeddingTeam(wedding);
        if (!result.success) console.error(result.message);
        else {
          Alert.new({
            type: "success",
            title: "Sucesso!",
            text: 'Equipa do casamento publicada!'
          });
          // history.goBack();
          history.push('/employee-weddings');
        }
      }

    }
  }

  /**
   * @description Notification Team List
   */
  setNotificationTeam = async () => {
    let { wedding } = this.state;

    const resTeam = await GetWeddingTeamByWedding(wedding.id);
    let teamCompleteBD = resTeam.success && resTeam.data.length > 0 ? resTeam.data : [];
    let teamComplete = resTeam.success && resTeam.data.length > 0 ? resTeam.data : [];
    if (teamComplete.length > 0) teamComplete = teamComplete.filter(f => !f.confirmed);
    // this.setState({ teamComplete });

    const teamList = [];
    for (let index = 0; index < teamComplete.length; index++) {
      const indexList = teamList.findIndex(f => f.employee.id === teamComplete[index].employee.id);
      if (indexList === -1) {
        teamList.push({
          employee: teamComplete[index].employee,
          weddingTeam: [teamComplete[index]],
          notify: false
        });
      } else {
        teamList[indexList].weddingTeam.push(teamComplete[index]);
      }
    }

    this.setState({
      weddingTeamAction: { addEdit: [], delete: [] },
      notificationValues: {
        team: teamList,
        allTeamNotified: teamCompleteBD.length > 0 ? teamCompleteBD.filter(f => f.confirmed).length === teamCompleteBD.length ? true : false : false
      },
      openNotificationModal: true,
    });
  }

  notifyTeam = async (event: any) => {
    const { wedding } = this.state;
    const { history } = this.props;
    const team = event.filter(f => f.notify);

    const result = await NotifyWeddingTeam({ wedding, employees: team });
    if (!result.success) console.error(result.message);
    else {
      Alert.new({
        type: "success",
        title: "Sucesso!",
        text: 'Notificação enviada!'
      });
      // history.goBack();
      history.push('/employee-weddings');
    }
  }

  showPublishModal = async () => {
    let { wedding } = this.state;

    const resBooking = await GetTeamBookingByWedding(wedding.id);
    const teamBooking = resBooking.success && resBooking.data.length > 0 ? resBooking.data : [];

    let totalRows = 0;
    for (let index = 0; index < teamBooking.length; index++) {
      const booking = teamBooking[index];
      const subcategory = booking.subcategory;
      totalRows = totalRows + booking.quantity;
    }

    const resTeam = await GetWeddingTeamByWedding(wedding.id);
    const teamComplete = resTeam.success && resTeam.data.length > 0 ? resTeam.data : [];

    // console.warn('teamBooking', teamBooking);
    // console.warn('teamComplete', teamComplete);
    // console.warn('totalRows', totalRows);

    if (teamComplete.length < totalRows) {
      return Alert.new({
        type: "error",
        title: "Erro!",
        text: 'Falta atribuir funcionários!'
      });
    }

    this.setState({
      publishModalValues: { teamComplete, publish: true },
      openPublishModal: true
    });
  }

  render() {
    const { currentUser, currentEmployee, employeesInCharge, colors, subcategories, payments } = this.state;
    const { employeesInChargeAction, openModal, openNotificationModal, notificationValues, openPublishModal, publishModalValues } = this.state;
    const { ourDay, scheduler } = this.state;
    let { wedding, teamBooking, weddingTeam, totalRowsBooking, teamComplete } = this.state;
    let { weddingTeamAction, teamBookingAction } = this.state;
    const { loading } = this.state;
    const bgColor = wedding?.wedding_place === 1 ? colors.colorSys1 : wedding?.wedding_place === 2 ? colors.colorSys2 : '';

    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle>
            <PageTitle buttons={(currentUser.chief || currentUser.humanResources) ? 2 : 0}>Casamentos</PageTitle>
            <Breadcrumb>
              <Breadcrumb.Item>
                <a href="/weddings">Casamentos</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {teamBooking ? 'Detalhe' : 'Equipa do Casamento'}
              </Breadcrumb.Item>
            </Breadcrumb>
          </HeaderTitle>

          <HeaderButtonsContainer buttons={(currentUser.chief || currentUser.humanResources) ? 2 : 0}>
            {(currentUser.chief || currentUser.humanResources) &&
              <React.Fragment>
                <BaseButton
                  type={'primary'}
                  icon={'save'}
                  text={'Gravar'}
                  onClick={() => {
                    this.setState({ openModal: true });
                    // this.save(true)
                  }}
                  disabled={this.state.payments && this.state.payments.length > 0}
                />
                <BaseButton
                  type={'default'}
                  icon={'close'}
                  text={'Cancelar'}
                  onClick={() => this.props.history.push('/employee-weddings')}
                />
              </React.Fragment>}
          </HeaderButtonsContainer>
        </HeaderContainer>

        <PageContainer buttons={(currentUser.chief || currentUser.humanResources) ? 2 : 0}>
          <div className='wedding-team-header' style={{ marginBottom: '1rem' }}>
            <SectionTitle subsection style={{ width: 'fit-content', marginTop: '0', backgroundColor: bgColor, borderRadius: 5, padding: '5px 10px', color: '#fff' }}>Casamento</SectionTitle>
            <div className='wedding-team-btns '>
              {isCurrentUserHumanResources() && teamComplete.length > 0 && teamComplete.filter(t => t.weddingTeam.published).length > 0 ? <BaseButton
                type={'default'}
                block
                text={'Notificar'}
                onClick={() => {
                  const { weddingTeamAction } = this.state;
                  if (teamBookingAction.addEdit.length > 0 || teamBookingAction.delete.length > 0 || teamBookingAction.deleteInCharge.length > 0 || employeesInChargeAction.addEdit.length > 0 || employeesInChargeAction.delete.length > 0 || weddingTeamAction.addEdit.length > 0 || weddingTeamAction.delete.length > 0) {
                    this.save(false, true);
                  } else this.setNotificationTeam()
                }}
                disabled={teamComplete.length === 0 || payments.length > 0}
              /> : null}

              {currentUser.humanResources && teamComplete.length > 0 && teamComplete.filter(t => t.weddingTeam.published).length > 0 ? (<BaseButton
                type={'primary'}
                block
                text={'Publicada a Equipa'}
                // onClick={() => this.publishTeam(true)}
                disabled={true}
              />) : null}

              {currentUser.humanResources && (teamComplete.length === 0 || teamComplete.length > 0 && teamComplete.filter(t => t.weddingTeam.published).length === 0) ?
                (<BaseButton
                  type={'primary'}
                  block
                  text={'Publicar a Equipa'}
                  onClick={() => {
                    const { weddingTeamAction } = this.state;
                    if (teamBookingAction.addEdit.length > 0 || teamBookingAction.delete.length > 0 || teamBookingAction.deleteInCharge.length > 0 || employeesInChargeAction.addEdit.length > 0 || employeesInChargeAction.delete.length > 0 || weddingTeamAction.addEdit.length > 0 || weddingTeamAction.delete.length > 0) {
                      this.save(false, false);
                    } else this.showPublishModal(); //this.publishTeam();
                  }}
                  disabled={teamComplete.length > 0 && teamComplete.filter(t => t.published).length > 0 /*|| weddingTeam.length === 0 && weddingTeamAction.addEdit.length !== totalRowsBooking*/}
                />) : null}
            </div>
          </div>

          <WeddingInfo
            showHomeHeader={false}
            showTable={false}
            currentEmployee={currentEmployee}
            selectedWedding={{
              wedding: wedding,
              ourDay: ourDay,
              scheduler: scheduler,
              employeesInCharge: JSON.parse(JSON.stringify(employeesInCharge))
            }}
          />

          {/* Employees In Charge */}
          {isCurrentUserHumanResources() ? (<AddEditEmployeesInCharge
            currentUser={currentUser}
            selectedWedding={wedding}
            ourDay={ourDay}
            scheduler={scheduler}
            teamBooking={JSON.parse(JSON.stringify(teamBooking))}
            teamComplete={JSON.parse(JSON.stringify(teamComplete))}
            payments={payments}
            subcategories={subcategories}
            published={teamComplete && teamComplete.length > 0 && teamComplete.filter(t => t.published).length > 0 ? true : false}
            updateTeam={event => {
              // console.warn('$event', event);
              this.setState({ employeesInChargeAction: event });
            }}
            updateRowsBooking={event => {
              let { totalRowsBooking } = this.state;
              totalRowsBooking = totalRowsBooking + event;
              this.setState({ totalRowsBooking })
            }}
            updateRowsInCharge={event => {
              this.setState({ rowsInCharge: event });
            }}
            deleteBooking={event => {
              const { teamBookingAction } = this.state;
              teamBookingAction.deleteInCharge = event;
              this.setState({ teamBookingAction });
            }}
            updateBooking={event => {
              const { teamBookingAction } = this.state;
              teamBookingAction.editInCharge = event;
              this.setState({ teamBookingAction });
            }}
          />) : null}

          {/* Human Resources Management */}
          {(currentUser.humanResources || currentUser.chief) && teamBooking ? (<AddEditWeddingTeam
            currentEmployee={currentEmployee}
            selectedWedding={wedding}
            ourDay={ourDay}
            scheduler={scheduler}
            employeesInCharge={employeesInCharge}
            teamBooking={JSON.parse(JSON.stringify(teamBooking))}
            teamComplete={JSON.parse(JSON.stringify(teamComplete))}
            weddingTeam={JSON.parse(JSON.stringify(weddingTeam))}
            payments={payments}
            published={teamComplete && teamComplete.length > 0 && teamComplete.filter(t => t.published).length > 0 ? true : false}
            updateWeddingTeam={event => this.setState({ weddingTeamAction: event })}
            updateRowsBooking={event => {
              let { totalRowsBooking } = this.state;
              totalRowsBooking = totalRowsBooking + event;
              this.setState({ totalRowsBooking })
            }}
            updateRowsTeam={event => {
              this.setState({ rowsTeam: event });
            }}
            deleteBooking={event => {
              const { teamBookingAction } = this.state;
              teamBookingAction.delete = event;
              this.setState({ teamBookingAction });
            }}
            updateBooking={event => {
              const { teamBookingAction } = this.state;
              teamBookingAction.addEdit = event;
              this.setState({ teamBookingAction });
            }}
          />) : null}
        </PageContainer>

        <ModalTeamMng
          isOpen={openModal}
          initialValues={{
            publish: false,
            updatedTeam: employeesInChargeAction.addEdit.length > 0 || employeesInChargeAction.delete.length > 0 || weddingTeamAction.addEdit.length > 0 || weddingTeamAction.delete.length > 0 ? true : false,
          }}
          onSubmit={() => this.setState({ openModal: false }, () => this.save(true, false))}
          onClose={() => this.setState({ openModal: false })}>
        </ModalTeamMng>

        <ModalTeamMng
          isOpen={openPublishModal}
          initialValues={publishModalValues}
          onSubmit={() => this.setState({ openPublishModal: false }, () => {
            this.publishTeam(false);
          })}
          onClose={() => this.setState({ openPublishModal: false })}>
        </ModalTeamMng>

        <ModalTeamNotification
          isOpen={openNotificationModal}
          initialValues={notificationValues}
          onSubmit={event => this.setState({ openNotificationModal: false }, () => this.notifyTeam(event))}
          onClose={() => this.setState({ openNotificationModal: false })}>
        </ModalTeamNotification>
      </React.Fragment>
    );
  }
}

export default WeddingPage;
