import React, { Component } from 'react';
import { Col, Row, TimePicker } from 'antd';
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
} from '../../styles/BasicStyles';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import FormValidator from '../../infra/services/validations/FormValidator';
import { withLocalize } from 'react-localize-redux';
import TextInput from '../../components/inputs/TextInput';
import TagInput from '../../components/inputs/TagInput';
import BaseButton from '../../components/buttons/BaseButton';
import SubcategoryTable from './SubcategoryTable';
import Alert from "../../components/alert/Alert";
import {
  CreateCategory,
  GetCategory,
  UpdateCategory,
} from '../../infra/requests/CategoryRequests';
import moment from 'moment';
import {
  CreateSubcategory,
  DeleteSubcategory,
  UpdateSubcategory,
} from '../../infra/requests/SubcategoryRequests';

const timeFormat = "HH:mm";

const validations = values => {
  let errors = {};
  errors = FormValidator.make({
    name: 'required',
    acronym: 'required|noSpaces',
  })(values);

  return errors;
};

class CategoryPage extends Component {
  state = {
    isNew: false,
    loading: true,
    category: null,
    startTime: null,
    endTime: null,
    addEditSubcategories: [],
    deleteSubcategories: [],
    addEditPrices: [],
    deletePrices: [],
  }

  componentDidMount = async () => {
    //Get category
    const id = this.props.match.params.id;
    const { dispatch } = this.props;

    if (id && id != 0) {
      const result = await GetCategory(id);
      this.setState({
        category: result.data,
        startTime: moment(new Date(result.data.startTime)),
        endTime: moment(new Date(result.data.endTime))
      });
      dispatch(
        initialize('category_form', {
          ...result.data
        })
      );
      this.setState({ isNew: false, loading: false });
    } else
      this.setState({
        isNew: true,
        loading: false
      });
  };

  /**
     * @description Validate times and create/update category
     * @returns category table updated
     */
  onSubmit = async (values) => {
    const { history } = this.props;
    const { isNew, startTime, endTime } = this.state;
    const { addEditSubcategories, deleteSubcategories } = this.state;

     if (!startTime) {
      Alert.new({
        type: "error",
        title: "Erro!",
        text: 'Deve introduzir uma hora de entrada.'
      });
    } else {
      const startMoment = moment(moment(startTime).format(timeFormat), timeFormat);
      // const endMoment = moment(moment(endTime).format(timeFormat), timeFormat);

      const category = {
        acronym: values.acronym,
        id: values.id,
        name: values.name,
        order: values.order,
        startTime: startMoment,
        // endTime: endMoment
      };
      const resultCategory = isNew ? await CreateCategory(category) : await UpdateCategory(category);

      //CRUD Subcategories
      if (addEditSubcategories.length > 0) {
        for (let index = 0; index < addEditSubcategories.length; index++) {
          const subcategory = addEditSubcategories[index];
          if (subcategory?.name?.trim() === '') {
            return Alert.new({
              type: "error",
              title: "Erro!",
              text: 'Deve inserir o nome da subcategoria'
            });
          }
          else {
            subcategory.category = resultCategory.data;
            subcategory.acronym = subcategory.acronym.toUpperCase();
            subcategory.id.includes('id#FFFFF') ? await CreateSubcategory(subcategory) : await UpdateSubcategory(subcategory);
          }
        }
      } else if (deleteSubcategories.length > 0) {
        for (let index = 0; index < deleteSubcategories.length; index++) {
          const element = deleteSubcategories[index];
          await DeleteSubcategory(element.id);
        }
      }


      if (resultCategory.success) return history.push('/settings');
      else return console.error(resultCategory.message);
    }
  };

  /**
   * @description Cancel add/edit category
   * @returns Navigate to settings page
   */
  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/settings/1');
  };

  /**
   * @description Update field addEditSubcategories with data from child component
   * @param {*} values 
   */
  addEditSubcategories = (values) => {
    this.setState({ addEditSubcategories: values });
  }

  /**
 * @description Update field deleteSubcategories with data from child component
 * @param {*} values 
 */
  deleteSubcategories = (values) => {
    this.setState({ deleteSubcategories: values });
  }

  /**
   * @description Update field addEditPrices with data from child component
   * @param {*} values 
   */
  updatePrices = (values) => {
    this.setState({ addEditPrices: values });
  }

  /**
 * @description Update field deletePrices with data from child component
 * @param {*} values 
 */
  deletePrices = (values) => {
    this.setState({ deletePrices: values });
  }

  render() {
    const { handleSubmit } = this.props;
    const { isNew, category, categoryForm } = this.state;
    const title = isNew ? 'Adicionar Categoria' : 'Editar Categoria';

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{title}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit((values) => this.onSubmit(values))}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>

        <FormContainer style={{ maxWidth: 600 }}>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={[16, 16]}>
              <Col lg={12} md={12} xs={24}>
                <Field
                  component={TextInput}
                  name="name"
                  label="Nome"
                  placeholder="Insira o nome da categoria"
                  type="text"
                />
              </Col>

              <Col lg={12} md={12} xs={24}>
                <Field
                  component={TagInput}
                  name="acronym"
                  label="Acrónimo"
                  placeholder="Insira o acrónimo da categoria"
                  type="text"
                />

              </Col>

              <Col lg={12} md={12} xs={24}>
                <div className="sc-dxgOiQ cGTQcX">
                  <div className="sc-hMqMXs liDmfg">Horário de Entrada</div>
                  <TimePicker style={{ width: '100%' }}
                    type="time"
                    format='HH:mm'
                    value={this.state.startTime ? moment(this.state.startTime).local() : null}
                    onChange={(e) => {
                      this.setState({ startTime: e ? e?.utc() : null });
                    }}
                  />
                </div>
              </Col>

              {/* <Col lg={12} md={12} xs={24}>
                <div className="sc-dxgOiQ cGTQcX">
                  <div className="sc-hMqMXs liDmfg">Horário de Saída</div>
                  <TimePicker style={{ width: '100%' }}
                    type="time"
                    format='HH:mm'
                    value={this.state.endTime ? moment(this.state.endTime).local() : null}
                    onChange={(e) => {
                      this.setState({ endTime: e ? e?.utc() : null });
                    }}
                  />
                </div>
              </Col> */}
            </Row>
          </BaseForm>
        </FormContainer>

        <div style={{ width: 'calc(100% - 20px)', maxWidth: '600px', padding: '20px 8px', margin: 'auto' }}>
          <SubcategoryTable
            category={category}
            categoryForm={this.props.categoryForm}
            categoryStartTime={this.state.startTime}
            categoryEndTime={this.state.endTime}
            // addEditSubcategories={values => this.addEditSubcategories(values)}
            deleteSubcategories={values => this.deleteSubcategories(values)}
            updateIsNew={event => this.setState({ isNew: event })}
            updateCategoryForm={event => {
              const { dispatch } = this.props;
              dispatch(
                initialize('category_form', {
                  ...event
                })
              );
            }}
          />
        </div>

      </React.Fragment>
    );
  }

}

const selector = formValueSelector('category_form');

CategoryPage = reduxForm({
  form: 'category_form',
  validate: validations
})(CategoryPage);

const mapStateToProps = state => ({
  categoryForm: {
    name: selector(state, 'name'),
    acronym: selector(state, 'acronym')
  }
});

export default withLocalize(connect(mapStateToProps)(CategoryPage));