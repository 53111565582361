import React from 'react';
import {reduxForm} from 'redux-form';
import {Modal, Button} from 'antd';
import {BaseForm} from '../../styles/BasicStyles';
import {ModalText} from './NotesModalStyles';

let NotesModal = ({
  open,
  loading,
  handleSubmit,
  onSubmit,
  closeModal,
  exitWithoutSaving
}) => {
  return (
    <Modal
      visible={open}
      title='Gravar alterações'
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={exitWithoutSaving}>
          Descartar alterações
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar alterações
        </Button>
      ]}>
      <BaseForm onSubmit={handleSubmit(onSubmit)}>
        <ModalText>Efetuou alterações nas notas que não foram gravadas!</ModalText>
        <ModalText>Pretende gravar antes de sair?</ModalText>
      </BaseForm>
    </Modal>
  );
};

NotesModal = reduxForm({
  form: 'notes_modal_confirm_form'
})(NotesModal);

export default NotesModal;