import React, { Fragment } from 'react';
import { Row, Col, Icon } from 'antd';
import moment from 'moment';
import { SectionTitle } from '../../styles/BasicStyles';
import {
  RegisterBox,
  Description,
  Title,
  Value,
  Date,
  DetailedLine,
  CollapseContainer,
  PanelContainer,
  NoRecords,
  BudgetGuestsNote
} from './BudgetStyles';
import BudgetGraph from '../../process/budget/components/BudgetGraph';
import { numberFormat } from '../../infra/services/calc/numbers';
import DetailBlock from '../../process/budget/components/DetailBlock';
import BaseServiceBlock from '../../process/budget/components/BaseServiceBlock';
import BillingBlock from '../../process/budget/components/BillingBlock';
import { BudgetInfo } from '../../process/budget/BudgetStyles';
import CurrencyComponent from '../currency/CurrencyComponent';
import { inRoomPlanTest } from '../../infra/helpers/Helpers';
import { checkPreviousPhasesCompleted, getMissingValue, getPhaseDate } from '../../process/budget/budgetUtils';
import { CostTypes } from '../../infra/services/options/Options';

const graphData2 = [
  {
    name: 'Serviço Base',
    value: 0,
    color: '#CEC0C0',
    tag: 'BUDGET_ADJUDICATED'
  },
  // {
  //   name: 'Extras Plano de Sala',
  //   value: 0,
  //   color: '#CEC0C0',
  //   tag: 'BUDGET_ROOM_PLAN'
  // },
  {
    name: 'Extras Cerimónia',
    value: 0,
    color: '#CEC9C0',
    tag: 'BUDGET_CEREMONY'
  },
  {
    name: 'Extras Staff',
    value: 0,
    color: '#C3CEC0',
    tag: 'BUDGET_STAFF'
  },
  {
    name: 'Extras Food Selection',
    value: 0,
    color: '#C0C8CE',
    tag: 'BUDGET_FOOD'
  },
  {
    name: 'Extras Drink Selection',
    value: 0,
    color: '#C0C1CE',
    tag: 'BUDGET_DRINK'
  },
  {
    name: 'Extras de Decoração',
    value: 0,
    color: '#CCC0CE',
    tag: 'BUDGET_DECORATION'
  },
  {
    name: 'Extras Party Selection',
    value: 0,
    color: '#CEC0C6',
    tag: 'BUDGET_PARTY'
  },
  {
    name: 'Upgrade',
    value: 0,
    color: '#DEB3B3',
    tag: 'BUDGET_UPGRADE'
  },
  {
    name: 'Valores a adicionar (+)',
    value: 0,
    color: 'rgba(222, 179, 179, 0.47)',
    tag: 'BUDGET_EXPENSES'
  }
];
if (inRoomPlanTest()) {
  graphData2.splice(1, 0, {
    name: 'Extras Plano de Sala',
    value: 0,
    color: '#CEC0C0',
    tag: 'BUDGET_ROOM_PLAN'
  });
}

const budgetExpenses = {
  name: 'Valores a adicionar (+)',
  value: 0,
  tag: 'BUDGET_EXPENSES',
  index: 0,
};

const budgetOffers = {
  name: 'Ofertas (-)',
  value: 0,
  tag: 'BUDGET_OFFERS',
  index: 2,
};

const budgetDiscounts = {
  name: 'Valores a descontar (-)',
  value: 0,
  tag: 'BUDGET_DISCOUNTS',
  index: 1,
};

const calculateSections = (data = {}) => {
  let total = 0;
  const graph2 = graphData2.map(section => {
    if (section.name === 'Serviço Base')
      section.value = data?.costs?.base || 0;
    if (section.name === 'Extras Plano de Sala')
      section.value = data?.costs?.tables || 0;
    if (section.name === 'Extras Cerimónia')
      section.value = data?.costs?.ceremony || 0;
    if (section.name === 'Extras Staff')
      section.value = data?.costs?.staff || 0;
    if (section.name === 'Extras Food Selection')
      section.value = data?.costs?.food || 0;
    if (section.name === 'Extras Drink Selection')
      section.value = data?.costs?.drinks || 0;
    if (section.name === 'Extras de Decoração')
      section.value = data?.costs?.decoration || 0;
    if (section.name === 'Extras Party Selection')
      section.value = data?.costs?.party || 0;
    if (section.name === 'Upgrade') section.value = data?.costs?.upgrade || 0;
    if (section.name === 'Valores a adicionar (+)') section.value = data?.costs?.expense || 0;
    total = total + section.value;
    return section;
  });

  //Expenses
  const expenses = data?.costs?.expense || 0;
  budgetExpenses.value = expenses;

  //Offers
  const offers = data?.costs?.offer || 0;
  budgetOffers.value = offers;

  //Discounts
  const discounts = data?.costs?.discount || 0;
  budgetDiscounts.value = discounts;

  total = total - offers - discounts;

  let payed = 0;
  if (data.budget) {
    data.budget.forEach(line => {
      if (line.type === 'PAYMENT') payed = payed + line.value;
    });
  }
  const missing = total - payed;
  const graph1 = [
    {
      name: 'Pago',
      value: payed,
      color: '#DECBB3'
    },
    {
      name: 'Falta',
      value: missing,
      color: '#ECE8E2'
    }
  ];

  return {
    total,
    graph1,
    graph2,
    budget: data.budget || []
  };
};

const RenderCollapseHeader = section => {
  return (
    <DetailedLine>
      <Row>
        <Col xs={12}>{section.name}</Col>
        <Col xs={12}>
          {section.value > 0 ? `${numberFormat(section.value)}€` : ''}
        </Col>
      </Row>
    </DetailedLine>
  );
};

const BudgetDetails = ({ weddingInfo, budgetInfo, upgrade, venueConfig, questionsPaymentMethods, paymentPhases, localColor }) => {
  const { total, graph1, graph2, budget } = calculateSections(budgetInfo);

  const RenderPriceDetail = (type, price, quantity, cost) => {
    const { pax, guests, children, tables } = budgetInfo;

    if (type === CostTypes.QUANTITY) {
      return `${numberFormat(cost !== undefined ? cost : price)}€/un x ${quantity} unidades`;
    } else if (type === CostTypes.GUEST) {
      return `${numberFormat(cost !== undefined ? cost : price)}€/pax x ${pax} pax`;
    } else if (type === CostTypes.PEOPLE) {
      return `${numberFormat(cost !== undefined ? cost : price)}€/pessoa x ${guests + children} pessoas`;
    } else if (type === CostTypes.TABLE) {
      return `${numberFormat(cost !== undefined ? cost : price)}€/mesa x ${tables} mesas`;
    }
    return 'Valor';
  };

  const RenderPrice = (type, price, quantity, cost) => {
    const { pax, guests, children, tables } = budgetInfo;
    if (cost !== undefined) cost = parseFloat(cost);

    if (type === CostTypes.QUANTITY) {
      return `${numberFormat(quantity * (cost !== undefined ? cost : price))}€`;
    } else if (type === CostTypes.GUEST) {
      return `${numberFormat(pax * (cost !== undefined ? cost : price))}€`;
    } else if (type === CostTypes.PEOPLE) {
      return `${numberFormat((guests + children) * (cost !== undefined ? cost : price))}€`;
    } else if (type === CostTypes.TABLE) {
      return `${numberFormat(tables * (cost !== undefined ? cost : price))}€`;
    }
    return `${numberFormat(cost !== undefined ? cost : price)}€`;
  };

  const RenderDetailedExpenses = tag => {
    const {
      extras,
      min_people,
      guests,
      children,
      staff,
      pax_show,
      places,
      price,
      budget,
      music
    } = budgetInfo;

    switch (tag) {
      case 'BUDGET_ADJUDICATED':
        return (
          <React.Fragment>
            <BudgetGuestsNote>
              Quanto ao orçamento base, ter em consideração que, é considerado o número mínimo de convidados para a vossa data, sempre que número de convidados previsto ou definitivo seja inferior.
            </BudgetGuestsNote>
            <BaseServiceBlock
              min_people={min_people}
              price={`${numberFormat(price)}€`}
              pax={pax_show}
              seats={places}
            />
            {guests > 0 && (
              <DetailBlock
                title="Convidados com + de 8 anos"
                subtitle={`${guests} x ${numberFormat(price)}€`}
                subtitleValue={`${numberFormat(guests * price)}€`}
              />
            )}
            {children > 0 && (
              <DetailBlock
                title="Crianças (3 a 7 anos)"
                subtitle={`${children} x ${numberFormat(
                  price / 2,
                  2,
                  ',',
                  '.'
                )}€`}
                subtitleValue={`${numberFormat(
                  children * (price / 2),
                  2,
                  ',',
                  '.'
                )}€`}
              />
            )}
            {staff > 0 && (
              <DetailBlock
                title="Staff dos noivos"
                subtitle={`${staff} x ${numberFormat(price / 2)}€`}
                subtitleValue={`${numberFormat(
                  staff * (price / 2),
                  2,
                  ',',
                  '.'
                )}€`}
              />
            )}
            {music > 0 && (
              <DetailBlock
                title="Licença de Música (mecânica)"
                subtitle={`${numberFormat(music)}€`}
              />
            )}
          </React.Fragment>
        );
      case 'BUDGET_ROOM_PLAN':
        return inRoomPlanTest() && extras?.tables?.qtd > 0 && extras?.tables?.cost > 0
          ? <DetailBlock
            title="Mesas extra (mesas com quantidade de lugares abaixo do número mínimo)"
            subtitle={`${extras?.tables?.qtd} x ${numberFormat(extras?.tables?.cost || 0)}€`}
            subtitleValue={`${numberFormat(extras?.tables?.qtd * (extras?.tables?.cost || 0))}€`}
          />
          : <NoRecords>Não existem registos a apresentar.</NoRecords>;
      case 'BUDGET_CEREMONY':
        if (extras && extras.ceremony?.length > 0) {
          return extras.ceremony.map((expense, index) => (
            <DetailBlock
              key={`ceremony_${index}`}
              title={expense.title.pt}
              subtitle={
                expense.cost && expense.cost_price > 0
                  ? RenderPriceDetail(
                    expense.cost_type,
                    expense.cost_price,
                    expense.selected_quantity,
                    expense.selected_cost,
                  )
                  : null
              }
              subtitleValue={
                expense.cost && expense.cost_price > 0
                  ? RenderPrice(
                    expense.cost_type,
                    expense.cost_price,
                    expense.selected_quantity,
                    expense.selected_cost
                  )
                  : null
              }
              distinguish={expense.distinguishBudget}
              color={localColor}
            />
          ));
        }
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
      case 'BUDGET_STAFF':
        if (extras && extras.staff?.length > 0) {
          return extras.staff.map((expense, index) => (
            <DetailBlock
              key={`staff_${index}`}
              title={expense.title.pt}
              subtitle={
                expense.cost && expense.cost_price > 0
                  ? RenderPriceDetail(
                    expense.cost_type,
                    expense.cost_price,
                    expense.selected_quantity,
                    expense.selected_cost
                  )
                  : null
              }
              subtitleValue={
                expense.cost && expense.cost_price > 0
                  ? RenderPrice(
                    expense.cost_type,
                    expense.cost_price,
                    expense.selected_quantity,
                    expense.selected_cost
                  )
                  : null
              }
              distinguish={expense.distinguishBudget}
              color={localColor}
            />
          ));
        }
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
      case 'BUDGET_FOOD':
        if (extras && extras.food?.length > 0) {
          return extras.food.map((expense, index) => (
            <DetailBlock
              key={`food_${index}`}
              title={expense.title ? expense.title.pt : expense.name ? expense.name.pt : null}
              subtitle={
                // ((expense.title && expense.cost && expense.cost_price > 0) || (expense.name && expense.extra_cost > 0))
                (expense.title || expense.name)
                  ? RenderPriceDetail(
                    expense.name ? CostTypes.GUEST : expense.cost_type,
                    expense.name ? expense.extra_cost : expense.cost_price,
                    expense.selected_quantity,
                    expense.selected_cost
                  )
                  : null
              }
              subtitleValue={
                // ((expense.title && expense.cost && expense.cost_price > 0) || (expense.name && expense.extra_cost > 0))
                (expense.title || expense.name)
                  ? RenderPrice(
                    expense.name ? CostTypes.GUEST : expense.cost_type,
                    expense.name ? expense.extra_cost : expense.cost_price,
                    expense.selected_quantity,
                    expense.selected_cost
                  )
                  : null
              }
              distinguish={expense.distinguishBudget}
              color={localColor}
            />
          ));
        }
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
      case 'BUDGET_DRINK':
        if (extras && extras.drinks?.length > 0) {
          return extras.drinks.map((expense, index) => (
            <DetailBlock
              key={`drink_${index}`}
              title={expense.title.pt}
              subtitle={
                expense.cost && expense.cost_price > 0
                  ? RenderPriceDetail(
                    expense.cost_type,
                    expense.cost_price,
                    expense.selected_quantity,
                    expense.selected_cost,
                  )
                  : null
              }
              subtitleValue={
                expense.cost && expense.cost_price > 0
                  ? RenderPrice(
                    expense.cost_type,
                    expense.cost_price,
                    expense.selected_quantity,
                    expense.selected_cost,
                  )
                  : null
              }
              distinguish={expense.distinguishBudget}
              color={localColor}
            />
          ));
        }
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
      case 'BUDGET_DECORATION':
        if (extras && extras.decoration?.length > 0) {
          return extras.decoration.map((expense, index) => (
            <DetailBlock
              key={`decoration_${index}`}
              title={expense.title.pt}
              subtitle={
                expense.cost && expense.cost_price > 0
                  ? RenderPriceDetail(
                    expense.cost_type,
                    expense.cost_price,
                    expense.selected_quantity,
                    expense.selected_cost
                  )
                  : null
              }
              subtitleValue={
                expense.cost && expense.cost_price > 0
                  ? RenderPrice(
                    expense.cost_type,
                    expense.cost_price,
                    expense.selected_quantity,
                    expense.selected_cost
                  )
                  : null
              }
              distinguish={expense.distinguishBudget}
              color={localColor}
            />
          ));
        }
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
      case 'BUDGET_PARTY':
        if (extras && extras.party?.length > 0) {
          return extras.party.map((expense, index) => (
            <DetailBlock
              key={`party_${index}`}
              title={expense.title.pt}
              subtitle={
                expense.cost && expense.cost_price > 0
                  ? RenderPriceDetail(
                    expense.cost_type,
                    expense.cost_price,
                    expense.selected_quantity,
                    expense.selected_cost
                  )
                  : null
              }
              subtitleValue={
                expense.cost && expense.cost_price > 0
                  ? RenderPrice(
                    expense.cost_type,
                    expense.cost_price,
                    expense.selected_quantity,
                    expense.selected_cost
                  )
                  : null
              }
              distinguish={expense.distinguishBudget}
              color={localColor}
            />
          ));
        }
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
      case 'BUDGET_UPGRADE':
        if (upgrade) {
          return (
            <DetailBlock
              title={upgrade.name.pt}
              subtitle={
                upgrade.price > 0
                  ? RenderPriceDetail(CostTypes.GUEST, upgrade.price, 0, undefined)
                  : null
              }
              subtitleValue={
                upgrade.price > 0
                  ? RenderPrice(CostTypes.GUEST, upgrade.price, 0, undefined)
                  : null
              }
            />
          );
        }
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
      case 'BUDGET_EXPENSES':  // 'BUDGET_OTHERS':
        const expenses = budget.filter(b => b.type === 'EXPENSE');

        if (expenses.length > 0) {
          return expenses.map((expense, index) => (
            <DetailBlock
              key={`others_${index}`}
              title={expense.title}
              subtitle={
                expense.value > 0
                  ? RenderPriceDetail(CostTypes.GLOBAL, expense.value, 0, undefined)
                  : null
              }
              subtitleValue={
                expense.value > 0
                  ? RenderPrice(CostTypes.GLOBAL, expense.value, 0, undefined)
                  : null
              }
            />
          ));
        }
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
      case 'BUDGET_OFFERS':
        const offers = budget.filter(b => b?.type === 'OFFER');

        if (offers.length > 0) {
          return offers.map((offer, index) => (
            <DetailBlock
              key={`offers_${index}`}
              title={offer.title}
              subtitle={
                offer.value > 0
                  ? RenderPriceDetail(CostTypes.GLOBAL, offer.value, 0, undefined)
                  : null
              }
              subtitleValue={
                offer.value > 0
                  ? RenderPrice(CostTypes.GLOBAL, offer.value, 0, undefined)
                  : null
              }
            />
          ));
        }
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
      case 'BUDGET_DISCOUNTS':
        const discounts = budget.filter(b => b?.type === 'DISCOUNT');

        if (discounts.length > 0) {
          return discounts.map((discount, index) => (
            <DetailBlock
              key={`discounts_${index}`}
              title={discount.title}
              subtitle={
                discount.value > 0
                  ? RenderPriceDetail(CostTypes.GLOBAL, discount.value, 0, undefined)
                  : null
              }
              subtitleValue={
                discount.value > 0
                  ? RenderPrice(CostTypes.GLOBAL, discount.value, 0, undefined)
                  : null
              }
            />
          ));
        }
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
      default:
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
    }
  };

  const checkIfItsOpen = (tag) => {
    const {
      extras,
      min_people,
      guests,
      children,
      staff,
      pax_show,
      places,
      price,
      budget,
      music
    } = budgetInfo;

    switch (tag) {
      case 'BUDGET_ADJUDICATED':
        return true;
      case 'BUDGET_ROOM_PLAN':
        return inRoomPlanTest() && extras?.tables?.qtd > 0 && extras?.tables?.cost > 0 ? true : false;
      case 'BUDGET_CEREMONY':
        return extras?.ceremony?.length > 0 ? true : false;
      case 'BUDGET_STAFF':
        return extras?.staff?.length > 0 ? true : false;
      case 'BUDGET_FOOD':
        return extras?.food?.length > 0 ? true : false;
      case 'BUDGET_DRINK':
        return extras?.drinks?.length > 0 ? true : false;
      case 'BUDGET_DECORATION':
        return extras.decoration?.length > 0 ? true : false;
      case 'BUDGET_PARTY':
        return extras?.party?.length > 0 ? true : false;
      case 'BUDGET_UPGRADE':
        return upgrade ? true : false;
      case 'BUDGET_EXPENSES':  // 'BUDGET_OTHERS':
        const expenses = budget.filter(b => b.type === 'EXPENSE');
        return expenses?.length > 0 ? true : false;
      case 'BUDGET_OFFERS':
        const offers = budget.filter(b => b?.type === 'OFFER');
        return offers?.length > 0 ? true : false;
      case 'BUDGET_DISCOUNTS':
        const discounts = budget.filter(b => b?.type === 'DISCOUNT');
        return discounts?.length > 0 ? true : false;
      default:
        return false;
    }
  }

  const getOpenPanels = () => {
    const openPanels = [];

    let stats = [...graph2];
    stats = stats.filter(f => f?.tag !== 'BUDGET_EXPENSES');
    for (let index = 0; index < stats.length; index++) {
      const section = stats[index];
      const toOpen = checkIfItsOpen(section?.tag);
      if (toOpen) openPanels.push(index?.toString())
    }

    if (budgetExpenses?.value > 0) {
      const toOpen = checkIfItsOpen(budgetExpenses?.tag);
      if (toOpen) {
        let index = stats?.length + budgetExpenses.index;
        openPanels.push(index?.toString());
      }
    }

    if (budgetDiscounts?.value > 0) {
      const toOpen = checkIfItsOpen(budgetDiscounts?.tag);
      if (toOpen) {
        let index = stats?.length + budgetDiscounts.index;
        openPanels.push(index?.toString());
      }
    }

    if (budgetOffers?.value > 0) {
      const toOpen = checkIfItsOpen(budgetOffers?.tag);
      if (toOpen) {
        let index = stats?.length + budgetOffers.index;
        openPanels.push(index?.toString());
      }
    }

    return openPanels;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <SectionTitle>Valor Total: {numberFormat(total)}€</SectionTitle>
        </Col>
        <Col xs={12}>
          <BudgetGraph data={graph1} />
          <BudgetInfo>
            <ul>
              {weddingInfo && weddingInfo?.updatedBudget &&
                <li className='updated'>
                  <b>Atualizado em:</b>
                  <span>{moment.utc(weddingInfo?.updatedBudget).format('DD/MM/YYYY HH:mm')}</span>
                </li>}
              {venueConfig && venueConfig?.iban &&
                <li className='iban'>
                  <b>IBAN:</b>
                  <span>{venueConfig?.iban} {venueConfig?.swift || ''}</span>
                </li>}
              {paymentPhases && Array.isArray(paymentPhases) && paymentPhases.length > 0
                && <React.Fragment>
                  {paymentPhases.map((p, index) => {
                    return <li key={'phase' + index} className='payment-phase'>
                      <div className='title'>
                        <b>{p?.phase?.pt || ''} {p?.order || ''}: </b>
                        <span>{getPhaseDate(p)}</span>
                      </div>
                      {p?.order > 2 && p?.paymentQuestion && <div className='subtitle'>
                        {p?.paymentQuestion?.option?.title && <span>
                          <Icon type='question-circle' />
                          {p?.paymentQuestion?.option?.title?.pt || ''}
                        </span>
                        }
                      </div>}
                      <div className='price'>
                        {p?.order == 3 && <span className='preview'>
                          <b>Previsto de:</b>
                          <CurrencyComponent value={p?.toPay || 0} />
                          <b>a</b>
                          <CurrencyComponent value={p?.totalMissing || 0} />
                        </span>}
                        {p?.order == 4 && <span className='preview'>
                          <b>Previsto:</b>
                          <CurrencyComponent value={p?.toPay || 0} />
                        </span>}
                        <span><b>Pago:</b><CurrencyComponent value={p?.payed || 0} /></span>
                        {p?.order == 3 && checkPreviousPhasesCompleted(p, paymentPhases) && <span style={{ color: getMissingValue(p) > 0 ? 'red' : 'inherit' }}>
                          <b>Falta:</b>
                          <CurrencyComponent value={getMissingValue(p)} />
                        </span>}
                      </div>
                    </li>
                  })}
                </React.Fragment>}
            </ul>
          </BudgetInfo>
        </Col>
        <Col xs={12}>
          <BudgetGraph data={graph2} />
        </Col>
      </Row>

      <Row gutter={[24]}>
        <Col xs={12}>
          <SectionTitle subsection>Pagamentos Efectuados</SectionTitle>
          {budget
            .filter(b => b.type === 'PAYMENT')
            .map(register => (
              <RegisterBox key={register._id}>
                <Title>{register.title}</Title>
                <Description>{register.description}</Description>
                <Value>-{numberFormat(register.value)}€</Value>
                <Date>{moment.utc(register.date).format('DD/MM/YYYY')}</Date>
              </RegisterBox>
            ))}
          <SectionTitle subsection>Valores a descontar (-)</SectionTitle>
          {budget
            .filter(b => b?.type === 'DISCOUNT')
            .map(register => (
              <RegisterBox key={register._id}>
                <Title>{register.title}</Title>
                <Description>{register.description}</Description>
                <Value>+{numberFormat(register.value)}€</Value>
                <Date>{moment.utc(register.date).format('DD/MM/YYYY')}</Date>
              </RegisterBox>
            ))}
          <SectionTitle subsection>Valores a adicionar (+)</SectionTitle>
          {budget
            .filter(b => b.type === 'EXPENSE')
            .map(register => (
              <RegisterBox key={register._id}>
                <Title>{register.title}</Title>
                <Description>{register.description}</Description>
                <Value>+{numberFormat(register.value)}€</Value>
                <Date>{moment.utc(register.date).format('DD/MM/YYYY')}</Date>
              </RegisterBox>
            ))}
          <SectionTitle subsection>Ofertas (-)</SectionTitle>
          {budget
            .filter(b => b?.type === 'OFFER')
            .map(register => (
              <RegisterBox key={register._id}>
                <Title>{register.title}</Title>
                <Description>{register.description}</Description>
                <Value>+{numberFormat(register.value)}€</Value>
                <Date>{moment.utc(register.date).format('DD/MM/YYYY')}</Date>
              </RegisterBox>
            ))}
        </Col>
        <Col xs={12}>
          <SectionTitle subsection>Resumo de Orçamento</SectionTitle>
          <CollapseContainer expandIconPosition="right" defaultActiveKey={getOpenPanels()}>
            {graph2
              .filter(f => f?.tag !== 'BUDGET_EXPENSES')
              .map((section, index) => (
                <PanelContainer
                  header={RenderCollapseHeader(section)}
                  key={index}
                >
                  {RenderDetailedExpenses(section.tag)}
                </PanelContainer>
              ))}
            {budgetExpenses?.value > 0 && <PanelContainer
              header={RenderCollapseHeader(budgetExpenses)}
              key={graph2?.filter(f => f?.tag !== 'BUDGET_EXPENSES')?.length + budgetExpenses.index}>
              {RenderDetailedExpenses(budgetExpenses.tag)}
            </PanelContainer>}
            {budgetDiscounts?.value > 0 && <PanelContainer
              header={RenderCollapseHeader(budgetDiscounts)}
              key={graph2?.filter(f => f?.tag !== 'BUDGET_EXPENSES')?.length + budgetDiscounts.index}>
              {RenderDetailedExpenses(budgetDiscounts.tag)}
            </PanelContainer>}
            {budgetOffers?.value > 0 && <PanelContainer
              header={RenderCollapseHeader(budgetOffers)}
              key={graph2?.filter(f => f?.tag !== 'BUDGET_EXPENSES')?.length + budgetOffers.index}>
              {RenderDetailedExpenses(budgetOffers.tag)}
            </PanelContainer>}
          </CollapseContainer>

          {weddingInfo && weddingInfo.billings.length > 0 && (
            <React.Fragment>
              <SectionTitle subsection>Dados Faturação</SectionTitle>
              {weddingInfo.billings.map((billing, index) => (
                <BillingBlock key={index} billing={billing} />
              ))}
            </React.Fragment>
          )}
        </Col>
      </Row>
    </Fragment>
  );
};

export default BudgetDetails;
