import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Icon, Row, Col, Steps, TimePicker, Checkbox, Collapse } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import FormValidator from '../../../infra/services/validations/FormValidator';
import CheckboxInput from '../../../components/inputs/CheckboxInput';
import SelectInput from '../../../components/inputs/SelectInput';
import { BaseForm } from '../../../styles/BasicStyles';
import PropTypes from 'prop-types';
import DateInput from '../../../components/inputs/DateInput';
import XpertGoTimeInput from '../../../components/inputs/XpertGoTimeInput';
import TextInput from '../../../components/inputs/TextInput';
import moment from 'moment';
import Alerts from '../../../components/alert/Alert';
import XpertGoDateTimeInput from '../../../components/inputs/XpertGoDateTimeInput';
import styledComponents from 'styled-components';

const { Panel } = Collapse;

export const ExceptionList = styledComponents.ol`
    list-style: disc;
    margin-bottom: 0;
    margin-block-start: 0;
    margin-inline-start: 0;
    padding-inline-start: 28px;
`;

const Weekdays = {
    SUNDAY: 'SN',
    MONDAY: 'M',
    TUESDAY: 'TU',
    WEDNESDAY: 'W',
    THURSDAY: 'TH',
    FRIDAY: 'F',
    SATURDAY: 'ST'
}

export const ScheduleServiceType = {
    CUSTOM: 'CUSTOM',
    RECURSIVE: 'RECURSIVE',
}

const validations = (values) => {
    let errors = {};

    const validate = {
        type: 'required',
        time: 'required',
        // weddingPlace: 'required',
        startDate: 'required'
    };

    if (values?.type == ScheduleServiceType.RECURSIVE) {
        validate['endDate'] = 'required';
    }

    if (!values?.allWeekdays) {
        validate['weekday'] = 'required';
    }

    errors = FormValidator.make(validate)(values);

    if (!values?.allWeekdays && (!values?.weekday || !Array.isArray(values?.weekday) || values?.weekday?.length == 0)) {
        errors['weekday'] = 'true';
    }

    if (values?.type == ScheduleServiceType.RECURSIVE && values?.startDate && values?.endDate
        && moment.utc(values.startDate).startOf('day').isAfter(moment.utc(values.endDate).startOf('day'))) {
        errors['startDate'] = 'true';
        errors['endDate'] = 'true';
        Alerts.new({
            type: 'warning',
            title: 'Atenção',
            text: 'Data final têm que ser superior ou igual à data inicial'
        });
    }

    return errors;
};

let ScheduleServiceModal = ({
    isOpen,
    loading,
    handleSubmit,
    initialValues,
    onSubmit,
    onClose,
    onDelete,
    change,
    serviceForm
}) => {
    const [date, setDate] = useState();

    useEffect(() => {
        if (initialValues?.date) setDate(initialValues?.date);
        if (initialValues?.type && initialValues.type == ScheduleServiceType.CUSTOM) {
            if (initialValues?._id || initialValues?.recursive) {
                change('time', initialValues?.startDate);
            }
        }
    }, [initialValues]);

    // Disable dates
    const disabledDate = (current) => {
        return current && current.isBefore(moment.utc().startOf('day'), 'day');
    }

    const disabledEndDate = (current) => {
        const startDate = serviceForm?.startDate
            ? moment.utc(serviceForm?.startDate)
            : null;
        return startDate
            ? current && current.isSameOrBefore(startDate.startOf('day'), 'day')
            : current && current.isBefore(moment.utc().startOf('day'), 'day');
    }

    const disableAllWeekdays = () => {
        if (serviceForm?.startDate && serviceForm?.endDate) {
            return moment.utc(serviceForm.endDate).diff(moment.utc(serviceForm.startDate), 'days') < 7 ? true : false;
        } else {
            return true;
        }
    }

    return (
        <Modal
            visible={isOpen}
            title={initialValues?._id ? 'Editar horário de atendimento' : 'Criar horário de atendimento'}
            maskClosable={false}
            onCancel={onClose}
            footer={<React.Fragment>
                {/* {(initialValues?._id || initialValues?.recursive) && <Button
                    key='delete'
                    type='danger'
                    onClick={() => onDelete(initialValues)}>
                    Remover
                </Button>} */}
                <Button
                    key='cancel'
                    type='default'
                    onClick={onClose}>
                    Cancelar
                </Button>
                <Button
                    key='submit'
                    type='primary'
                    loading={loading}
                    onClick={handleSubmit(onSubmit)}>
                    Gravar
                </Button>
            </React.Fragment>}>

            <BaseForm onSubmit={handleSubmit(onSubmit)}>
                <Row gutter={[12, 12]}>
                    {/* <Col style={{ display: 'none' }} xs={24} md={24} lg={24} xl={24}>
                        <Field
                            component={TextInput}
                            label='Id *'
                            name={`_id`}
                        />
                    </Col> */}
                    {/* <Col style={{ display: 'none' }} xs={24} md={24} lg={24} xl={24}>
                        <Field
                            component={TextInput}
                            label='Evento Recursivo Pai *'
                            name={`recursive`}
                        />
                    </Col> */}
                    {(!serviceForm?.recursive || serviceForm?.recursive === '')
                        && <Col xs={24} md={24} lg={24} xl={24}>
                            <Field
                                component={SelectInput}
                                data={[
                                    { _id: ScheduleServiceType.RECURSIVE, name: 'Periódico' },
                                    { _id: ScheduleServiceType.CUSTOM, name: 'Pontual' }
                                ]}
                                label='Tipo de horário *'
                                name={`type`}
                                placeholder={'Indique o tipo'}
                                onChange={(e) => {
                                    if (e == ScheduleServiceType.RECURSIVE) {
                                        change('allWeekdays', true);
                                    }
                                }}
                            />
                        </Col>}
                    {serviceForm?.type && serviceForm.type === ScheduleServiceType.RECURSIVE
                        ? <React.Fragment>
                            <Col xs={24} md={24} lg={12} xl={12}>
                                <Field
                                    component={DateInput}
                                    label='Data Inicial*'
                                    name={`startDate`}
                                    placeholder={'Indique a data inicial'}
                                    disabledDate={disabledDate}
                                    onChange={(e) => {
                                        const date = e && moment.utc(e).isValid() ? e : null;
                                        setDate(date);

                                        const time = serviceForm?.time && moment.utc(serviceForm.time).isValid() && date
                                            ? moment.utc(serviceForm.time).set('year', date.year()).set('month', date.month()).set('date', date.date())
                                            : null;

                                        change('time', time);
                                    }}
                                />
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={12}>
                                <Field
                                    component={DateInput}
                                    label='Data Final*'
                                    name={`endDate`}
                                    placeholder={'Indique a data final'}
                                    disabledDate={disabledEndDate}
                                />
                            </Col>
                            <Col xs={24} md={24} lg={8} xl={8}>
                                <Field
                                    component={XpertGoDateTimeInput}
                                    label='Horário *'
                                    name={`time`}
                                    placeholder={'Indique o horário'}
                                    dateRef={date}
                                />
                            </Col>
                        </React.Fragment>
                        : <React.Fragment>
                            <Col xs={24} md={24} lg={12} xl={12}>
                                <Field
                                    component={DateInput}
                                    label='Data *'
                                    name={`startDate`}
                                    placeholder={'Indique a data'}
                                    disabled={initialValues?.recursive ? true : false}
                                    disabledDate={disabledDate}
                                    onChange={(e) => {
                                        const date = e && moment.utc(e).isValid() ? e : null;
                                        setDate(date);

                                        const time = serviceForm?.time && moment.utc(serviceForm.time).isValid() && date
                                            ? moment.utc(serviceForm.time).set('year', date.year()).set('month', date.month()).set('date', date.date())
                                            : null;

                                        change('time', time);
                                    }}
                                />
                            </Col>
                            <Col xs={24} md={24} lg={8} xl={8}>
                                <Field
                                    component={XpertGoDateTimeInput}
                                    label='Horário *'
                                    name={`time`}
                                    placeholder={'Indique o horário'}
                                    dateRef={date}
                                />
                            </Col>
                        </React.Fragment>}
                    {/* <Col xs={24} md={24} lg={24} xl={24}>
                        <Field
                            component={SelectInput}
                            data={[
                                { _id: 1, name: 'Solar da Levada' },
                                { _id: 2, name: 'Quinta Lago dos Cisnes' },
                                { _id: 3, name: 'Ambas' },
                            ]}
                            label='Local de Atendimento'
                            name={`weddingPlace`}
                            placeholder={'Selecione o local'}
                            allowClear={true}
                        />
                    </Col> */}
                    {serviceForm?.type && serviceForm.type === ScheduleServiceType.RECURSIVE && !serviceForm?.recursive &&
                        <React.Fragment>
                            <Col xs={24} md={24} lg={24} xl={6} style={{ marginBottom: 6 }}>
                                <Field
                                    component={CheckboxInput}
                                    label='Todos os Dias?'
                                    name={`allWeekdays`}
                                    positiveLabel='Sim'
                                    disabled={disableAllWeekdays()}
                                />
                            </Col>
                            {!serviceForm?.allWeekdays && <Col xs={24} md={24} lg={24} xl={18}>
                                <Field
                                    component={SelectInput}
                                    data={[
                                        { _id: '7', name: 'Domingo' },
                                        { _id: '1', name: 'Segunda' },
                                        { _id: '2', name: 'Terça' },
                                        { _id: '3', name: 'Quarta' },
                                        { _id: '4', name: 'Quinta' },
                                        { _id: '5', name: 'Sexta' },
                                        { _id: '6', name: 'Sábado' },
                                    ]}
                                    label='Dias da Semana'
                                    name={'weekday'}
                                    placeholder={'Selecione os dias da semana'}
                                    allowClear={true}
                                    type="select"
                                    dataKey={'_id'}
                                    dataLabel={'name'}
                                    mode="multiple"
                                />
                            </Col>}
                        </React.Fragment>
                    }
                    {initialValues?.exceptions?.length > 0
                        && <Col xs={24} md={24} lg={24} xl={24}>
                            <Collapse className='service-modal'>
                                <Panel header={`Horários alterados e apagados (${initialValues?.exceptions?.length})`} key="1">
                                    <ExceptionList>
                                        {initialValues?.exceptions.map(exceptionDate => <li>{moment.utc(exceptionDate).format('DD/MM/YYYY')}</li>)}
                                    </ExceptionList>
                                </Panel>
                            </Collapse>
                        </Col>}
                </Row>
            </BaseForm>
        </Modal>
    );
};

ScheduleServiceModal = reduxForm({
    form: 'service_form',
    enableReinitialize: true,
    validate: validations,
})(ScheduleServiceModal);

const selector = formValueSelector('service_form');

const mapStateToProps = state => ({
    serviceForm: {
        _id: selector(state, '_id'),
        recursive: selector(state, 'recursive'),
        type: selector(state, 'type'),
        startDate: selector(state, 'startDate'),
        endDate: selector(state, 'endDate'),
        time: selector(state, 'time'),
        // weddingPlace: selector(state, 'weddingPlace'),
        allWeekdays: selector(state, 'allWeekdays'),
        weekday: selector(state, 'weekday'),
    }
});

ScheduleServiceModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(ScheduleServiceModal)