import styled from 'styled-components';

export const TranslationBox = styled.div`
  margin-bottom: 20px;
  background: #f3f3f3;
  padding: 30px;
  position: relative;
`;

export const TranslationLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: ${({ first }) => (first ? '0' : '20px')};
`;

export const TranslationValue = styled.div`
  font-size: 16px;
  margin-top: 10px;
`;

export const TranslationOptions = styled.div`
  font-size: 16px;
  margin-top: 10px;
`;

export const EditOption = styled.div`
  position: absolute;
  top: 30px;
  right: ${({single}) => (single ? '30px' : '70px')};
`;

export const DeleteOption = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
`;
