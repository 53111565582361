import styled from 'styled-components';

export const YearValue = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
  font-size: 20px;
  span {
    font-size: 26px;
  }
`;

export const DateFilterSection = styled.div`
  display: inline-block;
  width: 100%;
  text-align: left;
`;
// padding-left: 82px;

export const BackButton = styled.div`
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
`;

export const Year = styled.div`
  display: inline-block;
  font-size: 22px;
  margin: 10px 15px 0 15px;
`;

export const NextButton = styled.div`
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
`;

export const GraphsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;