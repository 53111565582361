import React from 'react';
import {Field} from 'redux-form';
import {
  OptionsContainer,
  OptionLine,
  OptionColumn,
  OptionQuantity,
  OptionHeader
} from './Styles';
import TextInput from '../../../components/inputs/TextInput';
import NumberInput from '../../../components/inputs/NumberInput';
import TimeInput from '../../../components/inputs/TimeInput';

const OrderStaffList = ({ fields }) => {
  return (
    <OptionsContainer>
      <OptionLine>
        <OptionHeader maxWidth={120} noBorderTopRight={true} noBorderBottomRight={true}>Categoria</OptionHeader>
        <OptionHeader maxWidth={150} noBorderRadius={true}>Cálculo Horário</OptionHeader>
        <OptionHeader maxWidth={150} noBorderRadius={true}>Horário de Entrada</OptionHeader>
        <OptionHeader maxWidth={180} noBorderRadius={true}>Quantidade</OptionHeader>
      </OptionLine> 
      {
        fields?.map((field, index) =>
          <OptionLine key={index}>  
            <OptionColumn maxWidth={120} noBorderTopRight={true} noBorderBottomRight={true}>
              <Field
                component={TextInput}
                name={`${field}.category`}
                type='text'
                disabled={true}
                noLabel={true}
              />
            </OptionColumn>
            <OptionColumn maxWidth={150} noBorderRadius={true}>
              <Field
                component={TextInput}
                name={`${field}.formula`}
                type='text'
                disabled={true}
                noLabel={true}
              />
            </OptionColumn>
            {/* <OptionColumn maxWidth={150} noBorderRadius={true}>
              <Field
                component={TextInput}
                name={`${field}.start`}
                type='text'
                disabled={true}
                noLabel={true}
              />
            </OptionColumn> */}
            <OptionColumn maxWidth={150} noBorderRadius={true}>
              <Field
                component={TimeInput}
                name={`${field}.start`}
                noLabel={true}
                weddingOrder={true}
              />
            </OptionColumn>
            <OptionQuantity>
              <Field
                component={NumberInput}
                name={`${field}.quantity`}
                step={1}
                min={0}
              />
            </OptionQuantity>
          </OptionLine>
        )
      }
    </OptionsContainer>
  );
};

export default OrderStaffList;