import React from "react";
import { GroupPanel, GroupCollapse } from "../ConfigsStyles";
import PropTypes from "prop-types";

const CollapseGroup = ({ title, panelKey, content, isPrimaryTitle }) => (
  <GroupCollapse bordered={false}>
    <GroupPanel isPrimaryTitle={isPrimaryTitle} header={title} key={panelKey}>
      {content}
    </GroupPanel>
  </GroupCollapse>
);

CollapseGroup.propTypes = {
  title: PropTypes.string.isRequired,
  panelKey: PropTypes.string,
  content: PropTypes.object,
  isPrimaryTitle: PropTypes.bool
};

export default CollapseGroup;
