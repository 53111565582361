import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {Modal, Button} from 'antd';
import {BaseForm} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import StaffCategory from "../../components/staffCategory/StaffCategory";
import TextInput from "../../components/inputs/TextInput";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import {
  SelectContainer, 
  NewOptionContainer
} from './AddStaffModalStyles';

const validations = FormValidator.make({
  category: 'required',
  name: 'required'
});

let AddStaffModal = ({
  open,
  editStaff,
  loading,
  handleSubmit,
  onSubmit,
  closeModal,
  categories,
  requiredError,
  showNewCategory,
  toggleShowNew
}) => {
  return (
    <Modal
      visible={open}
      title={editStaff >= 0 ? 'Editar Staff Externo' : 'Adicionar Staff Externo'}
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      <BaseForm onSubmit={handleSubmit(onSubmit)}>
        <SelectContainer>
          <Field
            component={StaffCategory} 
            name={'category'}
            categories={categories}
            showNew={showNewCategory}
            toggleShowNew={toggleShowNew}
          />
        </SelectContainer>
        <NewOptionContainer showNew={showNewCategory}>
          <Field
            name='newCategory'
            component={TextInput}
            placeholder='Introduza o nome'
            type="text"
            requiredError={requiredError}
            noLabel={true}
          />
        </NewOptionContainer>
        <Field
          component={TextInput}
          name='name'
          type='text'
          label='Nome'
        />
        <Field
          component={TextInput}
          name='email'
          type='text'
          label='Email'
        />
        <Field
          component={TextInput}
          name='contact'
          type='text'
          label='Telefone'
        />
        <Field
          component={TextAreaInput}
          name='observations'
          label='Observações'
          placeholder='Mensagem'
        />
      </BaseForm>
    </Modal>
  );
};

AddStaffModal = reduxForm({
  form: 'manage_staff_form',
  validate: validations
})(AddStaffModal);

export default AddStaffModal;