import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {Modal, Button} from 'antd';
import {BaseForm} from '../../../styles/BasicStyles';
import DraftInput from '../../../components/inputs/DraftInput';

let PrivateNotesModalComponent = ({
  open,
  loading,
  handleSubmit,
  onSubmit,
  closeModal
}) => {
  return (
    <Modal
      visible={open}
      title='Pendentes e Orçamento'
      maskClosable={false}
      onCancel={closeModal}
      width={600}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      <BaseForm onSubmit={handleSubmit(onSubmit)}>
        <Field 
          component={DraftInput} 
          name="private_notes" 
          label='Editar'
          minRows={10}
          toolbarOnFocus={false}
          toolbar='notes'
        />
      </BaseForm>
    </Modal>
  );
};

PrivateNotesModalComponent = reduxForm({
  form: 'private_notes_modal_form'
})(PrivateNotesModalComponent);

export default connect()(PrivateNotesModalComponent);