import styled from 'styled-components';
import { Row, Col, Button } from 'antd';
import { device } from '../../styles/Responsive';

export const DashboardPage = styled.div`
  display: inline-block;
  width: 100%;
  padding: 15px;
  text-align: left;
`;

export const BasicInfoSection = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: ${({ printed }) => printed ? '80px' : '0px'};
`;

export const CouplePhoto = styled.div`
  display: inline-block;
  width: 250px;
  float: left;
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.inputBorderColor};
`;

export const CoupleInfo = styled.div`
  display: inline-block;
  width: calc(100% - 300px);
  margin-left: 20px;
  float: left;
`;

export const InfoText = styled.div`
  font-size: ${({ isTitle }) => (isTitle ? 24 : 16)}px;
  font-weight: bold;
  margin-top: ${({ isTitle, noTop }) => (noTop ? 0 : isTitle ? 20 : 0)}px;
`;

export const UnansweredQuestion = styled.div`
  font-size: 16px;
  color: ${p => p?.color ? p?.color : 'inherit'};
  background-color: ${p => p?.bgColor ? p?.bgColor : '#f5f5f5'};
  padding: 5px;
  border-radius: 4px;
`;

export const SectionCTA = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;

  & button {
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
`;

export const SectionTitle = styled.div`
  display: inline-block;
  width: ${({ insideTable }) => insideTable ? '100%' : 'calc(100% + 30px)'};
  position: relative;
  font-size: 28px;
  color: white;
  background: ${(p) => p.darkBg ? '#787878' : p.theme.primaryColor};
  text-align: left;
  margin: ${({ insideTable }) => insideTable ? '15px 0px 10px 0px' : '30px -15px 10px -15px'};
  padding: 10px 15px;
`;

export const InformationContainer = styled.div`
  padding: 0px 20px;
`;

export const SectionInformation = styled.div`
  white-space: pre-wrap;
  color: #f03434;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
`;

export const GraphSection = styled.div`
  display: inline-block;
  width: 50%;
  text-align: center;
`;

export const Moment = styled.div`
  display: inline-block;
  clear: both;
  margin-bottom: 5px;
  border: 1px solid #cacaca;
  border-radius: 12px;
  float: left;
  line-height: 30px;
  padding: 0 10px;
  font-weight: ${p => p.required || p.requiredOrderModule ? 'bold' : 'normal'};
  color: ${p => p.requiredOrderModule ? '#d91e18' : 'rgba(0, 0, 0, 0.65)'};
`;

export const MomentHour = styled.div`
  display: inline-block;
  line-height: 30px;
  margin-right: 10px;
  border-right: 1px solid #cacaca;
  padding-right: 10px;
`;

export const QuestionsContainer = styled(Row)`
  margin-top: 20px;
`;

export const SectionContainer = styled(Row)`
  margin-bottom: 30px;
`;

export const QuestionCol = styled(Col)`
  margin-bottom: 10px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  cursor: ${({ archived }) => (archived ? 'default' : 'pointer')};
`;

export const StaffContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

export const StaffContent = styled.div`
  background-color: #f5f5f5;
  margin-bottom: 15px;
`;

export const StaffInfo = styled.div`
  padding: 5px;
`;

export const StaffLabel = styled.div`
  display: inline-block;
  padding-right: 5px;
`;

export const StaffValue = styled.div`
  display: inline-block;
  font-weight: bold;
`;

export const DecorationModalContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

export const DecorationModalTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

export const DecorationModalInput = styled.div`
  margin-bottom: 20px;
`;

export const AnswerContainer = styled.div`
  margin-top: 10px;
`;

export const Answer = styled.div`
  font-weight: bold;
  display: inline-block;
`;

export const RealAnswer = styled.div`
  font-weight: bold;
  display: inline-block;
  color: red;
`;

export const Observation = styled.div`
  font-size: 13px;
  margin-top: 10px;
`;

export const NotesSectionContainer = styled.div`
  margin: 10px 0px;
  background-color: #faf9f9;
  padding: 5px;
`;

export const PrivateNotesSectionContainer = styled.div`
  margin: 10px 0px;
  background-color: #E8E8E8;
  padding: 10px  5px;
`;

export const NotesContainer = styled.div`
  margin-top: 40px;
  background-color: #faf9f9;
  padding: 5px;
`;

export const NoteContent = styled.div``;

export const NoteTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 16px;
`;

export const Note = styled.div``;

export const PrivateNote = styled.div`
  font-size: 18px;
  line-height: 25px;
`;

export const DecorationModalTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const ShowDefaultMenu = styled(Button)``;

export const GraphicsContainer = styled.div`
  max-height: 600px;
  overflow-y: auto;
`;

export const GraphicsTitle = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
  background-color: #decbb3;
  color: #ffffff;
  padding: 5px;

  &:first-child {
    margin-top: 0;
  }
`;

export const PrintedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: ${({ printed }) => printed ? '80px' : '0px'};
`;

export const PrintedBar = styled.div`
position: fixed;
top: ${({ small }) => small ? '138px' : '64px'};
width: calc(100% - 80px);
z-index: 999;
background-color: #ffffff;
`;

// margin-left: -15px
// padding: ${({ small }) => small ? '7px 0 0 0' : '15px 0 0 0'};
// left: 80px;

export const PrintedMessage = styled.div`
  color: #54a0ff;
  font-size: 18px;
`;

export const PrintedBarContent = styled.div`
background-color: #54a0ff;
color: #ffffff;
text-transform: ${({ btn }) => btn ? 'normal' : 'uppercase'};
font-size: ${({ small }) => small ? '18px' : '22px'};
text-align: center;
width: ${p => p?.small ? 'auto' : '100%'};
padding: ${({ small, btn }) => btn ? '5px 10px' : small ? '5px' : '10px'};
border-radius: ${({ btn }) => btn ? '1px' : '0'};
height: ${({ btn }) => btn ? '32px' : 'auto'};

.row-btn {
  display: flex;
  flexDirection: row;
  justifyContent: center;
  white-space: nowrap;
  align-items: center;
  height: 100%;
}

.anticon {
  margin-right: 5px;
}

@media ${device.desktop} {
  font-size: ${({ small }) => small ? '16px' : '20px'};
}

@media ${device.laptop} {
  font-size: ${({ small }) => small ? '15px' : '18px'};
}
`;
// border-radius: 5px;