import React, { Component } from "react";
import { Row, Col, Pagination, Icon, Popconfirm, message } from "antd";
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer
} from "../../styles/BasicStyles";
import {
  GetBanners,
  DeleteBannerById
} from "../../infra/requests/BannersRequests";
import ImageComponent from "../../components/images/ImageComponent";
import BaseButton from "../../components/buttons/BaseButton";
import { BannerTitle, ImageHover } from "./BannerStyles";
import BannerFilters from "./BannerFilters";

class BannersPage extends Component {
  state = {
    pageSize: 12,
    currentPage: 1,
    loading: false,
    filters: "",
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetBanners(currentPage, pageSize, filters);
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  deleteBanner = async id => {
    try {
      await DeleteBannerById(id);
      await this.updateDataTable();
      message.success("Banner Eliminado");
    } catch (err) {
      console.error(err);
    }
  };

  handleFilterChange = filters => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const { history } = this.props;
    const { rows, total, currentPage, pageSize } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Banners</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={"primary"}
              icon={"plus"}
              block
              text={"Criar"}
              onClick={() => history.push("/management/banners/add")}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={1}>
          <Row gutter={[12, 12]}>
            <Col xs={24}>
              <BannerFilters queryChange={this.handleFilterChange} />
            </Col>
            {rows.map(banner => (
              <Col key={banner._id} xs={12} sm={8} md={6}>
                <ImageComponent
                  ratio={0.6}
                  url={banner?.image?.url}
                  color={banner?.image?.color}
                >
                  <ImageHover>
                    <Icon
                      type="edit"
                      onClick={() =>
                        history.push(`/management/banners/${banner._id}`)
                      }
                    />
                    <Popconfirm
                      title="Deseja apagar este banner?"
                      okText="Sim"
                      onConfirm={() => this.deleteBanner(banner._id)}
                      cancelText="Não"
                    >
                      <Icon type="delete" />
                    </Popconfirm>
                  </ImageHover>
                </ImageComponent>
                <BannerTitle>{banner?.title?.pt}</BannerTitle>
              </Col>
            ))}
            <Col xs={24}>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
defaultPageSize={pageSize}
                total={total}
                onChange={this.handleChangePage}
              />
            </Col>
          </Row>
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default BannersPage;
