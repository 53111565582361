import client from "../config/AxiosConfig";

export const GetPortfolioImages = async (page = 1, limit = 20, filter = "") =>
  client.get(`/portfolio/${page}/${limit}?filter=${filter}`);

export const GetPortfolioImageById = async id => client.get(`/portfolio/${id}`);

export const UpdatePortfolioImageById = async (id, data) =>
  client.put(`/portfolio/${id}`, data);

export const CreatePortfolioImage = async data =>
  client.post(`/portfolio`, data);

export const DeletePortfolioImageById = async id =>
  client.delete(`/portfolio/${id}`);
