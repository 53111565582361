import React from 'react';
import {Field, reduxForm, FieldArray} from 'redux-form';
import {Modal, Button} from 'antd';
import {
  BaseForm, 
  Separator
} from '../../styles/BasicStyles';
import {
  FiltersContainer,
  FiltersColumn,
  ReportDownload, 
  ReportTitle,
  FileInputContainer,
  FileInputTitle
} from './OrderStyles';
import SelectInput from '../../components/inputs/SelectInput';
import TextInput from '../../components/inputs/TextInput';
import EmailList from '../../components/inputs/EmailList';
import FormValidator from '../../infra/services/validations/FormValidator';
import BaseButton from '../../components/buttons/BaseButton';
import moment from 'moment';
import FileInput from './components/FileInput';

const validations = FormValidator.make({
  supplier: 'required',
  email: 'required|email',
  subject: 'required'
});

let SendOrderModal = ({
  open,
  loading,
  handleSubmit,
  onSubmit,
  closeModal,
  suppliers,
  loadSupplierEmail,
  supplierEmails,
  downloadReport,
  filters,
  category,
  handleInputFile
}) => {
  const filter = filters ? JSON.parse(filters) : {};

  return (
    <Modal
      visible={open}
      title={'Enviar Encomenda'}
      maskClosable={false}
      onCancel={closeModal}
      width='80%'
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Enviar
        </Button>
      ]}>
      <FiltersContainer>
        <FiltersColumn>
          Categoria: <span>{category?.name ? category.name : '-'}</span>
        </FiltersColumn>
        <FiltersColumn>
          Intervalo de datas: <span>{moment.utc(filter?.dates[0]).format('DD-MM-YYYY')} - {moment.utc(filter?.dates[1]).format('DD-MM-YYYY')}</span>
        </FiltersColumn>
        <FiltersColumn>
          Local: <span>{parseInt(filter?.wedding_place) === 1 ? 'Solar da Levada' : parseInt(filter?.wedding_place) === 2 ? 'Quinta Lago dos Cisnes' : '-'}</span>
        </FiltersColumn>
      </FiltersContainer>
      <Separator/>
      <ReportDownload>
        <ReportTitle>Download Relatório:</ReportTitle>
        <BaseButton
          type={'primary'}
          icon={'download'}
          text="Download"
          onClick={downloadReport}
        />
      </ReportDownload>
      <BaseForm onSubmit={handleSubmit(onSubmit)}>
        <FileInputContainer>
          <FileInputTitle>Selecione aqui um ficheiro para substituir o resultado da exportação e enviar por email para o Fornecedor</FileInputTitle>
          <Field
            component={FileInput}
            name={'file'}
            execAfterChange={handleInputFile}
          />
        </FileInputContainer>
        <Separator/>
        <Field
          component={TextInput}
          name={'subject'}
          type='text'
          label={'Assunto do Email'}
          placeholder={'Assunto do Email'}
        />
        <Separator/>
        <Field
          component={SelectInput}
          name={'supplier'}
          label={'Fornecedor'}
          allowClear
          placeholder={'Selecione um fornecedor'}
          type='select'
          data={suppliers}
          execAfterChange={loadSupplierEmail}
        />
        {
          supplierEmails && (
            <React.Fragment>
              <Field
                component={TextInput}
                name={'email'}
                type="email"
                label={'Email do Fornecedor'}
                placeholder={'Email do Fornecedor'}
              />
              <FieldArray
                component={EmailList}
                name="extra_emails"
                label="Outros Emails"
              />
            </React.Fragment>
          )
        }
      </BaseForm>
    </Modal>
  );
};

SendOrderModal = reduxForm({
  form: 'manage_send_order_form',
  validate: validations
})(SendOrderModal);

export default SendOrderModal;