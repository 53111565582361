import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pt';
import styled from 'styled-components';
import { StyledHeader } from '../../components/layout/LayoutStyles';
import {
  getWeddingName,
  getLocal,
  getWeddingEmails
} from '../../infra/services/wedding/weddingUtils';
import { WeddingHeader, Information, Title, SpaceBetween, WeddingHeaderLeft, WeddingHeaderRight, UpgradeName } from './HeaderStyles';
import ExportationModal from '../exportation/ExportationModal';
import BaseButton from '../../components/buttons/BaseButton';
import { Icon, Tooltip, message } from 'antd';



export const ScrollBtn = styled.div`
`;

const HeaderComponent = ({ wedding, onNavigateToSection, location }) => {

  const copyWeddingInfo = ($event) => {
    $event.stopPropagation();

    let info = `${getWeddingName(wedding?.wedding)} 
    | ${moment.utc(wedding?.wedding?.date).locale('pt').format('dddd, DD MMMM YYYY')} 
    | ${getLocal(wedding?.wedding)}`;
    if (wedding?.wedding?.upgrade?.name?.pt) {
      info = info.concat(' | ', wedding?.wedding?.upgrade?.name?.pt);
    }
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(info);
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement("textarea");
      textArea.value = info;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";

      document.body.prepend(textArea);
      textArea.select();

      document.execCommand('copy');
    }
    message.success('Processo copiado!');
  };

  const copyEmails = ($event) => {
    $event.stopPropagation();

    const emails = getWeddingEmails(wedding?.wedding);
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(emails);
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement("textarea");
      textArea.value = emails;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";

      document.body.prepend(textArea);
      textArea.select();

      document.execCommand('copy');
    }
    message.success('E-mails copiados!');
  };

  return (
    <StyledHeader>
      <WeddingHeader>
        <WeddingHeaderLeft>
          <Title>
            {getWeddingName(wedding?.wedding)}
            <Tooltip title="Copiar">
              <Icon
                type="copy"
                onClick={($event) => copyWeddingInfo($event)}></Icon>
            </Tooltip>
            <Tooltip title="Copiar e-mails">
              <Icon
                type="mail"
                onClick={($event) => copyEmails($event)}></Icon>
            </Tooltip>
          </Title>
          <Information>
            {moment.utc(wedding?.wedding?.date).locale('pt').format('dddd, DD MMMM YYYY')}
            <SpaceBetween>|</SpaceBetween>
            {getLocal(wedding?.wedding)}
            {wedding?.wedding?.upgrade?.name?.pt && <React.Fragment>
              <SpaceBetween>|</SpaceBetween>
              {wedding?.wedding?.upgrade?.name?.pt || ''}
            </React.Fragment>}
          </Information>
        </WeddingHeaderLeft>
        <WeddingHeaderRight>
          {location.pathname === `/wedding/${wedding?.wedding?._id}` && <BaseButton
            type="default"
            icon="arrow-down"
            text="Sem Respostas"
            onClick={() => onNavigateToSection('/unanswered')}
          />}
          <BaseButton
            type="default"
            icon="edit"
            text="Dist. Convidados" onClick={() => onNavigateToSection(`/wedding/${wedding?.wedding?._id}/guestMap`)}
          />
          <ExportationModal weddingID={wedding?.wedding._id} />
        </WeddingHeaderRight>
      </WeddingHeader>
    </StyledHeader>
  );
};

const mapStateToProps = state => ({ wedding: state.wedding });

export default connect(mapStateToProps)(HeaderComponent);