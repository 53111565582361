import client from '../config/AxiosConfig';

export const CreateReserve = async (data) =>
  client.put(`/reserve/create`, data);

export const UpdateReserve = async (data) =>
  client.post(`/reserve/update`, data);

// export const UpdateReserveNotes = async (data) =>
//   client.post(`/reserve/updateNotes`, data);

export const DeleteReserve = async id =>
  client.delete(`/reserve/remove/${id}`);

export const GetReserveByMonth = async (filter) =>
  client.post(`/reserve/getAllByMonth`, filter);

export const GetReserveAgenda = async (filters) =>
  client.post(`/reserve/agenda`, filters);

export const SoftDeleteReserve = async (id, deleted) =>
  client.delete(`/reserve/softDelete/${id}/${deleted}`);

export const GetReservesWithOrder = async (data) =>
  client.post(`/reserve/getWithOrder`, data);

export const ConfirmReserve = async (id) =>
  client.post(`/reserve/confirm/${id}`);

export const ReorderReserve = async (id) =>
  client.post(`/reserve/reorder/${id}`);

export const ReactivateReserve = async (id) =>
  client.post(`/reserve/reactivate/${id}`);

export const MngMultipleReserves = async (data) =>
  client.put(`/reserve/mngMultiple`, data);

export const GetReservesPaginated = async (currentPage, limit, filters) =>
  client.get(`/reserve/paginate/${currentPage}/${limit}?filter=${filters}`);

export const GetReservesPaginatedAndGrouped = async (currentPage, limit, filters) =>
  client.get(`/reserve/paginateGrouped/${currentPage}/${limit}?filter=${filters}`);

export const GetReservesByWedding = async (weddingId) =>
  client.get(`/reserve/findByWedding/${weddingId}`);

export const GetReserve = async (reserveId, employeeAttending) =>
  client.get(`/reserve/find/${reserveId}/${employeeAttending}`);

export const CheckMngMultipleByAttending = async (weddingId) =>
  client.get(`/reserve/checkMngMultipleByAttending/${weddingId}`);

export const UpdateReserveRelatedInfo = async (weddingId, data) =>
  client.put(`/weddings/${weddingId}/relatedReserveBO`, data);

export const CheckReservesReactivations = async () =>
  client.get(`/reserve/checkReservesReactivations`);

export const CheckDatesAvailable = async (data) =>
  client.post(`/reserve/checkDatesAvailable`, data);

  export const UpdateReserveConfirmationDate = async (id, data) =>
  client.post(`/reserve/${id}/confirmationDate`, data);

export const DownloadReservesCouple = async (filters) => {
  return client.post(`/reserve/exportCouple?filter=${filters}`, {}, {
    responseType: 'blob'
  }).then(response => {
    return response;
  })
}

export const DownloadReserves = async (filters) => {
  return client.post(`/reserve/export?filter=${filters}`, {}, {
    responseType: 'blob'
  }).then(response => {
    return response;
  })
}

export const DownloadAllReserves = async (filters) => {
  return client.post(`/reserve/exportAll?filter=${filters}`, {}, {
    responseType: 'blob'
  }).then(response => {
    return response;
  })
}

export const UploadReservesToDrive = async (filters) =>
  client.get(`/reports/reserves/uploadDrive?filter=${filters}`);