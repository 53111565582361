import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { TableFilterSection } from "../../styles/BasicStyles";
import SelectInput from "../../components/inputs/SelectInput";

class ReviewsFilters extends Component {
  state = {
    local: ""
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map(key => {
      if (this.state[key] && this.state[key] !== "") {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const { locals } = this.props;
    const { local } = this.state;
    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={6}>
            <SelectInput
              label="Local"
              allowClear
              placeholder="Escolher local"
              data={locals}
              input={{
                value: local,
                onChange: value => this.onInputChange("local", value)
              }}
              meta={{ valid: true }}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

const mapStateToProps = state => ({
  locals: state.locals
});

export default connect(mapStateToProps)(ReviewsFilters);
