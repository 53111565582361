import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Checkbox } from 'antd';
import {
  BaseForm,
} from '../../../styles/BasicStyles';
import {
  Row,
  Col,
} from 'antd';
import { useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import FormValidator from '../../../infra/services/validations/FormValidator';
import TextInput from '../../../components/inputs/TextInput';
import TagInput from '../../../components/inputs/TagInput';
import SubcategoryPriceTable from '../SubcategoryPriceTable';
import { CheckboxField, ColourInput } from '../../../components/inputs/InputStyles';
import SimpleCheckboxInput from '../../../components/inputs/SimpleCheckboxInput';
import NumberInput from '../../../components/inputs/NumberInput';
import CheckboxInput from '../../../components/inputs/CheckboxInput';
import SelectInput from '../../../components/inputs/SelectInput';
import { getRuleDescription } from './PaymentPhasesTable';
import ColourInputComponent from '../../../components/inputs/ColourInput';

const validations = values => {
  let error = {};
  if (values.personalized) {
    error = FormValidator.make({
      order: 'required',
    })(values);
  } else {
    error = FormValidator.make({
      order: 'required',
      condition: 'required',
      quantity: 'required|isInteger|minNumber:1',
      unit: 'required',
      conditionDate: 'required',
      dateToCheck: 'required',
    })(values);
  }

  return error;
};

export const UnitTypes = [
  { _id: 'Y', name: 'Ano' },
  { _id: 'M', name: 'Mês' },
  { _id: 'D', name: 'Dia' },
  { _id: 'H', name: 'Hora' },
  { _id: 'MIN', name: 'Minuto' },
];

export const ConditionTypes = [
  { _id: 'GT', name: 'Mais de' },
  { _id: 'GTE', name: 'Mais de/Igual a' },
  { _id: 'E', name: 'Igual a' },
  { _id: 'LTE', name: 'Menos de/Igual a' },
  { _id: 'LT', name: 'Menos de' },
  // { _id: 'U', name: 'Até' },
];

export const ConditionDateTypes = [
  { _id: 'B', name: 'Antes da' },
  // { _id: 'SB', name: 'Antes/Igual à' },
  // { _id: 'S', name: 'Igual à' },
  // { _id: 'SA', name: 'Após/Igual à' },
  { _id: 'A', name: 'Após a' },
];

export const DateTypes = [
  { _id: 'C', name: 'Data do Contrato' },
  { _id: 'W', name: 'Data do Casamento' },
];

let PaymentPhaseRuleModal = (props): React$Element<React$FragmentType> => {
  const ruleForm = useSelector(state => {
    // console.warn('Val', state.form.rule_form?.values);
    return state.form.rule_form?.values;
  });
  const [initialValues, setInitialValues] = React.useState(null);

  useEffect(() => {
    setInitialValues(JSON.parse(JSON.stringify(props.initialValues)));
  }, [props.initialValues])

  useEffect(() => {
    if (ruleForm) {
      const data = JSON.parse(JSON.stringify(ruleForm));
      if(!data.personalized) data.condition = 'U';
      setInitialValues(data);
    }
  }, [ruleForm])

  const isChild = initialValues && initialValues.paymentPhaseRule && initialValues.paymentPhaseRule !== null ? true : false;
  const title = `${initialValues?.id === null ? 'Adicionar' : 'Editar'} ${isChild ? 'Sub-regra' : 'Regra'}`;

  return (
    <Modal
      visible={props.isOpen}
      title={title}
      maskClosable
      onCancel={props.onClose}
      width={window.outerWidth >= 1024 ? 700 : '90%'}
      footer={[
        <Button
          key='cancel'
          onClick={() => props.onClose()}>
          Cancelar
        </Button>,
        <Button
          key='submit'
          type='primary'
          onClick={props.handleSubmit(props.onSubmit)}>
          Gravar
        </Button>
      ]}
    >

      {isChild && initialValues && <p>{getRuleDescription(initialValues.paymentPhaseRule)}</p>}

      <BaseForm onSubmit={props.handleSubmit(props.onSubmit)}>
        <Row gutter={[12, 12]}>
          {isChild && <Col xl={24} lg={24} md={24} xs={24}>
            <Field
              component={CheckboxInput}
              name='personalized'
              label="Personalizável por Casamento"
              positiveLabel="Sim"
            />
          </Col>}
          {initialValues && !initialValues.personalized &&
            <React.Fragment>
              <Col xl={5} lg={5} md={5} xs={24}>
                <Field
                  component={SelectInput}
                  data={isChild ? [{ _id: 'U', name: 'Até' }] : ConditionTypes}
                  name="condition"
                  label="Condição"
                  placeholder="Indique a condição"
                  type="text"
                  // disabled={isChild}
                />
              </Col>
              <Col xl={3} lg={3} md={3} xs={24}>
                <Field
                  component={NumberInput}
                  name="quantity"
                  label="Quantidade"
                  placeholder="Insira a quantidade"
                  step='1'
                />
              </Col>
              <Col xl={3} lg={3} md={3} xs={24}>
                <Field
                  component={SelectInput}
                  data={UnitTypes}
                  name="unit"
                  label="Unidade"
                  placeholder="Indique a unidade"
                  type="text"
                  disabled={!isChild}
                />
              </Col>
              <Col xl={5} lg={5} md={5} xs={24}>
                <Field
                  component={SelectInput}
                  data={ConditionDateTypes}
                  name="conditionDate"
                  label="Condição"
                  placeholder="Indique a condição"
                  type="text"
                />
              </Col>
              <Col xl={7} lg={7} md={7} xs={24}>
                <Field
                  component={SelectInput}
                  data={DateTypes}
                  name="dateToCheck"
                  label="Data a Validar"
                  placeholder="Selecione a data"
                  type="text"
                  disabled={!isChild}
                />
              </Col>
            </React.Fragment>}
          {isChild && <Col xl={24} lg={24} md={24} xs={24}>
            <Field
              component={ColourInputComponent}
              name="warningColor"
              label="Cor de Aviso"
              placeholder="Selecione a cor"
              hideInput={false}
            />
          </Col>}
        </Row>
      </BaseForm>
    </Modal>
  );
};

const selector = formValueSelector('rule_form');

PaymentPhaseRuleModal = reduxForm({
  form: 'rule_form',
  enableReinitialize: true,
  validate: validations
})(PaymentPhaseRuleModal);

PaymentPhaseRuleModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default withLocalize(PaymentPhaseRuleModal);