import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import BaseButton from '../../../components/buttons/BaseButton';
import { TableFilterSection } from '../../../styles/BasicStyles';
import TextInput from '../../../components/inputs/TextInput';
import SelectInput from '../../../components/inputs/SelectInput';

const requiredOptions = [
  {
    _id: 1,
    name: 'Sim',
    value: true
  },
  {
    _id: 2,
    name: 'Não',
    value: false
  }
];

class FoodRestrictionFilters extends Component {
  timeout = 0;
  state = {
    search: '',
    notesRequired: '',
    loading: false
  };

  componentDidMount = async () => {
  };

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map(key => {
      if (key !== 'loading' && this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    // queryChange(JSON.stringify(query));
    queryChange(query);
  };

  render() {
    const { search, notesRequired, loading } = this.state;
    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <div className='settings-search'>
              <TextInput
                input={{
                  value: search,
                  onChange: event =>
                    this.onInputChange('search', event.target.value, 300)
                }}
                meta={{ valid: true }}
                type="text"
                label="Pesquisar"
                placeholder="Procurar por sigla, legenda ou descrição"
              />
            </div>
          </Col>
          <Col xs={24} sm={12} md={4} lg={3} xl={3}>
            <SelectInput
              label="Requer Observações"
              disabled={loading}
              allowClear
              placeholder="Escolher opção"
              data={requiredOptions}
              dataKey='value'
              input={{
                value: notesRequired,
                onChange: value => this.onInputChange('notesRequired', value)
              }}
              meta={{ valid: true }}
            />
          </Col>

          <Col xs={24} sm={12} md={14} lg={15} className='setting-col-btn'>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Adicionar Restrição'}
              onClick={(e) => this.props.addAction(e)}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}


export default (FoodRestrictionFilters);
