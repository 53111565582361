import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, message, Input, Popconfirm, Tooltip, Icon } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector, change } from 'redux-form';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { BaseForm, SpinLoading } from '../../styles/BasicStyles';
import PropTypes from 'prop-types';
import DraftInput from '../../components/inputs/DraftInput';
import SelectInput from '../../components/inputs/SelectInput';
import { isCurrentUserAdmin } from '../../infra/helpers/Helpers';
import { GetAttendingEmployees, GetAttendingList, GetAvailableAttendingList, GetWeddingGuests } from '../../infra/requests/UsersRequests';
import TextInput from '../../components/inputs/TextInput';
import SelectCompactInput from '../../components/inputs/SelectCompactInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import { GenerateAccessCode, GetConfirmedWeddings, GetWedding, GetWeddingsSelect, MngGuestAccess, NotifyWeddingGuest, UpdateCreateWeddingGuest } from '../../infra/requests/WeddingRequests';
import Alerts from '../../components/alert/Alert';
import { CodeInput, CodeP } from '../../process/ChoicesPage/SectionStyles';
import BaseButton from '../../components/buttons/BaseButton';
import { bindActionCreators } from 'redux';
import { getWeddingFullName, getWeddingName, getWeddingTitle } from '../../infra/services/wedding/weddingUtils';
import styledComponents from 'styled-components';
import { CheckSchedule, GetSchedule } from '../../infra/requests/SchedulesRequests';
import { GetAttendingTypes } from '../../infra/requests/AttendingTypeRequests';
import { visitedLocals } from './SchedulesFilters';
import { TimeInput } from '../../components/inputs/InputStyles';
import XpertGoTimeInput from '../../components/inputs/XpertGoTimeInput';
import DateInput from '../../components/inputs/DateInput';
import momentTimezone from 'moment-timezone';
import moment from 'moment';
const { confirm } = Modal;

export const PresenceStatus = {
    PRESENT: 'P',
    MISSED: 'M',
};

export const ModalRow = styledComponents.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: flex-start;

    div.col {
        width: 50%;    
    }
`;

export const ModalTextLine = styledComponents.div`
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 7px;

  .anticon {
    margin-right: 5px;
  }
`;

class SchedulePresenceModal extends React.Component {
    state = {
        loading: false,
        schedule: null
    }

    componentDidMount() {
        const { initialValues } = this.props;

        this.setState({
            schedule: initialValues,
        });
    }

    render() {
        const { isOpen } = this.props;
        const { onClose, onMarkAsPresent, onMarkAsMissed } = this.props;
        const { markAsPresent, markAsMissed } = this.props;
        const { loading, schedule } = this.state;

        return (
            <Modal
                visible={isOpen}
                title={'Atendimento'}
                maskClosable
                onCancel={onClose}
                footer={[
                    <Button
                        key='cancel'
                        type='default'
                        onClick={onClose}>
                        Fechar
                    </Button>,
                    <Button
                        key='submit'
                        type='danger'
                        loading={markAsMissed}
                        disabled={loading}
                        onClick={() => onMarkAsMissed(PresenceStatus.MISSED)}>
                        Registar Falta
                    </Button>,
                    <Button
                        key='submit'
                        type='primary'
                        loading={markAsPresent}
                        disabled={loading}
                        onClick={() => onMarkAsPresent(PresenceStatus.PRESENT)}>
                        Registar Presença
                    </Button>
                ]} >

                {
                    loading
                        ? <SpinLoading />
                        : <div>
                            <div style={{ display: 'inline-flex', marginTop: 10, width: '100%' }}>
                                <Icon type='warning' style={{ color: 'orange', fontSize: '2rem', margin: 'auto 10px auto 0' }}></Icon>
                                <div style={{ width: '100%' }}>
                                    <ModalTextLine><b>{getWeddingTitle(schedule?.wedding)}</b></ModalTextLine>
                                    <ModalRow>
                                        <div className='col'>
                                            <ModalTextLine>
                                                <Icon type="calendar" />
                                                {moment.utc(schedule?.date).format('DD/MM/YYYY')}
                                            </ModalTextLine>
                                            <ModalTextLine>
                                                <Icon type="environment" />
                                                {schedule?.weddingPlace === 3
                                                    ? 'Ambos os espaços'
                                                    : schedule?.weddingPlace === 2
                                                        ? 'Quinta Lago dos Cisnes'
                                                        : 'Solar da Levada'
                                                }
                                            </ModalTextLine>
                                            <ModalTextLine>
                                                <Icon type="tag" />
                                                {schedule?.attendingType?.name?.pt || ''}
                                            </ModalTextLine>
                                        </div>
                                        <div className='col'>
                                            <ModalTextLine>
                                                <Icon type="clock-circle" />
                                                {moment.utc(schedule?.startDate).local().format('HH:mm')}
                                                <Icon type="swap-right" />
                                                {moment.utc(schedule?.endDate).local().format('HH:mm')}
                                            </ModalTextLine>
                                            <ModalTextLine>
                                                <Icon type="flag" />
                                                {schedule?.language == 'pt_en'
                                                    ? 'Preferencialmente em Inglês, mas pode ser em Português'
                                                    : schedule?.language == 'en'
                                                        ? 'Inglês'
                                                        : 'Português'}
                                            </ModalTextLine>
                                        </div>
                                    </ModalRow>

                                </div>
                            </div>
                        </div>}
            </Modal >
        );
    }
};

SchedulePresenceModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default connect()(SchedulePresenceModal)