import React from 'react';
import {
  BillingBox,
  BillingItem
} from './BillingBlockStyles';

const Billings = ({ billing }) => {
  return (
    <BillingBox>
      {
        billing.nif &&
        <BillingItem>
          NIF:<span>{billing.nif}</span>
        </BillingItem>
      }
      {
        billing.name &&
        <BillingItem>
          Nome:<span>{billing.name}</span>
        </BillingItem>
      }
      {
        billing.address &&
        <BillingItem>
          Morada:<span>{billing.address}</span>
        </BillingItem>
      }
      {
        billing.observations &&
        <BillingItem>
          Observações:<span>{billing.observations}</span>
        </BillingItem>
      }
    </BillingBox>
  );
};

export default Billings;