import PropTypes from 'prop-types';
import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Icon } from 'antd';
import { Field } from 'redux-form';
import { SectionTitle } from '../../styles/BasicStyles';
import {
  GetIngredients,
  CreateIngredient
} from '../../infra/requests/IngredientsRequests';
import SelectInput from '../inputs/SelectInput';
import TextInput from '../inputs/TextInput';
import { AddIngredientButton, DeleteIngredientButton } from './IngredientsStyles';
import NumberInput from '../inputs/NumberInput';

const sortIngredients = (a, b) =>
  a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;

const QuantityTypes = [
  { _id: 'kg', name: 'Quilogramas' },
  { _id: 'l', name: 'Litros' },
  { _id: 'un', name: 'Unidades' }
];

const RoundingTypes = [
  { _id: 's', name: 'Sem Arredondamento' },
  { _id: 'int', name: 'Inteiro' },
  { _id: 'decimal', name: 'Decimal' },
];

const IngredientsComponent = ({ fields }) => {
  const [string, setString] = useState('');
  const [ingredients, SetIngredients] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await GetIngredients();
      data.sort(sortIngredients);
      SetIngredients(data);
    }
    fetchData();
  }, []);

  const saveString = async () => {
    try {
      if (string !== '') {
        const { data } = await CreateIngredient({ name: string });
        const found = ingredients.find(x => x._id === data._id);
        if (!found) {
          const list = [...ingredients, data];
          list.sort(sortIngredients);
          SetIngredients(list);
        }
        setString('');
        fields.unshift({ ingredient: data, quantity: 0, type: '' });
      }
    } catch (e) { }
  };

  const addIngredient = id => {
    const ingredient = ingredients.find(x => x._id === id);
    if (ingredient) {
      fields.unshift({ ingredient, quantity: 0, type: '' });
    }
  };

  const GetIngredientName = index => {
    const field = fields.get(index);
    return field?.ingredient?.name || 'Ingrediente removido';
  };

  return (
    <Row gutter={12}>
      <Col xs={24}>
        <SectionTitle subsection>Gestão de catering</SectionTitle>
      </Col>
      <Col xs={12}>
        <SelectInput
          label="Selecionar ingrediente"
          placeholder="Selecione um ingrediente da lista"
          data={ingredients}
          meta={{}}
          input={{ value: '', onChange: addIngredient }}
        />
      </Col>
      <Col xs={12}>
        <Row gutter={4}>
          <Col xs={20}>
            <TextInput
              label="Novo ingrediente"
              placeholder="Escreva o nome do novo ingrediente"
              meta={{}}
              type="text"
              input={{ value: string, onChange: e => setString(e.target.value) }}
            />
          </Col>
          <Col xs={4}>
            <AddIngredientButton onClick={saveString}>
              <Icon type="plus" />
            </AddIngredientButton>
          </Col>
        </Row>
      </Col>

      {fields.map((field, index) => (
        <Fragment key={index}>
          <Col xs={8}>
            <TextInput
              disabled
              label="Ingrediente"
              placeholder="Nome do ingrediente"
              meta={{}}
              type="text"
              input={{ value: GetIngredientName(index), onChange: () => null }}
            />
          </Col>
          <Col xs={3}>
            <Field
              label="Quantidade"
              placeholder="Quantidade do ingrediente"
              component={NumberInput}
              name={`${field}.quantity`}
            />
          </Col>
          <Col xs={6}>
            <Field
              label="Tipo"
              placeholder="Tipo de quantidade"
              component={SelectInput}
              data={QuantityTypes}
              name={`${field}.type`}
            />
          </Col>
          <Col xs={6}>
            <Field
              label="Arredondamento"
              placeholder="Tipo de arredondamento"
              component={SelectInput}
              data={RoundingTypes}
              name={`${field}.roundType`}
            />
          </Col>
          <Col xs={1}>
            <DeleteIngredientButton onClick={() => fields.remove(index)}>
              <Icon type="delete" />
            </DeleteIngredientButton>
          </Col>
        </Fragment>
      ))}
    </Row>
  );
};

IngredientsComponent.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default IngredientsComponent;
