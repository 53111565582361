import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Modal, Button } from 'antd';
import { BaseForm } from '../../styles/BasicStyles';
import ManageQuestionType from './ManageQuestionType';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import { QuestionTitleModal, ResponseContainer } from './SectionStyles';
import TextAreaInput from '../../components/inputs/TextAreaInput';

let SectionModal = ({
  open,
  loading,
  handleSubmit,
  onSubmit,
  closeModal,
  question = {},
  editOption,
}) => {
  return (
    <Modal
      visible={open}
      title="Editar resposta"
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      <BaseForm onSubmit={handleSubmit(onSubmit)}>
        <QuestionTitleModal>
          {TranslateValue(question?.title)}
        </QuestionTitleModal>
        <ResponseContainer>
          <Field
            name="response"
            component={ManageQuestionType}
            question={question}
            editOption={editOption}
          />
        </ResponseContainer>
        {question?.observations && (
          <Field
            component={TextAreaInput}
            name="observations"
            label="Observações"
            disabled={question?._id == '5e1e58a76c30747c0329e56d' ? true : false}
          />
        )}
      </BaseForm>
    </Modal>
  );
};

SectionModal = reduxForm({
  form: 'manage_answers_form'
})(SectionModal);

export default SectionModal;
