import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import {Row, Col, Modal, Button} from 'antd';
import {BaseForm} from '../../styles/BasicStyles';
import LanguagesInput from '../../components/inputs/LanguagesInput';
import SelectInput from '../../components/inputs/SelectInput';
import ColourInput from '../../components/inputs/ColourInput';
import ImageInput from '../../components/inputs/ImageInput';
import FormValidator from '../../infra/services/validations/FormValidator';

const SectionTypes = [
  {_id: 'text_right', name: 'Imagem à esquerda'},
  {_id: 'text_left', name: 'Imagem à direita'},
  {_id: 'text_only', name: 'Texto em duas secções'}
];

const validations = values => {
  const errors = FormValidator.make({
    text: 'required|languages',
    type: 'required'
  })(values);

  if (values.type === 'text_right' || values.type === 'text_left') {
    errors.image = FormValidator.validateField(
      'image',
      values.image,
      'required'
    );
  }

  if (values.type === 'text_only') {
    errors.extra = FormValidator.validateField(
      'extra',
      values.extra,
      'required|languages'
    );
  }

  return errors;
};

let SectionModal = ({
  handleSubmit,
  open,
  type,
  loading,
  closeModal,
  onSubmit
}) => {
  return (
    <Modal
      visible={open}
      title="Secção"
      width={900}
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      {open && (
        <BaseForm onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={[12]}>
            <Col xs={10}>
              <Field
                component={SelectInput}
                name="type"
                label={'Estrutura da secção'}
                placeholder={'escolha a estrutura da secção'}
                type="select"
                data={SectionTypes}
              />
            </Col>
          </Row>
          <Row gutter={[12]}>
            <Col xs={8}>
              <Field
                component={ColourInput}
                name="background"
                defaultColor="#fff"
                label={'Cor de fundo da Secção'}
              />
            </Col>
          </Row>
          <Row gutter={[12]}>
            <Col xs={12}>
              {type === 'text_right' ? (
                <Field label="Imagem" name="image" component={ImageInput} />
              ) : (
                <Field
                  label="Texto"
                  type="draft"
                  placeholder="Texto da secção"
                  name="text"
                  component={LanguagesInput}
                />
              )}
            </Col>
            <Col xs={12}>
              {type === 'text_left' ? (
                <Field label="Imagem" name="image" component={ImageInput} />
              ) : (
                <Field
                  label="Texto"
                  type="draft"
                  placeholder="Texto da secção"
                  name={type === 'text_only' ? 'extra' : 'text'}
                  component={LanguagesInput}
                />
              )}
            </Col>
          </Row>
        </BaseForm>
      )}
    </Modal>
  );
};

SectionModal = reduxForm({
  form: 'manage_upgrade_section_form',
  validate: validations
})(SectionModal);

const selector = formValueSelector('manage_upgrade_section_form');

const mapStateToProps = state => ({
  type: selector(state, 'type')
});

export default connect(mapStateToProps)(SectionModal);
