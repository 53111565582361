import React, {Component} from 'react';
import {reduxForm, Field, initialize} from 'redux-form';
import {connect} from 'react-redux';
import BaseButton from '../../components/buttons/BaseButton';
import {
  FormContainer,
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  BaseForm,
  HeaderButtonsContainer,
  SpinLoading
} from '../../styles/BasicStyles';
import {
  GetIngredient,
  UpdateIngredient,
  CreateIngredient
} from '../../infra/requests/IngredientsRequests';
import {
  GetAllSupplierCategories
} from '../../infra/requests/SupplierRequests';
import {Row, Col} from 'antd';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';

const validations = FormValidator.make({
  name: 'required'
});

class ManageIngredientsPages extends Component {
  state = {
    isNew: false,
    loading: true,
    ready: false,
    title: 'Novo Ingrediente',
    supplierCategories: []
  };

  componentDidMount = async () => {
    const {
      match: {params},
      dispatch
    } = this.props;

    const supplierCategories = await GetAllSupplierCategories();

    if(params.id) {
      const result = await GetIngredient(params.id);
      dispatch(initialize('manage_ingredients_form', {...result.data}));

      this.setState({
        ready: true,
        isNew: false,
        title: result?.data?.name,
        supplierCategories: supplierCategories?.data || []
      });
    } 
    else {
      this.setState({
        ready: true,
        isNew: true,
        supplierCategories: supplierCategories?.data || []
      });
    }
  };

  onSubmit = async values => {
    try {
      this.setState({loading: true});
      const {
        match: {params},
        history
      } = this.props;
      const {isNew} = this.state;

      isNew ? await CreateIngredient(values) : await UpdateIngredient(params.id, values);
      return history.push('/ingredients');
    } catch (e) {
      console.error(e);
      this.setState({loading: false});
    }
  };

  handleOnCancel = async () => {
    try {
      const {history} = this.props;
      return history.push('/ingredients');
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const {handleSubmit} = this.props;
    const {ready, title, supplierCategories} = this.state;

    if(!ready) return <SpinLoading />;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{title}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
          <Row style={{ display: 'grid', justifyItems: 'center' }}>
              <Col xs={24} sm={18} md={15} lg={10}>
                <Field
                  component={TextInput}
                  name={'name'}
                  type="text"
                  label={'Nome'}
                  placeholder={'Nome do Ingrediente'}
                />
                <Field
                  component={SelectInput}
                  name={'supplier_category'}
                  label={'Categoria de Encomenda'}
                  placeholder={'Escolha uma categoria'}
                  data={supplierCategories}
                  allowClear={true}
                  mode='multiple'
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageIngredientsPages = reduxForm({
  form: 'manage_ingredients_form',
  validate: validations
})(ManageIngredientsPages);

export default connect()(ManageIngredientsPages);