import React from 'react';
import { connect } from "react-redux";
import CurrencyComponent from '../../../components/currency/CurrencyComponent';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import {
  OptionSelection,
  OptionContent,
  OptionContentExtra
} from '../SectionStyles';
import Quantity from '../../../components/inputs/Quantity';
import { checkOption, GetPriceTag, CostTypes } from '../../../infra/services/options/Options';
import { showLocal } from '../../../infra/services/wedding/weddingUtils';
import Cost from '../../../components/inputs/Cost';
import { isString } from '../components/OptionUtils';
import { Icon } from 'antd';

const ListCard = ({ type, record, input, text, question, wedding }) => {
  let selected = false;
  let disabled = false;
  const isPremiumOption = wedding?.upgrade && wedding?.upgrade?.hasPremiumOptions && record && record?.isPremiumOption ? true : false;
  const isPaymentMethod = question && question._id === '5e73610729196b814fb9887f' ? true : false;

  //Options List
  if (type === 1) {
    selected = input?.value && input?.value.find(x => x?.id === record?._id);

    if (
      input?.value.length > 0 &&
      !input?.value.find(x => x.id === record._id)
    ) {
      //We have to check every element in input.value
      //If at least one option still exists in this question, then we can disable this option
      //If not then we cannot disable any option as the current option doesn't exits anymore
      for (let i = 0; i < input.value.length; i++) {
        if (checkOption(input.value[i], question, wedding.wedding_place)) {
          disabled = true;
          break;
        }
      }
    }
  }
  //Confirmation Options
  else if (type === 2) {
    selected = input?.value && input?.value.find(x => x.id === record);

    if (input?.value.length > 0 && !input?.value.find(x => x.id === record)) {
      //We have to check every element in input.value
      //If at least one option still exists in this question, then we can disable this option
      //If not then we cannot disable any option as the current option doesn't exits anymore
      for (let i = 0; i < input.value.length; i++) {
        if (checkOption(input.value[i], question, wedding.wedding_place)) {
          disabled = true;
          break;
        }
      }
    }
  }

  const toggleOption = () => {
    if (!disabled) {
      const list = [...input.value];
      let index = 0;

      //Options List
      if (type === 1) {
        index = list.findIndex(x => x.id === record._id);
        const isPremiumOption = wedding && wedding.upgrade && wedding.upgrade.hasPremiumOptions && record && record.isPremiumOption ? true : false;

        if (index >= 0) list.splice(index, 1);
        else {
          if (isPaymentMethod) {
            list.unshift({ id: record._id, qtd: 1 })
          } else if (isPremiumOption) {
            list.push({ id: record._id, qtd: 1, cost: 0 });
          } else list.push({ id: record._id, qtd: 1 });
        }
      }
      //Confirmation Options
      else if (type === 2) {
        index = list.findIndex(x => x.id === record);

        if (index >= 0) list.splice(index, 1);
        else list.push({ id: record, qtd: 1 });
      }

      // console.warn('list', list);
      input.onChange(list);
    }
  };

  const getCost = () => {
    let cost = 0;

    if (input?.value) {
      let found = input.value.find(x => x?.id === record?._id);

      if (found && found?.cost && found?.cost !== undefined) {
        cost = isString(found.cost) ? parseFloat(found.cost) : found.cost;
      } else {
        // cost = isPremiumOption
        //   ? record?.costPriceExtra
        //   : record.cost_price;
        cost = record.cost_price;
      }
    } else {
      // cost = isPremiumOption
      //   ? record?.costPriceExtra
      //   : record.cost_price;
      cost = record.cost_price;
    }

    return cost;
  }

  return (
    <OptionSelection
      selected={selected}
      disabled={disabled}
      onClick={() => toggleOption()}>
      <OptionContent transparent>
        {text ? text : TranslateValue(record.title)}
      </OptionContent>
      {record.cost && (
        <OptionContentExtra
          last={
            record.cost_type === CostTypes.QUANTITY &&
              selected &&
              parseInt(selected.qtd) > 0
              ? 0
              : 1
          }>
          {record?.isPremiumOption && <Icon type={'sketch'} />}
          {type === 1
            ? <Cost selected={selected} option={record} input={input} upgrade={wedding.upgrade} />
            : <CurrencyComponent value={getCost()} />}
          {GetPriceTag(record.cost_type)}
        </OptionContentExtra>
      )}
      {record.cost_type === CostTypes.QUANTITY &&
        selected &&
        parseInt(selected.qtd) > 0 && (
          <OptionContentExtra last={!record.local}>
            <Quantity optionID={record._id} input={input} />
          </OptionContentExtra>
        )}
      {record.local && (
        <OptionContentExtra last>{showLocal(record.local)}</OptionContentExtra>
      )}
    </OptionSelection>
  );
};

const mapStateToProps = state => ({
  wedding: state.wedding.wedding
});

export default connect(mapStateToProps)(ListCard);