import client from '../config/AxiosConfig';

export const CreateCalendarSetting = async (data) =>
  client.put(`/calendarSettings/create`, data);

export const GetCalendarSetting = async id =>
  client.get(`/calendarSettings/find/${id}`);

  export const GetCalendarSettings = async () =>
  client.get(`/calendarSettings/findAll`);

export const UpdateCalendarSetting = async (data) =>
  client.post(`/calendarSettings/update`, data);

export const DeleteCalendarSetting = async id =>
  client.delete(`/calendarSettings/remove/${id}`);