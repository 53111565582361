import AboutIcon from '../../assets/icons/ic_about.svg';
import StyleIcon from '../../assets/icons/ic_style.svg';
import DayIcon from '../../assets/icons/ic_day.svg';
import CeremonyIcon from '../../assets/icons/ic_ceremony.svg';
import StaffIcon from '../../assets/icons/ic_staff.svg';
import GuestsIcon from '../../assets/icons/ic_guests.svg';
import FoodIcon from '../../assets/icons/ic_food.svg';
import DrinksIcon from '../../assets/icons/ic_drinks.svg';
import DecorationIcon from '../../assets/icons/ic_decoration.svg';
import PartyIcon from '../../assets/icons/ic_party.svg';
import ProcessIcon from '../../assets/menu/ic_process.svg';
import BudgetIcon from '../../assets/menu/ic_budget.svg';
import LogsIcon from '../../assets/menu/ic_logs.svg';
import { PermissionsEnum } from '../../infra/helpers/Permissions';
import { hasPermissions } from '../../infra/helpers/Helpers';

const ProcessItems = id => [
  {
    to: `/wedding/${id}`,
    external_icon: true,
    size: 20,
    icon: ProcessIcon,
    name: 'Processo',
    exact: true,
    permissions: [PermissionsEnum.WEDDING_SUMMARY],
    // disabled: !hasPermissions([PermissionsEnum.WEDDING_SUMMARY])
  },
  {
    to: `/wedding/${id}/budget`,
    external_icon: true,
    size: 20,
    icon: BudgetIcon,
    name: 'Orçamento',
    exact: true,
    permissions: [PermissionsEnum.WEDDING_BUDGET],
    disabled: !hasPermissions([PermissionsEnum.WEDDING_BUDGET])
  },
  {
    to: `/wedding/${id}/about`,
    external_icon: true,
    size: 20,
    icon: AboutIcon,
    name: 'Sobre Nós',
    exact: true,
    permissions: [PermissionsEnum.WEDDING_ABOUT_US],
    disabled: !hasPermissions([PermissionsEnum.WEDDING_ABOUT_US])
  },
  {
    to: `/wedding/${id}/style`,
    external_icon: true,
    size: 20,
    icon: StyleIcon,
    name: 'O nosso estilo',
    exact: true,
    permissions: [PermissionsEnum.WEDDING_STYLE],
    disabled: !hasPermissions([PermissionsEnum.WEDDING_STYLE])
  },
  {
    to: `/wedding/${id}/day`,
    external_icon: true,
    size: 20,
    icon: DayIcon,
    name: 'O nosso dia',
    exact: true,
    permissions: [PermissionsEnum.WEDDING_DAY],
    disabled: !hasPermissions([PermissionsEnum.WEDDING_DAY])
  },
  {
    to: `/wedding/${id}/ceremony`,
    external_icon: true,
    size: 20,
    icon: CeremonyIcon,
    name: 'Cerimónia',
    exact: true,
    permissions: [PermissionsEnum.WEDDING_CEREMONY],
    disabled: !hasPermissions([PermissionsEnum.WEDDING_CEREMONY])
  },
  {
    to: `/wedding/${id}/staff`,
    external_icon: true,
    size: 20,
    icon: StaffIcon,
    name: 'Staff',
    exact: true,
    permissions: [PermissionsEnum.WEDDING_STAFF],
    disabled: !hasPermissions([PermissionsEnum.WEDDING_STAFF])
  },
  {
    to: `/wedding/${id}/guests`,
    external_icon: true,
    size: 20,
    icon: GuestsIcon,
    name: 'Convidados',
    exact: true,
    permissions: [PermissionsEnum.WEDDING_GUEST],
    disabled: !hasPermissions([PermissionsEnum.WEDDING_GUEST])
  },
  {
    to: `/wedding/${id}/food`,
    external_icon: true,
    size: 20,
    icon: FoodIcon,
    name: 'Food Selection',
    exact: true,
    permissions: [PermissionsEnum.WEDDING_FOOD],
    disabled: !hasPermissions([PermissionsEnum.WEDDING_FOOD])
  },
  {
    to: `/wedding/${id}/drinks`,
    external_icon: true,
    size: 20,
    icon: DrinksIcon,
    name: 'Drink Selection',
    exact: true,
    permissions: [PermissionsEnum.WEDDING_DRINK],
    disabled: !hasPermissions([PermissionsEnum.WEDDING_DRINK])
  },
  {
    to: `/wedding/${id}/decoration`,
    external_icon: true,
    size: 20,
    icon: DecorationIcon,
    name: 'Decoração',
    exact: true,
    permissions: [PermissionsEnum.WEDDING_DECOR],
    disabled: !hasPermissions([PermissionsEnum.WEDDING_DECOR])
  },
  {
    to: `/wedding/${id}/party`,
    external_icon: true,
    size: 20,
    icon: PartyIcon,
    name: 'Party Selection',
    exact: true,
    permissions: [PermissionsEnum.WEDDING_PARTY],
    disabled: !hasPermissions([PermissionsEnum.WEDDING_PARTY])
  },
  {
    to: `/wedding/${id}/logs`,
    external_icon: true,
    size: 20,
    icon: LogsIcon,
    name: 'Logs',
    exact: true,
    permissions: [PermissionsEnum.WEDDING_LOGS],
    disabled: !hasPermissions([PermissionsEnum.WEDDING_LOGS])
  }
];

export default ProcessItems;
