import client from '../config/AxiosConfig';

export const GetTranslations = async (page, limit, search, platform = 'BO') =>
  await client.get(
    `/translations/${page}/${limit}?search=${search}&platform=${platform}`
  );

export const CreateTranslation = async data =>
  await client.post('/translations', data);

export const UpdateTranslations = async data =>
  await client.put('/translations', data);

export const EditTranslation = async data =>
  await client.put(`/translations/${data.tag}`, data);

export const DeleteTranslation = async id =>
  await client.delete(`/translations/${id}`);
