import React, { useState } from 'react';
import { Modal, notification } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { withLocalize } from "react-localize-redux";
import { BasicInfoSection, SectionTitle, SectionCTA } from '../ProcessStyles';
import {
  QuestionTitleContainer,
  QuestionTitle
} from '../../ChoicesPage/SectionStyles';
import { UpdateGraphicMenu, SaveSectionNotes } from '../../../infra/requests/WeddingProcessRequests';
import { SaveGraphicMenu, SaveNotes } from '../../../redux/wedding/wedding.actions';
import { findPageByTag } from '../../../infra/services/sections/Sections';
import {
  findAnsweredQuestions,
  findFoodAnswers,
  findSpecificQuestion
} from '../../../infra/services/questions/AnsweredQuestions';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import { BaseButton } from '../../../components/buttons/BaseButton';
import DecorationModalComponent from './DecorationModalComponent';
import DecorationGraphicsModalComponent from './DecorationGraphicsModalComponent';
import moment from 'moment';
import 'moment/locale/pt';
import SectionResponse from '../../ChoicesPage/SectionResponse';
import SectionNotes from './SectionNotes';
import CommentsDrawer from '../../../components/comments/CommentsDrawer';
import NotesConfirmModal from '../../../components/notesModal/NotesModal';
import { hasNotes } from '../../../infra/services/notes/FindNotes';
import { getMenuPrintedConfirmation, getPrintedConfirmation } from '../../../infra/services/wedding/printedUtils';

const {confirm} = Modal;

const DecorationComponent = ({
  structureInfo,
  dispatch,
  weddingInfo,
  foodInfo,
  colorsInfo,
  SaveGraphicMenu,
  menuInfo,
  notes = [],
  saveNotesOnReducer,
  info,
  props
}) => {
  const [drawer, setDrawer] = useState(false);
  const [showModal, toggleModal] = useState(false);
  const [showGraphicsModal, toggleGraphicsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openNotesConfirm, setNotesConfirm] = useState(false);

  let section = findPageByTag(structureInfo, 'DECORATION');
  const decoration = findAnsweredQuestions(
    section,
    foodInfo,
    colorsInfo,
    weddingInfo.wedding_place
  );
  const note = notes.find(note => note.section === 'DECORATION');

  section = findPageByTag(structureInfo, 'FOOD');
  const sidedishes = foodInfo?.sidedishes;
  const food = findFoodAnswers(section, foodInfo, sidedishes);

  //Get specific questions for Graphics Modal ("Topper" from Food Selection and "Itens variados" from Party Selection)
  const topper = findSpecificQuestion(section, '5e1ecdec6c30745f8729e587');

  section = findPageByTag(structureInfo, 'PARTY');
  const partyItems = findSpecificQuestion(section, '5e34472cc51c945f8b29dd6d');

  const buildDefaultMenu = () => {
    let menu = '';

    //Check if the users have field the question "Nome para Ementas" (5e1cd8f0d0091dfd7f399c20)
    if (decoration && decoration.length > 0) {
      for (let i = 0; i < decoration.length; i++) {
        if (
          decoration[i]._id === '5e1cd8f0d0091dfd7f399c20' &&
          decoration[i].answer &&
          decoration[i].answer.response
        ) {
          menu = decoration[i].answer.response;
          break;
        }
      }
    }

    //Default, use the name of the bride and groom
    if (menu === '') {
      if (weddingInfo.bride && weddingInfo.bride.name) {
        menu = `${weddingInfo.bride.name}`;
      }
      if (weddingInfo.groom && weddingInfo.groom.name) {
        menu = `${menu}\n${weddingInfo.groom.name}`;
      }
    }

    if (weddingInfo.date) {
      menu = `${menu}\n${moment.utc(weddingInfo.date).locale('pt').format('LL')}`;
    }

    menu = `${menu}\nMENU`;

    if (food.length > 0) {
      for (let i = 0; i < food.length; i++) {
        menu = `${menu}\n${food[i].label}`;

        if (food[i].answer) {
          menu = `${menu}: ${food[i].answer}`;
        }
      }
    }

    return menu;
  };

  const closeGraphicsModal = () => {
    toggleGraphicsModal(false);
  };

  const closeModal = () => {
    toggleModal(false);
  };

  const openModal = () => {
    const {history} = props;
    history.push(`/wedding/${weddingInfo._id}/menu`);

    // let menu = undefined;

    // if (menuInfo?.text) {
    //   menu = menuInfo?.text;
    // } else {
    //   menu = buildDefaultMenu();
    // }

    // dispatch(
    //   initialize('decoration_modal_form', {
    //     text: menu
    //   })
    // );

    // toggleModal(true);
  };

  const onModalSubmit = async (values) => {
    if (weddingInfo?.printed) {
      confirm({
        ...getMenuPrintedConfirmation(),
        onOk() { saveMenu(values); },
        onCancel() { closeModal(); },
      });
    } else saveMenu(values);
  };

  const saveMenu = async (values) => {
    try {
      setLoading(true);

      const { success, data } = await UpdateGraphicMenu(weddingInfo._id, values);

      if (success) {
        notification.success({
          message: 'Gravado com sucesso',
          description: 'A informação foi gravada com sucesso!'
        });

        SaveGraphicMenu(data.text);
      }

      setLoading(false);
      closeModal();
    } catch (e) {
      console.error(e);
      setLoading(false);
      closeModal();
    }
  };

  const loadDefaultMenu = () => {
    const menu = buildDefaultMenu();

    dispatch(
      initialize('decoration_modal_form', {
        text: menu
      })
    );
  };

  const openNotes = () => {
    dispatch(initialize('wedding_notes_section_form', note));
    setDrawer(true);
  };

  const closeNotes = () => {
    dispatch(initialize('wedding_notes_section_form', note));
    setDrawer(false);
    setNotesConfirm(false);
  };

  const checkBeforeCloseNotes = (values) => {
    //If theres any change to the notes, ask before closing if we want to save the changes
    if (values && (note?.notes !== values.notes || note?.private !== values.private)) {
      dispatch(initialize('notes_modal_confirm_form', values));
      setNotesConfirm(true);
    }
    //Otherwise, just the close the notes
    else {
      setDrawer(false);
    }
  }

  const saveNotes = async values => {
    if (weddingInfo?.printed) {
      confirm({
        ...getPrintedConfirmation(),
        onOk() { updateNotes(values); },
        onCancel() { closeNotes(); },
      });
    } else updateNotes(values);
  };
  
  const updateNotes = async (values) => {
    setLoading(true);

    const { data } = await SaveSectionNotes(weddingInfo._id, {
      ...values,
      section: 'DECORATION'
    });

    dispatch(initialize('wedding_notes_section_form', data));
    saveNotesOnReducer(data);
    setLoading(false);
    setDrawer(false);
    setNotesConfirm(false);

    document.body.style.overflowY = 'auto';
  };

  return (
    <BasicInfoSection>
      <SectionTitle>
        Decoração
        <SectionCTA>
          <BaseButton
            type={'default'}
            icon={'read'}
            text={'Notas'}
            onClick={openNotes}
            notes={hasNotes(notes, 'DECORATION')}
          />
          <BaseButton
            type={'default'}
            icon={'arrow-right'}
            text={'Abrir Grafismos'}
            onClick={() => toggleGraphicsModal(true)}
          />
          <BaseButton
            type={'default'}
            icon={'arrow-right'}
            text={'Editar Menu'}
            onClick={() => openModal()}
          />
        </SectionCTA>
      </SectionTitle>
      <SectionNotes title="Notas Públicas:" note={note?.notes} />
      <SectionNotes title="Notas Privadas:" note={note?.private} />
      {decoration?.length > 0 ? (
        decoration.map((question, index) => (
          <div key={index}>
            <QuestionTitleContainer>
              <QuestionTitle>{TranslateValue(question.title)}</QuestionTitle>
            </QuestionTitleContainer>
            <SectionResponse
              weddingPlace={weddingInfo.wedding_place}
              upgrade={weddingInfo.upgrade}
              question={question}
              printed={weddingInfo?.printed}
            />
          </div>
        ))
      ) : (
        <div>Não existem registos para apresentar!</div>
      )}
      {showModal && (
        <DecorationModalComponent
          open={showModal}
          onSubmit={onModalSubmit}
          loading={loading}
          closeModal={closeModal}
          food={food}
          sidedishes={sidedishes}
          loadDefaultMenu={loadDefaultMenu}
        />
      )}
      {showGraphicsModal && (
        <DecorationGraphicsModalComponent
          open={showGraphicsModal}
          closeModal={closeGraphicsModal}
          decoration={decoration}
          topper={topper}
          partyItems={partyItems}
        />
      )}
      <CommentsDrawer
        title="Notas De Decoração"
        open={drawer}
        loading={loading}
        onClose={checkBeforeCloseNotes}
        saveNotes={saveNotes}
      />
      <NotesConfirmModal
        open={openNotesConfirm}
        loading={loading}
        onSubmit={saveNotes}
        closeModal={() => setNotesConfirm(false)}
        exitWithoutSaving={closeNotes}
      />
    </BasicInfoSection>
  );
};

const mapStateToProps = state => ({
  structureInfo: state.wedding.structure,
  weddingInfo: state.wedding.wedding,
  foodInfo: state.info.food,
  colorsInfo: state.info.colours,
  menuInfo: state.wedding.menu,
});

const mapActionToProps = dispatch =>
  bindActionCreators({ saveNotesOnReducer: SaveNotes, SaveGraphicMenu: SaveGraphicMenu, dispatch }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(DecorationComponent));