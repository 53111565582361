import ProcessPage from '../../process/dashboard/ProcessPage';
import AboutPage from '../../process/about/AboutPage';
import OurStylePage from '../../process/our_style/OurStylePage';
import OurDayPage from '../../process/our_day/OurDayPage';
import CeremonyPage from '../../process/ceremony/CeremonyPage';
import StaffPage from '../../process/staff/StaffPage';
import GuestsPage from '../../process/guests/GuestsPage';
import FoodPage from '../../process/food/FoodPage';
import DrinkPage from '../../process/drink/DrinkPage';
import DecorationPage from '../../process/decoration/DecorationPage';
import PartyPage from '../../process/party/PartyPage';
import BudgetPage from '../../process/budget/BudgetPage';
import UpgradeDetailComponent from '../../process/dashboard/components/UpgradeDetailComponent';
import LogsPage from '../../weddingday/logs/LogsPage';
import ManageMenuPage from '../../process/decoration/ManageMenuPage';
import ManageWeddingRoomPlanPage from '../../process/guests/ManageWeddingRoomPlanPage';
import { inRoomPlanTest } from '../../infra/helpers/Helpers';
import ManageWeddingGuestMapPage from '../../process/guests/ManageWeddingGuestMapPage';
import { PermissionsEnum } from '../../infra/helpers/Permissions';

const routes = [
  {
    path: '/wedding/:wedding',
    component: ProcessPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    // permissions: [PermissionsEnum.WEDDING_SUMMARY],
  },
  {
    path: '/wedding/:wedding/menu',
    component: ManageMenuPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.WEDDING_MENU],
  },
  {
    path: '/wedding/:wedding/budget',
    component: BudgetPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.WEDDING_BUDGET],
  },
  {
    path: '/wedding/:wedding/about',
    component: AboutPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.WEDDING_ABOUT_US],
  },
  {
    path: '/wedding/:wedding/style',
    component: OurStylePage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.WEDDING_STYLE],
  },
  {
    path: '/wedding/:wedding/day',
    component: OurDayPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.WEDDING_DAY],
  },
  {
    path: '/wedding/:wedding/ceremony',
    component: CeremonyPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.WEDDING_CEREMONY],
  },
  {
    path: '/wedding/:wedding/staff',
    component: StaffPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.WEDDING_STAFF],
  },
  {
    path: '/wedding/:wedding/guests',
    component: GuestsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.WEDDING_GUEST],
  },
  {
    path: '/wedding/:wedding/food',
    component: FoodPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.WEDDING_FOOD],
  },
  {
    path: '/wedding/:wedding/drinks',
    component: DrinkPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.WEDDING_DRINK],
  },
  {
    path: '/wedding/:wedding/decoration',
    component: DecorationPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.WEDDING_DECOR],
  },
  {
    path: '/wedding/:wedding/party',
    component: PartyPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.WEDDING_PARTY],
  },
  {
    path: '/wedding/:wedding/upgrade/:id',
    component: UpgradeDetailComponent,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.WEDDING_UPGRADE],
  },
  {
    path: '/wedding/:wedding/logs',
    component: LogsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.WEDDING_LOGS],
  }
];

if (inRoomPlanTest()) {
  routes.push({
    path: '/wedding/:wedding/roomPlan',
    component: ManageWeddingRoomPlanPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.WEDDING_ROOM_PLAN],
  });
  routes.push({
    path: '/wedding/:wedding/guestMap',
    component: ManageWeddingGuestMapPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.WEDDING_GUEST_MAP],
  });
}

export default routes;
