import client from '../config/AxiosConfig';

export const CreateEmployeePrice = async (data) =>
  client.put(`/employeePrice/create`, data);

export const GetEmployeePrice = async id =>
  client.get(`/employeePrice/find/${id}`);

export const GetEmployeePrices = async () =>
  client.get(`/employeePrice/findAll`);

export const GetEmployeePriceByEmployee = async (id) =>
  client.get(`/employeePrice/findByEmployee/${id}`);

export const GetEmployeePriceByEmployeeAndSubcategory = async (employeeId, subcategoryId) =>
  client.get(`/employeePrice/findByEmployeeAndSubcategory/${employeeId}/${subcategoryId}`);

export const UpdateEmployeePrice = async (data) =>
  client.post(`/employeePrice/update`, data);

export const DeleteEmployeePrice = async id =>
  client.delete(`/employeePrice/remove/${id}`);