import React, { useState, useEffect } from 'react';
import { Modal, Button, Icon } from 'antd';
import { InputField, InputGroupField, InputLabelDiv } from '../../components/inputs/InputStyles';
import moment from 'moment';


let ModalNotifyWaitingList = (props): React$Element<React$FragmentType> => {
    const [password, setPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [errorPassword, setErrorPassword] = React.useState(false);

    useEffect(() => {
        setPassword('');
    }, [props.isOpen])

    const footer = [
        <Button
            key='cancel'
            onClick={() => props.onClose()}>
            Cancelar
        </Button>,
        <Button
            key='submit'
            type='primary'
            disabled={errorPassword}
            onClick={(e) => {
                if (password.length === 0) {
                    e.stopPropagation();
                    setErrorPassword(true);
                } else props.onSubmit(password)
            }}>
            Notificar
        </Button>
    ];
    return (
        <Modal
            visible={props.isOpen}
            title={'Notificação de casais em lista de espera'}
            centered={true}
            width={400}
            onCancel={() => props.onClose()}
            footer={footer}
        >
            <p><b>Têm a certeza que pretende notificar os casais em lista de espera?</b></p>
            <p>
                Desta forma serão notificados os casais em lista de espera sobre a desistência/alteração da data e que esta se encontra disponível.
            </p>

            <InputLabelDiv>Palavra-passe</InputLabelDiv>

            <InputGroupField compact>
                {showPassword ? <InputField
                    prefix={<Icon type="lock" />}
                    type="text"
                    value={password}
                    onChange={($event: any) => {
                        setPassword($event.target.value);
                        setErrorPassword(false);
                    }}
                    placeholder="Insira a sua palavra-passe"
                    error={errorPassword ? 'true' : null}
                    autoComplete="new-password"
                /> : <InputField
                    prefix={<Icon type="lock" />}
                    type="password"
                    value={password}
                    onChange={($event: any) => {
                        setPassword($event.target.value);
                        setErrorPassword(false);
                    }}
                    placeholder="Insira a sua palavra-passe"
                    error={errorPassword == true ? 'true' : null}
                    autoComplete="new-password"
                />}
                <Button type="primary" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Icon type="eye-invisible" /> : <Icon type="eye" />}
                </Button>
            </InputGroupField>
        </Modal >
    );
};

export default ModalNotifyWaitingList;