import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { arrayPush, arraySplice, initialize, arrayInsert } from 'redux-form';
import { Row, Col, Icon, Popconfirm } from 'antd';
import {
  CreateChapter,
  UpdateChapter,
  DeleteChapter,
  UpdateChapterOrder
} from '../../infra/requests/ChapterRequests';
import ManageChapter from './ManageChapter';
import { padDigits } from '../../infra/services/calc/numbers';
import {
  EmptyBox,
  ChapterBox,
  Counter,
  Name,
  Description,
  OptionButton,
  QuestionsButton,
  HalfButton
} from './ChapterStyles';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import OrderChapters from './OrderChapters';
import { EnableQuestions } from '../../infra/services/disabled/DisabledInformation';

let ChaptersComponent = ({
  history,
  formName,
  dispatch,
  fields,
  tag,
  handleSubmit,
  disableSection,
  url = 'chapter'
}) => {
  const [modal, setModal] = useState(false);
  const [orderModal, setOrderModal] = useState(false);
  const [loading, setLoading] = useState(false);
  let chapters = fields.getAll() || [];

  const closeModal = () => {
    dispatch(initialize('manage_newchapter_form', {}, false));
    setModal(false);
  };

  const openEdit = data => {
    setModal(true);
    dispatch(initialize('manage_newchapter_form', data, false));
  };

  const closeOrderModal = () => {
    setOrderModal(false);
  };

  const openOrderModal = chapters => {
    dispatch(initialize('manage_orderchapters_form', { chapters: [...chapters] }, false));
    setOrderModal(true);
  };

  const onSubmit = async values => {
    try {
      setLoading(true);
      const isUpdate = values._id;
      const { data } = isUpdate
        ? await UpdateChapter(values._id, values)
        : await CreateChapter({ ...values, tag });
      if (isUpdate) {
        const index = chapters.findIndex(x => x._id === values._id);
        dispatch(arraySplice(formName, 'chapters', index, 1));
        dispatch(arrayInsert(formName, 'chapters', index, data));
      } else {
        dispatch(arrayPush(formName, 'chapters', data));
      }
      setLoading(false);
      closeModal();
      dispatch(initialize('manage_newchapter_form', {}, false));
    } catch (e) {
      setLoading(false);
    }
  };

  const onSubmitOrder = async values => {
    try {
      setLoading(true);

      if (values.chapters.length > 0) {
        let orderedList = [];
        Object.keys(values.chapters).forEach(function (key) {
          let obj = { _id: values.chapters[key]._id, order: key };

          orderedList.push(obj);
        });

        if (orderedList.length > 0) {
          const res = await UpdateChapterOrder({ order: orderedList });

          if (res.success) {
            dispatch(initialize(formName, values, false));
          }
        }
      }

      setLoading(false);
      closeOrderModal();
    } catch (e) {
      setLoading(false);
    }
  };

  const deleteChapter = async (id, index) => {
    const result = await DeleteChapter(id);
    if (result.success) {
      dispatch(arraySplice(formName, 'chapters', index, 1));
    }
  };

  return (
    <Row gutter={[24, 24]}>
      {chapters.map((chapter, index) => (
        <Col xs={12} sm={8} md={6} key={chapter._id}>
          <ChapterBox>
            <Counter>{padDigits(index + 1, 2)}</Counter>
            <Name>{TranslateValue(chapter?.name)}</Name>
            <Description>{TranslateValue(chapter?.description)}</Description>

            {EnableQuestions(chapter._id) && (
              <QuestionsButton
                onClick={() => history.push(`/${url}/${chapter._id}`)}>
                Perguntas
              </QuestionsButton>
            )}

            <OptionButton number={0} onClick={() => openEdit(chapter)}>
              <Icon type="edit" />
            </OptionButton>

            {!disableSection && (
              <Popconfirm
                title="Tem a certeza que quer remover este capítulo?"
                onConfirm={() => deleteChapter(chapter._id, index)}>
                <OptionButton number={1}>
                  <Icon type="delete" />
                </OptionButton>
              </Popconfirm>
            )}
          </ChapterBox>
        </Col>
      ))}

      {!disableSection && (
        <Col xs={12} sm={8} md={6}>
          <EmptyBox>
            <HalfButton border={true} onClick={() => setModal(true)}>
              Adicionar Capítulo
              <Icon type="plus" />
            </HalfButton>
            <HalfButton onClick={() => openOrderModal(chapters)}>
              Editar Ordem
              <Icon type="retweet" />
            </HalfButton>
          </EmptyBox>
        </Col>
      )}

      <ManageChapter
        open={modal}
        loading={loading}
        onSubmit={onSubmit}
        closeModal={closeModal}
      />

      {
        orderModal &&
        <OrderChapters
          open={orderModal}
          loading={loading}
          onSubmit={onSubmitOrder}
          closeModal={closeOrderModal}
          dispatch={dispatch}
        />
      }
    </Row>
  );
};

export default withRouter(connect()(ChaptersComponent));
