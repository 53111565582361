import React, { Component, Fragment } from 'react';
import Table from '../../components/table/Table';
import {
    Popconfirm,
    Icon,
    Collapse,
    Row,
    Col,
    Tag,
} from 'antd';
import {
    TableButton,
    SectionTitle,
    TableFilterSection
} from '../../styles/BasicStyles';
import Alert from "../../components/alert/Alert";
import { withRouter } from 'react-router-dom';
import BaseButton from '../../components/buttons/BaseButton';
import { CreateDEYear, DeleteDEYear, EmailYearType, GetALLDEYear, GetDEYearByType, UpdateDEYear } from '../../infra/requests/DynamicEmailYearRequests';
import Alerts from '../../components/alert/Alert';
import TextInput from '../../components/inputs/TextInput';
import { getUserId } from '../../infra/helpers/Helpers';
import XpertGoTimeInput from '../../components/inputs/XpertGoTimeInput';
import moment from 'moment';
import { CreateAttendingType, DeleteAttendingType, GetAttendingTypes, UpdateAttendingType } from '../../infra/requests/AttendingTypeRequests';
import FormValidator from '../../infra/services/validations/FormValidator';
import { GetEmployeesAttending } from '../../infra/requests/EmployeeRequests';
import { AddAttendingOrder, GetAttendingOrdered, RemoveAttendingOrder, UpdateAttendingOrder } from '../../infra/requests/UsersRequests';
import OrderAttendingEmployees from './OrderAttendingEmployees';
import CreateOrderAttending from './CreateOrderAttending';
import NumberInput from '../../components/inputs/NumberInput';
import { GetConfigs } from '../../infra/requests/ConfigsRequests';
import InfoSection from '../../pages/configs/components/InfoSection';
import { HeaderSection, SectionAction, SectionSubTitle } from '../../pages/configs/ConfigsStyles';
import XpertGoDateTimeInput from '../../components/inputs/XpertGoDateTimeInput';


const { Panel } = Collapse;

const EmailExpression = /^(([^<>()\\[\]\\.,;:\s@\\"]+(\.[^<>()\\[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;

const RemoveMessage = (
    <div>
        <div>Tem a certeza que quer remover este tipo? </div>
        <div>
            Toda a informação do tipo de atendimento será removido da plataforma e não
            conseguirá ser recuperado.
        </div>
    </div>
);

const RemoveEmployeeMessage = (
    <div>
        <div>Tem a certeza que quer a prioridade? </div>
        <div>
            O colaborador deixará de ter prioridade.
        </div>
    </div>
);

export const AttendingSubTypes = {
    IN_PERSON: 'IN_PERSON',
    ONLINE: 'ONLINE',
    ONLINE_VISIT: 'ONLINE_VISIT',
    ONLINE_QUESTIONS: 'ONLINE_QUESTIONS',
    PHONE: 'PHONE',
};


class AttendingTable extends Component {
    state = {
        loading: false,

        searchType: '',
        loadingType: false,
        pageSize: 15,
        currentPage: 1,
        rowsType: [],
        totalType: 0,
        editType: null,
        columnsType: [
            {
                title: 'Nome PT',
                key: 'name',
                width: '150px',
                render: data => (
                    <Fragment>
                        {!this.isEditing(data) ? (<div>{data.name.pt}</div>) :
                            (<div style={{ marginTop: '-30px' }}>
                                <TextInput
                                    input={{
                                        value: this.state.editType.name.pt,
                                        onChange: event => {
                                            let x = this.state.editType;
                                            x.name.pt = event.target.value;
                                            this.setState({ editType: x })
                                        }
                                    }}
                                    meta={{}}
                                    type="text"
                                    placeholder="Insira o nome"
                                />
                            </div>)
                        }
                    </Fragment>
                )
            },
            {
                title: 'Nome EN',
                key: 'name',
                width: '150px',
                render: data => (
                    <Fragment>
                        {!this.isEditing(data) ? (<div>{data.name.en}</div>) :
                            (<div style={{ marginTop: '-30px' }}>
                                <TextInput
                                    input={{
                                        value: this.state.editType.name.en,
                                        onChange: event => {
                                            let x = this.state.editType;
                                            x.name.en = event.target.value;
                                            this.setState({ editType: x })
                                        }
                                    }}
                                    meta={{}}
                                    type="text"
                                    placeholder="Insira o nome"
                                />
                            </div>)
                        }
                    </Fragment>
                )
            },
            {
                title: 'Nome FR',
                key: 'name',
                width: '150px',
                render: data => (
                    <Fragment>
                        {!this.isEditing(data) ? (<div>{data.name.fr}</div>) :
                            (<div style={{ marginTop: '-30px' }}>
                                <TextInput
                                    input={{
                                        value: this.state.editType.name.fr,
                                        onChange: event => {
                                            let x = this.state.editType;
                                            x.name.fr = event.target.value;
                                            this.setState({ editType: x })
                                        }
                                    }}
                                    meta={{}}
                                    type="text"
                                    placeholder="Insira o nome"
                                />
                            </div>)
                        }
                    </Fragment>
                )
            },
            {
                title: 'Nome ES',
                key: 'name',
                width: '150px',
                render: data => (
                    <Fragment>
                        {!this.isEditing(data) ? (<div>{data.name.es}</div>) :
                            (<div style={{ marginTop: '-30px' }}>
                                <TextInput
                                    input={{
                                        value: this.state.editType.name.es,
                                        onChange: event => {
                                            let x = this.state.editType;
                                            x.name.es = event.target.value;
                                            this.setState({ editType: x })
                                        }
                                    }}
                                    meta={{}}
                                    type="text"
                                    placeholder="Insira o nome"
                                />
                            </div>)
                        }
                    </Fragment>
                )
            },
            {
                title: 'Subtipo',
                key: 'subType',
                width: '150px',
                render: data => {
                    return data?.subType == AttendingSubTypes.IN_PERSON
                        ? <Tag color="green">Presencial</Tag>
                        : data?.subType == AttendingSubTypes.ONLINE
                            ? <Tag color="blue">Online</Tag>
                            : data?.subType == AttendingSubTypes.ONLINE_VISIT
                                ? <Tag color="cyan">Online Visita</Tag>
                                : data?.subType == AttendingSubTypes.ONLINE_QUESTIONS
                                    ? <Tag color="geekblue">Online Dúvidas</Tag>
                                    : '';
                }
            },
            {
                title: 'Acções',
                width: '120px',
                render: data => (
                    <Fragment>
                        {!this.isEditing(data) ? (<TableButton onClick={e => this.setEditForm(data)}>
                            <Icon type="edit" />
                            {' Editar'}
                        </TableButton>) : (<TableButton onClick={e => this.addEditType()}>
                            <Icon type="save" />
                            {' Guardar'}
                        </TableButton>)
                        }

                        {!this.isEditing(data)
                            ? data?.canBeDeleted && <TableButton onClick={e => e.stopPropagation()}>
                                <Popconfirm
                                    placement="topRight"
                                    title={RemoveMessage}
                                    okText="Remover"
                                    onConfirm={async () => { await this.deleteType(data._id) }}>
                                    <Icon type="delete" />
                                    {' Remover'}
                                </Popconfirm>
                            </TableButton>
                            : (<TableButton onClick={e => {
                                if (this.state.editType === null) {
                                    let rows = this.state.rows.filter(f => f.id !== null);
                                    this.setState({ editType: null, rows: rows });
                                } else this.setState({ editType: null });
                            }}>
                                <Icon type="close" />
                                {' Cancelar'}
                            </TableButton>)
                        }
                    </Fragment>
                )
            }
        ],

        searchEmployee: '',
        loadingEmployees: false,
        rowsEmployees: [],
        totalEmployees: 0,
        columnsEmployees: [
            {
                title: 'Ordem',
                key: 'order',
                width: '30px',
                render: data => (data?.attendingOrder || 0) + 1
            },
            {
                title: 'Tipo',
                key: 'type',
                width: '30px',
                render: data => data?.organizer
                    ? 'Organizador'
                    : data?.admin
                        ? 'Admin'
                        : 'CA'
            },
            {
                title: 'Colaborador',
                key: 'name',
                width: '150px',
                render: data => data?.name || ''
            },
            {
                title: 'E-mail',
                key: 'name',
                width: '150px',
                render: data => data?.email || ''
            },
            {
                title: 'Idioma',
                key: 'language',
                width: '150px',
                render: data => Array.isArray(data?.languages)
                    ? data?.languages?.join(', ')
                    : ''
            },
            {
                title: 'Tipo de Atendimento',
                key: 'attendingType',
                width: '150px',
                render: data => data?.organizer && Array.isArray(data?.attendingType)
                    ? data?.attendingType.map(m => m?.name?.pt).join(', ')
                    : Array.isArray(data?.employee?.attendingType)
                        ? data?.employee?.attendingType.map(m => m?.name?.pt).join(', ')
                        : ''
            },
            {
                title: 'Acções',
                width: '120px',
                render: data => (
                    <TableButton onClick={e => e.stopPropagation()}>
                        <Popconfirm
                            placement="topRight"
                            title={RemoveEmployeeMessage}
                            okText="Remover"
                            onConfirm={async () => { await this.onRemoveOrder(data._id) }}>
                            <Icon type="delete" />
                            {' Remover'}
                        </Popconfirm>
                    </TableButton>
                )
            }
        ],

        orderModal: false,
        savingOrder: false,

        createOrderModal: false,
        addingOrder: false,
        removingOrder: false,

        loadingTime: false,
        savingTime: false,
        cancelingTime: false,
        submittedTime: false,
        startTimeInfo: null,
        startTime: null,
        endTimeInfo: null,
        endTime: null,
        hostTimeInfo: null,
        hostTime: 90,

        loadingEmails: false,
        savingEmails: false,
        addingEmails: false,
        cancelingEmails: false,
        submittedEmails: false,
        emailsInfo: null,
        emails: [''],

        loadingConfigs: false,
        configs: [],
    };

    componentDidMount = async () => {
        await this.getTime();
        await this.getNotifyEmails();
        await this.getConfigurations();
        await this.updateTypeDataTable();
        await this.updateEmployeesDataTable();
    };

    // Max start time for schedule visits
    getTime = async () => {
        this.setState({ loadingTime: true });

        try {
            const resultStartTime = await GetDEYearByType(EmailYearType.START_ATTENDING_TIME);

            const resultEndTime = await GetDEYearByType(EmailYearType.END_ATTENDING_TIME);

            const resultHost = await GetDEYearByType(EmailYearType.HOST_TIME);
            // console.warn('Res', result);
            this.setState({
                startTimeInfo: resultStartTime?.success && resultStartTime?.data ? resultStartTime?.data : null,
                startTime: resultStartTime?.success && resultStartTime?.data?.contentPT ? resultStartTime?.data.contentPT : '',
                endTimeInfo: resultEndTime?.success && resultEndTime?.data ? resultEndTime?.data : null,
                endTime: resultEndTime?.success && resultEndTime?.data?.contentPT ? resultEndTime?.data.contentPT : '',
                hostTimeInfo: resultHost?.success && resultHost?.data ? resultHost?.data : null,
                hostTime: resultHost?.success && resultHost?.data?.hostTime ? resultHost?.data?.hostTime : 90,
                loadingTime: false,
                cancelingTime: false,
            });
        } catch (e) {
            this.setState({ loadingTime: false, cancelingTime: false });
            console.error(e);
        }
    }

    saveTime = async () => {
        const { startTime, endTime, hostTime } = this.state;
        let { startTimeInfo, endTimeInfo, hostTimeInfo } = this.state;
        this.setState({ submittedTime: true });

        if (!startTime
            || startTime === ''
            || !endTime
            || endTime === ''
            || !hostTime
            || hostTime === ''
            || hostTime < 0) {
            return;
        }

        this.setState({ savingTime: true });

        startTimeInfo.contentPT = startTime;
        const resultStartTime = await UpdateDEYear(startTimeInfo);

        endTimeInfo.contentPT = endTime;
        const resultEndTime = await UpdateDEYear(endTimeInfo);

        hostTimeInfo.hostTime = hostTime;
        const resultHost = await UpdateDEYear(hostTimeInfo);

        if (resultStartTime?.success && resultEndTime?.success && resultHost?.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso!',
                text: 'Horários atualizados!'
            });
            this.setState({
                savingTime: false,
                submittedtime: false
            });
        } else {
            Alerts.new({
                type: 'error',
                title: 'Erro!',
                text: 'Não foi possível atualizar os horários!'
            });
            this.setState({
                savingTime: false,
                submittedTime: false
            }, () => this.getTime());
        }
    }

    // General emails to notify of tomorrow schedules
    getNotifyEmails = async () => {
        this.setState({ loadingEmails: true });

        try {
            const result = await GetDEYearByType(EmailYearType.EMAILS_ATTENTING);
            // console.warn('Res', result);
            this.setState({
                emailsInfo: result?.success && result?.data ? result?.data : '',
                emails: result?.success && result?.data && result?.data?.emails ? result?.data.emails : [''],
                loadingEmails: false,
                cancelingEmails: false,
            });
        } catch (e) {
            this.setState({ loadingEmails: false, cancelingEmails: false });
            console.error(e);
        }
    }

    saveNotifyEmails = async () => {
        const { emails } = this.state;
        let { emailsInfo } = this.state;
        this.setState({ submittedEmails: true });

        const checkEmptyEmails = emails.some(element => typeof element === 'string' && element.trim() === '');
        const checkInvalidEmails = emails.some(element => EmailExpression.test(element) === false);
        if (checkEmptyEmails || checkInvalidEmails) {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Emails inválidos'
            });
        }

        this.setState({ savingEmails: true });

        emailsInfo.emails = emails;
        const result = await UpdateDEYear(emailsInfo);
        // console.warn('Res', result);

        if (result?.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso!',
                text: 'E-mails atualizados!'
            });
            this.setState({
                savingEmails: false,
                submittedEmails: false
            });
        } else {
            Alerts.new({
                type: 'error',
                title: 'Erro!',
                text: 'Não foi possível atualizar os e-mails!'
            });
            this.setState({
                savingEmails: false,
                submittedEmails: false
            }, () => this.getNotifyEmails());
        }
    }

    addEmailRow = () => {
        let { emails } = this.state;

        this.setState({ addingEmails: true });

        const checkEmptyEmails = emails.some(element => typeof element === 'string' && element.trim() === '');
        if (checkEmptyEmails) {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Deve indicar um email em todos os campos'
            });
        }

        const checkInvalidEmails = emails.some(element => EmailExpression.test(element) === false);
        if (checkInvalidEmails) {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Emails inválidos'
            });
        }

        emails.push('');
        this.setState({ emails, addingEmails: false });
    }

    validateEmail = (index) => {
        const { emails, submittedEmails, addingEmails } = this.state;
        return (submittedEmails || addingEmails) && (emails[index] === '' || EmailExpression.test(emails[index]) === false) ? true : false
    }

    removeEmailRow = (index) => {
        let { emails } = this.state;
        if (index > 0) {
            emails.splice(index, 1);
        } else {
            emails[index] = '';
        }
        this.setState({ emails });
    }

    isEditing = (type) => {
        const { editType } = this.state;
        return editType ? editType.id === type.id : false;
    }


    // Configs
    getConfigurations = async () => {
        this.setState({ loadingConfigs: true });
        const result = await GetConfigs();

        if (result?.success) {
            this.setState({
                configs: result?.data || [],
                loadingConfigs: false
            });
        } else {
            this.setState({ loadingConfigs: false });
        }
    };

    /**
    * Attending Types
    */
    updateTypeDataTable = async () => {
        this.setState({ loadingType: true });
        try {
            const result = await GetAttendingTypes();

            this.setState({
                rowsType: result.data ? result.data : [],
                totalType: result.data ? result.data.length : 0,
                loadingType: false
            });
        } catch (e) {
            this.setState({ loadingType: false });
            console.error(e);
        }
    }

    setEditForm = (type) => {
        let x = type ? JSON.parse(JSON.stringify(type)) : {
            name: ''
        };
        this.setState({ editType: x });
    }

    setAddForm = () => {
        const { editType, rowsType } = this.state;

        const type = {
            id: null,
            name: {
                pt: '',
                en: '',
                fr: '',
                es: ''
            },
        };
        if (!editType) {
            rowsType.push(type);
            this.setState({ rowsType, editType: type });
        }
    }

    addEditType = async () => {
        const { editType } = this.state;

        const errors = FormValidator.make({
            name: 'required|languages',
        })(editType || {});

        if (errors?.name && Object.keys(errors.name).length > 0) {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Deve inserir o nome do tipo'
            });
        }

        const result = editType?.id === null
            ? await CreateAttendingType(editType)
            : await UpdateAttendingType(editType?._id, editType);

        this.setState({ editType: null });
        return this.updateTypeDataTable();
    }

    deleteType = async id => {
        await DeleteAttendingType(id);
        return this.updateTypeDataTable();
    }

    /**
   * Employees Attending
   */
    updateEmployeesDataTable = async () => {
        this.setState({ loadingEmployees: true });
        try {
            const result = await GetAttendingOrdered();

            this.setState({
                rowsEmployees: result.data ? result.data : [],
                totalType: result.data ? result.data.length : 0,
                loadingEmployees: false
            });
        } catch (e) {
            this.setState({ loadingEmployees: false });
            console.error(e);
        }
    }

    onSubmitOrder = async values => {
        try {
            this.setState({ savingOrder: true });

            if (values.length > 0) {
                let orderedList = [];
                Object.keys(values).forEach(function (key) {
                    let obj = { _id: values[key]._id, order: key };
                    orderedList.push(obj);
                });

                if (orderedList && Array.isArray(orderedList) && orderedList.length > 0) {
                    const res = await UpdateAttendingOrder({ order: orderedList });

                    if (res?.success) {
                        this.updateEmployeesDataTable();
                    }
                }
            }
            this.setState({ savingOrder: false, orderModal: false });
        } catch (e) {
            this.setState({ savingOrder: false });
        }
    }

    onAddOrder = async values => {
        this.setState({ addingOrder: true });

        const res = await AddAttendingOrder(values.user);

        if (res?.success) {
            this.setState({
                addingOrder: false,
                createOrderModal: false
            }, () => this.updateEmployeesDataTable());
        } else this.setState({ addingOrder: false, createOrderModal: false });
    }

    onRemoveOrder = async (userId) => {
        this.setState({ removingOrder: true });

        const res = await RemoveAttendingOrder(userId);

        if (res?.success) {
            this.setState({
                removingOrder: false
            }, () => this.updateEmployeesDataTable());
        }
        this.setState({ removingOrder: false });
    }

    // handleChangePage = currentPage => {
    //     this.setState({ currentPage }, this.updateDataTable);
    // };

    // handleChangeRowsPerPage = (currentSize, pageSize) => {
    //     this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
    // };

    render() {
        const { history } = this.props;
        const { loading, loadingType } = this.state;
        const { loadingTime, savingTime, cancelingTime, submittedTime } = this.state;
        let { startTime, endTime, hostTime } = this.state;
        const { searchType, rowsType, columnsType, currentPageType, pageSizeType, totalType } = this.state;
        const { loadingEmails, savingEmails, addingEmails, cancelingEmails, submittedEmails, emailsInfo, emails } = this.state;
        const { loadingEmployees, searchEmployee, rowsEmployees, totalEmployees, columnsEmployees } = this.state;
        const { orderModal, savingOrder } = this.state;
        const { createOrderModal, addingOrder } = this.state;
        const { configs } = this.state;
        const invalidHostTime = submittedTime && (!hostTime || (hostTime && (hostTime === '' || hostTime < 0))) ? true : false;

        return (
            <React.Fragment>
                <SectionTitle>Horários</SectionTitle>
                <Row gutter={[12, 12]} style={{ marginBottom: 10 }}>
                    <Col xl={4} lg={4} md={6} sm={14} xs={24}>
                        <XpertGoDateTimeInput
                            first={true}
                            label={'Horário de ínicio'}
                            allowClear={false}
                            input={{
                                value: startTime,
                                onChange: (value) => {
                                    // startTime = value && moment(value).isValid()
                                    //     ? moment(value).format('HH:mm')
                                    //     : value;
                                    this.setState({ startTime: value })
                                }
                            }}
                            meta={{
                                invalid: submittedTime && startTime && startTime === '' ? true : false,
                                submitFailed: submittedTime && startTime && startTime === '' ? true : false,
                            }}
                            placeholder="Insira o horário"
                        />
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={14} xs={24}>
                        <XpertGoDateTimeInput
                            first={true}
                            label={'Horário de fim'}
                            allowClear={false}
                            input={{
                                value: endTime,
                                onChange: (value) => {
                                    // endTime = value && moment(value).isValid()
                                    //     ? moment(value).format('HH:mm')
                                    //     : value;
                                    this.setState({ endTime: value })
                                }
                            }}
                            meta={{
                                invalid: submittedTime && endTime && endTime === '' ? true : false,
                                submitFailed: submittedTime && endTime && endTime === '' ? true : false,
                            }}
                            placeholder="Insira o horário"
                        />
                    </Col>
                    <Col xl={6} lg={8} md={10} sm={14} xs={24}>
                        <NumberInput
                            first={true}
                            label={'Tempo de desconto para hospedeiras (min)'}
                            allowClear={false}
                            input={{
                                value: hostTime,
                                onChange: (value) => {
                                    this.setState({ hostTime: value || '' })
                                }
                            }}
                            meta={{
                                invalid: invalidHostTime,
                                submitFailed: invalidHostTime,
                            }}
                            placeholder="Insira os minutos"
                        />
                    </Col>
                    <Col xl={5} lg={5} md={4} sm={24} xs={24} style={{ height: 66, display: 'flex', alignItems: 'flex-end' }}>
                        <BaseButton
                            type={'primary'}
                            icon={'save'}
                            text={'Guardar'}
                            disabled={loadingTime}
                            loading={savingTime}
                            onClick={() => this.saveTime()}
                        />
                        <BaseButton
                            style={{ marginLeft: 10 }}
                            type={'default'}
                            icon={'close'}
                            text={'Cancelar'}
                            loading={cancelingTime}
                            onClick={() => this.setState({ cancelingTime: true }, () => this.getTime())}
                        />
                    </Col>
                </Row>

                <SectionTitle>Notificar no dia anterior aos atendimentos</SectionTitle>
                {emails.map((m, index) => {
                    return <Row gutter={[12, 12]} style={{ marginBottom: 10 }}>
                        <Col lg={8} md={10} sm={14} xs={24}>
                            <TextInput
                                first={true}
                                noLabel={true}
                                type='email'
                                input={{
                                    value: m,
                                    onChange: (event) => {
                                        emails[index] = event.target.value;
                                        this.setState({ emails })
                                    }
                                }}
                                meta={{
                                    invalid: this.validateEmail(index),
                                    submitFailed: this.validateEmail(index),
                                }}
                                placeholder="Insira o emails"
                            />
                        </Col>
                        <Col lg={2} md={4} sm={10} xs={24}>
                            <div style={{ display: 'flex' }}>
                                {index === (emails?.length - 1) && <BaseButton
                                    style={{ marginRight: 10 }}
                                    type={'primary'}
                                    icon={'plus'}
                                    text={'Adicionar'}
                                    disabled={loadingEmails}
                                    onClick={() => this.addEmailRow()}
                                />}
                                <BaseButton
                                    type={'default'}
                                    icon={'delete'}
                                    text={'Remover'}
                                    disabled={loadingEmails}
                                    onClick={() => this.removeEmailRow(index)}
                                />
                            </div>
                        </Col>
                    </Row>
                })}
                <Row gutter={[12, 12]} style={{ marginBottom: 10 }}>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <BaseButton
                            type={'primary'}
                            icon={'save'}
                            text={'Guardar'}
                            disabled={loadingEmails}
                            loading={savingEmails}
                            onClick={() => this.saveNotifyEmails()}
                        />
                        <BaseButton
                            style={{ marginLeft: 10 }}
                            type={'default'}
                            icon={'close'}
                            text={'Cancelar'}
                            loading={cancelingEmails}
                            onClick={() => this.setState({ cancelingEmails: true }, () => this.getNotifyEmails())}
                        />
                    </Col>
                </Row>
                <Row>
                    <SectionTitle>Localização/direções</SectionTitle>
                    {configs.map(config => <Col lg={12} md={12} sm={24} xs={24}>
                        <HeaderSection>
                            <SectionSubTitle>{config?.local == 1 ? 'Solar da Levada' : config?.local == 2 ? 'Quinta Lago dos Cisnes' : ''}</SectionSubTitle>
                            <SectionAction>
                                <BaseButton
                                    type={"primary"}
                                    icon={"edit"}
                                    text={"Edit"}
                                    onClick={() =>
                                        history.push(`/management/configs/${config._id}?page=S6`)
                                    }
                                />
                            </SectionAction>
                        </HeaderSection>
                        <InfoSection
                            title={"Localização do espaço (link)"}
                            text={config?.venueLinkLocation}
                        />
                        <InfoSection
                            title={"Direções para o espaço"}
                            text={config?.venueDirections?.pt || ''}
                        />
                        <InfoSection
                            title={"Ponto de encontro para visitas (direções)"}
                            text={config?.visitMeetingPointDirections?.pt || ''}
                        />
                        <InfoSection
                            title={"Ponto de encontro para reuniões (direções)"}
                            text={config?.organizationMeetingPointDirections?.pt || ''}
                        />
                    </Col>)}
                </Row>

                <Row>
                    <SectionTitle style={{ marginTop: '3rem' }} >Prioridade dos Colaboradores de Atendimento</SectionTitle>

                    <TableFilterSection>
                        <Row gutter={24}>
                            <Col lg={8} md={8} sm={12} xs={24}>
                                <div className='settings-search'>
                                    <TextInput
                                        type="text"
                                        label="Pesquisa"
                                        placeholder="Pesquise pelo nome"
                                        input={{
                                            value: searchEmployee,
                                            onChange: event => this.setState({ searchEmployee: event.target.value })
                                        }}
                                        meta={{}}
                                    />
                                </div>
                            </Col>

                            <Col lg={16} md={16} sm={12} xs={24} className='graphic-col-btn'>
                                <div style={{ display: 'flex' }}>
                                    <BaseButton
                                        type={'default'}
                                        icon={'retweet'}
                                        text={'Editar Ordem'}
                                        onClick={() => this.setState({ orderModal: true })}
                                    />
                                    <span style={{ marginLeft: 5 }}>
                                        <BaseButton
                                            type={'primary'}
                                            icon={'plus'}
                                            text={'Adicionar Colaborador'}
                                            onClick={() => this.setState({ createOrderModal: true })}
                                        />
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </TableFilterSection>

                    <div className='table-responsive'>
                        <Table
                            columns={columnsEmployees}
                            // currentPage={currentPage}
                            // pageSize={pageSize}
                            // defaultPageSize={pageSize}
                            loading={loadingEmployees}
                            rows={searchEmployee.trim() === "" ? rowsEmployees : rowsEmployees.filter(f => f?.name.toLowerCase().includes(searchEmployee.toLowerCase()))}
                            showHeader={true}
                            emptyIcon="setting"
                            emptyText={'Não existem colaboradores'}
                            total={searchEmployee.trim() === "" ? totalEmployees : rowsEmployees.filter(f => f?.name.toLowerCase().includes(searchEmployee.toLowerCase())).length}
                            rowKey={'_id'}
                            hasPagination={false}
                        // handleChangePage={this.handleChangePage}
                        // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </div>
                </Row>

                <Row>
                    <SectionTitle style={{ marginTop: '3rem' }} >Tipos de Atendimento</SectionTitle>

                    <TableFilterSection>
                        <Row gutter={24}>
                            <Col lg={8} md={8} sm={12} xs={24}>
                                <div className='settings-search'>
                                    <TextInput
                                        type="text"
                                        label="Pesquisa"
                                        placeholder="Pesquise por tipo"
                                        input={{
                                            value: searchType,
                                            onChange: event => this.setState({ searchType: event.target.value })
                                        }}
                                        meta={{}}
                                    />
                                </div>
                            </Col>

                            <Col lg={16} md={16} sm={12} xs={24} className='setting-col-btn'>
                                <BaseButton
                                    type={'primary'}
                                    icon={'plus'}
                                    text={'Adicionar Tipo'}
                                    onClick={() => this.setAddForm()}
                                />
                            </Col>
                        </Row>
                    </TableFilterSection>

                    <div className='table-responsive'>
                        <Table
                            columns={columnsType}
                            // currentPage={currentPage}
                            // pageSize={pageSize}
                            // defaultPageSize={pageSize}
                            loading={loadingType}
                            rows={searchType.trim() === "" ? rowsType : rowsType.filter(f => f?.name.toLowerCase().includes(searchType.toLowerCase()))}
                            showHeader={true}
                            emptyIcon="setting"
                            emptyText={'Não existem entidades'}
                            total={searchType.trim() === "" ? totalType : rowsType.filter(f => f?.name.toLowerCase().includes(searchType.toLowerCase())).length}
                            rowKey={'_id'}
                            hasPagination={false}
                        // handleChangePage={this.handleChangePage}
                        // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </div>
                </Row>

                {orderModal &&
                    <OrderAttendingEmployees
                        open={orderModal}
                        loading={savingOrder}
                        onSubmit={this.onSubmitOrder}
                        closeModal={() => this.setState({ orderModal: false })}
                        initialValues={rowsEmployees}
                    />}

                {createOrderModal &&
                    <CreateOrderAttending
                        open={createOrderModal}
                        loading={addingOrder}
                        onSubmit={this.onAddOrder}
                        closeModal={() => this.setState({ createOrderModal: false })}
                    />}
            </React.Fragment>
        );
    }
}

export default withRouter(AttendingTable);