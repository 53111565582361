import React, { Component, Fragment } from 'react';
import { Button, Checkbox, Col, DatePicker, Icon, InputNumber, Modal, Popconfirm, Row, Tag, Tooltip } from 'antd';
import Table from '../../components/table/Table';
import {
    PageContainer,
    PageTitle,
    HeaderContainer,
    HeaderTitle,
    HeaderButtonsContainer,
    TableButton,
    TableFilterSection
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import moment from 'moment';
import { connect } from 'react-redux';
import { GetColorSystemByType } from '../../infra/requests/ColorSystemRequests';
import CurrencyComponent from '../../components/currency/CurrencyComponent';
import { CreateReservePrice, DeleteReservePrice, GetReservePriceByMonth, UpdateMultipleReservePrice, UpdateReservePrice } from '../../infra/requests/ReservePriceRequests';
import NumberInput from '../../components/inputs/NumberInput';
import debounce from 'lodash.debounce';
import { getUserId } from '../../infra/helpers/Helpers';
import { InputNumberField } from '../../components/inputs/InputStyles';
import SelectInput from '../../components/inputs/SelectInput';
import DateInput from '../../components/inputs/DateInput';
import Alert from "../../components/alert/Alert";
import MonthInput from '../../components/inputs/MonthInput';

const RemoveMessage = (
    <div>
        <div>Tem a certeza que quer remover este casamento? </div>
        <div>
            Toda a informação deste casamento será removida da plataforma e não
            conseguirá ser recuperada.
        </div>
        <div>
            Os noivos não receberão qualquer notificação sobre o acesso à plataforma.
        </div>
    </div>
);

const isPositive = /^[+]?([.]\d+|\d+([.]\d+)?)$/;
const isInteger = /(?<=\s|^)\d+(?=\s|$)/;

const fields = [
    { _id: 'minPax', name: 'PAX Mínimo' },
    { _id: 'minPrice', name: 'Preço PAX Mínimo' },
    { _id: 'maxPax', name: 'PAX Máximo' },
    { _id: 'maxPrice', name: 'Preço PAX Máximo' },
];

const locals = [
    { _id: 1, name: 'Solar da Levada' },
    { _id: 2, name: 'Quinta Lago dos Cisnes' }
];

class ReservePrice extends Component {
    state = {
        pageSize: 30,
        currentPage: 1,
        loading: false,
        currentMonth: moment(),
        monthList: [],
        selectRowIndex: null,
        selectedRow: null,

        columns: [
            {
                title: '',
                key: 'select',
                render: data => {
                    const { selectedRow, selectedRowIndex, rows } = this.state;

                    return selectedRow !== null && selectedRow.dateTimestamp === data.dateTimestamp ?
                        <div className="sc-dxgOiQ fWHHkk" style={{ textAlign: 'center' }}>
                            <Checkbox
                                checked={data.selected}
                                onChange={(e) => {
                                    data.selected = e.target.checked;
                                    rows[selectedRowIndex] = data;
                                    this.setState({ rows, selectedRow: rows[selectedRowIndex] });
                                }}>
                            </Checkbox>
                        </div> :
                        <div className="sc-dxgOiQ fWHHkk" style={{ textAlign: 'center' }}>
                            <Checkbox checked={data.selected}></Checkbox>
                        </div>
                }
            },
            {
                title: 'Dia',
                render: value => {
                    return moment.utc(value.date).format('DD')
                }
            },
            {
                title: 'Dia',
                render: value => {
                    moment.locale('pt');
                    return moment(value.date).format('ddd')
                }
            },
            {
                title: 'Mínimo PAX',
                render: data => {
                    const { selectedRow, selectedRowIndex, rows } = this.state;

                    return selectedRow !== null && selectedRow.dateTimestamp === data.dateTimestamp ? <InputNumberField
                        type="number"
                        min={0}
                        step={1}
                        value={data.minPax}
                        onChange={(val: any) => {
                            if (val !== '' && isInteger.test(val)) {
                                data.minPax = val;
                                rows[selectedRowIndex] = data;
                                this.setState({ rows, selectedRow: rows[selectedRowIndex] }, () => this.updatePrice(rows[selectedRowIndex]));
                            }
                        }}
                    /> : <div>{data.minPax}</div>
                }
            },
            {
                title: 'Preço Min PAX',
                render: (data) => {
                    const { selectedRow, selectedRowIndex, rows } = this.state;

                    return selectedRow !== null && moment(selectedRow.date).startOf('day').isSame(moment(data.date).startOf('day')) ? <InputNumberField
                        type="number"
                        min={0}
                        step={1}
                        value={data.minPrice}
                        onChange={(val: any) => {
                            if (val !== '' && isPositive.test(val)) {
                                data.minPrice = val;
                                rows[selectedRowIndex] = data;
                                this.setState({ rows, selectedRow: rows[selectedRowIndex] }, () => this.updatePrice(rows[selectedRowIndex]));
                            }
                        }}
                    /> : <CurrencyComponent value={data.minPrice}></CurrencyComponent>
                }
            },
            {
                title: 'Máximo PAX',
                render: data => {
                    const { selectedRow, selectedRowIndex, rows } = this.state;

                    return selectedRow !== null && moment(selectedRow.date).startOf('day').isSame(moment(data.date).startOf('day')) ? <InputNumberField
                        type="number"
                        min={0}
                        step={1}
                        value={data.maxPax}
                        onChange={(val: any) => {
                            if (val !== '' && isInteger.test(val)) {
                                data.maxPax = val;
                                rows[selectedRowIndex] = data;
                                this.setState({ rows, selectedRow: rows[selectedRowIndex] }, () => this.updatePrice(rows[selectedRowIndex]));
                            }
                        }}
                    /> : <div>{data.maxPax}</div>
                }
            },
            {
                title: 'Preço Max PAX',
                render: data => {
                    const { selectedRow, selectedRowIndex, rows } = this.state;

                    return selectedRow !== null && moment(selectedRow.date).startOf('day').isSame(moment(data.date).startOf('day')) ? <InputNumberField
                        type="number"
                        min={0}
                        step={1}
                        value={data.maxPrice}
                        onChange={(val: any) => {
                            if (val !== '' && isPositive.test(val)) {
                                data.maxPrice = val;
                                rows[selectedRowIndex] = data;
                                this.setState({ rows, selectedRow: rows[selectedRowIndex] }, () => this.updatePrice(rows[selectedRowIndex]));
                            }
                        }}
                    /> : <CurrencyComponent value={data.maxPrice}></CurrencyComponent>
                }
            },
            {
                title: '',
                // width: '50px',
                render: data => (
                    <Tooltip title='Remover'>
                        <Icon type="delete" onClick={e => {
                            // e.stopPropagation();
                            this.deletePrice(data);
                        }} />
                    </Tooltip>
                )
            }
        ],
        rows: [],
        filters: {
            local: 1,
            month: '',
            startDate: moment(),
            endDate: moment().add(4, 'month')
        },
        edit: {
            startDate: null,
            endDate: null,
            value: 0
        },

        colors: {
            colorSys1: null,
            colorSys2: null
        },
    };

    componentDidMount = async () => {
        this.updateDataTable();
        this.getLocalColors();
    };

    getLocalColors = async () => {
        const resColorSys1: any = await GetColorSystemByType(4);
        const colorSys1 = resColorSys1.success && resColorSys1.data ? resColorSys1.data.color : '#f7b5d6';
        const resColorSys2: any = await GetColorSystemByType(5);
        const colorSys2 = resColorSys2.success && resColorSys2.data ? resColorSys2.data.color : '#b6d7a9';
        this.setState({ colors: { colorSys1, colorSys2 } });
    }

    updateDataTable = async (updateSelection: boolean = false) => {
        this.setState({ loading: true });
        try {
            const { filters } = this.state;
            const result = await GetReservePriceByMonth(filters);
            // console.warn('Res', result);

            this.setState({
                rows: result.data.map(m => ({ ...m, selected: false })),
                monthList: [...new Set(result.data.map(f => f.month))],
                loading: false
            }, () => updateSelection ? this.updateSelection() : null);
        } catch (e) {
            this.setState({ loading: false });
            console.error(e);
        }
    };

    handleFilterChange = filters => {
        this.setState({ filters: JSON.parse(filters) }, this.updateDataTable);
    };

    deletePrice = async (row: any) => {
        const { selectedRow, selectedRowIndex, rows } = this.state;

        if (selectedRow !== null && moment.utc(selectedRow.date).startOf('day').isSame(moment.utc(row.date).startOf('day'))) {

            if (selectedRow.id) {
                const result = await DeleteReservePrice(selectedRow.id);
                // console.warn('REs', result);
                if (result.data.ok == 1) {
                    rows[selectedRowIndex].id = null;
                    rows[selectedRowIndex]._id = null;
                    rows[selectedRowIndex].minPax = 0;
                    rows[selectedRowIndex].minPrice = 0;
                    rows[selectedRowIndex].maxPax = 0;
                    rows[selectedRowIndex].maxPrice = 0;
                }
            } else {
                rows[selectedRowIndex].id = null;
                rows[selectedRowIndex]._id = null;
                rows[selectedRowIndex].minPax = 0;
                rows[selectedRowIndex].minPrice = 0;
                rows[selectedRowIndex].maxPax = 0;
                rows[selectedRowIndex].maxPrice = 0;
            }
            this.setState({ rows });
        }

    }

    updatePrice = debounce(async (e: any) => {
        const { rows } = this.state;
        e.user = getUserId();
        const res = e.id ? await UpdateReservePrice(e) : await CreateReservePrice(e);
        const index = rows.findIndex((f: any) => moment(f.date).isSame(moment(res.data.date)));
        if (index > -1) {
            rows[index]._id = res.data._id;
            rows[index].id = res.data.id;
            this.setState({ rows });
        }
    }, 500)

    updateMultiplePrices = async () => {
        const { edit, filters } = this.state;
        let { rows } = this.state;
        const rowsNotSelected = rows.filter(f => !f.selected);
        const rowsSelected = rows.filter(f => f.selected);

        if (!edit.field || edit.field === '' || edit.field.length === 0) {
            Alert.new({
                type: "error",
                title: "Atenção",
                text: "É necessário selecionar pelo menos um campo a alterar."
            });
            return;
        }

        if (!edit.startDate && !edit.endDate && rowsSelected.length === 0) {
            Alert.new({
                type: "error",
                title: "Atenção",
                text: "É necessário selecionar uma data ou selecionar linhas!"
            });
            return;
        }

        const result = await UpdateMultipleReservePrice(edit, filters, rowsNotSelected, getUserId());
        // console.warn('Res Multiple', result);

        this.updateDataTable(true);
    }

    getMonthName(month: number) {
        moment().locale('pt');
        return moment().set('month', month - 1).format('MMMM');
    }

    updateSelection() {
        const { edit, rows } = this.state;

        for (let index = 0; index < rows.length; index++) {
            const date = moment(rows[index].date).startOf('day');

            if (edit.startDate && edit.startDate.isValid() && edit.endDate && edit.endDate.isValid()) {
                if (date.isAfter(edit.startDate) && date.isBefore(edit.endDate) || date.isSame(edit.startDate) || date.isSame(edit.endDate)) {
                    rows[index].selected = true;
                } else rows[index].selected = false;
            } else if (edit.startDate && edit.startDate.isValid()) {
                if (date.isSameOrAfter(edit.startDate)) rows[index].selected = true;
                else rows[index].selected = false;
            } else if (edit.endDate && edit.endDate.isValid()) {
                if (date.isSameOrBefore(edit.endDate)) rows[index].selected = true;
                else rows[index].selected = false;
            } else rows[index].selected = false;
        }

        this.setState({ rows });
    }

    selectAllByMonth = (checked: boolean, month: number) => {
        const { rows } = this.state;

        for (let index = 0; index < rows.length; index++) {
            if (rows[index].month === month) rows[index].selected = checked;
        }
        this.setState({ rows });
    }

    // disabledStartDate = startValue => {
    //     const { filters } = this.state;
    //     if (!startValue || !filters.endDate) {
    //         return false;
    //     }
    //     return moment(startValue).isSameOrBefore(filters.endDate) && moment(startValue).isSameOrAfter(moment(filters.endDate).subtract(6, 'month')) ? false : true;
    // };

    // disabledEndDate = endValue => {
    //     const { filters } = this.state;
    //     if (!endValue || !filters.startDate) {
    //         return false;
    //     }
    //     return moment(endValue).isSameOrAfter(filters.startDate) && moment(endValue).isSameOrBefore(moment(filters.startDate).add(6, 'month')) ? false : true;
    // };

    render() {
        // const { history } = this.props;
        const { columns, loading, rows, monthList, filters, colors, edit } = this.state;

        return (
            <React.Fragment>
                <HeaderContainer>
                    <HeaderTitle buttons={2}>
                        <PageTitle>Preços das Reservas</PageTitle>
                        Lista de Preços
                    </HeaderTitle>
                    <HeaderButtonsContainer buttons={2}>
                        <BaseButton
                            type={'primary'}
                            icon={'right'}
                            text={'Mês Seguinte'}
                            onClick={() => {
                                filters.startDate = moment(filters.startDate).add(1, 'month').toISOString();
                                filters.endDate = moment(filters.endDate).add(1, 'month').toISOString();
                                this.setState({ filters }, this.updateDataTable);
                            }}
                        />
                        <BaseButton
                            type={'primary'}
                            icon={'left'}
                            text={'Mês Anterior'}
                            onClick={() => {
                                filters.startDate = moment(filters.startDate).subtract(1, 'month').toISOString();
                                filters.endDate = moment(filters.endDate).subtract(1, 'month').toISOString();
                                this.setState({ filters }, this.updateDataTable);
                            }}
                        />
                    </HeaderButtonsContainer>
                </HeaderContainer>
                <PageContainer buttons={2}>
                    {/* <ReserveFilters
                        loading={loading}
                        local={filters.local}
                        startDate={filters.startDate}
                        endDate={filters.endDate}
                        field={filters.field}
                        queryChange={this.handleFilterChange}
                    /> */}

                    <TableFilterSection>
                        <Row gutter={20}>
                            <Col xs={24} sm={12} md={5} lg={4}>
                                <SelectInput
                                    label="Local"
                                    disabled={loading}
                                    allowClear={false}
                                    placeholder="Escolher local"
                                    data={locals}
                                    input={{
                                        value: filters.local,
                                        onChange: value => {
                                            filters.local = value;
                                            this.setState({ filters }, this.updateDataTable);
                                        }
                                    }}
                                    meta={{ valid: true }}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={4} lg={3}>
                                <MonthInput
                                    label="Mês"
                                    placeholder="Escolher mês"
                                    input={{
                                        value: filters.month,
                                        onChange: value => {
                                            filters.month = value;
                                            const date = moment(value, 'MM/YYYY');
                                            if (date.isValid()) {
                                                filters.startDate = moment().set('month', date.month()).startOf('month');
                                                filters.endDate = moment().set('month', date.month()).endOf('month');
                                            } else {
                                                filters.startDate = moment().startOf('month');
                                                filters.endDate = moment().add(4, 'month').endOf('month');
                                            }
                                            this.setState({ filters }, this.updateDataTable);
                                        }
                                    }}
                                    allowClear={true}
                                    meta={{ valid: true }}
                                />
                            </Col>
                        </Row>
                    </TableFilterSection>

                    <TableFilterSection className="reserve-edit">
                        <Row gutter={20}>
                            <Col xs={24} sm={12} md={5} lg={4}>
                                <DateInput
                                    label="Data Inicial"
                                    disabled={loading}
                                    placeholder="Escolher data inicial"
                                    // disabledDate={this.disabledStartDate}
                                    input={{
                                        value: edit.startDate,
                                        onChange: value => {
                                            edit.startDate = moment(value).isValid() ? moment(value).startOf('day') : null;
                                            this.setState({ edit }, this.updateSelection);
                                        }
                                    }}
                                    meta={{ valid: true }}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={5} lg={4}>
                                <DateInput
                                    label="Data Final"
                                    disabled={loading}
                                    placeholder="Escolher data final"
                                    // disabledDate={this.disabledEndDate}
                                    input={{
                                        value: edit.endDate,
                                        onChange: value => {
                                            edit.endDate = moment(value).isValid() ? moment(value).endOf('day') : null;
                                            this.setState({ edit }, this.updateSelection);
                                        }
                                    }}
                                    meta={{ valid: true }}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={5} lg={8}>
                                <SelectInput
                                    label="Campos"
                                    mode='multiple'
                                    disabled={loading}
                                    allowClear
                                    placeholder="Escolher os campos"
                                    data={fields}
                                    input={{
                                        value: edit.field,
                                        onChange: value => {
                                            edit.field = value;
                                            this.setState({ edit });
                                        }
                                    }}
                                    meta={{ valid: true }}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={4} lg={2}>
                                <NumberInput
                                    label="Valor"
                                    min={0}
                                    step={1}
                                    input={{
                                        value: edit.value,
                                        onChange: value => {
                                            edit.value = value;
                                            this.setState({ edit });
                                        }
                                    }}
                                    placeholder="Insira o valor"
                                    meta={{}}
                                    type="number"
                                />
                            </Col>
                            <Col xs={24} sm={12} md={4} lg={2}>
                                <div className="sc-kEYyzF hppttv">
                                    <div className="sc-eHgmQL fRMxMJ"></div>
                                    <BaseButton
                                        type={'primary'}
                                        icon={'check'}
                                        text={'Aplicar'}
                                        onClick={() => this.updateMultiplePrices()}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </TableFilterSection>

                    <Row gutter={12} style={{ overflow: 'auto', display: 'flex' }}>
                        {
                            monthList.map((month) => {
                                const data = rows.filter((r: any) => r.month === month);

                                return <Col xs={24} md={monthList.length > 2 ? 12 : (24 / monthList.length)}>
                                    <div className='reserve-price-month' style={{ backgroundColor: filters.local === 1 ? colors.colorSys1 : filters.local === 2 ? colors.colorSys2 : '' }}>
                                        <b>{this.getMonthName(month)}</b>
                                        <Checkbox
                                            checked={data.checked}
                                            onChange={(e) => this.selectAllByMonth(e.target.checked, month)}>
                                        </Checkbox>
                                    </div>
                                    <Table
                                        columns={columns}
                                        currentPage={0}
                                        pageSize={data.length}
                                        loading={loading}
                                        rows={data}
                                        showHeader={true}
                                        emptyIcon="team"
                                        emptyText={'There are no weddings'}
                                        total={data.length}
                                        rowKey={'date'}
                                        hasPagination={false}
                                        onPressRow={(row) => {
                                            this.setState({
                                                selectedRowIndex: rows.findIndex((f: any) => moment(f.date).isSame(moment(row.date), 'day')),
                                                selectedRow: row
                                            });
                                        }}
                                    />
                                </Col>
                            })
                        }
                    </Row>
                </PageContainer>
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(ReservePrice);
