import { notification } from "antd";

export default class Alerts {
  static new = ({ type, title, text }) => {
    notification[type]({
      className: type === 'success' ? 'successAlert' : type === 'info' ? 'infoAlert' : type === 'warning' ? 'warningAlert' : type === 'errorTime' ? 'errorTimeAlert' : 'errorAlert',
      message: title,
      description: text
    });
  };
}