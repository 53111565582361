import client from '../config/AxiosConfig';

export const CreateCategory = async (data) =>
  client.put(`/employee_categories/create`, data);

export const GetCategory = async id =>
  client.get(`/employee_categories/find/${id}`);

export const GetCategories = async () =>
  client.get(`/employee_categories/findAll`);

export const UpdateCategory = async (data) =>
  client.post(`/employee_categories/update`, data);

export const DeleteCategory = async id =>
  client.delete(`/employee_categories/remove/${id}`);