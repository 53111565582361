import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { BaseForm, SpinLoading } from '../../styles/BasicStyles';
import PropTypes from 'prop-types';
import DraftInput from '../../components/inputs/DraftInput';
import SelectInput from '../../components/inputs/SelectInput';
import { isCurrentUserAdmin } from '../../infra/helpers/Helpers';
import { GetAttendingEmployees } from '../../infra/requests/UsersRequests';
import DateInput from '../../components/inputs/DateInput';

const validations = (values) => {
    let errors = {};

    errors = FormValidator.make({
        confirmationDate: 'required',
    })(values);

    return errors;
};

let ReserveDateModal = ({
    isOpen,
    saving,
    handleSubmit,
    initialValues,
    onSubmit,
    onClose,
    reserveForm
}) => {

    useEffect(() => {
    }, [isOpen]);

    return (
        <Modal
            visible={isOpen}
            title='Detalhes da Reserva'
            maskClosable
            onCancel={onClose}
            footer={[
                <Button
                    key='cancel'
                    type='default'
                    onClick={onClose}>
                    Cancelar
                </Button>,
                <Button
                    key='submit'
                    type='primary'
                    loading={saving}
                    onClick={handleSubmit(onSubmit)}>
                    Gravar
                </Button>
            ]}>
            <BaseForm onSubmit={handleSubmit(onSubmit)}>
                <Field
                    component={DateInput}
                    name="confirmationDate"
                    label="Data de Confirmação"
                    placeholder="Selecione a data"
                    allowClear={false}
                />
            </BaseForm>
        </Modal>
    );
};

ReserveDateModal = reduxForm({
    form: 'reserve_form',
    enableReinitialize: true,
    validate: validations,
})(ReserveDateModal);

const selector = formValueSelector('reserve_form');

const mapStateToProps = state => ({
    reserveForm: {
        confirmationDate: selector(state, 'confirmationDate'),
    }
});

ReserveDateModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(ReserveDateModal)