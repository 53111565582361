import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import BaseButton from "../../components/buttons/BaseButton";
import {
  FormContainer,
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  BaseForm,
  HeaderButtonsContainer,
  SpinLoading
} from "../../styles/BasicStyles";
import { AddDocument } from "../../infra/requests/ReservationsRequests";
import { withLocalize } from "react-localize-redux";
import FormValidator from "../../infra/services/validations/FormValidator";
import TextInput from "../../components/inputs/TextInput";
import SelectInput from "../../components/inputs/SelectInput";
import YearInput from "../../components/inputs/YearInput";

const validations = FormValidator.make({
  local: "required",
  text: "required|languages",
  link_text: "required|languages",
  link_url: "required"
});

class ManageReservationPage extends Component {
  state = {
    loading: false,
    ready: true
  };

  onSubmit = async values => {
    try {
      this.setState({ loading: true });
      const { history } = this.props;
      const { success } = await AddDocument(values);
      if (success) return history.push("/management/reservations");
      this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    try {
      const { history } = this.props;
      return history.push("/management/reservations");
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit, locals } = this.props;
    const { ready, loading } = this.state;
    if (!ready || loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>New Reservation Date</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={SelectInput}
              name={"local"}
              label={"Local"}
              placeholder={"Escolha o Local"}
              type="select"
              data={locals}
              dataKey={"_id"}
              dataLabel={"name"}
            />
            <Field
              component={YearInput}
              name="year"
              label="Ano *"
              placeholder="Escolha o ano"
            />
            <Field
              component={TextInput}
              name="document"
              type="text"
              label={"URL do documento *"}
              placeholder={"Insira URL do documento"}
            />
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageReservationPage = reduxForm({
  form: "manage_reviews_form",
  validate: validations
})(ManageReservationPage);

const mapStateToProps = state => ({
  locals: state.locals
});

export default withLocalize(connect(mapStateToProps)(ManageReservationPage));
