// @flow
import React, { useState, useEffect, Fragment, useRef } from 'react';
import Table from '../../components/table/Table';
import { PageContainer, PageTitle, HeaderContainer, HeaderButtonsContainer, HeaderTitle, SpinLoading, SpinLoadingCalendar } from '../../styles/BasicStyles';
import { Row, Col, Popconfirm, Icon, Tag, Avatar, Breadcrumb, DatePicker, Checkbox } from 'antd';
import { GetAllColorSystem } from '../../infra/requests/ColorSystemRequests';
import { GetAllWeddingsByUser, GetAllWeddings, GetAllWeddingsCalendarByUser } from '../../infra/requests/WeddingRequests';
import { GetEmployeeAndCompanyRest } from '../../infra/requests/UnavailableRequests';
import BaseButton from '../../components/buttons/BaseButton';
import { withRouter } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import Alert from "../../components/alert/Alert";
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { getCurrentUser, getUserId, isCurrentUserChief, isCurrentUserEmployee, isCurrentUserHumanResources } from '../../infra/helpers/Helpers';
import { GetEmployeeByUser } from '../../infra/requests/EmployeeRequests';

// main component
let EmployeeCalendar = (props): React$Element<React$FragmentType> => {

    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [currentEmployee, setCurrentEmployee] = useState(null);
    const [defaultDate, setDefaultDate] = useState(null);
    const [colorSystem, setColorSystem] = useState([]);
    const [events, setEvents] = useState([]);
    const [searchStartDate, setSearchStartDate] = useState(moment().startOf('month'));
    const [searchEndDate, setSearchEndDate] = useState(moment().endOf('month'));
    const [searchSpecificDate, setSearchSpecificDate] = useState(false);

    const calendarComponentRef = useRef();
    const {
        dispatch
    } = props;

    const { history } = props;

    useEffect(() => {
        const localUser = getCurrentUser();
        setCurrentUser(localUser);
        getCurrentEmployee(localUser)
    }, [])

    useEffect(() => {
        let calendarApi = calendarComponentRef.current.getApi();
        if (defaultDate) {
            calendarApi.changeView("timeGridDay");
            calendarApi.gotoDate(defaultDate.format('YYYY-MM-DD'));
        } else {
            calendarApi.changeView("dayGridMonth");
            calendarApi.gotoDate(moment(new Date()).format('YYYY-MM-DD'));
        }
    }, [defaultDate])

    const getAllColorSystem = async () => {
        const result = await GetAllColorSystem();
        setColorSystem(result.data)
    }

    const getCurrentEmployee = async (localUserObj) => {
        const resEmployee = isCurrentUserChief() || isCurrentUserEmployee() ? await GetEmployeeByUser(localUserObj._id) : null;
        setCurrentEmployee(resEmployee && resEmployee.data ? resEmployee.data : null);
        getAllColorSystem();
    }

    useEffect(() => {
        if (colorSystem.length > 0)
            getAllEvents();
    }, [colorSystem])

    useEffect(() => {
        if (colorSystem.length > 0) getAllEvents();
    }, [searchStartDate, searchEndDate, searchSpecificDate])

    const getEventColor = (weddingInfo, empWeddingTeam, teamPublished) => {
        let color;
        // console.warn('WEdding', new Date(weddingInfo.wedding.date).toDateString());
        // console.warn('empWeddingTeam', empWeddingTeam);
        // console.warn('teamPublished', teamPublished);
        // console.warn('payment', weddingInfo.payment);
        if (teamPublished && empWeddingTeam.length > 0) {
            if (moment(new Date()).isAfter(new Date(weddingInfo.wedding.date))) {
                if (weddingInfo.payment && weddingInfo.payment.length > 0) {
                    color = colorSystem.find(x => x.type == 2).color;
                } else {
                    color = colorSystem.find(x => x.type == 1).color;
                }
            } else {
                color = weddingInfo.wedding && weddingInfo.wedding.isWeddingOutOfInterval == true ? colorSystem.find(x => x.type == 6).color : weddingInfo.wedding.wedding_place == 1 ? colorSystem.find(y => y.type == 4).color : colorSystem.find(y => y.type == 5).color;
            }
        } else {
            color = weddingInfo.wedding.wedding_place == 1 ? colorSystem.find(y => y.type == 4).color : colorSystem.find(y => y.type == 5).color;
        }
        return color;
    }

    const getAllEvents = async () => {
        setLoading(true);
        // const result = isCurrentUserHumanResources() ? await GetAllWeddings() : await GetAllWeddingsByUser(getUserId());
        let filter = { calendar: true, startDate: searchStartDate, endDate: searchEndDate, specificDate: searchSpecificDate };
        const result = await GetAllWeddingsCalendarByUser(getUserId(), JSON.stringify(filter));
        // console.warn('Reuslt', result);

        let weddings = result.success && result.data ? result.data.sort((a, b) => (a.wedding_place > b.wedding_place) ? 1 : -1) : [];
        let evts = [];
        //console.log('Casamentos', weddings)
        for (let i = 0; i < weddings.length; i++) {
            // let x = isCurrentUserHumanResources() ? weddings[i] : weddings[i].wedding;
            // let p = isCurrentUserHumanResources() ? undefined : weddings[i]?.payment;
            let x = weddings[i].wedding;
            const teamPublished = weddings[i].teamComplete && weddings[i].teamComplete.length > 0 && weddings[i].teamComplete.filter(f => f.published).length > 0 ? true : false;
            const empWeddingTeam = weddings[i].teamComplete && weddings[i].teamComplete.length > 0 ? weddings[i].teamComplete.filter(f => f.employee.id === currentEmployee?.id) : [];
            const color = getEventColor(weddings[i], empWeddingTeam, teamPublished);
            const empInterested = weddings[i].interest && weddings[i].interest.length > 0 ? true : false;

            const title = teamPublished && empWeddingTeam.length > 0 ? 'Selecionado' : teamPublished && empWeddingTeam.length === 0 ? 'Não Selecionado' : empInterested ? 'Interessado' : '';

            let obj = {
                id: x._id,
                title: title,//`${weddings[i].wedding.bride?.name} & ${weddings[i].wedding.groom?.name}`,
                allDay: true,
                start: moment(x.date).startOf('day').toDate(),
                end: moment(x.date).endOf('day').toDate(),
                color: color,
                textColor: x.isWeddingOutOfInterval == true ? '#303030' : null,
                borderColor: color,
                display: 'block',
                className: 'hideTime',
                type: 1
            }
            evts.push(obj);
        }
        const result2 = await GetEmployeeAndCompanyRest(currentUser._id);
        // console.log('rest', result2)
        for (let i = 0; i < result2.data.length; i++) {
            let obj = {
                id: result2.data[i]._id,
                title: `${result2.data[i].companyRest ? 'Indisponível' : 'Dia de Descanso'} ${result2.data[i]?.weddingPlace ? result2.data[i]?.weddingPlace === 2 ? '(Quinta Lago dos Cisnes)' : '(Solar da Levada)' : ''}`,
                allDay: result2.data[i].allDay,
                start: moment(result2.data[i].startDate).toDate(),
                end: moment(result2.data[i].endDate).toDate(),
                color: result2.data[i].companyRest ? colorSystem.find(x => x.type == 7).color : colorSystem.find(x => x.type == 3).color,
                display: 'block',
                className: result2.data[i].allDay ? 'hideTime' : '',
                type: 2
            }
            evts.push(obj);
        }
        setEvents(evts);
        setLoading(false);
    }

    const handleEventClick = (e) => {
        if (e.event.backgroundColor == colorSystem.find(x => x.type == 3).color) { //indisponível

        } else { //casamento
            history.push(`/wedding/${e.event.id}`);
        }
    }

    return (
        <React.Fragment>
            <HeaderContainer>
                <HeaderTitle buttons={1}>
                    <PageTitle>Calendário</PageTitle>
                </HeaderTitle>
                <HeaderButtonsContainer buttons={1}>
                    <BaseButton
                        type="primary"
                        icon="calendar"
                        text="Dias indisponíveis"
                        block
                        onClick={() => { history.push('/rest-days') }}
                    />
                </HeaderButtonsContainer>
            </HeaderContainer>

            <PageContainer id="pageContainerCalendarAdmin" buttons={1}>
                <Row style={{ marginBottom: 5 }}>
                    <Col xs={20} sm={10} md={5} lg={4} style={{ textAlign: 'left' }}>
                        <DatePicker className='mobile-full-width' value={defaultDate} format={'DD/MM/YYYY'} placeholder='Selecione Data' onChange={(e) => {
                            //console.log('e', e);
                            if (e === null) {
                                setDefaultDate(null);
                                setSearchSpecificDate(false);
                            } else {
                                setDefaultDate(e);
                                setSearchSpecificDate(true);
                                setSearchStartDate(e);
                                setSearchEndDate(e);
                            }
                        }} />
                    </Col>
                    <Col xs={4} sm={14} md={19} lg={20} style={{ textAlign: 'right', paddingTop: 5 }}>
                        <Icon type="unordered-list" style={{ fontSize: '24px', cursor: 'pointer' }} onClick={() => { history.push('/employee-weddings') }} />
                    </Col>
                </Row>
                <Row>
                    <div id="calendar" style={{ position: 'relative' }}>
                        {loading === true ? <SpinLoadingCalendar /> : null}
                        <FullCalendar
                            initialView="dayGridMonth"
                            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
                            handleWindowResize={true}
                            themeSystem="default"
                            height="calc(100vh - 260px)"
                            allDaySlot={true}
                            locale="pt"
                            buttonText={{
                                today: 'Hoje',
                                month: 'Mês',
                                week: 'Semana',
                                day: 'Dia',
                                list: 'Lista',
                                prev: 'Anterior',
                                next: 'Seguinte',
                            }}
                            headerToolbar={{
                                left: 'prev,today,next',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay', //listMonth
                            }}
                            editable={false}
                            selectable={true}
                            droppable={true}
                            events={events}
                            datesSet={($event) => {
                                //console.warn('Dateset', $event);
                                const start = new Date($event.start);
                                const end = new Date($event.end);
                                setEvents([]);
                                setSearchStartDate(moment.utc(start.toUTCString()));
                                setSearchEndDate(moment.utc(end.toUTCString()));
                            }}
                            //eventsSet={($event) => {console.warn('eventsSet', $event)}}
                            ref={calendarComponentRef}
                        //    eventClick={handleEventClick}
                        // dateClick={handleDateClick}
                        // drop={handleDrop}
                        />
                    </div>
                </Row>
                <Row style={{ marginTop: 10, display: 'inline-flex' }}>
                    {colorSystem.map((x) => {
                        return [3, 4, 5, 6, 7, 1, 2].includes(x.type) ?
                            <Col style={{ margin: 5 }}><div style={{ display: 'inline-flex', userSelect: 'none' }}><div style={{ width: 25, height: 25, margin: 5, backgroundColor: x.color, borderRadius: 3, border: '1px solid #c2c2c2' }}></div><span style={{ margin: 'auto 0px', fontWeight: 'bold' }}>{x.description}</span></div></Col>
                            : null
                    })}
                </Row>
            </PageContainer>
        </React.Fragment>
    );
};

export default withRouter(EmployeeCalendar);