import React, { Component, Fragment } from 'react';
import Table from '../../components/table/Table';
import {
    HeaderButtonsContainer,
    TableButton,
    SectionTitle,
    TableFilterSection
} from '../../styles/BasicStyles';
import { Row, Col, Popconfirm, Icon, Checkbox } from 'antd';
import { withLocalize } from 'react-localize-redux';
import BaseButton from '../../components/buttons/BaseButton';
import SelectInput from '../../components/inputs/SelectInput';
import { GetSettingsByType } from '../../infra/requests/SettingRequests';
import { GetEmployeePriceByEmployee } from '../../infra/requests/EmployeePriceRequests';
import { GetSubcategories } from '../../infra/requests/SubcategoryRequests';
import { GetPriceBySubcategory } from '../../infra/requests/PriceRequests';
import Alert from "../../components/alert/Alert";
import NumberInput from '../../components/inputs/NumberInput';
import moment from 'moment';
import Alerts from '../../components/alert/Alert';
import { isCurrentUserAdmin } from '../../infra/helpers/Helpers';
import { ResetEmployeePriceForTrainee } from '../../infra/requests/EmployeeRequests';

const RemoveMessage = (
    <div>
        <div>Tem a certeza que quer esta subcategoria? </div>
    </div>
);


class EmployeePrice extends Component {
    state = {
        pageSize: 15,
        currentPage: 1,
        loading: false,
        search: '',
        employee: null,
        employeePrices: {
            addEdit: [],
            delete: []
        },
        addSubcategoryPrices: [],
        subcategories: [],
        unitPrices: [],
        columns: [
            {
                title: ' ',
                key: 'select',
                width: '30px',
                render: data => {
                    return <div className="sc-dxgOiQ fWHHkk" style={{ textAlign: 'center' }}>
                        <Checkbox
                            checked={data.checked}
                            onChange={(e) => {
                                let element = data;
                                element.checked = !element.checked;
                                let elements = this.state.rows;
                                let index = elements.findIndex(x => x.employeePrice?._id === element?.employeePrice?._id);
                                if (index > -1) {
                                    elements[index] = element;
                                    this.setState({ rows: elements })
                                }
                            }}>
                        </Checkbox>
                    </div>;
                }
            },
            {
                title: 'Subcategoria',
                key: 'subcategory',
                render: data => (
                    <SelectInput
                        allowClear
                        placeholder="Escolher a subcategoria"
                        data={this.state.subcategories}
                        input={{
                            value: data.employeePrice?.subcategory?.name, onChange: event => this.onSelectSubcategoryChange(event, data)
                        }}
                        meta={{ valid: true }}
                    />
                )
            },
            {
                title: 'Unidade',
                key: 'unit',
                render: data => (
                    <SelectInput
                        allowClear
                        placeholder="Escolher a unidade"
                        data={this.state.unitPrices}
                        input={{
                            value: data.employeePrice?.setting?.name, onChange: event => this.onSelectUnitChange(event, data)
                        }}
                        meta={{ valid: true }}
                    />
                )
            },
            {
                title: this.props.hasInsurance ? 'Valor c/Seguro' : 'Valor s/Seguro',
                key: 'value',
                width: '100px',
                render: data => (
                    <NumberInput
                        min={0}
                        step={.01}
                        onInput="validity.valid || (value = '')"
                        placeholder="Valor"
                        input={{
                            value: data.employeePrice.value, onChange: event => this.onValueChange(event, data)
                        }}
                        meta={{ valid: true }}
                    />
                )
            },
            {
                title: 'Acções',
                width: '120px',
                render: data => (
                    <Fragment>
                        {data.employeePrice.id.includes('id#FFFFF') ?
                            (<TableButton onClick={e => {
                                let { rows, employeePrices } = this.state;
                                rows = rows.filter(f => f.employeePrice.id !== data.employeePrice.id);
                                employeePrices.addEdit = employeePrices.addEdit.filter(f => f.id !== data.employeePrice.id);
                                this.setState({
                                    rows: rows,
                                    employeePrices: employeePrices,
                                    total: rows.length
                                });
                                this.props.addEditPrice(employeePrices.addEdit);
                            }}>
                                <Icon type="close" />
                                {' Cancelar'}
                            </TableButton>) :

                            (<TableButton onClick={e => e.stopPropagation()}>
                                <Popconfirm
                                    placement="topRight"
                                    title={RemoveMessage}
                                    okText="Remover"
                                    onConfirm={async () => { this.deleteEmployeePrice(data) }}>
                                    <Icon type="delete" />
                                    {' Remover'}
                                </Popconfirm>
                            </TableButton>)}
                    </Fragment>
                )
            }
        ],
        rows: [],
        total: 0,
        favoriteSubcategory: [],
        selectAllRows: false,
    };

    componentDidMount = async () => {
        this.setState({
            employee: this.props.employee,
            favoriteSubcategory: this.props.favoriteSubcategory
        }, async () => {
            await this.updateDataTable();
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.employee !== this.props.employee) {
            this.setState({ employee: this.props.employee }, async () => {
                await this.updateDataTable();
            });
        }

        if (prevProps.favoriteSubcategory !== this.props.favoriteSubcategory) {
            this.setState({ favoriteSubcategory: this.props.favoriteSubcategory }, async () => {
                await this.updateDataTableByFavoriteSubcategories();
            });
        }
    }

    /**
     * @description Update data on datatable
     */
    updateDataTable = async () => {
        this.setState({ loading: true });
        let { employee, subcategories, unitPrices, employeePrices } = this.state;

        const resultSub = await GetSubcategories();
        subcategories = resultSub?.success && resultSub?.data ? resultSub.data : [];
        // If employee trainee remove chief and coordinator subcategories
        if (employee?.user?.employeeTrainee) {
            subcategories = subcategories.filter(f => !f?.chief && !f?.coordinator);
        }

        const resultSetting = await GetSettingsByType(4);
        unitPrices = resultSetting?.success && resultSetting?.data ? resultSetting.data : [];

        this.setState({
            subcategories: subcategories.sort((a, b) => a?.name < b?.name ? -1 : 1),
            unitPrices: unitPrices.sort((a, b) => a?.name < b?.name ? -1 : 1)
        });

        try {
            const result = await GetEmployeePriceByEmployee(employee.id);
            const data = [];
            for (let index = 0; index < result.data.length; index++) {
                let element = result.data[index];

                const indexSubcategory = subcategories.findIndex(f => f?.id === element?.subcategory?.id);
                if (indexSubcategory > -1) subcategories.splice(indexSubcategory, 1);

                const dataElement = {
                    checked: false,
                    employeePrice: element,
                    prices: element.prices,
                };

                // const resultPrice = await GetPriceBySubcategory(element.subcategory.id);
                // dataElement.prices = resultPrice.data;

                data.push(dataElement);
                if (!element?._id) employeePrices.addEdit.push(element);
            }

            this.setState({
                rows: data?.length > 0 ? data : [],
                total: data.length || 0,
                subcategories: subcategories.sort((a, b) => a?.name < b?.name ? -1 : 1),
                employeePrices,
                loading: false
            }, () => this.updateDataTableByFavoriteSubcategories());
            this.props.addEditPrice(employeePrices.addEdit);
        } catch (e) {
            this.setState({ loading: false }, () => this.updateDataTableByFavoriteSubcategories());
            console.error(e);
        }
    }

    updateDataTableByFavoriteSubcategories = async () => {
        const { employee, favoriteSubcategory, subcategories } = this.state;
        let { employeePrices, rows } = this.state;

        if (favoriteSubcategory && Array.isArray(favoriteSubcategory) && favoriteSubcategory.length > 0) {
            for (let index = 0; index < favoriteSubcategory.length; index++) {
                const favoriteSub = favoriteSubcategory[index];

                // Check if this favorite subcategory isn't on datatable
                const indexTable = rows.findIndex(f => f?.employeePrice?.subcategory?._id && f?.employeePrice?.subcategory?._id === favoriteSub);
                if (indexTable === -1) {

                    const resultPrice = await GetPriceBySubcategory(favoriteSub);
                    // console.warn('resultPrice', resultPrice);

                    const element = {
                        id: 'id#FFFFF' + favoriteSub + moment().valueOf() + index,
                        subcategory: subcategories.find(f => f?._id && f._id == favoriteSub),
                        prices: resultPrice?.sucess ? (resultPrice?.data || []) : [],
                        employee,
                        setting: null,
                        value: null
                    };
                    const dataElement = {
                        employeePrice: element,
                        prices: element.prices,
                    };
                    rows.push(dataElement);
                    if (!element?._id) employeePrices.addEdit.push(element);
                }

            }
        }

        this.setState({
            rows,
            total: rows.length,
            subcategories: subcategories.sort((a, b) => a?.name < b?.name ? -1 : 1),
            employeePrices,
            loading: false
        });
        this.props.addEditPrice(employeePrices.addEdit);
    }

    /**
     * @description Show add row
     */
    setAddForm = () => {
        const { rows, employee, total, employeePrices } = this.state;

        let errorRows = false;
        employeePrices.addEdit.forEach(price => {
            if (price?.subcategory === null || price?.setting === null || price?.value === null) {
                errorRows = true;
            }
        });

        if (errorRows) {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Faltam dados nas subcategorias!'
            });
        } else {
            const price = {
                employeePrice: {
                    id: 'id#FFFFF' + moment().valueOf(),
                    subcategory: null,
                    setting: null,
                    employee: employee,
                    value: 0
                },
                prices: [],
            };

            rows.push(price);
            employeePrices.addEdit.push(price.employeePrice);
            this.setState({
                rows: rows,
                employeePrices: employeePrices,
                total: rows.length
            });
            this.props.addEditPrice(employeePrices.addEdit);
        }

    }


    onSelectSubcategoryChange = async (event, data) => {
        const { subcategories, rows, employee, employeePrices } = this.state;
        //console.warn('Data', data);

        if (event === '') {
            subcategories.push(data.employeePrice.subcategory);
        }

        const subcategory = subcategories.find(f => f?.id === event);

        data.employeePrice.subcategory = subcategory;
        data.employeePrice.setting = null;
        data.employeePrice.value = 0;

        if (subcategory) {
            const resultPrice = await GetPriceBySubcategory(subcategory.id);
            data.prices = resultPrice?.success ? (resultPrice?.data || []) : [];

            if (data?.setting) {
                const selectedPrice = resultPrice.data.find(f => f?.setting?.id === data?.setting?.id);
                data.employeePrice.value = selectedPrice ? employee?.insurance ? selectedPrice?.priceWithInsurance : selectedPrice?.priceWithoutInsurance : 0;
            }
        }

        const index = rows.findIndex(f => f?.employeePrice?.id === data?.employeePrice?.id);
        rows[index] = data;

        const addEditIndex = employeePrices.addEdit.findIndex(f => f?.id === data?.employeePrice?.id);
        if (addEditIndex > -1) employeePrices.addEdit[addEditIndex] = data?.employeePrice;
        else employeePrices.addEdit.push(data.employeePrice);

        this.setState({
            rows: rows,
            employeePrices: employeePrices,
            total: rows.length,
            subcategories: subcategories.sort((a, b) => a?.name < b?.name ? -1 : 1)
        });
        this.props.addEditPrice(employeePrices.addEdit);

        //const checkPlusSubcategory = rows.filter(f => f.employeePrice.subcategory?.id === subcategory.id);

        // if (checkPlusSubcategory.length > 0 && (data.employeePrice.subcategory === null || data.employeePrice.subcategory.id !== subcategory.id)) {
        //     Alert.new({
        //         type: "error",
        //         title: "Erro!",
        //         text: 'A subcategoria já foi selecionada! Selecione uma diferente.'
        //     });
        // } else if (data.employeePrice.subcategory === null || data.employeePrice.subcategory && data.employeePrice.subcategory.id !== subcategory.id) {
        //     data.employeePrice.subcategory = subcategory;
        //     data.employeePrice.setting = null;
        //     data.employeePrice.value = 0;

        //     const resultPrice = await GetPriceBySubcategory(subcategory.id);
        //     data.prices = resultPrice.data;

        //     if (data.setting) {
        //         const selectedPrice = resultPrice.data.find(f => f.setting.id === data.setting.id);
        //         data.employeePrice.value = selectedPrice ? employee.insurance ? selectedPrice.priceWithInsurance : selectedPrice.priceWithoutInsurance : 0;
        //     }

        //     const index = rows.findIndex(f => f.employeePrice.id === data.employeePrice.id);
        //     rows[index] = data;

        //     const addEditIndex = employeePrices.addEdit.findIndex(f => f.id === data.employeePrice.id);
        //     if (addEditIndex > -1) employeePrices.addEdit[addEditIndex] = data.employeePrice;
        //     else employeePrices.addEdit.push(data.employeePrice);

        //     this.setState({
        //         rows: rows,
        //         employeePrices: employeePrices,
        //         total: rows.length
        //     });
        //     this.props.addEditPrice(employeePrices.addEdit);
        // }

    }

    /**
     * @description Action trigered when select an unit price
     * @param {*} event 
     * @param {*} data 
     */
    onSelectUnitChange = async (event, data) => {
        const { rows, employee, unitPrices, addSubcategoryPrices, employeePrices } = this.state;

        if (event && event !== '') {
            const setting = unitPrices.find(f => f?.id === event);
            data.employeePrice.setting = setting;

            const price = data?.prices && Array.isArray(data?.prices) && data?.prices.length > 0
                ? data?.prices.find(f => f?.setting?.id ? f?.setting?.id === event : f?.setting === event)
                : null;
            if (price) {
                data.employeePrice.value = employee?.insurance ? price?.priceWithInsurance : price?.priceWithoutInsurance;
            } else {
                const total = addSubcategoryPrices.length;
                const price = {
                    id: `id#FFF${total + 1}`,
                    priceWithInsurance: 0,
                    priceWithoutInsurance: 0,
                    subcategory: data?.employeePrice?.subcategory,
                    setting: setting,
                    employee: null
                };
                data.employeePrice.value = 0;
                addSubcategoryPrices.push(price);
                this.setState({ addSubcategoryPrices: addSubcategoryPrices });
                this.props.addSubcategoryPrice(addSubcategoryPrices);
            }

            const index = rows.findIndex(f => f?.employeePrice?.id === data?.employeePrice?.id);
            rows[index] = data;

            const addEditIndex = employeePrices.addEdit.findIndex(f => f?.id === data?.employeePrice?.id);
            if (addEditIndex > -1) employeePrices.addEdit[addEditIndex] = data.employeePrice;
            else employeePrices.addEdit.push(data.employeePrice);

            this.setState({
                rows: rows,
                employeePrices: employeePrices,
                total: rows.length
            });
            this.props.addEditPrice(employeePrices.addEdit);
        }
    }

    onValueChange = (event, data) => {
        const { rows, addSubcategoryPrices, employee, employeePrices } = this.state;

        data.employeePrice.value = event;
        // const addSubPriceIndex = addSubcategoryPrices.findIndex(f => f.setting.id === data.employeePrice.setting.id && f.subcategory.id === data.employeePrice.subcategory.id);
        // if (addSubPriceIndex > -1) {
        //     const subPrice = addSubcategoryPrices[addSubPriceIndex];
        //     employee?.insurance ? subPrice.priceWithInsurance = event : subPrice.priceWithoutInsurance = event;
        //     addSubcategoryPrices[addSubPriceIndex] = subPrice;
        //     this.props.addSubcategoryPrice(addSubcategoryPrices);
        // }

        const index = rows.findIndex(f => f?.employeePrice?.id === data?.employeePrice?.id);
        rows[index] = data;

        const addEditIndex = employeePrices.addEdit.findIndex(f => f?.id === data?.employeePrice?.id);
        if (addEditIndex > -1) employeePrices.addEdit[addEditIndex] = data.employeePrice;
        else employeePrices.addEdit.push(data.employeePrice);

        this.setState({
            rows: rows,
            employeePrices: employeePrices,
            total: rows.length
        });
        this.props.addEditPrice(employeePrices.addEdit);
    }

    deleteEmployeePrice = (data) => {
        let { rows, employeePrices, subcategories } = this.state;

        if (data?.subcategory !== null && data?.subcategory !== undefined && data?.subcategory !== '') {
            const indexSub = subcategories.findIndex(s => s?.id === data?.id);
            if (indexSub === -1) subcategories.push(data.subcategory);
        }

        rows = rows.filter(f => f?.employeePrice?.id !== data?.employeePrice?.id);

        const addEditIndex = employeePrices.addEdit.findIndex(f => f?.id === data?.employeePrice?.id);
        if (addEditIndex > -1) employeePrices.addEdit = employeePrices.addEdit.filter(f => f?.id !== data?.employeePrice?.id);

        employeePrices.delete.push(data.employeePrice);

        this.setState({ rows: rows, employeePrices: employeePrices, total: rows.length });
        this.props.addEditPrice(employeePrices.addEdit);
        this.props.deletePrice(employeePrices.delete);
    }

    removeMany = () => {
        let { rows, employeePrices } = this.state;

        const selectedRows = rows.filter(f => f?.checked);
        if (selectedRows?.length == 0) {
            return Alert.new({
                type: "warning",
                title: "Atenção",
                text: "Selecione uma subcategoria."
            });
        }

        for (let index = 0; index < selectedRows?.length; index++) {
            const selectedRow = selectedRows[index];

            rows = rows.filter(f => f?.employeePrice?.id !== selectedRow?.employeePrice?.id);

            const addEditIndex = employeePrices.addEdit.findIndex(f => f?.id === selectedRow?.employeePrice?.id);
            if (addEditIndex > -1) employeePrices.addEdit = employeePrices.addEdit.filter(f => f?.id !== selectedRow?.employeePrice?.id);

            employeePrices.delete.push(selectedRow.employeePrice);
        }

        this.setState({ rows, employeePrices, total: rows.length });
        this.props.addEditPrice(employeePrices.addEdit);
        this.props.deletePrice(employeePrices.delete);
    }

    resetByTrainee = async () => {
        const { employee } = this.state;

        this.setState({ loading: true });
        const result = await ResetEmployeePriceForTrainee(employee.id);
        if (result?.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: 'Subcategorias redefinidas!'
            });
            this.updateDataTable();
        } else {
            this.setState({ loading: false });
        }
    }

    handleChangePage = currentPage => {
        this.setState({ currentPage }, this.updateDataTable);
    };

    handleChangeRowsPerPage = (currentSize, pageSize) => {
        this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
    };

    render() {
        const { loading, currentPage, pageSize } = this.state;
        const { columns, rows, total } = this.state;
        const { selectAllRows } = this.state;
        const { employee } = this.state;

        return (
            <React.Fragment >
                <Row gutter={24} className="employee-price-content" style={{ margin: '0 0 1rem 0' }}>
                    <SectionTitle style={{ width: 'fit-content' }}>Tabela de Subcategorias</SectionTitle>

                    <div style={{ width: 'min-content', position: 'absolute', top: 0, right: 0, display: 'inline-flex' }}>
                        {isCurrentUserAdmin() && employee?.user?.employeeTrainee && <BaseButton
                            type={'default'}
                            icon={'undo'}
                            text={'Redefinir'}
                            style={{ marginRight: 8 }}
                            onClick={() => this.resetByTrainee()}
                        />}
                        <BaseButton
                            type={'danger'}
                            icon={'delete'}
                            text={'Remover'}
                            style={{ marginRight: 8 }}
                            onClick={() => this.removeMany()}
                        />
                        <BaseButton
                            type={'primary'}
                            icon={'plus'}
                            text={'Adicionar'}
                            onClick={() => this.setAddForm()}
                        />
                    </div>
                </Row>

                <div className='payment-table-container'>
                    <div className="sc-dxgOiQ fWHHkk" style={{ position: 'absolute', top: 6, left: 8, zIndex: 49 }}>
                        <Checkbox
                            checked={selectAllRows}
                            onChange={(e) => {
                                rows.forEach(r => { r.checked = e.target.checked; });
                                this.setState({ selectAllRows: e.target.checked, rows });
                            }}>
                        </Checkbox>
                    </div>
                </div>
                <Table
                    columns={columns}
                    loading={loading}
                    rows={rows}
                    showHeader={true}
                    emptyIcon="setting"
                    emptyText={'Não existem preços'}
                    total={total}
                    rowKey={'_id'}
                    hasPagination={false}
                    noPadding={true}
                />
            </React.Fragment >
        );
    }
}

export default withLocalize(EmployeePrice);