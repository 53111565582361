import React from 'react';
import ReactSVG from 'react-svg';
import { Row, Col } from 'antd';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  DefaultSectionOption
} from '../../styles/BasicStyles';
import AboutIcon from '../../assets/icons/ic_about.svg';
import StyleIcon from '../../assets/icons/ic_style.svg';
import DayIcon from '../../assets/icons/ic_day.svg';
import CeremonyIcon from '../../assets/icons/ic_ceremony.svg';
import StaffIcon from '../../assets/icons/ic_staff.svg';
import GuestsIcon from '../../assets/icons/ic_guests.svg';
import FoodIcon from '../../assets/icons/ic_food.svg';
import DrinksIcon from '../../assets/icons/ic_drinks.svg';
import DecorationIcon from '../../assets/icons/ic_decoration.svg';
import PartyIcon from '../../assets/icons/ic_party.svg';
import UpgradeIcon from '../../assets/icons/ic_upgrade.svg';
import { hasPermissions } from '../../infra/helpers/Helpers';
import { PermissionsEnum } from '../../infra/helpers/Permissions';

const SectionsPage = ({ history }) => {
  return (
    <React.Fragment>
      <HeaderContainer>
        <HeaderTitle buttons={0}>
          <PageTitle>Secções do processo de casamento</PageTitle>
        </HeaderTitle>
      </HeaderContainer>
      <PageContainer buttons={0}>
        <Row gutter={[24, 24]}>
          <Col xs={6}>
            <DefaultSectionOption
              disabled={!hasPermissions([PermissionsEnum.SECTION_ABOUT_US])}
              onClick={() => hasPermissions([PermissionsEnum.SECTION_ABOUT_US]) ? history.push('/sections/about_us') : null}>
              <ReactSVG src={AboutIcon} />
              <span>Sobre nós</span>
            </DefaultSectionOption>
          </Col>
          <Col xs={6}>
            <DefaultSectionOption
              disabled={!hasPermissions([PermissionsEnum.SECTION_STYLE])}
              onClick={() => hasPermissions([PermissionsEnum.SECTION_STYLE]) ? history.push('/sections/style') : null}>
              <ReactSVG src={StyleIcon} />
              <span>O nosso estilo</span>
            </DefaultSectionOption>
          </Col>
          <Col xs={6}>
            <DefaultSectionOption
              disabled={!hasPermissions([PermissionsEnum.SECTION_DAY])}
              onClick={() => hasPermissions([PermissionsEnum.SECTION_DAY]) ? history.push('/sections/day') : null}>
              <ReactSVG src={DayIcon} />
              <span>O nosso dia</span>
            </DefaultSectionOption>
          </Col>
          <Col xs={6}>
            <DefaultSectionOption
              disabled={!hasPermissions([PermissionsEnum.SECTION_CEREMONY])}
              onClick={() => hasPermissions([PermissionsEnum.SECTION_CEREMONY]) ? history.push('/sections/ceremony') : null}>
              <ReactSVG src={CeremonyIcon} />
              <span>Cerimónia</span>
            </DefaultSectionOption>
          </Col>
          <Col xs={6}>
            <DefaultSectionOption
              disabled={!hasPermissions([PermissionsEnum.SECTION_STAFF])}
              onClick={() => hasPermissions([PermissionsEnum.SECTION_STAFF]) ? history.push('/sections/staff') : null}>
              <ReactSVG src={StaffIcon} />
              <span>Staff</span>
            </DefaultSectionOption>
          </Col>
          <Col xs={6}>
            <DefaultSectionOption
              disabled={!hasPermissions([PermissionsEnum.SECTION_GUEST])}
              onClick={() => hasPermissions([PermissionsEnum.SECTION_GUEST]) ? history.push('/sections/guests') : null}>
              <ReactSVG src={GuestsIcon} />
              <span>Convidados</span>
            </DefaultSectionOption>
          </Col>
          <Col xs={6}>
            <DefaultSectionOption
              disabled={!hasPermissions([PermissionsEnum.SECTION_FOOD])}
              onClick={() => hasPermissions([PermissionsEnum.SECTION_FOOD]) ? history.push('/sections/food') : null}>
              <ReactSVG src={FoodIcon} />
              <span>Food Selection</span>
            </DefaultSectionOption>
          </Col>
          <Col xs={6}>
            <DefaultSectionOption
              disabled={!hasPermissions([PermissionsEnum.SECTION_DRINK])}
              onClick={() => hasPermissions([PermissionsEnum.SECTION_DRINK]) ? history.push('/sections/drinks') : null}>
              <ReactSVG src={DrinksIcon} />
              <span>Drink Selection</span>
            </DefaultSectionOption>
          </Col>
          <Col xs={6}>
            <DefaultSectionOption
              disabled={!hasPermissions([PermissionsEnum.SECTION_DECOR])}
              onClick={() => hasPermissions([PermissionsEnum.SECTION_DECOR]) ? history.push('/sections/decoration') : null}>
              <ReactSVG src={DecorationIcon} />
              <span>Decoração</span>
            </DefaultSectionOption>
          </Col>
          <Col xs={6}>
            <DefaultSectionOption
              disabled={!hasPermissions([PermissionsEnum.SECTION_PARTY])}
              onClick={() => hasPermissions([PermissionsEnum.SECTION_PARTY]) ? history.push('/sections/party') : null}>
              <ReactSVG src={PartyIcon} />
              <span>Party selection</span>
            </DefaultSectionOption>
          </Col>
          <Col xs={6}>
            <DefaultSectionOption
              disabled={!hasPermissions([PermissionsEnum.EDIT_SECTION_UPGRADE])}
              onClick={() => hasPermissions([PermissionsEnum.EDIT_SECTION_UPGRADE]) ? history.push('/sections/upgrades') : null}>
              <ReactSVG src={UpgradeIcon} />
              <span>Upgrades</span>
            </DefaultSectionOption>
          </Col>
        </Row>
      </PageContainer>
    </React.Fragment>
  );
};

export default SectionsPage;
