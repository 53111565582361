import React from 'react';
import {connect} from 'react-redux';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import {ResponsePill, ColorBox, ImageBox, ColorName} from '../SectionStyles';

const ColorOption = ({color, colours = []}) => {
  const option = colours.find(opt => opt._id === color);
  if (option) {
    const name = TranslateValue(option.name);
    return (
      <ResponsePill transparent>
        {option.image ? (
          <ImageBox src={option.image.url} />
        ) : (
          <ColorBox color={option.hex} />
        )}
        <ColorName>{name}</ColorName>
      </ResponsePill>
    );
  }
  return (
    <ResponsePill>A cor selecionada já não se encontra disponivel</ResponsePill>
  );
};

const mapStateToProps = state => ({
  colours: state.info.colours
});

export default connect(mapStateToProps)(ColorOption);
