import React from 'react';
import { Modal, Button } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ChapterOrderName, OrderContainer } from '../../components/chapters/ChapterStyles';

class OrderAttendingEmployees extends React.Component {
  state = {
    employees: []
  }

  componentDidMount() {
    const { initialValues } = this.props;

    this.setState({
      employees: initialValues ? initialValues : []
    });
  }

  onDragEnd = (data) => {
    let { employees } = this.state;
    if (data.destination && data.source.index !== data.destination.index) {
      employees = this.rearrangeArrayElement(employees, data.source.index, data.destination.index);
      this.setState({ employees });
    }
  }

  rearrangeArrayElement = (arr, oldIndex, newIndex) => {
    if (
      oldIndex < 0 ||
      oldIndex >= arr.length ||
      newIndex < 0 ||
      newIndex >= arr.length
    ) {
      return;
    }

    // Remove the element at the old index and store it
    const removedElement = arr.splice(oldIndex, 1)[0];

    // Insert the removed element at the new index
    arr.splice(newIndex, 0, removedElement);

    return arr;
  }

  render() {
    const { open, initialValues } = this.props;
    const { closeModal, onSubmit, loading } = this.props;
    const { employees } = this.state;

    return (
      <Modal
        visible={open}
        title="Editar ordem dos colaboradores"
        maskClosable={false}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={() => onSubmit(employees)}>
            Gravar
          </Button>
        ]}>
        <OrderContainer>
          <DragDropContext onDragEnd={data => this.onDragEnd(data)}>
            <Droppable droppableId="employees">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {employees.map((employee, index) => (
                    <Draggable key={employee._id} draggableId={employee._id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ChapterOrderName>
                            {employee.name}
                          </ChapterOrderName>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </OrderContainer>
      </Modal>
    );
  };
};


export default OrderAttendingEmployees;