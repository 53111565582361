import React, { Component } from 'react';
import { reduxForm, Field, initialize, FieldArray, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import BaseButton from '../../components/buttons/BaseButton';
import {
  FormContainer,
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  BaseForm,
  HeaderButtonsContainer,
  SpinLoading,
  DefaultLanguageTab,
  SectionTitle
} from '../../styles/BasicStyles';
import {
  GetContentByCategory,
  EditContentByCategoryId,
  RemoveFile,
  AddFile,
  SaveImage,
  DeleteImage,
  AddFileLink,
  RemoveFileLink
} from '../../infra/requests/InformationRequest';
import { Tabs, Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import FormValidator from '../../infra/services/validations/FormValidator';
import DraftInput from '../../components/inputs/DraftInput';
import FilesGallery from '../../components/inputs/FilesGallery';
import ImageGalleryInput from '../../components/inputs/ImageGalleryInput';
import TextInput from '../../components/inputs/TextInput';
import Alerts from '../../components/alert/Alert';
import FileInput from './FileInput';
import { InputLabelDiv } from '../../components/inputs/InputStyles';
import TinyInput from '../../components/inputs/TinyInput';
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  description: 'required|languages',
  // text: 'required|languages',
  // link: 'required|languages',
});

class ManageInformationPage extends Component {
  state = {
    isNew: false,
    loading: true,
    ready: false,
    title: 'Information Page',
    category: null,
    askLink: false,
    document: {},
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;

    const result = await GetContentByCategory(params.id);
    dispatch(initialize('manage_information_form', { ...result.data }));

    this.setState({
      ready: true,
      title: result?.data?.category?.name,
      category: result?.data?.category,
      askLink: result?.data?.category?.askLink || false,
      document: result?.data?.document ? result.data.document : {},
    });
  };

  onSubmit = async values => {
    try {
      // console.warn('val', values);
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;

      const { askLink } = this.state;

      if (askLink && (!values.link || values.link['pt'] === '' || values.link['en'] === ''
        || values.link['fr'] === '' || values.link['es'] === '')) {
        return Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'Link do Documento em falta'
        });
      }

      await EditContentByCategoryId(params.id, values);
      return history.push('/information');
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    try {
      const { history } = this.props;
      return history.push('/information');
    } catch (e) {
      console.error(e);
    }
  };

  handleFileAdd = async file => {
    const {
      match: { params }
    } = this.props;
    const payload = new FormData();
    payload.append('image', file);
    return await AddFile(params.id, payload);
  };

  handleFileDelete = async id => {
    const {
      match: { params }
    } = this.props;
    return await RemoveFile(params.id, id);
  };

  uploadLinkFile = async (file, language) => {
    const {
      match: { params },
      dispatch,
      infoForm
    } = this.props;
    // console.warn('infoForm', infoForm);

    const payload = new FormData();
    payload.append('file', file);

    const result = await AddFileLink(params.id, language, payload);
    // console.warn('Res', result);
    if (result.success) {
      this.setState({ document: result?.data?.document ? result.data.document : {} })

      dispatch(initialize('manage_information_form', {
        ...infoForm,
        link: result.data.link,
        document: result.data.document
      }));
    }

    return result;
  };

  deleteFileLink = async (language) => {
    const {
      match: { params },
      dispatch,
      infoForm
    } = this.props;
    const { document } = this.state;
    // console.warn('infoForm', infoForm);

    const id = document[language] && document[language]._id ?
      document[language]._id : document[language];
    // console.warn('id', id);

    const result = await RemoveFileLink(params.id, id, language);
    // console.warn('Res', result);
    if (result.success) {
      this.setState({ document: result?.data?.document ? result.data.document : {} })

      dispatch(initialize('manage_information_form', {
        ...infoForm,
        link: result.data.link,
        document: result.data.document
      }));
    }

    return result;
  };

  saveNewImage = async image => {
    const {
      match: { params }
    } = this.props;
    const payload = new FormData();
    payload.append('image', image.blob);
    return await SaveImage(params.id, payload);
  };

  deleteImage = async image => {
    const {
      match: { params }
    } = this.props;
    return await DeleteImage(params.id, image);
  };

  render() {
    const { handleSubmit, activeLanguage, languages } = this.props;
    const { ready, title, askLink, document } = this.state;
    if (!ready) return <SpinLoading />;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{title}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={12}>
              <Col xs={12}>
                <DefaultLanguageTab>
                  {GetTranslationDescription(activeLanguage.code)}
                </DefaultLanguageTab>
                <Field
                  component={TinyInput}
                  name={`text.${activeLanguage.code}`}
                  label={'Conteúdo'}
                />
                {askLink && <React.Fragment>
                  <Field
                    component={TextInput}
                    type={'text'}
                    name={`link.${activeLanguage.code}`}
                    label={'Link do Documento'}
                    disabled={document && document[activeLanguage.code] ? true : false}
                  />

                  <InputLabelDiv style={{ marginTop: '15px' }}>Documento</InputLabelDiv>
                  <Field
                    component={FileInput}
                    name={`document.${activeLanguage.code}`}
                    handleAdd={f => this.uploadLinkFile(f, activeLanguage.code)}
                    handleDelete={() => this.deleteFileLink(activeLanguage.code)}
                  />
                </React.Fragment>
                }
              </Col>
              <Col xs={12}>
                <Tabs>
                  {languages
                    .filter(x => !x.active)
                    .map(language => (
                      <TabPane
                        tab={GetTranslationDescription(language.code)}
                        key={language.code}>
                        <Field
                          component={TinyInput}
                          name={`text.${language.code}`}
                          label={'Conteúdo'}
                        />
                        {askLink && <React.Fragment>
                          <Field
                            component={TextInput}
                            type={'text'}
                            name={`link.${language.code}`}
                            label={'Link do Documento'}
                            disabled={document && document[language.code] ? true : false}
                          />

                          <InputLabelDiv style={{ marginTop: '15px' }}>Documento</InputLabelDiv>
                          <Field
                            component={FileInput}
                            name={`document.${language.code}`}
                            handleAdd={f => this.uploadLinkFile(f, language.code)}
                            handleDelete={() => this.deleteFileLink(language.code)}
                          />
                        </React.Fragment>
                        }
                      </TabPane>
                    ))}
                </Tabs>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <SectionTitle subsection>Galeria de Imagens</SectionTitle>
                <FieldArray
                  name="gallery"
                  component={ImageGalleryInput}
                  handleAdd={this.saveNewImage}
                  handleDelete={this.deleteImage}
                />
              </Col>
              <Col xs={24}>
                <SectionTitle subsection>Ficheiros</SectionTitle>
                <FieldArray
                  name="files"
                  component={FilesGallery}
                  handleAdd={this.handleFileAdd}
                  handleDelete={this.handleFileDelete}
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

const selector = formValueSelector('manage_information_form');

ManageInformationPage = reduxForm({
  form: 'manage_information_form',
  validate: validations
})(ManageInformationPage);

const mapStateToProps = state => ({
  infoForm: {
    text: selector(state, 'text'),
    // link: selector(state, 'link'),
    // document: selector(state, 'document'),
    gallery: selector(state, 'gallery'),
    files: selector(state, 'files'),
  }
});

export default withLocalize(connect(mapStateToProps)(ManageInformationPage));
