import client from '../config/AxiosConfig';

export const GetFlowers = async (page, limit) => client.get(`/flowers/${page}/${limit}`);

export const GetFlowersList = async () => client.get(`/flowers`);

export const GetFlower = async id => client.get(`/flowers/${id}`);

export const CreateFlower = async data => client.post('/flowers', data);

export const UpdateFlower = async (id, data) => client.put(`/flowers/${id}`, data);

export const DeleteFlower = async id => client.delete(`/flowers/${id}`);