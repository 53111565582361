import React, { Fragment, useState } from 'react';
import { notification, Icon, Popconfirm } from 'antd';
import Dropzone from 'react-dropzone';
import { ResponsePill, ExtraPill, FileSection } from '../orders/components/Styles';
import styled from 'styled-components';

const Error = styled.div`
    color: ${p => p.theme.inputErrorColor};
`;

const XpertGoDropzone = styled(Dropzone)`
  border: 1px solid green;
  ${p =>
    p.error &&
    `border: 1px solid ${p.theme.inputErrorColor} !important;`
  }
`;

const SvgInput = ({ input, meta, handleAdd, handleDelete }) => {
  const [loading, setLoading] = useState(false);
  const { invalid, submitFailed } = meta;
  let showError = invalid && submitFailed;

  const handleDrop = async (accepted, rejected) => {
    if (accepted.length <= 0 || (rejected && rejected.length > 0)) {
      return notification.error({
        message: 'Ficheiro Inválido',
        description: 'O ficheiro é inválido ou tem um tamanho superior a 12Mb'
      });
    }

    setLoading(true);

    getBase64(accepted[0]).then(result => {
      input.onChange(result);
    })
      .catch(err => {
        // console.log(err);
        input.onChange('');
      });

    setLoading(false);
  };

  const getBase64 = file => {
    return new Promise(resolve => {
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        resolve(reader.result);
      };
    });
  };

  const handleRemove = async () => {
    setLoading(true);
    input.onChange('');
    // await handleDelete();
    setLoading(false);
    // return fields.remove(index);
  };

  const renderDropZone = () => {
    return <React.Fragment>
      <Dropzone
        multiple={false}
        onDrop={handleDrop}
        accept="image/*"
        // style={{border: showError ? '1px solid red' : ''}}
      // maxSize={120000000}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <FileSection style={{ marginTop: 0 }} {...getRootProps()}>
              <input {...getInputProps()} />
              {loading ? <Icon type={'loading'} /> : <Icon type="file" />}
              {loading ? ' A carregar...' : ' Clique ou arraste um ficheiro'}
            </FileSection>
          );
        }}
      </Dropzone>
      {showError && <Error>Insira um ícone.</Error>}
    </React.Fragment>
  };

  if (input.value) {
    const size = (input.value.size / (1024 * 1024)).toFixed(2);

    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center', alignItems: 'stretch' }}>
        {renderDropZone()}
        <ResponsePill single={true} style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', border: '1px solid #E0E0E0', backgroundColor: 'unset', borderRadius: 0 }}>
          <img style={{ width: 40, height: 40, objectFit: 'contain' }} src={input.value} alt='icon' />
          {loading && <Icon type={'loading'} />}
          {!loading && <Popconfirm
            placement={'topRight'}
            title="Tem a certeza que quer remover este ícone?"
            onConfirm={() => handleRemove()}>
            <Icon type="delete" />
          </Popconfirm>}
        </ResponsePill>
      </div>
    );
  }

  return renderDropZone();
};

export default SvgInput;