import React, {Component} from 'react';
import {Row, Col} from 'antd';
import {Field, reduxForm, initialize, FieldArray} from 'redux-form';
import {withLocalize} from 'react-localize-redux';
import {
  FormContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading,
  SectionTitle
} from '../../../styles/BasicStyles';
import BaseButton from '../../../components/buttons/BaseButton';
import LanguagesInput from '../../../components/inputs/LanguagesInput';
import ChaptersComponent from '../../../components/chapters/ChaptersComponent';
import FormValidator from '../../../infra/services/validations/FormValidator';
import {
  GetSection,
  UpdateSection
} from '../../../infra/requests/SectionRequests';
import SectionNames from '../../../infra/services/sections/SectionNames';

const validations = FormValidator.make({
  description: 'required|languages'
});

class FoodSection extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    this.loadSection();
  }

  loadSection = async () => {
    const {dispatch} = this.props;
    const {data} = await GetSection('FOOD');
    dispatch(initialize('manage_food_form', data));
    this.setState({loading: false});
  };

  onSubmit = async values => {
    const {history} = this.props;
    this.setState({loading: true});
    await UpdateSection('FOOD', values);
    history.push('/sections');
  };

  render() {
    const {history, handleSubmit} = this.props;
    const {loading} = this.state;
    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{SectionNames.FOOD}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={() => history.push('/sections')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>

        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Col xs={10} offset={7}>
                <Field
                  component={LanguagesInput}
                  name={'description'}
                  type="text"
                  label={'Descrição'}
                  placeholder={'Descrição da secção'}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <SectionTitle>Capítulos</SectionTitle>
                <FieldArray
                  name="chapters"
                  tag="FOOD"
                  formName="manage_food_form"
                  component={ChaptersComponent}
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

FoodSection = reduxForm({
  form: 'manage_food_form',
  validate: validations
})(FoodSection);

export default withLocalize(FoodSection);
