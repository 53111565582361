import PropTypes from 'prop-types';
import React from 'react';
import {InputDiv, InputLabelDiv, CheckboxField} from './InputStyles';

const CheckboxInput = ({
  input,
  meta,
  label,
  first,
  positiveLabel,
  disabled
}) => {
  const {invalid, submitFailed} = meta;
  const showError = invalid && submitFailed;

  return (
    <InputDiv first={first || !label}>
      {label && <InputLabelDiv>{label}</InputLabelDiv>}
      <CheckboxField label={label ? true : false}
        disabled={disabled}
        checked={input.value ? input.value : false}
        onChange={input.onChange}
        error={showError ? 1 : 0}>
        {positiveLabel}
      </CheckboxField>
    </InputDiv>
  );
};

CheckboxInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

CheckboxInput.defaultProps = {
  positiveLabel: 'Yes'
};

export default CheckboxInput;
