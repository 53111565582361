import styled from 'styled-components';

export const BillingBox = styled.div`
  display: block;
  width: 100%;
  background: #faf9f9;
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 20px 10px;
`;

export const BillingItem = styled.div`
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  padding: 3px 0px;
  color: #000000;

  span {
    margin-left: 10px;
    font-size: 14px;
    font-weight: normal;
    color: #000000;
  }
`;