import React from 'react'
import moment from 'moment'
import { navigate } from 'react-big-calendar/lib/utils/constants'
import { Badge, Col, Dropdown, Menu, Row, Tooltip } from 'antd'
import CurrencyComponent from '../currency/CurrencyComponent'
import AgendaActions from '../../infra/enum/agendaAction'
import { ReserveStatus, WeddingStatus } from '../../weddingday/reserves/ReserveAgenda'
import { isCurrentUserEmployeeAttending } from '../../infra/helpers/Helpers'
import Bookmark from "../../assets/icons/bookmark.svg";
import SelectedBookmark from "../../assets/icons/selected_bookmark.svg";
import Star from "../../assets/icons/star.svg";
import SelectedStar from "../../assets/icons/selected_star.svg";
import { WaitingIcon } from '../inputs/InputStyles'
import { WaitingActionType } from '../../weddingday/waitingDates/WaitingDatesPage'
import { AgendaType } from '../../weddingday/reserves/ReserveAgenda'
import { AgendaAttendingType } from '../../weddingday/reservesAttending/ReservesAttending'

function createCalendar(currentDate) {
    if (!currentDate) {
        currentDate = moment()
    } else {
        currentDate = moment(currentDate)
    }

    const first = currentDate.clone().startOf('month')
    const last = currentDate.clone().endOf('month')
    const weeksCount = Math.ceil((first.day() + last.date()) / 7)
    const calendar = Object.assign([], { currentDate, first, last })

    for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
        const week = []
        calendar.push(week)
        calendar.year = currentDate.year()
        calendar.month = currentDate.month()

        for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
            const date = currentDate.clone().set('date', day + 1 - first.day())
            date.calendar = calendar
            week.push(date)
        }
    }

    return calendar
}

function getTitle(title, agendaType) {
    return title === 'OCCUPIED_DATE'
        ? 'Data Ocupada'
        : title === 'AVAILABLE_DATE'
            ? ((agendaType == AgendaAttendingType.INTEREST || agendaType == AgendaType.INTEREST)
                ? 'Data de Interesse'
                : 'Data Disponível')
            : title === 'UNAVAILABLE_DATE'
                ? 'Data Indisponível'
                : title === 'PRICE_TO_DEFINE'
                    ? 'Preços a definir'
                    : '';
}

function getHolidayName(holiday) {
    return holiday.localName;
}

function CalendarDate(props) {
    // console.warn('Props Date', props);
    const { dateToRender, dateOfMonth, events, waitingDates, interestDates, selectedDates, wedding } = props;
    const { reserveView, agendaType } = props;
    const today = dateToRender.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? 'today' : '';
    const date = moment.utc(dateToRender.toISOString());
    const tmpId = moment.utc(date).format('DDDYYYY');
    const event = events.find(f => f.id === tmpId);
    const waitingDate = !reserveView && (agendaType === AgendaType.WAITING_DATE || agendaType === AgendaAttendingType.WAITING_DATE)
        ? waitingDates?.find(f => f?.date && moment.utc(f?.date).format('DDDYYYY') === tmpId)
        : null;
    const interestDate = !reserveView && (agendaType === AgendaType.INTEREST || agendaType === AgendaAttendingType.INTEREST)
        ? interestDates?.find(f => f?.date && moment.utc(f?.date).format('DDDYYYY') === tmpId)
        : null;

    const className = event?.resource.isDateOccupied
        ? 'occupied'
        : !event?.resource?.isCompanyRestDay && !event?.resource?.price
            ? 'noPrice'
            : event?.resource?.edit
                ? 'edit'
                : agendaType === AgendaType.AGENDA && event?.resource?.selected
                    ? 'selected' : '';

    const style = event?.resource?.holiday || event?.resource?.localHoliday
        ? { color: '#bc9222' }
        : agendaType === AgendaType.AGENDA && Array.isArray(event?.resource?.reserves) && event?.resource?.reserves?.length > 0 ?
            {
                backgroundColor: event?.resource?.color || '',
                color: event ? event?.resource?.color === '#bc9222' ? 'white' : 'grey' : ''
            }
            : null;

    const borderBottom = event && !event?.resource?.isCompanyRestDay &&
        !event?.resource?.isDateOccupied && event?.resource?.price
        && event?.resource?.price?.minPax > 0 && event?.resource?.price?.minPrice > 0 ?
        '1px solid rgb(255 255 255 / 90%)' : 'none';

    const marginBottom = event && !event?.resource?.isCompanyRestDay &&
        !event?.resource?.isDateOccupied && event?.resource?.price
        && event?.resource?.price?.minPax > 0 && event?.resource?.price?.minPrice > 0 ?
        5 : 0;

    if (dateToRender.month() < dateOfMonth.month() || dateToRender.month() > dateOfMonth.month()) {
        return (<button disabled={true} className="date next-month prev-month"></button>)
    }

    // if (dateToRender.month() < dateOfMonth.month()) {
    //     return (
    //         <button disabled={true} className="date prev-month">
    //             {dateToRender.date()}
    //         </button>
    //     )
    // }

    // if (dateToRender.month() > dateOfMonth.month()) {
    //     return (
    //         <button disabled={true} className="date next-month">
    //             {dateToRender.date()}
    //         </button>
    //     )
    // }

    const tooltip = <React.Fragment>
        {(event && event.resource.holiday || event && event.resource.localHoliday) &&
            <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 13, color: '#dbdbdb' }}>
                {getHolidayName(event && event.resource.localHoliday ? event.resource.localHoliday : event.resource.holiday)}
            </p>
        }

        {Array.isArray(event?.resource?.reserves) && event?.resource?.reserves?.length > 0
            && !event?.resource?.isDateOccupied ?
            <React.Fragment>
                <p style={{ marginBottom, textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>
                    Pode reservar esta data. Terá resposta dentro de 3 dias.
                </p>
                <p style={{ borderBottom, marginBottom, textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>
                    {`Data com ${event.resource.reserves.length} reserva(s) sem compromisso`}
                </p>
            </React.Fragment>
            : <p style={{ borderBottom, marginBottom, textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>
                {event ? getTitle(event.title, agendaType) : ''}
            </p>}

        {event && !event.resource.isCompanyRestDay && !event.resource.isDateOccupied && event.resource.price
            && event.resource.price.minPax && event.resource.price.minPax > 0 && event.resource.price.minPrice
            && event.resource.price.minPrice > 0 ?
            <React.Fragment>
                <p style={{ marginBottom: 0 }}>
                    <span><CurrencyComponent value={event.resource.price.minPrice} /></span>
                    &nbsp; por PAX
                </p>
                <p style={{ marginBottom: 0 }}>
                    Mínimo: &nbsp;
                    <span>{event.resource.price.minPax}</span> PAX
                </p>
                <p style={{ marginBottom: 0 }}>
                    Preço Global Mínimo: &nbsp;
                    <span><CurrencyComponent value={parseFloat((event.resource.price.minPax * event.resource.price.minPrice).toFixed(2))} /></span>
                </p>
            </React.Fragment>
            : null
        }
    </React.Fragment>

    const tooltipWaiting = <React.Fragment>
        {(event && event.resource.holiday || event && event.resource.localHoliday) &&
            <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 13, color: '#dbdbdb' }}>
                {getHolidayName(event && event.resource.localHoliday ? event.resource.localHoliday : event.resource.holiday)}
            </p>
        }

        <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>
            Lista de Espera
        </p>
    </React.Fragment>

    const tooltipInterest = <React.Fragment>
        {(event && event.resource.holiday || event && event.resource.localHoliday) &&
            <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 13, color: '#dbdbdb' }}>
                {getHolidayName(event && event.resource.localHoliday ? event.resource.localHoliday : event.resource.holiday)}
            </p>
        }

        <p style={{ borderBottom, marginBottom, textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>
            {event && event?.resource?.interestDates > 0
                ? `Data com ${event?.resource?.interestDates} interessado(s)`
                : event
                    ? getTitle(event.title, agendaType) : ''}
        </p>

        {event && !event.resource.isCompanyRestDay && !event.resource.isDateOccupied && event.resource.price
            && event.resource.price.minPax && event.resource.price.minPax > 0 && event.resource.price.minPrice
            && event.resource.price.minPrice > 0 ?
            <React.Fragment>
                <p style={{ marginBottom: 0 }}>
                    <span><CurrencyComponent value={event.resource.price.minPrice} /></span>
                    &nbsp; por PAX
                </p>
                <p style={{ marginBottom: 0 }}>
                    Mínimo: &nbsp;
                    <span>{event.resource.price.minPax}</span> PAX
                </p>
                <p style={{ marginBottom: 0 }}>
                    Preço Global Mínimo: &nbsp;
                    <span><CurrencyComponent value={parseFloat((event.resource.price.minPax * event.resource.price.minPrice).toFixed(2))} /></span>
                </p>
            </React.Fragment>
            : null
        }
    </React.Fragment>

    const menu = event && event.resource.isCompanyRestDay ?
        <Menu
            theme='light'
            onClick={(e) => props.onClick({
                action: e.key,
                date,
                event: event
            })}>
            <Menu.Item key={AgendaActions.REMOVE_UNAVAILABLE}>Remover Indisponibilidade</Menu.Item>
        </Menu>
        : event && !event.resource.isCompanyRestDay && !event.resource.isDateOccupied && !event.resource.isDateArchived ?
            <Menu
                theme='light'
                onClick={(e) => props.onClick({
                    action: e.key,
                    date,
                    event: event,
                    selectedInterestDate: interestDate !== null && interestDate !== undefined ? true : false
                })}>
                {((!wedding || (wedding?.status && wedding?.status === WeddingStatus.RESERVE)) && reserveView
                    && event?.resource?.price && event?.resource?.price?.minPax > 0 && event?.resource?.price?.minPrice > 0)
                    && <Menu.Item key={AgendaActions.SELECT}>Selecionar Data</Menu.Item>}
                {wedding !== null && wedding !== undefined && wedding?.status === WeddingStatus.RESERVE
                    && event?.resource?.price && event?.resource?.price?.minPax > 0 && event?.resource?.price?.minPrice > 0
                    && agendaType === AgendaType.INTEREST &&
                    <Menu.Item key={'INTEREST_DATE'}>
                        {interestDate ? 'Eliminar data de interesse' : 'Adicionar data de interesse'}
                    </Menu.Item>}
                {event && (!event.resource.price || event.resource.price) && !event.resource.isDateOccupied ?
                    <Menu.Item key={AgendaActions.MNG_PRICE}>Gerir Preço</Menu.Item> : null}
                {event && event.resource.reserves.length === 0 ?
                    <Menu.Item key={AgendaActions.CREATE_UNAVAILABLE}>Marcar Indisponibilidade</Menu.Item> : null}
            </Menu>
            : event && !event.resource.isCompanyRestDay && event.resource.isDateOccupied && event.resource.isDateArchived ?
                <Menu
                    theme='light'
                    onClick={(e) => props.onClick({
                        action: e.key,
                        date,
                        weddingId: event.resource.reserves.find(f => f.status === ReserveStatus.ARCHIVED)?.wedding,
                        event: event,
                    })}>
                    {/* <Menu.Item key={AgendaActions.RESERVE_WEDDING}>Gerir Reserva</Menu.Item> */}
                    <Menu.Item key={AgendaActions.ARCHIVED_WEDDING}>Aceder Arquivo</Menu.Item>
                </Menu>
                : event && !event.resource.isCompanyRestDay && event.resource.isDateOccupied && !event.resource.isDateArchived ?
                    <Menu
                        theme='light'
                        onClick={(e) => props.onClick({
                            action: e.key,
                            date,
                            weddingId: event.resource.reserves.find(f => f.status === ReserveStatus.OCCUPIED)?.wedding,
                            event: event,
                            selectedWaitingDate: waitingDate !== null && waitingDate !== undefined ? true : false
                        })}>
                        {/* <Menu.Item key={AgendaActions.RESERVE_WEDDING}>Gerir Reserva</Menu.Item> */}
                        {wedding !== null && wedding !== undefined && !reserveView
                            && (agendaType === AgendaType.WAITING_DATE || agendaType === AgendaAttendingType.WAITING_DATE)
                            && <Menu.Item key={WaitingActionType.DAY}>
                                {waitingDate ? 'Remover da lista de espera' : 'Adicionar à lista de espera'}
                            </Menu.Item>}
                        <Menu.Item key={AgendaActions.EDIT_WEDDING}>Editar Casamento</Menu.Item>
                        <Menu.Item key={AgendaActions.INFO_WEDDING}>Aceder Casamento</Menu.Item>
                    </Menu>
                    : null;


    if (isCurrentUserEmployeeAttending()) {
        const waitingDateTxt = waitingDate && !reserveView && (agendaType === AgendaType.WAITING_DATE || agendaType === AgendaAttendingType.WAITING_DATE)
            ? event && event.resource.localHoliday
                ? 'FM'
                : event && event.resource.holiday
                    ? 'F'
                    : <b>{dateToRender.date()}</b>
            : <b>{dateToRender.date()}</b>;
        const interestDateTxt = interestDate && !reserveView && (agendaType === AgendaType.INTEREST || agendaType === AgendaAttendingType.INTEREST)
            ? event && event.resource.localHoliday
                ? 'FM'
                : event && event.resource.holiday
                    ? 'F'
                    : <b>{dateToRender.date()}</b>
            : <b>{dateToRender.date()}</b>;

        return event && event?.resource?.isCompanyRestDay
            ? <Tooltip title={tooltip} trigger='hover'>
                <button
                    className={`date in-month ${today} ${className}`}
                    style={style}>
                    {event && event.resource.isCompanyRestDay
                        ? 'I'
                        : dateToRender.date()
                    }
                </button>
            </Tooltip >
            // : event && (!event?.resource?.price || (event?.resource?.price && (event?.resource?.price?.minPax <= 0 || event?.resource?.price?.minPrice <= 0)))
            //     ? <Tooltip
            //         title={!reserveView && (agendaType === AgendaType.INTEREST || agendaType === AgendaAttendingType.INTEREST)
            //             ? interestDate
            //                 ? 'Eliminar data de interesse'
            //                 : tooltipInterest
            //             : tooltip
            //         }
            //         trigger='hover'>
            //         <button
            //             className={`date in-month ${today} ${className}`}
            //             style={style}
            //             onClick={(e) => {
            //                 if (!reserveView && (agendaType === AgendaType.INTEREST || agendaType === AgendaAttendingType.INTEREST)) {
            //                     props.onInterestClick({ date, event, selectedInterestDate: interestDate ? true : false })
            //                 } else e.preventDefault();
            //             }}>
            //             {interestDate && !reserveView && (agendaType === AgendaType.INTEREST || agendaType === AgendaAttendingType.INTEREST)
            //                 ? <Badge className='interest-date' count={<WaitingIcon src={SelectedStar} />}>
            //                     {interestDateTxt}
            //                 </Badge>
            //                 : dateToRender.date()
            //             }
            //         </button>
            //     </Tooltip >
            : event.resource.isDateOccupied
                ? <Tooltip
                    title={!reserveView && (agendaType === AgendaType.WAITING_DATE || agendaType === AgendaAttendingType.WAITING_DATE)
                        ? waitingDate ? 'Eliminar da lista de espera'
                            : 'Adicionar à lista de espera'
                        : tooltip
                    }
                    trigger='hover'>
                    <button
                        className={`date in-month ${today} ${className}`}
                        style={style}
                        onClick={e => !reserveView && (agendaType === AgendaType.WAITING_DATE || agendaType === AgendaAttendingType.WAITING_DATE)
                            ? props.onWaitingClick({ date, selectedWaitingDate: waitingDate ? true : false })
                            : e?.preventDefault()
                        }>
                        {waitingDate && !reserveView && (agendaType === AgendaType.WAITING_DATE || agendaType === AgendaAttendingType.WAITING_DATE)
                            ? <Badge className='waiting-date' count={<WaitingIcon src={SelectedBookmark} />}>
                                {waitingDateTxt}
                            </Badge>
                            : event && event.resource.localHoliday
                                ? 'FM'
                                : event && event.resource.holiday
                                    ? 'F'
                                    : (<b>{dateToRender.date()}</b>)
                        }
                    </button>
                </Tooltip >
                : event?.resource?.price
                    && event?.resource?.price?.minPax && event?.resource?.price?.minPax > 0 && event?.resource?.price?.minPrice
                    && event?.resource?.price?.minPrice > 0
                    ? <Tooltip
                        title={!reserveView && (agendaType === AgendaType.INTEREST || agendaType === AgendaAttendingType.INTEREST)
                            ? interestDate
                                ? 'Eliminar data de interesse'
                                : 'Adicionar data de interesse'
                            : tooltip
                        }
                        trigger='hover'>
                        <button
                            className={`date in-month ${today} ${className}`}
                            style={style}
                            onClick={(e) => {
                                if (!reserveView && (agendaType === AgendaType.INTEREST || agendaType === AgendaAttendingType.INTEREST)) {
                                    props.onInterestClick({ date, event, selectedInterestDate: interestDate ? true : false })
                                } else if (event?.resource?.price && (!wedding || (wedding?.status && wedding?.status === WeddingStatus.RESERVE)) && reserveView) {
                                    props.onClick({
                                        date,
                                        event: event
                                    })
                                } else e.preventDefault();
                            }}>
                            {interestDate && !reserveView && (agendaType === AgendaType.INTEREST || agendaType === AgendaAttendingType.INTEREST)
                                ? <Badge className='interest-date' count={<WaitingIcon src={SelectedStar} />}>
                                    {interestDateTxt}
                                </Badge>
                                : event && event.resource.localHoliday
                                    ? 'FM'
                                    : event && event.resource.holiday
                                        ? 'F'
                                        : (<b>{dateToRender.date()}</b>)}
                        </button>
                    </Tooltip >
                    : <Tooltip
                        title={tooltip}
                        trigger='hover'>
                        <button
                            className={`date in-month ${today} ${className}`}
                            style={style}
                            onClick={(e) => e.preventDefault()}>
                            {event && event.resource.localHoliday
                                ? 'FM'
                                : event && event.resource.holiday
                                    ? 'F'
                                    : dateToRender.date()}
                        </button>
                    </Tooltip >;
    } else {
        const interestDateTxt = interestDate && !reserveView && agendaType === AgendaType.INTEREST
            ? event && event.resource.isCompanyRestDay
                ? 'I'
                : event && event.resource.localHoliday
                    ? 'FM'
                    : event && event.resource.holiday
                        ? 'F'
                        : event && event.resource.isDateOccupied
                            ? dateToRender.date()
                            : <b>{dateToRender.date()}</b>
            : <b>{dateToRender.date()}</b>;

        return <Dropdown overlay={menu} trigger={['click']}>
            <Tooltip
                title={(agendaType === AgendaType.INTEREST || agendaType === AgendaAttendingType.INTEREST)
                    ? (interestDate ? 'Eliminar data de interesse' : tooltipInterest)
                    : (event && event.resource.isDateOccupied && !reserveView && (agendaType === AgendaType.WAITING_DATE || agendaType === AgendaAttendingType.WAITING_DATE))
                        ? (waitingDate ? 'Eliminar da lista de espera' : tooltipWaiting)
                        : tooltip
                }
                trigger='hover'>
                <button
                    className={`date in-month ${className} ${today}`}
                    style={style}
                    onClick={e => e.preventDefault()}>
                    {interestDate && !reserveView && agendaType === AgendaType.INTEREST
                        ? <Badge className='interest-date' count={<WaitingIcon src={SelectedStar} />}>
                            {interestDateTxt}
                        </Badge>
                        : event && event.resource.isCompanyRestDay
                            ? 'I'
                            : event && event.resource.localHoliday
                                ? (waitingDate && !reserveView && (agendaType === AgendaType.WAITING_DATE || agendaType === AgendaAttendingType.WAITING_DATE)
                                    ? <Badge className='waiting-date' count={<WaitingIcon src={SelectedBookmark} />}>FM</Badge>
                                    : 'FM')
                                : event && event.resource.holiday
                                    ? (waitingDate && !reserveView && (agendaType === AgendaType.WAITING_DATE || agendaType === AgendaAttendingType.WAITING_DATE)
                                        ? <Badge className='waiting-date' count={<WaitingIcon src={SelectedBookmark} />}>F</Badge>
                                        : 'F')
                                    : event && event.resource.isDateOccupied
                                        ? waitingDate && !reserveView && (agendaType === AgendaType.WAITING_DATE || agendaType === AgendaAttendingType.WAITING_DATE)
                                            ? <Badge className='waiting-date' count={<WaitingIcon src={SelectedBookmark} />}>
                                                {dateToRender.date()}
                                            </Badge>
                                            : dateToRender.date()
                                        : (<b>{dateToRender.date()}</b>)}
                </button>
            </Tooltip >
        </Dropdown>;
    }
}
class YearView extends React.Component {
    state = {
        date: moment(),
        events: [],
        waitingDates: [],
        interestDates: [],
        months: [],
        activeLanguage: {},
        selectedDates: [],
        wedding: null,
        reserveView: true,
        agendaType: AgendaType.AGENDA,
    }

    componentDidMount() {
        this.setState({
            date: this.props.date,
            events: this.props.events || [],
            waitingDates: this.props.waitingDates || [],
            interestDates: this.props.interestDates || [],
            wedding: this.props.wedding,
            reserveView: this.props.reserveView,
            agendaType: this.props.agendaType,
        }, () => {
            this.setMonthsCalendar();
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.date !== prevProps.date) {
            this.setState({ date: this.props.date })
        }

        if (this.props.loading !== prevProps.loading) {
            this.setState({
                events: this.props.events || [],
                waitingDates: this.props.waitingDates || [],
                interestDates: this.props.interestDates || [],
                wedding: this.props.wedding,
                reserveView: this.props.reserveView,
                agendaType: this.props.agendaType,
            }, () => this.setMonthsCalendar())
        }

        if (this.props.selectedDates !== prevProps.selectedDates) {
            this.setState({ selectedDates: this.props.selectedDates })
        }
    }

    setMonthsCalendar = () => {
        const { date, events, waitingDates, interestDates } = this.state;
        const months = [];
        moment().locale('pt')

        for (let i = 0; i < 12; i++) {
            const startDate = moment.utc(date).set('month', i).startOf('month');

            const monthInfo = {
                month: i,
                calendar: createCalendar(startDate),
                events: events?.length > 0
                    ? events.filter(f => moment.utc(f.start).month() === i)
                    : [],
                waitingDates: waitingDates?.length > 0
                    ? waitingDates.filter(f => moment.utc(f.date).month() === i)
                    : [],
                interestDates: interestDates?.length > 0
                    ? interestDates.filter(f => moment.utc(f.date).month() === i)
                    : []
            };
            months.push(monthInfo);
        }

        this.setState({ months });
    }

    getMonthName = (date) => {
        return moment(date).locale('pt').format('MMMM').toUpperCase();
    }

    getWeekdays = () => {
        const { wedding } = this.state;
        moment.locale('pt');
        // return wedding !== null && wedding !== undefined
        //     ? ['S', ...moment.weekdaysShort()]
        //     : moment.weekdaysShort();
        return moment.weekdaysShort();
    }

    getWeekInterval = (datesOfWeek, month, waitingDates) => {
        let startDate = null;
        let endDate = null;
        const waitingDatesOfWeek = [];

        if (Array.isArray(datesOfWeek) && datesOfWeek.length > 0) {
            const datesFromMonth = datesOfWeek.filter(f => moment(f).month() === month);

            if (datesFromMonth?.length > 0) {
                startDate = datesFromMonth[0];
                endDate = datesFromMonth[datesFromMonth.length - 1];

                for (let index = 0; index < datesFromMonth.length; index++) {
                    const date = datesFromMonth[index];
                    const waitingDate = waitingDates.find(f => moment.utc(f?.date).format('DDDYYYY') === moment.utc(date).format('DDDYYYY'))
                    if (waitingDate) waitingDatesOfWeek.push(waitingDate);
                }
            }
        }

        return {
            date: startDate ? moment.utc(startDate) : null,
            endDate: endDate ? moment.utc(endDate) : null,
            waitingDates: waitingDatesOfWeek,
            selected: waitingDatesOfWeek?.length > 0
                ? true
                : false
        };
    }

    getWaitingDatesByWeekDay = (weekday, month, waitingDates) => {
        let selected = false;

        if (Array.isArray(waitingDates) && waitingDates.length > 0) {
            const waitingDatesByWeekNumber = waitingDates.filter(f => f?.date && f?.type && f?.type === WaitingActionType.WEEKDAY && moment.utc(f?.date).month() === month && moment.utc(f?.date).day() === weekday);
            selected = waitingDatesByWeekNumber?.length > 0 ? true : false;
        }

        return selected;
    }

    render() {
        let { months, selectedDates, wedding, reserveView, agendaType } = this.state;
        // const reservePhase = (!wedding || (wedding?.status && wedding?.status === WeddingStatus.RESERVE)) ? true : false;

        return <Row gutter={[12, 12]} className="year">
            {months.map((month, monthIndex) => {
                // const data = {
                //     month: month.month,
                //     startDate: moment.utc().set('month', monthIndex).startOf('month') || null,
                //     endDate: moment.utc().set('month', monthIndex).endOf('month') || null,
                //     waitingDate: month?.waitingDate || null,
                //     selected: month?.waitingDate !== null && month?.waitingDate !== undefined ? true : false
                // };

                return <Col key={monthIndex} xs={24} sm={12} md={8} lg={8} xl={8} className="month">
                    <div className="month-name">
                        {this.getMonthName(month)}
                        {/* <Tooltip title={weekInterval?.selected ? 'Remover da lista de espera' : 'Adicionar à lista de espera'}>
                            <WaitingIcon 
                                src={weekInterval?.selected ? SelectedBookmark : Bookmark} 
                                onClick={(e) => this.props.onWaitingClick(month)} />
                        </Tooltip> */}
                    </div>
                    <div className='weekdays'>
                        {this.getWeekdays().map((day, index) => {
                            const selectedByWeekday = this.getWaitingDatesByWeekDay(index, monthIndex, month.waitingDates);

                            return wedding !== null && wedding !== undefined && !reserveView && (agendaType === AgendaType.WAITING_DATE || agendaType === AgendaAttendingType.WAITING_DATE)
                                ? <span key={index} className="weekday">
                                    <Tooltip title={selectedByWeekday ? 'Remover da lista de espera' : 'Adicionar à lista de espera'}>
                                        <button
                                            className='waiting-weekday'
                                            onClick={(e) => this.props.onMultipleWaitingClick({ type: WaitingActionType.WEEKDAY, weekday: index, month: monthIndex, selected: selectedByWeekday })}>
                                            <WaitingIcon src={selectedByWeekday ? SelectedBookmark : Bookmark} />
                                            <span>{day}</span>
                                        </button>
                                    </Tooltip>
                                </span>
                                : <span key={index} className="weekday">
                                    {day}
                                </span>
                        })}
                    </div>
                    {month.calendar.map((week, index) => {
                        // const weekInterval = this.getWeekInterval(week[index].calendar[index], month.month, month.waitingDates);
                        // const data = {
                        //     month: month.month,
                        //     startDate: weekInterval?.startDate || null,
                        //     endDate: weekInterval?.endDate || null,
                        //     waitingDate: weekInterval?.waitingDate || null,
                        //     selected: weekInterval?.selected || false
                        // };
                        // const translation = weekInterval?.selected
                        //     ? 'Elimiminar da lista de espera'
                        //     : 'Adicionar à lista de espera';

                        return <div key={index} className='month-days'>
                            {/* {wedding !== null && wedding !== undefined && <Tooltip title={translation}>
                                <button className='waitingDate' onClick={(e) => this.props.onWaitingClick(data)}>
                                    <WaitingIcon src={weekInterval?.selected ? SelectedBookmark : Bookmark} />
                                </button>
                            </Tooltip>} */}
                            {week.map(date => {
                                // console.warn('Date', date)
                                return <CalendarDate
                                    key={date.date()}
                                    dateToRender={date}
                                    events={month.events}
                                    waitingDates={month.waitingDates}
                                    interestDates={month.interestDates}
                                    selectedDates={selectedDates}
                                    dateOfMonth={month.calendar.currentDate}
                                    wedding={wedding}
                                    reserveView={reserveView}
                                    agendaType={agendaType}
                                    onClick={date => this.props.onDayClick(date)}
                                    onWaitingClick={data => this.props.onWaitingClick({ type: WaitingActionType.DAY, ...data })}
                                    onInterestClick={data => this.props.onInterestClick(data)}
                                />
                            })}
                        </div>
                    })}
                </Col>
            })}
        </Row>
    }
}

// Day.propTypes = {
//     date: PropTypes.instanceOf(Date).isRequired,
// }

YearView.range = date => {
    //     return [dates.startOf(date, 'year')]
}

YearView.navigate = (date, action) => {
    switch (action) {
        case navigate.PREVIOUS:
            return moment.utc(date).add(1, 'year') //dates.add(date, -1, 'year')
        case navigate.NEXT:
            return moment.utc(date).subtract(1, 'year')
        default:
            return date
    }
}

YearView.title = (date, { localizer }) => localizer.format(date, 'yearHeaderFormat')

export default YearView
