import React from "react";
import PropTypes from "prop-types";
import {
  TimeInputSection,
  TimeInputLabel,
  TimeInputContainer,
  InputContainer,
  ArrowsContainer,
  ArrowUp,
  ArrowDown,
  TimeInput
} from "./InputStyles";
import Arrow from "../../assets/icons/arrow_down.svg";

const TextInput = ({
  input,
  meta: { invalid, submitFailed, error },
  label,
  disabled,
  readOnly,
  editTime,
  noLabel,
  weddingOrder
}) => {
  const showError = invalid && submitFailed;

  //When editing a Moment, change the input to the current value
  if(editTime && !input.value) {
    input.onChange(editTime);
  }

  //Change Time using the arrows
  const changeCurrentTime = (direction) => {
    const pattern = /^[0-9]{2}:{1}[0-9]{2}$/;
    let hour = 0, minute = 0;

    if(input.value && pattern.test(input.value)) {
      const parts = input.value.split(':');
      hour = parseInt(parts[0]);
      minute = parseInt(parts[1]);
    }

    if(direction === 'up') {
      minute++;

      //MAX Minutes: 59
      if(minute > 59) {
        minute = 0;

        //Increment Hour. If greater than 23 return to 0
        hour++;
      }

      if(hour > 23) {
        hour = 0;
      }
    }
    else if(direction === 'down') {
      minute--;

      //Min Minutes: 0
      if(minute < 0) {
        minute = 59;

        //Decrement Hour. If inferior to 0 return to 23
        hour--;
      }

      if(hour < 0) {
        hour = 23;
      }
    }

    //Mantain Hours and Minutes with 2 digits
    if(hour < 10) {
      hour = `0${hour}`;
    }
    if(minute < 10) {
      minute = `0${minute}`;
    }

    input.onChange(`${hour}:${minute}`);
  }

  //Editing the Time directly
  const inputTime = (value) => {
    const pattern = /^[0-9]{0,2}:{0,1}[0-9]{0,2}$/;

    if(!value) {
      input.onChange('');
    }
    else if(pattern.test(value)) {
      //Only 5 digits allowed (hh:mm)
      if(value.length > 5) {
        value = value.slice(0, 5);
      }

      input.onChange(value);
    }
  }

  return (
    <TimeInputSection weddingOrder={weddingOrder}>
      {
        !noLabel && <TimeInputLabel showError={showError}>{label}</TimeInputLabel>
      }      
      <TimeInputContainer weddingOrder={weddingOrder} error={showError}>
        <InputContainer>
          <TimeInput
            type="text"
            placeholder="00:00"
            value={input.value}
            onChange={(e) => inputTime(e.target.value)}
            disabled={disabled}
            readOnly={readOnly}
            filled={input.value ? true : false}
          />
        </InputContainer>
        <ArrowsContainer>
          <ArrowUp 
            src={Arrow}
            onClick={() => changeCurrentTime('up')}
          />
          <ArrowDown 
            src={Arrow}
            onClick={() => changeCurrentTime('down')}
          />
        </ArrowsContainer>
      </TimeInputContainer>
    </TimeInputSection>
  );
};

TextInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  noLabel: PropTypes.bool,
  weddingOrder: PropTypes.bool
};

export default TextInput;