import UserIcon from '../../assets/menu/ic_user2.svg';
import HomeIcon from '../../assets/menu/ic_home.svg';
import CalendarIcon from '../../assets/menu/ic_calendar.svg';
import PaymentIcon from '../../assets/menu/ic_payment.svg';
import ReserveIcon from '../../assets/menu/ic_reserve.svg';
import WaitingListIcon from '../../assets/menu/ic_waiting_list.svg';
import InterestListIcon from '../../assets/menu/ic_interest_list.svg';

export default [
  {
    type: 'menu',
    to: '/home-attending',
    external_icon: true,
    size: 20,
    icon: HomeIcon,
    name: 'Home',
    exact: true
  },
  {
    type: 'menu',
    to: '/agenda',
    external_icon: true,
    size: 20,
    icon: CalendarIcon,
    name: 'Agenda de Reservas',
    exact: true
  },
  {
    type: 'menu',
    to: '/reserves',
    external_icon: true,
    size: 20,
    icon: ReserveIcon,
    name: 'Reservas',
    exact: true
  },
  {
    type: 'menu',
    to: '/waiting-dates',
    external_icon: true,
    size: 20,
    icon: WaitingListIcon,
    name: 'Lista de Espera',
    exact: true
  },
  {
    type: 'menu',
    to: '/interest-dates',
    external_icon: true,
    size: 20,
    icon: InterestListIcon,
    name: 'Datas de Interesse',
    exact: true
  },
  // {
  //   type: 'menu',
  //   to: '/payments',
  //   external_icon: true,
  //   size: 20,
  //   icon: PaymentIcon,
  //   name: 'Pagamentos',
  //   exact: true
  // },
  {
    type: 'menu',
    to: '/profile',
    external_icon: true,
    size: 20,
    icon: UserIcon,
    name: 'Perfil',
    exact: true
  },
];
