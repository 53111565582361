import React, {Component} from 'react';
import {Row, Col} from 'antd';
import {TableFilterSection} from '../../styles/BasicStyles';
import SelectInput from '../../components/inputs/SelectInput';
import {GetTags} from '../../infra/requests/InspirationsRequest';

class InspirationFilters extends Component {
  state = {
    categories: '',
    __tags: [],
    tag: ''
  };

  componentDidMount() {
    this.getInfo();
  }

  getInfo = async tag => {
    try {
      const tags = await GetTags({tag});
      this.setState({__tags: tags.data.map(tag => ({name: tag}))});
    } catch (error) {
      console.error(error);
    }
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({[field]: value}, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const {queryChange} = this.props;
    const query = {};
    Object.keys(this.state).map(key => {
      if (
        key.search('__') === -1 &&
        this.state[key] &&
        this.state[key] !== ''
      ) {
        query[key] = this.state[key].replace('#', '');
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  selectTag = tag => {
    this.onInputChange('tag', tag);
  };

  render() {
    const {list} = this.props;
    const {categories, __tags, tag} = this.state;
    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={6}>
            <SelectInput
              label="Categoria"
              allowClear
              placeholder="Escolher categoria"
              data={list}
              input={{
                value: categories,
                onChange: value => this.onInputChange('categories', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label="Tag"
              allowClear
              placeholder="Escolher tag"
              data={__tags}
              dataKey="name"
              dataLabel="name"
              input={{
                value: tag,
                onChange: value => this.onInputChange('tag', value)
              }}
              meta={{valid: true}}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

export default InspirationFilters;
