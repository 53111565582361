import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import BaseButton from '../../components/buttons/BaseButton';
import {
  FormContainer,
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  BaseForm,
  HeaderButtonsContainer,
  SpinLoading
} from '../../styles/BasicStyles';
import { CreateVideo } from '../../infra/requests/VideoRequests';
import { withLocalize } from 'react-localize-redux';
import FormValidator from '../../infra/services/validations/FormValidator';
import VideoInput from '../../components/inputs/VideoInput';
import SelectInput from '../../components/inputs/SelectInput';

const validations = FormValidator.make({
  local: 'required',
  url: 'required'
});

class ManagePortfolioPage extends Component {
  state = {
    loading: false,
    ready: true
  };

  onSubmit = async values => {
    try {
      this.setState({ loading: true });
      const { history } = this.props;
      await CreateVideo(values);
      return history.push('/management/videos');
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    try {
      const { history } = this.props;
      return history.push('/management/videos');
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit, locals } = this.props;
    const { ready, loading } = this.state;
    if (!ready || loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>New Video</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={SelectInput}
              name={'local'}
              label={'Local'}
              placeholder={'Escolha o Local'}
              type="select"
              data={locals}
              dataKey={'_id'}
              dataLabel={'name'}
            />
            <Field
              component={VideoInput}
              name="url"
              type="text"
              label="Url do Video"
              sublabel="Pré-Visualizar"
              placeholder="youtube ou vimeo link"
            />
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManagePortfolioPage = reduxForm({
  form: 'manage_video_form',
  validate: validations
})(ManagePortfolioPage);

const mapStateToProps = state => ({
  locals: state.locals
});

export default withLocalize(connect(mapStateToProps)(ManagePortfolioPage));
