import React from 'react';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import {ColorSelection, OptionContent, ColorBox, ImageBox, ColorName} from '../SectionStyles';

const ColorCard = ({question, record, input}) => {
  const selected = input?.value && input.value.find(id => id === record._id);

  const toggleColor = (id) => {
    const list = [...input.value];
    const index = list.findIndex(x => x === id);

    if(index >= 0) list.splice(index, 1);
    else list.push(id);

    input.onChange(list);
  };

  return (
    <ColorSelection selected={selected} onClick={() => toggleColor(record._id)}>
      <OptionContent transparent>
        {record.defaultOption && record.defaultOption.image || record.image ? (
          <ImageBox src={record.defaultOption ? record.defaultOption.image.url : record.image.url} />
        ) : (
          <ColorBox color={record.defaultOption ? record.defaultOption.hex : record.hex} />
        )}
        <ColorName>{TranslateValue(record.defaultOption ? record.defaultOption.name : record.name)}</ColorName>
      </OptionContent>
    </ColorSelection>
  );
};

export default ColorCard;