import { SHOW_LOCALS } from "../ActionsType";

const locals = [
  {
    _id: 1,
    name: "Solar da Levada"
  },
  {
    _id: 2,
    name: "Quinta Lago dos Cisnes"
  }
];

export default function(state = locals, action) {
  switch (action.type) {
    case SHOW_LOCALS:
      return action.locals;

    default:
      return state;
  }
}
