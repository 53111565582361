import React from 'react'
import moment from 'moment'
import { navigate } from 'react-big-calendar/lib/utils/constants'
import { Badge, Col, Dropdown, Menu, Row, Tooltip } from 'antd'
import CurrencyComponent from '../currency/CurrencyComponent'
import AgendaActions from '../../infra/enum/agendaAction'
import { ReserveStatus, WeddingStatus } from '../../weddingday/reserves/ReserveAgenda'
import { isCurrentUserEmployeeAttending } from '../../infra/helpers/Helpers'
import Bookmark from "../../assets/icons/bookmark.svg";
import SelectedBookmark from "../../assets/icons/selected_bookmark.svg";
import Star from "../../assets/icons/star.svg";
import SelectedStar from "../../assets/icons/selected_star.svg";
import { WaitingIcon } from '../inputs/InputStyles'
import { WaitingActionType } from '../../weddingday/waitingDates/WaitingDatesPage'
import { AgendaType } from '../../weddingday/reserves/ReserveAgenda'
import { AgendaAttendingType } from '../../weddingday/reservesAttending/ReservesAttending'

function createCalendar(currentDate) {
    if (!currentDate) {
        currentDate = moment()
    } else {
        currentDate = moment(currentDate)
    }

    const first = currentDate.clone().startOf('month')
    const last = currentDate.clone().endOf('month')
    const weeksCount = Math.ceil((first.day() + last.date()) / 7)
    const calendar = Object.assign([], { currentDate, first, last })

    for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
        const week = []
        calendar.push(week)
        calendar.year = currentDate.year()
        calendar.month = currentDate.month()

        for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
            const date = currentDate.clone().set('date', day + 1 - first.day())
            date.calendar = calendar
            week.push(date)
        }
    }

    return calendar
}

function getTitle(title) {
    return title === 'OCCUPIED_DATE'
        ? 'Data Ocupada'
        : title === 'AVAILABLE_DATE_LAGO'
            ? 'Data Disponível QLC'
            : title === 'AVAILABLE_DATE_SOLAR'
                ? 'Data Disponível SLE'
                : title === 'AVAILABLE_DATE'
                    ? 'Data Disponível'
                    : title === 'UNAVAILABLE_DATE'
                        ? 'Data Indisponível'
                        : title === 'PRICE_TO_DEFINE'
                            ? 'Preços a definir'
                            : '';
}

function getHolidayName(holiday) {
    return holiday.localName;
}

function CalendarDate(props) {
    // console.warn('Props Date', props);
    const { dateToRender, dateOfMonth, events } = props;
    const date = moment.utc(dateToRender.toISOString());
    const tmpId = moment.utc(date).format('DDDYYYY');
    const event = events.find(f => f.id === tmpId);

    const className = event?.resource.isDateOccupied
        ? 'occupied'
        : '';

    const style = {};
    if (event?.resource?.companyRest) {
        if (Array.isArray(event?.resource?.colors) && event?.resource?.colors?.length > 1) {
            style.background = `linear-gradient(90deg ,${event?.resource?.colors[0]}, ${event?.resource?.colors[1]})`;
        } else if (Array.isArray(event?.resource?.colors) && event?.resource?.colors?.[0]) {
            style.background = `${event?.resource?.colors[0]}`;
        }
    }
    if (event?.resource?.weddings?.length > 0 && !event?.resource?.companyRest) {
        if (Array.isArray(event?.resource?.colors) && event?.resource?.colors?.length > 1) {
            style.background = `linear-gradient(90deg ,${event?.resource?.colors[0]}, ${event?.resource?.colors[1]})`;
        } else if (Array.isArray(event?.resource?.colors) && event?.resource?.colors?.[0]) {
            style.background = `${event?.resource?.colors[0]}`;
        }
    }

    const borderBottom = event && !event?.resource?.isCompanyRestDay &&
        !event?.resource?.isDateOccupied && event?.resource?.price
        && event?.resource?.price?.minPax > 0 && event?.resource?.price?.minPrice > 0 ?
        '1px solid rgb(255 255 255 / 90%)' : 'none';

    const marginBottom = event && !event?.resource?.isCompanyRestDay &&
        !event?.resource?.isDateOccupied && event?.resource?.price
        && event?.resource?.price?.minPax > 0 && event?.resource?.price?.minPrice > 0 ?
        5 : 0;

    if (dateToRender.month() < dateOfMonth.month() || dateToRender.month() > dateOfMonth.month()) {
        return (<button disabled={true} className="date next-month prev-month"></button>)
    }

    const tooltip = <React.Fragment>
        {(event && event.resource.holiday || event && event.resource.localHoliday) &&
            <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 13, color: '#dbdbdb' }}>
                {getHolidayName(event && event.resource.localHoliday ? event.resource.localHoliday : event.resource.holiday)}
            </p>
        }

        <p style={{ borderBottom, marginBottom, textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>
            {event ? getTitle(event.title) : ''}
        </p>
    </React.Fragment>

    return event && event?.resource?.companyRest
        ? <Tooltip title={tooltip} trigger='hover'>
            <button
                className={`date in-month ${className}`}
                style={style}>
                {event && event?.resource?.isCompanyRestDay
                    ? 'I'
                    : dateToRender.date()
                }
            </button>
        </Tooltip >
        : <Tooltip
            title={tooltip}
            trigger='hover'>
            <button
                className={`date in-month ${className}`}
                style={style}>
                {event?.resource?.localHoliday || event?.resource?.holiday
                    ? <span className='date-round'>
                        {event?.resource?.localHoliday
                            ? 'FM'
                            : 'F'
                        }
                    </span>
                    : <b>{dateToRender.date()}</b>}
            </button>
        </Tooltip >;
}

class YearWeddingsView extends React.Component {
    state = {
        date: moment(),
        events: [],
        months: [],
        activeLanguage: {},
        exportView: false,
    }

    componentDidMount() {
        this.setState({
            date: this.props.date,
            events: this.props.events || [],
            exportView: this.props.export || false,
        }, () => {
            this.setMonthsCalendar();
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.date !== prevProps.date) {
            this.setState({ date: this.props.date })
        }

        if (this.props.loading !== prevProps.loading) {
            this.setState({
                events: this.props.events || [],
                exportView: this.props.export || false,
            }, () => this.setMonthsCalendar())
        }
    }

    setMonthsCalendar = () => {
        const { date, events } = this.state;
        const months = [];
        moment().locale('pt')

        for (let i = 0; i < 12; i++) {
            const startDate = moment.utc(date).set('month', i).startOf('month');

            const monthInfo = {
                month: i,
                calendar: createCalendar(startDate),
                events: events?.length > 0
                    ? events.filter(f => moment.utc(f.start).month() === i)
                    : [],
            };
            months.push(monthInfo);
        }

        this.setState({ months });
    }

    getMonthName = (date) => {
        return moment(date).locale('pt').format('MMMM').toUpperCase();
    }

    getWeekdays = () => {
        moment.locale('pt');
        return moment.weekdaysShort();
    }

    render() {
        let { months, exportView } = this.state;

        return <Row gutter={[12, 12]} className="year">
            {months.map((month, monthIndex) => {
                return exportView
                    ? <Col key={monthIndex} span={8} className="month">
                        <div className="month-name-weddings">
                            {this.getMonthName(month)}
                        </div>
                        <div className='weekdays'>
                            {this.getWeekdays().map((day, index) => {
                                return <span key={index} className="weekday-weddings">
                                    {day}
                                </span>
                            })}
                        </div>
                        {month.calendar.map((week, index) => {
                            return <div key={index} className='month-days'>
                                {week.map(date => {
                                    // console.warn('Date', date)
                                    return <CalendarDate
                                        key={date.date()}
                                        dateToRender={date}
                                        events={month.events}
                                        dateOfMonth={month.calendar.currentDate}
                                    />
                                })}
                            </div>
                        })}
                    </Col>
                    : <Col key={monthIndex} xs={24} sm={12} md={8} lg={8} xl={6} className="month">
                        <div className="month-name-weddings">
                            {this.getMonthName(month)}
                        </div>
                        <div className='weekdays'>
                            {this.getWeekdays().map((day, index) => {
                                return <span key={index} className="weekday-weddings">
                                    {day}
                                </span>
                            })}
                        </div>
                        {month.calendar.map((week, index) => {
                            return <div key={index} className='month-days'>
                                {week.map(date => {
                                    // console.warn('Date', date)
                                    return <CalendarDate
                                        key={date.date()}
                                        dateToRender={date}
                                        events={month.events}
                                        dateOfMonth={month.calendar.currentDate}
                                    />
                                })}
                            </div>
                        })}
                    </Col>
            })}
        </Row>
    }
}

// YearWeddingsView.propTypes = {
//     date: PropTypes.instanceOf(Date).isRequired,
// }

YearWeddingsView.range = date => {
    //     return [dates.startOf(date, 'year')]
}

YearWeddingsView.navigate = (date, action) => {
    switch (action) {
        case navigate.PREVIOUS:
            return moment.utc(date).add(1, 'year') //dates.add(date, -1, 'year')
        case navigate.NEXT:
            return moment.utc(date).subtract(1, 'year')
        default:
            return date
    }
}

YearWeddingsView.title = (date, { localizer }) => localizer.format(date, 'yearHeaderFormat')

export default YearWeddingsView
