import React from 'react';
import {Field} from 'redux-form';
import {
  TableContainer,
  TableHeader,
  TableHeaderColumn,
  TableHeaderColumnContent,
  TableLine,
  TableColumn,
  NoResults,
  SpinLoading
} from './Styles';
import TextInput from '../../components/inputs/TextInput';
import NumberInput from '../../components/inputs/NumberInput';
import SelectInput from '../../components/inputs/SelectInput';
import moment from 'moment';
import { getLocal } from '../../infra/services/wedding/weddingUtils';

const WeddingsList = ({ fields, staffMeals, loading }) => {
  const renderDate = (index) => {
    const field = fields.get(index);
    return moment.utc(field.date).format('DD-MM-YYYY');
  };

  const renderLocal = (index) => {
    const field = fields.get(index);
    return getLocal(field);
  };

  return (
    <TableContainer>
      <TableHeader>
        <TableHeaderColumn width='25%'>
          <TableHeaderColumnContent>Data</TableHeaderColumnContent>
        </TableHeaderColumn>
        <TableHeaderColumn width='25%'>
          <TableHeaderColumnContent>Local</TableHeaderColumnContent>
        </TableHeaderColumn>
        <TableHeaderColumn width='25%'>
          <TableHeaderColumnContent>Prato</TableHeaderColumnContent>
        </TableHeaderColumn>
        <TableHeaderColumn width='25%'>
        <TableHeaderColumnContent>Nº de Funcionários</TableHeaderColumnContent>
        </TableHeaderColumn>
      </TableHeader>
      {
        loading ?
        <SpinLoading/>
        :
        fields.length > 0 ?
        fields.map((field, index) => 
          <TableLine key={index}>  
            <TableColumn width='25%'>
              <Field
                component={TextInput}
                name={`${field}.date`}
                type='text'
                disabled={true}
                noLabel={true}
                meta={{}}
                input={{ value: renderDate(index)}}
              />
            </TableColumn>
            <TableColumn width='25%'>
              <Field
                component={TextInput}
                name={`${field}.wedding_place`}
                type='text'
                disabled={true}
                noLabel={true}
                meta={{}}
                input={{ value: renderLocal(index)}}
              />
            </TableColumn>
            <TableColumn width='25%'>
              <Field
                component={SelectInput}
                name={`${field}.staff_meal`}
                data={staffMeals}
                dataKey={'_id'}
                dataLabel={'name'}
                translatable={true}
                placeholder={'Selecione um prato'}
                allowClear={true}
              />
            </TableColumn>
            <TableColumn width='25%'>
              <Field
                component={NumberInput}
                name={`${field}.staff_number`}
                step={1}
                min={0}
              />
            </TableColumn>
          </TableLine>
        )
        :
        <NoResults>Não existem casamentos para a pesquisa atual!</NoResults>
      }
    </TableContainer>
  );
};

export default WeddingsList;