import React from "react";
import {
  QuantityContainer,
  ButtonSubtract,
  ButtonAdd,
  QuantityInput
} from "./InputStyles";

const numberRegex = /^[0-9\b]+$/;

const Quantity = ({ optionId, input }) => {
  const changeQuantity = (type, qtd) => {
    const list = [...input.value];
    const index = list.findIndex(x => x.id === optionId);
    const response = list.find(x => x.id === optionId);

    if(index >= 0) {
      //Subtract
      if(type === 1) {
        list[index] = {
          'id': optionId, 
          'qtd': parseInt(list[index].qtd) - 1 <= 0 ? 1 : parseInt(list[index].qtd) - 1,
          'cost': response.cost
        };
      }
      //Add
      else if(type === 2) {
        list[index] = {
          'id': optionId, 
          'qtd': parseInt(list[index].qtd) + 1,
          'cost': response.cost
        };
      }
      //Input value directly
      else if(type === 0) {
        if(numberRegex.test(qtd)) {
          list[index] = {
            'id': optionId, 
            'qtd': parseInt(qtd),
            'cost': response.cost
          };
        }
        else if(qtd === '') {
          list[index] = {
            'id': optionId, 
            'qtd': 1,
            'cost': response.cost
          };
        }
      }

      input.onChange(list);
    }
  }

  const getQuantity = () => {
    let qtd = 1;

    if(input?.value) {
      let found = input.value.find(x => x.id === optionId);

      if(found?.qtd) {
        qtd = found.qtd;
      }
    }

    return qtd;
  }

  return (
    <QuantityContainer onClick={(e) => e.stopPropagation()}>
      <ButtonSubtract onClick={() => changeQuantity(1)}>-</ButtonSubtract>
      <QuantityInput onChange={(e) => changeQuantity(0, e.target.value)} value={getQuantity()}/>
      <ButtonAdd onClick={() => changeQuantity(2)}>+</ButtonAdd>
    </QuantityContainer>
  );
};

export default Quantity;