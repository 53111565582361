import styled from 'styled-components';
import { Table } from 'antd';

export const StyledTable = styled(Table)`
  margin: 0;
  
  &&& .ant-pagination {
    float: left;
  }
  
  .ant-table {
    overflow: auto;
  }

  table {
    background-color: #fff;
    tr {
      th {
        color: ${p => p.secondary ? '#565656' : p.theme.textColor};
        background-color: ${p => p.secondary ? p.theme.tableSecondaryBg : p.theme.secondaryColor} !important;
        padding: ${p => p.secondary ? p.theme.tableSecondaryPadding : '5px'} !important;
        line-height: ${p => p.secondary ? p.theme.tableSecondaryLineHeight : 'inherit'};
      }
    }
    
    td {
      max-width: 400px;
      vertical-align: ${({ alignContentTop }) => alignContentTop ? 'top' : 'inherit'};
      padding: ${p => p?.secondary ? p.theme.tableSecondaryPadding : p?.noPadding ? '0px' : '5px'} !important;
    }
  }

  .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr, .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr, .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr, .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr, .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr, .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr, .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr, .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr {
    border-bottom: 1px solid ${p => p.theme.tableBorder} !important;
  }

  .ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid ${p => p.theme.tableBorder} !important;
}

  .ant-table-body {
    min-width: auto !important;
    width: auto;
  }

  .ant-table-tbody > tr {
    cursor: ${p => (p.onPressRow ? 'default' : 'pointer')};
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${p => p.theme.tableBorder} !important;
    position: relative;
  }

  .ant-table-tbody > tr > td.align-right {
    text-align: right;
  }

  .ant-table-tbody > tr > td.align-center {
    text-align: center;
  }

  .ant-table-expand-icon-th, .ant-table-row-expand-icon-cell {
    width: 30px !important;
    min-width: 30px !important;
    text-align: center !important;
    padding: 5px !important;
  }

  ${({ fixedHeader, secondary }) => fixedHeader && !secondary
    ? `.ant-table-thead {
        position: sticky;
        top: 150px;
        z-index: 99;
      }`
    : `.ant-table-thead {
      position: relative !important;
      top: unset;
      z-index: unset;
    }`}

  .ant-table-thead > tr > th.header-align-center {
    text-align: center;
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0px;
  }

  .ant-table-small tr.ant-table-expanded-row td > .ant-table-wrapper {
    margin: 0px;
    display: inline-block;
    width: 100%;
  }
`;

export const FakePagination = styled.ul`
  display: flex;
  justify-content: flex-start;
  margin-block-start: 0;
  padding-inline-start: 0;
  float: none;
  width: 100%;

  .fake-pagination {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
  }

  .fake-pagination.mini .fake-pagination-total-text, 
  .fake-pagination.mini .fake-pagination-simple-pager {
    height: 24px;
    line-height: 24px;
  }

  .fake-pagination-total-text {
    display: inline-block;
    height: 32px;
    margin-right: 8px;
    line-height: 30px;
    vertical-align: middle;
  }

  .fake-pagination-prev,
  .fake-pagination-next {
    outline: 0;
  }

  .fake-pagination-prev,
  .fake-pagination-next, 
  .fake-pagination-jump-prev,
  .fake-pagination-jump-next {
    display: inline-block;
    min-width: 24px;
    height: 24px;
    margin: 0;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.65);
    font-family: Arial;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s;
  }

  .fake-pagination-disabled,
  .fake-pagination-disabled:hover,
  .fake-pagination-disabled:focus {
    cursor: not-allowed;
  }

  .fake-pagination-disabled a,
  .fake-pagination-disabled:hover a,
  .fake-pagination-disabled:focus a,
  .fake-pagination-disabled .fake-pagination-item-link,
  .fake-pagination-disabled:hover .fake-pagination-item-link,
  .fake-pagination-disabled:focus .fake-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
  }

  .fake-pagination-prev .fake-pagination-item-link,
  .fake-pagination-next .fake-pagination-item-link {
    display: block;
    height: 100%;
    font-size: 12px;
    text-align: center;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    outline: none;
    transition: all 0.3s;
  }

  .fake-pagination-prev a, 
  .fake-pagination-next a {
    color: rgba(0, 0, 0, 0.65);
    // user-select: none;
  }
`;