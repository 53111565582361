import client from '../config/AxiosConfig';

export const GetSupplierCategories = async (page, limit) =>
  client.get(`/supplier_categories/${page}/${limit}`);

export const GetSupplierCategory = async id =>
  client.get(`/supplier_categories/${id}`);

export const CreateSupplierCategories = async data =>
  client.post('/supplier_categories', data);

export const UpdateSupplierCategory = async (id, data) =>
  client.put(`/supplier_categories/${id}`, data);

export const GetSuppliers = async (page, limit) =>
  client.get(`/suppliers/${page}/${limit}`);

export const GetSupplier = async id => client.get(`/suppliers/${id}`);

export const CreateSupplier = async data => client.post('/suppliers', data);

export const UpdateSupplier = async (id, data) =>
  client.put(`/suppliers/${id}`, data);

export const GetAllSupplierCategories = async () =>
  client.get('/supplier_categories');

export const DeleteSupplier = async id => client.delete(`/suppliers/${id}`);

export const DeleteSupplierCategory = async id => client.delete(`/supplier_categories/${id}`);