import React, { useState, useEffect, Fragment } from 'react';
import { Modal, notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { BasicInfoSection, SectionTitle, SectionCTA } from '../ProcessStyles';
import {
  QuestionTitleContainer,
  QuestionTitle
} from '../../ChoicesPage/SectionStyles';
import { AddUpgrade, getWeddingInfo, MngUpgrade, RemoveUpgrade } from '../../../infra/requests/WeddingProcessRequests';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import { BaseButton } from '../../../components/buttons/BaseButton';
import UpgradeModalComponent from './UpgradeModalComponent';
import {
  ResponseSection,
  ResponsePill,
  OptionResponse,
  ExtraPill
} from '../../ChoicesPage/SectionStyles';
import CurrencyComponent from '../../../components/currency/CurrencyComponent';
import { getUpgradePrintedConfirmation } from '../../../infra/services/wedding/printedUtils';
const { confirm } = Modal;

const UpgradeComponent = ({
  weddingInfo,
  upgrade,
  upgradeList,
  dispatch,
  history,
  updateWeddingUpgrade
}) => {
  const [showModal, toggleModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    toggleModal(false);
  };

  const openModal = () => {
    dispatch(
      initialize('upgrade_form', {
        upgrade: upgrade ? upgrade._id : undefined
      })
    );

    toggleModal(true);
  };

  const onModalSubmit = async values => {
    if (weddingInfo?.printed) {
      confirm({
        ...getUpgradePrintedConfirmation(),
        onOk() { updateUpgrade(values); },
        onCancel() { closeModal(); },
      });
    } else updateUpgrade(values);
  }

  const updateUpgrade = async values => {
    try {
      setLoading(true);
      // console.warn('val', values);

      const upgradeDetail = upgradeList.find(f => f?._id === values?.upgrade);
      // console.warn('upgradeDetail', upgradeDetail);

      if (upgradeDetail?.hasPremiumOptions) {
        confirm({
          className: 'upgradeConfirm',
          title: 'Tem a certeza que pretende ativar este upgrade?',
          content: 'O upgrade Wedding Prime poderá substituir as escolhas realizadas pelas escolhas pertencentes ao serviço premium',
          okText: 'Ativar',
          async onOk() {
            const result = await MngUpgrade(weddingInfo._id, values);
            // console.warn('Res', result);

            if (result.success) {
              await updateWeddingUpgrade(result.data.upgrade);

              notification.success({
                message: 'Gravado com sucesso',
                description: 'A informação foi gravada com sucesso!'
              });
            }

            setLoading(false);
            closeModal();
          },
          onCancel() {
            setLoading(false);
            closeModal();
          }
        });
      } else {
        const result = await MngUpgrade(weddingInfo._id, values);
        // console.warn('Res', result);

        if (result.success) {
          await updateWeddingUpgrade(result.data.upgrade);

          notification.success({
            message: 'Gravado com sucesso',
            description: 'A informação foi gravada com sucesso!'
          });
        }

        setLoading(false);
        closeModal();
      }

      // If it's still the same we don't do anything
      // if (!upgrade || values.upgrade !== upgrade?._id) {
      //   // // We always have to remove the current upgrade in this case
      //   // let {success, data} = await RemoveUpgrade(weddingInfo._id);

      //   // // If there's a new one, save it
      //   // if(values.upgrade) {
      //   //   ({success, data} = await AddUpgrade(weddingInfo._id, values));
      //   // }

      //   const result = await MngUpgrade(weddingInfo._id, values);
      //   // console.warn('Res', result);

      //   if (result.success) {
      //     await updateWeddingUpgrade(result.data.upgrade);

      //     notification.success({
      //       message: 'Gravado com sucesso',
      //       description: 'A informação foi gravada com sucesso!'
      //     });
      //   }
      // }
    } catch (e) {
      console.error(e);
      setLoading(false);
      closeModal();
    }
  };

  return (
    <BasicInfoSection>
      <SectionTitle>
        Upgrade
        <SectionCTA>
          <BaseButton
            type={'default'}
            icon={'arrow-right'}
            text={'Selecionar Upgrade'}
            onClick={() => openModal()}
          />
        </SectionCTA>
      </SectionTitle>
      <QuestionTitleContainer>
        <QuestionTitle>Upgrade Selecionado</QuestionTitle>
      </QuestionTitleContainer>
      <ResponseSection>
        {
          upgrade ?
            <OptionResponse style={{ cursor: 'pointer' }} onClick={() => history.push(`/wedding/${weddingInfo._id}/upgrade/${upgrade._id}`)}>
              <ResponsePill>{TranslateValue(upgrade.name)}</ResponsePill>
              <ExtraPill last={true}>
                <CurrencyComponent value={upgrade.price == 0 ? '00.00' : upgrade.price} />
                /pax
              </ExtraPill>
            </OptionResponse>
            :
            <div>Não existe nenhum Upgrade selecionado!</div>
        }
      </ResponseSection>
      {showModal && (
        <UpgradeModalComponent
          open={showModal}
          onSubmit={onModalSubmit}
          loading={loading}
          closeModal={closeModal}
          upgradeList={upgradeList}
          upgrade={upgrade}
        />
      )}
    </BasicInfoSection>
  );
};

const mapStateToProps = state => ({
  weddingInfo: state.wedding.wedding
});

export default withRouter(connect(mapStateToProps)(UpgradeComponent));