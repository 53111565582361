import React, { Component, Fragment } from 'react';
import { Checkbox, Icon, Popconfirm, Row, Tag } from 'antd';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton
} from '../../styles/BasicStyles';
import {
  GetWeddings,
  TogglePublic,
  DeleteWedding,
  GetWeddingsByUser,
  GetAllWeddingsByUser,
  GetWeddingsByEmployee
} from '../../infra/requests/WeddingRequests';
import { ArchiveWedding } from '../../infra/requests/ArchivedRequests';
import BaseButton from '../../components/buttons/BaseButton';
import moment from 'moment';
import CurrencyComponent from '../../components/currency/CurrencyComponent';
import { LocalLabel, StateLabel } from './Styles';
import { connect } from 'react-redux';
import { ClearWedding } from '../../redux/wedding/wedding.actions';
import { bindActionCreators } from 'redux';
import { GetTeamBookingByWedding } from '../../infra/requests/TeamBookingRequests';
import { getLocal, getLocalColor, getScheduler, getWeddingName, getWeddingTeamAndEmployeesInCharge } from '../../infra/services/wedding/weddingUtils';
import { getCurrentUser, getUserId, isCurrentUserAdmin, isCurrentUserChief, isCurrentUserEmployee, isCurrentUserHumanResources, isEmployeeOrChief } from '../../infra/helpers/Helpers';
import EmployeeWeddingsFilters from './EmployeeWeddingsFilters';
import { GetOurDayByWedding } from '../../infra/requests/OurDayRequests';
import { GetColorSystemByType } from '../../infra/requests/ColorSystemRequests';
import { ConfirmWeddingTeamByemployee, GetWeddingTeamByWedding } from '../../infra/requests/WeddingTeamRequests';
import { GetSubcategories, GetSubcategoriesByType, GetSubcategoriesInCharge } from '../../infra/requests/SubcategoryRequests';
import { GetEmployeeByUser } from '../../infra/requests/EmployeeRequests';
import { GetTextColor } from '../../infra/services/text/textUtils';

class EmployeeWeddingsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    filters: {
      calendar: true,
      month: moment.utc().startOf('month').format('MM/YYYY')
    },
    currentUser: null,
    currentEmployee: null,
    subcategories: [],

    columnsRH: [
      {
        title: 'Data',
        render: data => moment.utc(data?.wedding.date).format('DD-MM-YYYY')
      },
      {
        title: 'Local',
        render: data => {
          const local = getLocalColor(data?.wedding, this.state.colors);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
        }
      },
      {
        title: 'Horário Chegada à Quinta',
        render: data => data.scheduler.arrivingTime
      },
      {
        title: 'Horário Aperitivos',
        render: data => data.scheduler.snackTime
      },
      {
        title: 'Horário Jantar',
        render: data => data.scheduler.dinnerTime
      },
      {
        title: 'Interesse Manifestado',
        render: data => {
          const weddingInterest = data.interest && data.interest.length > 0 ? true : false;
          return <Tag color={weddingInterest ? 'green' : 'red'}>{weddingInterest ? 'Sim' : 'Não'}</Tag>
        }
      },
      {
        title: 'Selecionado para',
        render: data => {
          const teamPublished = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.published).length > 0 ? true : false;
          const weddingTeam = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.employee.id === this.state.currentEmployee.id).length > 0 ? data.teamComplete.filter(f => f.employee.id === this.state.currentEmployee.id) : null;
          // console.warn('teamPublished', teamPublished);
          // console.warn('weddingTeam', weddingTeam);
          return teamPublished && weddingTeam ? weddingTeam.map(m => {
            return <div>{m.subcategory.name}</div>
          }) : null;
        }
      },
      {
        title: 'Horário de Entrada',
        render: data => {
          const teamPublished = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.published).length > 0 ? true : false;
          const weddingTeam = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.employee.id === this.state.currentEmployee.id).length > 0 ? data.teamComplete.filter(f => f.employee.id === this.state.currentEmployee.id) : null;
          // console.warn('teamPublished', teamPublished);
          // console.warn('weddingTeam', weddingTeam);
          return teamPublished && weddingTeam ? weddingTeam.map(m => {
            return <div>{moment(m.startTime).format('HH:mm')}</div>
          }) : null;
        }
      },
      {
        title: 'Confirmar',
        render: data => {
          // to test only
          // data.allowToConfirm = true;
          // const weddingDate = new Date(data.wedding.date);
          // const now = new Date(weddingDate.toDateString() + ' 00:00:00');
          // now.setDate(now.getDate() - 1);
          // const startWeddingDate = new Date(weddingDate.toDateString() + ' 00:00:00');
          //  console.warn('Data', data);
          // console.warn('Now', now);
          // console.warn('startWeddingDate', startWeddingDate);
          // data.allowToConfirm = moment(now).isBefore(startWeddingDate);
          const teamPublished = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.published).length > 0 ? true : false;

          return teamPublished && data.empWeddingTeam && data.empWeddingTeam.length > 0 ? (
            <div className="sc-dxgOiQ fWHHkk">
              <Checkbox
                checked={data.confirmed}
                disabled={!data.allowToConfirm} // disabled={this.state.payments.length > 0}
                onChange={(e) => this.confirmWeddingTeam(e, data)}>
                Sim
              </Checkbox>
            </div>
          ) : null;
        }
      },
      {
        title: 'Equipa Confirmada',
        render: data => {
          const confirmed = data.teamComplete.length > 0 && data.teamComplete.filter(f => f.confirmed).length === data.teamComplete.length ? true : false;
          return <Tag color={confirmed ? 'green' : 'red'}>{confirmed ? 'Sim' : 'Não'}</Tag>
        }
      },
      {
        title: 'Acções',
        width: '120px',
        render: data => (
          <Fragment>
            {isCurrentUserHumanResources() && data.teamBooking && data.teamBooking.length > 0 && data.teamBooking.filter(t => t.showEmployee).length > 0 ||
              isCurrentUserChief() && data.employeesInCharge && data.employeesInCharge.chief.length > 0 && data.employeesInCharge.chief.find(f => f.employee.id === this.state.currentEmployee.id) && data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(t => t.published).length > 0 ? (
              <TableButton onClick={() => {
                const { history } = this.props;
                history.push(`/wedding-info/${data.wedding._id}`);
              }}>
                <Icon type="team" />
                {' Equipa'}
              </TableButton>
            ) : null}

            {/*!isCurrentUserHumanResources() && !(data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(t => t.published).length > 0) */}
            {(isCurrentUserEmployee() || isCurrentUserChief()) && data.teamBooking && data.teamBooking.length > 0 && data.teamBooking.filter(t => t.showEmployee).length > 0 && data.allowToRegisterInterest ? (
              <TableButton onClick={() => {
                const { history } = this.props;
                history.push(`/wedding-interest/${data.wedding._id}`)
              }}>
                <Icon type="search" />
                &nbsp; Consultar
              </TableButton>
            ) : null}
          </Fragment>
        )
      }
    ],
    columnsEmployee: [
      {
        title: 'Data',
        render: value => moment(value?.wedding?.date).format('DD-MM-YYYY')
      },
      {
        title: 'Local',
        render: value => {
          const local = getLocalColor(value?.wedding, this.state.colors);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
        }
      },
      {
        title: 'Horário Chegada à Quinta',
        render: data => data.scheduler.arrivingTime
      },
      {
        title: 'Horário Aperitivos',
        render: data => data.scheduler.snackTime
      },
      {
        title: 'Horário Jantar',
        render: data => data.scheduler.dinnerTime
      },
      {
        title: 'Interesse Manifestado',
        render: data => {
          const weddingInterest = data.interest && data.interest.length > 0 ? true : false;
          return <Tag color={weddingInterest ? 'green' : 'red'}>{weddingInterest ? 'Sim' : 'Não'}</Tag>
        }
      },
      {
        title: 'Selecionado para',
        render: data => {
          const teamPublished = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.published).length > 0 ? true : false;
          const weddingTeam = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.employee.id === this.state.currentEmployee.id).length > 0 ? data.teamComplete.filter(f => f.employee.id === this.state.currentEmployee.id) : null;
          // console.warn('teamPublished', teamPublished);
          // console.warn('weddingTeam', weddingTeam);
          return teamPublished && weddingTeam ? weddingTeam.map(m => {
            return <div>{m.subcategory.name}</div>
          }) : null;
        }
      },
      {
        title: 'Horário de Entrada',
        render: data => {
          const teamPublished = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.published).length > 0 ? true : false;
          const weddingTeam = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.employee.id === this.state.currentEmployee.id).length > 0 ? data.teamComplete.filter(f => f.employee.id === this.state.currentEmployee.id) : null;
          // console.warn('teamPublished', teamPublished);
          // console.warn('weddingTeam', weddingTeam);
          return teamPublished && weddingTeam ? weddingTeam.map(m => {
            return <div>{moment(m.startTime).format('HH:mm')}</div>
          }) : null;
        }
      },
      {
        title: 'Confirmar',
        render: data => {
          // to test only
          // data.allowToConfirm = true;
          // const weddingDate = new Date(data.wedding.date);
          // const now = new Date(weddingDate.toDateString() + ' 00:00:00');
          // now.setDate(now.getDate() - 1);
          // const startWeddingDate = new Date(weddingDate.toDateString() + ' 00:00:00');
          //  console.warn('Data', data);
          // console.warn('Now', now);
          // console.warn('startWeddingDate', startWeddingDate);
          // data.allowToConfirm = moment(now).isBefore(startWeddingDate);
          const teamPublished = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.published).length > 0 ? true : false;

          return teamPublished && data.empWeddingTeam && data.empWeddingTeam.length > 0 ? (
            <div className="sc-dxgOiQ fWHHkk">
              <Checkbox
                checked={data.confirmed}
                disabled={!data.allowToConfirm} // disabled={this.state.payments.length > 0}
                onChange={(e) => this.confirmWeddingTeam(e, data)}>
                Sim
              </Checkbox>
            </div>
          ) : null;
        }
      },
      {
        title: 'Acções',
        width: '120px',
        render: data => (
          <Fragment>
            {/* {console.warn('Data', data)} */}
            {/* {console.warn('CheckCharge', data.employeesInCharge && data.employeesInCharge.chief.length > 0 && data.employeesInCharge.chief.find(f => f.employee.id === this.state.currentEmployee.id))}
            {console.warn('Check', isCurrentUserChief() && data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(t => t.published).length > 0)} */}
            {isCurrentUserHumanResources() && data.teamBooking && data.teamBooking.length > 0 && data.teamBooking.filter(t => t.showEmployee).length > 0 ||
              isCurrentUserChief() && data.employeesInCharge && data.employeesInCharge.chief.length > 0 && data.employeesInCharge.chief.find(f => f.employee.id === this.state.currentEmployee.id) && data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(t => t.published).length > 0 ? (
              <TableButton onClick={() => {
                const { history } = this.props;
                history.push(`/wedding-info/${data.wedding._id}`);
              }}>
                <Icon type="team" />
                &nbsp; Equipa
              </TableButton>
            ) : null}

            {/*!isCurrentUserHumanResources() && !(data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(t => t.published).length > 0) */}
            {(isCurrentUserEmployee() || isCurrentUserChief()) && data.teamBooking && data.teamBooking.length > 0 && data.teamBooking.filter(t => t.showEmployee).length > 0 && data.allowToRegisterInterest ? (
              <TableButton onClick={() => {
                const { history } = this.props;
                history.push(`/wedding-interest/${data.wedding._id}`)
              }}>
                <Icon type="search" />
                &nbsp; Consultar
              </TableButton>
            ) : null}
          </Fragment>
        )
      }
    ],
    rows: [],
    rowsSearch: null,
    total: 0,
    colors: {
      colorSys1: null,
      colorSys2: null
    },
  };

  componentDidMount = async () => {
    const { location } = this.props;
    let { filters } = this.state;

    if (isCurrentUserAdmin()) return this.props.history.push('/404');

    const filterDate = new URLSearchParams(location.search).get('date');
    if (filterDate && parseInt(filterDate)) {
      if (moment.utc(parseInt(filterDate)).isValid()) {
        filters.date = moment.utc(parseInt(filterDate)).startOf('day');
        filters.month = null;
      }
    }
    this.setState({ filters });

    await this.getLocalColors();
    const localUser = getCurrentUser();

    const resEmployee = isCurrentUserChief() || isCurrentUserEmployee()
      ? await GetEmployeeByUser(localUser._id)
      : null;
    this.setState({
      currentUser: localUser,
      currentEmployee: resEmployee && resEmployee.data ? resEmployee.data : null
    }, () => this.getSubcategories())
  };

  getSubcategories = async () => {
    const resSubcategory = await GetSubcategoriesInCharge();
    this.setState({ subcategories: resSubcategory.success && resSubcategory.data ? resSubcategory.data : {} }, () => { this.updateDataTable() });
  }

  getLocalColors = async () => {
    const resColorSys1 = await GetColorSystemByType(4);
    const colorSys1 = resColorSys1.success && resColorSys1.data ? resColorSys1.data.color : '#f7b5d6';
    const resColorSys2 = await GetColorSystemByType(5);
    const colorSys2 = resColorSys2.success && resColorSys2.data ? resColorSys2.data.color : '#b6d7a9';
    this.setState({ colors: { colorSys1, colorSys2 } });
  }

  updateDataTable = async () => {
    const { currentEmployee, subcategories, filters } = this.state;
    this.setState({ loading: true });
    const now = moment.utc();

    try {
      let filter = { calendar: true };
      if (filters?.date) {
        filter['date'] = filters?.date;
        filter['month'] = null;
      } else {
        const month = moment(filters?.month, ['MM/YYYY']).utc(true).startOf('day').toISOString();
        filter['month'] = month;
      }
      if (filters?.wedding_place) {
        filter['wedding_place'] = filters?.wedding_place;
      }
      //const result = await GetAllWeddingsByUser(getUserId(), JSON.stringify(filter));
      GetAllWeddingsByUser(getUserId(), JSON.stringify(filter)).then((result) => {
        // console.warn('Res', result);

        for (let index = 0; index < result.data.length; index++) {
          const weddingTeam = [];
          result.data[index].teamComplete.forEach(t => weddingTeam.push({ weddingTeam: t }));
          const team = getWeddingTeamAndEmployeesInCharge({ data: weddingTeam }, subcategories);

          result.data[index]['weddingTeam'] = team.weddingTeam;
          result.data[index]['employeesInCharge'] = team.employeesInCharge;
          result.data[index].scheduler = getScheduler(result.data[index].ourDay);
          result.data[index]['empWeddingTeam'] = result.data[index].teamComplete.length > 0 ? result.data[index].teamComplete.filter(f => f.employee.id === this.state.currentEmployee.id) : [];
          result.data[index]['confirmed'] = result.data[index].empWeddingTeam.length > 0 && result.data[index].empWeddingTeam.filter(f => f.confirmed).length > 0 ? true : false;

          const weddingDate = moment.utc(result.data[index].wedding.date);
          const startWeddingDate = moment.utc(weddingDate).startOf('day');
          const endWeddingDate = moment.utc(weddingDate).endOf('day');
          result.data[index]['allowToConfirm'] = now.isBefore(startWeddingDate) ? true : false;
          result.data[index]['allowToRegisterInterest'] = now.isSameOrBefore(endWeddingDate) ? true : false;
        }
        //result.data.forEach(r => r.scheduler = getScheduler(r.ourDay));
        // console.warn('Rows', result.data);
        this.setState({
          rows: result.data,
          total: result.data.length,
          loading: false
        });
      })

    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  // handleChangePage = currentPage => {
  //   this.setState({ currentPage }, this.updateDataTable);
  // };

  // handleChangeRowsPerPage = (currentSize, pageSize) => {
  //   this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  // };

  handleFilterChange = filters => {
    // this.setState({ filters, currentPage: 1 }, this.updateDataTable);
    this.setState({ filters }, () => this.updateDataTable());

    // let { rows, rowsSearch, searchMonth } = this.state;
    // if (filtersObj.wedding_place === undefined && filtersObj.month !== undefined) {
    //   this.setState({ rowsSearch: null })
    //   this.setState({ searchMonth: filtersObj.month }, () => { this.updateDataTable() });
    // }

    // if (filtersObj.wedding_place !== undefined) {
    //   rowsSearch = rows.filter(f => f.wedding.wedding_place == filtersObj.wedding_place);
    //   this.setState({ rowsSearch, total: rowsSearch.length });
    // }
  };

  handlePreviousMonth = () => {
    let { filters } = this.state;
    if (filters?.month) {
      const monthToSearch = moment.utc(filters?.month, 'MM/YYYY').subtract(1, 'month');
      filters.month = monthToSearch.format('MM/YYYY');
      this.setState({ filters }, () => this.updateDataTable());
    } else if (filters?.date) {
      filters.date = moment.utc(filters?.date).subtract(1, 'month');
      this.setState({ filters }, () => this.updateDataTable());
    }
  }

  handleNextMonth = () => {
    let { filters } = this.state;
    if (filters?.month) {
      const monthToSearch = moment.utc(filters?.month, 'MM/YYYY').add(1, 'month');
      filters.month = monthToSearch.format('MM/YYYY');
      this.setState({ filters }, () => this.updateDataTable());
    } else if (filters?.date) {
      filters.date = moment.utc(filters?.date).add(1, 'month');
      this.setState({ filters }, () => this.updateDataTable());
    }
  }

  confirmWeddingTeam = async (event, data) => {
    // console.warn('event', event.target.checked);
    // console.warn('Data', data);
    this.setState({ loading: true });

    data.empWeddingTeam.forEach(weddingTeam => {
      weddingTeam.confirmed = event.target.checked;
    });
    // console.warn('empWeddingTeam', data.empWeddingTeam);

    const result = await ConfirmWeddingTeamByemployee(data.empWeddingTeam);
    // console.warn('Result', result);
    if (!result.success) {
      console.error(result.errors);
      this.setState({ loading: false });
    } else {
      this.updateDataTable();
    }
  }

  render() {
    const { history } = this.props;
    const { loading, currentPage, pageSize, subcategories } = this.state;
    const { columnsRH, columnsEmployee, rows, rowsSearch, total } = this.state;
    const { filters } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Casamentos</PageTitle>
            Lista de Casamentos
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer buttons={0}>
          <EmployeeWeddingsFilters
            loading={loading}
            month={filters?.month}
            date={filters?.date}
            queryChange={this.handleFilterChange}
          // subcategories={subcategories}
          />
          <Table
            columns={isCurrentUserHumanResources() ? columnsRH : columnsEmployee}
            // currentPage={currentPage}
            // pageSize={pageSize}
            // defaultPageSize={pageSize}
            loading={loading}
            rows={rowsSearch ? rowsSearch : rows}
            showHeader={true}
            emptyIcon="calendar"
            emptyText={'There are no weddings'}
            total={total}
            rowKey={'_id'}
            hasPagination={false}
          // onPressRow={this.openProcess}
          // handleChangePage={this.handleChangePage}
          // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          <ul className="ant-pagination ant-table-pagination mini" style={{ float: 'left' }}>
            <li className="ant-pagination-total-text">{`1-${total} items`}</li>
            <li title="Mês Anterior" className={`ant-pagination-prev`} aria-disabled={false}>
              <a className="ant-pagination-item-link" onClick={(value) => this.handlePreviousMonth()}>
                <Icon type="left" />
              </a>
            </li>
            <li title="Mês Seguinte" className="ant-pagination-next" aria-disabled="false">
              <a className="ant-pagination-item-link" onClick={(value) => this.handleNextMonth()}>
                <Icon type="right" />
              </a>
            </li>
            {/* <ChangeSizerInput input={{ value: pageSize, onChange: value => this.handleChangeRowsPerPage(pageSize, value) }} /> */}
          </ul>
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({ wedding: state.wedding });

const mapActionToProps = dispatch => bindActionCreators({ ClearWedding }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(EmployeeWeddingsPage);
