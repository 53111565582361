import styled from "styled-components";

export const ImageHover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  i {
    padding: 10px;
    margin: 0 10px;
    font-size: 30px;
    cursor: pointer;
  }

  &:hover {
    opacity: 1;
  }
`;

export const Title = styled.div`
  font-size: 30px;
`;

export const TagsContainer = styled.div`
  display: inline-block;
  width: 100%;

  span {
    text-transform: uppercase;
    margin: 5px 10px;
    font-size: 16px;
    line-height: 30px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ActionsSection = styled.div`
  display: inline-block;
  width: 100%;
`;
