import React, { Fragment } from 'react';
import { Popconfirm } from 'antd';
import moment from 'moment';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import { ResponsePill, ExtraPill } from '../SectionStyles';

const PrintedDelete = <div>
  <div>Tem a certeza que quer apagar o ficheiro?</div>
  <b>Este processo de casamento já foi impresso!</b>
</div>

const Delete = <div>
  <div>Tem a certeza que quer apagar o ficheiro?</div>
</div>

const { REACT_APP_IMAGES_URL } = process.env;

const FileOption = ({ file, question, handleDelete, fileType = '', wedding, printed }) => {
  const openFile = id => window.open(`${REACT_APP_IMAGES_URL}${id}`, '_blank');

  if (file) {
    const name = TranslateValue(file.filename);
    const size = (file.length / (1024 * 1024)).toFixed(2);
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
        <ResponsePill single={false}>{name}</ResponsePill>
        <ExtraPill>{size}MB</ExtraPill>
        <ExtraPill>{moment(file.createdAt).format('DD/MM/YYYY')}</ExtraPill>
        <ExtraPill last={!handleDelete} link onClick={() => openFile(file._id)}>
          Abrir ficheiro
        </ExtraPill>
        {handleDelete && (
          <ExtraPill last link>
            <Popconfirm
              placement="topRight"
              title={printed ? PrintedDelete : Delete}
              onConfirm={() => handleDelete(fileType === 'document' ? file : question)}>
              Apagar
            </Popconfirm>
          </ExtraPill>
        )}
      </div>
    );
  }
  return <ResponsePill>Ficheiro inválido</ResponsePill>;
};

export default FileOption;
