import React, { Fragment } from 'react';
import CurrencyComponent from '../../../components/currency/CurrencyComponent';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import { ResponsePill, ExtraPill, ImagePill, ResponseContainer, OptionResponse } from '../SectionStyles';
import { showLocal } from '../../../infra/services/wedding/weddingUtils';
import styledComponents from 'styled-components';
import { Icon } from 'antd';
import { isString } from './OptionUtils';
import { GetPriceTag, CostTypes } from '../../../infra/services/options/Options';

const { REACT_APP_IMAGES_URL } = process.env;

const Response = styledComponents.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
`;

const WarningCostMessage = styledComponents.div`
  color: #fb635b;

  .anticon {
    margin-right: 5px;
  }
`;

const OptionInformation = ({ selected, question, options = [], weddingPlace, upgrade }) => {
  const option = options
    .filter(option => !option.local || option.local === weddingPlace)
    .find(opt => opt._id === selected.id);
  const openFile = id => window.open(`${REACT_APP_IMAGES_URL}${id}`, '_blank');
  const isPremiumOption = upgrade && upgrade.hasPremiumOptions && option && option.isPremiumOption ? true : false;

  const getCost = () => {
    let cost = 0;

    if (selected) {
      if (selected?.cost && selected?.cost !== undefined) {
        cost = isString(selected.cost) ? parseFloat(selected.cost) : selected.cost;
      } else {
        // cost = upgrade?.hasPremiumOptions && option?.isPremiumOption
        //   ? option?.costPriceExtra
        //   : option?.cost_price;
        cost = option?.cost_price;
      }
    }
    return cost;
  }

  if (option) {
    const name = TranslateValue(option.defaultOption ? option.defaultOption.title : option.title);
    return (
      <Response>
        <div style={{ display: 'flex' }}>
          <ResponsePill
            single={((option.cost || (selected && selected.cost)) || (option.cost_type === CostTypes.QUANTITY && parseInt(selected.qtd) > 0) || option.local) ? 0 : 1}>
            {name}
          </ResponsePill>
          {(option.cost || (selected && selected.cost)) && (
            <ExtraPill last={((option.cost_type === CostTypes.QUANTITY && parseInt(selected.qtd) > 0) || option.local) ? 0 : 1}>
              {option?.isPremiumOption && <Icon type={'sketch'} style={{marginRight: 5, fontSize: 20}} />}
              <CurrencyComponent value={getCost() === 0 ? '0.00' : getCost()} />
              {GetPriceTag(option.cost_type)}
            </ExtraPill>
          )}
          {option.cost_type === CostTypes.QUANTITY && parseInt(selected.qtd) > 0 && (
            <ExtraPill last={!selected.local}>x{selected.qtd}</ExtraPill>
          )}
          {option.local && <ExtraPill last>{showLocal(option.local)}</ExtraPill>}
          {
            /* Bolo de Noivos - must show the selected option image */
            question?._id === '5e26e86511ad703d903609aa' && option.image &&
            <ResponsePill transparent>
              <ImagePill src={`${REACT_APP_IMAGES_URL}${option.image.file}`} onClick={() => openFile(option.image.file)} />
            </ResponsePill>
          }
        </div>
        {(selected && selected.cost && selected.cost !== undefined && option && isPremiumOption && parseFloat(selected.cost) > 0)
          || (selected && selected.cost && selected.cost !== undefined && option && !isPremiumOption && parseFloat(selected.cost) !== option.cost_price)
          && <WarningCostMessage>
            <Icon type='warning' />
            <span>*Atualização do preço em acordo com o cliente.</span>
          </WarningCostMessage>}
      </Response >
    );
  }
  return (
    <ResponsePill>A escolha já não se encontra na lista de opções</ResponsePill>
  );
};

export default OptionInformation;
