import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { Select, Spin } from 'antd';
import { InputDiv, InputLabelDiv, ChangeSizerInputDiv, StyledSelectInput } from './InputStyles';
const Option = Select.Option;

const ChangeSizerInput = ({
  input,
  data,
  disabled,
}) => {
  const formatValue = value => {
    if (!value) return undefined;
    return value.toString();
  };

  return (
    <ChangeSizerInputDiv first={true}>
      <StyledSelectInput
        disabled={disabled}
        onChange={(value) => input.onChange(value || '')}
        value={formatValue(input?.value)}
        showArrow={true}
        showSearch={false}>
        {data.map(current => (
          <Option key={current}>
            {`${current} / page`}
          </Option>
        ))}
      </StyledSelectInput>
    </ChangeSizerInputDiv>
  );
};

ChangeSizerInput.propTypes = {
  input: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
};

ChangeSizerInput.defaultProps = {
  data: ['15', '30', '45', '60'],
};

export default withLocalize(ChangeSizerInput);
