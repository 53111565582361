import React, { useState, useEffect, Fragment, useReducer, useCallback } from 'react';
import { Row, Col, Icon, Tooltip, Modal, Menu, Dropdown, Input } from 'antd';
import { BtnAddTable, DeleteSeatButton, SeatAge, SeatFoodRestrict, SeatName, SeatRow, StatsContent, StatsContentRow, StatsTotal, TableGuests, TableGuestsHeader, TableGuestsModal, TableGuestsOptions, TableGuestsStats, TableGuestsType, TableName, TableSeatCode } from '../../process/guests/RoomPlanStyles';
import GuestTableComponent from './GuestTableComponent';
import { SidesEnum } from './utilGuest';

const GuestTablesComponent = ({
  tables,
  objectTypes,
  foodRestrictions,
  parentActiveTable,
  parentDeleteTable,
  editTableCouple,
  updateTable,
  changeTablePurpose,
  parentSelectToChangeGuests,
  parentCancelChangeGuests,
  parentOpenImportModal
}) => {

  const canResize = (table) => {
    // Resizable tables
    if (table?.tableType == 'RectangularTable' || table?.tableType == 'RectangularLTable') {
      return true;
    } else {
      return false;
    }
  }

  const canIncrease = (table, increasedTablesRight, increasedTablesLeft) => {
    if (increasedTablesLeft > increasedTablesRight) {
      return table?.sideOfRoom == SidesEnum.RIGHT;
    } else if (increasedTablesLeft < increasedTablesRight) {
      return table?.sideOfRoom == SidesEnum.LEFT;
    } else return true;
  }

  const canReduce = (table, increasedTablesRight, increasedTablesLeft) => {
    if (increasedTablesLeft > increasedTablesRight) {
      return table?.sideOfRoom == SidesEnum.LEFT;
    } else if (increasedTablesLeft < increasedTablesRight) {
      return table?.sideOfRoom == SidesEnum.RIGHT;
    } else return true;
  }

  const checkCanIncrease = (table) => {
    if (table?.tableType == 'RectangularTable') {
      return true;
    } else {
      return false;
    }
  }

  const checkCanReduce = (table) => {
    if (table?.tableType == 'RectangularLTable') {
      const increasedTables = tables?.filter(f => f?.tableClass == 'RectangularLTable');
      const increasedTablesRight = increasedTables.filter(f => f?.sideOfRoom == SidesEnum.RIGHT);
      const increasedTablesLeft = increasedTables.filter(f => f?.sideOfRoom == SidesEnum.LEFT);

      return canReduce(table, increasedTablesRight, increasedTablesLeft);
    } else {
      return false;
    }
  }

  return (
    <Row id="section-tables" gutter={[12, 12]} style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'stretch' }}>

      {tables.filter(f => !f?.removeBecauseOfOvalL)?.map((table, index) => (
          <GuestTableComponent
            key={index}
            index={index}
            table={table}
            resizable={canResize(table)}
            increasable={checkCanIncrease(table)}
            objectTypes={objectTypes}
            foodRestrictions={foodRestrictions}
            parentActiveTable={parentActiveTable}
            parentDeleteTable={parentDeleteTable}
            parentChangeTablePurpose={(e) => changeTablePurpose(e, table.code)}
            parentUpdateTable={updateTable}
            parentEditTableCouple={editTableCouple}
            parentSelectToChangeGuests={() => parentSelectToChangeGuests(table.code, table)}
            parentUnselectChange={parentCancelChangeGuests}
            parentOpenImportModal={parentOpenImportModal} />
      ))}
      {/* <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
        <BtnAddTable>
          <BaseButton
            type='primary'
            icon={'plus'}
            text={'Adicionar mesa'}
            onClick={() => addTable(false)} />
        </BtnAddTable>
      </Col> */}
    </Row>
  );
};

export default React.memo(GuestTablesComponent, (props, nextProps) => {
  if (props.tables == nextProps.tables) {
    return true;
  }

  // Re-render
  // console.log('re-render tables');
  return false;

});