import { file } from "@babel/types";

export const resizeImage = (currentCanvas, width = 250, height = 250) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Set width and height
    canvas.width = width;
    canvas.height = height;

    // Draw image and export to a data-uri
    ctx.drawImage(currentCanvas, 0, 0, canvas.width, canvas.height);
    return canvas;
}

export const buildImageObject = blob => ({
    preview: URL.createObjectURL(blob),
    size: blob.size,
    type: blob.type,
    blob: blob
});

export const blobToFile = (blob, fileName) => {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    // blob.lastModified = new Date().getTime();
    // blob.lastModifiedDate = new Date();
    // blob.name = fileName;
    // blob.path = fileName;
    return new File([blob], fileName, {
        lastModified: new Date().getTime(),
        type: blob.type,
    });
}