import React, {Component} from 'react';
import {reduxForm, Field, initialize} from 'redux-form';
import {connect} from 'react-redux';
import BaseButton from '../../components/buttons/BaseButton';
import {
  FormContainer,
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  BaseForm,
  HeaderButtonsContainer,
  SpinLoading,
  DefaultLanguageTab
} from '../../styles/BasicStyles';
import {
  GetDynamicPage,
  UpdateDynamicPage,
  CreateDynamicPage
} from '../../infra/requests/BaseRequests';
import {Tabs, Row, Col} from 'antd';
import {withLocalize} from 'react-localize-redux';
import {GetTranslationDescription} from '../../infra/services/translations/AvailableTranslations';
import FormValidator from '../../infra/services/validations/FormValidator';
import DraftInput from '../../components/inputs/DraftInput';
import TinyInput from '../../components/inputs/TinyInput';
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  description: 'required|languages'
});

class ManageDynamicPages extends Component {
  state = {
    isNew: false,
    loading: true,
    ready: false,
    title: 'New Dynamic Page'
  };

  componentDidMount = async () => {
    const {
      match: {params},
      dispatch
    } = this.props;

    if (params.id !== 'add') {
      const result = await GetDynamicPage(params.id);
      dispatch(initialize('manage_dynamic_page_form', {...result.data}));
      this.setState({
        ready: true,
        isNew: false,
        title: result?.data?.title
      });
    } else {
      this.setState({
        ready: true,
        isNew: true
      });
    }
  };

  onSubmit = async values => {
    try {
      this.setState({loading: true});
      const {
        match: {params},
        history
      } = this.props;
      const {isNew} = this.state;

      isNew
        ? await CreateDynamicPage(values)
        : await UpdateDynamicPage(params.id, values);
      return history.push('/dynamic-pages');
    } catch (e) {
      console.error(e);
      this.setState({loading: false});
    }
  };

  handleOnCancel = async () => {
    try {
      const {history} = this.props;
      return history.push('/dynamic-pages');
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const {handleSubmit, activeLanguage, languages} = this.props;
    const {ready, title} = this.state;
    if (!ready) return <SpinLoading />;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{title}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={12}>
              <Col xs={12}>
                <DefaultLanguageTab>
                  {GetTranslationDescription(activeLanguage.code)}
                </DefaultLanguageTab>
                <Field
                  component={TinyInput}
                  name={`description.${activeLanguage.code}`}
                  label={'Conteúdo'}
                />
              </Col>
              <Col xs={12}>
                <Tabs>
                  {languages
                    .filter(x => !x.active)
                    .map(language => (
                      <TabPane
                        tab={GetTranslationDescription(language.code)}
                        key={language.code}>
                        <Field
                          component={TinyInput}
                          name={`description.${language.code}`}
                          label={'Conteúdo'}
                        />
                      </TabPane>
                    ))}
                </Tabs>
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageDynamicPages = reduxForm({
  form: 'manage_dynamic_page_form',
  validate: validations
})(ManageDynamicPages);

export default withLocalize(connect()(ManageDynamicPages));
