import React, {useState} from 'react';
import {Tag, Input, Icon} from 'antd';
import {InputDiv, InputLabelDiv} from './InputStyles';

const TagsInput = ({meta, input, label}) => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState('');

  let tags = Array.isArray(input?.value) ? input.value : [];

  const handleConfirm = () => {
    if (value && tags.indexOf(value) === -1) {
      const newTags = [...tags, value.replace('#', '')];
      input.onChange(newTags);
    }
    setVisible(false);
    setValue('');
  };

  const OpenInput = () => {
    setVisible(true);
    setTimeout(() => {
      const elem = document.getElementById('tag_input');
      if (elem) elem.focus();
    }, 100);
  };

  const onRemove = tag => {
    const result = input.value.filter(t => t !== tag);
    input.onChange(result);
  };

  return (
    <InputDiv>
      <InputLabelDiv>{label}</InputLabelDiv>
      {tags.map((tag, index) => (
        <Tag key={tag} closable onClose={() => onRemove(tag)}>
          #{tag}
        </Tag>
      ))}
      {visible && (
        <Input
          id="tag_input"
          type="text"
          size="small"
          style={{width: 78}}
          value={value}
          onChange={e => setValue(e.target.value)}
          onBlur={handleConfirm}
          onPressEnter={handleConfirm}
        />
      )}
      {!visible && (
        <Tag
          onClick={OpenInput}
          style={{background: '#fff', borderStyle: 'dashed'}}>
          <Icon type="plus" /> New Tag
        </Tag>
      )}
    </InputDiv>
  );
};

export default TagsInput;
