import { Checkbox, Icon, Row } from 'antd';
import styled from 'styled-components';

export const IconWarning = styled(Icon)`
    margin: auto 0 4px 5px;
    padding-top: 5px;
    color: ${p => p?.error ? 'red' : p?.warning ? 'orange' : 'green'};
`

export const DivSelectInput = styled.div`
    width: ${p => p?.showWarning ? 'calc(100% - 10px)' : '100%'};
`;

export const CheckboxDiv = styled(Checkbox)`
    height: 32px;
    display: flex !important;
    align-items: center;
`;

export const RowInCharge = styled(Row)`
    margin-top: ${p => p?.first ? '20px' : '10px'} !important;
    text-align: left;

    @media screen and (min-width: 1024px) {
        .ant-col .ant-btn {
            margin-top: ${p => p?.first ? '17px' : '27px'};
        }
    }

    @media screen and (min-width: 992px) and (max-width: 1024px) {
        .ant-btn {
            margin-top: ${p => p?.first ? '16px' : '26px'};
        }
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        .ant-btn {
            margin-top: 0px;
        }
    }

    @media screen and (min-width: 576px) and (max-width: 767px) {
        .ant-btn {
            margin-top: ${p => p?.first ? '16px' : '26px'};
        }
    }
    
    @media screen and (max-width: 575) {
        .ant-btn {
            margin-top: 0px;
        }
    }
`;

export const TeamTableActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    justify-content: flex-start;

    .ant-checkbox-wrapper {
      margin-bottom: 8px;
    }
    
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
`;