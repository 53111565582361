import React from 'react';
import { FieldArray } from 'redux-form';
import {
  ResponseContainer,
  Title,
  Response,
  ImageLink,
  Image,
  NoResponse,
  Observations
} from './Styles';
import OrderCakeIngredientsList from './OrderCakeIngredientsList';

const WeddingCakeDetail = ({ index, wedding }) => {
  return (
    <React.Fragment>
      <ResponseContainer first={true}>
        <Title>Bolo dos Noivos</Title>
        {
          wedding?.cake ?
            <React.Fragment>
              <Response>{wedding.cake.title?.pt}</Response>
              <Observations><span>Observações: </span>{wedding.cake.observations || '-'}</Observations>
              {
                wedding.cake.image?.url ?
                  <ImageLink href={wedding.cake.image.url} target='_blank'><Image src={wedding.cake.image.url} /></ImageLink>
                  :
                  <NoResponse>Sem imagem disponível!</NoResponse>
              }
            </React.Fragment>
            :
            <NoResponse>Não existem resultados a apresentar!</NoResponse>
        }
      </ResponseContainer>
      <ResponseContainer first={false}>
        <Title>Bolo dos Noivos (upload de imagem)</Title>
        {wedding?.cake_upload ?
          <React.Fragment>
            <Observations><span>Observações: </span>{wedding.cake_upload?.observations || '-'}</Observations>
            {wedding?.cake_upload?.file
              ? <ImageLink href={`${process.env.REACT_APP_IMAGES_URL}${wedding.cake_upload.file}`} target='_blank'><Image src={`${process.env.REACT_APP_IMAGES_URL}${wedding.cake_upload.file}`} /></ImageLink>
              : <NoResponse>Não efetuaram upload de imagem!</NoResponse>
            }
          </React.Fragment>
          : <NoResponse>Não existem resultados a apresentar!</NoResponse>
        }
      </ResponseContainer>
      <ResponseContainer first={false}>
        <Title>Massa & Recheio</Title>
        {
          wedding?.filling ?
            <React.Fragment>
              <Response>{wedding.filling.name?.pt}</Response>
              <Observations><span>Observações: </span>{wedding.filling.observations || '-'}</Observations>
            </React.Fragment>
            :
            <NoResponse>Não existem resultados a apresentar!</NoResponse>
        }
      </ResponseContainer>
      {wedding?.baptism && <ResponseContainer first={false}>
        <Title>Bolo de Batismo</Title>
        {
          wedding?.baptism ?
            <React.Fragment>
              <Response>{wedding.baptism.title?.pt}</Response>
              <Observations><span>Observações: </span>{wedding.baptism.observations || '-'}</Observations>
              {
                wedding.baptism.image?.url ?
                  <ImageLink href={wedding.baptism.image.url} target='_blank'><Image src={wedding.baptism.image.url} /></ImageLink>
                  :
                  <NoResponse>Sem imagem disponível!</NoResponse>
              }
            </React.Fragment>
            :
            <NoResponse>Não existem resultados a apresentar!</NoResponse>
        }
      </ResponseContainer>}
      {
        wedding?.ingredients?.length > 0 &&
        <ResponseContainer first={false}>
          <Title>Ingredientes</Title>
          <FieldArray
            component={OrderCakeIngredientsList}
            name={`weddings[${index}].ingredients`}
          />
        </ResponseContainer>
      }
    </React.Fragment>
  );
};

export default WeddingCakeDetail;