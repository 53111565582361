import client from '../config/AxiosConfig';

export const GetFood = async id =>
  client.get(`/food/${id}`);

export const CreateFood = async data =>
  client.post('/food', data);

export const UpdateFood = async (id, data) =>
  client.put(`/food/${id}`, data);

export const UpdateFoodOrder = async data =>
  client.post(`/food/order`, data);

export const DeleteFood = async (id, data) =>
  client.delete(`/food/${id}`, data);

export const GetFoodByCategory = async category =>
  client.get(`/food/category/${category}`);

export const GetStaffMeals = async () =>
  client.get(`/food/staff_meals`);

export const CreateSidedishe = async data =>
  client.post('/food/sidedishe', data);

export const UpdateSidedishe = async (id, data) =>
  client.put(`/food/${id}/sidedishe`, data);