import PropTypes from 'prop-types';
import React from 'react';
import {SimpleCheckboxDiv, SimpleCheckboxLabel, SimpleCheckboxField} from './InputStyles';

const SimpleCheckboxInput = ({
  input,
  meta,
  label,
  first,
  disabled
}) => {
  const {invalid, submitFailed} = meta;
  const showError = invalid && submitFailed;

  return (
    <SimpleCheckboxDiv first={first}>
      {label && <SimpleCheckboxLabel>{label}</SimpleCheckboxLabel>}
      <SimpleCheckboxField
        disabled={disabled}
        checked={input.value ? input.value : false}
        onChange={input.onChange}
        error={showError ? 1 : 0}>
      </SimpleCheckboxField>
    </SimpleCheckboxDiv>
  );
};

SimpleCheckboxInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

export default SimpleCheckboxInput;