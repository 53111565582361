import React, { Component } from 'react';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle
} from '../../styles/BasicStyles';
import { GetArchivedList } from '../../infra/requests/ArchivedRequests';
import moment from 'moment';
import CurrencyComponent from '../../components/currency/CurrencyComponent';
import ArchivedFilters from './ArchivedFilters';

class WeddingsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    filters: '',
    columns: [
      {
        title: 'Data',
        dataIndex: 'date',
        render: value => moment.utc(value).format('DD-MM-YYYY')
      },
      {
        title: 'Local',
        dataIndex: 'wedding_place',
        render: value =>
          value === 1 ? 'Solar da Levada' : 'Quinta Lago dos Cisnes'
      },
      {
        title: 'Noivos',
        dataIndex: 'name'
      },
      {
        title: 'Preço por PAX',
        dataIndex: 'price',
        render: value => <CurrencyComponent value={value} />
      },
      {
        title: 'Minimo Pessoas',
        dataIndex: 'min_people'
      },
      {
        title: 'Arquivado às',
        dataIndex: 'createdAt',
        render: value => moment.utc(value).format('HH:mm, DD-MM-YYYY')
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  openProcess = record => {
    const { history } = this.props;
    history.push(`/archived/${record._id}`);
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetArchivedList(currentPage, pageSize, filters);
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = filters => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Arquivo</PageTitle>
            Lista de Casamentos Arquivados
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer buttons={0}>
          <ArchivedFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="container"
            emptyText={'Não existem casamentos arquivados'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.openProcess}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default WeddingsPage;
