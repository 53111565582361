import React, { Fragment, Component } from 'react';
import { Modal, notification } from 'antd';
import { initialize } from 'redux-form';
import { connect } from 'react-redux';
import SectionMenu from '../ChoicesPage/SectionMenu';
import { bindActionCreators } from 'redux';
import StyleContent from './StyleContent';
import {
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  SpinLoading,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import { ChoicesPageContainer } from '../ChoicesPage/SectionStyles';
import {
  GetOurStyle,
  SaveStyleImage,
  DeleteStyleImage
} from '../../infra/requests/WeddingProcessRequests';
import { SaveNotes } from '../../redux/wedding/wedding.actions';
import { SaveSectionNotes } from '../../infra/requests/WeddingProcessRequests';
import BaseButton from '../../components/buttons/BaseButton';
//import CommentsDrawer from '../../components/comments/CommentsDrawer';
import NotesConfirmModal from '../../components/notesModal/NotesModal';
import { hasNotes } from '../../infra/services/notes/FindNotes';
import PrintedBar from '../dashboard/components/PrintedBar';
import CommentsDrawerFixed from '../../components/comments/CommentsDrawerFixed';
import { getImagePrintedConfirmation, getPrintedConfirmation } from '../../infra/services/wedding/printedUtils';

const { confirm } = Modal;
class DecorationPage extends Component {
  state = {
    ready: false,
    loadImages: false,
    loadNotes: false,
    openDrawer: false,
    openNotesConfirm: false,
    section: [],
    style: {}
  };

  componentDidMount() {
    this.getInformation();
  }

  getInformation = async () => {
    const {
      wedding,
      match: { params }
    } = this.props;
    const section = wedding.structure.find(s => s.tag === 'STYLE');
    const { data } = await GetOurStyle(params.wedding);
    this.setState({ ready: true, section, style: data || {} });
  };

  openNotes = () => {
    const { wedding, dispatch } = this.props;
    const { openDrawer } = this.state;
    if (!openDrawer) {
      const notes = wedding.notes.find(note => note.section === 'STYLE');
      dispatch(initialize('wedding_notes_section_form', notes));
      this.setState({ openDrawer: true });
    }
  };

  closeNotes = () => {
    const { wedding, dispatch } = this.props;
    const notes = wedding.notes.find(note => note.section === 'STYLE');
    dispatch(initialize('wedding_notes_section_form', notes));
    this.setState({ openDrawer: false, openNotesConfirm: false });
  };

  checkBeforeCloseNotes = (values) => {
    const { wedding, dispatch } = this.props;
    const notes = wedding.notes.find(note => note.section === 'STYLE');

    //If theres any change to the notes, ask before closing if we want to save the changes
    if (values && (notes?.notes !== values.notes || notes?.private !== values.private)) {
      dispatch(initialize('notes_modal_confirm_form', values));
      this.setState({ openNotesConfirm: true });
    }
    //Otherwise, just the close the notes
    else {
      this.setState({ openDrawer: false });
    }
  }

  saveNotes = async values => {
    const { wedding } = this.props;

    if (wedding?.wedding?.printed) {
      confirm({
        ...getPrintedConfirmation(),
        onOk: () => { this.updateNotes(values); },
        onCancel: () => { this.closeNotes(); },
      });
    } else this.updateNotes(values);
  };

  updateNotes = async values => {
    const { wedding, saveNotesOnReducer, dispatch } = this.props;
    this.setState({ loadNotes: true });
    const { data } = await SaveSectionNotes(wedding.wedding._id, {
      ...values,
      section: 'STYLE'
    });

    dispatch(initialize('wedding_notes_section_form', data));
    saveNotesOnReducer(data);

    const { openNotesConfirm } = this.state;
    if (openNotesConfirm) {
      return this.setState({
        loadNotes: false,
        openDrawer: false,
        openNotesConfirm: false
      });
    }
    return this.setState({ loadNotes: false });
  };

  // handleAddImage = async (accepted, rejected) => {
  //   if (accepted.length <= 0 || (rejected && rejected.length > 0)) {
  //     return notification.error({
  //       message: 'Ficheiro Inválido',
  //       description: 'O ficheiro é inválido ou tem um tamanho superior a 6Mb'
  //     });
  //   }
  //   const {
  //     match: { params }
  //   } = this.props;
  //   const payload = new FormData();
  //   payload.append('image', accepted[0]);
  //   const { data, success } = await SaveStyleImage(params.wedding, payload);
  //   if (success) this.setState({ style: data });
  // };

  handleAddImage = async (image) => {
    const { wedding } = this.props;

    if (wedding?.wedding?.printed) {
      confirm({
        ...getImagePrintedConfirmation(),
        onOk: () => { this.addImage(image); },
        onCancel: () => {},
      });
    } else this.addImage(image);
  };

  addImage = async (image) => {
    const {
      match: { params }
    } = this.props;
    // console.warn('style image', image);

    const payload = new FormData();
    payload.append('image', image);
    const { data, success } = await SaveStyleImage(params.wedding, payload);
    if (success) this.setState({ style: data });
  };

  handleDeleteImage = async id => {
    const { wedding } = this.props;

    const {
      match: { params }
    } = this.props;
    const { data, success } = await DeleteStyleImage(params.wedding, id);
    if (success) this.setState({ style: data });
  };

  render() {
    const { wedding } = this.props;
    const { section, ready, style, openDrawer, loadNotes, openNotesConfirm } = this.state;
    if (!ready) return <SpinLoading />;

    return (
      <Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>O nosso estilo</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'default'}
              icon={'read'}
              text={'Notas'}
              onClick={this.openNotes}
              notes={hasNotes(wedding.notes, 'STYLE')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        {
          wedding?.wedding?.printed && <PrintedBar small={true} />
        }
        <ChoicesPageContainer printed={wedding?.wedding?.printed}>
          <SectionMenu section={section} printed={wedding?.wedding?.printed} />
          <StyleContent
            section={section}
            style={style}
            handleAdd={this.handleAddImage}
            handleDelete={this.handleDeleteImage}
            printed={wedding?.wedding?.printed}
            notesOpen={openDrawer}
          />
          <CommentsDrawerFixed
            open={openDrawer}
            loading={loadNotes}
            onClose={this.checkBeforeCloseNotes}
            saveNotes={this.saveNotes}
            printed={wedding?.wedding?.printed}
          />
        </ChoicesPageContainer>
        {/* <CommentsDrawer
          title="Notas De O Nosso Estilo"
          open={openDrawer}
          loading={loadNotes}
          onClose={this.checkBeforeCloseNotes}
          saveNotes={this.saveNotes}
        /> */}
        <NotesConfirmModal
          open={openNotesConfirm}
          loading={loadNotes}
          onSubmit={this.saveNotes}
          closeModal={() => { this.setState({ openNotesConfirm: false }) }}
          exitWithoutSaving={this.closeNotes}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  wedding: state.wedding
});

const mapActionToProps = dispatch =>
  bindActionCreators({ saveNotesOnReducer: SaveNotes, dispatch }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(DecorationPage);
