import React, {useState} from 'react';
import styled from 'styled-components';
import {Input, Icon, Popconfirm} from 'antd';
import {InputDiv, InputLabelDiv} from './InputStyles';

const Line = styled.div`
  display: inline-block;
  width: 100%;
`;
const InputSection = styled.div`
  display: inline-block;
  width: 80%;
  float: left;

  input {
    border: 1px solid
      ${({error, theme}) =>
        error ? theme.inputErrorColor : theme.inputBorderColor};
  }
`;

const ActionSection = styled.div`
  display: inline-block;
  width: 20%;
  float: left;
  button {
    margin-left: 10px;
    height: 32px;
    border: 1px solid
      ${({add, theme}) => (add ? theme.primaryColor : theme.inputErrorColor)};
    background: ${({add, theme}) =>
      add ? theme.primaryColor : theme.inputErrorColor};
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 4px;
    color: white;
  }
`;

const TextInputList = ({fields, label, placeholder}) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const texts = fields.getAll() || [];

  const onAdd = () => {
    if (value && value !== '') {
      fields.push(value);
      setValue('');
      setError(false);
    } else setError(true);
  };

  const onRemove = index => fields.remove(index);

  return (
    <InputDiv>
      <InputLabelDiv>{label}</InputLabelDiv>
      {placeholder && <Line style={{marginBottom: '10px'}}>{placeholder}</Line>}
      <Line>
        <InputSection error={error}>
          <Input
            type="text"
            value={value}
            onChange={e => setValue(e.target.value)}
          />
        </InputSection>
        <ActionSection add>
          <button type="button" onClick={onAdd}>
            <Icon type="plus" />
          </button>
        </ActionSection>
      </Line>
      {texts
      .sort(function(a, b) {
        if(a < b) { return -1; }
        if(a > b) { return 1; }
        return 0;
      })
      .map((text, index) => (
        <Line key={index}>
          <InputSection>
            <Input type="text" value={text} disabled={true}/>
          </InputSection>
          <ActionSection>
            <Popconfirm
              placement="topRight"
              title="Tem a certeza que quer apagar esta flor?"
              onConfirm={() => onRemove(index)}
            >
              <button type="button">
                <Icon type="delete" />
              </button>
            </Popconfirm>
          </ActionSection>
        </Line>
      ))}
    </InputDiv>
  );
};

export default TextInputList;