import client from '../config/AxiosConfig';

export const GetOurDay = async id =>
  client.get(`/process/${id}/day`);

export const GetOurDayByWedding = async weddingId =>
  client.get(`/our-day/findByWedding/${weddingId}`);

export const AddMoment = async data =>
  await client.post('/our-day', data);

export const EditMoment = async (id, data) =>
  client.put(`/our-day/${id}`, data);

export const DeleteMoment = async id =>
  client.delete(`/our-day/${id}`);