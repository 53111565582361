import React, { useState } from 'react';
import { connect } from "react-redux";
import CurrencyComponent from '../../../components/currency/CurrencyComponent';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import { OptionSelection, OptionContent, OptionContentExtra } from '../SectionStyles';
import { checkFoodOption } from '../../../infra/services/options/Options';
import { Icon } from 'antd';
import SelectInput from '../../../components/inputs/SelectInput';
import { ErrorText } from '../../../styles/BasicStyles';
import CheckboxInput from '../../../components/inputs/CheckboxInput';
import SelectSidedisheInput from '../../../components/inputs/SelectSidedisheInput';
import { checkExtraCost, checkQuestionWithSidedishes, checkSidedishesOriginal, getEndSidedishesName, getFoodExtraCost, getPlateSidedishesName } from '../../../infra/services/food/SidedishesUtils';
import styledComponents from 'styled-components';
import { isString } from '../components/OptionUtils';

const PlateName = styledComponents.div`
  font-size: 16px;
  line-height: 1.5;
  font-weight: ${({ showBold }) => showBold ? 'bold' : 'lighter'}
`;


const FoodCard = ({ question, record, input, options, sidedishes, wedding }) => {
  const foodWithSidedishes = checkQuestionWithSidedishes(question);

  const sidedishesData = sidedishes && Array.isArray(sidedishes) && sidedishes.length > 0 ? sidedishes.map(m => ({ ...m, label: TranslateValue(m.name) })) : [];
  const selected = input?.value && input.value.find(resp => resp && resp.id ? resp.id === record._id : resp === record._id);
  // console.warn('selected', selected);
  const sidedishesList = sidedishesData.filter(f => f.sidedisheType === record.category || f.sidedisheType === 'both');
  let disabled = false;
  const isPremiumFood = wedding?.upgrade && wedding?.upgrade?.hasPremiumOptions && record && record?.isPremiumFood ? true : false;

  const [edit, setEdit] = useState(false);
  const [selectedSidedishes, selectSidedishes] = useState([]);

  if (selected && selected.sidedishes && Array.isArray(selected.sidedishes) && selected.sidedishes.toString() !== selectedSidedishes.toString()) {
    selectSidedishes(selected.sidedishes);
  }

  if (selected && selected.sidedishes && selected.sidedishes.length > 0) {
    for (let index = 0; index < selected.sidedishes.length; index++) {
      const selectedSide = selected.sidedishes[index];
      const indexSelected = sidedishesList.findIndex(f => f._id === selectedSide);
      if (indexSelected === -1) {
        const sidedishe = sidedishesData.find(f => f._id === selectedSide);
        if (sidedishe) sidedishesList.push(sidedishe);
      }
    }
  }

  if (input.value && Array.isArray(input.value) && input.value.length > 0 && !input.value.find(resp => resp.id ? resp.id === record._id : resp === record._id)) {
    //We have to check every element in input.value
    //If at least one option still exists in this question, then we can disable this option
    //If not then we cannot disable any option as the current option doesn't exits anymore
    for (let i = 0; i < input.value.length; i++) {
      if (checkFoodOption(input.value[i], options)) {
        disabled = true;
        break;
      }
    }
  }

  const toggleOption = record => {
    if (!disabled) {
      const hasSidedishe = record.category === 'meat' || record.category === 'fish';
      const list = [...input.value];
      const index = list.findIndex((elem) => elem && elem.id ? elem.id === record._id : elem === record._id);

      if (index >= 0) list.splice(index, 1);
      else {
        if (hasSidedishe) {
          const response = {
            id: record._id,
            sidedishes: record.sidedishes && record.sidedishes.length > 0 ? record.sidedishes.map(m => m._id) : []
          };
          list.push(response);
        } else if (isPremiumFood && record && record.costPriceExtra !== undefined) {
          list.push({ id: record._id, cost: record?.costPriceExtra });
        } else list.push(record._id);
      }

      input.onChange(list);
    }
  };

  const updateResponseSidedishes = (value) => {
    const list = [...input.value];
    const index = list.findIndex(elem => elem.id ? elem.id === record._id : elem === record._id);
    if (index >= 0) {
      if (list[index].id) {
        list[index].sidedishes = value;
        selectSidedishes(list[index].sidedishes);
      } else {
        const response = {
          id: list[index],
          sidedishes: value
        };
        list[index] = response;
      }
    }
    input.onChange(list);
  }

  const hasSidedishes = () => {
    if (foodWithSidedishes) {
      return selected && selected.sidedishes && selected.sidedishes.length > 0 ? true
        : record.sidedishes && record.sidedishes.length > 0 ? true : false;
    } else return false;
  }

  const hasSidedishesOriginal = () => {
    if (foodWithSidedishes) {
      const foodSidedishes = record && record.sidedishes && record.sidedishes.length > 0
        ? record.sidedishes[0].id
          ? record.sidedishes.map(m => m._id)
          : record.sidedishes
        : [];
      const answerSidedishes = selected ? selected.sidedishes && selected.sidedishes.length > 0
        ? selected.sidedishes
        : []
        : undefined;
      return checkSidedishesOriginal(foodSidedishes, answerSidedishes);
    } else return false;
  }

  const getPlateSidedishes = () => {
    if (foodWithSidedishes) {
      const foodSidedishes = record && record.sidedishes && record.sidedishes.length > 0
        ? record.sidedishes
        : [];
      const answerSidedishes = selected && selected.sidedishes && selected.sidedishes.length > 0
        ? selected.sidedishes
        : [];

      // const plateSidedishes = hasSidedishesOriginal()
      //   ? foodSidedishes
      //   : answerSidedishes;

      return getPlateSidedishesName(foodSidedishes, sidedishes);
    } else return '';
  }

  const getSidedishes = () => {
    if (foodWithSidedishes) {
      const sidedishesList = selected && selected.sidedishes && selected.sidedishes.length > 0 ?
        selected.sidedishes.map(s => sidedishes.find(side => side._id === s))
        : [];
      return getEndSidedishesName(sidedishesList)
    } else return '';
  }

  const hasExtraCost = () => {
    if (foodWithSidedishes) {
      const sidedishesList = selected && selected.sidedishes && selected.sidedishes.length > 0 ?
        selected.sidedishes.map(s => sidedishes.find(side => side._id === s))
        : record.sidedishes ? record.sidedishes : [];
      return checkExtraCost(record, sidedishesList);
    } else {
      return getExtraCost() !== null ? true : false;
    }
  }

  const getExtraCost = () => {
    if (foodWithSidedishes) {
      const sidedishesList = selected && selected.sidedishes && selected.sidedishes.length > 0 ?
        selected.sidedishes.map(s => sidedishes.find(side => side._id === s))
        : record.sidedishes ? record.sidedishes : [];
      return getFoodExtraCost(record, sidedishesList);
    } else return getCost();
  }

  const getCost = () => {
    let cost = null;

    if (input?.value) {
      let found = input.value.find(x => x && x.id && record ? x.id === record._id : x === record._id);

      if (found && found.cost && found.cost !== undefined) {
        cost = isString(found.cost) ? parseFloat(found.cost) : found.cost;
      } else {
        // cost = isPremiumFood
        //   ? record?.costPriceExtra
        //   : record.extra_cost
        //     ? record.extra_cost
        //     : null;
        cost = record?.extra_cost
          ? record.extra_cost
          : null;
      }
    } else {
      // cost = isPremiumFood
      //   ? record?.costPriceExtra
      //   : record.extra_cost
      //     ? record.extra_cost
      //     : null;
      cost = record?.extra_cost
        ? record.extra_cost
        : null;
    }

    return cost;
  }

  return (
    <React.Fragment>
      <OptionSelection
        selected={selected}
        disabled={disabled}
        onClick={(e) => foodWithSidedishes ? e.stopPropagation() : toggleOption(record)}
      >
        <OptionContent transparent>
          {foodWithSidedishes && <CheckboxInput
            // label='Arquivado'
            positiveLabel='Selecionado'
            input={{
              value: selected ? true : false,
              onChange: () => {
                if (selected && edit) setEdit(!edit);
                toggleOption(record);
              }
            }}
            meta={{}} />}

          {foodWithSidedishes
            ? <React.Fragment>
              <PlateName showBold={hasSidedishesOriginal() ? false : true}>Prato Original:&nbsp;{`${TranslateValue(record.name)?.trim()}${hasSidedishes() ? getPlateSidedishes() : ''}`}</PlateName>
              {hasSidedishesOriginal() && !edit ? <PlateName showBold={true}>Prato Final:&nbsp;{`${TranslateValue(record.name)?.trim()}${getSidedishes()}`}</PlateName> : null}
            </React.Fragment>
            : <PlateName showBold={true}>{TranslateValue(record.name)}</PlateName>}

          {edit && <React.Fragment>
            <SelectSidedisheInput
              label="Guarnições"
              // allowClear
              placeholder="Selecione uma guarnição da lista"
              data={sidedishesList}
              dataLabel={'label'}
              mode={'multiple'}
              input={{
                value: selectedSidedishes,
                onChange: (value) => updateResponseSidedishes(value)
              }}
              meta={{ valid: true }}
            />
            {/* {selectedSidedishes.length > 2 &&
              <ErrorText>Só pode selecionar no máximo 2 guarnições.</ErrorText>
            } */}
          </React.Fragment>}
        </OptionContent>
        {
          hasExtraCost() &&
          <OptionContentExtra last>
             {record?.isPremiumFood && <Icon type={'sketch'} />}
            <CurrencyComponent value={getExtraCost()} />
            /pax
          </OptionContentExtra>
        }
        {/* {
          record.extra_cost &&
          <OptionContentExtra last>
            <CurrencyComponent value={record.extra_cost} />
            /pax
          </OptionContentExtra>
        } */}
      </OptionSelection>
      {selected && foodWithSidedishes &&
        <Icon
          style={{ marginLeft: 10 }}
          type={'edit'}
          onClick={() => setEdit(!edit)}
        />
      }
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  wedding: state.wedding.wedding
});


export default connect(mapStateToProps)(FoodCard);