import React, { Component } from 'react';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { Row, Col, Icon, Popconfirm } from 'antd';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  SpinLoading,
  DefaultSectionOption
} from '../../styles/BasicStyles';
import {
  GetFoodByCategory,
  DeleteFood,
  UpdateFoodOrder
} from '../../infra/requests/FoodRequests';
import BaseButton from '../../components/buttons/BaseButton';
import { GetFoodType } from '../../infra/services/food/FoodCategories';
import {
  OptionBox,
  OptionTitle,
  OptionCost,
  OptionDescription,
  FloatButton
} from './FoodStyles';
import ImageComponent from '../../components/images/ImageComponent';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import OrderFood from './OrderFood';
import CateringIcon from '../../assets/icons/catering.svg';
import { checkExtraCost, getFoodExtraCost, getPlateSidedishesName, hasSidedishes } from '../../infra/services/food/SidedishesUtils';

class FoodPage extends Component {
  state = {
    rows: [],
    loading: false,
    ready: false,
    openModal: false,
    orderModal: false
  };

  componentDidMount() {
    this.getFood();
  }

  getFood = async () => {
    const {
      match: { params }
    } = this.props;
    this.setState({ loading: true });
    try {
      const { data } = await GetFoodByCategory(params.category);
      this.setState({
        rows: data || [],
        ready: true
      });
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  removeOption = async id => {
    await DeleteFood(id);
    await this.getFood();
  };

  closeOrderModal = () => {
    this.setState({ orderModal: false });
  };

  openOrderModal = food => {
    const { dispatch } = this.props;
    dispatch(initialize('manage_orderfood_form', { food: [...food] }, false));
    this.setState({ orderModal: true });
  };

  onSubmitOrder = async values => {
    try {
      this.setState({ loading: true });

      if (values.food.length > 0) {
        let orderedList = [];
        Object.keys(values.food).forEach(function (key) {
          let obj = { _id: values.food[key]._id, order: key };
          orderedList.push(obj);
        });

        if (orderedList.length > 0) {
          const res = await UpdateFoodOrder({ order: orderedList });

          if (res.success) {
            this.getFood();
          }
        }
      }

      this.setState({ loading: false });
      this.closeOrderModal();
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  goBack = () => {
    const { match: { params }, history } = this.props;

    let url = '/food';
    if (params.category === 'internal_appetizers' || params.category === 'internal_buffets' || params.category === 'internal_staff') url = '/food/internal';

    history.push(url);
  };

  hasSidedishes = () => {
    const {
      match: { params },
    } = this.props;
    return params.category === 'meat' || params.category === 'fish';
  }

  getPlateName = (option) => {
    if (this.hasSidedishes() && option && option.sidedishes && option.sidedishes.length > 0) {
      return TranslateValue(option.name)?.trim() + getPlateSidedishesName(option.sidedishes, [])
    } else return TranslateValue(option.name)?.trim();
  }

  hasExtraCost = (option) => {
    if (this.hasSidedishes() && option && option.sidedishes && option.sidedishes.length > 0) {
      return checkExtraCost(option, option.sidedishes);
    } else return option.extra_cost;
  }

  getExtraCost = (option) => {
    if (this.hasSidedishes() && option && option.sidedishes && option.sidedishes.length > 0) {
      return getFoodExtraCost(option, option.sidedishes);
    } else return option.extra_cost;
  }

  render() {
    const {
      match: { params },
      history
    } = this.props;
    const { ready, rows, orderModal, loading } = this.state;

    if (!ready) return <SpinLoading />;

    let numberButtons = 3;
    if (params.category === 'internal_appetizers' || params.category === 'internal_buffets') numberButtons = 1;
    else if (params.category === 'internal_staff') numberButtons = 2;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={numberButtons}>
            <PageTitle>{GetFoodType(params.category)}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={numberButtons}>
            {
              params.category !== 'internal_appetizers' && params.category !== 'internal_buffets' &&
              <BaseButton
                type={'primary'}
                icon={'plus'}
                text={'Nova opção'}
                onClick={() => history.push(`/food/${params.category}/add`)}
              />
            }
            {
              params.category !== 'internal_appetizers' && params.category !== 'internal_buffets' && params.category !== 'internal_staff' &&
              <BaseButton
                type="default"
                icon="retweet"
                text="Editar Ordem"
                onClick={() => this.openOrderModal(rows)}
              />
            }
            <BaseButton
              type="default"
              icon="arrow-left"
              text="Voltar"
              onClick={() => this.goBack()}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={numberButtons}>
          <Row gutter={[24, 24]}>
            {rows.map(option => (
              <Col key={option._id} xs={12} sm={8} md={6}>
                <OptionBox>
                  {params.category === 'sidedishes' ?
                    <DefaultSectionOption>
                      <img src={CateringIcon} alt="icon" />
                    </DefaultSectionOption> :
                    <ImageComponent
                      color={option?.image?.color || '#ccc'}
                      url={option?.image?.url}
                      ratio={0.6}
                    />
                  }
                  <OptionTitle>{this.getPlateName(option)}</OptionTitle>
                  <OptionCost style={{ marginTop: params.category === 'sidedishes' ? 0 : 15 }}>
                    {option?.isPremiumFood && <Icon type={'sketch'} />}
                    {this.hasExtraCost(option) && <span>{this.getExtraCost(option)}€ por PAX</span>}
                  </OptionCost>
                  {params.category !== 'sidedishes' && <OptionDescription>
                    {TranslateValue(option.description)}
                  </OptionDescription>}
                  {
                    option.canBeDeleted && params.category !== 'internal_appetizers' && params.category !== 'internal_buffets' &&
                    <FloatButton number={0}>
                      <Popconfirm
                        placement="topRight"
                        title="De certeza que quer apagar esta opção?"
                        onConfirm={() => this.removeOption(option._id)}>
                        <Icon type="delete" />
                      </Popconfirm>
                    </FloatButton>
                  }
                  <FloatButton
                    // number={(params.category === 'internal_appetizers' || params.category === 'internal_buffets') ? 0 : 1}
                    number={(!option.canBeDeleted || (params.category === 'internal_appetizers' || params.category === 'internal_buffets')) ? 0 : 1}
                    onClick={() =>
                      history.push(`/food/${params.category}/${option._id}`)
                    }>
                    <Icon type="edit" />
                  </FloatButton>
                </OptionBox>
              </Col>
            ))}
          </Row>
        </PageContainer>
        <OrderFood
          open={orderModal}
          loading={loading}
          onSubmit={this.onSubmitOrder}
          closeModal={this.closeOrderModal}
        />
      </React.Fragment>
    );
  }
}

export default connect()(FoodPage);
