import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {Modal, Button} from 'antd';
import {BaseForm} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextInput from '../../components/inputs/TextInput';
import TextAreaInput from '../../components/inputs/TextAreaInput';

const validations = FormValidator.make({
  nif: 'required',
  name: 'required'
});

let AddBillingModal = ({
  open,
  editBilling,
  loading,
  handleSubmit,
  onSubmit,
  closeModal
}) => {
  return (
    <Modal
      visible={open}
      title={editBilling ? 'Editar Dado Faturação' : 'Adicionar Dado Faturação'}
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      <BaseForm onSubmit={handleSubmit(onSubmit)}>
        <Field component={TextInput} name="nif" type="number" label="NIF" />
        <Field component={TextInput} name="name" type="text" label="Nome" />
        <Field
          component={TextInput}
          name="address"
          type="text"
          label="Morada"
        />
        <Field
          component={TextAreaInput}
          name="observations"
          type="text"
          label="Observações"
        />
      </BaseForm>
    </Modal>
  );
};

AddBillingModal = reduxForm({
  form: 'manage_billing_form',
  validate: validations
})(AddBillingModal);

export default AddBillingModal;
