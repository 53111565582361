import React, {Component, Fragment} from 'react';
import {Popconfirm, Icon} from 'antd';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton
} from '../../styles/BasicStyles';
import {GetSuppliers, DeleteSupplier} from '../../infra/requests/SupplierRequests';
import BaseButton from '../../components/buttons/BaseButton';
import Table from '../../components/table/Table';

class SuppliersPage extends Component {
  state = {
    loading: false,
    rows: [],
    currentPage: 1,
    pageSize: 50,
    total: 0,
    columns: [
      {
        title: 'Nome',
        dataIndex: 'name'
      },
      {
        title: 'Categoria',
        dataIndex: 'category',
        render: value => value?.name || ''
      },
      {
        title: 'Email',
        dataIndex: 'email'
      },
      {
        title: 'Contacto',
        dataIndex: 'contact'
      },
      {
        title: 'Acções',
        render: data => (
          <Fragment>
            {!data.system && (
              <TableButton onClick={e => e.stopPropagation()}>
                <Popconfirm
                  placement="topRight"
                  title="Tem a certeza que quer apagar este fornecedor?"
                  onConfirm={() => this.deleteSupplier(data._id)}>
                  <Icon type="delete" />
                  {' Apagar'}
                </Popconfirm>
              </TableButton>
            )}
          </Fragment>
        )
      }
    ]
  };

  componentDidMount() {
    this.getInfo();
  }

  getInfo = async () => {
    try {
      const {currentPage, pageSize} = this.state;
      const {data} = await GetSuppliers(currentPage, pageSize);
      this.setState({
        rows: data.items || [],
        total: data.total || 0
      });
      this.setState({ready: true, loading: false});
    } catch (e) {
      this.setState({ready: true, loading: false});
    }
  };

  handleChangePage = currentPage => {
    this.setState({currentPage}, this.getNews);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({pageSize, currentPage: 1}, this.getNews);
  };

  onPressRow = data => {
    const {history} = this.props;
    history.push(`/suppliers/${data._id}`);
  };

  deleteSupplier = async id => {
    await DeleteSupplier(id);
    await this.getInfo();
  };

  render() {
    const {history} = this.props;
    const {columns, rows, total, loading, currentPage, pageSize} = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Fornecedores</PageTitle>
            Fornecedores de Encomendas
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              block
              text={'Criar'}
              onClick={() => history.push('/suppliers/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={1}>
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="database"
            emptyText={'Não existem fornecedores'}
            total={total}
            onPressRow={this.onPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowKey={'_id'}
            hasPagination={true}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default SuppliersPage;
