import React, { Component, Fragment } from 'react';
import reactCSS from 'reactcss';
import Table from '../../components/table/Table';
import {
    Row,
    Col,
    //Popconfirm,
    Icon,
} from 'antd';
import {
    //HeaderButtonsContainer,
    TableButton,
    SectionTitle,
    TableFilterSection
} from '../../styles/BasicStyles';
//import BaseButton from '../../components/buttons/BaseButton';
import TextInput from '../../components/inputs/TextInput';
import { withLocalize } from 'react-localize-redux';
import Alert from "../../components/alert/Alert";
import { SketchPicker } from 'react-color'
import { CreateColorSystem, DeleteColorSystem, GetAllColorSystem, UpdateColorSystem } from '../../infra/requests/ColorSystemRequests';
import { Colorpicker } from 'antd-colorpicker';
import { ColourBox } from '../../components/inputs/InputStyles';

// const RemoveMessage = (
//     <div>
//         <div>Tem a certeza que quer remover este sistema de cor? </div>
//         <div>
//             Toda a informação do sistema será removido da plataforma e não
//             conseguirá ser recuperado.
//         </div>
//     </div>
// );

const styles = reactCSS({
    'default': {
        swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
        },
        popover: {
            position: 'absolute',
            bottom: 0,
            left: '80px',
            zIndex: 99,
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        },
    },
});


class ColorSystemTable extends Component {
    state = {
        pageSize: 15,
        currentPage: 1,
        loading: false,
        search: '',
        editColorSystem: null,
        displayColorPicker: false,
        columns: [
            {
                title: 'Cor',
                key: 'color',
                width: '10px',
                render: data => (
                    <Fragment>
                        {!this.isEditing(data)
                            ? <ColourBox table={true} colour={data.color} />
                            : <Colorpicker
                                popup
                                value={this.state.editColorSystem.color}
                                picker={'ChromePicker'}
                                onColorResult={(color) => color.hex}
                                onChange={event => this.handleChange(event)}
                                blockStyles={{
                                    boxSizing: 'border-box',
                                    boxShadow: 'unset',
                                    width: '32px',
                                    height: '32px',
                                    borderRadius: '4px',
                                    border: '1px solid #d9d9d9',
                                    cursor: 'picker',
                                    float: 'left'
                                }} />
                                
                            // ? <div><div style={styles.swatch}>
                            //     <div style={{ width: '36px', height: '14px', borderRadius: '2px', backgroundColor: data.color }} />
                            // </div></div>
                            // : <div>
                            //     <div style={styles.swatch} onClick={this.handleClick}>
                            //         <div style={{ width: '36px', height: '14px', borderRadius: '2px', backgroundColor: this.state.editColorSystem.color }} />
                            //     </div>
                            //     {this.state.displayColorPicker ? <div style={styles.popover}>
                            //         <div style={styles.cover} onClick={this.handleClose} />
                            //         <SketchPicker color={this.state.editColorSystem.color} onChange={event => this.handleChange(event)} />
                            //     </div> : null}
                            // </div>
                        }
                    </Fragment>
                )
            },
            {
                title: 'Significado',
                key: 'description',
                width: '200px',
                render: data => (
                    <Fragment>
                        {!this.isEditing(data) || (this.isEditing(data) && data.blockedDescription == true)
                            ? <div>{data.description}</div>
                            : <div style={{ marginTop: '-30px' }}>
                                <TextInput
                                    input={{
                                        value: this.state.editColorSystem.description,
                                        onChange: event => {
                                            let x = this.state.editColorSystem;
                                            x.description = event.target.value;
                                            this.setState({ editColorSystem: x })
                                        }
                                    }}
                                    meta={{}}
                                    type="text"
                                    placeholder="Selecione uma cor"
                                />
                            </div>
                        }
                    </Fragment>
                )
            },
            {
                title: 'Acções',
                width: '120px',
                render: data => (
                    <Fragment>
                        {!this.isEditing(data) ? (<TableButton onClick={e => this.setEditForm(data)}>
                            <Icon type="edit" />
                            {' Editar'}
                        </TableButton>) : (<TableButton onClick={e => this.addEditColorSystem()}>
                            <Icon type="save" />
                            {' Guardar'}
                        </TableButton>)
                        }

                        {/* {!this.isEditing(data) ? (<TableButton onClick={e => e.stopPropagation()}>
                            <Popconfirm
                                placement="topRight"
                                title={RemoveMessage}
                                okText="Remover"
                                onConfirm={async () => { await this.deleteColorSystem(data.id) }}>
                                <Icon type="delete" />
                                {' Remover'}
                            </Popconfirm>
                        </TableButton>) : (<TableButton onClick={e => {
                            if (this.state.editColorSystem.id === null) {
                                const { rows } = this.state;
                                let rowsFilter = rows.filter(f => f.id !== null);
                                this.setState({ editColorSystem: null, rows: rowsFilter });
                            } else this.setState({ editColorSystem: null });

                        }}>
                            <Icon type="close" />
                            {' Cancelar'}
                        </TableButton>)
                        } */}
                    </Fragment>
                )
            }
        ],
        rows: [],
        total: 0
    };

    componentDidMount = async () => {
        await this.updateDataTable();
    };

    isEditing = (colorSystem) => {
        const { editColorSystem } = this.state;
        return editColorSystem ? editColorSystem.id === colorSystem.id : false;
    }

    /**
     * @description Update data on datatable
     */
    updateDataTable = async () => {
        this.setState({ loading: true });
        try {
            const result = await GetAllColorSystem();

            this.setState({
                rows: result.data ? result.data : [],
                total: result.data ? result.data.length : 0,
                loading: false
            });
        } catch (e) {
            this.setState({ loading: false });
            console.error(e);
        }
    }

    /**
     * @description Show editing row with color system values
     * @param {*} colorSystem 
     */
    setEditForm = (colorSystem) => {
        let x = colorSystem ? JSON.parse(JSON.stringify(colorSystem)) : {
            description: '', color: '#fffff'
        };
        this.setState({ editColorSystem: x });
    }

    /**
    * @description Show add row
    */
    setAddForm = () => {
        const { editColorSystem, rows } = this.state;

        const colorSystem = {
            id: null,
            description: '',
            name: '#fffff',
        };
        if (!editColorSystem) {
            rows.push(colorSystem);
            this.setState({ rows: rows, editColorSystem: colorSystem });
        }
    }

    /**
     * @description Create/Update color system
     * @returns datatable color system updated
     */
    addEditColorSystem = async () => {
        const { editColorSystem } = this.state;

        if (editColorSystem.name === '') {
            Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Deve inserir o significado da cor'
            });
        } else if (editColorSystem.id === null) {
            await CreateColorSystem(editColorSystem);
            this.setState({ editColorSystem: null });
            return this.updateDataTable();
        } else {
            await UpdateColorSystem(editColorSystem);
            this.setState({ editColorSystem: null });
            return this.updateDataTable();
        }
    }

    /**
     * @description Delete color system
     * @param {*} id - color system id
     * @returns datatable color system updated
     */
    deleteColorSystem = async id => {
        await DeleteColorSystem(id);
        return this.updateDataTable();
    }

    // Color Picker Handles
    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color) => {
        let { editColorSystem } = this.state;
        // editColorSystem.color = color.hex;
        editColorSystem.color = color;

        this.setState({ editColorSystem: editColorSystem });
    };

    // DataTable Handles
    handleChangePage = currentPage => {
        this.setState({ currentPage }, this.updateDataTable);
    };

    handleChangeRowsPerPage = (currentSize, pageSize) => {
        this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
    };

    render() {
        const { loading, currentPage, pageSize } = this.state;
        const { columns, rows, total, search } = this.state;

        return (
            <React.Fragment>
                <SectionTitle style={{ marginTop: '3rem' }} >Sistema de Cores</SectionTitle>

                <TableFilterSection>
                    <Row gutter={24}>
                        <Col lg={8} md={8} sm={12} xs={24}>
                            <div className='settings-search'>
                                <TextInput
                                    type="text"
                                    label="Pesquisa"
                                    placeholder="Pesquise pelo significado"
                                    input={{
                                        value: search,
                                        onChange: event => this.setState({ search: event.target.value })
                                    }}
                                    meta={{}}
                                />
                            </div>
                        </Col>

                        {/* <Col lg={5} md={6} xs={24} style={{ height: '66px', position: 'relative' }}>
                            <HeaderButtonsContainer buttons={1} style={{ position: 'absolute', bottom: 0, right: 15 }}>
                                <BaseButton
                                    type={'primary'}
                                    icon={'plus'}
                                    block
                                    text={'Adicionar Cor'}
                                    onClick={() => this.setAddForm()}
                                />
                            </HeaderButtonsContainer>
                        </Col> */}
                    </Row>
                </TableFilterSection>

                <div className='table-responsive'>
                    <Table
                        columns={columns}
                        // currentPage={currentPage}
                        // pageSize={pageSize}
                        // defaultPageSize={pageSize}
                        loading={loading}
                        rows={search.trim() === "" ? rows : rows.filter(f => f?.description.toLowerCase().includes(search.toLowerCase()))}
                        showHeader={true}
                        emptyIcon="setting"
                        emptyText={'Não existem cores'}
                        total={search.trim() === "" ? total : rows.filter(f => f?.description.toLowerCase().includes(search.toLowerCase())).length}
                        rowKey={'_id'}
                        hasPagination={false}
                    // handleChangePage={this.handleChangePage}
                    // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default withLocalize(ColorSystemTable);