import React from 'react';
import {
  BasicInfoSection,
  CouplePhoto,
  CoupleInfo,
  InfoText
} from '../ProcessStyles';
import ImageComponent from '../../../components/images/ImageComponent';

const BasicInfoComponent = ({ weddingInfo }) => {
  return (
    <BasicInfoSection printed={weddingInfo?.printed ? true : false}>
      <CouplePhoto>
        <ImageComponent url={weddingInfo?.photo?.url} align='top' />
      </CouplePhoto>
      <CoupleInfo>
        <InfoText isTitle noTop>{weddingInfo?.bride?.name}</InfoText>
        <InfoText>email: {weddingInfo?.bride?.email}</InfoText>
        <InfoText>
          contacto: {weddingInfo?.bride?.contact || 'Não registado'}
        </InfoText>
        <InfoText isTitle>{weddingInfo?.groom?.name}</InfoText>
        <InfoText>email: {weddingInfo?.groom?.email}</InfoText>
        <InfoText>
          contacto: {weddingInfo?.groom?.contact || 'Não registado'}
        </InfoText>
      </CoupleInfo>
    </BasicInfoSection>
  );
};

export default BasicInfoComponent;
