import client from '../config/AxiosConfig';

export const GetReservations = async () => await client.get('/reservations');

export const GetReservationById = async reservationId =>
  await client.get(`/reservations/${reservationId}`);

export const UpdateReservationById = async (reservationId, data) =>
  await client.put(`/reservations/${reservationId}`, data);

export const CreateReservation = async data =>
  await client.post(`/reservations`, data);

export const AddDocument = async data =>
  await client.put(`/reservations/update`, data);

export const DeleteReservationLink = async (local, id) =>
  await client.delete(`/reservations/${local}/link/${id}`);
