import React, {Component} from 'react';
import {initialize} from 'redux-form';
import {Row, Col} from 'antd';
import {connect} from 'react-redux';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import {GetChapter} from '../../infra/requests/ChapterRequests';
import {
  CreateQuestion,
  UpdateQuestion
} from '../../infra/requests/QuestionsRequests';
import BaseButton from '../../components/buttons/BaseButton';
import SectionNames from '../../infra/services/sections/SectionNames';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import {GetQuestionType} from '../../infra/services/questions/QuestionTypes';
import ManageQuestionModal from './ManageQuestionModal';
import {FlattenToFormData} from '../../infra/services/formdata/TransformToFormData';

class GuestQuestionsPage extends Component {
  state = {
    chapter: {},
    columns: [
      {
        title: 'Titulo',
        dataIndex: 'title',
        render: value => TranslateValue(value)
      },
      {
        title: 'Tipo de pergunta',
        dataIndex: 'type',
        render: value => GetQuestionType(value)
      }
    ],
    rows: [],
    loading: false,
    openModal: false,
    orderModal: false
  };

  componentDidMount() {
    this.getChapter();
  }

  getChapter = async () => {
    const {
      match: {params}
    } = this.props;
    this.setState({loading: true});
    try {
      const {data} = await GetChapter(params.id);
      this.setState({
        chapter: data,
        rows: data?.questions || [],
        loading: false
      });
    } catch (e) {
      this.setState({loading: false});
    }
  };

  openModal = (data = {}) => {
    const {dispatch} = this.props;
    dispatch(initialize('manage_question_form', data));
    this.setState({openModal: true, edit: data._id});
  };

  onModalSubmit = async values => {
    const {
      match: {params}
    } = this.props;
    this.setState({loading: true});
    delete values.images;
    const payload = FlattenToFormData({
      ...values,
      chapter: params.id
    });

    values._id
      ? await UpdateQuestion(values._id, payload)
      : await CreateQuestion(payload);
    this.closeModal();
    await this.getChapter();
  };

  closeModal = () => {
    const {dispatch} = this.props;
    dispatch(initialize('manage_question_form', {}));
    this.setState({openModal: false, edit: false});
  };

  render() {
    const {history} = this.props;
    const {chapter, rows, columns, loading, openModal, edit} = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>
              {SectionNames[chapter?.section?.tag || undefined]}
            </PageTitle>
            {TranslateValue(chapter.name)}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type="default"
              icon="arrow-left"
              text="Voltar"
              block
              onClick={() =>
                history.push(`/sections/${chapter?.section?.tag.toLowerCase()}`)
              }
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={1}>
          <Row gutter={[12, 12]} type="flex" justify="center">
            <Col xs={16} style={{textAlign: 'center', marginBottom: '30px'}}>
              {TranslateValue(chapter?.description)}
            </Col>
          </Row>
          <Table
            columns={columns}
            currentPage={1}
            pageSize={rows.length}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="message"
            emptyText={'There are no questions'}
            total={rows.length}
            rowKey={'_id'}
            hasPagination={false}
            onPressRow={this.openModal}
          />
        </PageContainer>
        <ManageQuestionModal
          open={openModal}
          edit={edit}
          loading={loading}
          onSubmit={this.onModalSubmit}
          closeModal={this.closeModal}
        />
      </React.Fragment>
    );
  }
}

export default connect()(GuestQuestionsPage);
