import React, { Component, Fragment } from 'react';
import { Col, Icon, Row } from 'antd';
import {
  GetNearestWeddings,
  GetClosingWeddings,
  GetAdminWeddings
} from '../../infra/requests/WeddingRequests';
import { GetYearNumbers } from '../../infra/requests/ReportsRequests';
import moment from 'moment';
import CurrencyComponent from '../../components/currency/CurrencyComponent';
import Table from '../../components/table/Table';
import SelectInput from '../../components/inputs/SelectInput';
import {
  SpinLoading,
  HeaderContainer,
  HeaderTitle,
  PageTitle,
  PageContainer,
  SectionTitle,
  HeaderButtonsContainer,
  TableButton,
  TableFilterSection
} from '../../styles/BasicStyles';
import {
  getWeddingName,
  getLocalColor
} from '../../infra/services/wedding/weddingUtils';
import BaseButton from '../../components/buttons/BaseButton';
import BaseGraph from '../../components/graphs/BaseGraph';
import AddRegisterModal from './AddRegisterModal';
import {
  DateFilterSection,
  BackButton,
  Year,
  NextButton,
  GraphsContainer
} from './DashboardStyles';
import { GetColorSystemByType } from '../../infra/requests/ColorSystemRequests';
import { LocalLabel } from '../weddings/Styles';
import { GetTextColor } from '../../infra/services/text/textUtils';
import { getCurrentUser } from '../../infra/helpers/Helpers';

class DashboardPage extends Component {
  state = {
    ready: false,
    openModal: false,
    currentUser: null,
    searchLocal: '',
    locals: [{ _id: 3, name: '' }, { _id: 1, name: 'Solar da Levada' }, { _id: 2, name: 'Quinta Lago dos Cisnes' }],
    selected: moment()
      .utc(true)
      .format('YYYY'),
    yearLago: [
      {
        name: 'Realizados',
        value: 0,
        color: '#CEC9C0'
      },
      {
        name: 'Em Falta',
        value: 0,
        color: '#C3CEC0'
      }
    ],
    totalLago: 0,
    yearSolar: [
      {
        name: 'Realizados',
        value: 0,
        color: '#CEC9C0'
      },
      {
        name: 'Em Falta',
        value: 0,
        color: '#C3CEC0'
      }
    ],
    totalSolar: 0,
    near_weddings: [],
    closing_weddings: [],
    homeWeddings: [],
    columns: [
      {
        title: 'Data',
        render: data => moment.utc(data?.wedding.date).format('DD-MM-YYYY')
      },
      {
        title: 'Local',
        render: data => {
          const local = getLocalColor(data?.wedding, this.state.colors);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
        }
      },
      {
        title: 'Noivos',
        render: data => getWeddingName(data?.wedding)
      },
      {
        title: 'Preço por PAX',
        render: data => <CurrencyComponent value={data?.wedding.price} />
      },
      {
        title: 'Minimo Pessoas',
        key: 'minPeople',
        render: data => (data.wedding.min_people)
      },
      {
        title: 'Acções',
        width: '100px',
        render: data => (
          <Fragment>
            <TableButton onClick={e => this.manageWeddingTeam(e, data)}>
              {this.state.currentUser.humanResources && data?.teamBooking && data?.teamBooking.length > 0 && data?.teamBooking.filter(t => t.showEmployee).length > 0 ? (<Icon type="plus" />) : (<Icon type="team" />)}
              {this.state.currentUser.humanResources && data?.teamBooking && data?.teamBooking.length > 0 && data?.teamBooking.filter(t => t.showEmployee).length > 0 ? ' Criar Equipa' : ' Gerir Equipa'}
            </TableButton>
          </Fragment>
        )
      }
    ],
    columnsClose: [
      {
        title: 'Data',
        render: data => moment.utc(data.date).format('DD-MM-YYYY')
      },
      {
        title: 'Local',
        render: data => {
          const local = getLocalColor(data, this.state.colors);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
        }
      },
      {
        title: 'Noivos',
        render: data => getWeddingName(data)
      },
      {
        title: 'Preço por PAX',
        render: data => <CurrencyComponent value={data.price} />
      },
      {
        title: 'Minimo Pessoas',
        dataIndex: 'min_people'
      },
    ],
    colors: {
      colorSys1: null,
      colorSys2: null
    },
  };

  componentDidMount() {
    const localUser = getCurrentUser();
    this.setState({ currentUser: localUser }, () => this.getAllInformation());
  }

  getAllInformation = async () => {
    this.getLocalColors();
    const nearest = await GetNearestWeddings();
    const closing = await GetClosingWeddings();
    this.getAdminWeddings(3);

    this.getYearInformation();

    this.setState({
      near_weddings: nearest.data.length > 0 ? nearest.data : [],
      closing_weddings: closing.data || []
    });
  };

  getAdminWeddings = async (local) => {
    const resHomeWeddings = await GetAdminWeddings(local);
    // console.warn('resHomeWeddings', resHomeWeddings)

    const resp = [];
    const dtFormat = 'DD-MM-YYYY';

    for (let index = 0; index < resHomeWeddings.data.length; index++) {
      const date = moment(resHomeWeddings.data[index].wedding.date).format(dtFormat);

      if (index === 0) {
        resp.push({ date, weddings: [resHomeWeddings.data[index]] });
      } else {
        const indexResp = resp.findIndex(f => f.date === date);
        if (indexResp === -1) resp.push({ date, weddings: [resHomeWeddings.data[index]] });
        else {
          const indexWedding = resp[indexResp].weddings.findIndex(f => f.wedding.id === resHomeWeddings.data[index].id);
          if (indexWedding === -1) resp[indexResp].weddings.push(resHomeWeddings.data[index]);
        }
      }
    }

    // console.warn('resp', resp)
    this.setState({ ready: true, homeWeddings: resp });
  }

  getLocalColors = async () => {
    const resColorSys1 = await GetColorSystemByType(4);
    const colorSys1 = resColorSys1.success && resColorSys1.data ? resColorSys1.data.color : '#f7b5d6';
    const resColorSys2 = await GetColorSystemByType(5);
    const colorSys2 = resColorSys2.success && resColorSys2.data ? resColorSys2.data.color : '#b6d7a9';
    this.setState({ colors: { colorSys1, colorSys2 } });
  }

  manageWeddingTeam = (e, data) => {
    e.stopPropagation();
    const { history } = this.props;
    history.push(`/wedding-team/${data?.wedding._id}`);
  }

  getYearInformation = async () => {
    const { yearSolar, yearLago, selected } = this.state;

    const yearsSolar = await GetYearNumbers(selected, 1);
    yearSolar[0].value = yearsSolar?.data?.past || 0;
    yearSolar[1].value = yearsSolar?.data?.total - yearsSolar?.data?.past || 0;

    const yearsLago = await GetYearNumbers(selected, 2);
    yearLago[0].value = yearsLago?.data?.past || 0;
    yearLago[1].value = yearsLago?.data?.total - yearsLago?.data?.past || 0;

    this.setState({
      yearLago,
      totalLago: yearsLago?.data?.total || 0,
      yearSolar,
      totalSolar: yearsSolar?.data?.total || 0
    });
  };

  changeYear = type => {
    const { selected } = this.state;
    if (type === 'minus') {
      const lastYear = moment(selected, 'YYYY')
        .subtract(1, 'year')
        .format('YYYY');
      this.setState({ selected: lastYear }, this.getYearInformation);
    }
    if (type === 'plus') {
      const nextYear = moment(selected, 'YYYY')
        .add(1, 'year')
        .format('YYYY');
      this.setState({ selected: nextYear }, this.getYearInformation);
    }
  };

  render() {
    const { ready, openModal, selected, colors } = this.state;
    const { columns, columnsClose } = this.state;
    const { near_weddings, closing_weddings, homeWeddings } = this.state;
    const { yearLago, totalLago, yearSolar, totalSolar } = this.state;
    const colSize = parseInt(24 / homeWeddings.length);

    if (!ready) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Dashboard</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              block
              text={'Registar Pagamento efetuado /Outros valores'}
              onClick={() => this.setState({ openModal: true })}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>

        <PageContainer buttons={2}>
          <SectionTitle>Casamentos por ano</SectionTitle>
          <DateFilterSection>
            <BackButton onClick={() => this.changeYear('minus')}>
              <Icon type="arrow-left" />
            </BackButton>
            <Year>{selected}</Year>
            <NextButton onClick={() => this.changeYear('plus')}>
              <Icon type="arrow-right" />
            </NextButton>
          </DateFilterSection>
          <GraphsContainer>
            <BaseGraph
              title='Solar da Levada'
              data={yearSolar}
              addedLabels={[
                {
                  name: 'Total',
                  value: totalSolar,
                  color: '#DECBB3'
                }
              ]}
            />
            <BaseGraph
              title='Quinta Lago dos Cisnes'
              data={yearLago}
              addedLabels={[
                {
                  name: 'Total',
                  value: totalLago,
                  color: '#DECBB3'
                }
              ]}
            />
          </GraphsContainer>

          <SectionTitle subsection>Próximos casamentos</SectionTitle>
          <Table
            columns={columns}
            currentPage={1}
            pageSize={near_weddings.length}
            loading={!ready}
            onPressRow={event => {
              const { history } = this.props;
              history.push(`/wedding/${event.wedding._id}`);
            }}
            rows={near_weddings}
            showHeader={true}
            emptyIcon="calendar"
            emptyText={'Não existem casamentos próximos'}
            total={near_weddings.length}
            rowKey={'_id'}
            hasPagination={false}
          />

          <SectionTitle subsection>
            Processos de casamento a fechar
          </SectionTitle>
          <Table
            columns={columnsClose}
            currentPage={1}
            pageSize={closing_weddings.length}
            loading={!ready}
            onPressRow={event => {
              const { history } = this.props;
              history.push(`/wedding/${event._id}`);
            }}
            rows={closing_weddings}
            showHeader={true}
            emptyIcon="calendar"
            emptyText={'Não existem casamentos no próximo mes'}
            total={closing_weddings.length}
            rowKey={'_id'}
            hasPagination={false}
          />

          <SectionTitle subsection>
            Equipa dos Próximos Casamentos
          </SectionTitle>
          <TableFilterSection>
            <Row gutter={12}>
              <Col lg={4} md={4} xs={24}>
                <SelectInput
                  label="Local"
                  allowClear
                  placeholder="Escolher o local"
                  data={this.state.locals}
                  input={{
                    value: this.state.searchLocal,
                    onChange: value => {
                      let { searchLocal } = this.state;
                      searchLocal = value === undefined ? '' : value;
                      this.setState({ searchLocal });
                      this.getAdminWeddings(searchLocal === '' ? 3 : searchLocal);
                    }
                  }}
                  meta={{ valid: true }}
                />
              </Col>
            </Row>
          </TableFilterSection>

          <div className='adminWeddings'>
            <Row className='header'>
              {homeWeddings.map(data => { return <Col xs={colSize} md={colSize} lg={colSize}>{data.date}</Col> })}
            </Row>
            <Row className='body'>
              {homeWeddings.map(data => {
                return (<Col xs={colSize} md={colSize} lg={colSize}>
                  {data.weddings.map(weddingInfo => {
                    return (<div style={{ display: 'inline-flex', width: '100%', borderBottom: '1px solid #e8e8e8' }}>
                      <div style={{ backgroundColor: weddingInfo.wedding.wedding_place === 1 ? colors.colorSys1 : colors.colorSys2, width: 50, wordBreak: 'break-word', padding: 5, color: 'white' }}>{getWeddingName(weddingInfo.wedding)}</div>
                      <ul>
                        {weddingInfo.weddingTeam.map(team => { return <li><b>{team.subcategory.category.name} {team.subcategory.name}:</b> {team.employee.name}</li> })}
                      </ul>
                    </div>)
                  })}
                </Col>)
              })}
            </Row>
          </div>

        </PageContainer>
        <AddRegisterModal
          open={openModal}
          closeModal={() => this.setState({ openModal: false })}
        />
      </React.Fragment>
    );
  }
}

export default DashboardPage;
