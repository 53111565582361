import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import { BaseForm } from '../../styles/BasicStyles';
import { useSelector } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import DateInput from '../../components/inputs/DateInput';
import SelectInput from '../../components/inputs/SelectInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';

const locals = [
    { _id: 3, name: 'Ambas' },
    { _id: 1, name: 'Solar da Levada' },
    { _id: 2, name: 'Quinta Lago dos Cisnes' },
];

let UnavailableModal = (props): React$Element<React$FragmentType> => {
    const form = useSelector(state => {
        // console.warn('Val', state.form.price_form?.values);
        return state.form.unavailable_form?.values
    });

    const [initialValues, setInitialValues] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        setInitialValues(JSON.parse(JSON.stringify(props.initialValues)));
    }, [props.initialValues])

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading])

    return (
        <Modal
            visible={props.isOpen}
            title={'Registar indisponibilidade'}
            maskClosable
            onCancel={props.onClose}
            footer={[
                <Button
                    key='cancel'
                    onClick={() => props.onClose()}>
                    Cancelar
                </Button>,
                <Button
                    key='submit'
                    type='primary'
                    loading={props.loading}
                    onClick={props.handleSubmit(props.onSubmit)}>
                    Gravar
                </Button>
            ]}
        >
            <BaseForm onSubmit={props.handleSubmit(props.onSubmit)}>
                <Field
                    component={DateInput}
                    name="startDate"
                    label="Data"
                    disabled={true}
                />
                <Field
                    component={SelectInput}
                    name="weddingPlace"
                    label="Local"
                    data={locals}
                    disabled={true}
                />
                <Field
                    component={CheckboxInput}
                    name="total"
                    label="Local fechado?"
                    positiveLabel="Sim"
                />
            </BaseForm>
        </Modal>
    );
};

const selector = formValueSelector('unavailable_form');

UnavailableModal = reduxForm({
    form: 'unavailable_form',
    enableReinitialize: true,
})(UnavailableModal);

UnavailableModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default withLocalize(UnavailableModal);