import client from '../config/AxiosConfig';

export const CreateInterestDate = async (data) =>
  client.put(`/interestDate/create`, data);

// export const UpdateInterestDate = async (data) =>
//   client.post(`/interestDate/update`, data);

export const DeleteInterestDate = async (id, deleted) =>
  client.delete(`/interestDate/delete/${id}/${deleted}`);

export const DeleteInterestDateAgenda = async (deleted, data) =>
  client.post(`/interestDate/deleteAgenda/${deleted}`, data);

export const GetInterestDatePaginated = async (currentPage, limit, filters) =>
  client.get(`/interestDate/paginate/${currentPage}/${limit}?filter=${filters}`);

export const GetInterestDateByWedding = async (weddingId, filters) =>
  client.get(`/interestDate/findByWedding/${weddingId}?filter=${filters}`);

export const DownloadInterestDate = async (filters) => {
  return client.post(`/interestDate/export?filter=${filters}`, {}, {
    responseType: 'blob'
  }).then(response => {
    return response;
  })
}

export const UploadInterestDateToDrive = async (filters) =>
  client.get(`/reports/interestDate/uploadDrive?filter=${filters}`);