import React from 'react';
import { BasicInfoSection, SectionTitle } from '../../../process/dashboard/ProcessStyles';
import LogsDetail from './LogsDetail';
import moment from 'moment';
import Table from '../../../components/table/Table';

const DecorationComponent = ({ logs }) => {
  const columns = [
    {
      title: 'Date',
      key: 'createdAt',
      render: data => moment(data.createdAt).format('DD/MM/YYYY, HH:mm:ss'),
      width: '12%'
    },
    {
      title: 'Utilizador',
      dataIndex: 'user',
      width: '12%'
    },
    {
      title: 'Secção',
      dataIndex: 'section',
      width: '12%'
    },
    {
      title: 'Capítulo',
      dataIndex: 'chapter',
      width: '15%'
    },
    {
      title: 'Pergunta',
      dataIndex: 'question',
      width: '20%'
    },
    {
      title: 'Ação',
      key: 'description',
      render: data => <span dangerouslySetInnerHTML={{__html: data?.description}}/>,
      width: '29%'
    }
  ];

  return (
    <BasicInfoSection>
      <SectionTitle>Logs</SectionTitle>
      <Table
        columns={columns}
        currentPage={1}
        pageSize={30}
        rows={logs || []}
        showHeader={true}
        emptyIcon="warning"
        emptyText={'Não existe histórico inserido!'}
        rowKey={'_id'}
        hasPagination={false}
        expandedRowRender={(data) => <LogsDetail data={data}/>}
      />
    </BasicInfoSection>
  );
};

export default DecorationComponent;
