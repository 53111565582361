import React, { Component } from 'react';
import { Row, Col, Popconfirm, Pagination } from 'antd';
import BaseButton from '../../components/buttons/BaseButton';
import TextInput from '../../components/inputs/TextInput';
import {
  FormContainer,
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  HeaderButtonsContainer,
  SpinLoading
} from '../../styles/BasicStyles';
import {
  GetTranslations,
  CreateTranslation,
  EditTranslation,
  DeleteTranslation
} from '../../infra/requests/TranslationsRequests';
import { withLocalize } from 'react-localize-redux';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import AddTranslationModal from './AddTranslationModal';
import {
  TranslationBox,
  TranslationLabel,
  TranslationValue,
  TranslationOptions,
  EditOption,
  DeleteOption
} from './TranslationStyles';

class ManageTranslationsPage extends Component {
  state = {
    loading: false,
    search: '',
    editable: true,
    page: 1,
    limit: 30,
    total: 0,
    openModal: false,
    translations: [],
    initialValues: undefined
  };

  componentDidMount = async () => {
    await this.getTranslations();
  };

  getTranslations = async () => {
    try {
      const { page, limit, search } = this.state;
      this.setState({ loading: true }, async () => {
        const { data } = await GetTranslations(page, limit, search, 'FO');
        this.setState({
          loading: false,
          translations: data?.items || [],
          total: data?.total || 0
        });
        return true;
      });
    } catch (e) {
      console.error(e);
    }
  };

  deleteTranslation = async translation => {
    try {
      this.setState({ loading: true });
      if (translation.tag) {
        await DeleteTranslation(translation.tag);
        await this.getTranslations();
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleEdit = trans => {
    this.setState({ openModal: true, initialValues: trans, editable: false });
  };

  handleModalSave = async values => {
    const { initialValues } = this.state;
    initialValues
      ? await EditTranslation(values)
      : await CreateTranslation({ ...values, platform: 'FO' });
    await this.getTranslations();
    this.setState({ openModal: false, initialValues: undefined });
  };

  handlePagination = page => {
    this.setState({ page }, this.getTranslations);
  };

  timeoutSearch = () => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(this.getTranslations, 1000);
  };

  handleSearch = e => {
    const search = e.target.value;
    this.setState({ search, page: 1 }, this.timeoutSearch);
  };

  render() {
    const { languages } = this.props;
    const {
      openModal,
      translations,
      initialValues,
      loading,
      page,
      limit,
      total,
      search,
      editable
    } = this.state;
    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Translations</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Add'}
              onClick={() =>
                this.setState({
                  openModal: true,
                  initialValues: undefined,
                  editable: true
                })
              }
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer>
          <Row>
            <Col xs={10} style={{ marginBottom: '30px' }}>
              <TextInput
                input={{
                  value: search,
                  onChange: this.handleSearch
                }}
                meta={{}}
                type="text"
                label="Search for:"
                placeholder="Search for tags and translations"
              />
            </Col>
          </Row>

          {translations.map(trans => (
            <TranslationBox key={trans.tag}>
              <TranslationLabel first>Tag:</TranslationLabel>
              <TranslationValue>{trans.tag}</TranslationValue>
              <TranslationLabel>Plataforma:</TranslationLabel>
              <TranslationValue>
                {trans.platform === 'FO' ? 'Websites' : 'Wedding Day'}
              </TranslationValue>
              <Row>
                {languages.map(lang => (
                  <Col xs={8} key={`${trans.tag}_${lang.code}`}>
                    <TranslationLabel>
                      {GetTranslationDescription(lang.code)}
                    </TranslationLabel>
                    <TranslationValue>
                      {trans.value[lang.code]}
                    </TranslationValue>
                  </Col>
                ))}
              </Row>
              <TranslationOptions>
                <EditOption single={trans.canBeDeleted ? false : true}>
                  <BaseButton
                    type={'default'}
                    icon={'edit'}
                    onClick={() => this.handleEdit(trans)}
                  />
                </EditOption>
                {trans.canBeDeleted && <DeleteOption>
                  <Popconfirm
                    placement="topLeft"
                    title="Are you sure you want to delete this tag?"
                    onConfirm={() => this.deleteTranslation(trans)}>
                    <BaseButton type={'danger'} icon={'delete'} />
                  </Popconfirm>
                </DeleteOption>}
              </TranslationOptions>
            </TranslationBox>
          ))}
        </FormContainer>
        <AddTranslationModal
          initialValues={initialValues}
          isOpen={openModal}
          editable={editable}
          onConfirm={this.handleModalSave}
          onClose={() => this.setState({ openModal: false })}
        />

        <Pagination
          simple
          current={page}
          total={total}
          onChange={this.handlePagination}
          defaultPageSize={limit}
        />
      </React.Fragment>
    );
  }
}

export default withLocalize(ManageTranslationsPage);
