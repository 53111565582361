import React from "react";
import {withLocalize} from "react-localize-redux";
import CustomSelectInput from "../inputs/CustomSelectInput";
import {findCategoryIndex} from "../../process/staff/Categories";

const StaffCategory = ({ input, meta, categories, showNew, toggleShowNew, activeLanguage }) => {
  if(input.value && input.value !== 'Criar' && !categories[activeLanguage?.code].includes(input.value)) {
    const index = findCategoryIndex(input.value);

    if(input.value !== categories[activeLanguage?.code][index]) {
      input.onChange(categories[activeLanguage?.code][index]);
    }
  }

  const selectOption = () => {
    toggleShowNew(false);
  }

  const createNew = () => {
    toggleShowNew(true);
  }

  return (
    <CustomSelectInput
      data={categories[activeLanguage?.code]}
      selectOption={selectOption}
      createNew={createNew}
      showNew={showNew}
      label='Categoria'
      placeholder='Escolher Categoria'
      meta={meta}
      input={input}
    />
  );
}

export default withLocalize(StaffCategory);