export const getPrintedConfirmation = () => {
  return {
    className: 'printedConfirm',
    title: 'Tem a certeza que pretende guardar as notas?',
    content: 'Este processo de casamento já foi impresso!',
    okText: 'Guardar',
    cancelText: 'Cancelar'
  };
}

export const getMenuPrintedConfirmation = () => {
  return {
    className: 'printedConfirm',
    title: 'Tem a certeza que pretende alterar o menu?',
    content: 'Este processo de casamento já foi impresso!',
    okText: 'Guardar',
    cancelText: 'Cancelar'
  };
}

export const getUpgradePrintedConfirmation = () => {
  return {
    className: 'printedConfirm',
    title: 'Tem a certeza que pretende alterar o upgrade?',
    content: 'Este processo de casamento já foi impresso!',
    okText: 'Guardar',
    cancelText: 'Cancelar'
  };
}

export const getBudgetPrintedConfirmation = () => {
  return {
    className: 'printedConfirm',
    title: 'Tem a certeza que pretende alterar os registos do orçamento?',
    content: 'Este processo de casamento já foi impresso!',
    okText: 'Guardar',
    cancelText: 'Cancelar'
  };
}

export const getAboutUsPrintedConfirmation = () => {
  return {
    className: 'printedConfirm',
    title: 'Tem a certeza que pretende alterar a secção Sobre Nós?',
    content: 'Este processo de casamento já foi impresso!',
    okText: 'Guardar',
    cancelText: 'Cancelar'
  };
}

export const getBillingPrintedConfirmation = () => {
  return {
    className: 'printedConfirm',
    title: 'Tem a certeza que pretende alterar os dados de faturação?',
    content: 'Este processo de casamento já foi impresso!',
    okText: 'Guardar',
    cancelText: 'Cancelar'
  };
}

export const getAnswerPrintedConfirmation = () => {
  return {
    className: 'printedConfirm',
    title: 'Tem a certeza que pretende guardar a resposta?',
    content: 'Este processo de casamento já foi impresso!',
    okText: 'Guardar',
    cancelText: 'Cancelar'
  };
}

export const getFilePrintedConfirmation = () => {
  return {
    className: 'printedConfirm',
    title: 'Tem a certeza que pretende alterar os documentos?',
    content: 'Este processo de casamento já foi impresso!',
    okText: 'Guardar',
    cancelText: 'Cancelar'
  };
}

export const getImagePrintedConfirmation = () => {
  return {
    className: 'printedConfirm',
    title: 'Tem a certeza que pretende alterar as imagens?',
    content: 'Este processo de casamento já foi impresso!',
    okText: 'Guardar',
    cancelText: 'Cancelar'
  };
}

export const getMomentPrintedConfirmation = () => {
  return {
    className: 'printedConfirm',
    title: 'Tem a certeza que pretende guardar o momento?',
    content: 'Este processo de casamento já foi impresso!',
    okText: 'Guardar',
    cancelText: 'Cancelar'
  };
}

export const getStaffPrintedConfirmation = () => {
  return {
    className: 'printedConfirm',
    title: 'Tem a certeza que pretende guardar o staff externo?',
    content: 'Este processo de casamento já foi impresso!',
    okText: 'Guardar',
    cancelText: 'Cancelar'
  };
}

export const getGuestPrintedConfirmation = () => {
  return {
    className: 'printedConfirm',
    title: 'Tem a certeza que pretende guardar a lista definitiva de convidados?',
    content: 'Este processo de casamento já foi impresso!',
    okText: 'Guardar',
    cancelText: 'Cancelar'
  };
}

export const getRoomPlanPrintedConfirmation = () => {
  return {
    className: 'printedConfirm',
    title: 'Tem a certeza que pretende guardar a lista de convidados?',
    content: 'A lista de convidados já se encontra encerrada!',
    okText: 'Guardar',
    cancelText: 'Cancelar'
  };
}