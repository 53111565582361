import React from 'react';
import {Modal, Button} from 'antd';
import {DeleteModalText} from '../../styles/BasicStyles';

const DeleteConfirmModal = ({
  open,
  loading,
  onSubmit,
  closeModal
}) => {
  return (
    <Modal
      visible={open}
      title={'Atenção!'}
      maskClosable={false}
      onCancel={closeModal}
      width={800}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={() => onSubmit()}>
          Sim
        </Button>
      ]}>
      <DeleteModalText>Está prestes a eliminar um ingrediente que está relacionado com uma categoria de encomenda.</DeleteModalText>
      <DeleteModalText>Tem a certeza que pretende continuar e apagar o ingrediente?</DeleteModalText>
    </Modal>
  );
};

export default DeleteConfirmModal;