import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Checkbox } from 'antd';
import {
  BaseForm,
} from '../../styles/BasicStyles';
import {
  Row,
  Col,
} from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextInput from '../../components/inputs/TextInput';
import TagInput from '../../components/inputs/TagInput';
import { CheckboxField, InputGroupField } from '../../components/inputs/InputStyles';
import SimpleCheckboxInput from '../../components/inputs/SimpleCheckboxInput';
import NumberInput from '../../components/inputs/NumberInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import SelectInput from '../../components/inputs/SelectInput';
import DateInput from '../../components/inputs/DateInput';
import XpertGoDateInput from '../../components/inputs/XpertGoDateInput';
import moment from 'moment';

const fields = [
  { _id: 'minPax', name: 'PAX Mínimo' },
  { _id: 'minPrice', name: 'Preço PAX Mínimo' },
];

const actions = [
  { _id: '+', name: 'Adicionar' },
  { _id: '-', name: 'Subtrair' },
];

const validations = FormValidator.make({
  minPax: 'required|isInteger|minNumber:1',
  minPrice: 'required|minNumber:0.5',
});

let MultipleReservePriceModal = (props): React$Element<React$FragmentType> => {
  const form = useSelector(state => {
    // console.warn('Val', state.form.multiple_price_form?.values);
    return state.form.multiple_price_form?.values
  });

  const [initialValues, setInitialValues] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);
  const [dateList, setDateList] = React.useState(true);

  useEffect(() => {
    setInitialValues(JSON.parse(JSON.stringify(props.initialValues)));
  }, [props.initialValues])

  useEffect(() => {
    setDateList(props.dateList);
  }, [props.dateList])

  useEffect(() => {
    setSubmitting(props.submitting);
  }, [props.submitting])

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && (moment(current).year() < props.year || moment(current).year() > props.year);
  }

  return (
    <Modal
      visible={props.isOpen}
      title={'Preços das Reservas'}
      maskClosable
      onCancel={props.onClose}
      footer={[
        <Button
          key='cancel'
          onClick={() => props.onClose()}>
          Cancelar
        </Button>,
        <Button
          key='submit'
          type='primary'
          loading={submitting}
          onClick={props.handleSubmit(props.onSubmit)}>
          Gravar
        </Button>
      ]}
    >

      <BaseForm onSubmit={props.handleSubmit(props.onSubmit)}>
        <Row gutter={12}>
          {/* Range Date Selection */}
          {!initialValues?.checkReserveList && <React.Fragment>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Field
                component={XpertGoDateInput}
                name="startDate"
                label="Data Inicial"
                placeholder="Selecione a data inicial"
                disabledDate={disabledDate}
                />
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Field
                component={XpertGoDateInput}
                name="endDate"
                label="Data Final"
                placeholder="Selecione a data final"
                disabledDate={disabledDate}
              />
            </Col>
          </React.Fragment>}

          {/* Min Pax */}
          <Col lg={5} md={5} sm={5} xs={12}>
            <Field
              component={SelectInput}
              data={actions}
              allowClear={true}
              name="actionMinPax"
              label="Operação"
            />
          </Col>
          <Col lg={7} md={7} sm={7} xs={12}>
            <Field
              component={NumberInput}
              name="minPax"
              label="PAX"
              // placeholder="Insira o PAX"
              min={0}
              step={1}
              type="number"
            />
          </Col>
          {/* Min Price */}
          <Col lg={5} md={12} sm={5} xs={12}>
            <Field
              component={SelectInput}
              data={actions}
              allowClear={true}
              name="actionMinPrice"
              label="Operação"
            // placeholder="Selecione "
            />
          </Col>
          <Col lg={7} md={12} sm={7} xs={12}>
            <Field
              component={NumberInput}
              name="minPrice"
              label="Preço"
              // placeholder="Insira o preço"
              min={0}
              step={1}
              type="number"
            />
          </Col>
        </Row>
      </BaseForm>
    </Modal>
  );
};

const selector = formValueSelector('multiple_price_form');

MultipleReservePriceModal = reduxForm({
  form: 'multiple_price_form',
  enableReinitialize: true,
  validate: validations
})(MultipleReservePriceModal);

MultipleReservePriceModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default connect()(MultipleReservePriceModal);