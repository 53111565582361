import React, { useState, useEffect, Fragment, Component } from 'react';
import Table from '../../components/table/Table';
import { PageContainer, PageTitle, HeaderContainer, HeaderButtonsContainer, HeaderTitle, TableFilterSection, TableButton } from '../../styles/BasicStyles';
import { Row, Col, Popconfirm, Icon, Tag, Avatar, Modal, Tooltip } from 'antd';
import BaseButton from '../../components/buttons/BaseButton';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import NoAvatar from '../../assets/no-avatar.png'
import { withRouter } from 'react-router-dom';
import { ConvertTraineeToEmployee, DeleteEmployee, GetEmployeesPaginate, ResendEmailEmployee, RestoreEmployee, UpdateEmployee } from '../../infra/requests/EmployeeRequests';
import { GetSubcategories } from '../../infra/requests/SubcategoryRequests';
import { getUserId } from '../../infra/helpers/Helpers';
import Alert from "../../components/alert/Alert";
import EmployeesFilters, { EmployeeStatus } from './EmployeesFilters';
import styled from 'styled-components';
import Alerts from '../../components/alert/Alert';
import MechanicalModal from './MechanicalModal';

const { confirm } = Modal;

const RemoveMessage = (
    <div>
        <div>Tem a certeza que quer remover este utilizador? </div>
        <div>Este utilizador deixará de ter acesso à plataforma e de estar disponível nas equipas.</div>
    </div>
);

const RestoreMessage = (
    <div>
        <div>Tem a certeza que quer recuperar este utilizador? </div>
        <div>Este utilizador voltará a ter acesso à plataforma e estará disponível para as equipas.</div>
    </div>
);

const ConvertTraineeMessage = (
    <div>
        <div>Tem a certeza que pretende converter o estagiário para funcionário? </div>
        <div>O utilizador será notificado para definir as suas credenciais e passará a ter acesso à plataforma dos funcionários.</div>
    </div>
);

const RowTrainee = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const MechanicalCol = styled.div`
    display: grid;
    width: 100%;
    min-height: 32px;
    height: auto;
`;

const MechanicalDiv = styled.div`
    display: inline-flex;
`;

class EmployeesTable extends Component {
    state = {
        pageSize: 15,
        currentPage: 1,
        loading: false,

        filters: {},
        needRefresh: false,

        columns: [
            {
                title: 'Nome',
                key: 'name',
                render: data => {
                    return <div className='employee-center'>
                        <Avatar className='pfpImage' src={(!data?.employee?.picture || data?.employee?.picture === "") ? NoAvatar : data?.employee?.picture} />
                        <span>{data?.employee?.name || ''}</span>
                    </div>
                },
            },
            {
                title: 'Email',
                dataIndex: 'employee.email',
            },
            {
                title: 'Contacto',
                dataIndex: 'employee.emergencyNumber'
            },
            {
                title: 'Fixo',
                key: 'fixedEmployee',
                render: tag => (
                    <Tag color={tag?.employee?.fixedEmployee ? 'green' : 'red'} key={tag}>
                        {tag?.employee?.fixedEmployee ? 'Sim' : 'Não'}
                    </Tag>
                )
            },
            {
                title: 'Tipo',
                key: 'typeEmployee',
                render: obj => {
                    const showType = obj?.employee?.user?.employeeAttending || obj?.employee?.user?.employeeTrainee
                        ? true : false;
                    const trainee = obj?.employee?.user?.employeeTrainee ? true : false;
                    return showType && trainee
                        ? <RowTrainee>
                            <Tag color="geekblue" key={obj}>Estagiário</Tag>
                            <TableButton onClick={e => e.stopPropagation()}>
                                <Popconfirm
                                    placement="topRight"
                                    title={ConvertTraineeMessage}
                                    okText="Converter"
                                    onConfirm={() => { this.convertTraineeToEmployee(obj) }}>
                                    <Icon type="interaction" />
                                    {' Converter para funcionário'}
                                </Popconfirm>
                            </TableButton>
                        </RowTrainee>
                        : showType
                            ? 'Colaborador de Atendimento'
                            : null;
                }
            },
            {
                title: 'Subcategoria',
                key: 'subcategory',
                render: data => (
                    <div>
                        {data?.subcategories?.length > 0
                            ? data?.subcategories?.map(subcategory => {
                                return <div>{subcategory.name}</div>
                            })
                            : ''}
                    </div>
                )
            },
            {
                title: 'Seguro',
                key: 'insurance',
                render: tag => (
                    <Tag color={tag?.employee?.insurance ? 'green' : 'red'} key={tag}>
                        {tag?.employee?.insurance ? 'Sim' : 'Não'}
                    </Tag>
                )
            },
            {
                title: 'Entidade',
                dataIndex: 'employee.entity.name'
            },
            {
                title: 'Nº mecanográficos',
                render: data => {
                    return <Tooltip title='Editar'>
                        <MechanicalCol onClick={() => this.setState({ showMechanicalModal: true, mechanicalData: data?.employee })}>
                            {data?.employee?.mechanicalNumberSolar &&
                                <MechanicalDiv>
                                    <b>SLE:</b>
                                    &nbsp;
                                    <span>{data?.employee?.mechanicalNumberSolar}</span>
                                </MechanicalDiv>
                            }
                            {data?.employee?.mechanicalNumberLago &&
                                <MechanicalDiv>
                                    <b>QLC:</b>
                                    &nbsp;
                                    <span>{data?.employee?.mechanicalNumberLago}</span>
                                </MechanicalDiv>
                            }
                        </MechanicalCol>
                    </Tooltip>
                }
            },
            {
                title: 'Estado',
                render: data => {
                    const color = data?.employee?.deleted
                        ? 'magenta'
                        : data?.employee?.status === EmployeeStatus.ACTIVE
                            ? 'green'
                            : data?.employee?.status === EmployeeStatus.PENDING
                                ? 'yellow'
                                : data?.employee?.status === EmployeeStatus.INCOMPLETE
                                    ? 'red'
                                    : null;
                    const text = data?.employee?.deleted
                        ? 'Eliminado'
                        : data?.employee?.status === EmployeeStatus.ACTIVE
                            ? 'Ativo'
                            : data?.employee?.status === EmployeeStatus.PENDING
                                ? 'Pendente'
                                : data?.employee?.status === EmployeeStatus.INCOMPLETE
                                    ? 'Incompleto'
                                    : 'Por preencher';
                    return <Tag color={color}>{text}</Tag>
                }
            },
            {
                title: 'Acções',
                width: '150px',
                render: data => (
                    <Fragment>
                        {!data?.employee?.deleted && !data?.employee?.active
                            && <TableButton onClick={() => this.activateEmployee(data)}>
                                <Icon type="check" />
                                {' Ativar'}
                            </TableButton>}
                        {!data?.employee?.deleted
                            && <TableButton onClick={() => this.props.history.push(`/employee/${data.employee._id}`)}>
                                <Icon type="user" />
                                {' Perfil'}
                            </TableButton>}
                        {data?.employee?.deleted
                            ? <TableButton onClick={e => e.stopPropagation()}>
                                <Popconfirm
                                    placement="topRight"
                                    title={RestoreMessage}
                                    okText="Recuperar"
                                    onConfirm={() => { this.restoreEmployee(data) }}>
                                    <Icon type="eye" />
                                    {' Recuperar'}
                                </Popconfirm>
                            </TableButton>
                            : <TableButton onClick={e => e.stopPropagation()}>
                                <Popconfirm
                                    placement="topRight"
                                    title={RemoveMessage}
                                    okText="Remover"
                                    onConfirm={() => { this.deleteEmployee(data) }}>
                                    <Icon type="eye-invisible" />
                                    {' Remover'}
                                </Popconfirm>
                            </TableButton>
                        }
                        {!data?.employee?.deleted && !data?.employee?.user?.accepted && !data?.employee?.user?.employeeTrainee ?
                            <TableButton onClick={e => e.stopPropagation()}>
                                <Popconfirm
                                    placement="topRight"
                                    title="Quer reenviar o email de acesso a este utilizador?"
                                    onConfirm={() => this.resendEmail(data)}>
                                    <Icon type="inbox" />
                                    {' Reenviar email'}
                                </Popconfirm>
                            </TableButton>
                            : null}
                    </Fragment>
                )
            }
        ],
        rows: [],
        total: 0,

        showMechanicalModal: false,
        mechanicalData: null,
        savingMechanical: false,
    }

    componentDidMount = async () => {
        await this.updateDataTable();
    };

    updateDataTable = async () => {
        const { filters, currentPage, pageSize, loading } = this.state;
        if (loading) {
            this.setState({ needRefresh: true });
            return;
        }

        this.setState({ loading: true });
        try {
            if (filters?.employeeType && filters?.employeeType !== '') {
                filters[filters?.employeeType] = true;
                delete filters['employeeType'];
            }

            const result = await GetEmployeesPaginate(currentPage, pageSize, JSON.stringify(filters));
            //console.warn('Result', result);

            this.setState({
                loading: false,
                rows: Array.isArray(result.data.items) && result.data.items.length > 0 ? result?.data.items : [],
                total: result?.data?.total || 0
            }, () => {
                if (this.state.needRefresh) {
                    this.setState({ needRefresh: false }, () => this.updateDataTable());
                }
            });
        } catch (e) {
            this.setState({ loading: false });
            console.error(e);
        }
    }

    handleChangePage = currentPage => {
        this.setState({ currentPage }, this.updateDataTable);
    };

    handleChangeRowsPerPage = (currentSize, pageSize) => {
        this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
    };

    handleFilterChange = filters => {
        this.setState({ filters, currentPage: 1 }, this.updateDataTable);
    };

    convertTraineeToEmployee = async (data) => {
        const result = await ConvertTraineeToEmployee(data?.employee?._id);
        if (result?.success) {
            Alert.new({
                type: 'success',
                title: 'Sucesso',
                text: 'Estagiário convertido com sucesso. E-mail enviado!'
            });
            return this.updateDataTable();
        }
    }

    resendEmail = async (data) => {
        const result = await ResendEmailEmployee({ employee: data.employee });
        if (result?.success) {
            Alert.new({
                type: "success",
                title: "Sucesso",
                text: 'Email re-enviado!'
            });
            await this.updateDataTable();
        } else {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Falha no envio do email!'
            });
        }
    };

    deleteEmployee = async (data) => {
        const emp = data.employee;
        const resultEmp = await DeleteEmployee(emp._id);
        if (resultEmp?.success) {
            Alert.new({
                type: "success",
                title: "Sucesso",
                text: 'Funcionário removido!'
            });
            await this.updateDataTable();
        } else {
            console.error('Error Employee', resultEmp?.message);
        }
    }

    restoreEmployee = async (data) => {
        const emp = data.employee;
        const resultEmp = await RestoreEmployee(emp._id);
        if (resultEmp?.success) {
            Alert.new({
                type: "success",
                title: "Sucesso",
                text: 'Funcionário recuperado!'
            });
            await this.updateDataTable();
        } else {
            console.error('Error Employee', resultEmp?.message);
        }
    }

    activateEmployee = async (data) => {
        data = data.employee;

        if (data && !data?.entity) {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Falta a Entidade na secção Faturação'
            });
        }

        if (data?.user?.employeeAttending && (!data?.attendingType || data?.attendingType === ''
            || data?.attendingType == null || !data?.attendingLessTime || data?.attendingLessTime == ''
            || data?.attendingLessTime == null || data?.attendingLessTime < 0)) {
            const missingAttendingType = !data?.attendingType || data?.attendingType === ''
                || data?.attendingType == null ? true : false;
            const missingAttendingLessTime = !data?.attendingLessTime || data?.attendingLessTime == ''
                || data?.attendingLessTime == null || data?.attendingLessTime < 0 ? true : false;

            let fields = 'campos de atendimento';
            if (missingAttendingType && missingAttendingLessTime) fields = 'tipo de atendimento e tempo de cálculo';
            if (missingAttendingType) fields = 'tipo de atendimento';
            if (missingAttendingLessTime) fields = 'tempo de cálculo';
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: `Falta ${fields} na secção Perfil`
            });
        }

        if (data?.id === undefined && data?._id) data.id = data._id;
        data.active = true;
        data.currentUser = getUserId()
        const result = await UpdateEmployee(data);
        if (result?.success) {
            Alert.new({
                type: "success",
                title: "Info!",
                text: 'Funcionário Ativo'
            });
            await this.updateDataTable();
        }
    }

    onSubmitMechanical = async (values) => {
        this.setState({ savingMechanical: true });

        const result = await UpdateEmployee({ ...values, id: values?._id });

        if (result?.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: 'Funcionário atualizado.'
            })
            this.setState({
                savingMechanical: false,
                showMechanicalModal: false
            }, () => this.updateDataTable());
        } else {
            this.setState({ savingMechanical: false });
        }
    }

    render() {
        const { history } = this.props;
        const { columns, rows, total, loading, currentPage, pageSize } = this.state;
        const { showMechanicalModal, mechanicalData, savingMechanical } = this.state;

        return (
            <React.Fragment>
                <HeaderContainer>
                    <HeaderTitle buttons={1}>
                        <PageTitle>Funcionários</PageTitle>
                        Lista de Funcionários
                    </HeaderTitle>
                    <HeaderButtonsContainer buttons={1}>
                        <BaseButton
                            type={'primary'}
                            icon={'plus'}
                            text={'Adicionar Funcionário'}
                            block
                            onClick={() => history.push('/employee')}
                        />
                    </HeaderButtonsContainer>
                </HeaderContainer>

                <PageContainer buttons={1}>
                    <EmployeesFilters loading={loading} queryChange={this.handleFilterChange} />
                    <Table
                        columns={columns}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        defaultPageSize={pageSize}
                        loading={loading}
                        rows={rows}
                        showHeader={true}
                        emptyIcon="team"
                        emptyText={'Não existem funcionários'}
                        total={total}
                        rowKey={'employee._id'}
                        hasPagination={true}
                        hasPageSize={false}
                        handleChangePage={this.handleChangePage}
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />

                    {showMechanicalModal
                        && <MechanicalModal
                            isOpen={showMechanicalModal}
                            initialValues={mechanicalData}
                            saving={savingMechanical}
                            onSubmit={this.onSubmitMechanical}
                            onClose={() => this.setState({ showMechanicalModal: false, mechanicalData: null })}
                        />}
                </PageContainer>
            </React.Fragment >
        );
    }
};

export default withRouter(EmployeesTable);
