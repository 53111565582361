import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Icon, Row, Col, Steps, Popconfirm } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import FormValidator from '../../infra/services/validations/FormValidator';
import NumberInput from '../../components/inputs/NumberInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import SelectInput from '../../components/inputs/SelectInput';
import moment from 'moment';
import Table from '../../components/table/Table';
import OrdinalNumberComponent from '../../components/ordinalNumber/OrdinalNumberComponent';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { locals } from '../reserves/ReserveAgenda';
import TextInput from '../../components/inputs/TextInput';
import SelectLanguageInput from '../../components/inputs/SelectLanguageInput';
import { PrevButton, StyledStep, StyledSteps, TableContent, Title } from './ReserveInfoModalStyles';
import { BaseForm, TableButton } from '../../styles/BasicStyles';
import PropTypes from 'prop-types';
import CurrencyComponent from '../../components/currency/CurrencyComponent';
import { DateColumn } from '../reserves/ReservesPage';
import { isCurrentUserAdmin, isCurrentUserEmployeeAttending } from '../../infra/helpers/Helpers';
import { RelationToCouple } from '../weddings/CreateWeddingPage';

const solar = 'Solar da Levada';
const lago = 'Quinta Lago dos Cisnes';
export const visitedLocals = [
    {
        _id: 1,
        name: {
            pt: solar,
            en: solar,
            es: solar,
            fr: solar
        }
    },
    {
        _id: 2,
        name: {
            pt: lago,
            en: lago,
            es: lago,
            fr: lago
        }
    },
    {
        _id: 3,
        name: {
            pt: 'Ambos os espaços',
            en: 'Both spaces',
            es: 'Ambos espacios',
            fr: 'Les deux espaces'
        }
    }
];

const validations = (values) => {
    let errors = {};

    const validations = {
        language: 'required',
        bridename: 'required',
        brideemail: 'required|email',
        bridecontact: 'required',
        groomname: 'required',
        groomemail: 'required|email',
        groomcontact: 'required',
        expectedMinPeople: 'required|isInteger|minNumber:1'
    }

    if (values.visitedPlace) validations['visitedWeddingPlace'] = 'required';
    // if (values.placeToVisit) {
    //     validations['weddingPlaceToVisit'] = 'required';
    //     validations['shecheduleToVisit'] = 'required';
    // }

    // If exists guest contact or email info, then name is required
    if ((values?.alternativemail && values?.alternativemail !== '')
        || (values?.alternativecontact && values?.alternativecontact !== '')) {
        validations['alternativename'] = 'required';
        validations['alternativerelationcouple'] = 'required';
    }

    // If exists guest contact info, then email is required
    if ((values?.alternativecontact && values?.alternativecontact !== '')) {
        validations['alternativemail'] = 'required|email';
    }
    // Else if exists guest email info, then check that is valid email
    else if (values?.alternativemail && values?.alternativemail !== '') {
        validations['alternativemail'] = 'email';
    }
    errors = FormValidator.make(validations)(values);

    if (values?.brideemail && values?.groomemail && values?.brideemail.toLowerCase().trim() === values?.groomemail.toLowerCase().trim()) {
        errors['brideemail'] = 'REGISTER_ERROR_SAME_EMAIL';
        errors['groomemail'] = 'REGISTER_ERROR_SAME_EMAIL';
    }

    if (values?.alternativemail && (
        (values?.brideemail && values?.brideemail.toLowerCase().trim() === values?.alternativemail.toLowerCase().trim()) ||
        (values?.groomemail && values?.groomemail.toLowerCase().trim() === values?.alternativemail.toLowerCase().trim()))
    ) {
        errors['alternativemail'] = 'ALTERNATIVE_GUEST_ERROR_SAME_EMAIL';
    }

    if (values.bridecontact && !isValidPhoneNumber(values.bridecontact)) {
        errors['bridecontact'] = 'CONTACT_ERROR_INVALID_MOBILE_PHONE';
    }

    if (values.groomcontact && !isValidPhoneNumber(values.groomcontact)) {
        errors['groomcontact'] = 'CONTACT_ERROR_INVALID_MOBILE_PHONE';
    }

    if (values?.bridecontact && values?.groomcontact && values?.bridecontact?.trim() === values?.groomcontact?.trim()) {
        errors['bridecontact'] = 'REGISTER_ERROR_SAME_CONTACT';
        errors['groomcontact'] = 'REGISTER_ERROR_SAME_CONTACT';
    }

    if (values?.alternativecontact && !isValidPhoneNumber(values?.alternativecontact)) {
        errors['alternativecontact'] = 'CONTACT_ERROR_INVALID_MOBILE_PHONE';
    }

    if (values?.alternativecontact && (
        (values?.bridecontact && values?.bridecontact?.trim() === values?.alternativecontact?.trim()) ||
        (values?.groomcontact && values?.groomcontact?.trim() === values?.alternativecontact?.trim()))
    ) {
        errors['alternativecontact'] = 'ALTERNATIVE_GUEST_ERROR_SAME_CONTACT';
    }

    return errors;
};

class ReserveInfoModal extends React.Component {
    state = {
        columns: [
            {
                title: 'Posição da Reserva',
                width: '80px',
                render: data => {
                    return <OrdinalNumberComponent value={data.order} />
                }
            },
            {
                title: 'Data',
                width: '85px',
                render: data => {
                    return data?.date
                        ? <DateColumn>
                            <span className='date'>{moment.utc(data.date).format('DD-MM-YYYY')}</span>
                            <span className='weekdayName'>{moment.utc(data.date).locale('pt').format('dddd')}</span>
                        </DateColumn>
                        : '';
                }
            },
            {
                title: 'Preço p/PAX',
                // className: 'align-right',
                render: data => {
                    const sameDate = data?.event?.resource?.weddingReserve && moment.utc(data?.event?.resource?.weddingReserve?.date).isSame(moment.utc(data.date), 'day');
                    const price = sameDate && data?.event?.resource?.weddingReserve?.priceObj?.minPrice
                        ? data?.event?.resource?.weddingReserve?.priceObj?.minPrice
                        : data?.event?.resource?.price?.minPrice
                            ? data?.event?.resource?.price?.minPrice
                            : 0;
                    return <CurrencyComponent value={price} />
                }
            },
            {
                title: 'PAX',
                // className: 'align-right',
                render: data => {
                    const sameDate = data?.event?.resource?.weddingReserve && moment.utc(data?.event?.resource?.weddingReserve?.date).isSame(moment.utc(data.date), 'day');
                    const pax = sameDate && data?.event?.resource?.weddingReserve?.priceObj?.minPax
                        ? data?.event?.resource?.weddingReserve?.priceObj?.minPax
                        : data?.event?.resource?.price?.minPax
                            ? data?.event?.resource?.price?.minPax
                            : 0;
                    return pax;
                }
            },
            {
                title: 'Ações',
                render: data => {
                    return <React.Fragment>
                        {(data?.event?.resource?.weddingReserve?.sentWaitingNotification === undefined ||
                            (data?.event?.resource?.weddingReserve?.sentWaitingNotification !== undefined &&
                                data?.event?.resource?.weddingReserve?.sentWaitingNotification === false)) &&
                            <TableButton onClick={() => this.props.editDate(data)}>
                                <Icon type="edit" />
                                &nbsp;Editar Reserva
                            </TableButton>
                        }
                        <TableButton onClick={e => e.stopPropagation()}>
                            <Popconfirm
                                placement="topRight"
                                title={this.getRemoveMessage()}
                                okText={'Eliminar'}
                                onConfirm={() => this.props.deleteDate(data)}>
                                <Icon type="delete" />
                                &nbsp;Eliminar Reserva
                            </Popconfirm>
                        </TableButton>
                    </React.Fragment>
                }
            }
        ],
        // reserveList: [],
        // rowsSolar: [],
        // rowsLago: [],
        wedding: null,
        onlyCoupleInfo: false,
        screenWidth: 0,
    }

    componentDidMount() {
        const { initialize, dispatch } = this.props;
        const { initialValues, reserves, onlyCoupleInfo } = this.props;

        this.setState({
            // reserveList: reserves ? reserves : [],
            wedding: initialValues ? initialValues : null,
            onlyCoupleInfo: onlyCoupleInfo || false,
            screenWidth: window.outerWidth
        });
    }

    onSubmit = (values) => {
        const { reset, dispatch, submit, onSubmit } = this.props;
        dispatch(submit('reserve_info_form'));
        // onSubmit(values);
    }

    // Clear the fields when submit is successful
    clearFields = () => {
        const { dispatch, onClose } = this.props;
        dispatch(initialize('reserve_info_form', {}, false));
        onClose();
    }

    getRemoveMessage = () => {
        return <div>
            <div>Tem a certeza que quer remover esta reserva?</div>
            <div>Toda a informação desta reserva será removida da plataforma.</div>
        </div>
    }

    getRows = (local) => {
        const { reserves } = this.props;

        return reserves.length > 0
            ? reserves.filter(f => f.weddingPlace === local)
                .map(m => ({ ...m, date: m?.date && moment.utc(m.date).isValid() ? moment.utc(m.date).toISOString() : m.date }))
                .sort((a, b) => a.event && b.event && a.event.id > b.event.id ? 1 : -1)
            : [];
    }

    render() {
        const { isOpen, visitedPlace, placeToVisit, expectedMinPeople, reserves, initialValues, saving } = this.props;
        const { handleSubmit, onClose, onSubmit, invalid, submitFailed } = this.props;
        const { columns, rowsSolar, rowsLago, wedding, onlyCoupleInfo } = this.state;
        const { screenWidth } = this.state;
        // console.warn('reserves', reserves);

        return (
            <Modal
                visible={isOpen}
                title={onlyCoupleInfo ? 'Informação do Casal' : 'Finalizar Reserva'}
                maskClosable
                width={screenWidth >= 1024 ? 1000 : '90%'}
                onCancel={() => this.clearFields()}
                footer={[
                    <Button
                        key='cancel'
                        type='default'
                        onClick={this.clearFields}>
                        Cancelar
                    </Button>,
                    <Button
                        key='submit'
                        type='primary'
                        loading={saving}
                        onClick={handleSubmit(onSubmit)}>
                        Gravar
                    </Button>
                ]}
            >
                {reserves?.length > 0 && <Row gutter={[12, 12]}>
                    {/* List reserves dates separated by place */}
                    {locals.map((local, index) => (
                        <Col xs={24} lg={12}>
                            <TableContent key={index} first={index > 0 ? false : true}>
                                <Title>Reservas {local?.name}</Title>
                                <Table
                                    columns={columns}
                                    rows={this.getRows(local._id)}
                                    showHeader={true}
                                    emptyText='Não existem datas reservadas para este espaço'
                                    rowKey={'event.id'}
                                    hasPagination={false}
                                />
                            </TableContent>
                        </Col>
                    ))}
                </Row>}

                <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
                    <Title>Informação Complementar</Title>
                    <Row gutter={[12, 12]}>
                        <Col xs={24} md={12} lg={6}>
                            <Field
                                component={SelectLanguageInput}
                                name={'language'}
                                label={'Idioma de comunicação'}
                                placeholder={'Selecionar idioma para comunicações da plataforma'}
                                languages={[
                                    { code: 'pt', name: 'Português' },
                                    { code: 'en', name: 'Inglês' }
                                ]}
                            />
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <Field
                                component={NumberInput}
                                label='Número Previsto de Convidados'
                                name={'expectedMinPeople'}
                                step={1}
                                min={1}
                            />
                        </Col>
                        {expectedMinPeople && expectedMinPeople !== '' && expectedMinPeople < 140 ?
                            <Col xs={24} md={24} lg={24}>
                                Atenção: o número previsto de convidados é inferior ao número mínimo para a data.
                                É possível realizar o casamento com este número de convidados, no entanto será cobrado o
                                número mínimo estipulado.
                            </Col>
                            : <Col xs={24} md={12} lg={12} style={{ height: 78 }}></Col>
                        }
                        <Col xs={24} md={!visitedPlace ? 24 : 12} lg={!visitedPlace ? 24 : 12}>
                            <Field
                                component={CheckboxInput}
                                name={'visitedPlace'}
                                label='Já fizeram visita aos nossos espaços com acompanhamento?'
                                positiveLabel='Sim'
                            />
                        </Col>
                        {visitedPlace
                            ? <Col xs={24} md={12} lg={6}>
                                <Field
                                    component={SelectInput}
                                    data={visitedLocals}
                                    dataKey='_id'
                                    dataLabel='name'
                                    translatable={true}
                                    name={'visitedWeddingPlace'}
                                    allowClear={true}
                                    label='Espaço Visitado *'
                                />
                            </Col>
                            : <React.Fragment>
                                {/* <Col xs={24} md={!placeToVisit ? 24 : 12} lg={!placeToVisit ? 12 : 6}>
                                    <Field
                                        component={CheckboxInput}
                                        name={'placeToVisit'}
                                        label='Pretendem agendar uma visita?'
                                        positiveLabel='Sim'
                                    />
                                </Col>
                                {placeToVisit
                                    ? <React.Fragment>
                                        <Col xs={24} md={12} lg={6}>
                                            <Field
                                                component={SelectInput}
                                                data={visitedLocals}
                                                dataKey='_id'
                                                dataLabel='name'
                                                translatable={true}
                                                name={'weddingPlaceToVisit'}
                                                allowClear={true}
                                                label='Espaço que pretende visitar *'
                                            />
                                        </Col>
                                        <Col xs={24} md={24} lg={12}>
                                            <Field
                                                component={TextInput}
                                                name={'shecheduleToVisit'}
                                                label={'Disponibilidade para a visita *'}
                                            />
                                        </Col>
                                    </React.Fragment>
                                    : <Col xs={24} md={24} lg={24}>
                                        <Field
                                            component={CheckboxInput}
                                            name={'wantToVisitWithoutInfo'}
                                            label={'Pretendo visitar, mas ainda não sei quando.'}
                                            positiveLabel='Sim'
                                        />
                                    </Col>} */}
                            </React.Fragment>}
                    </Row>

                    <Title>Dados da Noiva/Noivo</Title>
                    <Row gutter={[12, 12]}>
                        <Col xs={24} md={12} lg={8}>
                            <Field
                                component={TextInput}
                                name={'bridename'}
                                type="text"
                                label="Nome *"
                                disabled={isCurrentUserEmployeeAttending() && initialValues?._id}
                            />
                        </Col>
                        <Col xs={24} md={12} lg={8}>
                            <Field
                                component={TextInput}
                                name={'brideemail'}
                                type="email"
                                label="Endereço de E-mail *"
                                disabled={isCurrentUserEmployeeAttending() && initialValues?._id}
                            />
                        </Col>
                        <Col xs={24} md={12} lg={8}>
                            <Field
                                component={TextInput}
                                name={'bridecontact'}
                                type="tel"
                                label="Número de Telemóvel (ex: +351911111111) *"
                                disabled={isCurrentUserEmployeeAttending() && initialValues?._id}
                            />
                        </Col>
                    </Row>

                    <Title>Dados da Noiva/Noivo</Title>
                    <Row gutter={[12, 12]}>
                        <Col xs={24} md={12} lg={8}>
                            <Field
                                component={TextInput}
                                name={'groomname'}
                                type="text"
                                label="Nome *"
                                disabled={isCurrentUserEmployeeAttending() && initialValues?._id}
                            />
                        </Col>
                        <Col xs={24} md={12} lg={8}>
                            <Field
                                component={TextInput}
                                name={'groomemail'}
                                type="email"
                                label="Endereço de E-mail *"
                                disabled={isCurrentUserEmployeeAttending() && initialValues?._id}
                            />
                        </Col>
                        <Col xs={24} md={12} lg={8}>
                            <Field
                                component={TextInput}
                                name={'groomcontact'}
                                type="tel"
                                label="Número de Telemóvel (ex: +351911111111) *"
                                disabled={isCurrentUserEmployeeAttending() && initialValues?._id}
                            />
                        </Col>
                    </Row>

                    {initialValues?._id && initialValues?.alternativemail && initialValues?.alternativemail !== ''
                        && <React.Fragment>
                            <Title>Dados do Convidado</Title>
                            <Row gutter={[12, 12]}>
                                <Col xs={24} md={12} lg={8}>
                                    <Field
                                        component={TextInput}
                                        name={'alternativename'}
                                        type="text"
                                        label="Nome"
                                        disabled={isCurrentUserEmployeeAttending() && initialValues?._id}
                                    />
                                </Col>
                                <Col xs={24} md={12} lg={8}>
                                    <Field
                                        component={TextInput}
                                        name={'alternativemail'}
                                        type="email"
                                        label="Endereço de E-mail"
                                        disabled={initialValues?._id}
                                    />
                                </Col>
                                <Col xs={24} md={12} lg={8}>
                                    <Field
                                        component={TextInput}
                                        name={'alternativecontact'}
                                        type="tel"
                                        label="Número de Telemóvel (ex: +351911111111)"
                                        disabled={isCurrentUserEmployeeAttending() && initialValues?._id}
                                    />
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Field
                                        component={SelectInput}
                                        name={'alternativerelationcouple'}
                                        label={'Relação do convidado com o casal'}
                                        dataKey={'value'}
                                        dataLabel={'name'}
                                        data={[
                                            { value: RelationToCouple.THIRD_USER, name: '3º email do casal' },
                                            { value: RelationToCouple.FAMILIAR_FRIEND, name: 'Familiar e/ou amigo' },
                                            { value: RelationToCouple.WEDDING_PLANNER, name: 'Wedding Planner' },
                                        ]}
                                    />
                                </Col>
                                {isCurrentUserAdmin() && <Col xs={24} md={12} lg={12}>
                                    <Field
                                        component={CheckboxInput}
                                        name={'accessToConfirmedWedding'}
                                        label={'Permitir que o convidado aceda ao casamento após confirmação?'}
                                        positiveLabel='Sim'
                                    />
                                </Col>}
                            </Row>
                        </React.Fragment>}
                </BaseForm>
            </Modal>
        );
    }
};

const selector = formValueSelector('reserve_info_form');

ReserveInfoModal = reduxForm({
    form: 'reserve_info_form',
    enableReinitialize: true,
    validate: validations
})(ReserveInfoModal);

ReserveInfoModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    expectedMinPeople: selector(state, 'expectedMinPeople'),
    visitedPlace: selector(state, 'visitedPlace'),
    placeToVisit: selector(state, 'placeToVisit')
});

export default withLocalize(connect(mapStateToProps)(ReserveInfoModal))