// @flow
import React, { useState, useEffect, useRef } from 'react';
import { PageContainer, PageTitle, HeaderContainer, HeaderButtonsContainer, HeaderTitle, SpinLoadingCalendar } from '../../styles/BasicStyles';
import { Row, Col, Icon, DatePicker, Dropdown, Menu, Button } from 'antd';
import { GetAllColorSystem } from '../../infra/requests/ColorSystemRequests';
import { GetAdminCalendar, GetAllWeddings } from '../../infra/requests/WeddingRequests';
import { GetAllCompanyRest, GetCalendarCompanyRest } from '../../infra/requests/UnavailableRequests';
import { GetAllPaymentDays, GetCalendarPaymentDays } from '../../infra/requests/PaymentDaysRequests';
import BaseButton from '../../components/buttons/BaseButton';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { isCurrentUserAdmin, isCurrentUserEmployee, isCurrentUserHumanResources } from '../../infra/helpers/Helpers';

// main component
let Calendar = (props): React$Element<React$FragmentType> => {

    const [loading, setLoading] = useState(true);
    const [defaultDate, setDefaultDate] = useState(null);
    const [colorSystem, setColorSystem] = useState([]);
    const [events, setEvents] = useState([]);
    const [searchStartDate, setSearchStartDate] = useState(moment.utc().startOf('month'));
    const [searchEndDate, setSearchEndDate] = useState(moment.utc().endOf('month'));
    const [searchSpecificDate, setSearchSpecificDate] = useState(false);

    const calendarComponentRef = useRef();

    const { history } = props;

    useEffect(() => {
        getAllColorSystem();
    }, [])

    useEffect(() => {
        let calendarApi = calendarComponentRef.current.getApi();
        if (defaultDate) {
            calendarApi.changeView('timeGridDay');
            calendarApi.gotoDate(defaultDate.format('YYYY-MM-DD'));
        } else {
            calendarApi.changeView('dayGridMonth');
            calendarApi.gotoDate(moment.utc(new Date()).format('YYYY-MM-DD'));
        }
    }, [defaultDate])

    const getAllColorSystem = async () => {
        const result = await GetAllColorSystem();
        setColorSystem(result.data)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getAllEvents = async () => {
        setLoading(true);
        let filter = { startDate: searchStartDate, endDate: searchEndDate, specificDate: searchSpecificDate };
        const result = await GetAdminCalendar(JSON.stringify(filter));
        // console.warn('Res', result);

        let weddings = result.data.sort((a, b) => (a.wedding_place > b.wedding_place) ? 1 : -1);
        let evts = []; //JSON.parse(JSON.stringify(events));
        for (let i = 0; i < weddings.length; i++) {
            const weddingDate = moment.utc(weddings[i].date);
            let obj = {
                id: weddings[i]._id,
                title: `${weddings[i].bride.name} & ${weddings[i].groom.name}`,
                allDay: true,
                start: moment(weddingDate).startOf('day').toDate(),
                end: moment(weddingDate).endOf('day').toDate(),
                color: weddings[i].wedding_place === 1 ? colorSystem.find(x => x.type === 4).color : colorSystem.find(x => x.type === 5).color,
                display: 'block',
                className: 'hideTime',
                type: 1
            }
            evts.push(obj);
        }
        const result2 = await GetCalendarCompanyRest(JSON.stringify(filter));
        for (let i = 0; i < result2.data.length; i++) {
            let obj = {
                id: result2.data[i]._id,
                title: result2.data[i].title,
                allDay: result2.data[i].allDay,
                start: moment(result2.data[i].start).toDate(),
                end: moment(result2.data[i].end).toDate(),
                color: result2.data[i].color,
                display: 'block',
                className: result2.data[i].allDay ? 'hideTime' : '',
                type: 2
            }
            evts.push(obj);
        }

        const result3 = await GetCalendarPaymentDays(JSON.stringify(filter));
        for (let i = 0; i < result3.data.length; i++) {
            const date = moment.utc(result3.data[i].date);
            const day = searchStartDate.date();
            const month = searchSpecificDate || day === 1 ? searchStartDate.month() : searchStartDate.month() + 1;
            const year = searchStartDate.year();
            const startDate = result3.data[i].monthly
                ? moment.utc().set('year', year).set('month', month).set('date', date.date()).startOf('day').toDate()
                : moment.utc(result3.data[i].date).startOf('day').toDate()
            const endDate = result3.data[i].monthly
                ? moment.utc().set('year', year).set('month', month).set('date', date.date()).endOf('day').toDate()
                : moment.utc(result3.data[i].date).endOf('day').toDate()
            // console.warn('Start', startDate);
            // console.warn('End', endDate);

            let obj = {
                id: result3.data[i]._id,
                title: 'Dia de Pagamento',
                allDay: true,
                start: startDate,
                end: endDate,
                color: colorSystem.find(x => x.type === 8).color,
                display: 'block',
                className: 'hideTime',
                type: 2
            }
            evts.push(obj);
        }

        setEvents(evts);
        setLoading(false);
    }

    useEffect(() => {
        if (colorSystem.length > 0)
            getAllEvents();
    }, [colorSystem])

    useEffect(() => {
        if (colorSystem.length > 0) getAllEvents();
    }, [searchStartDate, searchEndDate, searchSpecificDate])

    const handleEventClick = (e) => {
        // If event is unavailable or paymentDay
        if (e.event.backgroundColor === colorSystem.find(x => x.type === 3).color || e.event.backgroundColor === colorSystem.find(x => x.type === 8).color) {
            return;
        }
        // Wedding
        else {
            history.push(`/wedding/${e.event.id}`);
        }
    }

    const onSelectCalendarMenu = (value) => {
        // console.warn('Val', value);
        const itemSelected = parseInt(value.key);
        if (itemSelected === 1) {
            history.push('/rest-days');
        } else if (itemSelected === 2) {
            history.push('/payment-days');
        } else if (itemSelected === 3) {
            history.push('/couple-payment-days');
        }
    }

    const menu = (
        <Menu onClick={onSelectCalendarMenu}>
            <Menu.Item key={1}>
                <Icon type="calendar" />
                Dias de Descanso
            </Menu.Item>
            <Menu.Item key={2}>
                <Icon type="euro" />
                Dias de Pagamento
            </Menu.Item>
            <Menu.Item key={3} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                <Icon type="sketch" />
                <span style={{ display: 'flex', flexDirection: 'column', alignContent: 'flex-start', alignItems: 'flex-start' }}>
                    <span>Dias de Exceção</span>
                    <span>Pagamento Noivos</span>
                </span>
            </Menu.Item>
        </Menu>
    );

    return (
        <React.Fragment>
            <HeaderContainer>
                <HeaderTitle buttons={1}>
                    <PageTitle>Calendário</PageTitle>
                </HeaderTitle>
                <HeaderButtonsContainer buttons={isCurrentUserAdmin() ? 2 : 1}>
                    {isCurrentUserAdmin() && <BaseButton
                        type="primary"
                        icon="calendar"
                        text="Calendário Anual"
                        onClick={() => { history.push('/weddings-year-calendar') }}
                    />}
                    {isCurrentUserAdmin()
                        ? <Dropdown overlay={menu} trigger={['click']}>
                            <BaseButton
                                type={'primary'}
                                icon={'down'}
                                text={'Opções do Calendário'}
                            />
                        </Dropdown>
                        : <BaseButton
                            type="primary"
                            icon="calendar"
                            text="Dias de Descanso"
                            onClick={() => { history.push('/rest-days') }}
                        />
                    }
                </HeaderButtonsContainer>
            </HeaderContainer>
            <PageContainer buttons={1} id="pageContainerCalendarAdmin">
                <Row style={{ marginBottom: 5 }}>
                    <Col xs={20} sm={10} md={5} lg={4} style={{ textAlign: 'left' }}>
                        <DatePicker
                            className='mobile-full-width'
                            value={defaultDate}
                            format={'DD/MM/YYYY'}
                            placeholder='Selecione Data'
                            onChange={(e) => {
                                if (e == null) {
                                    setDefaultDate(null)
                                    setSearchSpecificDate(false);
                                } else {
                                    setDefaultDate(e);
                                    setSearchSpecificDate(true);
                                    setSearchStartDate(e);
                                    setSearchEndDate(e);
                                }
                            }} />
                    </Col>
                    <Col xs={4} sm={14} md={19} lg={20} style={{ textAlign: 'right', paddingTop: 5 }}>
                        <Icon type="unordered-list" style={{ fontSize: '24px', cursor: 'pointer' }} onClick={() => { history.push('/weddings') }} />
                    </Col>
                </Row>
                <Row>
                    <div id="calendar">
                        {loading === true ? <SpinLoadingCalendar /> : null}
                        <FullCalendar
                            initialView="dayGridMonth"
                            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
                            handleWindowResize={true}
                            themeSystem="default"
                            height="calc(100vh - 260px)"
                            allDaySlot={true}
                            locale="pt"
                            buttonText={{
                                today: 'Hoje',
                                month: 'Mês',
                                week: 'Semana',
                                day: 'Dia',
                                list: 'Lista',
                                prev: 'Anterior',
                                next: 'Seguinte'
                            }}
                            headerToolbar={{
                                left: 'prev,today,next',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay' //listMonth
                            }}
                            editable={false}
                            selectable={true}
                            droppable={true}
                            events={events}
                            datesSet={($event) => {
                                // console.warn('Dateset', $event);
                                const start = new Date($event.start);
                                const end = new Date($event.end);
                                setEvents([]);
                                setSearchStartDate(moment.utc(start.toUTCString()));
                                setSearchEndDate(moment.utc(end.toUTCString()));
                                // if (searchSpecificDate && defaultDate && moment.utc($event?.start).isSame(defaultDate, 'day')) {
                                //     setSearchSpecificDate(false);
                                //     setDefaultDate(null);
                                // }
                            }}
                            ref={calendarComponentRef}
                            eventClick={handleEventClick}
                        // dateClick={handleDateClick}
                        // drop={handleDrop}
                        />
                    </div>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    {colorSystem.map((x) => {
                        return ([3, 4, 5, 8]).includes(x.type) ?
                            <Col md={6}><div style={{ display: 'inline-flex', userSelect: 'none' }}><div style={{ width: 25, height: 25, margin: 5, backgroundColor: x.color, borderRadius: 3 }}></div><span style={{ margin: 'auto 0px', fontWeight: 'bold' }}>{x.description}</span></div></Col>
                            : null
                    })}
                </Row>
            </PageContainer>
        </React.Fragment>
    );
};

export default withRouter(Calendar);