import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {Modal, Button} from 'antd';
import {BaseForm} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import TimelineCategory from "../../components/timelineCategory/TimelineCategory";
import TimeInput from "../../components/inputs/TimeInput";
import TextInput from "../../components/inputs/TextInput";
import {SelectContainer, NewOptionContainer} from './AddMomentModalStyles';

const validations = FormValidator.make({
  name: 'required',
  time: 'required'
});

let AddMomentModal = ({
  open,
  editMoment,
  loading,
  handleSubmit,
  onSubmit,
  closeModal,
  categories,
  showNew,
  setShowNew,
  requiredError
}) => {
  return (
    <Modal
      visible={open}
      title={editMoment ? 'Editar Momento' : 'Adicionar Momento'}
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      <BaseForm onSubmit={handleSubmit(onSubmit)}>
        <SelectContainer>
          <Field
            name='name'
            component={TimelineCategory}
            categories={categories}
            editName={editMoment && editMoment.name}
            showNew={showNew}
            setShowNew={setShowNew}
          />
          <NewOptionContainer showNew={showNew}>
            <Field
              name='newCategory'
              component={TextInput}
              placeholder='Introduza o nome'
              type="text"
              requiredError={requiredError}
              noLabel={true}
            />
          </NewOptionContainer>
        </SelectContainer>
        <Field
          name='time'
          component={TimeInput}
          label='Hora'
          editTime={editMoment && editMoment.time}
        />
      </BaseForm>
    </Modal>
  );
};

AddMomentModal = reduxForm({
  form: 'manage_our_day_form',
  validate: validations
})(AddMomentModal);

export default AddMomentModal;