import React from "react";
import PropTypes from "prop-types";
import { 
  SelectDiv, 
  StyledSelectInput, 
  OptionContainer,
  OptionTextOurDay,
  CustomSelectLabel
} from "./InputStyles";

const filterOption = (inputValue, option) => {
  if (option?.props?.children)
    return (
      option?.props?.children.toLowerCase().indexOf(inputValue.toLowerCase()) >
      -1
    );
  return option.key.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
};

const CustomSelectInput = ({
  input,
  data,
  placeholder,
  disabled,
  fetchData,
  meta,
  translatable,
  activeLanguage,
  allowClear,
  createNew,
  selectOption,
  label,
  showNew
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  const formatValue = value => {
    if (!value) return undefined;
    if (Array.isArray(value)) return value;
    return value.toString();
  };

  const changeSelect = value => {
    input.onChange(value || '');
  };
  
  return (
    <React.Fragment>
      <SelectDiv>
        {
          label &&
          <CustomSelectLabel>{label}</CustomSelectLabel>
        } 
        <StyledSelectInput
          disabled={disabled}
          placeholder={placeholder}
          filterOption={filterOption}
          allowClear={allowClear}
          onSearch={fetchData}
          onChange={changeSelect}
          value={formatValue(input?.value)}
          showArrow={true}
          showSearch={false}
          error={showError ? 1 : 0}
        > 
          <OptionContainer
            key='Criar Momento'
            onClick={() => createNew()}
          >
            <OptionTextOurDay>
              Criar Momento
            </OptionTextOurDay>
          </OptionContainer>
          {
            data.map((elem, index) => (
              <OptionContainer
                key={elem.name['pt']}
                onClick={() => selectOption()}
              >
                <OptionTextOurDay required={elem.required} requiredOrderModule={elem.requiredOrderModule}>
                  { elem.required || elem.requiredOrderModule ? `${elem.name['pt']} *` : elem.name['pt'] }
                </OptionTextOurDay>
              </OptionContainer>
            ))
          }
        </StyledSelectInput>
      </SelectDiv>
    </React.Fragment>
  );
};

CustomSelectInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  placeholder: PropTypes.string,
  fetchData: PropTypes.func,
  onPressEnter: PropTypes.func,
  allowClear: PropTypes.bool,
  createNew: PropTypes.func,
  selectOption: PropTypes.func,
  label: PropTypes.string
};

CustomSelectInput.defaultProps = {
  data: [],
  allowClear: false,
};

export default CustomSelectInput;