import PropTypes from 'prop-types';
import React from 'react';
import { InputDiv, InputLabelDiv, DateRangeInputField } from './InputStyles';
import moment from 'moment';

const RangePickerInput = ({
  input,
  meta,
  label,
  first,
  placeholder,
  disabled,
  dateFormat,
  allowClear
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed ? 1 : 0;
  return (
    <InputDiv first={first}>
      <InputLabelDiv>{label}</InputLabelDiv>
      <DateRangeInputField
        disabled={disabled}
        error={showError}
        allowClear={allowClear}
        placeholder={placeholder}
        format={dateFormat || 'DD-MM-YYYY'}
        defaultValue={
          Array.isArray(input.value)
            ? [moment.utc(input.value[0]), moment.utc(input.value[1])]
            : []
        }
        onChange={dates => {
          Array.isArray(dates) && dates?.length > 0
            ? input.onChange([
              moment(dates[0]).utc(true),
              moment(dates[1]).utc(true)
            ])
            : input.onChange([])
        }}
      />
    </InputDiv>
  );
};

RangePickerInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

export default RangePickerInput;
