import client from '../config/AxiosConfig';

export const CreateContentCategory = async (data) =>
  client.post(`/content_categories`, data);

export const GetContentCategory = async id =>
  client.get(`/content_categories/${id}`);

export const GetContentCategories = async () =>
  client.get(`/content_categories`);

export const UpdateContentCategory = async (id, data) =>
  client.put(`/content_categories/${id}`, data);

export const DeleteContentCategory = async id =>
  client.delete(`/content_categories/${id}`);