import React from 'react';
import { FieldArray } from 'redux-form';
import {
  OrderTable,
  OrderTableTitle
} from './OrderStyles';
import OrderIngredientsList from './components/OrderIngredientsList';
import OrderOptionsList from './components/OrderOptionsList';
import OrderFoodRestrictionsList from './components/OrderFoodRestrictionsList';

let GenericOrderForm = ({ ingredients, options, category, foodRestrictions }) => {
  return (
    <React.Fragment>
      {
        ingredients?.length > 0 &&
        <OrderTable>
          <OrderTableTitle>Ingredientes</OrderTableTitle>
          <FieldArray
            component={OrderIngredientsList}
            name='ingredients'
          />
        </OrderTable>
      }
      {
        options?.length > 0 &&
        <OrderTable>
          <OrderTableTitle>Opções</OrderTableTitle>
          <FieldArray
            component={OrderOptionsList}
            name='options'
          />
        </OrderTable>
      }
      {category == '5fd741299a4672b87a97104c' && foodRestrictions?.length > 0 &&
        <OrderTable>
          <OrderTableTitle>Restrições alimentares</OrderTableTitle>
          <FieldArray
            component={OrderFoodRestrictionsList}
            name='foodRestrictions'
          />
        </OrderTable>
      }
    </React.Fragment>
  );
};

export default GenericOrderForm;