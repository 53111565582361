import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Popconfirm, Icon, Tag } from 'antd';
import {
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  SpinLoading,
  TableButton,
  PageContainer
} from '../../styles/BasicStyles';
import {
  GetNews,
  CreateNews,
  DeleteNews,
  ToggleNews
} from '../../infra/requests/NewsRequests';
import BaseButton from '../../components/buttons/BaseButton';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import Table from '../../components/table/Table';
import NewsModal from './NewsModal';
import { GetCategoryName } from '../../infra/services/NewsCategories/NewsCategories';
import { LocalLabel } from '../weddings/Styles';
import { GetColorSystemByType, GetVenueColors } from '../../infra/requests/ColorSystemRequests';
import { GetTextColor } from '../../infra/services/text/textUtils';
import { VenueColors, getVenueColor } from '../../infra/services/wedding/weddingUtils';

export const NewsPlatform = {
  RESERVE: 'R', // R: Reserve
  CONFIRMED: 'C', // C: Confirmed Weddings
  BOTH: 'B', // B: Both
};

class UpgradesSection extends Component {
  state = {
    pageSize: 15,
    currentPage: 1,
    total: 0,
    loading: true,
    ready: false,
    openModal: false,
    news: [],
    columns: [
      {
        title: 'Titulo',
        dataIndex: 'title',
        render: value => TranslateValue(value)
      },
      {
        title: 'Categoria',
        dataIndex: 'category',
        render: value => GetCategoryName(value)
      },
      {
        title: 'Plataforma',
        dataIndex: 'platform',
        render: value => (value == NewsPlatform.RESERVE
          ? 'Reservas'
          : value === NewsPlatform.CONFIRMED
            ? 'Noivos'
            : value === NewsPlatform.BOTH
              ? 'Ambas'
              : '')
      },
      {
        title: 'Local',
        width: '50px',
        className: 'align-center',
        render: data => {
          const local = getVenueColor(data, this.state.colors);
          return local
            ? <LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.initials}</LocalLabel>
            : null;
        }
      },
      {
        title: 'Data',
        dataIndex: 'date',
        render: value => moment.utc(value).format('DD/MM/YYYY')
      },
      {
        title: 'Publicado',
        dataIndex: 'active',
        render: value => (value ? <Tag color="green">Sim</Tag> : 'Não')
      },
      {
        title: 'Acções',
        render: data => (
          <Fragment>
            <TableButton onClick={e => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title={`Quer ${data.active ? 'desativar' : 'publicar'
                  } esta notícia?`}
                onConfirm={() => this.toggleActive(data)}>
                <Icon type={data.active ? 'close-circle' : 'check-circle'} />
                {data.active ? ' Desativar' : ' Publicar'}
              </Popconfirm>
            </TableButton>
            <TableButton onClick={e => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title="Tem a certeza que quer apagar esta notícia?"
                onConfirm={() => this.deleteUpgrade(data._id)}>
                <Icon type="delete" />
                {' Apagar'}
              </Popconfirm>
            </TableButton>
          </Fragment>
        )
      }
    ],

    colors: VenueColors,
  };

  componentDidMount() {
    this.getLocalColors();
    this.getNews();
  }

  getLocalColors = async () => {
    let { colors } = this.state;
    const result = await GetVenueColors();

    if (result?.success) colors = result?.data;
    this.setState({ colors });
  }

  getNews = async () => {
    try {
      const { currentPage, pageSize } = this.state;
      const { data } = await GetNews(currentPage, pageSize);
      this.setState({
        news: data.items || [],
        total: data.total || 0
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
    }
  };

  createNew = async values => {
    this.setState({ loading: true });
    const { history } = this.props;
    const payload = FlattenToFormData(values);
    const { data, success } = await CreateNews(payload);
    this.setState({ loading: false });
    if (success) history.push(`/news/${data._id}`);
    else this.setState({ openModal: false });
  };

  deleteUpgrade = async id => {
    await DeleteNews(id);
    await this.getNews();
  };

  onPressRow = data => {
    const { history } = this.props;
    history.push(`/news/${data._id}`);
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.getNews);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.getNews);
  };

  toggleActive = async record => {
    this.setState({ loading: true });
    await ToggleNews(record._id, { active: !record.active });
    await this.getNews();
  };

  render() {
    const {
      ready,
      columns,
      news,
      total,
      loading,
      currentPage,
      pageSize,
      openModal
    } = this.state;
    if (!ready) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Notícias</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Nova Notícia'}
              block
              onClick={() => this.setState({ openModal: true })}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={1}>
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={news}
            showHeader={true}
            emptyIcon="database"
            emptyText={'Não existem notícias'}
            total={total}
            onPressRow={this.onPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowKey={'_id'}
            hasPagination={true}
          />
        </PageContainer>
        <NewsModal
          open={openModal}
          loading={loading}
          onSubmit={this.createNew}
          closeModal={() => this.setState({ openModal: false })}
        />
      </React.Fragment>
    );
  }
}

export default UpgradesSection;
