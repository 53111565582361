import React from 'react';
import PropTypes from 'prop-types';
import {withLocalize} from 'react-localize-redux';
import {Select} from 'antd';
import {InputDiv, InputLabelDiv, StyledSelectInput} from './InputStyles';
const Option = Select.Option;

const SelectLanguageInput = ({
  input,
  dataKey,
  dataLabel,
  placeholder,
  label,
  disabled,
  loading,
  first,
  meta,
  activeLanguage,
  languages
}) => {
  const {invalid, submitFailed} = meta;
  const showError = invalid && submitFailed;

  const changeSelect = value => {
    input.onChange(value || '');
  };

  // style={{marginBottom: '30px'}}
  return (
    <InputDiv first={first} >
      <InputLabelDiv>{label}</InputLabelDiv>
      <StyledSelectInput
        disabled={disabled}
        placeholder={placeholder}
        allowClear={false}
        onChange={changeSelect}
        value={input?.value || undefined}
        showArrow={true}
        showSearch={false}
        loading={loading}
        error={showError ? 1 : 0}>
        {languages.map(current => (
          <Option key={current[dataKey]} disabled={current.disabled}>
            {current[dataLabel]}
          </Option>
        ))}
      </StyledSelectInput>
    </InputDiv>
  );
};

SelectLanguageInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  loading: PropTypes.bool
};

SelectLanguageInput.defaultProps = {
  dataKey: 'code',
  dataLabel: 'name'
};

export default withLocalize(SelectLanguageInput);
