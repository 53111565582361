import React, { Component, Fragment } from 'react';
import { Avatar, Checkbox, Icon, Popconfirm, Tag, Tooltip } from 'antd';
import Table from '../../components/table/Table';
import { PageContainer, PageTitle, HeaderContainer, HeaderTitle, HeaderButtonsContainer, TableButton } from '../../styles/BasicStyles';
import { GetWeddings, TogglePublic, DeleteWedding, GetWeddingsByUser, GetAllWeddingsByUser } from '../../infra/requests/WeddingRequests';
import { ArchiveWedding } from '../../infra/requests/ArchivedRequests';
import BaseButton from '../../components/buttons/BaseButton';
import moment from 'moment';
import CurrencyComponent from '../../components/currency/CurrencyComponent';
import { LocalLabel, StateLabel } from '../weddings/Styles';
import { connect } from 'react-redux';
import { ClearWedding } from '../../redux/wedding/wedding.actions';
import { bindActionCreators } from 'redux';
import { GetTeamBookingByWedding } from '../../infra/requests/TeamBookingRequests';
import { VenueColors, getInitials, getLocal, getLocalColor, getScheduler, getVenueColor, getWeddingEmails, getWeddingName, getWeddingStatus, getWeddingTeamAndEmployeesInCharge } from '../../infra/services/wedding/weddingUtils';
import { getUserId, isCurrentUserAdmin, isCurrentUserChief, isCurrentUserEmployee, isCurrentUserEmployeeAttending, isCurrentUserHumanResources, isEmployeeOrChief } from '../../infra/helpers/Helpers';
import EmployeeWeddingsFilters from '../weddings/EmployeeWeddingsFilters';
import { GetOurDayByWedding } from '../../infra/requests/OurDayRequests';
import { GetColorSystemByType, GetVenueColors } from '../../infra/requests/ColorSystemRequests';
import { ConfirmWeddingTeamByemployee, GetWeddingTeamByWedding } from '../../infra/requests/WeddingTeamRequests';
import { GetSubcategories, GetSubcategoriesByType } from '../../infra/requests/SubcategoryRequests';
import { GetEmployeeByUser } from '../../infra/requests/EmployeeRequests';
import { GetTextColor } from '../../infra/services/text/textUtils';
import { WeddingStatus } from '../reserves/ReserveAgenda';
import OrdinalNumberComponent from '../../components/ordinalNumber/OrdinalNumberComponent';
import { DateColumn } from '../reserves/ReservesPage';
import { GetReservesPaginated, GetReservesPaginatedAndGrouped } from '../../infra/requests/ReserveRequests';
import EmployeeProfileReserveFilters from './EmployeeProfileReserveFilters';
import { getReserveStatus } from '../reserves/reservesUtils';
import { SpanContact } from '../weddings/WeddingsPage';
import { GetSchedulesPaginatedByEmployee } from '../../infra/requests/SchedulesRequests';

class EmployeeProfileSchedules extends Component {
    state = {
        pageSize: 30,
        currentPage: 1,
        loading: false,
        filters: {
            month: moment.utc().format('MM/YYYY')
        },
        employee: null,

        columnsAttending: [
            {
                title: 'Data',
                width: '80px',
                render: data => {
                    return data?.date
                        ? <DateColumn>
                            <span className='date'>{moment.utc(data.date).format('DD-MM-YYYY')}</span>
                            <span className='weekdayName'>{moment.utc(data.date).locale('pt').format('dddd')}</span>
                        </DateColumn>
                        : '';
                }
            },
            {
                title: 'Horário',
                width: '60px',
                render: data => {
                    return <div>
                        {data.startTime}
                        < Icon type="swap-right" />
                        {data.endTime}
                    </div>
                }
            },
            {
                title: 'Local',
                width: '50px',
                render: data => {
                    const local = getVenueColor(data, this.state.colors);
                    return local
                        ? <LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.initials}</LocalLabel>
                        : null
                }
            },
            {
                title: 'Noivos',
                width: '100px',
                render: data => getWeddingName(data.wedding)
            },
            {
                title: 'Idioma',
                width: '100px',
                render: data => {
                    return data?.language == 'pt_en'
                        ? 'Preferencialmente Inglês, mas pode ser em Português'
                        : data?.language == 'en'
                            ? 'Inglês'
                            : 'Português';
                }
            },
            {
                title: 'Tipo de Atendimento',
                width: '80px',
                render: data => {
                    return data?.attendingType?.name?.pt || '';
                }
            },
            {
                title: 'Presença',
                width: '50px',
                render: data => {
                    return data?.presence && data?.presence == 'P'
                        ? <Tag color="green">Presente</Tag>
                        : data?.presence && data?.presence == 'M'
                            ? <Tag color="red">Faltou</Tag>
                            : '';
                }
            },
        ],
        columns: [
            {
                title: 'Data',
                width: '80px',
                render: data => {
                    return data?.date
                        ? <DateColumn>
                            <span className='date'>{moment.utc(data.date).format('DD-MM-YYYY')}</span>
                            <span className='weekdayName'>{moment.utc(data.date).locale('pt').format('dddd')}</span>
                        </DateColumn>
                        : '';
                }
            },
            {
                title: 'Horário',
                width: '60px',
                render: data => {
                    return <div>
                        {data.startTime}
                        < Icon type="swap-right" />
                        {data.endTime}
                    </div>
                }
            },
            {
                title: 'Local',
                width: '50px',
                render: data => {
                    const local = getVenueColor(data, this.state.colors);
                    return local
                        ? <LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.initials}</LocalLabel>
                        : null
                }
            },
            {
                title: 'Email',
                width: '60px',
                render: data => {
                    return <Tooltip title={getWeddingEmails(data.wedding)}>
                        <SpanContact onClick={($event) => this.copy($event, getWeddingEmails(data?.wedding))}>
                            {getWeddingEmails(data.wedding)}
                        </SpanContact>
                    </Tooltip>
                }
            },
            // {
            //     title: 'Tel',
            //     width: '60px',
            //     render: data => {
            //         return <Tooltip title={getWeddingContacts(data.wedding)}>
            //             <SpanContact onClick={($event) => this.copy($event, getWeddingContacts(data.wedding))}>
            //                 {getWeddingContacts(data.wedding)}
            //             </SpanContact>
            //         </Tooltip>
            //     }
            // },
            {
                title: 'Idioma',
                width: '100px',
                render: data => {
                    return data?.language == 'pt_en'
                        ? 'Preferencialmente em Inglês, mas pode ser em Português'
                        : data?.language == 'en'
                            ? 'Inglês'
                            : 'Português';
                }
            },
            {
                title: 'Tipo de Atendimento',
                width: '80px',
                render: data => {
                    return data?.attendingType?.name?.pt || '';
                }
            },
            {
                title: 'Presença',
                width: '50px',
                render: data => {
                    return data?.presence && data?.presence == 'P'
                        ? <Tag color="green">Presente</Tag>
                        : data?.presence && data?.presence == 'M'
                            ? <Tag color="red">Faltou</Tag>
                            : '';
                }
            },
        ],

        rows: [],
        rowsSearch: null,
        total: 0,
        colors: VenueColors,
    };

    componentDidMount = async () => {
        this.setState({ employee: this.props.employee }, () => this.getLocalColors());
    };

    getLocalColors = async () => {
        let { colors } = this.state;
        const result = await GetVenueColors();
    
        if (result?.success) colors = result?.data;
        this.setState({ colors }, () => this.updateDataTable());
      }

    updateDataTable = async () => {
        const { currentPage, pageSize, employee } = this.state;
        let { filters } = this.state;

        this.setState({ loading: true });

        try {
            filters['profileEmployeeAttending'] = true;

            if (filters?.month) {
                filters.month = moment(filters.month, ['MM/YYYY']).format('MM/YYYY');
            }

            if (employee?.user?.employeeAttending) {
                filters['attendedBy'] = employee?.user?._id;
            }

            const result = await GetSchedulesPaginatedByEmployee(currentPage, pageSize, JSON.stringify(filters));
            // console.warn('Res', result);

            this.setState({
                rows: result.data.items,
                total: result.data.total,
                pageSize: result.data.limit,
                loading: false
            });
        } catch (e) {
            this.setState({ loading: false });
            console.error(e);
        }
    };

    handleChangePage = currentPage => {
        this.setState({ currentPage }, this.updateDataTable);
    };

    handleChangeRowsPerPage = (currentSize, pageSize) => {
        this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
    };

    handleFilterChange = filters => {
        this.setState({ filters, currentPage: 1 }, this.updateDataTable);
    };

    render() {
        const { history } = this.props;
        const { loading, currentPage, pageSize } = this.state;
        const { columns } = this.state;
        const { columnsAttending } = this.state;
        const { rows, total } = this.state;
        const { employee } = this.state;

        return (
            <React.Fragment>
                <EmployeeProfileReserveFilters queryChange={this.handleFilterChange} />
                <div className='table-responsive'>
                    <Table
                        columns={isCurrentUserEmployeeAttending() ? columnsAttending : columns}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        defaultPageSize={pageSize}
                        loading={loading}
                        rows={rows}
                        showHeader={true}
                        emptyIcon="calendar"
                        emptyText={'Não existem atendimentos'}
                        total={total}
                        rowKey={'_id'}
                        hasPagination={true}
                        // onPressRow={this.openProcess}
                        handleChangePage={this.handleChangePage}
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({ wedding: state.wedding });

const mapActionToProps = dispatch => bindActionCreators({ ClearWedding }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(EmployeeProfileSchedules);