export const FoodTypes = {
  entrees: 'Pratos de entrada',
  meat: 'Pratos de carne',
  fish: 'Pratos de Peixe',
  dessert: 'Sobremesas',
  cake: 'Bolos',
  supper: 'Ceia',
  sidedishes: 'Guarnição',
  internal_appetizers: 'Aperitivos',
  internal_buffets: 'Buffet de Doces',
  internal_staff: 'Refeições do Staff',
};

export const GetFoodType = value => FoodTypes[value];

export const GetFoodOptions = () => {
  return Object.keys(FoodTypes).map(key => ({
    _id: key,
    name: FoodTypes[key]
  }));
};

export default FoodTypes;
