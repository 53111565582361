import React, {Fragment, useEffect, useState} from 'react';
import {Modal} from 'antd';
import {
  Field,
  reduxForm,
  initialize,
  change as ChangeField
} from 'redux-form';
import FormValidator from '../../../infra/services/validations/FormValidator';
import CheckboxInput from '../../../components/inputs/CheckboxInput';
import SelectLanguageInput from '../../../components/inputs/SelectLanguageInput';
import SelectInput from '../../../components/inputs/SelectInput';
import {Form} from '../../../process/exportation/ExportationStyles';
import {Costumizations, CostumeDefinitions} from '../../../process/exportation/Costumizations';
import {auth_token_key} from '../../../infra/config/LocalStorageKeys';

const validations = FormValidator.make({
  language: 'required'
});

const REACT_APP_API = process.env.REACT_APP_API;

let ExportationModal = ({weddingID, onCancel, openModal, handleSubmit, dispatch, language}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(
      initialize('manage_archived_exportations_form', {
        ...Costumizations['original'],
        definition: 'original',
        language
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = values => {
    setLoading(true);
    let filename = weddingID;
    const authToken = localStorage.getItem(auth_token_key);
    fetch(
      `${REACT_APP_API}/archived/report/${weddingID}?filter=${JSON.stringify(
        values
      )}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then(resp => {
        const header = resp.headers.get('Content-Disposition');
        filename = header.split('=')[1];
        return resp.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        const name = filename.replace(/^"|"$/g, '');
        a.download = name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setLoading(false);
      })
      .catch(e => console.error(e));
  };

  const handleCheckChange = () => {
    dispatch(ChangeField('manage_archived_exportations_form', 'definition', ''));
  };

  const handleCostumeChange = option => {
    dispatch(
      initialize('manage_archived_exportations_form', {
        ...Costumizations[option],
        language
      })
    );
  };

  return (
    <Fragment>
      <Modal
        visible={openModal}
        title="Exportações"
        okText="Exportar"
        maskClosable
        onCancel={() => onCancel()}
        okButtonProps={{loading}}
        onOk={handleSubmit(onSubmit)}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={SelectInput}
            name={'definition'}
            label={'Pré-definidas'}
            placeholder="Customizado"
            data={CostumeDefinitions}
            onChange={handleCostumeChange}
          />
          <Field
            component={SelectLanguageInput}
            name={'language'}
            label={'Idioma'}
          />
          <Field
            component={CheckboxInput}
            name="pending_and_budget"
            label="Secções"
            onChange={handleCheckChange}
            positiveLabel="Pendentes e Orçamento"
          />
          <Field
            component={CheckboxInput}
            name="timeline"
            onChange={handleCheckChange}
            positiveLabel="O nosso dia: Timeline completa"
          />
          <Field
            component={CheckboxInput}
            name="guests"
            positiveLabel="Convidados"
            onChange={handleCheckChange}
          />
          <Field
            component={CheckboxInput}
            name="food"
            positiveLabel="Food Selection"
            onChange={handleCheckChange}
          />
          <Field
            component={CheckboxInput}
            name="drinks"
            positiveLabel="Drinks Selection"
            onChange={handleCheckChange}
          />
          <Field
            component={CheckboxInput}
            name="staff"
            positiveLabel="Staff"
            onChange={handleCheckChange}
          />
          <Field
            component={CheckboxInput}
            name="decoration"
            onChange={handleCheckChange}
            positiveLabel="Decoração"
          />
          <Field
            component={CheckboxInput}
            name="style"
            onChange={handleCheckChange}
            positiveLabel="O nosso estilo"
          />
          <Field
            component={CheckboxInput}
            name="ceremony"
            onChange={handleCheckChange}
            positiveLabel="Cerimónia"
          />
          <Field
            component={CheckboxInput}
            name="party"
            onChange={handleCheckChange}
            positiveLabel="Party Selection"
          />
          <Field
            component={CheckboxInput}
            name="upgrade"
            onChange={handleCheckChange}
            positiveLabel="Detalhes do Upgrade"
          />
          <Field
            component={CheckboxInput}
            name="budget"
            onChange={handleCheckChange}
            positiveLabel="Orçamento"
          />
          <Field
            component={CheckboxInput}
            name="unanswered"
            onChange={handleCheckChange}
            positiveLabel="Perguntas sem resposta"
          />

          <Field
            component={CheckboxInput}
            name="photo"
            label="Opções"
            onChange={handleCheckChange}
            positiveLabel="Incluir photo do casal no cabeçalho"
          />
          <Field
            component={CheckboxInput}
            name="public_notes"
            onChange={handleCheckChange}
            positiveLabel="Mostrar notas publicas"
          />
          <Field
            component={CheckboxInput}
            name="private_notes"
            onChange={handleCheckChange}
            positiveLabel="Mostrar notas privadas"
          />
        </Form>
      </Modal>
    </Fragment>
  );
};

ExportationModal = reduxForm({
  form: 'manage_archived_exportations_form',
  validate: validations
})(ExportationModal);

export default ExportationModal;