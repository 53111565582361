import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import moment from 'moment';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton
} from '../../styles/BasicStyles';
import {
  GetReservations,
  DeleteReservationLink
} from '../../infra/requests/ReservationsRequests';
import BaseButton from '../../components/buttons/BaseButton';

class ReservationsPage extends Component {
  state = {
    loading: false,
    columns: [
      {
        title: 'Local',
        dataIndex: 'local',
        render: value =>
          value === 1 ? 'Solar da Levada' : 'Quinta Lago dos Cisnes'
      },
      {
        title: 'Ano',
        dataIndex: 'year',
        render: value => moment(value).year()
      },
      {
        title: 'Link',
        dataIndex: 'document'
      },
      {
        title: 'Actions',
        render: data => (
          <React.Fragment>
            <TableButton onClick={e => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title={'Tem a certeza que quer apagar este link de reservas?'}
                onConfirm={() => this.deleteItem(data)}>
                <Icon type="delete" />
                {' Remove'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        )
      }
    ],
    rows: []
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  createList = data => {
    const result = [];
    data.forEach(place => {
      place.links.forEach(link => {
        result.push({
          local: place.local,
          _id: link._id,
          year: link.year,
          document: link.document
        });
      });
    });
    result.sort((a, b) => {
      const isBefore = moment(a.year).isBefore(moment(b.year));
      return isBefore ? 1 : -1;
    });
    return result;
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { data } = await GetReservations();
      this.setState({
        loading: false,
        rows: this.createList(data)
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  deleteItem = async record => {
    try {
      await DeleteReservationLink(record.local, record._id);
      await this.updateDataTable();
    } catch (err) {
      console.error(err);
    }
  };

  handleOnPressRow = record => { };

  render() {
    const { history } = this.props;
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Reservas</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/management/reservations/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={1}>
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="calendar"
            emptyText={'There are not reservation links'}
            total={total}
            rowKey={'_id'}
            hasPagination={false}
            onPressRow={this.handleOnPressRow}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default ReservationsPage;
