import styled from 'styled-components';


export const StaffBox = styled.div`
  display: block;
  width: 100%;
  max-width: 500px;
  background: #f5f5f5;
  margin-bottom: 30px;
  border-radius: 4px;
  padding: 30px 15px;
  position: relative;
`;

export const StaffItem = styled.div`
  font-size: 14px;
  font-weight: bold;
  white-space: pre-line;

  span {
    margin-left: 10px;
    font-size: 16px;
    font-weight: normal;
  }
`;

export const EmptySection = styled.div`
  display: inline-block;
  margin: 50px 0;
  text-align: center;
  width: 100%;
  i {
    font-size: 30px;
    margin-right: 15px;
  }
`;

export const SectionIcon = styled.img`
  display: inline-block;
  width: 30px;
  margin-right: 15px;
`;

export const OptionButton = styled.div`
  cursor: pointer;
  position: absolute;
  background: white;
  padding: 5px;
  top: -5px;
  right: ${({number}) => -5 + number * 30}px;
  box-shadow: 1px 1px 3px #ccc;
`;