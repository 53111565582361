import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { bindActionCreators } from 'redux';
import SectionMenu from '../ChoicesPage/SectionMenu';
import {
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  SpinLoading,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import { ChoicesPageContainer } from '../ChoicesPage/SectionStyles';
import {
  GetOurGuests,
  SaveGuests,
  SaveFloorPlan,
  ActivateRoomPlan
} from '../../infra/requests/WeddingProcessRequests';
import GuestsContent from './GuestsContent';
import { SaveNotes } from '../../redux/wedding/wedding.actions';
import { SaveSectionNotes } from '../../infra/requests/WeddingProcessRequests';
import BaseButton from '../../components/buttons/BaseButton';
//import CommentsDrawer from '../../components/comments/CommentsDrawer';
import NotesConfirmModal from '../../components/notesModal/NotesModal';
import { hasNotes } from '../../infra/services/notes/FindNotes';
import PrintedBar from '../dashboard/components/PrintedBar';
import CommentsDrawerFixed from '../../components/comments/CommentsDrawerFixed';
import { getPrintedConfirmation } from '../../infra/services/wedding/printedUtils';
import { Modal } from 'antd';
import { GetRoomPlanConfigByPlace, GetRoomPlanConfigByWedding } from '../../infra/requests/RoomPlanConfigRequests';

const { confirm } = Modal;

const Questions = {
  min_people: '5e2adf6947f38787fd9e8426',
  provisory_guests: '5e2adf9947f38787fd9e8427',
  guests_location: '5e2adfbf47f38787fd9e8428',
  provisory_tables: '5e2adfea47f38787fd9e8429',
  system_plan: '5e2ae09f47f38787fd9e842a',
  room_plan: '5e2ae0b247f38787fd9e842b',
  roomPlanType: '6579de7741f842ab6e10b56c',
  roomPlanActive: '657a0f2e41f842ab6e10b571',
  guests: '5e2ae0f647f38787fd9e842c',
  children: '5e2ae11147f38787fd9e842d',
  staff: '5e2ae12647f38787fd9e842e',
  babies: '5e2ae14347f38787fd9e842f',
  pax: '5e2ae16b47f38787fd9e8430',
  places: '5e2ae17c47f38787fd9e8431',
  tables: '5e2ae18d47f38787fd9e8432'
};

class DecorationPage extends Component {
  state = {
    loading: true,
    ready: false,
    loadNotes: false,
    openDrawer: false,
    openNotesConfirm: false,
    section: [],
    guests: {},
    roomPlanOptions: [],
  };

  componentDidMount() {
    this.getInformation();
  }

  addInformation = (data = {}) => {
    const {
      wedding: { wedding, structure }
    } = this.props;
    const { roomPlanOptions } = this.state;
    const section = structure.find(s => s.tag === 'GUESTS');
    section.chapters = section.chapters.map(chapter => {
      chapter.questions = chapter.questions.map(question => {
        if (question._id === Questions.min_people) {
          question.answer = {
            _id: question._id,
            response: wedding.min_people
          };
        }
        if (question._id === Questions.provisory_guests) {
          question.answer = {
            _id: question._id,
            response: data.provisory_guests
          };
        }
        if (question._id === Questions.guests_location) {
          question.answer = {
            _id: question._id,
            response: data.guests_location
          };
        }
        if (question._id === Questions.provisory_tables) {
          const tables = Math.ceil(data.provisory_guests / 10);
          question.answer = {
            _id: question._id,
            response: tables
          };
        }
        if (question._id === Questions.system_plan) {
          question.type = 9;
        }
        if (question._id === Questions.room_plan) {
          question.answer = {
            _id: question._id,
            response: data.room_plan
          };
        }
        if (question._id === Questions.roomPlanType) {
          const response = data?.roomPlanTemplate
            ? [{ id: data.roomPlanTemplate, qtd: 1 }]
            : null;
          question.answer = {
            _id: question._id,
            response
          };
          question.options = roomPlanOptions?.map(m => ({ ...m, title: m?.name }));
        }
        if (question._id === Questions.roomPlanActive) {
          question.answer = {
            _id: question._id,
            response: [{
              id: (data?.roomPlanActive || false)?.toString(),
              qtd: 1
            }]
          };
        }
        if (question._id === Questions.guests) {
          question.answer = {
            _id: question._id,
            response: Number.isInteger(parseInt(data.guests))
          };
        }
        if (question._id === Questions.children) {
          question.answer = {
            _id: question._id,
            response: Number.isInteger(parseInt(data.children))
          };
        }
        if (question._id === Questions.staff) {
          question.answer = {
            _id: question._id,
            response: Number.isInteger(parseInt(data.staff))
          };
        }
        if (question._id === Questions.babies) {
          question.answer = {
            _id: question._id,
            response: Number.isInteger(parseInt(data.babies))
          };
        }
        if (question._id === Questions.pax) {
          question.answer = {
            _id: question._id,
            response: Number.isInteger(parseInt(data.pax))
          };
        }
        if (question._id === Questions.places) {
          question.answer = {
            _id: question._id,
            response: Number.isInteger(parseInt(data.places))
          };
        }
        if (question._id === Questions.tables) {
          question.answer = {
            _id: question._id,
            response: Number.isInteger(parseInt(data.tables))
          };
        }
        return question;
      });
      return chapter;
    });
    return section;
  };

  getInformation = async () => {
    const {
      match: { params },
      dispatch,
      wedding
    } = this.props;
    this.setState({ loading: true });
    const resultRoomPlanConfigs = await GetRoomPlanConfigByWedding(params.wedding);
    this.setState({
      roomPlanOptions: resultRoomPlanConfigs?.success && resultRoomPlanConfigs?.data ? resultRoomPlanConfigs?.data : [],
    });

    const { data } = await GetOurGuests(params.wedding);
    dispatch(initialize('manage_guests_form', data));
    const section = this.addInformation(data);
    this.setState({
      ready: true,
      loading: false, section, guests: data || {},
      roomPlanOptions: resultRoomPlanConfigs?.success && resultRoomPlanConfigs?.data ? resultRoomPlanConfigs?.data : [],
    });
  };

  submitGuests = async values => {
    const {
      match: { params }
    } = this.props;
    // this.setState({ready: false, loading: true});
    await SaveGuests(params.wedding, values);
    await this.getInformation();
  };

  submitFloorPlan = async file => {
    const {
      match: { params }
    } = this.props;
    // this.setState({ ready: false, loading: true });
    const payload = new FormData();
    payload.append('file', file.blob);
    await SaveFloorPlan(params.wedding, payload);
    await this.getInformation();
  };

  openNotes = () => {
    const { wedding, dispatch } = this.props;
    const { openDrawer } = this.state;
    if (!openDrawer) {
      const notes = wedding.notes.find(note => note.section === 'GUESTS');
      dispatch(initialize('wedding_notes_section_form', notes));
      this.setState({ openDrawer: true });
    }
  };

  closeNotes = () => {
    const { wedding, dispatch } = this.props;
    const notes = wedding.notes.find(note => note.section === 'GUESTS');
    dispatch(initialize('wedding_notes_section_form', notes));
    this.setState({ openDrawer: false, openNotesConfirm: false });
  };

  checkBeforeCloseNotes = (values) => {
    const { wedding, dispatch } = this.props;
    const notes = wedding.notes.find(note => note.section === 'GUESTS');

    //If theres any change to the notes, ask before closing if we want to save the changes
    if (values && (notes?.notes !== values.notes || notes?.private !== values.private)) {
      dispatch(initialize('notes_modal_confirm_form', values));
      this.setState({ openNotesConfirm: true });
    }
    //Otherwise, just the close the notes
    else {
      this.setState({ openDrawer: false });
    }
  }

  saveNotes = async values => {
    const { wedding } = this.props;

    if (wedding?.wedding?.printed) {
      confirm({
        ...getPrintedConfirmation(),
        onOk: () => { this.updateNotes(values); },
        onCancel: () => { this.closeNotes(); },
      });
    } else this.updateNotes(values);
  };

  updateNotes = async values => {
    const { wedding, saveNotesOnReducer, dispatch } = this.props;
    this.setState({ loadNotes: true });
    const { data } = await SaveSectionNotes(wedding.wedding._id, {
      ...values,
      section: 'GUESTS'
    });

    dispatch(initialize('wedding_notes_section_form', data));
    saveNotesOnReducer(data);
    this.setState({ loadNotes: false, openDrawer: false, openNotesConfirm: false });
  };

  render() {
    const { wedding, history } = this.props;
    const { section, loading, ready, openDrawer, loadNotes, openNotesConfirm, guests } = this.state;
    if (!ready) return <SpinLoading />;
    return (
      <Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Convidados</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type={'default'}
              icon={'edit'}
              text={'Dist. Convidados'}
              onClick={() => history.push(`/wedding/${wedding?.wedding?._id}/guestMap`)}
            />
            <BaseButton
              type={'default'}
              icon={'read'}
              text={'Notas'}
              onClick={this.openNotes}
              notes={hasNotes(wedding.notes, 'GUESTS')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        {
          wedding?.wedding?.printed && <PrintedBar small={true} />
        }
        <ChoicesPageContainer printed={wedding?.wedding?.printed}>
          <SectionMenu section={section} printed={wedding?.wedding?.printed} />
          <GuestsContent
            section={section}
            submitGuests={this.submitGuests}
            submitPlan={this.submitFloorPlan}
            loading={loading}
            printed={wedding?.wedding?.printed}
            guestsInfo={guests}
            notesOpen={openDrawer}
            history={this.props.history}
          />
          <CommentsDrawerFixed
            open={openDrawer}
            loading={loadNotes}
            onClose={this.checkBeforeCloseNotes}
            saveNotes={this.saveNotes}
            printed={wedding?.wedding?.printed}
          />
        </ChoicesPageContainer>
        {/* <CommentsDrawer
          title="Notas De Convidados"
          open={openDrawer}
          loading={loadNotes}
          onClose={this.checkBeforeCloseNotes}
          saveNotes={this.saveNotes}
        /> */}
        <NotesConfirmModal
          open={openNotesConfirm}
          loading={loadNotes}
          onSubmit={this.saveNotes}
          closeModal={() => { this.setState({ openNotesConfirm: false }) }}
          exitWithoutSaving={this.closeNotes}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  wedding: state.wedding
});

const mapActionToProps = dispatch =>
  bindActionCreators({ saveNotesOnReducer: SaveNotes, dispatch }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(DecorationPage);
