import styled from 'styled-components';
import { device } from '../../styles/Responsive';

export const WeddingHeader = styled.div`
  position: relative;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: left;
  flex-direction: row;
  height: 64px;
  width: calc(100% - 100px);

  @media ${device.tabletL} {
    width: calc(100% - 15px)
  }
`;

export const Title = styled.div`
  color: white;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .anticon {
    margin-left: 8px;
    font-size: 17px;
  }
`;

export const Information = styled.div`
  color: white;
  font-size: 14px;
  line-height: 14px;
  margin-top: 5px;
`;

export const SpaceBetween = styled.div`
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  margin: 0 10px;
`;

export const WeddingHeaderLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  width: 50%;
`;

export const WeddingHeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  margin-right: 130px;

  .ant-btn {
    margin-left: 8px;
  }

  .ant-btn span {
    white-space: nowrap;
  }

  @media ${device.tabletL} {
    margin-right: 110px;
  }
`;

export const UpgradeName = styled.div`
  font-size: 16px;
  line-height: 16px;
  margin-right: 8px;
`;