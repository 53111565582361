import React, { Component } from "react";
import { reduxForm, Field, initialize } from "redux-form";
import { connect } from "react-redux";
import BaseButton from "../../components/buttons/BaseButton";
import {
  FormContainer,
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  BaseForm,
  HeaderButtonsContainer,
  SpinLoading,
  DefaultLanguageTab
} from "../../styles/BasicStyles";
import {
  GetPortfolioImageById,
  UpdatePortfolioImageById,
  CreatePortfolioImage
} from "../../infra/requests/ImagePortfolioRequests";
import { Tabs, Row, Col } from "antd";
import { withLocalize } from "react-localize-redux";
import { GetTranslationDescription } from "../../infra/services/translations/AvailableTranslations";
import FormValidator from "../../infra/services/validations/FormValidator";
import TextInput from "../../components/inputs/TextInput";
import ImageInput from "../../components/inputs/ImageInput";
import CheckboxInput from "../../components/inputs/CheckboxInput";
import SelectInput from "../../components/inputs/SelectInput";
import { SampleInputSpace } from "../../components/inputs/InputStyles";
import { TransformToFormData } from "../../infra/services/formdata/TransformToFormData";
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  local: "required",
  text: "required|languages",
  image: "required"
});

class ManagePortfolioPage extends Component {
  state = {
    isNew: false,
    loading: true,
    ready: false
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;
    if (params.id) {
      const result = await GetPortfolioImageById(params.id);
      dispatch(initialize("manage_portfolio_form", { ...result.data }));
      this.setState({
        ready: true,
        isNew: false,
        loading: false
      });
    } else {
      this.setState({
        ready: true,
        isNew: true,
        loading: false
      });
    }
  };

  onSubmit = async values => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;
      const { isNew } = this.state;

      if (values.image && !values.image.blob) {
        delete values.image;
      }
      const payload = TransformToFormData(values);

      isNew
        ? await CreatePortfolioImage(payload)
        : await UpdatePortfolioImageById(params.id, payload);
      return history.push("/management/portfolio");
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    try {
      const { history } = this.props;
      return history.push("/management/portfolio");
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit, defaultLanguage, languages, locals } = this.props;
    const { ready, isNew, loading } = this.state;
    if (!ready || loading) return <SpinLoading />;
    const title = isNew ? "New Portfolio Image" : "Edit Portfolio Image";
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{title}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={12}>
              <Col xs={12}>
                <DefaultLanguageTab>
                  {GetTranslationDescription(defaultLanguage)}
                </DefaultLanguageTab>
                <Field
                  component={SelectInput}
                  name={"local"}
                  label={"Local"}
                  placeholder={"Escolha o Local"}
                  type="select"
                  data={locals}
                  dataKey={"_id"}
                  dataLabel={"name"}
                />
                <Field
                  component={TextInput}
                  name={`text.${defaultLanguage}`}
                  type="text"
                  label={"Titulo *"}
                  placeholder={"Insira título para o banner"}
                />
                <Field
                  component={ImageInput}
                  name="image"
                  label={"Imagem do Banner *"}
                  width="100%"
                  ratio={0.6}
                />
                <Field
                  component={CheckboxInput}
                  name="highlight"
                  label={"Destacar"}
                />
              </Col>
              <Col xs={12}>
                <Tabs>
                  {languages
                    .filter(x => !x.active)
                    .map(language => (
                      <TabPane
                        tab={GetTranslationDescription(language.code)}
                        key={language.code}
                      >
                        <SampleInputSpace first />
                        <Field
                          component={TextInput}
                          name={`text.${language.code}`}
                          type="text"
                          label={"Título *"}
                          placeholder={"Insira título do banner"}
                        />
                      </TabPane>
                    ))}
                </Tabs>
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManagePortfolioPage = reduxForm({
  form: "manage_portfolio_form",
  validate: validations
})(ManagePortfolioPage);

const mapStateToProps = state => ({
  locals: state.locals
});

export default withLocalize(connect(mapStateToProps)(ManagePortfolioPage));
