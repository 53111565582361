import client from '../config/AxiosConfig';

export const GetOption = async id => client.get(`/options/${id}`);

export const CreateOption = async data => client.post('/options', data);

export const CreateOptionAndUpdateAnswer = async data => client.post('/options/createOptionAndUpdateAnswer', data);

export const UpdateOption = async (id, data) => client.put(`/options/${id}`, data);

export const UpdateOptionOrder = async data => client.post(`/options/order`, data);

export const DeleteOption = async (id, data) => client.delete(`/options/${id}`, data);

export const SoftDeleteOption = async (id, deleted) =>
    client.delete(`/options/softDelete/${id}/${deleted}`);

export const ActivateOption = async (id, active) =>
    client.put(`/options/activate/${id}/${active}`);

export const SaveImageToOptionGallery = async (id, data) =>
    client.put(`/options/${id}/image`, data);

export const UpdateImageOrderOnGallery = async (id, data) =>
    client.post(`/options/${id}/galleryOrder`, data);

export const DeleteImageFromOptionGallery = async (id, img_id) =>
    client.delete(`/options/${id}/image/${img_id}`);

export const UpdateCoverImageToGallery = async (id, data) =>
    client.post(`/options/${id}/coverToGallery`, data);

export const UpdateGalleryImageToCoverImage = async (id, img_id) =>
    client.post(`/options/${id}/image/${img_id}/cover`);

// export const SaveFile = async (id, data) =>
//     client.put(`/options/${id}/file`, data);

// export const DeleteFile = async (id, file_id) =>
//     client.delete(`/options/${id}/file/${file_id}`);


/* Category Orders */

export const GetOptionsPagination = async (page, limit, filter = "") => client.get(`/options/orders/${page}/${limit}?filter=${filter}`);

export const GetOptionOrders = async id => client.get(`/options/orders/${id}`);

export const UpdateOptionOrders = async (id, data) => client.put(`/options/orders/${id}`, data);