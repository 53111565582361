import React, { Component, Fragment } from 'react';
import { Icon, Checkbox, Row, Col, Popconfirm, Modal, Button } from 'antd';
import Table from '../../components/table/Table';
import {
  HeaderButtonsContainer,
  TableButton,
  TableFilterSection
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import moment from 'moment';
import { DownloadPendingPayments, GetPaymentsByEmployee, GetPaymentsByEmployeeGroupedByAccount, GetPaymentsByEmployeePaginate, GetPaymentsGrouped, GetPaymentsPaginate, GetPaymentsToExport } from '../../infra/requests/PaymentRequests';
import Alert from "../../components/alert/Alert";
import { ApprovePaymentAccounts, DeletePaymentAccount, GetPaymentAccount, GetPaymentAccountNumbers, RemoveReceipt, SendEmailUpdateApproved, UpdatePaymentAccountApproved, UpdatePaymentAccountDeclaration, UpdateReceipt } from '../../infra/requests/PaymentAccountRequests';
import { isCurrentUserAdmin, isCurrentUserChief, isCurrentUserEmployee, isCurrentUserHumanResources, isEmployeeOrChief } from '../../infra/helpers/Helpers'
import { LocalLabel, StateLabel } from '../weddings/Styles';
import ModalPDF from '../employees/ModalPDF';
import TextInput from '../../components/inputs/TextInput';
import DateInput from '../../components/inputs/DateInput';
import SelectInput from '../../components/inputs/SelectInput';
import PaymentTotals from './PaymentTotals';
import { withRouter } from 'react-router-dom';
import ModalPaymentDeclaration from './ModalPaymentDeclaration';
import { getCurrencyNumber, getLocalColor, setDeclaration } from '../../infra/services/wedding/weddingUtils';
import { GetColorSystemByType } from '../../infra/requests/ColorSystemRequests';
import { GetSettingsByType } from '../../infra/requests/SettingRequests';
import { GetTextColor } from '../../infra/services/text/textUtils';
import { getFirstAndLastName, getLinesForEachWord, stylesPDF } from './utilPayments';
import ReactPDF, { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import styled from 'styled-components';
import Alerts from '../../components/alert/Alert';

const REACT_APP_IMAGES_URL = process.env.REACT_APP_IMAGES_URL;

export const ReceiptName = styled.div`
  position: relative; 
  padding-right: 14px;

  span.input {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: ${p => p?.detail ? '160px' : '200px'};
  }
`;


const RemoveMessage = (
  <div>
    <div>Tem a certeza que quer remover este recibo? </div>
    <div>
      O recibo será removido da plataforma e não
      conseguirá ser recuperado.
    </div>
  </div>
);

class PendingPayments extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    approving: false,
    openModal: false,
    status: 2,
    selectedRow: null,
    selectAllRows: false,

    showDeclarationModal: false,
    modalValues: null,
    declaration: null,

    searchNomeColaborador: '',
    searchDataInicial: null,
    searchDataFinal: null,
    searchEntities: [],
    searchEntity: null,
    accountList: [],
    searchAccountNumber: null,

    exporting: false,

    printing: false,
    pdfDocumentModal: false,
    pdfDocumentSize: "B6",
    pdfDocument: null,

    columns: [
      {
        title: ' ',
        key: 'select',
        width: '30px',
        render: data => !data.paymentAccount.approved ?
          <div className="sc-dxgOiQ fWHHkk" style={{ textAlign: 'center' }}>
            <Checkbox
              checked={data.checked}
              onChange={(e) => {
                let element = data;
                element.checked = !element.checked;
                let elements = this.state.rows;
                let index = elements.findIndex(x => x._id === element._id);
                if (index > -1) {
                  elements[index] = element;
                  this.setState({ rows: elements })
                }
              }}>
            </Checkbox>
          </div> : <Icon type='check-square' style={{ color: 'green', fontSize: 18 }}></Icon>
      },
      {
        title: 'Nº Conta',
        key: 'accountNumber',
        width: '90px',
        render: data => data?.paymentAccount.accountNumber
      },
      {
        title: 'Funcionário',
        key: 'employee',
        render: data => data?.employee.name
      },
      {
        title: 'Entidade',
        key: 'entity',
        render: value => {
          const localWedding = value?.paymentAccount.entityValue;
          const local = getLocalColor(null, this.state.colors, localWedding);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
        }
      },
      {
        title: 'Intervalo de Datas',
        key: 'dataInterval',
        render: data => {
          const dateInterval = data.dateInterval;
          const dtFormat = 'DD-MM-YYYY';
          const start = moment.utc(dateInterval.start).format(dtFormat);
          const end = moment.utc(dateInterval.end).format(dtFormat);
          if (start === end) return start;
          else return start + ' / ' + end;
        }
      },
      {
        title: 'Oficial',
        render: data => {
          const officialPercentage = data.paymentAccount.officialPercentage;
          const value = Math.round((data.paymentAccount.value + data.paymentAccount.bonus) * officialPercentage);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (officialPercentage * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Bónus',
        render: data => {
          const notOfficialPercentage = (1 - data.paymentAccount.officialPercentage);
          const value = Math.round((data.paymentAccount.value + data.paymentAccount.bonus) * notOfficialPercentage);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (notOfficialPercentage * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Total',
        render: data => {
          const value = Math.round(data.paymentAccount.value + data.paymentAccount.bonus);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Declaração',
        key: 'declaration',
        render: data => (
          <TableButton onClick={() => this.setState({ showDeclarationModal: true, modalValues: data.paymentAccount })}>
            <Icon type="check" />
            {data.paymentAccount.declaration ? ' Declaração Aceite' : ' Aceitar Declaração'}
          </TableButton>
        )
      },
      {
        title: 'Recibo',
        key: 'receipt',
        width: '200px',
        render: data => !data?.paymentAccount.declaration ? <div className="sc-dxgOiQ cGTQcX">
          <div style={{ height: 32, padding: '4px 11px', color: 'rgba(0,0,0, 0.65)', backgroundColor: '#fff', border: '1px solid #d9d9d9', borderRadius: '4px', transition: 'all 0.3s', position: 'relative' }}>
            {!data?.paymentAccount.hasReceipt
              ? <Icon onClick={e => this.onUploadButtonClick(data)} style={{ position: 'absolute', right: 11, top: 8 }} type={'upload'} />
              : <ReceiptName>
                <span className='input' onClick={e => this.onPreviewPDF(data)}>{data?.paymentAccount.receiptName}</span>
                {!data?.paymentAccount.approved && <Popconfirm
                  title={RemoveMessage}
                  onConfirm={() => this.clearReceipt(data)}
                  okText="Eliminar"
                  okType='danger'
                  cancelText="Cancelar"
                >
                  <Icon type={'close-circle'} style={{ position: 'absolute', right: 0, top: 4 }} />
                </Popconfirm>}
              </ReceiptName>}
          </div>
        </div> : null //data?.paymentAccount.receipt
      },
      {
        title: 'Acções',
        width: '120px',
        render: data => (
          <Fragment>
            <TableButton onClick={e => {
              //this.setState({ openModal: true, modalValues: data })
              const { history } = this.props;
              const { status } = this.state;
              const paymentAccountId = data.paymentAccount.id ? data.paymentAccount.id : data.paymentAccount._id;
              history.push(`/payment/${data.employee._id}/${status}/0/${paymentAccountId}`);
            }}>
              <Icon type="info" />
              {' Detalhe'}
            </TableButton>
            <TableButton onClick={e => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title='Tem a certeza que pretende reverter esta conta de pagamento?'
                text='Os pagamentos associados passarão ao estado "A pagar".'
                onConfirm={() => this.removePaymentAccount(data)}>
                <Icon type="undo" />
                {' Reverter'}
              </Popconfirm>
            </TableButton>
          </Fragment>
        )
      }
    ],
    columnsEmployee: [
      {
        title: ' ',
        key: 'select',
        width: '30px',
        render: data => !data.paymentAccount.approved ?
          <div className="sc-dxgOiQ fWHHkk" style={{ textAlign: 'center' }}>
            <Checkbox
              checked={data.checked}
              onChange={(e) => {
                let element = data;
                element.checked = !element.checked;
                let elements = this.state.rows;
                let index = elements.findIndex(x => x._id === element._id);
                if (index > -1) {
                  elements[index] = element;
                  this.setState({ rows: elements })
                }
              }}>
            </Checkbox>
          </div> : <Icon type='check-square' style={{ color: 'green', fontSize: 18 }}></Icon>
      },
      {
        title: 'Nº Conta',
        key: 'accountNumber',
        width: '90px',
        render: data => data.paymentAccount.accountNumber
      },
      {
        title: 'Entidade',
        key: 'entity',
        render: value => {
          const localWedding = value?.paymentAccount.entityValue;
          const local = getLocalColor(null, this.state.colors, localWedding);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
        }
      },
      {
        title: 'Intervalo de Datas',
        key: 'dataInterval',
        render: data => {
          const dateInterval = data.dateInterval;
          const dtFormat = 'DD-MM-YYYY';
          const start = moment.utc(dateInterval.start).format(dtFormat);
          const end = moment.utc(dateInterval.end).format(dtFormat);
          if (start === end) return start;
          else return start + ' / ' + end;
        }
      },
      {
        title: 'Oficial',
        render: data => {
          const officialPercentage = data.paymentAccount.officialPercentage;
          const value = Math.round((data.paymentAccount.value + data.paymentAccount.bonus) * officialPercentage);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (officialPercentage * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Bónus',
        render: data => {
          const notOfficialPercentage = 1 - data.paymentAccount.officialPercentage;
          const value = Math.round((data.paymentAccount.value + data.paymentAccount.bonus) * notOfficialPercentage);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (notOfficialPercentage * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Total',
        render: data => {
          const value = Math.round(data.paymentAccount.value + data.paymentAccount.bonus);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Declaração',
        key: 'declaration',
        render: data => (
          <TableButton onClick={() => this.setState({ showDeclarationModal: true, modalValues: data.paymentAccount })}>
            <Icon type="check" />
            {data.paymentAccount.declaration ? ' Declaração Aceite' : ' Aceitar Declaração'}
          </TableButton>
        )
      },
      {
        title: 'Recibo',
        key: 'receipt',
        width: '200px',
        render: data => <div className="sc-dxgOiQ cGTQcX">
          <div style={{ height: 32, padding: '4px 11px', color: 'rgba(0,0,0, 0.65)', backgroundColor: '#fff', border: '1px solid #d9d9d9', borderRadius: '4px', transition: 'all 0.3s', position: 'relative' }}>
            {!data?.paymentAccount.hasReceipt
              ? <Icon onClick={e => this.onUploadButtonClick(data)} style={{ position: 'absolute', right: 11, top: 8 }} type={'upload'} />
              : <ReceiptName>
                <span className='input' onClick={e => this.onPreviewPDF(data)}>{data?.paymentAccount.receiptName}</span>
                {!data?.paymentAccount.approved &&
                  <Popconfirm
                    title={RemoveMessage}
                    onConfirm={() => this.clearReceipt(data)}
                    okText="Eliminar"
                    okType='danger'
                    cancelText="Cancelar"
                  >
                    <Icon type={'close-circle'} style={{ position: 'absolute', right: 0, top: 4 }} />
                  </Popconfirm>}
              </ReceiptName>}
          </div>
        </div> //data?.paymentAccount.receipt
      },
      {
        title: 'Acções',
        width: '120px',
        render: data => (
          <Fragment>
            <TableButton onClick={e => {
              const { history } = this.props;
              const { status } = this.state;
              const paymentAccountId = data.paymentAccount.id ? data.paymentAccount.id : data.paymentAccount._id;
              history.push(`/payment/${data.paymentAccount.employee?._id}/${status}/0/${paymentAccountId}`);
            }}>
              <Icon type="info" />
              {' Detalhe'}
            </TableButton>
            <TableButton onClick={e => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title='Tem a certeza que pretende reverter esta conta de pagamento?'
                text='Os pagamentos associados passarão ao estado "A pagar".'
                onConfirm={() => this.removePaymentAccount(data)}>
                <Icon type="undo" />
                {' Reverter'}
              </Popconfirm>
            </TableButton>
          </Fragment>
        )
      }
    ],
    columnsCurrentEmployee: [
      {
        title: 'Nº Conta',
        key: 'accountNumber',
        width: '90px',
        render: data => data.paymentAccount.accountNumber
      },
      {
        title: 'Entidade',
        key: 'entity',
        render: value => {
          const localWedding = value?.paymentAccount.entityValue;
          const local = getLocalColor(null, this.state.colors, localWedding);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
        }
      },
      {
        title: 'Intervalo de Datas',
        key: 'dataInterval',
        render: data => {
          const dateInterval = data.dateInterval;
          const dtFormat = 'DD-MM-YYYY';
          const start = moment.utc(dateInterval.start).format(dtFormat);
          const end = moment.utc(dateInterval.end).format(dtFormat);
          if (start === end) return start;
          else return start + ' / ' + end;
        }
      },
      {
        title: 'Oficial',
        render: data => {
          const officialPercentage = data.paymentAccount.officialPercentage;
          const value = Math.round((data.paymentAccount.value + data.paymentAccount.bonus) * officialPercentage);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (officialPercentage * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Bónus',
        render: data => {
          const notOfficialPercentage = 1 - data.paymentAccount.officialPercentage;
          const value = Math.round((data.paymentAccount.value + data.paymentAccount.bonus) * notOfficialPercentage);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (notOfficialPercentage * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Total',
        render: data => {
          const value = Math.round(data.paymentAccount.value + data.paymentAccount.bonus);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Declaração',
        key: 'declaration',
        render: data => (
          <Fragment>
            <TableButton onClick={() => this.setState({ showDeclarationModal: true, modalValues: data.paymentAccount })}>
              <Icon type="check" />
              {data.paymentAccount.declaration ? ' Declaração Aceite' : ' Aceitar Declaração'}
            </TableButton>
          </Fragment>
        )
      },
      {
        title: 'Recibo',
        key: 'receipt',
        width: '200px',
        render: data => <div className="sc-dxgOiQ cGTQcX">
          <div style={{ height: 32, padding: '4px 11px', color: 'rgba(0,0,0, 0.65)', backgroundColor: '#fff', border: '1px solid #d9d9d9', borderRadius: '4px', transition: 'all 0.3s', position: 'relative' }}>
            {!data?.paymentAccount.hasReceipt
              ? <Icon onClick={e => this.onUploadButtonClick(data)} style={{ position: 'absolute', right: 11, top: 8 }} type={'upload'} />
              : <ReceiptName>
                <span className='input' onClick={e => this.onPreviewPDF(data)}>{data?.paymentAccount.receiptName}</span>
                {!data?.paymentAccount.approved &&
                  <Popconfirm
                    title={RemoveMessage}
                    onConfirm={() => this.clearReceipt(data)}
                    okText="Eliminar"
                    okType='danger'
                    cancelText="Cancelar"
                  >
                    <Icon type={'close-circle'} style={{ position: 'absolute', right: 0, top: 4 }} />
                  </Popconfirm>}
              </ReceiptName>}
          </div>
        </div> //data?.paymentAccount.receipt
      },
      {
        title: 'Acções',
        width: '120px',
        render: data => (
          <Fragment>
            <TableButton onClick={e => {
              const { history } = this.props;
              const { status } = this.state;
              const paymentAccountId = data.paymentAccount.id ? data.paymentAccount.id : data.paymentAccount._id;
              history.push(`/payment/${data.paymentAccount.employee?._id}/${status}/0/${paymentAccountId}`);
            }}>
              <Icon type="info" />
              {' Detalhe'}
            </TableButton>
          </Fragment>
        )
      }
    ],

    rows: [],
    total: 0,
    totalValue: 0,
    totalBonus: 0,
    updateChild: null,
    colors: {
      colorSys1: null,
      colorSys2: null
    },
  };

  componentDidMount = async () => {
    await this.updateDataTable();
    this.getLocalColors();
    this.getEntities();
    this.getPaymentAccountNumbers();
    await this.getDeclaration();
    this.setState({ updateChild: this.props.updateChild });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.updateChild !== this.props.updateChild) {
      this.setState({ updateChild: this.props.updateChild });
      this.updateDataTable();
    }

    if (prevProps.activeTab !== this.props.activeTab) {
      this.setState({
        selectAllRows: false,
        searchNomeColaborador: '',
        searchDataInicial: null,
        searchDataFinal: null,
        searchEntity: null,
        searchAccountNumber: null,
      }, () => {
        this.updateDataTable();
      });
    }
  }

  getDeclaration = async () => {
    const result = await GetSettingsByType(5);
    this.setState({ declaration: result.success && result.data.length > 0 ? result.data[0] : null });
  }

  getEntities = async () => {
    const result = await GetSettingsByType(1);

    if (result.success) {
      let list = [];
      result.data.forEach(element => {
        if (element.entityValue !== 3) list.push({ _id: element.entityValue, name: element.name });
      });
      this.setState({ searchEntities: list });
    } else console.error(result.message);
  }

  getPaymentAccountNumbers = async () => {
    const { status } = this.state;
    const result = await GetPaymentAccountNumbers(status, this.props.type === 0 ? null : this.props.employeeId, false);
    // console.warn('Result', result);
    if (result.success && result.data) {
      const list = result.data.map(m => {
        return { _id: m, name: m }
      });
      this.setState({ accountList: list });
    }
  }

  getLocalColors = async () => {
    const resColorSys1 = await GetColorSystemByType(4);
    const colorSys1 = resColorSys1.success && resColorSys1.data ? resColorSys1.data.color : '#f7b5d6';
    const resColorSys2 = await GetColorSystemByType(5);
    const colorSys2 = resColorSys2.success && resColorSys2.data ? resColorSys2.data.color : '#b6d7a9';
    this.setState({ colors: { colorSys1, colorSys2 } });
  }

  updateDataTable = async () => {
    let { status, totalValue, totalBonus, currentPage, pageSize } = this.state;
    const { selectAllRows } = this.state;
    const { searchNomeColaborador, searchEntity, searchAccountNumber, searchDataInicial, searchDataFinal } = this.state;
    this.setState({ loading: true });

    try {
      let filter = { employee: this.props.employeeId, status: status }
      if (searchNomeColaborador !== '') filter['name'] = searchNomeColaborador;
      if (searchEntity !== null) filter['entity'] = searchEntity;
      if (searchAccountNumber !== null) filter['accountNumber'] = searchAccountNumber;
      // if (searchDataInicial !== null) filter['startDate'] = searchDataInicial;
      // if (searchDataFinal !== null) filter['endDate'] = searchDataFinal;

      let result = await GetPaymentsPaginate(currentPage, pageSize, JSON.stringify(filter));
      // console.warn('Result', result);

      if (result.success && result.data.items.length > 0) {
        result.data.items = result.data.items.map((element) => {
          return {
            ...element,
            checked: selectAllRows ? true : false
          }
        }).sort((a, b) => a.paymentAccount.accountNumber > b.paymentAccount.accountNumber ? 1 : (b.paymentAccount.accountNumber > a.paymentAccount.accountNumber) ? -1 : 0)

        for (let i = 0; i < result.data.items.length; i++) {
          if (!isCurrentUserAdmin()) {
            totalValue = totalValue + result.data.items[i].paymentAccount.value;
            totalBonus = totalBonus + result.data.items[i].paymentAccount.bonus;
          }
        }
      }

      this.setState({
        rows: result.data.items,
        total: result.data.total,
        pageSize: result.data.limit,
        totalValue,
        totalBonus,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  removePaymentAccount = async (data) => {
    const result = await DeletePaymentAccount(data._id.paymentAccount);
    if (result.success) {
      Alert.new({
        type: "success",
        title: "Successo",
        text: "Conta de pagamento eliminada!"
      });
    }
    this.updateDataTable();
  }

  approvePayments = async () => {
    let selectedPayments = this.state.rows.filter(x => x.checked == true);
    if (selectedPayments.length == 0) {
      Alert.new({
        type: "error",
        title: "Atenção",
        text: "É necessário selecionar pelo menos um pagamento."
      });
      return;
    }
    //console.log('selectedPayments', selectedPayments);
    if (selectedPayments?.filter(x => x?.paymentAccount?.declaration == false && (x?.paymentAccount?.receipt == null || x?.paymentAccount?.receipt == '')).length > 0) {
      Alert.new({
        type: "error",
        title: "Atenção",
        text: "Para aprovar pagamentos, é necessário aceitar a declaração ou fornecer o recibo."
      });
      return;
    }

    this.setState({
      loading: true,
      approving: true
    });

    let paymentAccounts = selectedPayments?.length > 0
      ? selectedPayments.map(x => x?.paymentAccount)
      : [];
    const result = await ApprovePaymentAccounts(paymentAccounts);
    if (result?.success) {
      Alert.new({
        type: "success",
        title: "Successo",
        text: "Contas de pagamento aprovadas!"
      });
      this.setState({ approving: false }, () => this.updateDataTable());
    } else {
      this.setState({
        loading: false,
        approving: false
      });
    }
  }

  exportPayments = async () => {
    const { rows, status } = this.state;
    const { searchNomeColaborador, searchEntity, searchAccountNumber, searchDataInicial, searchDataFinal } = this.state;

    this.setState({
      exporting: true,
      loading: true
    });

    let filters = {
      employee: this.props.employeeId,
      status: status
    };
    if (searchNomeColaborador !== '') filters['name'] = searchNomeColaborador;
    if (searchEntity !== null) filters['entity'] = searchEntity;
    if (searchAccountNumber !== null) filters['accountNumber'] = searchAccountNumber;
    // if (searchDataInicial !== null) filter['startDate'] = searchDataInicial;
    // if (searchDataFinal !== null) filter['endDate'] = searchDataFinal;

    let selectedPayments = rows?.length > 0
      ? rows.filter(x => x.checked === true)
      : [];

    // Export file
    let result = await DownloadPendingPayments(JSON.stringify(filters), { selected: selectedPayments });

    if (result) {
      let filename = 'Pagamentos_' + moment().format('YYYYMMDD') + '.xlsx';

      if (result.headers) {
        const contentDisposition = result.headers.get('content-disposition');
        filename = contentDisposition.split('filename="')[1].split('"')[0];
      }

      //Create blob link to download
      const url = window.URL.createObjectURL(new Blob([result])); //result.blob
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);

      //Append to html link element page
      document.body.appendChild(link);

      //Start download
      link.click();

      //Clean up and remove the link
      link.parentNode.removeChild(link);
    }

    this.setState({
      exporting: false,
      loading: false
    });
  };

  printPaymentAccount = async () => {
    const { selectAllRows, rows } = this.state;
    const { status, searchNomeColaborador, searchEntity, searchAccountNumber, searchDataInicial, searchDataFinal } = this.state;
    const { pdfDocumentSize } = this.state;
    let selectedPayments = [];

    if (selectAllRows) {
      this.setState({
        loading: true,
        printing: true
      });
      let filter = { employee: this.props.employeeId, status }
      if (searchNomeColaborador !== '') filter['name'] = searchNomeColaborador;
      if (searchEntity !== null) filter['entity'] = searchEntity;
      if (searchAccountNumber !== null) filter['accountNumber'] = searchAccountNumber;
      // if (searchDataInicial !== null) filter['startDate'] = searchDataInicial;
      // if (searchDataFinal !== null) filter['endDate'] = searchDataFinal;

      const result = await GetPaymentsToExport(filter);
      if (result?.success) {
        selectedPayments = result.data || [];
      } else {
        this.setState({
          loading: false,
          printing: false
        });
      }
    } else {
      selectedPayments = rows.filter(x => x.checked === true);
      if (selectedPayments?.length === 0) {
        this.setState({
          loading: false,
          printing: false
        });
        return Alert.new({
          type: "error",
          title: "Atenção",
          text: "É necessário selecionar pelo menos um pagamento."
        });
      } else {
        this.setState({
          loading: true,
          printing: true
        });
      }
    }

    if (selectedPayments?.length == 0) {
      this.setState({
        loading: false,
        printing: false
      });
      return Alert.new({
        type: "error",
        title: "Erro",
        text: "Não existem pagamentos para imprimir."
      });
    }

    let document =
      <Document title='Envelopes'>
        {selectedPayments.map((sp, index) => {
          return <Page key={index} size={pdfDocumentSize} orientation="landscape" wrap>
            <View style={stylesPDF.section} orientation="landscape">
              <View style={stylesPDF.row}>
                <View style={stylesPDF.column}>
                  <Text style={stylesPDF.text}>Contas</Text>
                  <Text style={stylesPDF.text}>{sp.paymentAccount.accountNumber.split('/')[0]}</Text>
                  <Text style={stylesPDF.text}>{sp.paymentAccount.accountNumber.split('/')[1]}</Text>
                </View>
                <View style={stylesPDF.column}>
                  <Text style={stylesPDF.text}>{sp.paymentAccount.entity}</Text>
                </View>
                <View style={stylesPDF.column}>
                  {getFirstAndLastName(sp.employee.name)}
                </View>
                <View style={stylesPDF.sectionPayments}>
                  {sp.payments
                    .sort((a, b) => a?.weddingDate && b?.weddingDate && a?.weddingDate > b?.weddingDate ? 1 : -1)
                    .map((p, pIndex) => {
                      return <View key={pIndex} style={stylesPDF.payment}>
                        <Text style={stylesPDF.text}>{moment.utc(p.weddingDate).date()} {moment.utc(p.weddingDate).locale('pt-br').format('MMM')}</Text>
                        <Text style={stylesPDF.text}>{Math.round((p.value + p.bonus) * (1 - p.officialPercentage))}</Text>
                      </View>
                    })}
                </View>
                <View style={stylesPDF.total}>
                  <Text style={stylesPDF.text}>{Math.round((sp.paymentAccount.value + sp.paymentAccount.bonus) * (1 - sp.paymentAccount.officialPercentage))}</Text>
                </View>
              </View>
            </View>
          </Page>
        })}
      </Document>;

    this.setState({
      pdfDocument: document,
      pdfDocumentModal: true,
      loading: false,
      printing: false
    });
  }

  closePdfDocumentModal = () => {
    this.setState({ pdfDocumentModal: false })
  }

  inputFileInsurance = React.createRef();

  onUploadButtonClick = (row) => {
    this.setState({ selectedRow: row });
    this.inputFileInsurance.current.click();
  };

  clearReceipt = async (row) => {
    let result = await RemoveReceipt(row.paymentAccount._id);
    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Recibo removido!'
      });
      this.updateDataTable();
    }
  }

  onChangeFile = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { selectedRow } = this.state;
    let file = e.target.files[0];

    if (!file) {
      Alerts.new({
        type: 'error',
        title: 'Erro',
        text: 'Nenhum ficheiro selecionado!'
      });
      return;
    }

    // Check if the file is a PDF
    if (file.type !== 'application/pdf') {
      Alerts.new({
        type: 'error',
        title: 'Erro',
        text: 'Por favor, selecione um ficheiro PDF!'
      });
      return;
    }

    const updateReceipt = async (id, receipt, filename) => {
      let result = await UpdateReceipt(id, { receipt, filename });
      if (result?.success) {
        Alerts.new({
          type: 'success',
          title: 'Sucesso',
          text: 'Recibo atualizado!'
        });
        this.updateDataTable();
      }
    }

    var reader = new FileReader();
    reader.fileName = file.name
    reader.readAsDataURL(file);
    reader.onload = function () {
      updateReceipt(selectedRow.paymentAccount._id, reader.result, reader.fileName);
    }

    e.target.value = '';
  }

  onPreviewPDF = async (row) => {
    this.setState({ loading: true });
    const resultPA = await GetPaymentAccount(row.paymentAccount._id);

    if (resultPA?.success && resultPA?.data) {
      row.paymentAccount.receipt = `${REACT_APP_IMAGES_URL}${resultPA?.data?.receipt}`;
      this.setState({ selectedRow: row }, () => {
        this.setState({
          showPreviewPDF: true,
          loading: false
        });
      });
    } else {
      this.setState({ loading: false });
    }

  }

  closeModal = () => {
    this.setState({ showPreviewPDF: false })
  }

  handleChangePage = currentPage => {
    this.setState({ currentPage }, () => this.updateDataTable());
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, () => this.updateDataTable());
  };

  render() {
    const { loading, currentPage, pageSize, selectAllRows, approving, exporting, printing } = this.state;
    const { showPreviewPDF, showDeclarationModal, declaration, modalValues } = this.state;
    const { columns, columnsEmployee, columnsCurrentEmployee, rows, total } = this.state;
    const { pdfDocumentModal, pdfDocument } = this.state;

    return (
      <React.Fragment>
        <div className='btn-payment-container'>
          {isCurrentUserAdmin() && this.props.type === 0 && <BaseButton
            type={'dafault'}
            icon={'file-excel'}
            text={'Exportar'}
            style={{ marginRight: 8 }}
            loading={exporting}
            onClick={() => this.exportPayments()}
          />}
          {isCurrentUserAdmin() && this.props.type === 0 && <BaseButton
            type={'default'}
            icon={'printer'}
            text={'Imprimir'}
            loading={printing}
            style={{ marginRight: 8 }}
            onClick={() => this.printPaymentAccount()}
          />}
          {isCurrentUserAdmin() && <BaseButton
            type={'primary'}
            icon={'check-circle'}
            text={'Aprovar Pagamentos'}
            loading={approving}
            onClick={() => this.approvePayments()}
          />}
        </div>
        <input type='file' id='fileInsurance' ref={this.inputFileInsurance} accept="application/pdf" style={{ display: 'none' }} onChange={e => this.onChangeFile(e)} />

        <TableFilterSection>
          <Row gutter={24}>
            {this.props.type === 0 ? <Col lg={6} md={6} sm={8} xs={24}>
              <TextInput
                type="text"
                label="Pesquisa"
                placeholder="Pesquisa pelo nome do funcionário"
                input={{
                  value: this.state.searchNomeColaborador,
                  onChange: (event) => { this.setState({ searchNomeColaborador: event.target.value }, () => this.updateDataTable()); }
                }}
                meta={{}}
              />
            </Col> : null}
            {/* <Col xs={12} md={4}>
              <DateInput
                label="Data Inicial"
                placeholder="Selecionar data"
                input={{
                  value: this.state.searchDataInicial,
                  onChange: (event) => { this.setState({ searchDataInicial: event }, () => this.updateDataTable()); }
                }}
                meta={{ valid: true }}
              />
            </Col>
            <Col xs={12} md={4}>
              <DateInput
                label="Data Final"
                placeholder="Selecionar data"
                input={{
                  value: this.state.searchDataFinal,
                  onChange: (event) => { this.setState({ searchDataFinal: event }, () => this.updateDataTable()); }
                }}
                meta={{ valid: true }}
              />
            </Col> */}
            <Col xs={24} sm={8} md={6} lg={4}>
              <SelectInput
                label="Entidade"
                allowClear
                placeholder="Escolher entidade"
                data={this.state.searchEntities}
                input={{
                  value: this.state.searchEntity,
                  onChange: (event) => { this.setState({ searchEntity: event == '' ? null : event }, () => this.updateDataTable()); }
                }}
                meta={{ valid: true }}
              />
            </Col>
            {isCurrentUserAdmin() ? <Col xs={24} sm={8} md={6} lg={4}>
              <SelectInput
                label="Nº de Conta"
                allowClear
                placeholder="Escolher conta"
                data={this.state.accountList}
                input={{
                  value: this.state.searchAccountNumber,
                  onChange: (event) => { this.setState({ searchAccountNumber: event !== '' ? event : null }, () => this.updateDataTable()) }
                }}
                meta={{ valid: true }}
              />
            </Col> : null}
          </Row>
        </TableFilterSection>

        <div className='payment-table-container'>
          {/* style={{ position: 'absolute', top: this.props.type === 0 ? '20px' : '10px', left: '10px', zIndex: 49 }} */}
          {isCurrentUserAdmin() ? <div className="sc-dxgOiQ fWHHkk checkbox-simple-payment">
            <Checkbox
              checked={selectAllRows}
              onChange={(e) => {
                rows.forEach(r => {
                  if (r.paymentAccount && !r.paymentAccount.approved) r.checked = e.target.checked;
                });
                this.setState({ selectAllRows: e.target.checked });
              }}>
            </Checkbox>
          </div> : null}

          <Table
            columns={this.props.type === 0 ? columns : (isCurrentUserChief() || isCurrentUserEmployee()) ? columnsCurrentEmployee : columnsEmployee}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="euro"
            emptyText={'Não existem pagamentos'}
            total={total}
            rowKey={'paymentAccount.id'}
            hasPagination={true}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>

        <PaymentTotals
          hasSelection={isCurrentUserAdmin() ? true : false}
          receipts={false}
          value={!isCurrentUserAdmin() ? rows.reduce((a, b) => a + ((b['paymentAccount'].value + b['paymentAccount'].bonus) * b['paymentAccount'].officialPercentage || 0), 0) : rows.filter(x => x.checked == true).reduce((a, b) => a + ((b['paymentAccount'].value + b['paymentAccount'].bonus) * b['paymentAccount'].officialPercentage || 0), 0)}
          bonus={!isCurrentUserAdmin() ? rows.reduce((a, b) => a + ((b['paymentAccount'].value + b['paymentAccount'].bonus) * (1 - b['paymentAccount'].officialPercentage) || 0), 0) : rows.filter(x => x.checked == true).reduce((a, b) => a + ((b['paymentAccount'].value + b['paymentAccount'].bonus) * (1 - b['paymentAccount'].officialPercentage) || 0), 0)}
          total={!isCurrentUserAdmin() ? rows.reduce((a, b) => a + (b['paymentAccount'].value + b['paymentAccount'].bonus || 0), 0) : rows.filter(x => x.checked == true).reduce((a, b) => a + (b['paymentAccount'].value + b['paymentAccount'].bonus || 0), 0)}
        />

        {showPreviewPDF && <ModalPDF
          openModal={showPreviewPDF}
          closeModal={this.closeModal}
          title='Recibo'
          file={this.state.selectedRow?.paymentAccount.receipt}
        />}

        {pdfDocumentModal && <Modal
          visible={pdfDocumentModal}
          title={'Envelopes'}
          width={800}
          maskClosable
          onCancel={this.closePdfDocumentModal}
          footer={[<Button key='cancel' type='primary' onClick={this.closePdfDocumentModal}>OK</Button>]}>
          <PDFViewer fileName='Envelopes' style={{ width: '100%', height: '390px', margin: '-24px 0' }}>
            {pdfDocument}
          </PDFViewer>
        </Modal>}

        <ModalPaymentDeclaration
          isOpen={showDeclarationModal}
          onClose={() => this.setState({ showDeclarationModal: false, modalValues: null })}
          onSubmit={async e => {
            const data = {
              id: e._id,
              declaration: e.declaration
            };
            const result = await UpdatePaymentAccountDeclaration(data);
            if (!result.success) console.error(result.message);
            this.setState({ showDeclarationModal: false, modalValues: null }, () => this.updateDataTable());
          }}
          initialValues={modalValues}
          blocked={modalValues ? modalValues.approved : false}
          declaration={setDeclaration(declaration, modalValues)}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(PendingPayments);
