import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Row, Col, Icon, Popconfirm} from 'antd';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  SpinLoading
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import {
  GetFlowers,
  DeleteFlower
} from '../../infra/requests/FlowersRequests';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import {ColourSection, ColourName, Options} from '../colours/ColoursStyles';
import { NumberFlowers } from './FlowersStyles';

class FlowersPage extends Component {
  state = {
    loading: false,
    colors: []
  };

  componentDidMount() {
    this.getInfo();
  }

  getInfo = async () => {
    this.setState({loading: true});
    try {
      const {data} = await GetFlowers();
      this.setState({
        colors: data?.items || [],
        loading: false
      });
    } catch (e) {
      this.setState({loading: false});
    }
  };

  deleteColor = async id => {
    await DeleteFlower(id);
    await this.getInfo();
  };

  render() {
    const {history} = this.props;
    const {colors, loading} = this.state;
    
    if(loading) return <SpinLoading />;
  
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Cores & Flores</PageTitle>
            Lista de Cores & Flores
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              block
              text={'Nova Cor'}
              onClick={() => history.push('/flowers/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={1}>
          <Row gutter={[32, 32]}>
            {
              colors?.length > 0 ?
              colors.map(color => (
                <Col xs={12} sm={8} md={4} key={color._id}>
                  <ColourSection colour={color.hex} />
                  <ColourName>{TranslateValue(color.name)}</ColourName>
                  <NumberFlowers><span>{color.flowers?.length || 0}</span> flores associadas</NumberFlowers>
                  <Options number={1} onClick={() => history.push(`/flowers/${color._id}`)}>
                    <Icon type="edit" />
                  </Options>
                  <Options number={0}>
                    <Popconfirm
                      placement="topRight"
                      title="De certeza que quer apagar esta Cor?"
                      onConfirm={() => this.deleteColor(color._id)}>
                      <Icon type="delete" />
                    </Popconfirm>
                  </Options>
                </Col>
              ))
              :
              <div>Não existem Cores inseridas!</div>
            }
          </Row>
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default withRouter(FlowersPage);