import React, { Component } from 'react';
import { Tabs, Row, Col, Breadcrumb, Radio, Icon, Modal } from 'antd';
import { withLocalize } from 'react-localize-redux';
import { DeleteRoomPlan, GetRoomPlanConfig, GetRoomPlanConfigByType, RestoreRoomPlan, UpdateRoomPlanConfig } from '../../../infra/requests/RoomPlanConfigRequests';
import { HeaderButtonsContainer, HeaderContainer, HeaderTitle, PageContainer, PageTitle, SpinLoading } from '../../../styles/BasicStyles';
import BaseButton from '../../../components/buttons/BaseButton';
import { RadioPlace } from '../../../components/YearView/CalendarStyles';
import { locals } from '../../reserves/ReserveAgenda';
import { GetConfigByPlace, UploadBackgroundRoomPlan } from '../../../infra/requests/ConfigsRequests';
import { ButtonBgUpdaload } from '../../../components/roomPlans/RoomPlanStyles';
import Dropzone from 'react-dropzone';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateBackgroundRoomPlan } from '../../../redux/Info/info.actions';
import Alerts from '../../../components/alert/Alert';
import RoomPlanEditor from '../../../process/guests/RoomPlanEditor';
import ConfigRoomPlanModal from './ConfigRoomPlanModal';
const { confirm } = Modal;

class ManageRoomPlan extends Component {
    state = {
        loading: false,
        roomPlanTemplate: null,
        weddingPlace: 1,

        roomPlanConfigId: null,
        roomPlanConfig: null,

        loadingBg: false,
        config: null,

        removing: false,
        openModal: false,
        saving: false,
    };

    componentDidMount = async () => {
        this.setState({
            loading: true,
            roomPlanConfigId: this.props.match.params.id,
        }, () => this.getInfo())

    };

    componentDidUpdate = (prevProps) => {
        const prevId = prevProps.match.params.id;
        const newId = this.props.match.params.id;

        if (prevId != newId) {
            this.setState({
                loading: true,
                roomPlanConfigId: newId
            }, () => this.getInfo())
        }
    }

    getInfo = async () => {
        const { roomPlanConfigId, weddingPlace } = this.state;

        const result = await GetRoomPlanConfig(roomPlanConfigId);
        const roomPlanConfig = result?.data || null;

        const { data } = await GetConfigByPlace(weddingPlace);

        if (result?.success) {
            this.setState({
                loading: false,
                roomPlanConfig,
                roomPlanTemplate: roomPlanConfig?._id,
                weddingPlace: roomPlanConfig?.weddingPlace || null,
                config: data || {}
            });
        } else this.props.history.goBack();
    }

    confirmRemoveRestoreRoomPlan = () => {
        const { roomPlanConfig } = this.state;

        const title = roomPlanConfig?.deleted
            ? `Têm a certeza que pretende recuperar o plano base ${roomPlanConfig?.name}?`
            : `Têm a certeza que pretende remover o plano base ${roomPlanConfig?.name}?`;

        const content = roomPlanConfig?.deleted
            ? `A partir deste momento poderá associar qualquer casamento a este plano base.`
            : `A partir deste momento, não poderá associar nenhum casamento a este plano base.`;
        confirm({
            title,
            content: (<div>
                <p>{content}</p>
            </div>
            ),
            okType: roomPlanConfig?.deleted ? 'primary' : 'danger',
            okText: roomPlanConfig?.deleted ? 'Recuperar' : 'Remover',
            cancelText: 'Cancelar',
            onOk: () => this.removeRoomPlan(),
            onCancel: () => null,
        });
    }

    removeRoomPlan = async () => {
        const { roomPlanConfig } = this.state;
        if (!roomPlanConfig?._id) return;
        this.setState({ removing: true });

        const result = roomPlanConfig?.deleted
            ? await RestoreRoomPlan(roomPlanConfig?._id)
            : await DeleteRoomPlan(roomPlanConfig?._id);

        if (result?.success) {
            Alerts.new({
                type: 'success',
                title: 'Successo',
                text: roomPlanConfig?.deleted ? 'Plano base recuperado!' : 'Plano base removido!'
            });
            this.setState({ removing: false }, () => {
                window.location.reload();
            });
        } else {
            this.setState({ removing: false });
        }
    }

    editRoomPlan = () => {
        this.setState({ openModal: true });
    }

    submitConfigRoomPlan = async (values) => {
        const { roomPlanConfig } = this.state;
        if (!roomPlanConfig?._id) return;

        this.setState({ saving: true });
        const { success, data } = await UpdateRoomPlanConfig(roomPlanConfig?._id, values);

        if (success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: 'Plano editado'
            });
            this.setState({
                openModal: false,
                saving: false
            }, () => this.getInfo());
        } else this.setState({ saving: false });
    }

    render() {
        const { loading, loadingBg } = this.state;
        const { roomPlanTemplate, weddingPlace, roomPlanConfig, roomPlanConfigId } = this.state;
        const { config } = this.state;
        const { openModal, saving } = this.state;
        const localName = weddingPlace == 2 ? 'Quinta Lago dos Cisnes' : 'Solar da Levada';

        return (
            <React.Fragment>
                <HeaderContainer>
                    <HeaderTitle buttons={1}>
                        <PageTitle>Plano Base {localName}</PageTitle>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <a href="/settings">Configurações</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Planos Base</Breadcrumb.Item>
                        </Breadcrumb>
                    </HeaderTitle>
                    <HeaderButtonsContainer buttons={1}>
                        {!roomPlanConfig?.default && <BaseButton
                            type={roomPlanConfig?.deleted ? 'primary' : 'danger'}
                            icon={roomPlanConfig?.deleted ? 'undo' : "delete"}
                            disabled={loading}
                            text={roomPlanConfig?.deleted ? 'Recuperar' : 'Remover'}
                            onClick={() => this.confirmRemoveRestoreRoomPlan()}
                        />}
                        {!roomPlanConfig?.deleted && <BaseButton
                            type="default"
                            icon="edit"
                            text="Editar"
                            disabled={loading}
                            onClick={() => this.editRoomPlan()}
                        />}
                        <BaseButton
                            type="default"
                            icon="close"
                            text="Voltar"
                            onClick={() => this.props.history.push('/settings/7')}
                        />
                    </HeaderButtonsContainer>
                </HeaderContainer>

                <PageContainer buttons={1}>
                    <RoomPlanEditor
                        ready={loading}
                        basePlan={true}
                        roomPlanConfigId={roomPlanConfigId}
                        history={this.props.history}
                    />
                </PageContainer>

                {openModal && <ConfigRoomPlanModal
                    isOpen={openModal}
                    loading={saving}
                    initialValues={roomPlanConfig}
                    onSubmit={this.submitConfigRoomPlan}
                    onClose={() => this.setState({ openModal: false })} />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    configs: state.info.configs
});

const mapActionToProps = dispatch =>
    bindActionCreators({ updateBackgroundRoomPlan }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageRoomPlan));