import styled from 'styled-components';

export const ColourSection = styled.div`
  display: inline-block;
  width: 100%;
  padding-bottom: 100%;
  background-image: ${({image}) => `url(${image})`};
  background-color: ${({colour}) => colour};
  border: 1px solid #ccc;
  background-origin: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const ColourName = styled.div`
  font-size: 18px;
  margin-top: 10px;
`;

export const Options = styled.div`
  cursor: pointer;
  position: absolute;
  background: white;
  padding: 5px;
  top: 6px;
  right: ${({number}) => 6 + number * 30}px;
  box-shadow: 1px 1px 3px #ccc;
`;

export const ChapterOrderName = styled.div`
  background-color: #faf9f9;
  padding: 5px 10px;
  margin-bottom: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
`;

export const OrderContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;