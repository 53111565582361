import React from 'react';
import { Element } from 'react-scroll';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import StyleImages from './StyleImages';
import {
  SectionContentStyle,
  SectionTitle,
  Counting
} from '../ChoicesPage/SectionStyles';

const StylesContent = ({
  handleAdd,
  handleDelete,
  style,
  section = { chapters: [] },
  printed,
  notesOpen
}) => {
  return (
    <SectionContentStyle id="section-container" name="section-container" printed={printed} notesOpen={notesOpen}>
      {section.chapters.map((chapter, cIndex) => (
        <Element key={`anchor-${cIndex}`} name={`anchor-${cIndex}`}>
          <SectionTitle>
            {TranslateValue(chapter.name)}
            <Counting>
              {cIndex + 1} de {section.chapters.length}
            </Counting>
          </SectionTitle>
          <StyleImages
            type={cIndex === 0 ? 'inspirations' : 'images'}
            style={style}
            handleAdd={handleAdd}
            handleDelete={handleDelete}
            printed={printed}
          />
        </Element>
      ))}
    </SectionContentStyle>
  );
};

export default StylesContent;
