import React from 'react';
import {scroller} from 'react-scroll';
import {Tree, Icon} from 'antd';
import {SectionMenuStyle} from '../ChoicesPage/SectionStyles';
import TranslateValue from '../../infra/services/translations/TranslateValue';

const {TreeNode} = Tree;

const defineIcon = question => {
  if (question.type === 9) {
    return <Icon style={{color: 'green'}} type="info-circle" />;
  }
  if (question?.answer?.response) {
    return <Icon style={{color: 'green'}} type="check-circle" />;
  }
  return <Icon style={{color: '#decbb3'}} type="close-circle" />;
};

const StaffMenu = ({printed, section = {chapters: []}}) => {
  const scrollTo = selected => {
    if (selected && selected[0]) {
      return scroller.scrollTo(selected[0], {
        duration: 500,
        smooth: true,
        containerId: 'section-container'
      });
    }
  };

  return (
    <SectionMenuStyle printed={printed}>
      <Tree
        showLine={false}
        defaultExpandAll
        blockNode
        showIcon
        autoExpandParent
        onSelect={scrollTo}>
        {section.chapters.map((chapter, cIndex) => (
          <TreeNode
            title={TranslateValue(chapter.name)}
            key={`anchor-${cIndex}`}
            icon={item => (
              <Icon type={item.expanded ? 'folder-open' : 'folder'} />
            )}>
            {chapter.questions.map((question, qIndex) =>
              qIndex > 1 ? (
                <TreeNode
                  icon={defineIcon(question)}
                  title={TranslateValue(question.title)}
                  key={`anchor-${cIndex}-${qIndex}`}
                />
              ) : null
            )}
          </TreeNode>
        ))}
      </Tree>
    </SectionMenuStyle>
  );
};

export default StaffMenu;
