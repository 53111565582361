import React from 'react';
import {Row, Col} from 'antd';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  DefaultSectionOption
} from '../../styles/BasicStyles';
import EntreesIcon from '../../assets/icons/ic_entrees.svg';
import MeatIcon from '../../assets/icons/ic_meat.svg';
import FishIcon from '../../assets/icons/ic_fish.svg';
import DessertIcon from '../../assets/icons/ic_dessert.svg';
import CakeIcon from '../../assets/icons/ic_cake.svg';
import SupperIcon from '../../assets/icons/ic_supper.svg';
import WaiterIcon from '../../assets/icons/waiter.svg';
import CateringIcon from '../../assets/icons/catering.svg';

const FoodCategories = ({history}) => {
  return (
    <React.Fragment>
      <HeaderContainer>
        <HeaderTitle buttons={0}>
          <PageTitle>Catering</PageTitle>
        </HeaderTitle>
      </HeaderContainer>
      <PageContainer buttons={0}>
        <Row gutter={[24, 24]}>
          <Col xs={6}>
            <DefaultSectionOption onClick={() => history.push('/food/entrees')}>
              <img src={EntreesIcon} alt="icon" />
              <span>Entradas</span>
            </DefaultSectionOption>
          </Col>
          <Col xs={6}>
            <DefaultSectionOption onClick={() => history.push('/food/meat')}>
              <img src={MeatIcon} alt="icon" />
              <span>Pratos de Carne</span>
            </DefaultSectionOption>
          </Col>
          <Col xs={6}>
            <DefaultSectionOption onClick={() => history.push('/food/fish')}>
              <img src={FishIcon} alt="icon" />
              <span>Pratos de Peixe</span>
            </DefaultSectionOption>
          </Col>
          <Col xs={6}>
            <DefaultSectionOption onClick={() => history.push('/food/dessert')}>
              <img src={DessertIcon} alt="icon" />
              <span>Sobremesa</span>
            </DefaultSectionOption>
          </Col>
          <Col xs={6}>
            <DefaultSectionOption onClick={() => history.push('/food/cake')}>
              <img src={CakeIcon} alt="icon" />
              <span>Bolos</span>
            </DefaultSectionOption>
          </Col>
          <Col xs={6}>
            <DefaultSectionOption onClick={() => history.push('/food/supper')}>
              <img src={SupperIcon} alt="icon" />
              <span>Ceia</span>
            </DefaultSectionOption>
          </Col>
          <Col xs={6}>
            <DefaultSectionOption onClick={() => history.push('/food/internal')}>
              <img src={WaiterIcon} alt="icon" />
              <span>Ementas Internas</span>
            </DefaultSectionOption>
          </Col>
          <Col xs={6}>
            <DefaultSectionOption onClick={() => history.push('/food/sidedishes')}>
              <img src={CateringIcon} alt="icon" />
              <span>Guarnição</span>
            </DefaultSectionOption>
          </Col>
        </Row>
      </PageContainer>
    </React.Fragment>
  );
};

export default FoodCategories;
