import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import SelectInput from '../../components/inputs/SelectInput';
import DateInput from '../../components/inputs/DateInput';
import { getOrganizers } from '../../infra/requests/AdminsRequests';
import moment from 'moment';
import MonthInput from '../../components/inputs/MonthInput';
import XpertGoRangePickerInput from '../../components/inputs/XpertGoRangePickerInput';
import XpertGoDateInput from '../../components/inputs/XpertGoDateInput';

class EmployeeWeddingsFilters extends Component {
  state = {
    loading: false,
    search: '',
    wedding_place: '',
    month: moment.utc().startOf('month').format('MM/YYYY'),
    date: null,
    dates: [],
  };

  componentDidMount = async () => {
    this.setState({
      loading: this.props.loading,
      month: this.props.month,
      date: this.props.date || null
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }

    if (prevProps.month !== this.props.month) {
      this.setState({ month: this.props.month });
    }

    if (prevProps.date !== this.props.date) {
      this.setState({ date: this.props.date });
    }
  }

  onInputChange = (field, value, timeout = 0) => {
    if (field === 'month') this.setState({ wedding_place: '' });
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map(key => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(query);
  };

  render() {
    const { locals } = this.props;
    const { loading } = this.state;
    const { wedding_place, month, date, dates } = this.state;

    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={5} lg={4} xl={3}>
            <MonthInput
              label="Mês"
              placeholder="Escolher mês"
              input={{
                value: month,
                onChange: value => {
                  this.onInputChange('month', value);
                  this.onInputChange('wedding_place', null);
                }
              }}
              allowClear={false}
              meta={{ valid: true }}
              disabled={loading}
            />
          </Col>
          <Col xs={24} sm={12} md={5} lg={4} xl={3}>
            <XpertGoDateInput
              label="Date"
              placeholder="Escolher uma data"
              input={{
                value: date,
                onChange: value => {
                  if (!value || value == '') {
                    this.onInputChange('date', value);
                    this.onInputChange('month', moment.utc().startOf('month').format('MM/YYYY'));
                    this.onInputChange('dates', []);
                    this.onInputChange('wedding_place', null);
                  } else {
                    this.onInputChange('date', value);
                    this.onInputChange('month', null);
                    this.onInputChange('dates', []);
                    this.onInputChange('wedding_place', null);
                  }
                }
              }}
              allowClear={true}
              meta={{ valid: true }}
              disabled={loading}
            />
          </Col>
          {/* <Col xs={24} md={6}>
            <XpertGoRangePickerInput
              label="Intervalo de datas"
              allowClear={true}
              placeholder="Escolher datas"
              input={{
                value: dates,
                onChange: value => {
                  if (!value || value == '') {
                    this.onInputChange('date', value);
                    this.onInputChange('month', moment.utc().startOf('month').format('MM/YYYY'));
                    this.onInputChange('dates', []);
                  } else {
                    this.onInputChange('dates', value);
                    this.onInputChange('date', null);
                    this.onInputChange('month', null);
                  }
                }
              }}
              meta={{ valid: true }}
              disabled={loading}
            />
          </Col> */}
          <Col xs={24} sm={12} md={6} lg={4}>
            <SelectInput
              label="Local"
              allowClear
              placeholder="Escolher local do casamento"
              data={locals}
              input={{
                value: wedding_place,
                onChange: value => this.onInputChange('wedding_place', value)
              }}
              meta={{ valid: true }}
              disabled={loading}
            />
          </Col>
          {/* <Col xs={24} md={4}>
            <SelectInput
              label="Subcategoria"
              allowClear
              placeholder="Escolher subcategoria"
              data={subcategories}
              input={{
                value: organizer,
                onChange: value => this.onInputChange('subcategory', value)
              }}
              meta={{valid: true}}
            />
          </Col> */}
        </Row>
      </TableFilterSection>
    );
  }
}

const mapStateToProps = state => ({
  locals: state.locals
});

export default connect(mapStateToProps)(EmployeeWeddingsFilters);
