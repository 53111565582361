import React, { Component, Fragment } from 'react';
import Table from '../../components/table/Table';
import {
    Row,
    Col,
    Popconfirm,
    Icon,
} from 'antd';
import {
    HeaderButtonsContainer,
    TableButton,
    SectionTitle,
    TableFilterSection
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import SelectInput from '../../components/inputs/SelectInput';
import { withLocalize } from 'react-localize-redux';
import Alert from "../../components/alert/Alert";
import { CreateCalendarSetting, DeleteCalendarSetting, GetCalendarSettings, UpdateCalendarSetting } from '../../infra/requests/CalendarSettingRequests';

const RemoveMessage = (
    <div>
        <div>Tem a certeza que quer remover este intervalo? </div>
        <div>
            Toda a informação da configuração do calendário será removido da plataforma e não
            conseguirá ser recuperado.
        </div>
    </div>
);


class CalendarTable extends Component {
    state = {
        pageSize: 15,
        currentPage: 1,
        loading: false,
        search: '',
        editCalendarSetting: null,
        months: [
            { _id: 1, name: 'Janeiro' },
            { _id: 2, name: 'Fevereiro' },
            { _id: 3, name: 'Março' },
            { _id: 4, name: 'Abril' },
            { _id: 5, name: 'Maio' },
            { _id: 6, name: 'Junho' },
            { _id: 7, name: 'Julho' },
            { _id: 8, name: 'Agosto' },
            { _id: 9, name: 'Setembro' },
            { _id: 10, name: 'Outubro' },
            { _id: 11, name: 'Novembro' },
            { _id: 12, name: 'Dezembro' },
        ],
        monthsSelect: [],
        columns: [
            {
                title: 'Mês Inicial',
                key: 'startMonth',
                width: '150px',
                render: data => (
                    <Fragment>
                        {!this.isEditing(data) ? (<div>{data.startMonthDesc}</div>) :
                            (
                                <div style={{ marginTop: '-10px' }}>
                                    <SelectInput
                                        allowClear
                                        placeholder="Escolher o mês"
                                        data={this.state.monthsSelect}
                                        input={{
                                            value: data.startMonthDesc, onChange: event => this.setMonth(true, event, data)
                                        }}
                                        meta={{ valid: true }}
                                    />


                                </div>)
                        }
                    </Fragment>
                )
            },
            {
                title: 'Mês Final',
                key: 'endMonth',
                width: '150px',
                render: data => (
                    <Fragment>
                        {!this.isEditing(data) ? (<div>{data.endMonthDesc}</div>) :
                            (<div style={{ marginTop: '-10px' }}>
                                <SelectInput
                                    allowClear
                                    placeholder="Escolher o mês"
                                    data={this.state.monthsSelect}
                                    input={{
                                        value: data.endMonthDesc, onChange: event => this.setMonth(false, event, data)
                                    }}
                                    meta={{ valid: true }}
                                />

                            </div>)
                        }
                    </Fragment>
                )
            },
            {
                title: 'Acções',
                width: '120px',
                render: data => (
                    <Fragment>
                        {!this.isEditing(data) ? (<TableButton onClick={e => this.setEditForm(data)}>
                            <Icon type="edit" />
                            {' Editar'}
                        </TableButton>) : (<TableButton onClick={e => this.addEditCalendarSetting()}>
                            <Icon type="save" />
                            {' Guardar'}
                        </TableButton>)
                        }

                        {!this.isEditing(data) ? (<TableButton onClick={e => e.stopPropagation()}>
                            <Popconfirm
                                placement="topRight"
                                title={RemoveMessage}
                                okText="Remover"
                                onConfirm={async () => { await this.deleteCalendarSetting(data.id) }}>
                                <Icon type="delete" />
                                {' Remover'}
                            </Popconfirm>
                        </TableButton>) : (<TableButton onClick={e => {
                            const { editCalendarSetting, rows } = this.state;
                            if (editCalendarSetting.id === null) {
                                let rowsFilter = rows.filter(f => f.id !== null);
                                this.setState({ editCalendarSetting: null, rows: rowsFilter });
                            } else this.setState({ editCalendarSetting: null });

                        }}>
                            <Icon type="close" />
                            {' Cancelar'}
                        </TableButton>)
                        }
                    </Fragment>
                )
            }
        ],
        rows: [],
        total: 0
    };

    componentDidMount = async () => {
        await this.updateDataTable();
    };

    isEditing = (calendarSetting) => {
        const { editCalendarSetting } = this.state;
        return editCalendarSetting ? editCalendarSetting.id === calendarSetting.id : false;
    }

    setMonth = (isStart, event, data) => {
        const { rows, months } = this.state;
        const selectMonth = parseInt(event);

        if (event !== '') {
            const month = months.filter(f => f._id === selectMonth)[0];

            if (isStart) {
                data.startMonth = month._id;
                data.startMonthDesc = month.name;
            } else {
                data.endMonth = month._id;
                data.endMonthDesc = month.name;
            }

            this.setState({ editCalendarSetting: data });

            const index = rows.findIndex(f => f.id === data.id);
            rows[index] = data;
            this.setState({ rows: rows })
        }
    }

    /**
     * @description Update data on datatable
     */
    updateDataTable = async () => {
        this.setState({ loading: true });
        try {
            const result = await GetCalendarSettings();

            this.setState({
                rows: result.data ? result.data : [],
                total: result.data ? result.data.length : 0,
                loading: false
            });
        } catch (e) {
            this.setState({ loading: false });
            console.error(e);
        }
    }

    /**
     * @description Show editing row with calendar setting values
     * @param {*} calendarSetting 
     */
    setEditForm = (calendarSetting) => {
        const { months, rows } = this.state;
        let monthsFiltered = months;

        for (let index = 0; index < rows.length; index++) {
            const element = rows[index];

            if (element.id !== calendarSetting.id) {
                for (let j = element.startMonth; j <= element.endMonth; j++) {
                    monthsFiltered = monthsFiltered.filter(f => f._id !== j);
                }
            }
        }

        let x = calendarSetting ? JSON.parse(JSON.stringify(calendarSetting)) : {
            startMonth: null,
            startMonthDesc: '',
            endMonth: null,
            endMonthDesc: ''
        };
        this.setState({ monthsSelect: monthsFiltered, editCalendarSetting: x });
    }

    /**
    * @description Show add row
    */
    setAddForm = () => {
        const { editCalendarSetting, rows, months } = this.state;
        let monthsFiltered = months;

        for (let index = 0; index < rows.length; index++) {
            const element = rows[index];

            for (let j = element.startMonth; j <= element.endMonth; j++) {
                monthsFiltered = monthsFiltered.filter(f => f._id !== j);
            }
        }

        const calendarSetting = {
            id: null,
            startMonth: null,
            startMonthDesc: '',
            endMonth: null,
            endMonthDesc: '',
        };
        if (!editCalendarSetting) {
            rows.push(calendarSetting);
            this.setState({ rows: rows, monthsSelect: monthsFiltered, editCalendarSetting: calendarSetting });
        }
    }

    /**
     * @description Create/Update payment method
     * @returns datatable payment updated
     */
    addEditCalendarSetting = async () => {
        const { editCalendarSetting, months } = this.state;

        if (editCalendarSetting.startMonthDesc === '' || editCalendarSetting.endMonthDesc === '') {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Deve selecionar um mês'
            });
        } else if (editCalendarSetting.endMonth < editCalendarSetting.startMonth) {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Deve selecionar um mês final maior ou igual ao mês inicial'
            });
        } else if (editCalendarSetting.id === null) {
            await CreateCalendarSetting(editCalendarSetting);

        } else {
            await UpdateCalendarSetting(editCalendarSetting);
        }

        const monthsRange = months;
        for (let index = editCalendarSetting.startMonth; index < editCalendarSetting.endMonth; index++) {
            monthsRange.filter(f => f._id !== index);
        }

        this.setState({ editCalendarSetting: null });
        return this.updateDataTable();
    }

    /**
     * @description Delete calendar setting
     * @param {*} id - calendar setting id
     * @returns datatable calendar setting updated
     */
    deleteCalendarSetting = async id => {
        await DeleteCalendarSetting(id);
        return this.updateDataTable();
    }

    handleChangePage = currentPage => {
        this.setState({ currentPage }, this.updateDataTable);
    };

    handleChangeRowsPerPage = (currentSize, pageSize) => {
        this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
    };

    render() {
        const { loading, currentPage, pageSize } = this.state;
        const { columns, rows, total } = this.state;

        return (
            <React.Fragment>
                <TableFilterSection>
                    <Row gutter={24} style={{ marginTop: '2rem' }}>
                        <Col lg={8} md={8} sm={12} xs={24}>
                            <SectionTitle>Gestão do Calendário</SectionTitle>
                        </Col>

                        <Col lg={16} md={16} sm={12} xs={24} className='subcategory-col-btn'>
                            <BaseButton
                                type={'primary'}
                                icon={'plus'}
                                text={'Adicionar Configuração'}
                                onClick={() => this.setAddForm()}
                            />
                        </Col>
                    </Row>
                </TableFilterSection>

                <div className='table-responsive'>
                    <Table
                        columns={columns}
                        // currentPage={currentPage}
                        // pageSize={pageSize}
                        // defaultPageSize={pageSize}
                        loading={loading}
                        rows={rows}
                        showHeader={true}
                        emptyIcon="setting"
                        emptyText={'Não existem configurações do calendário'}
                        total={total}
                        rowKey={'_id'}
                        hasPagination={false}
                    // handleChangePage={this.handleChangePage}
                    // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default withLocalize(CalendarTable);