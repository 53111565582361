import React, { Component, Fragment } from 'react';
import { Icon, Checkbox, Col, Row } from 'antd';
import Table from '../../components/table/Table';
import {
  HeaderButtonsContainer,
  TableButton,
  TableFilterSection
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import moment from 'moment';
import { GetColorSystemByType } from '../../infra/requests/ColorSystemRequests';
import { GetPaymentsByEmployeePaginate, GetPaymentsGrouped, GetPaymentsPaginate } from '../../infra/requests/PaymentRequests';
import Alert from "../../components/alert/Alert";
import { CreateManyPaymentAccount, CreatePaymentAccount, CreatePaymentAccountByEmployee } from '../../infra/requests/PaymentAccountRequests';
import { UpdateStatusAndPaymentAccount } from '../../infra/requests/PaymentRequests';
import TextInput from '../../components/inputs/TextInput';
import DateInput from '../../components/inputs/DateInput';
import SelectInput from '../../components/inputs/SelectInput';
import { withRouter } from 'react-router-dom';
import { getCurrencyNumber, getLocalColor } from '../../infra/services/wedding/weddingUtils';
import { LocalLabel } from '../weddings/Styles';
import PaymentTotals from './PaymentTotals';
import { isCurrentUserAdmin, isCurrentUserChief, isCurrentUserEmployee, isCurrentUserHumanResources, isEmployeeOrChief } from '../../infra/helpers/Helpers';
import { GetSettingsByType } from '../../infra/requests/SettingRequests';
import NumberInput from '../../components/inputs/NumberInput';
import ModalPaymentAccount from './ModalPaymentAccount';
import { GetTextColor } from '../../infra/services/text/textUtils';
import Alerts from '../../components/alert/Alert';

class ToBePayed extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    openModal: false,
    modalValues: null,
    status: 1,
    selectAllRows: false,

    columns: [
      {
        title: ' ',
        key: 'select',
        width: '30px',
        render: data => (
          <div className="sc-dxgOiQ fWHHkk" style={{ textAlign: 'center' }}>
            <Checkbox
              checked={data.checked}
              onChange={(e) => {
                let element = data;
                element.checked = !element.checked;
                let elements = this.state.rows;
                let index = elements.findIndex(x => x._id === element._id);
                if (index > -1) {
                  elements[index] = element;
                  this.setState({ rows: elements })
                }
              }}>
            </Checkbox>
          </div>
        )
      },
      {
        title: 'Funcionário',
        key: 'employee',
        render: data => data?.employee.name
      },
      {
        title: 'Entidade',
        key: 'entity',
        render: value => {
          const local = getLocalColor(null, this.state.colors, value?._id.entity);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
        }
      },
      {
        title: 'Nº Serviços',
        key: 'countPayments',
        width: '85px',
        render: data => (data?.payments?.length || 0)
      },
      {
        title: 'Nº Casamentos',
        key: 'countWeddings',
        width: '85px',
        render: data => (data?.weddings.length || 0)
      },
      {
        title: 'Data do 1º Casamento',
        key: 'date1Wedding',
        width: '90px',
        render: data => moment.utc(data?.dateFirstWedding).format('DD-MM-YYYY')
      },
      {
        title: 'Oficial',
        render: data => {
          const officialPer = this.state.updatePercentage !== '' && this.state.updatePercentage !== null
            ? (this.state.updatePercentage / 100)
            : data.officialPercentage;
          const value = Math.round((data.totalValue + data.totalBonus) * officialPer);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (officialPer * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Bónus',
        render: data => {
          const notOfficialPer = this.state.updatePercentage !== '' && this.state.updatePercentage !== null
            ? 1 - (this.state.updatePercentage / 100)
            : 1 - data.officialPercentage;
          const value = Math.round((data.totalValue + data.totalBonus) * notOfficialPer);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (notOfficialPer * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Total',
        render: data => {
          const value = Math.round(data.totalValue + data.totalBonus);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Acções',
        width: '120px',
        render: data => (
          <Fragment>
            <TableButton onClick={e => {
              //this.setState({ openModal: true, modalValues: data })
              const { history } = this.props;
              const { status, searchDataInicial, searchDataFinal } = this.state;
              const startDate = searchDataInicial ? moment.utc(searchDataInicial).valueOf() : null;
              const endDate = searchDataFinal ? moment.utc(searchDataFinal).valueOf() : null;
              history.push(`/payment/${data.employee._id}/${status}/${data?._id.entity}/${startDate}/${endDate}`);
            }}>
              <Icon type="info" />
              {' Detalhe'}
            </TableButton>
          </Fragment>
        )
      }
    ],
    columnsEmployee: [
      {
        title: ' ',
        key: 'select',
        width: '30px',
        render: data => (
          <div className="sc-dxgOiQ fWHHkk" style={{ textAlign: 'center' }}>
            <Checkbox
              checked={data.checked}
              onChange={(e) => {
                let element = data;
                element.checked = !element.checked;
                let elements = this.state.rows;
                let index = elements.findIndex(x => x._id === element._id);
                if (index > -1) {
                  elements[index] = element;
                  this.setState({ rows: elements })
                }
              }}>
            </Checkbox>
          </div>
        )
      },
      {
        title: 'Entidade',
        key: 'entity',
        render: data => {
          const local = getLocalColor(null, this.state.colors, data?.entity);
          // const local = getLocalColor(data?.wedding, this.state.colors);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
        }
      },
      {
        title: 'Data',
        key: 'weddingDate',
        width: '90px',
        render: data => moment.utc(data?.wedding.date).format('DD-MM-YYYY')
      },
      {
        title: 'Oficial',
        render: data => {
          const officialPer = this.state.updatePercentage !== '' && this.state.updatePercentage !== null
            ? (this.state.updatePercentage / 100)
            : data.officialPercentage;
          const value = Math.round((data.totalValue + data.totalBonus) * officialPer);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (officialPer * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Bónus',
        render: data => {
          const notOfficialPer = this.state.updatePercentage !== '' && this.state.updatePercentage !== null
            ? 1 - (this.state.updatePercentage / 100)
            : 1 - data.officialPercentage;
          const value = Math.round((data.totalValue + data.totalBonus) * notOfficialPer);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (notOfficialPer * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Total',
        render: data => {
          const value = Math.round(data.totalValue + data.totalBonus);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {getCurrencyNumber(value)}
          </div>
        },
      },
    ],
    columnsCurrentEmployee: [
      {
        title: 'Entidade',
        key: 'entity',
        render: data => {
          const local = getLocalColor(null, this.state.colors, data?.entity);
          // const local = getLocalColor(data?.wedding, this.state.colors);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
        }
      },
      {
        title: 'Data',
        key: 'weddingDate',
        width: '90px',
        render: data => moment.utc(data?.wedding.date).format('DD-MM-YYYY')
      },
      {
        title: 'Oficial',
        render: data => {
          const officialPer = this.state.updatePercentage !== '' && this.state.updatePercentage !== null
            ? (this.state.updatePercentage / 100)
            : data.officialPercentage;
          const value = Math.round((data.totalValue + data.totalBonus) * officialPer);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (officialPer * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Bónus',
        render: data => {
          const notOfficialPer = this.state.updatePercentage !== '' && this.state.updatePercentage !== null
            ? 1 - (this.state.updatePercentage / 100)
            : 1 - data.officialPercentage;
          const value = Math.round((data.totalValue + data.totalBonus) * notOfficialPer);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (notOfficialPer * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Total',
        render: data => {
          const value = Math.round(data.totalValue + data.totalBonus);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {getCurrencyNumber(value)}
          </div>
        },
      },
    ],
    rows: [],
    total: 0,
    totalValue: 0,
    totalBonus: 0,
    colors: {
      colorSys1: null,
      colorSys2: null
    },
    searchNomeColaborador: '',
    searchDataInicial: moment.utc().subtract(1, 'week').startOf('week').add(1, 'day'),
    searchDataFinal: moment.utc().subtract(1, 'week').endOf('week'),
    searchEntities: [],
    searchEntity: null,
    searchLocal: null,
    updatePercentage: null,
    updateChild: null,

    openPaymentAccountModal: false,
  };

  componentDidMount = async () => {
    this.props.type === 0 ? await this.updateDataTable() : await this.updateDataTableEmployee();
    this.getLocalColors();
    this.getEntities();
    this.setState({ updateChild: this.props.updateChild });
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.updateChild !== this.props.updateChild) {
      this.setState({ updateChild: this.props.updateChild });
      this.props.type === 0 ? await this.updateDataTable() : await this.updateDataTableEmployee();
    }

    if (prevProps.activeTab !== this.props.activeTab) {
      this.setState({
        selectAllRows: false,
        searchNomeColaborador: '',
        searchDataInicial: moment.utc().subtract(1, 'week').startOf('week').add(1, 'day'),
        searchDataFinal: moment.utc().subtract(1, 'week').endOf('week'),
        searchEntity: null,
        searchLocal: null,
        updatePercentage: null,
      }, async () => {
        this.props.type === 0 ? await this.updateDataTable() : await this.updateDataTableEmployee();
      });
    }
  }

  getEntities = async () => {
    const result = await GetSettingsByType(1);

    if (result.success) {
      let list = [];
      result.data.forEach(element => {
        if (element.entityValue !== 3) list.push({ _id: element.entityValue, name: element.name });
      });
      this.setState({ searchEntities: list });
    } else console.error(result.message);
  }

  getLocalColors = async () => {
    const resColorSys1 = await GetColorSystemByType(4);
    const colorSys1 = resColorSys1.success && resColorSys1.data ? resColorSys1.data.color : '#f7b5d6';
    const resColorSys2 = await GetColorSystemByType(5);
    const colorSys2 = resColorSys2.success && resColorSys2.data ? resColorSys2.data.color : '#b6d7a9';
    this.setState({ colors: { colorSys1, colorSys2 } });
  }

  updateDataTable = async () => {
    const { status, currentPage, pageSize } = this.state;
    const { searchNomeColaborador, searchLocal, searchEntity, searchDataInicial, searchDataFinal } = this.state;
    const { selectAllRows } = this.state;
    this.setState({ loading: true });
    try {
      let filter = { status: status, toBePayed: true };
      if (searchNomeColaborador !== '') filter['name'] = searchNomeColaborador;
      if (searchEntity !== null) filter['entity'] = searchEntity;
      if (searchDataInicial !== null && searchDataInicial !== '' && searchDataInicial !== 'undefined') filter['startDate'] = moment.utc(searchDataInicial).startOf('day');
      if (searchDataFinal !== null && searchDataFinal !== '' && searchDataFinal !== undefined) filter['endDate'] = moment.utc(searchDataFinal).endOf('day');

      let result = await GetPaymentsPaginate(currentPage, pageSize, JSON.stringify(filter));
      // console.warn('Result', result);

      if (result.success && result.data.items.length > 0) {
        result.data.items = result.data.items.map((element) => {
          return {
            ...element,
            checked: selectAllRows ? true : false
          }
        });
      }

      this.setState({
        rows: result.data.items,
        total: result.data.total,
        pageSize: result.data.limit,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  updateDataTableEmployee = async () => {
    let { status, totalValue, totalBonus, currentPage, pageSize } = this.state;
    const { searchLocal, searchEntity, searchDataInicial, searchDataFinal } = this.state;
    const { selectAllRows } = this.state;
    this.setState({ loading: true });
    try {
      let filter = { employee: this.props.employeeId, status: status }
      if (searchEntity !== null) filter['entity'] = searchEntity;
      if (searchDataInicial !== null && searchDataInicial !== '' && searchDataInicial !== 'undefined') filter['startDate'] = moment.utc(searchDataInicial).startOf('day');
      if (searchDataFinal !== null && searchDataFinal !== '' && searchDataFinal !== undefined) filter['endDate'] = moment.utc(searchDataFinal).endOf('day');

      let result = await GetPaymentsByEmployeePaginate(currentPage, pageSize, JSON.stringify(filter));
      //console.warn('Result', result);

      if (result.success && result.data.items) {
        result.data.items = result.data.items.map((element) => {
          if (!isCurrentUserAdmin()) {
            totalValue = totalValue + element.value;
            totalBonus = totalBonus + element.bonus;
          }

          return {
            ...element,
            checked: selectAllRows ? true : false
          }
        });
      }

      this.setState({
        rows: result.data.items,
        total: result.data.total,
        pageSize: result.data.limit,
        totalValue,
        totalBonus,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  newPaymentAccount = async () => {
    const { rows } = this.state;
    const selectedPayments = rows.filter(x => x.checked === true);

    if (selectedPayments.length == 0) {
      return Alert.new({
        type: "error",
        title: "Atenção",
        text: "É necessário selecionar pelo menos um pagamento."
      });
    }

    this.setState({ loading: true });
    const data = {
      selectedPayments,
      officialPercentage: this.state.updatePercentage
    };
    const result = await CreateManyPaymentAccount(data);
    if (result?.success) {
      if (result?.data?.missingPASettings) {
        Alerts.new({
          type: 'warning',
          title: 'Atenção',
          text: 'Contas de pagamento por criar devido à falta de configurações de conta!'
        });
      } else {
        Alerts.new({
          type: 'success',
          title: 'Sucesso',
          text: 'Contas de pagamentos criadas!'
        });
      }
      this.setState({
        loading: false,
        selectAllRows: false
      }, () => this.updateDataTable());
    } else {
      this.setState({ loading: false });
    }
  }

  // showModalPaymentAccount = () => {
  //   const { rows } = this.state;
  //   let selectedPayments = rows.filter(x => x.checked === true);
  //   // console.warn('selectedPayments', selectedPayments);
  //   if (selectedPayments.length === 0) {
  //     Alert.new({
  //       type: "error",
  //       title: "Atenção",
  //       text: "É necessário selecionar pelo menos um pagamento."
  //     });
  //     return;
  //   }

  //   this.setState({ openPaymentAccountModal: true });
  // }

  newPaymentAccountEmployee = async (accountNumber) => {
    const { rows } = this.state;
    const selectedPayments = rows.filter(x => x.checked === true);

    if (selectedPayments.length == 0) {
      return Alert.new({
        type: "error",
        title: "Atenção",
        text: "É necessário selecionar pelo menos um pagamento."
      });
    }

    this.setState({ loading: true });
    const data = {
      accountNumber,
      selectedPayments,
      officialPercentage: this.state.updatePercentage
    };
    const result = await CreatePaymentAccountByEmployee(data);
    if (result?.success) {
      if (result?.data?.missingPASettings) {
        Alerts.new({
          type: 'warning',
          title: 'Atenção',
          text: 'Contas de pagamento por criar devido à falta de configurações de conta!'
        });
      } else {
        Alerts.new({
          type: 'success',
          title: 'Sucesso',
          text: 'Contas de pagamentos criadas!'
        });
      }
      this.setState({
        loading: false,
        selectAllRows: false
      }, () => {
        this.props.setUpdateChild();
        this.updateDataTableEmployee();
      });
    } else {
      this.setState({ loading: false });
    }
  }

  handleChangePage = currentPage => {
    this.setState({ currentPage }, () => {
      if (this.props.type === 0) {
        this.updateDataTable();
      } else {
        this.updateDataTableEmployee();
      }
    });
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, () => {
      if (this.props.type === 0) {
        this.updateDataTable();
      } else {
        this.updateDataTableEmployee();
      }
    });
  };

  render() {
    const { history } = this.props;
    const { loading, currentPage, pageSize, selectAllRows, updatePercentage, openPaymentAccountModal } = this.state;
    const { columns, columnsEmployee, columnsCurrentEmployee, rows, total } = this.state;
    const checkboxStyle = this.props.type === 0
      ? 'sc-dxgOiQ fWHHkk checkbox-payment'
      : 'sc-dxgOiQ fWHHkk checkbox-simple-payment';

    return (
      <React.Fragment>
        <div className='btn-payment-container'>
          {isCurrentUserAdmin() ? <BaseButton
            type={'primary'}
            icon={'plus'}
            text={'Novo Pagamento'}
            onClick={() => { this.props.type === 0 ? this.newPaymentAccount() : this.newPaymentAccountEmployee() }}
          /> : null}
        </div>

        <TableFilterSection>
          <Row gutter={20}>
            {this.props.type === 0 ? <Col lg={6} md={6} sm={8} xs={24}>
              <TextInput
                type="text"
                label="Pesquisa"
                placeholder="Pesquisa pelo nome do funcionário"
                input={{
                  value: this.state.searchNomeColaborador,
                  onChange: (event) => {
                    this.setState({ searchNomeColaborador: event.target.value }, () => {
                      this.props.type === 0 ? this.updateDataTable() : this.updateDataTableEmployee();
                    });
                  }
                }}
                meta={{}}
              />
            </Col> : null}
            <Col lg={4} md={6} sm={8} xs={24}>
              <SelectInput
                label="Entidade"
                allowClear
                placeholder="Escolher entidade"
                data={this.state.searchEntities}
                input={{
                  value: this.state.searchEntity,
                  onChange: (event) => {
                    //console.warn('Event', event);
                    this.setState({ searchEntity: event == '' ? null : event }, () => {
                      this.props.type === 0 ? this.updateDataTable() : this.updateDataTableEmployee();
                    });
                  }
                }}
                meta={{ valid: true }}
              />
            </Col>
            <Col xs={12} md={4}>
              <DateInput
                label="Data Inicial"
                placeholder="Selecionar data"
                input={{
                  value: this.state.searchDataInicial,
                  onChange: (event) => {
                    this.setState({ searchDataInicial: event }, () => {
                      this.props.type === 0 ? this.updateDataTable() : this.updateDataTableEmployee();
                    });
                  }
                }}
                meta={{ valid: true }}
              />
            </Col>
            <Col xs={24} md={4}>
              <DateInput
                label="Data Final"
                placeholder="Selecionar data"
                input={{
                  value: this.state.searchDataFinal,
                  onChange: (event) => {
                    this.setState({ searchDataFinal: event }, () => {
                      this.props.type === 0 ? this.updateDataTable() : this.updateDataTableEmployee();
                    });
                  }
                }}
                meta={{ valid: true }}
              />
            </Col>
            {isCurrentUserAdmin() ? <Col lg={3} md={4} sm={6} xs={24}>
              <NumberInput
                label="Valor (%)"
                min={0}
                max={100}
                step={0.01}
                onInput="validity.valid || (value = '')"
                input={{
                  value: this.state.updatePercentage,
                  onChange: (event) => {
                    if (!Number(event) && event > 0) {
                      return;
                    }
                    this.setState({ updatePercentage: event });
                  }
                }}
                meta={{}}
                type="number"
                placeholder="Insira a percentagem"
              />
            </Col> : null}
          </Row>
        </TableFilterSection>

        <div className='payment-table-container'>
          {isCurrentUserAdmin() ?
            <div className={checkboxStyle}>
              <Checkbox
                checked={selectAllRows}
                onChange={(e) => {
                  rows.forEach(r => {
                    r.checked = e.target.checked;
                  });
                  this.setState({ selectAllRows: e.target.checked });
                }}>
              </Checkbox>
            </div> : null}

          <Table
            columns={this.props.type === 0 ? columns : isCurrentUserChief() || isCurrentUserEmployee() ? columnsCurrentEmployee : columnsEmployee}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="euro"
            emptyText={'Não existem pagamentos'}
            total={total}
            rowKey={'employee._id'}
            hasPagination={true}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>

        <PaymentTotals
          hasSelection={isCurrentUserAdmin() ? true : false}
          receipts={false}
          value={!isCurrentUserAdmin() ? rows.reduce((a, b) => a + ((b['value'] + b['bonus']) * (updatePercentage !== null ? (updatePercentage / 100) : b['officialPercentage']) || 0), 0) : rows.filter(x => x.checked == true).reduce((a, b) => this.props.type === 0 ? a + ((b['totalValue'] + b['totalBonus']) * (updatePercentage !== null ? (updatePercentage / 100) : b['officialPercentage']) || 0) : a + ((b['value'] + b['bonus']) * (updatePercentage !== null ? (updatePercentage / 100) : b['officialPercentage']) || 0), 0)}
          bonus={!isCurrentUserAdmin() ? rows.reduce((a, b) => this.props.type === 0 ? a + ((b['totalValue'] + b['totalBonus']) * (updatePercentage !== null ? (1 - (updatePercentage / 100)) : (1 - b['officialPercentage'])) || 0) : a + ((b['value'] + b['bonus']) * (updatePercentage !== null ? (1 - (updatePercentage / 100)) : (1 - b['officialPercentage'])) || 0), 0) : rows.filter(x => x.checked == true).reduce((a, b) => this.props.type === 0 ? a + ((b['totalValue'] + b['totalBonus']) * (updatePercentage !== null ? (1 - (updatePercentage / 100)) : (1 - b['officialPercentage'])) || 0) : a + ((b['value'] + b['bonus']) * (updatePercentage !== null ? (1 - (updatePercentage / 100)) : (1 - b['officialPercentage'])) || 0), 0)}
          total={!isCurrentUserAdmin() ? rows.reduce((a, b) => this.props.type === 0 ? a + (b['totalValue'] + b['totalBonus'] || 0) : a + (b['value'] + b['bonus'] || 0), 0) : rows.filter(x => x.checked == true).reduce((a, b) => this.props.type === 0 ? a + (b['totalValue'] + b['totalBonus'] || 0) : a + (b['value'] + b['bonus'] || 0), 0)}
        />

        {/* <ModalPaymentAccount
          isOpen={openPaymentAccountModal}
          onSubmit={(accountNumber) => this.setState({ openPaymentAccountModal: false }, () => {
            this.newPaymentAccountEmployee(accountNumber);
          })}
          onClose={() => this.setState({ openPaymentAccountModal: false })}
        /> */}

      </React.Fragment>
    );
  }
}

export default withRouter(ToBePayed);
