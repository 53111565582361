import client from '../config/AxiosConfig';

export const GetAllPaymentDays = async () =>
  client.get(`/paymentDay/findAll`);

export const GetAllPaymentDaysGrouped = async () =>
  client.get(`/paymentDay/findAllGrouped`);

export const GetCalendarPaymentDays = async (filter) =>
  client.get(`/paymentDay/findByCalendar?filter=${filter}`);

export const GetPaymentDay = async (id) =>
  client.get(`/paymentDay/find/${id}`);

export const CreatePaymentDay = async (data) =>
  client.put(`/paymentDay/create`, data);

// export const DeletePaymentDay = async (id) =>
//   client.delete(`/paymentDay/remove/${id}`);

export const DeletePaymentDay = async (id) =>
  client.delete(`/paymentDay/removeByOriginalId/${id}`);

export const UpdatePaymentDay = async (data) =>
  client.post(`/paymentDay/update`, data);