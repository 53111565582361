import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {Modal, Button} from 'antd';
import {BaseForm} from '../../styles/BasicStyles';
import TitleInput from '../../components/inputs/TitleInput';
import ColourInput from '../../components/inputs/ColourInput';
import ImageInput from '../../components/inputs/ImageInput';
import FormValidator from '../../infra/services/validations/FormValidator';

const validations = FormValidator.make({
  name: 'required|languages',
  hex: 'hexColour'
});

let ManageColourModal = ({
  open,
  edit,
  loading,
  handleSubmit,
  onSubmit,
  closeModal
}) => {
  return (
    <Modal
      visible={open}
      title={edit ? 'Editar cor' : 'Adicionar cor'}
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      <BaseForm onSubmit={handleSubmit(onSubmit)}>
        <Field
          component={TitleInput}
          name={'name'}
          type="text"
          label={'Nome'}
          placeholder={'Nome da cor'}
        />
        <Field
          component={ColourInput}
          name={'hex'}
          type="text"
          label={'Cor'}
          placeholder={'Insira a cor em hexadecimal'}
        />
        <Field
          component={ImageInput}
          name={'image'}
          label={'Imagem'}
          ratio={1}
        />
      </BaseForm>
    </Modal>
  );
};

ManageColourModal = reduxForm({
  form: 'manage_colour_form',
  validate: validations
})(ManageColourModal);

export default ManageColourModal;
