import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';

class UserFilters extends Component {
  state = {
    search: '',
    userType: null,
    loading: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map(key => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const { search, userType, loading } = this.state;

    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={6}>
            <TextInput
              input={{
                value: search,
                onChange: event =>
                  this.onInputChange('search', event.target.value, 1000)
              }}
              meta={{ valid: true }}
              type="text"
              label="Pesquisar"
              placeholder="Procurar por nome ou email"
            />
          </Col>
          <Col xs={24} sm={12} md={5} lg={4} xl={4}>
            <SelectInput
              label="Tipo de Utilizador"
              disabled={loading}
              allowClear
              placeholder="Selecione o tipo"
              data={[
                { _id: 'RU', name: 'Reserva' },
                { _id: 'AU', name: 'Convidados' },
                { _id: 'U', name: 'Confirmados' },
              ]}
              input={{
                value: userType,
                onChange: value => this.onInputChange('userType', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

export default UserFilters;
