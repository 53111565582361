import React from 'react';
import {
  PrivateNotesSectionContainer,
  PrivateNote
} from '../ProcessStyles';

const SectionPrivateNotes = ({note}) => {
  return (
    <PrivateNotesSectionContainer>
      <PrivateNote
        dangerouslySetInnerHTML={{
          __html: note || 'Não existe nenhuma nota registada!'
        }}
      />
    </PrivateNotesSectionContainer>
  );
};

export default SectionPrivateNotes;
