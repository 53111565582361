import React, { Component } from 'react';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle
} from '../../styles/BasicStyles';
import { GetMetatags } from '../../infra/requests/BaseRequests';
import MetatagsFilters from './MetatagsFilters';

class Metatags extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    filters: '',
    columns: [
      {
        title: 'Local',
        dataIndex: 'local',
        render: value => value === 1 ? 'Solar da Levada' : 'Quinta Lago dos Cisnes',
        width: '25%'
      },
      {
        title: 'Metatags',
        dataIndex: 'name',
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetMetatags(currentPage, pageSize, filters);
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = filters => {
    this.setState({ filters }, this.updateDataTable);
  };

  handleOnPressRow = record => {
    const { history } = this.props;
    return history.push(`/management/metatags/${record._id}`);
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Metatags</PageTitle>
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer buttons={0}>
          <MetatagsFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="file-text"
            emptyText={'There are no metatags'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment >
    );
  }
}

export default Metatags;
