import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import { ChartTypes } from '../../pages/appointments/AppointmentsPage';
import { SpinLoading } from '../../styles/BasicStyles';
import {
  GraphContainer,
  GraphContent,
  LabelsContainer,
  Label,
  LabelColor,
  LabelName,
  GraphTitle
} from './GraphStyles';

const LineGraph = ({ loading, title, data, legend, type, colors }) => {

  const formatter = (value, name, props) => {
    let nameFormat;
    if (type === ChartTypes.WEBSITE) {
      nameFormat = name === 'lago' ? 'Lago dos Cisnes' : name === 'solar' ? 'Solar da Levada' : '';
    } else if (type === ChartTypes.LANGUAGE) {
      nameFormat = name === 'en' ? 'Inglês' : name === 'pt' ? 'Português' : 'ND';
    }
    return [value, nameFormat]
  }

  return (
    <GraphContainer width={550} height={280}>
      {loading ?
        <SpinLoading />
        : <React.Fragment>
          <GraphContent>
            <ResponsiveContainer width={500} height={280}>
              {type === ChartTypes.WEBSITE ?
                <LineChart width={730} height={250} data={data}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip formatter={formatter} />
                  {/* <Legend /> */}
                  <Line type="monotone" dataKey="solar" stroke={colors.find(f => f?.type === 4)?.color} strokeWidth={2} />
                  <Line type="monotone" dataKey="lago" stroke={colors.find(f => f?.type === 5)?.color} strokeWidth={2} />
                </LineChart>
                : type === ChartTypes.LANGUAGE ?
                  <LineChart width={730} height={250} data={data}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip formatter={formatter} />
                    {/* <Legend /> */}
                    <Line type="monotone" dataKey="pt" stroke={colors.find(f => f?.type === 13)?.color} strokeWidth={2} />
                    <Line type="monotone" dataKey="en" stroke={colors.find(f => f?.type === 14)?.color} strokeWidth={2} />
                    <Line type="monotone" dataKey="nd" stroke="#d9d9d9" strokeWidth={2} />
                  </LineChart>
                  : null
              }
              {/* <LineChart width={730} height={250} data={data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="lago" stroke={colors.find(f => f?.type === 5)?.color} />
                <Line type="monotone" dataKey="solar" stroke={colors.find(f => f?.type === 4)?.color} />
              </LineChart> */}
            </ResponsiveContainer>
          </GraphContent>
          <LabelsContainer>
            {title && <GraphTitle>{title}</GraphTitle>}
            {legend.map((elem, index) => (
              <Label key={elem.key}>
                <LabelColor color={elem.color} />
                <LabelName>{elem.label}</LabelName>
              </Label>
            ))}
          </LabelsContainer>
        </React.Fragment>
      }
    </GraphContainer>
  );
};

export default LineGraph;
