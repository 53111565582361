import React from 'react';
import { Modal, Button } from 'antd';

let ModalPDF = ({ openModal, closeModal, onSubmit, loading, title, file }) => {
    return (
        <Modal
            visible={openModal}
            title={title}
            maskClosable
            onCancel={closeModal}
            footer={[
                <Button
                    key='cancel'
                    onClick={() => closeModal()}
                >
                    OK
                </Button>
            ]}
        >
            <div style={{margin: '-24px 0', width: '100%', height: 400}}>
                <iframe src={file} height={'100%'} width={'100%'} style={{  }}></iframe>
            </div>
        </Modal>
    );
};

export default ModalPDF;