import React from 'react';
import {PieChart, Pie, Cell, ResponsiveContainer, Tooltip} from 'recharts';
import {
  GraphContainer,
  GraphContent,
  LabelsContainer,
  Label,
  LabelColor,
  LabelName
} from './BudgetGraphStyles';
import {numberFormat} from '../../../infra/services/calc/numbers';

const BudgetGraph = ({data}) => {
  return (
    <GraphContainer>
      <GraphContent>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              dataKey="value"
              data={data.filter(elem => elem.value > 0)}
              outerRadius={100}>
              {data
                .filter(elem => elem.value > 0)
                .map((entry, index) => (
                  <Cell key={index} fill={entry.color} />
                ))}
            </Pie>
            <Tooltip formatter={(value, name) => `${numberFormat(value)}€`} />
          </PieChart>
        </ResponsiveContainer>
      </GraphContent>
      <LabelsContainer>
        {data.map((label, index) => (
          <Label key={index}>
            <LabelColor color={label.color} />
            <LabelName>
              {label.name} - {numberFormat(label.value)}€
            </LabelName>
          </Label>
        ))}
      </LabelsContainer>
    </GraphContainer>
  );
};

export default BudgetGraph;
