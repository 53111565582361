import React from 'react';
import PropTypes from 'prop-types';
import UpgradeCardComponent from './UpgradeCardComponent';
import {ResponseSection, OptionContainer} from '../../ChoicesPage/SectionStyles';

const ManageUpgradeQuestion = ({input, meta, upgradeList}) => {
  return (
    <ResponseSection>
      {
        upgradeList?.length > 0 ?
        upgradeList.map((elem, index) =>
          <OptionContainer key={index}>
            <UpgradeCardComponent upgrade={elem} input={input} />
          </OptionContainer>
        )
        :
        <div>Não existem Upgrades disponíveis!</div>
      }
    </ResponseSection>
  );
};

ManageUpgradeQuestion.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

export default ManageUpgradeQuestion;