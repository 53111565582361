export const NewsCategories = {
  NEWS: 'Novidades',
  DECORATIONS: 'Últimas Decorações'
};

export const GetCategoryOptions = () => {
  return Object.keys(NewsCategories).map(key => ({
    _id: key,
    name: NewsCategories[key]
  }));
};

export const GetCategoryName = value => NewsCategories[value];

export default NewsCategories;
