import styled from "styled-components";
import { Collapse } from "antd";

const Panel = Collapse.Panel;
export const HeaderSection = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  background-color: #f3f3f3;
  text-align: left;
  margin-bottom: 10px;
  padding: 15px;
  white-space: pre-wrap;
  margin-left: 0;
`;

export const SectionTitle = styled.div`
  line-height: 25px;
  margin: 0;
  font-size: 20px;
  margin-right: 10px;
  color: ${p => p.theme.primaryColor};
  width: calc(100% - 90px);
`;

export const SectionSubTitle = styled.div`
  line-height: 20px;
  margin: 0;
  font-size: 18px;
  margin-right: 10px;
  color: ${p => p.theme.primaryColor};
  width: calc(100% - 90px);
`;

export const SectionAction = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

export const InfoSectionContainer = styled.div`
  width: ${p => (p.isPrimaryTitle ? "100%" : "calc(100% - 5px)")};
  display: inline-block;
  background-color: #f3f3f3;
  text-align: left;
  margin-bottom: 10px;
  padding: 8px;
  white-space: pre-wrap;
  margin-left: ${p => (p.isPrimaryTitle ? "0px" : "5px")};
`;

export const InfoSectionTitle = styled.h3`
  line-height: 25px;
  margin: 0;
  font-size: 20px;
  margin-right: 10px;
  color: ${p => (p.isPrimaryTitle ? p.theme.primaryColor : undefined)};
`;

export const InfoSectionText = styled.p`
  line-height: 25px;
  margin: 0;
  font-size: 16px;
  word-break: break-word;
`;

export const GroupCollapse = styled(Collapse)`
  &&& {
    width: ${p => (p.isPrimaryTitle ? "100%" : "calc(100% - 5px)")};
    display: inline-block;
    background-color: #f3f3f3;
    text-align: left;
    margin-bottom: 10px;
    padding: 8px;
    white-space: pre-wrap;
    margin-left: ${p => (p.isPrimaryTitle ? "0px" : "5px")};
    border: 0;
  }
`;

export const GroupPanel = styled(Panel)`
  border: 0 !important;
`;
