const TranslateValue = value => {
  if (!value) return undefined;
  if (typeof value === 'string') return value;
  if (value?.pt) return value.pt;
  if (value?.en) return value.en;
  if (Object.keys(value).length) return value[Object.keys(value)[0]];
  return value;
};

export default TranslateValue;
