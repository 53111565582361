import React, { Component, Fragment } from 'react';
import Table from '../../../components/table/Table';
import {
    Row,
    Col,
    Popconfirm,
    Icon,
} from 'antd';
import {
    HeaderButtonsContainer,
    TableButton,
    SectionTitle,
    TableFilterSection
} from '../../../styles/BasicStyles';
import BaseButton from '../../../components/buttons/BaseButton';
import TextInput from '../../../components/inputs/TextInput';
import { withLocalize } from 'react-localize-redux';
import {
    GetSettingsByType,
    UpdateSetting,
    DeleteSetting,
    CreateSetting
} from '../../../infra/requests/SettingRequests'
import Alert from "../../../components/alert/Alert";
import moment from 'moment';
import { CreatePaymentPhaseRule, GetChildPaymentPhaseRule, GetFatherPaymentPhaseRule, SoftDeletePaymentPhaseRule, UpdatePaymentPhaseRule } from '../../../infra/requests/PaymentPhaseRulesRequests';
import Alerts from '../../../components/alert/Alert';
import PaymentPhaseRuleModal, { UnitTypes } from './PaymentPhaseRuleModal';

const RemoveRuleMessage = (
    <div>
        <div>Tem a certeza que quer remover esta regra? </div>
        <div>
            Toda a informação da regras será removida da plataforma e esta regra deixará de ser tida em conta
            nas fases de pagamentos do casamento.
        </div>
    </div>
);

const RemoveSubruleMessage = (
    <div>
        <div>Tem a certeza que quer remover esta sub-regra? </div>
        <div>
            Toda a informação da sub-regra será removida da plataforma e esta sub-regra deixará de ser
            tida em conta nas fases de pagamento do casamento.
        </div>
    </div>
);

const EnumUnitTypes = {
    YEAR: 'Y',
    MONTH: 'M',
    DAY: 'D',
    HOUR: 'H',
    MINUTE: 'MIN'
}

const EnumCondition = {
    GREATER: 'GT',
    GREATER_EQUAL: 'GTE',
    EQUAL: 'E',
    LOWER_EQUAL: 'LTE',
    LOWER: 'LT',
    UNTIL: 'U'
}

const EnumConditionDate = {
    BEFORE: 'B',
    // SAMEBEFORE: 'SB',
    // SAME: 'S',
    // SAMEAFTER: 'SA',
    AFTER: 'A'
}

const getUnitDescription = (unit, quantity) => {
    return quantity > 1
        ? unit === EnumUnitTypes.YEAR
            ? 'anos'
            : unit === EnumUnitTypes.MONTH
                ? 'meses'
                : unit === EnumUnitTypes.DAY
                    ? 'dias'
                    : unit === EnumUnitTypes.HOUR
                        ? 'horas'
                        : 'minutos'
        : unit === EnumUnitTypes.YEAR
            ? 'ano'
            : unit === EnumUnitTypes.MONTH
                ? 'mês'
                : unit === EnumUnitTypes.DAY
                    ? 'dia'
                    : unit === EnumUnitTypes.HOUR
                        ? 'hora'
                        : 'minuto';
}

const getConditionDescription = (condition) => {
    return condition === EnumCondition.GREATER
        ? 'Mais de'
        : condition === EnumCondition.GREATER_EQUAL
            ? 'Mais de ou igual a'
            : condition === EnumCondition.EQUAL
                ? 'Igual a'
                : condition === EnumCondition.LOWER_EQUAL
                    ? 'Menos de ou igual a'
                    : condition === EnumCondition.LOWER
                        ? 'Menos de'
                        : 'Até';
}

const getConditionDateDescription = (condition) => {
    return condition === EnumConditionDate.BEFORE
        ? 'antes da'
        // : condition === EnumConditionDate.SAMEBEFORE
        //     ? 'antes ou igual à'
        //     : condition === EnumConditionDate.SAME
        //         ? 'igual à'
        //         : condition === EnumConditionDate.SAMEAFTER
        //             ? 'após ou igual à'
        : condition === EnumConditionDate.AFTER
            ? 'após a'
            : '';
}

export const getRuleDescription = (rule) => {
    const condition = getConditionDescription(rule.condition);
    const unit = getUnitDescription(rule.unit, rule.quantity);
    const conditionDate = getConditionDateDescription(rule.conditionDate);
    const date = rule.dateToCheck === 'C' ? 'Contrato' : 'Casamento';

    return rule.personalized
        ? 'Personalizável por casamento'
        : <div>{condition} {rule.quantity} {unit} {conditionDate} Data do {date}</div>
}

class PaymentPhasesTable extends Component {
    state = {
        pageSize: 15,
        currentPage: 1,
        loading: false,
        search: '',

        showModal: false,
        ruleModal: null,

        columnsFather: [
            {
                title: 'Regra',
                render: data => {
                    return getRuleDescription(data.father);
                }
            },
            {
                title: 'Acções',
                width: '180px',
                render: data => {
                    return <Fragment>
                        <TableButton onClick={e => this.openModalFatherRule(data.father)}>
                            <Icon type="edit" />
                            {' Editar'}
                        </TableButton>
                        <TableButton onClick={e => this.openModalChildRule(null, data)}>
                            <Icon type="plus" />
                            {' Adicionar'}
                        </TableButton>
                        {data.rules.length === 0 && <TableButton onClick={e => e.stopPropagation()}>
                            <Popconfirm
                                placement="topRight"
                                title={RemoveRuleMessage}
                                okText="Remover"
                                onConfirm={async () => { await this.deleteRule(data.father.id) }}>
                                <Icon type="delete" />
                                {' Remover'}
                            </Popconfirm>
                        </TableButton>}
                    </Fragment>
                }
            }
        ],
        columns: [
            {
                title: 'Fase',
                width: '60px',
                render: data => <div>Fase {data.order}</div>
            },
            {
                title: 'Sub-regra',
                render: data => {
                    return getRuleDescription(data);
                }
            },
            {
                title: 'Acções',
                width: '170px',
                render: data => (<Fragment>
                    <TableButton onClick={e => this.openModalChildRule(data)}>
                        <Icon type="edit" />
                        {' Editar'}
                    </TableButton>

                    <TableButton onClick={e => e.stopPropagation()}>
                        <Popconfirm
                            placement="topRight"
                            title={RemoveSubruleMessage}
                            okText="Remover"
                            onConfirm={async () => { await this.deleteRule(data.id) }}>
                            <Icon type="delete" />
                            {' Remover'}
                        </Popconfirm>
                    </TableButton>
                </Fragment>)
            }
        ],
        rows: [],
        total: 0
    };

    componentDidMount = async () => {
        await this.updateDataTable();
    };

    /**
     * @description Update data on datatable
     */
    updateDataTable = async () => {
        this.setState({ loading: true });
        try {
            const result = await GetFatherPaymentPhaseRule();

            this.setState({
                rows: result.data ? result.data : [],
                total: result.data ? result.data.length : 0,
                loading: false
            });
        } catch (e) {
            this.setState({ loading: false });
            console.error(e);
        }
    }

    openModalFatherRule = async (rule = null) => {
        const { rows } = this.state;

        this.setState({
            showModal: true,
            ruleModal: rule
                ? rule
                : {
                    order: rows.length + 1,
                    condition: null,
                    quantity: 0,
                    unit: EnumUnitTypes.MONTH,
                    conditionDate: null,
                    dateToCheck: 'W',
                    personalized: false,
                    warningColor: (rows.length + 1) === 3 ? '#ff4d4f' : '#ffec3d',
                }
        });
    }

    openModalChildRule = async (rule = null, ruleFather = null) => {
        this.setState({
            showModal: true,
            ruleModal: rule
                ? rule
                : {
                    order: ruleFather.rules.length + 1,
                    condition: EnumCondition.UNTIL,
                    quantity: 0,
                    unit: null,
                    conditionDate: null,
                    dateToCheck: null,
                    paymentPhaseRule: ruleFather.father,
                    personalized: false
                }
        });
    }

    /**
     * @description Create/Update rule
     * @returns datatable rules updated
     */
    submitRule = async (values) => {

        const data = { ...values };
        if (data.paymentPhaseRule && data.paymentPhaseRule._id) {
            data.paymentPhaseRule = data.paymentPhaseRule._id;
        }

        // If rule is personalized by wedding, then remove enum fields
        if (data.personalized) {
            delete data.condition;
            delete data.unit;
            delete data.conditionDate;
            delete data.dateToCheck;
        }

        const result = data.id
            ? await UpdatePaymentPhaseRule(data)
            : await CreatePaymentPhaseRule(data);

        if (result.success) {
            this.setState({
                showModal: false,
                ruleModal: null,
            }, () => this.updateDataTable());
        } else {
            return Alerts.new({
                type: 'error',
                title: 'Erro',
                text: result.data
            });
        }
    }

    /**
     * @description Delete rule
     * @param {*} id - rule id
     * @returns datatable unit updated
     */
    deleteRule = async id => {
        const result = await SoftDeletePaymentPhaseRule(id);

        if (result.success) {
            await this.updateDataTable();
        } else {
            return Alerts.new({
                type: 'error',
                title: 'Erro',
                text: result.data
            });
        }
    }

    render() {
        const { loading, } = this.state;
        const { columns, columnsFather, rows, total, search } = this.state;
        const { showModal, ruleModal } = this.state;

        return (
            <React.Fragment>

                <TableFilterSection>
                    <Row gutter={[12, 12]}>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <SectionTitle style={{ marginBottom: 0 }}>Regras para Fases de Pagamento (Processo Casamento)</SectionTitle>
                            {/* <div className='settings-search'>
                                <TextInput
                                    type="text"
                                    label="Pesquisa"
                                    placeholder="Pesquise por entidade"
                                    input={{
                                        value: search,
                                        onChange: event => this.setState({ search: event.target.value })
                                    }}
                                    meta={{}}
                                />
                            </div> */}
                        </Col>

                        <Col lg={12} md={12} sm={12} xs={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <BaseButton
                                type={'primary'}
                                icon={'plus'}
                                text={'Adicionar Regra'}
                                onClick={() => this.openModalFatherRule()}
                            />
                        </Col>
                    </Row>
                </TableFilterSection>

                <div className='table-responsive'>
                    <Table
                        columns={columnsFather}
                        loading={loading}
                        rows={search.trim() === "" ? rows : rows.filter(f => f?.name.toLowerCase().includes(search.toLowerCase()))}
                        showHeader={true}
                        emptyIcon="setting"
                        emptyText={'Não existem regras para as fases de pagamento'}
                        total={search.trim() === "" ? total : rows.filter(f => f?.name.toLowerCase().includes(search.toLowerCase())).length}
                        rowKey={'father._id'}
                        hasPagination={false}
                        defaultExpandAllRows={true}
                        expandedRowRender={(data) =>
                            <Table
                                columns={columns}
                                loading={loading}
                                rows={data.rules}
                                showHeader={true}
                                emptyIcon="setting"
                                emptyText={'Não existem sub-regras'}
                                rowKey={'_id'}
                                hasPagination={false}
                                secondary={true}
                            />
                        }
                    />
                </div>

                {showModal && <PaymentPhaseRuleModal
                    isOpen={showModal}
                    onClose={() => this.setState({ showModal: false, ruleModal: null })}
                    onSubmit={this.submitRule}
                    initialValues={ruleModal} />
                }
            </React.Fragment>
        );
    }
}

export default withLocalize(PaymentPhasesTable);