import React, { Component } from 'react';
import { Col, Icon, Popconfirm, Radio, Row, Tooltip } from 'antd';
import {
    SpinLoading,
    HeaderContainer,
    HeaderTitle,
    PageTitle,
    PageContainer,
    HeaderButtonsContainer,
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import { GetEmployeeByUser } from '../../infra/requests/EmployeeRequests';
import XpertGoLeavePageBlocker from './XpertGoLeavePageBlocker';
import ReserveInfoModal from './ReserveInfoModal';
import moment, { weekdays } from 'moment';
import { CheckDatesAvailable, CheckMngMultipleByAttending, GetReserve, GetReserveAgenda, GetReservesByWedding, GetReservesWithOrder, MngMultipleReserves, SoftDeleteReserve, UpdateReserve, UpdateReserveRelatedInfo } from '../../infra/requests/ReserveRequests';
import Alerts from '../../components/alert/Alert';
import { CalendarBox, CalendarPicker, RadioPlace, RadioYear } from '../../components/YearView/CalendarStyles';
import { AgendaType, Legend, LegendItem, locals, ReserveStatus, WeddingStatus } from '../reserves/ReserveAgenda';
import YearInput from '../../components/inputs/YearInput';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import YearView from '../../components/YearView/YearView';
import { ReserveContent, ReserveItem, ReserveList, ReserveSubtitle, ReserveTitle } from '../reserves/ReserveStyles';
import { CreateCoupleReserve } from '../../infra/requests/UsersRequests';
import { getUserId, isCurrentUserEmployeeAttending } from '../../infra/helpers/Helpers';
import ReserveDateWarningModal from './ReserveDateWarningModal';
import { CheckAttendingAt, GetWedding, UpdateWaitingInfo } from '../../infra/requests/WeddingRequests';
import { CreateMultipleWaitingDate, CreateWaitingDate, GetWaitingDateByWedding, SoftDeleteMultipleWaitingDate, SoftDeleteWaitingDate, SoftDeleteWaitingDateAgenda } from '../../infra/requests/WaitingDatesRequests';
import Bookmark from "../../assets/icons/bookmark.svg";
import Star from "../../assets/icons/star.svg";
import { WaitingIcon } from '../../components/inputs/InputStyles';
import { WaitingActionType } from '../waitingDates/WaitingDatesPage';
import AgendaActions from '../../infra/enum/agendaAction';
import { CreateInterestDate, DeleteInterestDateAgenda, GetInterestDateByWedding } from '../../infra/requests/InterestDatesRequests';
import { getLocal, getWeddingName, getWeddingTitle } from '../../infra/services/wedding/weddingUtils';
import { setDataToUpdateForReserveWedding } from '../reserves/reservesUtils';

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer
localizer.formats.yearHeaderFormat = 'YYYY'
// localizer.messages.year = 'Year'

const removeMessage = <div>
    <div>Têm a certeza que quer remover esta reserva?</div>
    <div>Toda a informação desta reserva será removida da plataforma.</div>
</div>

export const AgendaAttendingType = {
    AGENDA: 'agenda', // Reserve Agenda
    WAITING_DATE: 'waiting', // Waiting Dates Agenda
    INTEREST: 'interest', // Interest Dates Agenda
};

class ReservesAttending extends Component {
    state = {
        loading: false,

        loadingAgenda: false,
        year: moment().year(),
        yearFilter: null,
        local: 1,
        agendaType: AgendaAttendingType.AGENDA,
        dataSource: [],

        waitingDatesLoading: false,
        waitingDates: [],
        selectingWaitingDate: false,

        interestDatesLoading: false,
        interestDates: [],
        selectingInterestDate: false,

        reservesLoading: false,
        reserveList: [],
        removeReserveList: [],

        editReserve: null,
        wedding: null,

        untilPeriodNotificationWaitingDate: 2,
        acceptedWaitingPolicy: false,
        showPolicyModal: false,

        acceptedInterestPolicy: false,
        savingWeddingInterest: false,
        showInterestPolicyModal: false,

        loadingModal: false,
        showReserveModal: false,
        valuesReserveModal: {},
        showDateModal: false,
        dataDateModal: null,
    }

    componentDidMount = async () => {
        const { location, history } = this.props;
        let { agendaType } = this.state;

        const waitingUrl = location?.pathname && location?.pathname === '/waiting' ? true : false;
        const interestUrl = location?.pathname && location?.pathname === '/interest' ? true : false;
        if (waitingUrl) agendaType = AgendaAttendingType.WAITING_DATE;
        else if (interestUrl) agendaType = AgendaAttendingType.INTEREST;
        else agendaType = AgendaAttendingType.AGENDA;

        const reserveId = new URLSearchParams(location.search).get('reserve') !== undefined ? new URLSearchParams(location.search).get('reserve') : null;
        const weddingId = new URLSearchParams(location.search).get('wedding') !== undefined ? new URLSearchParams(location.search).get('wedding') : null;

        if ((agendaType === AgendaAttendingType.INTEREST || agendaType === AgendaAttendingType.WAITING_DATE) && !weddingId) {
            history.push('/404');
            return;
        }

        this.setState({
            agendaType,
            // year: agendaType == AgendaType.INTEREST ? moment.utc().year() + 1 : moment.utc().year()
        }, async () => {
            if (reserveId && weddingId) history.push('/agenda');
            else if (weddingId) await this.getWedding(weddingId);
            else if (reserveId) await this.getReserve(reserveId);
            else await this.getAgenda();
        });
    }

    componentDidUpdate = (prevProps, props) => {
        const oldReserveId = new URLSearchParams(prevProps.location.search).get('reserve') !== undefined ? new URLSearchParams(prevProps.location.search).get('reserve') : null;
        const newReserveId = new URLSearchParams(prevProps.history.location.search).get('reserve') !== undefined ? new URLSearchParams(prevProps.history.location.search).get('reserve') : null;
        if (oldReserveId && !newReserveId) {
            this.cancel();
        }
    }

    getEmployee = async (user) => {
        this.setState({ loading: true });
        const resEmployee = await GetEmployeeByUser(user._id);

        this.setState({
            currentEmployee: resEmployee.success && resEmployee.data ? resEmployee.data : null,
            loading: false
        });
    }

    getAgenda = async (fromReserve = false, weddingId = null) => {
        const { year, local, agendaType } = this.state;
        let { reserveList, editReserve, wedding } = this.state;

        if (fromReserve) this.setState({ reservesLoading: true });
        else this.setState({ loadingAgenda: true, reservesLoading: (weddingId || wedding?._id) ? true : false });
        try {
            const filters = { year, local };
            if (agendaType == AgendaType.INTEREST) {
                filters['agendaType'] = 'INTEREST';
            }
            const result = await GetReserveAgenda(filters);
            const data = result && result?.data ? result.data : [];

            if (weddingId) {
                const resultReserve = await GetReservesByWedding(weddingId);
                reserveList = resultReserve?.success && resultReserve?.data ? resultReserve.data : [];
            }

            if ((weddingId || wedding?._id)) {
                if (agendaType === AgendaAttendingType.WAITING_DATE) await this.getWaitingDates(weddingId);
                if (agendaType === AgendaAttendingType.INTEREST) await this.getInterestDates(weddingId);
            }

            // Select dates that are in reserve list
            reserveList.forEach((reserve) => {
                const index = data.findIndex(f => reserve.event && f.id === reserve.event.id && reserve.weddingPlace === local);
                if (index > -1) {
                    data[index].resource.selected = true;

                    if ((editReserve?.event && reserve?.event?.id === editReserve?.event?.id) ||
                        (!editReserve?.event && reserve?.event?.resource?.weddingReserve && reserve?.event?.resource?.weddingReserve?._id === editReserve?._id)) {
                        data[index].resource.edit = true;
                        editReserve = reserve;
                    }
                }
            })

            this.setState({
                dataSource: data,
                reserveList,
                editReserve,
                loadingAgenda: false,
                reservesLoading: false,
            });
        } catch (e) {
            this.setState({ loadingAgenda: false, reservesLoading: false });
            console.error(e);
        }
    }

    getWaitingDates = async (weddingId = null) => {
        const { year, local } = this.state;
        const filters = { year, local };

        let { waitingDates, wedding } = this.state;
        this.setState({ waitingDatesLoading: true });

        if (weddingId || wedding?._id) {
            const id = weddingId ? weddingId : wedding?._id;
            const resultWaitingDates = await GetWaitingDateByWedding(id, JSON.stringify(filters));
            waitingDates = resultWaitingDates?.success && resultWaitingDates?.data ? resultWaitingDates.data : [];
        }

        this.setState({
            waitingDates,
            waitingDatesLoading: false
        });
    }

    getInterestDates = async (weddingId = null) => {
        const { year, local } = this.state;
        const filters = { year, local };

        let { interestDates, wedding } = this.state;
        this.setState({ interestDatesLoading: true });

        if (weddingId || wedding?._id) {
            const id = weddingId ? weddingId : wedding?._id;
            const resultInteresrDates = await GetInterestDateByWedding(id, JSON.stringify(filters));
            interestDates = resultInteresrDates?.success && resultInteresrDates?.data ? resultInteresrDates.data : [];
        }

        this.setState({
            interestDates,
            interestDatesLoading: false
        });
    }

    getReserve = async (reserveId) => {
        const { history } = this.props;
        const { year } = this.state;

        this.setState({ loadingAgenda: true });
        const result = await GetReserve(reserveId, isCurrentUserEmployeeAttending());
        // console.warn('result', result);

        if (result.success) {
            const reserveYear = result?.data?.date && moment(result?.data?.date).isValid()
                ? moment(result?.data?.date).year()
                : null;

            this.setState({
                editReserve: result.data,
                wedding: result.data.wedding,
                acceptedWaitingPolicy: result?.data?.acceptedWaitingPolicy || false,
                untilPeriodNotificationWaitingDate: result?.data?.wedding?.untilPeriodNotificationWaitingDate || 2,
                local: result?.data?.weddingPlace,
                year: reserveYear && reserveYear !== year ? reserveYear : year
            }, () => this.getAgenda(false, result.data.wedding._id));
        } else {
            Alerts.new({
                type: 'error',
                title: 'Erro',
                text: result.data
            });
            this.setState({
                editReserve: null,
                wedding: null,
                acceptedWaitingPolicy: false,
                untilPeriodNotificationWaitingDate: 2
            }, () => {
                history.goBack();
            });
        }
    }

    getWedding = async (weddingId) => {
        const { history } = this.props;
        const { agendaType } = this.state;

        this.setState({ loadingAgenda: true });
        const result = await GetWedding(weddingId);
        // console.warn('result', result);

        if (result?.success) {
            if ((agendaType === AgendaAttendingType.AGENDA || agendaType === AgendaAttendingType.INTEREST)
                && result?.data?.status !== WeddingStatus.RESERVE) {
                history.push('/404');
                return;
            }

            // If is employeeAttending
            if (isCurrentUserEmployeeAttending() && result?.data) {
                // Check if the employeeAttending has access to this wedding
                if (result?.data?.attendedBy && result?.data?.attendedBy == getUserId()) {
                    // Check if can edit (15 minutes)
                    if (result?.data?.attendingAt && moment.utc().isAfter(moment.utc(result?.data?.attendingAt).add(15, 'minutes'))) {
                        Alerts.new({
                            type: 'error',
                            title: 'Erro',
                            text: 'O período de edição terminou!'
                        });
                        history.goBack();
                        return;
                    }
                } else if (result?.data?.attendedBy && result?.data?.attendedBy != getUserId()) {
                    Alerts.new({
                        type: 'error',
                        title: 'Erro',
                        text: 'Não autorizado!'
                    });
                    history.goBack();
                    return;
                }
            }

            this.setState({
                editReserve: null,
                wedding: result?.data,
                acceptedWaitingPolicy: result?.data?.acceptedWaitingPolicy || false,
                untilPeriodNotificationWaitingDate: result?.data?.untilPeriodNotificationWaitingDate || 2,
                acceptedInterestPolicy: result?.data?.acceptedInterestPolicy || false,
            }, () => this.getAgenda(false, result?.data?._id));
        } else {
            Alerts.new({
                type: 'error',
                title: 'Erro',
                text: result?.data
            });
            this.setState({
                editReserve: null,
                wedding: null,
                acceptedWaitingPolicy: false,
                untilPeriodNotificationWaitingDate: 2,
                acceptedInterestPolicy: false,
            }, () => {
                history.goBack();
            });
        }
    }

    changeTab = (event) => {
        this.setState({ local: event.target.value }, () => this.getAgenda());
    }

    changeYear = (event) => {
        const { year } = this.state;
        this.setState({ year: (year + parseInt(event.target.value)) }, () => this.getAgenda());
    }

    /** Waiting Dates */
    onMultipleWaitingClick = async (value) => {
        const { wedding, local, year } = this.state;
        // console.warn('value attending', value);
        this.setState({ selectingWaitingDate: true });

        if (isCurrentUserEmployeeAttending()) {
            const resultCanEdit = await CheckAttendingAt(wedding?._id ? wedding?._id : wedding);
            if (!resultCanEdit?.success) {
                const errorTxt = resultCanEdit?.data === 'NOT_AUTHORIZED'
                    ? 'Não Autorizado'
                    : resultCanEdit?.data === 'TIME_CREATE_EDIT_ENDED'
                        ? 'O período de edição terminou!'
                        : resultCanEdit?.data;
                Alerts.new({
                    type: 'error',
                    title: 'Erro',
                    text: errorTxt
                });
                this.setState({ selectingWaitingDate: false });
                return;
            }
        }

        if (value?.month >= 0) {
            if (moment.utc().set('year', year).set('month', value?.month).startOf('month').isBefore(moment.utc().startOf('month'), 'date')) {
                Alerts.new({
                    type: 'warning',
                    title: 'Atenção',
                    text: 'Não é possível selecionar meses anteriores à data atual.'
                });
                this.setState({ selectingWaitingDate: false });
                return;
            }

            const data = {
                type: value.type,
                weekday: value?.weekday >= 0 ? value.weekday : null,
                year,
                month: value.month,
                weddingPlace: local,
                wedding: wedding?._id ? wedding?._id : wedding,
            };

            const result = value?.selected
                ? await SoftDeleteMultipleWaitingDate(true, data)
                : await CreateMultipleWaitingDate(data);
            if (result?.success) {
                Alerts.new({
                    type: 'success',
                    title: 'Sucesso',
                    text: value?.selected
                        ? 'Datas eliminadas da lista de espera.'
                        : 'Datas adicionadas à lista de espera.'
                });
                this.setState({
                    selectingWaitingDate: false,
                    waitingDatesLoading: true
                }, () => this.getWaitingDates());
            } else {
                const errorMsg = result?.data == 'ERROR_DATE_FREE'
                    ? 'Ação inválida. Só pode adicionar semanas com datas ocupadas.'
                    : result?.data;
                Alerts.new({
                    type: 'error',
                    title: 'Erro',
                    text: errorMsg
                });
                this.setState({ selectingWaitingDate: false });
            }
        }
    }

    onWaitingClick = async (value) => {
        const { wedding, local } = this.state;
        // console.warn('value', value);

        if (wedding?.status === WeddingStatus.RESERVE_SINALIZATION || wedding?.status === WeddingStatus.CONFIRMED
            && wedding?.date && value?.date && moment.utc(wedding.date).isSame(moment.utc(value.date), 'date')) {
            Alerts.new({
                type: 'warning',
                title: 'Atenção',
                text: 'Ação inválida. Não pode adicionar a data do casamento à lista de espera.'
            });
            return;
        }

        this.setState({ selectingWaitingDate: true });

        if (isCurrentUserEmployeeAttending()) {
            const resultCanEdit = await CheckAttendingAt(wedding?._id ? wedding?._id : wedding);
            if (!resultCanEdit?.success) {
                const errorTxt = resultCanEdit?.data === 'NOT_AUTHORIZED'
                    ? 'Não Autorizado'
                    : resultCanEdit?.data === 'TIME_CREATE_EDIT_ENDED'
                        ? 'O período de edição terminou!'
                        : resultCanEdit?.data;
                Alerts.new({
                    type: 'error',
                    title: 'Erro',
                    text: errorTxt
                });
                this.setState({ selectingWaitingDate: false });
                return;
            }
        }

        if (value?.date) {
            if (moment.utc().startOf('date').isSameOrAfter(moment(value?.date).startOf('date'), 'date')) {
                Alerts.new({
                    type: 'warning',
                    title: 'Atenção',
                    text: 'Não é possível selecionar datas inferiores à data atual.'
                });
                this.setState({ selectingWaitingDate: false });
                return;
            }

            const data = {
                type: WaitingActionType.DAY,
                date: value?.date,
                weddingPlace: local,
                wedding: wedding?._id ? wedding?._id : wedding,
            };
            const result = value?.selectedWaitingDate
                ? await SoftDeleteWaitingDateAgenda(true, data)
                : await CreateWaitingDate(data);
            if (result?.success) {
                Alerts.new({
                    type: 'success',
                    title: 'Sucesso',
                    text: value?.selectedWaitingDate
                        ? 'Data eliminada da lista de espera.'
                        : 'Data adicionada à lista de espera.'
                });
                this.setState({
                    selectingWaitingDate: false,
                    waitingDatesLoading: true
                }, () => this.getWaitingDates());
            } else {
                Alerts.new({
                    type: 'error',
                    title: 'Erro',
                    text: result?.data
                });
                this.setState({ selectingWaitingDate: false });
            }
        }
    }

    /** Interest Dates */
    onInterestClick = async (value) => {
        const { wedding, local } = this.state;
        // console.warn('value', value);
        // if (wedding?.status === WeddingStatus.RESERVE_SINALIZATION || wedding?.status === WeddingStatus.CONFIRMED
        //     && wedding?.date && value?.date && moment.utc(wedding.date).isSame(moment.utc(value.date), 'date')) {
        //     Alerts.new({
        //         type: 'warning',
        //         title: 'Atenção',
        //         text: 'Ação inválida. Não pode adicionar a data do casamento à lista de espera.'
        //     });
        //     return;
        // }

        this.setState({ selectingInterestDate: true });

        if (isCurrentUserEmployeeAttending()) {
            const resultCanEdit = await CheckAttendingAt(wedding?._id ? wedding?._id : wedding);
            if (!resultCanEdit?.success) {
                const errorTxt = resultCanEdit?.data === 'NOT_AUTHORIZED'
                    ? 'Não Autorizado'
                    : resultCanEdit?.data === 'TIME_CREATE_EDIT_ENDED'
                        ? 'O período de edição terminou!'
                        : resultCanEdit?.data;
                Alerts.new({
                    type: 'error',
                    title: 'Erro',
                    text: errorTxt
                });
                this.setState({ selectingInterestDate: false });
                return;
            }
        }

        if (value?.date) {
            if (moment.utc().startOf('date').isSameOrAfter(moment(value?.date).startOf('date'), 'date')) {
                Alerts.new({
                    type: 'warning',
                    title: 'Atenção',
                    text: 'Não é possível selecionar datas inferiores à data atual.'
                });
                this.setState({ selectingInterestDate: false });
                return;
            }

            const data = {
                date: value?.date,
                weddingPlace: local,
                price: value?.event?.resource?.price?._id,
                wedding: wedding?._id ? wedding?._id : wedding,
            };
            const result = value?.selectedInterestDate
                ? await DeleteInterestDateAgenda(true, data)
                : await CreateInterestDate(data);
            if (result?.success) {
                Alerts.new({
                    type: 'success',
                    title: 'Sucesso',
                    text: value?.selectedInterestDate
                        ? 'Data de interesse eliminada.'
                        : 'Data de interesse adicionada.'
                });
                this.setState({
                    selectingInterestDate: false,
                    interestDatesLoading: true
                }, () => this.getInterestDates());
            } else {
                const errorTxt = result?.data === 'EXISTS_RESERVES_FOR_DATE'
                    ? 'Ação inválida. A data encontra-se reservada.'
                    : result?.data === 'EXISTS_INTEREST_DATE'
                        ? 'Essa data já se encontra nas datas de interesse.'
                        : result?.data;
                Alerts.new({
                    type: 'error',
                    title: 'Erro',
                    text: errorTxt
                });
                this.setState({ selectingInterestDate: false });
            }
        }
    }

    /** Reserve Dates */
    onDayClick = async (value) => {
        const { local, editReserve, wedding } = this.state;
        let { dataSource, reserveList } = this.state;

        // Check if wedding/couple is in reserve phase
        if (wedding?.status && wedding?.status !== WeddingStatus.RESERVE) {
            return;
        }

        // Select Data
        if (value?.date && moment(value?.date).isBefore(moment().startOf('date'))) {
            Alerts.new({
                type: 'warning',
                title: 'Atenção',
                text: 'Não é possível selecionar datas inferiores à data atual.'
            });
            return;
        }

        // If wedding.reservesBlocked then all reserves action are blocked
        // if (wedding.reservesBlocked) return;

        // Edit date selected
        if (editReserve) {
            //Check if can edit selected date by local
            const reservesByLocal = reserveList.filter(f => f?.weddingPlace === local && editReserve && editReserve?.event && f?.event?.id !== editReserve?.event?.id);
            if (reservesByLocal.length >= 2) {
                return Alerts.new({
                    type: 'warning',
                    title: 'Atenção!',
                    text: 'Somente pode selecionar 2 datas por espaço!'
                });
            }

            //Check if the selected date in already in reserve list
            const index = reserveList?.findIndex(f => f?.weddingPlace && f?.weddingPlace === local && value && value.event && f.event.id === value.event.id);
            if (index > -1) {
                return Alerts.new({
                    type: 'warning',
                    title: 'Atenção',
                    text: 'Essa data já se encontra selecionada!'
                });
            }

            this.setState({
                showDateModal: true,
                dataDateModal: {
                    original: editReserve,
                    new: value
                }
            });
        }
        // select a new date
        else {
            //Check if can add selected date by local
            const reservesByLocal = reserveList.filter(f => f.weddingPlace === local);
            if (reservesByLocal.length >= 2) {
                return Alerts.new({
                    type: 'warning',
                    title: 'Atenção!',
                    text: 'Somente pode selecionar 2 datas por espaço!'
                });
            }

            this.setState({ reservesLoading: true });

            const index = dataSource.findIndex(f => value.event && f.id === value.event.id);
            if (index > -1) {
                value.event.resource.selected = true;
                dataSource[index] = value.event;

                const indexReserve = reserveList.findIndex(f => f.event && value.event && f.event.id === value.event.id && f.weddingPlace === local);
                if (indexReserve === -1) reserveList.push({ ...value, weddingPlace: local });
            }
            this.setState({ reserveList, dataSource }, () => {
                setTimeout(() => this.setState({ reservesLoading: false }), 200);
            });
        }
    }

    editReserveDate = (value) => {
        const { local, editReserve } = this.state;
        let { dataSource, reserveList } = this.state;

        this.setState({ showDateModal: false, dataDateModal: null, reservesLoading: true });

        // Unselect editReserve date
        const indexEdit = dataSource.findIndex(f => editReserve && editReserve.event && f.id === editReserve.event.id && editReserve.weddingPlace === local);
        if (indexEdit > -1) {
            editReserve.event.resource.selected = false;
            editReserve.event.resource.edit = false;
            dataSource[indexEdit] = editReserve.event;
        }

        // Select new date
        const indexData = dataSource.findIndex(f => value && value.event && f.id === value.event.id);
        if (indexData > -1) {
            value.event.resource.selected = true;
            dataSource[indexData] = value.event;
        }

        // Update reserve list with new date information
        const indexReserve = reserveList.findIndex(f => f.event && editReserve.event && f.event.id === editReserve.event.id && f.weddingPlace === editReserve.weddingPlace);
        if (indexReserve > -1) {
            const weddingReserve = reserveList[indexReserve].event && reserveList[indexReserve].event.resource && reserveList[indexReserve].event.resource.weddingReserve ? reserveList[indexReserve].event.resource.weddingReserve : null;
            const updateReserve = { ...value, weddingPlace: local };
            if (weddingReserve && updateReserve && updateReserve.event && updateReserve.event.resource) updateReserve.event.resource['weddingReserve'] = weddingReserve;
            // console.warn('updateReserve', updateReserve);
            reserveList[indexReserve] = updateReserve;
        }

        this.setState({
            reserveList,
            dataSource,
            editReserve: null,
        }, () => {
            setTimeout(() => this.setState({ reservesLoading: false }), 200);
        });
    }

    removeDate = (reserve) => {
        this.setState({ reservesLoading: true });
        let { reserveList, removeReserveList, dataSource } = this.state;
        const indexData = dataSource.findIndex(f => f.id === reserve.event.id);
        if (indexData > -1) {

            reserve.event.resource.selected = false;
            reserve.event.resource.edit = false;
            dataSource[indexData] = reserve.event;
        }

        const indexReserve = reserveList.findIndex(f => f.event && reserve.event && f.event.id === reserve.event.id && f.weddingPlace === reserve.weddingPlace);
        if (indexReserve > -1) {
            // if reserve that it's already in database add to removeReserveList
            if (reserveList[indexReserve] && reserveList[indexReserve].event && reserveList[indexReserve].event.resource && reserveList[indexReserve].event.resource.weddingReserve) {
                const indexDelete = removeReserveList.findIndex(f => f._id === reserveList[indexReserve].event.resource.weddingReserve._id);
                if (indexDelete === -1) removeReserveList.push(reserveList[indexReserve].event.resource.weddingReserve);
            }

            reserveList.splice(indexReserve, 1);
        }

        this.setState({ reserveList, dataSource, removeReserveList }, () => {
            setTimeout(() => this.setState({ reservesLoading: false }), 200);
        });
    }

    editDate = (reserve) => {
        const { local, editReserve, year } = this.state;
        let { dataSource } = this.state;
        // console.warn('editReserve', editReserve);
        // console.warn('reserve', reserve);

        // Remove edit color on editReserve date
        const indexEdit = dataSource.findIndex(f => editReserve && editReserve.event && f?.id === editReserve.event?.id && editReserve?.weddingPlace === local);
        if (indexEdit > -1) {
            editReserve.event.resource.edit = false;
            dataSource[indexEdit] = editReserve.event;
        }

        // Set edit color
        const indexData = dataSource.findIndex(f => f?.id === reserve?.event?.id);
        if (indexData > -1) {
            reserve.event.resource.edit = true;
            dataSource[indexData] = reserve.event;
        }

        const reserveYear = reserve?.date && moment(reserve.date).isValid()
            ? moment(reserve.date).year()
            : null;

        this.setState({
            dataSource,
            editReserve: reserve,
            local: reserve?.weddingPlace,
            year: reserveYear && reserveYear !== year ? reserveYear : year
        }, () => this.getAgenda(false));
    }

    cancelEditDate = () => {
        const { local, editReserve } = this.state;
        let { dataSource } = this.state;

        // Remove edit color on editReserve date
        const indexEdit = dataSource.findIndex(f => editReserve && editReserve.event && f?.id === editReserve.event?.id && editReserve?.weddingPlace === local);
        if (indexEdit > -1 && editReserve) {
            editReserve.event.resource.edit = false;
            dataSource[indexEdit] = editReserve.event;
        }

        this.setState({ dataSource, editReserve: null }, () => this.getAgenda(false));
    }

    // Create Reserve
    finishReserve = async (values) => {
        const { history } = this.props;
        const { wedding, editReserve, reserveList, removeReserveList } = this.state;

        // if ((!values?.visitedPlace || values?.visitedPlace == false)
        //     && (!values?.placeToVisit || values?.placeToVisit == false)
        //     && (!values?.wantToVisitWithoutInfo || values?.wantToVisitWithoutInfo == false)) {
        //     Alerts.new({
        //         type: 'warning',
        //         title: 'Atenção',
        //         text: 'Indique se já visitou ou pretende visitar algum dos espaços.'
        //     });
        //     return;
        // }

        //Check if employee attending still has time to create/edit wedding reserves
        if (wedding?._id) {
            const resultCheck = await CheckMngMultipleByAttending(wedding._id);
            if (!resultCheck?.success) {
                Alerts.new({
                    type: 'error',
                    title: 'Erro',
                    text: 'O período de edição desta(s) reserva(s) terminou!'
                });
                this.cancel();
                return;
            }
        }

        // Check if all dates selected are still available
        const dataCheck = {
            wedding,
            reserves: reserveList,
            removeReserves: removeReserveList
        };
        const resultCheckDates = await CheckDatesAvailable(dataCheck);
        if (!resultCheckDates?.success) {
            if (resultCheckDates?.data === 'ERROR_DATE_OCCUPIED' || resultCheckDates?.data === 'ERROR_DATE_UNAVAILABLE'
                || resultCheckDates?.data === 'DUPLICATED_DATES' || resultCheckDates?.data === 'NUMBER_RESERVES_BY_PLACE') {
                const message = resultCheckDates?.data === 'ERROR_DATE_OCCUPIED'
                    ? 'Não é possivel realizar a operação pois já existe uma data ocupada ou em sinalização.'
                    : resultCheckDates?.data === 'ERROR_DATE_UNAVAILABLE'
                        ? 'Função indisponível, data indisponível para reserva.'
                        : resultCheckDates?.data === 'DUPLICATED_DATES'
                            ? 'Datas duplicadas!'
                            : resultCheckDates?.data === 'NUMBER_RESERVES_BY_PLACE'
                                ? 'Somente pode selecionar 2 datas por espaço!'
                                : '';
                Alerts.new({
                    type: 'warning',
                    title: 'Atenção!',
                    text: message
                });
            } else {
                Alerts.new({
                    type: 'error',
                    title: 'Erro!',
                    text: resultCheckDates?.data
                });
            }
            return;
        }

        this.setState({ loadingModal: true });
        const data = setDataToUpdateForReserveWedding(values);
        const resultCoupleWedding = wedding
            ? await UpdateReserveRelatedInfo(wedding._id, data)
            : await CreateCoupleReserve(data);

        if (resultCoupleWedding.success) {
            this.mngReserves(wedding ? resultCoupleWedding.data : resultCoupleWedding.data.wedding);
        } else return;
    }

    mngReserves = async (wedding) => {
        const { history } = this.props;
        const { reserveList, removeReserveList } = this.state;

        const data = {
            wedding,
            reserves: reserveList,
            removeReserves: removeReserveList
        };
        const resultReserves = await MngMultipleReserves(data);
        // console.warn('resultReserves', resultReserves);

        if (resultReserves.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: 'Reservas Criadas'
            });

            this.setState({
                reserveList: [],
                removeReserveList: [],
                loadingModal: true,
                showReserveModal: false,
                valuesReserveModal: {}
            }, () => history.push('/reserves'));
        }
    }

    showModalReserve = async () => {
        const { reserveList, editReserve, wedding } = this.state;

        if (reserveList.length === 0) {
            return Alerts.new({
                type: 'error',
                title: 'Erro',
                text: 'Selecione as datas pretendidas.'
            });
        }

        const result = await GetReservesWithOrder(reserveList);
        if (result.success) {
            const valuesReserveModal = wedding ? {
                ...wedding,
                bridename: wedding.bride.name,
                brideemail: wedding.bride.email,
                bridecontact: wedding.bride.contact,
                groomname: wedding.groom.name,
                groomemail: wedding.groom.email,
                groomcontact: wedding.groom.contact,
                alternativename: wedding?.alternativeGuest?.name || '',
                alternativemail: wedding?.alternativeGuest?.email || '',
                alternativecontact: wedding?.alternativeGuest?.contact || '',
                alternativerelationcouple: wedding?.alternativeGuest?.relationToCouple || null
            } : {};

            this.setState({
                showReserveModal: true,
                valuesReserveModal,
                reserveList: result.data
            });
        }
    }

    cancel = () => {
        const { history } = this.props;
        history.goBack();
        // this.setState({
        //     reserveList: [],
        //     removeReserveList: [],
        //     editReserve: null,
        //     wedding: null,
        //     acceptedWaitingPolicy: false,
        //     untilPeriodNotificationWaitingDate: 2,
        //     waitingDates: [],
        //     showReserveModal: false,
        //     valuesReserveModal: {},
        //     showDateModal: false,
        //     dataDateModal: null,
        // }, () => {
        //     // history.push('/home-attending');
        //     // this.getAgenda();
        //     history.goBack();
        // });
    }

    changeWeddingWaitingInfo = async () => {
        const { untilPeriodNotificationWaitingDate, acceptedWaitingPolicy, wedding } = this.state;
        const weddingId = wedding?._id ? wedding?._id : wedding;
        this.setState({ savingWeddingWaiting: true });

        if (isCurrentUserEmployeeAttending()) {
            const resultCanEdit = await CheckAttendingAt(weddingId);
            if (!resultCanEdit?.success) {
                const errorTxt = resultCanEdit?.data === 'NOT_AUTHORIZED'
                    ? 'Não Autorizado'
                    : resultCanEdit?.data === 'TIME_CREATE_EDIT_ENDED'
                        ? 'O período de edição terminou!'
                        : resultCanEdit?.data;
                Alerts.new({
                    type: 'error',
                    title: 'Erro',
                    text: errorTxt
                });
                this.setState({ savingWeddingWaiting: false });
                return;
            }
        }

        const data = {
            period: untilPeriodNotificationWaitingDate,
            // accepted: acceptedWaitingPolicy
        };
        const result = await UpdateWaitingInfo(weddingId, data);

        if (result?.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: 'Informação atualizada com sucesso!'
            });
            this.setState({
                wedding: result?.data,
                acceptedWaitingPolicy: result?.data?.acceptedWaitingPolicy,
                untilPeriodNotificationWaitingDate: result?.data?.untilPeriodNotificationWaitingDate,
                savingWeddingWaiting: false,
            });
        } else {
            this.setState({ savingWeddingWaiting: false });
            Alerts.new({
                type: 'error',
                title: 'Erro',
                text: result?.data
            });
        }
    }

    render() {
        const { loading } = this.state;
        const { loadingAgenda, agendaType } = this.state;
        const { year, yearFilter, local, dataSource, waitingDates, interestDates } = this.state;
        const { reserveList, reservesLoading, removeReserveList } = this.state;
        const { showReserveModal, valuesReserveModal, loadingModal } = this.state;
        const { showDateModal, dataDateModal, showPolicyModal } = this.state;
        const { editReserve, wedding } = this.state;
        const { selectingWaitingDate, waitingDatesLoading, untilPeriodNotificationWaitingDate, acceptedWaitingPolicy, savingWeddingWaiting } = this.state;
        const { selectingInterestDate, interestDatesLoading, acceptedInterestPolicy, savingWeddingInterest } = this.state;
        const reservePhase = !wedding || (wedding?.status && wedding?.status === WeddingStatus.RESERVE) ? true : false;
        const reserveSinalizationPhase = wedding && wedding?.status && wedding?.status === WeddingStatus.RESERVE_SINALIZATION ? true : false;

        if (loading) return <SpinLoading />;
        return (
            <React.Fragment>
                {agendaType === AgendaAttendingType.AGENDA
                    && <XpertGoLeavePageBlocker
                        when={reserveList?.length > 0 || removeReserveList?.length > 0}
                        message='Pretende sair sem guardar as alterações?'
                    />}
                <HeaderContainer>
                    <HeaderTitle buttons={2}>
                        <PageTitle>Agenda de Reservas</PageTitle>
                        {wedding !== undefined && wedding !== null
                            ? getWeddingTitle(wedding)
                            : ''}
                    </HeaderTitle>
                    <HeaderButtonsContainer buttons={2}>
                        <BaseButton
                            type={'default'}
                            icon={'close'}
                            text={'Cancelar'}
                            onClick={() => this.cancel()}
                        />
                        {reservePhase && agendaType === AgendaAttendingType.AGENDA && <BaseButton
                            type={'primary'}
                            icon={'plus'}
                            disabled={loading || loadingAgenda || reservesLoading}
                            text={'Finalizar Reserva'}
                            onClick={() => this.showModalReserve()}
                        />}
                        {agendaType === AgendaAttendingType.WAITING_DATE && wedding !== null && wedding !== undefined && <BaseButton
                            type={'primary'}
                            icon={'save'}
                            disabled={savingWeddingWaiting || loading || loadingAgenda}
                            text={'Guardar'}
                            onClick={() => this.changeWeddingWaitingInfo()}
                        />}
                    </HeaderButtonsContainer>
                </HeaderContainer>

                <PageContainer buttons={2}>
                    <Row gutter={[12, 12]} type='flex'>
                        <Col
                            xl={{ span: 20, order: 1 }}
                            lg={{ span: 19, order: 1 }}
                            md={{ span: 24, order: 2 }}
                            sm={{ span: 24, order: 2 }}
                            xs={{ span: 24, order: 2 }}>
                            <CalendarBox reserves={true}>
                                {/* <Title>{translate('RESERVE_YOUR_DAY')}</Title> */}

                                <Row gutter={[12, 12]}>
                                    <Col xs={24} md={12} lg={11} xl={8} xxl={8}>
                                        <RadioPlace>
                                            <Radio.Group value={local} disabled={loadingAgenda} onChange={(event) => this.changeTab(event)}>
                                                {locals.map((local, index) => (
                                                    <Radio.Button key={index} value={local._id}>
                                                        {local.name}
                                                    </Radio.Button>
                                                ))}
                                            </Radio.Group>
                                        </RadioPlace>
                                    </Col>
                                    <Col xs={24} md={7} lg={9} xl={10} xxl={10}>
                                        <RadioYear>
                                            <Radio.Group value={0} disabled={loadingAgenda} onChange={(event) => this.changeYear(event)}>
                                                <Radio.Button value={-1} disabled={year <= 2022}>
                                                    <Tooltip className='iconPrevious' title='Ano Anterior'>
                                                        <Icon type="left" />
                                                    </Tooltip>

                                                    <span className='spanPrevious'>
                                                        <Icon type="left" />
                                                        Ano Anterior
                                                    </span>
                                                </Radio.Button>
                                                <Radio.Button value={0}>{year}</Radio.Button>
                                                <Radio.Button value={1}>
                                                    <Tooltip className='iconNext' title='Ano Seguinte'>
                                                        <Icon type="right" />
                                                    </Tooltip>

                                                    <span className='spanNext'>
                                                        Ano Seguinte
                                                        <Icon type="right" />
                                                    </span>
                                                </Radio.Button>
                                            </Radio.Group>
                                        </RadioYear>
                                    </Col>
                                    <Col xs={24} md={5} lg={4} xl={6} xxl={6} className='calendarPicker'>
                                        <CalendarPicker>
                                            <YearInput
                                                placeholder="Selecione o ano"
                                                input={{
                                                    value: yearFilter,
                                                    onChange: (value) => {
                                                        this.setState({
                                                            yearFilter: value,
                                                            year: value !== '' ? parseInt(value) : moment().year()
                                                        }, this.getAgenda);
                                                    }
                                                }}
                                                disabled={loadingAgenda}
                                                allowClear={true}
                                                meta={{}} />
                                        </CalendarPicker>
                                    </Col>
                                </Row>

                                <Row>
                                    <div style={{ marginTop: '2rem', position: 'relative' }}>
                                        {loadingAgenda ?
                                            <SpinLoading /> :
                                            <React.Fragment>
                                                {(reservesLoading || selectingWaitingDate || waitingDatesLoading || selectingInterestDate || interestDatesLoading)
                                                    && <div className='backdrop'>
                                                        <SpinLoading style={{ position: 'absolute', minHeight: 30 }} />
                                                    </div>}
                                                <Calendar
                                                    loading={loadingAgenda || reservesLoading || waitingDatesLoading || selectingWaitingDate || selectingInterestDate || interestDatesLoading}
                                                    localizer={localizer}
                                                    defaultDate={moment().set('year', year)}
                                                    events={dataSource}
                                                    wedding={wedding}
                                                    waitingDates={waitingDates}
                                                    interestDates={interestDates}
                                                    reserveView={agendaType === AgendaAttendingType.AGENDA}
                                                    agendaType={agendaType}
                                                    toolbar={false}
                                                    defaultView='year'
                                                    views={{
                                                        // day: true,
                                                        // week: true,
                                                        // month: true,
                                                        year: YearView
                                                    }}
                                                    messages={{ year: 'Year' }}
                                                    onDayClick={this.onDayClick}
                                                    onWaitingClick={this.onWaitingClick}
                                                    onMultipleWaitingClick={this.onMultipleWaitingClick}
                                                    onInterestClick={this.onInterestClick}
                                                />
                                                <Legend>
                                                    <LegendItem><b>F:</b> Feriado Português</LegendItem>
                                                    <LegendItem><b>FM:</b> Feriado Municipal Português</LegendItem>
                                                    <LegendItem><b>I:</b> Data Indisponível</LegendItem>
                                                    {wedding !== null && wedding !== undefined && agendaType === AgendaAttendingType.WAITING_DATE
                                                        && <LegendItem><b><WaitingIcon src={Bookmark} />:</b> Lista de Espera</LegendItem>}
                                                    {wedding !== null && wedding !== undefined && agendaType === AgendaAttendingType.INTEREST
                                                        && <LegendItem><b><WaitingIcon src={Star} />:</b> Datas de Interesse</LegendItem>}
                                                </Legend>
                                            </React.Fragment>
                                        }
                                    </div>
                                </Row>
                            </CalendarBox>
                        </Col>
                        <Col className='reserve-column'
                            xl={{ span: 4, order: 2 }}
                            lg={{ span: 5, order: 2 }}
                            md={{ span: 24, order: 1 }}
                            sm={{ span: 24, order: 1 }}
                            xs={{ span: 24, order: 1 }}>
                            <ReserveContent>
                                {wedding && reservePhase && <React.Fragment>
                                    <Row gutter={[12, 12]} className='reserve-title'>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <ReserveTitle>Selecione a agenda que pretende</ReserveTitle>
                                        </Col>
                                    </Row>
                                    <Row gutter={[12, 12]} className='reserve-title'>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Radio.Group
                                                onChange={(e) => this.setState({ agendaType: e?.target.value }, () => this.props.history.push('/' + e?.target.value + this.props.location.search))}
                                                value={agendaType}
                                                disabled={loadingAgenda}
                                                style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                {reservePhase && <Radio value={AgendaAttendingType.AGENDA}>Reservas</Radio>}
                                                {reservePhase && <Radio value={AgendaAttendingType.INTEREST}>Reservas + Datas de Interesse</Radio>}
                                                {wedding && <Radio value={AgendaAttendingType.WAITING_DATE}>Reservas + Lista de Espera</Radio>}
                                            </Radio.Group>
                                        </Col>
                                    </Row>
                                </React.Fragment>}

                                {agendaType === AgendaAttendingType.AGENDA && <React.Fragment>
                                    <Row gutter={[12, 12]} className='reserve-title'>
                                        <ReserveTitle>Lista de Reservas</ReserveTitle>
                                    </Row>

                                    <Row gutter={[12, 12]}>
                                        {locals.map((local, index) => (
                                            <Col key={index} xs={24} sm={12} md={12} lg={24} xl={24}>
                                                <ReserveSubtitle>{local.name}</ReserveSubtitle>
                                                {reservesLoading && <SpinLoading style={{ minHeight: 30 }} />}
                                                {reserveList.filter(f => f.weddingPlace === local._id).length === 0 && <p>Sem reservas</p>}
                                                {reserveList.filter(f => f.weddingPlace === local._id).length > 0 &&
                                                    <ReserveList>
                                                        {reserveList.filter(f => f.weddingPlace === local._id)
                                                            .map(m => ({ ...m, date: m?.date && moment.utc(m.date).isValid() ? moment.utc(m.date).toISOString() : m.date }))
                                                            .sort((a, b) => a.date && b.date && a.date > b.date ? 1 : -1)
                                                            .map((reserve, indexR) => (
                                                                <ReserveItem key={indexR} edit={editReserve && editReserve?.weddingPlace && reserve?.weddingPlace && reserve.weddingPlace === editReserve.weddingPlace && moment.utc(reserve.date).isSame(editReserve.date, 'day')}>
                                                                    <div>
                                                                        <p style={{ marginBottom: 0 }}>
                                                                            <b>Data: </b>
                                                                            {reserve?.date ? moment.utc(reserve.date).format('DD/MM/YYYY') : ''}
                                                                        </p>
                                                                    </div>
                                                                    {/* Options on reserveList will only appear if wedding.reservesBlocked is false */}
                                                                    {/* {!wedding.reservesBlocked && <div> */}
                                                                    <div>
                                                                        {(reserve?.event?.resource?.weddingReserve?.sentWaitingNotification === undefined ||
                                                                            (reserve?.event?.resource?.weddingReserve?.sentWaitingNotification !== undefined &&
                                                                                reserve?.event?.resource?.weddingReserve?.sentWaitingNotification === false)) && <React.Fragment>
                                                                                {(reserve && reserve?.event?.resource?.weddingReserve
                                                                                    && reserve?.event?.resource?.weddingReserve?.status === ReserveStatus.RSC
                                                                                    && editReserve && editReserve?.weddingPlace && reserve?.weddingPlace && editReserve?.weddingPlace === reserve.weddingPlace
                                                                                    && editReserve?.date && reserve?.date && moment.utc(reserve?.date).isSame(editReserve.date, 'day'))
                                                                                    || (editReserve && editReserve?.weddingPlace && reserve?.weddingPlace && editReserve?.weddingPlace === reserve.weddingPlace
                                                                                        && editReserve?.date && reserve?.date && moment.utc(reserve?.date).isSame(editReserve.date, 'day'))
                                                                                    ? <Tooltip title='Cancelar Edição'>
                                                                                        <Icon type='close-circle' onClick={() => this.cancelEditDate(reserve)}></Icon>
                                                                                    </Tooltip>
                                                                                    : <Tooltip title='Editar'>
                                                                                        <Icon type='edit' onClick={() => this.editDate(reserve)}></Icon>
                                                                                    </Tooltip>
                                                                                }
                                                                            </React.Fragment>}
                                                                        {(reserve && reserve?.event && reserve?.event?.resource
                                                                            && reserve?.event?.resource?.weddingReserve
                                                                            && reserve?.event?.resource?.weddingReserve?.status !== ReserveStatus.RSC)
                                                                            ? null
                                                                            : <Popconfirm
                                                                                placement="topRight"
                                                                                title={removeMessage}
                                                                                okText='Eliminar'
                                                                                onConfirm={() => this.removeDate(reserve)}>
                                                                                <Icon type="delete" />
                                                                            </Popconfirm>
                                                                        }
                                                                    </div>
                                                                    {/* </div>} */}
                                                                </ReserveItem>
                                                            ))
                                                        }
                                                    </ReserveList>
                                                }
                                            </Col>
                                        ))}
                                    </Row>
                                </React.Fragment>}

                                {agendaType === AgendaAttendingType.WAITING_DATE && <React.Fragment>
                                    <Row gutter={[12, 12]} className='reserve-title'>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <ReserveTitle>Lista de Espera</ReserveTitle>
                                        </Col>
                                    </Row>

                                    {(savingWeddingWaiting || loading || loadingAgenda)
                                        ? <SpinLoading style={{ minHeight: 30 }} />
                                        : <Row gutter={[12, 12]}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <ReserveSubtitle>Pretendo ser notificado até</ReserveSubtitle>
                                                <Radio.Group
                                                    onChange={(e) => this.setState({ untilPeriodNotificationWaitingDate: e?.target?.value || 2 })}
                                                    value={untilPeriodNotificationWaitingDate}
                                                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                    <Radio value={12}>12 meses antes</Radio>
                                                    <Radio value={6}>6 meses antes</Radio>
                                                    <Radio value={2}>2 meses antes</Radio>
                                                </Radio.Group>
                                            </Col>
                                            {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Checkbox
                                                className='checkbox-waiting-policy'
                                                checked={acceptedWaitingPolicy}
                                                disabled={wedding?.acceptedWaitingPolicy}
                                                onChange={(e) => this.setState({ acceptedWaitingPolicy: e?.target?.checked })}>
                                                <LinkAcceptPolicy onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({ showPolicyModal: true })
                                                }}>Aceito os termos e condições da lista de espera</LinkAcceptPolicy>
                                            </Checkbox>
                                        </Col> */}
                                        </Row>
                                    }
                                </React.Fragment>}
                            </ReserveContent>
                        </Col>
                    </Row>

                    {showReserveModal && <ReserveInfoModal
                        isOpen={showReserveModal}
                        onClose={() => this.setState({ loadingModal: false, showReserveModal: false, valuesReserveModal: {} })}
                        onSubmit={this.finishReserve}
                        initialValues={valuesReserveModal}
                        reserves={reserveList}
                        loading={loadingModal}
                    />}

                    {showDateModal && <ReserveDateWarningModal
                        openModal={showDateModal}
                        closeModal={() => this.setState({ showDateModal: false, dataDateModal: null })}
                        onSubmit={(value) => this.editReserveDate(value)}
                        // loading={loadingButton}
                        initialValues={dataDateModal}
                    />}
                </PageContainer>
            </React.Fragment>
        );
    }
}

export default ReservesAttending;