import {
  SAVE_WEDDING,
  CLEAR_WEDDING,
  SAVE_ABOUT,
  SAVE_NOTES,
  UPDATE_QUESTION,
  SAVE_GRAPHIC_MENU,
  ADD_WEDDING_DOCUMENT,
  DELETE_WEDDING_DOCUMENT,
  EDIT_UPGRADE,
  SAVE_PRINTED,
  SAVE_PRIVATE_NOTES
} from '../ActionsType';

export const AddWedding = wedding => {
  return {
    type: SAVE_WEDDING,
    wedding
  };
};

export const ClearWedding = () => {
  return {
    type: CLEAR_WEDDING
  };
};

export const ChangeAboutUs = about => {
  return {
    type: SAVE_ABOUT,
    about
  };
};

export const SaveNotes = note => {
  return {
    type: SAVE_NOTES,
    note
  };
};

export const updateQuestionAction = (section, data) => {
  return {
    type: UPDATE_QUESTION,
    section,
    data
  };
};

export const SaveGraphicMenu = menu => {
  return {
    type: SAVE_GRAPHIC_MENU,
    menu
  };
};

export const addDocument = file => {
  return {
    type: ADD_WEDDING_DOCUMENT,
    file
  };
};

export const deleteDocument = file => {
  return {
    type: DELETE_WEDDING_DOCUMENT,
    file
  };
};

export const ChangeWeddingUpgrade = id => {
  return {
    type: EDIT_UPGRADE,
    id
  };
};

export const SaveWeddingPrinted = printed => {
  return {
    type: SAVE_PRINTED,
    printed
  };
};

export const SavePrivateNotes = notes => {
  return {
    type: SAVE_PRIVATE_NOTES,
    notes
  };
};