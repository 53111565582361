import React from 'react';
import { Avatar, Dropdown, Icon, Menu } from 'antd';
import { withRouter } from 'react-router-dom';
import {
  StyledHeader,
  HeaderSectionTitle,
  HeaderSectionToggle,
  HeaderSectionButtons
} from './LayoutStyles';
import { getCurrentUser } from '../../infra/helpers/Helpers';
import { getInitials } from '../../infra/services/wedding/weddingUtils';

const HeaderComponent = ({ history, location }) => {
  const localUser = JSON.parse(localStorage.user);

  const handleMenuClick = e => {
    if (e.key == 1) {
      return history.push('/visits');
    } else if (e?.key == 2) {
      return history.push('/meetings');
    } else if (e?.key == 3) {
      return history.push('/management');
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={1}>
        <Icon type="calendar" />
        Visitas
      </Menu.Item>
      <Menu.Item key={2}>
        <Icon type="calendar" />
        Reuniões
      </Menu.Item>
      <Menu.Item key={3}>
        <Icon type="global" />
        Manage websites
      </Menu.Item>
    </Menu>
  );

  if (location.pathname.indexOf('/management') === 0) {
    return (
      <StyledHeader>
        <HeaderSectionTitle>
          Solar da levada & Quinta Lago dos Cisnes
          <HeaderSectionButtons>
            <HeaderSectionToggle onClick={() => history.push('/')}>
              <Icon type="team" />
              Go To Wedding Day
            </HeaderSectionToggle>
          </HeaderSectionButtons>
        </HeaderSectionTitle>
      </StyledHeader>
    );
  }

  return (
    <StyledHeader>
      <HeaderSectionTitle>
        Wedding Day
        {(localUser?.admin || localUser?.organizer)
          && <HeaderSectionButtons>
            <Avatar style={{ backgroundColor: 'white', color: 'black', border: '1px solid black', verticalAlign: 'middle' }}>
              {getInitials(getCurrentUser()?.name || '')}
            </Avatar>
            <HeaderSectionToggle onClick={() => history.push('/visits')} className='desktop'>
              <Icon type="calendar" />
              Visitas
            </HeaderSectionToggle>
            <HeaderSectionToggle onClick={() => history.push('/meetings')} className='desktop'>
              <Icon type="calendar" />
              Reuniões
            </HeaderSectionToggle>
            <HeaderSectionToggle onClick={() => history.push('/management')} className='desktop'>
              <Icon type="global" />
              Manage websites
            </HeaderSectionToggle>
            <Dropdown overlay={menu} className='mobile'>
              <HeaderSectionToggle default>
                Opções
                <Icon type="down" />
              </HeaderSectionToggle>
            </Dropdown>
          </HeaderSectionButtons>}
      </HeaderSectionTitle>
    </StyledHeader>
  );
};

export default withRouter(HeaderComponent);
