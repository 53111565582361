import React from 'react';
import { FieldArray } from 'redux-form';
import {
  WeddingDetailTitle,
  ColorResponse,
  ColorPill,
  ImageBox,
  ColorBox,
  ColorName,
  OrderTable
} from '../OrderStyles';
import {
  OptionsContainer,
  OptionContent,
  OptionLine,
  OptionObs,
  OptionColumn,
  OptionQuantity,
  OptionType,
  EmptyResults,
  NotesContainer,
  NotesColumn,
  NotesContent,
  NotesTitle,
  NotesText
} from './Styles';
import TextInput from '../../../components/inputs/TextInput';
import OrderFlowersList from './OrderFlowersList';
import { Col, Row } from 'antd';

const WeddingFlowersDetail = ({ index, wedding, flowersColors }) => {
  return (
    <React.Fragment>
      {
        wedding.decorationNotes &&
        <NotesContainer>
          {
            wedding.decorationNotes.notes &&
            <NotesColumn>
              <NotesContent>
                <NotesTitle><span>Decoração:</span> Notas Públicas</NotesTitle>
                <NotesText dangerouslySetInnerHTML={{ __html: wedding.decorationNotes.notes }} />
              </NotesContent>
            </NotesColumn>
          }
          {
            wedding.decorationNotes.private &&
            <NotesColumn>
              <NotesContent>
                <NotesTitle><span>Decoração:</span> Notas Privadas</NotesTitle>
                <NotesText dangerouslySetInnerHTML={{ __html: wedding.decorationNotes.private }} />
              </NotesContent>
            </NotesColumn>
          }
        </NotesContainer>
      }
      {
        wedding.ceremonyNotes &&
        <NotesContainer>
          {
            wedding.ceremonyNotes.notes &&
            <NotesColumn>
              <NotesContent>
                <NotesTitle><span>Cerimónia:</span> Notas Públicas</NotesTitle>
                <NotesText dangerouslySetInnerHTML={{ __html: wedding.ceremonyNotes.notes }} />
              </NotesContent>
            </NotesColumn>
          }
          {
            wedding.ceremonyNotes.private &&
            <NotesColumn>
              <NotesContent>
                <NotesTitle><span>Cerimónia:</span> Notas Privadas</NotesTitle>
                <NotesText dangerouslySetInnerHTML={{ __html: wedding.ceremonyNotes.private }} />
              </NotesContent>
            </NotesColumn>
          }
        </NotesContainer>
      }
      {
        // wedding.flowersObs?.observations &&
       Array.isArray(wedding?.flowersObs) && wedding?.flowersObs.filter(f => f?.observations)?.length > 0 &&
        <Row gutter={[12, 12]} style={{margin: '0 -6px'}}>
          {wedding?.flowersObs
            .filter(f => f?.observations)
            .map(m => <Col xs={12}>
              <NotesContent>
                {/* <NotesTitle><span>Flores:</span> Observações</NotesTitle> */}
                <NotesTitle><span>{m?.question?.title?.pt}:</span> Observações</NotesTitle>
                <NotesText dangerouslySetInnerHTML={{ __html: m.observations }} />
              </NotesContent>
            </Col>)}
        </Row>
      }
      <WeddingDetailTitle>Paleta de Cores</WeddingDetailTitle>
      {
        wedding?.wedding_palette?.length > 0 ?
          wedding.wedding_palette.map((color, index) =>
            <ColorResponse key={index}>
              <ColorPill transparent>
                {color.image ? (
                  <ImageBox src={color.image.url} />
                ) : (
                  <ColorBox color={color.hex} />
                )}
                <ColorName>{color.name.pt}</ColorName>
              </ColorPill>
            </ColorResponse>
          )
          :
          <EmptyResults>Não existem resultados a apresentar!</EmptyResults>
      }
      <OrderTable>
        <WeddingDetailTitle>Opções</WeddingDetailTitle>
        {
          wedding?.flowers_options?.length > 0 ?
            <OptionsContainer>
              {
                wedding.flowers_options.map((option, index) =>
                  <OptionContent key={index}>
                    <OptionLine>
                      <OptionColumn maxWidth={150} noBorderTopRight={true} noBorderBottomRight={true}>
                        <TextInput
                          meta={{}}
                          input={{ value: option.section }}
                          type='text'
                          disabled={true}
                          noLabel={true}
                        />
                      </OptionColumn>
                      <OptionColumn maxWidth={250} noBorderRadius={true}>
                        <TextInput
                          meta={{}}
                          input={{ value: option.chapter }}
                          type='text'
                          disabled={true}
                          noLabel={true}
                          tooltip={option.chapter.length > 45 ? true : false}
                          tooltipMsg={option.chapter}
                        />
                      </OptionColumn>
                      <OptionColumn maxWidth={250} noBorderRadius={true}>
                        <TextInput
                          meta={{}}
                          input={{ value: option.question }}
                          type='text'
                          disabled={true}
                          noLabel={true}
                          tooltip={option.question.length > 45 ? true : false}
                          tooltipMsg={option.question}
                        />
                      </OptionColumn>
                      <OptionColumn maxWidth={250} noBorderRadius={true}>
                        <TextInput
                          meta={{}}
                          input={{ value: option.name }}
                          type='text'
                          disabled={true}
                          noLabel={true}
                        />
                      </OptionColumn>
                      <OptionQuantity>
                        <TextInput
                          meta={{}}
                          input={{ value: option.quantity }}
                          type='text'
                          disabled={true}
                          noLabel={true}
                        />
                      </OptionQuantity>
                      <OptionType>
                        <TextInput
                          meta={{}}
                          input={{ value: option.type }}
                          type='text'
                          disabled={true}
                          noLabel={true}
                        />
                      </OptionType>
                    </OptionLine>
                    {
                      option.observations &&
                      <OptionObs><span>Observações:</span>&nbsp;{option.observations}</OptionObs>
                    }
                  </OptionContent>
                )
              }
            </OptionsContainer>
            :
            <EmptyResults>Não existem resultados a apresentar!</EmptyResults>
        }
      </OrderTable>
      {
        flowersColors?.length > 0 &&
        <OrderTable>
          <WeddingDetailTitle>Flores</WeddingDetailTitle>
          <FieldArray
            component={OrderFlowersList}
            name={`weddings[${index}].flowers`}
          />
        </OrderTable>
      }
    </React.Fragment>
  );
};

export default WeddingFlowersDetail;