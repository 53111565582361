import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Icon, Row, Col, Steps, TimePicker, Checkbox } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import FormValidator from '../../infra/services/validations/FormValidator';
import { BaseForm, SpinLoading } from '../../styles/BasicStyles';
import PropTypes from 'prop-types';
import TextInput from '../../components/inputs/TextInput';
import TagInput from '../../components/inputs/TagInput';
import FileInput from './FileInput';
import SelectInput from '../../components/inputs/SelectInput';
import SvgInput from './SvgInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import { InputLabelDiv } from '../../components/inputs/InputStyles';
import styled from 'styled-components';

const Error = styled.div`
    color: ${p => p.theme.inputErrorColor};
`;

const PlatformTypes = {
    RESERVE: 'R',
    COUPLE: 'C',
    BOTH: 'B'
};

const validations = (values) => {
    let errors = {};

    errors = FormValidator.make({
        name: 'required',
        tag: 'required',
        icon: 'required',
        iconCouple: 'required',
        platform: 'required',
    })(values);

    return errors;
};

let ContentCategoryModal = ({
    loading,
    isOpen,
    handleSubmit,
    initialValues,
    onSubmit,
    onClose,
    onDelete,
    categoryForm
}) => {
    return (
        <Modal
            visible={isOpen}
            title={initialValues?._id ? 'Editar Categoria' : 'Criar Categoria'}
            maskClosable={false}
            onCancel={onClose}
            footer={<React.Fragment>
                <Button
                    key='cancel'
                    type='default'
                    onClick={onClose}>
                    Cancelar
                </Button>
                <Button
                    key='submit'
                    type='primary'
                    loading={loading}
                    onClick={handleSubmit(onSubmit)}>
                    Gravar
                </Button>
            </React.Fragment>}>

            <BaseForm onSubmit={handleSubmit(onSubmit)}>
                <Row gutter={[12, 12]}>
                    <Col style={{ display: 'none' }} xs={24} md={24} lg={24} xl={24}>
                        <Field
                            component={TextInput}
                            label='Id *'
                            name={`_id`}
                            type="text"
                        />
                    </Col>
                    <Col xs={24} md={24} lg={24} xl={24}>
                        <Field
                            component={TextInput}
                            label='Nome *'
                            name={`name`}
                            type="text"
                        />
                    </Col>
                    <Col xs={24} md={24} lg={24} xl={24}>
                        <Field
                            component={TagInput}
                            label='Tag de Tradução *'
                            name={`tag`}
                            type="text"
                            disabled={initialValues?._id ? true : false}
                        />
                    </Col>
                    <Col xs={24} md={24} lg={24} xl={24}>
                        <Field
                            component={SelectInput}
                            data={[
                                { _id: PlatformTypes.COUPLE, name: 'Noivos' },
                                { _id: PlatformTypes.RESERVE, name: 'Reservas Não Confirmadas' },
                                { _id: PlatformTypes.BOTH, name: 'Ambas' }
                            ]}
                            label='Plataforma *'
                            name={`platform`}
                            placeholder={'Selecione a plataforma'}
                        />
                    </Col>
                    <Col xs={24} md={24} lg={24} xl={24}>
                        <InputLabelDiv>Ícone Backoffice *</InputLabelDiv>
                        <Field
                            component={SvgInput}
                            label=''
                            name={`icon`}
                        />
                        {/* {!categoryForm?.icon && <Error>Insira um ícone.</Error>} */}
                    </Col>
                    <Col xs={24} md={24} lg={24} xl={24}>
                        <InputLabelDiv>Ícone Noivos *</InputLabelDiv>
                        <Field
                            component={SvgInput}
                            name={`iconCouple`}
                        />
                    </Col>
                </Row>
            </BaseForm>
        </Modal>
    );
};

ContentCategoryModal = reduxForm({
    form: 'category_form',
    enableReinitialize: true,
    validate: validations,
})(ContentCategoryModal);

const selector = formValueSelector('category_form');

const mapStateToProps = state => ({
    categoryForm: {
        _id: selector(state, '_id'),
        recursive: selector(state, 'recursive'),
        type: selector(state, 'type'),
        availableTime: selector(state, 'availableTime'),
        startDate: selector(state, 'startDate'),
        allDay: selector(state, 'allDay'),
        startTime: selector(state, 'startTime'),
        endTime: selector(state, 'endTime'),
        weddingPlace: selector(state, 'weddingPlace'),
        employee: selector(state, 'employee'),
    }
});

ContentCategoryModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(ContentCategoryModal)