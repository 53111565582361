import React from 'react';
import EmptyIcon from '../../assets/icons/ic_empty_answer.svg';
import {
  EmptySection,
  SectionIcon,
  StaffBox,
  StaffItem,
  OptionButton
} from './ExternalStaffStyles';
import {Icon, Popconfirm} from 'antd';

const RemoveMessage = (
  <div>
    <div>Tem a certeza que quer remover este staff externo? </div>
  </div>
);

const RemovePrintedMessage = (
  <div>
    <div>Tem a certeza que quer remover este staff externo? </div>
    <b>Este processo de casamento já foi impresso!</b>
  </div>
);

const ExternalStaff = ({ staff = [], editStaff, deleteStaff, printed }) => {
  if (staff.length < 1)
    return (
      <EmptySection id="externo">
        <SectionIcon src={EmptyIcon} alt="empty" />
        Ainda não foi adicionado staff externo
      </EmptySection>
    );
  return staff.map((record, index) => (
    <StaffBox key={index} id="externo">
      <Popconfirm
        title={printed ? RemovePrintedMessage : RemoveMessage}
        onConfirm={() => deleteStaff(index)}>
        <OptionButton number={0}>
          <Icon type="delete" />
        </OptionButton>
      </Popconfirm>
      <OptionButton number={1} onClick={() => editStaff(record, index)}>
        <Icon type="edit" />
      </OptionButton>
      <StaffItem>
        Categoria:<span>{record.category}</span>
      </StaffItem>
      <StaffItem>
        Nome:<span>{record.name}</span>
      </StaffItem>
      <StaffItem>
        Email:<span>{record.email}</span>
      </StaffItem>
      {record.contact && (
        <StaffItem>
          Contacto:<span>{record.contact}</span>
        </StaffItem>
      )}
      {record.observations && (
        <StaffItem>
          Observações:<span>{record.observations}</span>
        </StaffItem>
      )}
    </StaffBox>
  ));
};

export default ExternalStaff;
