import styled from "styled-components";

export const ImageHover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    padding: 10px;
    margin: 0 20px;
    font-size: 30px;
    cursor: pointer;
  }

  &:hover {
    opacity: 1;
  }
`;
