import FoodCategoriesPage from '../../weddingday/food/FoodCategoriesPage';
import FoodPage from '../../weddingday/food/FoodPage';
import ManageFoodPage from '../../weddingday/food/ManageFoodPage';
import FoodInternalPage from '../../weddingday/food/FoodInternalPage';
import { PermissionsEnum } from '../../infra/helpers/Permissions';

export default [
  {
    path: '/food',
    component: FoodCategoriesPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_MNG_FOOD],
  },
  {
    path: '/food/internal',
    component: FoodInternalPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
  },
  {
    path: '/food/:category',
    component: FoodPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.FOOD_CATEGORY],
  },
  {
    path: '/food/:category/add',
    component: ManageFoodPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.CREATE_FOOD_CATEGORY],
  },
  {
    path: '/food/:category/:id',
    component: ManageFoodPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.EDIT_FOOD_CATEGORY],
  }
];
