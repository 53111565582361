import React, { Component, Fragment } from 'react';
import Table from '../../components/table/Table';
import {
    Row,
    Col,
    Popconfirm,
    Icon,
    Checkbox,
} from 'antd';
import {
    HeaderButtonsContainer,
    TableButton,
    SectionTitle,
    TableFilterSection,
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import { withLocalize } from 'react-localize-redux';
import Alert from "../../components/alert/Alert";
import TextInput from '../../components/inputs/TextInput';
import {
    GetSettingsByType,
    UpdateSetting,
    DeleteSetting,
    CreateSetting
} from '../../infra/requests/SettingRequests';
import { StateLabel } from '../weddings/Styles';

const RemoveMessage = (
    <div>
        <div>Tem a certeza que quer remover esta unidade? </div>
        <div>
            Toda a informação da unidade será removida da plataforma e não
            conseguirá ser recuperada.
        </div>
    </div>
);


class PriceUnitTable extends Component {
    state = {
        pageSize: 15,
        currentPage: 1,
        search: '',
        loading: false,
        editUnit: null,
        columns: [
            {
                title: 'Unidade',
                key: 'name',
                width: '200px',
                render: data => (
                    <Fragment>
                        {!this.isEditing(data) ? (<div>{data.name}</div>) :
                            (<div style={{ marginTop: '-30px' }}>
                                <TextInput
                                    input={{
                                        value: this.state.editUnit.name,
                                        onChange: event => {
                                            let x = this.state.editUnit;
                                            x.name = event.target.value;
                                            this.setState({ editUnit: x })
                                        }
                                    }}
                                    meta={{}}
                                    type="text"
                                    placeholder="Insira o nome da unidade"
                                />
                            </div>)
                        }
                    </Fragment>
                )
            },
            {
                title: 'Valor Inteiro',
                key: 'integerValue',
                width: '200px',
                render: data => (
                    <Fragment>
                        {!this.isEditing(data) ? (<StateLabel printed={data.integerValue}>{data.integerValue ? 'Sim' : 'Não'}</StateLabel>) :
                            (<div className="sc-dxgOiQ fWHHkk">
                                <Checkbox
                                    checked={this.state.editUnit.integerValue}
                                    onChange={(e) => {
                                        let x = this.state.editUnit;
                                        x.integerValue = e.target.checked;
                                        this.setState({ editUnit: x })
                                    }}>
                                    Sim
                                </Checkbox>
                            </div>)
                        }
                    </Fragment>
                )
            },
            {
                title: 'Acções',
                width: '120px',
                render: data => (
                    <Fragment>
                        {!this.isEditing(data) ? (<TableButton onClick={e => this.setEditForm(data)}>
                            <Icon type="edit" />
                            {' Editar'}
                        </TableButton>) : (<TableButton onClick={e => this.addEditUnit()}>
                            <Icon type="save" />
                            {' Guardar'}
                        </TableButton>)
                        }

                        {!this.isEditing(data) ? (data?.canBeDeleted && <TableButton onClick={e => e.stopPropagation()}>
                            <Popconfirm
                                placement="topRight"
                                title={RemoveMessage}
                                okText="Remover"
                                onConfirm={async () => { await this.deleteUnit(data.id) }}>
                                <Icon type="delete" />
                                {' Remover'}
                            </Popconfirm>
                        </TableButton>) : (<TableButton onClick={e => {
                            if (this.state.editUnit.id === 'FFFFF') {
                                let rows = this.state.rows.filter(f => f.id !== 'FFFFF');
                                this.setState({ editUnit: null, rows: rows });
                            } else this.setState({ editUnit: null });

                        }}>
                            <Icon type="close" />
                            {' Cancelar'}
                        </TableButton>)
                        }
                    </Fragment>
                )
            }
        ],
        rows: [],
        total: 0
    };

    componentDidMount = async () => {
        await this.updateDataTable();
    };

    isEditing = (unit) => {
        const { editUnit } = this.state;
        return editUnit ? editUnit.id === unit.id : false;
    }

    /**
     * @description Update data on datatable
     */
    updateDataTable = async () => {
        this.setState({ loading: true });
        try {
            const result = await GetSettingsByType(4);

            this.setState({
                rows: result?.data,
                total: result?.data.length,
                loading: false
            });
        } catch (e) {
            this.setState({ loading: false });
            console.error(e);
        }
    }


    /**
     * @description Show editing row with unit values
     * @param {*} unit 
     */
    setEditForm = (unit) => {
        let x = unit ? JSON.parse(JSON.stringify(unit)) : {
            name: ''
        };
        this.setState({ editUnit: x });
    }

    /**
     * @description Show add row
     */
    setAddForm = () => {
        const editUnit = this.state.editUnit;
        const rows = this.state.rows;
        const unit = {
            id: 'FFFFF',
            name: '',
            entity: false,
            paymentMethod: false,
            invoicingType: false,
            priceUnits: true,
            canBeDeleted: false
        };
        if (!editUnit) {
            rows.push(unit);
            this.setState({ rows: rows, editUnit: unit });
        }
    }

    /**
     * @description Create/Update Unit Price
     * @returns datatable unit updated
     */
    addEditUnit = async () => {
        const unit = this.state.editUnit;

        if (unit?.name?.trim() === '') {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Deve inserir o nome da unidade'
            });
        } else if (unit?.id === 'FFFFF') {
            unit.id = null;
            await CreateSetting(unit);
            this.setState({ editUnit: null });
            return this.updateDataTable();
        } else {
            await UpdateSetting(unit);
            this.setState({ editUnit: null });
            return this.updateDataTable();
        }
    }

    /**
     * @description Delete unit
     * @param {*} id - unit id
     * @returns datatable unit updated
     */
    deleteUnit = async id => {
        await DeleteSetting(id);
        return this.updateDataTable();
    }

    handleChangePage = currentPage => {
        this.setState({ currentPage }, this.updateDataTable);
    };

    handleChangeRowsPerPage = (currentSize, pageSize) => {
        this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
    };

    render() {
        const { loading, currentPage, pageSize } = this.state;
        const { columns, rows, total, search } = this.state;

        return (
            <React.Fragment>
                <SectionTitle style={{ marginTop: '3rem' }} >Unidades do Preços</SectionTitle>

                <TableFilterSection>
                    <Row gutter={24}>
                        <Col lg={8} md={8} sm={12} xs={24}>
                            <div className='settings-search'>
                                <TextInput
                                    type="text"
                                    label="Pesquisa"
                                    placeholder="Pesquise por unidade"
                                    input={{
                                        value: search,
                                        onChange: event => this.setState({ search: event.target.value })
                                    }}
                                    meta={{}}
                                />
                            </div>
                        </Col>

                        <Col lg={16} md={16} sm={12} xs={24} className='setting-col-btn'>
                            <BaseButton
                                type={'primary'}
                                icon={'plus'}
                                text={'Adicionar Unidade'}
                                onClick={() => this.setAddForm()}
                            />
                        </Col>
                    </Row>
                </TableFilterSection>

                <div className='table-responsive'>
                    <Table
                        columns={columns}
                        // currentPage={currentPage}
                        // pageSize={pageSize}
                        // defaultPageSize={pageSize}
                        loading={loading}
                        rows={search.trim() === "" ? rows : rows.filter(f => f?.name.toLowerCase().includes(search.toLowerCase()))}
                        showHeader={true}
                        emptyIcon="setting"
                        emptyText={'Não existem unidades'}
                        total={search.trim() === "" ? total : rows.filter(f => f?.name.toLowerCase().includes(search.toLowerCase())).length}
                        rowKey={'_id'}
                        hasPagination={false}
                    // handleChangePage={this.handleChangePage}
                    // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default withLocalize(PriceUnitTable);