import React from 'react';
import {
  NotesSectionContainer,
  NoteContent,
  NoteTitle,
  Note
} from '../ProcessStyles';

const SectionNotes = ({title, note}) => {
  if(title && note)
    return (
      <NotesSectionContainer>
        <NoteContent>
          <NoteTitle>{title}</NoteTitle>
          <Note
            dangerouslySetInnerHTML={{
              __html: note || ''
            }}
          />
        </NoteContent>
      </NotesSectionContainer>
    );

  return null;
};

export default SectionNotes;
