import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Element } from 'react-scroll';
import { initialize } from 'redux-form';
import { connect } from 'react-redux';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import SectionResponse from '../ChoicesPage/SectionResponse';
import SectionModal from '../ChoicesPage/SectionModal';
import {
  SectionContentStyle,
  SectionTitle,
  Counting,
  QuestionTitle,
  QuestionTitleContainer,
  QuestionIcon,
  ResponseSection
} from '../ChoicesPage/SectionStyles';
import GuestsForm from './GuestsForm';
import { getAnswerPrintedConfirmation } from '../../infra/services/wedding/printedUtils';
import { Alert, Modal } from 'antd';
import BaseButton from '../../components/buttons/BaseButton';
import Alerts from '../../components/alert/Alert';
import { inRoomPlanTest } from '../../infra/helpers/Helpers';

const { confirm } = Modal;

const GuestsContent = ({
  dispatch,
  wedding,
  section = { chapters: [] },
  submitGuests,
  submitPlan,
  loading,
  location: { hash },
  history,
  printed,
  guestsInfo,
  notesOpen
}) => {
  const [showModal, toggleModal] = useState(false);
  const [editQuestion, setEditQuestion] = useState(undefined);

  useEffect(() => {
    //If there's a ID, we have to scroll to that question
    if (hash) {
      const questionID = hash.substr(1);

      if (questionID) {
        const questionTop = document.getElementById(questionID) ? document.getElementById(questionID).scrollIntoView(true) : 0;
        window.scrollTo({ top: questionTop, behavior: 'smooth' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModal = question => {
    dispatch(initialize('manage_answers_form', {}));

    if (question?.answer) {
      dispatch(
        initialize('manage_answers_form', {
          ...question?.answer,
          question_obs: false
        })
      );
    }
    setEditQuestion(question);
    toggleModal(true);
  };

  const closeModal = () => {
    toggleModal(false);
    setEditQuestion(undefined);
  };

  const onModalSubmit = async values => {
    if (printed) {
      confirm({
        ...getAnswerPrintedConfirmation(),
        onOk: () => { saveModal(values); },
        onCancel: () => { closeModal(); },
      });
    } else saveModal(values);
  }

  const saveModal = async values => {
    if (values._id === '5e2adf9947f38787fd9e8427') {
      await submitGuests({ provisory_guests: values.response });
    }
    if (values._id === '5e2adfbf47f38787fd9e8428') {
      await submitGuests({ guests_location: values.response });
    }
    if (values._id === '5e2ae0b247f38787fd9e842b') {
      await submitPlan(values.response);
    }
    closeModal();
  };

  const chaptersToCheck = inRoomPlanTest() ? 4 : 2;
  return (
    <SectionContentStyle id="section-container" name="section-container" printed={printed} notesOpen={notesOpen}>
      {section.chapters.map((chapter, cIndex) => (
        <Element key={`anchor-${cIndex}`} name={`anchor-${cIndex}`}>
          <SectionTitle>
            {TranslateValue(chapter.name)}
            <Counting>
              {cIndex + 1} de {section.chapters.length}
            </Counting>
          </SectionTitle>
          {cIndex < chaptersToCheck 
            ? <React.Fragment>
              {chapter.questions.map((question, qIndex) => (
                <Element
                  key={`anchor-${cIndex}-${qIndex}`}
                  name={`anchor-${cIndex}-${qIndex}`}
                  id={question._id}
                >
                  <QuestionTitleContainer>
                    <QuestionTitle>
                      {TranslateValue(question.title)}
                    </QuestionTitle>
                    {(question._id === '5e2adf9947f38787fd9e8427' ||
                      question._id === '5e2adfbf47f38787fd9e8428' ||
                      question._id === '6579de7741f842ab6e10b56c' ||
                      question._id === '657a0f2e41f842ab6e10b571') && (
                        <QuestionIcon
                          type="edit"
                          title="Editar resposta"
                          onClick={() => openModal(question)}
                        />
                      )}
                  </QuestionTitleContainer>
                  <SectionResponse
                    upgrade={wedding.wedding.upgrade}
                    weddingPlace={wedding.wedding.wedding_place}
                    question={question}
                    printed={printed} />
                </Element>
              ))}
              {inRoomPlanTest() && (chapter?._id === '65afd1a7b42ba97e4a0db4b3' || chapter?._id === '65b37c49b42ba97e4a0db4c9')
                && <Element
                  key={chapter?._id === '65afd1a7b42ba97e4a0db4b3' ? `anchor-edit-roomPlan` : `anchor-edit-guestMap`}
                  name={chapter?._id === '65afd1a7b42ba97e4a0db4b3' ? `anchor-edit-roomPlan` : `anchor-edit-guestMap`}
                  id={chapter?._id === '65afd1a7b42ba97e4a0db4b3' ? `anchor-edit-roomPlan` : `anchor-edit-guestMap`}
                >
                  <ResponseSection>
                    <BaseButton
                      style={{ width: 'auto', minWidth: 150 }}
                      type={'default'}
                      icon={'edit'}
                      loading={loading}
                      text={'Gerir'}
                      onClick={() => {
                        if (chapter?._id === '65afd1a7b42ba97e4a0db4b3') {
                          history.push(`/wedding/${wedding?.wedding?._id}/roomPlan`);
                        } else {
                          history.push(`/wedding/${wedding?.wedding?._id}/guestMap`);
                        }
                      }} />
                  </ResponseSection>
                </Element>}
            </React.Fragment>
            : <GuestsForm
              submitGuests={submitGuests}
              questions={chapter.questions}
              loading={loading}
              guestsInfo={guestsInfo}
              printed={printed}
            />}
        </Element>
      ))}
      <SectionModal
        open={showModal}
        onSubmit={onModalSubmit}
        closeModal={closeModal}
        loading={loading}
        question={editQuestion}
      />
    </SectionContentStyle>
  );
};

const mapStateToProps = state => ({
  wedding: state.wedding
});

export default withRouter(connect(mapStateToProps)(GuestsContent));
