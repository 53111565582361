import {
  SAVE_WEDDING,
  CLEAR_WEDDING,
  SAVE_ABOUT,
  SAVE_NOTES,
  UPDATE_QUESTION,
  SAVE_GRAPHIC_MENU,
  ADD_WEDDING_DOCUMENT,
  DELETE_WEDDING_DOCUMENT,
  EDIT_UPGRADE,
  SAVE_PRINTED,
  SAVE_PRIVATE_NOTES
} from '../ActionsType';

const initialState = {
  wedding: {},
  answers: [],
  structure: [],
  notes: [],
  menu: {}
};

const findQuestion = (structure, tag, id) => {
  let found = false;
  let sectionIndex = 0;
  let ChapterIndex = 0;
  let QuestionIndex = 0;

  structure.forEach((section, sIndex) => {
    if (section.tag === tag) {
      sectionIndex = sIndex;
      section.chapters.forEach((chapter, cIndex) => {
        chapter.questions.forEach((question, qIndex) => {
          if (question._id === id) {
            found = true;
            ChapterIndex = cIndex;
            QuestionIndex = qIndex;
          }
        });
      });
    }
  });

  return {sectionIndex, ChapterIndex, QuestionIndex, found};
};

const updateQuestion = (state, tag, data) => {
  const result = {...state};
  const {sectionIndex, ChapterIndex, QuestionIndex, found} = findQuestion(
    result.structure,
    tag,
    data.question
  );

  if (found) {
    result.structure[sectionIndex].chapters[ChapterIndex].questions[
      QuestionIndex
    ].answer = data;
  }

  return result;
};

const SaveNotes = (notes = [], note) => {
  const result = [...notes];
  const index = notes.findIndex(n => n.section === note.section);
  if (index !== -1) {
    result[index] = note;
  } else {
    result.push(note);
  }
  return result;
};

const saveDocument = (wedding, file) => {
  const result = {...wedding};
  result.documents.unshift(file);
  return result;
};

const deleteDocument = (wedding, file) => {
  const result = {...wedding};
  const index = wedding.documents.findIndex(x => x._id === file);
  if (index >= 0) result.documents.splice(index, 1);
  return result;
};

const changeUpgrade = (state, id) => {
  const result = {...state};
  result.wedding.upgrade = id;
  return result;
}

const changePrinted = (state, printed) => {
  const result = {...state};
  result.wedding.printed = printed;
  return result;
}

const changePrivateNotes = (state, notes) => {
  const result = {...state};
  result.wedding.private_notes = notes;
  return result;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_WEDDING:
      return {...action.wedding};
    case SAVE_ABOUT:
      return {...state, wedding: action.about};
    case EDIT_UPGRADE:
      return changeUpgrade(state, action.id);
    case ADD_WEDDING_DOCUMENT:
      return {...state, wedding: saveDocument(state.wedding, action.file)};
    case DELETE_WEDDING_DOCUMENT:
      return {...state, wedding: deleteDocument(state.wedding, action.file)};
    case SAVE_NOTES:
      return {...state, notes: SaveNotes(state.notes, action.note)};
    case CLEAR_WEDDING:
      return initialState;
    case UPDATE_QUESTION:
      return updateQuestion(state, action.section, action.data);
    case SAVE_GRAPHIC_MENU:
      return {...state, menu: {text: action.menu}};
    case SAVE_PRINTED:
      return changePrinted(state, action.printed);
    case SAVE_PRIVATE_NOTES:
      return changePrivateNotes(state, action.notes);
    default:
      return state;
  }
};
