import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Icon, Row, Col, Steps } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import FormValidator from '../../infra/services/validations/FormValidator';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import SelectInput from '../../components/inputs/SelectInput';
import DraftInput from '../../components/inputs/DraftInput';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import TextInput from '../../components/inputs/TextInput';
import { PrevButton, StyledStep, StyledSteps, TableContent, Title } from '../reservesAttending/ReserveInfoModalStyles';
import { BaseForm, SpinLoading } from '../../styles/BasicStyles';
import PropTypes from 'prop-types';
import { CreateSetting, GetSettingsByType } from '../../infra/requests/SettingRequests';
import BaseButton from '../../components/buttons/BaseButton';
import { InputField, InputGroupField, InputLabelDiv } from '../../components/inputs/InputStyles';
import SelectCompactInput from '../../components/inputs/SelectCompactInput';
import { GetAttendingEmployees, GetOrganizerList } from '../../infra/requests/UsersRequests';
import { getOrganizers } from '../../infra/requests/AdminsRequests';

const validations = (values) => {
    let errors = {};

    errors = FormValidator.make({
        // date: 'required',
        // wedding_place: 'required',
        // price: 'required',
        // min_people: 'required|minNumber:140',
        // language: 'required',
        // dateSecondPaymentPhase: 'required'
    })(values);

    return errors;
};

let WeddingModal = ({
    isOpen,
    saving,
    handleSubmit,
    initialValues,
    onSubmit,
    onClose,
    onAddGraphic,
    weddingForm
}) => {
    const [loadingGraphic, toggleLoadingGraphic] = useState(false);
    const [graphics, setGraphics] = useState([]);
    const [showAddGraphicInput, setShowGraphicInput] = useState(false);
    const [graphicName, setGraphicName] = useState('');
    const [graphicSubmitted, submitGraphic] = useState(false);
    const [screenWidth, setScreenWidth] = useState('90%');
    const [loadingOrganizers, toggleLoadingOrganizers] = useState(false);
    const [organizers, setOrganizers] = useState([]);

    useEffect(() => {
        getGraphics();
        getOrganizersList();
        setScreenWidth(window.outerWidth);
    }, [isOpen]);

    useEffect(() => {
        setGraphicName('');
        submitGraphic(false);
    }, [showAddGraphicInput]);

    const getGraphics = async () => {
        toggleLoadingGraphic(true);
        const result = await GetSettingsByType(6);
        setGraphics(result?.success && result?.data ? result.data : []);
        toggleLoadingGraphic(false);
    }

    const addGraphic = async (value, form) => {
        toggleLoadingGraphic(true);

        const graphic = {
            name: value,
            entity: false,
            paymentMethod: false,
            invoicingType: false,
            priceUnits: false,
            graphics: true,
            graphicColor: '#fff',
            graphicOrder: graphics.length
        };

        const result = await CreateSetting(graphic);
        // console.warn('result', result);

        if (result?.success && result?.data) {
            await getGraphics();
            onAddGraphic({ ...weddingForm, graphics: result.data._id });
            setShowGraphicInput(false);
        } else toggleLoadingGraphic(false);
    }

    const getOrganizersList = async () => {
        toggleLoadingOrganizers(true);
        const result = await getOrganizers();
        setOrganizers(result?.success && result?.data ? result.data : []);
        toggleLoadingOrganizers(false);
    }

    return (
        <Modal
            visible={isOpen}
            title='Detalhes do Casamento'
            maskClosable
            // width={screenWidth >= 1024 ? 1000 : '90%'}
            onCancel={onClose}
            footer={[
                <Button
                    key='cancel'
                    type='default'
                    onClick={onClose}>
                    Cancelar
                </Button>,
                <Button
                    key='submit'
                    type='primary'
                    onClick={handleSubmit(onSubmit)}>
                    Gravar
                </Button>
            ]}>

            {loadingOrganizers
                ? <SpinLoading />
                : <BaseForm onSubmit={handleSubmit(onSubmit)}>
                    <Field
                        component={DraftInput}
                        label='Notas de pagamentos'
                        name={`paymentNotes`}
                        placeholder={'Indique as notas'}
                        minRows={10}
                        toolbarOnFocus={false}
                        toolbar='notes'
                    />
                    <Field
                        component={SelectInput}
                        mode="multiple"
                        name={'organizer'}
                        label={'Organizador'}
                        placeholder={'Selecione o organizador'}
                        type="select"
                        data={organizers || []}
                    />
                    <Field
                        component={DraftInput}
                        label='Notas das reservas'
                        name={`reserveNotes`}
                        placeholder={'Indique as notas'}
                        minRows={10}
                        toolbarOnFocus={false}
                        toolbar='notes'
                    />
                    <Field
                        component={DraftInput}
                        label='Provas'
                        name={`proofs`}
                        placeholder={'Indique as provas'}
                        minRows={10}
                        toolbarOnFocus={false}
                        toolbar='notes'
                    />
                    <Field
                        component={DraftInput}
                        label='Reuniões com o organizador'
                        name={`meetings`}
                        placeholder={'Introduza aqui os horários e locais das reuniões'}
                        minRows={10}
                        toolbarOnFocus={false}
                        toolbar='notes'
                    />

                    <Field
                        component={SelectCompactInput}
                        data={graphics}
                        label='Grafismo'
                        name={`graphics`}
                        placeholder={'Selecione o grafismo'}
                        allowClear={true}
                        btnType='primary'
                        icon={'plus'}
                        onClick={() => setShowGraphicInput(true)}
                    />

                    {showAddGraphicInput && <div style={{ marginTop: 10 }}>
                        <InputLabelDiv>Nome do grafismo</InputLabelDiv>
                        <InputGroupField compact>
                            <InputField
                                type="text"
                                placeholder={'Introduza o nome do grafismo'}
                                value={graphicName}
                                onChange={(e) => {
                                    setGraphicName(e.target.value);
                                }}
                                error={graphicSubmitted && graphicName?.trim() === '' ? 'true' : null} />
                            <Button
                                type="primary"
                                onClick={() => {
                                    submitGraphic(true);
                                    if (graphicName?.trim() !== '') addGraphic(graphicName, weddingForm);
                                }}>
                                {loadingGraphic ? <Icon type="loading" /> : <Icon type="save" />}
                                {loadingGraphic ? 'A guardar' : 'Adicionar'}
                            </Button>
                        </InputGroupField>
                    </div>}

                    <Field
                        component={DraftInput}
                        label='Notas de grafismo'
                        name={`graphicNotes`}
                        placeholder={'Indique as notas'}
                        minRows={10}
                        toolbarOnFocus={false}
                        toolbar='notes'
                    />
                </BaseForm>
            }
        </Modal>
    );
};

WeddingModal = reduxForm({
    form: 'wedding_form',
    enableReinitialize: true,
    validate: validations,
})(WeddingModal);

const selector = formValueSelector('wedding_form');

const mapStateToProps = state => ({
    weddingForm: {
        // environments: selector(state, 'environments'),
        proofs: selector(state, 'proofs'),
        meetings: selector(state, 'meetings'),
        graphics: selector(state, 'graphics'),
        attendedBy: selector(state, 'attendedBy'),
    }
});

WeddingModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(WeddingModal)