import React from "react";
import { Field } from "redux-form";
import TextInput from "../../../components/inputs/TextInput";
import TextAreaInput from "../../../components/inputs/TextAreaInput";

const TextCard = ({ component, type }) => {
  return (
    <Field
      component={component === 'Text' ? TextInput : TextAreaInput}
      type={type}
      placeholder={component === 'TextArea' ? 'Mensagem' : ''}
      name="response"
      noLabel={true}
    />
  );
};

export default TextCard;