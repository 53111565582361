export const SAVE_INFO = 'SAVE_INFO';
export const SHOW_LOCALS = 'SHOW_LOCALS';
export const SAVE_USER = 'SAVE_USER';

export const SAVE_WEDDING = 'SAVE_WEDDING';
export const CLEAR_WEDDING = 'CLEAR_WEDDING';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';

export const SAVE_ABOUT = 'SAVE_ABOUT';
export const SAVE_NOTES = 'SAVE_NOTES';

export const UPDATE_PLAN_ROOM = 'UPDATE_PLAN_ROOM';

export const SAVE_GRAPHIC_MENU = 'SAVE_GRAPHIC_MENU';

export const ADD_WEDDING_DOCUMENT = 'ADD_WEDDING_DOCUMENT';
export const DELETE_WEDDING_DOCUMENT = 'DELETE_WEDDING_DOCUMENT';

export const EDIT_UPGRADE = 'EDIT_UPGRADE';

export const SAVE_PRINTED = 'SAVE_PRINTED';

export const SAVE_PRIVATE_NOTES = 'SAVE_PRIVATE_NOTES'; 
