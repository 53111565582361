import React from 'react';
import {
  OptionsContainer,
  OptionLine,
  OptionColumn,
  OptionQuantity
} from './Styles';
import {
  WeddingTotalTitle
} from '../OrderStyles';
import TextInput from '../../../components/inputs/TextInput';
import ColourInput from '../../../components/inputs/ColourInput';

const TotalFlowersList = ({ list }) => {
  return (
    <React.Fragment>
      <WeddingTotalTitle>Total</WeddingTotalTitle>
      <OptionsContainer>
        {
          list?.map((elem, index) =>
            <OptionLine key={index}>  
              <OptionColumn maxWidth={32} noBorderTopRight={true} noBorderBottomRight={true}>
                <ColourInput
                  meta={{}}
                  input={{value: elem.hex}}
                  disabled={true}
                  hideInput={true}
                  first={true}
                />
              </OptionColumn>
              <OptionColumn maxWidth={250} noBorderTopRight={true} noBorderBottomRight={true}>
                <TextInput
                  meta={{}}
                  input={{value: elem.color}}
                  type='text'
                  disabled={true}
                  noLabel={true}
                />
              </OptionColumn>
              <OptionColumn maxWidth={250} noBorderRadius={true}>
                <TextInput
                  meta={{}}
                  input={{value: elem.flower}}
                  type='text'
                  disabled={true}
                  noLabel={true}
                />
              </OptionColumn>
              <OptionQuantity>
                <TextInput
                  meta={{}}
                  input={{value: `${elem.quantity} ${elem.type}`}}
                  type='text'
                  disabled={true}
                  noLabel={true}
                />
              </OptionQuantity>
            </OptionLine>
          )
        }
      </OptionsContainer>
    </React.Fragment>
  );
};

export default TotalFlowersList;