import React, { Component, Fragment } from 'react';
import { Anchor, Avatar, Button, Dropdown, Icon, Menu, message, Modal, Popconfirm, Tag, Tooltip } from 'antd';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton,
  SectionTitle
} from '../../styles/BasicStyles';
import { ExtendReserveEditingTime, GetWeddingsNoReserves, SoftDeleteWaitingWedding, UnblockMeetings, UpdateReserveNotes, UpdateWeddingContractAndPaymentPhases } from '../../infra/requests/WeddingRequests';
import BaseButton from '../../components/buttons/BaseButton';
import moment from 'moment';
import CurrencyComponent from '../../components/currency/CurrencyComponent';
import { connect } from 'react-redux';
import { ClearWedding } from '../../redux/wedding/wedding.actions';
import { bindActionCreators } from 'redux';
import { getInitials, getLocalColor, getWeddingContacts, getWeddingEmails, getWeddingName, getWeddingStatus } from '../../infra/services/wedding/weddingUtils';
import { getUserId, isCurrentUserAdmin, isCurrentUserEmployeeAttending } from '../../infra/helpers/Helpers';
import ReservesFilters from './ReservesFilters';
import { ReserveStatus, WeddingStatus } from './ReserveAgenda';
import OrdinalNumberComponent from '../../components/ordinalNumber/OrdinalNumberComponent';
import { CheckMngMultipleByAttending, ConfirmReserve, DownloadReserves, GetReservesPaginated, GetReservesPaginatedAndGrouped, ReactivateReserve, ReorderReserve, SoftDeleteReserve, TimeForEmployeeAttending, UpdateReserveConfirmationDate, UpdateReserveRelatedInfo, UploadReservesToDrive } from '../../infra/requests/ReserveRequests';
import Alerts from '../../components/alert/Alert';
import { GetColorSystemByType } from '../../infra/requests/ColorSystemRequests';
import { LocalLabel } from '../weddings/Styles';
import styledComponents from 'styled-components';
import ReserveInfoModal from '../reservesAttending/ReserveInfoModal';
import { GetTextColor } from '../../infra/services/text/textUtils';
import { GetConfirmationDateColor, GetContractDateColor, GetPaymentColor, getReserveStatus, GetRowColor, GetStatusColor, GetTagPaymentColor, inactiveColor, setDataToUpdateForReserveWedding, yellowColor } from './reservesUtils';
import { SpanContact } from '../weddings/WeddingsPage';
import ReserveNotesModal from './ReserveNotesModal';
import momentTimezone from 'moment-timezone';
import WeddingNoReservesFilters from './WeddingNoReservesFilters';
import { AuthGoogleDrive } from '../../infra/requests/ReportsRequests';
import AttendingReservesFilters from './AttendingReservesFilters';
import AccessGuestModal from '../weddings/AccessGuestModal';
import ReserveDateModal from './ReserveDateModal';
import OpenLockIcon from '../../assets/icons/open_lock.svg';
import ReactSVG from 'react-svg';
import ModalDeleteWaitingWedding from '../waitingDates/DeleteWaitingWeddingModal';
import { ReserveAnchor } from './ReserveStyles';
const { Link } = Anchor;

export const DateColumn = styledComponents.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  .date {
    font-size: inherit;
    font-weigth: bold;
  }

  .weekdayName {
    font-size: 11px;
    color: light-gray;
  }
`;

export const SpanNotes = styledComponents.div`
  width: 100%;
  height: 110px;
  max-height: 110px;
  justify-content: center;
  overflow: auto;
`;

export const SpanDate = styledComponents.div`
  width: 100%;
  height: 110px;
  max-height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpanAttended = styledComponents.div`
  width: 100%;
  height: 115px;
  max-height: 115px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: auto;
  overflow-x: hidden;
`;

const RemoveMessage = (
  <div>
    <div>Tem a certeza que quer remover esta reserva?</div>
    <div>
      Toda a informação desta reserva será removida da plataforma.
    </div>
  </div>
);

const ReorderMessage = (
  <div>
    <div>Tem a certeza que quer reordenar esta reserva?</div>
    <div>Ao reordenar a reserva passará a último lugar da lista para esta data e espaço. </div>
    <div>O preço da reserva passará ao preço atual apresentado na agenda.</div>
    <div>A reserva será ativada por um período de 15 dias.</div>
  </div >
);

const ReactivateMessage = (
  <div>
    <div>Tem a certeza que quer reativar esta reserva?</div>
    <div>
      A reserva será ativada por um período de 15 dias. Quando faltar 1 dia para o término do período será notificado por e-mail.
    </div>
  </div>
);

const ConfirmMessage = (
  <div>
    <div>Tem a certeza que quer confirmar esta reserva?</div>
    <div>
      Após confirmar a reserva iremos enviar um e-mail com a informação contratual para a realização do seu dia especial.
    </div>
  </div>
);

class ReservesPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    download: false,
    upload: false,

    showReserveModal: false,
    valuesReserveModal: {},

    filters: {},
    needRefresh: false,

    columnsAttending: [
      {
        title: 'Data',
        key: 'date',
        width: '80px',
        render: data => {
          return data?.date ?
            <DateColumn>
              <span className='date'>{moment.utc(data.date).format('DD-MM-YYYY')}</span>
              <span className='weekdayName'>{moment.utc(data.date).locale('pt').format('dddd')}</span>
            </DateColumn>
            : '';
        }
      },
      {
        title: 'Local',
        key: 'weddingPlace',
        width: '40px',
        className: 'align-center',
        render: data => {
          const local = getLocalColor(data, this.state.colors);
          return local
            ? <LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.initials}</LocalLabel>
            : null;
        }
      },
      {
        title: 'Noivos',
        key: 'coupleName',
        width: '100px',
        render: data => getWeddingName(data.wedding)
      },
      {
        title: 'Posição',
        key: 'order',
        width: '30px',
        render: data => {
          return data?.status !== ReserveStatus.INACTIVE && data?.order
            ? <OrdinalNumberComponent value={data?.order} />
            : '';
        }
      },
      {
        title: 'Estado',
        key: 'status',
        width: '45px',
        render: data => getReserveStatus(data?.status)
      },
      {
        title: 'Data Ativação',
        key: 'createdAt',
        width: '85px',
        render: data => {
          return data?.createdAt
            ? moment.utc(data?.createdAt).format('DD-MM-YYYY')
            : '';
        }
      },
      {
        title: 'Ativado por',
        key: 'createdBy',
        width: '50px',
        className: 'align-center',
        render: data => {
          const isCoupleUser = !data?.user?.admin && !data?.user?.employee && !data?.user?.organizer
            && !data?.user?.humanResources && !data?.user?.employeeAttending && !data?.user?.chief
            ? true : false;
          return data?.user
            ? <Tooltip title={data?.user?.name}>
              <Avatar style={{ backgroundColor: isCoupleUser ? '#cce9ff' : data?.user?.employeeAttending ? '#e4ffdf' : '#d4d4d4', border: '1px solid black', color: 'black' }}>
                {getInitials(data?.user?.name)}
              </Avatar>
            </Tooltip>
            : null
        }
      },
      {
        title: 'Atendido por',
        key: 'attendedBy',
        width: '50px',
        className: 'align-center',
        render: data => data?.wedding?.attendedBy
          ? <Tooltip title={`Atendido por: ${data?.wedding?.attendedBy?.name}`}>
            <Avatar style={{ backgroundColor: '#e4ffdf', border: '1px solid black', color: 'black' }}>
              {getInitials(data?.wedding?.attendedBy?.name)}
            </Avatar>
          </Tooltip>
          : null
      },
      {
        title: 'Preço por PAX',
        key: 'price',
        width: '70px',
        className: 'align-right',
        render: data => {
          return <CurrencyComponent value={data?.priceObj ? data?.priceObj?.minPrice : data?.price ? data?.price?.minPrice : 0} />;
        }
      },
      {
        title: 'PAX Mínimo',
        key: 'minPax',
        width: '50px',
        className: 'align-right',
        render: data => {
          return data?.priceObj
            ? data?.priceObj?.minPax
            : data?.price
              ? data?.price?.minPax
              : 0;
        }
      },
      {
        title: 'Espaço Visitado',
        key: 'visitedPlace',
        width: '55px',
        className: 'align-center',
        render: data => {
          return <Tag color={data?.wedding?.visitedPlace ? 'green' : 'red'}>{data?.wedding?.visitedPlace ? 'Sim' : 'Não'}</Tag>;
        }
      },
      {
        title: 'Total SLE',
        key: 'totalSolar',
        width: '40px',
        // className: 'align-right',
        render: data => {
          return data?.reserves && Array.isArray(data?.reserves)
            ? data?.reserves?.filter(f => f.weddingPlace === 1).length
            : 0;
        }
      },
      {
        title: 'Total QLC',
        key: 'totalLago',
        width: '40px',
        // className: 'align-right',
        render: data => {
          return data?.reserves && Array.isArray(data?.reserves)
            ? data?.reserves?.filter(f => f.weddingPlace === 2).length
            : 0;
        }
      },
      {
        title: 'Data de Vencimento',
        key: 'endActivationDate',
        width: '85px',
        render: data => {
          const validityDecision = moment.utc(data.waitingNotificationDate).add(72, 'hours');
          const validityActivation = moment.utc(data.endActivationDate);
          const validity = data?.sentWaitingNotification && validityDecision.isSameOrBefore(validityActivation)
            ? validityDecision
            : validityActivation;
          return momentTimezone(validity).tz('Europe/Lisbon').format('DD-MM-YYYY HH:mm') + ' - hora de Lisboa - GMT';
        }
      },
      {
        title: 'Notas',
        key: 'reserveNotes',
        width: '150px',
        render: data => {
          return <SpanNotes
            dangerouslySetInnerHTML={{
              __html: data?.wedding?.reserveNotes || ''
            }}>
          </SpanNotes>
        }
      },
      {
        title: 'Visitas',
        key: 'visits',
        width: '150px',
        render: data => {
          return <Tooltip title={'Editar'}>
            <SpanNotes
              dangerouslySetInnerHTML={{
                __html: data?.wedding?.visits || ''
              }}>
            </SpanNotes>
          </Tooltip>
        }
      },
      {
        title: 'Acções',
        key: 'actions',
        width: '70px',
        render: data => {
          const now = moment.utc();
          const canAction = now.isSameOrBefore(moment.utc(data.date), 'day');
          const isActive = now.isSameOrBefore(moment.utc(data.endActivationDate));
          // Check if there are 48 hours or less left to end the reservation activation period
          const canReactive = data?.endActivationDate && moment.utc(data.endActivationDate).diff(now, 'hours') <= 48 ? true : false;

          return <div onClick={e => e.stopPropagation()}>
            {data?.wedding?.status === WeddingStatus.RESERVE &&
              <Tooltip title={'Editar Noivos/Info Geral'}>
                <TableButton onClick={e => this.editGeneral(e, data)}>
                  <Icon type="team" />
                </TableButton>
              </Tooltip>}
            {data?.status === ReserveStatus.RSC && data?.status && (data.sentWaitingNotification === undefined || (data.sentWaitingNotification !== undefined && data.sentWaitingNotification === false)) && <Tooltip title='Editar'>
              <TableButton onClick={() => this.editReserve(data)}>
                <Icon type="edit" />
              </TableButton>
            </Tooltip>}
            {data?.status === ReserveStatus.RSC && data?.sentWaitingNotification &&
              <Tooltip title='Reordenar'>
                <TableButton onClick={e => e.stopPropagation()}>
                  <Popconfirm
                    placement="topRight"
                    title={ReorderMessage}
                    okText='Reordenar'
                    onConfirm={async () => await this.reorderReserve(data)}>
                    <Icon type="sort-ascending" />
                  </Popconfirm>
                </TableButton>
              </Tooltip>}
            {data?.status && !data?.sentWaitingNotification &&
              (data?.status === ReserveStatus.INACTIVE || (canReactive && data?.status === ReserveStatus.RSC))
              && canAction && <React.Fragment>
                <Tooltip title='Agenda'>
                  <TableButton onClick={() => this.goToReserveAgenda(data?.wedding)}>
                    <Icon type="calendar" />
                  </TableButton>
                </Tooltip>

                <Tooltip title='Reativar'>
                  <TableButton onClick={e => e.stopPropagation()}>
                    <Popconfirm
                      placement="topRight"
                      title={ReactivateMessage}
                      okText='Reativar'
                      onConfirm={async () => await this.reactivateReserve(data)}>
                      <Icon type="check-circle" />
                    </Popconfirm>
                  </TableButton>
                </Tooltip>
              </React.Fragment>}
            {data?.status === ReserveStatus.RSC && data?.order === 1 && isActive && canAction &&
              <Tooltip title='Confimar'>
                <TableButton onClick={e => e.stopPropagation()}>
                  <Popconfirm
                    placement="topRight"
                    title={ConfirmMessage}
                    okText='Confirmar'
                    onConfirm={async () => await this.confirmReserve(data)}>
                    <Icon type="carry-out" />
                  </Popconfirm>
                </TableButton>
              </Tooltip>
            }
            {(data?.status === ReserveStatus.RSC || data?.status === ReserveStatus.INACTIVE) && <Tooltip title={'Eliminar'}>
              <TableButton onClick={e => e.stopPropagation()}>
                <Popconfirm
                  placement="topRight"
                  title={RemoveMessage}
                  okText='Eliminar'
                  onConfirm={async () => await this.deleteReserve(data)}>
                  <Icon type="delete" />
                </Popconfirm>
              </TableButton>
            </Tooltip>}
          </div>
        }
      }
    ],
    columns: [
      {
        title: 'Data',
        key: 'date',
        width: '80px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: data?.date ?
              <DateColumn>
                <span className='date'>{moment.utc(data.date).format('DD-MM-YYYY')}</span>
                <span className='weekdayName'>{moment.utc(data.date).locale('pt').format('dddd')}</span>
              </DateColumn>
              : ''
          };
        }
      },
      {
        title: 'Local',
        key: 'weddingPlace',
        width: '50px',
        className: 'align-center',
        render: data => {
          const local = getLocalColor(data, this.state.colors);

          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: local
              ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.initials}</LocalLabel>)
              : null
          };
        }
      },
      {
        title: 'Noivos',
        key: 'coupleName',
        width: '100px',
        className: 'align-center',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <React.Fragment>
              {data?.wedding?.hidden && <Tag style={{ marginRight: 0, marginBottom: 4 }} color="red">Fictício</Tag>}
              <p>{getWeddingName(data.wedding)}</p>
            </React.Fragment>
          };
        }
      },
      {
        title: 'Email',
        key: 'coupleEmail',
        width: '60px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <Tooltip title={getWeddingEmails(data.wedding)}>
              <SpanContact onClick={($event) => this.copy($event, getWeddingEmails(data?.wedding))}>
                {getWeddingEmails(data.wedding)}
              </SpanContact>
            </Tooltip>
          };
        }
      },
      {
        title: 'Tel',
        key: 'coupleContact',
        width: '60px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <Tooltip title={getWeddingContacts(data.wedding)}>
              <SpanContact onClick={($event) => this.copy($event, getWeddingContacts(data.wedding))}>
                {getWeddingContacts(data.wedding)}
              </SpanContact>
            </Tooltip>
          };
        }
      },
      {
        title: 'Email convidado',
        key: 'guestEmail',
        width: '65px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: data?.wedding?.alternativeGuest?.email?.trim() !== ''
              ? <Tooltip title={data?.wedding?.alternativeGuest?.email}>
                <SpanContact onClick={($event) => this.copy($event, data?.wedding?.alternativeGuest?.email)}>
                  {data?.wedding?.alternativeGuest?.email}
                </SpanContact>
              </Tooltip>
              : null
          };
        }
      },
      {
        title: 'Idioma',
        key: 'language',
        width: '85px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: data?.wedding?.language == 'pt'
              ? 'Português'
              : data?.wedding?.language == 'en'
                ? 'Inglês'
                : data?.wedding?.language == 'fr'
                  ? 'Francês'
                  : data?.wedding?.language == 'es'
                    ? 'Espanhol'
                    : ''
          };
        }
      },
      {
        title: 'Posição',
        key: 'order',
        width: '30px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: data?.status !== ReserveStatus.INACTIVE && data?.order
              ? <OrdinalNumberComponent value={data?.order} />
              : ''
          };
        }
      },
      {
        title: 'Estado',
        key: 'status',
        width: '30px',
        className: 'align-center',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetStatusColor(this.state.colors, data),
                color: GetTextColor(GetStatusColor(this.state.colors, data))
              }
            },
            children: getReserveStatus(data?.status)
          };
        }
      },
      {
        title: 'Ativado por',
        key: 'createdBy',
        width: '80px',
        className: 'align-center',
        render: data => {
          const isCoupleUser = !data?.user?.admin && !data?.user?.employee && !data?.user?.organizer
            && !data?.user?.humanResources && !data?.user?.employeeAttending && !data?.user?.chief
            ? true : false;
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center' }}>
              {data?.user && <Tooltip title={data?.user?.name}>
                <Avatar style={{ backgroundColor: isCoupleUser ? '#cce9ff' : data?.user?.employeeAttending ? '#e4ffdf' : '#d4d4d4', border: '1px solid black', color: 'black' }}>
                  {getInitials(data?.user?.name)}
                </Avatar>
              </Tooltip>}

              {data?.createdAt && <span style={{ fontSize: 13, marginTop: 5 }}>{moment.utc(data?.createdAt).format('DD-MM-YYYY')}</span>}
            </div>
          }
        }
      },
      {
        title: 'Atendido por',
        key: 'attendedBy',
        width: '80px',
        className: 'align-center',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <SpanAttended onClick={($event) => this.editReserveNotes($event, data)}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center' }}>
                {data?.wedding?.attendedBy && <Tooltip title={`Atendido por: ${data?.wedding?.attendedBy?.name}`}>
                  <Avatar style={{ backgroundColor: '#e4ffdf', border: '1px solid black', color: 'black' }}>
                    {getInitials(data?.wedding?.attendedBy?.name)}
                  </Avatar>
                </Tooltip>}

                {data?.schedules?.length > 0 ?
                  data?.schedules
                    .sort((a, b) => a?.date && b?.date && a?.date > b?.date ? -1 : 1)
                    .map((schedule, index) => <span key={index} style={{ fontSize: 13, marginTop: 5 }}>
                      {schedule?.presence === 'M'
                        ? <Tag style={{ margin: 0 }} color="red"> {moment.utc(schedule?.date).format('DD-MM-YYYY')}</Tag>
                        : moment.utc(schedule?.date).format('DD-MM-YYYY')}
                    </span>)
                  : null}
              </div>
            </SpanAttended>
          }
        }
      },
      {
        title: 'Preço p/PAX',
        key: 'price',
        width: '60px',
        className: 'align-right',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <CurrencyComponent value={data?.priceObj ? data?.priceObj?.minPrice : data?.price ? data?.price?.minPrice : 0} />
          };
        }
      },
      {
        title: 'PAX Mínimo',
        key: 'minPax',
        width: '40px',
        className: 'align-right',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: data?.priceObj
              ? data?.priceObj?.minPax
              : data?.price
                ? data?.price?.minPax
                : 0
          };
        }
      },
      {
        title: 'Espaço Visitado',
        key: 'visitedPlace',
        width: '55px',
        className: 'align-center',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <Tag color={data?.wedding?.visitedPlace ? 'green' : 'red'}>{data?.wedding?.visitedPlace ? 'Sim' : 'Não'}</Tag>
          };
        }
      },
      {
        title: 'Total SLE',
        key: 'totalSolar',
        width: '40px',
        // className: 'align-right',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: data?.reserves && Array.isArray(data?.reserves)
              ? data.reserves?.filter(f => f?.weddingPlace && f.weddingPlace === 1)?.length
              : 0
          };
        }
      },
      {
        title: 'Total QLC',
        key: 'totalLago',
        width: '40px',
        // className: 'align-right',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: data?.reserves && Array.isArray(data?.reserves)
              ? data.reserves?.filter(f => f?.weddingPlace && f.weddingPlace === 2)?.length
              : 0
          };
        }
      },
      {
        title: 'Data de Vencimento',
        key: 'endActivationDate',
        width: '100px',
        render: data => {
          const validityDecision = moment.utc(data.waitingNotificationDate).add(72, 'hours');
          const validityActivation = moment.utc(data.endActivationDate);
          const validity = data?.sentWaitingNotification && validityDecision.isSameOrBefore(validityActivation)
            ? validityDecision
            : validityActivation;
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: data?.wedding?.status && data?.wedding?.status == WeddingStatus.RESERVE
              ? momentTimezone(validity).tz('Europe/Lisbon').format('DD-MM-YYYY HH:mm') + ' - hora de Lisboa - GMT'
              : ''
          };
        }
      },
      {
        title: 'Data Confirmação',
        key: 'confirmationDate',
        width: '80px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetConfirmationDateColor(this.state.colors, data),
                color: GetTextColor(GetConfirmationDateColor(this.state.colors, data))
              }
            },
            children: data?.wedding?.status && data?.wedding?.status !== WeddingStatus.RESERVE
              ? <Tooltip title={'Editar'}>
                <SpanDate onClick={($event) => this.editConfirmationDate($event, data)}>
                  {data?.confirmationDate && data?.wedding?.date && moment.utc(data?.confirmationDate).isSame(moment.utc(data?.wedding?.date), 'date')
                    ? <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center' }}>
                      <Tag color="red" style={{ margin: '0 0 5px 0' }}>Validar</Tag>
                      {moment.utc(data?.confirmationDate).format('DD-MM-YYYY')}
                    </div>
                    : data?.confirmationDate
                      ? moment.utc(data?.confirmationDate).format('DD-MM-YYYY')
                      : ''}
                </SpanDate>
              </Tooltip>
              : data?.confirmationDate
                ? moment.utc(data?.confirmationDate).format('DD-MM-YYYY')
                : ''
          };
        }
      },
      {
        title: 'Data Contrato',
        key: 'contractDate',
        width: '80px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetContractDateColor(this.state.colors, data),
                color: GetTextColor(GetContractDateColor(this.state.colors, data))
              }
            },
            children: data?.wedding?.contractDate
              ? moment.utc(data?.wedding?.contractDate).format('DD-MM-YYYY')
              : <TableButton onClick={e => this.sendContract(e, data?.wedding)}>
                <Icon type="edit" />
                &nbsp;Enviar Contrato
              </TableButton>
          };
        }
      },
      {
        title: 'Pago',
        key: 'payed',
        width: '80px',
        className: 'align-right',
        render: data => {
          const bgColor = data?.status === ReserveStatus.INACTIVE
            ? inactiveColor
            : data?.paymentColor;
          const tagColor = data?.status === ReserveStatus.INACTIVE
            ? null
            : data?.tagColor;
          return {
            props: {
              style: {
                backgroundColor: bgColor,
                color: GetTextColor(bgColor, true)
              }
            },
            children: <React.Fragment>
              <CurrencyComponent value={data?.budget?.payed} />
              <div style={{ marginTop: 5 }}>
                <Tag color={''} key={data.id} >
                  {(data?.budget?.payedPercentage || 0).toFixed()} %
                </Tag>
              </div>
            </React.Fragment>
          };
        }
      },
      {
        title: 'Notas',
        key: 'reserveNotes',
        width: '150px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <Tooltip title={'Editar'}>
              <SpanNotes
                dangerouslySetInnerHTML={{
                  __html: data?.wedding?.reserveNotes || ''
                }}
                onClick={($event) => this.editReserveNotes($event, data)}>
              </SpanNotes>
            </Tooltip>
          };
        }
      },
      {
        title: 'Visitas',
        key: 'visits',
        width: '150px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <Tooltip title={'Editar'}>
              <SpanNotes
                dangerouslySetInnerHTML={{
                  __html: data?.wedding?.visits || ''
                }}
                onClick={($event) => this.editReserveNotes($event, data)}>
              </SpanNotes>
            </Tooltip>
          };
        }
      },
      {
        title: 'Acções',
        key: 'actions',
        width: '70px',
        // fixed: 'right',
        render: data => {
          const now = moment.utc();
          const canAction = now.isSameOrBefore(moment.utc(data.date), 'day');
          const isActive = now.isSameOrBefore(moment.utc(data.endActivationDate));
          // Check if there are 48 hours or less left to end the reservation activation period
          const canReactive = data?.endActivationDate && moment.utc(data.endActivationDate).diff(now, 'hours') <= 48 ? true : false;

          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <div onClick={e => e.stopPropagation()}>
              <Tooltip title={data?.wedding?.status !== WeddingStatus.RESERVE ? 'Editar Acessos/Padrão' : 'Editar Noivos/Info Geral'}>
                <TableButton onClick={e => this.editGeneral(e, data)}>
                  <Icon type="team" />
                </TableButton>
              </Tooltip>
              {data?.wedding && <Tooltip title='Acesso Convidado'>
                <TableButton onClick={e => this.editGuestAccess(e, data)}>
                  <Icon type="solution" />
                </TableButton>
              </Tooltip>}
              {data?.wedding?.status == WeddingStatus.CONFIRMED && data?.wedding?.meetingsBlocked
                && <Tooltip title='Desbloquear Reuniões'>
                  <TableButton onClick={e => this.unblockMeetings(data?.wedding)}>
                    <ReactSVG src={OpenLockIcon} />
                  </TableButton>
                </Tooltip>}
              {data?.wedding?.attendedBy && <Tooltip title={'Prolongar Tempo de Edição'}>
                <TableButton onClick={e => this.extendEditingTime(e, data)}>
                  <Icon type="clock-circle" />
                </TableButton>
              </Tooltip>}
              {data?.status === ReserveStatus.RSC && data?.status && (data.sentWaitingNotification === undefined || (data.sentWaitingNotification !== undefined && data.sentWaitingNotification === false)) && <Tooltip title='Editar'>
                <TableButton onClick={() => this.editReserve(data)}>
                  <Icon type="edit" />
                </TableButton>
              </Tooltip>}
              {data?.status === ReserveStatus.RSC && data?.sentWaitingNotification
                && <Tooltip title='Reordenar'>
                  <TableButton onClick={e => e.stopPropagation()}>
                    <Popconfirm
                      placement="topRight"
                      title={ReorderMessage}
                      okText='Reordenar'
                      onConfirm={async () => await this.reorderReserve(data)}>
                      <Icon type="sort-ascending" />
                    </Popconfirm>
                  </TableButton>
                </Tooltip>}
              {/* {data?.status && !data?.sentWaitingNotification
                && (data?.status === ReserveStatus.INACTIVE || (canReactive && data?.status === ReserveStatus.RSC))
                && canAction && <React.Fragment>
                  <Tooltip title='Agenda'>
                    <TableButton onClick={() => this.goToReserveAgenda(data?.wedding)}>
                      <Icon type="calendar" />
                    </TableButton>
                  </Tooltip>

                  <Tooltip title='Reativar'>
                    <TableButton onClick={e => e.stopPropagation()}>
                      <Popconfirm
                        placement="topRight"
                        title={ReactivateMessage}
                        okText='Reativar'
                        onConfirm={async () => await this.reactivateReserve(data)}>
                        <Icon type="check-circle" />
                      </Popconfirm>
                    </TableButton>
                  </Tooltip>
                </React.Fragment>} */}
              {data?.status === ReserveStatus.RSC && data?.order === 1 && isActive && canAction &&
                <Tooltip title='Confimar'>
                  <TableButton onClick={e => e.stopPropagation()}>
                    <Popconfirm
                      placement="topRight"
                      title={ConfirmMessage}
                      okText='Confirmar'
                      onConfirm={async () => await this.confirmReserve(data)}>
                      <Icon type="carry-out" />
                    </Popconfirm>
                  </TableButton>
                </Tooltip>
              }
              {(data?.status === ReserveStatus.RSC || data?.status === ReserveStatus.INACTIVE) && <Tooltip title={'Eliminar'}>
                <TableButton onClick={e => e.stopPropagation()}>
                  <Popconfirm
                    placement="topRight"
                    title={RemoveMessage}
                    okText='Eliminar'
                    onConfirm={async () => await this.deleteReserve(data)}>
                    <Icon type="delete" />
                  </Popconfirm>
                </TableButton>
              </Tooltip>}
            </div>
          };
        }
      }
    ],
    rows: [],
    total: 0,

    pageSizeInactive: 15,
    currentPageInactive: 1,
    loadingInactive: false,
    filtersInactive: {},
    needRefreshInactive: false,
    columnsInactive: [
      {
        title: 'Data',
        key: 'date',
        width: '80px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: data?.date ?
              <DateColumn>
                <span className='date'>{moment.utc(data.date).format('DD-MM-YYYY')}</span>
                <span className='weekdayName'>{moment.utc(data.date).locale('pt').format('dddd')}</span>
              </DateColumn>
              : ''
          };
        }
      },
      {
        title: 'Local',
        key: 'weddingPlace',
        width: '50px',
        className: 'align-center',
        render: data => {
          const local = getLocalColor(data, this.state.colors);

          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: local
              ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.initials}</LocalLabel>)
              : null
          };
        }
      },
      {
        title: 'Noivos',
        key: 'coupleName',
        width: '100px',
        className: 'align-center',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <React.Fragment>
              {data?.wedding?.hidden && <Tag style={{ marginRight: 0, marginBottom: 4 }} color="red">Fictício</Tag>}
              <p>{getWeddingName(data.wedding)}</p>
            </React.Fragment>
          };
        }
      },
      {
        title: 'Email',
        key: 'coupleEmail',
        width: '60px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <Tooltip title={getWeddingEmails(data.wedding)}>
              <SpanContact onClick={($event) => this.copy($event, getWeddingEmails(data?.wedding))}>
                {getWeddingEmails(data.wedding)}
              </SpanContact>
            </Tooltip>
          };
        }
      },
      {
        title: 'Tel',
        key: 'coupleContact',
        width: '60px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <Tooltip title={getWeddingContacts(data.wedding)}>
              <SpanContact onClick={($event) => this.copy($event, getWeddingContacts(data.wedding))}>
                {getWeddingContacts(data.wedding)}
              </SpanContact>
            </Tooltip>
          };
        }
      },
      {
        title: 'Email convidado',
        key: 'guestEmail',
        width: '65px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: data?.wedding?.alternativeGuest?.email?.trim() !== ''
              ? <Tooltip title={data?.wedding?.alternativeGuest?.email}>
                <SpanContact onClick={($event) => this.copy($event, data?.wedding?.alternativeGuest?.email)}>
                  {data?.wedding?.alternativeGuest?.email}
                </SpanContact>
              </Tooltip>
              : null
          };
        }
      },
      {
        title: 'Idioma',
        key: 'language',
        width: '85px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: data?.wedding?.language == 'pt'
              ? 'Português'
              : data?.wedding?.language == 'en'
                ? 'Inglês'
                : data?.wedding?.language == 'fr'
                  ? 'Francês'
                  : data?.wedding?.language == 'es'
                    ? 'Espanhol'
                    : ''
          };
        }
      },
      {
        title: 'Posição',
        key: 'order',
        width: '30px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: data?.status !== ReserveStatus.INACTIVE && data?.order
              ? <OrdinalNumberComponent value={data?.order} />
              : ''
          };
        }
      },
      {
        title: 'Estado',
        key: 'status',
        width: '30px',
        className: 'align-center',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetStatusColor(this.state.colors, data),
                color: GetTextColor(GetStatusColor(this.state.colors, data))
              }
            },
            children: getReserveStatus(data?.status)
          };
        }
      },
      {
        title: 'Ativado por',
        key: 'createdBy',
        width: '80px',
        className: 'align-center',
        render: data => {
          const isCoupleUser = !data?.user?.admin && !data?.user?.employee && !data?.user?.organizer
            && !data?.user?.humanResources && !data?.user?.employeeAttending && !data?.user?.chief
            ? true : false;
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center' }}>
              {data?.user && <Tooltip title={data?.user?.name}>
                <Avatar style={{ backgroundColor: isCoupleUser ? '#cce9ff' : data?.user?.employeeAttending ? '#e4ffdf' : '#d4d4d4', border: '1px solid black', color: 'black' }}>
                  {getInitials(data?.user?.name)}
                </Avatar>
              </Tooltip>}

              {data?.createdAt && <span style={{ fontSize: 13, marginTop: 5 }}>{moment.utc(data?.createdAt).format('DD-MM-YYYY')}</span>}
            </div>
          }
        }
      },
      {
        title: 'Atendido por',
        key: 'attendedBy',
        width: '80px',
        className: 'align-center',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <SpanAttended onClick={($event) => this.editReserveNotes($event, data)}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center' }}>
                {data?.wedding?.attendedBy && <Tooltip title={`Atendido por: ${data?.wedding?.attendedBy?.name}`}>
                  <Avatar style={{ backgroundColor: '#e4ffdf', border: '1px solid black', color: 'black' }}>
                    {getInitials(data?.wedding?.attendedBy?.name)}
                  </Avatar>
                </Tooltip>}

                {data?.schedules?.length > 0 ?
                  data?.schedules
                    .sort((a, b) => a?.date && b?.date && a?.date > b?.date ? -1 : 1)
                    .map((schedule, index) => <span key={index} style={{ fontSize: 13, marginTop: 5 }}>
                      {schedule?.presence === 'M'
                        ? <Tag style={{ margin: 0 }} color="red"> {moment.utc(schedule?.date).format('DD-MM-YYYY')}</Tag>
                        : moment.utc(schedule?.date).format('DD-MM-YYYY')}
                    </span>)
                  : null}
              </div>
            </SpanAttended>
          }
        }
      },
      {
        title: 'Preço p/PAX',
        key: 'price',
        width: '60px',
        className: 'align-right',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <CurrencyComponent value={data?.priceObj ? data?.priceObj?.minPrice : data?.price ? data?.price?.minPrice : 0} />
          };
        }
      },
      {
        title: 'PAX Mínimo',
        key: 'minPax',
        width: '40px',
        className: 'align-right',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: data?.priceObj
              ? data?.priceObj?.minPax
              : data?.price
                ? data?.price?.minPax
                : 0
          };
        }
      },
      {
        title: 'Espaço Visitado',
        key: 'visitedPlace',
        width: '55px',
        className: 'align-center',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <Tag color={data?.wedding?.visitedPlace ? 'green' : 'red'}>{data?.wedding?.visitedPlace ? 'Sim' : 'Não'}</Tag>
          };
        }
      },
      {
        title: 'Total SLE',
        key: 'totalSolar',
        width: '40px',
        // className: 'align-right',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: data?.reserves && Array.isArray(data?.reserves)
              ? data.reserves?.filter(f => f?.weddingPlace && f.weddingPlace === 1)?.length
              : 0
          };
        }
      },
      {
        title: 'Total QLC',
        key: 'totalLago',
        width: '40px',
        // className: 'align-right',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: data?.reserves && Array.isArray(data?.reserves)
              ? data.reserves?.filter(f => f?.weddingPlace && f.weddingPlace === 2)?.length
              : 0
          };
        }
      },
      {
        title: 'Data de Vencimento',
        key: 'endActivationDate',
        width: '100px',
        render: data => {
          const validityDecision = moment.utc(data.waitingNotificationDate).add(72, 'hours');
          const validityActivation = moment.utc(data.endActivationDate);
          const validity = data?.sentWaitingNotification && validityDecision.isSameOrBefore(validityActivation)
            ? validityDecision
            : validityActivation;
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: data?.wedding?.status && data?.wedding?.status == WeddingStatus.RESERVE
              ? momentTimezone(validity).tz('Europe/Lisbon').format('DD-MM-YYYY HH:mm') + ' - hora de Lisboa - GMT'
              : ''
          };
        }
      },
      {
        title: 'Data Confirmação',
        key: 'confirmationDate',
        width: '80px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetConfirmationDateColor(this.state.colors, data),
                color: GetTextColor(GetConfirmationDateColor(this.state.colors, data))
              }
            },
            children: data?.wedding?.status && data?.wedding?.status !== WeddingStatus.RESERVE
              ? <Tooltip title={'Editar'}>
                <SpanDate onClick={($event) => this.editConfirmationDate($event, data)}>
                  {data?.confirmationDate && data?.wedding?.date && moment.utc(data?.confirmationDate).isSame(moment.utc(data?.wedding?.date), 'date')
                    ? <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center' }}>
                      <Tag color="red" style={{ margin: '0 0 5px 0' }}>Validar</Tag>
                      {moment.utc(data?.confirmationDate).format('DD-MM-YYYY')}
                    </div>
                    : data?.confirmationDate
                      ? moment.utc(data?.confirmationDate).format('DD-MM-YYYY')
                      : ''}
                </SpanDate>
              </Tooltip>
              : data?.confirmationDate
                ? moment.utc(data?.confirmationDate).format('DD-MM-YYYY')
                : ''
          };
        }
      },
      {
        title: 'Data Contrato',
        key: 'contractDate',
        width: '80px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetContractDateColor(this.state.colors, data),
                color: GetTextColor(GetContractDateColor(this.state.colors, data))
              }
            },
            children: data?.wedding?.contractDate
              ? moment.utc(data?.wedding?.contractDate).format('DD-MM-YYYY')
              : <TableButton onClick={e => this.sendContract(e, data?.wedding)}>
                <Icon type="edit" />
                &nbsp;Enviar Contrato
              </TableButton>
          };
        }
      },
      {
        title: 'Pago',
        key: 'payed',
        width: '80px',
        className: 'align-right',
        render: data => {
          const bgColor = data?.status === ReserveStatus.INACTIVE
            ? inactiveColor
            : data?.paymentColor;
          const tagColor = data?.status === ReserveStatus.INACTIVE
            ? null
            : data?.tagColor;
          return {
            props: {
              style: {
                backgroundColor: bgColor,
                color: GetTextColor(bgColor, true)
              }
            },
            children: <React.Fragment>
              <CurrencyComponent value={data?.budget?.payed} />
              <div style={{ marginTop: 5 }}>
                <Tag color={''} key={data.id} >
                  {(data?.budget?.payedPercentage || 0).toFixed()} %
                </Tag>
              </div>
            </React.Fragment>
          };
        }
      },
      {
        title: 'Notas',
        key: 'reserveNotes',
        width: '150px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <Tooltip title={'Editar'}>
              <SpanNotes
                dangerouslySetInnerHTML={{
                  __html: data?.wedding?.reserveNotes || ''
                }}
                onClick={($event) => this.editReserveNotes($event, data)}>
              </SpanNotes>
            </Tooltip>
          };
        }
      },
      {
        title: 'Visitas',
        key: 'visits',
        width: '150px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <Tooltip title={'Editar'}>
              <SpanNotes
                dangerouslySetInnerHTML={{
                  __html: data?.wedding?.visits || ''
                }}
                onClick={($event) => this.editReserveNotes($event, data)}>
              </SpanNotes>
            </Tooltip>
          };
        }
      },
      {
        title: 'Acções',
        key: 'actions',
        width: '70px',
        // fixed: 'right',
        render: data => {
          const now = moment.utc();
          const canAction = now.isSameOrBefore(moment.utc(data.date), 'day');
          const isActive = now.isSameOrBefore(moment.utc(data.endActivationDate));
          // Check if there are 48 hours or less left to end the reservation activation period
          const canReactive = data?.endActivationDate && moment.utc(data.endActivationDate).diff(now, 'hours') <= 48 ? true : false;

          return {
            props: {
              style: {
                backgroundColor: GetRowColor(this.state.colors, data),
                color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <div onClick={e => e.stopPropagation()}>
              <Tooltip title={data?.wedding?.status !== WeddingStatus.RESERVE ? 'Editar Acessos/Padrão' : 'Editar Noivos/Info Geral'}>
                <TableButton onClick={e => this.editGeneral(e, data)}>
                  <Icon type="team" />
                </TableButton>
              </Tooltip>
              {data?.wedding && <Tooltip title='Acesso Convidado'>
                <TableButton onClick={e => this.editGuestAccess(e, data)}>
                  <Icon type="solution" />
                </TableButton>
              </Tooltip>}
              {data?.wedding?.status == WeddingStatus.CONFIRMED && data?.wedding?.meetingsBlocked
                && <Tooltip title='Desbloquear Reuniões'>
                  <TableButton onClick={e => this.unblockMeetings(data?.wedding)}>
                    <ReactSVG src={OpenLockIcon} />
                  </TableButton>
                </Tooltip>}
              {data?.wedding?.attendedBy && <Tooltip title={'Prolongar Tempo de Edição'}>
                <TableButton onClick={e => this.extendEditingTime(e, data)}>
                  <Icon type="clock-circle" />
                </TableButton>
              </Tooltip>}
              {data?.status && !data?.sentWaitingNotification
                && (data?.status === ReserveStatus.INACTIVE || (canReactive && data?.status === ReserveStatus.RSC))
                && canAction && <React.Fragment>
                  <Tooltip title='Agenda'>
                    <TableButton onClick={() => this.goToReserveAgenda(data?.wedding)}>
                      <Icon type="calendar" />
                    </TableButton>
                  </Tooltip>

                  <Tooltip title='Reativar'>
                    <TableButton onClick={e => e.stopPropagation()}>
                      <Popconfirm
                        placement="topRight"
                        title={ReactivateMessage}
                        okText='Reativar'
                        onConfirm={async () => await this.reactivateReserve(data)}>
                        <Icon type="check-circle" />
                      </Popconfirm>
                    </TableButton>
                  </Tooltip>
                </React.Fragment>}
              {(data?.status === ReserveStatus.RSC || data?.status === ReserveStatus.INACTIVE) && <Tooltip title={'Eliminar'}>
                <TableButton onClick={e => e.stopPropagation()}>
                  <Popconfirm
                    placement="topRight"
                    title={RemoveMessage}
                    okText='Eliminar'
                    onConfirm={async () => await this.deleteReserve(data)}>
                    <Icon type="delete" />
                  </Popconfirm>
                </TableButton>
              </Tooltip>}
            </div>
          };
        }
      }
    ],
    rowsInactive: [],
    totalInactive: 0,

    pageSizeWeddings: 15,
    currentPageWeddings: 1,
    loadingWeddings: false,
    filtersWeddings: {},
    needRefreshWedding: false,
    columnsWeddings: [
      {
        title: 'Noivos',
        key: 'coupleName',
        render: data => getWeddingName(data)
      },
      {
        title: 'Email',
        key: 'coupleEmail',
        render: data => {
          return <a onClick={($event) => this.copy($event, getWeddingEmails(data))}>
            {getWeddingEmails(data)}
          </a>;
        }
      },
      {
        title: 'Tel',
        key: 'coupleContact',
        render: data => {
          return <a onClick={($event) => this.copy($event, getWeddingContacts(data))}>
            {getWeddingContacts(data)}
          </a>;
        }
      },
      {
        title: 'Data Ativação',
        key: 'createdAt',
        width: '80px',
        render: data => {
          return data?.createdAt ? moment.utc(data?.createdAt).format('DD-MM-YYYY') : '';
        }
      },
      {
        title: 'Acções',
        key: 'actions',
        width: '70px',
        render: data => {
          return <div onClick={e => e.stopPropagation()}>
            <Tooltip title="Editar Noivos/Info Geral">
              <TableButton onClick={e => this.editGeneral(e, { wedding: data })}>
                <Icon type="team" />
              </TableButton>
            </Tooltip>
            <Tooltip title='Acesso Convidado'>
              <TableButton onClick={e => this.editGuestAccess(e, { wedding: data })}>
                <Icon type="solution" />
              </TableButton>
            </Tooltip>
            {isCurrentUserAdmin() && data?.status == WeddingStatus.CONFIRMED && data?.meetingsBlocked
              && <Tooltip title='Desbloquear Reuniões'>
                <TableButton onClick={e => this.unblockMeetings(data)}>
                  <ReactSVG src={OpenLockIcon} />
                </TableButton>
              </Tooltip>}
            <Tooltip title={'Criar Reservas'}>
              <TableButton onClick={e => this.createReservesOnWeddingNoReserves(data)}>
                <Icon type="plus-circle" />
              </TableButton>
            </Tooltip>
            {isCurrentUserAdmin() && data?.canBeDeleted
              && <Tooltip title={'Eliminar casal da plataforma'}>
                <TableButton onClick={e => {
                  e.stopPropagation()
                  this.setState({
                    openModalDelete: true,
                    weddingToDelete: data
                  });
                }}>
                  <Icon type="usergroup-delete" />
                </TableButton>
              </Tooltip>}
          </div>
        }
      }
    ],
    rowsWeddings: [],
    totalWeddings: 0,

    colors: {
      colorSys1: null,
      colorSys2: null
    },

    notesModal: {},
    openNotesModal: false,
    savingNotesModal: false,

    showGuestAccessModal: false,
    guestWeddingId: null,

    showDateModal: false,
    reserveToEdit: null,
    savingDate: false,

    openModalDelete: false,
    weddingToDelete: null,
    deletingWedding: false,
  };

  componentDidMount = async () => {
    this.navigateToTop();
    this.getLocalColors();
    this.updateAllDataTables();
  };

  getLocalColors = async () => {
    const resColorSys1 = await GetColorSystemByType(4);
    const colorSys1 = resColorSys1.success && resColorSys1.data ? resColorSys1.data?.color : '#f7b5d6';
    const resColorSys2 = await GetColorSystemByType(5);
    const colorSys2 = resColorSys2.success && resColorSys2.data ? resColorSys2.data?.color : '#b6d7a9';
    this.setState({ colors: { colorSys1, colorSys2 } });
  }

  navigateToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  updateAllDataTables = async () => {
    const resultReserves = await this.updateDataTable();
    const rowsReserves = resultReserves?.success && resultReserves?.data
      ? resultReserves?.data?.items || []
      : [];
    const resutlInactive = isCurrentUserAdmin()
      ? await this.updateInactiveDataTable()
      : null;
    const rowsInactive = resutlInactive?.success && resutlInactive?.data
      ? resutlInactive?.data?.items || []
      : [];
    const resultWedding = await this.updateWeddingDataTable();
    const rowsWedding = resultWedding?.success && resultWedding?.data
      ? resultWedding?.data?.items || []
      : [];

    if (rowsReserves?.length > 0) {
      this.navigateToReservesTop();
    } else if (rowsInactive?.length > 0) {
      this.navigateToInactiveTop();
    } else if (rowsWedding?.length > 0) {
      this.navigateToWeddingsTop();
    } else {
      this.navigateToTop();
    }
  }

  copy = ($event, text) => {
    $event.stopPropagation();
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text);
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement('textarea');
      textArea.value = text;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';

      document.body.prepend(textArea);
      textArea.select();

      document.execCommand('copy');
    }
    message.success('Campo copiado!');
  }

  // Reserve Info Only
  editConfirmationDate = async ($event, data) => {
    $event.stopPropagation();

    this.setState({
      reserveToEdit: data,
      showDateModal: true
    });
  }

  onDateSubmit = async (values) => {
    const weddingId = values?.wedding?._id ? values?.wedding?._id : values?.wedding;
    if (isCurrentUserEmployeeAttending()) {
      const resultCheck = await CheckMngMultipleByAttending(weddingId);
      if (!resultCheck.success) {
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'O período de edição terminou!'
        });
        return;
      }
    }

    // console.warn('values', values);
    try {
      this.setState({ savingDate: true });

      const dataToUpdate = {
        confirmationDate: values.confirmationDate,
      }
      const { success, data } = await UpdateReserveConfirmationDate(values?._id, dataToUpdate);

      if (success && data) {
        Alerts.new({
          type: 'success',
          title: 'Gravado com sucesso',
          text: 'A informação foi gravada com sucesso!'
        });

        this.setState({
          showDateModal: false,
          reserveToEdit: {},
          savingDate: false
        }, () => {
          this.updateDataTable();
        });
      } else {
        const message = data == 'TIME_CREATE_EDIT_ENDED'
          ? 'O período de edição terminou!'
          : data == 'NOT_AUTHORIZED'
            ? 'Não autorizado'
            : data;
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: message
        });
        this.setState({
          savingDate: false,
          showDateModal: data == 'TIME_CREATE_EDIT_ENDED' || data == 'NOT_AUTHORIZED' ? false : true
        });
        return;
      }
    } catch (e) {
      console.error(e);
      this.setState({ savingDate: false });
    }
  }

  // Reserve Notes
  editReserveNotes = async ($event, data) => {
    $event.stopPropagation();

    this.setState({
      notesModal: {
        wedding: data?.wedding,
        notes: data?.wedding?.reserveNotes,
        visits: data?.wedding?.visits,
        attendedBy: data?.wedding?.attendedBy?._id ? data?.wedding?.attendedBy?._id : data?.wedding?.attendedBy,
      },
      openNotesModal: true
    });
  }

  onNotesModalSubmit = async (values) => {
    const { notesModal, rows } = this.state;
    const weddingId = values?.wedding?._id ? values?.wedding?._id : values?.wedding;
    if (isCurrentUserEmployeeAttending()) {
      const resultCheck = await CheckMngMultipleByAttending(weddingId);
      if (!resultCheck.success) {
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'O período de edição desta(s) reserva(s) terminou!'
        });
        return;
      }
    }

    // console.warn('values', values);
    try {
      this.setState({ savingNotesModal: true });

      const dataToUpdate = {
        reserveNotes: values.notes,
        visits: values.visits,
        attendedBy: values?.attendedBy
      }
      const { success, data } = await UpdateReserveNotes(weddingId, dataToUpdate);

      if (success && data) {
        Alerts.new({
          type: 'success',
          title: 'Gravado com sucesso',
          text: 'A informação foi gravada com sucesso!'
        });

        this.setState({ openNotesModal: false, notesModal: {}, savingNotesModal: false }, () => {
          this.updateDataTable();
        });
      } else {
        const message = data == 'TIME_CREATE_EDIT_ENDED'
          ? 'O período de edição desta(s) reserva(s) terminou!'
          : data == 'NOT_AUTHORIZED'
            ? 'Não autorizado'
            : data;
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: message
        });
        this.setState({
          savingNotesModal: false,
          openNotesModal: data == 'TIME_CREATE_EDIT_ENDED' || data == 'NOT_AUTHORIZED' ? false : true
        });
        return;
      }
    } catch (e) {
      console.error(e);
      this.setState({ savingNotesModal: false });
    }
  };
  // End Reserve Notes

  // Reserve Table
  navigateToReservesTop = () => {
    const elem = document.getElementById('reservesTop');
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth' });
    }
  }

  handleChangePage = currentPage => {
    this.navigateToReservesTop();
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.navigateToReservesTop();
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  updateDataTable = async () => {
    let { filters, loading } = this.state;
    if (loading) {
      this.setState({ needRefresh: true });
      return;
    }

    if (isCurrentUserEmployeeAttending() && (filters?.search === undefined || filters?.search === null || filters?.search.trim() === '')) {
      this.setState({
        rows: [],
        total: 0,
        //currentPage: 1,
        pageSize: 30,
      });
      return;
    }

    this.setState({ loading: true });
    try {
      const { currentPage, pageSize } = this.state;

      if (isCurrentUserEmployeeAttending()) {
        filters['attendedBy'] = getUserId();
        filters['employeeAttending'] = true;
      }

      // Ignore filter dates if array is empty
      if (filters?.dates && Array.isArray(filters?.dates) && filters?.dates.length === 0) {
        delete filters.dates;
      }
      // Ignore filter status if array is empty
      if (filters?.status && Array.isArray(filters?.status) && filters?.status.length === 0) {
        delete filters.status;
      }

      const result = await GetReservesPaginated(currentPage, pageSize, JSON.stringify(filters));
      // console.warn('Res', result);

      this.setState({
        rows: result?.data?.items || [],
        total: result?.data?.total || 0,
        //currentPage: result.data.page,
        pageSize: result?.data?.limit || 30,
        loading: false
      }, () => {
        if (this.state.needRefresh) {
          this.setState({ needRefresh: false }, () => this.updateDataTable());
        }
      });

      return result;
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = filters => {
    if (isCurrentUserAdmin()
      && filters?.search?.trim() !== '') {
      let { filtersInactive, filtersWeddings } = this.state;
      filtersInactive['search'] = filters?.search;
      filtersWeddings['search'] = filters?.search;
      this.setState({
        filters,
        filtersInactive,
        filtersWeddings
      }, () => this.updateAllDataTables())
    } else {
      this.setState({ filters, currentPage: 1 }, this.updateDataTable);
    }
  };
  // End Reserve Table

  editReserve = (reserve) => {
    const { history } = this.props;

    if (isCurrentUserEmployeeAttending()) {
      return history.push(`/agenda?reserve=${reserve?._id}&page=R`);
    } else {
      return history.push(`/reserves-agenda?reserve=${reserve?._id}&page=R`);
    }
  }

  goToReserveAgenda = (wedding) => {
    const { history } = this.props;

    if (isCurrentUserEmployeeAttending()) {
      return history.push(`/agenda?wedding=${wedding?._id}&page=R`);
    } else {
      return history.push(`/reserves-agenda?wedding=${wedding?._id}&page=R`);
    }
  }

  reorderReserve = async (reserve) => {
    //Check if employee attending still has time to create/edit wedding reserves
    if (isCurrentUserEmployeeAttending()) {
      const weddingId = reserve?.wedding?._id ? reserve?.wedding?._id : reserve.wedding;
      const resultCheck = await CheckMngMultipleByAttending(weddingId);
      if (!resultCheck.success) {
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'O período de edição desta(s) reserva(s) terminou!'
        });
        return;
      }
    }

    const result = await ReorderReserve(reserve?._id);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Reserva passou a último lugar da lista. A reserve encontra-se ativa por 15 dias.'
      });
      await this.updateDataTable();
    } else {
      if (result?.data === 'ERROR_DATE_UNAVAILABLE'
        || result?.data === 'ERROR_DATE_OCCUPIED') {
        const message = result?.data === 'ERROR_DATE_UNAVAILABLE'
          ? 'Função indisponível, data indisponível para reserva.'
          : result?.data === 'ERROR_DATE_OCCUPIED'
            ? 'Não é possivel realizar a operação pois já existe uma data ocupada ou em sinalização.'
            : '';

        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: message
        });
      }
      await this.updateDataTable();
    }
  }

  reactivateReserve = async (reserve) => {
    //Check if employee attending still has time to create/edit wedding reserves
    if (isCurrentUserEmployeeAttending()) {
      const weddingId = reserve?.wedding?._id ? reserve?.wedding?._id : reserve.wedding;
      const resultCheck = await CheckMngMultipleByAttending(weddingId);
      if (!resultCheck.success) {
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'O período de edição desta(s) reserva(s) terminou!'
        });
        return;
      }
    }

    const result = await ReactivateReserve(reserve?._id);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Reserva reativada por mais 15 dias'
      });
      await this.updateDataTable();
    } else {
      if (result?.data === 'ERROR_DATE_OCCUPIED' || result?.data === 'ERROR_CANT_REACTIVATE'
        || result?.data === 'ERROR_REACTIVATE_RESERVES' || result?.data === 'ERROR_DATE_UNAVAILABLE'
        || result?.data === 'ERROR_TAKE_DECISION') {
        const message = result?.data === 'ERROR_DATE_OCCUPIED' ?
          'Não é possivel realizar a operação pois já existe uma data ocupada ou em sinalização.'
          : result?.data === 'ERROR_CANT_REACTIVATE'
            ? 'Função indisponível, prazo limite ultrapassado ou reserva eliminada.'
            : result?.data === 'ERROR_DATE_UNAVAILABLE'
              ? 'Função indisponível, data indisponível para reserva.'
              : result?.data === 'ERROR_TAKE_DECISION'
                ? 'Função indisponível. Existem casais em espera, deve confirmar ou cancelar a reserva.,'
                : '';

        return Alerts.new({
          type: 'error',
          title: 'Erro',
          text: message
        });
      } else if (result?.data === 'ERROR_RESERVE_ACTIVE' || result?.data === 'ERROR_RESERVE_ACTIVE_RELOAD') {
        Alerts.new({
          type: 'warning',
          title: 'Atenção',
          text: 'Reserva encontra-se ativa.'
        });
        if (result?.data === 'ERROR_RESERVE_ACTIVE_RELOAD') this.updateDataTable();
        return;
      } else {
        return Alerts.new({
          type: 'error',
          title: 'Erro',
          text: result?.data
        });
      }
    }
  }

  confirmReserve = async (reserve) => {
    //Check if employee attending still has time to create/edit wedding reserves
    if (isCurrentUserEmployeeAttending()) {
      const weddingId = reserve?.wedding?._id ? reserve?.wedding?._id : reserve.wedding;
      const resultCheck = await CheckMngMultipleByAttending(weddingId);
      if (!resultCheck.success) {
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'O período de edição desta(s) reserva(s) terminou!'
        });
        return;
      }
    }

    const result = await ConfirmReserve(reserve?._id);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Reserva Confirmada'
      });

      this.updateDataTable();
    } else {
      if (result?.data === 'ERROR_DATE_OCCUPIED' || result?.data === 'ERROR_CANT_CONFIRM' || result?.data === 'ERROR_DATE_UNAVAILABLE') {
        const message = result?.data === 'ERROR_DATE_OCCUPIED'
          ? 'Não é possivel realizar a operação pois já existe uma data ocupada ou em sinalização.'
          : result?.data === 'ERROR_CANT_CONFIRM'
            ? 'Função indisponível, reserva eliminada/inativa ou não se encontra em 1º lugar.'
            : result?.data === 'ERROR_DATE_UNAVAILABLE'
              ? 'Função indisponível, data indisponível para reserva.'
              : '';
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: message
        });
      } else {
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: result?.data
        });
      }
    }
  }

  deleteReserve = async (reserve) => {
    //Check if employee attending still has time to create/edit wedding reserves
    if (isCurrentUserEmployeeAttending()) {
      const weddingId = reserve?.wedding?._id ? reserve?.wedding?._id : reserve.wedding;
      const resultCheck = await CheckMngMultipleByAttending(weddingId);
      if (!resultCheck.success) {
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'O período de edição desta(s) reserva(s) terminou!'
        });
        return;
      }
    }

    const result = await SoftDeleteReserve(reserve?._id, true);

    if (result?.success) {
      this.updateDataTable();
      this.updateWeddingDataTable();
    }
  }

  sendContract = async (event, wedding) => {
    event.stopPropagation();

    const result = await UpdateWeddingContractAndPaymentPhases(wedding?._id);
    // console.warn('Result', result);

    if (result?.success) {
      this.updateDataTable();
    } else {
      console.error(result);
    }
  }

  openProcess = record => {
    const { history } = this.props;

    if (isCurrentUserAdmin() && record?.wedding?.status !== WeddingStatus.RESERVE) {
      //Clear previous saved Wedding
      ClearWedding();
      history.push(`/wedding/${record?.wedding?._id}?page=R`);
    } else return;
  };

  editGuestAccess = (event, record) => {
    event.stopPropagation();
    this.setState({ showGuestAccessModal: true, guestWeddingId: record.wedding._id });
  };

  unblockMeetings = async (wedding) => {
    const res = await UnblockMeetings(wedding?._id);
    if (res?.success) {
      Alerts.new({
        type: 'success',
        title: 'Successo',
        text: 'Reuniões desbloqueadas!'
      })
      this.updateDataTable();
    }
  }

  editGeneral = (event, record) => {
    event.stopPropagation();
    const { history } = this.props;

    if (record?.wedding?.status === WeddingStatus.ARCHIVED) {
      if (isCurrentUserAdmin()) history.push(`/archived/wedding/${record?.wedding?._id}?page=R`);
      else return;
    } else if (record?.wedding?.status !== WeddingStatus.RESERVE) {
      if (isCurrentUserAdmin()) history.push(`/weddings/${record?.wedding?._id}?page=R`);
    } else {
      const valuesReserveModal = record?.wedding ? {
        ...record.wedding,
        bridename: record.wedding.bride.name,
        brideemail: record.wedding.bride.email,
        bridecontact: record.wedding.bride.contact,
        groomname: record.wedding.groom.name,
        groomemail: record.wedding.groom.email,
        groomcontact: record.wedding.groom.contact,
        alternativename: record.wedding.alternativeGuest?.name || '',
        alternativemail: record.wedding.alternativeGuest?.email || '',
        alternativecontact: record.wedding.alternativeGuest?.contact || '',
        alternativerelationcouple: record.wedding.alternativeGuest?.relationToCouple || '',
      } : {};

      // Check employee attending
      if (isCurrentUserEmployeeAttending() && record?.wedding?.attendedBy) {
        const attendedById = record?.wedding?.attendedBy?._id ? record?.wedding?.attendedBy?._id : record?.wedding?.attendedBy;
        if (attendedById != getUserId()) {
          Alerts.new({
            type: 'error',
            title: 'Erro',
            text: 'Acesso não autorizado!'
          });
          return;
        }

        if (attendedById === getUserId() && record?.wedding?.attendingAt && moment.utc().isAfter(moment.utc(record?.wedding?.attendingAt).add(15, 'minutes'))) {
          Alerts.new({
            type: 'error',
            title: 'Erro',
            text: 'O período de edição destas reservas terminou!'
          });
          return;
        }
      }

      this.setState({ showReserveModal: true, valuesReserveModal });
    }
  };

  extendEditingTime = async (event, record) => {
    event.stopPropagation();

    const result = await ExtendReserveEditingTime(record?.wedding?._id);
    if (result.success) {
      return Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Tempo de edição prolongado por mais 15 minutos!'
      });
    }
  };

  getConfirmedExpandedRow = (record) => {
    const local = record?.wedding?.wedding_place === 2 ? 'Quinta Lago dos Cisnes' : 'Solar da Levada';
    return <p>Reserva para a data {moment.utc(record?.wedding?.date).format('DD-MM-YYYY')}
      ({moment.utc(record?.wedding?.date).locale('pt').format('dddd')}) no/a {local}.
    </p>
  }

  updateWedding = async (values) => {
    //Check if employee attending still has time to create/edit wedding reserves
    if (isCurrentUserEmployeeAttending()) {
      const resultCheck = await CheckMngMultipleByAttending(values._id);
      if (!resultCheck.success) {
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'O período de edição terminou!'
        });
        this.setState({ showReserveModal: false, valuesReserveModal: {} });
        return;
      }
    }

    // if ((!values?.visitedPlace || values?.visitedPlace == false)
    //   && (!values?.placeToVisit || values?.placeToVisit == false)
    //   && (!values?.wantToVisitWithoutInfo || values?.wantToVisitWithoutInfo == false)) {
    //   Alerts.new({
    //     type: 'warning',
    //     title: 'Atenção',
    //     text: 'Indique se já visitou ou pretende visitar algum dos espaços.'
    //   });
    //   return;
    // }

    const data = setDataToUpdateForReserveWedding(values);
    const result = await UpdateReserveRelatedInfo(values._id, data);
    // console.warn('resultCoupleWedding', resultCoupleWedding);

    if (result.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Informação guardada'
      });
      this.setState({
        showReserveModal: false,
        valuesReserveModal: {}
      }, () => {
        this.updateDataTable();
        this.updateWeddingDataTable();
      });
    }
  }

  // Export Methods
  onSelectExportMenu = (value) => {
    const itemSelected = parseInt(value.key);
    // console.warn('itme', itemSelected);

    // Download
    if (itemSelected === 1) {
      this.exportReserves();
    }
    // Upload to Google Drive
    else if (itemSelected === 2) {
      this.uploadReservesToGoogleDrive();
    }
  }

  exportMenu = (
    <Menu onClick={this.onSelectExportMenu}>
      <Menu.Item key={1}>
        <Icon type="file-protect" />
        Download
      </Menu.Item>
      <Menu.Item key={2}>
        <Icon type="cloud-download" />
        Google Drive
      </Menu.Item>
    </Menu>
  );

  exportReserves = async () => {
    let { filters } = this.state;
    this.setState({ download: true });
    let result;

    // Export all reserves information
    result = await DownloadReserves(JSON.stringify(filters));

    if (result) {
      let filename = 'Reservas_' + moment().format('YYYYMMDD') + '.xlsx';

      if (result.headers) {
        const contentDisposition = result.headers.get('content-disposition');
        filename = contentDisposition.split('filename="')[1].split('"')[0];
      }

      //Create blob link to download
      const url = window.URL.createObjectURL(new Blob([result])); //result.blob
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);

      //Append to html link element page
      document.body.appendChild(link);

      //Start download
      link.click();

      //Clean up and remove the link
      link.parentNode.removeChild(link);
    }

    this.setState({ download: false });
  };

  uploadReservesToGoogleDrive = async () => {
    let { filters } = this.state;
    this.setState({ upload: true });

    const result = await AuthGoogleDrive();
    // console.warn('result', result);

    if (!result?.data?.active) {
      localStorage.setItem('googleReturnUrl', window.location.pathname);
      const newWindow = window.open(result?.data.authUrl, '_self', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null;
      this.setState({ upload: false });
      return;
    }

    const res = await UploadReservesToDrive(JSON.stringify(filters));
    // console.warn('res', res);

    this.setState({ upload: false });
    if (res?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Upload com sucesso para o Google Drive!'
      });
    }
  }

  // Inactive reserves table
  navigateToInactiveTop = () => {
    const elem = document.getElementById('reservesInactive');
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth' });
    }
  }

  handleInactiveChangePage = currentPageInactive => {
    this.navigateToInactiveTop();
    this.setState({ currentPageInactive }, this.updateInactiveDataTable);
  };

  handleInactiveChangeRowsPerPage = (currentSize, pageSizeInactive) => {
    this.navigateToInactiveTop();
    this.setState({ pageSizeInactive, currentPageInactive: 1 }, this.updateInactiveDataTable);
  };

  updateInactiveDataTable = async () => {
    if (!isCurrentUserAdmin()) return;

    let { filtersInactive, loadingInactive } = this.state;
    if (loadingInactive) {
      this.setState({ needRefreshInactive: true });
      return;
    }

    this.setState({ loadingInactive: true });
    try {
      const { currentPageInactive, pageSizeInactive } = this.state;

      // Ignore filter dates if array is empty
      if (filtersInactive?.dates && Array.isArray(filtersInactive?.dates) && filtersInactive?.dates.length === 0) {
        delete filtersInactive.dates;
      }
      // Only show inactive reserves
      const endFilters = {
        ...filtersInactive,
        status: [ReserveStatus.INACTIVE]
      }

      const result = await GetReservesPaginated(currentPageInactive, pageSizeInactive, JSON.stringify(endFilters));
      // console.warn('Res', result);

      this.setState({
        rowsInactive: result?.data?.items || [],
        totalInactive: result?.data?.total || 0,
        //currentPageInactive: result.data.page,
        pageSizeInactive: result?.data?.limit || 30,
        loadingInactive: false
      }, () => {
        if (this.state.needRefreshInactive) {
          this.setState({ needRefreshInactive: false }, () => this.updateInactiveDataTable());
        }
      });

      return result;
    } catch (e) {
      this.setState({ loadingInactive: false });
      console.error(e);
    }
  };

  handleInactiveFilterChange = filtersInactive => {
    this.setState({ filtersInactive, currentPageInactive: 1 }, this.updateInactiveDataTable);
  };

  // Weddings Table
  navigateToWeddingsTop = () => {
    const elem = document.getElementById('weddingsNoReserves');
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth' });
    }
  }

  handleWeddingChangePage = currentPageWeddings => {
    this.navigateToWeddingsTop();
    this.setState({ currentPageWeddings }, this.updateWeddingDataTable);
  };

  handleWeddingChangeRowsPerPage = (currentSize, pageSizeWeddings) => {
    this.navigateToWeddingsTop();
    this.setState({ pageSizeWeddings, currentPageWeddings: 1 }, this.updateWeddingDataTable);
  };

  updateWeddingDataTable = async () => {
    if (this.state.loadingWeddings) {
      this.setState({ needRefreshWedding: true });
      return;
    }

    this.setState({ loadingWeddings: true });
    try {
      const { currentPageWeddings, pageSizeWeddings } = this.state;
      let { filtersWeddings } = this.state;

      const result = await GetWeddingsNoReserves(currentPageWeddings, pageSizeWeddings, JSON.stringify(filtersWeddings));

      this.setState({
        rowsWeddings: result.data.items || [],
        totalWeddings: result.data.total || 0,
        pageSizeWeddings: result.data.limit || 15,
        loadingWeddings: false
      }, () => {
        if (this.state.needRefreshWedding) {
          this.setState({ needRefreshWedding: false }, () => this.updateWeddingDataTable());
        }
      });

      return result;
    } catch (e) {
      this.setState({ loadingWeddings: false });
      console.error(e);
    }
  };

  handleWeddingsFilterChange = filtersWeddings => {
    this.setState({ filtersWeddings, currentPageWeddings: 1 }, this.updateWeddingDataTable);
  };

  createReservesOnWeddingNoReserves = (wedding) => {
    const { history } = this.props;

    if (isCurrentUserEmployeeAttending()) {
      history.push(`/agenda?wedding=${wedding?._id}&page=R`);
    } else {
      history.push(`/reserves-agenda?wedding=${wedding?._id}&page=R`);
    }
  }

  deleteWedding = async (wedding, password) => {
    this.setState({ deletingWedding: true });
    const res = await SoftDeleteWaitingWedding(wedding?._id, true, JSON.parse(localStorage.user).email, password);
    if (res?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Casal eliminado com sucesso!'
      });
      this.setState({
        openModalDelete: false,
        weddingToDelete: null,
        deletingWedding: false,
      }, () => {
        this.updateWeddingDataTable();
      });
    }
    else {
      this.setState({
        openModalDelete: false,
        weddingToDelete: null,
        deletingWedding: false,
      });
    }
  };
  // End Weddings Table

  render() {
    const { history } = this.props;
    const { columns, columnsAttending, columnsInactive, columnsWeddings } = this.state;
    const { rows, total, loading, currentPage, pageSize, filters } = this.state;
    const { showReserveModal, valuesReserveModal } = this.state;
    const { download, upload } = this.state;
    const { loadingNotesModal, openNotesModal, notesModal, savingNotesModal } = this.state;
    const { rowsWeddings, totalWeddings, loadingWeddings, currentPageWeddings, pageSizeWeddings, filtersWeddings } = this.state;
    const { showGuestAccessModal, guestWeddingId } = this.state;
    const { showDateModal, reserveToEdit, savingDate } = this.state;
    const { openModalDelete, weddingToDelete, deletingWedding } = this.state;
    const { rowsInactive, totalInactive, loadingInactive, currentPageInactive, pageSizeInactive, filtersInactive } = this.state;

    const countBtns = isCurrentUserAdmin() ? 2 : 1;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={countBtns}>
            <PageTitle>Reservas</PageTitle>
            {/* Lista de Reservas */}
            <ReserveAnchor affix={true} targetOffset={200}>
              <Link href="#reservesTop" title="Reservas ativas" />
              <Link href="#reservesInactive" title="Reservas inativas" />
              <Link href="#weddingsNoReserves" title="Casais sem reservas" />
            </ReserveAnchor>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={countBtns}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Nova Reserva'}
              onClick={() => isCurrentUserEmployeeAttending() ? history.push('/agenda') : history.push('/reserves-agenda')}
            />
            {isCurrentUserAdmin() &&
              <Dropdown disabled={download || loading} overlay={this.exportMenu} trigger={['click']}>
                <Button loading={upload || download} style={{ marginLeft: 5 }}>
                  Exportar
                  <Icon type="down" />
                </Button>
              </Dropdown>}
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer id="reservesTop" buttons={countBtns}>

          {isCurrentUserEmployeeAttending()
            ? <AttendingReservesFilters loading={loading} queryChange={this.handleFilterChange} />
            : <ReservesFilters search={filters?.search} loading={loading} queryChange={this.handleFilterChange} />}
          <Table
            columns={isCurrentUserEmployeeAttending() ? columnsAttending : columns}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            autoScrollTop={false}
            // scroll={{ x: 1700 }}
            emptyIcon="calendar"
            emptyText={'Não existem reservas'}
            total={total}
            rowKey={data => data?._id}
            hasPagination={true}
            onPressRow={this.openProcess}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />

          {isCurrentUserAdmin() && <React.Fragment>
            <SectionTitle id="reservesInactive" subsection>Reservas Inativas</SectionTitle>
            <ReservesFilters search={filtersInactive?.search} inactive={true} loading={loading} queryChange={this.handleInactiveFilterChange} />
            <Table
              columns={columnsInactive}
              currentPage={currentPageInactive}
              pageSize={pageSizeInactive}
              defaultPageSize={pageSizeInactive}
              loading={loadingInactive}
              rows={rowsInactive}
              showHeader={true}
              autoScrollTop={false}
              emptyIcon="calendar"
              emptyText={'Não existem reservas inativas'}
              total={totalInactive}
              rowKey={data => data?._id}
              hasPagination={true}
              handleChangePage={this.handleInactiveChangePage}
              handleChangeRowsPerPage={this.handleInactiveChangeRowsPerPage}
            />
          </React.Fragment>}

          <SectionTitle id="weddingsNoReserves" subsection>Casais Sem Reservas</SectionTitle>
          <WeddingNoReservesFilters search={filtersWeddings?.search} loading={loadingWeddings} queryChange={this.handleWeddingsFilterChange} />
          <Table
            columns={columnsWeddings}
            currentPage={currentPageWeddings}
            pageSize={pageSizeWeddings}
            defaultPageSize={pageSizeWeddings}
            loading={loadingWeddings}
            rows={rowsWeddings}
            showHeader={true}
            autoScrollTop={false}
            emptyIcon="calendar"
            emptyText={'Não existem casais sem reservas'}
            total={totalWeddings}
            rowKey={data => data?._id}
            hasPagination={true}
            handleChangePage={this.handleWeddingChangePage}
            handleChangeRowsPerPage={this.handleWeddingChangeRowsPerPage}
          />

          {showReserveModal && <ReserveInfoModal
            isOpen={showReserveModal}
            onClose={() => this.setState({ showReserveModal: false, valuesReserveModal: {} })}
            onSubmit={this.updateWedding}
            initialValues={valuesReserveModal}
            onlyCoupleInfo={true}
          />}

          {openNotesModal &&
            <ReserveNotesModal
              isOpen={openNotesModal}
              onSubmit={this.onNotesModalSubmit}
              saving={savingNotesModal}
              onClose={() => this.setState({ openNotesModal: false, notesModal: {}, savingNotesModal: false })}
              initialValues={notesModal}
            />}

          {showGuestAccessModal
            && <AccessGuestModal
              isOpen={showGuestAccessModal}
              onClose={() => this.setState({ showGuestAccessModal: false, guestWeddingId: null })}
              weddingId={guestWeddingId}
            />}

          {showDateModal && <ReserveDateModal
            isOpen={showDateModal}
            onSubmit={this.onDateSubmit}
            saving={savingDate}
            onClose={() => this.setState({ showDateModal: false, reserveToEdit: {}, savingDate: false })}
            initialValues={reserveToEdit} />}

          {openModalDelete
            && <ModalDeleteWaitingWedding
              isOpen={openModalDelete}
              deleting={deletingWedding}
              initialValues={{ wedding: weddingToDelete }}
              onSubmit={($event) => this.deleteWedding($event.wedding, $event.password)}
              onClose={() => this.setState({ openModalDelete: false, weddingToDelete: null })}
            />}
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({ wedding: state.wedding });

const mapActionToProps = dispatch => bindActionCreators({ ClearWedding }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(ReservesPage);
