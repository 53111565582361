import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Row, Col} from 'antd';
import {TableFilterSection} from '../../styles/BasicStyles';
import SelectInput from '../../components/inputs/SelectInput';
import DateInput from '../../components/inputs/DateInput';
import {getOrganizers} from '../../infra/requests/AdminsRequests';
import moment from 'moment';
import MonthInput from '../../components/inputs/MonthInput';

class EmployeeProfileReserveFilters extends Component {
  state = {
    search: '',
    weddingPlace: '',
    month: moment().startOf('month').format('MM/YYYY'),
  };

  componentDidMount = async () => {
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({[field]: value}, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const {queryChange} = this.props;
    const query = {};
    Object.keys(this.state).map(key => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(query);
  };

  render() {
    const {locals} = this.props;
    const {weddingPlace, month} = this.state;
    
    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={4} lg={3}>
            <MonthInput
              label="Mês"
              placeholder="Escolher mês"
              input={{
                value: month,
                onChange: value => this.onInputChange('month', value)
              }}
              allowClear={true}
              meta={{valid: true}}
            />
          </Col>
          {/* <Col xs={24} md={4}>
            <DateInput
              label="Date"
              placeholder="Escolher uma data"
              input={{
                value: date,
                onChange: value => this.onInputChange('date', value)
              }}
              allowClear={true}
              meta={{valid: true}}
            />
          </Col> */}
          <Col xs={24} sm={12} md={6} lg={4}>
            <SelectInput
              label="Local"
              allowClear
              placeholder="Escolher local"
              data={locals}
              input={{
                value: weddingPlace,
                onChange: value => this.onInputChange('weddingPlace', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          {/* <Col xs={24} md={4}>
            <SelectInput
              label="Subcategoria"
              allowClear
              placeholder="Escolher subcategoria"
              data={subcategories}
              input={{
                value: organizer,
                onChange: value => this.onInputChange('subcategory', value)
              }}
              meta={{valid: true}}
            />
          </Col> */}
        </Row>
      </TableFilterSection>
    );
  }
}

const mapStateToProps = state => ({
  locals: state.locals
});

export default connect(mapStateToProps)(EmployeeProfileReserveFilters);
