import React, { Component, Fragment } from 'react';
import {SectionTitle,} from '../../styles/BasicStyles';
import { withRouter } from 'react-router-dom';
import PendingPayments from '../payments/PendingPayments';
import ToBePayed from '../payments/ToBePayed';
import PaidPayments from '../payments/PaidPayments';

class EmployeePayments extends Component {
  state = {
    pageSizeToPay: 30,
    pageSizePendent: 30,
    pageSizePayed: 30,

    currentPageToPay: 1,
    currentPagePendent: 1,
    currentPagePayed: 1,

    loading: false,
    showPreviewPDF: false,

    updateChild: 1,

    rowsToPay: [],
    rowsPendent: [],
    rowsPayed: [],

    colors: {
      colorSys1: null,
      colorSys2: null
    },
  };

  componentDidMount = async () => {
    
  };

  render() {
    const { history } = this.props;
    const { updateChild } = this.state;

    return (
      <React.Fragment>
        <SectionTitle subsection>Pagamentos a Realizar</SectionTitle>
        <div style={{ position: 'relative' }}>
          <ToBePayed type={1} employeeId={this.props.match.params.id} updateChild={updateChild} setUpdateChild={() => this.setState({ updateChild: 2 })} />
        </div>

        <SectionTitle subsection>Pagamentos Pendentes</SectionTitle>
        <div style={{ position: 'relative' }}>
          <PendingPayments type={1} employeeId={this.props.match.params.id} updateChild={updateChild} setUpdateChild={() => this.setState({ updateChild: 2 })} />
        </div>

        <SectionTitle subsection>Pagamentos Realizados</SectionTitle>
        <div style={{ position: 'relative' }}>
          <PaidPayments type={1} employeeId={this.props.match.params.id} updateChild={updateChild} setUpdateChild={() => this.setState({ updateChild: 2 })} />
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(EmployeePayments);
