import styled from 'styled-components';
import { Spin } from 'antd';

export const TableContainer = styled.div`
  border: 1px solid #e8e8e8;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.secondaryColor};
  border-bottom: 1px solid #e8e8e8;
`;

export const TableHeaderColumn = styled.div`
  width: ${({ width }) => width};
  text-align: left;
`;

export const TableHeaderColumnContent = styled.div`
  padding: 8px 11px;
`;

export const TableLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
`;

export const TableColumn = styled.div`
  width: ${({ width }) => width};
  height: 38px;
  border-right: 1px solid #e8e8e8;

  & > div {
    margin-top: 0;
  }

  & .ant-input, & .ant-input-number, & .ant-select-selection, & .ant-input-number:hover, & .ant-input-number-input {
    border-radius: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
  }

  & .ant-select-selection--single {
    height: 38px;
  }
  & .ant-select-selection__rendered {
    line-height: 38px;
  }

  & .ant-input-number {
    height: 38px;
  }
  & .ant-input-number-input {
    height: 38px;
  }

  & .ant-input {
    height: 38px;
  }

  & .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.65);
    cursor: default;
    background-color: transparent;
  }
`;

export const NoResults = styled.div`
  padding: 20px 10px;
`;

export const SpinLoading = styled(Spin)`
  display: flex;
  width: 100%;
  padding: 20px 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
`;