import ChurchIcon from '../../assets/menu/ic_church.svg';
import UserIcon from '../../assets/menu/ic_user2.svg';
import HomeIcon from '../../assets/menu/ic_home.svg';
import CalendarIcon from '../../assets/menu/ic_calendar.svg';
import PaymentIcon from '../../assets/menu/ic_payment.svg';

export default [
  {
    type: 'menu',
    to: '/home',
    external_icon: true,
    size: 20,
    icon: HomeIcon,
    name: 'Home',
    exact: true
  },
  {
    type: 'menu',
    to: '/calendar',
    external_icon: true,
    size: 20,
    icon: CalendarIcon,
    name: 'Calendário',
    exact: true
  },
  {
    type: 'menu',
    to: '/employee-weddings',
    external_icon: true,
    size: 20,
    icon: ChurchIcon,
    name: 'Casamentos',
    exact: true
  },
  {
    type: 'menu',
    to: '/payments',
    external_icon: true,
    size: 20,
    icon: PaymentIcon,
    name: 'Pagamentos',
    exact: true
  },
  {
    type: 'menu',
    to: '/profile',
    external_icon: true,
    size: 20,
    icon: UserIcon,
    name: 'Perfil',
    exact: true
  },
];
