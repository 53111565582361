import styled from 'styled-components';
import { Collapse } from 'antd';
import { size } from '../../styles/Responsive';

export const DecorContainer = styled.div`
  width: 100%;
  margin-top: ${({ subpage, printed }) => printed ? 125 : subpage ? 0 : 80}px;
  display: inline-block;

  @media (max-width:${size.tablet}) {
    margin-top: ${({ subpage, printed }) => printed ? 125 : subpage ? 0 : 100}px;
  }
`;

export const PageContainer = styled.div`
  width: calc(100% - 20px);
  max-width: ${({ singleColumn }) => (singleColumn ? '700px' : '100%')};
  height: ${({ printed }) => printed ? 'calc(100vh - 195px)' : 'calc(100vh - 150px)'};
  text-align: left;
  position: relative;
  display: inline-block;
  padding: 20px 8px;
  line-height: 1;
  background-color: ${p => p.theme.primaryBackgroundColor};

  @media screen and (max-width: 575px) {
    max-width: 100%;
  }
`;

export const Observation = styled.div`
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  margin-top: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
`;

export const NotesContainer = styled.div`
  margin-top: 40px;
  background-color: #faf9f9;
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
`;