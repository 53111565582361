import React, {Fragment} from 'react';
import {notification, Icon} from 'antd';
import Dropzone from 'react-dropzone';
import {ResponsePill, ExtraPill, FileSection} from './Styles';

const FileInput = ({input, execAfterChange}) => {
  const buildImageObject = blob => ({
    preview: URL.createObjectURL(blob),
    size: blob.size,
    type: blob.type,
    blob: blob
  });

  const handleDrop = async (accepted, rejected) => {
    if (accepted.length <= 0 || (rejected && rejected.length > 0)) {
      return notification.error({
        message: 'Ficheiro Inválido',
        description: 'O ficheiro é inválido ou tem um tamanho superior a 12Mb'
      });
    }

    const uploaded = buildImageObject(accepted[0]);
    input.onChange(uploaded);

    if(typeof execAfterChange === "function") {
      await execAfterChange(uploaded);
    }
  };

  const renderDropZone = () => {
    return (
      <Dropzone
        multiple={false}
        onDrop={handleDrop}
        accept=""
        maxSize={120000000}>
        {({getRootProps, getInputProps}) => {
          return (
            <FileSection {...getRootProps()}>
              <input {...getInputProps()} />
              <Icon type="file" />
              Clique ou arraste um ficheiro
            </FileSection>
          );
        }}
      </Dropzone>
    );
  };

  if(input.value) {
    const size = (input.value.size / (1024 * 1024)).toFixed(2);

    return (
      <Fragment>
        <ResponsePill single={false}>{input.value.blob.name}</ResponsePill>
        <ExtraPill last={true}>{size}MB</ExtraPill>
        {renderDropZone()}
      </Fragment>
    );
  }

  return renderDropZone();
};

export default FileInput;