import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { bindActionCreators } from 'redux';
import {
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  SpinLoading,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import { ChoicesPageContainer } from '../ChoicesPage/SectionStyles';
import { GetOurStaff, SaveStaff } from '../../infra/requests/WeddingProcessRequests';
import StaffMenu from './StaffMenu';
import StaffContent from './StaffContent';
import { SaveNotes } from '../../redux/wedding/wedding.actions';
import { SaveSectionNotes } from '../../infra/requests/WeddingProcessRequests';
//import CommentsDrawer from '../../components/comments/CommentsDrawer';
import BaseButton from '../../components/buttons/BaseButton';
import AddStaffModal from './AddStaffModal';
import { categories } from "./Categories";
import NotesConfirmModal from '../../components/notesModal/NotesModal';
import { hasNotes } from '../../infra/services/notes/FindNotes';
import PrintedBar from '../dashboard/components/PrintedBar';
import CommentsDrawerFixed from '../../components/comments/CommentsDrawerFixed';
import { getPrintedConfirmation, getStaffPrintedConfirmation } from '../../infra/services/wedding/printedUtils';
import { Modal } from 'antd';

const {confirm} = Modal;
class DecorationPage extends Component {
  state = {
    loading: true,
    loadNotes: false,
    openDrawer: false,
    section: [],
    external: [],
    openModal: false,
    requiredError: false,
    showNewCategory: false,
    editStaffIndex: undefined,
    openNotesConfirm: false
  };

  componentDidMount() {
    this.getInformation();
  }

  getInformation = async () => {
    const { wedding, match: { params }, upgrade } = this.props;
    const section = wedding.structure.find(s => s.tag === 'STAFF');

    const { data } = await GetOurStaff(params.wedding);

    if (data && data.list.length > 0) {
      for (let i = 0; i < data.list.length; i++) {
        if (
          data.list[i].category &&
          data.list[i].category !== 'Criar' &&
          !categories['pt'].includes(data.list[i].category) &&
          !categories['en'].includes(data.list[i].category) &&
          !categories['fr'].includes(data.list[i].category) &&
          !categories['es'].includes(data.list[i].category)
        ) {
          categories['pt'].push(data.list[i].category);
          categories['en'].push(data.list[i].category);
          categories['fr'].push(data.list[i].category);
          categories['es'].push(data.list[i].category);
        }
      }
    }

    this.setState({
      loading: false,
      section,
      external: data?.list || []
    });
  }

  toggleShowNew = (state) => {
    this.setState({
      showNewCategory: state
    });
  };

  openModal = () => {
    this.setState({
      openModal: true
    });
  };

  openEditModal = (staff, index) => {
    const { dispatch } = this.props;

    dispatch(initialize('manage_staff_form', staff));

    this.setState({
      openModal: true,
      editStaffIndex: index
    });
  };

  closeModal = () => {
    const { dispatch } = this.props;

    //Clear the form (don't use "reset", as it will lose the Form Validations)
    dispatch(initialize('manage_staff_form'));

    this.setState({
      openModal: false,
      showNewCategory: false,
      requiredError: false,
      editStaffIndex: undefined
    });
  };

  onModalSubmit = async values => {
    const { wedding } = this.props;

    if (wedding?.wedding?.printed) {
      confirm({
        ...getStaffPrintedConfirmation(),
        onOk: () => { this.saveModal(values); },
        onCancel: () => { this.closeModal(); },
      });
    } else this.saveModal(values);
  }

  saveModal = async values => {
    try {
      const { wedding, dispatch } = this.props;
      const { external, editStaffIndex } = this.state;

      //If we're creating a New Type of Staff
      if (values.category === 'Criar') {
        //It's required
        if (!values.newCategory) {
          this.setState({
            requiredError: true
          });

          return false;
        }

        values.category = values.newCategory;
        values.newCategory = '';
      }

      if (external.length > 0 && editStaffIndex >= 0) {
        external[editStaffIndex] = values;
      }
      else {
        external.push(values);
      }

      this.setState({
        // loading: true,
        requiredError: false,
        showNewCategory: false
      });

      await SaveStaff(wedding.wedding._id, { list: external });

      //Clear the form (don't use "reset", as it will lose the Form Validations)
      dispatch(initialize('manage_staff_form'));

      this.closeModal();
      await this.getInformation();
    }
    catch (e) {
      console.error(e);
    }
  }

  deleteStaff = async (index) => {
    const { wedding } = this.props;
    const { external } = this.state;

    external.splice(index, 1);

    await SaveStaff(wedding.wedding._id, { list: external });

    await this.getInformation();
  }

  openNotes = () => {
    const { wedding, dispatch } = this.props;
    const { openDrawer } = this.state;
    if (!openDrawer) {
      const notes = wedding.notes.find(note => note.section === 'STAFF');
      dispatch(initialize('wedding_notes_section_form', notes));
      this.setState({ openDrawer: true });
    }
  };

  closeNotes = () => {
    const { wedding, dispatch } = this.props;
    const notes = wedding.notes.find(note => note.section === 'STAFF');
    dispatch(initialize('wedding_notes_section_form', notes));
    this.setState({ openDrawer: false, openNotesConfirm: false });
  };

  checkBeforeCloseNotes = (values) => {
    const { wedding, dispatch } = this.props;
    const notes = wedding.notes.find(note => note.section === 'STAFF');

    //If theres any change to the notes, ask before closing if we want to save the changes
    if (values && (notes?.notes !== values.notes || notes?.private !== values.private)) {
      dispatch(initialize('notes_modal_confirm_form', values));
      this.setState({ openNotesConfirm: true });
    }
    //Otherwise, just the close the notes
    else {
      this.setState({ openDrawer: false });
    }
  }

  saveNotes = async values => {
    const { wedding } = this.props;

    if (wedding?.wedding?.printed) {
      confirm({
        ...getPrintedConfirmation(),
        onOk: () => { this.updateNotes(values); },
        onCancel: () => { this.closeNotes(); },
      });
    } else this.updateNotes(values);
  };

  updateNotes = async values => {
    const { wedding, saveNotesOnReducer, dispatch } = this.props;
    this.setState({ loadNotes: true });
    const { data } = await SaveSectionNotes(wedding.wedding._id, {
      ...values,
      section: 'STAFF'
    });

    dispatch(initialize('wedding_notes_section_form', data));
    saveNotesOnReducer(data);
    this.setState({ loadNotes: false, openDrawer: false, openNotesConfirm: false });
  };

  render() {
    const { wedding } = this.props;
    const { section, loading, external, loadNotes, openDrawer, editStaffIndex, openModal, requiredError, showNewCategory, openNotesConfirm } = this.state;

    if (loading) return <SpinLoading />;

    return (
      <Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Staff</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Novo Staff Externo'}
              onClick={() => this.openModal()}
            />
            <BaseButton
              type={'default'}
              icon={'read'}
              text={'Notas'}
              onClick={this.openNotes}
              notes={hasNotes(wedding.notes, 'STAFF')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        {
          wedding?.wedding?.printed && <PrintedBar small={true} />
        }
        <ChoicesPageContainer printed={wedding?.wedding?.printed}>
          <StaffMenu section={section} printed={wedding?.wedding?.printed} />
          <StaffContent
            section={section}
            external={external}
            editStaff={this.openEditModal}
            deleteStaff={this.deleteStaff}
            printed={wedding?.wedding?.printed}
            notesOpen={openDrawer}
          />
          <CommentsDrawerFixed
            open={openDrawer}
            loading={loadNotes}
            onClose={this.checkBeforeCloseNotes}
            saveNotes={this.saveNotes}
            printed={wedding?.wedding?.printed}
          />
        </ChoicesPageContainer>
        <AddStaffModal
          open={openModal}
          editStaff={editStaffIndex}
          onSubmit={this.onModalSubmit}
          closeModal={this.closeModal}
          categories={categories}
          requiredError={requiredError}
          showNewCategory={showNewCategory}
          toggleShowNew={this.toggleShowNew}
        />
        {/* <CommentsDrawer
          title="Notas De Staff"
          open={openDrawer}
          loading={loadNotes}
          onClose={this.checkBeforeCloseNotes}
          saveNotes={this.saveNotes}
        /> */}
        <NotesConfirmModal
          open={openNotesConfirm}
          loading={loading}
          onSubmit={this.saveNotes}
          closeModal={() => { this.setState({ openNotesConfirm: false }) }}
          exitWithoutSaving={this.closeNotes}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  wedding: state.wedding
});

const mapActionToProps = dispatch =>
  bindActionCreators({ saveNotesOnReducer: SaveNotes, dispatch }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(DecorationPage);
