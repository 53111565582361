import styled from 'styled-components';

export const SectionBox = styled.div`
  display: block;
  width: 100%;
  min-height: 100px;
  padding: 24px;
  background: ${({color}) => color || '#fff'};
  color: ${({text}) => text || '#000'};
  position: relative;

  h1,
  h2,
  h3 {
    color: ${({text}) => text || '#000'};
  }
`;

export const SectionImage = styled.div`
  padding: 5px;
  border: 1px solid ${({color}) => color || '#000'};
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const DraftText = styled.div`
  display: inline-block;
  width: 100%;
  text-align: left;
`;

export const OptionsSelection = styled.div`
  margin-top: 30px;
`;

export const OptionButton = styled.div`
  cursor: pointer;
  color: #000;
  position: absolute;
  background: white;
  padding: 5px 8px;
  top: 12px;
  right: ${({number}) => 12 + number * 40}px;
  box-shadow: 1px 1px 3px #ccc;
`;
