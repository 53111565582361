export const QuestionTypes = {
  1: 'Texto',
  2: 'Área de texto',
  3: 'Número',
  4: 'Selecionar uma opção',
  5: 'Selecionar multiplas opções',
  6: 'Escolher de uma lista',
  7: 'Upload de ficheiro',
  8: 'Upload de imagem',
  9: 'Informação',
  10: 'Confirmação',
  11: 'Listagem de Cores',
  12: 'Pratos de entrada',
  13: 'Pratos de Carne',
  14: 'Pratos de Peixe',
  15: 'Pratos de Sobremesa',
  16: 'Opções de Bolo',
  17: 'Opções de Ceia'
};

export const GetQuestionType = value => QuestionTypes[value];

export const GetQuestionOptions = () => {
  return [
    {
      value: 'input',
      label: 'Inserção',
      children: [
        {value: 1, label: 'Texto'},
        {value: 2, label: 'Área de texto'},
        {value: 3, label: 'Número'}
      ]
    },
    {
      value: 'options',
      label: 'Opções',
      children: [
        {value: 4, label: 'Selecionar uma opção'},
        {value: 5, label: 'Selecionar multiplas opções'},
        {value: 6, label: 'Escolher de uma lista'}
      ]
    },
    {
      value: 'upload',
      label: 'Upload',
      children: [
        {value: 7, label: 'Upload de ficheiro'},
        {value: 8, label: 'Upload de imagem'}
      ]
    },
    {value: 9, label: 'Informação'},
    {value: 10, label: 'Confirmação'},
    {value: 11, label: 'Listagem de Cores'},
    {
      value: 'food',
      label: 'Catering',
      children: [
        {value: 12, label: 'Pratos de entrada'},
        {value: 13, label: 'Pratos de Carne'},
        {value: 14, label: 'Pratos de Peixe'},
        {value: 15, label: 'Pratos de Sobremesa'},
        {value: 16, label: 'Opções de Bolo'},
        {value: 17, label: 'Opções de Ceia'}
      ]
    }
  ];
};

export const QuestionHaveOptions = type => {
  if (type === 4 || type === 5 || type === 6) return true;
  return false;
};

export default QuestionTypes;
