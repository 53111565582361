import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, message, Input, Popconfirm, Tooltip, Select, Spin } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector, change } from 'redux-form';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { BaseForm, SpinLoading } from '../../styles/BasicStyles';
import PropTypes from 'prop-types';
import DraftInput from '../../components/inputs/DraftInput';
import SelectInput from '../../components/inputs/SelectInput';
import { dateFormat, dateTimeFormat, isCurrentUserAdmin, isCurrentUserEmployeeAttending } from '../../infra/helpers/Helpers';
import { CreateCoupleReserve, GetAttendingEmployees, GetAttendingList, GetAvailableAttendingList, GetOrganizerList, GetWeddingGuests } from '../../infra/requests/UsersRequests';
import TextInput from '../../components/inputs/TextInput';
import SelectCompactInput from '../../components/inputs/SelectCompactInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import { GenerateAccessCode, GetConfirmedWeddings, GetWedding, GetWeddingsSelect, MngGuestAccess, NotifyWeddingGuest, UpdateCreateWeddingGuest } from '../../infra/requests/WeddingRequests';
import Alerts from '../../components/alert/Alert';
import { CodeInput, CodeP } from '../../process/ChoicesPage/SectionStyles';
import BaseButton from '../../components/buttons/BaseButton';
import { bindActionCreators } from 'redux';
import { getUserLabel, getWeddingFullName, getWeddingLabelSelect, getWeddingName, getWeddingTitle } from '../../infra/services/wedding/weddingUtils';
import styledComponents from 'styled-components';
import { CheckSchedule, GetPossibleScheduleTime, GetSchedule, ValidateScheduleType } from '../../infra/requests/SchedulesRequests';
import { GetAttendingTypes } from '../../infra/requests/AttendingTypeRequests';
import { visitedLocals } from './SchedulesFilters';
import { TimeInput } from '../../components/inputs/InputStyles';
import XpertGoTimeInput from '../../components/inputs/XpertGoTimeInput';
import DateInput from '../../components/inputs/DateInput';
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import debounce from 'lodash.debounce';
import ReserveInfoModal from '../reservesAttending/ReserveInfoModal';
import { GetScheduleServicesList } from '../../infra/requests/ScheduleServiceRequests';
import XpertGoDateTimeInput from '../../components/inputs/XpertGoDateTimeInput';
import { setDataToUpdateForReserveWedding } from '../reserves/reservesUtils';
import { OrganizerLanguageOptins, ScheduleTypes } from './utilSchedules';
import SelectLanguageInput from '../../components/inputs/SelectLanguageInput';

const { confirm } = Modal;

const CreateCoupleMessage = (
    <div>
        <div>Tem a certeza que pretende criar casal de reserva?</div>
        <div>
            O casal criado estará na fase de reservas e receberá email para configurar as suas palavras-passes.
        </div>
    </div>
);

const validations = (values) => {
    let errors = {};

    errors = FormValidator.make({
        date: 'required',
        wedding: 'required',
        type: 'required',
        language: 'required',
        attendingType: 'required',
        weddingPlace: 'required',
        // attendedUsers: 'required',
        attendedBy: 'required',
        startTime: 'required'
    })(values);

    return errors;
};

class MeetingModal extends React.Component {
    state = {
        loadingWeddings: false,
        fetchingWeddings: false,
        weddings: [],

        loading: false,
        schedule: null,
        saving: false,

        loadingAttendingTypes: false,
        attendingTypes: [],

        loadingAttendedUsers: false,
        filtersAttended: {
            type: ScheduleTypes.ORGANIZATION
        },
        attendedUsers: [],

        loadingServices: false,
        services: [],

        specificDate: false,
        specificPlace: false,

        showReserveModal: false,
        savingReserveModal: false,
    }

    componentDidMount() {
        const { dispatch } = this.props;
        const { initialValues } = this.props;

        this.setState({
            specificDate: initialValues?.specific || false,
            specificPlace: initialValues?.specificPlace || false
        });

        if (initialValues?._id) {
            this.getSchedule();
        }
        this.getWeddings();
        this.getAttendingTypes();

        dispatch(
            initialize('schedule_form', {
                date: initialValues?.date || null,
                startDate: null,
                endDate: null,
                wedding: null,
                type: ScheduleTypes.ORGANIZATION,
                language: null,
                attendingType: null,
                weddingPlace: initialValues?.weddingPlace || null,
                attendedUsers: [],
                startTime: null,
            })
        );
    }

    getSchedule = async () => {
        const { dispatch, initialValues } = this.props;
        let { weddings } = this.state;

        this.setState({ loading: true });
        const result = await GetSchedule(initialValues?._id);
        if(result?.success) {
            const schedule = (result?.success && result?.data) || null;
    
            if (schedule?.wedding) {
                weddings.push({
                    ...schedule.wedding,
                    label: getWeddingFullName(schedule.wedding)
                });
            }
    
            dispatch(
                initialize('schedule_form', {
                    _id: schedule?._id,
                    date: schedule?.date,
                    startDate: schedule?.startDate,
                    endDate: schedule?.endDate,
                    wedding: schedule?.wedding?._id || schedule?.wedding,
                    type: schedule?.type,
                    language: schedule?.language,
                    attendingType: schedule?.attendingType?._id || schedule?.attendingType,
                    weddingPlace: schedule?.weddingPlace,
                    // attendedUsers: schedule?.attendedUsers || [],
                    attendedBy: schedule?.attendedBy?._id || schedule?.attendedBy,
                    startTime: schedule?.organizerSchedule
                })
            );
    
            this.setState({
                schedule,
                weddings,
                loading: false,
                cheked: true,
                date: schedule?.date,
                filtersAttended: {
                    _id: schedule?._id,
                    organizerSchedule: schedule?.organizerSchedule,
                    date: schedule?.date,
                    type: schedule?.type,
                    language: schedule?.language,
                    attendingType: schedule?.attendingType?._id || schedule?.attendingType,
                    weddingPlace: schedule?.weddingPlace,
                    attendedBy: schedule?.attendedBy?._id || schedule?.attendedBy,
                    startTime: schedule?.organizerSchedule,
                    wedding: schedule?.wedding?._id || schedule?.wedding,
                }
            }, () => {
                this.getScheduleServices();
            });
        } else {
            this.setState({ loading: false }, () => this.props.onClose());
        }
    }

    getWeddings = async () => {
        const { weddings } = this.state;
        this.setState({ loadingWeddings: true });
        const result = await GetWeddingsSelect(true);
        let data = result?.success && result?.data ? result.data : [];

        this.setState({
            loadingWeddings: false,
            weddings: [...weddings, ...data],
        });
    }

    fetchWeddings = debounce(async (value) => {
        this.setState({ fetchingWeddings: true });
        const result = await GetWeddingsSelect(true, value);
        let data = result?.success && result?.data ? result.data : [];

        this.setState({
            weddings: data
        }, () => {
            this.setState({ fetchingWeddings: false });
        });

    }, 500)

    getAttendingTypes = async () => {
        this.setState({ loadingAttendingTypes: true });
        const result = await GetAttendingTypes();
        let data = result?.success && result?.data ? result.data : [];
        if (Array.isArray(data) && data.length > 0) {
            data = data.map(m => ({ ...m, label: m?.name?.pt }))
        }

        this.setState({
            loadingAttendingTypes: false,
            attendingTypes: data,
        });
    }

    getScheduleServices = async () => {
        const { change } = this.props;
        const { filtersAttended } = this.state;
        if (!filtersAttended?.type || filtersAttended?.type == ScheduleTypes.FIRST_VISIT) {
            return;
        }

        this.setState({ loadingServices: true });

        const result = await GetScheduleServicesList(filtersAttended);
        let data = result?.success && result?.data ? result.data : [];

        if (filtersAttended?.startTime) {
            const exist = data.find(f => f?._id == filtersAttended?.startTime);
            if (!exist) change('startTime', null);
        }
        this.setState({
            loadingServices: false,
            services: data,
        });
    }

    getServiceLabel = (value) => {
        return <div>
            {value?.startDate ? moment.utc(value?.startDate).local().format('HH:mm') : ''} ({value?.user?.name || ''})
        </div>
    }

    clearFields = () => {
        const { dispatch, onClose } = this.props;
        dispatch(initialize('schedule_form', {}, false));
        onClose();
    }

    // Disable dates
    disabledDate = (current) => {
        return current && current.isBefore(moment.utc().startOf('day'), 'day');
    }

    render() {
        const { isOpen } = this.props;
        const { handleSubmit, onClose, onSubmit, invalid, change, submitFailed, saving, confirming, scheduleForm } = this.props;
        const { loadingWeddings, loading, loadingAttendedUsers, loadingAttendingTypes, loadingServices } = this.state;
        const { fetchingWeddings, weddings, attendedUsers, attendingTypes, services } = this.state;
        const { schedule, specificDate, specificPlace } = this.state;
        const { showReserveModal, savingReserveModal } = this.state;
        const { date } = this.state;

        const ready = loading || loadingWeddings || loadingAttendingTypes;

        return (
            <React.Fragment>
                <Modal
                    visible={isOpen}
                    title={schedule?._id ? 'Editar reunião' : 'Criar reunião'}
                    maskClosable
                    onCancel={onClose}
                    footer={[
                        <Button
                            key='cancel'
                            type='default'
                            onClick={onClose}>
                            Fechar
                        </Button>,
                        <Button
                            key='submit'
                            type='primary'
                            loading={saving}
                            disabled={ready}
                            onClick={handleSubmit(onSubmit)}>
                            Gravar
                        </Button>,
                    ]} >

                    {
                        ready
                            ? <SpinLoading />
                            : <BaseForm onSubmit={handleSubmit(onSubmit)}>
                                <Field
                                    component={DateInput}
                                    name="date"
                                    label="Data *"
                                    placeholder="Selecione a data"
                                    disabled={specificDate || schedule?._id ? true : false}
                                    disabledDate={this.disabledDate}
                                    onChange={(e) => {
                                        let { filtersAttended } = this.state;
                                        filtersAttended['date'] = e;
                                        const date = e && moment.utc(e).isValid() ? e : null;
                                        this.setState({ date });
                                        this.setState({ filtersAttended }, () => this.getScheduleServices());
                                    }}
                                />
                                <Field
                                    component={SelectInput}
                                    name={'wedding'}
                                    label={'Casal *'}
                                    placeholder={'Selecione o casal'}
                                    allowClear={true}
                                    type='select'
                                    data={weddings}
                                    disabled={schedule ? true : false}
                                    fetching={fetchingWeddings}
                                    fetchData={this.fetchWeddings}
                                    costumeLabel={getWeddingLabelSelect}
                                    withSearchAPI={true}
                                    onChange={(e) => {
                                        let { filtersAttended } = this.state;
                                        if (e && e !== '') {
                                            filtersAttended['wedding'] = e;
                                            const weddingSelected = weddings.find(f => f?._id == e);
                                            change('language', weddingSelected?.organizerLanguage || null);
                                            filtersAttended['language'] = weddingSelected?.organizerLanguage || null;

                                            change('weddingPlace', weddingSelected?.wedding_place || null);
                                            filtersAttended['weddingPlace'] = weddingSelected?.wedding_place || null;

                                            this.setState({ filtersAttended }, () => this.getScheduleServices());
                                        } else {
                                            filtersAttended['wedding'] = e;
                                            change('language', null);
                                            filtersAttended['language'] = null;

                                            change('weddingPlace', null);
                                            filtersAttended['weddingPlace'] = null;

                                            this.setState({ filtersAttended }, () => this.getScheduleServices());
                                        }
                                    }}
                                />
                                <Field
                                    component={SelectLanguageInput}
                                    name={'language'}
                                    label={'Selecione o idioma da reunião'}
                                    languages={OrganizerLanguageOptins}
                                    onChange={(e) => {
                                        let { filtersAttended } = this.state;
                                        filtersAttended['language'] = e;
                                        this.setState({ filtersAttended }, () => this.getScheduleServices());
                                    }}
                                />
                                <Field
                                    component={SelectInput}
                                    name={'attendingType'}
                                    label={'Selecione o tipo de atendimento'}
                                    data={attendingTypes}
                                    dataLabel='label'
                                    onChange={(e) => {
                                        let { filtersAttended } = this.state;
                                        filtersAttended['attendingType'] = e;
                                        this.setState({ filtersAttended }, () => this.getScheduleServices());
                                    }}
                                />
                                <React.Fragment>
                                    <Field
                                        component={SelectInput}
                                        name={'startTime'}
                                        label={'Selecione o horário'}
                                        fetching={loadingServices}
                                        data={services}
                                        costumeLabel={this.getServiceLabel}
                                    />
                                </React.Fragment>
                            </BaseForm >}
                </Modal>
            </React.Fragment>
        );
    }
};

MeetingModal = reduxForm({
    form: 'schedule_form',
    // enableReinitialize: true,
    validate: validations,
})(MeetingModal);

const selector = formValueSelector('schedule_form');

const mapStateToProps = state => ({
    scheduleForm: {
        date: selector(state, 'date'),
        startDate: selector(state, 'startDate'),
        endDate: selector(state, 'endDate'),
        wedding: selector(state, 'wedding'),
        type: selector(state, 'type'),
        language: selector(state, 'language'),
        attendingType: selector(state, 'attendingType'),
        weddingPlace: selector(state, 'weddingPlace'),
        attendedUsers: selector(state, 'attendedUsers'),
        attendedBy: selector(state, 'attendedBy'),
        startTime: selector(state, 'startTime'),
    }
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ change }, dispatch);
}

MeetingModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingModal)