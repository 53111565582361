import styled from 'styled-components';

export const ModalTextTitle = styled.div`
  color: red;
  font-size: 17px;
  padding-bottom: 12px;
  font-weight: bold;
`;

export const ModalTextLine = styled.div`
  font-size: 15px;
  padding-bottom: 7px;
`;

export const StateLabel = styled.div`
  padding: 3px 5px;
  border-radius: 5px;
  background-color: ${({ printed }) => printed ? '#389e0d' : 'transparent'};
  color: ${({ printed }) => printed ? '#ffffff' : 'rgba(0, 0, 0, 0.65)'};
  display: inline-block;
  width: auto;
`;

export const LocalLabel = styled.div`
  padding: 3px 5px;
  border-radius: 5px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  display: inline-block;
  width: auto;
`;