import client from "../config/AxiosConfig";

export const GetReviews = async (page = 1, limit = 20, filter = "") =>
  client.get(`/reviews/${page}/${limit}?filter=${filter}`);

export const GetReviewById = async id => client.get(`/reviews/${id}`);

export const UpdateReviewById = async (id, data) =>
  client.put(`/reviews/${id}`, data);

export const CreateReview = async data => client.post(`/reviews`, data);

export const DeleteReview = async id => client.delete(`/reviews/${id}`);
