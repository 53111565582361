import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import { Icon, Select, Spin } from "antd";
import { InputDiv, InputLabelDiv, SelectDiv, SelectOption, SelectOptions, StyledManualSelectInput, StyledSelectDiv, StyledSelectInput } from "./InputStyles";
import { GuestAgeEnum } from "../guestMap/utilGuest";


const SelectAgeInput = ({
  input,
  data,
  dataKey,
  dataLabel,
  placeholder,
  label,
  disabled,
  loading,
  first,
  meta,
  translatable,
  activeLanguage,
  allowClear,
  horizontal = false,
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  const [open, toogleOpen] = useState(false);
  const [value, setValue] = useState([]);
  const selectAgeRef = useRef(null);
  const iconAgeRef = useRef(null);

  useEffect(() => {
    setValue(input.value || []);
  }, [input.value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectAgeRef.current && !selectAgeRef.current?.contains(event.target)
        && !event?.target?.class?.includes('select-age-icon')) {
        toogleOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const formatValue = () => {
    if (!input.value) return undefined;
    if (input.value == GuestAgeEnum.ADULT) {
      return undefined;
    } else {
      const selected = data.find(f => f?._id == input.value);
      return selected ? selected[dataLabel] : undefined;
    }
  };

  const isFilled = () => {
    return input?.value && input?.value !== GuestAgeEnum.ADULT ? true : false;
  }

  const changeSelect = value => {
    input.onChange(value || GuestAgeEnum.ADULT);
    toogleOpen(!open);
  };

  const toogleSelect = () => {
    if (disabled) {
      return null;
    } else {
      toogleOpen(!open)
    }
  }

  return (
    <StyledSelectDiv first={first || !label} horizontal={horizontal} ref={selectAgeRef} >
      {label && <InputLabelDiv horizontal={horizontal}>{label}</InputLabelDiv>}
      <StyledManualSelectInput filled={isFilled()} disabled={disabled} onClick={toogleSelect}>
        <div className="select-input">
          {formatValue()}
        </div>
        <span className="ant-select-arrow" onClick={toogleSelect} ref={iconAgeRef}>
          {open
            ? <i class="fa-solid fa-angle-up select-age-icon"></i>
            : <i className="fa-solid fa-angle-down select-age-icon"></i>
          }
        </span>
        {
          allowClear && input?.value && input?.value?.length > 0 && formatValue() != undefined
          && <span className="ant-select-selection__clear" onClick={() => changeSelect(null)}>
            <Icon type="close-circle" theme="filled" />
          </span>
        }
      </StyledManualSelectInput>

      {open && <SelectOptions age={true}>
        {data.map((elem, index) => (
          <SelectOption key={dataKey ? elem[dataKey] : index}
            disabled={elem?.disabled || false}
            selectAge={true}
            onClick={(e) => changeSelect(elem[dataKey])}>
            <span>
              {dataLabel
                ? (translatable ? elem[dataLabel][activeLanguage.code] : elem[dataLabel])
                : elem}
            </span>
          </SelectOption>
        ))}
      </SelectOptions>}
    </StyledSelectDiv >
  );
};

SelectAgeInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  onPressEnter: PropTypes.func,
  allowClear: PropTypes.bool,
  removeSelection: PropTypes.func,
  tag: PropTypes.string
};

SelectAgeInput.defaultProps = {
  data: [],
  allowClear: true,
  dataLabel: 'name',
  dataKey: '_id'
};

export default withLocalize(SelectAgeInput);
