import React from 'react';
import { BasicInfoSection, SectionTitle } from '../ProcessStyles';
import {
  QuestionTitleContainer,
  QuestionTitle
} from '../../ChoicesPage/SectionStyles';
import { findPageByTag } from '../../../infra/services/sections/Sections';
import { findAnsweredQuestions } from '../../../infra/services/questions/AnsweredQuestions';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import SectionResponse from '../../ChoicesPage/SectionResponse';
import SectionNotes from './SectionNotes';

const DecorationComponent = ({
  structureInfo,
  dispatch,
  weddingInfo,
  foodInfo,
  colorsInfo,
  SaveGraphicMenu,
  menuInfo,
  notes = [],
  archived,
}) => {
  const section = findPageByTag(structureInfo, 'DECORATION');
  const decoration = findAnsweredQuestions(
    section,
    foodInfo,
    colorsInfo,
    weddingInfo.wedding_place
  );
  const note = notes.find(note => note.section === 'DECORATION');

  return (
    <BasicInfoSection>
      <SectionTitle>Decoração</SectionTitle>
      <SectionNotes title="Notas Públicas:" note={note?.notes} />
      <SectionNotes title="Notas Privadas:" note={note?.private} />
      {decoration?.length > 0 ? (
        decoration.map((question, index) => (
          <div key={index}>
            <QuestionTitleContainer>
              <QuestionTitle>{TranslateValue(question.title)}</QuestionTitle>
            </QuestionTitleContainer>
            <SectionResponse
              weddingPlace={weddingInfo.wedding_place}
              upgrade={weddingInfo.upgrade}
              question={question}
              printed={weddingInfo?.printed}
            />
          </div>
        ))
      ) : (
        <div>Não existem registos para apresentar!</div>
      )}
    </BasicInfoSection>
  );
};

export default DecorationComponent;
