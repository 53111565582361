import React, {Component} from 'react';
import {Popconfirm, Icon} from 'antd';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton
} from '../../styles/BasicStyles';
import {GetReviews, DeleteReview} from '../../infra/requests/ReviewsRequests';
import BaseButton from '../../components/buttons/BaseButton';
import ReviewsFilters from './ReviewsFilters';

class ReviewsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    filters: '',
    columns: [
      {
        title: 'Local',
        dataIndex: 'local',
        render: value =>
          value === 1 ? 'Solar da Levada' : 'Quinta Lago dos Cisnes'
      },
      {
        title: 'Texto',
        dataIndex: 'text',
        render: value => value?.pt
      },
      {
        title: 'Actions',
        render: data => (
          <React.Fragment>
            <TableButton onClick={e => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title={'Tem a certeza que quer apagar este review?'}
                onConfirm={() => this.deleteItem(data._id)}>
                <Icon type="delete" />
                {' Remove'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        )
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleChangePage = currentPage => {
    this.setState({currentPage}, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({pageSize, currentPage: 1}, this.updateDataTable);
  };

  updateDataTable = async () => {
    this.setState({loading: true});
    try {
      const {currentPage, pageSize, filters} = this.state;
      const result = await GetReviews(currentPage, pageSize, filters);
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false
      });
    } catch (e) {
      this.setState({loading: false});
      console.error(e);
    }
  };

  handleFilterChange = filters => {
    this.setState({filters, currentPage: 1}, this.updateDataTable);
  };

  deleteItem = async id => {
    try {
      await DeleteReview(id);
      await this.updateDataTable();
    } catch (err) {
      console.error(err);
    }
  };

  handleOnPressRow = record => {
    const {history} = this.props;
    return history.push(`/management/reviews/${record._id}`);
  };

  render() {
    const {history} = this.props;
    const {columns, rows, total, loading, currentPage, pageSize} = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Reviews</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/management/reviews/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={1}>
          <ReviewsFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="file-text"
            emptyText={'There are not reviews'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default ReviewsPage;
