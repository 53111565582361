import React, { Fragment, useRef, useState } from 'react';
import { Icon, Modal, notification, Popconfirm } from 'antd';
import Dropzone from 'react-dropzone';
import StackGrid from 'react-stack-grid';
import styled from 'styled-components';
import EmptyIcon from '../../assets/icons/ic_empty_answer.svg';
import BaseButton from '../../components/buttons/BaseButton';
import Cropper from 'react-cropper';
import { buildImageObject, resizeImage } from '../../infra/services/images/ImageUtils';
import { SpinLoading } from '../../styles/BasicStyles';
import { CropperBox } from '../ChoicesPage/questionOptions/ImageCard';

const DeleteMessage = (
  <div>
    <div>Tem a certeza que quer remover esta imagem?</div>
  </div>
);

const DeletePrintedMessage = (
  <div>
    <div>Tem a certeza que quer remover esta imagem?</div>
    <b>Este processo de casamento já se encontra impresso.</b>
  </div>
);

const Grid = styled(StackGrid)`
  margin: 20px 30px 50px 30px;
`;

const AddImageSection = styled.div`
  display: inline-block;
`;

const ImageSection = styled.div`
  width: 100%;
  display: inline-block;
  position: relative;
`;

const DeleteButton = styled.div`
  cursor: pointer;
  position: absolute;
  background: white;
  padding: 5px;
  top: 0;
  right: 0;
  box-shadow: 1px 1px 3px #ccc;
`;

const Image = styled.img`
  width: 100%;
`;

export const EmptySection = styled.div`
  display: inline-block;
  margin: 50px 0;
  text-align: center;
  width: 100%;
  i {
    font-size: 30px;
    margin-right: 15px;
  }
`;

export const SectionIcon = styled.img`
  display: inline-block;
  width: 30px;
  margin-right: 15px;
`;

const StyleImages = ({ type, style, handleAdd, handleDelete, noCrop, ratio, printed }) => {
  let images = style[type] || [];
  const width = window.innerWidth;

  const [openModal, toggleModal] = useState(false);
  const [uploaded, setUploaded] = useState(null);
  const [loading, toggleLoading] = useState(false);
  const [saving, toggleSaving] = useState(false);
  const cropper = useRef(null);

  if (type === 'inspirations') {
    images = images.map(inspiration => inspiration.image);
  }

  const handleImageDrop = (accepted, rejected) => {

    if (accepted.length <= 0 || (rejected && rejected.length > 0)) {
      return notification.error({
        message: 'Ficheiro Inválido',
        description: 'O ficheiro é inválido ou tem um tamanho superior a 6Mb'
      });
    }

    const uploaded = buildImageObject(accepted[0]);

    if (noCrop) {
      handleAdd(accepted[0])
    }
    else {
      setUploaded(uploaded);
      toggleModal(true);
      toggleLoading(true);
    }
  };

  const onCropComplete = () => {
    if (cropper) {
      toggleSaving(true);
      const currCanvas = cropper.current.getCroppedCanvas();

      // const canvas = resizeImage(currCanvas);

      currCanvas.toBlob((blob) => {
        const image = buildImageObject(blob);
        handleAdd(image.blob)
        closeCropModal();
      });
    }
  };

  const closeCropModal = () => {
    toggleModal(false);
    setUploaded(null);
    toggleSaving(false);
  };

  const onCancel = () => {
    handleAdd(uploaded.blob);
    closeCropModal();
  }

  const AddImagesComponent = () => (
    <React.Fragment>
      <Dropzone
        multiple={false}
        // onDrop={handleAdd}
        onDrop={handleImageDrop}
        accept="image/jpeg, image/png"
        maxSize={60000000}>
        {({ getRootProps, getInputProps }) => (
          <AddImageSection {...getRootProps()}>
            <input {...getInputProps()} />
            <BaseButton type="primary" icon="plus" text="Adicionar imagem" />
          </AddImageSection>
        )}
      </Dropzone>
      {openModal && <Modal
        maskClosable={false}
        closable={false}
        title="Recorte a imagem se pretender"
        visible={openModal}
        confirmLoading={saving}
        onOk={onCropComplete}
        onCancel={onCancel}
        bodyStyle={{ padding: 0 }}
        width="600px">
        <CropperBox>
          {loading && <div className='backdrop'>
            <SpinLoading style={{ position: 'absolute', minHeight: 30 }} />
          </div>}
          <Cropper
            ref={cropper}
            viewMode={2}
            autoCropArea={1}
            aspectRatio={1}
            style={{ height: 400, width: '100%' }}
            guides={true}
            src={uploaded ? uploaded.preview : ''}
            ready={(evt) => {
              if (evt?.type === 'ready') toggleLoading(false);
            }}
          />
        </CropperBox>
      </Modal>}
    </React.Fragment>
  );

  if (!images || images.length === 0) {
    return (
      <Fragment>
        {type === 'images' && AddImagesComponent()}
        <EmptySection>
          <SectionIcon src={EmptyIcon} alt="empty" />
          Ainda não foram selecionadas imagens
        </EmptySection>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {type === 'images' && AddImagesComponent()}
      <Grid
        columnWidth={width <= 450 ? '100%' : width <= 768 ? '50%' : '33.33%'}
        gutterWidth={30}
        gutterHeight={30}
        monitorImagesLoaded={true}
        duration={0}>
        {images.map(image => (
          <ImageSection key={image?._id}>
            {type === 'images' && (
              <DeleteButton>
                <Popconfirm
                  placement={'topRight'}
                  title={printed ? DeletePrintedMessage : DeleteMessage}
                  onConfirm={() => handleDelete(image._id)}>
                  <Icon type="delete" />
                </Popconfirm>
              </DeleteButton>
            )}
            <Image src={image?.url} alt={image?.file} />
          </ImageSection>
        ))}
      </Grid>
    </Fragment>
  );
};

export default StyleImages;
