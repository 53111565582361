import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {Modal, Button} from 'antd';
import {BaseForm} from '../../../styles/BasicStyles';
import TextAreaInput from '../../../components/inputs/TextAreaInput';
import {
  DecorationModalContainer,
  DecorationModalTitle,
  DecorationModalInput,
  AnswerContainer,
  Observation,
  Answer,
  NotesContainer,
  NoteContent,
  NoteTitle,
  Note,
  DecorationModalTitleContainer,
  ShowDefaultMenu,
  RealAnswer
} from '../ProcessStyles';
import {Tabs} from 'antd';
const TabPane = Tabs.TabPane;

let DecorationModalComponent = ({
  open,
  loading,
  handleSubmit,
  onSubmit,
  closeModal,
  food,
  sidedishes,
  notes,
  loadDefaultMenu
}) => {
  const foodNotes = notes.find(note => note.section === 'FOOD');
  const decorationNotes = notes.find(note => note.section === 'DECORATION');
  const foodQuestions = food.filter(elem => (elem.showQuestion ? elem : null));

  return (
    <Modal
      visible={open}
      title="Editar Menu"
      maskClosable={false}
      onCancel={closeModal}
      width={600}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      <BaseForm onSubmit={handleSubmit(onSubmit)}>
        <DecorationModalContainer>
          <DecorationModalTitleContainer>
            <DecorationModalTitle>Menu:</DecorationModalTitle>
            <ShowDefaultMenu onClick={() => loadDefaultMenu()}>
              Ver Menu Original
            </ShowDefaultMenu>
          </DecorationModalTitleContainer>
          <DecorationModalInput>
            <Field component={TextAreaInput} name="text" noLabel={true} />
          </DecorationModalInput>
          {foodQuestions?.length > 0 && (
            <React.Fragment>
              <DecorationModalTitle>Perguntas:</DecorationModalTitle>
              <Tabs>
                {foodQuestions.map((elem, index) => (
                  <TabPane tab={elem.label} key={index}>
                    <AnswerContainer>
                      {elem.answer && <Answer>{elem.answer}{elem.realAnswer && <React.Fragment>: <RealAnswer>{elem.realAnswer}</RealAnswer></React.Fragment>}</Answer>}
                      <Observation>
                        {elem.observations || 'Sem observações'}
                      </Observation>
                    </AnswerContainer>
                  </TabPane>
                ))}
              </Tabs>
            </React.Fragment>
          )}
          {foodNotes && (foodNotes?.notes || foodNotes?.private) && (
            <NotesContainer>
              {foodNotes.notes && (
                <NoteContent>
                  <NoteTitle>Notas Públicas (Comida):</NoteTitle>
                  <Note
                    dangerouslySetInnerHTML={{
                      __html: foodNotes.notes
                    }}
                  />
                </NoteContent>
              )}
              {foodNotes.private && (
                <NoteContent>
                  <NoteTitle>Notas Privadas (Comida):</NoteTitle>
                  <Note
                    dangerouslySetInnerHTML={{
                      __html: foodNotes.private
                    }}
                  />
                </NoteContent>
              )}
            </NotesContainer>
          )}
          {decorationNotes &&
            (decorationNotes.notes || decorationNotes.private) && (
              <NotesContainer>
                {decorationNotes.notes && (
                  <NoteContent>
                    <NoteTitle>Notas Públicas (Decoração):</NoteTitle>
                    <Note
                      dangerouslySetInnerHTML={{
                        __html: decorationNotes.notes
                      }}
                    />
                  </NoteContent>
                )}
                {decorationNotes.private && (
                  <NoteContent>
                    <NoteTitle>Notas Privadas (Decoração):</NoteTitle>
                    <Note
                      dangerouslySetInnerHTML={{
                        __html: decorationNotes.private
                      }}
                    />
                  </NoteContent>
                )}
              </NotesContainer>
            )}
        </DecorationModalContainer>
      </BaseForm>
    </Modal>
  );
};

DecorationModalComponent = reduxForm({
  form: 'decoration_modal_form'
})(DecorationModalComponent);

const mapStateToProps = state => ({
  notes: state.wedding.notes
});

export default connect(mapStateToProps)(DecorationModalComponent);
