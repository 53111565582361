import React, { Component } from 'react';
import { Tabs } from 'antd';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
} from '../../styles/BasicStyles';
import { connect } from 'react-redux';
import { ClearWedding } from '../../redux/wedding/wedding.actions';
import { bindActionCreators } from 'redux';
import ToBePayed from './ToBePayed';
import PendingPayments from './PendingPayments';
import PaidPayments from './PaidPayments';
import { getCurrentUser, getUserId, isCurrentUserAdmin, isCurrentUserChief, isCurrentUserEmployee, isCurrentUserHumanResources } from '../../infra/helpers/Helpers';
import { GetEmployeeByUser } from '../../infra/requests/EmployeeRequests';

class PaymentsPage extends Component {
  state = {
    loading: true,
    currentEmployee: null
  };

  componentDidMount = async () => {
    const localUser = getCurrentUser();
    const resEmployee = isCurrentUserChief() || isCurrentUserEmployee()
      ? await GetEmployeeByUser(localUser._id)
      : null;
    this.setState({
      loading: false,
      currentEmployee: resEmployee && resEmployee.data ? resEmployee.data : null
    });
  };

  onChangeTab = (activeKey) => {
    // console.warn('activeKey', activeKey);
    this.props.history.push('/payments/' + activeKey);
  }

  render() {
    const { currentEmployee, loading } = this.state;
    const tabKey = this.props.match.params.tabKey ? this.props.match.params.tabKey : 1;
    const activeTabKey = tabKey.toString();
    // const paymentType = isCurrentUserAdmin() || isCurrentUserHumanResources() ? 0 : 1;
    const paymentType = isCurrentUserAdmin() ? 0 : 1;
    const employeeId = currentEmployee ? currentEmployee.id : null;

    const { TabPane } = Tabs;

    return !loading ? (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Pagamentos</PageTitle>
            Lista de Pagamentos
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer buttons={0}>
          <Tabs style={{ textAlign: 'start', marginTop: -25 }} defaultActiveKey={activeTabKey} onChange={(activeKey) => this.onChangeTab(activeKey)}>
            <TabPane tab={paymentType === 0 ? 'A Pagar' : 'A Receber'} key="1" style={{ position: 'relative' }}>
              {paymentType === 0 ? <ToBePayed type={paymentType} activeTab={activeTabKey} /> : <ToBePayed type={paymentType} employeeId={employeeId} activeTab={activeTabKey} />}
            </TabPane>
            <TabPane tab="Pendentes" key="2" style={{ position: 'relative' }}>
              {paymentType === 0 ? <PendingPayments type={paymentType} activeTab={activeTabKey} /> : <PendingPayments type={paymentType} employeeId={employeeId} activeTab={activeTabKey} />}
            </TabPane>
            <TabPane tab={paymentType === 0 ? 'Pagos' : 'Recebidos'} key="3" style={{ position: 'relative' }}>
              {paymentType === 0 ? <PaidPayments type={paymentType} activeTab={activeTabKey} /> : <PaidPayments type={paymentType} employeeId={employeeId} activeTab={activeTabKey} />}
            </TabPane>
          </Tabs>
        </PageContainer>
      </React.Fragment>
    ) : null
  }
}

const mapStateToProps = state => ({ wedding: state.wedding });

const mapActionToProps = dispatch => bindActionCreators({ ClearWedding }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(PaymentsPage);
