import React, {useState} from 'react';
import {Icon} from 'antd';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Dropzone from 'react-dropzone';
import {showLocal} from '../../infra/services/wedding/weddingUtils';
import {UploadRoomPlan} from '../../infra/requests/ConfigsRequests';
import {updateRoomPlan} from '../../redux/Info/info.actions';
import {
  Section,
  Label,
  FloatButton,
  FileZone,
  FileName
} from './RoomPlanStyles';

const ManageRoomPlans = ({configs, updateRoomPlan}) => {
  const [loading, setLoading] = useState(false);
  const handleDrop = async (id, accepted) => {
    if (accepted[0]) {
      setLoading(true);
      const payload = new FormData();
      payload.append('file', accepted[0]);
      const {data} = await UploadRoomPlan(id, payload);
      updateRoomPlan(id, data);
      setLoading(false);
    }
  };

  return configs.map(config => {
    if (config.room_plan) {
      return (
        <Section key={config._id}>
          <Label>{showLocal(config.local)}</Label>
          <FileZone>
            <FileName>{config?.room_plan?.filename}</FileName>
            <FloatButton disable={loading}>
              <Dropzone
                disabled={loading}
                onDrop={(accepted, rejected) =>
                  handleDrop(config._id, accepted, rejected)
                }>
                {({getRootProps, getInputProps}) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {loading ? <Icon type="loading" /> : 'Alterar'}
                  </div>
                )}
              </Dropzone>
            </FloatButton>
            <FloatButton
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_IMAGES_URL}${config?.room_plan?._id}`,
                  '_blank'
                )
              }>
              Ver
            </FloatButton>
          </FileZone>
        </Section>
      );
    }

    return (
      <Section key={config._id}>
        <Label>{showLocal(config.local)}</Label>
        <FileZone>
          <FloatButton disable={loading}>
            <Dropzone
              onDrop={(accepted, rejected) =>
                handleDrop(config._id, accepted, rejected)
              }>
              {({getRootProps, getInputProps}) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {loading ? <Icon type="loading" /> : 'Carregar'}
                </div>
              )}
            </Dropzone>
          </FloatButton>
        </FileZone>
      </Section>
    );
  });
};

const mapStateToProps = state => ({
  configs: state.info.configs
});

const mapActionToProps = dispatch =>
  bindActionCreators({updateRoomPlan}, dispatch);

export default connect(mapStateToProps, mapActionToProps)(ManageRoomPlans);
