import React from "react";
import BaseButton from "../../components/buttons/BaseButton";
import Logo from "../../assets/icons/logo_white.svg";
import {
  StyledNotFoundContainer,
  StyledTitle,
  StyledDescription,
  StyledCompanyLogo,
  StyledNotFound,
  StyledNotFoundGroup,
  StyledSubTitle,
  StyledRightContainer,
  StyledLeftContainer
} from "./NotFoundStyles";
import { isCurrentUserChief, isCurrentUserEmployeeAttending, isCurrentUserHumanResources, isEmployeeOrChief } from "../../infra/helpers/Helpers";

const NotFound = ({ history }) => (
  <StyledNotFoundContainer>
    <StyledNotFoundGroup>
      <StyledLeftContainer>
        <StyledNotFound>404</StyledNotFound>
        <StyledCompanyLogo src={Logo} />
      </StyledLeftContainer>
      <StyledRightContainer>
        <StyledTitle>Oops, </StyledTitle>
        <StyledSubTitle>You are lost!</StyledSubTitle>
        <StyledDescription>
          We are really sorry for the inconvenience. For what it looks like, the
          page that you are trying to access has been deleted or never existed.
        </StyledDescription>
        <BaseButton
          disabled={false}
          loading={false}
          text={"Back to Home"}
          onClick={() => {
            if (isCurrentUserEmployeeAttending()) {
              history.push('/home-attending');
            } else if (isEmployeeOrChief() || isCurrentUserChief() || isCurrentUserHumanResources()) {
              history.push('/home');
            } else {
              history.push('/');
            };
          }}
          type={"primary"}
        />
      </StyledRightContainer>
    </StyledNotFoundGroup>
  </StyledNotFoundContainer>
);

export default NotFound;
