import client from '../config/AxiosConfig';

export const GetNews = async (page, limit) =>
  client.get(`/news/${page}/${limit}`);

export const GetNewsDetail = async id => client.get(`/news/${id}/detail`);

export const CreateNews = async data => client.post('/news', data);

export const UpdateNews = async (id, data) => client.put(`/news/${id}`, data);

export const ToggleNews = async (id, data) =>
  client.put(`/news/${id}/toggle`, data);

export const DeleteNews = async (id, data) =>
  client.delete(`/news/${id}`, data);

export const SaveImage = async (id, data) =>
  client.put(`/news/${id}/image`, data);

export const DeleteImage = async (id, img_id) =>
  client.delete(`/news/${id}/image/${img_id}`);

export const SaveFile = async (id, data) =>
  client.put(`/news/${id}/file`, data);

export const DeleteFile = async (id, file_id) =>
  client.delete(`/news/${id}/file/${file_id}`);
