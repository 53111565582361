import React, { Component } from 'react';
import { reduxForm, Field, initialize } from 'redux-form';
import { connect } from 'react-redux';
import BaseButton from '../../components/buttons/BaseButton';
import {
  FormContainer,
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  BaseForm,
  HeaderButtonsContainer,
  SpinLoading
} from '../../styles/BasicStyles';
import {
  GetQuestionOrders,
  UpdateQuestionOrders
} from '../../infra/requests/QuestionsRequests';
import {
  GetAllSupplierCategories
} from '../../infra/requests/SupplierRequests';
import { Row, Col } from 'antd';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import SectionNames from '../../infra/services/sections/SectionNames';

class ManageQuestionsPage extends Component {
  state = {
    isNew: false,
    loading: true,
    ready: false,
    title: 'Pergunta',
    supplierCategories: []
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;

    const supplierCategories = await GetAllSupplierCategories();

    if (params.id) {
      const result = await GetQuestionOrders(params.id);

      let optionsData = {}, title = '';
      if (result?.data) {
        optionsData = {
          section: result?.data?.chapter?.section?.tag ? SectionNames[result?.data?.chapter?.section?.tag] : '',
          chapter: result?.data?.chapter?.name?.pt,
          question: result?.data?.title.pt,
          supplier_category: result?.data?.supplier_category
        }

        title = `${optionsData.section} » ${optionsData.chapter} » ${optionsData.question}`;
      }

      dispatch(initialize('manage_order_questions_form', { ...optionsData }));

      this.setState({
        ready: true,
        title,
        supplierCategories: supplierCategories?.data || []
      });
    }
  };

  onSubmit = async values => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;

      await UpdateQuestionOrders(params.id, { supplier_category: values.supplier_category });
      return history.push('/order-questions');
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    try {
      const { history } = this.props;
      return history.push('/order-questions');
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit } = this.props;
    const { ready, title, supplierCategories } = this.state;

    if (!ready) return <SpinLoading />;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Categorias: Perguntas</PageTitle>
            {title}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row style={{ display: 'grid', justifyItems: 'center' }}>
              <Col xs={24} sm={18} md={15} lg={10}>
                <Field
                  component={TextInput}
                  name={'section'}
                  type="text"
                  label={'Secção'}
                  disabled={true}
                />
                <Field
                  component={TextInput}
                  name={'chapter'}
                  type="text"
                  label={'Capítulo'}
                  disabled={true}
                />
                <Field
                  component={TextInput}
                  name={'question'}
                  type="text"
                  label={'Pergunta'}
                  disabled={true}
                />
                <Field
                  component={SelectInput}
                  name={'supplier_category'}
                  label={'Categoria de Encomenda'}
                  placeholder={'Escolha uma categoria'}
                  data={supplierCategories}
                  allowClear={true}
                  mode='multiple'
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageQuestionsPage = reduxForm({
  form: 'manage_order_questions_form'
})(ManageQuestionsPage);

export default connect()(ManageQuestionsPage);