import React from 'react';
import { Field } from 'redux-form';
import {
  OptionsContainer,
  OptionLine,
  OptionColumn,
  OptionQuantity,
  OptionType
} from './Styles';
import TextInput from '../../../components/inputs/TextInput';
import NumberInput from '../../../components/inputs/NumberInput';

const OrderIngredientsList = ({ fields }) => {
  return (
    <OptionsContainer>
      {
        fields.map((field, index) =>
          <OptionLine key={index}>
            <OptionColumn maxWidth={100} noBorderTopRight={true} noBorderBottomRight={true}>
              <Field
                component={TextInput}
                name={`${field}.date`}
                type='text'
                disabled={true}
                noLabel={true}
              />
            </OptionColumn>
            <OptionColumn maxWidth={150} noBorderRadius={true}>
              <Field
                component={TextInput}
                name={`${field}.wedding_place_txt`}
                type='text'
                disabled={true}
                noLabel={true}
              />
            </OptionColumn>
            <OptionColumn maxWidth={300} noBorderRadius={true}>
              <Field
                component={TextInput}
                name={`${field}.name`}
                type='text'
                disabled={true}
                noLabel={true}
                tooltip={fields.get(index).name.length > 45 ? true : false}
                tooltipMsg={fields.get(index).name}
              />
            </OptionColumn>
            <OptionQuantity>
              <Field
                component={NumberInput}
                name={`${field}.quantity`}
                step={0.01}
                min={0}
              />
            </OptionQuantity>
            <OptionType>
              <Field
                component={TextInput}
                name={`${field}.type`}
                type='text'
                disabled={true}
                noLabel={true}
              />
            </OptionType>
          </OptionLine>
        )}
    </OptionsContainer>
  );
};

export default OrderIngredientsList;