import { PermissionsEnum } from "../../infra/helpers/Permissions";

export default [
  {
    to: '/management',
    icon: 'read',
    name: 'Basic Configuration',
    exact: true,
    // permissions: [PermissionsEnum.MNG_CONFIGS]
  },
  {
    to: '/management/appointments',
    icon: 'schedule',
    name: 'Appointments',
    exact: true,
    permissions: [PermissionsEnum.MNG_APPOINTMENTS]
  },
  {
    to: '/management/contacts',
    icon: 'message',
    name: 'Contacts',
    exact: true,
    permissions: [PermissionsEnum.MNG_CONTACTS]
  },
  {
    to: '/management/banners',
    icon: 'file-image',
    name: 'Banners',
    exact: true,
    permissions: [PermissionsEnum.MNG_BANNERS]
  },
  {
    to: '/management/portfolio',
    icon: 'camera',
    name: 'Portfolio de Imagens',
    exact: true,
    permissions: [PermissionsEnum.MNG_IMAGE_PORTFOLIO]
  },
  {
    to: '/management/videos',
    icon: 'video-camera',
    name: 'Portfolio de Videos',
    exact: true,
    permissions: [PermissionsEnum.MNG_VIDEO_PORTFOLIO]
  },
  {
    to: '/management/reviews',
    icon: 'file-text',
    name: 'Reviews',
    exact: true,
    permissions: [PermissionsEnum.MNG_REVIEWS]
  },
  {
    to: '/management/reservations',
    icon: 'calendar',
    name: 'Reservas',
    exact: true,
    permissions: [PermissionsEnum.MNG_RESERVATIONS]
  },
  {
    to: '/management/dynamic-pages',
    icon: 'copy',
    name: 'Dynamic Pages',
    exact: true,
    permissions: [PermissionsEnum.LIST_DYNAMIC_PAGE]
  },
  {
    to: '/management/translations',
    icon: 'global',
    name: 'Translations',
    exact: true,
    permissions: [PermissionsEnum.LIST_TRANSLATION]
  },
  {
    to: '/management/metatags',
    icon: 'tags',
    name: 'Metatags',
    exact: false,
    permissions: [PermissionsEnum.MNG_METATAGS]
  }
];
