import moment from 'moment';
import momentTimezone from 'moment-timezone';

export const checkWeekdaysBetweenDates = (startDate, endDate) => {
    const start = moment.utc(startDate);
    const end = moment.utc(endDate);
    const weekDays = [];

    for (let m = start; m.isSameOrBefore(end); m.add(1, 'days')) {
        const isoWeekday = m.isoWeekday();
        if (isoWeekday !== 6 && isoWeekday !== 7) {
            weekDays.push(m.format('YYYY-MM-DD'));
        }
    }

    return weekDays;
};

export const checkWeekendsBetweenDates = (startDate, endDate) => {
    const start = moment.utc(startDate);
    const end = moment.utc(endDate);

    for (let m = start; m.isSameOrBefore(end); m.add(1, 'days')) {
        const isoWeekday = m.isoWeekday();
        if (isoWeekday === 6 && isoWeekday === 7) {
            return true;
        }
    }

    return false;
};