import PropTypes from 'prop-types';
import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Icon } from 'antd';
import { Field } from 'redux-form';
import { SectionTitle } from '../../styles/BasicStyles';
import TextInput from '../inputs/TextInput';
import XpertGoDateInput from '../inputs/XpertGoDateInput';
import CheckboxInput from '../inputs/CheckboxInput';
import { ColourInput } from '../inputs/InputStyles';
import ColourInputComponent from '../inputs/ColourInput';

const PaymentPhasesComponent = ({ fields }) => {
  const [paymentPhases, setPaymentPhases] = useState([]);

  useEffect(() => {
  }, []);

  const getFieldPhaseName = (index) => {
    const field = fields.get(index);
    return `${field?.phase?.pt} ${field?.order}`;
  }

  const getPhase = (index) => {
    const field = fields.get(index);
    return field;
  }

  return (
    <Row gutter={[12, 12]}>
      {fields.map((field, index) => (
        <React.Fragment key={index}>
          <Col xs={4}>
            {/* <Field
              label="Fase"
              placeholder="Fase"
              component={TextInput}
              name={`${field?.phase?.pt}`}
              disabled={true}
              type='text'
            /> */}
            <TextInput
              label="Fase"
              placeholder="Fase"
              meta={{}}
              type="text"
              input={{ value: getFieldPhaseName(index)}}
              disabled={true}
            />
          </Col>
          <Col xs={6}>
            <Field
              label="Data"
              placeholder="Data"
              component={XpertGoDateInput}
              name={`${field}.date`}
            />
          </Col>
          <Col xs={6}>
            <Field
              label="Cor de Aviso"
              component={ColourInputComponent}
              name={getPhase(index)?.phaseRule?.personalized ? `${field}.warningColor` : `${field}.phaseRule.warningColor`}
              disabled={getPhase(index)?.phaseRule?.personalized ? false : true}
            />
          </Col>
          <Col xs={8} style={{ paddingBottom: 12 }}>
            <Field
              label="Pagamento Completo"
              positiveLabel='Sim'
              component={CheckboxInput}
              name={`${field}.paymentComplete`}
              disabled={true}
            />
          </Col>
        </React.Fragment>
      ))}
    </Row>
  );
};

PaymentPhasesComponent.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default PaymentPhasesComponent;
