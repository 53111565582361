import client from '../config/AxiosConfig';

export const GetPASettings = async () =>
  client.get(`/paymentAccountSetting`);

export const GetMaxPASetting = async () =>
  client.get(`/paymentAccountSetting/max`);

export const GetPASettingById = async (id) =>
  client.get(`/paymentAccountSetting/${id}`);

export const GetPASettingsPaginated = async (currentPage, limit, filters = {}) =>
  client.get(`/paymentAccountSetting/${currentPage}/${limit}?filter=${JSON.stringify(filters)}`);

export const CreatePASetting = async (data) =>
  client.post(`/paymentAccountSetting`, data);

export const UpdatePASetting = async (id, data) =>
  client.put(`/paymentAccountSetting/${id}`, data);

export const DeletePASetting = async (id) =>
  client.delete(`/paymentAccountSetting/${id}`);