import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { SpinLoading } from '../../styles/BasicStyles';
import {
  GraphContainer,
  GraphContent,
  LabelsContainer,
  Label,
  LabelColor,
  LabelName,
  GraphTitle
} from './GraphStyles';

const BaseGraph = ({ loading, title, data, addedLabels }) => {
  return (
    <GraphContainer width={300} height={280}>
      {loading ?
        <SpinLoading />
        : <React.Fragment>
          <GraphContent>
            <ResponsiveContainer width={280} height={280}>
              <PieChart>
                <Pie
                  dataKey="value"
                  data={data.filter(elem => elem.value > 0)}
                  outerRadius={100}>
                  {data
                    .filter(elem => elem.value > 0)
                    .map((entry, index) => (
                      <Cell key={index} fill={entry.color} />
                    ))}
                </Pie>
                <Tooltip formatter={(value, name) => value} />
              </PieChart>
            </ResponsiveContainer>
          </GraphContent>
          <LabelsContainer>
            {title && <GraphTitle>{title}</GraphTitle>}
            {[...addedLabels, ...data].map((label, index) => (
              <Label key={index}>
                <LabelColor color={label.color} />
                <LabelName>
                  {label.name} - {label.value}
                </LabelName>
              </Label>
            ))}
          </LabelsContainer>
        </React.Fragment>
      }
    </GraphContainer>
  );
};

export default BaseGraph;
