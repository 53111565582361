import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Checkbox, TimePicker, Row, Popconfirm, Icon, Col, Popover, Tooltip } from 'antd';
import {
  SpinLoading,
  SectionTitle,
  HeaderButtonsContainer,
  TableButton,
} from '../../styles/BasicStyles';
import SelectInput from '../../components/inputs/SelectInput';
import Table from '../../components/table/Table';
import BaseButton from '../../components/buttons/BaseButton';
import Alert from "../../components/alert/Alert";
import { GetCategories } from '../../infra/requests/CategoryRequests';
import { GetSubcategoriesByCategory } from '../../infra/requests/SubcategoryRequests';
import { GetEmployeesAvailableBySubcategory, GetEmployeesBySubcategory } from '../../infra/requests/EmployeeRequests';
import { GetWeddingInterestByWeddingAndEmployee, GetWeddingInterestByWeddingAndSubcategory } from '../../infra/requests/WeddingInterestRequests';
import { getEmployeesOrdered, getStartTimeBySubcategory, updateRowsAndEmployeesList } from '../../infra/services/wedding/weddingUtils';
import { GetEmployeePriceByEmployee, GetEmployeePriceByEmployeeAndSubcategory } from '../../infra/requests/EmployeePriceRequests';
import { isCurrentUserAdmin, isCurrentUserChief, isCurrentUserEmployee, isCurrentUserHumanResources } from '../../infra/helpers/Helpers';
import { GetWeddingTeamRows } from '../../infra/requests/WeddingTeamRequests';
import { TeamTableActions } from '../adminTeamMng/WeddingTeamStyles';

const newWeddingTeam = {
  id: 'new#FFSDD',
  startTime: null,
  endTime: null,
  presence: false,
  notes: '',
  employee: null,
  wedding: null,
  subcategory: null,
  setting: null,
  published: false
};
class AddEditWeddingTeam extends Component {
  state = {
    pageSize: 15,
    currentPage: 1,
    loading: true,
    currentEmployee: null,
    selectedWedding: null,
    ourDay: [],
    scheduler: null,
    employeesInCharge: null,
    teamBooking: [],
    teamComplete: [],
    weddingTeam: [],
    teamPublished: false,
    totalRows: 0,
    chiefAllowed: false,

    confirmedAll: false,
    presentAll: false,

    addEditTeam: [],
    deleteTeam: [],
    deleteBooking: [],
    updateBooking: [],

    categories: [],

    columns: [
      {
        title: 'Categoria',
        key: 'category',
        width: '100px',
        render: data => (data?.weddingTeam?.subcategory && !data?.extraToBooking
          ? data?.weddingTeam?.subcategory?.category && data?.weddingTeam?.subcategory?.category?.name
          : <SelectInput
            allowClear
            placeholder="Escolher a categoria"
            disabled={this.state.payments?.length > 0 || !data?.extraToBooking} //this.state.teamPublished
            data={this.state.categories}
            input={{
              value: data.category?.id,
              onChange: event => this.onCategoryChange(event, data)
            }}
            meta={{ valid: true }}
          />
        )
      },
      {
        title: 'Subcategoria',
        key: 'susubcategory',
        width: '120px',
        render: data => (data?.weddingTeam?.subcategory && !data?.extraToBooking
          ? data.weddingTeam.subcategory.name
          : <SelectInput
            allowClear
            placeholder="Escolher a subcategoria"
            disabled={this.state.payments?.length > 0 || !data?.extraToBooking} //this.state.teamPublished
            data={data.subcategories}
            input={{
              value: data.weddingTeam.subcategory?.name,
              onChange: event => this.onSubcategoryChange(event, data)
            }}
            meta={{ valid: true }}
          />
        )
      },
      {
        title: 'Funcionário',
        key: 'employee',
        width: '200px',
        render: data => (
          <Fragment>
            {isCurrentUserHumanResources()
              ? (<div style={{ display: 'inline-flex', width: '100%' }}>
                <SelectInput
                  allowClear
                  placeholder="Escolher o funcionário"
                  data={data.employees}
                  input={{
                    value: data.weddingTeam.employee?.name,
                    onChange: event => this.onEmployeeChange(event, data)
                  }}
                  disabled={this.state.payments?.length > 0} //this.state.teamPublished
                  meta={{ valid: true }}
                />
                {data?.isUnavailable && <Tooltip title="Funcionário indisponível">
                  <Icon type="warning" style={{ margin: 'auto 0 auto 5px', fontSize: 20, paddingTop: 5, color: 'orange' }} />
                </Tooltip>}
                {data?.weddingTeam?.employee?.deleted && <Tooltip title="Funcionário inativo">
                  <Icon type="user-delete" style={{ margin: 'auto 0 auto 5px', fontSize: 20, paddingTop: 5, color: 'red' }} />
                </Tooltip>}
              </div>)
              : (<div>{data?.weddingTeam?.employee?.name}</div>)}
          </Fragment>
        )
      },
      {
        title: 'Horário de Entrada',
        width: '100px',
        render: (data) => (
          <TimePicker style={{ width: '100%' }}
            type="time"
            format='HH:mm'
            disabled={this.state.payments?.length > 0} //this.state.teamPublished
            value={data.weddingTeam.startTime ? moment.utc(data.weddingTeam.startTime).local() : moment.utc().local()}
            onChange={event => {
              let { rows, addEditTeam, deleteTeam } = this.state;
              data.weddingTeam.startTime = event ? event.utc() : moment().utc();
              const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
              rows[index] = data;

              const indexEdit = addEditTeam.findIndex(f => f.id === data.weddingTeam.id);
              if (indexEdit === -1) addEditTeam.push(data.weddingTeam);
              else addEditTeam[indexEdit] = data.weddingTeam;

              deleteTeam = deleteTeam.filter(f => f.id !== data.weddingTeam.id);

              this.setState({ rows, addEditTeam, deleteTeam });
              this.props.updateWeddingTeam({ addEdit: addEditTeam, delete: deleteTeam });
              this.props.updateRowsTeam(rows);
            }}
          />
        )
      },
      {
        title: 'Confirmado',
        key: 'confirmed',
        width: '60px',
        render: data => (
          <div className="sc-dxgOiQ fWHHkk">
            <Checkbox
              checked={data.weddingTeam.confirmed}
              disabled={this.state.payments?.length > 0}
              onChange={(e) => {
                let { rows, addEditTeam, deleteTeam } = this.state;
                data.weddingTeam.confirmed = e.target.checked;

                const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
                rows[index] = data;

                const indexEdit = addEditTeam.findIndex(f => f.id === data.weddingTeam.id);
                if (indexEdit === -1) addEditTeam.push(data.weddingTeam);
                else addEditTeam[indexEdit] = data.weddingTeam;

                deleteTeam = deleteTeam.filter(f => f.id !== data.weddingTeam.id);

                this.setState({ rows, addEditTeam, deleteTeam });
                this.props.updateWeddingTeam({ addEdit: addEditTeam, delete: deleteTeam });
                this.props.updateRowsTeam(rows);
              }}>
              Sim
            </Checkbox>
          </div>
        )
      },
      {
        title: 'Notas',
        key: 'notes',
        width: '250px',
        render: (data) => (
          <div className="sc-dxgOiQ fWHHkk">
            <textarea
              placeholder="Escreva as suas notas"
              className="ant-input sc-hSdWYo jDnoVv"
              style={{ height: '50px', minHeight: '50px' }}
              value={data.weddingTeam.notes}
              disabled={this.state.payments?.length > 0} //this.state.teamPublished
              onChange={event => {
                let { rows, addEditTeam, deleteTeam } = this.state;
                const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
                rows[index].weddingTeam.notes = event.target.value;

                const indexEdit = addEditTeam.findIndex(f => f.id === data.weddingTeam.id);
                if (indexEdit === -1) addEditTeam.push(data.weddingTeam);
                else addEditTeam[indexEdit] = data.weddingTeam;

                deleteTeam = deleteTeam.filter(f => f.id !== data.weddingTeam.id);

                this.setState({ rows, addEditTeam, deleteTeam });
                this.props.updateWeddingTeam({ addEdit: addEditTeam, delete: deleteTeam });
                this.props.updateRowsTeam(rows);
              }}
            />
          </div>
        )
      },
      {

        title: 'Opção',
        width: '50px',
        render: (data) => (
          this.state.payments?.length === 0 ? <TableButton
            // disabled={this.state.loading}
            onClick={async () => {
              const { teamBooking, selectedWedding } = this.state;
              let { rows, addEditTeam, deleteTeam, deleteBooking, updateBooking } = this.state;
              // const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);

              if (data.weddingTeam.employee) {
                const rowsEmp = rows.filter(f => f.employeesOriginal.length > 0 && f.employeesOriginal.filter(f => f.id === data.weddingTeam.employee.id).length > 0);
                rowsEmp.forEach(row => {
                  const indexRow = rows.findIndex(f => f.weddingTeam.id === row.weddingTeam.id);
                  const indexEmp = row.employees.findIndex(f => f.id === data.weddingTeam.employee.id);
                  if (indexEmp === -1) rows[indexRow].employees.push(data.weddingTeam.employee);
                });
              }

              data.weddingTeam.employee = null;
              data.weddingTeam.confirmed = false;
              data.weddingTeam.presence = false;
              data.weddingTeam.notes = '';
              data.weddingTeam.value = 0.00;
              data.weddingTeam.bonus = 0.00;
              data.weddingTeam.total = 0.00;
              data.employeePrice = [];

              //Set list of employees by subcategory
              if (data.weddingTeam.subcategory) {
                const startTime = data?.weddingTeam?.startTime ? data.weddingTeam.startTime : null;
                const resultEmp = await GetEmployeesAvailableBySubcategory(selectedWedding.date, data.weddingTeam.subcategory.id, selectedWedding.id, startTime);
                const resInterest = { success: resultEmp.success, data: resultEmp.data.interest };
                const resEmpBySub = { success: resultEmp.success, data: resultEmp.data.subcategories };

                data.employees = getEmployeesOrdered(resInterest, resEmpBySub);
                data.employeesOriginal = getEmployeesOrdered(resInterest, resEmpBySub);
                data.selectedTeams = resultEmp.success && resultEmp.data ? resultEmp.data.selectedTeams : [];
              } else {
                data.employees = [];
                data.employeesOriginal = [];
                data.selectedTeams = [];
              }
              if (data.weddingTeam.employee && data.employees.findIndex(f => f.id === data.weddingTeam.employee.id) === -1) data.employees.push(data.weddingTeam.employee);

              //Update row
              const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
              rows[index] = data;

              //Update all rows and employees list
              rows = updateRowsAndEmployeesList(rows, selectedWedding);
              rows = rows.filter(f => f.weddingTeam.id !== data.weddingTeam.id);

              addEditTeam = addEditTeam.filter(f => f.id !== data.weddingTeam.id);

              if (data.extraToBooking === false) {
                const booking = teamBooking.find(f => f.subcategory.id === data.weddingTeam.subcategory.id);
                if (booking && booking.quantity === 1) {
                  const indexDeleteBooking = deleteBooking.findIndex(f => f.id === booking.id);
                  if (indexDeleteBooking === -1) deleteBooking.push(booking);

                  updateBooking = updateBooking.filter(f => f.id !== booking.id);
                } else if (booking && booking.quantity > 1) {
                  booking.quantity = booking.quantity - 1;
                  const indexUpdateBooking = updateBooking.findIndex(f => f.id === booking.id);
                  if (indexUpdateBooking === -1) updateBooking.push(booking);

                  deleteBooking = deleteBooking.filter(f => f.id !== booking.id);
                }
              }

              if (!data.weddingTeam.id.includes('new#FFSDD')) {
                const indexDelete = deleteTeam.findIndex(f => f.id === data.weddingTeam.id);
                if (indexDelete > -1) deleteTeam[indexDelete] = data.weddingTeam;
                else deleteTeam.push(data.weddingTeam);
              }

              this.setState({ rows, addEditTeam, deleteTeam });
              this.props.updateWeddingTeam({ addEdit: addEditTeam, delete: deleteTeam });
              this.props.deleteBooking(deleteBooking);
              this.props.updateBooking(updateBooking);
              this.props.updateRowsTeam(rows);
            }}
          >
            <Icon type="delete" />
          </TableButton>
            : null)
      }
    ],
    columnsChief: [
      {
        title: 'Categoria: Subcategoria',
        key: 'subcategory',
        width: '200px',
        render: data => <div>{data?.weddingTeam?.subcategory?.category?.name}: <b>{data?.weddingTeam?.subcategory?.name}</b></div>
      },
      {
        title: 'Funcionário',
        key: 'employee',
        width: '200px',
        render: data => (
          <Fragment>
            <div>{data?.weddingTeam?.employee?.name}</div>
          </Fragment>
        )
      },
      {
        title: 'Horário de Entrada',
        width: '100px',
        render: data => (
          <Fragment>
            <div>{moment(data?.weddingTeam?.startTime).format('HH:mm')}</div>
          </Fragment>
        )
      },
      {
        title: 'Presença',
        key: 'presence',
        width: '6%',
        render: (data) => (
          <div className="sc-dxgOiQ fWHHkk">
            <Checkbox
              checked={data.weddingTeam.presence}
              disabled={!this.state.chiefAllowed || this.state.payments?.length > 0}
              onChange={(e) => {
                let { rows, addEditTeam, deleteTeam } = this.state;
                data.weddingTeam.presence = e.target.checked;

                const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
                rows[index] = data;

                const indexEdit = addEditTeam.findIndex(f => f.id === data.weddingTeam.id);
                if (indexEdit === -1) addEditTeam.push(data.weddingTeam);
                else addEditTeam[indexEdit] = data.weddingTeam;

                deleteTeam = deleteTeam.filter(f => f.id !== data.weddingTeam.id);

                this.setState({ rows, addEditTeam, deleteTeam });
                this.props.updateWeddingTeam({ addEdit: addEditTeam, delete: deleteTeam });
                this.props.updateRowsTeam(rows);
              }}>
              Sim
            </Checkbox>
          </div>
        )
      },
      {
        title: 'Notas',
        key: 'notes',
        width: '250px',
        render: (data, index) => (
          <div className="sc-dxgOiQ fWHHkk">
            <textarea
              placeholder="Escreva as suas notas"
              className="ant-input sc-hSdWYo jDnoVv"
              style={{ height: '50px', minHeight: '50px' }}
              disabled={!this.state.chiefAllowed || this.state.payments?.length > 0}
              value={data.weddingTeam.notes}
              onChange={event => {
                let { rows, addEditTeam, deleteTeam } = this.state;
                const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
                rows[index].weddingTeam.notes = event.target.value;

                const indexEdit = addEditTeam.findIndex(f => f.id === data.weddingTeam.id);
                if (indexEdit === -1) addEditTeam.push(data.weddingTeam);
                else addEditTeam[indexEdit] = data.weddingTeam;

                deleteTeam = deleteTeam.filter(f => f.id !== data.weddingTeam.id);

                this.setState({ rows, addEditTeam, deleteTeam });
                this.props.updateWeddingTeam({ addEdit: addEditTeam, delete: deleteTeam });
                this.props.updateRowsTeam(rows);
              }}
            />
          </div>
        )
      },
      {
        title: 'Opção',
        width: '50px',
        render: (data) => (
          (this.state.chiefAllowed && this.state.payments?.length === 0) ? <TableButton
            onClick={() => {
              let { rows, addEditTeam, deleteTeam } = this.state;
              data.weddingTeam.presence = false;
              data.weddingTeam.notes = '';

              const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
              rows[index] = data;

              const indexAddEdit = addEditTeam.findIndex(f => f.id === data.weddingTeam.id)
              if (indexAddEdit > -1) addEditTeam[indexAddEdit] = data.weddingTeam;
              else addEditTeam.push(data.weddingTeam);

              this.setState({ rows, addEditTeam, deleteTeam });
              this.props.updateWeddingTeam({ addEdit: addEditTeam, delete: deleteTeam });
              this.props.updateRowsTeam(rows);
            }}
          >
            <Icon type="delete" />
          </TableButton> : null
        )
      }
    ],
    columnsRHChief: [
      {
        title: 'Categoria',
        key: 'category',
        width: '100px',
        render: data => (data?.weddingTeam?.subcategory && !data?.extraToBooking
          ? data?.weddingTeam?.subcategory?.category && data?.weddingTeam?.subcategory?.category?.name
          : <SelectInput
            allowClear
            placeholder="Escolher a categoria"
            disabled={this.state.payments?.length > 0 || !data?.extraToBooking} //this.state.teamPublished
            data={this.state.categories}
            input={{
              value: data.category?.id,
              onChange: event => this.onCategoryChange(event, data)
            }}
            meta={{ valid: true }}
          />
        )
      },
      {
        title: 'Subcategoria',
        key: 'subcategory',
        width: '120px',
        render: data => (data?.weddingTeam?.subcategory && !data?.extraToBooking
          ? data?.weddingTeam?.subcategory?.name
          : <SelectInput
            allowClear
            placeholder="Escolher a subcategoria"
            disabled={this.state.payments?.length > 0 || !data?.extraToBooking} //this.state.teamPublished
            data={data.subcategories}
            input={{
              value: data.weddingTeam.subcategory?.name,
              onChange: event => this.onSubcategoryChange(event, data)
            }}
            meta={{ valid: true }}
          />
        )
      },
      {
        title: 'Funcionário',
        key: 'employee',
        width: '200px',
        render: data => (
          <Fragment>
            {isCurrentUserHumanResources()
              ? (<div style={{ display: 'inline-flex', width: '100%' }}>
                <SelectInput
                  allowClear
                  placeholder="Escolher o funcionário"
                  data={data.employees}
                  input={{
                    value: data.weddingTeam.employee?.name,
                    onChange: event => this.onEmployeeChange(event, data)
                  }}
                  disabled={this.state.payments?.length > 0} //this.state.teamPublished
                  meta={{ valid: true }}
                />
                {data?.isUnavailable && <Tooltip title="Funcionário indisponível">
                  <Icon type="warning" style={{ margin: 'auto 0 auto 5px', fontSize: 20, paddingTop: 5, color: 'orange' }} />
                </Tooltip>}
                {data?.weddingTeam?.employee?.deleted && <Tooltip title="Funcionário inativo">
                  <Icon type="user-delete" style={{ margin: 'auto 0 auto 5px', fontSize: 20, paddingTop: 5, color: 'red' }} />
                </Tooltip>}
              </div>)
              : (<div>{data?.weddingTeam?.employee?.name}</div>)}
          </Fragment>
        )
      },
      {
        title: 'Horário de Entrada',
        width: '80px',
        render: (data) => (
          <TimePicker style={{ width: '100%' }}
            type="time"
            format='HH:mm'
            disabled={this.state.payments?.length > 0} //this.state.teamPublished
            value={data.weddingTeam.startTime ? moment(data.weddingTeam.startTime).local() : moment().local()}
            onChange={event => {
              let { rows, addEditTeam, deleteTeam } = this.state;
              data.weddingTeam.startTime = event ? event.utc() : moment().utc();
              const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
              rows[index] = data;

              const indexEdit = addEditTeam.findIndex(f => f.id === data.weddingTeam.id);
              if (indexEdit === -1) addEditTeam.push(data.weddingTeam);
              else addEditTeam[indexEdit] = data.weddingTeam;

              deleteTeam = deleteTeam.filter(f => f.id !== data.weddingTeam.id);

              this.setState({ rows, addEditTeam, deleteTeam });
              this.props.updateWeddingTeam({ addEdit: addEditTeam, delete: deleteTeam });
              this.props.updateRowsTeam(rows);
            }}
          />
        )
      },
      {
        title: 'Confirmado',
        key: 'confirmed',
        width: '65px',
        render: data => (
          <div className="sc-dxgOiQ fWHHkk">
            <Checkbox
              checked={data?.weddingTeam?.confirmed}
              disabled={this.state.payments.length > 0}
              onChange={(e) => {
                let { rows, addEditTeam, deleteTeam } = this.state;
                data.weddingTeam.confirmed = e.target.checked;

                const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
                rows[index] = data;

                const indexEdit = addEditTeam.findIndex(f => f.id === data.weddingTeam.id);
                if (indexEdit === -1) addEditTeam.push(data.weddingTeam);
                else addEditTeam[indexEdit] = data.weddingTeam;

                deleteTeam = deleteTeam.filter(f => f.id !== data.weddingTeam.id);

                this.setState({ rows, addEditTeam, deleteTeam });
                this.props.updateWeddingTeam({ addEdit: addEditTeam, delete: deleteTeam });
                this.props.updateRowsTeam(rows);
              }}>
              Sim
            </Checkbox>
          </div>
        )
      },
      {
        title: 'Presença',
        key: 'presence',
        width: '6%',
        render: (data) => (
          <div className="sc-dxgOiQ fWHHkk">
            <Checkbox
              checked={data.weddingTeam.presence}
              disabled={!this.state.chiefAllowed}
              onChange={(e) => {
                let { rows, addEditTeam, deleteTeam } = this.state;
                data.weddingTeam.presence = e.target.checked;

                const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
                rows[index] = data;

                const indexEdit = addEditTeam.findIndex(f => f.id === data.weddingTeam.id);
                if (indexEdit === -1) addEditTeam.push(data.weddingTeam);
                else addEditTeam[indexEdit] = data.weddingTeam;

                deleteTeam = deleteTeam.filter(f => f.id !== data.weddingTeam.id);

                this.setState({ rows, addEditTeam, deleteTeam });
                this.props.updateWeddingTeam({ addEdit: addEditTeam, delete: deleteTeam });
                this.props.updateRowsTeam(rows);
              }}>
              Sim
            </Checkbox>
          </div>
        )
      },
      {
        title: 'Notas',
        key: 'notes',
        width: '250px',
        render: (data) => (
          <div className="sc-dxgOiQ fWHHkk">
            <textarea
              placeholder="Escreva as suas notas"
              className="ant-input sc-hSdWYo jDnoVv"
              style={{ height: '50px', minHeight: '50px' }}
              value={data.weddingTeam.notes}
              disabled={!this.state.chiefAllowed} //this.state.teamPublished
              onChange={event => {
                let { rows, addEditTeam, deleteTeam } = this.state;
                const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
                rows[index].weddingTeam.notes = event.target.value;

                const indexEdit = addEditTeam.findIndex(f => f.id === data.weddingTeam.id);
                if (indexEdit === -1) addEditTeam.push(data.weddingTeam);
                else addEditTeam[indexEdit] = data.weddingTeam;

                deleteTeam = deleteTeam.filter(f => f.id !== data.weddingTeam.id);

                this.setState({ rows, addEditTeam, deleteTeam });
                this.props.updateWeddingTeam({ addEdit: addEditTeam, delete: deleteTeam });
                this.props.updateRowsTeam(rows);
              }}
            />
          </div>
        )
      },
      {

        title: 'Opção',
        width: '50px',
        render: (data) => (
          this.state.payments.length === 0
            ? <TableButton
              // disabled={this.state.loading}
              onClick={async () => {
                this.setState({ loading: true });
                const { teamBooking, selectedWedding } = this.state;
                let { rows, addEditTeam, deleteTeam, deleteBooking, updateBooking } = this.state;
                // const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);

                if (data.weddingTeam.employee) {
                  const rowsEmp = rows.filter(f => f.employeesOriginal.length > 0 && f.employeesOriginal.filter(f => f.id === data.weddingTeam.employee.id).length > 0);
                  rowsEmp.forEach(row => {
                    const indexRow = rows.findIndex(f => f.weddingTeam.id === row.weddingTeam.id);
                    const indexEmp = row.employees.findIndex(f => f.id === data.weddingTeam.employee.id);
                    if (indexEmp === -1) rows[indexRow].employees.push(data.weddingTeam.employee);
                  });
                }

                data.weddingTeam.employee = null;
                data.weddingTeam.confirmed = false;
                data.weddingTeam.presence = false;
                data.weddingTeam.notes = '';
                data.weddingTeam.value = 0.00;
                data.weddingTeam.bonus = 0.00;
                data.weddingTeam.total = 0.00;
                data.employeePrice = [];

                //Set list of employees by subcategory
                if (data.weddingTeam.subcategory) {
                  const startTime = data.weddingTeam.startTime ? data.weddingTeam.startTime : null;
                  const resultEmp = await GetEmployeesAvailableBySubcategory(selectedWedding.date, data.weddingTeam.subcategory.id, selectedWedding.id, startTime);
                  const resInterest = { success: resultEmp.success, data: resultEmp.data.interest };
                  const resEmpBySub = { success: resultEmp.success, data: resultEmp.data.subcategories };

                  data.employees = getEmployeesOrdered(resInterest, resEmpBySub);
                  data.employeesOriginal = getEmployeesOrdered(resInterest, resEmpBySub);
                  data.selectedTeams = resultEmp.success && resultEmp.data ? resultEmp.data.selectedTeams : [];
                } else {
                  data.employees = [];
                  data.employeesOriginal = [];
                  data.selectedTeams = [];
                }
                if (data.weddingTeam.employee && data.employees.findIndex(f => f.id === data.weddingTeam.employee.id) === -1) data.employees.push(data.weddingTeam.employee);

                //Update row
                const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
                rows[index] = data;

                //Update all rows and employees list
                rows = updateRowsAndEmployeesList(rows, selectedWedding);
                rows = rows.filter(f => f.weddingTeam.id !== data.weddingTeam.id);

                addEditTeam = addEditTeam.filter(f => f.id !== data.weddingTeam.id);

                if (data.extraToBooking === false) {
                  const booking = teamBooking.find(f => f.subcategory.id === data.weddingTeam.subcategory.id);
                  if (booking && booking.quantity === 1) {
                    const indexDeleteBooking = deleteBooking.findIndex(f => f.id === booking.id);
                    if (indexDeleteBooking === -1) deleteBooking.push(booking);

                    updateBooking = updateBooking.filter(f => f.id !== booking.id);
                  } else if (booking && booking.quantity > 1) {
                    booking.quantity = booking.quantity - 1;
                    const indexUpdateBooking = updateBooking.findIndex(f => f.id === booking.id);
                    if (indexUpdateBooking === -1) updateBooking.push(booking);

                    deleteBooking = deleteBooking.filter(f => f.id !== booking.id);
                  }
                }

                if (!data.weddingTeam.id.includes('new#FFSDD')) {
                  const indexDelete = deleteTeam.findIndex(f => f.id === data.weddingTeam.id);
                  if (indexDelete > -1) deleteTeam[indexDelete] = data.weddingTeam;
                  else deleteTeam.push(data.weddingTeam);
                }

                this.setState({ rows, addEditTeam, deleteTeam, loading: false });
                this.props.updateWeddingTeam({ addEdit: addEditTeam, delete: deleteTeam });
                this.props.deleteBooking(deleteBooking);
                this.props.updateBooking(updateBooking);
                this.props.updateRowsTeam(rows);
              }}
            >
              <Icon type="delete" />
            </TableButton>
            : null)
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    //to test only
    // const now = moment.utc(this.props.selectedWedding.date, 'YYYY-MM-DDTHH:mm:ssZ')
    const now = moment.utc();
    const weddingDate = moment.utc(this.props.selectedWedding.date);
    const startWeddingDate = moment.utc(weddingDate).startOf('day');
    const endWeddingDate = moment.utc(startWeddingDate.toDate().toDateString() + ' 13:00:00');
    endWeddingDate.add(1, 'day');

    this.setState({
      currentEmployee: this.props.currentEmployee,
      selectedWedding: this.props.selectedWedding,
      ourDay: this.props.ourDay,
      scheduler: this.props.scheduler,
      employeesInCharge: this.props.employeesInCharge,
      teamBooking: this.props.teamBooking,
      weddingTeam: this.props.weddingTeam,
      teamPublished: this.props.published,
      payments: this.props.payments,
      chiefAllowed: (now.isSameOrAfter(startWeddingDate) && now.isBefore(endWeddingDate)) || (now.isAfter(startWeddingDate) && now.isSameOrBefore(endWeddingDate)),
      confirmedAll: false,
      presentAll: false,
    }, () => {
      this.getCategories();
      this.setRows();
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.selectedWedding !== this.props.selectedWedding) {
      //to test only
      // const now = moment(this.props.selectedWedding.date, 'YYYY-MM-DDTHH:mm:ssZ')
      const now = moment.utc();
      const weddingDate = moment.utc(this.props.selectedWedding.date);
      const startWeddingDate = moment.utc(weddingDate).startOf('day');
      const endWeddingDate = moment.utc(startWeddingDate.toDate().toDateString() + ' 13:00:00');
      endWeddingDate.add(1, 'day');

      this.setState({
        currentEmployee: this.props.currentEmployee,
        selectedWedding: this.props.selectedWedding,
        ourDay: this.props.ourDay,
        scheduler: this.props.scheduler,
        employeesInCharge: this.props.employeesInCharge,
        teamBooking: this.props.teamBooking,
        teamComplete: this.props.teamComplete,
        weddingTeam: this.props.weddingTeam,
        teamPublished: this.props.published,
        payments: this.props.payments,
        chiefAllowed: (now.isSameOrAfter(startWeddingDate) && now.isBefore(endWeddingDate)) || (now.isAfter(startWeddingDate) && now.isSameOrBefore(endWeddingDate)),
        confirmedAll: false,
        presentAll: false,
      }, () => {
        //this.setRows() 
      }
      );
    }
  }

  getCategories = async () => {
    const result = await GetCategories();
    let categories = result?.data && Array.isArray(result.data) && result.data.length > 0
      ? result.data
      : [];
    categories = categories.filter(f => f?.subcategories && Array.isArray(f.subcategories) && f.subcategories.length > 0
      && f.subcategories.filter(s => !s?.coordinator && !s?.chief && !s?.host).length > 0);
    this.setState({ categories });
  }

  setRows = async () => {
    let { selectedWedding, teamBooking, teamComplete, weddingTeam, totalRows } = this.state;
    let { rows } = this.state;

    const result = await GetWeddingTeamRows(selectedWedding?._id);
    if (!result.success) console.error(result.message);
    else {
      rows = result.data.rows;
      totalRows = result.data.totalRows;
    }

    // console.warn('Rows', rows);
    rows = updateRowsAndEmployeesList(rows, selectedWedding);

    this.setState({ rows, totalRows: rows.length, loading: false });
    this.props.updateRowsBooking(totalRows);
    this.props.updateRowsTeam(rows);
  }

  setSubcategories = async (event, data) => {
    const { rows, categories, addEditTeam, deleteTeam } = this.state;

    data.category = categories.find(f => f?.id && f?.id === event);

    const resultSub = await GetSubcategoriesByCategory(event);
    data.subcategories = resultSub?.data && Array.isArray(resultSub.data) && resultSub.data.length > 0
      ? resultSub.data
      : [];

    const index = rows.findIndex(f => f?.weddingTeam?.id && data?.weddingTeam?.id && f.weddingTeam.id === data.weddingTeam.id);
    rows[index] = data;

    const indexEdit = addEditTeam.findIndex(f => f?.id && data?.weddingTeam?.id && f.id === data.weddingTeam.id);
    if (indexEdit === -1) addEditTeam.push(data.weddingTeam);
    else addEditTeam[indexEdit] = data.weddingTeam;

    this.setState({ rows, addEditTeam });
    this.props.updateWeddingTeam({ addEdit: addEditTeam, delete: deleteTeam });
    this.props.updateRowsTeam(rows);
  }

  onCategoryChange = async (event, data) => {
    this.setState({ disableInputs: true });
    const { selectedWedding } = this.state;
    let { rows, categories, addEditTeam, deleteTeam } = this.state;
    const dataOriginal = JSON.parse(JSON.stringify(data));

    //If Employee Selected update employees list
    if (dataOriginal.weddingTeam.employee) {
      const rowsEmp = rows.filter(f => f.employeesOriginal.length > 0 && f.employeesOriginal.filter(f => f.id === dataOriginal.weddingTeam.employee.id).length > 0);
      rowsEmp.forEach(row => {
        const indexRow = rows.findIndex(f => f.weddingTeam.id === row.weddingTeam.id);
        const indexEmp = row.employees.findIndex(f => f.id === dataOriginal.weddingTeam.employee.id);
        if (indexEmp === -1) rows[indexRow].employees.push(dataOriginal.weddingTeam.employee);
      });
    }

    const category = categories.find(f => f.id === event);
    data.category = category ? category : null;
    data.weddingTeam.startTime = category ? category.startTime : moment();
    data.subcategories = category ? category.subcategories : [];
    // data.weddingTeam.subcategory = null;
    data.employees = [];
    data.employeesOriginal = [];
    data.selectedTeams = [];
    data.employeePrice = [];

    //Update row
    const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
    rows[index] = data;

    rows = updateRowsAndEmployeesList(rows, selectedWedding);

    const indexAddEdit = addEditTeam.findIndex(f => f.id === data.weddingTeam.id);
    if (indexAddEdit > -1 && data.weddingTeam.employee) addEditTeam[indexAddEdit] = data.weddingTeam;
    else if (data.weddingTeam.employee) addEditTeam.push(data.weddingTeam);

    this.setState({ rows, addEditTeam, deleteTeam, disableInputs: false }, () => {
      //Update employee list from previous subcategory
      this.onSubcategoryChange('', data);
    });
    this.props.updateWeddingTeam({ addEdit: addEditTeam, delete: deleteTeam });
    this.props.updateRowsTeam(rows);
  }

  onSubcategoryChange = async (event, data) => {
    this.setState({ disableInputs: true });
    let { rows, addEditTeam, deleteTeam, categories, selectedWedding, scheduler, teamBooking, deleteBooking, updateBooking } = this.state;
    const dataOriginal = JSON.parse(JSON.stringify(data));

    //If Employee Selected update employees list
    if (dataOriginal.weddingTeam.employee) {
      const rowsEmp = rows.filter(f => f.employeesOriginal.length > 0 && f.employeesOriginal.filter(f => f.id === dataOriginal.weddingTeam.employee.id).length > 0);
      rowsEmp.forEach(row => {
        const indexRow = rows.findIndex(f => f.weddingTeam.id === row.weddingTeam.id);
        const indexEmp = row.employees.findIndex(f => f.id === dataOriginal.weddingTeam.employee.id);
        if (indexEmp === -1) rows[indexRow].employees.push(dataOriginal.weddingTeam.employee);
      });
    }

    // if (dataOriginal.weddingTeam.subcategory && dataOriginal.weddingTeam.subcategory.id !== event) {
    //   const booking = teamBooking.find(f => f.subcategory && dataOriginal.weddingTeam.subcategory && f.subcategory.id === dataOriginal.weddingTeam.subcategory.id);
    //   if (booking && booking.quantity === 1) {
    //     const indexDeleteBooking = deleteBooking.findIndex(f => f.id === booking.id);
    //     if (indexDeleteBooking === -1) deleteBooking.push(booking);
    //   } else if (booking && booking.quantity > 1) {
    //     booking.quantity = booking.quantity - 1;
    //     const indexUpdateBooking = updateBooking.findIndex(f => f.id === booking.id);
    //     if (indexUpdateBooking === -1) updateBooking.push(booking);
    //   }
    // }

    const subcategory = data.subcategories.find(f => f.id === event);
    data.weddingTeam.subcategory = subcategory ? subcategory : null;

    //Calculate start time based on subcategory rules or category start time
    if (subcategory && subcategory.timeRule && subcategory.timeRule !== 0.00 && subcategory.weddingTimeRule) {
      const startTime = getStartTimeBySubcategory(subcategory, scheduler);
      if (startTime) data.weddingTeam.startTime = startTime;
    } else if (subcategory && subcategory.category) {
      data.weddingTeam.startTime = moment(subcategory.category.startTime);
    } else data.weddingTeam.startTime = moment();

    //Fill employee list based on the subcategory selected
    if (event === '' || !data.weddingTeam.subcategory) {
      data.employees = [];
      data.employeesOriginal = [];
      data.selectedTeams = [];
    } else {
      const startTime = subcategory.category && data.weddingTeam.startTime ? data.weddingTeam.startTime : null;
      const resultEmp = await GetEmployeesAvailableBySubcategory(selectedWedding.date, data.weddingTeam.subcategory.id, selectedWedding.id, startTime);
      const resInterest = { success: resultEmp.success, data: resultEmp.data.interest };
      const resEmpBySub = { success: resultEmp.success, data: resultEmp.data.subcategories };

      data.employees = getEmployeesOrdered(resInterest, resEmpBySub);
      data.employeesOriginal = getEmployeesOrdered(resInterest, resEmpBySub);
      data.selectedTeams = resultEmp.success && resultEmp.data ? resultEmp.data.selectedTeams : [];
    }
    if (data.weddingTeam.employee && data.employees.findIndex(f => f.id === data.weddingTeam.employee.id) === -1) data.employees.push(data.weddingTeam.employee);
    data.employeePrice = [];

    //Update row
    const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
    rows[index] = data;

    rows = updateRowsAndEmployeesList(rows, selectedWedding);

    const indexAddEdit = addEditTeam.findIndex(f => f.id === data.weddingTeam.id);
    if (indexAddEdit > -1 && data.weddingTeam.employee) addEditTeam[indexAddEdit] = data.weddingTeam;
    else if (data.weddingTeam.employee) addEditTeam.push(data.weddingTeam);

    this.setState({ rows, addEditTeam, deleteTeam, categories, disableInputs: false }, () => {
      //Update employee list from previous subcategory
      this.onEmployeeChange('', data);
    });
    this.props.updateWeddingTeam({ addEdit: addEditTeam, delete: deleteTeam });
    this.props.updateRowsTeam(rows);
  }

  onEmployeeChange = async (event, data) => {
    this.setState({ loading: true });
    let { rows, addEditTeam, deleteTeam, selectedWedding } = this.state;
    const dataOriginal = JSON.parse(JSON.stringify(data));

    if (dataOriginal?.weddingTeam?.employee) {
      const rowsEmp = rows.filter(f => f.employeesOriginal.length > 0 && f.employeesOriginal.filter(f => f.id === dataOriginal.weddingTeam.employee.id).length > 0);
      rowsEmp.forEach(row => {
        const indexRow = rows.findIndex(f => f.weddingTeam.id === row.weddingTeam.id);
        const indexEmp = row.employees.findIndex(f => f.id === dataOriginal.weddingTeam.employee.id);
        if (indexEmp === -1) rows[indexRow].employees.push(dataOriginal.weddingTeam.employee);
      });
    }

    if (event && event === '') {
      data.weddingTeam.employee = null;
      data.weddingTeam.confirmed = false;
      data.weddingTeam.presence = false;
      data.weddingTeam.notes = '';
      data.weddingTeam.value = 0.00;
      data.weddingTeam.bonus = 0.00;
      data.weddingTeam.total = 0.00;
      data.employeePrice = [];
      data.isUnavailable = false;
    } else {
      const resPrice = await GetEmployeePriceByEmployee(event);
      const employeePrice = resPrice.success && resPrice.data ? resPrice.data : [];
      const priceBySubcategory = employeePrice.length > 0 && employeePrice.find(f => f.subcategory.id === data.weddingTeam.subcategory.id);

      data.weddingTeam.employee = data.employees.find(f => f?.id && f.id == event);
      data.weddingTeam.value = priceBySubcategory ? priceBySubcategory.value : 0.00;
      data.weddingTeam.setting = priceBySubcategory ? priceBySubcategory.setting : null;
      data.employeePrice = employeePrice;
      data.isUnavailable = false;
    }

    //Set list of employees by subcategory
    if (data?.weddingTeam?.subcategory) {
      const startTime = data?.weddingTeam?.startTime ? data.weddingTeam.startTime : null;
      const resultEmp = await GetEmployeesAvailableBySubcategory(selectedWedding.date, data.weddingTeam.subcategory.id, selectedWedding.id, startTime);
      const resInterest = { success: resultEmp.success, data: resultEmp.data.interest };
      const resEmpBySub = { success: resultEmp.success, data: resultEmp.data.subcategories };

      data.employees = getEmployeesOrdered(resInterest, resEmpBySub);
      data.employeesOriginal = getEmployeesOrdered(resInterest, resEmpBySub);
      data.selectedTeams = resultEmp.success && resultEmp.data ? resultEmp.data.selectedTeams : [];
      if (data.weddingTeam.employee && data.employees.findIndex(f => f.id === data.weddingTeam.employee.id) === -1) data.employees.push(data.weddingTeam.employee);
    } else {
      data.employees = [];
      data.employeesOriginal = [];
      data.selectedTeams = [];
    }

    //Update row
    const index = rows.findIndex(f => f?.weddingTeam?.id && data?.weddingTeam?.id && f.weddingTeam.id === data.weddingTeam.id);
    rows[index] = data;

    //Update all rows and employees list
    rows = updateRowsAndEmployeesList(rows, selectedWedding);

    if (event === '') {
      addEditTeam = addEditTeam.filter(f => f?.id && data?.weddingTeam?.id && f.id !== data.weddingTeam.id);

      if (!data?.weddingTeam?.published && !data?.weddingTeam?.id.includes('new#FFSDD')) {
        const indexDelete = deleteTeam.findIndex(f => f?.id && data?.weddingTeam?.id && f.id === data.weddingTeam.id);
        if (indexDelete > -1) deleteTeam[indexDelete] = data.weddingTeam;
        else deleteTeam.push(data.weddingTeam);
      }
    } else {
      const indexEdit = addEditTeam.findIndex(f => f?.id && data?.weddingTeam?.id && f.id === data.weddingTeam.id);
      if (indexEdit === -1) addEditTeam.push(data.weddingTeam);
      else addEditTeam[indexEdit] = data.weddingTeam;

      deleteTeam.filter(f => f?.id && data?.weddingTeam?.id && f.id === data.weddingTeam.id);
    }

    this.setState({ rows, addEditTeam, deleteTeam, loading: false });
    this.props.updateWeddingTeam({ addEdit: addEditTeam, delete: deleteTeam });
    this.props.updateRowsTeam(rows);
  }

  addRow = () => {
    let { teamPublished, rows, total, selectedWedding, addEditTeam, deleteTeam } = this.state;

    const team = JSON.parse(JSON.stringify(newWeddingTeam));
    team.id = team.id + (moment.utc().valueOf() + (rows?.length || 1));
    team.startTime = moment();
    team.wedding = selectedWedding;
    team.published = teamPublished;

    rows.push({
      weddingTeam: team,
      extraToBooking: true,
      employeePrice: [],
      employees: [],
      employeesOriginal: [],
      selectedTeams: [],
      category: null,
      subcategories: [],
      isUnavailable: false
    });

    // addEditTeam.push(team);
    this.setState({ rows, total: rows.length, addEditTeam, deleteTeam });
    this.props.updateWeddingTeam({ addEdit: addEditTeam, delete: deleteTeam });
    this.props.updateRowsTeam(rows);
  }

  confirmAll = (e) => {
    let { addEditTeam } = this.state;
    const { rows, payments, deleteTeam } = this.state;
    if (payments?.length > 0) return;

    const updatedRows = [...rows];
    updatedRows
      .filter(f => f?.weddingTeam?.employee)
      .forEach(f => {
        f.weddingTeam.confirmed = e?.target?.checked;

        const indexEdit = addEditTeam.findIndex(f2 => f2.id === f.weddingTeam.id);
        if (indexEdit === -1) addEditTeam.push(f.weddingTeam);
        else addEditTeam[indexEdit] = f.weddingTeam;
      });
    this.setState({
      confirmedAll: e?.target?.checked,
      rows: updatedRows
    });

    this.props.updateWeddingTeam({ addEdit: addEditTeam, delete: deleteTeam });
    this.props.updateRowsTeam(rows);
  }

  checkPresentAll = (e) => {
    let { addEditTeam } = this.state;
    const { rows, payments, chiefAllowed, deleteTeam } = this.state;
    if (payments?.length > 0 || !chiefAllowed) return;

    const updatedRows = [...rows];
    updatedRows
      .filter(f => f?.weddingTeam?.employee)
      .forEach(f => {
        f.weddingTeam.presence = e?.target?.checked;

        const indexEdit = addEditTeam.findIndex(f2 => f2.id === f.weddingTeam.id);
        if (indexEdit === -1) addEditTeam.push(f.weddingTeam);
        else addEditTeam[indexEdit] = f.weddingTeam;
      });
    this.setState({
      presentAll: e?.target?.checked,
      rows: updatedRows
    });

    this.props.updateWeddingTeam({ addEdit: addEditTeam, delete: deleteTeam });
    this.props.updateRowsTeam(rows);
  }

  render() {
    const { loading, currentPage, pageSize } = this.state;
    const { currentEmployee, teamPublished, payments, employeesInCharge } = this.state;
    const { columns, columnsChief, columnsRHChief, rows, total } = this.state;
    const { confirmedAll, presentAll, chiefAllowed } = this.state;

    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>

        <Row gutter={24} style={{ marginTop: '2rem', marginBottom: '1rem' }}>
          <Col lg={12} md={8} sm={12} xs={24}>
            <SectionTitle>Equipa do Casamento</SectionTitle>
          </Col>

          <Col lg={12} md={16} sm={12} xs={24}>
            <TeamTableActions>
              {isCurrentUserHumanResources() && <Checkbox
                checked={confirmedAll}
                disabled={payments.length > 0}
                onChange={(e) => this.confirmAll(e)}>
                Confirmar equipa
              </Checkbox>}
              {(isCurrentUserChief() && employeesInCharge?.chief?.length > 0 && employeesInCharge?.chief.find(f => f.employee.id === currentEmployee.id))
                && <Checkbox
                  checked={presentAll}
                  disabled={payments.length > 0 || !chiefAllowed}
                  onChange={(e) => this.checkPresentAll(e)}>
                  Registar presença
                </Checkbox>}
              {isCurrentUserHumanResources() && <BaseButton
                type={'primary'}
                icon={'plus'}
                disabled={payments.length > 0}
                text={'Adicionar Linha'}
                onClick={() => this.addRow()}
              />}
            </TeamTableActions>
          </Col>
        </Row>

        <Table
          columns={isCurrentUserHumanResources() && employeesInCharge?.chief?.length > 0 && employeesInCharge?.chief.find(f => f.employee.id === currentEmployee.id) ? columnsRHChief : isCurrentUserHumanResources() ? columns : columnsChief}
          // currentPage={currentPage}
          // pageSize={pageSize}
          // defaultPageSize={pageSize}
          loading={loading}
          rows={rows}
          showHeader={true}
          emptyIcon="team"
          emptyText={'Ainda não foi definida a equipa'}
          total={total}
          rowKey={'_id'}
          hasPagination={false}
        //handleChangePage={this.handleChangePage}
        //handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </React.Fragment>
    );
  }
}

export default AddEditWeddingTeam;
