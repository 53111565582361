import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Checkbox } from 'antd';
import {
  BaseForm,
} from '../../styles/BasicStyles';
import {
  Row,
  Col,
} from 'antd';
import { useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector, change } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextInput from '../../components/inputs/TextInput';
import TagInput from '../../components/inputs/TagInput';
import SubcategoryPriceTable from './SubcategoryPriceTable';
import { CheckboxField } from '../../components/inputs/InputStyles';
import SimpleCheckboxInput from '../../components/inputs/SimpleCheckboxInput';
import NumberInput from '../../components/inputs/NumberInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import SelectInput from '../../components/inputs/SelectInput';

const FormValidations = values => {
  // console.warn('values', values);
  const validations = {
    name: 'required',
    acronym: 'required|noSpaces',
  };


  if (values?.calcTime == true || values?.calcTime == 'true') {
    validations['timeRule'] = 'required';
    validations['weddingTimeRule'] = 'required';
  }

  if (values?.calcAutomatic == true || values?.calcAutomatic == 'true') {
    validations['quantityRule'] = 'required';
  }

  let errors = FormValidator.make(validations)(values);
  return errors;
}

const weddingTimeRuleOptions = [
  { _id: 'Chegada à Quinta', name: 'Chegada à Quinta' },
  { _id: 'Início dos Aperitivos', name: 'Início dos Aperitivos' },
  { _id: 'Entrada na Sala de Jantar', name: 'Entrada na Sala de Jantar' }
];

const quantityRuleOptions = [
  { _id: '1', name: 'Montagem' },
  { _id: '2', name: 'Início até ao Fim (IF)' },
  { _id: '3', name: 'AJB' },
  { _id: '4', name: 'JB' },
  { _id: '5', name: 'Copa Inteiro' },
  { _id: '6', name: 'Copa Meio' },
  { _id: '7', name: 'Laminadores' },
  { _id: '8', name: 'Decor GOLD' },
  { _id: '9', name: 'Decor SILVER' },
  { _id: '50', name: 'Fixo' }
];

let SubcategoryModal = (props): React$Element<React$FragmentType> => {
  const subcategoryForm = useSelector(state => {
    // console.warn('Val', state.form.subcategory_form?.values);
    return state.form.subcategory_form?.values
  });
  const [initialValues, setInitialValues] = React.useState(null);

  useEffect(() => {
    setInitialValues(JSON.parse(JSON.stringify(props.initialValues)));
  }, [props.initialValues])

  return (
    <Modal
      visible={props.isOpen}
      title={initialValues?.id === null ? 'Adicionar Subcategoria' : 'Editar Subcategoria'}
      maskClosable
      onCancel={props.onClose}
      footer={[
        <Button
          key='cancel'
          onClick={() => props.onClose()}>
          Cancelar
        </Button>,
        <Button
          key='submit'
          type='primary'
          onClick={props.handleSubmit(props.onSubmit)}>
          Gravar
        </Button>
      ]}
    >

      <BaseForm onSubmit={props.handleSubmit(props.onSubmit)}>
        <Row gutter={[12, 12]}>
          <Col lg={12} md={12} xs={24}>
            <Field
              component={TextInput}
              name="name"
              label="Nome"
              placeholder="Insira o nome da subcategoria"
              type="text"
            />
          </Col>

          <Col lg={12} md={12} xs={24}>
            <Field
              component={TagInput}
              name="acronym"
              label="Acrónimo"
              placeholder="Insira o acrónimo da categoria"
              type="text"
            />
          </Col>

          <Col lg={12} md={12} xs={24}>
            <Field
              component={CheckboxInput}
              name="repeat"
              label="Repetição"
              positiveLabel='Sim'
            />
          </Col>

          <Col lg={12} md={12} xs={24}>
            <Field
              component={CheckboxInput}
              name="calcTime"
              label="Horário calculado"
              positiveLabel='Sim'
              onChange={(event) => {
                props.change('timeRule', 0);
                props.change('weddingTimeRule', null);
              }}
            />
          </Col>

          {subcategoryForm?.calcTime &&
            <React.Fragment>
              <Col lg={12} md={12} xs={24}>
                <Field
                  component={NumberInput}
                  name="timeRule"
                  label="Regra de cálculo (horas)"
                  placeholder="Insira a regra"
                  type="number"
                  step='.01'
                />
              </Col>

              <Col lg={12} md={12} xs={24}>
                <Field
                  component={SelectInput}
                  data={weddingTimeRuleOptions}
                  allowClear={true}
                  name="weddingTimeRule"
                  label="Em função de"
                  placeholder="Selecione o momento"
                  type="text"
                />
              </Col>
            </React.Fragment>}

          <Col lg={12} md={12} xs={12}>
            <Field
              component={CheckboxInput}
              name="calcAutomatic"
              label="Quantidade calculada"
              positiveLabel='Sim'
              onChange={(val) => {
                props.change('quantityRule', null);
              }}
            />
          </Col>

          {subcategoryForm?.calcAutomatic && <Col lg={12} md={12} xs={12}>
            <Field
              component={SelectInput}
              data={quantityRuleOptions}
              allowClear={true}
              name="quantityRule"
              label="Regra de cálculo"
              placeholder="Selecione a regra"
              type="text"
            />
          </Col>}
        </Row>
      </BaseForm>

      <div style={{ width: '100%', margin: 'auto' }}>
        <Row>
          <SubcategoryPriceTable
            subcategory={initialValues}
            updatePrice={values => props.updatePrices(values)}
            deletePrice={values => props.deletePrices(values)}
          />
        </Row>
      </div>

    </Modal>
  );
};

const selector = formValueSelector('subcategory_form');

SubcategoryModal = reduxForm({
  form: 'subcategory_form',
  enableReinitialize: true,
  validate: FormValidations
})(SubcategoryModal);

SubcategoryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default withLocalize(SubcategoryModal);