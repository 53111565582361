import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal, Row, Col, Button } from 'antd';
import { arraySplice, formValueSelector, reduxForm } from 'redux-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ImageComponent from './ImageComponent';
import HandleImageUrl from '../../infra/services/images/HandleImageUrl';
import { connect } from 'react-redux';
import { OrderImage } from '../inputs/InputStyles';
import { OrderContainer } from '../../weddingday/questionOptions/OptionsStyles';

let ImageOrderModal = ({
  open,
  loading,
  handleSubmit,
  onSubmit,
  onClose,
  dispatch,
  images = []
}) => {

  const onDragEnd = (data) => {
    if (data.destination && data.source.index !== data.destination.index) {
      const [removed] = images.splice(data.source.index, 1);

      dispatch(
        arraySplice(
          'mng_order_images',
          'images',
          data.destination.index,
          0,
          removed
        )
      );
    }
  };

  const buildImageObject = blob => ({
    preview: URL.createObjectURL(blob),
    size: blob.size,
    type: blob.type,
    blob: blob
  });

  return (
    <Modal
      visible={open}
      title="Editar ordem"
      maskClosable={false}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      <OrderContainer>
        <DragDropContext onDragEnd={data => onDragEnd(data)}>
          <Droppable droppableId="images">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {images.map((image, index) => (
                  <Draggable key={image._id} draggableId={image._id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ImageComponent
                          color={image.color}
                          url={image.preview || HandleImageUrl(image.file)}
                          orderModal={true}
                        >
                          <OrderImage>{index + 1}</OrderImage>
                        </ImageComponent>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </OrderContainer>
    </Modal>
  );
}

const selector = formValueSelector('mng_order_images');

ImageOrderModal = reduxForm({
  form: 'mng_order_images'
})(ImageOrderModal);

const mapStateToProps = state => ({
  images: selector(state, 'images')
});

export default connect(mapStateToProps)(ImageOrderModal);
