import LoginPage from '../../pages/auth/LoginPage';
import ResetPasswordPage from '../../pages/auth/ResetPasswordPage';
import SetPasswordPage from '../../pages/auth/SetPasswordPage';
import RecoverAccountPage from '../../pages/auth/RecoverAccountPage';
import DashboardPage from '../../weddingday/dashboard/DashboardPage';
import EmployeeHome from '../../weddingday/employeeHome/EmployeeHome';
import HomeAttending from '../../weddingday/reservesAttending/ReservesAttending';
import AuthGooglePage from '../../pages/authGoogle/AuthGooglePage';
import SchedulesPage from '../../weddingday/schedules/SchedulesPage';

export default [
  {
    path: '/',
    component: DashboardPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
  },
  {
    path: '/login',
    component: LoginPage,
    isAuthenticated: false,
  },
  {
    path: '/recover-account',
    component: RecoverAccountPage,
    isAuthenticated: false
  },
  {
    path: '/recover-password',
    component: ResetPasswordPage,
    isAuthenticated: false
  },
  {
    path: '/set-password',
    component: SetPasswordPage,
    isAuthenticated: false
  },
  {
    path: '/home',
    component: EmployeeHome,
    isAuthenticated: true,
    employeeAllowed: true,
    chiefAllowed: true,
    humanResoursesAllowed: true,
    employeeAttendingAllowed: false,
    onlyEmployee: true,
  },
  {
    path: '/home-attending',
    component: SchedulesPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: true,
    onlyEmployeeAttending: true,
  },
  {
    path: '/auth-google',
    component: AuthGooglePage,
    isAuthenticated: false,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
  },
];
