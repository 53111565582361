import React, { Component } from 'react';
import { Breadcrumb, Modal } from 'antd';
import { Tabs } from 'antd';
import {
  SpinLoading,
  HeaderContainer,
  HeaderTitle,
  PageTitle,
  PageContainer,
  SectionTitle,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import { GetWedding, GetWeddingInfo } from '../../infra/requests/WeddingRequests';
import { CreatePayment, CreatePaymentsByWedding, GetPaymentsBywedding, UndoPaymentsByWedding } from '../../infra/requests/PaymentRequests';
import { GetOurDayByWedding } from '../../infra/requests/OurDayRequests';
import { CreateTeamBooking, DeleteTeamBooking, GetTeamBookingByWedding, PublishToRH, UpdateTeamBooking } from '../../infra/requests/TeamBookingRequests';
import { CreateWeddingTeam, DeleteWeddingTeam, GetWeddingTeamByWedding, GetWeddingTeamByWeddingAndEmployee, NotifyWeddingTeam, PublishWeddingTeam, SaveTeamInfo, UpdateWeddingTeam } from '../../infra/requests/WeddingTeamRequests';
import AddEditTeamBooking from './AddEditTeamBooking';
import Alert from "../../components/alert/Alert";
import AddEditEmployeesInCharge from './AddEditEmployeesInCharge';
import { GetSubcategoriesByType, GetSubcategoriesInCharge } from '../../infra/requests/SubcategoryRequests';
import WeddingInfo from '../employeeHome/WeddingInfo';
import AdminAddEditWeddingTeam from './AdminAddEditWeddingTeam';
import { getScheduler, getWeddingTeamAndEmployeesInCharge } from '../../infra/services/wedding/weddingUtils';
import { GetColorSystemByType } from '../../infra/requests/ColorSystemRequests';
import ModalTeamMng from './ModalTeamMng';
import ModalTeamNotification from './ModalTeamNotification';
import { getCurrentUser } from '../../infra/helpers/Helpers';

const { confirm } = Modal;

class AdminTeamMng extends Component {
  state = {
    loading: true,
    currentUser: null,
    currentEmployee: null,
    wedding: null,
    pax: 0,
    adults: 0,
    tables: 0,
    ourDay: [],
    scheduler: null,
    teamBooking: null,
    teamComplete: null,
    weddingTeam: null,
    employeesInCharge: {
      chief: null, coordinator: null, host: null
    },
    payments: null,

    colors: {
      colorSys1: null,
      colorSys2: null
    },
    subcategories: null,

    teamBookingAction: { addEdit: [], delete: [], deleteInCharge: [], editInCharge: [] },
    employeesInChargeAction: { addEdit: [], delete: [] },
    editWeddingTeam: [],
    deleteWeddingTeam: [],

    rowsTeam: [],
    rowsInCharge: [],

    openModal: false,
    openModalToBookingOrTeam: false,
    openNotificationModal: false,
    notificationValues: { team: [], allTeamNotified: false },
    openPublishTeamModal: false,
    publishTeamModalValues: { publish: true },
    openPublishRHModal: false,
    publishRHModalValues: { publishRH: true },
    openPaymentsModal: false,
    paymentModalValues: { createPayments: true }
  };

  componentDidMount = async () => {
    const localUser = getCurrentUser();
    this.getLocalColors();

    const result = await GetSubcategoriesInCharge();
    const subcategories = result.data;

    this.setState({
      currentUser: localUser,
      subcategories
    }, () => {
      this.getWedding();
    });
  }

  getLocalColors = async () => {
    const resColorSys1 = await GetColorSystemByType(4);
    const colorSys1 = resColorSys1.success && resColorSys1.data ? resColorSys1.data.color : '#f7b5d6';
    const resColorSys2 = await GetColorSystemByType(5);
    const colorSys2 = resColorSys2.success && resColorSys2.data ? resColorSys2.data.color : '#b6d7a9';
    this.setState({ colors: { colorSys1, colorSys2 } });
  }

  /**
   * @description Get wedding
   */
  getWedding = async () => {
    const { subcategories } = this.state;
    const weddingId = this.props.match.params.id;
    const result = await GetWeddingInfo(weddingId);
    // console.warn('Result', result);

    const weddingTeam = JSON.parse(JSON.stringify(result.data.weddingTeam));
    const team = getWeddingTeamAndEmployeesInCharge({ data: weddingTeam }, subcategories);
    // console.warn('Team', team);

    this.setState({
      wedding: result.data.wedding,
      pax: result?.data?.pax || 0,
      adults: result?.data?.adults || 0,
      tables: result?.data?.tables || 0,
      ourDay: result.data.ourDay,
      scheduler: getScheduler(result.data.ourDay),
      teamBooking: result.data.teamBooking,
      teamComplete: result.data.weddingTeam,
      weddingTeam: team.weddingTeam,
      employeesInCharge: team.employeesInCharge,
      payments: result.data.payments,
      loading: false
    });
  }

  save = async (toExit, publishToRH, publishToTeam, notificationAction, exitType) => {
    const { currentUser, wedding } = this.state;
    const { teamBookingAction, employeesInChargeAction, editWeddingTeam, deleteWeddingTeam } = this.state;
    let { rowsTeam, rowsInCharge } = this.state;
    const { history } = this.props;

    // console.warn('teamBookingAction', teamBookingAction);
    // console.warn('employeesInChargeAction', employeesInChargeAction);
    // console.warn('editWeddingTeam', editWeddingTeam);
    // console.warn('deleteWeddingTeam', deleteWeddingTeam);
    // console.warn('rowsTeam', rowsTeam);

    rowsInCharge = rowsInCharge.filter(f => !(f?.extraToBooking && (f?.weddingTeam?.employee === '' ||
      f?.weddingTeam?.employee === null || f?.weddingTeam?.employee === undefined ||
      f?.weddingTeam?.startTime === null)));
    let rowsAllTeam = [...rowsTeam, ...rowsInCharge].map(m => m.weddingTeam);

    //Validate selected employees in charge to employees selected on wedding team
    let errorEmployeesChargeAllRows = false, errorEmployeesChargeMessageAllRows = '';
    rowsInCharge.forEach(row => {
      const rowsTeamBySub = rowsAllTeam.filter(f => f?.employee && row?.weddingTeam?.employee && f.employee.id === row.weddingTeam.employee.id);

      if (row?.weddingTeam?.subcategory && (row.weddingTeam?.subcategory?.repeat && rowsTeamBySub?.length > 2 || !row?.weddingTeam?.subcategory?.repeat && rowsTeamBySub?.length > 1)) {
        errorEmployeesChargeAllRows = true;
        errorEmployeesChargeMessageAllRows = `O ${row.weddingTeam.subcategory.name} ${row.weddingTeam.employee.name} encontra-se selecionado na equipa!`;
      }
    });

    if (errorEmployeesChargeAllRows) {
      return Alert.new({
        type: "error",
        title: "Erro!",
        text: errorEmployeesChargeMessageAllRows
      });
    }

    //Employees In Charge of the wedding
    let errorEmployeesCharge = false, errorEmployeesChargeMessage = '';
    if (employeesInChargeAction?.addEdit?.length > 0) {
      employeesInChargeAction.addEdit.forEach(weddingTeam => {
        if (weddingTeam?.employee === '' || weddingTeam?.employee === null || weddingTeam?.employee === undefined ||
          weddingTeam?.startTime === null) {
          errorEmployeesCharge = true;
          errorEmployeesChargeMessage = 'Faltam dados nos funcionários responsáveis!';
        }

        // const editTeamBySub = editWeddingTeam.filter(f => f.employee && weddingTeam.employee && f.employee.id === weddingTeam.employee.id);
        const rowsTeamBySub = rowsAllTeam.filter(f => f?.employee && weddingTeam?.employee && f.employee.id === weddingTeam.employee.id);
        if (weddingTeam?.subcategory && (weddingTeam?.subcategory?.repeat && rowsTeamBySub?.length > 2 || !weddingTeam?.subcategory?.repeat && rowsTeamBySub?.length > 1)) {
          errorEmployeesCharge = true;
          errorEmployeesChargeMessage = `O ${weddingTeam.subcategory.name} ${weddingTeam.employee.name} encontra-se selecionado na equipa!`;
        }
      });

      if (errorEmployeesCharge) {
        return Alert.new({
          type: "error",
          title: "Erro!",
          text: errorEmployeesChargeMessage
        });
      }

      employeesInChargeAction.addEdit.forEach(weddingTeam => {
        const index = editWeddingTeam.findIndex(f => f.id === weddingTeam.id);
        if (index === -1) editWeddingTeam.push(weddingTeam);
        else editWeddingTeam[index] = weddingTeam;
      });
    }

    if (employeesInChargeAction.delete.length > 0) {
      employeesInChargeAction.delete.forEach(weddingTeam => {
        const index = deleteWeddingTeam.findIndex(f => f.id === weddingTeam.id);
        if (index === -1) deleteWeddingTeam.push(weddingTeam);
        else deleteWeddingTeam[index] = weddingTeam;
      });
    }

    /** Team Booking */
    let errorTeamBooking = false;
    if (currentUser.admin && teamBookingAction.addEdit.length > 0) {
      teamBookingAction.addEdit.forEach(teamBooking => {
        if (teamBooking?.subcategory === undefined || teamBooking?.subcategory === null ||
          teamBooking?.startTime === null || teamBooking?.quantity === null ||
          teamBooking?.quantity === '' || teamBooking?.quantity <= 0) {
          errorTeamBooking = true;
          return;
        }
      });

      if (errorTeamBooking) {
        return Alert.new({
          type: "error",
          title: "Erro!",
          text: 'Faltam dados na encomenda da equipa!'
        });
      }
    }

    //Team Booking In Charge of the wedding
    if (teamBookingAction.deleteInCharge.length > 0) {
      teamBookingAction.deleteInCharge.forEach(booking => {
        const index = teamBookingAction.delete.findIndex(f => f.id === booking.id);
        if (index === -1) teamBookingAction.delete.push(booking);
        else teamBookingAction.delete[index] = booking;

        const rowAllTeam = rowsAllTeam.find(f => f.subcategory.id === booking.subcategory.id);
        if (rowAllTeam) rowsAllTeam = rowsAllTeam.filter(f => f.id !== rowAllTeam.id);
      });
    }

    if (teamBookingAction.editInCharge.length > 0) {
      teamBookingAction.editInCharge.forEach(booking => {
        const index = teamBookingAction.addEdit.findIndex(f => f.id === booking.id);
        if (index === -1) teamBookingAction.addEdit.push(booking);
        else teamBookingAction.addEdit[index] = booking;
      });
    }

    /** Wedding Team */
    let errorWeddingTeam = false;
    if (editWeddingTeam.length > 0) {
      editWeddingTeam.forEach(weddingTeam => {
        if (weddingTeam?.employee === null || weddingTeam?.employee === '' || weddingTeam?.employee === undefined ||
          weddingTeam?.bonus === null || weddingTeam?.bonus === '' || weddingTeam?.startTime === null) {
          errorWeddingTeam = true;
        }
      });

      if (errorWeddingTeam) {
        return Alert.new({
          type: "error",
          title: "Erro!",
          text: 'Faltam dados na equipa do casamento!'
        });
      }
    }

    const data = {
      currentUser: currentUser,
      wedding: wedding,
      teamBookingAction: { addEdit: teamBookingAction.addEdit, delete: teamBookingAction.delete },
      weddingTeamAction: { addEdit: editWeddingTeam, delete: deleteWeddingTeam },
      allRowsTeam: rowsAllTeam
    };

    const result = await SaveTeamInfo(data);
    //console.warn('Result', result);

    if (toExit) {
      if (result.success) {
        this.setState({
          teamBookingAction: { addEdit: [], delete: [], deleteInCharge: [], editInCharge: [] },
          employeesInChargeAction: { addEdit: [], delete: [] },
          editWeddingTeam: [],
          deleteWeddingTeam: [],
        }, () => {
          Alert.new({
            type: "success",
            title: "Sucesso!",
            text: 'Alterações gravadas com sucesso!'
          });
          if (exitType === 0) {
            return this.handleOnCancel();
          } else if (exitType === 1) {
            return this.handleNavigateToTeam();
          } else {
            return this.handleNavigateToBooking();
          }
        });
      } else {
        Alert.new({
          type: "error",
          title: "Erro!",
          text: 'Erro ao gravar!'
        });
      }
    } else {
      this.setState({
        teamBookingAction: { addEdit: [], delete: [], deleteInCharge: [], editInCharge: [] },
        employeesInChargeAction: { addEdit: [], delete: [] },
        editWeddingTeam: [],
        deleteWeddingTeam: [],
      }, () => {
        if (notificationAction) this.setNotificationTeam();
        else if (publishToRH) this.publishToEmployee(true);
        else if (publishToTeam) this.showPublishModal();
        else this.showPaymentModal();
      });
    }
  }

  /**
   * @description Publish team booking for employees
   */
  publishToEmployee = async (toExit) => {
    let { teamBooking, wedding } = this.state;

    const resBooking = await GetTeamBookingByWedding(wedding.id);
    teamBooking = resBooking.success && resBooking.data.length > 0 ? resBooking.data : [];
    // console.warn('teamBooking', teamBooking);

    if (teamBooking && teamBooking.length > 0) {
      const result = await PublishToRH(wedding);
      if (!result.success) console.error(result.message);
      else {
        if (toExit) {
          this.setState({ teamBooking }, () => {
            Alert.new({
              type: "success",
              title: "Sucesso!",
              text: 'Encomenda publicada para os RH!'
            });
            this.handleOnCancel();
          });
        } else this.publishTeam(false);
      }
    } else {
      Alert.new({
        type: "error",
        title: "Erro!",
        text: 'Encomenda vazia!'
      });
    }
  }

  disabledCreatePayments = () => {
    const { payments } = this.state;
    let { teamBooking, weddingTeam } = this.state;
    // return (payments.length > 0
    //   || (payments.length === 0 && (teamBooking && teamBooking?.length > 0 && teamBooking?.filter(t => t?.showEmployee).length === 0) && (weddingTeam && weddingTeam?.length > 0 && weddingTeam?.filter(t => t?.published).length === 0)))
    //   ? true
    //   : false;
    return (teamBooking && teamBooking?.length > 0 && teamBooking?.filter(t => t?.showEmployee).length === 0) && (weddingTeam && weddingTeam?.length > 0 && weddingTeam?.filter(t => t?.published).length === 0)
      ? true
      : false;
  }

  showPaymentModal = async () => {
    const { wedding } = this.state;
    let { teamBooking, weddingTeam } = this.state;

    const resBooking = await GetTeamBookingByWedding(wedding.id);
    teamBooking = resBooking.success && resBooking.data.length > 0 ? resBooking.data : [];
    const showEmployee = teamBooking && teamBooking.length > 0 && teamBooking.filter(t => t.showEmployee).length > 0 ? true : false;

    const resTeam = await GetWeddingTeamByWedding(wedding.id);
    weddingTeam = resTeam.success && resTeam.data.length > 0 ? resTeam.data : [];
    const publishedTeam = weddingTeam && weddingTeam.length > 0 && weddingTeam.filter(t => t.published).length > 0 ? true : false;

    if ((teamBooking && teamBooking.length > 0 && showEmployee) && (weddingTeam && weddingTeam.length > 0 && publishedTeam)) {

      weddingTeam = weddingTeam.filter(f => f.presence);

      if (weddingTeam.length === 0) {
        return Alert.new({
          type: "error",
          title: "Erro!",
          text: 'Não existem pagamentos para gerar!'
        });
      }

      this.setState({ openPaymentsModal: true });

    } else if (teamBooking && teamBooking.length > 0 && !showEmployee) {
      return Alert.new({
        type: "error",
        title: "Erro!",
        text: 'A encomenda ainda não foi publicada para os RH'
      });
    } else if (weddingTeam && weddingTeam.length > 0 && !publishedTeam) {
      return Alert.new({
        type: "error",
        title: "Erro!",
        text: 'A equipa ainda não foi publicada!'
      });
    }
  }

  gerarPagamentos = async () => {
    const weddingId = this.props.match.params.id;

    const result = await CreatePaymentsByWedding(weddingId);
    if (result?.success) {
      Alert.new({
        type: "success",
        title: "Sucesso!",
        text: 'Pagamentos gerados com sucesso!'
      });
      window.location.reload();
      // this.handleOnCancel();
    }
  }

  disabledUndoPayments = () => {
    const { payments } = this.state;
    return !payments || payments?.length == 0
      || payments?.filter(f => f?.status == 1)?.length == 0
      ? true
      : false;
  }

  confirmUndoPayments = async () => {
    const { payments } = this.state;

    if (!payments || payments?.filter(f => f?.status == 1)?.length == 0) {
      return;
    }

    confirm({
      title: 'Tem a certeza que pretende reverter os pagamentos por realizar?',
      content: (<div>
        <p>Todos os pagamentos que se encontrem por realizar deste casamento serão eliminados.</p>
        <p>Estes pagamentos não poderão ser recuperados.</p>
      </div>
      ),
      okType: 'primary',
      okText: 'Reverter',
      cancelText: 'Cancelar',
      onOk: () => this.undoPayments(),
      onCancel: () => null,
    });
  }

  undoPayments = async () => {
    const weddingId = this.props.match.params.id;

    const result = await UndoPaymentsByWedding(weddingId)
    if (result?.success) {
      Alert.new({
        type: "success",
        title: "Sucesso!",
        text: 'Pagamentos por realizar revertidos!'
      });
      window.location.reload();
      // this.handleOnCancel();
    }
  }

  /**
   * @description Notification Team List
   */
  setNotificationTeam = async () => {
    let { wedding } = this.state;

    const resTeam = await GetWeddingTeamByWedding(wedding.id);
    let teamCompleteBD = resTeam.success && resTeam.data.length > 0 ? resTeam.data : [];
    let teamComplete = resTeam.success && resTeam.data.length > 0 ? resTeam.data : [];
    if (teamComplete.length > 0) teamComplete = teamComplete.filter(f => !f.confirmed);
    // this.setState({ teamComplete });

    const teamList = [];
    for (let index = 0; index < teamComplete.length; index++) {
      const indexList = teamList.findIndex(f => f.employee === teamComplete[index].employee);
      if (indexList === -1) {
        teamList.push({
          employee: teamComplete[index].employee,
          weddingTeam: [teamComplete[index]],
          notify: false
        });
      } else {
        teamList[indexList].weddingTeam.push(teamComplete[index]);
      }
    }

    this.setState({
      notificationValues: {
        team: teamList,
        allTeamNotified: teamCompleteBD.length > 0 ? teamCompleteBD.filter(f => f.confirmed).length === teamCompleteBD.length ? true : false : false
      },
      openNotificationModal: true,
    });
  }

  notifyTeam = async (event: any) => {
    const { wedding } = this.state;
    const team = event.filter(f => f?.notify);

    const result = await NotifyWeddingTeam({ wedding, employees: team });
    if (!result.success) console.error(result.message);
    else {
      Alert.new({
        type: "success",
        title: "Sucesso!",
        text: 'Notificação enviada!'
      });
      this.handleOnCancel();
    }
  }

  showPublishModal = async () => {
    let { wedding } = this.state;

    const resBooking = await GetTeamBookingByWedding(wedding.id);
    const teamBooking = resBooking.success && resBooking.data.length > 0 ? resBooking.data : [];

    const bookingShowEmployee = teamBooking.length > 0 && teamBooking.filter(b => b.showEmployee).length > 0 ? true : false;
    if (!bookingShowEmployee) {
      this.setState({
        publishRHModalValues: { teamBooking, publishRH: true },
        openPublishRHModal: true
      });
    } else {
      let totalRows = 0;
      for (let index = 0; index < teamBooking.length; index++) {
        const booking = teamBooking[index];
        const subcategory = booking.subcategory;
        totalRows = totalRows + booking.quantity;
      }

      const resTeam = await GetWeddingTeamByWedding(wedding.id);
      const teamComplete = resTeam.success && resTeam.data.length > 0 ? resTeam.data : [];

      // console.warn('teamBooking', teamBooking);
      // console.warn('teamComplete', teamComplete);
      // console.warn('totalRows', totalRows);

      if (teamComplete.length < totalRows) {
        return Alert.new({
          type: "error",
          title: "Erro!",
          text: 'Falta atribuir funcionários!'
        });
      }

      this.setState({
        publishTeamModalValues: { teamComplete, publish: true },
        openPublishTeamModal: true
      });
    }
  }

  /**
   * @description Publish wedding team
   */
  publishTeam = async (remove: boolean) => {
    let { wedding, teamComplete, teamBooking } = this.state;

    const resTeam = await GetWeddingTeamByWedding(wedding.id);
    teamComplete = resTeam.success && resTeam.data.length > 0 ? resTeam.data : [];

    if (teamComplete && teamComplete.length > 0) {
      if (remove) {
        for (let index = 0; index < teamComplete.length; index++) {
          teamComplete[index].published = false;
          const result = await UpdateWeddingTeam(teamComplete[index]);
          if (!result.success) console.error(result.message);
        }
        // alert('Done');
      } else {
        const result = await PublishWeddingTeam(wedding);
        if (!result.success) console.error(result.message);
        else {
          Alert.new({
            type: "success",
            title: "Sucesso!",
            text: 'Equipa do casamento publicada!'
          });
          this.handleOnCancel();
        }
      }

    }
  }

  handleOnCancel = () => {
    const { history, location } = this.props;
    const fromWeddingPayments = new URLSearchParams(location.search).get('page') === 'WPAY' ? true : false;

    if (fromWeddingPayments) return history.push('/weddings-payments');
    else return history.push('/weddings');
  }

  handleNavigateToTeam = () => {
    const { history, location, match } = this.props;
    const fromWeddingPayments = new URLSearchParams(location.search).get('page') === 'WPAY' ? true : false;

    const url = fromWeddingPayments
      ? `/wedding-team/${match.params.id}?page=WPAY`
      : `/wedding-team/${match.params.id}`;
    return history.push(url);
  }

  handleNavigateToBooking = () => {
    const { history, location, match } = this.props;
    const fromWeddingPayments = new URLSearchParams(location.search).get('page') === 'WPAY' ? true : false;

    const url = fromWeddingPayments
      ? `/team-booking/${match.params.id}?page=WPAY`
      : `/team-booking/${match.params.id}`;
    return history.push(url);
  }

  handleNavigate = (urlBooking) => {
    const { history, location, match } = this.props;
    const fromWeddingPayments = new URLSearchParams(location.search).get('page') === 'WPAY' ? true : false;

    if (urlBooking) {
      const url = fromWeddingPayments
        ? `/wedding-team/${match.params.id}?page=WPAY`
        : `/wedding-team/${match.params.id}`
      return history.push(url);
    } else {
      const url = fromWeddingPayments
        ? `/team-booking/${match.params.id}?page=WPAY`
        : `/team-booking/${match.params.id}`;
      return history.push(url);
    }
  }

  render() {
    const { currentUser, currentEmployee, colors, subcategories } = this.state;
    let { wedding, pax, adults, tables, ourDay, scheduler, teamBooking, teamComplete, weddingTeam, employeesInCharge, payments } = this.state;
    const { openModal, openModalToBookingOrTeam, teamBookingAction, editWeddingTeam, deleteWeddingTeam, employeesInChargeAction } = this.state;
    const { openNotificationModal, notificationValues, openPublishRHModal, publishRHModalValues, openPublishTeamModal, publishTeamModalValues, openPaymentsModal, paymentModalValues } = this.state;
    const { loading } = this.state;
    const { TabPane } = Tabs;
    const bgColor = wedding?.wedding_place === 1 ? colors.colorSys1 : wedding?.wedding_place === 2 ? colors.colorSys2 : '';
    const urlBooking = this.props.match.path.includes('team-booking') ? true : false;
    const urlTeam = this.props.match.path.includes('wedding-team') ? true : false;

    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={3}>
            <PageTitle>Casamentos</PageTitle>
            <Breadcrumb>
              <Breadcrumb.Item>
                <a href="/weddings">Casamentos</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {teamBooking ? 'Encomenda' : 'Equipa do Casamento'}
              </Breadcrumb.Item>
            </Breadcrumb>
          </HeaderTitle>

          <HeaderButtonsContainer buttons={3}>
            <BaseButton
              type={'primary'}
              icon={'save'}
              text={'Gravar'}
              onClick={() => {
                this.setState({ openModal: true });
                //this.save(true, false);
              }}
            // disabled={this.state.payments && this.state.payments.length > 0}
            />
            <BaseButton
              type={'default'}
              icon={'close'}
              text={'Cancelar'}
              onClick={() => this.handleOnCancel()}
            />
            <BaseButton
              type={'primary'}
              icon={urlBooking ? 'team' : 'inbox'}
              text={urlBooking ? 'Equipa' : 'Encomenda'}
              onClick={() => {
                const { editWeddingTeam, deleteWeddingTeam, teamBookingAction } = this.state;
                if (teamBookingAction.addEdit.length > 0 || teamBookingAction.delete.length > 0 || teamBookingAction.deleteInCharge.length > 0 || employeesInChargeAction.addEdit.length > 0 || employeesInChargeAction.delete.length > 0 || editWeddingTeam.length > 0 || deleteWeddingTeam.length > 0) {
                  // this.save(true, false, false, false, urlBooking ? 1 : 2);
                  this.setState({ openModalToBookingOrTeam: true });
                } else {
                  this.handleNavigate(urlBooking);
                }
              }}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={3}>
          <div className='wedding-team-header'>
            <SectionTitle subsection style={{ width: 'fit-content', marginTop: '0', backgroundColor: bgColor, borderRadius: 5, padding: '5px 10px', color: '#fff' }}>Casamento</SectionTitle>
            <div className='wedding-team-btns '>
              {urlBooking ? <BaseButton
                style={{ width: '160px' }}
                type={'primary'}
                block
                text={teamBooking && teamBooking.length > 0 && teamBooking.filter(t => t.showEmployee).length > 0 ? 'Publicado para RH' : 'Publicar para RH'}
                onClick={() => {
                  const { editWeddingTeam, deleteWeddingTeam, teamBookingAction } = this.state;
                  if (teamBookingAction.addEdit.length > 0 || teamBookingAction.delete.length > 0 || teamBookingAction.deleteInCharge.length > 0 || employeesInChargeAction.addEdit.length > 0 || employeesInChargeAction.delete.length > 0 || editWeddingTeam.length > 0 || deleteWeddingTeam.length > 0) {
                    this.save(false, true, false, false, 0);
                  } else this.publishToEmployee(true);
                }}
                disabled={teamBooking && teamBooking.length > 0 && teamBooking.filter(t => t.showEmployee).length > 0}
              /> : null}

              {urlTeam && (teamComplete.length === 0 || teamComplete.length > 0 && teamComplete.filter(t => t.weddingTeam.published).length > 0) ? <BaseButton
                type={'default'}
                block
                text={'Notificar Equipa'}
                onClick={() => {
                  const { editWeddingTeam, deleteWeddingTeam, teamBookingAction } = this.state;
                  if (teamBookingAction.addEdit.length > 0 || teamBookingAction.delete.length > 0 || teamBookingAction.deleteInCharge.length > 0 || employeesInChargeAction.addEdit.length > 0 || employeesInChargeAction.delete.length > 0 || editWeddingTeam.length > 0 || deleteWeddingTeam.length > 0) {
                    this.save(false, false, false, true, 0);
                  } else this.setNotificationTeam();
                }}
                disabled={teamComplete.length === 0 || payments.length > 0}
              /> : null}

              {urlTeam && <BaseButton
                type={'primary'}
                block
                text={(teamComplete.length === 0 || teamComplete.length > 0 && teamComplete.filter(t => t.weddingTeam.published).length === 0) ? 'Publicar a Equipa' : 'Publicada a Equipa'}
                onClick={() => {
                  const { editWeddingTeam, deleteWeddingTeam, teamBookingAction } = this.state;
                  if (teamBookingAction.addEdit.length > 0 || teamBookingAction.delete.length > 0 || teamBookingAction.deleteInCharge.length > 0 || employeesInChargeAction.addEdit.length > 0 || employeesInChargeAction.delete.length > 0 || editWeddingTeam.length > 0 || deleteWeddingTeam.length > 0) {
                    this.save(false, false, true, false, 0);
                  } else this.showPublishModal(); //this.publishTeam();
                  // this.publishTeam(true);
                }}
                disabled={teamComplete.length > 0 && teamComplete.filter(t => t.weddingTeam.published).length > 0 /*|| weddingTeam.length === 0 && weddingTeamAction.addEdit.length !== totalRowsBooking*/}
              />}

              {urlTeam && <BaseButton
                style={{ width: '160px' }}
                type={'primary'}
                block
                text={'Gerar Pagamentos'}
                onClick={() => {
                  const { editWeddingTeam, deleteWeddingTeam, teamBookingAction } = this.state;
                  if (teamBookingAction.addEdit.length > 0 || teamBookingAction.delete.length > 0 || teamBookingAction.deleteInCharge.length > 0 || employeesInChargeAction.addEdit.length > 0 || employeesInChargeAction.delete.length > 0 || editWeddingTeam.length > 0 || deleteWeddingTeam.length > 0) {
                    this.save(false, false, false, false, 0);
                  } else this.showPaymentModal();  // this.gerarPagamentos();
                }}
                disabled={this.disabledCreatePayments()}
              />}

              {urlTeam && !this.disabledUndoPayments() && <BaseButton
                style={{ width: '160px' }}
                type={'primary'}
                block
                text={'Reverter Pagamentos'}
                onClick={() => {
                  const { editWeddingTeam, deleteWeddingTeam, teamBookingAction } = this.state;
                  if (teamBookingAction.addEdit.length > 0 || teamBookingAction.delete.length > 0 || teamBookingAction.deleteInCharge.length > 0 || employeesInChargeAction.addEdit.length > 0 || employeesInChargeAction.delete.length > 0 || editWeddingTeam.length > 0 || deleteWeddingTeam.length > 0) {
                    this.save(false, false, false, false, 0);
                  } else this.confirmUndoPayments();
                }}
                disabled={this.disabledUndoPayments()}
              />}
            </div>
          </div>

          <WeddingInfo
            showHomeHeader={false}
            showTable={false}
            currentEmployee={currentEmployee}
            selectedWedding={{
              wedding: wedding,
              ourDay: ourDay,
              scheduler: scheduler
            }}
          />

          {/* Admin Team Management */}
          {urlTeam ? <AddEditEmployeesInCharge
            currentUser={currentUser}
            selectedWedding={wedding}
            ourDay={ourDay}
            scheduler={scheduler}
            pax={pax}
            // adults={adults}
            teamBooking={teamBooking}
            teamComplete={teamComplete}
            subcategories={subcategories}
            payments={payments}
            published={teamBooking && teamBooking.length > 0 && teamBooking.filter(t => t.showEmployee).length > 0 ? true : false}
            updateTeam={event => this.setState({ employeesInChargeAction: event })}
            updateRowsBooking={event => { }}
            updateRowsInCharge={event => {
              this.setState({ rowsInCharge: event });
            }}
            deleteBooking={event => {
              const { teamBookingAction } = this.state;
              teamBookingAction.deleteInCharge = event;
              this.setState({ teamBookingAction });
            }}
            updateBooking={event => {
              const { teamBookingAction } = this.state;
              teamBookingAction.editInCharge = event;
              this.setState({ teamBookingAction });
            }}
          /> : null}

          {urlBooking ? <AddEditTeamBooking
            currentUser={currentUser}
            selectedWedding={wedding}
            pax={pax}
            adults={adults}
            tables={tables}
            ourDay={ourDay}
            scheduler={scheduler}
            teamBooking={JSON.parse(JSON.stringify(teamBooking))}
            teamComplete={JSON.parse(JSON.stringify(teamComplete))}
            payments={payments}
            showEmployee={teamBooking && teamBooking.length > 0 && teamBooking.filter(t => t.showEmployee).length > 0 ? true : false}
            addEditBooking={event => {
              const { teamBookingAction } = this.state;
              teamBookingAction.addEdit = event;
              this.setState({ teamBookingAction });
            }}
            deleteBooking={event => {
              const { teamBookingAction } = this.state;
              teamBookingAction.delete = event;
              this.setState({ teamBookingAction });
            }}
          /> : null}

          {urlTeam ? <AdminAddEditWeddingTeam
            currentUser={currentUser}
            selectedWedding={wedding}
            ourDay={ourDay}
            scheduler={scheduler}
            pax={pax}
            teamBooking={JSON.parse(JSON.stringify(teamBooking))}
            teamComplete={JSON.parse(JSON.stringify(teamComplete))}
            weddingTeam={JSON.parse(JSON.stringify(weddingTeam))}
            payments={payments}
            updateWeddingTeam={event => this.setState({ editWeddingTeam: event })}
            deleteWeddingTeam={event => this.setState({ deleteWeddingTeam: event })}
            updateRowsBooking={event => {
              // let { totalRowsBooking } = this.state;
              // totalRowsBooking = totalRowsBooking + event;
              // this.setState({ totalRowsBooking })
            }}
            updateRowsTeam={event => {
              this.setState({ rowsTeam: event });
            }}
            deleteBooking={event => {
              const { teamBookingAction } = this.state;
              teamBookingAction.delete = event;
              this.setState({ teamBookingAction });
            }}
            updateBooking={event => {
              const { teamBookingAction } = this.state;
              teamBookingAction.addEdit = event;
              this.setState({ teamBookingAction });
            }}
          /> : null}

        </PageContainer>

        <ModalTeamMng
          isOpen={openModal}
          initialValues={{
            updatedBooking: teamBookingAction.addEdit.length > 0 || teamBookingAction.delete.length > 0 || teamBookingAction.deleteInCharge.length > 0 ? true : false,
            updatedTeam: editWeddingTeam.length > 0 || deleteWeddingTeam.length > 0 ? true : false,
            weddingTeam: teamComplete
          }}
          onSubmit={() => this.setState({ openModal: false }, () => this.save(true, false, false, false, 0))}
          onClose={() => this.setState({ openModal: false })}>
        </ModalTeamMng>

        <ModalTeamMng
          isOpen={openModalToBookingOrTeam}
          initialValues={{
            updatedBooking: teamBookingAction.addEdit.length > 0 || teamBookingAction.delete.length > 0 || teamBookingAction.deleteInCharge.length > 0 ? true : false,
            updatedTeam: editWeddingTeam.length > 0 || deleteWeddingTeam.length > 0 ? true : false,
            weddingTeam: teamComplete
          }}
          onSubmit={() => this.setState({ openModalToBookingOrTeam: false }, () => this.save(true, false, false, false, urlBooking ? 1 : 2))}
          onClose={() => this.setState({ openModalToBookingOrTeam: false })}>
        </ModalTeamMng>

        <ModalTeamMng
          isOpen={openPublishRHModal}
          initialValues={publishRHModalValues}
          onSubmit={() => this.setState({ openPublishRHModal: false }, () => {
            this.publishToEmployee(false);
          })}
          onClose={() => this.setState({ openPublishRHModal: false })}>
        </ModalTeamMng>

        <ModalTeamMng
          isOpen={openPublishTeamModal}
          initialValues={publishTeamModalValues}
          onSubmit={() => this.setState({ openPublishTeamModal: false }, () => {
            this.publishTeam(false);
          })}
          onClose={() => this.setState({ openPublishTeamModal: false })}>
        </ModalTeamMng>

        <ModalTeamMng
          isOpen={openPaymentsModal}
          initialValues={paymentModalValues}
          onSubmit={() => this.setState({ openPaymentsModal: false }, () => {
            this.gerarPagamentos();
          })}
          onClose={() => this.setState({ openPaymentsModal: false })}>
        </ModalTeamMng>

        <ModalTeamNotification
          isOpen={openNotificationModal}
          initialValues={notificationValues}
          onSubmit={event => this.setState({ openNotificationModal: false }, () => this.notifyTeam(event))}
          onClose={() => this.setState({ openNotificationModal: false })}>
        </ModalTeamNotification>
      </React.Fragment>
    );
  }
}

export default AdminTeamMng;
