import styled from 'styled-components';

export const ChapterBox = styled.div`
  display: inline-block;
  width: 100%;
  padding: 30px;
  background: #faf9f9;
  position: relative;
`;

export const Counter = styled.div`
  position: absolute;
  background: white;
  padding: 5px;
  top: -5px;
  right: -5px;
  box-shadow: 1px 1px 3px #ccc;
`;

export const Name = styled.div`
  display: block;
  font-size: 18px;
  line-height: 22px;
  display: -webkit-box;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 44px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
`;

export const Description = styled.div`
  padding-top: 10px;
  border-top: 1px solid #ece8e2;
  font-size: 14px;
  display: -webkit-box;
  line-height: 20px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 90px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  width: 100%;
`;

export const EmptyBox = styled.div`
  cursor: pointer;
  display: flex;
  width: 100%;
  background: #faf9f9;
  position: relative;
  height: 194px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const HalfButton = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: ${p => p.border ? '1px solid #ece8e2' : '0px'};

  font-size: 18px;
  i {
    margin-top: 20px;
    font-size: 26px;
  }
`;

export const QuestionsButton = styled.div`
  cursor: pointer;
  position: absolute;
  background: white;
  padding: 5px;
  bottom: -5px;
  right: -5px;
  box-shadow: 1px 1px 3px #ccc;
`;

export const OptionButton = styled.div`
  cursor: pointer;
  position: absolute;
  background: white;
  padding: 5px;
  bottom: -5px;
  left: ${({number}) => -5 + number * 30}px;
  box-shadow: 1px 1px 3px #ccc;
`;

export const ChapterOrderName = styled.div`
  background-color: #faf9f9;
  padding: 5px 10px;
  margin-bottom: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
`;

export const OrderContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;