import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, initialize } from "redux-form";
import { withLocalize } from "react-localize-redux";
import {
  GetConfig,
  UpdateConfigType
} from "../../infra/requests/ConfigsRequests";
import TextInput from "../../components/inputs/TextInput";
import ImageInput from "../../components/inputs/ImageInput";
import BaseButton from "../../components/buttons/BaseButton";
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading
} from "../../styles/BasicStyles";
import FormValidator from "../../infra/services/validations/FormValidator";
import { TransformToFormData } from "../../infra/services/formdata/TransformToFormData";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import TitleInput from "../../components/inputs/TitleInput";

const validations = FormValidator.make({
  email: "required|email",
  mobile_phone: "required",
  address_line_1: "required",
  address_line_2: "required",
  venueLinkLocation: "required",
  facebook_page: "required",
  instagram_page: "required",
  pinterest_page: "required",
  homepage_left_image: "required",
  homepage_right_image: "required",
  booking_image: "required",
  contact_image: "required",
  iban: "required",
  swift: "required",
  payment_company: "required"
});

class ManageConfigsPage extends Component {
  state = {
    isNew: false,
    loading: true,
    title: '',
  };

  componentDidMount = async () => {
    try {
      const {
        dispatch,
        match: { params }
      } = this.props;

      const configurations = await GetConfig(params.id);
      let title = '';
      if (configurations?.success && configurations?.data) {
        title = configurations?.data?.local == 2
          ? 'Quinta Lago dos Cisnes'
          : configurations?.data?.local == 1
            ? 'Solar da Levada'
            : '';
      }

      dispatch(
        initialize("manage_config_form", {
          ...configurations.data
        })
      );
      this.setState({
        loading: false,
        title
      });
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false
      });
    }
  };

  onSubmit = async values => {
    try {
      this.setState({ loading: true });
      const {
        history,
        match: { params }
      } = this.props;
      const formDataValues = TransformToFormData(values);
      await UpdateConfigType(params.id, formDataValues);
      return this.handleOnCancel();
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history, location } = this.props;
    const fromAttendingSettings = new URLSearchParams(location.search).get('page') === 'S6' ? true : false;
    if (fromAttendingSettings) {
      return history.push('/settings/6');
    } else {
      return history.push("/management");
    }
  };

  render() {
    const { handleSubmit } = this.props;
    const { loading } = this.state;
    const { title } = this.state;

    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Configurações {title}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={TextInput}
              name="email"
              type="text"
              label={"Email"}
              placeholder={"Insira o e-mail"}
            />
            <Field
              component={TextInput}
              name="mobile_phone"
              type="text"
              label={"Telemóvel"}
              placeholder={"Insira o nº de telemóvel"}
            />
            <Field
              component={TextInput}
              name="address_line_1"
              type="text"
              label={"Morada - linha 1"}
              placeholder={"Insira a morada"}
            />
            <Field
              component={TextInput}
              name="address_line_2"
              type="text"
              label={"Morada - linha 2"}
              placeholder={"Insira a morada"}
            />
            <Field
              component={TextInput}
              name="venueLinkLocation"
              type="text"
              label={"Localização do espaço (link)"}
              placeholder={"Insira o link da localização do espaço"}
            />
            <Field
              component={TitleInput}
              name="venueDirections"
              type="text"
              label={"Direções para o espaço"}
              placeholder={"Descreva as direções até ao espaço"}
            />
            <Field
              component={TitleInput}
              name="visitMeetingPointDirections"
              type="text"
              label={"Ponto de encontro para visitas (direções)"}
              placeholder={"Descreva as direções para visitas"}
            />
            <Field
              component={TitleInput}
              name="organizationMeetingPointDirections"
              type="text"
              label={"Ponto de encontro para reuniões (direções)"}
              placeholder={"Descreva as direções para reuniões"}
            />
            <Field
              component={TextInput}
              name="facebook_page"
              type="text"
              label={"Facebook"}
              placeholder={"Insira o link da página do Facebook"}
            />
            <Field
              component={TextInput}
              name="instagram_page"
              type="text"
              label={"Instagram"}
              placeholder={"Insira o link da página do Instagram"}
            />
            <Field
              component={TextInput}
              name="pinterest_page"
              type="text"
              label={"Pinterest"}
              placeholder={"Insira o link da página do Pinterest"}
            />
            <Field
              component={TextInput}
              name="payment_company"
              type="text"
              label={"Nome da Empresa (para pagamentos)"}
              placeholder={"Insira o nome da empresa"}
            />
            <Field
              component={TextInput}
              name="iban"
              type="text"
              label={"IBAN"}
              placeholder={"Insira o IBAN"}
            />
            <Field
              component={TextInput}
              name="swift"
              type="text"
              label={"BIC/SWIFT"}
              placeholder={"Insira o bic/swift"}
            />
            <Field
              component={ImageInput}
              name="homepage_left_image"
              label={"Homepage (imagem à esquerda)"}
              width="100%"
              ratio={0.6}
            />
            <Field
              component={ImageInput}
              name="homepage_right_image"
              label={"Homepage (imagem à direita)"}
              width="100%"
              ratio={0.6}
            />
            <Field
              component={ImageInput}
              name="booking_image"
              label={"Imagem da página Bookings"}
              width="100%"
              ratio={1}
            />
            <Field
              component={ImageInput}
              name="contact_image"
              label={"Imagem da página de Contactos"}
              width="100%"
              ratio={1}
            />
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageConfigsPage = reduxForm({
  form: "manage_config_form",
  validate: validations
})(ManageConfigsPage);

const mapStateToProps = state => ({
  user: state.user
});

export default withLocalize(connect(mapStateToProps)(ManageConfigsPage));
