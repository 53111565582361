import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetMetatag,
  UpdateMetatag
} from '../../infra/requests/BaseRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  HeaderButtonsContainer,
  PageSubtite
} from '../../styles/BasicStyles';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import TextInput from '../../components/inputs/TextInput';
import BaseButton from "../../components/buttons/BaseButton";
const TabPane = Tabs.TabPane;

class ManageMetatagsPage extends Component {
  state = {
    loading: true,
    ready: false,
    info: {},
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
    } = this.props;

    if (params.id !== 'add') {
      const result = await GetMetatag(params.id);
      dispatch(initialize('manage_metatags_form', { ...result.data }));
      this.setState({
        ready: true,
        info: result || {},
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history,
      } = this.props;

      await UpdateMetatag(params.id, values);
      return history.push('/management/metatags');
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    try {
      const { history } = this.props;
      return history.push('/management/metatags');
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit, activeLanguage, languages } = this.props;
    const { info, ready } = this.state;
    if (!ready) return <SpinLoading />;
    
    const local = info?.data?.local === 1 ? 'Solar da Levada' : 'Quinta Lago dos Cisnes';

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Editar Metatags</PageTitle>
            <PageSubtite>{`${local} » ${info?.data?.name}`}</PageSubtite>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={12}>
              <Col xs={12}>
                <DefaultLanguageTab>
                  {GetTranslationDescription(activeLanguage.code)}
                </DefaultLanguageTab>
                <Field
                  component={TextInput}
                  name={`title.${activeLanguage.code}`}
                  label={'Title'}
                  type={'text'}
                />
                <Field
                  component={TextAreaInput}
                  name={`description.${activeLanguage.code}`}
                  label={'Description'}
                />
                <Field
                  component={TextAreaInput}
                  name={`keywords.${activeLanguage.code}`}
                  label={'Keywords'}
                />
              </Col>
              <Col xs={12}>
                <Tabs>
                  {languages
                    .filter((x) => !x.active)
                    .map((language) => (
                      <TabPane
                        tab={GetTranslationDescription(language.code)}
                        key={language.code}
                      >
                        <Field
                          component={TextInput}
                          name={`title.${language.code}`}
                          label={'Title'}
                          type={'text'}
                        />
                        <Field
                          component={TextAreaInput}
                          name={`description.${language.code}`}
                          label={'Description'}
                        />
                        <Field
                          component={TextAreaInput}
                          name={`keywords.${language.code}`}
                          label={'Keywords'}
                        />
                      </TabPane>
                    ))}
                </Tabs>
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageMetatagsPage = reduxForm({
  form: 'manage_metatags_form'
})(ManageMetatagsPage);

export default withLocalize(connect()(ManageMetatagsPage));