import React from 'react';
import styled from 'styled-components';
import {Drawer} from 'antd';
import {Field, reduxForm} from 'redux-form';
import DraftInput from '../inputs/DraftInput';
import {BaseButton} from '../buttons/BaseButton';

const NotesForm = styled.form`
  margin: 0;
  padding: 0 0 60px 0;
`;

const ActionBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  text-align: right;
  background: white;
  z-index: 99;
  border-top: 1px solid #e8e8e8;
`;

let CommentsDrawer = ({
  handleSubmit,
  open,
  onClose,
  saveNotes,
  loading,
  title,
  archived = false,
}) => {
  return (
    <Drawer
      title={title || 'Notas da secção'}
      placement="right"
      width={500}
      onClose={handleSubmit((values) => onClose(values))}
      visible={open}
      keyboard={false}
    >
      {
        open && 
        <NotesForm onSubmit={handleSubmit(saveNotes)}>
          <Field
            name="notes"
            component={DraftInput}
            minRows={10}
            label="Notas Publicas"
            placeholder="Adicione aqui as notas para partilhar"
            toolbarOnFocus={false}
            disabled={archived}
            toolbar='notes'
          />
          <Field
            name="private"
            component={DraftInput}
            minRows={10}
            label="Notas Privadas"
            placeholder="Adicione aqui as suas notas privadas"
            toolbarOnFocus={false}
            toolbar='notes'
            disabled={archived}
          />
          <ActionBar>
            {!archived && <BaseButton
              loading={loading}
              type={'primary'}
              icon={'save'}
              htmlType="submit"
              align="right"
              text={'Gravar'}
            />}
          </ActionBar>
        </NotesForm>
      }
    </Drawer>
  );
};

CommentsDrawer = reduxForm({
  form: 'wedding_notes_section_form'
})(CommentsDrawer);

export default CommentsDrawer;