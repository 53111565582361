import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {Modal, Button} from 'antd';
import {BaseForm} from '../../styles/BasicStyles';
import LanguagesInput from '../../components/inputs/LanguagesInput';
import TitleInput from '../../components/inputs/TitleInput';
import SelectQuestion from '../../components/inputs/SelectQuestion';
import FormValidator from '../../infra/services/validations/FormValidator';

const validations = FormValidator.make({
  title: 'required|languages',
  type: 'required'
});

let ManageQuestionModal = ({
  open,
  edit,
  loading,
  handleSubmit,
  onSubmit,
  closeModal
}) => {
  return (
    <Modal
      visible={open}
      title={edit ? 'Editar Pergunta' : 'Adicionar pergunta'}
      maskClosable={false}
      onCancel={closeModal}
      width={800}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          icon={loading ? 'loading' : ''}
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      {open && (
        <BaseForm onSubmit={handleSubmit(onSubmit)}>
          <Field
            disabled={edit ? true : false}
            component={SelectQuestion}
            name={'type'}
            label={'Tipo de pergunta'}
            placeholder={'Escolha o tipo de pergunta'}
          />
          <Field
            component={TitleInput}
            name={'title'}
            type="text"
            label={'Titulo'}
            placeholder={'Titulo da pergunta'}
          />
          <Field
            component={LanguagesInput}
            name={'description'}
            type="text"
            label={'Descrição'}
            placeholder={'Descrição da pergunta'}
          />
        </BaseForm>
      )}
    </Modal>
  );
};

ManageQuestionModal = reduxForm({
  form: 'manage_question_form',
  validate: validations
})(ManageQuestionModal);

export default ManageQuestionModal;
