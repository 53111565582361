import React, { Fragment, useEffect, useState } from 'react';
import { Checkbox, Col, Form, Modal, Row } from 'antd';
import { GetSubcategories, GetSubcategory } from '../../infra/requests/SubcategoryRequests';
import moment from 'moment';
import { DownloadAvailableMap, GetEmployeesAvailableBySubcategories } from '../../infra/requests/EmployeeRequests';
import { getEmployeesOrdered } from '../../infra/services/wedding/weddingUtils';
import DateInput from '../../components/inputs/DateInput';

let AvailableMapModal = (props): React$Element<React$FragmentType> => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [subcategories, setSubcategories] = useState([]);
  const [date, setDate] = useState(moment());
  const [midleSize, setMidleSize] = useState(0);

  useEffect(() => {
    getSubcategory();
  }, []);

  useEffect(() => {
    setOpenModal(props.openModal);
  }, [props.openModal])

  const getSubcategory = async () => {
    const result: any = await GetSubcategories();
    if (result.success && result.data) {
      setSubcategories(result.data.map(m => ({ subcategory: m, checked: false })));
      setMidleSize(Math.ceil(result.data.length / 2));
    }
  }

  const onSubmit = async () => {
    setLoading(true);

    const data = {
      date: date.toISOString(),
      subcategories: subcategories.filter((f: any) => f.checked).map((m: any) => m.subcategory)
    };

    const result: any = await GetEmployeesAvailableBySubcategories(data);
    // console.warn('result', result);

    const respAvailable = result.data.map(r => ({
      subcategory: r.subcategory,
      employees: getEmployeesOrdered({ success: result.success, data: r.interest }, { success: result.success, data: r.subcategories })
    }));
    // console.warn('respAvailable', respAvailable);

    const resultPdf = await DownloadAvailableMap({ respAvailable, date: date.toISOString() });

    if (resultPdf) {
      let filename = 'AvailableMap_' + date.format('YYYYMMDD') + '.pdf';

      if (resultPdf.headers) {
        const contentDisposition = resultPdf.headers.get('content-disposition');
        filename = contentDisposition.split('filename="')[1].split('"')[0];
      }

      //Create blob link to download
      const url = window.URL.createObjectURL(new Blob([resultPdf])); //result.blob
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);

      //Append to html link element page
      document.body.appendChild(link);

      //Start download
      link.click();

      //Clean up and remove the link
      link.parentNode.removeChild(link);
    }
    setLoading(false);
  };

  const getRowCheckbox = (elem: any, index: number) => {
    return (<Row>
      <div className="sc-dxgOiQ fWHHkk" >
        <Checkbox
          checked={elem.checked}
          onChange={e => {
            subcategories[index].checked = e.target.checked;
            setSubcategories(JSON.parse(JSON.stringify(subcategories)));
          }}>
          {elem.subcategory.name}
        </Checkbox>
      </div>
    </Row>)
  }

  return (
    <Fragment>
      <Modal
        visible={openModal}
        title="Mapa de Disponibilidade"
        okText="Exportar"
        maskClosable
        onCancel={() => props.onCancel()}
        okButtonProps={{ loading }}
        onOk={onSubmit}>

        <div style={{ width: '40%' }}>
          <DateInput
            label="Data"
            placeholder="Selecione a data"
            input={{
              value: date,
              onChange: value => setDate(value)
            }}
            meta={{ valid: true }}
          />
        </div>

        <div className="sc-eHgmQL fRMxMJ" style={{ marginTop: 15 }}>Subcategorias</div>
        <Row>
          <Col span="12">
            {subcategories.slice(0, midleSize).map((elem: any, index) => {
              return getRowCheckbox(elem, index);
            })}
          </Col>
          <Col span="12">
            {subcategories.slice(midleSize).map((elem: any, index) => {
              return getRowCheckbox(elem, midleSize + index);
            })}
          </Col>
        </Row>
        {/* <div className="sc-eHgmQL fRMxMJ">Subcategorias</div>
        {subcategories.length > 0 && subcategories.length / 2}
        {subcategories.map((elem: any, index) => {
          getRowCheckbox(elem);
        })} */}

      </Modal>
    </Fragment>
  );
};

export default AvailableMapModal;