import React, {Component} from 'react';
import {Row, Col} from 'antd';
import {Field, reduxForm, initialize, FieldArray} from 'redux-form';
import {withLocalize} from 'react-localize-redux';
import {
  GetFlower,
  CreateFlower,
  UpdateFlower
} from '../../infra/requests/FlowersRequests';
import TextInput from '../../components/inputs/TextInput';
import TextInputList from '../../components/inputs/TextInputList';
import BaseButton from '../../components/buttons/BaseButton';
import ColourInput from '../../components/inputs/ColourInput';
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';

const validations = FormValidator.make({
  name: 'required',
  hex: 'required'
});

class ManageFlowersPage extends Component {
  state = {
    loading: true,
    isNew: true
  };

  componentDidMount = async () => {
    const {
      match: {params},
      dispatch
    } = this.props;

    if(params.id) {
      const {data} = await GetFlower(params.id);
      dispatch(initialize('manage_flowers_form', data));
      this.setState({isNew: false});
    } 
    else {
      dispatch(initialize('manage_flowers_form', {}));
    }
    this.setState({loading: false});
  };

  onSubmit = async values => {
    try {
      this.setState({loading: true});
      const {
        match: {params},
        history
      } = this.props;

      const {success} = params.id ? await UpdateFlower(params.id, values) : await CreateFlower(values);
      if (success) return history.push('/flowers');
      return this.setState({loading: false});
    } catch (e) {
      this.setState({loading: false});
    }
  };

  handleOnCancel = async () => {
    const {history} = this.props;
    return history.push('/flowers');
  };

  render() {
    const {handleSubmit} = this.props;
    const {loading, isNew} = this.state;

    if(loading) return <SpinLoading />;

    const subtitle = isNew ? 'Inserir' : 'Editar';

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Cores & Flores</PageTitle>
            {subtitle}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
          <Row style={{ display: 'grid', justifyItems: 'center' }}>
              <Col xs={24} sm={18} md={15} lg={10}>
                <Field
                  component={TextInput}
                  name={'name'}
                  type="text"
                  label={'Nome da Cor'}
                  placeholder={'Nome da Cor'}
                />
                <Field
                  component={ColourInput}
                  name={'hex'}
                  type="text"
                  label={'Cor'}
                  placeholder={'Insira a cor em hexadecimal'}
                />
                <FieldArray
                  component={TextInputList}
                  name="flowers"
                  label="Flores"
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageFlowersPage = reduxForm({
  form: 'manage_flowers_form',
  validate: validations
})(ManageFlowersPage);

export default withLocalize(ManageFlowersPage);