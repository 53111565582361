import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initialize } from 'redux-form';
import { Row, Col } from 'antd';
import {
  GetBudget,
  GetOurStyle,
  GetOurGuests,
  GetOurStaff,
  getWeddingInfo
} from '../../infra/requests/WeddingProcessRequests';
import { GetOurDay } from '../../infra/requests/OurDayRequests';
import { DashboardPage } from './ProcessStyles';
import BasicInfoComponent from './components/BasicInfoComponent';
import BudgetComponent from './components/BudgetComponent';
import StyleComponent from './components/StyleComponent';
import OurDayComponent from './components/OurDayComponent';
import GuestsComponent from './components/GuestsComponent';
import UnansweredQuestionsComponent from './components/UnansweredQuestionsComponent';
import CeremonyComponent from './components/CeremonyComponent';
import StaffComponent from './components/StaffComponent';
import FoodComponent from './components/FoodComponent';
import DrinksComponent from './components/DrinksComponent';
import DecorationComponent from './components/DecorationComponent';
import PartyComponent from './components/PartyComponent';
import AboutUsComponent from './components/AboutUsComponent';
import UpgradeComponent from './components/UpgradeComponent';
import PrintedComponent from './components/PrintedComponent';
import PrivateNotesComponent from './components/PrivateNotesComponent';
import { SpinLoading } from '../../styles/BasicStyles';
import { GetUpgrade, GetUpgrades } from '../../infra/requests/UpgradesRequests';
import { ChangeWeddingUpgrade, AddWedding } from '../../redux/wedding/wedding.actions';
import { findPageByTag } from '../../infra/services/sections/Sections';
import PrintedBar from './components/PrintedBar';
import { GetQuestionByChapter, GetQuestionForBudget } from '../../infra/requests/QuestionsRequests';
import { GetConfigByWedding } from '../../infra/requests/ConfigsRequests';

class ProcessPage extends Component {
  state = {
    budget: {},
    style: {},
    day: [],
    guests: {},
    staff: {},
    upgrade: undefined,
    upgradeList: [],
    loading: true,
    dayInformation: undefined,
    questionsPaymentMethods: [],
    paymentPhases: [],
    venueConfig: null,
  };

  componentDidMount() {
    this.getInformation();
  }

  componentDidUpdate() {
    const { match, history } = this.props;

    //Scroll to Upgrade section when returning from Upgrade Detail
    if (window.location.hash === '#upgrade') {
      window.scrollTo(0, document.body.scrollHeight);
      history.replace(match.url);
    }
  }

  getInformation = async () => {
    const {
      match: { params },
      wedding,
      structure,
      dispatch
    } = this.props;

    const budget = await GetBudget(params.wedding);
    const resultVenueConfig = await GetConfigByWedding(params.wedding)
    const style = await GetOurStyle(params.wedding);
    const day = await GetOurDay(params.wedding);
    const guests = await GetOurGuests(params.wedding);
    const staff = await GetOurStaff(params.wedding);
    const upgradeList = await GetUpgrades();
    const dayInformation = findPageByTag(structure, 'DAY');

    let upgrade = undefined;
    if (wedding.upgrade) {
      const { data } = await GetUpgrade(wedding.upgrade._id ? wedding.upgrade._id : wedding.upgrade);
      upgrade = data;
    }

    dispatch(initialize('wedding_printed_form', { printed: wedding?.printed }));

    this.setState({
      loading: false,
      budget: budget?.data,
      style: style?.data || { inspirations: [], images: [] },
      day: day?.data,
      guests: guests?.data,
      upgrade,
      staff: staff?.data?.list || [],
      upgradeList: upgradeList?.data || [],
      dayInformation,
      questionsPaymentMethods: budget?.success && budget?.data?.questions
        ? budget?.data?.questions
        : [],
      paymentPhases: budget?.success && budget?.data?.paymentPhases
        ? budget?.data?.paymentPhases
        : [],
      venueConfig: resultVenueConfig?.success && resultVenueConfig?.data
        ? resultVenueConfig?.data
        : null
    });
  };

  updateWeddingUpgrade = async (upgrade) => {
    const { ChangeWeddingUpgrade, AddWedding, match: { params } } = this.props;
    const budget = await GetBudget(params.wedding);
    // console.warn('Upgrade', upgrade);

    this.setState({
      upgrade,
      budget: budget?.data,
    });
    ChangeWeddingUpgrade(upgrade ? upgrade._id : undefined);

    const { data, success } = await getWeddingInfo(params.wedding);
    if (success && data) AddWedding(data);
  }

  setProcessLoading = (value) => {
    this.setState({ loading: value });
  }

  render() {
    const { wedding, notes, structure, food, colours } = this.props;
    const { budget, style, day, guests, staff, loading, upgrade, upgradeList, dayInformation } = this.state;
    const { venueConfig, questionsPaymentMethods, paymentPhases } = this.state;

    if (loading) return <SpinLoading />;

    return (
      <DashboardPage>
        <Row style={{ marginLeft: -15 }}>
          {
            wedding?.printed &&
            <PrintedBar small={false} />
          }
        </Row>
        <Row>
          <Col md={12} lg={12} xs={{ span: 25, order: 3 }} sm={{ span: 24, order: 3 }}>
            <BasicInfoComponent
              weddingInfo={wedding}
            />
          </Col>
          <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={12} lg={12}>
            <PrintedComponent
              weddingInfo={wedding}
              getInformation={this.getInformation}
              setProcessLoading={this.setProcessLoading}
            />
          </Col>
          <Col xs={24}>
            <PrivateNotesComponent
              weddingInfo={wedding}
            />
          </Col>
          <Col xs={24}>
            <AboutUsComponent
              weddingInfo={wedding}
              structureInfo={structure}
              notes={notes}
            />
          </Col>
          <Col xs={24}>
            <GuestsComponent
              weddingInfo={wedding}
              guests={guests}
              minPeople={wedding.min_people}
              notes={notes}
            />
          </Col>
          <Col xs={24}>
            <OurDayComponent
              weddingInfo={wedding}
              day={day}
              notes={notes}
              dayInformation={dayInformation}
            />
          </Col>
          <Col xs={24}>
            <FoodComponent
              weddingInfo={wedding}
              structureInfo={structure}
              foodInfo={food}
              colorsInfo={colours}
              notes={notes}
            />
          </Col>
          <Col xs={24}>
            <DrinksComponent
              weddingInfo={wedding}
              structureInfo={structure}
              foodInfo={food}
              colorsInfo={colours}
              notes={notes}
            />
          </Col>
          <Col xs={24}>
            <StaffComponent
              staffInfo={staff}
              weddingInfo={wedding}
              structureInfo={structure}
              notes={notes}
            />
          </Col>
          <Col xs={24}>
            <DecorationComponent
              notes={notes}
              props={this.props}
            />
          </Col>
          <Col xs={24}>
            <StyleComponent
              weddingInfo={wedding}
              style={style}
              notes={notes}
            />
          </Col>
          <Col xs={24}>
            <CeremonyComponent
              structureInfo={structure}
              foodInfo={food}
              colorsInfo={colours}
              weddingInfo={wedding}
              notes={notes}
            />
          </Col>
          <Col xs={24}>
            <PartyComponent
              weddingInfo={wedding}
              structureInfo={structure}
              foodInfo={food}
              colorsInfo={colours}
              notes={notes}
            />
          </Col>
          <Col xs={24}>
            <BudgetComponent
              weddingInfo={wedding}
              budgetInfo={budget}
              notes={notes}
              upgrade={upgrade}
              venueConfig={venueConfig}
              questionsPaymentMethods={questionsPaymentMethods}
              paymentPhases={paymentPhases}
            />
          </Col>
          <Col xs={24} id='unanswered'>
            <UnansweredQuestionsComponent
              guestsInfo={guests}
              structureInfo={structure}
              foodInfo={food}
              colorsInfo={colours}
              weddingInfo={wedding}
              staffInfo={staff}
            />
          </Col>
          <Col xs={24}>
            <UpgradeComponent
              upgrade={upgrade}
              upgradeList={upgradeList}
              updateWeddingUpgrade={this.updateWeddingUpgrade}
            />
          </Col>
        </Row>
      </DashboardPage>
    );
  }
}

const mapStateToProps = state => ({
  weddingInfo: state.wedding,
  wedding: state.wedding.wedding,
  notes: state.wedding.notes,
  structure: state.wedding.structure,
  food: state.info.food,
  colours: state.info.colours,
});

const mapActionToProps = dispatch =>
  bindActionCreators({ ChangeWeddingUpgrade, AddWedding, dispatch }, dispatch);

export default withRouter(connect(mapStateToProps, mapActionToProps)(ProcessPage));
