const AgendaActions = {
    SELECT: "S",
    CREATE_RESERVE: "CR",
    MNG_RESERVE: 'MR',
    MNG_PRICE: 'MP',
    CREATE_UNAVAILABLE: 'CU',
    REMOVE_UNAVAILABLE: 'RU',
    RESERVE_WEDDING: 'RW',
    EDIT_WEDDING: 'EW',
    INFO_WEDDING: 'IW',
    ARCHIVED_WEDDING: 'AW'
}

export default AgendaActions