import styled from 'styled-components';
import {Form as AntForm} from 'antd';

export const ModalButton = styled.div`
  display: inline-block;
  position: absolute;
  top: 15px;
  right: 0;
`;

export const Form = styled(AntForm)`
  display: inline-block;
  width: 100%;
  margin: 30px 0;
`;
