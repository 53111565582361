import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { TimePicker, Icon, Col, Row } from 'antd';
import {
  SpinLoading,
  SectionTitle,
  TableButton,
  HeaderButtonsContainer,
} from '../../styles/BasicStyles';
import SelectInput from '../../components/inputs/SelectInput';
import Table from '../../components/table/Table';
import NumberInput from '../../components/inputs/NumberInput';
import BaseButton from '../../components/buttons/BaseButton';
import { GetCategories } from '../../infra/requests/CategoryRequests';
import { rest } from 'lodash';
import { CreateWeddingInterest, DeleteWeddingInterest, GetWeddingInterestByWeddingAndEmployeeAndSubcategory, GetWeddingInterestByWeddingAndSubcategory } from '../../infra/requests/WeddingInterestRequests';
import { GetEmployeePriceByEmployeeAndSubcategory } from '../../infra/requests/EmployeePriceRequests';

const newInterest = {
  _id: 'new#Int2',
  id: 'new#Int2',
  employee: null,
  wedding: null,
  subcategory: null
};
class AddDeleteWeddingInterest extends Component {
  state = {
    pageSize: 15,
    currentPage: 1,
    loading: true,
    currentUser: null,
    currentEmployee: null,
    wedding: null,
    teamBooking: null,

    columns: [
      {
        title: 'Categoria: Subcategoria',
        key: 'susubcategory',
        render: data => (<div>
          <b>{data.teamBooking.subcategory.category.name}:</b>
          &nbsp; {data.teamBooking.subcategory?.name}
        </div>)
      },
      {
        title: 'Horário de Entrada',
        width: '20%',
        render: data => moment(data.teamBooking.startTime).format('HH:mm')
      },
      {
        title: 'Quantidade',
        key: 'quantity',
        width: '15%',
        render: data => data.teamBooking.quantity
      },
      {
        title: 'Acções',
        width: '25%',
        render: data => (
          <Fragment>
            {data.weddingInterest ? (<TableButton onClick={async () => {
              let { rows } = this.state;
              const resInterest = await DeleteWeddingInterest(data.weddingInterest.id);

              const index = rows.findIndex(f => f.teamBooking.id === data.teamBooking.id);
              rows[index].weddingInterest = resInterest.success && resInterest.data ? null : resInterest.data;
              rows[index].canInterest = true;

              this.setState({ rows });
            }}>
              <Icon type="star" theme='filled' />
              &nbsp; Interesse Registado
            </TableButton>) : null}

            {data.canInterest && !data.weddingInterest ? (<TableButton onClick={async () => {
              let { rows, wedding, currentEmployee } = this.state;
              const interest = JSON.parse(JSON.stringify(newInterest));
              interest.wedding = wedding.id;
              interest.employee = currentEmployee.id;
              interest.subcategory = data.teamBooking.subcategory.id;

              const resInterest = await CreateWeddingInterest(interest);

              const index = rows.findIndex(f => f.teamBooking.id === data.teamBooking.id);
              rows[index].weddingInterest = resInterest.success && resInterest.data ? resInterest.data : null;

              this.setState({ rows });
            }}>
              <Icon type="star" />
              &nbsp; Registar Interesse
            </TableButton>) : null}
          </Fragment>
        )
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    this.setState({
      currentUser: this.props.currentUser,
      currentEmployee: this.props.currentEmployee,
      wedding: this.props.selectedWedding,
      teamBooking: this.props.teamBooking,
      loading: false
    }, () => {
      this.setRows();
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.selectedWedding !== this.props.selectedWedding) {
      this.setState({
        currentUser: this.props.currentUser,
        currentEmployee: this.props.currentEmployee,
        selectedWedding: this.props.selectedWedding,
        teamBooking: this.props.teamBooking,
        loading: false
      }, () => {
        this.setRows();
      });
    }
  }

  setRows = async () => {
    const { rows, currentEmployee, wedding, teamBooking } = this.state;

    for (let index = 0; index < teamBooking.length; index++) {
      const booking = teamBooking[index];

      const resInterestByEmployee = await GetWeddingInterestByWeddingAndEmployeeAndSubcategory(wedding.id, currentEmployee.id, booking.subcategory.id);
      // console.warn('resInterestByEmployee' ,resInterestByEmployee);
      // const resInterestByWedding = await GetWeddingInterestByWeddingAndSubcategory(wedding.id, booking.subcategory.id);
      // const resPrice = await GetEmployeePriceByEmployeeAndSubcategory(currentEmployee.id, booking.subcategory.id);

      let canInterest;
      if (resInterestByEmployee.success && resInterestByEmployee.data) {
        // const totalInterest = resInterestByEmployee.data.length;
        const hasSubcategory = resInterestByEmployee.success && resInterestByEmployee.data && resInterestByEmployee.data.price ? true : false;

        // canInterest = totalInterest < booking.quantity && hasSubcategory ? true : false;
        canInterest = hasSubcategory;
      } else canInterest = false;

      rows.push({
        teamBooking: booking,
        weddingInterest: resInterestByEmployee.success && resInterestByEmployee.data && resInterestByEmployee.data.interest ? resInterestByEmployee.data.interest : null,
        employeePrice: resInterestByEmployee.success && resInterestByEmployee.data && resInterestByEmployee.data.price ? resInterestByEmployee.data.price : null,
        canInterest: canInterest
      });
    }

    this.setState({ rows });
  }

  render() {
    const { loading, currentPage, pageSize } = this.state;
    const { columns, rows, total } = this.state;

    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <SectionTitle subsection>Funcionários Necessários</SectionTitle>
        <Table
          columns={columns}
          // currentPage={currentPage}
          // pageSize={pageSize}
          // defaultPageSize={pageSize}
          loading={loading}
          rows={rows}
          showHeader={true}
          emptyIcon="team"
          emptyText={'Ainda não foi definida a estrutura da equipa'}
          total={total}
          rowKey={'_id'}
          hasPagination={false}
          // handleChangePage={this.handleChangePage}
          // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        />

      </React.Fragment>
    );
  }
}

export default AddDeleteWeddingInterest;
