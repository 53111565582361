import React, {Component} from 'react';
import {Row, Col} from 'antd';
import {Field, reduxForm, initialize, FieldArray} from 'redux-form';
import {withLocalize} from 'react-localize-redux';
import {
  GetUpgrade
} from '../../../infra/requests/UpgradesRequests';
import TitleInput from '../../../components/inputs/TitleInput';
import LanguagesInput from '../../../components/inputs/LanguagesInput';
import ImageInput from '../../../components/inputs/ImageInput';
import ColourInput from '../../../components/inputs/ColourInput';
import CurrencyInput from '../../../components/inputs/CurrencyInput';
import ImageGalleryInput from '../../../components/inputs/ImageGalleryInput';
import BaseButton from '../../../components/buttons/BaseButton';
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading,
  SectionTitle
} from '../../../styles/BasicStyles';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import ManageSections from '../../../weddingday/upgrades/ManageSections';

class UpgradeDetailComponent extends Component {
  state = {
    loading: true,
    name: ''
  };

  componentDidMount = async () => {
    const {
      match: {params},
      dispatch
    } = this.props;
    const {data} = await GetUpgrade(params.id);
    dispatch(initialize('upgrade_detail_form', data));
    this.setState({loading: false, name: TranslateValue(data.name)});
  };

  handleOnCancel = async () => {
    const {history, match: {params}} = this.props;
    return history.push(`/wedding/${params.wedding}#upgrade`);
  };

  render() {
    const {
      match: {params}
    } = this.props;
    const {name, loading} = this.state;
    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>{name}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type="default"
              icon="arrow-left"
              text="Voltar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer>
          <BaseForm>
            <Row>
              <Col xs={4} offset={10}>
                <Field
                  name="icon"
                  label="Icon"
                  component={ImageInput}
                  ratio={1}
                  disabled={true}
                />
              </Col>
              <Col xs={10} offset={7}>
                <Field
                  component={TitleInput}
                  name={'name'}
                  type="text"
                  label={'Nome'}
                  placeholder={'Nome do Upgrade'}
                  disabled={true}
                />
                <Field
                  component={CurrencyInput}
                  name={'price'}
                  label={'Preço'}
                  placeholder={'Preço do upgrade'}
                  disabled={true}
                />
                <Field
                  component={LanguagesInput}
                  name={'description'}
                  type="text"
                  label={'Descrição'}
                  placeholder={'Descrição do Upgrade'}
                  disabled={true}
                />
                <Field
                  component={ColourInput}
                  name={'textColor'}
                  defaultColor="#000"
                  label={'Cor do texto'}
                  disabled={true}
                />
              </Col>
              <Col xs={20} offset={2}>
                <SectionTitle subsection>Galeria de Imagens</SectionTitle>
                <FieldArray
                  name="gallery"
                  component={ImageGalleryInput}
                  handleAdd={this.saveNewImage}
                  handleDelete={this.deleteImage}
                  disabled={true}
                />
              </Col>
              <Col xs={20} offset={2}>
                <SectionTitle subsection>Secções</SectionTitle>
                <FieldArray
                  name="sections"
                  component={ManageSections}
                  upgrade={params.id}
                  disabled={true}
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

UpgradeDetailComponent = reduxForm({
  form: 'upgrade_detail_form'
})(UpgradeDetailComponent);

export default withLocalize(UpgradeDetailComponent);