import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, initialize} from 'redux-form';
import {withLocalize} from 'react-localize-redux';
import {
  AddInspiration,
  GetInspiration,
  EditInspiration
} from '../../infra/requests/InspirationsRequest';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import TagsInput from '../../components/inputs/TagsInput';
import ImageInput from '../../components/inputs/ImageInput';
import BaseButton from '../../components/buttons/BaseButton';
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import {FlattenToFormData} from '../../infra/services/formdata/TransformToFormData';

const validations = FormValidator.make({
  text: 'required',
  categories: 'required|array',
  image: 'required'
});

class ManageInspirationPage extends Component {
  state = {
    isNew: false,
    loading: true
  };

  componentDidMount = async () => {
    const {
      match: {params},
      dispatch
    } = this.props;

    if (params.id) {
      const result = await GetInspiration(params.id);
      dispatch(
        initialize('manage_inspiration_form', {
          ...result.data,
          categories: result.data.categories.map(category => category._id)
        })
      );
      this.setState({loading: false});
    } else
      this.setState({
        isNew: true,
        loading: false
      });
  };

  onSubmit = async values => {
    try {
      this.setState({loading: true});
      const {
        match: {params},
        history
      } = this.props;
      const {isNew} = this.state;

      if (values.image && !values.image.blob) {
        delete values.image;
      }

      const payload = FlattenToFormData(values);

      const {success} = isNew
        ? await AddInspiration(payload)
        : await EditInspiration(params.id, payload);
      if (success) return history.push('/inspirations');
      return this.setState({loading: false});
    } catch (e) {
      console.error(e);
      this.setState({loading: false});
    }
  };

  handleOnCancel = async () => {
    const {history} = this.props;
    return history.push('/inspirations');
  };

  render() {
    const {handleSubmit, categories} = this.props;
    const {isNew, loading} = this.state;
    if (loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar inspiração' : 'Editar inspiração';
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{title}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={TextInput}
              name="text"
              type="text"
              label={'Título *'}
              placeholder={'Insira título da imagem'}
            />
            <Field
              component={SelectInput}
              name={'categories'}
              label={'Categoria *'}
              placeholder={'Escolha a categoria para a imagem'}
              type="select"
              data={categories}
              dataKey={'_id'}
              dataLabel={'name'}
              mode="multiple"
            />
            <Field component={TagsInput} name="tags" label={'Tags'} />
            <Field
              component={ImageInput}
              name="image"
              label={'Imagem *'}
              width="100%"
              ratio={1}
            />
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageInspirationPage = reduxForm({
  form: 'manage_inspiration_form',
  validate: validations
})(ManageInspirationPage);

const mapStateToProps = state => ({
  categories: state.info.categories
});

export default withLocalize(connect(mapStateToProps)(ManageInspirationPage));
