import React, { Component } from "react";
import moment from "moment";
import Table from "../../components/table/Table";
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle
} from "../../styles/BasicStyles";
import { GetAllContacts } from "../../infra/requests/ContactsRequests";

class ContactsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    columns: [
      {
        title: "Name",
        dataIndex: "name"
      },
      {
        title: "Email",
        dataIndex: "email"
      },
      {
        title: "Contacto",
        dataIndex: "contact"
      },
      {
        title: "Website",
        dataIndex: "website",
        render: value =>
          value === 1 ? "Solar da Levada" : "Quinta Lago dos Cisnes"
      },
      {
        title: "Message",
        dataIndex: "message"
      },
      {
        title: "requested at",
        dataIndex: "createdAt",
        render: value => moment(value).format("HH:mm, DD/MM/YYYY")
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize } = this.state;
      const result = await GetAllContacts(currentPage, pageSize);
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Contact Requests</PageTitle>
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer buttons={0}>
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="message"
            emptyText={"There are not contact requests"}
            total={total}
            rowKey={"_id"}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default ContactsPage;
