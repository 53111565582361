import client from '../config/AxiosConfig';

export const GetChapter = async id => client.get(`/chapters/${id}`);

export const CreateChapter = async data => client.post('/chapters', data);

export const UpdateChapter = async (id, data) =>
  client.put(`/chapters/${id}`, data);

export const UpdateChapterOrder = async data =>
  client.post(`/chapters/order`, data);

export const DeleteChapter = async (id) =>
  client.delete(`/chapters/${id}`);

export const GetChapterList = async () =>
  client.get('/chapters/list');

export const GetChapterLogsList = async (filters) =>
  client.get(`/chapters/logs/list?filter=${filters}`);
