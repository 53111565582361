import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  BasicInfoSection,
  SectionCTA,
  SectionTitle
} from '../ProcessStyles';
import { BaseButton } from '../../../components/buttons/BaseButton';
import SectionNotes from './SectionNotes';
import BudgetDetails from '../../../components/budget/BudgetDetails';
import CommentsDrawer from '../../../components/comments/CommentsDrawer';
import { SaveSectionNotes } from '../../../infra/requests/WeddingProcessRequests';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { bindActionCreators } from 'redux';
import { SaveNotes } from '../../../redux/wedding/wedding.actions';
import NotesConfirmModal from '../../../components/notesModal/NotesModal';
import { hasNotes } from '../../../infra/services/notes/FindNotes';
import { getPrintedConfirmation } from '../../../infra/services/wedding/printedUtils';
import { Modal } from 'antd';
import { GetVenueColors } from '../../../infra/requests/ColorSystemRequests';
import { VenueColors } from '../../../infra/services/wedding/weddingUtils';

const { confirm } = Modal;

const BudgetComponent = ({
  weddingInfo,
  budgetInfo,
  upgrade,
  venueConfig,
  questionsPaymentMethods,
  paymentPhases,
  history,
  match: { params },
  notes = [],
  archived,
  dispatch,
  saveNotesOnReducer
}) => {
  const [drawer, setDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openNotesConfirm, setNotesConfirm] = useState(false);
  const [colors, setColors] = useState(VenueColors);
  const [localColor, setLocalColor] = useState(null);

  const note = notes.find(note => note.section === 'BUDGET');

  useEffect(() => {
    getLocalColors();
  }, []);

  const getLocalColors = async () => {
    const result = await GetVenueColors();
    const dataColors = result?.success ? result?.data : VenueColors
    setColors(dataColors);
    const colorByPlace = weddingInfo?.wedding_place == 1
      ? dataColors?.solar
      : weddingInfo?.wedding_place == 2
        ? dataColors?.lago
        : null;
    setLocalColor(colorByPlace);
  }

  const openNotes = () => {
    dispatch(initialize('wedding_notes_section_form', note));
    setDrawer(true);
  };

  const closeNotes = () => {
    dispatch(initialize('wedding_notes_section_form', note));
    setDrawer(false);
    setNotesConfirm(false);
  };

  const checkBeforeCloseNotes = (values) => {
    //If theres any change to the notes, ask before closing if we want to save the changes
    if (values && (note?.notes !== values.notes || note?.private !== values.private)) {
      dispatch(initialize('notes_modal_confirm_form', values));
      setNotesConfirm(true);
    }
    //Otherwise, just the close the notes
    else {
      setDrawer(false);
    }
  }

  const saveNotes = async (values) => {
    if (weddingInfo?.printed) {
      confirm({
        ...getPrintedConfirmation(),
        onOk() { updateNotes(values); },
        onCancel() { closeNotes(); },
      });
    } else updateNotes(values);
  };

  const updateNotes = async (values) => {
    setLoading(true);

    const { data } = await SaveSectionNotes(weddingInfo._id, {
      ...values,
      section: 'BUDGET'
    });

    dispatch(initialize('wedding_notes_section_form', data));
    saveNotesOnReducer(data);
    setLoading(false);
    setDrawer(false);
    setNotesConfirm(false);

    document.body.style.overflowY = 'auto';
  };

  return (
    <BasicInfoSection>
      <SectionTitle>
        Orçamento
        <SectionCTA>
          <BaseButton
            type={'default'}
            icon={'read'}
            text={'Notas'}
            onClick={openNotes}
            notes={hasNotes(notes, 'BUDGET')}
          />
          {!archived && (
            <BaseButton
              type={'default'}
              icon={'arrow-right'}
              text={'Abrir Orçamento'}
              onClick={() => history.push(`/wedding/${params.wedding}/budget`)}
            />
          )}
        </SectionCTA>
      </SectionTitle>
      <SectionNotes title="Notas Públicas:" note={note?.notes} />
      <SectionNotes title="Notas Privadas:" note={note?.private} />

      <BudgetDetails
        weddingInfo={weddingInfo}
        budgetInfo={budgetInfo}
        upgrade={upgrade}
        venueConfig={venueConfig}
        questionsPaymentMethods={questionsPaymentMethods}
        paymentPhases={paymentPhases}
        localColor={localColor}
      />
      <CommentsDrawer
        title="Notas De Orçamento"
        open={drawer}
        loading={loading}
        onClose={checkBeforeCloseNotes}
        saveNotes={saveNotes}
        archived={archived}
      />
      <NotesConfirmModal
        open={openNotesConfirm}
        loading={loading}
        onSubmit={saveNotes}
        closeModal={() => setNotesConfirm(false)}
        exitWithoutSaving={closeNotes}
      />
    </BasicInfoSection>
  );
};

const mapActionToProps = dispatch =>
  bindActionCreators(
    { saveNotesOnReducer: SaveNotes, dispatch },
    dispatch
  );

export default withRouter(connect(null, mapActionToProps)(BudgetComponent));