import React, { Component, Fragment } from 'react';
import Table from '../../components/table/Table';
import {
    Row,
    Col,
    Popconfirm,
    Icon,
} from 'antd';
import {
    TableButton,
    SectionTitle,
    TableFilterSection
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import TextInput from '../../components/inputs/TextInput';
import { withLocalize } from 'react-localize-redux';
import {
    GetSettingsByType,
    UpdateSetting,
    DeleteSetting,
    CreateSetting,
    UpdateGraphicsOrder
} from '../../infra/requests/SettingRequests'
import Alert from "../../components/alert/Alert";
import { ColorBox, ColourInput } from '../../components/inputs/InputStyles';
import ColourInputComponent from '../../components/inputs/ColourInput';
import OrderGraphics from './OrderGraphics';
import { connect } from 'react-redux';

const RemoveMessage = (
    <div>
        <div>Tem a certeza que quer remover este grafismo? </div>
        <div>
            Toda a informação do grafismo será removida da plataforma e não
            conseguirá ser recuperada.
        </div>
    </div>
);

class GraphicsTable extends Component {
    state = {
        pageSize: 15,
        currentPage: 1,
        loading: false,
        search: '',
        editGraphic: null,

        columns: [
            {
                title: 'Nome',
                key: 'name',
                width: '150px',
                render: data => (
                    <Fragment>
                        {!this.isEditing(data) ? (<div>{data.name}</div>) :
                            (<div style={{ marginTop: '-30px' }}>
                                <TextInput
                                    input={{
                                        value: this.state.editGraphic.name,
                                        onChange: event => {
                                            let x = this.state.editGraphic;
                                            x.name = event.target.value;
                                            this.setState({ editGraphic: x })
                                        }
                                    }}
                                    meta={{}}
                                    type="text"
                                    placeholder="Insira o nome do grafismo"
                                />
                            </div>)
                        }
                    </Fragment>
                )
            },
            {
                title: 'Cor',
                key: 'color',
                width: '150px',
                render: data => (
                    <Fragment>
                        {!this.isEditing(data)
                            ? <ColorBox color={data?.graphicColor}></ColorBox>
                            : <div style={{ marginTop: '-10px' }}>
                                <ColourInputComponent
                                    input={{
                                        value: this.state.editGraphic.graphicColor,
                                        onChange: event => {
                                            let x = this.state.editGraphic;
                                            x.graphicColor = event;
                                            this.setState({ editGraphic: x })
                                        }
                                    }}
                                    meta={{}}
                                    placeholder="Selecione a cor"
                                />
                            </div>
                        }
                    </Fragment>
                )
            },
            {
                title: 'Acções',
                width: '120px',
                render: data => (
                    <Fragment>
                        {!this.isEditing(data) ? (<TableButton onClick={e => this.setEditForm(data)}>
                            <Icon type="edit" />
                            {' Editar'}
                        </TableButton>) : (<TableButton onClick={e => this.addEditGraphic()}>
                            <Icon type="save" />
                            {' Guardar'}
                        </TableButton>)
                        }

                        {!this.isEditing(data) ? (<TableButton onClick={e => e.stopPropagation()}>
                            <Popconfirm
                                placement="topRight"
                                title={RemoveMessage}
                                okText="Remover"
                                onConfirm={async () => { await this.deleteGraphic(data.id) }}>
                                <Icon type="delete" />
                                {' Remover'}
                            </Popconfirm>
                        </TableButton>) : (<TableButton onClick={e => {
                            if (this.state.editGraphic === null) {
                                let rows = this.state.rows.filter(f => f.id !== null);
                                this.setState({ editGraphic: null, rows: rows });
                            } else this.setState({ editGraphic: null });
                        }}>
                            <Icon type="close" />
                            {' Cancelar'}
                        </TableButton>)
                        }
                    </Fragment>
                )
            }
        ],
        rows: [],
        total: 0,

        orderModal: false,
        savingOrder: false
    };

    componentDidMount = async () => {
        await this.updateDataTable();
    };

    isEditing = (graphic) => {
        const { editGraphic } = this.state;
        return editGraphic ? editGraphic.id === graphic.id : false;
    }

    /**
     * @description Update data on datatable
     */
    updateDataTable = async () => {
        this.setState({ loading: true });
        try {
            const result = await GetSettingsByType(6);

            this.setState({
                rows: result.success && result.data ? result.data : [],
                total: result.success && result.data ? result.data.length : 0,
                loading: false
            });
        } catch (e) {
            this.setState({ loading: false });
            console.error(e);
        }
    }

    /**
     * @description Show editing row with graphic values
     * @param {*} graphic 
     */
    setEditForm = (graphic) => {
        let x = graphic ? JSON.parse(JSON.stringify(graphic)) : {
            name: ''
        };
        this.setState({ editGraphic: x });
    }

    /**
    * @description Show add row
    */
    setAddForm = () => {
        const { editGraphic, rows } = this.state;

        const graphic = {
            id: null,
            name: '',
            entity: false,
            paymentMethod: false,
            invoicingType: false,
            priceUnits: false,
            graphics: true,
            graphicColor: '',
            graphicOrder: rows.length
        };
        if (!editGraphic) {
            rows.push(graphic);
            this.setState({ rows: rows, editGraphic: graphic });
        }
    }

    /**
     * @description Create/Update graphic
     * @returns datatable graphics updated
     */
    addEditGraphic = async () => {
        const { editGraphic } = this.state;

        if (!editGraphic?.name || editGraphic?.name?.trim() === '') {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Deve inserir o nome do grafismo'
            });
        }

        if (editGraphic?.id === null) {
            await CreateSetting(editGraphic);
            this.setState({ editGraphic: null });
            return this.updateDataTable();
        } else {
            await UpdateSetting(editGraphic);
            this.setState({ editGraphic: null });
            return this.updateDataTable();
        }
    }

    /**
     * @description Delete graphic
     * @param {*} id - graphic id
     * @returns datatable unit updated
     */
    deleteGraphic = async id => {
        await DeleteSetting(id);
        return this.updateDataTable();
    }

    openOrderModal = () => {
        this.setState({ orderModal: true });
    }

    closeOrderModal = () => {
        this.setState({ orderModal: false });
    }

    onSubmitOrder = async values => {
        try {
            this.setState({ savingOrder: true });

            if (values.length > 0) {
                let orderedList = [];
                Object.keys(values).forEach(function (key) {
                    let obj = { _id: values[key]._id, order: key };
                    orderedList.push(obj);
                });

                if (orderedList && Array.isArray(orderedList) && orderedList.length > 0) {
                    const res = await UpdateGraphicsOrder({ order: orderedList });

                    if (res?.success) {
                        this.updateDataTable();
                    }
                }
            }
            this.setState({ savingOrder: false });
            this.closeOrderModal();
        } catch (e) {
            this.setState({ savingOrder: false });
        }
    }

    render() {
        const { loading, savingOrder } = this.state;
        const { columns, rows, total, search } = this.state;
        const { orderModal } = this.state;

        return (
            <React.Fragment>
                <SectionTitle style={{ marginTop: '3rem' }} >Grafismos</SectionTitle>

                <TableFilterSection>
                    <Row gutter={24}>
                        <Col lg={8} md={8} sm={12} xs={24}>
                            <div className='settings-search'>
                                <TextInput
                                    type="text"
                                    label="Pesquisa"
                                    placeholder="Pesquise por grafismo"
                                    input={{
                                        value: search,
                                        onChange: event => this.setState({ search: event.target.value })
                                    }}
                                    meta={{}}
                                />
                            </div>
                        </Col>

                        <Col lg={16} md={16} sm={12} xs={24} className='graphic-col-btn'>
                            <div style={{ display: 'flex' }}>
                                <BaseButton
                                    type={'default'}
                                    icon={'retweet'}
                                    text={'Editar Ordem'}
                                    onClick={() => this.openOrderModal()}
                                />
                                <span style={{ marginLeft: 5 }}>
                                    <BaseButton
                                        type={'primary'}
                                        icon={'plus'}
                                        text={'Adicionar Grafismo'}
                                        onClick={() => this.setAddForm()}
                                    />
                                </span>
                            </div>
                        </Col>
                    </Row>
                </TableFilterSection>

                <div className='table-responsive'>
                    <Table
                        columns={columns}
                        loading={loading}
                        rows={search.trim() === "" ? rows : rows.filter(f => f?.name.toLowerCase().includes(search.toLowerCase()))}
                        showHeader={true}
                        emptyIcon="setting"
                        emptyText={'Não existem entidades'}
                        total={search.trim() === "" ? total : rows.filter(f => f?.name.toLowerCase().includes(search.toLowerCase())).length}
                        rowKey={'_id'}
                        hasPagination={false}
                    />
                </div>

                {
                    orderModal &&
                    <OrderGraphics
                        open={orderModal}
                        loading={savingOrder}
                        onSubmit={this.onSubmitOrder}
                        closeModal={this.closeOrderModal}
                        initialValues={rows}
                    />
                }
            </React.Fragment>
        );
    }
}

export default withLocalize(GraphicsTable);