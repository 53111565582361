import React, { useState } from 'react';
import { Modal, notification } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { BasicInfoSection, SectionTitle, SectionCTA } from '../ProcessStyles';
import { UpdatePrivateNotes } from '../../../infra/requests/WeddingProcessRequests';
import { SavePrivateNotes } from '../../../redux/wedding/wedding.actions';
import { BaseButton } from '../../../components/buttons/BaseButton';
import PrivateNotesModalComponent from './PrivateNotesModalComponent';
import SectionPrivateNotes from './SectionPrivateNotes';
import { getPrintedConfirmation } from '../../../infra/services/wedding/printedUtils';

const { confirm } = Modal;

const PrivateNotesComponent = ({
  weddingInfo,
  dispatch,
  SavePrivateNotes,
  canEdit = true,
  insideTable = false,
}) => {
  const [showModal, toggleModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    toggleModal(false);
  };

  const openModal = () => {
    dispatch(initialize('private_notes_modal_form', { private_notes: weddingInfo?.private_notes }));
    toggleModal(true);
  };

  const onModalSubmit = async values => {
    if (weddingInfo?.printed) {
      confirm({
        ...getPrintedConfirmation(),
        onOk() { updateNotes(values); },
        onCancel() { closeModal(); },
      });
    } else updateNotes(values);
  };

  const updateNotes = async (values) => {
    try {
      setLoading(true);

      const { success, data } = await UpdatePrivateNotes(weddingInfo._id, values);

      if (success) {
        notification.success({
          message: 'Gravado com sucesso',
          description: 'A informação foi gravada com sucesso!'
        });

        SavePrivateNotes(data.private_notes);
      }

      setLoading(false);
      closeModal();
    } catch (e) {
      console.error(e);
      setLoading(false);
      closeModal();
    }
  }

  return (
    <BasicInfoSection>
      <SectionTitle darkBg={true} insideTable={insideTable}>
        Pendentes e Orçamento
        {
          canEdit &&
          <SectionCTA>
            <BaseButton
              type={'default'}
              icon={'arrow-right'}
              text={'Editar'}
              onClick={() => openModal()}
            />
          </SectionCTA>
        }
      </SectionTitle>
      <SectionPrivateNotes note={weddingInfo?.private_notes} />
      {
        showModal &&
        <PrivateNotesModalComponent
          open={showModal}
          onSubmit={onModalSubmit}
          loading={loading}
          closeModal={closeModal}
        />
      }
    </BasicInfoSection>
  );
};

const mapActionToProps = dispatch => bindActionCreators({ SavePrivateNotes: SavePrivateNotes, dispatch }, dispatch);

export default connect(null, mapActionToProps)(PrivateNotesComponent);