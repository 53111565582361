import styled from 'styled-components';
import { device } from '../../styles/Responsive';

export const SelectContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 30px;

  @media ${device.tablet} {
    max-width: 100%;
  }
`;

export const NewOptionContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  display: ${p => p.showNew ? 'block' : 'none'};
  position: relative;

  & div {
    margin-top: 0px;
    height: 40px;
  }

  & input {
    margin-top: 0px;
    height: 40px;
    padding-right: 30px;
  }

  @media ${device.tablet} {
    margin-left: 0px;
    margin-top: 15px;
    max-width: 100%;
  }
`;