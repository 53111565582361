import TranslateValue from "../translations/TranslateValue";

export const checkQuestionWithSidedishes = (question) => {
    return question && (question.type === 13 || question.type === 14)
        ? true
        : false;
}

export const hasSidedishes = (question, option) => {
    if (checkQuestionWithSidedishes(question)) {
        return option.sidedishes && option.sidedishes.length > 0 ? true : false;
    } else return false;
}

export const checkSidedishesOriginal = (foodSidedishes, answerSidedishes) => {
    const foodSide = foodSidedishes && foodSidedishes.length > 0
        ? foodSidedishes[0] && foodSidedishes[0]._id
            ? foodSidedishes.map(m => m._id)
            : foodSidedishes
        : [];

    const answerSide = answerSidedishes && answerSidedishes.length > 0
        ? answerSidedishes[0] && answerSidedishes[0]._id
            ? answerSidedishes.map(m => m._id)
            : answerSidedishes
        : [];

    return !answerSidedishes
        ? false
        : areEqual(foodSide, answerSide) ? false : true;
}

const areEqual = (array1, array2) => {
    if (array1.length === array2.length) {
        return array1.every(element => {
            if (array2.includes(element)) {
                return true;
            }

            return false;
        });
    }

    return false;
}

export const getPlateSidedishesName = (plateSidedishes, sidedishesFood) => {
    const sidedishes = plateSidedishes && plateSidedishes.length > 0 && plateSidedishes[0]?._id
        ? plateSidedishes
        : sidedishesFood && sidedishesFood.length > 0
            ? plateSidedishes.map(s => sidedishesFood.find(side => side._id === s))
            : [];

    const sidedishesName = sidedishes.length > 0
        ? sidedishes.map(m => m?.name ? TranslateValue(m.name) : '')
        : [];

    const lastSidedishe = sidedishesName.length > 1
        ? sidedishesName[sidedishesName.length - 1]
        : null;

    if (sidedishesName.length > 1) sidedishesName.splice(sidedishesName.length - 1, 1);

    return plateSidedishes.length > 0
        ? lastSidedishe
            ? `, ${sidedishesName.toString().toLowerCase().replace(/,/g, ', ')} e ${lastSidedishe?.toLowerCase()}`
            : `, ${sidedishesName.toString().toLowerCase().replace(/,/g, ', ')}`
        : '';
}

export const getTranslationPlateSidedishesName = (languageCode, plateSidedishes, sidedishesFood) => {
    const sidedishes = plateSidedishes && plateSidedishes.length > 0 && plateSidedishes[0]?._id
        ? plateSidedishes
        : sidedishesFood && sidedishesFood.length > 0
            ? plateSidedishes.map(s => sidedishesFood.find(side => side._id === s))
            : [];

    const sidedishesName = sidedishes.length > 0
        ? sidedishes.map(m => m?.name ? m.name[languageCode] : '')
        : [];

    const lastSidedishe = sidedishesName.length > 1
        ? sidedishesName[sidedishesName.length - 1]
        : null;

    if (sidedishesName.length > 1) sidedishesName.splice(sidedishesName.length - 1, 1);

    const andTxt = languageCode === 'en'
        ? 'and'
        : languageCode === 'fr'
            ? 'et'
            : languageCode === 'en'
                ? 'y'
                : 'e';

    return plateSidedishes.length > 0
        ? lastSidedishe
            ? `, ${sidedishesName.toString().toLowerCase().replace(/,/g, ', ')} ${andTxt} ${lastSidedishe.toString().toLowerCase()}`
            : `, ${sidedishesName.toString().toLowerCase().replace(/,/g, ', ')}`
        : '';
}

export const getEndSidedishesName = (sidedishes) => {
    const sidedishesName = sidedishes && sidedishes.length > 0
        ? sidedishes.map(m => TranslateValue(m.name))
        : [];

    const lastSidedishe = sidedishesName.length > 1
        ? sidedishesName[sidedishesName.length - 1]
        : null;

    if (sidedishesName.length > 1) sidedishesName.splice(sidedishesName.length - 1, 1);
    return sidedishes.length > 0
        ? lastSidedishe
            ? `, ${sidedishesName.toString().toLowerCase().replace(/,/g, ', ')} e ${lastSidedishe?.toLowerCase()}`
            : `, ${sidedishesName.toString().toLowerCase().replace(/,/g, ', ')}`
        : '';
}

export const checkExtraCost = (food, sidedishes) => {
    // const sidedishesList = sidedishes && sidedishes.length > 0
    //     ? sidedishes
    //     : [];

    const extraCost = getFoodExtraCost(food, sidedishes);
    return extraCost > 0 ? true : false;
}

export const getFoodExtraCost = (food, sidedishes) => {
    const sidedishesList = sidedishes && sidedishes.length > 0
        ? sidedishes
        : [];

    let extraCost = food && food.extra_cost ? food.extra_cost : 0;

    if (sidedishesList && sidedishesList.length > 0 && sidedishesList.find(f => f?.extra_cost)) {
        for (let index = 0; index < sidedishesList.length; index++) {
            const sidedishe = sidedishesList[index];
            const originalSidedishe = food && food.sidedishes && food.sidedishes.length > 0 && food.sidedishes.find(f => f._id === sidedishe._id);
            // console.warn('originalSidedishe', originalSidedishe);
            if (!originalSidedishe && sidedishe && sidedishe.extra_cost) {
                extraCost = extraCost + sidedishe.extra_cost;
            }
        }
    }
    return extraCost;
}