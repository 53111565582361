import React, {Component} from 'react';
import {Row, Col} from 'antd';
import {Field, reduxForm, initialize} from 'redux-form';
import {withLocalize} from 'react-localize-redux';
import { Prompt } from "react-router";
import {
  GetSupplier,
  CreateSupplier,
  UpdateSupplier,
  GetAllSupplierCategories
} from '../../infra/requests/SupplierRequests';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import BaseButton from '../../components/buttons/BaseButton';
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';

const validations = FormValidator.make({
  name: 'required',
  category: 'required',
  email: 'required|email'
});

class ManageCategoryPage extends Component {
  state = {
    loading: true,
    categories: [],
    isNew: true
  };

  componentDidMount = async () => {
    const {
      match: {params},
      dispatch
    } = this.props;
    let isNew = true;

    const result = await GetAllSupplierCategories();

    if (params.id) {
      isNew = false;
      const {data} = await GetSupplier(params.id);
      dispatch(
        initialize('manage_supplier_form', {
          ...data,
          category: data?.category?._id
        })
      );
    } else {
      dispatch(initialize('manage_supplier_form', {}));
    }
    this.setState({isNew, loading: false, categories: result.data || []});
  };

  onSubmit = async values => {
    try {
      this.setState({loading: true});
      const {
        match: {params},
        history
      } = this.props;

      const {success} = params.id
        ? await UpdateSupplier(params.id, values)
        : await CreateSupplier(values);
      if (success) return history.push('/suppliers');
      return this.setState({loading: false});
    } catch (e) {
      this.setState({loading: false});
    }
  };

  handleOnCancel = async () => {
    const {history} = this.props;
    return history.push('/suppliers');
  };

  render() {
    const {handleSubmit, dirty} = this.props;
    const {loading, categories, isNew} = this.state;
    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Fornecedor de Encomendas</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Prompt
              when={dirty}
              message='Está prestes a sair sem guardar as alterações. Deseja continuar?'
            />
            <Row style={{ display: 'grid', justifyItems: 'center' }}>
              <Col xs={24} sm={18} md={15} lg={10}>
                <Field
                  component={SelectInput}
                  disabled={!isNew}
                  name={'category'}
                  label={'Categoria'}
                  placeholder={'Categoria'}
                  data={categories}
                />
                <Field
                  component={TextInput}
                  name={'name'}
                  type="text"
                  label={'Nome'}
                  placeholder={'Nome do Fornecedor'}
                />
                <Field
                  component={TextInput}
                  name={'email'}
                  type="email"
                  label={'Email'}
                  placeholder={'Email do Fornecedor'}
                />
                <Field
                  component={TextInput}
                  name={'contact'}
                  type="text"
                  label={'Contacto'}
                  placeholder={'Contacto do Fornecedor'}
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageCategoryPage = reduxForm({
  form: 'manage_supplier_form',
  validate: validations
})(ManageCategoryPage);

export default withLocalize(ManageCategoryPage);
