import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Row, Col, Pagination, Icon, Popconfirm, message} from 'antd';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import {
  GetInspirations,
  DeleteInspirationById
} from '../../infra/requests/InspirationsRequest';
import ImageComponent from '../../components/images/ImageComponent';
import BaseButton from '../../components/buttons/BaseButton';
import {
  ImageHover,
  Title,
  TagsContainer,
  ActionsSection
} from './InspirationsStyles';
import {GetTags} from '../../infra/requests/InspirationsRequest';
import InspirationsFilters from './InspirationsFilters';

class InspirationsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    tags: [],
    filters: '',
    rows: [],
    total: 0
  };

  filterRef = React.createRef();

  componentDidMount = async () => {
    await this.getTags();
    await this.updateDataTable();
  };

  getTags = async () => {
    const tags = await GetTags();
    this.setState({tags: tags.data.map(tag => ({name: tag}))});
  };

  handleChangePage = currentPage => {
    this.setState({currentPage}, this.updateDataTable);
  };

  updateDataTable = async () => {
    this.setState({loading: true});
    try {
      const {currentPage, pageSize, filters} = this.state;
      const result = await GetInspirations(currentPage, pageSize, filters);
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false
      });
    } catch (e) {
      this.setState({loading: false});
      console.error(e);
    }
  };

  deleteImage = async id => {
    try {
      await DeleteInspirationById(id);
      await this.updateDataTable();
      message.success('Imagem Eliminada');
    } catch (err) {
      console.error(err);
    }
  };

  handleFilterChange = filters => {
    this.setState({filters, currentPage: 1}, this.updateDataTable);
  };

  onPressTag = (e, tag) => {
    e.stopPropagation();
    if (this.filterRef?.current?.selectTag)
      this.filterRef.current.selectTag(tag);
  };

  onSearchRef = elem => (this.filterRef = elem);

  render() {
    const {history, categories} = this.props;
    const {rows, total, currentPage, pageSize, tags} = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Inspirações</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              block
              text={'Criar'}
              onClick={() => history.push('/inspirations/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={1}>
          <Row gutter={[12, 12]}>
            <Col xs={24}>
              <InspirationsFilters
                tags={tags}
                list={categories}
                ref={this.filterRef}
                queryChange={this.handleFilterChange}
              />
            </Col>
            {rows.map(inspiration => (
              <Col key={inspiration._id} xs={12} sm={8}>
                <ImageComponent
                  ratio={1}
                  url={inspiration?.image?.url}
                  color={inspiration?.image?.color}>
                  <ImageHover>
                    <Title>{inspiration.text}</Title>

                    <ActionsSection>
                      <Icon
                        type="edit"
                        onClick={() =>
                          history.push(`/inspirations/${inspiration._id}`)
                        }
                      />

                      <Popconfirm
                        title="Deseja apagar esta imagem?"
                        okText="Sim"
                        onConfirm={() => this.deleteImage(inspiration._id)}
                        cancelText="Não">
                        <Icon type="delete" />
                      </Popconfirm>
                    </ActionsSection>

                    <TagsContainer>
                      {inspiration.tags.map((tag, index) => (
                        <span
                          key={`${inspiration._id}_tag_${tag}_${index}`}
                          onClick={e => this.onPressTag(e, tag)}>
                          #{tag}
                        </span>
                      ))}
                    </TagsContainer>
                  </ImageHover>
                </ImageComponent>
              </Col>
            ))}
            <Col xs={24}>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
defaultPageSize={pageSize}
                total={total}
                onChange={this.handleChangePage}
              />
            </Col>
          </Row>
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.info.categories
});

export default connect(mapStateToProps)(InspirationsPage);
