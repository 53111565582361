import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ColorCard from './questionOptions/ColorCard';
import OptionCard from './questionOptions/OptionCard';
import TextCard from './questionOptions/TextCard';
import ListCard from './questionOptions/ListCard';
import FoodCard from './questionOptions/FoodCard';
import ImageCard from './questionOptions/ImageCard';
import FileCard from './questionOptions/FileCard';
import TranslateValue from '../../infra/services/translations/TranslateValue';

import { ResponseSection, OptionContainer, QuestionInfo, OptionsSubContainer, OptionFileContainer } from './SectionStyles';
import { Icon } from 'antd';

const ManageQuestionType = ({ input, meta, question, colors, food, wedding, editOption }) => {
  // console.warn('Type', question.type);
  switch (question.type) {
    case 1:
      return <TextCard component="Text" type="text" />;
    case 2:
      return <TextCard component="TextArea" type="text" />;
    case 3:
      return <TextCard component="Text" type="number" />;
    case 4:
      return (
        <ResponseSection>
          {question.options?.filter(option => !option.local || option.local === wedding.wedding_place).map((elem, index) => (
            <OptionContainer key={index}>
              <OptionsSubContainer>
                <OptionCard question={question} record={elem} input={input} />
              </OptionsSubContainer>
            </OptionContainer>
          ))}
        </ResponseSection>
      );
    case 5:
      return (
        <ResponseSection>
          {question.options?.filter(option => !option.local || option.local === wedding.wedding_place).map((elem, index) => (
            <OptionContainer key={index}>
              <OptionsSubContainer>
                <OptionCard question={question} record={elem} input={input} />
              </OptionsSubContainer>
            </OptionContainer>
          ))}
        </ResponseSection>
      );
    case 6:
      return (
        <ResponseSection>
          {question.options?.filter(option => !option.local || option.local === wedding.wedding_place).map((elem, index) => (
            <OptionContainer key={index}>
              <OptionsSubContainer>
                <ListCard
                  type={1}
                  record={elem}
                  input={input}
                  question={question}
                />
              </OptionsSubContainer>
            </OptionContainer>
          ))}
        </ResponseSection>
      );
    case 7:
      return (
        <ResponseSection>
          <OptionFileContainer>
            <FileCard input={input} />
          </OptionFileContainer>
        </ResponseSection>
      );
    case 8:
      return (
        <ResponseSection>
          <OptionFileContainer>
            <ImageCard file={question?.answer?.response} input={input} />
          </OptionFileContainer>
        </ResponseSection>
      );
    case 10:
      return (
        <ResponseSection>
          <QuestionInfo>{TranslateValue(question.description)}</QuestionInfo>
          <OptionContainer>
            <ListCard
              input={input}
              type={2}
              record="true"
              text="Sim"
              question={question}
            />
          </OptionContainer>
          <OptionContainer>
            <ListCard
              input={input}
              type={2}
              record="false"
              text="Não"
              question={question}
            />
          </OptionContainer>
        </ResponseSection>
      );
    case 11:
      return (
        <ResponseSection isColor={true}>
          {colors?.map((elem, index) => (
            <OptionContainer key={index} style={{ display: 'inherit' }}>
              <ColorCard question={question} record={elem} input={input} />
            </OptionContainer>
          ))}
        </ResponseSection>
      );
    case 12:
      return (
        <ResponseSection>
          {food.entrees?.map((elem, index) => (
            <OptionContainer key={index}>
              <OptionsSubContainer>
                <FoodCard
                  question={question}
                  record={elem}
                  input={input}
                  options={food.entrees}
                />
              </OptionsSubContainer>
            </OptionContainer>
          ))}
        </ResponseSection>
      );
    case 13:
      return (
        <ResponseSection>
          {food.meat?.map((elem, index) => (
            <OptionContainer key={index}>
              <OptionsSubContainer>
                <FoodCard
                  question={question}
                  record={elem}
                  input={input}
                  options={food.meat}
                  sidedishes={food.sidedishes}
                />
              </OptionsSubContainer>
            </OptionContainer>
          ))}
        </ResponseSection>
      );
    case 14:
      return (
        <ResponseSection>
          {food.fish?.map((elem, index) => (
            <OptionContainer key={index}>
              <OptionsSubContainer>
                <FoodCard
                  question={question}
                  record={elem}
                  input={input}
                  options={food.fish}
                  sidedishes={food.sidedishes}
                />
              </OptionsSubContainer>
            </OptionContainer>
          ))}
        </ResponseSection>
      );
    case 15:
      return (
        <ResponseSection>
          {food.dessert?.map((elem, index) => (
            <OptionContainer key={index}>
              <OptionsSubContainer>
                <FoodCard
                  question={question}
                  record={elem}
                  input={input}
                  options={food.dessert}
                />
              </OptionsSubContainer>
            </OptionContainer>
          ))}
        </ResponseSection>
      );
    case 16:
      return (
        <ResponseSection>
          {food.cake?.map((elem, index) => (
            <OptionContainer key={index}>
              <OptionsSubContainer>
                <FoodCard
                  question={question}
                  record={elem}
                  input={input}
                  options={food.cake}
                />
              </OptionsSubContainer>
            </OptionContainer>
          ))}
        </ResponseSection>
      );
    case 17:
      return (
        <ResponseSection>
          {food.supper?.map((elem, index) => (
            <OptionContainer key={index}>
              <OptionsSubContainer>
                <FoodCard
                  question={question}
                  record={elem}
                  input={input}
                  options={food.supper}
                />
              </OptionsSubContainer>
            </OptionContainer>
          ))}
        </ResponseSection>
      );
    default:
      return null;
  }
};

ManageQuestionType.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  wedding: state.wedding.wedding,
  colors: state.info.colours,
  food: state.info.food
});

export default connect(mapStateToProps)(ManageQuestionType);
