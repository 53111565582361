import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import configureStore, {history} from './redux/ConfigStore';
import startThunk from './redux/thunks/StartThunk';
import {LocalizeProvider} from 'react-localize-redux';
import client from './infra/config/AxiosConfig';
import Theme from './styles/Theme';
import {ThemeProvider} from 'styled-components';
import {unregister} from './serviceWorker';
unregister();

const store = configureStore();
client.setupInterceptors(store);
store.dispatch(startThunk());

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <LocalizeProvider store={store}>
        <ThemeProvider theme={Theme}>
          <App />
        </ThemeProvider>
      </LocalizeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
