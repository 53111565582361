import client from '../config/AxiosConfig';

export const CreatePayment = async (data) =>
  client.put('/payment/create', data);

export const UpdateStatusAndPaymentAccount = async (data) =>
  client.post('/payment/updateStatusAndPaymentAccount', data);

export const UpdateStatusAndEndDate = async (data) =>
  client.post('/payment/updateStatusAndEndDate', data);

export const GetPaymentsBywedding = async (weddingId) =>
  client.get(`/payment/findByWedding/${weddingId}`);

export const GetPaymentsPaginate = async (currentPage, limit, filters) =>
  client.get(`/payment/${currentPage}/${limit}?filter=${filters}`);

export const GetPaymentsGrouped = async (status, approved) =>
  client.get(`/payment/findAllGrouped/${status}/${approved}`);

export const GetPaymentsByEmployeePaginate = async (currentPage, limit, filters) =>
  client.get(`/payment/paginateByEmployee/${currentPage}/${limit}?filter=${filters}`);

export const GetPaymentsByEmployee = async (employeeId, entity, filters) =>
  client.get(`/payment/findByEmployee/${employeeId}/${entity}?filter=${filters}`);

export const GetPaymentsByEmployeeGroupedByAccount = async (employeeId, status) =>
  client.get(`/payment/findByEmployeeGroupedByAccount/${employeeId}/${status}`);

export const GetPaymentsByEmployeeAndPaymentAccount = async (employeeId, paymentAccountId, status) =>
  client.get(`/payment/findByEmployeeAndPaymentAccount/${employeeId}/${paymentAccountId}/${status}`);

export const CreatePaymentsByWedding = async (weddingId) =>
  client.post(`/payment/createWeddingPayments/${weddingId}`, {});

export const UndoPaymentsByWedding = async (weddingId) =>
  client.post(`/payment/undoWeddingPayments/${weddingId}`, {});

export const GetPaymentsToExport = async (filters = {}) =>
  client.get(`/payment/findAllToExport?filter=${JSON.stringify(filters)}`);

export const DownloadPendingPayments = async (filters, data = {}) => {
  return client.post(`/payment/exportPending?filter=${filters}`, {}, {
    responseType: 'blob'
  }).then(response => {
    return response;
  })
}

export const DownloadPendingApprovedPayments = async (filters, data = {}) => {
  return client.post(`/payment/exportPendingApproved?filter=${filters}`, {}, {
    responseType: 'blob'
  }).then(response => {
    return response;
  })
}