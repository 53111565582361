import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { TimePicker, Icon, Col, Row } from 'antd';
import {
  SpinLoading,
  SectionTitle,
  TableButton,
  HeaderButtonsContainer,
} from '../../styles/BasicStyles';
import SelectInput from '../../components/inputs/SelectInput';
import Table from '../../components/table/Table';
import NumberInput from '../../components/inputs/NumberInput';
import BaseButton from '../../components/buttons/BaseButton';
import { GetCategories } from '../../infra/requests/CategoryRequests';
import { getQuantityBySubcategory, getStartTimeBySubcategory } from '../../infra/services/wedding/weddingUtils';
import { GetTeamBookingRowsByWedding } from '../../infra/requests/TeamBookingRequests';

class AddEditTeamBooking extends Component {
  state = {
    pageSize: 15,
    currentPage: 1,
    loading: true,
    currentUser: null,
    selectedWedding: null,
    pax: 0,
    adults: 0,
    tables: 0,
    ourDay: [],
    scheduler: null,
    teamBooking: null,
    addEditBooking: [],
    deleteBooking: [],
    categories: [],
    payments: null,
    showEmployee: null,

    columns: [
      {
        title: 'Categoria',
        key: 'category',
        width: '100px',
        render: data => (
          <SelectInput
            allowClear
            placeholder="Escolher a categoria"
            disabled={this.state.payments.length > 0} // || this.state.showEmployee
            data={this.state.categories}
            input={{
              value: data.category?.id,
              onChange: async event => {
                let { rows, categories, addEditBooking } = this.state;

                const category = categories.find(f => f.id === event);
                let subcategories = category ? category.subcategories : [];

                if (category) {
                  const rowsSameCategory = rows.filter(f => f?.category?.id && category?.id && f.category.id === category.id);
                  rowsSameCategory.forEach(row => {
                    if (row?.subcategory?.id) {
                      subcategories = subcategories.filter(f => row?.subcategory?.id && f.id !== row.subcategory.id);
                    }
                  });
                }

                data.category = category;
                data.startTime = category ? category.startTime : moment();
                data.subcategories = subcategories;
                data.subcategory = null;

                const index = rows.findIndex(f => f.id === data.id);
                rows[index] = data;

                const indexAddEdit = addEditBooking.findIndex(f => f.id === data.id);
                if (indexAddEdit > -1) addEditBooking[indexAddEdit] = data;
                else addEditBooking.push(data);

                this.setState({ rows, addEditBooking });
                this.props.addEditBooking(addEditBooking);
              }
            }}
            meta={{
              invalid: !data?.category || data?.category == '' ? true : false,
              submitFailed: !data?.category || data?.category == '' ? true : false,
            }}
          />
        )
      },
      {
        title: 'Subcategoria',
        key: 'susubcategory',
        width: '120px',
        render: data => (
          <SelectInput
            allowClear
            placeholder="Escolher a subcategoria"
            disabled={this.state.payments.length > 0} // || this.state.showEmployee
            data={data.subcategories}
            input={{
              value: data.subcategory?.name,
              onChange: event => {
                const { pax, adults, tables } = this.state;
                let { rows, addEditBooking, categories, scheduler } = this.state;
                const dataOriginal = JSON.parse(JSON.stringify(data));
                const index = rows.findIndex(f => f.id === data.id);

                if (dataOriginal.category && dataOriginal.subcategory) {
                  const rowsSameCategory = rows.filter(f => f.category.id === dataOriginal.category.id);
                  rowsSameCategory.forEach(row => {
                    const indexRow = rows.findIndex(f => f.id === row.id);
                    const indexSub = row.subcategories.findIndex(f => f.id === dataOriginal.subcategory.id);
                    if (indexSub === -1) rows[indexRow].subcategories.push(dataOriginal.subcategory);
                  });
                }

                data.subcategory = rows[index].subcategories.find(f => f.id === event);

                if (data.subcategory) {
                  const rowsSameCategory = rows.filter(f => f.category.id === data.category.id);
                  rowsSameCategory.forEach(row => {
                    const indexRow = rows.findIndex(f => f.id === row.id);
                    rows[indexRow].subcategories = rows[indexRow].subcategories.filter(f => f.id !== data.subcategory.id);
                  });

                  if (data.subcategory.timeRule && data.subcategory.timeRule !== 0.00 && data.subcategory.weddingTimeRule) {
                    const startTime = getStartTimeBySubcategory(data.subcategory, scheduler);
                    if (startTime) data.startTime = startTime;
                  }

                  //Calculate quantities automatically based on subcategory, pax and tables number
                  data.quantity = getQuantityBySubcategory(data.subcategory, pax, adults, tables);
                }

                rows[index] = data;

                const indexAddEdit = addEditBooking.findIndex(f => f.id === data.id);
                if (indexAddEdit > -1) addEditBooking[indexAddEdit] = rows[index];
                else addEditBooking.push(data);

                this.setState({ rows, addEditBooking, categories });
                this.props.addEditBooking(addEditBooking);
              }
            }}
            meta={{
              invalid: !data?.subcategory || data?.subcategory == '' ? true : false,
              submitFailed: !data?.subcategory || data?.subcategory == '' ? true : false,
            }}
          />
        )
      },
      {
        title: 'Horário de Entrada',
        width: '100px',
        render: data => (
          <div className="sc-dxgOiQ fWHHkk">
            <TimePicker
              type="time"
              format='HH:mm'
              value={data.startTime ? moment(data.startTime).local() : null}
              disabled={this.state.payments.length > 0} // || this.state.showEmployee
              onChange={event => {
                // console.warn('evt', event);
                let { rows, addEditBooking } = this.state;
                const index = rows.findIndex(f => f.id === data.id);
                rows[index].startTime = event ? event.utc() : null;

                const indexAddEdit = addEditBooking.findIndex(f => f.id === data.id);
                if (indexAddEdit > -1) addEditBooking[indexAddEdit] = rows[index];
                else addEditBooking.push(data);

                this.setState({ rows, addEditBooking });
                this.props.addEditBooking(addEditBooking);
              }}
              style={{
                width: '100%',
                border: !data?.startTime || data?.startTime == '' ? '1px solid red' : '',
                borderRadius: '4px'
              }}
            />
          </div>
        )
      },
      {
        title: 'Quantidade',
        key: 'quantity',
        width: '100px',
        render: data => (
          <div className="sc-dxgOiQ fWHHkk" key={data.id}>
            <NumberInput
              min={0}
              onInput="validity.valid || (value = '')"
              disabled={this.state.payments.length > 0} // || this.state.showEmployee
              step={1}
              input={{
                value: data.quantity,
                onChange: event => {
                  let { rows, addEditBooking } = this.state;
                  const index = rows.findIndex(f => f.id === data.id);
                  rows[index].quantity = event;

                  const indexAddEdit = addEditBooking.findIndex(f => f.id === data.id);
                  if (indexAddEdit > -1) addEditBooking[indexAddEdit] = rows[index];
                  else addEditBooking.push(data);

                  this.setState({ rows, addEditBooking });
                  this.props.addEditBooking(addEditBooking);
                }
              }}
              meta={{
                invalid: !data?.quantity || data?.quantity == '' || data?.quantity <= 0 ? true : false,
                submitFailed: !data?.quantity || data?.quantity == '' || data?.quantity <= 0 ? true : false
              }}
              type="number"
              placeholder="Insira a quantidade"
            />
          </div>
        )
      },
      {
        title: 'Acções',
        width: '120px',
        render: data => ( // || this.state.showEmployee
          this.state.payments.length > 0 ? null : <Fragment>
            <TableButton onClick={() => {
              let { rows, deleteBooking, addEditBooking, categories } = this.state;
              if (!data.id.includes('new#FFSDD')) deleteBooking.push(data);

              // If selected subcategory, then add to subcategory list inside category and add to same categories rows
              if (data?.subcategory && data?.subcategory !== null) {
                const indexCategory = categories.findIndex(f => data?.category?.id && f.id === data.category.id);
                categories[indexCategory].subcategories.push(data.subcategory);

                const rowsSameCategory = rows.filter(f => f?.category?.id && data?.category?.id && f.category.id === data.category.id);
                rowsSameCategory.forEach(row => {
                  const indexRow = rows.findIndex(r => row?.id && r?.id && r.id === row.id);
                  if (indexRow > -1) rows[indexRow].subcategories.push(data.subcategory);
                });
              }

              addEditBooking = addEditBooking.filter(f => f.id !== data.id);

              rows = rows.filter(f => f.id !== data.id);
              this.setState({ rows, deleteBooking, addEditBooking, categories });
              this.props.addEditBooking(addEditBooking);
              this.props.deleteBooking(deleteBooking);
            }}>
              <Icon style={{ marginRight: 5 }} type="delete" />
              Remover
            </TableButton>
          </Fragment>
        )
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    this.setState({
      currentUser: this.props.currentUser,
      selectedWedding: this.props.selectedWedding,
      pax: this.props.pax,
      adults: this.props.adults,
      tables: this.props.tables,
      ourDay: this.props.ourDay,
      scheduler: this.props.scheduler,
      payments: this.props.payments,
      showEmployee: this.props.showEmployee,
      rows: this.props.teamBooking ? this.props.teamBooking : [],
      loading: false
    }, () => {
      this.formatRows();
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.selectedWedding !== this.props.selectedWedding) {
      this.setState({
        currentUser: this.props.currentUser,
        selectedWedding: this.props.selectedWedding,
        pax: this.props.pax,
        adults: this.props.adults,
        tables: this.props.tables,
        ourDay: this.props.ourDay,
        scheduler: this.props.scheduler,
        payments: this.props.payments,
        showEmployee: this.props.showEmployee,
        rows: this.props.teamBooking ? this.props.teamBooking : [],
        loading: false
      }, () => {
        this.formatRows();
      });
    }

    if (prevProps.showEmployee !== this.props.showEmployee) {
      this.setState({
        showEmployee: this.props.showEmployee
      });
    }
    if (prevProps.payments !== this.props.payments) {
      this.setState({
        payments: this.props.payments
      });
    }
  }

  formatRows = async () => {
    let { rows, selectedWedding, scheduler, pax, adults, tables } = this.state;

    const data = {
      wedding: selectedWedding,
      scheduler,
      pax,
      adults,
      tables
    };
    const result = await GetTeamBookingRowsByWedding(selectedWedding?._id, data);

    this.setState({
      categories: result.success && result.data ? result.data.categories : [],
      rows: result.success && result.data ? result.data.rows : [],
    });
  }

  addRowTeamBooking = () => {
    const { rows, total, selectedWedding, addEditBooking } = this.state;
    let showEmployee = rows.length > 0 && rows.filter(t => t.showEmployee).length > 0 ? true : false;

    const newTeamBooking = {
      id: 'new#FFSDD' + moment().valueOf(),
      startTime: moment(),
      quantity: 1,
      wedding: selectedWedding.id,
      subcategory: null,
      category: null,
      showEmployee: showEmployee,
      subcategories: []
    };

    addEditBooking.push(newTeamBooking);
    rows.push(newTeamBooking);
    this.setState({ rows: rows, total: rows.length, addEditBooking: addEditBooking });
    this.props.addEditBooking(addEditBooking);
  }

  render() {
    const { loading, currentPage, pageSize, payments, showEmployee } = this.state;
    const { columns, rows, total } = this.state;

    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        {/* <div className='booking-btn'>
          <BaseButton
            type={'primary'}
            icon={'plus'}
            disabled={payments.length > 0} // || showEmployee
            text={'Adicionar Linha'}
            onClick={() => this.addRowTeamBooking()}
          />
        </div> */}

        <Row gutter={24} style={{ marginTop: '2rem', marginBottom: '1rem' }}>
          <Col lg={12} md={12} sm={12} xs={24}>
            <SectionTitle>Encomenda da Equipa</SectionTitle>
          </Col>

          <Col lg={12} md={12} sm={12} xs={24} className='subcategory-col-btn'>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              disabled={payments.length > 0} // || showEmployee
              text={'Adicionar Linha'}
              onClick={() => this.addRowTeamBooking()}
            />
          </Col>
        </Row>

        <Table
          columns={columns}
          // currentPage={currentPage}
          // pageSize={pageSize}
          // defaultPageSize={pageSize}
          loading={loading}
          rows={rows}
          showHeader={true}
          emptyIcon="team"
          emptyText={'Ainda não foi definida a estrutura da equipa'}
          total={total}
          rowKey={'_id'}
          hasPagination={false}
        // handleChangePage={this.handleChangePage}
        // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        />

      </React.Fragment>
    );
  }
}

export default AddEditTeamBooking;
