import React, {Fragment, useState} from 'react';
import {connect} from 'react-redux';
import {Row, Col, Icon, Popconfirm} from 'antd';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {initialize, formValueSelector} from 'redux-form';
import BaseButton from '../../components/buttons/BaseButton';
import {
  SectionBox,
  SectionImage,
  DraftText,
  OptionsSelection,
  OptionButton
} from './UpgradesStyles';
import SectionModal from './SectionModal';
import {FlattenToFormData} from '../../infra/services/formdata/TransformToFormData';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import {
  SaveSection,
  EditSection,
  DeleteSection,
  OrderSections
} from '../../infra/requests/UpgradesRequests';

const ManageSections = ({fields, dispatch, upgrade, textColor, disabled}) => {
  const [openModal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const sections = fields.getAll() || [];

  const onOpen = (section = {type: 'text_right'}) => {
    dispatch(initialize('manage_upgrade_section_form', section));
    setModal(true);
  };

  const closeModal = () => {
    dispatch(initialize('manage_upgrade_section_form', {}));
    setModal(false);
  };

  const handleSave = async values => {
    setLoading(true);
    const payload = FlattenToFormData({...values, upgrade});
    if (values._id) {
      const {data, success} = await EditSection(values._id, payload);
      if (success) {
        const index = sections.findIndex(sec => sec._id === values._id);
        fields.remove(index);
        fields.insert(index, data);
      }
    } else {
      const {data, success} = await SaveSection(payload);
      if (success) fields.push(data);
    }
    dispatch(initialize('manage_upgrade_section_form', {}));
    setModal(false);
    setLoading(false);
  };

  const handleDelete = async id => {
    setLoading(true);
    const {success} = await DeleteSection(id);
    if (success) {
      const index = sections.findIndex(sec => sec._id === id);
      fields.remove(index);
    }
    setLoading(false);
  };

  const onDragEnd = async data => {
    if (data.destination && data.source.index !== data.destination.index) {
      let new_order = [...sections];
      const [removed] = new_order.splice(data.source.index, 1);
      new_order.splice(data.destination.index, 0, removed);

      fields.splice(data.source.index, 1);
      fields.splice(data.destination.index, 0, sections[data.source.index]);

      if (new_order && new_order.length) {
        setLoading(true);
        const orderedList = [];
        new_order.forEach((section, index) => {
          orderedList.push({_id: section._id, order: index});
        });
        await OrderSections({order: orderedList});
        
        setLoading(false);
      }
    }
  };

  return (
    <Fragment>
      <DragDropContext onDragEnd={data => onDragEnd(data)}>
        <Droppable droppableId="sections">
          {provided => (
            <div {...provided.props} ref={provided.innerRef}>
              {sections.map((section, index) => (
                <Draggable
                  key={index}
                  draggableId={`section_${index}`}
                  index={index}>
                  {provided => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      {provided.placeholder}
                      <SectionBox text={textColor} color={section.background}>
                        <Row
                          gutter={[24]}
                          type="flex"
                          justify="space-around"
                          align="middle">
                          <Col xs={4} />
                          <Col xs={7}>
                            {section.type === 'text_right' ? (
                              <SectionImage color={textColor}>
                                <img src={section?.image?.url} alt="img" />
                              </SectionImage>
                            ) : (
                              <DraftText
                                dangerouslySetInnerHTML={{
                                  __html: TranslateValue(section.text)
                                }}
                              />
                            )}
                          </Col>
                          <Col xs={2} />
                          <Col xs={7}>
                            {section.type === 'text_left' ? (
                              <SectionImage color={textColor}>
                                <img src={section?.image?.url} alt="img" />
                              </SectionImage>
                            ) : section.type === 'text_only' ? (
                              <DraftText
                                dangerouslySetInnerHTML={{
                                  __html: TranslateValue(section.extra)
                                }}
                              />
                            ) : (
                              <DraftText
                                dangerouslySetInnerHTML={{
                                  __html: TranslateValue(section.text)
                                }}
                              />
                            )}
                          </Col>
                          <Col xs={4} />
                        </Row>
                        {
                          !disabled &&
                          <React.Fragment>
                            <OptionButton number={0}>
                              <Popconfirm
                                placement="topRight"
                                title="Tem a certeza que quer apagar esta secção?"
                                onConfirm={() => handleDelete(section._id)}>
                                <Icon type="delete" />
                              </Popconfirm>
                            </OptionButton>
                            <OptionButton
                              number={1}
                              onClick={() => onOpen(section)}>
                              <Icon type="edit" />
                            </OptionButton>
                            <OptionButton number={2} {...provided.dragHandleProps}>
                              <Icon type="column-height" />
                            </OptionButton>
                          </React.Fragment>
                        }
                      </SectionBox>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {
        !disabled &&
        <OptionsSelection>
          <BaseButton
            type="primary"
            icon="plus"
            text="Nova secção"
            onClick={() => onOpen()}
          />

          <SectionModal
            open={openModal}
            loading={loading}
            closeModal={closeModal}
            onSubmit={handleSave}
          />
        </OptionsSelection>
      }
    </Fragment>
  );
};

const selector = formValueSelector('manage_upgrade_form');

const mapStateToProps = state => ({
  textColor: selector(state, 'textColor')
});

export default connect(mapStateToProps)(ManageSections);
