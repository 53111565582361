import React from 'react';
import moment from 'moment';
import 'moment/locale/pt';
import {
  getWeddingName,
  getLocal
} from '../../infra/services/wedding/weddingUtils';
import SectionNames from '../../infra/services/sections/SectionNames';

const LogsDetail = ({ data, insideWedding }) => {
  const renderUser = (user) => {
    if(user && user?.admin) return user.name;
    else if(user && !user?.admin) return `${user.name} (Noivos)`;
    else return '';
  };

  const renderSection = (data) => {
    if(data?.extraSection === 'upgrade') return 'Upgrade';
    else if(data?.extraSection === 'budget') return 'Orçamento';
    else if(data?.extraSection === 'process') return 'Processo';
    else return SectionNames[data?.section?.tag];
  };

  const renderChapter = (data) => {
    if(data?.extraChapter === 'payment') return 'Pagamentos Efectuados';
    else if(data?.extraChapter === 'expense') return 'Outros valores';
    else if(data?.extraChapter === 'offer') return 'Valores a descontar';
    else return data?.chapter?.name?.pt || '-';
  };

  return (
    <React.Fragment>
      {
        !insideWedding &&
        <div style={{ marginTop: '10px', marginBottom: '10px', fontSize: '15px', color: '#c0392b' }}>
          Casamento: <strong>{moment.utc(data?.wedding?.date).locale('pt').format('dddd, DD MMMM YYYY')} - {getLocal(data?.wedding)} - {getWeddingName(data?.wedding)}</strong>
        </div>
      }
      <div style={{ marginBottom: '10px', fontSize: '15px' }}>
        Data: <strong>{moment(data.createdAt).format('DD/MM/YYYY, HH:mm:ss')}</strong>
      </div>
      <div style={{ marginBottom: '10px', fontSize: '15px' }}>
        Utilizador: <strong>{renderUser(data?.user)}</strong>
      </div>
      <div style={{ marginBottom: '10px', fontSize: '15px' }}>
        Secção: <strong>{renderSection(data)}</strong>
      </div>
      <div style={{ marginBottom: '10px', fontSize: '15px' }}>
        Capítulo: <strong>{renderChapter(data)}</strong>
      </div>
      <div style={{ marginBottom: '10px', fontSize: '15px' }}>
        Pergunta: <strong>{data?.question?.title?.pt || '-'}</strong>
      </div>
      <div style={{ marginBottom: '10px', fontSize: '15px' }}>
        Descrição: <strong><span dangerouslySetInnerHTML={{__html: data?.description}}/></strong>
      </div>
      {
        data?.file &&
        <div style={{ marginBottom: '10px', fontSize: '15px' }}>
          Ficheiro: <strong><a href={`${process.env.REACT_APP_IMAGES_URL}${data?.file}`} target='_blank' rel="noopener noreferrer" style={{ color: '#0652DD' }}>Visualizar</a></strong>
        </div>
      }
      {
        data?.image &&
        <div style={{ marginBottom: '10px', fontSize: '15px' }}>
          Imagem: <strong><a href={data.image} target='_blank' rel="noopener noreferrer" style={{ color: '#0652DD' }}>Visualizar</a></strong>
        </div>
      }
    </React.Fragment>
  );
};

export default LogsDetail;