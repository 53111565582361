import React from 'react';
import EmptyIcon from '../../../assets/icons/ic_empty_answer.svg';
import {
  EmptySection,
  SectionIcon,
  BillingBox,
  BillingItem,
  OptionButton
} from './Styles';
import {Icon, Popconfirm} from 'antd';

const PrintedDelete = <div>
  <div>Tem a certeza que quer remover este registo?</div>
  <b>Este processo já foi impresso!</b>
</div>

const Delete = <div>
  <div>Tem a certeza que quer remover este registo?</div>
</div>

const Billings = ({billings = [], editBilling, deleteBilling, wedding}) => {
  if (!billings || (billings && billings.length < 1))
    return (
      <EmptySection>
        <SectionIcon src={EmptyIcon} alt="empty" />
        Ainda não foram adicionados dados de faturação
      </EmptySection>
    );
  return billings.map((record, index) => (
    <BillingBox key={index}>
      <Popconfirm
        title={wedding?.printed ? PrintedDelete : Delete}
        onConfirm={() => deleteBilling(index)}>
        <OptionButton number={0}>
          <Icon type="delete" />
        </OptionButton>
      </Popconfirm>
      <OptionButton number={1} onClick={() => editBilling(record, index)}>
        <Icon type="edit" />
      </OptionButton>
      {record.nif && (
        <BillingItem>
          NIF:<span>{record.nif}</span>
        </BillingItem>
      )}
      {record.name && (
        <BillingItem>
          Nome:<span>{record.name}</span>
        </BillingItem>
      )}
      {record.address && (
        <BillingItem>
          Morada:<span>{record.address}</span>
        </BillingItem>
      )}
      {record.observations && (
        <BillingItem>
          Observações:<span>{record.observations}</span>
        </BillingItem>
      )}
    </BillingBox>
  ));
};

export default Billings;
