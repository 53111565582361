import { checkSidedishesOriginal, getPlateSidedishesName, getTranslationPlateSidedishesName } from '../food/SidedishesUtils';
import {
  checkOption,
  checkFoodOption,
  checkColorOption,
  getFoodOption,
  getFoodOptionWithSidedishes
} from '../options/Options';

/*
 * Define a order for each question of Food (defined by client in ClickUp)
 * Entrada
 * 1º Prato: Peixe
 * Corta-Sabores
 * 2º Prato: Carne
 * Sobremesa
 * Café e digestivos
 * Buffets de frutas, queijos e doces
 * Bolo dos Noivos
 * Ceia
 * Menu kids: creme de legumes, mimo de vitela com legumes e chips, gelado
 */
const getOrderFood = questionID => {
  switch (questionID) {
    case '5e1e51316c30743b8429e562':
      return 1;
    case '5e1e53826c3074ded429e565':
      return 2;
    case '65a5483c6142f7d47e87b773':
      return 3;
    case '5e1e547e6c3074ee1a29e567':
      return 4;
    case '5e1e4f886c307440a529e55e':
      return 5;
    case '5e1e5d9f6c30745d9f29e579':
      return 10;
    case '5e1e50b06c307405e529e561':
      return 6;
    case '5e1eda586c30741f7b29e590':
      return 7;
    case '5e1ee48d6c3074b93a29e595':
      return 9;
    case '5e26e86511ad703d903609aa':
      return 8;
    default:
      return 99;
  }
};

const hasSidedishesOriginal = (answer) => {
  const foodSidedishes = answer?.food?.sidedishes && answer?.food?.sidedishes?.length > 0
    ? answer?.food?.sidedishes[0]?.id
      ? answer?.food?.sidedishes.map(m => m._id)
      : answer?.food?.sidedishes
    : [];
  const answerSidedishes = answer ? answer?.sidedishes && answer?.sidedishes?.length > 0
    ? answer.sidedishes
    : []
    : undefined;
  return checkSidedishesOriginal(foodSidedishes, answerSidedishes);
}

const getPlateSidedishes = (answer, sidedishes) => {
  const foodSidedishes = answer?.food?.sidedishes && answer?.food?.sidedishes?.length > 0
    ? answer.food.sidedishes
    : [];
  const answerSidedishes = answer && answer?.sidedishes && answer?.sidedishes?.length > 0
    ? answer.sidedishes
    : [];

  const plateSidedishes = answer && answer?.sidedishes
    ? answerSidedishes
    : foodSidedishes;

  return answer?.food?.name?.pt?.trim() + getPlateSidedishesName(plateSidedishes, sidedishes)?.trim();
}

const getTranslationPlateSidedishes = (answer, sidedishes) => {
  const foodSidedishes = answer?.food?.sidedishes && answer?.food?.sidedishes?.length > 0
    ? answer.food.sidedishes
    : [];
  const answerSidedishes = answer && answer?.sidedishes && answer?.sidedishes?.length > 0
    ? answer.sidedishes
    : [];

  const plateSidedishes = answer && answer?.sidedishes
    ? answerSidedishes
    : foodSidedishes;

  return {
    pt: answer?.food?.name?.pt?.trim() + getTranslationPlateSidedishesName('pt', plateSidedishes, sidedishes)?.trim(),
    en: answer?.food?.name?.en?.trim() + getTranslationPlateSidedishesName('en', plateSidedishes, sidedishes)?.trim(),
    fr: answer?.food?.name?.fr?.trim() + getTranslationPlateSidedishesName('fr', plateSidedishes, sidedishes)?.trim(),
    es: answer?.food?.name?.es?.trim() + getTranslationPlateSidedishesName('es', plateSidedishes, sidedishes)?.trim()
  };
}

export const findFoodAnswers = (section, food, sidedishes) => {
  let answers = [],
    question = undefined,
    hasAnswer = undefined,
    foodType = undefined;

  if (section.chapters.length > 0) {
    for (let i = 0; i < section.chapters.length; i++) {
      if (section.chapters[i].questions.length > 0) {
        for (let j = 0; j < section.chapters[i].questions.length; j++) {
          question = section.chapters[i].questions[j];

          /*
           * Entradas: 5e1e51316c30743b8429e562
           * 1º Prato Peixe: 5e1e53826c3074ded429e565
           * Corta-Sabores: 65a5483c6142f7d47e87b773
           * 2º Prato Carne: 5e1e547e6c3074ee1a29e567
           * Sobremesa: 5e1e4f886c307440a529e55e
           * Menu Infantil: 5e1e5d9f6c30745d9f29e579
           * Café & Digestivos: 5e1e50b06c307405e529e561
           * Buffet de Doces, Queijos e Frutas: 5e1eda586c30741f7b29e590
           * Ceia: 5e1ee48d6c3074b93a29e595
           * Bolo dos Noivos: 5e26e86511ad703d903609aa
           */
          if (
            question._id === '5e1e51316c30743b8429e562' ||
            question._id === '5e1e53826c3074ded429e565' ||
            question._id === '5efc5d210173c39c0aadc136' ||
            question._id === '5e1e547e6c3074ee1a29e567' ||
            question._id === '5e1e4f886c307440a529e55e' ||
            question._id === '5e1e5d9f6c30745d9f29e579' ||
            question._id === '5e1e50b06c307405e529e561' ||
            question._id === '5e1eda586c30741f7b29e590' ||
            question._id === '5e1ee48d6c3074b93a29e595' ||
            question._id === '5e26e86511ad703d903609aa' ||
            question._id === '65a5483c6142f7d47e87b773'
          ) {
            //Default: has no valid answer
            hasAnswer = '';

            //Corta-Sabores, we have to show the answer, description and observations
            // if (question?._id === '65a5483c6142f7d47e87b773') {
            //   // let answer = 'Sem resposta';
            //   // if (question.answer?.response?.length > 0) {
            //   //   if (question.answer.response[0]?.id == 'true') answer = 'Sim';
            //   //   else answer = 'Não';
            //   // }

            //   answers.push({
            //     label: question?.title?.pt || '',
            //     answer: null,
            //     title: question?.title || {},
            //     description: question?.description || {},
            //     // realAnswer: answer,
            //     showQuestion: true,
            //     observations: question.answer?.observations,
            //     order: getOrderFood(question?._id)
            //   });
            // }
            //Menu Infantil, we have to show a fixed title and observations
            if (question._id === '5e1e5d9f6c30745d9f29e579') {
              answers.push({
                label: 'Menu kids: creme de legumes, mimo de vitela com legumes e chips, gelado',
                answer: null,
                // title: question?.title,
                // description: question?.description,
                title: {
                  pt: 'Menu kids',
                  en: 'Menu kids',
                  es: 'Menu kids',
                  fr: 'Menu kids'
                },
                description: {
                  pt: 'Creme de legumes, mimo de vitela com legumes e chips, gelado',
                  en: 'Vegetable cream, veal treat with vegetables and chips, ice cream',
                  es: 'Crema de verduras, golosina de ternera con verduras y patatas fritas, helado',
                  fr: 'Crème végétale, gourmandise de veau aux légumes et frites, glace'
                },
                showQuestion: true,
                observations: question.answer?.observations,
                order: getOrderFood('5e1e5d9f6c30745d9f29e579')
              });
            }
            //Café & Digestivos OR Buffet de Doces, Queijos e Frutas OR Bolo dos Noivos we only show the question, with no observations
            else if (
              question._id === '5e1e50b06c307405e529e561' ||
              question._id === '5e1eda586c30741f7b29e590' ||
              question._id === '5e26e86511ad703d903609aa'
            ) {
              answers.push({
                label: question?.title?.pt || '',
                answer: null,
                title: question?.title || {},
                description: (question._id === '5e1e50b06c307405e529e561' ||
                  question._id === '5e1eda586c30741f7b29e590')
                  ? {}
                  : question?.description || {},
                showQuestion: false,
                observations: null,
                order: getOrderFood(question._id)
              });
            }
            //Other questions, show the answer (if it exists)
            else {
              if (
                question.answer &&
                question.answer.response &&
                question.answer.response.length > 0
              ) {
                foodType = undefined;
                if (
                  question.type === 4 ||
                  question.type === 5 ||
                  question.type === 6
                )
                  foodType = question.options;
                else if (question.type === 12) foodType = food.entrees;
                else if (question.type === 13) foodType = food.meat;
                else if (question.type === 14) foodType = food.fish;
                else if (question.type === 15) foodType = food.dessert;
                else if (question.type === 16) foodType = food.cake;
                else if (question.type === 17) foodType = food.supper;

                if (foodType) {
                  //We need to check every option to see if it still exists or not. There can be invalid answers over the time
                  for (let h = 0; h < question.answer.response.length; h++) {

                    if (question.answer.response[h]?.id && (question.type === 13 || question.type === 14)) {
                      hasAnswer = getFoodOptionWithSidedishes(
                        question.answer.response[h],
                        foodType,
                        sidedishes
                      );
                    } else {
                      hasAnswer = getFoodOption(
                        question.answer.response[h],
                        foodType
                      );
                    }

                    //If we found a valid answer, exit right away
                    if (hasAnswer) {
                      break;
                    }
                  }
                }
              }

              answers.push({
                label: question.title.pt,
                answer: hasAnswer?.food
                  ? getPlateSidedishes(hasAnswer, sidedishes)
                  : hasAnswer?.name
                    ? hasAnswer.name?.pt
                    : hasAnswer?.title
                      ? hasAnswer.title?.pt
                      : null,
                title: question?.title,
                description: hasAnswer?.food
                  ? getTranslationPlateSidedishes(hasAnswer, sidedishes)
                  : hasAnswer?.name
                    ? hasAnswer.name
                    : hasAnswer?.title
                      ? hasAnswer?.title
                      : null,
                showQuestion: true,
                observations: question.answer?.observations,
                order: getOrderFood(question._id)
              });
            }
          }
        }
      }
    }
  }

  //Order the array
  if (answers.length > 0) {
    answers.sort((a, b) =>
      a.order > b.order ? 1 : a.order < b.order ? -1 : 0
    );
  }

  return answers;
};

export const findAnsweredAboutUs = (section, weddingInfo) => {
  const questions = [];
  let chapter = undefined,
    question = undefined,
    hasAnswer = undefined;

  //Show the language
  if (weddingInfo.language) {
    questions.push({
      title: {
        pt: 'Idioma das comunicações da plataforma',
        en: 'Platform communication language',
        fr: 'Langage de communication de la plateforme',
        es: 'Lenguaje de comunicaciones de plataforma'
      },
      type: 1,
      answer: {
        response: weddingInfo.language === 'pt' ? 'Português' : weddingInfo.language === 'en' ? 'Inglês' : weddingInfo.language === 'fr' ? 'Francês' : weddingInfo.language === 'es' ? 'Espanhol' : ''
      }
    });
  }

  if (section.chapters.length > 0) {
    for (let i = 0; i < section.chapters.length; i++) {
      chapter = section.chapters[i];

      //Dados para faturação e informação de pagamentos
      if (chapter._id === '5e735eeb29196b814fb983b6') {
        if (chapter.questions.length > 0) {
          for (let j = 0; j < chapter.questions.length; j++) {
            question = chapter.questions[j];

            if (
              question.answer &&
              question.answer.response &&
              question.answer.response.length > 0
            ) {
              //Default: has no valid answer
              hasAnswer = false;

              //We need to check every option to see if it still exists or not. There can be invalid answers over the time
              for (let h = 0; h < question.answer.response.length; h++) {
                //If we found a valid answer, exit right away
                if (
                  checkOption(
                    question.answer.response[h],
                    question,
                    weddingInfo.wedding_place
                  )
                ) {
                  hasAnswer = true;
                  break;
                }
              }

              //If there's an answer, add the question to the array
              if (hasAnswer) {
                questions.push(question);
              }
            }
          }
        }
      }
    }
  }

  return questions;
};

export const findAnsweredStaff = (section, weddingPlace) => {
  const questions = [];
  let chapter = undefined,
    question = undefined,
    hasAnswer = undefined;

  if (section.chapters.length > 0) {
    for (let i = 0; i < section.chapters.length; i++) {
      chapter = section.chapters[i];

      //Internal Staff
      if (chapter._id === '5e3181e69621e5d6a3d89740') {
        if (chapter.questions.length > 0) {
          for (let j = 0; j < chapter.questions.length; j++) {
            question = chapter.questions[j];

            //Ignore questions "Organizador" && "Coordenador"
            if (
              question._id !== '5e3184829621e5d6a3d89742' &&
              question._id !== '5e3184939621e5d6a3d89743'
            ) {
              if (
                question.answer &&
                question.answer.response &&
                question.answer.response.length > 0
              ) {
                //Default: has no valid answer
                hasAnswer = false;

                //We need to check every option to see if it still exists or not. There can be invalid answers over the time
                for (let h = 0; h < question.answer.response.length; h++) {
                  //If we found a valid answer, exit right away
                  if (
                    checkOption(
                      question.answer.response[h],
                      question,
                      weddingPlace
                    )
                  ) {
                    hasAnswer = true;
                    break;
                  }
                }

                //If there's an answer, add the question to the array
                if (hasAnswer) {
                  questions.push(question);
                }
              }
            }
          }
        }
      }
    }
  }

  return questions;
};

export const findAnsweredGuests = (section, guestsInfo) => {
  const questions = [];
  let chapter = undefined,
    question = undefined;

  if (guestsInfo && section.chapters.length > 0) {
    for (let i = 0; i < section.chapters.length; i++) {
      chapter = section.chapters[i];

      //Provisory List or Room Plan
      if (
        chapter._id === '5e2ada5d47f38787fd9e8423' ||
        chapter._id === '5e2adaa747f38787fd9e8424'
      ) {
        if (chapter.questions.length > 0) {
          for (let j = 0; j < chapter.questions.length; j++) {
            question = chapter.questions[j];

            //Provisory List
            if (chapter._id === '5e2ada5d47f38787fd9e8423') {
              //Provisory number of guests
              if (
                question._id === '5e2adf9947f38787fd9e8427' &&
                guestsInfo.provisory_guests > 0
              ) {
                questions.push(question);
              }
              //Provisory number of guests
              else if (
                question._id === '5e2adfbf47f38787fd9e8428' &&
                guestsInfo.guests_location
              ) {
                questions.push(question);
              }
            }
            //Room Plan
            else if (chapter._id === '5e2adaa747f38787fd9e8424') {
              if (
                question._id === '5e2ae0b247f38787fd9e842b' &&
                guestsInfo.room_plan &&
                guestsInfo.room_plan.filename
              ) {
                questions.push(question);
              }
            }
          }
        }
      }
    }
  }

  return questions;
};

export const findAnsweredQuestions = (section, food, colors, weddingPlace) => {
  const questions = [];
  let question = undefined,
    hasAnswer = undefined,
    foodType = undefined;

  if (section.chapters.length > 0) {
    for (let i = 0; i < section.chapters.length; i++) {
      if (section.chapters[i].questions.length > 0) {
        for (let j = 0; j < section.chapters[i].questions.length; j++) {
          question = section.chapters[i].questions[j];

          if (
            question.type === 1 ||
            question.type === 2 ||
            question.type === 3
          ) {
            if (question.answer && question.answer.response) {
              questions.push(question);
            }
          } else if (
            question.type === 4 ||
            question.type === 5 ||
            question.type === 6
          ) {
            if (
              question.answer &&
              question.answer.response &&
              question.answer.response.length > 0
            ) {
              //Default: has no valid answer
              hasAnswer = false;

              //We need to check every option to see if it still exists or not. There can be invalid answers over the time
              for (let h = 0; h < question.answer.response.length; h++) {
                //If we found a valid answer, exit right away
                if (
                  checkOption(
                    question.answer.response[h],
                    question,
                    weddingPlace
                  )
                ) {
                  hasAnswer = true;
                  break;
                }
              }

              //If there's an answer, add the question to the array
              if (hasAnswer) {
                questions.push(question);
              }
            }
          } else if (question.type === 7 || question.type === 8) {
            if (
              question.answer &&
              question.answer.response &&
              question.answer.response.filename
            ) {
              questions.push(question);
            }
          } else if (question.type === 9) {
            questions.push(question);
          } else if (question.type === 10) {
            //Check field "qtd" instead of "id" as it can be true / false and create problems when checking if it exists
            if (
              question.answer &&
              question.answer.response &&
              question.answer.response[0].qtd
            ) {
              questions.push(question);
            }
          } else if (question.type === 11) {
            if (
              question.answer &&
              question.answer.response &&
              question.answer.response.length > 0
            ) {
              //Default: has no valid answer
              hasAnswer = false;

              //We need to check every option to see if it still exists or not. There can be invalid answers over the time
              for (let h = 0; h < question.answer.response.length; h++) {
                //If we found a valid answer, exit right away
                if (checkColorOption(question.answer.response[h], colors)) {
                  hasAnswer = true;
                  break;
                }
              }

              //If there's an answer, add the question to the array
              if (hasAnswer) {
                questions.push(question);
              }
            }
          } else if (
            question.type === 12 ||
            question.type === 13 ||
            question.type === 14 ||
            question.type === 15 ||
            question.type === 16 ||
            question.type === 17
          ) {
            if (
              question.answer &&
              question.answer.response &&
              question.answer.response.length > 0
            ) {
              //Default: has no valid answer
              hasAnswer = false;

              const foodIsArray = food instanceof Array;
              foodType = undefined;
              if (question.type === 12) foodType = foodIsArray && food.length > 0 ? food.filter(f => f.category === 'entrees') : food.entrees;
              else if (question.type === 13) foodType = foodIsArray && food.length > 0 ? food.filter(f => f.category === 'meat') : food.meat;
              else if (question.type === 14) foodType = foodIsArray && food.length > 0 ? food.filter(f => f.category === 'fish') : food.fish;
              else if (question.type === 15) foodType = foodIsArray && food.length > 0 ? food.filter(f => f.category === 'dessert') : food.dessert;
              else if (question.type === 16) foodType = foodIsArray && food.length > 0 ? food.filter(f => f.category === 'cake') : food.cake;
              else if (question.type === 17) foodType = foodIsArray && food.length > 0 ? food.filter(f => f.category === 'supper') : food.supper;

              if (foodType) {
                //We need to check every option to see if it still exists or not. There can be invalid answers over the time
                for (let h = 0; h < question.answer.response.length; h++) {
                  //If we found a valid answer, exit right away
                  if (checkFoodOption(question.answer.response[h], foodType)) {
                    hasAnswer = true;
                    break;
                  }
                }

                //If there's an answer, add the question to the array
                if (hasAnswer) {
                  questions.push(question);
                }
              }
            }
          }
        }
      }
    }
  }

  return questions;
};

export const findSpecificQuestion = (section, questionID) => {
  let question = undefined;

  if (section.chapters.length > 0) {
    for (let i = 0; i < section.chapters.length; i++) {
      if (section.chapters[i].questions.length > 0) {
        for (let j = 0; j < section.chapters[i].questions.length; j++) {
          if (section.chapters[i].questions[j]._id === questionID) {
            question = section.chapters[i].questions[j];
            break;
          }
        }
      }
    }
  }

  return question;
};
