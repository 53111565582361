import React, { Component } from "react";
import { reduxForm, Field, initialize, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import BaseButton from "../../components/buttons/BaseButton";
import {
  FormContainer,
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  BaseForm,
  HeaderButtonsContainer,
  SpinLoading,
  DefaultLanguageTab
} from "../../styles/BasicStyles";
import {
  GetDynamicPage,
  UpdateDynamicPage,
  CreateDynamicPage,
  AddDynamicPageFileLink,
  RemoveDynamicPageFileLink
} from "../../infra/requests/BaseRequests";
import { Tabs, Row, Col } from "antd";
import { withLocalize } from "react-localize-redux";
import { GetTranslationDescription } from "../../infra/services/translations/AvailableTranslations";
import FormValidator from "../../infra/services/validations/FormValidator";
import DraftInput from "../../components/inputs/DraftInput";
import { InputLabelDiv } from "../../components/inputs/InputStyles";
import TextInput from "../../components/inputs/TextInput";
import FileInput from "../../weddingday/informations/FileInput";
import TinyInput from "../../components/inputs/TinyInput";
import { Editor } from '@tinymce/tinymce-react';
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  description: "required|languages"
});

class ManageDynamicPages extends Component {
  state = {
    isNew: false,
    loading: true,
    ready: false,
    title: "New Dynamic Page",
    askLink: false,
    document: {},
    page: {},
    pagePT: ''
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;

    if (params.id !== "add") {
      const result = await GetDynamicPage(params.id);
      dispatch(initialize("manage_dynamic_page_form", { ...result.data }));
      this.setState({
        ready: true,
        isNew: false,
        title: result?.data?.title,
        askLink: result?.data?.hasDocument || false,
        document: result?.data?.document ? result.data.document : {},
      });
    } else {
      this.setState({
        ready: true,
        isNew: true
      });
    }
  };

  onSubmit = async values => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;
      const { isNew, title } = this.state;

      isNew
        ? await CreateDynamicPage({ ...values, title })
        : await UpdateDynamicPage(params.id, { ...values, title });
      return history.push("/management/dynamic-pages");
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    try {
      const { history } = this.props;
      return history.push("/management/dynamic-pages");
    } catch (e) {
      console.error(e);
    }
  };

  uploadLinkFile = async (file, language) => {
    const {
      match: { params },
      dispatch,
      pageForm
    } = this.props;
    // console.warn('pageForm', pageForm);

    const payload = new FormData();
    payload.append('file', file);

    const result = await AddDynamicPageFileLink(params.id, language, payload);
    // console.warn('Res', result);
    if (result?.success) {
      this.setState({ document: result?.data?.document ? result.data.document : {} })

      dispatch(initialize('manage_dynamic_page_form', {
        ...pageForm,
        link: result?.data?.link,
        document: result?.data?.document
      }));
    }

    return result;
  };

  deleteFileLink = async (language) => {
    const {
      match: { params },
      dispatch,
      pageForm
    } = this.props;
    const { document } = this.state;
    // console.warn('pageForm', pageForm);

    const id = document[language] && document[language]._id ?
      document[language]._id : document[language];
    // console.warn('id', id);

    const result = await RemoveDynamicPageFileLink(params.id, id, language);
    // console.warn('Res', result);
    if (result?.success) {
      this.setState({ document: result?.data?.document ? result.data.document : {} })

      dispatch(initialize('manage_dynamic_page_form', {
        ...pageForm,
        link: result?.data?.link,
        document: result?.data?.document
      }));
    }

    return result;
  };

  render() {
    const { handleSubmit, activeLanguage, languages } = this.props;
    const { ready, title, askLink, document } = this.state;
    if (!ready) return <SpinLoading />;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{title}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={12}>
              <Col xs={12}>
                <DefaultLanguageTab>
                  {GetTranslationDescription(activeLanguage.code)}
                </DefaultLanguageTab>
                <Field
                  component={TinyInput}
                  name={`description.${activeLanguage.code}`}
                  label={"Conteúdo"}
                />
                {askLink && <React.Fragment>
                  <Field
                    component={TextInput}
                    type={'text'}
                    name={`link.${activeLanguage.code}`}
                    label={'Link do Documento'}
                    disabled={document && document[activeLanguage.code] ? true : false}
                  />

                  <InputLabelDiv style={{ marginTop: '15px' }}>Documento</InputLabelDiv>
                  <Field
                    component={FileInput}
                    name={`document.${activeLanguage.code}`}
                    handleAdd={f => this.uploadLinkFile(f, activeLanguage.code)}
                    handleDelete={() => this.deleteFileLink(activeLanguage.code)}
                  />
                </React.Fragment>}
              </Col>
              <Col xs={12}>
                <Tabs>
                  {languages
                    .filter(x => !x.active)
                    .map((language, index) => (
                      <TabPane
                        tab={GetTranslationDescription(language.code)}
                        key={language.code}
                      >
                        <Field
                          component={TinyInput}
                          name={`description.${language.code}`}
                          label={"Conteúdo"}
                          id={index}
                        />
                        {askLink && <React.Fragment>
                          <Field
                            component={TextInput}
                            type={'text'}
                            name={`link.${language.code}`}
                            label={'Link do Documento'}
                            disabled={document && document[language.code] ? true : false}
                          />

                          <InputLabelDiv style={{ marginTop: '15px' }}>Documento</InputLabelDiv>
                          <Field
                            component={FileInput}
                            name={`document.${language.code}`}
                            handleAdd={f => this.uploadLinkFile(f, language.code)}
                            handleDelete={() => this.deleteFileLink(language.code)}
                          />
                        </React.Fragment>}
                      </TabPane>
                    ))}
                </Tabs>
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

const selector = formValueSelector('manage_dynamic_page_form');

ManageDynamicPages = reduxForm({
  form: "manage_dynamic_page_form",
  validate: validations
})(ManageDynamicPages);

const mapStateToProps = state => ({
  pageForm: {
    description: selector(state, 'description'),
    // link: selector(state, 'link'),
    // document: selector(state, 'document'),
  }
});

export default withLocalize(connect(mapStateToProps)(ManageDynamicPages));
