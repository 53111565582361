import styled from 'styled-components';

export const OptionBox = styled.div`
  display: inline-block;
  text-align: left;
  width: 100%;
`;

export const OptionTitle = styled.div`
  margin-top: 15px;
  color: #757575;
  font-size: 18px;
  height: 36px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const OptionDescription = styled.div`
  margin-top: 10px;
  font-size: 14px;
  display: block;
  overflow: hidden;
  height: 56px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

export const OptionCost = styled.div`
  margin-top: 15px;
  color: #757575;
  font-size: 18px;
  height: 18px;

  .anticon {
    margin-right: 5px;
    color: ${p => p?.theme?.primaryColor}
  }
`;

export const FloatButton = styled.div`
  cursor: pointer;
  position: absolute;
  background: white;
  padding: 5px;
  top: 6px;
  right: ${({ number }) => 6 + number * 30}px;
  box-shadow: 1px 1px 3px #ccc;
`;

export const ItemsList = styled.div`
  margin-top: 30px;
`;

export const RadioItem = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  text-align: left;
  padding: 0 80px 0 15px;

  ${({inactive, theme, showIcon}) => showIcon && `
    ::before {
      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      overflow: hidden;
      background: ${inactive ? theme.inputErrorColor : theme.primaryColor};
    }
  `}
`;

export const ItemOption = styled.div`
  position: absolute;
  top: 0px;
  right: ${({ number }) => number * 30}px;
`;

export const ChapterOrderName = styled.div`
  background-color: #faf9f9;
  padding: 5px 10px;
  margin-bottom: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
`;

export const OrderContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;