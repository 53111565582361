import PropTypes from 'prop-types';
import React from 'react';
import { InputDiv, InputLabelDiv, TextAreaInputField } from './InputStyles';

const TextAreaInput = ({
  input,
  meta,
  prefix,
  size,
  suffix,
  onPressEnter,
  label,
  placeholder,
  minRows,
  first,
  maxRows,
  disabled,
  noLabel,
  guestMap,
  restrictionOrder = false,
  fillError = false
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  return (
    <InputDiv restrictionOrder={restrictionOrder} first={first || noLabel}>
      {!noLabel && <InputLabelDiv>{label}</InputLabelDiv>}
      <TextAreaInputField
        disabled={disabled}
        prefix={prefix}
        size={size}
        suffix={suffix}
        value={input.value}
        onChange={input.onChange}
        onPressEnter={onPressEnter}
        placeholder={placeholder}
        autoSize={{
          minRows: minRows ? minRows : 3,
          maxRows: maxRows ? maxRows : undefined
        }}
        error={showError ? 1 : 0}
        fillError={fillError && showError ? 1 : 0}
        guestmap={guestMap}
        restrictionOrder={restrictionOrder}
      />
    </InputDiv>
  );
};

TextAreaInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  onPressEnter: PropTypes.func,
  guestMap: PropTypes.bool
};
TextAreaInput.defaultProps = {
  guestMap: false,
}

export default TextAreaInput;
