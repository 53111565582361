import client from '../config/AxiosConfig';

export const GetIngredients = async () => client.get('/ingredients');

export const GetIngredientsPagination = async (page, limit, filter = "") => client.get(`/ingredients/${page}/${limit}?filter=${filter}`);

export const CreateIngredient = async data => client.post('/ingredients', data);

export const GetIngredient = async id => client.get(`/ingredients/${id}`);

export const UpdateIngredient = async (id, data) => client.put(`/ingredients/${id}`, data);

export const DeleteIngredient = async id => client.delete(`/ingredients/${id}`);