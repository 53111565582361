import client from '../config/AxiosConfig';

// export const CreatePaymentAccount = async (data) =>
//   client.put(`/paymentAccount/create`, data);

export const CreateManyPaymentAccount = async (data) =>
  client.put('/paymentAccount/createMany', data);

export const CreatePaymentAccountByEmployee = async (data) =>
  client.put('/paymentAccount/createByEmployee', data);

export const GetPaymentAccount = async id => client.get(`/paymentAccount/${id}`);

export const GetPaymentAccountByEmployeeAndStatus = async (employeeId, status) =>
  client.get(`/paymentAccount/findByEmployeeAndStatus/${employeeId}/${status}`);

export const GetPaymentAccountNumbers = async (status, employee, approvedOnly) =>
  client.get(`/paymentAccount/getPaymentAccountNumbers/${status}/${employee}/${approvedOnly}`);

export const UpdateReceipt = async (id, data) =>
  client.post(`/paymentAccount/${id}/updateReceipt`, data);

export const RemoveReceipt = async (id) =>
  client.post(`/paymentAccount/${id}/removeReceipt`, {});

// export const UpdatePaymentAccountStatusAndEndDate = async (data) =>
//   client.post(`/paymentAccount/updateStatusAndEndDate`, data);

export const MarkPaymentAccountAsPaid = async (data) =>
  client.post(`/paymentAccount/markAsPaid`, data);

export const RemoveMarkPaymentAccountAsPaid = async (data) =>
  client.post(`/paymentAccount/removeMarkAsPaid`, data);

export const UpdatePaymentAccountDeclaration = async (data) =>
  client.post(`/paymentAccount/updateDeclaration`, data);

export const UpdatePaymentAccountApproved = async (data) =>
  client.post(`/paymentAccount/updateApproved`, data);

export const ApprovePaymentAccounts = async (data) =>
  client.post(`/paymentAccount/approvePaymentAccounts`, data);

export const DisapprovePaymentAccounts = async (data) =>
  client.post(`/paymentAccount/disapprovePaymentAccounts`, data);

// export const SendEmailUpdateApproved = async (data) =>
//   client.post(`/paymentAccount/sendEmailUpdateApproved`, data);

export const DeletePaymentAccount = async (id) =>
  client.delete(`/paymentAccount/remove/${id}`);
