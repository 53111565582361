import React, { Component } from 'react';
import moment from 'moment';
import { TimePicker, Col, Row, Checkbox, Icon, Tooltip } from 'antd';
import {
  SpinLoading,
  SectionTitle,
  TableButton
} from '../../styles/BasicStyles';
import SelectInput from '../../components/inputs/SelectInput';
import Table from '../../components/table/Table';
import NumberInput from '../../components/inputs/NumberInput';
import { StateLabel } from '../weddings/Styles';
import { GetEmployeePriceByEmployee, GetEmployeePriceByEmployeeAndSubcategory } from '../../infra/requests/EmployeePriceRequests';
import { GetWeddingInterestByWeddingAndSubcategory } from '../../infra/requests/WeddingInterestRequests';
import { GetEmployeesAvailableBySubcategory } from '../../infra/requests/EmployeeRequests';
import { getEmployeesOrdered, getStartTimeBySubcategory, updateRowsAndEmployeesList } from '../../infra/services/wedding/weddingUtils';
import BaseButton from '../../components/buttons/BaseButton';
import { GetSubcategories } from '../../infra/requests/SubcategoryRequests';
import { GetWeddingTeamRows } from '../../infra/requests/WeddingTeamRequests';
import { TeamTableActions } from './WeddingTeamStyles';

const newWeddingTeam = {
  id: 'new#FFSDD',
  startTime: moment(),
  endTime: null,
  presence: false,
  notes: '',
  value: 0.00,
  bonus: 0.00,
  employee: null,
  wedding: null,
  subcategory: null,
  published: false
};

class AdminAddEditWeddingTeam extends Component {
  state = {
    pageSize: 15,
    currentPage: 1,
    loading: true,
    disableInputs: false,
    currentUser: null,
    selectedWedding: null,
    ourDay: [],
    scheduler: null,
    pax: null,
    teamBooking: null,
    weddingTeam: null,
    payments: [],
    subcategories: [],
    presenceAllowed: false,

    confirmedAll: false,
    presentAll: false,

    addEditTeam: [],
    deleteTeam: [],
    deleteBooking: [],
    updateBooking: [],

    columns: [
      {
        title: 'Subcategoria',
        key: 'subcategory',
        width: '150px',
        render: data => (data?.subcategory
          ? <div>
            {data?.weddingTeam?.subcategory?.category?.name}:
            <b>{data?.weddingTeam?.subcategory?.name}</b>
          </div>
          : <SelectInput
            allowClear
            placeholder="Escolher a subcategoria"
            disabled={this.checkPaymentDone(data) || this.state.disableInputs}
            data={this.state.subcategories}
            input={{
              value: data.weddingTeam.subcategory?.name,
              onChange: event => { this.onSubcategoryChange(event, data) }
            }}
            meta={{ valid: true }}
          />)
      },
      {
        title: 'Funcionário',
        key: 'employee',
        width: '200px',
        render: data => (
          <div style={{ display: 'inline-flex', width: '100%' }}>
            <SelectInput
              allowClear
              placeholder="Escolher o funcionário"
              data={data.employees}
              input={{
                value: data.weddingTeam.employee?.name,
                onChange: event => this.onEmployeeChange(event, data)
              }}
              disabled={this.checkPaymentDone(data) || this.state.disableInputs} // disabled={this.state.teamPublished}
              meta={{ valid: true }}
            />
            {data?.isUnavailable && <Tooltip title="Funcionário Indisponível">
              <Icon type="warning" style={{ margin: 'auto 0 auto 5px', fontSize: 20, paddingTop: 5, color: 'orange' }} />
            </Tooltip>}
            {data?.weddingTeam?.employee?.deleted && <Tooltip title="Funcionário inativo">
              <Icon type="user-delete" style={{ margin: 'auto 0 auto 5px', fontSize: 20, paddingTop: 5, color: 'red' }} />
            </Tooltip>}
          </div>
        )
      },
      {
        title: 'Horário de Entrada',
        width: '100px',
        render: data => (
          <TimePicker style={{ width: '100%' }}
            type="time"
            format='HH:mm'
            disabled={this.checkPaymentDone(data) || this.state.disableInputs} //this.state.teamPublished
            value={data.weddingTeam.startTime ? moment.utc(data.weddingTeam.startTime).local() : moment.utc().local()}
            onChange={event => {
              let { rows, addEditTeam, deleteTeam } = this.state;
              data.weddingTeam.startTime = event ? event.utc() : moment().utc();

              const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
              rows[index] = data;

              const indexEdit = addEditTeam.findIndex(f => f.id === data.weddingTeam.id);
              if (indexEdit === -1) addEditTeam.push(data.weddingTeam);
              else addEditTeam[indexEdit] = data.weddingTeam;

              deleteTeam = deleteTeam.filter(f => f.id !== data.weddingTeam.id);

              this.setState({ rows, addEditTeam, deleteTeam });
              this.props.updateWeddingTeam(addEditTeam);
              this.props.deleteWeddingTeam(deleteTeam);
              this.props.updateRowsTeam(rows);
            }}
          />
        )
      },
      {
        title: 'Confirmado',
        key: 'confirmed',
        width: '80px',
        render: data => (
          <div className="sc-dxgOiQ fWHHkk">
            <Checkbox
              checked={data.weddingTeam.confirmed}
              disabled={this.checkPaymentDone(data) || this.state.disableInputs}
              onChange={(e) => {
                let { rows, addEditTeam, deleteTeam } = this.state;
                data.weddingTeam.confirmed = e.target.checked;

                const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
                rows[index] = data;

                const indexEdit = addEditTeam.findIndex(f => f.id === data.weddingTeam.id);
                if (indexEdit === -1) addEditTeam.push(data.weddingTeam);
                else addEditTeam[indexEdit] = data.weddingTeam;

                deleteTeam = deleteTeam.filter(f => f.id !== data.weddingTeam.id);

                this.setState({ rows, addEditTeam, deleteTeam });
                this.props.updateWeddingTeam(addEditTeam);
                this.props.deleteWeddingTeam(deleteTeam);
                this.props.updateRowsTeam(rows);
              }}>
              Sim
            </Checkbox>
          </div>
        )
      },
      {
        title: 'Presença',
        key: 'presence',
        width: '80px',
        render: data => (
          <div className="sc-dxgOiQ fWHHkk">
            <Checkbox
              checked={data.weddingTeam.presence}
              disabled={this.checkPaymentDone(data) || this.state.disableInputs} // || !this.state.presenceAllowed
              onChange={(e) => {
                let { rows, addEditTeam, deleteTeam } = this.state;
                data.weddingTeam.presence = e.target.checked;

                const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
                rows[index] = data;

                const indexEdit = addEditTeam.findIndex(f => f.id === data.weddingTeam.id);
                if (indexEdit === -1) addEditTeam.push(data.weddingTeam);
                else addEditTeam[indexEdit] = data.weddingTeam;

                deleteTeam = deleteTeam.filter(f => f.id !== data.weddingTeam.id);

                this.setState({ rows, addEditTeam, deleteTeam });
                this.props.updateWeddingTeam(addEditTeam);
                this.props.deleteWeddingTeam(deleteTeam);
                this.props.updateRowsTeam(rows);
              }}>
              Sim
            </Checkbox>
          </div>
        )
      },
      {
        title: 'Valor',
        width: '60px',
        render: data => {
          return data?.weddingTeam?.setting ?
            <React.Fragment>
              <div>{parseFloat(data.weddingTeam.value).toFixed(2) + '€'}</div>
              <span>{data?.weddingTeam?.setting?.name}</span>
            </React.Fragment>
            : <div>{parseFloat(data.weddingTeam.value).toFixed(2) + '€'}</div>
        }
      },
      {
        title: 'Bónus',
        width: '100px',
        render: data => (<NumberInput
          // min={0}
          onInput="validity.valid || (value = '')"
          step={0.01}
          disabled={this.checkPaymentDone(data) || this.state?.disableInputs}
          input={{
            value: data.weddingTeam.bonus,
            onChange: event => {
              let { rows, addEditTeam, deleteTeam } = this.state;
              // If the payment - bonus < 0, then bonus is 0
              if ((parseFloat(data.weddingTeam.value) + parseFloat(event)) >= 0) {
                data.weddingTeam.bonus = event;
              } else {
                data.weddingTeam.bonus = 0;
              }

              const indexRows = rows.findIndex(f => f.id === data.weddingTeam.id);
              rows[indexRows] = data;

              const indexAddEdit = addEditTeam.findIndex(f => f.id === data.weddingTeam.id);
              if (indexAddEdit === -1) addEditTeam.push(data.weddingTeam);
              else addEditTeam[indexAddEdit] = data.weddingTeam;

              deleteTeam = deleteTeam.filter(f => f.id !== data.weddingTeam.id);

              this.setState({ rows, addEditTeam, deleteTeam });
              this.props.updateWeddingTeam(addEditTeam);
              this.props.deleteWeddingTeam(deleteTeam);
              this.props.updateRowsTeam(rows);
            }
          }}
          meta={{}}
          type="number"
          placeholder="Insira o valor do bónus"
        />
        )
      },
      {
        title: 'Total',
        width: '60px',
        render: data => {
          // console.warn('Setting', data?.weddingTeam?.setting);
          // console.warn('PAx', this.state.pax);
          const value = data?.weddingTeam?.setting && data?.weddingTeam?.setting?.integerValue == false
            ? (data?.weddingTeam?.value * this.state.pax)
            : data?.weddingTeam?.value;
          return parseFloat(value + data.weddingTeam.bonus).toFixed(2);
        }
      },
      {
        title: 'Notas',
        key: 'notes',
        width: '250px',
        render: data => <div className="sc-dxgOiQ fWHHkk">
          <textarea
            placeholder="Escreva as suas notas"
            className="ant-input sc-hSdWYo jDnoVv"
            style={{ height: '50px', minHeight: '50px' }}
            value={data.weddingTeam.notes}
            disabled={this.checkPaymentDone(data) || this.state.disableInputs} //this.state.teamPublished
            onChange={event => {
              let { rows, addEditTeam, deleteTeam } = this.state;
              const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
              rows[index].weddingTeam.notes = event.target.value;

              const indexEdit = addEditTeam.findIndex(f => f.id === data.weddingTeam.id);
              if (indexEdit === -1) addEditTeam.push(data.weddingTeam);
              else addEditTeam[indexEdit] = data.weddingTeam;

              deleteTeam = deleteTeam.filter(f => f.id !== data.weddingTeam.id);

              this.setState({ rows, addEditTeam, deleteTeam });
              this.props.updateWeddingTeam(addEditTeam);
              this.props.deleteWeddingTeam(deleteTeam);
              this.props.updateRowsTeam(rows);
            }}
          />
        </div>
      },
      {

        title: 'Opção',
        width: '50px',
        render: (data) => (
          !this.checkPaymentDone(data) && <TableButton
            disabled={this.checkPaymentDone(data) || this.state.disableInputs}
            onClick={async () => {
              this.setState({ loading: true });
              const { teamBooking, selectedWedding } = this.state;
              this.setState({ loading: true });
              let { rows, addEditTeam, deleteTeam, deleteBooking, updateBooking } = this.state;
              // const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);

              if (data.weddingTeam.employee) {
                const rowsEmp = rows.filter(f => f.employeesOriginal.length > 0 && f.employeesOriginal.filter(f => f.id === data.weddingTeam.employee.id).length > 0);
                rowsEmp.forEach(row => {
                  const indexRow = rows.findIndex(f => f.weddingTeam.id === row.weddingTeam.id);
                  const indexEmp = row.employees.findIndex(f => f.id === data.weddingTeam.employee.id);
                  if (indexEmp === -1) rows[indexRow].employees.push(data.weddingTeam.employee);
                });
              }

              data.weddingTeam.employee = null;
              data.weddingTeam.confirmed = false;
              data.weddingTeam.presence = false;
              data.weddingTeam.notes = '';
              data.weddingTeam.value = 0.00;
              data.weddingTeam.bonus = 0.00;
              data.weddingTeam.total = 0.00;
              data.employeePrice = [];

              //Set list of employees by subcategory
              if (data.weddingTeam.subcategory) {
                const startTime = data.weddingTeam.startTime ? data.weddingTeam.startTime : null;
                const resultEmp = await GetEmployeesAvailableBySubcategory(selectedWedding.date, data.weddingTeam.subcategory.id, selectedWedding.id, startTime);
                const resInterest = { success: resultEmp.success, data: resultEmp.data.interest };
                const resEmpBySub = { success: resultEmp.success, data: resultEmp.data.subcategories };

                data.employees = getEmployeesOrdered(resInterest, resEmpBySub);
                data.employeesOriginal = getEmployeesOrdered(resInterest, resEmpBySub);
                data.selectedTeams = resultEmp.success && resultEmp.data ? resultEmp.data.selectedTeams : [];
              } else {
                data.employees = [];
                data.employeesOriginal = [];
                data.selectedTeams = [];
              }
              if (data.weddingTeam.employee && data.employees.findIndex(f => f.id === data.weddingTeam.employee.id) === -1) data.employees.push(data.weddingTeam.employee);


              //Update row
              const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
              rows[index] = data;

              //Update all rows and employees list
              rows = updateRowsAndEmployeesList(rows, selectedWedding);
              rows = rows.filter(f => f.weddingTeam.id !== data.weddingTeam.id);

              addEditTeam = addEditTeam.filter(f => f.id !== data.weddingTeam.id);

              if (data.extraToBooking === false) {
                const booking = teamBooking.find(f => f.subcategory && data.weddingTeam.subcategory && f.subcategory.id === data.weddingTeam.subcategory.id);
                if (booking && booking.quantity === 1) {
                  const indexDeleteBooking = deleteBooking.findIndex(f => f.id === booking.id);
                  if (indexDeleteBooking === -1) deleteBooking.push(booking);

                  updateBooking = updateBooking.filter(f => f.id !== booking.id);
                } else if (booking && booking.quantity > 1) {
                  booking.quantity = booking.quantity - 1;
                  const indexUpdateBooking = updateBooking.findIndex(f => f.id === booking.id);
                  if (indexUpdateBooking === -1) updateBooking.push(booking);

                  deleteBooking = deleteBooking.filter(f => f.id !== booking.id);
                }
              }

              if (!data.weddingTeam.id.includes('new#FFSDD')) {
                const indexDelete = deleteTeam.findIndex(f => f.id === data.weddingTeam.id);
                if (indexDelete > -1) deleteTeam[indexDelete] = data.weddingTeam;
                else deleteTeam.push(data.weddingTeam);
              }

              this.setState({ rows, addEditTeam, deleteTeam, loading: false });
              this.props.updateWeddingTeam(addEditTeam);
              this.props.deleteWeddingTeam(deleteTeam);
              this.props.deleteBooking(deleteBooking);
              this.props.updateBooking(updateBooking);
              this.props.updateRowsTeam(rows);
            }}
          >
            <Icon type="delete" />
            {/* {' Remover'} */}
          </TableButton>
        )
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    //to test only
    // const now = moment.utc(this.props.selectedWedding.date, 'YYYY-MM-DDTHH:mm:ssZ')
    const now = moment.utc();
    const weddingDate = moment.utc(this.props.selectedWedding.date);
    const startWeddingDate = moment.utc(weddingDate).startOf('day');
    const endWeddingDate = moment.utc(startWeddingDate.format('YYYY-MM-DD') + ' 13:00:00', 'YYYY-MM-DD HH:mm:ss');
    endWeddingDate.add(1, 'day');

    this.setState({
      currentUser: this.props.currentUser,
      selectedWedding: this.props.selectedWedding,
      ourDay: this.props.ourDay,
      scheduler: this.props.scheduler,
      pax: this.props.pax,
      teamBooking: this.props.teamBooking,
      weddingTeam: this.props.weddingTeam,
      payments: this.props.payments,
      presenceAllowed: true, // now.isSameOrAfter(startWeddingDate)
      confirmedAll: false,
      presentAll: false,
    }, () => this.getSubcategories());
  };

  componentDidUpdate(prevProps) {
    if (prevProps?.selectedWedding !== this.props?.selectedWedding) {
      //to test only
      // const now = moment.utc(this.props.selectedWedding.date, 'YYYY-MM-DDTHH:mm:ssZ')
      const now = moment.utc();
      const weddingDate = moment.utc(this.props.selectedWedding.date);
      const startWeddingDate = moment.utc(weddingDate).startOf('day');
      const endWeddingDate = moment.utc(startWeddingDate.format('YYYY-MM-DD') + ' 13:00:00', 'YYYY-MM-DD HH:mm:ss');
      endWeddingDate.add(1, 'day');

      this.setState({
        currentUser: this.props.currentUser,
        selectedWedding: this.props.selectedWedding,
        ourDay: this.props.ourDay,
        scheduler: this.props.scheduler,
        pax: this.props.pax,
        teamBooking: this.props.teamBooking,
        weddingTeam: this.props.weddingTeam,
        payments: this.props.payments,
        presenceAllowed: true, //now.isSameOrAfter(startWeddingDate)
        confirmedAll: false,
        presentAll: false,
      }, () => this.setRows());
    }
  }

  checkPaymentDone = (data) => {
    const { payments } = this.state;
    return payments?.find(f => f?.employee?._id == data.weddingTeam.employee?._id && f?.weddingTeam == data?.weddingTeam?._id) ? true : false;
  }

  /**
   * @description Get all categories from database
   */
  getSubcategories = async () => {
    const result = await GetSubcategories();
    let subcategories = result.data.length > 0 ? result.data : [];
    subcategories = subcategories.filter(f => !f.coordinator && !f.chief && !f.host);
    if (result.success) this.setState({ subcategories }, () => this.setRows());
    else console.error(result.message);
  }

  setRows = async () => {
    this.setState({ loading: true });
    const { selectedWedding, teamBooking } = this.state;
    let { rows, totalRows, weddingTeam } = this.state;

    const result = await GetWeddingTeamRows(selectedWedding?._id);
    if (!result.success) console.error(result.message);
    else {
      rows = result.data.rows;
      totalRows = result.data.totalRows;
    }

    //Update employees list based on selected employee
    // console.warn('Rows', rows);
    rows = updateRowsAndEmployeesList(rows, selectedWedding);

    this.setState({ rows, totalRows, loading: false });
    this.props.updateRowsBooking(totalRows);
    this.props.updateRowsTeam(rows);
  }

  onSubcategoryChange = async (event, data) => {
    this.setState({ loading: true });
    const { teamBooking } = this.state;
    let { rows, addEditTeam, deleteTeam, subcategories, selectedWedding, scheduler, deleteBooking, updateBooking } = this.state;
    const dataOriginal = JSON.parse(JSON.stringify(data));

    //If Employee Selected update employees list
    if (dataOriginal.weddingTeam.employee) {
      const rowsEmp = rows.filter(f => f.employeesOriginal.length > 0 && f.employeesOriginal.filter(f => f.id === dataOriginal.weddingTeam.employee.id).length > 0);
      rowsEmp.forEach(row => {
        const indexRow = rows.findIndex(f => f.weddingTeam.id === row.weddingTeam.id);
        const indexEmp = row.employees.findIndex(f => f.id === dataOriginal.weddingTeam.employee.id);
        if (indexEmp === -1) rows[indexRow].employees.push(dataOriginal.weddingTeam.employee);
      });
    }

    if (dataOriginal.weddingTeam.subcategory && dataOriginal.weddingTeam.subcategory.id !== event) {
      const booking = teamBooking.find(f => f.subcategory && dataOriginal.weddingTeam.subcategory && f.subcategory.id === dataOriginal.weddingTeam.subcategory.id);
      if (booking && booking.quantity === 1) {
        const indexDeleteBooking = deleteBooking.findIndex(f => f.id === booking.id);
        if (indexDeleteBooking === -1) deleteBooking.push(booking);

        updateBooking = updateBooking.filter(f => f.id !== booking.id);
      } else if (booking && booking.quantity > 1) {
        booking.quantity = booking.quantity - 1;
        const indexUpdateBooking = updateBooking.findIndex(f => f.id === booking.id);
        if (indexUpdateBooking === -1) updateBooking.push(booking);

        deleteBooking = deleteBooking.filter(f => f.id !== booking.id);
      }
    }

    const subcategory = subcategories.find(f => f.id === event);
    data.weddingTeam.subcategory = subcategory ? subcategory : null;

    //Calculate start time based on subcategory rules or category start time
    if (subcategory && subcategory.timeRule && subcategory.timeRule !== 0.00 && subcategory.weddingTimeRule) {
      const startTime = getStartTimeBySubcategory(subcategory, scheduler);
      if (startTime) data.weddingTeam.startTime = startTime;
    } else if (subcategory && subcategory.category) {
      data.weddingTeam.startTime = moment(subcategory.category.startTime);
    } else data.weddingTeam.startTime = moment();


    //Fill employee list based on the subcategory selected
    if (event === '' || !data.weddingTeam.subcategory) {
      data.employees = [];
      data.employeesOriginal = [];
      data.selectedTeam = [];
    } else {
      const startTime = subcategory.category && data.weddingTeam.startTime ? data.weddingTeam.startTime : null;
      const resultEmp = await GetEmployeesAvailableBySubcategory(selectedWedding.date, data.weddingTeam.subcategory.id, selectedWedding.id, startTime);
      const resInterest = { success: resultEmp.success, data: resultEmp.data.interest };
      const resEmpBySub = { success: resultEmp.success, data: resultEmp.data.subcategories };

      data.employees = getEmployeesOrdered(resInterest, resEmpBySub);
      data.selectedTeams = resultEmp.success && resultEmp.data ? resultEmp.data.selectedTeams : [];
    }
    if (data.weddingTeam.employee && data.employees.findIndex(f => f.id === data.weddingTeam.employee.id) === -1) data.employees.push(data.weddingTeam.employee);
    data.employeePrice = [];

    //Update row
    const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
    rows[index] = data;

    rows = updateRowsAndEmployeesList(rows, selectedWedding);

    const indexEdit = addEditTeam.findIndex(f => f.id === data.weddingTeam.id);
    if (indexEdit === -1 && data.weddingTeam.employee) addEditTeam.push(data.weddingTeam);
    else if (data.weddingTeam.employee) addEditTeam[indexEdit] = data.weddingTeam;

    deleteTeam = deleteTeam.filter(f => f.id !== data.weddingTeam.id);

    this.setState({ rows, addEditTeam, deleteTeam, loading: false }, () => {
      //Update employee list from previous subcategory
      this.onEmployeeChange('', data);
    });
    this.props.updateWeddingTeam(addEditTeam);
    this.props.deleteWeddingTeam(deleteTeam);
    this.props.deleteBooking(deleteBooking);
    this.props.updateBooking(updateBooking);
  }

  onEmployeeChange = async (event, data) => {
    // console.warn('Data', data);
    // console.warn('Event', event);
    this.setState({ loading: true });
    let { rows, addEditTeam, deleteTeam, selectedWedding } = this.state;
    const dataOriginal = JSON.parse(JSON.stringify(data));

    if (dataOriginal?.weddingTeam?.employee) {
      const rowsEmp = rows.filter(f => f.employeesOriginal.length > 0 && f.employeesOriginal.filter(f => f.id === dataOriginal.weddingTeam.employee.id).length > 0);
      rowsEmp.forEach(row => {
        const indexRow = rows.findIndex(f => f.weddingTeam.id === row.weddingTeam.id);
        const indexEmp = row.employees.findIndex(f => f.id === dataOriginal.weddingTeam.employee.id);
        if (indexEmp === -1) rows[indexRow].employees.push(dataOriginal.weddingTeam.employee);
      });
    }

    if (event === '') {
      data.weddingTeam.employee = null;
      data.weddingTeam.confirmed = false;
      data.weddingTeam.presence = false;
      data.weddingTeam.notes = '';
      data.weddingTeam.value = 0.00;
      data.weddingTeam.bonus = 0.00;
      data.weddingTeam.total = 0.00;
      data.employeePrice = [];
    } else {
      const resPrice = await GetEmployeePriceByEmployee(event);
      const employeePrice = resPrice?.success && resPrice?.data ? resPrice.data : [];
      const priceBySubcategory = employeePrice.length > 0 && employeePrice.find(f => f?.subcategory?.id && data?.weddingTeam?.subcategory?.id && f.subcategory.id === data.weddingTeam.subcategory.id);

      data.weddingTeam.employee = data.employees.find(f => f.id == event);
      data.weddingTeam.value = priceBySubcategory ? priceBySubcategory?.value : 0.00;
      data.weddingTeam.setting = priceBySubcategory ? priceBySubcategory.setting : null;
      data.employeePrice = employeePrice;
    }

    //Set list of employees by subcategory
    if (data?.weddingTeam?.subcategory) {
      const startTime = data.weddingTeam.startTime ? data.weddingTeam.startTime : null;
      const resultEmp = await GetEmployeesAvailableBySubcategory(selectedWedding.date, data.weddingTeam.subcategory.id, selectedWedding.id, startTime);
      const resInterest = { success: resultEmp?.success, data: resultEmp?.data?.interest };
      const resEmpBySub = { success: resultEmp?.success, data: resultEmp?.data?.subcategories };

      data.employees = getEmployeesOrdered(resInterest, resEmpBySub);
      data.employeesOriginal = getEmployeesOrdered(resInterest, resEmpBySub);
      data.selectedTeams = resultEmp.success && resultEmp.data ? resultEmp.data.selectedTeams : [];
    } else {
      data.employees = [];
      data.employeesOriginal = [];
      data.selectedTeams = [];
    }
    if (data.weddingTeam.employee && data.employees.findIndex(f => f.id === data.weddingTeam.employee.id) === -1) data.employees.push(data.weddingTeam.employee);

    //Update row
    const index = rows.findIndex(f => f.weddingTeam.id === data.weddingTeam.id);
    rows[index] = data;

    //Update all rows and employees list
    rows = updateRowsAndEmployeesList(rows, selectedWedding);

    if (event === '') {
      addEditTeam = addEditTeam.filter(f => f.id !== data.weddingTeam.id);

      if (!data.weddingTeam.published && !data.weddingTeam.id.includes('new#FFSDD')) {
        const indexDelete = deleteTeam.findIndex(f => f.id === data.weddingTeam.id);
        if (indexDelete > -1) deleteTeam[indexDelete] = data.weddingTeam;
        else deleteTeam.push(data.weddingTeam);
      }
    } else {
      const indexEdit = addEditTeam.findIndex(f => f.id === data.weddingTeam.id);
      if (indexEdit === -1) addEditTeam.push(data.weddingTeam);
      else addEditTeam[indexEdit] = data.weddingTeam;

      deleteTeam = deleteTeam.filter(f => f.id !== data.weddingTeam.id);
    }

    this.setState({ rows, addEditTeam, deleteTeam, loading: false });
    this.props.updateWeddingTeam(addEditTeam);
    this.props.deleteWeddingTeam(deleteTeam);
    this.props.updateRowsTeam(rows);
  }

  addRow = () => {
    const { rows, selectedWedding, addEditTeam } = this.state;
    let published = rows.length > 0 && rows.filter(t => t.published).length > 0 ? true : false;

    const newWeddingTeam = {
      id: 'new#FFSDD' + (moment().valueOf() + (rows?.length || 1)),
      presence: false,
      value: 0,
      bonus: 0,
      total: 0,
      published: published,
      startTime: moment(),
      endTime: null,
      notes: '',
      employee: null,
      wedding: selectedWedding,
      subcategory: null,
      setting: null
    };

    // addEditTeam.push(newWeddingTeam);
    rows.push({
      weddingTeam: newWeddingTeam,
      extraToBooking: true,
      employeePrice: [],
      employees: [],
      employeesOriginal: [],
      selectedTeams: [],
      category: null,
      subcategories: [],
      isUnavailable: false
    });
    this.setState({ rows, total: rows.length, addEditTeam });
    this.props.updateWeddingTeam(addEditTeam);
    this.props.updateRowsTeam(rows);
  }

  confirmAll = (e) => {
    let { addEditTeam } = this.state;
    const { rows } = this.state;
    const updatedRows = [...rows];
    updatedRows
      .filter(f => f?.weddingTeam?.employee && !this.checkPaymentDone(f) && !this.state.disableInputs)
      .forEach(f => {
        f.weddingTeam.confirmed = e?.target?.checked;

        const indexEdit = addEditTeam.findIndex(f2 => f2.id === f.weddingTeam.id);
        if (indexEdit === -1) addEditTeam.push(f.weddingTeam);
        else addEditTeam[indexEdit] = f.weddingTeam;
      });
    this.setState({
      confirmedAll: e?.target?.checked,
      rows: updatedRows
    });

    this.props.updateWeddingTeam(addEditTeam);
    this.props.updateRowsTeam(rows);
  }

  checkPresentAll = (e) => {
    let { addEditTeam } = this.state;
    const { rows } = this.state;
    const updatedRows = [...rows];
    updatedRows
      .filter(f => f?.weddingTeam?.employee && !this.checkPaymentDone(f) && !this.state.disableInputs)
      .forEach(f => {
        f.weddingTeam.presence = e?.target?.checked;

        const indexEdit = addEditTeam.findIndex(f2 => f2.id === f.weddingTeam.id);
        if (indexEdit === -1) addEditTeam.push(f.weddingTeam);
        else addEditTeam[indexEdit] = f.weddingTeam;
      });
    this.setState({
      presentAll: e?.target?.checked,
      rows: updatedRows
    });

    this.props.updateWeddingTeam(addEditTeam);
    this.props.updateRowsTeam(rows);
  }

  render() {
    const { loading, currentPage, pageSize, payments } = this.state;
    const { columns, rows, total } = this.state;
    const { confirmedAll, presentAll } = this.state;

    return (
      <React.Fragment>
        <Row gutter={24} style={{ marginTop: '2rem', marginBottom: '1rem' }}>
          <Col lg={12} md={8} sm={12} xs={24}>
            <SectionTitle>Equipa do Casamento</SectionTitle>
          </Col>

          <Col lg={12} md={16} sm={12} xs={24}>
            <TeamTableActions>
              <Checkbox
                checked={confirmedAll}
                onChange={(e) => this.confirmAll(e)}>
                Confirmar equipa
              </Checkbox>
              <Checkbox
                checked={presentAll}
                onChange={(e) => this.checkPresentAll(e)}>
                Registar presença
              </Checkbox>
              <BaseButton
                type={'primary'}
                icon={'plus'}
                // disabled={payments.length > 0}
                text={'Adicionar Linha'}
                onClick={() => this.addRow()}
              />
            </TeamTableActions>
          </Col>
        </Row>

        <Table
          columns={columns}
          // currentPage={currentPage}
          // pageSize={pageSize}
          // defaultPageSize={pageSize}
          loading={loading}
          rows={rows}
          showHeader={true}
          emptyIcon="team"
          emptyText={'Ainda não foi definida a equipa'}
          total={total}
          rowKey={'_id'}
          hasPagination={false}
        // handleChangePage={this.handleChangePage}
        // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        />

      </React.Fragment>
    );
  }
}

export default AdminAddEditWeddingTeam;
