import React, { Component } from 'react';
import BaseButton from '../../components/buttons/BaseButton';
import Alerts from '../../components/alert/Alert';
import { Card, Icon } from 'antd';
import styled from 'styled-components';
import { getUserId } from '../../infra/helpers/Helpers';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const CardBlock = styled(Card)`
  .ant-card-body {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  &&& {
    background-color: ${({ theme }) => theme.secondaryBackgroundColor};
    max-width: 450px;
    padding: 30px;
    display: inline-block;
    border-radius: 5px;
  }
`;

export const IconSection = styled.div`
font-size: 70px;
color: #b7eb8f;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
`;

export const Title = styled.div`
  font-size: 30px;
  text-align: center;
  margin-bottom: 5px;
`;

export const Subtitle = styled.div`
  font-size: 25px;
  text-align: center;
  margin-bottom: 20px;
`;

class AuthGooglePage extends Component {
  state = {
    opened: false
  };

  componentDidMount = async () => {
  };

  closeWindow = () => {
    const { history } = this.props;
    const returnUrl = localStorage.getItem('googleReturnUrl');
    if (returnUrl) {
      history.push(`${returnUrl}`);
      localStorage.removeItem('googleReturnUrl');
    } else history.push('/weddings');
  }

  render() {
    return (
      <Container>
        <CardBlock>
          <IconSection>
            <Icon type="check-circle" />
          </IconSection>
          <Title>Google Drive</Title>
          <Subtitle>Autenticado com successo</Subtitle>
          {getUserId() && <BaseButton
            htmlType="submit"
            type="primary"
            text="Voltar"
            onClick={this.closeWindow}
          />}
        </CardBlock>
      </Container>
    );
  }
}

export default AuthGooglePage;
