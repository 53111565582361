import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Checkbox } from 'antd';
import {
  BaseForm, SpinLoading,
} from '../../styles/BasicStyles';
import {
  Row,
  Col,
} from 'antd';
import { useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector, change } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextInput from '../../components/inputs/TextInput';
import TagInput from '../../components/inputs/TagInput';
import SubcategoryPriceTable from './SubcategoryPriceTable';
import { CheckboxField } from '../../components/inputs/InputStyles';
import SimpleCheckboxInput from '../../components/inputs/SimpleCheckboxInput';
import NumberInput from '../../components/inputs/NumberInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import SelectInput from '../../components/inputs/SelectInput';
import { Title } from '../reservesAttending/ReserveInfoModalStyles';
import { GetDefaultEmployeesBySubcategory, GetEmployeesBySubcategory } from '../../infra/requests/EmployeeRequests';

const FormValidations = values => {
  // console.warn('values', values);
  const validations = {
    // defaultEmployeeSolar: 'required',
    // defaultEmployeeLago: 'required',
  };

  let errors = FormValidator.make(validations)(values);
  return errors;
}

let SubcategoryDefaultEmployeesModal = (props): React$Element<React$FragmentType> => {
  const [subcategory, setSubcategory] = React.useState(null);
  const [loading, toogleLoading] = React.useState(false);
  const [employees, setEmployees] = React.useState([]);

  useEffect(() => {
    setSubcategory(props.initialValues);
    getEmployees(props.initialValues?._id);
  }, [props.initialValues]);

  const getEmployees = async (subcatoryId) => {
    toogleLoading(true);
    const result = await GetDefaultEmployeesBySubcategory(subcatoryId);

    if (result?.success) {
      setEmployees(result?.data || []);
    }
    toogleLoading(false);
  }

  return (
    <Modal
      visible={props.isOpen}
      title={'Funcionários pré-definidos'}
      maskClosable
      onCancel={props.onClose}
      footer={[
        <Button
          key='cancel'
          onClick={() => props.onClose()}>
          Cancelar
        </Button>,
        <Button
          key='submit'
          type='primary'
          onClick={props.handleSubmit(props.onSubmit)}>
          Gravar
        </Button>
      ]}
    >
      {loading
        ? <SpinLoading />
        : <BaseForm onSubmit={props.handleSubmit(props.onSubmit)}>
          <Title>{subcategory?.name}</Title>
          <Field
            component={SelectInput}
            data={employees}
            allowClear={true}
            name="defaultEmployeeSolar"
            label="Funcionário SLE"
            placeholder="Selecione o funcionário"
            type="text"
          />
          <Field
            component={SelectInput}
            data={employees}
            allowClear={true}
            name="defaultEmployeeLago"
            label="Funcionário QLC"
            placeholder="Selecione o funcionário"
            type="text"
          />
        </BaseForm>}

    </Modal>
  );
};

SubcategoryDefaultEmployeesModal = reduxForm({
  form: 'subcategory_default_employee_form',
  enableReinitialize: true,
  validate: FormValidations
})(SubcategoryDefaultEmployeesModal);

SubcategoryDefaultEmployeesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default withLocalize(SubcategoryDefaultEmployeesModal);