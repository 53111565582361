// @flow
import React, { useState, useEffect, Fragment, useRef } from 'react';
import Table from '../../components/table/Table';
import { PageContainer, PageTitle, HeaderContainer, HeaderButtonsContainer, HeaderTitle, TableButton } from '../../styles/BasicStyles';
import { Row, Col, Popconfirm, Icon, Tag, Avatar, Breadcrumb, DatePicker, Checkbox, TimePicker, Select, Menu, Dropdown, Button, Badge } from 'antd';
import { CreateCompanyRest, UpdateCompanyRest, RemoveCompanyRest, GetEmployeeRest, GetUnavailable, UpdateUnavailable, CreateUnavailable, RemoveUnavailable, UpdateExceptionCouplePayment, CreateExceptionCouplePayment } from '../../infra/requests/UnavailableRequests';
import BaseButton from '../../components/buttons/BaseButton';
import { withRouter } from 'react-router-dom';
import Alert from "../../components/alert/Alert";
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { getUserId, isCurrentUserAdmin, isCurrentUserEmployee, isCurrentUserEmployeeAttending, isCurrentUserHumanResources, isEmployeeOrChief } from '../../infra/helpers/Helpers';
import SelectInput from '../../components/inputs/SelectInput';
import { goBack } from 'connected-react-router';
import DateInput from '../../components/inputs/DateInput';
import XpertGoDateInput from '../../components/inputs/XpertGoDateInput';
import { GetEmployeeByUser } from '../../infra/requests/EmployeeRequests';
import { checkWeekdaysBetweenDates } from './utilRestDay';

const locals = [
    { _id: 3, name: 'Ambas' },
    { _id: 1, name: 'Solar da Levada' },
    { _id: 2, name: 'Quinta Lago dos Cisnes' },
];

export const allDayOptions = [
    { _id: true, name: 'Sim' },
    { _id: false, name: 'Não' },
];

// main component
let RestDays = (props): React$Element<React$FragmentType> => {

    const { history, location } = props;
    const isRestDays = history.location.pathname.includes('/rest-days') ? true : false;
    const isPaymentDays = history.location.pathname.includes('/payment-days') ? true : false;
    const isCouplePaymentDays = history.location.pathname.includes('/couple-payment-days') ? true : false;
    const fromReserveAgenda = new URLSearchParams(location.search).get('page') === 'RA' ? true : false;
    const fromReservePriceAgenda = new URLSearchParams(location.search).get('page') === 'RP' ? true : false;

    const [title, setTitle] = useState((isEmployeeOrChief() || isCurrentUserHumanResources())
        ? "Dias Indisponíveis"
        : isCouplePaymentDays
            ? 'Dias de Exceção para Pagamentos dos Noivos'
            : "Dias de Descanso");

    const [loading, setLoading] = useState(true);
    const [showFilter, setShowFilter] = useState(false);
    const [startDate, setStartDate] = useState(moment.utc().subtract(1, 'month'));
    const [endDate, setEndDate] = useState(null);
    const [allDay, setAllDay] = useState(null);
    const [totalRest, setTotalRest] = useState(null);
    const [local, setLocal] = useState(null);
    const [pageSize, setPageSize] = useState(15);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [rows, setRows] = useState([]);
    const [editUnit, setEditUnit] = useState(null);
    const [editStartTime, setEditStartTime] = useState(null);
    const [editEndTime, setEditEndTime] = useState(null);

    const [loadingEmployee, toogleLoadingEmployee] = useState(false);
    const [employee, setEmployee] = useState(null);

    const columns = [
        {
            title: 'Data Inicial',
            key: 'startDate',
            width: '130px',
            render: data => (
                <React.Fragment>
                    {
                        !isEditing(data)
                            ?
                            moment.utc(data.startDate).format('DD/MM/YYYY')
                            :
                            <DatePicker
                                value={editUnit.startDate}
                                format={'DD/MM/YYYY'}
                                placeholder='Selecione Data'
                                onChange={(date, dateStr) => {
                                    let x = copyEditUnit();
                                    x.startDate = date ? moment(date).utc(true) : null;
                                    setEditUnit(x);
                                }}
                                style={{ width: '100%', marginTop: 5 }} />
                    }
                </React.Fragment>
            )
        },
        {
            title: 'Data Final',
            key: 'endDate',
            width: '130px',
            render: data => (
                <React.Fragment>
                    {
                        !isEditing(data)
                            ?
                            moment.utc(data.endDate).format('DD/MM/YYYY')
                            :
                            <DatePicker
                                value={editUnit.endDate}
                                format={'DD/MM/YYYY'}
                                placeholder='Selecione Data'
                                onChange={(date, dateStr) => {
                                    let x = copyEditUnit();
                                    x.endDate = date ? moment(date).utc(true) : null;
                                    setEditUnit(x);
                                }}
                                style={{ width: '100%', marginTop: 5 }} />
                    }
                </React.Fragment>
            )
        },
        {
            title: 'Todo o Dia',
            key: 'allDay',
            width: '60px',
            render: data => (

                !isEditing(data)
                    ?
                    < Tag color={data?.allDay === true ? 'green' : 'red'} key={data.allDay} >
                        {data?.allDay === true ? 'Sim' : 'Não'}
                    </Tag >
                    :
                    <div className="sc-dxgOiQ cGTQcX">
                        <Checkbox checked={editUnit.allDay} onChange={(event) => {
                            let x = copyEditUnit();
                            x.allDay = event.target.checked;
                            x.startTime = null;
                            x.endTime = null;
                            setEditUnit(x);
                            setEditStartTime(null);
                            setEditEndTime(null);
                        }}>Todo o Dia</Checkbox>
                    </div>
            )
        },
        {
            title: 'Hora de Início',
            key: 'startTime',
            width: '80px',
            render: data => (
                !isEditing(data)
                    ?
                    <div>
                        {data.startTime ? moment(data.startTime).local().format('HH:mm') : null}
                    </div>
                    :
                    <TimePicker
                        type="time"
                        format='HH:mm'
                        value={editStartTime}
                        disabled={editUnit.allDay}
                        style={{ width: '100%', marginTop: 10 }}
                        placeholder='Selecione Hora'
                        onChange={(event) => {
                            setEditStartTime(event ? event.utc() : null)
                        }}
                    />
            )
        },
        {
            title: 'Hora de Fim',
            key: 'endTime',
            width: '80px',
            render: data => (
                !isEditing(data)
                    ?
                    <div>{data.endTime ? moment(data.endTime).local().format('HH:mm') : null}</div>
                    :
                    <TimePicker
                        type="time"
                        format='HH:mm'
                        disabled={editUnit.allDay}
                        value={editEndTime}
                        style={{ width: '100%', marginTop: 10 }}
                        placeholder='Selecione Hora'
                        onChange={(event) => {
                            setEditEndTime(event ? event.utc() : null)
                        }}
                    />
            )
        },
        {
            title: 'Local',
            key: 'weddingPlace',
            width: '130px',
            render: data => (
                !isEditing(data)
                    ?
                    data.weddingPlace === 3 ? 'Ambas' : data.weddingPlace === 2 ? 'Quinta Lago dos Cisnes' :
                        data.weddingPlace === 1 ? 'Solar da Levada' : ''
                    :
                    <SelectInput
                        placeholder="Escolher local"
                        data={locals}
                        input={{
                            value: editUnit.weddingPlace,
                            onChange: event => {
                                let x = copyEditUnit();
                                x.weddingPlace = parseInt(event);
                                setEditUnit(x)
                            }
                        }}
                        meta={{ valid: true }}
                    />
            )
        },
        {
            title: 'Acções',
            width: '120px',
            render: data => (
                <Fragment>
                    {!isEditing(data)
                        ?
                        <React.Fragment>
                            <TableButton onClick={e => setEditForm(data)}>
                                <Icon type="edit" />
                                {' Editar'}
                            </TableButton>
                            <TableButton onClick={e => e.stopPropagation()}>
                                <Popconfirm
                                    placement="topRight"
                                    title={RemoveMessage}
                                    okText="Remover"
                                    onConfirm={() => { deleteUnit(data.id) }}>
                                    <Icon type="delete" />
                                    {' Remover'}
                                </Popconfirm>
                            </TableButton>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <TableButton onClick={addEditUnit}>
                                <Icon type="save" />
                                {' Guardar'}
                            </TableButton>
                            <TableButton onClick={e => {
                                if (editUnit.id === null) {
                                    setEditUnit(null);
                                    setRows(rows.filter(f => f.id !== null));
                                } else setEditUnit(null);
                            }}>
                                <Icon type="close" />
                                {' Cancelar'}
                            </TableButton>
                        </React.Fragment>
                    }
                </Fragment>
            )
        }
    ]

    const columnsRestDays = [
        {
            title: 'Data Inicial',
            key: 'startDate',
            width: '130px',
            render: data => (
                <React.Fragment>
                    {
                        !isEditing(data)
                            ?
                            moment.utc(data.startDate).format('DD/MM/YYYY')
                            :
                            <DatePicker
                                value={editUnit.startDate}
                                format={'DD/MM/YYYY'}
                                placeholder='Selecione Data'
                                onChange={(date, dateStr) => {
                                    let x = copyEditUnit();
                                    x.startDate = date ? moment(date).utc(true) : null;
                                    setEditUnit(x);
                                }}
                                style={{ width: '100%', marginTop: 5 }} />
                    }
                </React.Fragment>
            )
        },
        {
            title: 'Data Final',
            key: 'endDate',
            width: '130px',
            render: data => (
                <React.Fragment>
                    {
                        !isEditing(data)
                            ?
                            moment.utc(data.endDate).format('DD/MM/YYYY')
                            :
                            <DatePicker
                                value={editUnit.endDate}
                                format={'DD/MM/YYYY'}
                                placeholder='Selecione Data'
                                onChange={(date, dateStr) => {
                                    let x = copyEditUnit();
                                    x.endDate = date ? moment(date).utc(true) : null;
                                    setEditUnit(x);
                                }}
                                style={{ width: '100%', marginTop: 5 }} />
                    }
                </React.Fragment>
            )
        },
        {
            title: 'Todo o Dia',
            key: 'allDay',
            width: '60px',
            render: data => (

                !isEditing(data)
                    ?
                    < Tag color={data?.allDay === true ? 'green' : 'red'} key={data.allDay} >
                        {data?.allDay === true ? 'Sim' : 'Não'}
                    </Tag >
                    :
                    <div className="sc-dxgOiQ cGTQcX">
                        <Checkbox checked={editUnit.allDay} onChange={(event) => {
                            let x = copyEditUnit();
                            x.allDay = event.target.checked;
                            x.startTime = null;
                            x.endTime = null;
                            setEditUnit(x);
                            setEditStartTime(null);
                            setEditEndTime(null);
                        }}>Todo o Dia</Checkbox>
                    </div>
            )
        },
        {
            title: 'Hora de Início',
            key: 'startTime',
            width: '80px',
            render: data => (
                !isEditing(data)
                    ?
                    <div>
                        {data.startTime ? moment(data.startTime).local().format('HH:mm') : null}
                    </div>
                    :
                    <TimePicker
                        type="time"
                        format='HH:mm'
                        value={editStartTime}
                        disabled={editUnit.allDay}
                        style={{ width: '100%', marginTop: 10 }}
                        placeholder='Selecione Hora'
                        onChange={(event) => {
                            setEditStartTime(event ? event.utc() : null)
                        }}
                    />
            )
        },
        {
            title: 'Hora de Fim',
            key: 'endTime',
            width: '80px',
            render: data => (
                !isEditing(data)
                    ?
                    <div>{data.endTime ? moment(data.endTime).local().format('HH:mm') : null}</div>
                    :
                    <TimePicker
                        type="time"
                        format='HH:mm'
                        disabled={editUnit.allDay}
                        value={editEndTime}
                        style={{ width: '100%', marginTop: 10 }}
                        placeholder='Selecione Hora'
                        onChange={(event) => {
                            setEditEndTime(event ? event.utc() : null)
                        }}
                    />
            )
        },
        {
            title: 'Local',
            key: 'weddingPlace',
            width: '130px',
            render: data => (
                !isEditing(data)
                    ?
                    data.weddingPlace === 3 ? 'Ambas' : data.weddingPlace === 2 ? 'Quinta Lago dos Cisnes' :
                        data.weddingPlace === 1 ? 'Solar da Levada' : ''
                    :
                    <SelectInput
                        placeholder="Escolher local"
                        data={locals}
                        input={{
                            value: editUnit.weddingPlace,
                            onChange: event => {
                                let x = copyEditUnit();
                                x.weddingPlace = parseInt(event);
                                setEditUnit(x)
                            }
                        }}
                        meta={{ valid: true }}
                    />
            )
        },
        {
            title: 'Local fechado',
            key: 'total',
            width: '100px',
            render: data => (

                !isEditing(data)
                    ?
                    < Tag color={data?.total === true ? 'green' : 'red'} key={data.total} >
                        {data?.total === true ? 'Sim' : 'Não'}
                    </Tag >
                    :
                    <div className="sc-dxgOiQ cGTQcX">
                        <Checkbox checked={editUnit.total} onChange={(event) => {
                            let x = copyEditUnit();
                            x.total = event.target.checked;
                            setEditUnit(x);
                        }}>Local fechado</Checkbox>
                    </div>
            )
        },
        {
            title: 'Acções',
            width: '120px',
            render: data => (
                <Fragment>
                    {!isEditing(data)
                        ?
                        <React.Fragment>
                            <TableButton onClick={e => setEditForm(data)}>
                                <Icon type="edit" />
                                {' Editar'}
                            </TableButton>
                            <TableButton onClick={e => e.stopPropagation()}>
                                <Popconfirm
                                    placement="topRight"
                                    title={RemoveMessage}
                                    okText="Remover"
                                    onConfirm={() => { deleteUnit(data.id) }}>
                                    <Icon type="delete" />
                                    {' Remover'}
                                </Popconfirm>
                            </TableButton>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <TableButton onClick={addEditUnit}>
                                <Icon type="save" />
                                {' Guardar'}
                            </TableButton>
                            <TableButton onClick={e => {
                                if (editUnit.id === null) {
                                    setEditUnit(null);
                                    setRows(rows.filter(f => f.id !== null));
                                } else setEditUnit(null);
                            }}>
                                <Icon type="close" />
                                {' Cancelar'}
                            </TableButton>
                        </React.Fragment>
                    }
                </Fragment>
            )
        }
    ]

    const columnsEmployee = [
        {
            title: 'Data Inicial',
            key: 'startDate',
            width: '130px',
            render: data => (
                <>
                    {
                        !isEditing(data)
                            ?
                            moment.utc(data.startDate).format('DD/MM/YYYY')
                            :
                            <DatePicker
                                value={editUnit.startDate}
                                format={'DD/MM/YYYY'}
                                placeholder='Selecione Data'
                                disabledDate={disabledDate}
                                onChange={(event) => {
                                    let x = copyEditUnit();
                                    x.startDate = event ? moment(event).utc(true) : null;
                                    setEditUnit(x)
                                }}
                                style={{ width: '100%', marginTop: 5 }} />
                    }
                </>
            )
        },
        {
            title: 'Data Final',
            key: 'endDate',
            width: '130px',
            render: data => (
                <>
                    {
                        !isEditing(data)
                            ?
                            moment.utc(data.endDate).format('DD/MM/YYYY')
                            :
                            <DatePicker
                                value={editUnit.endDate}
                                format={'DD/MM/YYYY'}
                                placeholder='Selecione Data'
                                disabledDate={disabledDate}
                                onChange={(event) => {
                                    let x = copyEditUnit();
                                    x.endDate = event ? moment(event).utc(true) : null;
                                    setEditUnit(x)
                                }}
                                style={{ width: '100%', marginTop: 5 }} />
                    }
                </>
            )
        },
        {
            title: 'Todo o Dia',
            key: 'allDay',
            width: '60px',
            render: data => (

                !isEditing(data)
                    ?
                    <Tag color={data?.allDay === true ? 'green' : 'red'} key={data.allDay} >
                        {data?.allDay === true ? 'Sim' : 'Não'}
                    </Tag>
                    :
                    <div className="sc-dxgOiQ cGTQcX">
                        <Checkbox checked={editUnit.allDay} onChange={(event) => {
                            let x = copyEditUnit();
                            x.allDay = event.target.checked;
                            x.startTime = null;
                            x.endTime = null;
                            setEditUnit(x);
                            setEditStartTime(null);
                            setEditEndTime(null);
                        }}>Todo o Dia</Checkbox>
                    </div>
            )
        },
        {
            title: 'Hora de Início',
            key: 'startTime',
            width: '80px',
            render: data => (
                !isEditing(data)
                    ?
                    <div>{data.startTime ? moment(data.startTime).local().format('HH:mm') : null}</div>
                    :
                    <TimePicker
                        type="time"
                        format='HH:mm'
                        value={editStartTime}
                        disabled={editUnit.allDay}
                        style={{ width: '100%', marginTop: 10 }}
                        placeholder='Selecione Hora'
                        onChange={(event) => {
                            setEditStartTime(event ? event.utc() : null)
                        }}
                    />
            )
        },
        {
            title: 'Hora de Fim',
            key: 'endTime',
            width: '80px',
            render: data => (
                !isEditing(data)
                    ?
                    <div>{data.endTime ? moment(data.endTime).local().format('HH:mm') : null}</div>
                    :
                    <TimePicker
                        type="time"
                        format='HH:mm'
                        disabled={editUnit.allDay}
                        value={editEndTime}
                        style={{ width: '100%', marginTop: 10 }}
                        placeholder='Selecione Hora'
                        onChange={(event) => {
                            setEditEndTime(event ? event.utc() : null)
                        }}
                    />
            )
        },
        {
            title: 'Acções',
            width: '120px',
            render: data => (
                <Fragment>
                    {!isEditing(data)
                        ?
                        <React.Fragment>
                            <TableButton onClick={e => setEditForm(data)}>
                                <Icon type="edit" />
                                {' Editar'}
                            </TableButton>
                            <TableButton onClick={e => e.stopPropagation()}>
                                <Popconfirm
                                    placement="topRight"
                                    title={RemoveMessage}
                                    okText="Remover"
                                    onConfirm={() => { deleteUnit(data.id) }}>
                                    <Icon type="delete" />
                                    {' Remover'}
                                </Popconfirm>
                            </TableButton>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <TableButton onClick={addEditUnit}>
                                <Icon type="save" />
                                {' Guardar'}
                            </TableButton>
                            <TableButton onClick={e => {
                                if (editUnit.id === null) {
                                    setEditUnit(null);
                                    setRows(rows.filter(f => f.id !== null));
                                } else setEditUnit(null);
                            }}>
                                <Icon type="close" />
                                {' Cancelar'}
                            </TableButton>
                        </React.Fragment>
                    }
                </Fragment>
            )
        }
    ]

    const copyEditUnit = (unit) => {
        let x = JSON.parse(JSON.stringify(unit ? unit : editUnit));
        x.startDate = x.startDate != null ? moment.utc(x.startDate) : null;
        x.endDate = x.endDate != null ? moment.utc(x.endDate) : null;
        x.startTime = x.startTime != null ? moment(x.startTime) : null;
        x.endTime = x.endTime != null ? moment(x.endTime) : null;
        x.weddingPlace = x.weddingPlace != null ? parseInt(x.weddingPlace) : null;

        return x;
    }

    useEffect(() => {
        if (!isCurrentUserAdmin()) {
            getEmployee();
        }
        updateDatatable();
    }, [])

    useEffect(() => {
        setCurrentPage(1);
        updateDatatable();
    }, [allDay, totalRest, endDate, startDate, local])

    useEffect(() => {
        updateDatatable();
    }, [currentPage, pageSize]);

    const disabledDate = (current) => {
        return employee?.availableRegisters && employee?.onlyWeekends && current
            && current.isoWeekday() !== 6
            && current.isoWeekday() !== 7;
    }

    const getEmployee = async () => {
        toogleLoadingEmployee(true);

        const resEmployee = await GetEmployeeByUser(getUserId());
        const employeeInfo = resEmployee.success && resEmployee?.data ? resEmployee.data : null;
        setEmployee(employeeInfo);
        setTitle((isCurrentUserEmployeeAttending() && employeeInfo?.availableRegisters) ? 'Dias Disponíveis' : 'Dias Indisponíveis');
        toogleLoadingEmployee(false);
    }

    const handleChangePage = cp => {
        setEditUnit(null);
        setCurrentPage(cp);
    }

    const handleChangeRowsPerPage = (currentSize, pageSize) => {
        setEditUnit(null);
        setCurrentPage(1);
        setPageSize(pageSize);
    }

    const isEditing = (unit) => {
        return editUnit ? editUnit.id === unit.id : false;
    }

    const setEditForm = (unit) => {
        let x = copyEditUnit(unit);
        setEditStartTime(x.startTime);
        setEditEndTime(x.endTime);
        setEditUnit(x);
    }

    const updateDatatable = async () => {
        setLoading(true);

        const filter = {};
        if (startDate) filter['startDate'] = moment.utc(startDate).toISOString();
        if (endDate) filter['endDate'] = moment.utc(endDate).toISOString();
        if (allDay !== null && allDay !== '') filter['allDay'] = allDay;
        if (local !== null && local !== '') filter['weddingPlace'] = local;
        if (totalRest !== null && totalRest !== '') filter['total'] = totalRest;

        if ((isEmployeeOrChief() || isCurrentUserHumanResources())) {
            filter['employee'] = getUserId();
        } else {
            if (isCouplePaymentDays) {
                filter['exceptionDayForCouplePayment'] = true;
                filter['companyRest'] = false;
            } else {
                filter['exceptionDayForCouplePayment'] = false;
                filter['companyRest'] = true;
            }
        }

        const result = await GetUnavailable(currentPage, pageSize, JSON.stringify(filter));
        // console.warn('Res', result);

        const rowsData = result.data && result.data.items && result.data.items.length > 0 ? result.data.items : [];

        for (let index = 0; index < rowsData.length; index++) {
            if (rowsData[index].startTime) rowsData[index].startTime = moment.utc(rowsData[index].startTime, 'HH:mm');
            if (rowsData[index].endTime) rowsData[index].endTime = moment.utc(rowsData[index].endTime, 'HH:mm');
        }

        setRows(rowsData);
        setTotal(result.data && result.data.total ? result.data.total : 0);
        setLoading(false)
    }

    const RemoveMessage = (
        <div>
            <div>Tem a certeza que quer remover esse dia? </div>
            <div>
                Toda a informação será removida da plataforma e não conseguirá ser recuperada.
            </div>
        </div>
    );

    const setAddForm = () => {
        const unit = {
            id: null,
            startDate: null,
            endDate: null,
            allDay: false,
            startTime: null,
            endTime: null,
            total: isRestDays ? false : true,
            availableEmployee: isCurrentUserEmployeeAttending() && employee?.availableRegisters ? true : false,
            companyRest: isEmployeeOrChief() || isCurrentUserHumanResources() || isCouplePaymentDays ? false : true,
            exceptionDayForCouplePayment: isEmployeeOrChief() || isCurrentUserHumanResources() ? false : isCouplePaymentDays,
            user: getUserId(),
            employee: employee && employee?._id ? employee._id : null,
            weddingPlace: null
        };
        if (!editUnit) {
            setEditUnit(unit);
            let rowsUpdate = [...rows];
            rowsUpdate.splice(rows?.length - 1, 1);
            rowsUpdate.unshift(unit);
            setRows(rowsUpdate);
        }
    }

    const addEditUnit = async () => {
        const unit = editUnit;
        const startTime = editStartTime;
        const endTime = editEndTime;

        if (isEmployeeOrChief()
            || isCurrentUserHumanResources()
            || isCurrentUserEmployeeAttending()) {
            unit.weddingPlace = 3;
        }

        if (unit?.weddingPlace == null) {
            Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Deve selecionar o local.'
            });
            return
        }

        if (unit?.startDate == null) {
            Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Deve inserir a data inicial.'
            });
            return
        }
        if (unit?.endDate == null) {
            Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Deve inserir a data final.'
            });
            return
        }

        let temp1 = moment.utc(unit.startDate);
        let temp2 = moment.utc(unit.endDate);

        if (temp2.isBefore(temp1, 'day')) {
            Alert.new({
                type: "error",
                title: "Erro!",
                text: 'A data final deve ser igual ou superior à data inicial.'
            });
            return
        }

        if (employee?.availableRegisters && employee?.onlyWeekends) {
            const selectedWeekdays = checkWeekdaysBetweenDates(unit.startDate, unit.endDate);
            if (selectedWeekdays?.length > 0) {
                return Alert.new({
                    type: "error",
                    title: "Erro!",
                    text: 'Não pode selecionar um intervalo que inclua dias da semana.'
                });
            }
        }

        if (unit?.allDay == false && startTime == null) {
            Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Deve inserir a hora inicial.'
            });
            return
        }

        if (unit?.allDay == false && endTime == null) {
            Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Deve inserir a hora final.'
            });
            return
        }

        if (unit?.allDay == false && startTime && endTime
            && startTime >= endTime
            && temp2.isSame(temp1, 'day')) {
            Alert.new({
                type: "error",
                title: "Erro!",
                text: 'A hora final deve ser superior à hora inicial.'
            });
            return
        }

        // if (unit.allDay) {
        //     unit.startDate = moment(unit.startDate).startOf('day').toISOString();
        //     unit.endDate = moment(unit.endDate).endOf('day').toISOString();
        // } else {
        //     unit.startDate = moment(moment(unit.startDate).format('YYYY-MM-DD') + 'T' + moment(unit.startTime).format('HH:mm:SS')).toISOString();
        //     unit.endDate = moment(moment(unit.endDate).format('YYYY-MM-DD') + 'T' + moment(unit.endTime).format('HH:mm:SS')).toISOString();
        // }
        // console.warn('Unit', unit);

        const data = {
            id: unit.id,
            startDate: moment.utc(unit.startDate).startOf('day'),
            endDate: moment.utc(unit.endDate).endOf('day'),
            startTime: !unit?.allDay && startTime ? moment(startTime).utc().format('HH:mm') : null,
            endTime: !unit?.allDay && endTime ? moment(endTime).utc().format('HH:mm') : null,
            allDay: unit.allDay,
            total: unit?.total,
            availableEmployee: unit.availableEmployee,
            companyRest: unit.companyRest,
            exceptionDayForCouplePayment: unit.exceptionDayForCouplePayment,
            user: unit.user,
            employee: unit.employee,
            weddingPlace: unit.weddingPlace
        };
        // console.warn('data', data);

        const result = isCouplePaymentDays
            ? data.id
                ? await UpdateExceptionCouplePayment(data)
                : await CreateExceptionCouplePayment(data)
            : data.id
                ? await UpdateUnavailable(data)
                : await CreateUnavailable(data);

        if (result?.success) {
            setEditUnit(null);
            setEditStartTime(null);
            setEditEndTime(null);
            updateDatatable();
        }
    }

    const deleteUnit = async id => {
        await RemoveUnavailable(id);
        updateDatatable();
    }

    const goBack = () => {
        fromReserveAgenda
            ? history.push('/reserves-agenda')
            : fromReservePriceAgenda
                ? history.push('/reserves-prices')
                : isCurrentUserEmployeeAttending()
                    ? history.push('/home-attending')
                    : isEmployeeOrChief() || isCurrentUserHumanResources()
                        ? history.push('/calendar')
                        : history.push('/weddings-calendar')
    }

    const onSelectCalendarMenu = (value) => {
        const itemSelected = parseInt(value.key);
        if (itemSelected === 1) {
            const url = fromReservePriceAgenda
                ? '/rest-days?page=RP'
                : fromReserveAgenda
                    ? '/rest-days?page=RA'
                    : '/rest-days';
            history.push(url);
        } else if (itemSelected === 2) {
            const url = fromReservePriceAgenda
                ? '/payment-days?page=RP'
                : fromReserveAgenda
                    ? '/payment-days?page=RA'
                    : '/payment-days';
            history.push(url);
        } else if (itemSelected === 3) {
            const url = fromReservePriceAgenda
                ? '/couple-payment-days?page=RP'
                : fromReserveAgenda
                    ? '/couple-payment-days?page=RA'
                    : '/couple-payment-days';
            history.push(url);
        } else goBack();
    }

    const menu = (
        <Menu onClick={onSelectCalendarMenu}>
            {!isRestDays && <Menu.Item key={1}>
                <Icon type="calendar" />
                Dias de Descanso
            </Menu.Item>}
            {!isPaymentDays && <Menu.Item key={2}>
                <Icon type="euro" />
                Dias de Pagamento
            </Menu.Item>}
            {!isCouplePaymentDays && <Menu.Item key={3} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                <Icon type="sketch" />
                <span style={{ display: 'flex', flexDirection: 'column', alignContent: 'flex-start', alignItems: 'flex-start' }}>
                    <span>Dias de Exceção</span>
                    <span>Pagamento Noivos</span>
                </span>
            </Menu.Item>}
            <Menu.Item key={4}>
                <Icon type="arrow-left" />
                Voltar
            </Menu.Item>
        </Menu>
    );

    const countFilters = () => {
        let count = 0;
        if (startDate) count++;
        if (endDate) count++;
        if (local) count++;
        return count;
    }

    return (
        <React.Fragment>
            <HeaderContainer>
                <HeaderTitle buttons={1}>
                    <PageTitle>{title}</PageTitle>
                    {!isCurrentUserEmployeeAttending() && <Breadcrumb>
                        <Breadcrumb.Item>
                            <a href={goBack}>
                                {fromReserveAgenda ? 'Agenda de Reservas' : fromReservePriceAgenda ? 'Agenda de Preços das Reservas' : 'Calendário'}
                            </a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{title}</Breadcrumb.Item>
                    </Breadcrumb>}
                </HeaderTitle>
                <HeaderButtonsContainer buttons={1}>
                    {isCurrentUserAdmin() ?
                        <Dropdown overlay={menu} trigger={['click']}>
                            <BaseButton
                                type={'primary'}
                                icon={'down'}
                                text={'Opções'}
                            />
                        </Dropdown>
                        : <BaseButton
                            type="default"
                            icon="arrow-left"
                            text="Voltar"
                            block
                            onClick={goBack}
                        />
                    }
                </HeaderButtonsContainer>
            </HeaderContainer>

            <PageContainer buttons={1}>
                <Row gutter={[12, 12]} style={{ marginBottom: '10px', textAlign: 'left' }}>
                    {showFilter ? <React.Fragment>
                        <Col xs={24} sm={6} md={5} lg={3}>
                            <XpertGoDateInput
                                label='Data Inicial'
                                input={{
                                    value: startDate,
                                    onChange: value => {
                                        if (endDate !== null && moment.utc(value).isAfter(endDate)) {
                                            setStartDate(null);
                                            return Alert.new({
                                                type: "error",
                                                title: "Erro!",
                                                text: 'A data inicial deve ser anterior à data final.'
                                            });
                                        } else setStartDate(value);
                                    }
                                }}
                                meta={{}}
                            />
                        </Col>
                        <Col xs={24} sm={6} md={5} lg={3}>
                            <XpertGoDateInput
                                label='Data Final'
                                input={{
                                    value: endDate,
                                    onChange: value => {
                                        if (startDate !== null && moment.utc(value).isBefore(startDate)) {
                                            setEndDate(null);
                                            return Alert.new({
                                                type: "error",
                                                title: "Erro!",
                                                text: 'A data final deve ser posterior à data inicial.'
                                            });
                                        } else setEndDate(value);
                                    }
                                }}
                                meta={{}}
                            />
                        </Col>
                        <Col xs={24} sm={5} md={3} lg={2}>
                            <SelectInput
                                label='Todo o dia'
                                allowClear={true}
                                data={allDayOptions}
                                input={{
                                    value: allDay,
                                    onChange: value => setAllDay(value)
                                }}
                                meta={{}}
                            />
                        </Col>
                        {isRestDays && <Col xs={24} sm={5} md={4} lg={3}>
                            <SelectInput
                                label='Local fechado'
                                allowClear={true}
                                data={allDayOptions}
                                input={{
                                    value: totalRest,
                                    onChange: value => setTotalRest(value)
                                }}
                                meta={{}}
                            />
                        </Col>}
                        <Col xs={24} sm={6} md={6} lg={3}>
                            <SelectInput
                                label='Local'
                                allowClear={true}
                                data={locals}
                                input={{
                                    value: local,
                                    onChange: value => setLocal(value)
                                }}
                                meta={{}}
                            />
                        </Col>
                    </React.Fragment>
                        : null}
                    <Col
                        xs={24}
                        sm={showFilter && isRestDays ? 17 : showFilter && !isRestDays ? 4 : 24}
                        md={showFilter && isRestDays ? 24 : showFilter && !isRestDays ? 6 : 24}
                        lg={showFilter && isRestDays ? 10 : showFilter && !isRestDays ? 13 : 24}
                        className='btn-rest-days'>
                        {countFilters() > 0
                            ? <Badge count={countFilters()} style={{ margin: '22px 15px 0 0', width: 15, minWidth: 15, height: 15, padding: 0, lineHeight: '15px' }}>
                                <Icon type='filter' style={{ margin: '25px 15px 0 0', float: 'left', fontSize: '1.2rem' }} onClick={() => setShowFilter(!showFilter)}></Icon>
                            </Badge>
                            : <Icon type='filter' style={{ margin: '25px 15px 0 0', float: 'left', fontSize: '1.2rem' }} onClick={() => setShowFilter(!showFilter)}></Icon>
                        }
                        <BaseButton
                            type="primary"
                            icon="plus"
                            text={"Adicionar Dia"}
                            disabled={isCurrentUserEmployee() ? loadingEmployee : false}
                            style={{ marginTop: 18 }}
                            onClick={setAddForm}
                        />
                    </Col>
                </Row>

                <Table
                    columns={isEmployeeOrChief() || isCurrentUserHumanResources() ? columnsEmployee : isRestDays ? columnsRestDays : columns}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    defaultPageSize={pageSize}
                    loading={loading || loadingEmployee}
                    rows={rows}
                    showHeader={true}
                    emptyIcon="calendar"
                    emptyText={'Não existem dados'}
                    total={total}
                    rowKey={'_id'}
                    hasPagination={true}
                    hasPageSize={true}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </PageContainer>
        </React.Fragment>
    );
};

export default withRouter(RestDays);