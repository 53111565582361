import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../components/inputs/TextInput';
import FormValidator from '../../infra/services/validations/FormValidator';
import BaseButton from '../../components/buttons/BaseButton';
import Logo from '../../assets/icons/logo.svg';
import {
  LoginContainer,
  LoginCard,
  LoginForm,
  LogoImg,
  LogoSection,
  LoginButtonDiv,
  FormTitle,
  BaseLink
} from './LoginPageStyles';
import { Icon, notification } from 'antd';
import { RecoverAccount } from '../../infra/requests/AuthRequests';

const formRules = FormValidator.make({
  email: 'required|email'
});

class RecoverAccountPage extends Component {
  onSubmit = async values => {
    const { history } = this.props;
    try {
      const { success } = await RecoverAccount(values);
      if (success) {
        notification.success({
          message: 'Request sent!',
          description: 'You will receive an email to define a new password.'
        });
        history.push('/login');
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit(this.onSubmit)}>
          <LoginCard>
            <LogoSection>
              <LogoImg src={Logo} alt="logo" />
            </LogoSection>
            <FormTitle>Recover Account</FormTitle>
            <Field
              component={TextInput}
              name="email"
              type="email"
              label="Email"
              placeholder="Insert your email"
              prefix={<Icon type="mail" />}
            />
            <BaseLink to="/login">Login</BaseLink>
            <LoginButtonDiv>
              <BaseButton
                variant="raised"
                htmlType="submit"
                type="primary"
                loading={submitting}
                text="Send email"
              />
            </LoginButtonDiv>
          </LoginCard>
        </LoginForm>
      </LoginContainer>
    );
  }
}

export default reduxForm({
  form: 'recover_account',
  validate: formRules
})(RecoverAccountPage);
