import styled from 'styled-components';
import { device } from '../../styles/Responsive';

export const TimelineContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 30px 0px;

  @media ${device.tabletL} {
    display: none;
  }
`;

export const Column1 = styled.div`
  flex-basis: 50%;
  padding: 108px 0px;
`;

export const Column2 = styled.div`
  flex-basis: 50%;
  padding-top: 145px;
  padding-bottom: 105px;
  position: relative;
  border-left: 1px dashed #E0E0E0;
`;

export const Dot = styled.div`
  background-color: #E7E7E7;
  border-radius: 100%;
  width: ${p => p.isFirst || p.isLast ? '9px' : '5px'};
  height: ${p => p.isFirst || p.isLast ? '9px' : '5px'};
  position: absolute;
  left: ${p => p.isFirst || p.isLast ? '-5px' : '-3px'};
  top: ${p => `${p.top}px`};

  @media ${device.mobileL} {
    display: none;
  }
`;

export const TimelineMobileContainer = styled.div`
  width: 100%;
  display: none;
  flex-direction: row;
  justify-content: center;
  margin: 50px 0px;

  @media ${device.tabletL} {
    display: flex;
  }
`;

export const MobileColumn = styled.div`
  flex-basis: 100%;
  padding: 108px 0px;
  margin: 0 15px;
  position: relative;
  border-left: 1px dashed #E0E0E0;

  @media ${device.tabletL} {
    padding-bottom: 105px;
  }

  @media ${device.mobileL} {
    border-left: 0;
    margin: 0 10px;
    padding: 30px 0px;
  }
`;


export const Container = styled.div`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 30px;

  @media ${device.tablet} {
    max-width: 100%;
  }
`;

export const EmptyTimeline = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  opacity: 0.5;
  margin: 50px 0px;
  padding: 0px 15px;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
  }

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 22px;
  }

  @media ${device.mobileL} {
    padding: 0px 10px;
  }
`;

// overflow-y: auto;
// height: 100%;
export const TimelineDiv = styled.div`
  display: inline-block;
  width: ${({ notesOpen }) => notesOpen ? 'calc(100% - 300px)' : '100%'};
  padding: 0 15px;
`;