import React from "react";
import ReactPlayer from "react-player";
import { VideoCard } from "./VideoStyles";
import { Popconfirm, Icon } from "antd";

const VideoComponent = ({ video, deleteVideo }) => {
  return (
    <VideoCard key={video._id}>
      <ReactPlayer
        url={video.url}
        controls
        width="100%"
        height="calc(100% - 30px)"
        style={{ backgroundColor: "#000" }}
        config={{
          youtube: {
            embedOptions: { width: "100%", height: "100%" }
          }
        }}
      />
      <div style={{ padding: "10px" }}>
        <Popconfirm
          title="Deseja apagar este video?"
          okText="Sim"
          cancelText="Não"
          onConfirm={() => deleteVideo(video._id)}
        >
          <Icon type="delete" theme="filled" />
        </Popconfirm>
      </div>
    </VideoCard>
  );
};

export default VideoComponent;
