import React, { useEffect, useState } from 'react';
import { Alert, Icon } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { UploadBackgroundRoomPlan } from '../../../infra/requests/ConfigsRequests';
import { updateBackgroundRoomPlan } from '../../../redux/Info/info.actions';
import { showLocal } from '../../../infra/services/wedding/weddingUtils';
import { FileName, FileZone, FloatButton, FloatButtonBg, FloatButtonExternalBg, Label, Section, SectionBg } from '../../../components/roomPlans/RoomPlanStyles';
import ExternalLinkIcon from './../../../assets/icons/external_link.svg';
import { CreateRoomPlanConfig, GetRoomPlanConfigs } from '../../../infra/requests/RoomPlanConfigRequests';
import Alerts from '../../../components/alert/Alert';
import ConfigRoomPlanModal from './ConfigRoomPlanModal';
import ReactSVG from 'react-svg';

const ManageBackgroundRoomPlans = ({ configs, updateBackgroundRoomPlan, history }) => {
  const [loading, setLoading] = useState(false);
  const [loadingPlans, setLoadingPlans] = useState(false);
  const [configRoomPlans, setConfigRoomPlans] = useState([]);
  const [showModal, toggleModal] = useState(false);
  const [modalValues, setModalValues] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    getRoomPlanConfigs();
  }, []);

  // const handleDrop = async (id, accepted) => {
  //   if (accepted[0]) {
  //     setLoading(true);
  //     const payload = new FormData();
  //     payload.append('file', accepted[0]);
  //     const { data } = await UploadBackgroundRoomPlan(id, payload);
  //     updateBackgroundRoomPlan(id, data);
  //     setLoading(false);
  //   }
  // };

  // Room Plan Configs
  const getRoomPlanConfigs = async () => {
    setLoadingPlans(true);
    try {
      const result = await GetRoomPlanConfigs();

      setConfigRoomPlans(result?.success && result?.data ? result?.data : []);
      setLoadingPlans(false);
      toggleModal(false);
    } catch (e) {
      setLoadingPlans(false);
      console.error(e);
    }
  }

  const openModal = (local) => {
    setModalValues({
      weddingPlace: local
    });
    toggleModal(true);
  }

  const submitConfigRoomPlan = async (values) => {
    setSaving(true);
    const { success, data } = await CreateRoomPlanConfig(values);

    if (success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Plano adicionado'
      });
      setSaving(false);
      closeModal();
      getRoomPlanConfigs();
    } else {
      setSaving(false);
    }
  }

  const closeModal = () => {
    setModalValues({});
    toggleModal(false);
  }

  return (
    <React.Fragment>
      {configs.map(config => {
        return (
          <React.Fragment>
            <SectionBg key={config._id}>
              <Label>{showLocal(config.local)}</Label>
              <FileZone>
                <FileName>{config?.bgRoomPlan?.filename}</FileName>
                {configRoomPlans?.filter(f => f?.weddingPlace == config.local)
                  .map(m => (
                    <FloatButtonExternalBg key={m?._id} onClick={() => history.push(`/settings/room-plan/${m?._id}`)}>
                      <div className='btn'>{m?.name}</div>
                      <div className='icon'>
                        <ReactSVG src={ExternalLinkIcon} />
                      </div>
                    </FloatButtonExternalBg>
                  ))}
                <FloatButtonBg onClick={() => openModal(config.local)}>
                  Criar plano
                </FloatButtonBg>
                {/* <FloatButtonBg disable={loading}>
                <Dropzone
                  disabled={loading}
                  onDrop={(accepted, rejected) =>
                    handleDrop(config._id, accepted, rejected)
                  }>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      {loading ? <Icon type="loading" /> : 'Alterar'}
                    </div>
                  )}
                </Dropzone>
              </FloatButtonBg>
              <FloatButtonBg
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_IMAGES_URL}${config?.bgRoomPlan?._id}`,
                    '_blank'
                  )
                }>
                Ver
              </FloatButtonBg> */}
              </FileZone>
            </SectionBg>


          </React.Fragment>
        );
      })}

      {showModal && <ConfigRoomPlanModal
        isOpen={showModal}
        loading={saving}
        initialValues={modalValues}
        onSubmit={submitConfigRoomPlan}
        onClose={closeModal} />
      }
    </React.Fragment>
  )

};

const mapStateToProps = state => ({
  configs: state.info.configs
});

const mapActionToProps = dispatch =>
  bindActionCreators({ updateBackgroundRoomPlan }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(ManageBackgroundRoomPlans);
