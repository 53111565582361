import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  TimelineItemContainer,
  ItemContent,
  Dot,
  EmptyItem,
  ItemNameContainer,
  ItemName,
  ItemTime,
  TimelineItemContainerMobile,
  ItemNameContainerMobile,
  ItemNameMobile,
  ItemTimeMobile,
  ItemAnswersContainer,
  ItemAnswersContent,
  ItemAnswerContainer,
  ItemAnswer,
  ItemDelete,
  ItemEdit,
  ItemActionsMobile,
  ItemDeleteMobile,
  ItemEditMobile,
  TimelineItemContentMobile
} from './TimelineItemStyles';
import { findMoment } from "../../process/our_day/Categories";
import { findMomentAnswers } from "../../infra/services/ourDay/PageOurDay";
import OutsideAlerter from "../outsideAlerter/OutsideAlerter";
import {Icon, Popconfirm} from 'antd';

const RemoveMessage = (
  <div>
    <div>Tem a certeza que quer remover este momento?</div>
  </div>
);

const RemovePrintedMessage = (
  <div>
    <div>Tem a certeza que quer remover este momento?</div>
    <b>Este processo já se encontra impresso!</b>
  </div>
);

const TimelineItem = ({ id, name, time, required, requiredOrderModule, direction, active, zIndex, deleteMoment, openEditModal, toggleActive, closeActive, answersInfo, food, colors, printed }) => {
  const [momentAnswers, saveMomentAnswers] = useState([]);

  const checkItem = () => {
    let momentAnswers = [];

    //Check if the Moment is one of the defaults. If so return the code
    const moment = findMoment(name);

    if(moment?.question?.id) {
      momentAnswers = findMomentAnswers(moment.question, answersInfo, food, colors);
    }

    saveMomentAnswers(momentAnswers);
    toggleActive(id);
  }

  const prepareDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  const confirmDelete = (id) => {
    deleteMoment(id);
  }

  const truncate = (name) => {
    return name.length > 30 ? `${name.substring(0, 30)}...` : name;
  }

  return (
    name ?
    <OutsideAlerter section='OUR_DAY' close={closeActive}>
      <React.Fragment>
        <TimelineItemContainer zIndex={zIndex} direction={direction} onClick={() => checkItem()}>
          <ItemContent direction={direction} active={active}>
            <ItemDelete 
              className='itemDelete' 
              direction={direction} 
              active={active} 
              onClick={(e) => prepareDelete(e)}
              requiredOrderModule={requiredOrderModule}
            >
              <Popconfirm
                placement={'top'}
                title={printed ? RemovePrintedMessage : RemoveMessage}
                onConfirm={() => confirmDelete(id)}>
                <Icon type="delete" />
              </Popconfirm>
            </ItemDelete>
            <ItemEdit 
              className='itemEdit' 
              direction={direction} 
              active={active} 
              onClick={(e) => openEditModal(e, id)}
              requiredOrderModule={requiredOrderModule}
            >
              <Icon type="edit" />
            </ItemEdit>
            <ItemNameContainer className='itemNameContainer' direction={direction} active={active}>
              <ItemName 
                className='itemName' 
                active={active}
                required={required} 
                requiredOrderModule={requiredOrderModule}
              >
                {truncate(name)} {required || requiredOrderModule ? '*' : ''}
              </ItemName>
            </ItemNameContainer>
            <ItemTime 
              className='itemTime' 
              active={active}
              required={required} 
              requiredOrderModule={requiredOrderModule}
            >
              {time}
            </ItemTime>
          </ItemContent>
          <Dot active={active} direction={direction}/>
          <ItemAnswersContainer active={active} hasAnswers={momentAnswers.length > 0} direction={direction}>
            <ItemAnswersContent>
              {
                momentAnswers.map((answer, index) => 
                  <ItemAnswerContainer key={index}>
                    <ItemAnswer>
                      {
                        answer.title ? answer.title['pt'] : answer.name ? answer.name['pt'] : answer ? answer : null
                      }
                    </ItemAnswer>
                  </ItemAnswerContainer>
                )
              }
            </ItemAnswersContent>
          </ItemAnswersContainer>
        </TimelineItemContainer>
        <TimelineItemContainerMobile zIndex={zIndex} active={active} onClick={() => checkItem()}>
          <TimelineItemContentMobile>
            <ItemNameContainerMobile active={active} className='itemNameContainer'>
              <ItemNameMobile 
                active={active} 
                className='itemName'
                required={required} 
                requiredOrderModule={requiredOrderModule}
              >
                {truncate(name)} {required || requiredOrderModule ? '*' : ''}
              </ItemNameMobile>
              <ItemTimeMobile 
                active={active} 
                className='itemTime'
                required={required} 
                requiredOrderModule={requiredOrderModule}
              >
                {time}
              </ItemTimeMobile>
            </ItemNameContainerMobile>
          </TimelineItemContentMobile>
          <ItemActionsMobile>
            <ItemEditMobile 
              className='itemEdit' 
              direction={direction} 
              active={active} 
              onClick={(e) => openEditModal(e, id)}
              requiredOrderModule={requiredOrderModule}
            >
              <Icon type="edit" />
            </ItemEditMobile>
            <ItemDeleteMobile 
              className='itemDelete' 
              direction={direction} 
              active={active} 
              onClick={(e) => prepareDelete(e)}
              requiredOrderModule={requiredOrderModule}
            >
              <Popconfirm
                placement={'topRight'}
                title="Tem a certeza que quer remover este momento?"
                onConfirm={() => confirmDelete(id)}>
                <Icon type="delete" />
              </Popconfirm>
            </ItemDeleteMobile>
          </ItemActionsMobile>
          <ItemAnswersContainer active={active} hasAnswers={momentAnswers.length > 0} direction={direction}>
            <ItemAnswersContent>
              {
                momentAnswers.map((answer, index) => 
                  <ItemAnswerContainer key={index}>
                    <ItemAnswer>
                      {
                        answer.title ? answer.title['pt'] : answer.name ? answer.name['pt'] : answer ? answer : null
                      }
                    </ItemAnswer>
                  </ItemAnswerContainer>
                )
              }
            </ItemAnswersContent>
          </ItemAnswersContainer>
        </TimelineItemContainerMobile>
      </React.Fragment>
    </OutsideAlerter>
    :
    <EmptyItem/>
  );
}

const mapStateToProps = state => ({
  food: state.info.food,
  colors: state.info.colours,
  answersInfo: state.wedding.structure
});

export default connect(mapStateToProps)(TimelineItem);