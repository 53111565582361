import React, { Component, Fragment, useRef } from 'react';
import { Avatar, Icon, Checkbox, Row, Col, Result, Input, InputNumber, Modal } from 'antd';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton,
  SectionTitle
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import moment from 'moment';
import { GetColorSystemByType } from '../../infra/requests/ColorSystemRequests';
import { GetPaymentsByEmployee, GetPaymentsByEmployeeAndPaymentAccount, GetPaymentsGroupedByEmployee, UpdateStatusAndEndDate, UpdateStatusAndPaymentAccount } from '../../infra/requests/PaymentRequests';
import Alert from "../../components/alert/Alert";
import { GetEmployee, GetEmployeeByUser } from '../../infra/requests/EmployeeRequests';
import { LocalLabel, StateLabel } from '../weddings/Styles';
import { getCurrencyNumber, getLocalColor, setDeclaration } from '../../infra/services/wedding/weddingUtils';
import NoAvatar from '../../assets/no-avatar.png';
import { ApprovePaymentAccounts, CreatePaymentAccount, CreatePaymentAccountByEmployee, DeletePaymentAccount, MarkPaymentAccountAsPaid, RemoveMarkPaymentAccountAsPaid, RemoveReceipt, SendEmailUpdateApproved, UpdatePaymentAccount, UpdatePaymentAccountApproved, UpdatePaymentAccountDeclaration, UpdateReceipt } from '../../infra/requests/PaymentAccountRequests';
import { getCurrentUser, isCurrentUserAdmin, isCurrentUserChief, isCurrentUserEmployee, isCurrentUserHumanResources, isEmployeeOrChief } from '../../infra/helpers/Helpers';
import ModalPDF from '../employees/ModalPDF';
import ModalPaymentDeclaration from './ModalPaymentDeclaration';
import { GetSettingsByType } from '../../infra/requests/SettingRequests';
import PaymentTotals from './PaymentTotals';
import NumberInput from '../../components/inputs/NumberInput';
import ModalPaymentAccount from './ModalPaymentAccount';
import { GetTextColor } from '../../infra/services/text/textUtils';
import Alerts from '../../components/alert/Alert';
import { ReceiptName } from './PendingPayments';

const REACT_APP_IMAGES_URL = process.env.REACT_APP_IMAGES_URL;

const { confirm } = Modal;

class PaymentsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    currentEmployee: null,
    employee: null,
    paymentAccount: null,
    total: { value: 0, bonus: 0 },
    weddings: [],
    dateInterval: null,
    showPreviewPDF: false,
    showDeclarationModal: false,
    modalValues: null,
    declaration: null,
    selectAllRows: false,

    columnsToPay: [
      {
        title: ' ',
        width: '30px',
        render: (data) => (
          <div className="sc-dxgOiQ fWHHkk" style={{ textAlign: 'center' }}>
            <Checkbox
              checked={data.checked}
              onChange={(e) => {
                let { rows } = this.state;
                const index = rows.findIndex(f => f.id === data.id);
                data.checked = e.target.checked;
                rows[index] = data;
                this.setState({ rows });
              }}>
            </Checkbox>
          </div>
        )
      },
      {
        title: 'Data',
        width: '90px',
        render: data => moment.utc(data.weddingDate).format('DD-MM-YYYY')
      },
      {
        title: 'Local',
        key: 'local',
        render: data => {
          const local = getLocalColor(data?.wedding, this.state.colors);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
        }
      },
      {
        title: 'Oficial',
        render: data => {
          const officialPer = this.state.updatePercentage !== '' && this.state.updatePercentage !== null
            ? (this.state.updatePercentage / 100)
            : data.officialPercentage;
          const value = Math.round((data.value + data.bonus) * officialPer);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (officialPer * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Bónus',
        render: data => {
          const notOfficialPer = this.state.updatePercentage !== '' && this.state.updatePercentage !== null
            ? 1 - (this.state.updatePercentage / 100)
            : 1 - data.officialPercentage;
          const value = Math.round((data.value + data.bonus) * notOfficialPer);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (notOfficialPer * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Total',
        render: data => {
          const value = Math.round(data.value + data.bonus);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {getCurrencyNumber(value)}
          </div>
        },
      },
    ],
    columnsPayed: [
      {
        title: 'Data',
        width: '90px',
        render: data => {
          // moment(data.startDate).format('DD-MM-YYYY')
          return moment.utc(data.weddingDate).format('DD-MM-YYYY')
        }
      },
      {
        title: 'Local',
        key: 'local',
        render: data => {
          const local = getLocalColor(data?.wedding, this.state.colors);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
        }
      },
      {
        title: 'Oficial',
        render: data => {
          const officialPer = data.officialPercentage;
          const value = Math.round((data.value + data.bonus) * officialPer);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (officialPer * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Bónus',
        render: data => {
          const notOfficialPer = 1 - data.officialPercentage;
          const value = Math.round((data.value + data.bonus) * notOfficialPer);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (notOfficialPer * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Total',
        render: data => {
          const value = Math.round(data.value + data.bonus);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {getCurrencyNumber(value)}
          </div>
        },
      },
    ],
    rows: [],
    colors: {
      colorSys1: null,
      colorSys2: null
    },
    totalValue: 0,
    totalBonus: 0,
    updatePercentage: null,

    openPaymentAccountModal: false,
  };

  componentDidMount = async () => {
    const localUser = getCurrentUser();
    const resEmployee = isCurrentUserChief() || isCurrentUserEmployee()
      ? await GetEmployeeByUser(localUser._id)
      : null;
    this.setState({ currentEmployee: resEmployee && resEmployee.success && resEmployee.data ? resEmployee.data : null });
    await this.updateDataTable();
    this.getLocalColors();
    await this.getDeclaration()

  };

  getDeclaration = async () => {
    const result = await GetSettingsByType(5);
    this.setState({ declaration: result.success && result.data.length > 0 ? result.data[0] : null });
  }

  getLocalColors = async () => {
    const resColorSys1 = await GetColorSystemByType(4);
    const colorSys1 = resColorSys1.success && resColorSys1.data ? resColorSys1.data.color : '#f7b5d6';
    const resColorSys2 = await GetColorSystemByType(5);
    const colorSys2 = resColorSys2.success && resColorSys2.data ? resColorSys2.data.color : '#b6d7a9';
    this.setState({ colors: { colorSys1, colorSys2 } });
  }

  updateDataTable = async () => {
    this.setState({ loading: true });
    let { totalValue, totalBonus } = this.state;

    const employeeId = this.props.match.params.employeeId;
    const paymentAccountId = this.props.match.params.paymentAccountId;
    const status = this.props.match.params.status;
    const entity = this.props.match.params.entity;
    const startDate = this.props.match.params.startDate;
    const endDate = this.props.match.params.endDate;
    // console.warn('Params', this.props.match.params);

    try {
      let filter = { status: status };
      if (startDate !== 'null') filter['startDate'] = moment.utc(parseInt(startDate)).startOf('day');
      if (endDate !== 'null') filter['endDate'] = moment.utc(parseInt(endDate)).endOf('day');

      let result;
      if (parseInt(status) === 1) {
        result = await GetPaymentsByEmployee(employeeId, entity, JSON.stringify(filter));
      } else {
        result = await GetPaymentsByEmployeeAndPaymentAccount(employeeId, paymentAccountId, status);
      }

      let resPayments = [];
      if (result.success && result.data) {
        resPayments = result.data.payments.map((element) => {
          totalValue = totalValue + element.value;
          totalBonus = totalBonus + element.bonus;

          return {
            ...element,
            checked: false
          }
        });
      }

      //console.warn('Result DEtail', result);

      let paymentAccount = null;
      if (result.success && result.data) {
        paymentAccount = result.data.paymentAccount;
        if (paymentAccount) paymentAccount.employee = result.data.employee;
      }
      if (paymentAccount && paymentAccount.receipt === null) paymentAccount.receipt = '';

      this.setState({
        employee: result.success && result.data ? result.data.employee : null,
        paymentAccount: paymentAccount,
        weddings: result.success && result.data ? result.data.weddings : [],
        total: result.success && result.data ? { value: result.data.totalValue, bonus: result.data.totalBonus } : { value: 0, bonus: 0 },
        dateInterval: result.success && result.data ? result.data.dateInterval : null,
        rows: resPayments,
        totalValue: totalValue,
        totalBonus: totalBonus,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  getIntervalDate = () => {
    const { dateInterval } = this.state;
    const dtFormat = 'DD-MM-YYYY';
    const start = moment.utc(dateInterval.start).format(dtFormat);
    const end = moment.utc(dateInterval.end).format(dtFormat);
    if (start === end) return start;
    else return start + ' / ' + end;
  }

  // showModalPaymentAccount = () => {
  //   const { rows } = this.state;
  //   let selectedPayments = rows.filter(x => x.checked === true);
  //   if (selectedPayments.length === 0) {
  //     Alert.new({
  //       type: "error",
  //       title: "Atenção",
  //       text: "É necessário selecionar pelo menos um pagamento."
  //     });
  //     return;
  //   }

  //   this.setState({ openPaymentAccountModal: true });
  // }

  newPaymentAccount = async (accountNumber) => {
    const { rows } = this.state;
    let selectedPayments = rows.filter(x => x.checked === true);

    if (selectedPayments?.length == 0) {
      Alerts.new({
        type: 'warning',
        title: 'Atenção',
        text: 'Selecione um pagamento!'
      });
    }

    this.setState({ loading: true });
    const data = {
      accountNumber,
      selectedPayments,
      officialPercentage: this.state.updatePercentage
    };

    const result = await CreatePaymentAccountByEmployee(data);
    if (result?.success) {
      if (result?.data?.missingPASettings) {
        Alerts.new({
          type: 'warning',
          title: 'Atenção',
          text: 'Contas de pagamento por criar devido à falta de configurações de conta!'
        });
      } else {
        Alerts.new({
          type: 'success',
          title: 'Sucesso',
          text: 'Contas de pagamentos criadas!'
        });
      }
      this.setState({
        loading: false,
        selectAllRows: false
      }, () => {
        return this.props.history.push('/payments/2');
      })
    } else {
      this.setState({ loading: false });
    }
  }

  onChangeFile = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { paymentAccount } = this.state;
    let file = e.target.files[0];

    if (!file) {
      Alerts.new({
        type: 'error',
        title: 'Erro',
        text: 'Nenhum ficheiro selecionado!'
      });
      return;
    }

    // Check if the file is a PDF
    if (file.type !== 'application/pdf') {
      Alerts.new({
        type: 'error',
        title: 'Erro',
        text: 'Por favor, selecione um ficheiro PDF!'
      });
      return;
    }

    var reader = new FileReader();
    reader.fileName = file.name
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const data = {
        receipt: reader.result,
        filename: reader.fileName
      };

      let result = await UpdateReceipt(paymentAccount?._id, data);
      if (result?.success) {
        Alerts.new({
          type: 'success',
          title: 'Sucesso',
          text: 'Recibo atualizado!'
        });
        // this.setState({ paymentAccount: result?.data });
        this.updateDataTable();
      }
    };
    e.target.value = '';
  }

  removeReceipt = async () => {
    const { paymentAccount } = this.state;
    let result = await RemoveReceipt(paymentAccount._id);
    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Recibo removido!'
      });
      // this.setState({ paymentAccount: result?.data });
      this.updateDataTable();
    }
  }

  confirmUndoPaymentAccount = () => {
    confirm({
      title: 'Tem a certeza que pretende reverter esta conta de pagamento?',
      content: (<div>
        <p>Todos os pagamento que se encontrem associados a esta conta passarão ao estado "A pagar".</p>
      </div>
      ),
      okType: 'primary',
      okText: 'Reverter',
      cancelText: 'Cancelar',
      onOk: () => this.removePaymentAccount(),
      onCancel: () => null,
    });
  }

  removePaymentAccount = async () => {
    const { paymentAccount } = this.state;

    const result = await DeletePaymentAccount(paymentAccount?._id);
    if (result.success) {
      Alert.new({
        type: "success",
        title: "Successo",
        text: "Conta de pagamento eliminada!"
      });
      return this.props.history.goBack();
    }
  }

  approvePayment = async () => {
    const { rows, paymentAccount } = this.state;

    if (paymentAccount?.declaration === false && (paymentAccount?.receipt === null || paymentAccount?.receipt === '')) {
      Alert.new({
        type: "error",
        title: "Atenção",
        text: "Para aprovar o pagamento, é necessário aceitar a declaração ou fornecer o recibo."
      });
      return;
    }

    this.setState({
      loading: true,
      approving: true
    });

    const result = await ApprovePaymentAccounts([paymentAccount]);
    if (result?.success) {
      Alert.new({
        type: "success",
        title: "Successo",
        text: "Conta de pagamento aprovada!"
      });
      this.setState({ approving: false }, () => this.props.history.goBack());
    } else {
      this.setState({
        loading: false,
        approving: false
      });
    }
  }

  markAsPaid = async () => {
    const { rows, paymentAccount } = this.state;

    if (paymentAccount?.declaration === false && (paymentAccount?.receipt === null || paymentAccount?.receipt === '')) {
      Alert.new({
        type: "error",
        title: "Atenção",
        text: "Para marcar como pago, é necessário aceitar a declaração ou fornecer o recibo."
      });
      return;
    }

    const result = await MarkPaymentAccountAsPaid([paymentAccount]);
    if (result?.success) {
      Alert.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Conta registada como paga!'
      });
      return this.props.history.goBack();
    }
  }

  confirmUndoMarkAsPaid = () => {
    confirm({
      title: 'Tem a certeza que pretende marcar esta conta como pendente?',
      content: (<div>
        <p>A conta selecionada será listada em "Pagamentos pendentes".</p>
      </div>
      ),
      okType: 'primary',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: () => this.undoMarkAsPaid(),
      onCancel: () => null,
    });
  }

  undoMarkAsPaid = async () => {
    const { paymentAccount } = this.state;

    const result = await RemoveMarkPaymentAccountAsPaid([paymentAccount]);
    if (result?.success) {
      Alert.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Conta revertida para pendente!'
      });
      return this.props.history.goBack();
    }
  }

  render() {
    const { history } = this.props;
    const { loading, selectAllRows, updatePercentage, openPaymentAccountModal } = this.state;
    const { approving } = this.state;
    const { columnsToPay, columnsPayed, rows } = this.state;
    const { employee, paymentAccount, weddings, total } = this.state;
    const { showPreviewPDF, showDeclarationModal, modalValues, declaration } = this.state;
    const status = parseInt(this.props.match.params.status);
    const employeeId = this.props.match.params.employeeId;
    const entity = this.props.match.params.entity;
    const inputFileReceipt = React.createRef();

    const buttons = (status === 2 && paymentAccount && isCurrentUserAdmin()) || (status === 3 && isCurrentUserAdmin())
      ? 3
      : (status === 1 && isCurrentUserAdmin())
        ? 2
        : 1;

    return !loading ? (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={buttons}>
            <PageTitle>Pagamentos</PageTitle>
            <span onClick={() => {
              const { history } = this.props;
              isCurrentUserAdmin() ? history.push('/payments/' + status) : history.push('/employee/' + employeeId + '/4');
            }}>Lista de Pagamentos</span>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={buttons}>
            {status === 1 && isCurrentUserAdmin() && <BaseButton
              type={'primary'}
              icon={'plus'}
              style={{ width: 170 }}
              text={'Novo Pagamento'}
              onClick={() => this.newPaymentAccount()}
            />}
            {status === 2 && paymentAccount && isCurrentUserAdmin() && <BaseButton
              type={'default'}
              icon={'undo'}
              style={{ width: 170 }}
              text={'Reverter Pagamento'}
              onClick={() => this.confirmUndoPaymentAccount()}
            />}
            {status === 2 && paymentAccount && !paymentAccount.approved && isCurrentUserAdmin() && <BaseButton
              type={'primary'}
              icon={'check'}
              style={{ width: 170 }}
              loading={approving}
              text={'Aprovar Pagamento'}
              onClick={() => this.approvePayment()}
            />}
            {status === 2 && paymentAccount && paymentAccount.approved && isCurrentUserAdmin() && <BaseButton
              type={'primary'}
              icon={'check'}
              style={{ width: 170 }}
              text={'Marcar como Pago'}
              onClick={() => this.markAsPaid()}
            />}
            {status === 3 && isCurrentUserAdmin() && <BaseButton
              type={'primary'}
              icon={'undo'}
              style={{ width: 170 }}
              text={'Marcar como pendente'}
              onClick={() => this.confirmUndoMarkAsPaid()}
            />}
            {status === 3 && <BaseButton
              type={'primary'}
              icon={'check'}
              style={{ width: 170 }}
              text={'Pagamento Realizado'}
              disabled={true}
            />}
            <BaseButton
              type={'default'}
              icon={'left'}
              text={'Voltar'}
              onClick={() => {
                const { history } = this.props;
                history.goBack();
              }}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>

        <PageContainer buttons={buttons} style={{ textAlign: 'left' }}>
          <SectionTitle subsection>Funcionário</SectionTitle>
          <Row className='desktop-width-80 mobile-full-width payment-detail' style={{ marginRight: -5, marginLeft: -5 }}>
            <Col xs={24} sm={24} md={6} lg={6} style={{ marginLeft: 5, marginRight: 5 }}>
              <Avatar className='pfpImage' src={employee?.picture === "" ? NoAvatar : employee?.picture} />
              &nbsp;&nbsp;{employee?.name}
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} style={{ marginLeft: 5, marginRight: 5 }}>
              <Icon type="mail" />
              &nbsp;&nbsp;{employee?.email}
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} style={{ marginLeft: 5, marginRight: 5 }}>
              <Icon type="mobile" />
              &nbsp;&nbsp;{employee?.emergencyNumber}
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} style={{ marginLeft: 5, marginRight: 5 }}>
              <Icon type="environment" />
              &nbsp;&nbsp;{employee?.address}
            </Col>
          </Row>

          <SectionTitle subsection>Faturação</SectionTitle>
          <Row className='desktop-width-80 mobile-full-width payment-detail' style={{ marginRight: -5, marginLeft: -5 }}>
            <Col xs={24} sm={24} md={6} lg={6} style={{ marginLeft: 5, marginRight: 5 }}>
              <b>Nº Pagamento:</b>
              &nbsp;&nbsp;{paymentAccount?.accountNumber}
            </Col>
            <Col xs={24} sm={24} md={4} lg={6} style={{ marginLeft: 5, marginRight: 5 }}>
              <b>Nº Casamentos:</b>
              &nbsp;&nbsp;{weddings.length}
            </Col>
            {paymentAccount ? <Col xs={24} sm={24} md={12} lg={10} style={{ marginLeft: 5, marginRight: 5 }}>
              <b>Intervalo de Datas:</b>
              &nbsp;&nbsp; {this.getIntervalDate()}
            </Col> : <Col xs={12} md={12} lg={12}></Col>}
          </Row>
          <Row className='desktop-width-80 mobile-full-width payment-detail' style={{ marginTop: 10, marginRight: -5, marginLeft: -5 }}>
            <Col xs={24} md={6} lg={6} style={{ marginLeft: 5, marginRight: 5 }}>
              <b>Entidade a Faturar:</b>
              &nbsp;&nbsp;{paymentAccount ? paymentAccount.entity : entity && entity == 1 ? 'Solar da Levada' : 'Quinta Lago dos Cisnes'}
            </Col>
            <Col xs={24} md={6} lg={6} style={{ marginLeft: 5, marginRight: 5 }}>
              <b>Método de Pagamento:</b>
              &nbsp;&nbsp;{paymentAccount ? paymentAccount.paymentMethod : employee?.paymentMethod.name}
            </Col>
            <Col xs={24} md={4} lg={4} style={{ marginLeft: 5, marginRight: 5 }}>
              <b>NIF:</b>
              &nbsp;&nbsp;{employee?.nifInvoicing}
            </Col>
            <Col xs={24} md={7} lg={7} style={{ marginLeft: 5, marginRight: 5 }}>
              <b>IBAN:</b>
              &nbsp;&nbsp;{employee?.iban}
            </Col>
          </Row>
          <Row className='desktop-width-80 mobile-full-width payment-detail' style={{ marginTop: 10, marginRight: -5, marginLeft: -5 }}>
            <Col xs={24} md={6} lg={6} style={{ display: 'grid', marginLeft: 5, marginRight: 5 }}>
              <b>Declaração:</b>
              {(isCurrentUserEmployee() || isCurrentUserChief()) && paymentAccount?.receipt !== null && paymentAccount?.receipt !== '' && !paymentAccount?.declaration || (isCurrentUserAdmin() || isCurrentUserHumanResources()) && paymentAccount?.receipt !== null && paymentAccount?.receipt !== '' && !paymentAccount?.declaration ?
                <StateLabel printed={false}>Não</StateLabel> :
                <BaseButton style={{ marginTop: 5, width: '150px' }}
                  type={'default'}
                  icon={'check'}
                  block
                  text={paymentAccount.declaration ? ' Declaração Aceite' : ' Aceitar Declaração'}
                  onClick={() => this.setState({ showDeclarationModal: true, modalValues: paymentAccount })} />

              }
            </Col>
            <Col xs={24} md={6} lg={6} style={{ display: 'grid', marginLeft: 5, marginRight: 5 }}>
              <b>Recibo:</b>
              {paymentAccount && !paymentAccount.declaration ? <Fragment>
                <input type='file' id='fileInsurance' ref={inputFileReceipt} accept="application/pdf" style={{ display: 'none' }} onChange={e => this.onChangeFile(e)} />

                <div className="sc-dxgOiQ cGTQcX" style={{ marginTop: 5, width: '200px' }}>
                  <div style={{ height: 32, padding: '8px 11px', color: 'rgba(0,0,0, 0.65)', backgroundColor: '#fff', border: '1px solid #d9d9d9', borderRadius: '4px', transition: 'all 0.3s', position: 'relative' }}>
                    {!paymentAccount?.receipt
                      ? <Icon onClick={e => status === 2 ? inputFileReceipt.current.click() : null} style={{ position: 'absolute', right: 11, top: 8 }} type={'upload'} />
                      : <ReceiptName detail={true}>
                        <span className='input' onClick={e => this.setState({ showPreviewPDF: true })}>{paymentAccount.receiptName}</span>
                        {status === 2 && !paymentAccount.approved ? <Icon type={'close-circle'} style={{ position: 'absolute', right: 0, top: 0 }} onClick={e => this.removeReceipt()} /> : null}
                      </ReceiptName>}
                  </div>
                </div>
              </Fragment> : null}
            </Col>
          </Row>

          <Row gutter={24} style={{ marginTop: '2rem' }}>
            <Col lg={status === 1 ? 19 : 24} md={20} sm={18} xs={24}>
              <SectionTitle>Pagamentos</SectionTitle>
            </Col>
            {status === 1 ? <Col lg={5} md={4} sm={6} xs={24} style={{ display: 'inline-flex' }}>
              <div style={{ margin: 'auto 5px auto auto' }}>Valor (%)</div>
              <div style={{ width: 100 }}>
                <InputNumber
                  min={0}
                  max={100}
                  step={1}
                  value={this.state.updatePercentage}
                  onChange={(event) => {
                    if (!Number(event) && event > 0) {
                      return;
                    }
                    this.setState({ updatePercentage: event });
                  }}
                  type="number"
                  placeholder="Insira a percentagem"
                />
              </div>
            </Col> : null}
          </Row>

          <div className='payment-table-container'>
            {status === 1 ? <div className="sc-dxgOiQ fWHHkk checkbox-payment-detail">
              <Checkbox
                checked={selectAllRows}
                onChange={(e) => {
                  rows.forEach(r => {
                    r.checked = e.target.checked;
                  });
                  this.setState({ selectAllRows: e.target.checked });
                }}>
              </Checkbox>
            </div> : null}

            <Table
              columns={status === 1 ? columnsToPay : columnsPayed}
              loading={loading}
              rows={rows}
              showHeader={true}
              emptyIcon="euro"
              emptyText={'Não existem pagamentos'}
              total={rows.length}
              // rowKey={'_id'}
              hasPagination={false}
            />
          </div>

          <PaymentTotals
            hasSelection={status === 1 ? true : false}
            receipts={true}
            value={status === 1 ? rows.filter(x => x.checked == true).reduce((a, b) => a + ((b['value'] + b['bonus']) * (updatePercentage !== null ? (updatePercentage / 100) : b['officialPercentage']) || 0), 0) : rows.reduce((a, b) => a + ((b['value'] + b['bonus']) * b['officialPercentage'] || 0), 0)}
            bonus={status === 1 ? rows.filter(x => x.checked == true).reduce((a, b) => a + ((b['value'] + b['bonus']) * (updatePercentage !== null ? (1 - (updatePercentage / 100)) : (1 - b['officialPercentage'])) || 0), 0) : rows.reduce((a, b) => a + ((b['value'] + b['bonus']) * (1 - b['officialPercentage']) || 0), 0)}
            total={status === 1 ? rows.filter(x => x.checked == true).reduce((a, b) => a + (b['value'] + b['bonus'] || 0), 0) : rows.reduce((a, b) => a + (b['value'] + b['bonus'] || 0), 0)}
          />
        </PageContainer>

        {showPreviewPDF && <ModalPDF
          openModal={showPreviewPDF}
          closeModal={() => this.setState({ showPreviewPDF: false })}
          title='Recibo'
          file={paymentAccount?.receipt?._id ? `${REACT_APP_IMAGES_URL}${paymentAccount?.receipt?._id}` : paymentAccount?.receipt ? `${REACT_APP_IMAGES_URL}${paymentAccount?.receipt}` : null}
        />}

        {showDeclarationModal && <ModalPaymentDeclaration
          isOpen={showDeclarationModal}
          onClose={() => this.setState({ showDeclarationModal: false, modalValues: null })}
          onSubmit={async e => {
            const data = {
              id: e.id,
              declaration: e.declaration
            };
            const result = await UpdatePaymentAccountDeclaration(data);
            if (!result.success) console.error(result.message);
            this.setState({ showDeclarationModal: false, modalValues: null });
          }}
          initialValues={modalValues}
          blocked={modalValues ? modalValues.declaration : false}
          declaration={setDeclaration(declaration, modalValues)}
        />}

        {/* <ModalPaymentAccount
          isOpen={openPaymentAccountModal}
          onSubmit={(accountNumber) => this.setState({ openPaymentAccountModal: false }, () => {
            this.newPaymentAccount(accountNumber);
          })}
          onClose={() => this.setState({ openPaymentAccountModal: false })}
        /> */}
      </React.Fragment >
    ) : null;
  }
}


export default PaymentsPage;
