import client from "../config/AxiosConfig";

export const GetBanners = async (page = 1, limit = 20, filter = "") =>
  client.get(`/banners/${page}/${limit}?filter=${filter}`);

export const GetBannerById = async bannerId =>
  client.get(`/banners/${bannerId}`);

export const UpdateBannerById = async (bannerId, data) =>
  client.put(`/banners/${bannerId}`, data);

export const CreateBanner = async data => client.post(`/banners`, data);

export const DeleteBannerById = async bannerId =>
  client.delete(`/banners/${bannerId}`);
