import React from 'react';
import {Field} from 'redux-form';
import {
  OptionsContainer,
  OptionLine,
  OptionColumn,
  OptionQuantity,
  OptionType
} from './Styles';
import TextInput from '../../../components/inputs/TextInput';
import NumberInput from '../../../components/inputs/NumberInput';
import ColourInput from '../../../components/inputs/ColourInput';

const OrderFlowersList = ({ fields }) => {
  return (
    <OptionsContainer>
      {
        fields?.map((field, index) =>
          <OptionLine key={index}>  
            <OptionColumn maxWidth={32} noBorderTopRight={true} noBorderBottomRight={true}>
              <Field
                component={ColourInput}
                name={`${field}.hex`}
                disabled={true}
                hideInput={true}
                first={true}
              />
            </OptionColumn>
            <OptionColumn maxWidth={250} noBorderTopRight={true} noBorderBottomRight={true}>
              <Field
                component={TextInput}
                name={`${field}.color`}
                type='text'
                disabled={true}
                noLabel={true}
              />
            </OptionColumn>
            <OptionColumn maxWidth={250} noBorderRadius={true}>
              <Field
                component={TextInput}
                name={`${field}.flower`}
                type='text'
                disabled={true}
                noLabel={true}
              />
            </OptionColumn>
            <OptionQuantity>
              <Field
                component={NumberInput}
                name={`${field}.quantity`}
                step={1}
                min={0}
              />
            </OptionQuantity>
            <OptionType>
              <Field
                component={TextInput}
                name={`${field}.type`}
                type='text'
                disabled={true}
                noLabel={true}
              />
            </OptionType>
          </OptionLine>
        )
      }
    </OptionsContainer>
  );
};

export default OrderFlowersList;