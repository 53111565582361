import React, { Component, Fragment } from 'react';
import moment from 'moment';
import Table from '../../components/table/Table';
import { Checkbox, Icon, Tag } from 'antd';
import {
  SpinLoading,
  HeaderContainer,
  HeaderTitle,
  PageTitle,
  PageContainer,
  SectionTitle,
  HeaderButtonsContainer,
  TableButton
} from '../../styles/BasicStyles';
import {
  getWeddingName,
  getLocal,
  getHomeTitle,
  getScheduler,
  getWeddingTeamAndEmployeesInCharge,
  getLocalColor
} from '../../infra/services/wedding/weddingUtils';
import BaseButton from '../../components/buttons/BaseButton';
import { GetHomeWeddingsByUser, GetNearestWeddingsByUser } from '../../infra/requests/WeddingRequests';
import { GetEmployeeByUser } from '../../infra/requests/EmployeeRequests';
import { GetOurDayByWedding } from '../../infra/requests/OurDayRequests';
import WeddingInfo from './WeddingInfo';
import { GetTeamBookingByWedding } from '../../infra/requests/TeamBookingRequests';
import { ConfirmWeddingTeamByemployee, GetWeddingTeamByWedding, GetWeddingTeamByWeddingAndEmployee } from '../../infra/requests/WeddingTeamRequests';
import { GetSubcategoriesByType, GetSubcategoriesInCharge } from '../../infra/requests/SubcategoryRequests';
import { GetColorSystemByType } from '../../infra/requests/ColorSystemRequests';
import { LocalLabel, StateLabel } from '../weddings/Styles';
import { getCurrentUser, isCurrentUserAdmin, isCurrentUserChief, isCurrentUserEmployee, isCurrentUserHumanResources } from '../../infra/helpers/Helpers';
import { GetTextColor } from '../../infra/services/text/textUtils';

class EmployeeHome extends Component {
  state = {
    ready: false,
    currentUser: null,
    currentEmployee: null,
    showPreviousWedding: true,
    showNextWedding: true,
    selectedWedding: null,
    posSelectedWedding: 0,
    weddings: [],
    nearWeddings: [],
    columnsRH: [
      {
        title: 'Data',
        render: data => moment.utc(data?.wedding.date).format('DD-MM-YYYY')
      },
      {
        title: 'Local',
        render: data => {
          const local = getLocalColor(data?.wedding, this.state.colors);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
        }
      },
      {
        title: 'Horário Chegada à Quinta',
        render: data => data.scheduler.arrivingTime
      },
      {
        title: 'Horário Aperitivos',
        render: data => data.scheduler.snackTime
      },
      {
        title: 'Horário Jantar',
        render: data => data.scheduler.dinnerTime
      },
      {
        title: 'Selecionado para',
        render: data => {
          const teamPublished = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.published).length > 0 ? true : false;
          const weddingTeam = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.employee.id === this.state.currentEmployee.id).length > 0 ? data.teamComplete.filter(f => f.employee.id === this.state.currentEmployee.id) : null;
          // console.warn('teamPublished', teamPublished);
          // console.warn('weddingTeam', weddingTeam);
          return teamPublished && weddingTeam ? weddingTeam.map(m => {
            return <div>{m.subcategory.name}</div>
          }) : null;
        }
      },
      {
        title: 'Horário de Entrada',
        render: data => {
          const teamPublished = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.published).length > 0 ? true : false;
          const weddingTeam = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.employee.id === this.state.currentEmployee.id).length > 0 ? data.teamComplete.filter(f => f.employee.id === this.state.currentEmployee.id) : null;
          // console.warn('teamPublished', teamPublished);
          // console.warn('weddingTeam', weddingTeam);
          return teamPublished && weddingTeam ? weddingTeam.map(m => {
            return <div>{moment(m.startTime).format('HH:mm')}</div>
          }) : null;
        }
      },
      {
        title: 'Confirmar',
        render: data => {
          // to test only
          // data.allowToConfirm = true;
          // const weddingDate = new Date(data.wedding.date);
          // const now = new Date(weddingDate.toDateString() + ' 00:00:00');
          // now.setDate(now.getDate() - 1);
          // const startWeddingDate = new Date(weddingDate.toDateString() + ' 00:00:00');
          //  console.warn('Data', data);
          // console.warn('Now', now);
          // console.warn('startWeddingDate', startWeddingDate);
          // data.allowToConfirm = moment(now).isBefore(startWeddingDate);
          const teamPublished = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.published).length > 0 ? true : false;

          return teamPublished && data.empWeddingTeam && data.empWeddingTeam.length > 0 ? (
            <div className="sc-dxgOiQ fWHHkk">
              <Checkbox
                checked={data.confirmed}
                disabled={!data.allowToConfirm} // disabled={this.state.payments.length > 0}
                onChange={(e) => this.confirmWeddingTeam(e, data)}>
                Sim
              </Checkbox>
            </div>
          ) : null;
        }
      },
      {
        title: 'Equipa Confirmada',
        render: data => {
          const confirmed = data.teamComplete.length > 0 && data.teamComplete.filter(f => f.confirmed).length === data.teamComplete.length ? true : false;
          return <Tag color={confirmed ? 'green' : 'red'}>{confirmed ? 'Sim' : 'Não'}</Tag>
        }
      },
      {
        title: 'Acções',
        width: '120px',
        render: data => (
          <Fragment>
            {isCurrentUserHumanResources() && data.teamBooking && data.teamBooking.length > 0 && data.teamBooking.filter(t => t.showEmployee).length > 0 ||
              isCurrentUserChief() && data.employeesInCharge && data.employeesInCharge.chief.length > 0 && data.employeesInCharge.chief.find(f => f.employee === this.state.currentEmployee.id) && data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(t => t.published).length > 0 ? (
              <TableButton onClick={() => {
                const { history } = this.props;
                history.push(`/wedding-info/${data.wedding._id}`);
              }}>
                <Icon type="team" />
                {' Equipa'}
              </TableButton>
            ) : null}

            {/*!isCurrentUserHumanResources() && !(data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(t => t.published).length > 0) */}
            {(isCurrentUserEmployee() || isCurrentUserChief()) && data.teamBooking && data.teamBooking.length > 0 && data.teamBooking.filter(t => t.showEmployee).length > 0 && data.allowToRegisterInterest ? (
              <TableButton onClick={() => {
                const { history } = this.props;
                history.push(`/wedding-interest/${data.wedding._id}`)
              }}>
                <Icon type="star" />
                &nbsp;Consultar
              </TableButton>
            ) : null}
          </Fragment>
        )
      }
    ],
    columnsEmployee: [
      {
        title: 'Data',
        render: data => moment.utc(data?.wedding.date).format('DD-MM-YYYY')
      },
      {
        title: 'Local',
        render: data => {
          const local = getLocalColor(data?.wedding, this.state.colors);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
        }
      },
      {
        title: 'Horário Chegada à Quinta',
        render: data => data.scheduler.arrivingTime
      },
      {
        title: 'Horário Aperitivos',
        render: data => data.scheduler.snackTime
      },
      {
        title: 'Horário Jantar',
        render: data => data.scheduler.dinnerTime
      },
      {
        title: 'Interesse Manifestado',
        render: data => {
          const weddingInterest = data.interest && data.interest.length > 0 ? true : false;
          return <Tag color={weddingInterest ? 'green' : 'red'}>{weddingInterest ? 'Sim' : 'Não'}</Tag>
        }
      },
      {
        title: 'Selecionado para',
        render: data => {
          const teamPublished = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.published).length > 0 ? true : false;
          const weddingTeam = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.employee.id === this.state.currentEmployee.id).length > 0 ? data.teamComplete.filter(f => f.employee.id === this.state.currentEmployee.id) : null;
          // console.warn('teamPublished', teamPublished);
          // console.warn('weddingTeam', weddingTeam);
          return teamPublished && weddingTeam ? weddingTeam.map(m => {
            return <div>{m.subcategory.name}</div>
          }) : null;
        }
      },
      {
        title: 'Horário de Entrada',
        render: data => {
          const teamPublished = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.published).length > 0 ? true : false;
          const weddingTeam = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.employee.id === this.state.currentEmployee.id).length > 0 ? data.teamComplete.filter(f => f.employee.id === this.state.currentEmployee.id) : null;
          // console.warn('teamPublished', teamPublished);
          // console.warn('weddingTeam', weddingTeam);
          return teamPublished && weddingTeam ? weddingTeam.map(m => {
            return <div>{moment(m.startTime).format('HH:mm')}</div>
          }) : null;
        }
      },
      {
        title: 'Confirmar',
        render: data => {
          // to test only
          // data.allowToConfirm = true;
          const teamPublished = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.published).length > 0 ? true : false;
          return teamPublished && data.empWeddingTeam && data.empWeddingTeam.length > 0 ? (
            <div className="sc-dxgOiQ fWHHkk">
              <Checkbox
                checked={data.confirmed}
                disabled={!data.allowToConfirm} // disabled={this.state.payments.length > 0}
                onChange={(e) => this.confirmWeddingTeam(e, data)}>
                Sim
              </Checkbox>
            </div>
          ) : null;
        }
      },
      {
        title: 'Acções',
        width: '120px',
        render: data => (
          <Fragment>
            {/* 
            {console.warn('Emp', this.state.currentEmployee.id)}
            {console.warn('Chiefs', data.employeesInCharge.chief)}
            {console.warn('Check1', data.employeesInCharge.chief.find(f => f.employee.toString() === this.state.currentEmployee.id.toString()))}
            {console.warn('Check2', data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(t => t.published).length > 0)}
            {console.warn('Check3', isCurrentUserChief())}
            {console.warn('Check', isCurrentUserChief() && data.employeesInCharge && data.employeesInCharge.chief.length > 0 && data.employeesInCharge.chief.find(f => f.employee.id === this.state.currentEmployee.id) && data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(t => t.published).length > 0)}
             */}

            {isCurrentUserHumanResources() && data.teamBooking && data.teamBooking.length > 0 && data.teamBooking.filter(t => t.showEmployee).length > 0 ||
              isCurrentUserChief() && data.employeesInCharge && data.employeesInCharge.chief.length > 0 && data.employeesInCharge.chief.find(f => f.employee.id === this.state.currentEmployee.id) && data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(t => t.published).length > 0 ? (
              <TableButton onClick={() => {
                const { history } = this.props;
                history.push(`/wedding-info/${data.wedding._id}`);
              }}>
                <Icon type="team" />
                {' Equipa'}
              </TableButton>
            ) : null}

            {/*!isCurrentUserHumanResources() && !(data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(t => t.published).length > 0) */}
            {(isCurrentUserEmployee() || isCurrentUserChief()) && data.teamBooking && data.teamBooking.length > 0 && data.teamBooking.filter(t => t.showEmployee).length > 0 && data.allowToRegisterInterest ? (
              <TableButton onClick={() => {
                const { history } = this.props;
                history.push(`/wedding-interest/${data.wedding._id}`)
              }}>
                <Icon type="search" />
                &nbsp; Consultar
              </TableButton>
            ) : null}
          </Fragment>
        )
      }
    ],
    colors: {
      colorSys1: null,
      colorSys2: null
    },
    subcategories: [],
  };

  componentDidMount = async () => {
    if (isCurrentUserAdmin()) return this.props.history.push('/404');

    const localUser = getCurrentUser();
    this.setState({ currentUser: localUser });
    await this.getEmployee(localUser);
    this.getLocalColors();
  }

  getEmployee = async (user) => {
    const resEmployee = await GetEmployeeByUser(user._id);
    const resultSub = await GetSubcategoriesInCharge();

    this.setState({
      currentEmployee: resEmployee.success && resEmployee.data ? resEmployee.data : null,
      subcategories: resultSub.success && resultSub.data ? resultSub.data : []
    }, () => {
      this.getNearWeddings();
      this.getHomeWeddings();
    });
  }

  getLocalColors = async () => {
    const resColorSys1 = await GetColorSystemByType(4);
    const colorSys1 = resColorSys1.success && resColorSys1.data ? resColorSys1.data.color : '#f7b5d6';
    const resColorSys2 = await GetColorSystemByType(5);
    const colorSys2 = resColorSys2.success && resColorSys2.data ? resColorSys2.data.color : '#b6d7a9';
    this.setState({ colors: { colorSys1, colorSys2 } });
  }

  getNearWeddings = async () => {
    this.setState({ ready: false });
    const { subcategories, currentUser, currentEmployee } = this.state;
    const now = moment();
    const filter = { calendar: false, limit: isCurrentUserHumanResources() ? false : true };
    const resWedding = await GetNearestWeddingsByUser(currentUser._id, JSON.stringify(filter));
    // console.warn('resWedding', resWedding);

    let nearWeddings = [];
    for (let index = 0; index < resWedding.data.length; index++) {
      const weddingTeam = [];
      if (resWedding.data[index].teamComplete) resWedding.data[index].teamComplete.forEach(t => weddingTeam.push({ weddingTeam: t }));
      const team = getWeddingTeamAndEmployeesInCharge({ data: weddingTeam }, subcategories);
      // console.warn('Team', team);
      const empWeddingTeam = resWedding.data[index].teamComplete && resWedding.data[index].teamComplete.length > 0 ? resWedding.data[index].teamComplete.filter(f => f.employee.id === currentEmployee.id) : [];
      const weddingDate = new Date(resWedding.data[index].wedding.date);
      const startWeddingDate = new Date(weddingDate.toDateString() + ' 00:00:00');
      const endWeddingDate = new Date(weddingDate.toDateString() + ' 23:59:59');
      // startWeddingDate.setDate(weddingDate.getDate() - 1);

      nearWeddings.push({
        wedding: resWedding.data[index].wedding,
        scheduler: getScheduler(resWedding.data[index].ourDay),
        teamBooking: resWedding.data[index].teamBooking,
        teamComplete: resWedding.data[index].teamComplete,
        interest: resWedding.data[index].interest,
        weddingTeam: team.weddingTeam,
        employeesInCharge: team.employeesInCharge,
        empWeddingTeam: empWeddingTeam,
        confirmed: empWeddingTeam.length > 0 && empWeddingTeam.filter(f => f.confirmed).length > 0 ? true : false,
        allowToConfirm: now.isBefore(startWeddingDate) ? true : false,
        allowToRegisterInterest: now.isSameOrBefore(endWeddingDate) ? true : false
      });
    }

    this.setState({ nearWeddings, ready: true });
  }

  getHomeWeddings = async () => {
    this.setState({ ready: false });
    const { currentUser, subcategories } = this.state;
    const weddings = [];

    const resWedding = await GetHomeWeddingsByUser(currentUser._id);
    // console.warn('resWedding', resWedding);

    for (let index = 0; index < resWedding.data.length; index++) {
      //const wedding = resWedding.data[index];

      // const resOurDay = await GetOurDayByWedding(wedding.id);
      // const resBooking = await GetTeamBookingByWedding(wedding.id);
      // const resTeam = await GetWeddingTeamByWedding(wedding.id);
      const weddingTeam = [];
      resWedding.data[index].weddingTeam.forEach(t => weddingTeam.push({ weddingTeam: t }));
      const team = getWeddingTeamAndEmployeesInCharge({ data: weddingTeam }, subcategories);

      weddings.push({
        title: getHomeTitle(resWedding.data[index].wedding),
        wedding: resWedding.data[index].wedding,
        scheduler: getScheduler(resWedding.data[index].ourDay),
        teamBooking: resWedding.data[index].teamBooking,
        teamComplete: resWedding.data[index].weddingTeam,
        weddingTeam: team.weddingTeam,
        employeesInCharge: team.employeesInCharge
      });
    }

    const today = moment(moment().format('YYYY-MM-DD'));
    if (weddings.length > 0) {
      const weddingDate0 = moment(weddings[0].date).format('YYYY-MM-DD');
      if (weddings.length > 1 && moment(weddingDate0).isSameOrBefore(today)) {
        this.setState({ posSelectedWedding: 0, selectedWedding: weddings[0], showPreviousWedding: false, showNextWedding: true });
      } else if (weddings.length > 1 && moment(weddingDate0).isBefore(today)) {
        this.setState({ posSelectedWedding: 1, selectedWedding: weddings[1], showPreviousWedding: true, showNextWedding: false });
      } else {
        this.setState({ posSelectedWedding: 1, selectedWedding: weddings[0], showPreviousWedding: false, showNextWedding: false });
      }
    } else {
      this.setState({ posSelectedWedding: 0, selectedWedding: null, showPreviousWedding: false, showNextWedding: false });
    }
    this.setState({ weddings: weddings, ready: true });
  }

  confirmWeddingTeam = async (event, data) => {
    // console.warn('event', event.target.checked);
    // console.warn('Data', data);
    this.setState({ ready: false });

    data.empWeddingTeam.forEach(weddingTeam => {
      weddingTeam.confirmed = event.target.checked;
    });
    // console.warn('empWeddingTeam', data.empWeddingTeam);

    const result = await ConfirmWeddingTeamByemployee(data.empWeddingTeam);
    // console.warn('Result', result);
    if (!result.success) {
      console.error(result.errors);
      this.setState({ ready: true });
    } else {
      this.getNearWeddings();
    }
  }

  render() {
    const { currentUser, currentEmployee, ready, colors } = this.state;
    const { weddings, columnsRH, columnsEmployee, nearWeddings } = this.state;
    const { selectedWedding, posSelectedWedding, showPreviousWedding, showNextWedding } = this.state;
    const bgColor = selectedWedding?.wedding.wedding_place === 1 ?
      colors.colorSys1 : selectedWedding?.wedding.wedding_place === 2 ? colors.colorSys2 : '';

    if (!ready) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Dashboard</PageTitle>
          </HeaderTitle>
        </HeaderContainer>

        <PageContainer buttons={0}>
          {selectedWedding != null ? <div className='mobile-grid display-flex employee-home-btn-container'>
            <SectionTitle subsection style={{ padding: '5px 10px', color: '#fff', backgroundColor: bgColor, borderRadius: 5 }}>{selectedWedding?.title}</SectionTitle>
            <div className='home-btn-content'>
              <BaseButton
                type={'primary'}
                icon={'left'}
                block
                text={'Anterior'}
                style={{ width: 110 }}
                disabled={!showPreviousWedding}
                onClick={() => {
                  const pos = posSelectedWedding - 1;
                  this.setState({ posSelectedWedding: pos, selectedWedding: weddings[pos], showPreviousWedding: pos === 1 || pos === 2 ? true : false, showNextWedding: pos === 0 || pos === 1 ? true : false })
                }}
              />
              <BaseButton
                type={'primary'}
                icon={'right'}
                block
                text={'Seguinte'}
                style={{ width: 110 }}
                disabled={!showNextWedding}
                onClick={() => {
                  const pos = posSelectedWedding + 1;
                  this.setState({ posSelectedWedding: pos, selectedWedding: weddings[pos], showPreviousWedding: pos === 1 || pos === 2 ? true : false, showNextWedding: pos === 0 || pos === 1 ? true : false })
                }}
              />
            </div>
          </div> : null}

          {selectedWedding != null ? <WeddingInfo
            showHomeHeader={true}
            showTable={true}
            currentEmployee={currentEmployee}
            selectedWedding={selectedWedding}
          /> : null}


          <SectionTitle subsection>Próximos casamentos</SectionTitle>
          <Table
            columns={isCurrentUserHumanResources() ? columnsRH : columnsEmployee}
            currentPage={1}
            pageSize={nearWeddings.length}
            loading={!ready}
            rows={nearWeddings}
            showHeader={true}
            emptyIcon="calendar"
            emptyText={'Não existem casamentos próximos'}
            total={nearWeddings.length}
            rowKey={'_id'}
            hasPagination={false}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default EmployeeHome;
