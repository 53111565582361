import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { BaseForm } from '../../../styles/BasicStyles';
import SimpleCheckboxInput from '../../../components/inputs/SimpleCheckboxInput';
import BaseButton from '../../../components/buttons/BaseButton';
import { Modal, notification } from 'antd';
import {
  PrintedContainer
} from '../ProcessStyles';
import {
  UpdateWeddingPrinted
} from '../../../infra/requests/WeddingProcessRequests';
import {
  SaveWeddingPrinted
} from '../../../redux/wedding/wedding.actions';
import { bindActionCreators } from 'redux';
import moment from 'moment';
const { confirm } = Modal;

let PrintedComponent = ({ weddingInfo, handleSubmit, SaveWeddingPrinted, getInformation, setProcessLoading }) => {
  const [loading, setLoading] = useState(false);

  const confirmOnSubmit = (values) => {
    if (values?.printed && weddingInfo?.blockDate && moment.utc().isBefore(moment.utc(weddingInfo?.blockDate))) {
      confirm({
        title: 'Os noivos ainda se encontram dentro do período de edição.',
        content: (<div>
          <p>Ao registar o processo como impresso, não implica que a edição será bloqueada para os noivos. Sendo que estes ainda se encontram dentro do período de edição.</p>
        </div>
        ),
        okText: 'Registar como impresso',
        cancelText: 'Cancelar',
        onOk: () => onSubmit(values),
        onCancel: () => null
      });
    } else onSubmit(values);
  };

  const onSubmit = async values => {
    try {
      setLoading(true);
      setProcessLoading(true);

      const { success, data } = await UpdateWeddingPrinted(weddingInfo._id, values);

      if (success) {
        notification.success({
          message: 'Gravado com sucesso',
          description: 'A informação foi gravada com sucesso!'
        });

        SaveWeddingPrinted(data.printed);
        getInformation();
      }

      setLoading(false);
    }
    catch (e) {
      console.error(e);
      setLoading(false);
      setProcessLoading(false);
    }
  };

  return (
    <BaseForm onSubmit={handleSubmit(confirmOnSubmit)}>
      <PrintedContainer printed={weddingInfo?.printed ? true : false}>
        <Field
          component={SimpleCheckboxInput}
          name='printed'
          label='Impresso?'
        />
        <BaseButton
          type='primary'
          icon='save'
          text='Gravar alterações'
          loading={loading}
          onClick={handleSubmit(confirmOnSubmit)}
          style={{ float: 'none' }}
        />
      </PrintedContainer>
    </BaseForm>
  );
};

PrintedComponent = reduxForm({
  form: 'wedding_printed_form'
})(PrintedComponent);

const mapActionToProps = dispatch => bindActionCreators({ SaveWeddingPrinted: SaveWeddingPrinted, dispatch }, dispatch);

export default connect(null, mapActionToProps)(PrintedComponent);