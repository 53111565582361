import client from '../config/AxiosConfig';

export const CreateFoodRestriction = async (data) =>
  client.post(`/foodRestriction`, data);

export const GetFoodRestriction = async id =>
  client.get(`/foodRestriction/${id}`);

export const GetFoodRestrictionList = async () =>
  client.get(`/foodRestriction`);

export const GetFoodRestrictions = async (currentPage, limit, filters) =>
  client.get(`/foodRestriction/${currentPage}/${limit}?filter=${JSON.stringify(filters)}`);

export const UpdateFoodRestriction = async (id, data) =>
  client.put(`/foodRestriction/${id}`, data);

export const DeleteFoodRestriction = async id =>
  client.delete(`/foodRestriction/${id}`);