import client from '../config/AxiosConfig';

export const CreatePrice = async (data) =>
  client.put(`/price/create`, data);

export const GetPrice = async id =>
  client.get(`/price/find/${id}`);

export const GetPrices = async () =>
  client.get(`/price/findAll`);

export const GetPriceBySubcategory = async (id) =>
  client.get(`/price/findBySubcategory/${id}`);

export const UpdatePrice = async (data) =>
  client.post(`/price/update`, data);

export const DeletePrice = async id =>
  client.delete(`/price/remove/${id}`);