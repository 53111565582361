import React, {Component} from 'react';
import {notification} from 'antd';
import {initialize, reduxForm, FieldArray} from 'redux-form';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  BaseForm,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import {
  GetWeddingsStaffMeals,
  SaveWeddingsStaffMeals
} from '../../infra/requests/WeddingRequests';
import {
  GetStaffMeals
} from '../../infra/requests/FoodRequests';
import moment from 'moment';
import {BaseButton} from '../../components/buttons/BaseButton';
import Filters from './Filters';
import WeddingsList from './WeddingsList';

class StaffMealsPage extends Component {
  state = {
    loading: false,
    loadingButton: false,
    filters: JSON.stringify({
      dates: [moment().utc(true), moment().utc(true).add(1, 'week')],
    }),
    staffMeals: []
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  updateDataTable = async () => {
    const {dispatch} = this.props;
    const {filters} = this.state;

    this.setState({loading: true});
    try {
      const staffMeals = await GetStaffMeals();
      const result = await GetWeddingsStaffMeals(filters);

      dispatch(initialize('manage_staff_meals_form', { weddings: result?.data || [] }));

      this.setState({
        loading: false,
        saveButtonDisabled: result?.data?.length > 0 ? false : true,
        staffMeals: staffMeals?.data || [],
        loadingButton: false
      });
    } catch (e) {
      this.setState({loading: false});
      console.error(e);
    }
  };

  handleFilterChange = filters => {
    this.setState({filters}, this.updateDataTable);
  };

  onSubmit = async (values) => {
    try {
      this.setState({loadingButton: true});

      const result = await SaveWeddingsStaffMeals(values);

      if(result?.success) {
        notification.success({
          message: 'Alterações gravadas com sucesso!'
        });
      }

      await this.updateDataTable();
    } catch (e) {
      this.setState({loadingButton: false});
      console.error(e);
    }
  };

  render() {
    const {handleSubmit} = this.props;
    const {
      loading,
      loadingButton,
      saveButtonDisabled,
      staffMeals
    } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Refeições do Staff</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              disabled={saveButtonDisabled}
              loading={loadingButton}
              onClick={handleSubmit(this.onSubmit)}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={1}>
          <Filters
            queryChange={this.handleFilterChange}
          />
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <FieldArray
              component={WeddingsList}
              name='weddings'
              staffMeals={staffMeals}
              loading={loading}
            />
          </BaseForm>
        </PageContainer>
      </React.Fragment>
    );
  }
}

StaffMealsPage = reduxForm({
  form: 'manage_staff_meals_form'
})(StaffMealsPage);

export default StaffMealsPage;