import React from 'react';
import { Field } from 'redux-form';
import {
  OptionsContainer,
  OptionLine,
  OptionColumn,
  OptionQuantity,
  OptionType
} from './Styles';
import TextInput from '../../../components/inputs/TextInput';
import NumberInput from '../../../components/inputs/NumberInput';
import { Tooltip } from 'antd';
import TextAreaInput from '../../../components/inputs/TextAreaInput';
import styled from 'styled-components';

const RestrictionsContainer = styled.div`
  display: grid;
`;

export const RestrictionColumn = styled.div`
  width: 100%;
  max-width: ${p => p.maxWidth ? `${p.maxWidth}px` : '100px'};
  height: 100%;
  text-align: start;

  input, textarea {
    height: ${p => p?.showLabel ? 'calc(100% - 24px)' : '100%'};
    ${p => p?.noBorderRadius ? 'border-radius: 0;' : null}
    ${p => p?.noBorderRadiusLeft ? `
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    ` : null}
    ${p => p?.noBorderRadiusRight ? `
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    ` : null}
  }

  textarea.ant-input {
    height: auto !important;
  }

  & input[disabled] {
    color: rgba(0, 0, 0, 0.65);
    cursor: default;
  }
`;

const OrderFoodRestrictionsList = ({ fields }) => {
  return (
    <RestrictionsContainer>
      {
        fields.map((field, index) =>
          <OptionLine key={index}>
            <RestrictionColumn showLabel={index == 0 ? true : false} maxWidth={100} noBorderRadiusRight={true}>
              <Field
                component={TextInput}
                label='Data'
                name={`${field}.date`}
                type='text'
                first={index == 0 ? true : false}
                disabled={true}
                noLabel={index == 0 ? false : true}
                restrictionOrder={true}
              />
            </RestrictionColumn>
            <RestrictionColumn showLabel={index == 0 ? true : false} maxWidth={150} noBorderRadius={true}>
              <Field
                component={TextInput}
                name={`${field}.wedding_place_txt`}
                label='Local'
                type='text'
                disabled={true}
                first={index == 0 ? true : false}
                noLabel={index == 0 ? false : true}
                restrictionOrder={true}
              />
            </RestrictionColumn>
            {/* <RestrictionColumn showLabel={index == 0 ? true : false} maxWidth={300} noBorderRadius={true}>
              <Field
                component={TextInput}
                name={`${field}.name`}
                type='text'
                disabled={true}
                noLabel={index == 0 ? false : true}
                 first={index == 0 ? true : false}
                tooltip={fields.get(index)?.name && fields.get(index)?.name?.length > 45 ? true : false}
                tooltipMsg={fields.get(index)?.name}
                restrictionOrder={true}
              />
            </RestrictionColumn> */}
            <RestrictionColumn showLabel={index == 0 ? true : false} maxWidth={300} noBorderRadius={true}>
              <Field
                component={TextAreaInput}
                label='Restrições'
                name={`${field}.quantity`}
                noLabel={index == 0 ? false : true}
                first={index == 0 ? true : false}
                restrictionOrder={true}
              />
            </RestrictionColumn>
            <RestrictionColumn showLabel={index == 0 ? true : false} maxWidth={300} noBorderRadius={true}>
              <Field
                component={TextAreaInput}
                label='Notas cozinha'
                name={`${field}.notesKitchen`}
                noLabel={index == 0 ? false : true}
                first={index == 0 ? true : false}
                restrictionOrder={true}
              />
            </RestrictionColumn>
            <RestrictionColumn showLabel={index == 0 ? true : false} maxWidth={300} noBorderRadiusLeft={true}>
              <Field
                component={TextAreaInput}
                label='Notas do plano de sala'
                name={`${field}.notes`}
                noLabel={index == 0 ? false : true}
                first={index == 0 ? true : false}
                restrictionOrder={true}
              />
            </RestrictionColumn>
          </OptionLine>
        )}
    </RestrictionsContainer>
  );
};

export default OrderFoodRestrictionsList;