import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  TimeInputSection,
  TimeInputLabel,
  TimeInputContainer,
  InputContainer,
  InputDiv,
  InputLabelDiv,
  TimeInputField,
} from "./InputStyles";
import { TimePicker } from "antd";
import moment from "moment";
import momentTimezone from 'moment-timezone';
import { timeFormat } from "../../infra/helpers/Helpers";

const XpertGoDateTimeInput = ({
  input,
  meta,
  label,
  first,
  placeholder,
  disabled,
  allowClear,
  disabledSeconds,
  dateRef,
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed ? 1 : 0;
  const [date, setDate] = useState(null);

  useEffect(() => {
    setDate(dateRef
      ? moment.utc(dateRef)
      : null)
  }, [dateRef])

  return (
    <InputDiv first={first}>
      {label && <InputLabelDiv>{label}</InputLabelDiv>}
      <TimeInputField
        disabled={disabled}
        error={showError}
        placeholder={placeholder}
        format={'HH:mm'}
        value={input?.value ? moment.utc(input.value).local() : null}
        onChange={time => {
          if (time) {
            const result = date
              ? moment(time).set('year', date.year()).set('month', date.month()).set('date', date.date()).utc()
              : moment(time).utc();
            input.onChange(result);
          } else {
            input.onChange('');
          }
        }}
        // disabledSeconds={disabledSeconds}
        allowClear={allowClear}
      />
    </InputDiv>
  );
};

XpertGoDateTimeInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  allowClear: PropTypes.bool,
  disabledSeconds: PropTypes.bool,
};

XpertGoDateTimeInput.defaultProps = {
  allowClear: true,
  disabledSeconds: true,
}

export default XpertGoDateTimeInput;