import CategoriesPage from '../../weddingday/categories/CategoriesPage';
import ManageCategoryPage from '../../weddingday/categories/ManageCategoryPage';
import SuppliersPage from '../../weddingday/suppliers/SuppliersPage';
import ManageSupplierPage from '../../weddingday/suppliers/ManageSupplierPage';
import FlowersPage from '../../weddingday/flowers/FlowersPage';
import ManageFlowersPage from '../../weddingday/flowers/ManageFlowersPage';
import IngredientsPage from '../../weddingday/ingredients/IngredientsPage';
import ManageIngredientsPage from '../../weddingday/ingredients/ManageIngredientsPage';
import OrdersPage from '../../weddingday/orders/OrdersPage';
import OptionsPage from '../../weddingday/orderOptions/OptionsPage';
import ManageOptionsPage from '../../weddingday/orderOptions/ManageOptionsPage';
import QuestionsPage from '../../weddingday/orderQuestions/QuestionsPage';
import ManageQuestionsPage from '../../weddingday/orderQuestions/ManageQuestionsPage';
import StaffMealsPage from '../../weddingday/staffMeals/StaffMealsPage';
import { PermissionsEnum } from '../../infra/helpers/Permissions';

export default [
  {
    path: '/categories',
    component: CategoriesPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_CATEGORY],
  },
  {
    path: '/categories/add',
    component: ManageCategoryPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.CREATE_CATEGORY],
  },
  {
    path: '/categories/:id',
    component: ManageCategoryPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.EDIT_CATEGORY],
  },
  {
    path: '/suppliers',
    component: SuppliersPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_SUPPLIER],
  },
  {
    path: '/suppliers/add',
    component: ManageSupplierPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.CREATE_SUPPLIER],
  },
  {
    path: '/suppliers/:id',
    component: ManageSupplierPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.EDIT_SUPPLIER],
  },
  {
    path: '/flowers',
    component: FlowersPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_COLOR_FLOWER],
  },
  {
    path: '/flowers/add',
    component: ManageFlowersPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.CREATE_COLOR_FLOWER],
  },
  {
    path: '/flowers/:id',
    component: ManageFlowersPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.EDIT_COLOR_FLOWER],
  },
  {
    path: '/ingredients',
    component: IngredientsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_INGREDIENT],
  },
  {
    path: '/ingredients/add',
    component: ManageIngredientsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.CREATE_CATEGORY],
  },
  {
    path: '/ingredients/:id',
    component: ManageIngredientsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.EDIT_INGREDIENT],
  },
  {
    path: '/orders',
    component: OrdersPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_ORDER],
  },
  {
    path: '/order-options',
    component: OptionsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_CATEGORY_OPTIONS],
  },
  {
    path: '/order-options/:id',
    component: ManageOptionsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.EDIT_CATEGORY_OPTIONS],
  },
  {
    path: '/order-questions',
    component: QuestionsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_CATEGORY_QUESTIONS],
  },
  {
    path: '/order-questions/:id',
    component: ManageQuestionsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.EDIT_CATEGORY_QUESTIONS],
  },
  {
    path: '/staff-meals',
    component: StaffMealsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.STAFF_MEALS],
  },
];
