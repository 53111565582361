import React, { Component, Fragment } from 'react';
import { Popconfirm, Icon } from 'antd';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton
} from '../../styles/BasicStyles';
import { GetSupplierCategories, DeleteSupplierCategory } from '../../infra/requests/SupplierRequests';
import BaseButton from '../../components/buttons/BaseButton';
import Table from '../../components/table/Table';

class CategoriesPage extends Component {
  state = {
    loading: false,
    rows: [],
    currentPage: 1,
    pageSize: 30,
    total: 0,
    columns: [
      {
        title: 'Nome',
        dataIndex: 'name'
      },
      {
        title: 'Solar da Levada',
        render: data => {
          if (data.default_solar) {
            const found = data.suppliers.find(
              sup => sup._id === data.default_solar
            );
            if (found) return found.name;
          }
          return 'Não definido';
        }
      },
      {
        title: 'Lago dos Cisnes',
        render: data => {
          if (data.default_lago) {
            const found = data.suppliers.find(
              sup => sup._id === data.default_lago
            );
            if (found) return found.name;
          }
          return 'Não definido';
        }
      },
      {
        title: 'Acções',
        render: data => (
          <Fragment>
            {!data.system && (
              <TableButton onClick={e => e.stopPropagation()}>
                <Popconfirm
                  placement="topRight"
                  title="Tem a certeza que quer apagar esta categoria?"
                  onConfirm={() => this.deleteSupplierCategory(data._id)}>
                  <Icon type="delete" />
                  {' Apagar'}
                </Popconfirm>
              </TableButton>
            )}
          </Fragment>
        )
      }
    ]
  };

  componentDidMount() {
    this.getInfo();
  }

  getInfo = async () => {
    try {
      const { currentPage, pageSize } = this.state;
      const { data } = await GetSupplierCategories(currentPage, pageSize);
      this.setState({
        rows: data.items || [],
        total: data.total || 0
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
    }
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.getInfo);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.getInfo);
  };

  onPressRow = data => {
    const { history } = this.props;
    history.push(`/categories/${data._id}`);
  };

  deleteSupplierCategory = async id => {
    await DeleteSupplierCategory(id);
    await this.getInfo();
  };

  render() {
    const { history } = this.props;
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Categorias</PageTitle>
            Categorias de Encomenda
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              block
              text={'Criar'}
              onClick={() => history.push('/categories/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={1}>
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="database"
            emptyText={'Não existem categorias de encomenda'}
            total={total}
            onPressRow={this.onPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowKey={'_id'}
            hasPagination={true}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default CategoriesPage;
