import client from '../config/AxiosConfig';

export const GetUnavailable = async (currentPage, limit, filters) =>
  client.get(`/unavailable/${currentPage}/${limit}?filter=${filters}`);

export const GetAllCompanyRest = async () =>
  client.get(`/unavailable/getCompanyRest`);

export const GetCalendarCompanyRest = async (filter) =>
  client.get(`/unavailable/getCalendarCompanyRest?filter=${filter}`);

export const GetCalendarEmployeeRest = async (filter) =>
  client.get(`/unavailable/getCalendarEmplyeeRest?filter=${filter}`);

export const GetEmployeeRest = async (userId) =>
  client.get(`/unavailable/getEmployeeRest/${userId}`);

export const GetUnavailableByDate = async (date) =>
  client.get(`/unavailable/getByDate/${date}`);

export const GetEmployeeAndCompanyRest = async (userId) =>
  client.get(`/unavailable/getEmployeeAndCompanyRest/${userId}`);

export const CreateUnavailable = async (data) =>
  client.put(`/unavailable/create`, data);

export const CreateTotalUnavailable = async (data) =>
  client.put(`/unavailable/createTotalByAgenda`, data);

export const CreateMultipleUnavailable = async (data) =>
  client.post(`/unavailable/multipleByEmployee`, data);

export const UpdateUnavailable = async (data) =>
  client.post(`/unavailable/update`, data);

export const SaveUpdateAvailable = async (data) =>
  client.post(`/unavailable/saveUpdateAvailable`, data);

export const RemoveUnavailable = async (id) =>
  client.delete(`/unavailable/remove/${id}`);

export const RemoveMultipleUnavailable = async (employeeId, data) =>
  client.post(`/unavailable/removelMultipleByEmployee/${employeeId}`, data);

export const CreateExceptionCouplePayment = async (data) =>
  client.put(`/unavailable/createException`, data);

export const UpdateExceptionCouplePayment = async (data) =>
  client.post(`/unavailable/updateException`, data);

export const UpdateMultipleCompanyRest = async (local, reserveList) =>
  client.post(`/unavailable/updateManyCompanyRest`, { local, reserveList });

export const RemoveUnavailableByAgenda = async (date, local) =>
  client.delete(`/unavailable/removeByAgenda/${date}/${local}`);

export const RemoveTotalUnavailableByAgenda = async (date, local) =>
  client.delete(`/unavailable/removeTotalByAgenda/${date}/${local}`);
