import client from '../config/AxiosConfig';

export const EmailYearType = {
  'COMPLEMENTS': 'C',
  'SIGNATURE': 'S',
  'TOKEN_GOOGLE_DRIVE': 'T',
  'START_ATTENDING_TIME': 'SAT',
  'END_ATTENDING_TIME': 'EAT',
  'HOST_TIME': 'HT',
  'EMAILS_ATTENTING': 'EBA',
  'PASSAGE_SPACE_BET_TABLES': 'PSBT',
  'COST_EXTRA_TABLE': 'CET',
  'BASE_PRICE_TRAINEE': 'BPET',
}


// export const GetAllDEYear = async () =>
//   client.get(`/dynamicEmailYear/findAll`);

export const GetALLDEYear = async (currentPage, limit, filters) =>
  client.get(`/dynamicEmailYear/${currentPage}/${limit}?filter=${filters}`);

export const GetDEYearByType = async (type) =>
  client.get(`/dynamicEmailYear/findByType/${type}`);

export const GetAllDEYearByType = async (type) =>
  client.get(`/dynamicEmailYear/findAllByType/${type}`);

export const GetDEYear = async (id) =>
  client.get(`/dynamicEmailYear/find/${id}`);

export const CreateDEYear = async (data) =>
  client.put(`/dynamicEmailYear/create`, data);

export const UpdateDEYear = async (data) =>
  client.post(`/dynamicEmailYear/update`, data);

export const DeleteDEYear = async id =>
  client.delete(`/dynamicEmailYear/remove/${id}`);