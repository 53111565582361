import PropTypes from 'prop-types';
import React from 'react';
import { InputDiv, InputLabelDiv, DateInputField } from './InputStyles';
import moment from 'moment';

const XpertGoDateInput = ({
  input,
  meta,
  label,
  first,
  placeholder,
  disabled,
  dateFormat,
  allowClear = true,
  disabledDate,
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed ? 1 : 0;
  return (
    <InputDiv first={first}>
      {label && <InputLabelDiv>{label}</InputLabelDiv>}
      <DateInputField
        disabled={disabled}
        error={showError}
        placeholder={placeholder}
        format={dateFormat || 'DD-MM-YYYY'}
        // defaultValue={input.value && input.value !== null ? moment.utc(input.value) : null}
        value={input.value && input.value !== null && input.value !== '' ? moment.utc(input.value) : null}
        disabledDate={disabledDate}
        onChange={date =>
          date ? input.onChange(moment(date).utc(true)) : input.onChange('')
        }
        allowClear={allowClear}
      />
    </InputDiv>
  );
};

XpertGoDateInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

export default XpertGoDateInput;
