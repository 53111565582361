import React, { Component, Fragment } from 'react';
import Table from '../../components/table/Table';
import {
    Row,
    Col,
    Popconfirm,
    Icon,
} from 'antd';
import {
    HeaderButtonsContainer,
    TableButton,
    SectionTitle,
    TableFilterSection
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import { withLocalize } from 'react-localize-redux';
import {
    GetSettingsByType
} from '../../infra/requests/SettingRequests'
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import { GetPriceBySubcategory } from '../../infra/requests/PriceRequests';
import NumberInput from '../../components/inputs/NumberInput';

const RemoveMessage = (
    <div>
        <div>Tem a certeza que quer remover este preço? </div>
    </div>
);


class SubcategoryPriceTable extends Component {
    state = {
        pageSize: 15,
        currentPage: 1,
        loading: false,
        submitting: false,

        subcategory: null,

        unitData: [],
        unitDataOriginal: [],
        addEditPrice: [],
        deletePrice: [],
        columns: [
            {
                title: 'Unidade',
                key: 'unit',
                width: '150px',
                render: data => {
                    return <SelectInput
                        label=""
                        allowClear={true}
                        placeholder="Escolher a unidade"
                        data={this.state.unitData}
                        input={{
                            value: data.setting.name, onChange: event => this.onSelectChange(event, data)
                        }}
                        meta={{ invalid: false }}
                    />;
                    // return data?.setting?.id != null
                    //     ? <SelectInput
                    //         label=""
                    //         allowClear={true}
                    //         placeholder="Escolher a unidade"
                    //         data={this.state.unitData}
                    //         input={{
                    //             value: data.setting.name, onChange: event => this.onSelectChange(event, data)
                    //         }}
                    //         meta={{ invalid: false }}
                    //     />
                    //     : <TextInput
                    //         key={data.id}
                    //         input={{
                    //             value: data.setting.name,
                    //             onChange: event => this.onUnitChange(data, event)
                    //         }}
                    //         meta={{ invalid: false }}
                    //         type="text"
                    //         placeholder="Insira o nome da unidade"
                    //     />
                }
            },
            {
                title: 'Preço c/Seguro',
                key: 'priceWithInsurance',
                width: '100px',
                render: data => (
                    <NumberInput
                        min={0}
                        onInput="validity.valid || (value = '')"
                        step={.01}
                        input={{
                            value: data.priceWithInsurance,
                            onChange: event => this.onInputChange(true, data, event)
                        }}
                        meta={{}}
                        placeholder="Insira o preço c/seguro"
                    />
                )
            },
            {
                title: 'Preço s/Seguro',
                key: 'priceWithoutInsurance',
                width: '100px',
                render: data => (
                    <NumberInput
                        min={0}
                        onInput="validity.valid || (value = '')"
                        step={.01}
                        input={{
                            value: data.priceWithoutInsurance,
                            onChange: event => this.onInputChange(false, data, event)
                        }}
                        meta={{}}
                        type="number"
                        placeholder="Insira o preço s/seguro"
                    />
                )
            },
            {
                title: 'Acções',
                width: '120px',
                render: data => (
                    <Fragment>
                        <TableButton onClick={e => e.stopPropagation()}>
                            <Popconfirm
                                placement="topRight"
                                title={RemoveMessage}
                                okText="Remover"
                                onConfirm={async () => await this.deletePrice(data)}>
                                <Icon type="delete" />
                                {' Remover'}
                            </Popconfirm>
                        </TableButton>
                    </Fragment>
                )
            }
        ],
        rows: [],
        total: 0
    };

    componentDidMount = async () => {
        this.setState({ subcategory: this.props.subcategory }, async () => {
            await this.updateDataTable();
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.subcategory !== this.props.subcategory) {
            this.setState({ subcategory: this.props.subcategory, deletePrice: [], addEditPrice: [] }, async () => {
                await this.updateDataTable();
            });
        }
    }

    /**
     * @description Update data on datatable
     */
    updateDataTable = async () => {
        this.setState({ loading: true });
        const { subcategory } = this.state;
        //console.warn('subcategory', subcategory);

        const resultUnit = await GetSettingsByType(4);
        //resultUnit.data?.length > 0 ? resultUnit.data.push({ _id: 'idNew', name: 'Adicionar Nova' }) : resultUnit.data = [{ _id: 'idNew', name: 'Adicionar Nova' }];
        let unitData = resultUnit.data?.length > 0 ? resultUnit.data : [];
        this.setState({ unitData, unitDataOriginal: resultUnit.data?.length > 0 ? resultUnit.data : [] });

        try {
            const result = subcategory && subcategory.id ? await GetPriceBySubcategory(subcategory.id) : null;

            if (result && result.data && result.data.length > 0) {
                result.data.forEach(element => {
                    if (element.setting && element.setting.id) unitData = unitData.filter(f => f.id !== element?.setting.id)
                });
            }

            this.setState({
                rows: result?.data.length > 0 ? result.data : [],
                total: result?.data.length,
                unitData,
                loading: false
            });
        } catch (e) {
            this.setState({ loading: false });
            console.error(e);
        }
    }

    /**
     * @description Show add row
     */
    setAddForm = () => {
        const { rows, addEditPrice, subcategory, total } = this.state;

        const price = {
            id: 'id#FFFFF' + (total + 1),
            priceWithInsurance: 0.00,
            priceWithoutInsurance: 0.00,
            subcategory: subcategory,
            setting: {
                id: 'new'
            }
        };
        rows.push(price);
        addEditPrice.push(price);
        this.setState({ rows: rows, addEditPrice: addEditPrice, total: rows.length });
    }

    onSelectChange = (event, data) => {
        let { rows, addEditPrice, unitData } = this.state;

        if (event !== '') {
            if (event === 'idNew') {
                data.setting = {
                    id: null,
                    name: '',
                    entity: false,
                    paymentMethod: false,
                    invoicingType: false,
                    priceUnits: true
                };

                //Update price on rows
                const indexRows = rows.findIndex(f => f.id === data.id);
                rows[indexRows] = data;

                this.setState({ rows: rows, total: rows.length });
            } else {
                const unitSetting = unitData.filter(u => u.id === event)[0];
                data.setting = unitSetting;

                //Update price on rows
                const indexRows = rows.findIndex(f => f.id === data.id);
                rows[indexRows] = data;

                //Update UnitData List
                unitData = unitData.filter(u => u.id !== event);

                // Update list of prices to add or update
                const indexPrice = addEditPrice.findIndex(p => p.id === data.id);
                indexPrice > -1 ? addEditPrice[indexPrice] = data : addEditPrice.push(data);

                this.setState({ rows: rows, addEditPrice: addEditPrice, total: rows.length, unitData });
                this.props.updatePrice(addEditPrice);
            }
        }
    }

    onInputChange = (isWithInsurance, data, event) => {
        const { rows, addEditPrice } = this.state;

        if (isWithInsurance) data.priceWithInsurance = event;
        else data.priceWithoutInsurance = event;

        //Update price on rows
        const indexRows = rows.findIndex(f => f.id === data.id);
        rows[indexRows] = data;

        // Update list of prices to add or update
        const indexSub = addEditPrice.findIndex(p => p.id === data.id);
        indexSub > -1 ? addEditPrice[indexSub] = data : addEditPrice.push(data);

        this.setState({ rows: rows, addEditPrice: addEditPrice, total: rows.length });
        this.props.updatePrice(addEditPrice);
    }

    onUnitChange = (data, event) => {
        const { rows, addEditPrice } = this.state;

        data.setting.name = event.target.value;

        //Update price on rows
        const indexRows = rows.findIndex(f => f.id === data.id);
        rows[indexRows] = data;

        // Update list of prices to add or update
        const indexSub = addEditPrice.findIndex(p => p.id === data.id);
        indexSub > -1 ? addEditPrice[indexSub] = data : addEditPrice.push(data);

        this.setState({ rows: rows, addEditPrice: addEditPrice });
        this.props.updatePrice(addEditPrice);
    }

    /**
     * @description Delete price
     * @param {*} price - price 
     * @returns datatable price updated
     */
    deletePrice = async (price) => {
        let { deletePrice, rows, unitData, unitDataOriginal } = this.state;
        let { addEditPrice } = this.state;
        let rowsDelete;

        if (price?.setting) {
            unitData.push(price.setting);
        }

        if (price?.id && price?.id.includes('id#FFFFF')) {
            rowsDelete = rows.filter(f => f.id !== price.id);

            addEditPrice = addEditPrice.filter(f => f.id !== price.id);

            this.setState({ rows: rowsDelete, total: rowsDelete.length, unitData });
            this.props.updatePrice(addEditPrice);
        } else {
            addEditPrice = addEditPrice.filter(f => f.id !== price.id);
            rowsDelete = rows.filter(f => f.id !== price.id);
            deletePrice.push(price);
            this.setState({ rows: rowsDelete, deletePrice: deletePrice, total: rowsDelete.length, unitData });
            this.props.deletePrice(deletePrice);
            this.props.updatePrice(addEditPrice);
        }
    }

    render() {
        const { loading, currentPage, pageSize } = this.state;
        const { columns, rows, total } = this.state;

        return (
            <React.Fragment>
                <TableFilterSection style={{ marginBottom: '0' }}>
                    <Row gutter={24} style={{ marginTop: '2rem' }}>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <SectionTitle>Tabela de Preços</SectionTitle>
                        </Col>

                        <Col lg={12} md={12} sm={12} xs={24} className='subcategory-col-btn'>
                            <BaseButton
                                type={'primary'}
                                icon={'plus'}
                                text={'Adicionar Preço'}
                                onClick={() => this.setAddForm()}
                            />
                        </Col>
                    </Row>
                </TableFilterSection>

                <Table
                    columns={columns}
                    loading={loading}
                    rows={rows}
                    showHeader={true}
                    emptyIcon="setting"
                    emptyText={'Não existem preços'}
                    total={total}
                    rowKey={'_id'}
                    hasPagination={false}
                />
            </React.Fragment>
        );
    }
}

export default withLocalize(SubcategoryPriceTable);