import React, { Component } from 'react';
import moment from 'moment';
import { Breadcrumb } from 'antd';
import {
  SpinLoading,
  HeaderContainer,
  HeaderTitle,
  PageTitle,
  PageContainer,
  SectionTitle,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import { GetWedding, UpdateWedding } from '../../infra/requests/WeddingRequests';
import { GetOurDayByWedding } from '../../infra/requests/OurDayRequests';
import { CreateTeamBooking, DeleteTeamBooking, GetTeamBookingByWedding, UpdateTeamBooking } from '../../infra/requests/TeamBookingRequests';
import { CreateWeddingTeam, DeleteWeddingTeam, GetWeddingTeamByWedding, GetWeddingTeamByWeddingAndEmployee, UpdateWeddingTeam } from '../../infra/requests/WeddingTeamRequests';
import Alert from "../../components/alert/Alert";
import { GetSubcategoriesByType } from '../../infra/requests/SubcategoryRequests';
import WeddingInfo from '../employeeHome/WeddingInfo';
import AddDeleteWeddingInterest from './AddDeleteWeddingInterest';
import { rest } from 'lodash';
import { GetEmployeeByUser } from '../../infra/requests/EmployeeRequests';
import { GetWeddingInterestByWeddingAndEmployee, GetWeddingInterestByWeddingAndEmployeeAndSubcategory } from '../../infra/requests/WeddingInterestRequests';
import { getScheduler } from '../../infra/services/wedding/weddingUtils';
import { GetColorSystemByType } from '../../infra/requests/ColorSystemRequests';
import { getCurrentUser } from '../../infra/helpers/Helpers';

class WeddingInterestPage extends Component {
  state = {
    loading: true,
    currentUser: null,
    currentEmployee: null,
    wedding: null,
    teamBooking: null,
    colors: {
      colorSys1: null,
      colorSys2: null
    },
    addInterest: [],
    deleteInterest: []
  };

  componentDidMount = async () => {
    const localUser = getCurrentUser();
    this.setState({ currentUser: localUser });
    this.getEmployee(localUser);
    this.getLocalColors();
  }

  getLocalColors = async () => {
    const resColorSys1 = await GetColorSystemByType(4);
    const colorSys1 = resColorSys1.success && resColorSys1.data ? resColorSys1.data.color : '#f7b5d6';
    const resColorSys2 = await GetColorSystemByType(5);
    const colorSys2 = resColorSys2.success && resColorSys2.data ? resColorSys2.data.color : '#b6d7a9';
    this.setState({ colors: { colorSys1, colorSys2 } });
  }

  getEmployee = async (user) => {
    let resEmployee;
    resEmployee = await GetEmployeeByUser(user._id);

    this.setState({
      currentEmployee: resEmployee.data,
    }, () => this.getWedding());
  }

  getWedding = async () => {
    const { currentUser, currentEmployee } = this.state;
    const weddingId = this.props.match.params.id;
    const result = await GetWedding(weddingId);
    const wedding = result.data;

    const resBooking = await GetTeamBookingByWedding(wedding.id);
    const resOurDay = await GetOurDayByWedding(wedding.id);

    this.setState({
      wedding: wedding,
      ourDay: resOurDay.data.length > 0 ? resOurDay.data : null,
      scheduler: getScheduler(resOurDay),
      teamBooking: resBooking.success && resBooking.data.length > 0 ? resBooking.data : null,
      loading: false
    });
  }

  render() {
    const { currentUser, currentEmployee } = this.state;
    let { wedding, ourDay, scheduler, teamBooking, weddingInterest, colors } = this.state;
    const { loading } = this.state;
    const bgColor = wedding?.wedding_place === 1 ? colors.colorSys1 : wedding?.wedding_place === 2 ? colors.colorSys2 : '';

    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Casamentos</PageTitle>
            <Breadcrumb>
              <Breadcrumb.Item>
                <a href="/employee-weddings">Casamentos</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Interesse</Breadcrumb.Item>
            </Breadcrumb>
          </HeaderTitle>

          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'primary'}
              icon={'left'}
              text={'Voltar'}
              block
              onClick={() => {
                const { history } = this.props;
                history.goBack();
              }}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={1}>
          <SectionTitle subsection style={{ backgroundColor: bgColor, borderRadius: 5, padding: '5px 10px', color: '#fff', width: 'fit-content' }}>Casamento</SectionTitle>

          <WeddingInfo
            showHomeHeader={false}
            showTable={false}
            currentEmployee={currentEmployee}
            selectedWedding={{
              wedding: wedding,
              ourDay: ourDay,
              scheduler: scheduler
            }}
          />

          <AddDeleteWeddingInterest
            currentUser={currentUser}
            currentEmployee={currentEmployee}
            selectedWedding={wedding}
            teamBooking={teamBooking}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default WeddingInterestPage;
