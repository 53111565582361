import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Field, reduxForm, initialize, FieldArray } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import { Prompt } from "react-router";
import {
  GetSupplierCategory,
  CreateSupplierCategories,
  UpdateSupplierCategory
} from '../../infra/requests/SupplierRequests';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import EmailList from '../../components/inputs/EmailList';
import BaseButton from '../../components/buttons/BaseButton';
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';

const validations = FormValidator.make({
  name: 'required'
});

class ManageCategoryPage extends Component {
  state = {
    loading: true,
    system: false,
    suppliers: []
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;
    let system = false;
    let suppliers = [];
    if (params.id) {
      const { data } = await GetSupplierCategory(params.id);

      system = data.system;
      suppliers = data.suppliers || [];

      //Check if the Supplier still exists, so we don't show the deleted ID in the field
      let supplierExistsLago = false;
      if (data?.default_lago && data?.suppliers?.length > 0) {
        data.suppliers.forEach(supplier => {
          if (data.default_lago === supplier._id) {
            supplierExistsLago = true;
          }
        });
      }

      //Check if the Supplier still exists, so we don't show the deleted ID in the field
      let supplierExistsSolar = false;
      if (data?.default_solar && data?.suppliers?.length > 0) {
        data.suppliers.forEach(supplier => {
          if (data.default_solar === supplier._id) {
            supplierExistsSolar = true;
          }
        });
      }

      const formData = {
        name: data?.name,
        extra_emails: data?.extra_emails,
        default_lago: supplierExistsLago ? data?.default_lago : undefined,
        default_solar: supplierExistsSolar ? data?.default_solar : undefined
      }

      dispatch(initialize('manage_supplier_category_form', formData));
    } else {
      dispatch(initialize('manage_supplier_category_form', {}));
    }
    this.setState({ suppliers, system, loading: false });
  };

  onSubmit = async values => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;

      const { success } = params.id
        ? await UpdateSupplierCategory(params.id, values)
        : await CreateSupplierCategories(values);
      if (success) return history.push('/categories');
      return this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/categories');
  };

  render() {
    const { handleSubmit, dirty } = this.props;
    const { loading, system, suppliers } = this.state;
    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Categoria de Encomendas</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Prompt
              when={dirty}
              message='Está prestes a sair sem guardar as alterações. Deseja continuar?'
            />
            <Row style={{ display: 'grid', justifyItems: 'center' }}>
              <Col xs={24} sm={18} md={15} lg={10}>
                <Field
                  component={TextInput}
                  name={'name'}
                  disabled={system}
                  type="text"
                  label={'Nome *'}
                  placeholder={'Nome da Categoria'}
                />
                <Field
                  component={SelectInput}
                  name={'default_solar'}
                  label={'Fornecedor Solar da Levada'}
                  placeholder={'Fornecedor pré-definido'}
                  notFoundMessage="Não existem fornecedores para esta categoria"
                  data={suppliers}
                />
                <Field
                  component={SelectInput}
                  name={'default_lago'}
                  label={'Fornecedor Lago dos Cisnes'}
                  placeholder={'Fornecedor pré-definido'}
                  notFoundMessage="Não existem fornecedores para esta categoria"
                  data={suppliers}
                />
                <FieldArray
                  component={EmailList}
                  name="extra_emails"
                  label="Emails Extra"
                  placeholder="Sempre que for enviada uma encomenda desta categoria, todos os emails abaixo irão receber também"
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageCategoryPage = reduxForm({
  form: 'manage_supplier_category_form',
  validate: validations
})(ManageCategoryPage);

export default withLocalize(ManageCategoryPage);
