import React, { useEffect, useState } from 'react';
import {
  TimelineContainer,
  Column1,
  Column2,
  Dot,
  TimelineMobileContainer,
  MobileColumn,
  EmptyTimeline,
  TimelineDiv
} from './TimelineStyles';
import TimelineItem from '../timelineItem/TimelineItem';
import { compareHours } from '../../infra/services/ourDay/CompareHours';
import { splitTimeline } from '../../infra/services/ourDay/PageOurDay';
import { SpinLoading } from '../../styles/BasicStyles';

const emptyElem = {
  '_id': '',
  'name': '',
  'time': ''
};

const startHour = '05:01';

const Timeline = ({ list, deleteMoment, openEditModal, notesOpen, loading, printed }) => {
  const [active, setActive] = useState('');
  const [windowWidth, setWindowWidth] = useState(0);

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  const column1Array = [];
  const column2Array = [];
  const mobileArray = []
  let lastHour = '';
  let numGroups = 0;

  if (list.length > 0) {
    /* 
    * First we need to order the list by Time
    * Cannot sort directly because if we add a moment for 00h it shouldn't appear first
    * We have a START DATE for all weddings and divide into 2 temp arrays
    * One with moments before that hour, and the other with the moments after
    * Then we can order each array and join them into one
    */
    const orderedArray = splitTimeline(list, startHour);

    for (let i = 0; i < orderedArray.length; i++) {
      //If the time of the current element is 1 hourd after the last, we had 1 blank space in both arrays
      if (lastHour && compareHours(orderedArray[i].time, lastHour)) {
        column1Array.push(emptyElem);

        column2Array.push(emptyElem);

        mobileArray.push(emptyElem);

        numGroups++;
      }

      lastHour = orderedArray[i].time;

      //Odd array - First column of the page
      if (!(i % 2)) {
        column1Array.push(orderedArray[i]);
      }
      //Even array - Second column of the page
      else {
        column2Array.push(orderedArray[i]);
      }

      //Add to Mobile Version array, there it shows all the results in 1 column
      mobileArray.push(orderedArray[i]);
    }
  }

  //Number of Dots = Number of elements in array + 4 on top + 4 on bottom + 2 in middle the each 2 groups of elements
  let numDots = list.length + 8 + (numGroups * 2);

  //When all the Moments are displayed in a column, we must add 1 dot between every element (except in the middle of the groups)
  if (windowWidth < 850) {
    numDots += list.length - numGroups + 1;
  }

  //Variable to control the "z-index" of each Timeline Item. 
  //Each Item must have a greater "z-index" than the next, otherwise the Answers List won't work properly
  const zIndex = list.length + (numGroups * 2);

  const toggleActive = (item) => {
    if (item !== active) {
      setActive(item);
    }
    else {
      setActive('');
    }
  }

  const closeActive = () => {
    setActive('');
  }

  const renderDots = () => {
    const arrayDots = [];
    let top = 0;

    for (let i = 0; i < numDots; i++) {
      arrayDots.push(<Dot key={i} isFirst={i === 0} isLast={i === (numDots - 1)} top={top} />);
      top += 35;
    }

    return arrayDots;
  }

  const renderContent = () => {
    if (loading) return <SpinLoading />;
    return (
      <>
        {column1Array.length > 0 ? (
          <TimelineContainer>
            <Column1>
              {column1Array.map((item, index) => (
                <TimelineItem
                  key={`odd_${index}`}
                  id={item._id}
                  name={item.name}
                  time={item.time}
                  required={item.required}
                  requiredOrderModule={item.requiredOrderModule}
                  direction="right"
                  deleteMoment={deleteMoment}
                  openEditModal={openEditModal}
                  toggleActive={toggleActive}
                  closeActive={closeActive}
                  active={active === item._id}
                  zIndex={zIndex - index}
                  printed={printed}
                />
              ))}
            </Column1>
            <Column2>
              {renderDots()}
              {column2Array.map((item, index) => (
                <TimelineItem
                  key={`even_${index}`}
                  id={item._id}
                  name={item.name}
                  time={item.time}
                  required={item.required}
                  requiredOrderModule={item.requiredOrderModule}
                  direction="left"
                  deleteMoment={deleteMoment}
                  openEditModal={openEditModal}
                  toggleActive={toggleActive}
                  closeActive={closeActive}
                  active={active === item._id}
                  zIndex={zIndex - index}
                />
              ))}
            </Column2>
          </TimelineContainer>
        ) : (
          <EmptyTimeline>Ainda não adicionaram momentos ao dia!</EmptyTimeline>
        )}
        {mobileArray.length > 0 && (
          <TimelineMobileContainer>
            <MobileColumn>
              {renderDots()}
              {mobileArray.map((item, index) => (
                <TimelineItem
                  key={index}
                  id={item._id}
                  name={item.name}
                  time={item.time}
                  required={item.required}
                  requiredOrderModule={item.requiredOrderModule}
                  direction="left"
                  deleteMoment={deleteMoment}
                  openEditModal={openEditModal}
                  toggleActive={toggleActive}
                  closeActive={closeActive}
                  active={active === item._id}
                  zIndex={zIndex - index}
                />
              ))}
            </MobileColumn>
          </TimelineMobileContainer>
        )}
      </>
    );
  };
  return <TimelineDiv notesOpen={notesOpen}>{renderContent()}</TimelineDiv>;
};

export default Timeline;