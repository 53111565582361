import React, { Fragment, useState } from 'react';
import { notification, Icon, Popconfirm } from 'antd';
import Dropzone from 'react-dropzone';
import { ResponsePill, ExtraPill, FileSection } from './../orders/components/Styles';

const FileInput = ({ input, handleAdd, handleDelete }) => {
  const [loading, setLoading] = useState(false);

  const handleDrop = async (accepted, rejected) => {
    if (accepted.length <= 0 || (rejected && rejected.length > 0)) {
      return notification.error({
        message: 'Ficheiro Inválido',
        description: 'O ficheiro é inválido ou tem um tamanho superior a 12Mb'
      });
    }

    setLoading(true);
    await handleAdd(accepted[0]);
    setLoading(false);
  };

  const handleRemove = async () => {
    setLoading(true);
    await handleDelete();
    setLoading(false);
    // return fields.remove(index);
  };

  const renderDropZone = () => {
    return (
      <Dropzone
        multiple={false}
        onDrop={handleDrop}
        accept="application/pdf"
        maxSize={120000000}>
        {({ getRootProps, getInputProps }) => {
          return (
            <FileSection {...getRootProps()}>
              <input {...getInputProps()} />
              {loading ? <Icon type={'loading'} /> : <Icon type="file" />}
              {loading ? ' A carregar...' : ' Clique ou arraste um ficheiro'}
            </FileSection>
          );
        }}
      </Dropzone>
    );
  };

  if (input.value) {
    const size = (input.value.size / (1024 * 1024)).toFixed(2);

    return (
      <Fragment>
        {/* <ResponsePill single={false}>{input.value.blob.name}</ResponsePill>
        <ExtraPill last={true}>{size}MB</ExtraPill> */}
        <ResponsePill style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {(input.value.blob && input.value.blob.name) || input.value.filename || ''}
          {loading && <Icon type={'loading'} />}
          {!loading && <Popconfirm
            placement={'topRight'}
            title="Tem a certeza que quer remover este ficheiro?"
            onConfirm={() => handleRemove()}>
            <Icon type="delete" />
          </Popconfirm>}
        </ResponsePill>
        {renderDropZone()}
      </Fragment>
    );
  }

  return renderDropZone();
};

export default FileInput;