import React, { Component } from 'react';
import { Popconfirm, Icon, Tooltip } from 'antd';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton
} from '../../styles/BasicStyles';
import { GetAdmins, ResendEmailAdmin, RevokeAccess } from '../../infra/requests/AdminsRequests';
import BaseButton from '../../components/buttons/BaseButton';
import Alerts from '../../components/alert/Alert';

class AdminsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    columns: [
      {
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: 'E-mail',
        dataIndex: 'email'
      },
      {
        title: 'Organizador de Casamentos',
        dataIndex: 'organizer',
        render: value => (value ? 'Sim' : 'Não')
      },
      {
        title: 'Actions',
        render: data => (
          <div onClick={e => e.stopPropagation()}>
            <TableButton onClick={e => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title="Tem a certeza que pretende eliminar o administrador? Esta informação não será possível recuperar."
                onConfirm={() => this.revokeAccess(data)}>
                <Icon type="eye-invisible" />
                {' Revoke access'}
              </Popconfirm>
            </TableButton>
            {!data.accepted ?
              <TableButton onClick={e => e.stopPropagation()}>
                <Popconfirm
                  placement="topRight"
                  title="Quer reenviar o email de acesso a este utilizador?"
                  onConfirm={() => this.resendEmail(data)}>
                  <Icon type="inbox" />
                  {' Reenviar email'}
                </Popconfirm>
              </TableButton>
              : null}
            {data?.organizer && <TableButton onClick={e => this.goToScheduleServiceAgenda(e, data)}>
              <Icon type="clock-circle" />
              {' Horários do organizador'}
            </TableButton>}
          </div>
        )
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = record => {
    const { history } = this.props;
    history.push(`/admins/${record._id}`);
  };

  goToScheduleServiceAgenda = (e, record) => {
    const { history } = this.props;
    e.stopPropagation();
    if (!record?.organizer) return;
    history.push(`/schedule-agenda/${record._id}`);
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  revokeAccess = async data => {
    try {
      await RevokeAccess(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  resendEmail = async (data) => {
    const result = await ResendEmailAdmin(data._id);
    if (result.success) {
      Alerts.new({
        type: "success",
        title: "Sucesso!",
        text: 'Email re-enviado!'
      });
      await this.updateDataTable();
    } else {
      return Alerts.new({
        type: "error",
        title: "Erro!",
        text: 'Falha no envio do email!'
      });
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize } = this.state;
      const result = await GetAdmins(currentPage, pageSize);
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;
    const { history } = this.props;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Administradores</PageTitle>
            Lista de Administradores
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              block
              text={'Criar'}
              onClick={() => history.push('/admins/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={1}>
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="solution"
            emptyText={'There are not admins'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default AdminsPage;
