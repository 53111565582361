import React from 'react';
import PropTypes from 'prop-types';
import { Empty, ConfigProvider, Icon, Pagination } from 'antd';
import { StyledTable } from './TableStyles';

const Table = ({
  columns,
  rows,
  showHeader,
  alignContentTop,
  title,
  size,
  total,
  pageSize,
  pageSizeOptions,
  currentPage,
  handleChangePage,
  handleChangeRowsPerPage,
  loading,
  emptyText,
  emptyIcon,
  defaultPageSize,
  onPressRow,
  rowKey,
  expandedRowRender,
  hasPagination,
  hasTopPagination,
  hasPageSize,
  secondary,
  scroll,
  fixedHeader,
  noPadding,
  autoScrollTop
}) => {
  const renderEmptyTable = () => (
    <Empty
      description={emptyText ? emptyText : null}
      image={
        emptyIcon ? (
          <Icon type={emptyIcon} style={{ fontSize: '100px' }} />
        ) : null
      }
    />
  );

  const thisHandleChangePage = (currentPage) => {
    if (autoScrollTop) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    handleChangePage(currentPage);
  }

  const thisHandleChangeRowsPerPage = (currentSize, pageSize) => {
    if (autoScrollTop) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    handleChangeRowsPerPage(currentSize, pageSize);
  }

  return (
    <ConfigProvider renderEmpty={renderEmptyTable}>
      {hasPagination && hasTopPagination && <Pagination
        className='ant-table-pagination'
        style={{ display: 'flex', justifyContent: 'flex-start', float: 'none', width: '100%' }}
        showSizeChanger
        size="small"
        defaultCurrent={currentPage}
        total={total}
        showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} itens`}
        pageSize={pageSize ? pageSize : 15}
        defaultPageSize={defaultPageSize ? defaultPageSize : 15}
        pageSizeOptions={pageSizeOptions
          ? pageSizeOptions
          : ['15', '30', '45', '60']}
        onChange={thisHandleChangePage}
        onShowSizeChange={thisHandleChangeRowsPerPage}
      />}

      <StyledTable
        alignContentTop={alignContentTop}
        columns={columns}
        showHeader={showHeader}
        title={title}
        dataSource={rows ? rows : []}
        bordered={true}
        size={size ? size : 'small'}
        loading={loading}
        rowKey={rowKey}
        expandedRowRender={expandedRowRender}
        secondary={secondary}
        scroll={scroll || { scrollToFirstRowOnChange: true }}
        fixedHeader={fixedHeader}
        noPadding={noPadding}
        onRow={
          onPressRow
            ? (record, rowIndex) => ({
              onClick: event => onPressRow(record, rowIndex)
            })
            : () => false
        }
        pagination={
          hasPagination && hasPageSize
            ? {
              total: total,
              showQuickJumper: false,
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} de ${total} itens`,
              pageSize: pageSize ? pageSize : 15,
              pageSizeOptions: pageSizeOptions
                ? pageSizeOptions
                : ['15', '30', '45', '60'],
              current: currentPage,
              onChange: thisHandleChangePage,
              onShowSizeChange: thisHandleChangeRowsPerPage,
              defaultPageSize: defaultPageSize ? defaultPageSize : 15
            }
            : hasPagination && !hasPageSize ? {
              total: total,
              showQuickJumper: false,
              showSizeChanger: true,
              pageSize: pageSize ? pageSize : 15,
              pageSizeOptions: pageSizeOptions
                ? pageSizeOptions
                : ['15', '30', '45', '60'],
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} de ${total} itens`,
              current: currentPage,
              onChange: thisHandleChangePage,
              onShowSizeChange: thisHandleChangeRowsPerPage,
              defaultPageSize: defaultPageSize ? defaultPageSize : 15
            } : false
        }
      />
    </ConfigProvider>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  total: PropTypes.number,
  loading: PropTypes.bool,
  showHeader: PropTypes.bool,
  title: PropTypes.func,
  pageSizeOptions: PropTypes.array,
  emptyText: PropTypes.string.isRequired,
  emptyIcon: PropTypes.string,
  defaultPageSize: PropTypes.number,
  rowKey: PropTypes.any,
  size: PropTypes.oneOf(['small', 'default', 'middle']),
  scroll: PropTypes.object,
  hasTopPagination: PropTypes.bool,
  autoScrollTop: PropTypes.bool,
};

Table.defaultProps = {
  showHeader: true,
  emptyText: 'Sem dados',
  emptyIcon: 'table',
  rowKey: '_id',
  hasTopPagination: false,
  autoScrollTop: true,
}

export default Table;
