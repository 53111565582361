import React from 'react';
import CurrencyComponent from '../../../components/currency/CurrencyComponent';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import {
  OptionSelection,
  OptionContent,
  OptionContentExtra
} from '../../ChoicesPage/SectionStyles';

const UpgradeCardComponent = ({upgrade, input}) => {
  const selected = input?.value && input.value === upgrade._id;
  let disabled = false;

  if(input?.value && input.value !== upgrade._id) {
    disabled = true;
  }

  const toggleOption = id => {
    if(!disabled) {
      let newValue = '';
      if(!input.value) {
        newValue = id;
      } 

      input.onChange(newValue);
    }
  };

  return (
    <OptionSelection
      selected={selected}
      disabled={disabled}
      onClick={() => toggleOption(upgrade?._id)}
      style={{display: 'flex', margin: '5px'}}
    >
      <OptionContent>{TranslateValue(upgrade?.name)}</OptionContent>
      <OptionContentExtra last={true}>
        <CurrencyComponent value={upgrade?.price} />
        /pax
      </OptionContentExtra>
    </OptionSelection>
  );
};

export default UpgradeCardComponent;