import React from 'react';
import {FieldArray} from 'redux-form';
import {
  WeddingDetailTitle,
  OrderTable
} from '../OrderStyles';
import {
  OptionsContainer,
  OptionContent,
  OptionLine,
  OptionObs,
  OptionColumn,
  OptionQuantity,
  OptionType,
  EmptyResults
} from './Styles';
import TextInput from '../../../components/inputs/TextInput';
import OrderStaffList from './OrderStaffList';

const WeddingStaffDetail = ({ index, wedding }) => {
  return (
    <React.Fragment>
      <OrderTable first={true}>
        <WeddingDetailTitle>Funcionários extra</WeddingDetailTitle>
        {
          wedding?.extra_staff?.length > 0 ?
          <OptionsContainer>
            {
              wedding.extra_staff.map((option, index) => 
                <OptionContent key={index}>
                  <OptionLine>  
                    <OptionColumn maxWidth={500} noBorderTopRight={true} noBorderBottomRight={true}>
                      <TextInput
                        meta={{}}
                        input={{value: option.name}}
                        type='text'
                        disabled={true}
                        noLabel={true}
                      />
                    </OptionColumn>
                    <OptionQuantity>
                      <TextInput
                        meta={{}}
                        input={{value: option.quantity}}
                        type='text'
                        disabled={true}
                        noLabel={true}
                      />
                    </OptionQuantity>
                    <OptionType>
                      <TextInput
                        meta={{}}
                        input={{value: option.type}}
                        type='text'
                        disabled={true}
                        noLabel={true}
                      />
                    </OptionType>
                  </OptionLine>
                  {
                    option.observations &&
                    <OptionObs><span>Observações:</span>&nbsp;{option.observations}</OptionObs>
                  }
                </OptionContent>
              )
            }
          </OptionsContainer>
          :
          <EmptyResults>Não existem resultados a apresentar!</EmptyResults>
        }
      </OrderTable>
      <OrderTable>
        <WeddingDetailTitle>Timeline</WeddingDetailTitle>
        {
          wedding?.timeline?.length > 0 ?
          <OptionsContainer>
            {
              wedding.timeline.map((moment, index) => 
                <OptionLine key={index}>  
                  <OptionColumn maxWidth={350} noBorderTopRight={true} noBorderBottomRight={true}>
                    <TextInput
                      meta={{}}
                      input={{value: moment.name}}
                      type='text'
                      disabled={true}
                      noLabel={true}
                    />
                  </OptionColumn>
                  <OptionColumn maxWidth={100} noBorderTopLeft={true} noBorderBottomLeft={true}>
                    <TextInput
                      meta={{}}
                      input={{value: moment.time}}
                      type='text'
                      disabled={true}
                      noLabel={true}
                    />
                  </OptionColumn>
                </OptionLine>
              )
            }
          </OptionsContainer>
          :
          <EmptyResults>Não existem resultados a apresentar!</EmptyResults>
        }
      </OrderTable>
      <OrderTable>
        <WeddingDetailTitle>Funcionários</WeddingDetailTitle>
        {
          wedding?.staff_table?.length > 0 ?
          <FieldArray
            component={OrderStaffList}
            name={`weddings[${index}].staff_table`}
          />
          :
          <React.Fragment>
            <EmptyResults>Deverá inserir os momentos obrigatórios para o cálculo do Staff antes de poder introduzir valores!</EmptyResults>
            <EmptyResults>- Chegada à Quinta</EmptyResults>
            <EmptyResults>- Início dos Aperitivos</EmptyResults>
            <EmptyResults>- Entrada na Sala de Jantar</EmptyResults>
          </React.Fragment>
        }
      </OrderTable>
    </React.Fragment>
  );
};

export default WeddingStaffDetail;