import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import React from 'react';
import { LanguagesComponent, InputLabelDiv, InputField } from './InputStyles';

const getLabel = code => {
  if (code === 'en') return 'Inglês';
  if (code === 'pt') return 'Português';
  if (code === 'es') return 'Espanhol';
  if (code === 'fr') return 'Francês';
  return '';
};

const defaultObject = { pt: '', en: '', fr: '', es: '' };

const TagTranslateInput = ({
  label,
  name,
  input,
  placeholder,
  activeLanguage,
  languages,
  disabled,
  meta,
  errors,
  error
}) => {
  // const langs = Object.keys(input.value ? input.value : defaultObject);

  const langs = ['pt', 'en', 'fr', 'es'];
  const showError = meta.invalid && meta.submitFailed;

  const applyError = key => {
    const exist = meta.error && Object.keys(meta.error).find(x => x === key);
    return showError && exist;
  };

  const changeInput = (event, code) => {
    const result =
      input && input.value && input.value !== ''
        ? { ...input.value }
        : defaultObject;
    let value = event?.target?.value;
    value = value.replace(' ', '_');
    result[code] = value;
    input.onChange(result);
  };

  return (
    <LanguagesComponent>
      <InputLabelDiv>{label}</InputLabelDiv>
      {langs.map(code => (
        <InputField
          language={1}
          key={code}
          disabled={disabled}
          prefix={getLabel(code)}
          type="text"
          value={input.value[code]}
          onChange={e => changeInput(e, code)}
          placeholder={placeholder}
          error={applyError(code) && meta.submitFailed ? 1 : 0}
        />
      ))}
    </LanguagesComponent>
  );
};

TagTranslateInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string
};

export default withLocalize(TagTranslateInput);
