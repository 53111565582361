import React, { Fragment, useState } from 'react';
import { bindActionCreators } from 'redux';
import { initialize } from 'redux-form';
import { connect } from 'react-redux';
import SectionMenu from '../ChoicesPage/SectionMenu';
import SectionContent from '../ChoicesPage/SectionContent';
import {
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import { ChoicesPageContainer } from '../ChoicesPage/SectionStyles';
import { SaveSectionNotes } from '../../infra/requests/WeddingProcessRequests';
import { SaveNotes } from '../../redux/wedding/wedding.actions';
//import CommentsDrawer from '../../components/comments/CommentsDrawer';
import { BaseButton } from '../../components/buttons/BaseButton';
import NotesConfirmModal from '../../components/notesModal/NotesModal';
import { hasNotes } from '../../infra/services/notes/FindNotes';
import PrintedBar from '../dashboard/components/PrintedBar';
import CommentsDrawerFixed from '../../components/comments/CommentsDrawerFixed';
import { getPrintedConfirmation } from '../../infra/services/wedding/printedUtils';
import { Modal } from 'antd';

const {confirm} = Modal;

const DecorationPage = ({ wedding, dispatch, saveNotesOnReducer }) => {
  const [drawer, setDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openNotesConfirm, setNotesConfirm] = useState(false);

  const section = wedding.structure.find(s => s.tag === 'CEREMONY');

  const OpenNotes = () => {
    const notes = wedding.notes.find(note => note.section === 'CEREMONY');
    if (!drawer) {
      dispatch(initialize('wedding_notes_section_form', notes));
      setDrawer(true);
    }
  };

  const closeNotes = () => {
    const notes = wedding.notes.find(note => note.section === 'CEREMONY');
    dispatch(initialize('wedding_notes_section_form', notes));
    setDrawer(false);
    setNotesConfirm(false);
  };

  const checkBeforeCloseNotes = (values) => {
    const notes = wedding.notes.find(note => note.section === 'CEREMONY');

    //If theres any change to the notes, ask before closing if we want to save the changes
    if (values && (notes?.notes !== values.notes || notes?.private !== values.private)) {
      dispatch(initialize('notes_modal_confirm_form', values));
      setNotesConfirm(true);
    }
    //Otherwise, just the close the notes
    else {
      setDrawer(false);
    }
  }

  const saveNotes = async values => {

    if (wedding?.wedding?.printed) {
      confirm({
        ...getPrintedConfirmation(),
        onOk: () => { updateNotes(values); },
        onCancel: () => { closeNotes(); },
      });
    } else updateNotes(values);
  };

  const updateNotes = async values => {
    setLoading(true);
    const { data } = await SaveSectionNotes(wedding.wedding._id, {
      ...values,
      section: 'CEREMONY'
    });

    dispatch(initialize('wedding_notes_section_form', data));
    saveNotesOnReducer(data);
    setLoading(false);
    setDrawer(false);
    setNotesConfirm(false);
  };

  return (
    <Fragment>
      <HeaderContainer>
        <HeaderTitle buttons={1}>
          <PageTitle>Cerimónia</PageTitle>
        </HeaderTitle>
        <HeaderButtonsContainer buttons={1}>
          <BaseButton
            type={'default'}
            icon={'read'}
            text={'Notas'}
            onClick={OpenNotes}
            notes={hasNotes(wedding.notes, 'CEREMONY')}
          />
        </HeaderButtonsContainer>
      </HeaderContainer>
      {
        wedding?.wedding?.printed && <PrintedBar small={true} />
      }
      <ChoicesPageContainer printed={wedding?.wedding?.printed}>
        <SectionMenu section={section} printed={wedding?.wedding?.printed} />
        <SectionContent section={section} printed={wedding?.wedding?.printed} notesOpen={drawer} />
        <CommentsDrawerFixed
          open={drawer}
          loading={loading}
          onClose={checkBeforeCloseNotes}
          saveNotes={saveNotes}
          printed={wedding?.wedding?.printed}
        />
      </ChoicesPageContainer>
      {/* <CommentsDrawer
        title="Notas De Cerimónia"
        open={drawer}
        loading={loading}
        onClose={checkBeforeCloseNotes}
        saveNotes={saveNotes}
      /> */}
      <NotesConfirmModal
        open={openNotesConfirm}
        loading={loading}
        onSubmit={saveNotes}
        closeModal={() => { setNotesConfirm(false) }}
        exitWithoutSaving={closeNotes}
      />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  wedding: state.wedding
});

const mapActionToProps = dispatch =>
  bindActionCreators({ saveNotesOnReducer: SaveNotes, dispatch }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(DecorationPage);
