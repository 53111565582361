import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, Modal } from 'antd';
import Dropzone from 'react-dropzone';
import { DocumentSection, FileDropSection } from './Styles';
import FileOption from '../../ChoicesPage/components/FileOption';
import { AsyncForEach } from './../../../infra/services/async/AsyncFunctions';
import {
  SaveDocument,
  RemoveDocument
} from './../../../infra/requests/WeddingRequests';
import {
  addDocument,
  deleteDocument
} from '../../../redux/wedding/wedding.actions';
import { constrainPoint } from '@fullcalendar/common';
import { getFilePrintedConfirmation } from '../../../infra/services/wedding/printedUtils';

const { confirm } = Modal;

const WeddingDocuments = ({ wedding, addDocument, deleteDocument }) => {
  const [loading, setLoading] = useState(false);

  const handleDrop = async (accepted) => {
    if (wedding?.printed) {
      confirm({
        ...getFilePrintedConfirmation(),
        onOk: () => { saveOnDrop(accepted); },
        onCancel: () => { },
      });
    } else saveOnDrop(accepted);
  }

  const saveOnDrop = async (accepted) => {
    setLoading(true);
    await AsyncForEach(accepted, async file => {
      const payload = new FormData();
      payload.append('file', file);
      const { data, success } = await SaveDocument(wedding._id, payload);
      if (success) addDocument(data);
    });
    setLoading(false);
  };

  const handleDelete = async file => {
    setLoading(true);
    const { success } = await RemoveDocument(wedding._id, file._id);
    if (success) deleteDocument(file._id);
    setLoading(false);
  };

  return (
    <Fragment>
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <FileDropSection {...getRootProps()}>
            <input {...getInputProps()} />
            <Icon type={loading ? 'loading' : 'plus'} />
            <div>
              {loading
                ? 'loading...'
                : 'Click ou arraste os ficheiros para aqui'}
            </div>
          </FileDropSection>
        )}
      </Dropzone>
      {wedding.documents.map(document => (
        <DocumentSection key={document._id}>
          <FileOption file={document} handleDelete={handleDelete} fileType='document' wedding={wedding} printed={wedding.printed} />
        </DocumentSection>
      ))}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  wedding: state.wedding.wedding
});

const mapActionToProps = dispatch =>
  bindActionCreators({ addDocument, deleteDocument, dispatch }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(WeddingDocuments);
