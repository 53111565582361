import React, { Component, Fragment } from 'react';
import { Checkbox, Icon, Popconfirm, Tag } from 'antd';
import Table from '../../components/table/Table';
import { PageContainer, PageTitle, HeaderContainer, HeaderTitle, HeaderButtonsContainer, TableButton } from '../../styles/BasicStyles';
import { GetWeddings, TogglePublic, DeleteWedding, GetWeddingsByUser, GetAllWeddingsByUser } from '../../infra/requests/WeddingRequests';
import { ArchiveWedding } from '../../infra/requests/ArchivedRequests';
import BaseButton from '../../components/buttons/BaseButton';
import moment from 'moment';
import CurrencyComponent from '../../components/currency/CurrencyComponent';
import { LocalLabel, StateLabel } from '../weddings/Styles';
import { connect } from 'react-redux';
import { ClearWedding } from '../../redux/wedding/wedding.actions';
import { bindActionCreators } from 'redux';
import { GetTeamBookingByWedding } from '../../infra/requests/TeamBookingRequests';
import { getLocal, getLocalColor, getScheduler, getWeddingName, getWeddingTeamAndEmployeesInCharge } from '../../infra/services/wedding/weddingUtils';
import { getUserId, isCurrentUserChief, isCurrentUserEmployee, isCurrentUserHumanResources, isEmployeeOrChief } from '../../infra/helpers/Helpers';
import EmployeeWeddingsFilters from '../weddings/EmployeeWeddingsFilters';
import { GetOurDayByWedding } from '../../infra/requests/OurDayRequests';
import { GetColorSystemByType } from '../../infra/requests/ColorSystemRequests';
import { ConfirmWeddingTeamByemployee, GetWeddingTeamByWedding } from '../../infra/requests/WeddingTeamRequests';
import { GetSubcategories, GetSubcategoriesByType } from '../../infra/requests/SubcategoryRequests';
import { GetEmployeeByUser } from '../../infra/requests/EmployeeRequests';
import { GetTextColor } from '../../infra/services/text/textUtils';

class EmployeeProfileWeddings extends Component {
    state = {
        pageSize: 30,
        currentPage: 1,
        loading: false,
        filters: '',
        employee: null,
        columns: [
            {
                title: 'Data',
                render: value => moment(value?.wedding?.date).format('DD-MM-YYYY')
            },
            {
                title: 'Local',
                render: value => {
                    const local = getLocalColor(value?.wedding, this.state.colors);
                    return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
                }
            },
            {
                title: 'Horário Chegada à Quinta',
                render: data => data.scheduler.arrivingTime
            },
            {
                title: 'Horário Aperitivos',
                render: data => data.scheduler.snackTime
            },
            {
                title: 'Horário Jantar',
                render: data => data.scheduler.dinnerTime
            },
            {
                title: 'Interesse Manifestado',
                render: data => {
                    const weddingInterest = data.interest && data.interest.length > 0 ? true : false;
                    return <Tag color={weddingInterest ? 'green' : 'red'}>{weddingInterest ? 'Sim' : 'Não'}</Tag>
                }
            },
            {
                title: 'Selecionado para',
                render: data => {
                    const teamPublished = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.published).length > 0 ? true : false;
                    const weddingTeam = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.employee.id === this.state.employee.id).length > 0 ? data.teamComplete.filter(f => f.employee.id === this.state.employee.id) : null;
                    // console.warn('teamPublished', teamPublished);
                    // console.warn('weddingTeam', weddingTeam);
                    return teamPublished && weddingTeam ? weddingTeam.map(m => {
                        return <div>{m.subcategory.name}</div>
                    }) : null;
                }
            },
            {
                title: 'Horário de Entrada',
                render: data => {
                    const teamPublished = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.published).length > 0 ? true : false;
                    const weddingTeam = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.employee.id === this.state.employee.id).length > 0 ? data.teamComplete.filter(f => f.employee.id === this.state.employee.id) : null;
                    // console.warn('teamPublished', teamPublished);
                    // console.warn('weddingTeam', weddingTeam);
                    return teamPublished && weddingTeam ? weddingTeam.map(m => {
                        return <div>{moment(m.startTime).format('HH:mm')}</div>
                    }) : null;
                }
            },
            {
                title: 'Confirmar',
                render: data => {
                    // to test only
                    // data.allowToConfirm = true;
                    // const weddingDate = new Date(data.wedding.date);
                    // const now = new Date(weddingDate.toDateString() + ' 00:00:00');
                    // now.setDate(now.getDate() - 1);
                    // const startWeddingDate = new Date(weddingDate.toDateString() + ' 00:00:00');
                    // console.warn('Now', now);
                    // console.warn('startWeddingDate', startWeddingDate);
                    // data.allowToConfirm = moment(now).isBefore(startWeddingDate);
                    const teamPublished = data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(f => f.published).length > 0 ? true : false;

                    return teamPublished && data.empWeddingTeam && data.empWeddingTeam.length > 0 ? (
                        <div className="sc-dxgOiQ fWHHkk">
                            <Checkbox
                                checked={data.confirmed}
                                onChange={(e) => this.confirmWeddingTeam(e, data)}>
                                Sim
                            </Checkbox>
                        </div>
                    ) : null;
                }
            },
        ],
        rows: [],
        rowsSearch: null,
        total: 0,
        colors: {
            colorSys1: null,
            colorSys2: null
        },
        searchMonth: moment(),
    };

    componentDidMount = async () => {
        this.setState({ employee: this.props.employee }, () => this.getLocalColors());
    };

    getLocalColors = async () => {
        const resColorSys1 = await GetColorSystemByType(4);
        const colorSys1 = resColorSys1.success && resColorSys1.data ? resColorSys1.data.color : '#f7b5d6';
        const resColorSys2 = await GetColorSystemByType(5);
        const colorSys2 = resColorSys2.success && resColorSys2.data ? resColorSys2.data.color : '#b6d7a9';
        this.setState({ colors: { colorSys1, colorSys2 } }, () => {
            this.updateDataTable();
        });
    }

    updateDataTable = async () => {
        const { searchMonth } = this.state;
        this.setState({ loading: true });
        try {
            const month = moment(searchMonth, ['MM/YYYY']).format('YYYY-MM-DD') + 'T00:00:00.000Z';
            let filter = { calendar: true, month: month, admin: true };
            const result = await GetAllWeddingsByUser(this.props.employee.user._id, JSON.stringify(filter));
            // console.warn('Result', result);
            //const weddings = [];

            for (let index = 0; index < result.data?.length; index++) {
                result.data[index].scheduler = getScheduler(result.data[index].ourDay);
                result.data[index]['empWeddingTeam'] = result.data[index].teamComplete.length > 0 ? result.data[index].teamComplete.filter(f => f.employee.id === this.state.employee.id) : [];
                result.data[index]['confirmed'] = result.data[index].empWeddingTeam.length > 0 && result.data[index].empWeddingTeam.filter(f => f.confirmed).length > 0 ? true : false;

                //const wedding = result.data[index].wedding;
                //const resOurDay = await GetOurDayByWedding(wedding.id);
                // weddings.push({wedding, scheduler: getScheduler(result.data[index].ourDay)});
            }

            this.setState({
                rows: result.data,
                total: result.data ? result.data.length : 0,
                loading: false
            });
        } catch (e) {
            this.setState({ loading: false });
            console.error(e);
        }
    };

    confirmWeddingTeam = async (event, data) => {
        // console.warn('event', event.target.checked);
        // console.warn('Data', data);
        this.setState({ loading: true });

        data.empWeddingTeam.forEach(weddingTeam => {
            weddingTeam.confirmed = event.target.checked;
        });
        // console.warn('empWeddingTeam', data.empWeddingTeam);

        const result = await ConfirmWeddingTeamByemployee(data.empWeddingTeam);
        // console.warn('Result', result);
        if (!result.success) {
            console.error(result.errors);
            this.setState({ loading: false });
        } else {
            this.updateDataTable();
        }
    }

    // handleChangePage = currentPage => {
    //     this.setState({ currentPage }, this.updateDataTable);
    // };

    // handleChangeRowsPerPage = (currentSize, pageSize) => {
    //     this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
    // };

    handleFilterChange = filters => {
        //this.setState({ filters, currentPage: 1 }, this.updateDataTable);
        const filtersObj = JSON.parse(filters);
        const { rows } = this.state;
        let { rowsSearch } = this.state;

        if (filtersObj.wedding_place === undefined && filtersObj.month !== undefined) {
            this.setState({ rowsSearch: null })
            this.setState({ searchMonth: filtersObj.month }, () => { this.updateDataTable() });
        }

        if (filtersObj.wedding_place !== undefined) {
            rowsSearch = rows.filter(f => f.wedding.wedding_place == filtersObj.wedding_place);
            this.setState({ rowsSearch, total: rowsSearch.length });
        }
    };

    render() {
        const { history } = this.props;
        const { loading, currentPage, pageSize, subcategories } = this.state;
        const { columns, rows, rowsSearch, total } = this.state;
        return (
            <React.Fragment>
                <EmployeeWeddingsFilters queryChange={this.handleFilterChange} />
                <Table
                    columns={columns}
                    // currentPage={currentPage}
                    // pageSize={pageSize}
                    // defaultPageSize={pageSize}
                    loading={loading}
                    rows={rowsSearch ? rowsSearch : rows}
                    showHeader={true}
                    emptyIcon="calendar"
                    emptyText={'There are no weddings'}
                    total={total}
                    rowKey={'_id'}
                    hasPagination={false}
                // onPressRow={this.openProcess}
                // handleChangePage={this.handleChangePage}
                // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({ wedding: state.wedding });

const mapActionToProps = dispatch => bindActionCreators({ ClearWedding }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(EmployeeProfileWeddings);