import moment from 'moment';

const findPageByTag = (info, tag) => {
  const key = Object.keys(info).filter(key =>
    info[key].tag === tag ? key : null
  )[0];

  return info[key];
};

const findChapterByID = (chapters, id) => {
  let res;

  for (let i = 0; i < chapters.length; i++) {
    if (chapters[i]._id === id) {
      res = chapters[i];
      break;
    }
  }

  return res;
};

const findQuestionByID = (chapters, id) => {
  let res;

  for (let i = 0; i < chapters.length; i++) {
    if (chapters[i].questions.length > 0) {
      for (let j = 0; j < chapters[i].questions.length; j++) {
        if (chapters[i].questions[j]._id === id) {
          res = chapters[i].questions[j];
          break;
        }
      }
    }
  }

  return res;
};

//Used for "Our Day" page in the Timeline, not every answer should appear there
const findAnswerByID = (chapters, food, colors, id) => {
  let res;

  for (let i = 0; i < chapters.length; i++) {
    if (chapters[i].questions.length > 0) {
      for (let j = 0; j < chapters[i].questions.length; j++) {
        if (
          chapters[i].questions[j].type === 4 ||
          chapters[i].questions[j].type === 5 ||
          chapters[i].questions[j].type === 6
        ) {
          if (chapters[i].questions[j].options.length > 0) {
            for (let h = 0; h < chapters[i].questions[j].options.length; h++) {
              if (chapters[i].questions[j].options[h]._id === id) {
                res = chapters[i].questions[j].options[h];
                break;
              }
            }
          }
        } else if (chapters[i].questions[j].type === 11) {
          for (let h = 0; h < colors.length; h++) {
            if (colors[h]._id === id) {
              res = colors[h];
              break;
            }
          }
        } else if (chapters[i].questions[j].type === 12) {
          for (let h = 0; h < food.entrees.length; h++) {
            if (food.entrees[h]._id === id) {
              res = food.entrees[h];
              break;
            }
          }
        } else if (chapters[i].questions[j].type === 13) {
          for (let h = 0; h < food.meat.length; h++) {
            if (food.meat[h]._id === id) {
              res = food.meat[h];
              break;
            }
          }
        } else if (chapters[i].questions[j].type === 14) {
          for (let h = 0; h < food.fish.length; h++) {
            if (food.fish[h]._id === id) {
              res = food.fish[h];
              break;
            }
          }
        } else if (chapters[i].questions[j].type === 15) {
          for (let h = 0; h < food.dessert.length; h++) {
            if (food.dessert[h]._id === id) {
              res = food.dessert[h];
              break;
            }
          }
        } else if (chapters[i].questions[j].type === 16) {
          for (let h = 0; h < food.cake.length; h++) {
            if (food.cake[h]._id === id) {
              res = food.cake[h];
              break;
            }
          }
        } else if (chapters[i].questions[j].type === 17) {
          for (let h = 0; h < food.supper.length; h++) {
            if (food.supper[h]._id === id) {
              res = food.supper[h];
              break;
            }
          }
        }
      }
    }
  }

  return res;
};

export const findMomentByID = (list, id) => {
  let res = undefined;

  for (let i = 0; i < list.length; i++) {
    if (list[i]._id === id) {
      res = list[i];
      break;
    }
  }

  return res;
};

//Slit the array of moments into 2 using a defined Hour
export const splitTimeline = (list, startHour) => {
  const tempArray1 = [];
  const tempArray2 = [];
  let res = [];

  if (list.length > 0) {
    for (let i = 0; i < list.length; i++) {
      if (
        moment(list[i].time, 'HH:mm').utc(true) >=
        moment(startHour, 'HH:mm').utc(true)
      ) {
        tempArray1.push(list[i]);
      } else {
        tempArray2.push(list[i]);
      }
    }

    //Order the 2 arrays
    tempArray1.sort((a, b) =>
      moment(a.time, 'HH:mm').utc(true) > moment(b.time, 'HH:mm').utc(true)
        ? 1
        : moment(a.time, 'HH:mm').utc(true) < moment(b.time, 'HH:mm').utc(true)
        ? -1
        : 0
    );
    tempArray2.sort((a, b) =>
      moment(a.time, 'HH:mm').utc(true) > moment(b.time, 'HH:mm').utc(true)
        ? 1
        : moment(a.time, 'HH:mm').utc(true) < moment(b.time, 'HH:mm').utc(true)
        ? -1
        : 0
    );

    //Build the final array
    res = tempArray1.concat(tempArray2);
  }

  return res;
};

const findAnswers = (code, chapters, food, colors) => {
  let res = [], answer = undefined;

  if(code.type === 'question') {
    const question = findQuestionByID(chapters, code.id);

    if(question && question.answer?.response && !question.answer?.response.filename) {
      if(Array.isArray(question.answer.response)) {
        for(let i = 0; i < question.answer.response.length; i++) {
          if(question.answer.response[i].id) {
            answer = findAnswerByID(chapters, food, colors, question.answer.response[i].id);

            if(answer) {
              res.push(answer);
            }
          }
          else {
            answer = findAnswerByID(chapters, food, colors, question.answer.response[0]);

            if(answer) {
              res.push(answer);
            }
          }
        }
      }
      else {
        res.push(question.answer.response);
      }
    }
  }
  else if(code.type === 'chapter') {
    const chapter = findChapterByID(chapters, code.id);
    let question = undefined;

    if(chapter && chapter.questions.length > 0) {
      for(let i = 0; i < chapter.questions.length; i++) {
        question = findQuestionByID(chapters, chapter.questions[i]._id);

        if(question && question.answer?.response && !question.answer?.response.filename) {
          if(Array.isArray(question.answer.response)) {
            for(let j = 0; j < question.answer.response.length; j++) {
              if(question.answer.response[j].id) {
                answer = findAnswerByID(chapters, food, colors, question.answer.response[j].id);

                if(answer) {
                  res.push(answer);
                }
              }
              else {
                answer = findAnswerByID(chapters, food, colors, question.answer.response[0]);

                if(answer) {
                  res.push(answer);
                }
              }
            }
          }
          else {
            res.push(question.answer.response);
          }
        }
      }
    }
  }

  return res;
}

//Find the answers for a specific Moment
export const findMomentAnswers = (code, info, food, colors) => {
  let chapters = [];

  switch(code.section) {
    case 'ceremony':
      ({ chapters } = findPageByTag(info, 'CEREMONY'));

      return findAnswers(code, chapters, food, colors);
    case 'food':
      ({ chapters } = findPageByTag(info, 'FOOD'));

      return findAnswers(code, chapters, food, colors);
    case 'drinks':
      ({ chapters } = findPageByTag(info, 'DRINKS'));

      return findAnswers(code, chapters, food, colors);
    case 'decoration':
      ({ chapters } = findPageByTag(info, 'DECORATION'));

      return findAnswers(code, chapters, food, colors);
    case 'party':
      ({ chapters } = findPageByTag(info, 'PARTY'));

      return findAnswers(code, chapters, food, colors);
    default:
      return [];
  }
}
