import client from '../config/AxiosConfig';

export const CreatePaymentPhaseRule = async (data) =>
  client.put(`/paymentPhaseRules/create`, data);

export const GetPaymentPhaseRule = async (id) =>
  client.get(`/paymentPhaseRules/find/${id}`);

export const GetAllPaymentPhaseRule = async () =>
  client.get(`/paymentPhaseRules/findAll`);

export const GetChildPaymentPhaseRule = async () =>
  client.get(`/paymentPhaseRules/findAllChild`);

export const GetFatherPaymentPhaseRule = async () =>
  client.get(`/paymentPhaseRules/findAllFather`);

export const UpdatePaymentPhaseRule = async (data) =>
  client.post(`/paymentPhaseRules/update`, data);

export const SoftDeletePaymentPhaseRule = async id =>
  client.delete(`/paymentPhaseRules/softDelete/${id}/true`);