import React, { Component, Fragment } from "react";
import { reduxForm, Field, initialize, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import BaseButton from "../../components/buttons/BaseButton";
import {
  FormContainer,
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  BaseForm,
  HeaderButtonsContainer,
  SpinLoading,
  DefaultLanguageTab
} from "../../styles/BasicStyles";
import {
  GetDynamicPage,
  UpdateDynamicPage,
  CreateDynamicPage,
  AddDynamicPageFileLink,
  RemoveDynamicPageFileLink
} from "../../infra/requests/BaseRequests";
import { Tabs, Row, Col, notification, Modal } from "antd";
import { withLocalize } from "react-localize-redux";
import { GetTranslationDescription } from "../../infra/services/translations/AvailableTranslations";
import FormValidator from "../../infra/services/validations/FormValidator";
import DraftInput from "../../components/inputs/DraftInput";
import { InputDiv, InputField, InputLabelDiv, TextAreaInputField } from "../../components/inputs/InputStyles";
import TextInput from "../../components/inputs/TextInput";
import FileInput from "../../weddingday/informations/FileInput";
import TinyInput from "../../components/inputs/TinyInput";
import { Editor } from '@tinymce/tinymce-react';
import { findPageByTag } from "../../infra/services/sections/Sections";
import { findAnsweredQuestions, findFoodAnswers, findSpecificQuestion } from "../../infra/services/questions/AnsweredQuestions";
import moment from "moment";
import { GetGraphicMenu, UpdateGraphicMenu } from "../../infra/requests/WeddingProcessRequests";
import { SaveGraphicMenu } from "../../redux/wedding/wedding.actions";
import { getMenuPrintedConfirmation } from "../../infra/services/wedding/printedUtils";
import { DecorContainer, PageContainer, Observation, NotesContainer } from "./DecorationStyles";
import { bindActionCreators } from "redux";
import { Answer, AnswerContainer, DecorationModalTitle, Note, NoteContent, NoteTitle, RealAnswer } from "../dashboard/ProcessStyles";
import TitleInput from "../../components/inputs/TitleInput";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import { TransformToFormData } from "../../infra/services/formdata/TransformToFormData";
import PrintedBar from '../dashboard/components/PrintedBar';
const TabPane = Tabs.TabPane;
const { confirm } = Modal;

const validations = FormValidator.make({
  menu: "required|languages"
});

class ManageMenuPage extends Component {
  state = {
    loading: false,
    ready: false,
    saving: false,
    menu: {
      pt: '',
      en: '',
      fr: '',
      es: ''
    },
    decoration: [],
    sidedishes: [],
    food: [],
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;
    const { menuInfo, structureInfo, foodInfo, colorsInfo, weddingInfo } = this.props;

    const resMenu = await GetGraphicMenu(params.wedding);
    const menuRef = resMenu?.success && resMenu?.data ? resMenu?.data : null;

    let section = findPageByTag(structureInfo, 'DECORATION');
    const decoration = findAnsweredQuestions(
      section,
      foodInfo,
      colorsInfo,
      weddingInfo.wedding_place
    );

    section = findPageByTag(structureInfo, 'FOOD');
    const sidedishes = foodInfo?.sidedishes;
    const food = findFoodAnswers(section, foodInfo, sidedishes);

    this.setState({
      decoration,
      sidedishes,
      food
    }, () => {
      const menu = menuRef && menuRef?.menu
        ? menuRef.menu
        : menuRef && menuRef?.text
          ? {
            pt: menuRef?.text,
            en: '',
            fr: '',
            es: ''
          }
          : this.buildDefaultMenu();
      dispatch(initialize("menu_form", { menu }));
      this.setState({
        loading: false,
        ready: true,
        menu
      });
    });
  };

  buildDefaultMenu = () => {
    let { menu } = this.state;
    const { weddingInfo } = this.props;
    const { decoration, sidedishes, food } = this.state;
    menu = {
      pt: '',
      en: '',
      fr: '',
      es: ''
    };

    //Check if the users have field the question "Nome para Ementas" (5e1cd8f0d0091dfd7f399c20)
    if (decoration && decoration.length > 0) {
      for (let i = 0; i < decoration.length; i++) {
        if (
          decoration[i]._id === '5e1cd8f0d0091dfd7f399c20' &&
          decoration[i].answer &&
          decoration[i].answer.response
        ) {
          // menu = decoration[i].answer.response;
          for (const [key, value] of Object.entries(menu)) {
            menu[key] = decoration[i].answer.response;
          }
          break;
        }
      }
    }

    //Default, use the name of the bride and groom
    if (menu?.pt === '' && menu?.en === '' && menu?.fr === '' && menu?.es === '') {
      if (weddingInfo.bride && weddingInfo.bride.name) {
        // menu = `${weddingInfo.bride.name}`;
        for (const [key, value] of Object.entries(menu)) {
          menu[key] = `${weddingInfo.bride.name}`;
        }
      }
      if (weddingInfo.groom && weddingInfo.groom.name) {
        // menu = `${menu}\n${weddingInfo.groom.name}`;
        for (const [key, value] of Object.entries(menu)) {
          menu[key] = `${value} & ${weddingInfo.groom.name}`;
        }
      }
    }

    if (weddingInfo.date) {
      // menu = `${menu}\n${moment.utc(weddingInfo.date).locale('pt').format('LL')}`;
      for (const [key, value] of Object.entries(menu)) {
        menu[key] = `${value}\n${moment.utc(weddingInfo.date).locale(key).format('LL')}`;
      }
    }

    // menu = `${menu}\nMENU`;
    for (const [key, value] of Object.entries(menu)) {
      const menuTxt = key === 'es'
        ? 'MENÚ'
        : 'MENU';
      menu[key] = `${value}\n${menuTxt}`;
    }

    if (food.length > 0) {
      for (let i = 0; i < food.length; i++) {
        // menu = `${menu}\n${food[i].label}`;
        for (const [key, value] of Object.entries(menu)) {
          menu[key] = `${value}\n${food[i].title[key]}`;
        }

        // if (food[i].answer) {
        if (food[i]?.description) {
          // menu = `${menu}: ${food[i].answer}`;
          for (const [key, value] of Object.entries(menu)) {
            menu[key] = `${value}: ${food[i].description[key] || ''}`;
          }
        }
      }
    }

    return menu;
  };

  onSubmit = async (values) => {
    const { weddingInfo } = this.props;

    if (weddingInfo?.printed) {
      confirm({
        ...getMenuPrintedConfirmation(),
        onOk: () => this.saveMenu(values),
        onCancel: () => this.handleOnCancel(),
      });
    } else this.saveMenu(values);
  };

  saveMenu = async values => {
    try {
      this.setState({ saving: true });
      const {
        match: { params },
        history
      } = this.props;

      const { success, data } = await UpdateGraphicMenu(params.wedding, values);
      if (success) {
        notification.success({
          message: 'Gravado com sucesso',
          description: 'A informação foi gravada com sucesso!'
        });

        SaveGraphicMenu(data.menu);
        return history.push(`/wedding/${params.wedding}`);
      } else this.setState({ saving: false });
    } catch (e) {
      console.error(e);
      this.setState({ saving: false });
    }
  };

  handleOnCancel = async () => {
    try {
      const {
        match: { params },
        history
      } = this.props;
      return history.push(`/wedding/${params.wedding}`);
    } catch (e) {
      console.error(e);
    }
  };

  loadDefaultMenu = () => {
    const { dispatch } = this.props;
    this.setState({ loading: true });

    const menu = this.buildDefaultMenu();
    dispatch(initialize("menu_form", { menu }));
    this.setState({
      loading: false,
      menu
    });
  }

  render() {
    const { handleSubmit, activeLanguage, languages } = this.props;
    const { wedding, notes } = this.props;
    const { food } = this.state;
    const { loading, ready, saving } = this.state;
    const foodNotes = notes.find(note => note.section === 'FOOD');
    const decorationNotes = notes.find(note => note.section === 'DECORATION');
    const foodQuestions = food.filter(elem => (elem?.showQuestion ? elem : null));
    const getLabel = code => {
      if (code === 'en') return 'Inglês';
      if (code === 'pt') return 'Português';
      if (code === 'es') return 'Espanhol';
      if (code === 'fr') return 'Francês';
      return '';
    };

    if (!ready) return <SpinLoading />;

    return (
      <Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Menu</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="default"
              icon="redo"
              text="Ver Menu Original"
              disabled={loading | saving}
              onClick={() => this.loadDefaultMenu()}
            />
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              loading={saving}
              disabled={loading}
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        {
          wedding?.wedding?.printed && <PrintedBar small={true} />
        }
        <DecorContainer printed={wedding?.wedding?.printed}>
          <PageContainer buttons={2} printed={wedding?.wedding?.printed}>
            {loading
              ? <SpinLoading />
              : <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
                <Row gutter={12}>
                  <Col xs={12}>
                    <DefaultLanguageTab>
                      {GetTranslationDescription(activeLanguage.code)}
                    </DefaultLanguageTab>
                    <Field
                      // component={TinyInput}
                      component={TextAreaInput}
                      // minRows={10}
                      name={`menu.${activeLanguage.code}`}
                      noLabel={true}
                    // label={"Conteúdo"}
                    // toolbarOnFocus={false}
                    // toolbar='notes'
                    />
                  </Col>
                  <Col xs={12}>
                    <Tabs>
                      {languages
                        .filter(x => !x.active)
                        .map((language, index) => (
                          <TabPane
                            tab={GetTranslationDescription(language.code)}
                            key={language.code}
                          >
                            <Field
                              // component={TinyInput}
                              component={TextAreaInput}
                              // minRows={10}
                              name={`menu.${language.code}`}
                              noLabel={true}
                              // label={"Conteúdo"}
                              // toolbarOnFocus={false}
                              // toolbar='notes'
                              id={index}
                            />
                          </TabPane>
                        ))}
                    </Tabs>
                  </Col>
                  <Col xs={24} style={{ paddingTop: 20 }}>
                    {foodQuestions?.length > 0 &&
                      <Fragment>
                        <DecorationModalTitle>Perguntas:</DecorationModalTitle>
                        <Tabs>
                          {foodQuestions.map((elem, index) => (
                            <TabPane tab={elem?.title && elem?.title[activeLanguage.code] ? elem?.title[activeLanguage.code] : ''} key={index}>
                              <AnswerContainer>
                                {languages.map(language => (
                                  <InputField
                                    language={1}
                                    key={language.code}
                                    disabled={true}
                                    prefix={getLabel(language.code)}
                                    type="text"
                                    preview={true}
                                    value={`${elem?.description && elem?.description[language.code] ? elem?.description[language.code] : ''} ${elem?.realAnswer || ''}`}
                                  />
                                ))}
                                {/* {elem?.description &&
                                <Answer>
                                  {elem.description[activeLanguage.code]}
                                  {elem.realAnswer &&
                                    <Fragment>: <RealAnswer>{elem.realAnswer}</RealAnswer>
                                    </Fragment>}
                                </Answer>} */}
                                <InputDiv>
                                  <InputLabelDiv>Observações</InputLabelDiv>
                                  <TextAreaInputField
                                    disabled={true}
                                    preview={true}
                                    value={elem?.observations || 'Sem observações'}
                                    autoSize={{
                                      minRows: 3,
                                      maxRows: undefined
                                    }}
                                  />
                                </InputDiv>
                              </AnswerContainer>
                            </TabPane>
                          ))}
                        </Tabs>
                      </Fragment>}
                    {foodNotes && (foodNotes?.notes || foodNotes?.private) && (
                      <NotesContainer>
                        {foodNotes.notes && (
                          <NoteContent>
                            <NoteTitle>Notas Públicas (Comida):</NoteTitle>
                            <Note
                              dangerouslySetInnerHTML={{
                                __html: foodNotes.notes
                              }}
                            />
                          </NoteContent>
                        )}
                        {foodNotes.private && (
                          <NoteContent>
                            <NoteTitle>Notas Privadas (Comida):</NoteTitle>
                            <Note
                              dangerouslySetInnerHTML={{
                                __html: foodNotes.private
                              }}
                            />
                          </NoteContent>
                        )}
                      </NotesContainer>
                    )}
                    {decorationNotes &&
                      (decorationNotes.notes || decorationNotes.private) && (
                        <NotesContainer>
                          {decorationNotes.notes && (
                            <NoteContent>
                              <NoteTitle>Notas Públicas (Decoração):</NoteTitle>
                              <Note
                                dangerouslySetInnerHTML={{
                                  __html: decorationNotes.notes
                                }}
                              />
                            </NoteContent>
                          )}
                          {decorationNotes.private && (
                            <NoteContent>
                              <NoteTitle>Notas Privadas (Decoração):</NoteTitle>
                              <Note
                                dangerouslySetInnerHTML={{
                                  __html: decorationNotes.private
                                }}
                              />
                            </NoteContent>
                          )}
                        </NotesContainer>
                      )}
                  </Col>
                </Row>
              </BaseForm>
            }
          </PageContainer>
        </DecorContainer>
      </Fragment>
    );
  }
}

const selector = formValueSelector('menu_form');

ManageMenuPage = reduxForm({
  form: "menu_form",
  validate: validations
})(ManageMenuPage);

const mapStateToProps = state => ({
  wedding: state.wedding,
  structureInfo: state.wedding.structure,
  weddingInfo: state.wedding.wedding,
  foodInfo: state.info.food,
  colorsInfo: state.info.colours,
  menuInfo: state.wedding.menu,
  notes: state.wedding.notes,
  menuForm: {
    menu: selector(state, 'menu'),
  }
});

const mapActionToProps = dispatch =>
  bindActionCreators({ SaveGraphicMenu: SaveGraphicMenu, dispatch }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageMenuPage));
