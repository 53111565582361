import AdminsPage from '../../pages/admins/AdminsPage';
import ManageAdminPage from '../../pages/admins/ManageAdminPage';
import InspirationsPage from '../../weddingday/inspirations/InspirationsPages';
import ManageInspirationPage from '../../weddingday/inspirations/ManageInspirationPage';
import InformationPages from '../../weddingday/informations/InformationPages';
import ManageInformationPage from '../../weddingday/informations/ManageInformationPage';
import WeddingsPage from '../../weddingday/weddings/WeddingsPage';
import EmployeeWeddingsPage from '../../weddingday/weddings/EmployeeWeddingsPage';
import WeddingsCalendar from '../../weddingday/weddings/Calendar';
import RestDays from '../../weddingday/weddings/RestDays';
import UsersPage from '../../weddingday/users/UsersPage';
import CreateWeddingPage from '../../weddingday/weddings/CreateWeddingPage';
import ColoursPage from '../../weddingday/colours/ColoursPage';
import DynamicPages from '../../weddingday/dynamicPages/DynamicPages';
import TranslationPage from '../../weddingday/translations/TranslationsPage';
import SettingsPage from '../../weddingday/settings/SettingsPage';
import CategoryPage from '../../weddingday/settings/CategoryPage';
import ManageDynamicPages from '../../weddingday/dynamicPages/ManageDynamicPages';
import ManageUpgradePage from '../../weddingday/upgrades/ManageUpgradePage';
import NewsPage from '../../weddingday/news/NewsPage';
import ManageNewsPage from '../../weddingday/news/ManageNewsPage';
import ArchivedPage from '../../weddingday/archived/ArchivedPage';
import ArchivedDetails from '../../weddingday/archived/ArchivedDetails';
import LogsPage from '../../weddingday/logs/LogsPage';
import EmployeesTable from '../../weddingday/employees/EmployeesTable';
import AddEmployee from '../../weddingday/employees/AddEmployeePage';
import ProfileEmployeePage from '../../weddingday/employees/ProfileEmployeePage';
import EmployeePage from '../../weddingday/employees/EmployeePage';
import WeddingPage from '../../weddingday/employeeHome/WeddingPage';
import AdminTeamMng from '../../weddingday/adminTeamMng/AdminTeamMng';
import WeddingInterestPage from '../../weddingday/weddingInterest/WeddingInterestPage';
import EmployeeCalendar from '../../weddingday/weddings/EmployeeCalendar';
import PaymentsPage from '../../weddingday/payments/PaymentsPage';
import PaymentDetailPage from '../../weddingday/payments/PaymentDetailPage';
import ReceiptsTable from '../../weddingday/payments/ReceiptsTable';
import paymentDays from '../../weddingday/weddings/paymentDays';
import EmailPage from '../../weddingday/settings/EmailPage';
import YearCalendar from '../../weddingday/weddings/YearCalendar';
import ManageRoomPlan from '../../weddingday/settings/settingsRoomPlan/ManageRoomPlan';
import { inRoomPlanTest } from '../../infra/helpers/Helpers';
import WeddingsPaymentsPage from '../../weddingday/weddings/WeddingsPaymentsPage';
import { PermissionsEnum } from '../../infra/helpers/Permissions';
import ArchivedGuestMap from '../../weddingday/archived/ArchivedGuestMap';

const routes = [
  {
    path: '/admins',
    component: AdminsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_ADMIN],
  },
  {
    path: '/admins/add',
    component: ManageAdminPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.CREATE_ADMIN],
  },
  {
    path: '/admins/:id',
    component: ManageAdminPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.EDIT_ADMIN],
  },
  {
    path: '/dynamic-pages',
    component: DynamicPages,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_DYNAMIC_PAGE],
  },
  {
    path: '/dynamic-pages/:id',
    component: ManageDynamicPages,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.EDIT_DYNAMIC_PAGE],
  },
  {
    path: '/translations',
    component: TranslationPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_TRANSLATION],
  },
  {
    path: '/settings',
    component: SettingsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_SETTING],
  },
  {
    path: '/settings/:tabKey',
    component: SettingsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_SETTING],
  },
  {
    path: '/settings/category/0',
    component: CategoryPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.CREATE_EMPLOYEE_CATEGORY],
  },
  {
    path: '/settings/category/:id',
    component: CategoryPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.EDIT_EMPLOYEE_CATEGORY],
  },
  // {
  //   path: '/settings/email/:id',
  //   component: EmailPage,
  //   isAuthenticated: true,
  //   employeeAllowed: false,
  //   chiefAllowed: false,
  //   humanResoursesAllowed: false,
  //   employeeAttendingAllowed: false,
  // },
  // {
  //   path: '/settings/room-plan/:id',
  //   component: ManageRoomPlan,
  //   isAuthenticated: true,
  //   employeeAllowed: false,
  //   chiefAllowed: false,
  //   humanResoursesAllowed: false,
  //   employeeAttendingAllowed: false,
  //   permissions: [PermissionsEnum.READ_BASE_ROOM_PLAN],
  // },
  {
    path: '/weddings',
    component: WeddingsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_WEDDING],
  },
  {
    path: '/employee-weddings',
    component: EmployeeWeddingsPage,
    isAuthenticated: true,
    employeeAllowed: true,
    chiefAllowed: true,
    humanResoursesAllowed: true,
    employeeAttendingAllowed: false,
    onlyEmployee: true,
    // permissions: [PermissionsEnum.LIST_WEDDING],
  },
  {
    path: '/weddings-calendar',
    component: WeddingsCalendar,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.CALENDAR_WEDDING],
  },
  {
    path: '/weddings-year-calendar',
    component: YearCalendar,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.CALENDAR_YEAR_WEDDING],
  },
  {
    path: '/calendar',
    component: EmployeeCalendar,
    isAuthenticated: true,
    employeeAllowed: true,
    chiefAllowed: true,
    humanResoursesAllowed: true,
    employeeAttendingAllowed: false,
    onlyEmployee: true,
    // permissions: [PermissionsEnum.CALENDAR_WEDDING],
  },
  {
    path: '/rest-days',
    component: RestDays,
    isAuthenticated: true,
    employeeAllowed: true,
    chiefAllowed: true,
    humanResoursesAllowed: true,
    employeeAttendingAllowed: true,
    permissions: [PermissionsEnum.REST_DAYS],
  },
  {
    path: '/payment-days',
    component: paymentDays,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.PAYMENT_DAYS],
  },
  {
    path: '/couple-payment-days',
    component: RestDays,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.COUPLE_PAYMENT_DAYS],
  },
  {
    path: '/weddings/add',
    component: CreateWeddingPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.CREATE_WEDDING],
  },
  {
    path: '/weddings/:id',
    component: CreateWeddingPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.EDIT_WEDDING],
  },
  {
    path: '/wedding-team/:id',
    component: AdminTeamMng,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.WEDDING_TEAMS],
  },
  {
    path: '/team-booking/:id',
    component: AdminTeamMng,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.TEAM_BOOKING],
  },
  {
    path: '/wedding-info/:id',
    component: WeddingPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: true,
    humanResoursesAllowed: true,
    employeeAttendingAllowed: false,
    // permissions: [PermissionsEnum.READ_WEDDING],
  },
  {
    path: '/wedding-interest/:id',
    component: WeddingInterestPage,
    isAuthenticated: true,
    employeeAllowed: true,
    chiefAllowed: true,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    onlyEmployee: true,
    // permissions: [PermissionsEnum.READ_WEDDING],
  },
  {
    path: '/inspirations',
    component: InspirationsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_INSPIRATION],
  },
  {
    path: '/inspirations/add',
    component: ManageInspirationPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.CREATE_INSPIRATION],
  },
  {
    path: '/inspirations/:id',
    component: ManageInspirationPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.EDIT_INSPIRATION],
  },
  {
    path: '/information',
    component: InformationPages,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_INFORMATION],
  },
  {
    path: '/information/:id',
    component: ManageInformationPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.EDIT_INFORMATION],
  },
  {
    path: '/colours',
    component: ColoursPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_COLOR],
  },
  {
    path: '/users',
    component: UsersPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_USER],
  },
  {
    path: '/upgrades/:id',
    component: ManageUpgradePage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.EDIT_SECTION_UPGRADE],
  },
  {
    path: '/news',
    component: NewsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_NEW],
  },
  {
    path: '/news/:id',
    component: ManageNewsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.EDIT_NEW],
  },
  {
    path: '/weddings-payments',
    component: WeddingsPaymentsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.WEDDING_PAYMENTS],
  },
  {
    path: '/archived',
    component: ArchivedPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_ARCHIVE],
  },
  {
    path: '/archived/:id',
    component: ArchivedDetails,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.READ_ARCHIVE],
  },
  {
    path: '/archived/wedding/:id',
    component: ArchivedDetails,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.READ_ARCHIVE],
  },
  {
    path: '/archived/:id/roomPlan',
    component: ArchivedGuestMap,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.READ_ARCHIVE],
  },
  {
    path: '/archived/:id/guestMap',
    component: ArchivedGuestMap,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.READ_ARCHIVE],
  },
  {
    path: '/logs',
    component: LogsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LOGS],
  },
  {
    path: '/employees',
    component: EmployeesTable,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_EMPLOYEE],
  },
  {
    path: '/profile',
    component: ProfileEmployeePage,
    isAuthenticated: true,
    employeeAllowed: true,
    chiefAllowed: true,
    humanResoursesAllowed: true,
    employeeAttendingAllowed: true,
    onlyEmployee: true
    // permissions: [PermissionsEnum.LIST_EMPLOYEE],
  },
  {
    path: '/employee',
    component: AddEmployee,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.CREATE_EMPLOYEE],
  },
  {
    path: '/employee/:id',
    component: EmployeePage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.EDIT_EMPLOYEE],
  },
  {
    path: '/employee/:id/:tabKey',
    component: EmployeePage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.EDIT_EMPLOYEE],
  },
  {
    path: '/payments',
    component: PaymentsPage,
    isAuthenticated: true,
    employeeAllowed: true,
    chiefAllowed: true,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_PAYMENT],
  },
  {
    path: '/payments/:tabKey',
    component: PaymentsPage,
    isAuthenticated: true,
    employeeAllowed: true,
    chiefAllowed: true,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_PAYMENT],
  },
  {
    path: '/payment/:employeeId/:status/:entity/:startDate/:endDate',
    component: PaymentDetailPage,
    isAuthenticated: true,
    employeeAllowed: true,
    chiefAllowed: true,
    humanResoursesAllowed: true,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.READ_PAYMENT],
  },
  {
    path: '/payment/:employeeId/:status/:entity/:paymentAccountId',
    component: PaymentDetailPage,
    isAuthenticated: true,
    employeeAllowed: true,
    chiefAllowed: true,
    humanResoursesAllowed: true,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.READ_PAYMENT],
  },
  {
    path: '/receipts',
    component: ReceiptsTable,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_RECEIPT],
  },
];

if (inRoomPlanTest()) {
  routes.push({
    path: '/settings/room-plan/:id',
    component: ManageRoomPlan,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.READ_BASE_ROOM_PLAN],
  });
}

export default routes;
