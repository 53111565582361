import { Button } from 'antd';
import styled from 'styled-components';

export const Section = styled.div`
  padding: 10px 20px;
  border: 1px solid ${({ theme }) => theme.inputBorderColor};
  border-radius: 4px;
  margin-bottom: 10px;
  max-width: 500px;
`;

export const SectionBg = styled.div`
  padding: 10px 20px;
  border: 1px solid ${({ theme }) => theme.inputBorderColor};
  border-radius: 4px;
  margin-bottom: 10px;
`;

export const Label = styled.div``;

export const FloatButton = styled.div`
  float: right;
  display: inline-block;
  background: ${({ theme }) => theme.primaryColor};
  color: white;
  cursor: pointer;
  padding: 5px 10px;
  margin-left: 10px;
  width: 70px;
  opacity: ${({ disable }) => (disable ? 0.5 : 1)};
  text-align: center;
`;

export const FloatButtonBg = styled.div`
  float: right;
  display: inline-block;
  background: ${({ theme }) => theme.primaryColor};
  color: white;
  cursor: pointer;
  padding: 5px 10px;
  margin-left: 10px;
  min-width: 70px;
  width: auto;
  opacity: ${({ disable }) => (disable ? 0.5 : 1)};
  text-align: center;
`;

export const ButtonBgUpdaload = styled(Button)`
  display: flex !important;
  flex-direction: row;
  align-items: center;
  float: left;
  display: inline-block;
  cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
  padding: 5px 10px;
  min-width: 70px;
  width: auto;
  opacity: ${({ disable }) => (disable ? 0.5 : 1)};
  text-align: center;
  box-sizing: border-box;
`;

export const FloatButtonExternalBg = styled.div`
  float: right;
  display: inline-block;
  cursor: pointer;
  margin-left: 20px;
  min-width: 70px;
  width: auto;
  text-align: center;
  position: relative;
  
  div.btn {
    background: #FAF4E5;
    border: 1px solid ${({ theme }) => theme.primaryColor};
    color: ${({ theme }) => theme.primaryColor};
    padding: 5px 15px;
    opacity: ${({ disable }) => (disable ? 0.5 : 1)};
  }

  div.icon {
    background: ${({ theme }) => theme.primaryColor};
    color: white;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 5px;
    right: -10px;

    img {
      width: 13px !important;
    }
  }
`;

export const FileZone = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
`;

export const FileName = styled.div`
  float: left;
`;
