import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';

const CurrencyComponent = ({ value }) => {
  const formattedValue = (value === "0" || value === '0.00' || value === '00.00' || Number(value) === 0) ? '00.00' : value;
  return (
    <NumberFormat
      value={formattedValue || null}
      defaultValue={'00.00'}
      displayType={'text'}
      suffix="€"
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
    />
  );
};

CurrencyComponent.propTypes = {
  value: PropTypes.number
};

export default CurrencyComponent;
