import React from 'react';
import {
  PrintedBar,
  PrintedBarContent
} from '../ProcessStyles';

const PrintedBarComponent = ({ small }) => {
  return (
    <PrintedBar small={small}>
      <PrintedBarContent small={small}>
        Este processo já foi impresso.
      </PrintedBarContent>
    </PrintedBar>
  );
};

export default PrintedBarComponent;