import client from '../config/AxiosConfig';

export const CreateTeamBooking = async (data) =>
  client.put(`/teamBooking/create`, data);

export const GetTeamBooking = async id =>
  client.get(`/teamBooking/find/${id}`);

export const GetTeamBookings = async () =>
  client.get(`/teamBooking/findAll`);

export const GetTeamBookingByWedding = async (weddingId) =>
  client.get(`teamBooking/findByWedding/${weddingId}`);

export const GetTeamBookingByWeddingAndSubcategory = async (weddingId, subcategoryId) =>
  client.get(`teamBooking/findByWeddingAndSubcategory/${weddingId}/${subcategoryId}`);

export const UpdateTeamBooking = async (data) =>
  client.post(`/teamBooking/update`, data);

export const PublishToRH = async (wedding) =>
  client.post(`/teamBooking/publishToRH`, wedding);

export const GetTeamBookingRowsByWedding = async (weddingId, data) =>
  client.post(`/teamBooking/getRowsByWedding/${weddingId}`, data);

export const DeleteTeamBooking = async id =>
  client.delete(`/teamBooking/remove/${id}`);