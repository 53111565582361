import moment from "moment";
import { WeddingStatus } from "../../../weddingday/reserves/ReserveAgenda";

export const VenueColors = {
  solar: '#f7b5d6',
  lago: '#b6d7a9',
  both: '#4a90e2',
};

export const getWeddingName = wedding => {
  if (wedding?.groom?.name && wedding?.bride?.name) {
    const bride = wedding.bride.name.split(' ')[0];
    const groom = wedding.groom.name.split(' ')[0];
    return `${bride} & ${groom}`;
  }
  return 'Casamento';
};

export const getWeddingFullName = wedding => {
  if (wedding?.groom?.name && wedding?.bride?.name) {
    const bride = wedding.bride.name;
    const groom = wedding.groom.name;
    return `${bride} & ${groom}`;
  }
  return 'Casamento';
};

export const getWeddingEmails = (wedding) => {
  if (wedding?.groom?.email && wedding?.bride?.email) {
    const bride = wedding.bride.email;
    const groom = wedding.groom.email;
    return `${bride}; ${groom}`;
  }
  return '';
};

export const getWeddingContacts = wedding => {
  if (wedding?.groom?.contact && wedding?.bride?.contact) {
    const bride = wedding.bride.contact;
    const groom = wedding.groom.contact;
    return `${bride}; ${groom}`;
  }
  return '';
};

export const getWeddingTitle = wedding => {
  let txt = '';
  let coupleName = ''
  if (wedding?.groom?.name && wedding?.bride?.name) {
    const bride = wedding.bride.name.split(' ')[0];
    const groom = wedding.groom.name.split(' ')[0];
    coupleName = `${bride} & ${groom}`;
  } else coupleName = 'Casamento';
  txt = coupleName;

  if (wedding?.status !== WeddingStatus.RESERVE && wedding?.date) {
    const weddingDate = moment.utc(wedding?.date).locale('pt').format('dddd, DD MMMM YYYY');
    txt = `${txt} | ${weddingDate}`;
  }

  if (wedding?.status !== WeddingStatus.RESERVE && wedding && wedding?.wedding_place) {
    const weddingPlace = getLocal(wedding);
    if (weddingPlace !== '') txt = `${txt} | ${weddingPlace}`;
  }

  return txt;
};

export const getWeddingLabelSelect = wedding => {
  let txt = '';
  let coupleName = ''
  if (wedding?.groom?.name && wedding?.bride?.name) {
    const bride = wedding.bride.name.split(' ')[0];
    const groom = wedding.groom.name.split(' ')[0];
    coupleName = `${bride} & ${groom}`;
  } else coupleName = 'Casamento';
  txt = coupleName;

  if (wedding?.status !== WeddingStatus.RESERVE && wedding?.date) {
    const weddingDate = moment.utc(wedding?.date).locale('pt').format('dddd, DD MMMM YYYY');
    txt = `${txt} | ${weddingDate}`;
  }

  if (wedding?.status !== WeddingStatus.RESERVE && wedding && wedding?.wedding_place) {
    const weddingPlace = wedding?.wedding_place
      ? wedding?.wedding_place === 2
        ? 'QLC'
        : 'SLE'
      : '';
    if (weddingPlace !== '') txt = `${txt} | ${weddingPlace}`;
  }

  if (wedding?.status === WeddingStatus?.RESERVE) {
    txt = `${txt} (Reserva)`
  } else if (wedding?.status === WeddingStatus?.RESERVE_SINALIZATION) {
    txt = `${txt} (Em Sinalização)`
  } else if (wedding?.status === WeddingStatus?.CONFIRMED) {
    txt = `${txt} (Confirmado)`
  }

  return txt;
};

export const getUserLabel = (user) => {
  const type = user?.employeeAttending
    ? 'CA'
    : user?.organizer
      ? 'Organizador'
      : user?.admin
        ? 'Administrador'
        : '';
  return `${user.name} ${type !== '' ? `(${type})` : ''}`
}

export const getEmployeeUserLabel = (employee) => {
  const type = employee?.user?.employeeAttending
    ? 'CA'
    : employee?.user?.organizer
      ? 'Organizador'
      : employee?.user?.admin
        ? 'Administrador'
        : '';
  return `${employee?.user.name} ${type !== '' ? `(${type})` : ''}`
}

export const getLocal = (wedding) => {
  if (wedding?.wedding_place === 1) return 'SLE';
  if (wedding?.wedding_place === 2) return 'QLC';
  return '';
};

export const getLocalColor = (object, colors, local = null) => {
  if (object && (object.wedding_place === 1 || object.weddingPlace === 1) || (local !== null && local === 1)) return { name: 'Solar da Levada', initials: 'SLE', color: colors?.colorSys1 };
  if (object && (object.wedding_place === 2 || object.weddingPlace === 2) || (local !== null && local === 2)) return { name: 'Quinta Lago dos Cisnes', initials: 'QLC', color: colors?.colorSys2 };
  return null;
};

export const getVenueColor = (object, colors, local = null) => {
  if (object && (object.wedding_place === 1 || object.weddingPlace === 1) || (local !== null && local === 1)) {
    return { name: 'Solar da Levada', initials: 'SLE', color: colors?.solar };
  } else if (object && (object.wedding_place === 2 || object.weddingPlace === 2) || (local !== null && local === 2)) {
    return { name: 'Quinta Lago dos Cisnes', initials: 'QLC', color: colors?.lago };
  } else if (object && (object.wedding_place === 3 || object.weddingPlace === 3) || (local !== null && local === 3)) {
    return { name: 'Ambos', initials: 'AMB', color: colors?.both };
  } else return null;
};

export const showLocal = local => {
  if (local === 1) return 'Solar da Levada';
  if (local === 2) return 'Quinta Lago dos Cisnes';
  return '';
};

export const getHomeTitle = (wedding) => {
  const today = moment(moment().format('YYYY-MM-DD'));

  const weddingDate = moment(wedding.date).format('YYYY-MM-DD');
  if (moment(weddingDate).isBefore(today)) return 'Casamento Anterior';
  else if (moment(weddingDate).isSame(today)) return 'Casamento do Dia';
  else return 'Próximo Casamento';
}

export const getScheduler = (resOurDay) => {
  let scheduler = {
    arrivingTime: 'ND',
    snackTime: 'ND',
    dinnerTime: 'ND'
  };

  if (resOurDay !== undefined) {
    for (let index = 0; index < resOurDay.length; index++) {
      const moment = resOurDay[index];
      if (moment.name === 'Chegada à Quinta') scheduler.arrivingTime = moment.time;
      else if (moment.name === 'Início dos Aperitivos') scheduler.snackTime = moment.time;
      else if (moment.name === 'Entrada na Sala de Jantar') scheduler.dinnerTime = moment.time;
    }
  }

  return scheduler;
}

export const getStartTimeBySubcategory = (subcategory, scheduler) => {
  let startTime;

  if (subcategory?.calcTime && subcategory?.weddingTimeRule && subcategory?.weddingTimeRule === 'Chegada à Quinta' && scheduler && scheduler?.arrivingTime !== 'ND') {
    startTime = moment(scheduler.arrivingTime, 'HH:mm').utc();
  } else if (subcategory?.calcTime && subcategory?.weddingTimeRule && subcategory?.weddingTimeRule === 'Início dos Aperitivos' && scheduler && scheduler?.snackTime !== 'ND') {
    startTime = moment(scheduler.snackTime, 'HH:mm').utc();
  } else if (subcategory?.calcTime && subcategory?.weddingTimeRule && subcategory?.weddingTimeRule === 'Entrada na Sala de Jantar' && scheduler && scheduler?.dinnerTime !== 'ND') {
    startTime = moment(scheduler.dinnerTime, 'HH:mm').utc();
  } else startTime = moment.utc();

  if (startTime && subcategory?.calcTime && subcategory?.timeRule && subcategory?.timeRule != 0.00) {
    const isRuleBefore = subcategory?.timeRule > 0 ? false : true;
    const ammount = Math.abs(subcategory.timeRule);
    startTime = isRuleBefore
      ? startTime.subtract(ammount, 'hours')
      : startTime.add(ammount, 'hours');
  }

  return startTime;
}

export const getQuantityBySubcategory = (subcategory, pax, adults, tables) => {
  let quantity = 0;
  const qtdIF = pax <= 100
    ? 5
    : (pax > 100 && pax <= 140)
      ? 6
      : (pax > 140 && pax <= 180)
        ? 7
        : (pax > 180 && pax <= 210)
          ? 8
          : (pax > 210 && pax <= 250)
            ? 9
            : (pax > 250 && pax <= 280)
              ? 10
              : (pax > 280 && pax <= 320)
                ? 11
                : 11;

  const resAJB = ((tables - qtdIF) / 2);
  const qtdAJB = ((resAJB % 1) === 0) ? resAJB : Math.ceil(resAJB);

  if (subcategory?.calcAutomatic && subcategory?.quantityRule) {
    switch (subcategory?.quantityRule) {
      case 1: { //Subcategory Montagem
        const resM = ((adults / 20) + 2) / 2;
        quantity = ((resM % 1) === 0) ? resM : Math.ceil(resM);
        break;
      }
      case 2: { //Subcategory Início até ao Fim (IF)
        quantity = qtdIF;
        break;
      }
      case 3: { //Subcategory AJB
        quantity = qtdAJB;
        break;
      }
      case 4: { //Subcategory Jantar e Bolo (JB)
        const res = (tables - qtdIF - qtdAJB);
        quantity = ((res % 1) === 0) ? res : Math.ceil(res);
        break;
      }
      case 5: { //Subcategory Copa Inteiro
        quantity = 2;
        break;
      }
      case 6: { //Subcategory Copa Meio
        quantity = pax > 145 ? 1 : 0;
        break;
      }
      case 7: { //Subcategory Laminadores
        quantity = 4;
        break;
      }
      case 8: { //Subcategory Decor Gold
        quantity = 2;
        break;
      }
      case 9: { //Subcategory Decor Silver
        quantity = 2;
        break;
      }
      default: {
        quantity = 1;
        break;
      }
    }
  }

  return quantity;
}

export const updateRowsAndEmployeesList = (rows, wedding) => {
  //Update employees list based on selected employee
  rows.forEach(row => {
    const rowsEmp = rows?.filter(f => f.weddingTeam && f.weddingTeam.employee && row.weddingTeam.employee && f.weddingTeam.employee.id === row.weddingTeam.employee.id || row.weddingTeam.employee && f.employees && f.employees.length > 0 && f.employees.filter(e => e.id === row.weddingTeam.employee.id).length > 0);
    const empSelected = rowsEmp?.filter(f => f.weddingTeam.employee && row.weddingTeam.employee && f.weddingTeam.employee.id === row.weddingTeam.employee.id);
    const checkEmpRepeat = row?.employeePrice?.find(f => f.subcategory && f.subcategory.repeat);
    const employeeTeams = row?.weddingTeam?.employee && row?.selectedTeams?.find(f => f.employee.id === row.weddingTeam.employee.id)
      ? row.selectedTeams.find(f => f.employee.id === row.weddingTeam.employee.id).teams
      : [];
    const checkTeamOtherWedding = employeeTeams.find(f => f.wedding !== wedding.id);

    rowsEmp.forEach(rowE => {
      const indexE = rows.findIndex(f => f.weddingTeam?.id === rowE.weddingTeam?.id);
      //Remove employee selected from rows where employee search exists
      if (rowE?.weddingTeam?.employee) {
        rows[indexE].employees = rows[indexE].employees.filter(f => f && rowE?.weddingTeam?.employee && f.id !== rowE?.weddingTeam?.employee?.id);
      }

      //Remove employee searched if subcategory is equal
      if (row.weddingTeam.employee && row.weddingTeam.subcategory && rowE.weddingTeam.subcategory && row?.weddingTeam?.subcategory?.id === rowE?.weddingTeam?.subcategory?.id) {
        rows[indexE].employees = rows[indexE].employees.filter(f => f && row?.weddingTeam?.employee && f?.id !== row.weddingTeam?.employee?.id);
      }

      //Remove employees based on all lines
      if (empSelected && (checkEmpRepeat && (checkTeamOtherWedding && empSelected?.length === 1 || !checkTeamOtherWedding && empSelected?.length === 2) || !checkEmpRepeat && empSelected.length === 1)) {
        rows[indexE].employees = rows[indexE].employees.filter(f => f && row?.weddingTeam?.employee && f?.id !== row.weddingTeam?.employee?.id);
      }
    });
  });
  return rows;
}

export const getWeddingTeamAndEmployeesInCharge = (resTeam, subcategories) => {
  const weddingTeam = [];
  const employeesInCharge = {
    chief: [],
    coordinator: [],
    host: []
  };

  for (let index = 0; index < resTeam.data.length; index++) {
    const team = resTeam.data[index].weddingTeam;

    if (team.subcategory.id === subcategories.chief.id) employeesInCharge.chief.push(team);
    else if (team.subcategory.id === subcategories.coordinator.id) employeesInCharge.coordinator.push(team);
    else if (team.subcategory.id === subcategories.host.id) employeesInCharge.host.push(team);
    else weddingTeam.push(team);
  }
  return { weddingTeam, employeesInCharge };
}

export const getEmployeesOrdered = (resInterest, resEmpBySub) => {
  let employeesList = resEmpBySub.success && resEmpBySub.data.length > 0 ? resEmpBySub.data : [];
  const employeeInterested = resInterest.success && resInterest.data.length > 0 ? resInterest.data.map(m => m.employee) : [];

  for (let index = 0; index < employeeInterested.length; index++) {
    if (!employeeInterested[index].name.includes('(Interessado)')) employeeInterested[index].name += ' (Interessado)';
    employeesList = employeesList.filter(f => f._id !== employeeInterested[index]._id);
  }

  const employeeFarm = employeesList.filter(f => f?.entity?.entityValue == 2);
  employeeFarm.forEach(e => {
    if (!e.name.includes('(Quinta)')) e.name += ' (Quinta)';
  });
  employeeFarm.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

  const employeeSolar = employeesList.filter(f => f?.entity?.entityValue == 1);
  employeeSolar.forEach(e => {
    if (!e.name.includes('(Solar)')) e.name += ' (Solar)';
  });
  employeeSolar.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

  const employeeBoth = employeesList.filter(f => f?.entity?.entityValue == 3);
  employeeBoth.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

  const finalEmployees = [].concat(employeeInterested, employeeSolar, employeeFarm, employeeBoth);
  return finalEmployees;
}

export const setDeclaration = (settingDeclaration, paymentAccount) => {
  if (settingDeclaration && paymentAccount && paymentAccount.employee) {
    let declaration = settingDeclaration?.name;
    let month = moment().format('MM');
    let year = parseInt(month) >= 12 ? (parseInt(moment.utc().format('YYYY')) + 1).toString() : moment.utc().format('YYYY');

    declaration = declaration.replace('${name}', (paymentAccount?.employee?.name || '---'));
    declaration = declaration.replace('${citizenNumber}', (paymentAccount?.employee?.citizenNumber || '---'));
    declaration = declaration.replace('${nif}', (paymentAccount?.employee?.nifInvoicing || '---'));
    declaration = declaration.replace('${address}', (paymentAccount?.employee?.address || '---'));
    declaration = declaration.replace('${zipCode}', (paymentAccount?.employee?.zipCode || '---'));
    declaration = declaration.replace('${locality}', (paymentAccount?.employee?.locality || '---'));
    declaration = declaration.replace('${date}', '01/12/' + year);
    declaration = declaration.replace('${year}', year);

    return declaration;
  } else return null
}

export const getCurrencyNumber = (value) => {
  return parseFloat(value).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
}

export const getInitials = (name) => {
  let initials = '';
  if (name) {
    const nameParts = name.split(/\s|-/); // Use a regular expression to split on spaces or hyphens

    if (nameParts?.length > 4) {
      // Get initials from the first two names
      const firstInitials = nameParts.slice(0, 2).map((n) => n.substring(0, 1)).join('');

      // Get initials from the last two names
      const lastInitials = nameParts.slice(-2).map((n) => n.substring(0, 1)).join('');

      initials = (firstInitials + lastInitials).toUpperCase() || '';
    } else {
      initials = nameParts
        .map((n) => n.substring(0, 1))
        .join('')
        .toUpperCase() || '';
    }
  }

  return initials;
};


export const getWeddingStatus = (status) => {
  if (status) {
    return status === WeddingStatus.CONFIRMED
      ? 'O'
      : status === WeddingStatus.RESERVE_SINALIZATION
        ? 'S'
        : 'RSC';

  } else return '';
}

export const getPlaceInitials = (weddingPlace) => {
  return weddingPlace
    ? weddingPlace === 2
      ? 'QLC'
      : 'SLE'
    : '';
}