import React from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { Modal, Button } from 'antd';
import { BaseForm } from '../../styles/BasicStyles';
import TextInput from '../../components/inputs/TextInput';
import CurrencyInput from '../../components/inputs/CurrencyInput';
import SelectInput from '../../components/inputs/SelectInput';
import DateInput from '../../components/inputs/DateInput';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import CheckboxInput from '../../components/inputs/CheckboxInput';

const BudgetTypes = [
  { _id: 'PAYMENT', name: 'Pagamentos Efectuados' },
  { _id: 'DISCOUNT', name: 'Valores a descontar' },
  { _id: 'EXPENSE', name: 'Valores a adicionar' },
  { _id: 'OFFER', name: 'Ofertas' },
];

const validations = values => {
  let error = {};

  if (values.type === 'PAYMENT') {
    error = FormValidator.make({
      type: 'required',
      phase: 'required',
      title: 'required',
      description: 'required',
      value: 'required',
      date: 'required'
    })(values);
  } else {
    error = FormValidator.make({
      type: 'required',
      title: 'required',
      description: 'required',
      value: 'required',
      date: 'required'
    })(values);
  }

  return error;
};

let BudgetModal = ({
  open,
  edit,
  loading,
  handleSubmit,
  onSubmit,
  closeModal,
  wedding,
  type,
  change
}) => {
  // console.warn('wedding', wedding);
  // console.warn('paymentPhases', wedding.paymentPhases);
  return (
    <Modal
      visible={open}
      title={edit ? 'Editar registo' : 'Adicionar registo'}
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      {open && (
        <BaseForm onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={SelectInput}
            name={'type'}
            label={'Tipo de registo *'}
            placeholder={'Selecione o tipo de registo'}
            type="select"
            data={BudgetTypes}
          />
          {type === 'PAYMENT' && <Field
            component={SelectInput}
            name={'phase'}
            label={'Fase do Pagamento *'}
            placeholder={'Selecione a fase'}
            type="select"
            data={wedding.paymentPhases.map(m => ({ name: `${m.phase.pt} ${m.order} (${moment(m.date).format('DD/MM/YYYY')})`, ...m }))}
            dataKey='order'
            onChange={(event) => {
              const selectedPaymentPhase = wedding.paymentPhases.find(f => f?.order && event && f.order == event);
              if (selectedPaymentPhase) {
                change('title', `${selectedPaymentPhase.phase.pt} ${selectedPaymentPhase.order} (${moment(selectedPaymentPhase.date).format('DD/MM/YYYY')})`);
              }
            }}
          />}
          <Field
            component={TextInput}
            name={'title'}
            type="text"
            label={'Titulo *'}
            placeholder={'Titulo do registo'}
          />
          <Field
            component={CurrencyInput}
            name={'value'}
            defaultValue="00.00"
            label={'Valor *'}
            placeholder={'Insira o valor do registo'}
          />
          <Field
            component={DateInput}
            name="date"
            label="Data *"
            placeholder="Data do registo"
          />
          <Field
            component={TextAreaInput}
            name={'description'}
            type="text"
            label={'Descrição *'}
            placeholder={'Descrição do registo'}
          />
          {type === 'PAYMENT' && <Field
            component={CheckboxInput}
            name={'phaseComplete'}
            type="text"
            label={'Pagamento da fase concluído?'}
            positiveLabel={'Sim'}
          />}
        </BaseForm>
      )}
    </Modal>
  );
};


BudgetModal = reduxForm({
  form: 'manage_budget_form',
  validate: validations
})(BudgetModal);

const selector = formValueSelector('manage_budget_form');

const mapStateToProps = state => ({
  type: selector(state, 'type'),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ change }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BudgetModal);
