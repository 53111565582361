import { StyleSheet, Text } from "@react-pdf/renderer";
import React from "react";

export const stylesPDF = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'row'
  },
  section: {
    margin: '40px 20px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginTop: 90,
    width: '100%',
    flexGrow: 1
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    padding: 2,
    border: '1px solid black',
    width: '70px',
    height: 'auto'
  },
  sectionPayments: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: 1
  },
  payment: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    padding: 2,
    border: '1px solid black'
  },
  text: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  total: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    padding: 2,
    border: '1px solid black',
    width: '50px'
  }
});

export const getFirstAndLastName = (name) => {
  let names = name.split(' ');
  if (names?.length > 0) {
    if (names?.length > 2) {
      const firstName = names[0];
      const lastName = names[names?.length - 1];
      let middleNames = [...names];
      middleNames.shift(); //Remove 1st name
      middleNames.pop(); // Remove last name
      middleNames = middleNames.map(m => m?.charAt(0).toUpperCase());
      const middleName = middleNames?.length > 1
        ? middleNames.join('. ')
        : `${middleNames[0]}.`;
      return <Text style={stylesPDF.text}>{firstName} {middleName} {lastName}</Text>
    }
    if (names.length > 1) {
      return <Text style={stylesPDF.text}>{names[0]} {names[names.length - 1]}</Text>
    } else {
      return <Text style={stylesPDF.text}>{names[0]}</Text>;
    }
  } else {
    return <Text></Text>;
  }
}

export const getLinesForEachWord = (name) => {
  let names = name.split(' ');
  return <React.Fragment>{names.map((n) => { return <Text style={{ margin: 'auto' }}>{n}</Text> })}</React.Fragment>
}