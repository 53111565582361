import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { BaseForm, SpinLoading } from '../../styles/BasicStyles';
import PropTypes from 'prop-types';
import DraftInput from '../../components/inputs/DraftInput';
import SelectInput from '../../components/inputs/SelectInput';
import { isCurrentUserAdmin } from '../../infra/helpers/Helpers';
import { GetAttendingEmployees } from '../../infra/requests/UsersRequests';

const validations = (values) => {
    let errors = {};

    errors = FormValidator.make({
        // date: 'required',
        // wedding_place: 'required',
        // price: 'required',
        // min_people: 'required|minNumber:140',
        // language: 'required',
        // dateSecondPaymentPhase: 'required'
    })(values);

    return errors;
};

let ReserveNotesModal = ({
    isOpen,
    saving,
    handleSubmit,
    initialValues,
    onlyNotes,
    onSubmit,
    onClose,
    reserveNotesForm
}) => {
    const [loadingEmployees, toggleLoadingEmployees] = useState(false);
    const [employeesAttending, setEmployeesAttending] = useState([]);

    useEffect(() => {
        if(!onlyNotes) getEmployeesAttending();
    }, [isOpen]);

    const getEmployeesAttending = async () => {
        toggleLoadingEmployees(true);
        const result = await GetAttendingEmployees();
        setEmployeesAttending(result?.success && result?.data ? result.data : []);
        toggleLoadingEmployees(false);
    }

    return (
        <Modal
            visible={isOpen}
            title='Detalhes da Reserva'
            maskClosable
            onCancel={onClose}
            footer={[
                <Button
                    key='cancel'
                    type='default'
                    onClick={onClose}>
                    Cancelar
                </Button>,
                <Button
                    key='submit'
                    type='primary'
                    loading={saving}
                    onClick={handleSubmit(onSubmit)}>
                    Gravar
                </Button>
            ]}>

            {loadingEmployees
                ? <SpinLoading />
                : <BaseForm onSubmit={handleSubmit(onSubmit)}>
                    {!onlyNotes && isCurrentUserAdmin() && <Field
                        component={SelectInput}
                        name={'attendedBy'}
                        label={'Colaborador de Atendimento'}
                        placeholder={'Selecione o colaborador'}
                        type="select"
                        data={employeesAttending || []}
                    />}
                    <Field
                        component={DraftInput}
                        label='Notas'
                        name={`notes`}
                        placeholder={'Introduza aqui as notas da reserva'}
                        minRows={10}
                        toolbarOnFocus={false}
                        toolbar='notes'
                    />
                    {!onlyNotes && <Field
                        component={DraftInput}
                        label='Visitas'
                        name={`visits`}
                        placeholder={'Introduza aqui as informações das visitas'}
                        minRows={10}
                        toolbarOnFocus={false}
                        toolbar='notes'
                    />}
                </BaseForm>}
        </Modal>
    );
};

ReserveNotesModal = reduxForm({
    form: 'reserve_notes_form',
    enableReinitialize: true,
    validate: validations,
})(ReserveNotesModal);

const selector = formValueSelector('reserve_notes_form');

const mapStateToProps = state => ({
    reserveNotesForm: {
        notes: selector(state, 'notes'),
    }
});

ReserveNotesModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(ReserveNotesModal)