import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Row, Checkbox, Icon } from 'antd';
import { BaseForm } from '../../styles/BasicStyles';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import FormValidator from '../../infra/services/validations/FormValidator';
import moment from 'moment';


let ModalTeamNotification = (props): React$Element<React$FragmentType> => {
    const [selectAll, setSelectAll] = React.useState(false);
    const [allTeamNotified, setAllTeamNotified] = React.useState(false);
    const [rows, setRows] = React.useState([]);

    useEffect(() => {
        setAllTeamNotified(JSON.parse(JSON.stringify(props.initialValues.allTeamNotified)));
    }, [props.initialValues.allTeamNotified])

    useEffect(() => {
        setRows(JSON.parse(JSON.stringify(props.initialValues.team)));
    }, [props.initialValues.team])

    const footer = [
        <Button
            key='cancel'
            onClick={() => props.onClose()}>
            Cancelar
        </Button>,
        <Button
            key='submit'
            type='primary'
            onClick={() => props.onSubmit(rows)}>
            Notificar
        </Button>
    ];
    return (
        <Modal
            visible={props.isOpen}
            title={'Notificar'}
            centered={true}
            width={300}
            onCancel={() => props.onClose()}
            footer={footer}
        >
            <Row>
                <Checkbox
                    disabled={rows.length === 0}
                    checked={selectAll}
                    onChange={e => {
                        setSelectAll(e.target.checked);
                        for (let index = 0; index < rows.length; index++) {
                            rows[index].notify = e.target.checked;
                        }
                        setRows(rows);
                    }}>
                    Selecionar Todos
                </Checkbox>
            </Row>

            {rows.length > 0 ? rows.map((elem, index) => {
                return (<Row>
                    <div className="sc-dxgOiQ fWHHkk" >
                        <Checkbox
                            checked={elem.notify}
                            onChange={e => {
                                rows[index].notify = e.target.checked;
                                setRows(JSON.parse(JSON.stringify(rows)));
                            }}>
                            {elem.employee.name}
                        </Checkbox>
                    </div>
                </Row>)
            }) : allTeamNotified ? <p>A equipa do casamento encotra-se confirmada!</p>
                : <p>Equipa do casamento em constituição!</p>}
        </Modal >
    );
};

export default ModalTeamNotification;