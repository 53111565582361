import client from "../config/AxiosConfig";

export const GetAllAppointments = async (page = 1, limit = 12) =>
  await client.get(`/bookings/${page}/${limit}`);

export const GetAppointmentByID = async bookingId =>
  await client.get(`/bookings/${bookingId}`);

export const GetBookingForChart = async (filter) =>
  await client.get(`/bookings/chart?filter=${JSON.stringify(filter)}`);

export const GetBookingForLineChart = async (filter) =>
  await client.get(`/bookings/linechart?filter=${JSON.stringify(filter)}`);

  export const GetBookingStats = async () =>
  await client.get(`/bookings/stats`);
