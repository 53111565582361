import SectionsPage from '../../weddingday/sections/SectionsPage';
import AboutUsSection from '../../weddingday/sections/pages/AboutUsSection';
import CeremonySection from '../../weddingday/sections/pages/CeremonySection';
import StyleSection from '../../weddingday/sections/pages/StyleSection';
import DaySection from '../../weddingday/sections/pages/DaySection';
import StaffSection from '../../weddingday/sections/pages/StaffSection';
import GuestsSection from '../../weddingday/sections/pages/GuestsSection';
import FoodSection from '../../weddingday/sections/pages/FoodSection';
import DrinksSelection from '../../weddingday/sections/pages/DrinksSelection';
import DecorationSection from '../../weddingday/sections/pages/DecorationSection';
import PartySection from '../../weddingday/sections/pages/PartySection';
import UpgradesSection from '../../weddingday/sections/pages/UpgradesSection';
import ChapterQuestionsPage from '../../weddingday/chapterQuestions/ChapterQuestionsPage';
import QuestionOptionsPage from '../../weddingday/questionOptions/QuestionOptionsPage';
import GuestQuestionsPage from '../../weddingday/guestQuestions/GuestQuestionsPage';
import { PermissionsEnum } from '../../infra/helpers/Permissions';
import ManageOptionPage from '../../weddingday/questionOptions/ManageOptionPage';

export default [
  {
    path: '/sections',
    component: SectionsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_SECTION],
  },
  {
    path: '/sections/about_us',
    component: AboutUsSection,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.SECTION_ABOUT_US],
  },
  {
    path: '/sections/style',
    component: StyleSection,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.SECTION_STYLE],
  },
  {
    path: '/sections/day',
    component: DaySection,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.SECTION_DAY],
  },
  {
    path: '/sections/ceremony',
    component: CeremonySection,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.SECTION_CEREMONY],
  },
  {
    path: '/sections/staff',
    component: StaffSection,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.SECTION_STAFF],
  },
  {
    path: '/sections/guests',
    component: GuestsSection,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.SECTION_GUEST],
  },
  {
    path: '/sections/food',
    component: FoodSection,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.SECTION_FOOD],
  },
  {
    path: '/sections/drinks',
    component: DrinksSelection,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.SECTION_DRINK],
  },
  {
    path: '/sections/decoration',
    component: DecorationSection,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.SECTION_DECOR],
  },
  {
    path: '/sections/party',
    component: PartySection,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.SECTION_PARTY],
  },
  {
    path: '/sections/upgrades',
    component: UpgradesSection,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.EDIT_SECTION_UPGRADE],
  },
  {
    path: '/chapter/:id',
    component: ChapterQuestionsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_QUESTION],
  },
  {
    path: '/question/:id',
    component: QuestionOptionsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_OPTION],
  },
  {
    path: '/option/:id',
    component: ManageOptionPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_OPTION],
  },
  {
    path: '/guests/:id',
    component: GuestQuestionsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.LIST_QUESTION],
  }
];
