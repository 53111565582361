import React from "react";
import {
  DetailBlockContainer,
  TextContent,
  Question,
  Answer
} from "./DetailBlockStyles";

const BaseServiceBlock = ({ minGuests, price = null, pax = null, seats = null }) => {
  return (
    <DetailBlockContainer>
      {
        minGuests > 0 &&
        <TextContent>
          <Question>Número mínimo de convidados:</Question>
          <Answer>{minGuests}</Answer>
        </TextContent>
      }
      {
        price &&
        <TextContent>
          <Question small>Preço por PAX:</Question>
          <Answer small>{price}</Answer>
        </TextContent>
      }
      {
        pax > 0 &&
        <TextContent>
          <Question small>PAX:</Question>
          <Answer small>{pax}</Answer>
        </TextContent>
      }
      {
        seats > 0 &&
        <TextContent>
          <Question small>Lugares montados:</Question>
          <Answer small>{seats}</Answer>
        </TextContent>
      }
    </DetailBlockContainer>
  );
}
  
export default BaseServiceBlock;