import React, {Component} from 'react';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle
} from '../../styles/BasicStyles';
import {GetDynamicPages} from '../../infra/requests/BaseRequests';

class DynamicPages extends Component {
  state = {
    loading: false,
    columns: [
      {
        title: 'Pages',
        dataIndex: 'title'
      }
    ],
    rows: []
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = record => {
    const {history} = this.props;
    history.push(`/management/dynamic-pages/${record._id}`);
  };

  updateDataTable = async () => {
    this.setState({loading: true});
    try {
      const result = await GetDynamicPages();
      this.setState({
        rows: result.data,
        loading: false
      });
    } catch (e) {
      this.setState({loading: false});
      console.error(e);
    }
  };

  render() {
    const {columns, rows, total, loading} = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Dynamic Pages</PageTitle>
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer buttons={0}>
          <Table
            columns={columns}
            currentPage={1}
            pageSize={50}
            loading={loading}
            onPressRow={this.handleOnPressRow}
            rows={rows}
            showHeader={true}
            emptyIcon="copy"
            emptyText={'No Pages'}
            total={total}
            rowKey={'_id'}
            hasPagination={false}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default DynamicPages;
