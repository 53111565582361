import { inactiveColor, warningColor } from "../reserves/reservesUtils";
import { PresenceStatus } from "./SchedulePresenceModal";

export const ScheduleTypes = {
    FIRST_VISIT: 'FV',
    INTERNAL: 'I',
    ORGANIZATION: 'O',
};

export const OrganizerLanguageLabel = {
    'PT': 'Português',
    'EN': 'Inglês',
    'PT_EN': 'Português & Inglês'
};

export const OrganizerLanguageOptins = [
    { code: 'pt', name: OrganizerLanguageLabel['PT'] },
    { code: 'en', name: OrganizerLanguageLabel['EN'] },
    { code: 'pt_en', name: OrganizerLanguageLabel['PT_EN'] },
];

export const VistLanguageOptions = [
    { code: 'pt', name: 'Português' },
    { code: 'en', name: 'Inglês' },
    { code: 'pt_en', name: 'Preferencial Inglês, mas pode ser em Português' },
];

export const GetScheduleRowColor = (schedule) => {
    let color = '';
    if (schedule?.presence === PresenceStatus.MISSED) {
        color = inactiveColor;
    }

    return color;
}

export const GetScheduleWarningRowColor = (schedule) => {
    let color = '';
    if (schedule?.warningSummary) {
        color = warningColor;
    } else if (schedule?.presence === PresenceStatus.MISSED) {
        color = inactiveColor;
    }

    return color;
}