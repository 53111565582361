import { Col, Row } from 'antd';
import React, { Component, Fragment } from 'react';
import { getCurrencyNumber } from '../../infra/services/wedding/weddingUtils';

class PaymentTotals extends Component {
  state = {
    hasSelection: false,
    value: 0,
    bonus: 0,
    total: 0,
    receipts: false
  };

  componentDidMount = async () => {
    this.setState({
      hasSelection: this.props.hasSelection,
      value: this.props.value,
      bonus: this.props.bonus,
      total: this.props.total,
      receipts: this.props.receipts
    })
  };

  componentDidUpdate(prevProps) {
    if ((prevProps.value !== this.props.value) || (prevProps.bonus !== this.props.bonus) || (prevProps.total !== this.props.total)) {
      this.setState({
        hasSelection: this.props.hasSelection,
        value: this.props.value,
        bonus: this.props.bonus,
        total: this.props.total,
        receipts: this.props.receipts
      })
    }
  }

  render() {

    return (
      <React.Fragment>
        <div className='desktop' style={this.state.receipts === false ? { position: 'absolute', bottom: 0, right: 0, width: 600, padding: '16px 0', display: 'flex', justifyContent: 'end' } : { position: 'absolute', right: 20, width: 600, padding: '16px 0', display: 'flex', justifyContent: 'end' }}>
          {this.state.hasSelection ? <div style={{ margin: 'auto 10px auto auto' }}>Linhas Selecionadas</div> : null}
          <div className='ant-table ant-table-bordered'>
            <table className='ant-table-content' style={{ border: '1px solid #dfe0df', width: '100%' }}>
              <tbody className='ant-table-tbody' style={{ borderRadius: 4 }}>
                <tr style={{ borderRadius: 4 }}>
                  <td style={{ background: '#dfe0df', padding: 5/*, borderTopLeftRadius: 5*/ }}>Oficial</td>
                  <td style={{ padding: 5, textAlign: 'right' }}>{getCurrencyNumber(Math.round(this.state.value))}</td>

                  <td style={{ background: '#dfe0df', padding: 5 }}>Bónus</td>
                  <td style={{ padding: 5, textAlign: 'right' }}>{getCurrencyNumber(Math.round(this.state.bonus))}</td>

                  <td style={{ background: '#dfe0df', padding: 5 }}>Total</td>
                  <td style={{ padding: 5, textAlign: 'right' }}>{getCurrencyNumber(Math.round(this.state.total))}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div style={{ position: 'relative', width: '100%', padding: '16px 0' }} className='mobile'>
          {this.state.hasSelection ? <div style={{ margin: 'auto 10px auto auto' }}>Linhas Selecionadas</div> : null}
          <div className=' ant-table ant-table-bordered'>
            <table className='ant-table-content' style={{ border: '1px solid #dfe0df', width: '100%' }}>
              <tbody style={{ borderRadius: 4 }} className='ant-table-tbody'>
                <tr style={{ borderRadius: 4 }}>
                  <td style={{ background: '#dfe0df', padding: 5/*, borderTopLeftRadius: 5*/ }}>Oficial</td>
                  <td style={{ padding: 5, textAlign: 'right' }}>{getCurrencyNumber(Math.round(this.state.value))}</td>
                </tr>
                <tr style={{ borderRadius: 4 }}>
                  <td style={{ background: '#dfe0df', padding: 5 }}>Bónus</td>
                  <td style={{ padding: 5, textAlign: 'right' }}>{getCurrencyNumber(Math.round(this.state.bonus))}</td>
                </tr>
                <tr style={{ borderRadius: 4 }}>
                  <td style={{ background: '#dfe0df', padding: 5 }}>Total</td>
                  <td style={{ padding: 5, textAlign: 'right' }}>{getCurrencyNumber(Math.round(this.state.total))}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PaymentTotals;
