import React from 'react';
import { Modal, Button } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ChapterOrderName, OrderContainer } from '../../components/chapters/ChapterStyles';

class OrderGraphics extends React.Component {
  state = {
    graphics: []
  }

  componentDidMount() {
    const { initialValues } = this.props;

    this.setState({
      graphics: initialValues ? initialValues : []
    });
  }

  onDragEnd = (data) => {
    let { graphics } = this.state;
    if (data.destination && data.source.index !== data.destination.index) {
      graphics = this.rearrangeArrayElement(graphics, data.source.index, data.destination.index);
      this.setState({ graphics });
    }
  }

  rearrangeArrayElement = (arr, oldIndex, newIndex) => {
    if (
      oldIndex < 0 ||
      oldIndex >= arr.length ||
      newIndex < 0 ||
      newIndex >= arr.length
    ) {
      return;
    }

    // Remove the element at the old index and store it
    const removedElement = arr.splice(oldIndex, 1)[0];

    // Insert the removed element at the new index
    arr.splice(newIndex, 0, removedElement);

    return arr;
  }

  render() {
    const { open, initialValues } = this.props;
    const { closeModal, onSubmit, loading } = this.props;
    const { graphics } = this.state;

    return (
      <Modal
        visible={open}
        title="Editar ordem dos Grafismos"
        maskClosable={false}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={() => onSubmit(graphics)}>
            Gravar
          </Button>
        ]}>
        <OrderContainer>
          <DragDropContext onDragEnd={data => this.onDragEnd(data)}>
            <Droppable droppableId="graphics">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {graphics.map((graphic, index) => (
                    <Draggable key={graphic._id} draggableId={graphic._id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ChapterOrderName>
                            {graphic.name}
                          </ChapterOrderName>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </OrderContainer>
      </Modal>
    );
  };
};


export default OrderGraphics;