import React from 'react';
import PropTypes from 'prop-types';
import {InputDiv, InputLabelDiv, StyledCascadeInput} from './InputStyles';
import {GetQuestionOptions} from '../../infra/services/questions/QuestionTypes';

const SelectQuestion = ({input, meta, placeholder, label, disabled, first, execAfterChange}) => {
  const {invalid, submitFailed} = meta;
  const showError = invalid && submitFailed;

  const data = GetQuestionOptions();

  const formatValue = value => {
    const result = [];
    data.forEach(option => {
      if (option.value === value) {
        result.push(value);
      } else if (option.children) {
        option.children.forEach(child => {
          if (child.value === value) {
            result.push(option.value);
            result.push(child.value);
          }
        });
      }
    });
    return result;
  };

  const changeSelect = async value => {
    const realValue = value && Array.isArray(value) ? value.slice(-1)[0] : '';
    input.onChange(realValue);

    if(typeof execAfterChange === "function") {
      await execAfterChange(realValue);
    }
  };

  return (
    <InputDiv first={first}>
      <InputLabelDiv>{label}</InputLabelDiv>
      <StyledCascadeInput
        disabled={disabled}
        placeholder={placeholder}
        onChange={changeSelect}
        value={formatValue(input?.value)}
        showSearch={false}
        options={data}
        error={showError ? 1 : 0}
      />
    </InputDiv>
  );
};

SelectQuestion.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default SelectQuestion;
