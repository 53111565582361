import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Icon, Row, Col, Steps, TimePicker, Checkbox } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import FormValidator from '../../infra/services/validations/FormValidator';
import { BaseForm, SpinLoading } from '../../styles/BasicStyles';
import PropTypes from 'prop-types';
import TextAreaInput from '../../components/inputs/TextAreaInput';

const validations = (values) => {
    let errors = {};

    errors = FormValidator.make({
        names: 'required',
    })(values);

    return errors;
};

let ImportGuestModal = ({
    loading,
    isOpen,
    handleSubmit,
    initialValues,
    onSubmit,
    onClose,
    importGuestForm
}) => {
    return (
        <Modal
            visible={isOpen}
            title={'Importar Convidados'}
            maskClosable={true}
            onCancel={onClose}
            footer={<React.Fragment>
                <Button
                    key='cancel'
                    type='default'
                    onClick={onClose}>
                    Cancelar
                </Button>
                <Button
                    key='submit'
                    type='primary'
                    loading={loading}
                    onClick={handleSubmit(onSubmit)}>
                    Gravar
                </Button>
            </React.Fragment>}>

            <BaseForm onSubmit={handleSubmit(onSubmit)}>
                <Field
                    component={TextAreaInput}
                    // label='Convidados *'
                    noLabel={true}
                    name={`names`}
                    type="text"
                    minRows={5}
                />
            </BaseForm>
        </Modal>
    );
};

ImportGuestModal = reduxForm({
    form: 'importGuestForm',
    enableReinitialize: true,
    validate: validations,
})(ImportGuestModal);

const selector = formValueSelector('importGuestForm');

const mapStateToProps = state => ({
    importGuestForm: {
        names: selector(state, 'names'),
        code: selector(state, 'code'),
    }
});

ImportGuestModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(ImportGuestModal)