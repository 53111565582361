const sectionID = [
  {
    id: '5e05fabd416c7e482f51f6b8',
    name: 'Sobre Nós'
  },
  {
    id: '5e0621b747f1554b3dd6d417',
    name: 'O nosso estilo'
  },
  {
    id: '5e06224c47f1554b3dd6d418',
    name: 'O nosso dia'
  },
  {
    id: '5e061eac47f1554b3dd6d416',
    name: 'Cerimónia'
  },
  {
    id: '5e0a010b377c160945973e86',
    name: 'Staff'
  },
  {
    id: '5e0a0111377c160945973e87',
    name: 'Convidados'
  },
  {
    id: '5e0a0115377c160945973e88',
    name: 'Food selection'
  },
  {
    id: '5e0a0119377c160945973e89',
    name: 'Drink selection'
  },
  {
    id: '5e0a011e377c160945973e8a',
    name: 'Decoração'
  },
  {
    id: '5e0a0124377c160945973e8b',
    name: 'Party selection'
  }
];

export default sectionID;