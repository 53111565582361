import client from '../config/AxiosConfig';

export const CreateSubcategory = async (data) =>
  client.put(`/employee_subcategories/create`, data);

export const GetSubcategory = async id =>
  client.get(`/employee_subcategories/find/${id}`);

export const GetSubcategories = async () =>
  client.get(`/employee_subcategories/findAll`);

export const GetSubcategoriesByCategory = async (id) =>
  client.get(`/employee_subcategories/findByCategory/${id}`);

export const GetSubcategoriesByType = async (type) =>
  client.get(`/employee_subcategories/findByType/${type}`);

export const GetSubcategoriesInCharge = async () =>
  client.get(`/employee_subcategories/subcategoriesInCharge`);

export const UpdateSubcategory = async (data) =>
  client.post(`/employee_subcategories/update`, data);

export const DeleteSubcategory = async id =>
  client.delete(`/employee_subcategories/remove/${id}`);