import React, { Fragment } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { notification, Icon } from 'antd';
import Dropzone from 'react-dropzone';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import { ResponsePill, ExtraPill, ResponseSection } from '../SectionStyles';
import { FileSection, ResponseFileSection } from './ImageCard';

const { REACT_APP_IMAGES_URL } = process.env;

const FileCard = ({ input, name }) => {
  const openFile = id => window.open(`${REACT_APP_IMAGES_URL}${id}`, '_blank');

  const buildImageObject = blob => ({
    preview: URL.createObjectURL(blob),
    size: blob.size,
    type: blob.type,
    blob: blob
  });

  const handleDrop = (accepted, rejected) => {
    if (accepted.length <= 0 || (rejected && rejected.length > 0)) {
      return notification.error({
        message: 'Ficheiro Inválido',
        description: 'O ficheiro é inválido ou tem um tamanho superior a 6Mb'
      });
    }

    const uploaded = buildImageObject(accepted[0]);
    input.onChange(uploaded);
  };

  const renderDropZone = () => {
    return (
      <Dropzone
        multiple={false}
        onDrop={handleDrop}
        accept=""
        maxSize={60000000}>
        {({ getRootProps, getInputProps }) => {
          return (
            <FileSection {...getRootProps()}>
              <input {...getInputProps()} />
              <Icon type="file" />
              Clique ou arraste um ficheiro
            </FileSection>
          );
        }}
      </Dropzone>
    );
  };

  if (input.value) {
    const saved = input.value._id;
    const filename = TranslateValue(
      saved ? input.value.filename : input.value.blob.name
    );
    const size = (
      (saved ? input.value.length : input.value.size) /
      (1024 * 1024)
    ).toFixed(2);

    return (
      <React.Fragment>
        <ResponseFileSection>
          <ResponsePill single={false}>{filename}</ResponsePill>
          <ExtraPill>{size}MB</ExtraPill>
          <ExtraPill last={!saved}>
            {moment(input.value.createdAt).format('DD/MM/YYYY')}
          </ExtraPill>
          {saved && (
            <ExtraPill last link onClick={() => openFile(input.value._id)}>
              Abrir ficheiro
            </ExtraPill>
          )}
        </ResponseFileSection>
        {renderDropZone()}
      </React.Fragment>
    );
  }

  return renderDropZone();
};

export default FileCard;
