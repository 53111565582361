import React from 'react';
import {connect} from 'react-redux';
import {reduxForm, arraySplice, formValueSelector} from 'redux-form';
import {Modal, Button} from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import {OrderContainer, ChapterOrderName} from './ColoursStyles';

let OrderColour = ({
  open,
  loading,
  handleSubmit,
  onSubmit,
  closeModal,
  dispatch,
  colours = []
}) => {
  const onDragEnd = (data) => {
    if(data.destination && data.source.index !== data.destination.index) {
      const [removed] = colours.splice(data.source.index, 1);

      dispatch(
        arraySplice(
          'manage_ordercolours_form',
          'colours',
          data.destination.index,
          0,
          removed
        )
      );
    }
  };

  return (
    <Modal
      visible={open}
      title="Editar ordem das Cores"
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      <OrderContainer>
        <DragDropContext onDragEnd={data => onDragEnd(data)}>
          <Droppable droppableId="colours">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}   
              >
                {colours.map((colour, index) => (
                  <Draggable key={colour._id} draggableId={colour._id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ChapterOrderName>
                          {TranslateValue(colour.name)}
                        </ChapterOrderName>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </OrderContainer>
    </Modal>
  );
};

const selector = formValueSelector('manage_ordercolours_form');

OrderColour = reduxForm({
  form: 'manage_ordercolours_form'
})(OrderColour);

const mapStateToProps = state => ({
  colours: selector(state, 'colours')
});

export default connect(mapStateToProps)(OrderColour);