import React, { Component, Fragment } from 'react';
import { Avatar, Checkbox, Icon, Popconfirm, Tag, Tooltip } from 'antd';
import Table from '../../components/table/Table';
import { PageContainer, PageTitle, HeaderContainer, HeaderTitle, HeaderButtonsContainer, TableButton } from '../../styles/BasicStyles';
import { GetWeddings, TogglePublic, DeleteWedding, GetWeddingsByUser, GetAllWeddingsByUser } from '../../infra/requests/WeddingRequests';
import { ArchiveWedding } from '../../infra/requests/ArchivedRequests';
import BaseButton from '../../components/buttons/BaseButton';
import moment from 'moment';
import CurrencyComponent from '../../components/currency/CurrencyComponent';
import { LocalLabel, StateLabel } from '../weddings/Styles';
import { connect } from 'react-redux';
import { ClearWedding } from '../../redux/wedding/wedding.actions';
import { bindActionCreators } from 'redux';
import { GetTeamBookingByWedding } from '../../infra/requests/TeamBookingRequests';
import { getInitials, getLocal, getLocalColor, getScheduler, getWeddingName, getWeddingStatus, getWeddingTeamAndEmployeesInCharge } from '../../infra/services/wedding/weddingUtils';
import { getUserId, isCurrentUserAdmin, isCurrentUserChief, isCurrentUserEmployee, isCurrentUserEmployeeAttending, isCurrentUserHumanResources, isEmployeeOrChief } from '../../infra/helpers/Helpers';
import EmployeeWeddingsFilters from '../weddings/EmployeeWeddingsFilters';
import { GetOurDayByWedding } from '../../infra/requests/OurDayRequests';
import { GetColorSystemByType } from '../../infra/requests/ColorSystemRequests';
import { ConfirmWeddingTeamByemployee, GetWeddingTeamByWedding } from '../../infra/requests/WeddingTeamRequests';
import { GetSubcategories, GetSubcategoriesByType } from '../../infra/requests/SubcategoryRequests';
import { GetEmployeeByUser } from '../../infra/requests/EmployeeRequests';
import { GetTextColor } from '../../infra/services/text/textUtils';
import { WeddingStatus } from '../reserves/ReserveAgenda';
import OrdinalNumberComponent from '../../components/ordinalNumber/OrdinalNumberComponent';
import { DateColumn } from '../reserves/ReservesPage';
import { GetReservesPaginated, GetReservesPaginatedAndGrouped } from '../../infra/requests/ReserveRequests';
import EmployeeProfileReserveFilters from './EmployeeProfileReserveFilters';
import { getReserveStatus } from '../reserves/reservesUtils';

class EmployeeProfileReserves extends Component {
    state = {
        pageSize: 30,
        currentPage: 1,
        loading: false,
        filters: {
            month: moment.utc().format('MM/YYYY')
        },
        employee: null,

        columnsAttending: [
            {
                title: 'Data',
                width: '80px',
                render: data => {
                    return data?.date
                        ? <DateColumn>
                            <span className='date'>{moment.utc(data.date).format('DD-MM-YYYY')}</span>
                            <span className='weekdayName'>{moment.utc(data.date).locale('pt').format('dddd')}</span>
                        </DateColumn>
                        : '';
                }
            },
            {
                title: 'Local',
                width: '100px',
                render: data => {
                    const local = getLocalColor(data, this.state.colors);
                    return local
                        ? <LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>
                        : null
                }
            },
            {
                title: 'Noivos',
                width: '100px',
                render: data => getWeddingName(data.wedding)
            },
            {
                title: 'Estado',
                width: '85px',
                render: data => {
                    return getReserveStatus(data?.status);
                }
            },
            {
                title: 'Data Ativação',
                width: '85px',
                render: data => {
                    return data?.createdAt
                        ? moment(data?.createdAt).format('DD-MM-YYYY')
                        : '';
                }
            },
            // {
            //     title: 'Número Previsto de Convidados',
            //     width: '85px',
            //     className: 'align-right',
            //     render: data => {
            //         return data?.wedding?.expectedMinPeople;
            //     }
            // },
            {
                title: 'Espaço Visitado',
                width: '55px',
                className: 'align-center',
                render: data => {
                    return <Tag color={data?.wedding?.visitedPlace ? 'green' : 'red'}>{data?.wedding?.visitedPlace ? 'Sim' : 'Não'}</Tag>;
                }
            },
            {
                title: 'Total SLE',
                width: '40px',
                // className: 'align-right',
                render: data => {
                    return Array.isArray(data?.reserves)
                        ? data?.reserves?.filter(f => f.weddingPlace === 1).length
                        : 0;
                }
            },
            {
                title: 'Total QLC',
                width: '40px',
                // className: 'align-right',
                render: data => {
                    return Array.isArray(data?.reserves)
                        ? data?.reserves?.filter(f => f.weddingPlace === 2).length
                        : 0;
                }
            },
        ],
        columns: [
            {
                title: 'Data',
                width: '80px',
                render: data => {
                    return data?.date
                        ? <DateColumn>
                            <span className='date'>{moment.utc(data.date).format('DD-MM-YYYY')}</span>
                            <span className='weekdayName'>{moment.utc(data.date).locale('pt').format('dddd')}</span>
                        </DateColumn>
                        : '';
                }
            },
            {
                title: 'Local',
                width: '100px',
                render: data => {
                    const local = getLocalColor(data, this.state.colors);
                    return local
                        ? <LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>
                        : null
                }
            },
            {
                title: 'Noivos',
                width: '100px',
                render: data => getWeddingName(data.wedding)
            },
            {
                title: 'Posição',
                width: '30px',
                render: data => {
                    return data?.order
                        ? <OrdinalNumberComponent value={data?.order} />
                        : '';
                }
            },
            {
                title: 'Estado',
                width: '55px',
                render: data => {
                    return getReserveStatus(data?.status);
                    // return getWeddingStatus(data?.wedding);
                }
            },
            {
                title: 'Data Ativação',
                width: '85px',
                render: data => {
                    return data?.wedding?.createdAt
                        ? moment(data?.wedding?.createdAt).format('DD-MM-YYYY')
                        : '';
                }
            },
            {
                title: 'Ativado por',
                width: '50px',
                render: data => {
                    return data?.user
                        ? <Tooltip title={data?.user?.name}>
                            <Avatar>{getInitials(data?.user?.name)}</Avatar>
                        </Tooltip>
                        : null
                }
            },
            {
                title: 'Preço p/PAX',
                width: '70px',
                className: 'align-right',
                render: data => {
                    return <CurrencyComponent value={data?.priceObj ? data?.priceObj?.minPrice : data?.price ? data?.price?.minPrice : 0} />
                }
            },
            {
                title: 'PAX Mínimo',
                width: '50px',
                className: 'align-right',
                render: data => {
                    return data?.priceObj
                        ? data?.priceObj?.minPax
                        : data?.price
                            ? data?.price?.minPax
                            : 0;
                }
            },
            {
                title: 'Espaço Visitado',
                width: '55px',
                render: data => {
                    return <Tag color={data?.wedding?.visitedPlace ? 'green' : 'red'}>{data?.wedding?.visitedPlace ? 'Sim' : 'Não'}</Tag>
                }
            },
            {
                title: 'Total SLE',
                width: '40px',
                // className: 'align-right',
                render: data => {
                    return Array.isArray(data?.reserves)
                        ? data.reserves?.filter(f => f.weddingPlace === 1)?.length
                        : 0;
                }
            },
            {
                title: 'Total QLC',
                width: '40px',
                // className: 'align-right',
                render: data => {
                    return Array.isArray(data?.reserves)
                        ? data.reserves?.filter(f => f.weddingPlace === 2)?.length
                        : 0;
                }
            },
            {
                title: 'Data Confirmação',
                width: '85px',
                render: data => {
                    return data?.confirmationDate
                        ? moment(data?.confirmationDate).format('DD-MM-YYYY')
                        : ''
                }
            },
            // {
            //     title: 'Data Contrato',
            //     width: '85px',
            //     render: data => {
            //         return data?.wedding?.contractDate
            //             ? moment(data?.wedding?.contractDate).format('DD-MM-YYYY')
            //             : '';
            //     }
            // },
            // {
            //     title: 'Pago',
            //     width: '80px',
            //     className: 'align-right',
            //     render: data => {
            //         return <React.Fragment>
            //             <CurrencyComponent value={data?.budget?.payed} />
            //             <div style={{ marginTop: 5 }}>
            //                 <Tag color={GetTagPaymentColor(data.lastPayment, data.wedding, data.budget)} key={data.id}>
            //                     {(data?.budget?.payedPercentage).toFixed()} %
            //                 </Tag>
            //             </div>
            //         </React.Fragment>;
            //     }
            // },
        ],
        // columnsDetail: [
        //     {
        //         title: 'Posição da Reserva',
        //         width: '30px',
        //         render: data => {
        //             return <OrdinalNumberComponent value={data?.order} />
        //         }
        //     },
        //     {
        //         title: 'Dia da Semana',
        //         width: '40px',
        //         render: data => {
        //             return data?.date ? moment(data.date).locale('pt').format('dddd') : ''
        //         }
        //     },
        //     {
        //         title: 'Data',
        //         width: '85px',
        //         render: data => {
        //             return data?.date ? moment(data.date).format('DD-MM-YYYY') : ''
        //         }
        //     },
        //     {
        //         title: 'Local',
        //         width: '100px',
        //         render: data => {
        //             const local = getLocalColor(data, this.state.colors);
        //             return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
        //         }
        //     },
        //     // {
        //     //     title: 'Preço',
        //     //     width: '80px',
        //     //     className: 'align-right',
        //     //     render: data => {
        //     //         return <React.Fragment>
        //     //             <CurrencyComponent value={data?.priceObj ? data?.priceObj?.minPrice : data?.price ? data?.price?.minPrice : 0} />
        //     //             &nbsp;p/PAX
        //     //         </React.Fragment>
        //     //     }
        //     // },
        //     // {
        //     //     title: 'PAX Mínimo',
        //     //     width: '60px',
        //     //     className: 'align-right',
        //     //     render: data => {
        //     //         return data?.priceObj ? data?.priceObj?.minPax : data?.price ? data?.price?.minPax : 0;
        //     //     }
        //     // },
        // ],
        // columnsDetailConfirmed: [
        //     {
        //         title: 'Dia da Semana',
        //         width: '40px',
        //         render: data => {
        //             return data?.date ? moment(data.date).locale('pt').format('dddd') : ''
        //         }
        //     },
        //     {
        //         title: 'Data',
        //         width: '85px',
        //         render: data => {
        //             return data?.date ? moment(data.date).format('DD-MM-YYYY') : ''
        //         }
        //     },
        //     {
        //         title: 'Local',
        //         width: '100px',
        //         render: data => {
        //             const local = getLocalColor(data, this.state.colors);
        //             return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
        //         }
        //     },
        //     // {
        //     //     title: 'Preço',
        //     //     width: '80px',
        //     //     className: 'align-right',
        //     //     render: data => {
        //     //         return <React.Fragment>
        //     //             <CurrencyComponent value={data?.priceObj ? data?.priceObj?.minPrice : data?.price ? data?.price?.minPrice : 0} />
        //     //             &nbsp;p/PAX
        //     //         </React.Fragment>
        //     //     }
        //     // },
        //     // {
        //     //     title: 'PAX Mínimo',
        //     //     width: '60px',
        //     //     className: 'align-right',
        //     //     render: data => {
        //     //         return data?.priceObj ? data?.priceObj?.minPax : data?.price ? data?.price?.minPax : 0;
        //     //     }
        //     // }
        // ],

        rows: [],
        rowsSearch: null,
        total: 0,
        colors: {
            colorSys1: null,
            colorSys2: null
        },
    };

    componentDidMount = async () => {
        this.setState({ employee: this.props.employee }, () => this.getLocalColors());
    };

    getLocalColors = async () => {
        const resColorSys1 = await GetColorSystemByType(4);
        const colorSys1 = resColorSys1.success && resColorSys1.data ? resColorSys1.data.color : '#f7b5d6';
        const resColorSys2 = await GetColorSystemByType(5);
        const colorSys2 = resColorSys2.success && resColorSys2.data ? resColorSys2.data.color : '#b6d7a9';
        this.setState({ colors: { colorSys1, colorSys2 } }, () => {
            this.updateDataTable();
        });
    }

    updateDataTable = async () => {
        const { currentPage, pageSize, employee } = this.state;
        let { filters } = this.state;

        this.setState({ loading: true });

        try {
            filters['profileEmployeeAttending'] = true;

            if(filters?.month) {
                filters.month = moment(filters.month, ['MM/YYYY']).format('MM/YYYY');
            }

            if (employee?.user?.employeeAttending) {
                filters['attendedBy'] = employee?.user?._id;
            }

            // const result = await GetReservesPaginatedAndGrouped(currentPage, pageSize, JSON.stringify(filters));
            const result = await GetReservesPaginated(currentPage, pageSize, JSON.stringify(filters));
            // console.warn('Res', result);

            this.setState({
                rows: result.data.items,
                total: result.data.total,
                //currentPage: result.data.page,
                pageSize: result.data.limit,
                loading: false
            });
        } catch (e) {
            this.setState({ loading: false });
            console.error(e);
        }
    };

    handleChangePage = currentPage => {
        this.setState({ currentPage }, this.updateDataTable);
    };

    handleChangeRowsPerPage = (currentSize, pageSize) => {
        this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
    };

    handleFilterChange = filters => {
        this.setState({ filters, currentPage: 1 }, this.updateDataTable);
    };

    getConfirmedExpandedRow = (record) => {
        const local = record.wedding.wedding_place === 2 ? 'Quinta Lago dos Cisnes' : 'Solar da Levada';
        return <p>Reserva para a data {moment(record.wedding.date).format('DD-MM-YYYY')}
            ({moment(record.wedding.date).locale('pt').format('dddd')}) no/a {local}.
        </p>
    }

    openProcess = record => {
        const { history } = this.props;

        if (isCurrentUserAdmin() && record?.wedding?.status !== WeddingStatus.RESERVE) {
            //Clear previous saved Wedding
            ClearWedding();
            history.push(`/wedding/${record?.wedding?._id}?page=R`);
        } else return;
    };

    render() {
        const { history } = this.props;
        const { loading, currentPage, pageSize } = this.state;
        const { columns, columnsDetailConfirmed, columnsDetail } = this.state;
        const { columnsAttending, columnsAttendingDetail } = this.state;
        const { rows, total } = this.state;
        const { employee } = this.state;

        return (
            <React.Fragment>
                <EmployeeProfileReserveFilters queryChange={this.handleFilterChange} />
                <div className='table-responsive'>
                    <Table
                        columns={isCurrentUserEmployeeAttending() ? columnsAttending : columns}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        defaultPageSize={pageSize}
                        loading={loading}
                        rows={rows}
                        showHeader={true}
                        emptyIcon="calendar"
                        emptyText={'Não existem reservas'}
                        total={total}
                        rowKey={'_id'}
                        // expandedRowRender={(data) => {
                        //     const cols = isCurrentUserEmployeeAttending()
                        //         ? columnsAttendingDetail
                        //         : data.wedding.status !== WeddingStatus.RESERVE
                        //             ? columnsDetailConfirmed
                        //             : columnsDetail;

                        //     return data.wedding.status !== WeddingStatus.RESERVE
                        //         ? this.getConfirmedExpandedRow(data)
                        //         : <Table
                        //             columns={cols}
                        //             loading={loading}
                        //             rows={data.reserves}
                        //             showHeader={true}
                        //             emptyIcon="calendar"
                        //             emptyText={'Não existem reservas'}
                        //             rowKey={'_id'}
                        //             hasPagination={false}
                        //             secondary={true}
                        //         />
                        // }}
                        hasPagination={true}
                        onPressRow={this.openProcess}
                        handleChangePage={this.handleChangePage}
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({ wedding: state.wedding });

const mapActionToProps = dispatch => bindActionCreators({ ClearWedding }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(EmployeeProfileReserves);