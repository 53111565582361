import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../components/inputs/TextInput';
import FormValidator from '../../infra/services/validations/FormValidator';
import BaseButton from '../../components/buttons/BaseButton';
import Logo from '../../assets/icons/logo.svg';
import {
  LoginContainer,
  LoginCard,
  LoginForm,
  LogoImg,
  LogoSection,
  LoginButtonDiv,
  FormTitle
} from './LoginPageStyles';
import { Icon, notification } from 'antd';
import { ResetPassword } from '../../infra/requests/AuthRequests';
import queryString from 'query-string';

const ValidateResetForm = values => {
  const errors = {};
  errors.password = FormValidator.validateField(
    'password',
    values.password,
    'required|min:6'
  );
  errors.passwordconfirm = FormValidator.validateField(
    'passwordconfirm',
    values.passwordconfirm,
    'required|min:6'
  );
  if (values.password !== values.passwordconfirm) {
    errors.passwordconfirm = new Error('Palavra-passe inválida');
  }
  return errors;
};

class ResetPasswordPage extends Component {
  onSubmit = async values => {
    const {
      location: { search }
    } = this.props;
    const params = queryString.parse(search);
    const newParams = {
      password: values.password,
      token: params.token,
      email: params.email
    };

    try {
      const result = await ResetPassword(newParams);
      if (result && result.success) {
        notification.success({
          message: 'Palavra-passe atualizada',
          description:
            'Já pode entrar na sua conta com a palavra-passe que definiu.'
        });

        this.props.history.push('/login');
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit(this.onSubmit)}>
          <LoginCard>
            <LogoSection>
              <LogoImg src={Logo} alt="logo" />
            </LogoSection>
            <FormTitle>Defina a Palavra-passe</FormTitle>
            <Field
              component={TextInput}
              name="password"
              type="password"
              label="Nova Palavra-passe"
              placeholder="Insira uma nova palavra-passe"
              prefix={<Icon type="lock" />}
            />
            <Field
              component={TextInput}
              name="passwordconfirm"
              type="password"
              label="Confirme a Palavra-passe"
              placeholder="Deve ser igual à palavra-passe anterior"
              prefix={<Icon type="lock" />}
            />
            <div style={{ marginTop: 5 }}>
              <Icon type='warning'></Icon>
              &nbsp; No mínimo 6 caracteres
            </div>

            <LoginButtonDiv>
              <BaseButton
                variant="raised"
                htmlType="submit"
                type="primary"
                loading={submitting}
                text="Atualizar Palavra-passe"
              />
            </LoginButtonDiv>
          </LoginCard>
        </LoginForm>
      </LoginContainer>
    );
  }
}

export default reduxForm({
  form: 'reset_password',
  validate: ValidateResetForm
})(ResetPasswordPage);
