import React from "react";
import BaseButton from "../../components/buttons/BaseButton";
import Logo from "../../assets/icons/logo_white.svg";
import {
  StyledNotFoundContainer,
  StyledTitle,
  StyledDescription,
  StyledCompanyLogo,
  StyledNotFoundGroup,
  StyledRightContainer,
  StyledLeftContainer
} from "./NotFoundStyles";
import { isCurrentUserChief, isCurrentUserEmployeeAttending, isCurrentUserHumanResources, isEmployeeOrChief } from "../../infra/helpers/Helpers"

const NotAuthorized = ({ history }) => (
  <StyledNotFoundContainer>
    <StyledNotFoundGroup>
      <StyledLeftContainer>
        <StyledCompanyLogo src={Logo} />
      </StyledLeftContainer>
      <StyledRightContainer>
        <StyledTitle>Não autorizado</StyledTitle>
        <StyledDescription>
          Não está autorizado a ver a página solicitada. Para mais informações, entre em contacto com o administrador do sistema.
        </StyledDescription>
        <BaseButton
          disabled={false}
          loading={false}
          text={"Voltar ao início"}
          onClick={() => {
            if (isCurrentUserEmployeeAttending()) {
              history.push('/home-attending');
            } else if (isEmployeeOrChief() || isCurrentUserChief() || isCurrentUserHumanResources()) {
              history.push('/home');
            } else {
              history.push('/');
            };
          }}
          type={"primary"}
          style={{ margin: '0px auto' }}
        />
      </StyledRightContainer>
    </StyledNotFoundGroup>
  </StyledNotFoundContainer>
);

export default NotAuthorized;
