import React from 'react';
import { Row, Col } from 'antd';
import { Field, reduxForm } from 'redux-form';
import { Modal, Button } from 'antd';
import { BaseForm } from '../../styles/BasicStyles';
import TitleInput from '../../components/inputs/TitleInput';
import ImageInput from '../../components/inputs/ImageInput';
import CurrencyInput from '../../components/inputs/CurrencyInput';
import FormValidator from '../../infra/services/validations/FormValidator';

const validations = FormValidator.make({
  name: 'required|languages',
  icon: 'required'
});

let NewUpgradeModal = ({
  open,
  loading,
  handleSubmit,
  onSubmit,
  closeModal,
  questionType
}) => {
  return (
    <Modal
      visible={open}
      title="Novo Upgrade"
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      <BaseForm onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={8} offset={8}>
            <Field name="icon" label="Icon" component={ImageInput} ratio={1} />
          </Col>
          <Col xs={24}>
            <Field
              component={TitleInput}
              name={'name'}
              type="text"
              label={'Nome'}
              placeholder={'Nome do upgrade'}
            />
          </Col>
          <Col xs={24}>
            <Field
              component={CurrencyInput}
              name={'price'}
              label={'Preço'}
              placeholder={'Preço do upgrade'}
            />
          </Col>
        </Row>
      </BaseForm>
    </Modal>
  );
};

NewUpgradeModal = reduxForm({
  form: 'manage_new_upgrade_form',
  validate: validations
})(NewUpgradeModal);

export default NewUpgradeModal;
