const Names = {
  ABOUT_US: 'Sobre Nós',
  STYLE: 'O nosso estilo',
  DAY: 'O nosso dia',
  CEREMONY: 'Cerimónia',
  STAFF: 'Staff',
  GUESTS: 'Convidados',
  FOOD: 'Food selection',
  DRINKS: 'Drink selection',
  DECORATION: 'Decoração',
  PARTY: 'Party selection',
  UPGRADES: 'Upgrades'
};

export default Names;
