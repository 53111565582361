import React from 'react';
import { Modal, Button } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ChapterOrderName, OrderContainer } from '../../components/chapters/ChapterStyles';
import { GetAttendingList } from '../../infra/requests/UsersRequests';
import { BaseForm, SpinLoading } from '../../styles/BasicStyles';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector, change } from 'redux-form';
import FormValidator from '../../infra/services/validations/FormValidator';
import SelectInput from '../../components/inputs/SelectInput';
import PropTypes from 'prop-types';
import { getUserLabel } from '../../infra/services/wedding/weddingUtils';

const validations = (values) => {
  let errors = {};

  errors = FormValidator.make({
    user: 'required',
  })(values);

  return errors;
};

class CreateOrderAttending extends React.Component {
  state = {
    loading: false,
    users: []
  }

  componentDidMount() {
    this.getAttendingUsers();
  }

  getAttendingUsers = async () => {
    this.setState({ loading: true });

    const result = await GetAttendingList({});
    let data = result?.success && result?.data ? result.data : [];
    if (data?.length > 0) data = data.filter(f => f?.attendingOrder == null || f?.attendingOrder == undefined);

    this.setState({
      loading: false,
      users: data
    });
  }

  render() {
    const { open } = this.props;
    const { closeModal, onSubmit, handleSubmit } = this.props;
    const { users, loading } = this.state;

    return (
      <Modal
        visible={open}
        title="Adicionar Colaborador"
        maskClosable={false}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={handleSubmit(onSubmit)}>
            Gravar
          </Button>
        ]}>
        {loading ? <SpinLoading />
          : <BaseForm onSubmit={handleSubmit(onSubmit)} >
            <Field
              component={SelectInput}
              name={'user'}
              label={'Selecione o colaborador'}
              costumeLabel={getUserLabel}
              data={users}
            />
          </BaseForm>}
      </Modal>
    );
  };
};

CreateOrderAttending = reduxForm({
  form: 'order_form',
  enableReinitialize: true,
  validate: validations,
})(CreateOrderAttending);

CreateOrderAttending.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default connect()(CreateOrderAttending);