import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Row, Col} from 'antd';
import {TableFilterSection} from '../../styles/BasicStyles';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';

class IngredientsFilters extends Component {
  state = {
    search: '',
    supplier_category: ''
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({[field]: value}, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const {queryChange} = this.props;
    const query = {};
    Object.keys(this.state).map(key => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const {supplierCategories} = this.props;
    const {search, supplier_category} = this.state;
    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={6}>
            <TextInput
              input={{
                value: search,
                onChange: event =>
                  this.onInputChange('search', event.target.value, 1000)
              }}
              meta={{valid: true}}
              type="text"
              label="Pesquisar"
              placeholder="Procurar por nome"
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label="Categoria de Encomenda"
              allowClear
              placeholder="Escolher opção"
              data={supplierCategories}
              input={{
                value: supplier_category,
                onChange: value => this.onInputChange('supplier_category', value)
              }}
              meta={{valid: true}}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

const mapStateToProps = state => ({
  locals: state.locals
});

export default connect(mapStateToProps)(IngredientsFilters);