import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { Select, Spin } from 'antd';
import { InputDiv, InputLabelDiv, StyledSelectInput } from './InputStyles';
import CurrencyComponent from '../currency/CurrencyComponent';
const Option = Select.Option;

const filterOption = (inputValue, option) => {
  if (option?.props?.children && option?.props?.children.toLowerCase)
    return (
      option?.props?.children.toLowerCase().indexOf(inputValue.toLowerCase()) >
      -1
    );
  return option.key.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
};

const SelectSidedisheInput = ({
  input,
  data,
  dataKey,
  dataLabel,
  costLabel,
  placeholder,
  mode,
  notFoundMessage,
  fetching,
  label,
  disabled,
  fetchData,
  loading,
  first,
  meta,
  translatable,
  activeLanguage,
  allowClear,
  costumeLabel,
  execAfterChange
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  const formatValue = value => {
    if (!value) return undefined;
    if (Array.isArray(value)) return value;
    return value.toString();
  };

  const changeSelect = async value => {
    input.onChange(value || '');
    if (typeof execAfterChange === "function") {
      await execAfterChange(value);
    }
  };

  return (
    <InputDiv first={first || !label}>
      {label && <InputLabelDiv>{label}</InputLabelDiv>}
      <StyledSelectInput
        disabled={disabled}
        mode={mode}
        placeholder={placeholder}
        notFoundContent={fetching ? <Spin size="small" /> : notFoundMessage}
        filterOption={filterOption}
        allowClear={allowClear}
        onSearch={fetchData}
        onChange={changeSelect}
        value={formatValue(input?.value)}
        showArrow={true}
        showSearch={true}
        loading={loading}
        error={showError ? 1 : 0}>
        {data.map(current => (
          <Option key={current[dataKey]} disabled={current.disabled}>
            {costumeLabel
              ? costumeLabel(current)
              : translatable
                ? current[dataLabel][activeLanguage.code]
                : current[dataLabel]}
            {current[costLabel] && <span>&nbsp;(<CurrencyComponent value={current[costLabel]} />)</span>}
          </Option>
        ))}
      </StyledSelectInput>
    </InputDiv>
  );
};

SelectSidedisheInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  costLabel: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  onPressEnter: PropTypes.func,
  allowClear: PropTypes.bool
};

SelectSidedisheInput.defaultProps = {
  data: [],
  allowClear: false,
  dataKey: '_id',
  dataLabel: 'name',
  costLabel: 'extra_cost'
};

export default withLocalize(SelectSidedisheInput);
