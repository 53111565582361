import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {Modal, Button} from 'antd';
import {BaseForm} from '../../../styles/BasicStyles';
import {ResponseContainer} from '../../ChoicesPage/SectionStyles';
import ManageUpgradeQuestion from './ManageUpgradeQuestion';

let UpgradeModalComponent = ({
  open,
  loading,
  handleSubmit,
  onSubmit,
  closeModal,
  upgradeList,
  upgrade
}) => {
  return (
    <Modal
      visible={open}
      title="Selecionar Upgrade"
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      <BaseForm onSubmit={handleSubmit(onSubmit)}>
        <ResponseContainer>
          <Field
            name="upgrade"
            component={ManageUpgradeQuestion}
            upgradeList={upgradeList}
          />
        </ResponseContainer>
      </BaseForm>
    </Modal>
  );
};

UpgradeModalComponent = reduxForm({
  form: 'upgrade_form'
})(UpgradeModalComponent);

export default UpgradeModalComponent;
