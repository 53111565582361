import React from 'react';
import moment from 'moment';
import 'moment/locale/pt';

const LogsDetail = ({ data }) => {
  return (
    <React.Fragment>
      <div style={{ marginBottom: '10px', fontSize: '15px' }}>
        Data: <strong>{moment(data.createdAt).format('DD/MM/YYYY, HH:mm:ss')}</strong>
      </div>
      <div style={{ marginBottom: '10px', fontSize: '15px' }}>
        Utilizador: <strong>{data?.user}</strong>
      </div>
      <div style={{ marginBottom: '10px', fontSize: '15px' }}>
        Secção: <strong>{data?.section || '-'}</strong>
      </div>
      <div style={{ marginBottom: '10px', fontSize: '15px' }}>
        Capítulo: <strong>{data?.chapter || '-'}</strong>
      </div>
      <div style={{ marginBottom: '10px', fontSize: '15px' }}>
        Pergunta: <strong>{data?.question || '-'}</strong>
      </div>
      <div style={{ marginBottom: '10px', fontSize: '15px' }}>
        Descrição: <strong><span dangerouslySetInnerHTML={{__html: data?.description}}/></strong>
      </div>
      {
        data?.file &&
        <div style={{ marginBottom: '10px', fontSize: '15px' }}>
          Ficheiro: <strong><a href={`${process.env.REACT_APP_IMAGES_URL}${data?.file}`} target='_blank' rel="noopener noreferrer" style={{ color: '#0652DD' }}>Visualizar</a></strong>
        </div>
      }
      {
        data?.image &&
        <div style={{ marginBottom: '10px', fontSize: '15px' }}>
          Imagem: <strong><a href={data.image} target='_blank' rel="noopener noreferrer" style={{ color: '#0652DD' }}>Visualizar</a></strong>
        </div>
      }
    </React.Fragment>
  );
};

export default LogsDetail;