import client from "../config/AxiosConfig";

export const GetAdmin = async id =>
  client.get(`/management/${id}`);

export const GetAdmins = async (currentPage, limit) =>
  client.get(`/management/${currentPage}/${limit}`);

export const UpdateAdmin = async (id, data) =>
  client.put(`/management/${id}`, data);

export const CreateAdmin = async data =>
  client.post(`/management`, data);

export const RevokeAccess = async id =>
  client.delete(`/management/${id}`);

export const getOrganizers = async () =>
  client.get("/management/organizers");

export const GetOrganizer = async id =>
  client.get(`/management/organizer/${id}`);

export const ResendEmailAdmin = async (id) =>
  client.put(`/management/${id}/resend`);