import React, { useState, useEffect, useRef } from 'react';
import { PageTitle, HeaderContainer, HeaderButtonsContainer, HeaderTitle, BaseForm, FormContainer } from '../../styles/BasicStyles';
import { Row, Col, Avatar, Breadcrumb, DatePicker, Checkbox, Icon } from 'antd';
import { GetSubcategories } from '../../infra/requests/SubcategoryRequests';
import BaseButton from '../../components/buttons/BaseButton';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import NoAvatar from '../../assets/no-avatar.png';
import { Field, reduxForm, initialize, formValues } from 'redux-form';
import FormValidator from '../../infra/services/validations/FormValidator';
import { Tabs } from 'antd';
import { CreateEmployee, GetEmployee } from '../../infra/requests/EmployeeRequests';
import moment from 'moment';
import ModalPDF from './ModalPDF';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { GetSettingsByType } from '../../infra/requests/SettingRequests'
import EmployeePrice from './EmployeePrice';
import { withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import Alert from "../../components/alert/Alert";
import { CreatePrice } from '../../infra/requests/PriceRequests';
import { CreateEmployeePrice, DeleteEmployeePrice, UpdateEmployeePrice } from '../../infra/requests/EmployeePriceRequests';
import { CreateUser, DeleteUser } from '../../infra/requests/UsersRequests';
import { v4 as uuidv4 } from 'uuid';
import { getUserId } from '../../infra/helpers/Helpers';
import CheckboxInput from '../../components/inputs/CheckboxInput';

const validations = values => {
    let errors = {};

    errors = FormValidator.make({
        name: 'required',
        email: 'required|email',
        notificationEmail: 'required|email',
    })(values);

    return errors;
};

// main component
let AddEmployee = (props): React$Element<React$FragmentType> => {

    const employeeForm = useSelector(state => {
        return state.form.employee_form?.values
    });

    const [loading, setLoading] = useState(true);
    //const [separadorAtual, setSeparadorAtual] = useState(1);

    const [employee, setEmployee] = useState(null);
    const [selectedFavoriteSubcategory, setSelectedFavoriteSubcategory] = useState([]);
    const [startDate, setStartDate] = useState(parseInt(moment().format('YYYY')));
    const [fixedEmployee, setFixedEmployee] = useState(false);
    const [picture, setPicture] = useState('');
    const [pictureName, setPictureName] = useState('');
    const [insurance, setInsurance] = useState(false);
    const [insuranceValidity, setInsuranceValidity] = useState(null);
    const [insuranceFile, setInsuranceFile] = useState('');
    const [insuranceFileName, setInsuranceFileName] = useState('');
    const [entities, setEntities] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [invoicingTypes, setInvoicingTypes] = useState([]);
    const [selectedInvoicingType, setSelectedInvoicingType] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [selectedLanguages, setSelectedLanguages] = useState([]);

    const {
        dispatch,
        handleSubmit,
        history
    } = props;

    useEffect(() => {
        getEmployee();
        // obterSubcategoriasPreferidas();
        // getEntities();
        // getInvoicingTypes();
        // getPaymentMethods();
    }, [])

    const getEmployee = async () => {
        const employeeId = props.match.params.id;
        if (employeeId) {
            const result = await GetEmployee(employeeId);
            setEmployee(result.data);
            setPicture(result.data.picture);
            setPictureName(result.data.pictureName);
            setFixedEmployee(result.data.fixedEmployee);
            setInsurance(result.data.insurance);
            setInsuranceFile(result.data.insuranceFile);
            setInsuranceFileName(result.data.insuranceFileName);
            if (result.data.entity) setSelectedEntity(result.data.entity.id);
            if (result.data.invoicingType) setSelectedInvoicingType(result.data.invoicingType.id);
            if (result.data.paymentMethod) setSelectedPaymentMethod(result.data.paymentMethod.id);
            setSelectedLanguages(result.data.languages)
            setSelectedFavoriteSubcategory(result.data.favoriteSubcategories.map(x => x.id))

            dispatch(
                initialize('employee_form', {
                    ...result.data
                })
            );

            setLoading(false);
        } else {
            setLoading(true);
        }
    }

    const onSubmit = async (values) => {

        const user = {
            name: values.name,
            email: values.email,
            notificationEmail: values.notificationEmail,
            // password: '#15678_employee&xtgo$2022',
            admin: false,
            employee: true,
            chief: false,
            humanResources: false,
            employeeAttending: values.employeeAttending,
            employeeTrainee: values.employeeTrainee,
            organizer: false,
            createPassword_token: uuidv4()
        };

        let resultEmployee;
        const resultUser = await CreateUser(user);
        if (!resultUser.success) console.error(resultUser.message);
        else {
            const employeeValues = {
                favoriteSubcategories: selectedFavoriteSubcategory,
                name: values.name,
                email: values.email,
                notificationEmail: values.notificationEmail,
                address: values.address,
                zipCode: values.zipCode,
                locality: values.locality,
                citizenNumber: values.citizenNumber,
                phoneNumber: null,
                emergencyNumber: values.emergencyNumber,
                mobileNumber: values.mobileNumber,
                startDate: startDate,
                fixedEmployee: fixedEmployee,
                insurance: insurance,
                insuranceValidity: insuranceValidity,
                insuranceFile: insuranceFile,
                insuranceFileName: insuranceFileName,
                insuranceDetail: values.insuranceDetail ? values.insuranceDetail : null,
                iban: values.iban ? values.iban : null,
                nameInvoicing: values.nameInvoicing ? values.nameInvoicing : null,
                nifInvoicing: values.nifInvoicing ? values.nifInvoicing : null,
                invoicingNotes: values.invoicingNotes ? values.invoicingNotes : null,
                picture: picture,
                pictureName: pictureName,
                entity: null,
                invoicingType: null,
                paymentMethod: null,
                user: resultUser.data,
                active: false,
                currentUser: getUserId(),
                languages: selectedLanguages
            };

            resultEmployee = await CreateEmployee(employeeValues);
            if (!resultEmployee.success) {
                console.error(resultEmployee.message);
                const resultDelUser = await DeleteUser(resultUser.data._id);
                if (!resultDelUser.success) console.error(resultDelUser.message);
            }
        }

        //console.warn('Send email to employee to insert a new password')
        if (resultUser?.success && resultEmployee?.success) {
            const message = values?.employeeTrainee
                ? 'Estagiário criado com sucesso!'
                : 'Enviado e-mail para o funcionário!';
            Alert.new({
                type: "success",
                title: "Info!",
                text: message
            });
            history.push('/employees');
        }
    }

    return (
        <React.Fragment>
            <HeaderContainer>
                <HeaderTitle buttons={0}>
                    <PageTitle>Registar Funcionário</PageTitle>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <a href="/employees">Funcionários</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Registar
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderTitle>
                <HeaderButtonsContainer buttons={2}>
                    <BaseButton
                        type="primary"
                        icon="save"
                        text="Gravar"
                        onClick={handleSubmit((values) => onSubmit(values))}
                    />
                    <BaseButton
                        type="default"
                        icon="close"
                        text="Cancelar"
                        onClick={() => history.push('/employees')}
                    />
                </HeaderButtonsContainer>
            </HeaderContainer>
            <FormContainer singleColumn>
                <BaseForm onSubmit={handleSubmit(onSubmit)}>
                    <Field
                        component={TextInput}
                        name="name"
                        label="Nome Completo *"
                        placeholder="Insira o nome"
                        type="text"
                    />

                    <Field
                        component={TextInput}
                        name="email"
                        label="E-mail *"
                        placeholder="Insira o e-mail"
                        type="email"
                    />
                    <Field
                        component={TextInput}
                        name="notificationEmail"
                        label="E-mail para notificações *"
                        placeholder="Insira o e-mail"
                        type="email"
                    />

                    <Field
                        component={CheckboxInput}
                        name="employeeAttending"
                        label="Colaborador de Atendimento"
                        positiveLabel="Sim"
                        onChange={(val) => {
                            if (val?.target?.checked == true) props.change('employeeTrainee', false);
                        }}
                    />

                    <Field
                        component={CheckboxInput}
                        name="employeeTrainee"
                        label="Estagiário"
                        positiveLabel="Sim"
                        onChange={(val) => {
                            if (val?.target?.checked == true) props.change('employeeAttending', false);
                        }}
                    />
                </BaseForm>
            </FormContainer>
        </React.Fragment>
    );
};

AddEmployee = reduxForm({
    form: 'employee_form',
    validate: validations,
})(AddEmployee);

export default withLocalize(AddEmployee);
