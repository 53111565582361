import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Icon, Row, Col, Steps, TimePicker, Checkbox } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import FormValidator from '../../../infra/services/validations/FormValidator';
import { BaseForm, SpinLoading } from '../../../styles/BasicStyles';
import PropTypes from 'prop-types';
import TextInput from '../../../components/inputs/TextInput';
import Alerts from '../../../components/alert/Alert';
import TitleInput from '../../../components/inputs/TitleInput';
import NumberInput from '../../../components/inputs/NumberInput';

const validations = (values) => {
    let errors = {};

    errors = FormValidator.make({
        name: 'required',
        // name: 'required|languages',
        weddingPlace: 'required',
        maxTables: 'isInteger|minNumber:0'
    })(values);

    return errors;
};

let ConfigRoomPlanModal = ({
    isOpen,
    loading,
    handleSubmit,
    initialValues,
    onSubmit,
    onClose,
    roomPlanForm
}) => {
    useEffect(() => {
    }, []);

    return (
        <Modal
            visible={isOpen}
            title={initialValues?._id ? 'Editar plano base' : 'Criar plano base'}
            maskClosable={false}
            onCancel={onClose}
            footer={<React.Fragment>
                <Button
                    key='cancel'
                    type='default'
                    onClick={onClose}>
                    Cancelar
                </Button>
                <Button
                    key='submit'
                    type='primary'
                    loading={loading}
                    onClick={handleSubmit(onSubmit)}>
                    Gravar
                </Button>
            </React.Fragment>}>

            <BaseForm onSubmit={handleSubmit(onSubmit)}>
                {/* <Field
                    component={TitleInput}
                    name={'name'}
                    type="text"
                    label={'Nome do plano *'}
                    placeholder={'Nome'}
                /> */}
                <Field
                    component={TextInput}
                    name={'name'}
                    type="text"
                    label={'Nome do plano *'}
                    placeholder={'Nome'}
                />
                <Field
                    component={NumberInput}
                    name={'maxTables'}
                    label={'Nº máximo de mesas (notificação para alteração de planos) *'}
                />
            </BaseForm>
        </Modal>
    );
};

ConfigRoomPlanModal = reduxForm({
    form: 'room_plan_form',
    enableReinitialize: true,
    validate: validations,
})(ConfigRoomPlanModal);

const selector = formValueSelector('room_plan_form');

const mapStateToProps = state => ({
    roomPlanForm: {
        name: selector(state, 'name'),
    }
});

ConfigRoomPlanModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(ConfigRoomPlanModal)