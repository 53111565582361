import React, {Component} from 'react';
import {connect} from 'react-redux';
import {initialize} from 'redux-form';
import {Row, Col, Icon, Popconfirm} from 'antd';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import ManageColourModal from './ManageColourModal';
import {
  GetColours,
  UpdateColour,
  CreateColour,
  DeleteColour,
  UpdateColourOrder
} from '../../infra/requests/ColoursRequests';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import {FlattenToFormData} from '../../infra/services/formdata/TransformToFormData';
import {ColourSection, ColourName, Options} from './ColoursStyles';
import OrderColour from './OrderColour';

class ColoursPage extends Component {
  state = {
    loading: false,
    openModal: false,
    edit: false,
    colours: [],
    orderModal: false
  };

  componentDidMount() {
    this.getColours();
  }

  getColours = async () => {
    this.setState({loading: true});
    try {
      const {data} = await GetColours();
      this.setState({
        colours: data
      });
      this.setState({loading: false});
    } catch (e) {
      this.setState({loading: false});
    }
  };

  openModal = (data = {}) => {
    const {dispatch} = this.props;
    dispatch(initialize('manage_colour_form', data));
    this.setState({openModal: true, edit: data._id});
  };

  closeModal = () => {
    const {dispatch} = this.props;
    dispatch(initialize('manage_colour_form', {}));
    this.setState({openModal: false, edit: false});
  };

  onModalSubmit = async values => {
    this.setState({loading: true});
    if (values.image && !values.image.blob) {
      delete values.image;
    }
    const payload = FlattenToFormData(values);
    values._id
      ? await UpdateColour(values._id, payload)
      : await CreateColour(payload);
    this.closeModal();
    await this.getColours();
  };

  deleteColour = async id => {
    await DeleteColour(id);
    await this.getColours();
  };

  closeOrderModal = () => {
    this.setState({orderModal: false});
  };

  openOrderModal = colours => {
    const {dispatch} = this.props;
    dispatch(initialize('manage_ordercolours_form', {colours: [...colours]}, false));
    this.setState({orderModal: true});
  };

  onSubmitOrder = async values => {
    try {
      this.setState({loading: true});

      if (values.colours.length > 0) {
        let orderedList = [];
        Object.keys(values.colours).forEach(function(key) {
          let obj = {_id: values.colours[key]._id, order: key};

          orderedList.push(obj);
        });

        if (orderedList.length > 0) {
          const res = await UpdateColourOrder({order: orderedList});

          if (res.success) {
            this.getColours();
          }
        }
      }

      this.setState({loading: false});
      this.closeOrderModal();
    } catch (e) {
      this.setState({loading: false});
    }
  };

  render() {
    const {openModal, edit, colours, orderModal, loading} = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Palete de cores</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="default"
              icon="edit"
              text="Editar Ordem"
              onClick={() => this.openOrderModal(colours)}
            />
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Nova cor'}
              onClick={() => this.openModal()}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={2}>
          <Row gutter={[32, 32]}>
            {colours.map(colour => (
              <Col xs={12} sm={8} md={6} key={colour._id}>
                <ColourSection image={colour?.image?.url} colour={colour.hex} />
                <ColourName>{TranslateValue(colour.name)}</ColourName>
                <Options number={1} onClick={() => this.openModal(colour)}>
                  <Icon type="edit" />
                </Options>
                <Options number={0}>
                  <Popconfirm
                    placement="topRight"
                    title="De certeza que quer apagar esta cor?"
                    onConfirm={() => this.deleteColour(colour._id)}>
                    <Icon type="delete" />
                  </Popconfirm>
                </Options>
              </Col>
            ))}
          </Row>
          <ManageColourModal
            open={openModal}
            edit={edit}
            onSubmit={this.onModalSubmit}
            closeModal={this.closeModal}
          />
          <OrderColour
            open={orderModal}
            loading={loading}
            onSubmit={this.onSubmitOrder}
            closeModal={this.closeOrderModal}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default connect()(ColoursPage);
