import client from '../config/AxiosConfig';

export const GetInfo = async () => client.get('/info/bo');

export const GetDynamicPages = async () => client.get('/dynamic_pages');

export const GetWeddingDayPages = async () =>
  client.get('/dynamic_pages/wedding/day');

export const GetDynamicPage = async tag => client.get(`/dynamic_pages/${tag}`);

export const CreateDynamicPage = async data =>
  client.post('/dynamic_pages', data);

export const UpdateDynamicPage = async (tag, data) =>
  client.put(`/dynamic_pages/${tag}`, data);

export const GetMetatags = async (page = 1, limit = 20, filter = "") =>
  client.get(`/metatags/${page}/${limit}?filter=${filter}`);

export const GetMetatag = async id => client.get(`/metatags/${id}`);

export const UpdateMetatag = async (id, data) =>
  client.put(`/metatags/${id}`, data);

// Link Files
export const AddDynamicPageFileLink = async (id, lang, file) =>
  await client.put(`/dynamic_pages/${id}/fileLink/${lang}`, file);

export const RemoveDynamicPageFileLink = async (id, file, lang) =>
  await client.delete(`/dynamic_pages/${id}/fileLink/${file}/${lang}`);
