import BaseRoutes from './data/BaseRoutes';
import WeddingRoutes from './data/WeddingRoutes';
import ManagementRoutes from './data/ManagementRoutes';
import SectionRoutes from './data/SectionRoutes';
import FoodRoutes from './data/FoodRoutes';
import ReportsRoutes from './data/ReportsRoutes';
import ReserveRoutes from './data/ReserveRoutes';

export default [
  ...BaseRoutes,
  ...ManagementRoutes,
  ...WeddingRoutes,
  ...SectionRoutes,
  ...FoodRoutes,
  ...ReportsRoutes,
  ...ReserveRoutes
];
