import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import { InputDiv, InputLabelDiv, CurrencyInputStyle } from './InputStyles';

const CurrencyInput = ({
  input,
  meta,
  size,
  label,
  first,
  max,
  min,
  step,
  placeholder,
  disabled,
  defaultValue
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;
  return (
    <InputDiv first={first}>
      {label && <InputLabelDiv>{label}</InputLabelDiv>}
      <NumberFormat
        customInput={CurrencyInputStyle}
        value={input.value !== undefined ? input?.value : ''}
        defaultValue={defaultValue || '000.00'}
        showError={showError}
        displayType={'input'}
        suffix="€"
        decimalScale={2}
        placeholder={placeholder}
        fixedDecimalScale
        allowNegative={false}
        onValueChange={values => input.onChange(values.floatValue || '')}
        disabled={disabled}
      />
    </InputDiv>
  );
};

CurrencyInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

export default CurrencyInput;
