import React, { Component, Fragment } from 'react';
import Table from '../../components/table/Table';
import { Row, Col, Popconfirm, Icon } from 'antd';
import { HeaderButtonsContainer, TableButton, SectionTitle, TableFilterSection } from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import { withLocalize } from 'react-localize-redux';
import { CreateSubcategory, DeleteSubcategory, UpdateSubcategory } from '../../infra/requests/SubcategoryRequests'
import SubcateryModal from './SubcategoryModal';
import SubcategoryDefaultEmployeesModal from './SubcategoryDefaulEmployeesModal';
import { CreatePrice, DeletePrice, UpdatePrice } from '../../infra/requests/PriceRequests';
import moment from 'moment';
import { CreateCategory, GetCategory } from '../../infra/requests/CategoryRequests';
import Alerts from '../../components/alert/Alert';

const timeFormat = "HH:mm";

const RemoveMessage = (
    <div>
        <div>Tem a certeza que quer remover esta subcategoria? </div>
    </div>
);


class SubcategoryTable extends Component {
    state = {
        pageSize: 15,
        currentPage: 1,
        loading: false,
        search: '',
        category: null,
        categoryForm: null,
        categoryStartTime: null,
        categoryEndTime: null,
        showModal: false,
        subcategoryModal: null,

        updatePrices: [],
        deletePrices: [],

        deleteSubcategories: [],
        addEditSubcategories: [],

        columns: [
            {
                title: 'Ordenar',
                key: 'orderBtn',
                render: data => (
                    <Fragment>
                        {this.state.rows.findIndex(c => c.id === data.id) > 0 ? (<TableButton onClick={e => this.order(0, data)}>
                            <Icon type="up" />
                            {' Cima'}
                        </TableButton>) : null
                        }

                        {this.state.rows.findIndex(c => c.id === data.id) < (this.state.total - 1) ? (<TableButton onClick={e => this.order(1, data)}>
                            <Icon type="down" />
                            {' Baixo'}
                        </TableButton>) : null
                        }
                    </Fragment>
                )
            },
            {
                title: 'Ordem',
                dataIndex: 'order',
            },
            {
                title: 'Subcategoria',
                dataIndex: 'name',
            },
            {
                title: 'Acrónimo',
                dataIndex: 'acronym',
            },
            {
                title: 'Acções',
                width: '120px',
                render: data => (
                    <Fragment>
                        <TableButton onClick={() => this.setState({ showModal: true, subcategoryModal: data })}>
                            <Icon type="edit" />
                            {' Editar'}
                        </TableButton>
                        <TableButton onClick={() => this.setState({ showDefaultModal: true, subcategoryModal: data })}>
                            <Icon type="team" />
                            {' Pré-definir funcionários'}
                        </TableButton>
                        {!(data.chief || data.coordinator || data.host) ? <TableButton onClick={e => e.stopPropagation()}>
                            <Popconfirm
                                placement="topRight"
                                title={RemoveMessage}
                                okText="Remover"
                                onConfirm={async () => { await this.deleteSubcategory(data) }}>
                                <Icon type="delete" />
                                {' Remover'}
                            </Popconfirm>
                        </TableButton> : null}
                    </Fragment>
                )
            }
        ],
        rows: [],
        total: 0
    };

    componentDidMount = async () => {
        this.setState({
            category: this.props.category,
            categoryForm: this.props.categoryForm
        }, async () => {
            await this.updateDataTable();
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.category !== this.props.category) {
            this.setState({ category: this.props.category }, async () => {
                await this.updateDataTable();
            });
        }

        if (prevProps.categoryForm !== this.props.categoryForm) {
            this.setState({ categoryForm: this.props.categoryForm });
        }

        if (prevProps.categoryStartTime !== this.props.categoryStartTime) {
            this.setState({ categoryStartTime: this.props.categoryStartTime });
        }

        if (prevProps.categoryEndTime !== this.props.categoryEndTime) {
            this.setState({ categoryEndTime: this.props.categoryEndTime });
        }
    }

    /**
     * @description Update data on datatable
     */
    updateDataTable = async () => {
        this.setState({ loading: true });
        const { category } = this.state;
        try {
            if (category !== null) {
                const result = await GetCategory(category.id);
                //console.warn('Result Table', result);

                this.setState({
                    rows: result.success && result.data && result.data.subcategories.length > 0 ? result.data.subcategories : [],
                    total: result.success && result.data && result.data.subcategories ? result.data.subcategories.length : 0,
                    loading: false
                });
            } else this.setState({ loading: false });
        } catch (e) {
            this.setState({ loading: false });
            console.error(e);
        }
    }

    /**
     * @description Delete subcategory and reorder datatable
     * @param {*} subcategory - subcategory
     * @returns datatable subcategory updated
     */
    deleteSubcategory = async (subcategory) => {
        let { rows, deleteSubcategories } = this.state;

        if (subcategory.id.includes('id#FFFFF')) {
            rows = rows.filter(s => s.id !== subcategory.id);
            this.setState({ rows, total: rows.length });
        } else {
            const result = await DeleteSubcategory(subcategory.id);
            if (!result?.success) console.error(result.message);
            else {
                rows = rows.filter(s => s.id !== subcategory.id);
                this.setState({ rows, total: rows.length });
            }

            // const subIndex = rows.findIndex(sub => sub.id === subcategory.id);

            // deleteSubcategories.push(subcategory);
            // rowsDelete = rows.filter(s => s.id !== subcategory.id);
            // const totalDelete = rowsDelete.length;

            // if (subIndex < totalDelete) {
            //     for (let index = subIndex; index < totalDelete; index++) {
            //         const elem = rowsDelete[index];
            //         elem.order = index + 1;
            //         rowsDelete[index] = elem;
            //         await UpdateSubcategory(elem);
            //         this.setState({ rows: rowsDelete });
            //     }
            // }
            // this.setState({ rows: rowsDelete, deleteSubcategories: deleteSubcategories, total: totalDelete });
            // this.props.deleteSubcategories(deleteSubcategories);
        }
    }

    /**
     * @description Order rows from subcategory table
     * @param {number} type 
     * @param {object} subcategory 
     * @returns subcategory table with reordered rows
     */
    order = async (type, subcategory) => {
        let subcategoryChange = this.state.rows[this.state.rows.findIndex(c => c.id === subcategory.id) + (1 * (type === 1 ? 1 : -1))];

        const orderChange = 0 + subcategoryChange.order;
        subcategoryChange.order = subcategory.order;
        subcategory.order = orderChange;
        await UpdateSubcategory(subcategory);
        await UpdateSubcategory(subcategoryChange);
        return this.updateDataTable();
    }

    addCategory = async () => {
        const { categoryStartTime, categoryEndTime, categoryForm } = this.state;

        if (categoryForm.name === undefined || categoryForm.name === '' || categoryForm.acronym === undefined || categoryForm.acronym === '' || !categoryStartTime) {
            return Alerts.new({
                type: "error",
                title: "Erro!",
                text: 'Faltam dados na categoria.'
            });
        }

        const startMoment = moment(moment(categoryStartTime).format(timeFormat), timeFormat);
        // const endMoment = moment(moment(categoryEndTime).format(timeFormat), timeFormat);

        const categoryValues = {
            acronym: categoryForm.acronym,
            name: categoryForm.name,
            startTime: startMoment,
            // endTime: endMoment
        };
        const resultCategory = await CreateCategory(categoryValues);
        //console.warn('Result Category', resultCategory.data);
        this.setState({ category: resultCategory.success && resultCategory.data ? resultCategory.data : null });
        if (resultCategory.success && resultCategory.data) {
            this.props.updateIsNew(false);
            this.props.updateCategoryForm(resultCategory.data);
        }
    };

    //Modal
    onSubmit = async (values) => {
        // console.warn('Values', values);
        const { subcategoryModal, updatePrices, deletePrices, total, rows } = this.state;

        //CRUD Subcategory Prices
        if (updatePrices?.length > 0) {
            for (let index = 0; index < updatePrices.length; index++) {
                const price = updatePrices[index];

                if (price?.setting?.id?.includes('new') && !price?.setting?.name) {
                    Alerts.new({
                        type: "error",
                        title: "Erro!",
                        text: 'Deve selecionar uma unidade'
                    });
                    return;
                }

                if (price?.priceWithInsurance <= 0 || price?.priceWithoutInsurance <= 0) {
                    Alerts.new({
                        type: "error",
                        title: "Erro!",
                        text: 'Deve indicar os preços'
                    });
                    return;
                }
            }
        }

        let subcategory = subcategoryModal;
        subcategory.name = values.name;
        subcategory.acronym = values.acronym;
        subcategory.repeat = values.repeat;
        subcategory.calcTime = values.calcTime;
        subcategory.timeRule = (values?.calcTime && values.timeRule != '')
            ? parseFloat(values.timeRule)
            : 0.00;
        subcategory.weddingTimeRule = values?.calcTime && values?.weddingTimeRule != null && values?.weddingTimeRule != ''
            ? values.weddingTimeRule
            : null;
        subcategory.calcAutomatic = values.calcAutomatic;
        subcategory.quantityRule = values?.calcAutomatic && values?.quantityRule != null && values?.quantityRule != ''
            ? parseInt(values.quantityRule)
            : 0;

        let res;
        if (values.id === null) {
            subcategory.order = total + 1;
            subcategory.category = this.state.category;
            res = await CreateSubcategory(subcategory);
        } else {
            if (subcategory._id) subcategory.id = subcategory._id;
            const sub = {
                ...subcategory,
                id: subcategory._id ? subcategory._id : subcategory.id
            }
            res = await UpdateSubcategory(sub);
        }

        //console.warn('Res Subcategory', res);
        if (res?.success) {
            if (res.data) subcategory.id = res.data._id;
            if (!subcategory._id) subcategory['_id'] = subcategory.id;
            if (rows.filter(f => f._id === subcategory._id).length === 0) rows.push(subcategory);

            //CRUD Subcategory Prices
            if (updatePrices.length > 0) {
                for (let index = 0; index < updatePrices.length; index++) {
                    const price = updatePrices[index];
                    price.subcategory = subcategory;
                    price.category = this.props.category;

                    // if (price.setting.id === null) {
                    //     //criar unit price
                    //     const result = await CreateSetting(price.setting);
                    //     //console.warn('Result', res);

                    //     if (result.success) {
                    //         price.setting = result.data;
                    //         price.id.includes('id#FFFFF') ? await CreatePrice(price) : await UpdatePrice(price);
                    //     } else return console.error(result.message);

                    // } else price.id.includes('id#FFFFF') ? await CreatePrice(price) : await UpdatePrice(price);

                    if (price?.id?.includes('id#FFFFF')) await CreatePrice(price)
                    else await UpdatePrice(price);
                }
            }

            //console.warn('Delete', deletePrices);
            if (deletePrices.length > 0) {
                for (let index = 0; index < deletePrices.length; index++) {
                    const element = deletePrices[index];
                    await DeletePrice(element.id);
                }
            }

            this.setState({ updatePrices: [], deletePrices: [] });
        } else {
            Alerts.new({
                type: "error",
                title: "Erro!",
                text: 'Erro na gestão da subcategoria!'
            });
        }

        this.setState({ showModal: false, subcategoryModal: null }, () => {
            this.updateDataTable();
        });
    }

    onSubmitDefaultModal = async (values) => {
        const { subcategoryModal } = this.state;

        const data = {
            ...subcategoryModal,
            id: subcategoryModal._id ? subcategoryModal._id : subcategoryModal.id
        };
        data.defaultEmployeeSolar = values?.defaultEmployeeSolar || null;
        data.defaultEmployeeLago = values?.defaultEmployeeLago || null;

        const result = await UpdateSubcategory(data);
        if (result?.success) {
            Alerts.new({
                type: 'success',
                title: 'Successo',
                text: 'Subcategoria atualizada!'
            });
            this.setState({
                showDefaultModal: false,
                subcategoryModal: null
            }, () => {
                this.updateDataTable();
            });
        } 
    }

    render() {
        const { loading, currentPage, pageSize, category } = this.state;
        const { columns, rows, total } = this.state;
        const { showModal, showDefaultModal, subcategoryModal } = this.state;

        return (
            <React.Fragment>
                <TableFilterSection style={{ marginBottom: '0' }}>
                    <Row gutter={24} style={{ marginTop: '2rem' }}>
                        <Col lg={8} md={8} sm={12} xs={24}>
                            <SectionTitle>Subcategorias</SectionTitle>
                        </Col>

                        <Col lg={16} md={16} sm={12} xs={24} className='subcategory-col-btn'>
                            {!category?.protocol && <BaseButton
                                type={'primary'}
                                icon={'plus'}
                                text={'Adicionar Subcategoria'}
                                onClick={() => {
                                    if (category === null) {
                                        this.addCategory();
                                    }
                                    let x = {
                                        id: null,
                                        name: '',
                                        acronym: ''
                                    };
                                    this.setState({ showModal: true, subcategoryModal: x })

                                }}
                            />}
                        </Col>
                    </Row>
                </TableFilterSection>
                <div className='table-responsive'>
                    <Table
                        columns={columns}
                        loading={loading}
                        rows={rows}
                        showHeader={true}
                        emptyIcon="setting"
                        emptyText={'Não existem subcategorias'}
                        total={total}
                        rowKey={'_id'}
                        hasPagination={false}
                    />
                </div>

                {showModal && <SubcateryModal
                    isOpen={showModal}
                    onClose={e => this.setState({ showModal: false, subcategoryModal: null })}
                    onSubmit={e => this.onSubmit(e)}
                    initialValues={subcategoryModal}
                    updatePrices={e => this.setState({ updatePrices: e })}
                    deletePrices={e => this.setState({ deletePrices: e })}
                />}

                {showDefaultModal && <SubcategoryDefaultEmployeesModal
                    isOpen={showDefaultModal}
                    onClose={e => this.setState({ showDefaultModal: false, subcategoryModal: null })}
                    onSubmit={e => this.onSubmitDefaultModal(e)}
                    initialValues={subcategoryModal}
                />}
            </React.Fragment>
        );
    }
}

export default withLocalize(SubcategoryTable);