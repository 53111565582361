import React from 'react';
import {
  WeddingDetailTitle,
  OrderTable
} from '../OrderStyles';
import {
  OptionsContainer,
  OptionLine,
  OptionColumn,
  EmptyResults
} from './Styles';
import TextInput from '../../../components/inputs/TextInput';

const OthersCategoriesDetail = ({ wedding }) => {
  return (
    <React.Fragment>
      <OrderTable first={true}>
        <WeddingDetailTitle>Timeline</WeddingDetailTitle>
        {
          wedding?.timeline?.length > 0 ?
          <OptionsContainer>
            {
              wedding.timeline.map((moment, index) => 
                <OptionLine key={index}>  
                  <OptionColumn maxWidth={350} noBorderTopRight={true} noBorderBottomRight={true}>
                    <TextInput
                      meta={{}}
                      input={{value: moment.name}}
                      type='text'
                      disabled={true}
                      noLabel={true}
                    />
                  </OptionColumn>
                  <OptionColumn maxWidth={100} noBorderTopLeft={true} noBorderBottomLeft={true}>
                    <TextInput
                      meta={{}}
                      input={{value: moment.time}}
                      type='text'
                      disabled={true}
                      noLabel={true}
                    />
                  </OptionColumn>
                </OptionLine>
              )
            }
          </OptionsContainer>
          :
          <EmptyResults>Não existem resultados a apresentar!</EmptyResults>
        }
      </OrderTable>
    </React.Fragment>
  );
};

export default OthersCategoriesDetail;