import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Field, reduxForm, initialize} from 'redux-form';
import {BaseForm} from '../../styles/BasicStyles';
import {Modal, Button} from 'antd';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextInput from '../../components/inputs/TextInput';
import CurrencyInput from '../../components/inputs/CurrencyInput';
import SelectInput from '../../components/inputs/SelectInput';
import DateInput from '../../components/inputs/DateInput';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import {
  SearchWeddings,
  CreateBudget
} from '../../infra/requests/WeddingRequests';
import {
  getWeddingName,
  getLocal
} from '../../infra/services/wedding/weddingUtils';

const validations = FormValidator.make({
  type: 'required',
  wedding: 'required',
  title: 'required',
  description: 'required',
  value: 'required',
  date: 'required'
});

const BudgetTypes = [
  {_id: 'PAYMENT', name: 'Pagamentos Efectuados'},
  {_id: 'EXPENSE', name: 'Outros valores'},
  {_id: 'OFFER', name: 'Valores a descontar'}
];

let AddRegisterModal = ({dispatch, handleSubmit, open, closeModal}) => {
  const [loading, setLoading] = useState(false);
  const [weddings, setWeddings] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const {data} = await SearchWeddings('');
      setWeddings(data);
      setLoading(false);
    }
    fetchData();
  }, []);

  const searchWedding = async search => {
    const valid = moment(search, 'DD-MM-YYYY', true).isValid();
    if (valid) {
      setLoading(true);
      const {data} = await SearchWeddings(search);
      setWeddings(data);
      setLoading(false);
    }
  };

  const onSubmit = async values => {
    setLoading(true);
    await CreateBudget(values);
    setLoading(false);
    onClose();
  };

  const formatData = data => {
    return (
      <div>
        {moment.utc(data.date).format('DD-MM-YYYY')} | {getWeddingName(data)} |{' '}
        {getLocal(data)}
      </div>
    );
  };

  const onClose = () => {
    dispatch(initialize('manage_register_payment_form', {}));
    closeModal();
  };

  return (
    <Modal
      visible={open}
      title="Capítulo"
      maskClosable={false}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      <BaseForm onSubmit={handleSubmit(onSubmit)}>
        <Field
          component={SelectInput}
          name={'wedding'}
          label={'Casamento *'}
          placeholder={'Procure por dia-mes-ano'}
          type="select"
          data={weddings}
          fetching={loading}
          fetchData={searchWedding}
          costumeLabel={formatData}
        />
        <Field
          component={SelectInput}
          name={'type'}
          label={'Tipo de registo *'}
          placeholder={'Selecione o tipo de registo'}
          type="select"
          data={BudgetTypes}
        />
        <Field
          component={TextInput}
          name={'title'}
          type="text"
          label={'Titulo *'}
          placeholder={'Titulo do registo'}
        />
        <Field
          component={CurrencyInput}
          name={'value'}
          defaultValue="00.00"
          label={'Valor *'}
          placeholder={'Insira o valor do registo'}
        />
        <Field
          component={DateInput}
          name="date"
          label="Data *"
          placeholder="Data do registo"
        />
        <Field
          component={TextAreaInput}
          name={'description'}
          type="text"
          label={'Descrição *'}
          placeholder={'Descrição do registo'}
        />
      </BaseForm>
    </Modal>
  );
};

AddRegisterModal = reduxForm({
  form: 'manage_register_payment_form',
  validate: validations,
  enableReinitialize: true
})(AddRegisterModal);

export default connect()(AddRegisterModal);
