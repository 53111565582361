import client from "../config/AxiosConfig";

export const CreateEmployee = async (data) =>
  client.put(`/employee/create`, data);

export const GetEmployee = async id =>
  client.get(`/employee/find/${id}`);

export const GetEmployees = async () =>
  client.get(`/employee/findAll`);

export const GetEmployeesPaginate = async (currentPage, limit, filters) =>
  client.get(`/employee/${currentPage}/${limit}?filter=${filters}`);

export const GetEmployeeByUser = async userId =>
  client.get(`/employee/findByUser/${userId}`);

export const GetHostsEmployee = async () =>
  client.get(`/employee/findHosts`);

export const GetEmployeesBySubcategory = async subcategoryId =>
  client.get(`/employee/findAllBySubcategory/${subcategoryId}`);

export const GetDefaultEmployeesBySubcategory = async subcategoryId =>
  client.get(`/employee/findDefaultBySubcategory/${subcategoryId}`);

export const GetEmployeesAvailableBySubcategory = async (date, subcategoryId, weddingId, startTime) =>
  client.get(`/employee/findAllAvailableBySubcategory/${date}/${subcategoryId}/${weddingId}/${startTime}`);

export const GetEmployeesAvailableBySubcategories = async (data) =>
  client.post(`/employee/findAllAvailableBySubcategories`, data);

export const ConvertTraineeToEmployee = async (id) =>
  client.post(`/employee/convertTraineeToEmployee/${id}`, {});

export const ResetEmployeePriceForTrainee = async (id) =>
  client.post(`/employee/resetByTrainee/${id}`, {});

export const UncheckUpdatedIbanByEmployees = async (data) =>
  client.post(`/employee/uncheckUpdatedIban`, data);

export const UpdateEmployee = async (data) =>
  client.post(`/employee/update`, data);

export const NotifyInsuranceFile = async (employee) =>
  client.post(`/employee/updateInsurance`, employee);

export const DeleteEmployee = async id =>
  client.delete(`/employee/remove/${id}`);

export const RestoreEmployee = async id =>
  client.post(`/employee/restore/${id}`, {});

export const ResendEmailEmployee = async (data) =>
  client.put(`/employee/resendEmail`, data);

export const DownloadAvailableMap = async data => {
  return client.post('/employee/availableMap', data, {
    responseType: 'blob'
  }).then(response => {
    return response;
  })
}