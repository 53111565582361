import React, { Component } from 'react';
import { Modal, notification } from 'antd';
import { connect } from 'react-redux';
import { getOrganizers } from '../../infra/requests/AdminsRequests';
import { bindActionCreators } from 'redux';
import { reduxForm, initialize, formValueSelector } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import BaseButton from '../../components/buttons/BaseButton';
import {
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  HeaderButtonsContainer,
  SpinLoading
} from '../../styles/BasicStyles';
import { ChangeAboutUs, SaveNotes, AddWedding } from '../../redux/wedding/wedding.actions';
import FormValidator from '../../infra/services/validations/FormValidator';
import {
  UpdateWeddingAbout,
  UpdateWeddingPhoto,
  UpdateWeddingBillings,
  SaveSectionNotes,
  getWeddingInfo
} from '../../infra/requests/WeddingProcessRequests';
//import CommentsDrawer from '../../components/comments/CommentsDrawer';
import { ChoicesPageContainer } from '../ChoicesPage/SectionStyles';
import AboutMenu from './AboutMenu';
import AboutContent from './AboutContent';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import NotesConfirmModal from '../../components/notesModal/NotesModal';
import { hasNotes } from '../../infra/services/notes/FindNotes';
import PrintedBar from '../dashboard/components/PrintedBar';
import CommentsDrawerFixed from '../../components/comments/CommentsDrawerFixed';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { updateQuestionAction } from '../../redux/wedding/wedding.actions';
import { getAboutUsPrintedConfirmation, getBillingPrintedConfirmation, getPrintedConfirmation } from '../../infra/services/wedding/printedUtils';

const { confirm } = Modal;

declare var require: any
var _ = require('lodash');

//Form Validation
const validations = values => {
  let errors: any = {};

  errors = FormValidator.make({
    weddingDayLanguage: 'required',
    organizer: 'required',
  })(values);

  errors.bride = FormValidator.make({
    name: 'required',
    email: 'required|email',
    // contact: 'required'
  })(values?.bride);

  errors.groom = FormValidator.make({
    name: 'required',
    email: 'required|email',
    // contact: 'required'
  })(values?.groom);

  errors.alternative = FormValidator.make({
    // name: 'required',
    // email: 'email',
    // contact: 'required'
  })(values?.alternative);

  errors.alternative2 = FormValidator.make({
    // name: 'required',
    // email: 'email',
    // contact: 'required'
  })(values?.alternative2);

  const guestValidations = {};
  // If exists guest contact or email info, then name is required
  if ((values?.alternativeGuest?.email && values?.alternativeGuest?.email !== '')
    || (values?.alternativeGuest?.contact && values?.alternativeGuest?.contact !== '')) {
    guestValidations['name'] = 'required';
    guestValidations['relationToCouple'] = 'required';
  }
  errors.alternativeGuest = FormValidator.make(guestValidations)(values?.alternativeGuest);

  // Check for the emails between couple, alternative contacts and guest are not the same
  if (values?.alternative?.email && values?.alternative?.email !== '' && (
    (values?.bride?.email && values?.bride?.email.toLowerCase().trim() === values?.alternative?.email.toLowerCase().trim()) ||
    (values?.groom?.email && values?.groom?.email.toLowerCase().trim() === values?.alternative?.email.toLowerCase().trim()) ||
    (values?.alternative2?.email && values?.alternative2?.email.toLowerCase().trim() === values?.alternative?.email.toLowerCase().trim()) ||
    (values?.alternativeGuest?.email && values?.alternativeGuest?.email.toLowerCase().trim() === values?.alternative?.email.toLowerCase().trim()))
  ) {
    errors.alternative.email = 'Não pode ser igual ao email do casal ou do convidado!';
  }

  if (values?.alternative2?.email && values?.alternative2?.email !== '' && (
    (values?.bride?.email && values?.bride?.email.toLowerCase().trim() === values?.alternative2?.email.toLowerCase().trim()) ||
    (values?.groom?.email && values?.groom?.email.toLowerCase().trim() === values?.alternative2?.email.toLowerCase().trim()) ||
    (values?.alternative?.email && values?.alternative?.email.toLowerCase().trim() === values?.alternative2?.email.toLowerCase().trim()) ||
    (values?.alternativeGuest?.email && values?.alternativeGuest?.email.toLowerCase().trim() === values?.alternative2?.email.toLowerCase().trim()))
  ) {
    errors.alternative2.email = 'Não pode ser igual ao email do casal ou do convidado!';
  }

  // Check contact number is valid
  if (values.bride && values.bride.contact && !isValidPhoneNumber(values.bride.contact)) {
    errors.bride.contact = 'Número de telemóvel inválido';
  }

  if (values.groom && values.groom.contact && !isValidPhoneNumber(values.groom.contact)) {
    errors.groom.contact = 'Número de telemóvel inválido';
  }

  if (values?.bride?.contact && values?.groom?.contact && values?.bride?.contact?.trim() === values?.groom?.contact?.trim()) {
    errors.bride.contact = 'Número de telemóvel do casal não pode ser igual.';
    errors.groom.contact = 'Número de telemóvel do casal não pode ser igual.';
  }

  if (values.alternative && values.alternative.contact && !isValidPhoneNumber(values.alternative.contact)) {
    errors.alternative.contact = 'Número de telemóvel inválido';
  }

  if (values.alternative2 && values.alternative2.contact && !isValidPhoneNumber(values.alternative2.contact)) {
    errors.alternative2.contact = 'Número de telemóvel inválido';
  }

  if (values.alternativeGuest && values.alternativeGuest.contact && !isValidPhoneNumber(values.alternativeGuest.contact)) {
    errors.alternativeGuest.contact = 'Número de telemóvel inválido';
  }

  // if (values.alternative && values.alternative.contact && values.alternative.contact !== '' && values.alternative.name && values.alternative.name !== '' ? false : true) {
  //   errors.alternative.name = 'Nome é obrigatório';
  // }

  // if (values.alternative2 && values.alternative2.contact && values.alternative2.contact !== '' && (values.alternative2.name === undefined || values.alternative2.name && (values.alternative2.name !== '' ? false : true))) {
  //   errors.alternative2.name = 'Nome é obrigatório';
  // }

  // Check that the contact between couple, guest and alternative contacts are not the same
  if (values?.alternative?.contact && (
    (values?.bride?.contact && values?.bride?.contact?.trim() === values?.alternative?.contact?.trim()) ||
    (values?.groom?.contact && values?.groom?.contact?.trim() === values?.alternative?.contact?.trim()) ||
    (values?.alternativeGuest?.contact && values?.alternativeGuest?.contact?.trim() === values?.alternative?.contact?.trim()) ||
    (values?.alternative2?.contact && values?.alternative2?.contact?.trim() === values?.alternative?.contact?.trim()))
  ) {
    errors.alternative.contact = 'Não pode ser igual ao número de telemóvel do casal ou do convidado!';
  }

  if (values?.alternative2?.contact && (
    (values?.bride?.contact && values?.bride?.contact?.trim() === values?.alternative2?.contact?.trim()) ||
    (values?.groom?.contact && values?.groom?.contact?.trim() === values?.alternative2?.contact?.trim()) ||
    (values?.alternativeGuest?.contact && values?.alternativeGuest?.contact?.trim() === values?.alternative2?.contact?.trim()) ||
    (values?.alternative?.contact && values?.alternative?.contact?.trim() === values?.alternative2?.contact?.trim()))
  ) {
    errors.alternative2.contact = 'Não pode ser igual ao número de telemóvel do casal ou do convidado!';
  }

  if (values?.alternativeGuest?.contact && (
    (values?.bride?.contact && values?.bride?.contact?.trim() === values?.alternativeGuest?.contact?.trim()) ||
    (values?.groom?.contact && values?.groom?.contact?.trim() === values?.alternativeGuest?.contact?.trim()) ||
    (values?.alternative?.contact && values?.alternative?.contact?.trim() === values?.alternativeGuest?.contact?.trim()) ||
    (values?.alternative2?.contact && values?.alternative2?.contact?.trim() === values?.alternativeGuest?.contact?.trim()))
  ) {
    errors.alternativeGuest.contact = 'Não pode ser igual ao número de telemóvel do casal ou dos contactos alternativos!';
  }

  return errors;
};

class AboutPage extends Component {
  state = {
    loading: true,
    saving: false,
    openDrawer: false,
    formDirty: { aboutUs: false, notes: false },
    organizers: [],
    section: [],
    openModal: false,
    openNotesConfirm: false,
    editBillingIndex: undefined,
    aboutFormOriginalData: null,
  };

  componentDidMount = async () => {
    const { wedding } = this.props;
    const original = JSON.parse(JSON.stringify(wedding.wedding));
    original['organizer'] = wedding?.wedding?.organizer?.length > 0
      ? wedding?.wedding?.organizer?.map(m => m?._id)
      : [wedding?.wedding?.organizer?._id];
    delete original.__v;
    this.setState({ aboutFormOriginalData: original });

    this.getInfo();
  };

  componentDidUpdate(prevProps): void {
    const { notesForm, aboutUsForm } = this.props;
    if (_.isEqual(prevProps.notesForm, notesForm) === false || _.isEqual(prevProps.aboutUsForm, aboutUsForm) === false) {
      this.checkDirtyForms();
    }
  }

  getInfo = async () => {
    const { wedding, dispatch } = this.props;
    const { data } = await getOrganizers();
    const section = wedding.structure.find(s => s.tag === 'ABOUT_US');

    dispatch(
      initialize('wedding_aboutus_form', {
        ...wedding.wedding,
        organizer: wedding?.wedding?.organizer?.length > 0
          ? wedding?.wedding?.organizer?.map(m => m?._id)
          : [wedding?.wedding?.organizer?._id]
      })
    );

    this.setState({ loading: false, organizers: data, section });
  };

  updateWeddingInfo = async (dataAnswer: any) => {
    const { wedding, dispatch } = this.props;
    const { data, success } = await getWeddingInfo(wedding.wedding._id);
    const section = data.structure.find(s => s.tag === 'ABOUT_US');

    dispatch(AddWedding(data));
    // dispatch(updateQuestionAction(section.tag, dataAnswer));

    this.setState({ loading: false, section });
  }

  checkDirtyForms = () => {
    const { wedding, notesForm, aboutUsForm } = this.props;
    const { openDrawer, aboutFormOriginalData } = this.state;
    let { formDirty } = this.state;

    if (openDrawer) {
      const notes = wedding.notes.find(note => note.section === 'ABOUT_US');
      if (notesForm && (notes?.notes !== notesForm.notes || notes?.private !== notesForm.private)) {
        formDirty.notes = true;
      } else {
        formDirty.notes = false;
      }
      this.setState({ formDirty });
    }

    // delete aboutUsForm._v;
    // if (_.isEqual(aboutFormOriginalData, aboutUsForm) === false) {
    //   formDirty.aboutUs = true;
    // } else {
    //   formDirty.aboutUs = false;
    // }
    this.setState({ formDirty });
  }

  onSubmit = async values => {
    const { wedding } = this.props;

    if (wedding?.wedding?.printed) {
      confirm({
        ...getAboutUsPrintedConfirmation(),
        onOk: () => { this.saveCoupleInfo(values); },
        onCancel: () => { this.closeModal(); },
      });
    } else this.saveCoupleInfo(values)
  }

  saveCoupleInfo = async values => {
    try {
      const { wedding, changeAboutUs } = this.props;
      // this.setState({ loading: true });
      let { success, data } = await UpdateWeddingAbout(wedding.wedding._id, values);

      if (values.photo?.blob) {
        const payload = FlattenToFormData({ photo: values.photo });
        ({ success, data } = await UpdateWeddingPhoto(wedding.wedding._id, payload));
      }

      if (success) {
        notification.success({
          message: 'Gravado com sucesso',
          description: 'A informação foi gravada com sucesso!'
        });

        changeAboutUs(data);
      }

      await this.getInfo();
      this.checkDirtyForms();
      // this.setState({loading: false});
    }
    catch (e) {
      console.error(e);
      // this.setState({ loading: false });
    }
  };

  openNotes = () => {
    const { wedding, dispatch } = this.props;
    const { openDrawer } = this.state;
    if (!openDrawer) {
      const notes = wedding.notes.find(note => note.section === 'ABOUT_US');
      dispatch(initialize('wedding_notes_section_form', notes));
      this.setState({ openDrawer: true, loading: false });
    }
  };

  closeNotes = () => {
    const { wedding, dispatch } = this.props;
    const notes = wedding.notes.find(note => note.section === 'ABOUT_US');
    dispatch(initialize('wedding_notes_section_form', notes));
    this.setState({ openDrawer: false, openNotesConfirm: false });
  };

  checkBeforeCloseNotes = (values) => {
    const { wedding, dispatch } = this.props;
    const notes = wedding.notes.find(note => note.section === 'ABOUT_US');

    //If theres any change to the notes, ask before closing if we want to save the changes
    if (values && (notes?.notes !== values.notes || notes?.private !== values.private)) {
      dispatch(initialize('notes_modal_confirm_form', values));
      this.setState({ openNotesConfirm: true });
    }
    //Otherwise, just the close the notes
    else {
      this.setState({ openDrawer: false });
    }
  }

  saveNotes = async values => {
    const { wedding } = this.props;

    if (wedding?.wedding?.printed) {
      confirm({
        ...getPrintedConfirmation(),
        onOk: () => { this.updateNotes(values); },
        onCancel: () => { this.closeNotes(); },
      });
    } else this.updateNotes(values);
  };

  updateNotes = async values => {
    const { wedding, saveNotesOnReducer, dispatch } = this.props;
    this.setState({ saving: true });

    const { data } = await SaveSectionNotes(wedding.wedding._id, {
      ...values,
      section: 'ABOUT_US'
    });

    dispatch(initialize('wedding_notes_section_form', data));
    saveNotesOnReducer(data);

    const { openNotesConfirm } = this.state;
    if (openNotesConfirm) {
      return this.setState({
        saving: false,
        openDrawer: false,
        openNotesConfirm: false
      });
    }
    return this.setState({ saving: false });
  };

  openAddModal = () => {
    const { dispatch } = this.props;

    dispatch(initialize('manage_billing_form', {}));

    this.setState({
      openModal: true,
      editBillingIndex: undefined
    });
  };

  openEditModal = (billing, index) => {
    const { dispatch } = this.props;

    dispatch(initialize('manage_billing_form', billing));

    this.setState({
      openModal: true,
      editBillingIndex: index
    });
  };

  closeModal = () => {
    const { dispatch } = this.props;

    //Clear the form (don't use "reset", as it will lose the Form Validations)
    dispatch(initialize('manage_billing_form'));

    this.setState({
      openModal: false,
      editBillingIndex: undefined
    });
  };

  deleteBilling = async (index) => {
    try {
      const { wedding, changeAboutUs } = this.props;

      const billings = wedding.wedding.billings || [];

      if (billings.length > 0) {
        billings.splice(index, 1);
      }

      const { success, data } = await UpdateWeddingBillings(wedding.wedding._id, { billings: billings });

      if (success) {
        notification.success({
          message: 'Eliminado com sucesso',
          description: 'A informação foi eliminada com sucesso!'
        });

        changeAboutUs(data);
      }

      this.setState({
        editBillingIndex: undefined
      });
    }
    catch (e) {
      console.error(e);
    }
  }

  addModalSubmit = async (values) => {
    const { wedding } = this.props;

    if (wedding?.wedding?.printed) {
      confirm({
        ...getBillingPrintedConfirmation(),
        onOk: () => { this.saveBilling(values); },
        onCancel: () => { this.closeModal(); },
      });
    } else this.saveBilling(values);
  }

  saveBilling = async values => {
    try {
      const { wedding, changeAboutUs, dispatch } = this.props;
      const { editBillingIndex } = this.state;

      // this.setState({ loading: true });

      const billings = wedding.wedding.billings || [];

      //When editing, we must remove the original from the array and insert the new one in the same position
      if (editBillingIndex >= 0 && billings.length > 0) {
        billings.splice(editBillingIndex, 1, values);
      }
      //When adding a new Billing, we add it to the begginning of the array
      else {
        billings.unshift(values);
      }

      const { success, data } = await UpdateWeddingBillings(wedding.wedding._id, { billings: billings });
      if (success) {
        notification.success({
          message: 'Gravado com sucesso',
          description: 'A informação foi gravada com sucesso!'
        });

        changeAboutUs(data);
      }

      this.checkDirtyForms();

      this.setState({
        openModal: false,
        // loading: false,
        editBillingIndex: undefined
      });
    }
    catch (e) {
      console.error(e);
      // this.setState({ loading: false });
    }
  }

  render() {
    const { handleSubmit, wedding, dirty } = this.props;
    const { loading, saving, openModal, editBillingIndex, openDrawer, section, organizers, openNotesConfirm } = this.state;
    const { formDirty } = this.state;
    let checkDirty = dirty || formDirty.notes ? true : false;

    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Sobre nós</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'default'}
              icon={'read'}
              text={'Notas'}
              onClick={this.openNotes}
              notes={hasNotes(wedding.notes, 'ABOUT_US')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        {
          wedding?.wedding?.printed && <PrintedBar small={true} />
        }
        <ChoicesPageContainer printed={wedding?.wedding?.printed}>
          <AboutMenu section={section} printed={wedding?.wedding?.printed} wedding={wedding?.wedding} />
          <AboutContent
            section={section}
            editBilling={this.openEditModal}
            deleteBilling={this.deleteBilling}
            showModal={openModal}
            editBillingIndex={editBillingIndex}
            addModalSubmit={this.addModalSubmit}
            closeAddModal={this.closeModal}
            openAddModal={this.openAddModal}
            onSubmit={this.onSubmit}
            organizers={organizers}
            handleSubmit={handleSubmit}
            dirty={checkDirty}
            printed={wedding?.wedding?.printed}
            notesOpen={openDrawer}
            updateWeddingInfo={this.updateWeddingInfo}
          />
          <CommentsDrawerFixed
            open={openDrawer}
            loading={saving}
            onClose={this.checkBeforeCloseNotes}
            saveNotes={this.saveNotes}
            printed={wedding?.wedding?.printed}
          />
        </ChoicesPageContainer>
        {/* <CommentsDrawer
          title="Notas De Sobre Nós"
          open={openDrawer}
          loading={loading}
          onClose={this.checkBeforeCloseNotes}
          saveNotes={this.saveNotes}
        /> */}
        <NotesConfirmModal
          open={openNotesConfirm}
          loading={loading}
          onSubmit={this.saveNotes}
          closeModal={() => { this.setState({ openNotesConfirm: false }) }}
          exitWithoutSaving={this.closeNotes}
        />
      </React.Fragment>
    );
  }
}

const selectorNotes = formValueSelector('wedding_notes_section_form');
const selectorAboutUs = formValueSelector('wedding_aboutus_form');

AboutPage = reduxForm({
  form: 'wedding_aboutus_form',
  validate: validations
})(AboutPage);

const mapStateToProps = state => ({
  wedding: state.wedding,
  notesForm: {
    notes: selectorNotes(state, 'notes'),
    private: selectorNotes(state, 'private'),
  },
  aboutUsForm: {
    bride: selectorAboutUs(state, 'bride'),
    groom: selectorAboutUs(state, 'groom'),
    alternative: selectorAboutUs(state, 'alternative'),
    language: selectorAboutUs(state, 'language'),
    public: selectorAboutUs(state, 'public'),
    printed: selectorAboutUs(state, 'printed'),
    deleted: selectorAboutUs(state, 'deleted'),
    documents: selectorAboutUs(state, 'documents'),
    staff_number: selectorAboutUs(state, 'staff_number'),
    isWeddingOutOfInterval: selectorAboutUs(state, 'isWeddingOutOfInterval'),
    _id: selectorAboutUs(state, '_id'),
    wedding_place: selectorAboutUs(state, 'wedding_place'),
    date: selectorAboutUs(state, 'date'),
    price: selectorAboutUs(state, 'price'),
    min_people: selectorAboutUs(state, 'min_people'),
    createdAt: selectorAboutUs(state, 'createdAt'),
    updatedAt: selectorAboutUs(state, 'updatedAt'),
    _v: selectorAboutUs(state, '_v'),
    billings: selectorAboutUs(state, 'billings'),
    upgrade: selectorAboutUs(state, 'upgrade'),
    meetings: selectorAboutUs(state, 'meetings'),
    id: selectorAboutUs(state, 'id'),
    organizer: selectorAboutUs(state, 'organizer'),
  }
});

const mapActionToProps = dispatch => bindActionCreators(
  { changeAboutUs: ChangeAboutUs, saveNotesOnReducer: SaveNotes, changeNotes: SaveNotes, AddWedding, updateQuestionAction, dispatch },
  dispatch
);

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(AboutPage)
);
