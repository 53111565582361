import EN from './uk_flag.svg';
import FR from './france_flag.svg';
import PT from './portugal_flag.svg';
import ES from './spain_flag.svg';

export const GetAvailableLanguages = () => ['en', 'pt', 'es', 'fr'];

export const Languages = [
  {code: 'en', name: 'Inglês', icon: EN},
  {code: 'pt', name: 'Português', icon: PT},
  {code: 'es', name: 'Espanhol', icon: ES},
  {code: 'fr', name: 'Francês', icon: FR}
];

export const GetTranslationDescription = code =>
  Languages.find(x => x.code === code)?.name;
