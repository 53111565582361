import client from '../config/AxiosConfig';

export const CreateAttendingType = async (data) =>
  client.post(`/attendingType`, data);

export const GetAttendingType = async id =>
  client.get(`/attendingType/${id}`);

export const GetAttendingTypes = async () =>
  client.get(`/attendingType`);

export const UpdateAttendingType = async (id, data) =>
  client.put(`/attendingType/${id}`, data);

export const DeleteAttendingType = async id =>
  client.delete(`/attendingType/${id}`);