import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ImageContainer = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  width:  ${p => p?.orderModal ? 'auto' : '100%'};
  height: ${p => p?.orderModal ? '150px' : '100%'};
  margin-bottom: ${p => p?.orderModal ? '10px' : '0px'};
`;

const Image = styled.div`
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
`;

const ImageComponent = ({ width, ratio, children, color, url, align, orderModal }) => {
  return (
    <ImageContainer orderModal={orderModal}>
      <Image
        style={{
          width: width ? width : "100%",
          paddingTop: orderModal ? 'unset' : ratio ? 100 * ratio + "%" : "100%",
          backgroundColor: color ? color : "#FFF",
          backgroundImage: `url(${url})`,
          backgroundPosition: align ? align : "center center",
          backgroundOrigin: orderModal ? 'content-box' : 'unset',
          backgroundSize: 'contain'
        }}
      />
      {children}
    </ImageContainer>
  );
};

ImageComponent.propTypes = {
  url: PropTypes.string,
  ratio: PropTypes.number,
  width: PropTypes.string,
  color: PropTypes.string
};
export default ImageComponent;
