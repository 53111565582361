import React, { Component, Fragment } from 'react';
import { Icon, Checkbox, Row, Col, Modal, Button } from 'antd';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import Table from '../../components/table/Table';
import {
  HeaderButtonsContainer,
  TableButton,
  TableFilterSection
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import moment from 'moment';
import { GetColorSystemByType } from '../../infra/requests/ColorSystemRequests';
import { GetPaymentsByEmployee, GetPaymentsByEmployeeGroupedByAccount, GetPaymentsByEmployeePaginate, GetPaymentsGrouped, GetPaymentsPaginate, GetPaymentsToExport } from '../../infra/requests/PaymentRequests';
import Alert from "../../components/alert/Alert";
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import DateInput from '../../components/inputs/DateInput';
import { LocalLabel, StateLabel } from '../weddings/Styles';
import ModalPDF from '../employees/ModalPDF';
import PaymentTotals from './PaymentTotals';
import { withRouter } from 'react-router-dom';
import { getUserId, isCurrentUserAdmin, isCurrentUserChief, isCurrentUserEmployee, isCurrentUserHumanResources, isEmployeeOrChief } from '../../infra/helpers/Helpers';
import { GetSettingsByType } from '../../infra/requests/SettingRequests';
import ModalPaymentDeclaration from './ModalPaymentDeclaration';
import { getCurrencyNumber, getLocalColor, setDeclaration } from '../../infra/services/wedding/weddingUtils';
import { GetPaymentAccountNumbers, RemoveMarkPaymentAccountAsPaid } from '../../infra/requests/PaymentAccountRequests';
import { GetTextColor } from '../../infra/services/text/textUtils';
import { getFirstAndLastName, getLinesForEachWord, stylesPDF } from './utilPayments';

const { confirm } = Modal;

class ToBePayed extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    status: 3,
    selectAllRows: false,
    undoing: false,

    showDeclarationModal: false,
    modalValues: null,
    declaration: null,
    showPreviewPDF: false,

    columns: [
      {
        title: ' ',
        key: 'select',
        width: '30px',
        render: data =>
          <div className="sc-dxgOiQ fWHHkk" style={{ textAlign: 'center' }}>
            <Checkbox
              checked={data.checked}
              onChange={(e) => {
                let element = data;
                element.checked = !element.checked;
                let elements = this.state.rows;
                let index = elements.findIndex(x => x._id === element._id);
                if (index > -1) {
                  elements[index] = element;
                  this.setState({ rows: elements })
                }
              }}>
            </Checkbox>
          </div>
      },
      {
        title: 'Nº Conta',
        key: 'accountNumber',
        width: '90px',
        render: data => data.paymentAccount.accountNumber
      },
      {
        title: 'Funcionário',
        key: 'employee',
        render: data => data?.employee.name
      },
      {
        title: 'Entidade',
        key: 'entity',
        render: value => {
          const localWedding = value?.paymentAccount.entityValue;
          const local = getLocalColor(null, this.state.colors, localWedding);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
        }
      },
      {
        title: 'Intervalo de Datas',
        key: 'dataInterval',
        render: data => {
          const dateInterval = data.dateInterval;
          const dtFormat = 'DD-MM-YYYY';
          const start = moment.utc(dateInterval.start).format(dtFormat);
          const end = moment.utc(dateInterval.end).format(dtFormat);
          if (start === end) return start;
          else return start + ' / ' + end;
        }
      },
      {
        title: 'Oficial',
        render: data => {
          const officialPercentage = data.paymentAccount.officialPercentage;
          const value = Math.round((data.paymentAccount.value + data.paymentAccount.bonus) * officialPercentage);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (officialPercentage * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Bónus',
        render: data => {
          const notOfficialPercentage = (1 - data.paymentAccount.officialPercentage);
          const value = Math.round((data.paymentAccount.value + data.paymentAccount.bonus) * notOfficialPercentage);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (notOfficialPercentage * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Total',
        render: data => {
          const value = Math.round(data.paymentAccount.value + data.paymentAccount.bonus);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Acções',
        width: '120px',
        render: data => (
          <Fragment>
            <TableButton onClick={e => {
              const { history } = this.props;
              const { status } = this.state;
              const paymentAccountId = data.paymentAccount.id ? data.paymentAccount.id : data.paymentAccount._id;
              history.push(`/payment/${data.employee._id}/${status}/0/${paymentAccountId}`);
            }}>
              <Icon type="info" />
              {' Detalhe'}
            </TableButton>
          </Fragment>
        )
      }
    ],
    columnsEmployee: [
      {
        title: ' ',
        key: 'select',
        width: '30px',
        render: data =>
          <div className="sc-dxgOiQ fWHHkk" style={{ textAlign: 'center' }}>
            <Checkbox
              checked={data.checked}
              onChange={(e) => {
                let element = data;
                element.checked = !element.checked;
                let elements = this.state.rows;
                let index = elements.findIndex(x => x._id === element._id);
                if (index > -1) {
                  elements[index] = element;
                  this.setState({ rows: elements })
                }
              }}>
            </Checkbox>
          </div>
      },
      {
        title: 'Nº Conta',
        key: 'accountNumber',
        width: '90px',
        render: data => data.paymentAccount.accountNumber
      },
      {
        title: 'Entidade',
        key: 'entity',
        render: data => {
          const localWedding = data?.paymentAccount.entityValue;
          const local = getLocalColor(null, this.state.colors, localWedding);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
        }
      },
      {
        title: 'Intervalo de Datas',
        key: 'dataInterval',
        render: data => {
          const dateInterval = data.dateInterval;
          const dtFormat = 'DD-MM-YYYY';
          const start = moment.utc(dateInterval.start).format(dtFormat);
          const end = moment.utc(dateInterval.end).format(dtFormat);
          if (start === end) return start;
          else return start + ' / ' + end;
        }
      },
      {
        title: 'Oficial',
        render: data => {
          const officialPercentage = data.paymentAccount.officialPercentage;
          const value = Math.round((data.paymentAccount.value + data.paymentAccount.bonus) * officialPercentage);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (officialPercentage * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Bónus',
        render: data => {
          const notOfficialPercentage = (1 - data.paymentAccount.officialPercentage);
          const value = Math.round((data.paymentAccount.value + data.paymentAccount.bonus) * notOfficialPercentage);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (notOfficialPercentage * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Total',
        render: data => {
          const value = Math.round(data.paymentAccount.value + data.paymentAccount.bonus);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Acções',
        width: '120px',
        render: data => (
          <Fragment>
            <TableButton onClick={e => {
              const { history } = this.props;
              const { status } = this.state;
              const paymentAccountId = data.paymentAccount.id ? data.paymentAccount.id : data.paymentAccount._id;
              history.push(`/payment/${data.paymentAccount.employee}/${status}/0/${paymentAccountId}`);
            }}>
              <Icon type="info" />
              {' Detalhe'}
            </TableButton>
          </Fragment>
        )
      }
    ],
    columnsCurrentEmployee: [
      {
        title: 'Nº Conta',
        key: 'accountNumber',
        width: '90px',
        render: data => data.paymentAccount.accountNumber
      },
      {
        title: 'Entidade',
        key: 'entity',
        render: data => {
          const localWedding = data?.paymentAccount.entityValue;
          const local = getLocalColor(null, this.state.colors, localWedding);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
        }
      },
      {
        title: 'Intervalo de Datas',
        key: 'dataInterval',
        render: data => {
          const dateInterval = data.dateInterval;
          const dtFormat = 'DD-MM-YYYY';
          const start = moment.utc(dateInterval.start).format(dtFormat);
          const end = moment.utc(dateInterval.end).format(dtFormat);
          if (start === end) return start;
          else return start + ' / ' + end;
        }
      },
      {
        title: 'Oficial',
        render: data => {
          const officialPercentage = data.paymentAccount.officialPercentage;
          const value = Math.round((data.paymentAccount.value + data.paymentAccount.bonus) * officialPercentage);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (officialPercentage * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Bónus',
        render: data => {
          const notOfficialPercentage = (1 - data.paymentAccount.officialPercentage);
          const value = Math.round((data.paymentAccount.value + data.paymentAccount.bonus) * notOfficialPercentage);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (notOfficialPercentage * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Total',
        render: data => {
          const value = Math.round(data.paymentAccount.value + data.paymentAccount.bonus);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Acções',
        width: '120px',
        render: data => (
          <Fragment>
            <TableButton onClick={e => {
              const { history } = this.props;
              const { status } = this.state;
              const paymentAccountId = data.paymentAccount.id ? data.paymentAccount.id : data.paymentAccount._id;
              history.push(`/payment/${data.paymentAccount.employee}/${status}/0/${paymentAccountId}`);
            }}>
              <Icon type="info" />
              {' Detalhe'}
            </TableButton>
          </Fragment>
        )
      }
    ],
    rows: [],
    total: 0,
    totalValue: 0,
    totalBonus: 0,

    colors: {
      colorSys1: null,
      colorSys2: null
    },

    searchNomeColaborador: '',
    searchDataInicial: null,
    searchDataFinal: null,
    searchEntities: [],
    searchEntity: null,
    accountList: [],
    searchAccountNumber: null,
    updateChild: null,

    printing: false,
    pdfDocumentModal: false,
    pdfDocumentSize: "B6",
    pdfDocument: null,
  };

  componentDidMount = async () => {
    this.props.type === 0 ? await this.updateDataTable() : await this.updateDataTableEmployee();
    this.getLocalColors();
    this.getDeclaration();
    this.getEntities();
    this.getPaymentAccountNumbers();
    this.setState({ updateChild: this.props.updateChild });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.updateChild !== this.props.updateChild) {
      this.setState({ updateChild: this.props.updateChild });
      this.props.type === 0 ? this.updateDataTable() : this.updateDataTableEmployee();
    }

    if (prevProps.activeTab !== this.props.activeTab) {
      this.setState({
        selectAllRows: false,
        searchNomeColaborador: '',
        searchDataInicial: null,
        searchDataFinal: null,
        searchEntity: null,
        searchAccountNumber: null,
      }, () => {
        this.props.type === 0 ? this.updateDataTable() : this.updateDataTableEmployee();
      });
    }
  }

  getEntities = async () => {
    const result = await GetSettingsByType(1);

    if (result.success) {
      let list = [];
      result.data.forEach(element => {
        if (element.entityValue !== 3) list.push({ _id: element.entityValue, name: element.name });
      });
      this.setState({ searchEntities: list });
    } else console.error(result.message);
  }

  getPaymentAccountNumbers = async () => {
    const { status } = this.state;
    const result = await GetPaymentAccountNumbers(status, this.props.type === 0 ? null : this.props.employeeId, false);
    // console.warn('Result', result);
    if (result.success && result.data) {
      const list = result.data.map(m => {
        return { _id: m, name: m }
      });
      this.setState({ accountList: list });
    }
  }

  getDeclaration = async () => {
    const result = await GetSettingsByType(5);
    this.setState({ declaration: result.success && result.data.length > 0 ? result.data[0] : null });
  }

  getLocalColors = async () => {
    const resColorSys1 = await GetColorSystemByType(4);
    const colorSys1 = resColorSys1.success && resColorSys1.data ? resColorSys1.data.color : '#f7b5d6';
    const resColorSys2 = await GetColorSystemByType(5);
    const colorSys2 = resColorSys2.success && resColorSys2.data ? resColorSys2.data.color : '#b6d7a9';
    this.setState({ colors: { colorSys1, colorSys2 } });
  }

  updateDataTable = async () => {
    const { status, currentPage, pageSize } = this.state;
    const { searchNomeColaborador, searchEntity, searchAccountNumber, searchDataInicial, searchDataFinal } = this.state;
    const { selectAllRows } = this.state;
    let { totalValue, totalBonus } = this.state;
    this.setState({ loading: true });
    try {
      let filter = { status: status };
      if (searchNomeColaborador !== '') filter['name'] = searchNomeColaborador;
      if (searchEntity !== null && searchEntity !== '') filter['entity'] = searchEntity;
      if (searchAccountNumber !== null) filter['accountNumber'] = searchAccountNumber;
      // if (searchDataInicial !== null) filter['startDate'] = searchDataInicial;
      // if (searchDataFinal !== null) filter['endDate'] = searchDataFinal;

      let result = await GetPaymentsPaginate(currentPage, pageSize, JSON.stringify(filter));
      // console.warn('ResPaid', result);

      if (result.success && result.data.items.length > 0) {
        result.data.items = result.data.items.map((element) => {
          totalValue = totalValue + element.paymentAccount.value;
          totalBonus = totalBonus + element.paymentAccount.bonus;

          return {
            ...element,
            checked: selectAllRows ? true : false
          }
        }).sort((a, b) => a.paymentAccount.accountNumber > b.paymentAccount.accountNumber ? 1 : (b.paymentAccount.accountNumber > a.paymentAccount.accountNumber) ? -1 : 0)
      }

      this.setState({
        rows: result.data.items,
        total: result.data.total,
        pageSize: result.data.limit,
        totalValue,
        totalBonus,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  updateDataTableEmployee = async () => {
    let { status, totalValue, totalBonus, currentPage, pageSize } = this.state;
    const { searchEntity, searchAccountNumber, searchDataInicial, searchDataFinal } = this.state;
    const { selectAllRows } = this.state;

    this.setState({ loading: true });
    try {
      let filter = { employee: this.props.employeeId, status: status }

      if (searchEntity !== null && searchEntity !== '') filter['entity'] = searchEntity;
      if (searchAccountNumber !== null) filter['accountNumber'] = searchAccountNumber;
      // if (searchDataInicial !== null) filter['startDate'] = searchDataInicial;
      // if (searchDataFinal !== null) filter['endDate'] = searchDataFinal;

      let result = await GetPaymentsPaginate(currentPage, pageSize, JSON.stringify(filter));
      // console.warn('ResPaid', result);

      if (result.success && result.data.items.length > 0) {
        result.data.items = result.data.items.map((element) => {
          return {
            ...element,
            checked: selectAllRows ? true : false
          }
        });
      }

      for (let i = 0; i < result.data.items.length; i++) {
        if (!isCurrentUserAdmin()) {
          totalValue = totalValue + result.data.items[i].paymentAccount.value;
          totalBonus = totalBonus + result.data.items[i].paymentAccount.bonus;
        }
      }

      this.setState({
        rows: result.data.items,
        total: result.data.total,
        pageSize: result.data.limit,
        totalValue,
        totalBonus,
        employee: result.success && result.data.items.length > 0 && result.data.items[0].length > 0 ? result.data.items[0].employee : null,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  onPreviewPDF = (row) => {
    this.setState({ selectedRow: row }, () => {
      this.setState({ showPreviewPDF: true })
    })
  }

  closeModal = () => {
    this.setState({ showPreviewPDF: false })
  }

  printPaymentAccount = async () => {
    const { selectAllRows, rows } = this.state;
    const { status, searchNomeColaborador, searchEntity, searchAccountNumber, searchDataInicial, searchDataFinal } = this.state;
    const { pdfDocumentSize } = this.state;
    let selectedPayments = [];

    if (selectAllRows) {
      this.setState({
        loading: true,
        printing: true
      });
      let filter = { status }
      if (this.props.type !== 0) filter['employee'] = this.props.employeeId;
      if (searchNomeColaborador !== '') filter['name'] = searchNomeColaborador;
      if (searchEntity !== null) filter['entity'] = searchEntity;
      if (searchAccountNumber !== null) filter['accountNumber'] = searchAccountNumber;
      // if (searchDataInicial !== null) filter['startDate'] = searchDataInicial;
      // if (searchDataFinal !== null) filter['endDate'] = searchDataFinal;

      const result = await GetPaymentsToExport(filter);
      if (result?.success) {
        selectedPayments = result.data || [];
      } else {
        this.setState({
          loading: false,
          printing: false
        });
      }
    } else {
      selectedPayments = rows.filter(x => x.checked === true);
      if (selectedPayments?.length === 0) {
        this.setState({
          loading: false,
          printing: false
        });
        return Alert.new({
          type: "error",
          title: "Atenção",
          text: "É necessário selecionar pelo menos um pagamento."
        });
      } else {
        this.setState({
          loading: true,
          printing: true
        });
      }
    }

    if (selectedPayments?.length == 0) {
      this.setState({
        loading: false,
        printing: false
      });
      return Alert.new({
        type: "error",
        title: "Erro",
        text: "Não existem pagamentos para imprimir."
      });
    }

    let document =
      <Document title='Envelopes'>
        {selectedPayments.map((sp, index) => {
          return <Page key={index} size={pdfDocumentSize} orientation="landscape" wrap>
            <View style={stylesPDF.section} orientation="landscape">
              <View style={stylesPDF.row}>
                <View style={stylesPDF.column}>
                  <Text style={stylesPDF.text}>Contas</Text>
                  <Text style={stylesPDF.text}>{sp.paymentAccount.accountNumber.split('/')[0]}</Text>
                  <Text style={stylesPDF.text}>{sp.paymentAccount.accountNumber.split('/')[1]}</Text>
                </View>
                <View style={stylesPDF.column}>
                  <Text style={stylesPDF.text}>{sp.paymentAccount.entity}</Text>
                </View>
                <View style={stylesPDF.column}>
                  {getFirstAndLastName(sp.employee.name)}
                </View>
                <View style={stylesPDF.sectionPayments}>
                  {sp.payments
                    .sort((a, b) => a?.weddingDate && b?.weddingDate && a?.weddingDate > b?.weddingDate ? 1 : -1)
                    .map((p, pIndex) => {
                      return <View key={pIndex} style={stylesPDF.payment}>
                        <Text style={stylesPDF.text}>{moment.utc(p.weddingDate).date()} {moment.utc(p.weddingDate).locale('pt-br').format('MMM')}</Text>
                        <Text style={stylesPDF.text}>{Math.round((p.value + p.bonus) * (1 - p.officialPercentage))}</Text>
                      </View>
                    })}
                </View>
                <View style={stylesPDF.total}>
                  <Text style={stylesPDF.text}>{Math.round((sp.paymentAccount.value + sp.paymentAccount.bonus) * (1 - sp.paymentAccount.officialPercentage))}</Text>
                </View>
              </View>
            </View>
          </Page>
        })}
      </Document>;

    this.setState({
      pdfDocument: document,
      pdfDocumentModal: true,
      loading: false,
      printing: false
    })
  }

  closePdfDocumentModal = () => {
    this.setState({ pdfDocumentModal: false })
  }

  onSelectedRowKeysChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  confirmUndoing = () => {
    const { rows } = this.state;
    let selectedPayments = rows.filter(x => x?.checked === true);
    if (selectedPayments?.length === 0) {
      Alert.new({
        type: 'error',
        title: 'Atenção',
        text: 'É necessário selecionar pelo menos um pagamento.'
      });
      return;
    }

    confirm({
      title: 'Tem a certeza que pretende marcar essas contas como pendentes?',
      content: (<div>
        <p>As contas selecionadas serão listadas em "Pagamentos pendentes".</p>
      </div>
      ),
      okType: 'primary',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: () => this.undoPaidPayments(),
      onCancel: () => null,
    });
  }

  undoPaidPayments = async () => {
    const { rows } = this.state;
    this.setState({ loading: true });
    let selectedPayments = rows.filter(x => x?.checked === true);
    const paymentAccounts = selectedPayments?.length
      ? selectedPayments?.map(m => m?.paymentAccount)
      : [];

    const result = await RemoveMarkPaymentAccountAsPaid(paymentAccounts);
    if (result?.success) {
      Alert.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Contas revertidas para o estado pendentes!'
      });
      return this.updateDataTable();
    } else {
      this.setState({ loading: false });
    }
  }

  handleChangePage = currentPage => {
    this.setState({ currentPage }, () => {
      if (this.props.type === 0) {
        this.updateDataTable();
      } else {
        this.updateDataTableEmployee();
      }
    });
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, () => {
      if (this.props.type === 0) {
        this.updateDataTable();
      } else {
        this.updateDataTableEmployee();
      }
    });
  };

  render() {
    const { loading, currentPage, pageSize, selectAllRows } = this.state;
    const { columns, columnsEmployee, columnsCurrentEmployee, rows, total, showPreviewPDF } = this.state;
    const { showDeclarationModal, modalValues, declaration } = this.state;

    return (
      <React.Fragment>
        <div className='btn-payment-container'>
          {isCurrentUserAdmin() && <BaseButton
            type={'default'}
            icon={'undo'}
            style={{ marginRight: 8 }}
            text={'Marcar como pendentes'}
            onClick={() => this.confirmUndoing()}
          />}
          {isCurrentUserAdmin() ? <BaseButton
            type={'primary'}
            icon={'printer'}
            block
            text={'Imprimir'}
            onClick={() => this.printPaymentAccount()}
          /> : null}
        </div>

        <TableFilterSection>
          <Row gutter={24}>
            {this.props.type === 0 ? <Col lg={6} md={6} sm={8} xs={24}>
              <TextInput
                type="text"
                label="Pesquisa"
                placeholder="Pesquisa por nome de funcionário"
                input={{
                  value: this.state.searchNomeColaborador,
                  onChange: (event) => { this.setState({ searchNomeColaborador: event.target.value }, () => this.updateDataTable()) }
                }}
                meta={{}}
              />
            </Col> : null}
            <Col lg={4} md={6} sm={8} xs={24}>
              <SelectInput
                label="Entidade"
                allowClear
                placeholder="Escolher entidade"
                data={this.state.searchEntities}
                input={{
                  value: this.state.searchEntity,
                  onChange: (event) => {
                    // console.warn('Evt', event);
                    this.setState({ searchEntity: event === '' ? null : event }, () => {
                      this.props.type === 0 ? this.updateDataTable() : this.updateDataTableEmployee();
                    })
                  }
                }}
                meta={{ valid: true }}
              />
            </Col>
            {isCurrentUserAdmin() ? <Col xs={24} sm={8} md={6} lg={4}>
              <SelectInput
                label="Nº de Conta"
                allowClear
                placeholder="Escolher conta"
                data={this.state.accountList}
                input={{
                  value: this.state.searchAccountNumber,
                  onChange: (event) => { this.setState({ searchAccountNumber: event !== '' ? event : null }, () => this.updateDataTable()) }
                }}
                meta={{ valid: true }}
              />
            </Col> : null}
            {/* <Col xs={12} md={4}>
              <DateInput
                label="Data Inicial"
                placeholder="Selecionar data"
                input={{
                  value: this.state.searchDataInicial,
                  onChange: (event) => {
                    this.setState({ searchDataInicial: event }, () => {
                      this.props.type === 0 ? this.updateDataTable() : this.updateDataTableEmployee();
                    });
                  }
                }}
                meta={{ valid: true }}
              />
            </Col>
            <Col xs={12} md={4}>
              <DateInput
                label="Data Final"
                placeholder="Selecionar data"
                input={{
                  value: this.state.searchDataFinal,
                  onChange: (event) => {
                    this.setState({ searchDataFinal: event }, () => {
                      this.props.type === 0 ? this.updateDataTable() : this.updateDataTableEmployee();
                    });
                  }
                }}
                meta={{ valid: true }}
              />
            </Col> */}
          </Row>
        </TableFilterSection>

        <div className='payment-table-container'>
          {isCurrentUserAdmin() ? <div className="sc-dxgOiQ fWHHkk checkbox-simple-payment">
            <Checkbox
              checked={selectAllRows}
              onChange={(e) => {
                rows.forEach(r => {
                  r.checked = e.target.checked;
                });
                this.setState({ selectAllRows: e.target.checked });
              }}>
            </Checkbox>
          </div> : null}
          <Table
            columns={this.props.type === 0 ? columns : isCurrentUserChief() || isCurrentUserEmployee() ? columnsCurrentEmployee : columnsEmployee}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="euro"
            emptyText={'Não existem pagamentos'}
            total={total}
            rowKey={'paymentAccount.id'}
            hasPagination={true}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>

        <PaymentTotals
          hasSelection={isCurrentUserAdmin() ? true : false}
          receipts={false}
          value={!isCurrentUserAdmin() ? rows.reduce((a, b) => a + ((b['paymentAccount'].value + b['paymentAccount'].bonus) * b['paymentAccount'].officialPercentage || 0), 0) : rows.filter(x => x.checked == true).reduce((a, b) => a + ((b['paymentAccount'].value + b['paymentAccount'].bonus) * b['paymentAccount'].officialPercentage || 0), 0)}
          bonus={!isCurrentUserAdmin() ? rows.reduce((a, b) => a + ((b['paymentAccount'].value + b['paymentAccount'].bonus) * (1 - b['paymentAccount'].officialPercentage) || 0), 0) : rows.filter(x => x.checked == true).reduce((a, b) => a + ((b['paymentAccount'].value + b['paymentAccount'].bonus) * (1 - b['paymentAccount'].officialPercentage) || 0), 0)}
          total={!isCurrentUserAdmin() ? rows.reduce((a, b) => a + (b['paymentAccount'].value + b['paymentAccount'].bonus || 0), 0) : rows.filter(x => x.checked == true).reduce((a, b) => a + (b['paymentAccount'].value + b['paymentAccount'].bonus || 0), 0)}
        />

        {showPreviewPDF && <ModalPDF
          openModal={showPreviewPDF}
          closeModal={this.closeModal}
          title='Recibo'
          file={this.state.selectedRow?.paymentAccount.receipt}
        />}

        <Modal visible={this.state.pdfDocumentModal} title={'Envelopes'} width={800} maskClosable onCancel={this.closePdfDocumentModal} footer={[<Button key='cancel' type='primary' onClick={this.closePdfDocumentModal}>OK</Button>]}>
          <PDFViewer style={{ width: '100%', height: '390px', margin: '-24px 0' }}>
            {this.state.pdfDocument}
          </PDFViewer>
        </Modal>

        <ModalPaymentDeclaration
          isOpen={showDeclarationModal}
          onClose={() => this.setState({ showDeclarationModal: false, modalValues: null })}
          onSubmit={async e => this.setState({ showDeclarationModal: false, modalValues: null })}
          initialValues={modalValues}
          blocked={modalValues ? modalValues.declaration : false}
          declaration={setDeclaration(declaration, modalValues)}
        />
      </React.Fragment >
    );
  }
}

export default withRouter(ToBePayed);
