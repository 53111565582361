import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { Tree, Spin } from 'antd';
import { InputDiv, InputLabelDiv, StyledSelectInput } from './InputStyles';

const { TreeNode } = Tree;

const TreeInput = ({
  input,
  data,
  dataKey,
  dataLabel,
  label,
  disabled,
  checkable,
  autoExpandParent,
  showLine,
  first,
  meta,
  translatable,
  activeLanguage,
  costumeLabel,
  requiredError,
}) => {
  const { invalid, submitFailed } = meta;
  let showError = invalid && submitFailed;

  if (requiredError) {
    showError = true;
  }

  const onCheck = async (checkedKeys, info) => {
    input.onChange(checkedKeys || '');
  };

  // const onSelect = (selectedKeys, info) => {
  //   console.log('selected', selectedKeys);
  //   console.log('info', info);
  // };

  const renderTreeNodes = (data) => {
    return data.map(item => {
      if (item?.children && item?.children?.length > 0) {
        return (
          <TreeNode
            title={costumeLabel
              ? costumeLabel(item)
              : translatable
                ? item[dataLabel][activeLanguage.code]
                : item[dataLabel]}
            key={item[dataKey]}
            dataRef={item}
            disabled={item?.disabled || false}
            disableCheckbox={item?.disableCheckbox}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode
        title={costumeLabel
          ? costumeLabel(item)
          : translatable
            ? item[dataLabel][activeLanguage.code]
            : item[dataLabel]}
        key={item[dataKey]}
        disabled={item?.disabled || false}
        disableCheckbox={item?.disableCheckbox}
        {...item} />;
    });
  }

  return (
    <InputDiv first={first || !label} >
      {label && <InputLabelDiv>{label}</InputLabelDiv>}
      <Tree
        checkable={checkable}
        autoExpandParent={autoExpandParent}
        disabled={disabled}
        blockNode
        // defaultExpandedKeys={['0-0-0', '0-0-1']}
        // defaultSelectedKeys={['0-0-0', '0-0-1']}
        defaultCheckedKeys={input?.value || ''}
        // onSelect={onSelect}
        onCheck={onCheck}
      >
        {renderTreeNodes(data)}
      </Tree>
    </InputDiv>
  );
};

TreeInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  label: PropTypes.string,
  checkable: PropTypes.bool,
  autoExpandParent: PropTypes.bool,
  disabled: PropTypes.bool,
  showLine: PropTypes.bool,
};

TreeInput.defaultProps = {
  data: [],
  dataKey: '_id',
  dataLabel: 'label',
  checkable: true,
  autoExpandParent: true,
  disabled: false,
  showLine: false,
};

export default withLocalize(TreeInput);
