import React, { Component, Fragment } from 'react';
import Table from '../../components/table/Table';
import {
    Popconfirm,
    Icon,
    Collapse,
    Row,
    Col,
} from 'antd';
import {
    TableButton,
    SectionTitle,
    TableFilterSection
} from '../../styles/BasicStyles';
import { withLocalize } from 'react-localize-redux';
import Alert from "../../components/alert/Alert";
import ModalEmail from './EmailModal';
import { CreateDynamicEmail, DeleteDynamicEmail, GetAllGroupedDynamicEmail, GetDynamicEmailByType, UpdateDynamicEmail } from '../../infra/requests/DynamicEmailRequests';
import ModalYearEmail from './EmailYearModal';
import { withRouter } from 'react-router-dom';
import BaseButton from '../../components/buttons/BaseButton';
import { CreateDEYear, DeleteDEYear, EmailYearType, GetALLDEYear, GetDEYearByType, UpdateDEYear } from '../../infra/requests/DynamicEmailYearRequests';
import Alerts from '../../components/alert/Alert';
import TextInput from '../../components/inputs/TextInput';
import NumberInput from '../../components/inputs/NumberInput';
import { getUserId } from '../../infra/helpers/Helpers';
import YearInput from '../../components/inputs/YearInput';


const { Panel } = Collapse;

const RemoveMessage = (
    <div>
        <div>Tem a certeza que quer remover esta tradução? </div>
        <div>
            Toda a informação da tradução será removida da plataforma e não
            conseguirá ser recuperado.
        </div>
    </div>
);


class EmailTable extends Component {
    state = {
        loading: false,
        pageSize: 15,
        currentPage: 1,
        filtersYear: { type: 'C' },
        selectedIndex: null,
        submitted: false,

        loadingSign: false,
        saveSign: false,
        cancelSign: false,
        signature: null,
        submittedSign: false,

        rowsYear: [],
        totalYear: 0
    };

    componentDidMount = async () => {
        await this.getSignature();
    };

    getSignature = async () => {
        this.setState({ loadingSign: true });

        try {
            const result = await GetDEYearByType(EmailYearType.SIGNATURE);
            // console.warn('Res', result);

            this.setState({
                signature: result?.success && result?.data ? result?.data : null,
                loadingSign: false
            });
        } catch (e) {
            this.setState({ loadingSign: false });
            console.error(e);
        }
    }

    saveSignature = async () => {
        const { signature } = this.state;
        this.setState({ submittedSign: true });

        if (signature?.contentPT === '') {
            return;
        }

        this.setState({ saveSign: true });

        const result = await UpdateDEYear(signature);
        // console.warn('Res', result);

        if (result.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso!',
                text: 'Assinatura Atualizada!'
            });
            this.setState({ saveSign: false, submittedSign: false });
        } else {
            Alerts.new({
                type: 'error',
                title: 'Erro!',
                text: 'Não foi possível atualizar a assinatura!'
            });
            this.setState({ saveSign: false, submittedSign: false }, () => this.getSignature());
        }
    }

    render() {
        const { loading, currentPage, pageSize } = this.state;
        const { loadingSign, saveSign, cancelSign, submittedSign } = this.state;
        let { signature } = this.state;
        // const { showEmailModal, modalEmailData, modalEmailLanguages } = this.state;
        // const { showYearModal, modalYearType } = this.state;

        return (
            <React.Fragment>
                <SectionTitle>Assinatura</SectionTitle>
                <Row gutter={[12, 12]} style={{ marginBottom: 10 }}>
                    <Col lg={8} md={10} sm={14} xs={24}>
                        <TextInput
                            noLabel={true}
                            input={{
                                value: signature?.contentPT,
                                onChange: (e) => {
                                    signature.contentPT = e.target.value;
                                    this.setState({ signature })
                                }
                            }}
                            meta={{}}
                            requiredError={submittedSign && signature && signature.contentPT === '' ? true : false}
                            type="text"
                            placeholder="Insira a assinatura"
                        />
                    </Col>
                    <Col lg={2} md={4} sm={10} xs={24}>
                        <div style={{ display: 'flex' }}>
                            <BaseButton
                                type={'primary'}
                                icon={'save'}
                                text={'Guardar'}
                                loading={saveSign}
                                onClick={() => this.saveSignature()}
                            />
                            <BaseButton
                                style={{ marginLeft: 10 }}
                                type={'default'}
                                icon={'close'}
                                text={'Cancelar'}
                                loading={cancelSign}
                                onClick={() => this.getSignature()}
                            />
                        </div>
                    </Col>
                </Row>

                {/* <ModalEmail
                    isOpen={showEmailModal}
                    initialValues={modalEmailData}
                    languages={modalEmailLanguages}
                    onSubmit={($event) => this.saveEmail($event)}
                    onClose={() => this.setState({ showEmailModal: false, modalEmailData: null, modalEmailLanguages: [] })}>
                </ModalEmail> */}

                {/* <ModalYearEmail
                    isOpen={showYearModal}
                    type={modalYearType}
                    onClose={() => this.setState({ showYearModal: false, modalYearType: null })}>
                </ModalYearEmail> */}
            </React.Fragment>
        );
    }
}

export default withRouter(EmailTable);