import client from '../config/AxiosConfig';

export const GetUser = async id =>
  await client.get(`/users/${id}`);

export const GetUsers = async (currentPage, limit) =>
  await client.get(`/users/${currentPage}/${limit}`);

export const UpdateUser = async (id, data) =>
  await client.put(`/users/${id}`, data);

export const CreateUser = async data =>
  await client.post('/users', data);

export const DeleteUser = async id =>
  await client.delete(`/users/${id}`);

export const GetWeddingUsers = async (currentPage, limit, filter = {}) =>
  await client.get(`/users/weddings/${currentPage}/${limit}?filter=${filter}`);

export const ResendEmail = async data =>
  await client.post('/users/resend', data);

export const GetUsersList = async () =>
  await client.get('/users/list');

export const GetOrganizerList = async (filter = {}) =>
  await client.get(`/users/organizerList?filter=${JSON.stringify(filter)}`);

export const GetAttendingList = async (filter = {}) =>
  await client.get(`/users/attendingList?filter=${JSON.stringify(filter)}`);

export const GetAvailableAttendingList = async (data) =>
  await client.post('/users/availableAttendingList', data);

export const GetPermissionAttendingList = async () =>
  await client.get('/users/permissionAttendingList');

export const GetAttendingEmployees = async () =>
  await client.get('/users/employeeAttending');

export const GetWeddingGuests = async () =>
  await client.get('/users/guests');

export const GetAvailableEmployeesAttending = async (date) =>
  client.get(`/users/availableEmployeeAttending?date=${date}`);

export const CreateCoupleReserve = async data =>
  await client.post('/users/createCoupleReserveBO', data);

export const GetAttendingOrdered = async () =>
  client.get(`/users/attendingListOrder`);

export const AddAttendingOrder = async (id) =>
  client.post(`/users/${id}/addAttendingOrder`);

export const RemoveAttendingOrder = async (id) =>
  client.post(`/users/${id}/removeAttendingOrder`);

export const UpdateAttendingOrder = async (data) =>
  client.post(`/users/attendingOrder`, data);

// export const ResetEmail = async data => await client.post('users/reset-emails');