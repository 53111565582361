import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Row, Col} from 'antd';
import {TableFilterSection} from '../../styles/BasicStyles';
import SelectInput from '../../components/inputs/SelectInput';
import DateInput from '../../components/inputs/DateInput';

class ArchivedFilters extends Component {
  state = {
    wedding_place: '',
    date: ''
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({[field]: value}, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const {queryChange} = this.props;
    const query = {};
    Object.keys(this.state).map(key => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const {locals} = this.props;
    const {wedding_place, date} = this.state;
    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={6}>
            <DateInput
              label="Data do casamento"
              placeholder="Selecionar data"
              input={{
                value: date,
                onChange: value => this.onInputChange('date', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={4}>
            <SelectInput
              label="Local"
              allowClear
              placeholder="Escolher local do casamento"
              data={locals}
              input={{
                value: wedding_place,
                onChange: value => this.onInputChange('wedding_place', value)
              }}
              meta={{valid: true}}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

const mapStateToProps = state => ({
  locals: state.locals
});

export default connect(mapStateToProps)(ArchivedFilters);
