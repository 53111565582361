import client from "../config/AxiosConfig";

export const GetInspirations = async (page, limit, filter = "") =>
  await client.get(`/inspirations/${page}/${limit}?filter=${filter}`);

export const DeleteInspirationById = async imgId =>
  await client.delete(`/inspirations/${imgId}`);

export const GetInspiration = async id =>
  await client.get(`/inspirations/${id}`);

export const EditInspiration = async (id, data) =>
  await client.put(`/inspirations/${id}`, data);

export const AddInspiration = async data =>
  await client.post("/inspirations", data);

export const GetTags = async (filter = {}) =>
  await client.get(`/inspirations/tags/?filter=${JSON.stringify(filter)}`);
