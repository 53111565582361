import styled from 'styled-components';

export const AddIngredientButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 34px;
  text-align: center;
  border-radius: 4px;
  height: 32px;
  border: 1px solid ${({theme}) => theme.inputBorderColor};
`;

export const DeleteIngredientButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 34px;
  text-align: center;
  border-radius: 4px;
  height: 32px;
  border: 1px solid ${({theme}) => theme.inputBorderColor};
`;
