import React, { Component, Fragment } from 'react';
import Table from '../../../components/table/Table';
import {
    Row,
    Col,
    Popconfirm,
    Icon,
    DatePicker,
} from 'antd';
import {
    HeaderButtonsContainer,
    TableButton,
    SectionTitle,
    TableFilterSection
} from '../../../styles/BasicStyles';
import BaseButton from '../../../components/buttons/BaseButton';
import TextInput from '../../../components/inputs/TextInput';
import { withLocalize } from 'react-localize-redux';
import Alert from "../../../components/alert/Alert";
import moment from 'moment';
import Alerts from '../../../components/alert/Alert';
import { CreatePASetting, DeletePASetting, GetMaxPASetting, GetPASettingsPaginated, UpdatePASetting } from '../../../infra/requests/PaymentAccountSettingRequests';
import PaymentAccountsFilters from './PaymentAccountsFilters';

const RemoveMessage = (
    <div>
        <div>Tem a certeza que quer remover esta conta?</div>
        <div>
            Toda a informação da conta de pagamento será removida da plataforma e esta deixará de ser tida em conta
            na criação de pagamentos.
        </div>
    </div>
);

class PaymentAccountsTable extends Component {
    state = {
        pageSize: 15,
        currentPage: 1,
        loading: false,
        filters: {
            year: moment.utc().startOf('year').format('YYYY')
        },

        accountModal: null,
        editRow: null,

        columns: [
            {
                title: 'Conta',
                render: data => {
                    return data?.accountNumber;
                }
            },
            {
                title: 'Data inicial',
                render: data => {
                    return moment.utc(data.startDate).format('DD/MM/YYYY');
                }
            },
            {
                title: 'Data final',
                render: data => {
                    const { editRow } = this.state;

                    return !this.isEditing(data)
                        ? moment.utc(data.endDate).format('DD/MM/YYYY')
                        : <DatePicker
                            value={editRow?.endDate ? moment.utc(editRow.endDate) : null}
                            format={'DD/MM/YYYY'}
                            placeholder='Selecione data'
                            onChange={this.onChangeEndDate}
                            style={{ width: '100%', marginTop: 5 }}
                            disabledDate={this.disabledEndDate} />;
                }
            },
            {
                title: 'Acções',
                width: '180px',
                render: data => {
                    return this.isEditing(data)
                        ? <React.Fragment>
                            <TableButton onClick={() => this.saveRow()}>
                                <Icon type="save" />
                                {' Guardar'}
                            </TableButton>
                            <TableButton onClick={() => this.cancelEditRow()}>
                                <Icon type="close" />
                                {' Cancelar'}
                            </TableButton>
                        </React.Fragment>
                        : data?.isMaxSetting && data?.isEmpty
                            ? <React.Fragment>
                                <TableButton onClick={e => this.setState({ editRow: { ...data } })}>
                                    <Icon type="edit" />
                                    {' Editar'}
                                </TableButton>
                                <TableButton onClick={e => e.stopPropagation()}>
                                    <Popconfirm
                                        placement="topRight"
                                        title={RemoveMessage}
                                        okText="Remover"
                                        onConfirm={() => this.deleteRow(data)}>
                                        <Icon type="delete" />
                                        {' Remover'}
                                    </Popconfirm>
                                </TableButton>
                            </React.Fragment>
                            : null;
                }
            }
        ],
        rows: [],
        total: 0,
    };

    componentDidMount = async () => {
        await this.updateDataTable();
    };

    /**
     * @description Update data on datatable
     */
    updateDataTable = async () => {
        const { filters, currentPage, pageSize } = this.state;

        this.setState({ loading: true });
        try {
            const result = await GetPASettingsPaginated(currentPage, pageSize, filters);

            this.setState({
                rows: result?.data && result.data.items ? result.data.items : [],
                total: result?.data && result.data.total ? result.data.total : 0,
                //currentPage: result?.data && result.data.page ? result.data.page : 1,
                pageSize: result?.data && result.data.limit ? result.data.limit : 30,
                loading: false
            });
        } catch (e) {
            this.setState({ loading: false });
            console.error(e);
        }
    }

    handleChangePage = currentPage => {
        this.setState({ currentPage }, this.updateDataTable);
    };

    handleChangeRowsPerPage = (currentSize, pageSize) => {
        this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
    };

    handleFilterChange = filters => {
        this.setState({ filters, currentPage: 1 }, this.updateDataTable);
    };

    // Rows actions
    isEditing = (row) => {
        const { editRow } = this.state;
        return editRow ? editRow.id === row.id : false;
    }

    onChangeEndDate = (date, dateStr) => {
        const { editRow } = this.state;
        let x = { ...editRow }
        x.endDate = date ? moment(date).utc(true) : null;
        this.setState({ editRow: x });
    }

    // Disable dates
    disabledEndDate = (current) => {
        const { editRow } = this.state;
        return current && current.isBefore(moment.utc(editRow.startDate).startOf('day'), 'date');
    }

    addRow = async () => {
        const { rows } = this.state;
        this.setState({ adding: true });
        const result = await GetMaxPASetting();

        if (result?.success) {
            const lastAccount = result?.data || null;
            const updatedRows = [...rows];

            const order = lastAccount
                ? (lastAccount?.order + 1)
                : 1;
            const accountNumber = moment.utc().format('YYYY') + '/' + (order).toString().padStart(5, "0");
            const startDate = lastAccount
                ? moment.utc(lastAccount?.endDate).add(1, 'day')
                : moment.utc().startOf('year');

            let newRow = {
                order,
                accountNumber,
                startDate: startDate.startOf('day'),
                endDate: null,
            };
            updatedRows.unshift(newRow);
            this.setState({
                rows: updatedRows,
                editRow: newRow,
                adding: false
            });
        } else {
            this.setState({ adding: false });
        }

    }

    editRow = (row) => {
        let x = JSON.parse(JSON.stringify(row))
        this.setState({ editRow: x });
    }

    cancelEditRow = (row) => {
        const { rows } = this.state;

        if (!row?._id) {
            let updatedRows = [...rows];
            updatedRows = updatedRows.filter(f => f?._id);
            this.setState({
                editRow: null,
                rows: updatedRows
            });
        } else {
            this.setState({ editRow: null });
        }
    }

    saveRow = async () => {
        const { editRow } = this.state;

        const result = editRow?._id
            ? await UpdatePASetting(editRow?._id, editRow)
            : await CreatePASetting(editRow);

        if (result?.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: editRow?._id ? 'Configuração de conta alterada!' : 'Configuração de conta criada!'
            });
            this.setState({ editRow: null }, () => this.updateDataTable());
        }
    }

    deleteRow = async (row) => {
        if (!row?._id) return;

        const result = await DeletePASetting(row?._id);
        if (result?.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: 'Configuração de conta removida!'
            });
            this.updateDataTable();
        }
    }

    render() {
        const { loading, adding } = this.state;
        const { columns, rows, total, currentPage, pageSize } = this.state;

        return (
            <React.Fragment>
                <SectionTitle style={{ marginTop: '3rem' }}>Contas de pagamento</SectionTitle>

                <PaymentAccountsFilters
                    loading={loading}
                    queryChange={this.handleFilterChange}
                    adding={adding}
                    add={this.addRow} />

                <div className='table-responsive'>
                    <Table
                        columns={columns}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        defaultPageSize={pageSize}
                        loading={loading}
                        rows={rows}
                        showHeader={true}
                        emptyIcon="setting"
                        emptyText={'Não existem configurações'}
                        total={total}
                        rowKey={'_id'}
                        hasPagination={true}
                        handleChangePage={this.handleChangePage}
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default withLocalize(PaymentAccountsTable);