import React, { Component } from 'react';
import moment from 'moment';
import { Row, Icon, Col } from 'antd';
import WeddingIcon from '../../assets/menu/ic_wedding.svg';
import ChurchIcon from '../../assets/menu/ic_church.svg';
import { getLocal, getWeddingName } from '../../infra/services/wedding/weddingUtils';
import Table from '../../components/table/Table';
import {
  HeaderButtonsContainer,
  SectionTitle,
  SpinLoading
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import { withRouter } from 'react-router-dom';
import { GetColorSystemByType } from '../../infra/requests/ColorSystemRequests';
import { LocalLabel } from '../weddings/Styles';
import { isCurrentUserChief, isCurrentUserHumanResources } from '../../infra/helpers/Helpers';

class WeddingInfo extends Component {
  state = {
    pageSize: 15,
    currentPage: 1,
    loading: false,
    showHomeHeader: false,
    showTable: false,
    currentEmployee: null,
    selectedWedding: null,
    teamBooking: null,
    weddingTeam: null,
    employeesInCharge: null,

    columns: [
      {
        title: 'Funcionários',
        width: '150px',
        dataIndex: 'employee.name',
      },
      {
        title: 'Categoria: Subcategoria',
        width: '100px',
        render: data => (<div>{data.subcategory.category.name}: <b>{data.subcategory.name}</b></div>)
      },
      {
        title: 'Horário de Entrada',
        width: '80px',
        render: data => moment(data.startTime).format('HH:mm')
      },
      {
        title: 'Notas',
        width: '150px',
        dataIndex: 'notes'
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    this.setState({
      showHomeHeader: this.props.showHomeHeader,
      showTable: this.props.showTable,
      currentEmployee: this.props.currentEmployee,
      selectedWedding: this.props.selectedWedding,
      teamBooking: this.props.selectedWedding?.teamBooking,
      weddingTeam: this.props.selectedWedding?.weddingTeam,
      employeesInCharge: this.props.selectedWedding?.employeesInCharge,
      rows: this.props.selectedWedding.weddingTeam ? this.props.selectedWedding.weddingTeam : [],
      loading: false
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.selectedWedding !== this.props.selectedWedding) {
      this.setState({
        showHomeHeader: this.props.showHomeHeader,
        showTable: this.props.showTable,
        currentEmployee: this.props.currentEmployee,
        selectedWedding: this.props.selectedWedding,
        teamBooking: this.props.selectedWedding?.teamBooking,
        weddingTeam: this.props.selectedWedding?.weddingTeam,
        employeesInCharge: this.props.selectedWedding?.employeesInCharge,
        rows: this.props.selectedWedding?.weddingTeam,
        loading: false
      });
    }
  }

  setWeddingTeam = () => {
    const { weddingTeam } = this.state;

    if (weddingTeam) {
      for (let index = 0; index < weddingTeam.length; index++) {
        const element = weddingTeam[index];
        return (<Row className='display-flex mobile-grid ant-row-col'>
          <div>
            <b style={{ fontWeight: 600 }}>Subcategoria: </b>
            {element.subcategory.name}
          </div>

          <div>
            <b style={{ fontWeight: 600 }}>Horário de Entrada: </b>
            {moment(element.startTime).format('HH:mm')}
          </div>
        </Row>)
      }
    } else return null;
  }

  getEmployeesInCharge = (employeesInCharge) => {
    for (let index = 0; index < employeesInCharge.length; index++) {
      const weddingEmployee = employeesInCharge[index];

      return (<Row className='display-flex mobile-grid ant-row-col' style={{ textAlign: 'left', width: '100%', marginTop: '10px' }}>
        <div>
          <b style={{ fontWeight: 600 }}>Funcionário: </b>
          {weddingEmployee.employee.name}
        </div>
        <div>
          <b style={{ fontWeight: 600 }}>Subcategoria: </b>
          {weddingEmployee.subcategory.name}
        </div>

        <div>
          <b style={{ fontWeight: 600 }}>Horário de Entrada: </b>
          {moment(weddingEmployee.startTime).format('HH:mm')}
        </div>
      </Row>);
    }
  }

  render() {
    const { showHomeHeader, showTable, colors } = this.state;
    const { currentEmployee, selectedWedding, weddingTeam, employeesInCharge } = this.state;
    const { loading, currentPage, pageSize } = this.state;
    const { columns, rows, total } = this.state;

    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <div style={{ textAlign: 'left' }}>
          <Row className='ant-row-col' style={{ display: 'inline-flex' }}>
            <div>
              <img alt='Local' style={{ width: 14 }} src={ChurchIcon} />
              &nbsp;
              {getLocal(selectedWedding?.wedding)}
            </div>

            <div>
              <Icon type='calendar'></Icon
              >&nbsp;
              {moment.utc(selectedWedding?.wedding?.date).format('DD-MM-YYYY')}
            </div>
          </Row>

          <div style={{ fontSize: '18px', fontWeight: 700, margin: '25px 0 5px 0' }}>Horários</div>
          <Row className='display-flex mobile-grid ant-row-col'>
            <div>
              <b style={{ fontWeight: 600 }}>Chegada à Quinta: </b>
              {selectedWedding?.scheduler?.arrivingTime}
            </div>

            <div>
              <b style={{ fontWeight: 600 }}>Aperitivos: </b>
              {selectedWedding?.scheduler?.snackTime}
            </div>

            <div>
              <b style={{ fontWeight: 600 }}>Entrada na Sala de Jantar: </b>
              {selectedWedding?.scheduler?.dinnerTime}
            </div>
          </Row>
        </div>

        {(employeesInCharge && (employeesInCharge.coordinator.length > 0 || employeesInCharge.chief.length > 0 || employeesInCharge.host.length > 0)) ?
          (<div style={{ textAlign: 'left' }}>
            <div style={{ fontSize: '18px', fontWeight: 700, margin: '25px 0 0 0' }}>Funcionários Responsáveis</div>
            {this.getEmployeesInCharge(employeesInCharge.coordinator)}
            {this.getEmployeesInCharge(employeesInCharge.chief)}
            {this.getEmployeesInCharge(employeesInCharge.host)}
          </div>) : null}

        {currentEmployee && currentEmployee.user.employee && weddingTeam && weddingTeam.length > 0 && weddingTeam.filter(f => f.employee.id === currentEmployee.id).length > 0 ?
          (<div style={{ textAlign: 'left', marginTop: 15 }}>
            <div style={{ fontSize: '16px', fontWeight: 700, margin: '25px 0 5px 0' }}>Papel a Desempenhar</div>
            {this.setWeddingTeam()}
          </div>)
          : null}

        {showHomeHeader && ((isCurrentUserHumanResources() || isCurrentUserChief() && employeesInCharge.chief && employeesInCharge.chief.length > 0 && employeesInCharge.chief.find(f => f.employee.id === currentEmployee.id))) ?
          (<Row gutter={24} style={{ marginTop: '2rem', marginBottom: '1rem' }}>
            <Col lg={16} md={16} xs={24}>
              <SectionTitle>Equipa do Casamento</SectionTitle>
            </Col>
            <Col lg={8} md={8} xs={24} className='subcategory-col-btn'>
              <BaseButton
                type={'primary'}
                icon={currentEmployee.user.chief || weddingTeam && weddingTeam.length > 0 ? 'team' : 'plus'}
                text={currentEmployee.user.chief || weddingTeam && weddingTeam.length > 0 ? 'Gerir Equipa' : 'Criar Equipa'}
                onClick={() => {
                  const { history } = this.props;
                  history.push(`wedding-info/${selectedWedding.wedding.id}`)
                }}
                disabled={!((isCurrentUserHumanResources() || isCurrentUserChief()) && weddingTeam.length > 0 && weddingTeam.filter(t => t.published).length > 0)}
              />
            </Col>
          </Row>) : null}

        {(showTable && (isCurrentUserHumanResources() || isCurrentUserChief() && employeesInCharge.chief && employeesInCharge.chief.length > 0 && employeesInCharge.chief.find(f => f.employee.id === currentEmployee.id))) ? (
          <Table
            columns={columns}
            // currentPage={currentPage}
            // pageSize={pageSize}
            // defaultPageSize={pageSize}
            loading={loading}
            rows={weddingTeam}
            showHeader={true}
            emptyIcon="team"
            emptyText={'Ainda não foi definida a equipa'}
            total={total}
            rowKey={'_id'}
            hasPagination={false}
          // handleChangePage={this.handleChangePage}
          // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />) : null}

      </React.Fragment>
    );
  }
}

export default withRouter(WeddingInfo);
