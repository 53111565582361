import PropTypes from 'prop-types';
import React from 'react';
import { InputDiv, InputLabelDiv, ColourInput, ColourBox } from './InputStyles';
import { Colorpicker, ColorPickerValue } from 'antd-colorpicker'
import { isString } from 'lodash';

const rule = /^#([0-9A-F]{3}){1,2}$/i;

const ColourInputComponent = ({
  input,
  meta,
  label,
  first,
  placeholder,
  disabled,
  defaultColor,
  hideInput
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  if (input?.value?.length > 0 && input?.value?.charAt(0) !== '#') {
    input.value = `#${input.value}`;
  }

  const colour = rule.test(input.value) ? input.value : defaultColor || '#fff';

  const verifyOnBlur = () => {
    if (input?.value && isString(input?.value) && input.value.indexOf('#') !== 0) {
      input.onChange('#' + input.value);
    }
  };

  return (
    <InputDiv first={first}>
      {label && <InputLabelDiv>{label}</InputLabelDiv>}
      {disabled ? <ColourBox colour={colour} />
        : <Colorpicker
          popup
          value={colour}
          picker={'ChromePicker'}
          onColorResult={(color) => color.hex}
          onChange={input.onChange}
          blockStyles={{
            boxSizing: 'border-box',
            boxShadow: 'unset',
            width: '32px',
            height: '32px',
            borderRadius: '4px',
            border: '1px solid #d9d9d9',
            marginLeft: '8px',
            float: 'right',
            cursor: 'picker'
          }} />
      }
      {
        !hideInput &&
        <ColourInput
          disabled={true}
          type={'text'}
          value={input.value}
          onChange={input.onChange}
          onBlur={verifyOnBlur}
          placeholder={placeholder}
          error={showError ? 1 : 0}
        />
      }
    </InputDiv>
  );
};

ColourInputComponent.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

export default ColourInputComponent;
