import React, { useState, useEffect } from 'react';
import { Modal, Button, Icon } from 'antd';
import { InputField, InputGroupField, InputLabelDiv } from '../../components/inputs/InputStyles';
import moment from 'moment';


let ModalDeleteWaitingWedding = (props): React$Element<React$FragmentType> => {
    const [password, setPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [wedding, setWedding] = React.useState(null);
    const [errorPassword, setErrorPassword] = React.useState(false);

    useEffect(() => {
        setWedding(JSON.parse(JSON.stringify(props.initialValues.wedding)));
        setPassword('');
    }, [props.initialValues.wedding])

    const footer = [
        <Button
            key='cancel'
            onClick={() => props.onClose()}>
            Cancelar
        </Button>,
        <Button
            key='submit'
            type='danger'
            disabled={errorPassword}
            loading={props.deleting}
            onClick={(e) => {
                if (password.length === 0) {
                    e.stopPropagation();
                    setErrorPassword(true);
                } else props.onSubmit({ wedding, password, notify: false })
            }}>
            Eliminar
        </Button>
    ];
    return (
        <Modal
            visible={props.isOpen}
            title={'Têm a certeza que quer remover este casal da plataforma?'}
            centered={true}
            width={400}
            onCancel={() => props.onClose()}
            footer={footer}
        >
            <p>
                Toda a informação deste casal será removida da plataforma e não conseguirá ser recuperada.
            </p>
            <p>
                Os noivos não receberão qualquer notificação sobre o acesso à plataforma.
            </p>

            <InputLabelDiv>Palavra-passe</InputLabelDiv>

            <InputGroupField compact>
                {showPassword ? <InputField
                    prefix={<Icon type="lock" />}
                    type="text"
                    value={password}
                    onChange={($event: any) => {
                        setPassword($event.target.value);
                        setErrorPassword(false);
                    }}
                    placeholder="Insira a sua palavra-passe"
                    error={errorPassword ? 'true' : null}
                    autoComplete="new-password"
                /> : <InputField
                    prefix={<Icon type="lock" />}
                    type="password"
                    value={password}
                    onChange={($event: any) => {
                        setPassword($event.target.value);
                        setErrorPassword(false);
                    }}
                    placeholder="Insira a sua palavra-passe"
                    error={errorPassword == true ? 'true' : null}
                    autoComplete="new-password"
                />}
                <Button type="primary" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Icon type="eye-invisible" /> : <Icon type="eye" />}
                </Button>
            </InputGroupField>
        </Modal >
    );
};

export default ModalDeleteWaitingWedding;