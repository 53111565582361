import moment from 'moment';
import client from '../config/AxiosConfig';

export const GetWedding = async id => client.get(`/weddings/${id}`);

export const GetConfirmedWeddings = async (search) =>
  client.get(`/weddings/confirmed?search=${search || ''}`);

export const GetWeddingsSelect = async (confirmedOnly, search) =>
  client.get(`/weddings/select/${confirmedOnly}?search=${search || ''}`);

export const GetWeddings = async (currentPage, limit, filters) =>
  client.get(`/weddings/${currentPage}/${limit}?filter=${filters}`);

export const GetWeddingsPayments = async (currentPage, limit, filters) =>
  client.get(`/weddings/payments/${currentPage}/${limit}?filter=${filters}`);

export const UpdateWedding = async (id, data) =>
  client.put(`/weddings/${id}`, data);

export const NotifyWaitingListByWedding = async (id, data) =>
  client.post(`/weddings/${id}/notifyWaitingList`, data);

export const GetAllWeddings = async (id, data) =>
  client.put(`/weddings/getAllWeddings`, data);

export const GetAdminCalendar = async (filter) =>
  client.get(`/weddings/adminCalendar?filter=${filter}`);

export const GetAllWeddingsByUser = async (id, filter) =>
  client.get(`/weddings/nearestByEmployee/${id}?filter=${filter}`);

export const GetAllWeddingsCalendarByUser = async (id, filter) =>
  client.get(`/weddings/nearestByEmployeeCalendar/${id}?filter=${filter}`);

export const GetWeddingsByEmployee = async (currentPage, limit, filters) =>
  client.get(`/weddings/paginateByEmployee/${currentPage}/${limit}?filter=${filters}`);

export const CreateWedding = async data => client.post('/weddings', data);

export const DeleteWedding = async id => client.delete(`/weddings/${id}`);

export const ConvertToMeetingsAgenda = async id => client.post(`/weddings/${id}/convertToMeetings`);

export const ConvertToVisitsAgenda = async id => client.post(`/weddings/${id}/convertToVisits`);

export const UnblockMeetings = async id => client.post(`/weddings/${id}/unblockMeetings`);

export const SoftDeleteWedding = async (id, deleted, notifyWaitingList, email, password) =>
  client.post(`/weddings/${id}/softDelete/${deleted}/${notifyWaitingList}`, { email, password });

export const TogglePublic = async id => client.put(`/weddings/${id}/public`);

export const TogglePaymentsToTeam = async id => client.put(`/weddings/${id}/paymentsToTeam`);

export const GetNearestWeddings = async () => client.get('/weddings/nearest');

export const GetClosingWeddings = async () => client.get('/weddings/closing');

export const SearchWeddings = async search =>
  client.get(`/weddings/search?search=${search}`);

export const CreateBudget = async data => client.post('/budget', data);

export const SaveDocument = async (id, payload) =>
  client.put(`/weddings/${id}/documents`, payload);

export const RemoveDocument = async (id, file) =>
  client.delete(`/weddings/${id}/documents/${file}`);

export const CheckWeddingDate = async data =>
  client.post(`/weddings/check_date`, data);

export const GetWeddingsStaffMeals = async filters =>
  client.get(`/weddings/staff_meals?filter=${filters}`);

export const SaveWeddingsStaffMeals = async (payload) =>
  client.post(`/weddings/staff_meals`, payload);

export const DownloadWeddings = async (filters) => {
  return client.post(`/weddings/export?filter=${filters}`, {}, {
    responseType: 'blob'
  }).then(response => {
    return response;
  })
}

export const UpdateReserveNotes = async (id, data) =>
  client.post(`/weddings/${id}/updateReserveNotes`, data);

export const ExtendReserveEditingTime = async (id) =>
  client.post(`/weddings/${id}/extendReserveEditingTime`);

export const UpdateCreateWeddingGuest = async (id, data) =>
  client.post(`/weddings/${id}/updateCreateGuest`, data);

export const GenerateAccessCode = async (id, data) =>
  client.post(`/weddings/${id}/generateCode`, data);

export const MngGuestAccess = async (id, data) =>
  client.post(`/weddings/${id}/guestAccess`, data);

export const NotifyWeddingGuest = async (id) =>
  client.post(`/weddings/${id}/notifyGuest`);

//Back-office employees
export const GetWeddingByDateAndChief = async (date, chiefId) =>
  client.get(`/weddings/findByDate/${date}/${chiefId}`);

export const GetNearestWeddingsByUser = async (userId, filter) =>
  client.get(`/weddings/nearestByEmployee/${userId}?filter=${filter}`);

export const GetHomeWeddingsByUser = async userId =>
  client.get(`/weddings/homeWeddingsByUser/${userId}`);

export const GetAdminWeddings = async (local) =>
  client.get(`/weddings/adminWeddings/${local}`);

export const GetWeddingInfo = async (id) =>
  client.get(`/weddings/${id}/info`);

export const RecalculateWeddingPaymentPhases = async (id, data) =>
  client.post(`/weddings/${id}/recalculate`, data);

export const UpdateWeddingContractAndPaymentPhases = async (id) =>
  client.put(`/weddings/${id}/contract`);

export const UpdateWaitingInfo = async (id, data) =>
  client.put(`/weddings/${id}/updateWaitingInfo`, data);

export const UpdateInterestInfo = async (id, data) =>
  client.put(`/weddings/${id}/updateInterestInfo`, data);

export const CheckAttendingAt = async (id) =>
  client.post(`/weddings/${id}/checkAttendingAt`);

export const UpdateBudgetInfo = async (id, data) =>
  client.put(`/weddings/${id}/budget`, data);

export const GetWeddingsNoReserves = async (currentPage, limit, filters) =>
  client.get(`/weddings/noReserves/${currentPage}/${limit}?filter=${filters}`);

export const GetWeddingsNoWaitingDates = async (currentPage, limit, filters) =>
  client.get(`/weddings/noWaitingDates/${currentPage}/${limit}?filter=${filters}`);

export const GetWeddingsNoInterestDates = async (currentPage, limit, filters) =>
  client.get(`/weddings/noInterestDates/${currentPage}/${limit}?filter=${filters}`);

export const SoftDeleteWaitingWedding = async (id, deleted, email, password) =>
  client.post(`/weddings/${id}/softDeleteWaiting/${deleted}`, { email, password });

export const GetWeddingsAgenda = async (filters) =>
  client.post(`/weddings/agenda`, filters);

export const ExportWeddingsAgenda = async (filters) =>
  client.post(`/weddings/exportAgenda`, filters);



