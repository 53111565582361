import React, { useEffect } from 'react';
import { Modal, Button, Row, Col } from 'antd';
import Alert from "../../components/alert/Alert";
import { Editor } from '@tinymce/tinymce-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import SelectInput from '../../components/inputs/SelectInput';
import TextInput from '../../components/inputs/TextInput';
import { BaseForm, HeaderButtonsContainer, HeaderContainer, HeaderTitle, PageContainer, PageTitle } from '../../styles/BasicStyles';
import { getUserId } from '../../infra/helpers/Helpers';
import BaseButton from '../../components/buttons/BaseButton';
import { CreateDynamicEmail, GetDynamicEmail, GetDynamicEmailByType, UpdateDynamicEmail } from '../../infra/requests/DynamicEmailRequests';
import { InputLabelDiv } from '../../components/inputs/InputStyles';

const emailType = [
    { _id: 'NR', name: 'Reserva - Novos Utilizadores' }, // NR: new user reserve
    { _id: 'AR', name: 'Reserva - Agendamento' }, // AR: agending reserve
    { _id: 'CR', name: 'Reserva - Confirmação' }, // CR: confirmation reserve
    { _id: 'RDO', name: 'Reserva - Data Ocupada' }, // OD: reserve date occupied
    { _id: 'RR', name: 'Reserva - Reativação' },// RR: reserve reactivation
    { _id: 'PP', name: 'Fases de Pagamento' }, // PP: payment phases
    { _id: 'AB', name: 'Visitas - Agendamento' }, // AB: agending bookings
];

const languagesOptions = [
    { _id: 'pt', name: 'Português' },
    { _id: 'en', name: 'Inglês' },
    { _id: 'es', name: 'Espanhol' },
    { _id: 'fr', name: 'Francês' },
];

/** Style */
const maxDivHeight = 450;

const Item = styled.div`
    display: flex;
    user-select: none;
    padding: 0.5rem;
    margin: 0 0 0.5rem 0;
    align-items: flex-start;
    align-content: flex-start;
    line-height: 1.5;
    border-radius: 3px;
    background: #fff;
    border: 1px ${props => (props.isDragging ? 'dashed #4099ff' : 'solid #ddd')};
`;

const Clone = styled(Item)`
    + div {
        display: none !important;
    }
`;

const List = styled.div`
    flex: 0 0 150px;
    font-family: sans-serif;
`;

const Kiosk = styled(List)`
    max-height: ${maxDivHeight}px;
    overflow: auto
`;

const Container = styled(List)`
    border: ${props => (props.isDraggingOver ? '1px dashed #4099ff' : '0px solid #ddd')};
    // height: ${maxDivHeight}px;
    // margin-Top: -${maxDivHeight}px;
`;


let EmailPage = (props): React$Element<React$FragmentType> => {

    const [languages, setLanguages] = React.useState([]);
    let [data, setData] = React.useState(null);
    const [fields, setFields] = React.useState([]);
    let [submitted, setSubmitted] = React.useState(false);

    useEffect(() => {
        setSubmitted(false);
        const id = props.match.params.id;
        // console.warn('id', id);
        if (id != 'null') getEmail(id);
        else {
            setData({
                title: getEmailTitle(new URLSearchParams(props.location.search).get('type')),
                type: new URLSearchParams(props.location.search).get('type'),
                language: '',
                year: 0,
                subject: '',
                content: '',
                contentHtml: '',
                user: getUserId()
            });
            getEmailsByType(new URLSearchParams(props.location.search).get('type'))
        }
    }, []);

    useEffect(() => {
        let fieldsAvailable: any[] = [];
        if (data && data.type === "NR") {
            fieldsAvailable = [
                { label: 'Link Livro de Informações', value: 'linkBook' },
                { label: 'Link Complementos', value: 'linkComplements' },
                { label: 'Ano Atual', value: 'year' },
                { label: 'Ano + 1', value: 'yearPlus1' },
                { label: 'Ano + 2', value: 'yearPlus2' },
                { label: 'Ano + 3', value: 'yearPlus3' },
            ];
        } else if (data && data.type === "CR") {
            fieldsAvailable = [
                { label: 'Ano', value: 'year' },
                { label: 'Fases de Pagamento', value: 'paymentPhases' },
            ];
        }

        fieldsAvailable.push({ label: 'Assinatura', value: 'signature' });
        setFields(fieldsAvailable);
    }, [data]);

    const getEmailTitle = (type: any) => {
        let title = 'Email';
        if (type === 'NR') title = 'Reserva - Novos Utilizadores';
        else if (type === 'AR') title = 'Reserva - Agendamento';
        else if (type === 'CR') title = 'Reserva - Confirmação';
        else if (type === 'RDO') title = 'Reserva - Data Ocupada';
        else if (type === 'RR') title = 'Reserva - Reativação';
        else if (type === 'PP') title = 'Fases de Pagamento';
        else if (type === 'AB') title = 'Visitas - Agendamento';
        return title;
    }

    const getEmail = async (id) => {
        const result = await GetDynamicEmail(id);
        // console.warn('Res', result);

        if (result.success) {
            setData(result.data);
            getEmailsByType(result.data.type);
        }
    }

    const getEmailsByType = async (type: string) => {
        const result = await GetDynamicEmailByType(type);
        // console.warn('Res Type', result);

        if (result.success && result.data && result.data.length > 0) {
            setLanguages(result.data.map(m => ({_id: m._id, language: m.language})));
        }
    }

    const onDragEnd = (result) => {
        window.jQuery('#tinyMceEditor_ifr').css('pointer-events', 'all')

        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        let tiny = window.tinymce.activeEditor;

        let position = tiny.selection.getSel().anchorOffset;
        let content = tiny.selection.getRng().endContainer.textContent;

        if (typeof content == 'string') {
            let aux1 = content.substring(0, position);
            let aux2 = content.substring(position, content.length);
            let aux3 = aux1.split('{{')
            if (aux3.length > 1) {
                if (aux3[aux3.length - 1].split('}}').length == 1) return;
            }
            let aux4 = aux1.split('}}')
            if (aux4.length > 1) {
                if (aux4[0].split('{{').length == 1) return;
            }
        }

        window.tinymce.activeEditor.insertContent(`<span>{{${result.draggableId}}}</span>`);
    };

    const onDragStart = () => {
        window.jQuery('#tinyMceEditor_ifr').css('pointer-events', 'none')
    };

    const saveModal = async () => {
        submitted = true;
        setSubmitted(submitted);

        data.content = window.tinymce.activeEditor.getContent({ format: 'text' });
        data.contentHtml = window.tinymce.activeEditor.getContent({ format: 'html' });
        data.user = getUserId();
        setData(JSON.parse(JSON.stringify(data)));
        // console.warn('Data Submit', data);

        if (submitted && (data.subject === '' || data.language === '' || data.content.length === 0)) {
            return;
        }

        const selectedLanguage = languages && languages.find((l: any) => l.language === data.language);
        if (selectedLanguage && data._id !== selectedLanguage._id) {
            Alert.new({
                type: "error",
                title: "Atenção",
                text: "Já existe tradução nesse idioma!"
            });
            return;
        }

        const result = data._id ? await UpdateDynamicEmail(data) : await CreateDynamicEmail(data);
        // console.warn('Result', result);

        if (result.success) {
            Alert.new({
                type: "success",
                title: "Sucesso",
                text: data._id ? "Email atualizado!" : "Email criado!"
            });
            props.history.push('/settings/4')
        }
    }

    return (
        <React.Fragment>
            <HeaderContainer>
                <HeaderTitle buttons={2}>
                    <PageTitle>{data?.title}</PageTitle>
                </HeaderTitle>
                <HeaderButtonsContainer buttons={2}>
                    <BaseButton
                        type="primary"
                        icon="save"
                        text="Gravar"
                        onClick={(e) => saveModal()}
                    />
                    <BaseButton
                        type="default"
                        icon="close"
                        text="Cancelar"
                        onClick={() => props.history.push('/settings/4')}
                    />
                </HeaderButtonsContainer>
            </HeaderContainer>

            <PageContainer buttons={2} style={{ textAlign: 'start' }}>
                <BaseForm onSubmit={props.onSubmit}>
                    <Row gutter={12}>
                        <Col xs={24} md={20} lg={20}>
                            <TextInput
                                label='Assunto'
                                input={{
                                    value: data?.subject,
                                    onChange: (e) => {
                                        data.subject = e.target.value;
                                        setData(JSON.parse(JSON.stringify(data)));
                                    }
                                }}
                                meta={{}}
                                requiredError={submitted && data?.subject === '' ? true : false}
                                type="text"
                                placeholder="Insira o assunto"
                            />
                        </Col>
                        <Col xs={24} md={4} lg={4}>
                            <SelectInput
                                label='Idioma'
                                allowClear={true}
                                placeholder="Escolher o idioma"
                                data={languagesOptions}
                                input={{
                                    value: data?.language,
                                    onChange: value => {
                                        data.language = value;
                                        setData(JSON.parse(JSON.stringify(data)));
                                    }
                                }}
                                meta={{
                                    invalid: submitted && (data?.language === null || data?.language === '') ? true : false,
                                    submitFailed: submitted && (data?.language === null || data?.language === '') ? true : false,
                                }}
                            />
                        </Col>
                    </Row>
                </BaseForm>
                <Row gutter={12} style={{ marginTop: 15 }}>
                    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>

                        <Col xs={24} md={20} lg={20}>
                            <Droppable ignoreContainerClipping={true} droppableId="EDITOR">
                                {(provided, snapshot) => (
                                    <Container
                                        ref={provided.innerRef}
                                        isDraggingOver={
                                            snapshot.isDraggingOver
                                        }>
                                        <InputLabelDiv style={{ marginBottom: 5 }}>Template</InputLabelDiv>
                                        <div className={submitted && data?.content.length === 0 ? 'editor-error' : ''} style={{ pointerEvents: snapshot.isDraggingOver ? 'none' : 'all' }}>
                                            <Editor
                                                id="tinyMceEditor"
                                                apiKey="wwwkdcltuccxyiwj5v57znye5iw4mx189hfjiecmr9jx4spr"
                                                initialValue={data && data.contentHtml ? data.contentHtml : ''}
                                                init={{
                                                    block_unsupported_drop: false,
                                                    selector: 'textarea#file-picker',
                                                    height: 450,
                                                    menubar: true,
                                                    language: "pt_PT",
                                                    language_url: "/langs/pt_PT.js",
                                                    toolbar_mode: 'wrap',
                                                    plugins: [
                                                        'advlist', 'autolink', 'lists', 'link', 'image', 'emoticons', 'charmap', 'preview',
                                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                        'insertdatetime', 'media', 'table', 'help', 'wordcount'
                                                    ],
                                                    toolbar: 'undo redo | bold italic underline | fontselect fontsizeselect formatselect | ' +
                                                        'forecolor backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | image table emoticons | bullist numlist | pagebreak | print |' +
                                                        'removeformat | help',
                                                    file_picker_types: "file image media",
                                                    image_title: true,
                                                    automatic_uploads: true,
                                                    file_picker_types: 'image',
                                                    file_picker_callback: function (cb, value, meta) {
                                                        var input = document.createElement('input');
                                                        input.setAttribute('type', 'file');
                                                        input.setAttribute('accept', 'image/*');

                                                        input.onchange = function () {
                                                            var file = this.files[0];
                                                            // console.warn('Files', file);

                                                            var reader = new FileReader();
                                                            reader.onload = function () {
                                                                var id = 'blobid' + (new Date()).getTime();
                                                                var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                var base64 = reader.result.split(',')[1];
                                                                var blobInfo = blobCache.create(id, file, base64);
                                                                blobCache.add(blobInfo);
                                                                cb(blobInfo.blobUri(), { title: file.name });
                                                            };
                                                            reader.readAsDataURL(file);
                                                        };

                                                        input.click();
                                                    },
                                                }}
                                            // onChange={handleEditorChange}
                                            ></Editor>
                                        </div>
                                    </Container>
                                )}
                            </Droppable>
                        </Col>

                        <Col xs={24} md={4} lg={4}>
                            <InputLabelDiv style={{ marginBottom: 5 }}>Campos</InputLabelDiv>
                            <Droppable droppableId="ITEMS" isDropDisabled={true}>
                                {(provided, snapshot) => (
                                    <Kiosk
                                        ref={provided.innerRef}
                                        isDraggingOver={snapshot.isDraggingOver}>
                                        {/* <div id="external-events" style={{ maxHeight: maxDivHeight, overflow: 'auto' }}> */}
                                        {fields.map((f, i) => (
                                            <Draggable
                                                key={f.value}
                                                draggableId={f.value}
                                                index={i}>
                                                {(provided, snapshot) => (
                                                    <React.Fragment>
                                                        <Item
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            isDragging={snapshot.isDragging}
                                                            style={
                                                                provided.draggableProps
                                                                    .style
                                                            }>
                                                            {f.label}
                                                        </Item>
                                                        {snapshot.isDragging && (
                                                            <Clone>{f.label}</Clone>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </Draggable>
                                            //return <div key={i} className='external-event bg-primary-lighten text-dark' draggable="true" title={f.label} data="bg-primary" value={f.value}>{f.label}</div>
                                        ))}
                                        {/* </div> */}
                                    </Kiosk>
                                )}
                            </Droppable>
                        </Col>
                    </DragDropContext>
                </Row>
            </PageContainer>
        </React.Fragment>
    );
};

export default EmailPage;