import PropTypes from "prop-types";
import React from "react";
import ReactPlayer from "react-player";
import {
  InputDiv,
  InputLabelDiv,
  InputField,
  PreviewVideo
} from "./InputStyles";

const TextInput = ({ input, meta, label, sublabel, placeholder, disabled }) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;
  return (
    <InputDiv>
      <InputLabelDiv>{label}</InputLabelDiv>
      <InputField
        disabled={disabled}
        type={"text"}
        value={input.value}
        onChange={input.onChange}
        placeholder={placeholder}
        error={showError ? 1 : 0}
      />
      <InputLabelDiv sublabel>{sublabel}</InputLabelDiv>
      <PreviewVideo>
        <ReactPlayer controls width="100%" height="100%" url={input?.value} />
      </PreviewVideo>
    </InputDiv>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  onPressEnter: PropTypes.func
};

export default TextInput;
