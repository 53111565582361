import client from '../config/AxiosConfig';

export const ArchiveWedding = async data => client.post('/archived', data);

export const GetArchived = async id => client.get(`/archived/${id}`);

export const GetArchivedByWedding = async weddingId =>
  client.get(`/archived/wedding/${weddingId}`);

export const GetArchivedList = async (currentPage, limit, filters) =>
  client.get(`/archived/${currentPage}/${limit}?filter=${filters}`);

export const GetArchivedQuestionForBudget = async (id) => client.get(`/archived/${id}/budgetQuestions`);

export const GetArchivedOurGuestsEditor = async (weddingId) =>
  client.get(`/archived/${weddingId}/roomPlanEditor`);
