import styled from 'styled-components';

export const FiltersContainer = styled.div`
  width: 100%;
`;

export const FiltersColumn = styled.div`
  width: 100%;
  padding: 3px 0px;
  font-size: 18px;
  
  & span {
    font-weight: bold;
  }
`;

export const ReportDownload = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const ReportTitle = styled.div`
  flex-basis: 18%;
  padding-right: 10px;
  min-width: 120px;
  font-size: 15px;
`;

export const FileInputContainer = styled.div`
  padding-top: 20px;
`;

export const FileInputTitle = styled.div`
  font-size: 15px;
  padding-bottom: 5px;
`;

export const OrderTable = styled.div`
  margin-top: ${p => p.first ? '0px' : '30px'};
  position: relative;
`;

export const OrderTableTitle = styled.div`
  text-align: left;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 17px;
`;

export const SendButtonContainer = styled.div`
  position: fixed;
  right: 10px;
  bottom: 10px;
  
  & button {
    width: 170px;
    height: 45px;

    & i {
      font-size: 18px;
    }

    & span {
      font-size: 18px;
      font-weight: bold;
    }
  }
`;

export const WeddingDetailTitle = styled.div`
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
`;

export const WeddingTotalTitle = styled.div`
  padding-top: 20px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
  text-align: left;
`;

export const ColorResponse = styled.div`
  display: inline-block;
  margin: 0 5px 5px 0;
`;

export const ColorPill = styled.div`
  display: inline-block;
  text-align: left;
  padding: 5px 10px;
  background: transparent;
  border-radius: 4px;
  border: 2px solid #E0E0E0;
  white-space: pre-line;
  position: relative;
  font-weight: bold;
`;

export const ColorBox = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${({color}) => color || '#ffffff'};
`;

export const ImageBox = styled.img`
  width: 50px;
  height: 50px;
`;

export const ColorName = styled.div`
  font-size: 12px;
`;