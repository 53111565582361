import client from '../config/AxiosConfig';

export const GetColours = async id => client.get('/colours');

export const GetColour = async id => client.get(`/colours/${id}`);

export const CreateColour = async data => client.post('/colours', data);

export const UpdateColour = async (id, data) =>
  client.put(`/colours/${id}`, data);

export const UpdateColourOrder = async data =>
  client.post(`/colours/order`, data);

export const DeleteColour = async (id, data) =>
  client.delete(`/colours/${id}`, data);
