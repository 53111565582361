import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { reduxForm, Field } from 'redux-form';
import TagInput from '../../components/inputs/TagInput';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { BaseForm } from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import { withLocalize } from 'react-localize-redux';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';

const validations = FormValidator.make({
  tag: 'required|noSpaces',
  value: 'required|languages'
});

let AddTranslationModal = ({
  isOpen,
  onClose,
  onConfirm,
  handleSubmit,
  languages,
  reset,
  editable
}) => {
  return (
    <Modal
      title="Add translation"
      visible={isOpen}
      onOk={handleSubmit(onConfirm)}
      onCancel={() => {
        onClose();
        reset();
      }}
      maskClosable={false}
      width={900}>
      <BaseForm onSubmit={handleSubmit(onConfirm)}>
        <Field
          component={TagInput}
          name="tag"
          type="text"
          label="Translation tag"
          placeholder={'Insert the translation tag'}
          disabled={!editable}
        />

        {languages.map(lang => (
          <Field
            key={`inputlang_${lang.code}`}
            component={TextAreaInput}
            name={`value.${lang.code}`}
            type="text"
            label={`Translation in ${GetTranslationDescription(lang.code)}`}
            minRows={3}
            maxRows={5}
            placeholder={`Insert the translation for ${GetTranslationDescription(
              lang.code
            )}`}
          />
        ))}
      </BaseForm>
    </Modal>
  );
};

AddTranslationModal = reduxForm({
  form: 'add_translation_form',
  enableReinitialize: true,
  validate: validations
})(AddTranslationModal);

AddTranslationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default withLocalize(AddTranslationModal);
