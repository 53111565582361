// @flow
import React, { useState, useEffect, Fragment, useRef } from 'react';
import Table from '../../components/table/Table';
import { PageContainer, PageTitle, HeaderContainer, HeaderButtonsContainer, HeaderTitle, TableFilterSection, TableButton } from '../../styles/BasicStyles';
import { Row, Col, Popconfirm, Icon, Tag, Avatar, Breadcrumb, DatePicker, Checkbox, TimePicker, Dropdown, Menu } from 'antd';
import { GetAllColorSystem } from '../../infra/requests/ColorSystemRequests';
import { GetAllWeddings } from '../../infra/requests/WeddingRequests';
import { GetAllPaymentDaysGrouped, GetPaymentDay, CreatePaymentDay, DeletePaymentDay, UpdatePaymentDay } from '../../infra/requests/PaymentDaysRequests';
import BaseButton from '../../components/buttons/BaseButton';
import { withRouter } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import Alert from "../../components/alert/Alert";
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { isCurrentUserHumanResources, isEmployeeOrChief } from '../../infra/helpers/Helpers';

// main component
let PaymentDays = (props): React$Element<React$FragmentType> => {

    const { history, location, dispatch } = props;
    const isRestDays = history.location.pathname.includes('/rest-days') ? true : false;
    const isPaymentDays = history.location.pathname.includes('/payment-days') ? true : false;
    const isCouplePaymentDays = history.location.pathname.includes('/couple-payment-days') ? true : false;
    const fromReserveAgenda = new URLSearchParams(location.search).get('page') === 'RA' ? true : false;
    const fromReservePriceAgenda = new URLSearchParams(location.search).get('page') === 'RP' ? true : false;

    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(moment(new Date()));
    const [date2, setDate2] = useState(moment(new Date(new Date().getFullYear(), 12, 31)));
    const [multipleDays, setMultipleDays] = useState(false);
    const [pageSize, setPageSize] = useState(15);
    const [paymentDay, setPaymentDay] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [editUnit, setEditUnit] = useState(null);

    const columns = [
        {
            title: 'Data',
            key: 'date',
            render: data => (
                <>
                    {
                        !isEditing(data)
                            ?
                            data.dates.length > 1 ? new Date(data.dates[0].date).toLocaleDateString() + ' - ' + new Date(data.dates[data.dates.length - 1].date).toLocaleDateString() : new Date(data.dates[0].date).toLocaleDateString()
                            :
                            <DatePicker value={editUnit.dates[0].date} format={'DD/MM/YYYY'} placeholder='Selecione Data' onChange={(event) => {
                                let x = copyEditUnit();
                                x.dates[0].date = event;
                                setEditUnit(x)
                            }} style={{ width: '100%', marginTop: 5 }} />
                    }
                </>
            )
        },
        {
            title: 'Mensal',
            key: 'monthly',
            render: data => (

                !isEditing(data)
                    ?
                    < Tag color={data.dates.length > 0 && data.dates[0].monthly ? 'green' : 'red'} key={data._id} >
                        {data.dates.length > 0 && data.dates[0].monthly ? 'Sim' : 'Não'}
                    </Tag >
                    :
                    <div className="sc-dxgOiQ cGTQcX">
                        <Checkbox checked={editUnit.dates[0].monthly} onChange={(event) => {
                            let x = copyEditUnit();
                            x.dates[0].monthly = event.target.checked;
                            setEditUnit(x)
                        }}>Mensal?</Checkbox>
                    </div>
            )
        },
        {
            title: 'Acções',
            render: data => (
                <Fragment>
                    {!isEditing(data)
                        ?
                        <>
                            <TableButton onClick={e => setEditForm(data)}>
                                <Icon type="edit" />
                                {' Editar'}
                            </TableButton>
                            <TableButton onClick={e => e.stopPropagation()}>
                                <Popconfirm
                                    placement="topRight"
                                    title={RemoveMessage}
                                    okText="Remover"
                                    onConfirm={() => { deleteUnit(data._id) }}>
                                    <Icon type="delete" />
                                    {' Remover'}
                                </Popconfirm>
                            </TableButton>
                        </>
                        :
                        <>
                            <TableButton onClick={addEditUnit}>
                                <Icon type="save" />
                                {' Guardar'}
                            </TableButton>
                            <TableButton onClick={e => {
                                if (editUnit._id.includes('FFFFF')) {
                                    let rows = paymentDay.filter(f => !f._id.includes('FFFFF'));
                                    setEditUnit(null)
                                    setPaymentDay(rows)
                                } else setEditUnit(null);
                            }}>
                                <Icon type="close" />
                                {' Cancelar'}
                            </TableButton>
                        </>
                    }
                </Fragment>
            )
        }
    ]

    const copyEditUnit = (unit) => {
        let x = JSON.parse(JSON.stringify(unit ? unit : editUnit));
        x.dates[0].date = x.dates[0].date != null ? moment(x.dates[0].date) : null;

        return x;
    }

    useEffect(() => {
        getAllPaymentDaysGrouped();
    }, [])

    useEffect(() => {
        getAllPaymentDaysGrouped();
    }, [date])

    const handleChangePage = cp => {
        setCurrentPage(cp);
    }

    const handleChangeRowsPerPage = (currentSize, pageSize) => {
        setCurrentPage(1);
        setPageSize(pageSize)
    }

    const isEditing = (unit) => {
        return editUnit ? editUnit._id === unit._id : false;
    }

    const setEditForm = (unit) => {
        let x = copyEditUnit(unit);
        setEditUnit(x);
    }

    const getAllPaymentDaysGrouped = async () => {
        setLoading(true)
        const result = await GetAllPaymentDaysGrouped();

        if (date != null) {
            result.data = result.data.filter(x => moment(x.dates[0].date).isSameOrAfter(moment(date).startOf()))
        }

        if (date2 != null) {
            result.data = result.data.filter(x => moment(x.dates[x.dates.length - 1].date).isSameOrBefore(moment(date2).endOf()))
        }

        setPaymentDay(result.data);
        setLoading(false)
    }

    const RemoveMessage = (
        <div>
            <div>Tem a certeza que quer remover o dia de pagamento? </div>
            <div>
                Toda a informação será removida da plataforma e não conseguirá ser recuperada.
            </div>
        </div>
    );

    const setAddForm = () => {
        const rows = paymentDay;
        const unit = {
            _id: 'FFFFF' + moment().valueOf(),
            dates: [{
                id: 'FFFFF' + moment().valueOf(),
                date: null,
                monthly: false,
                originalId: null,
                user: JSON.parse(localStorage.user)._id,
                employee: null
            }]
        };
        if (!editUnit) {
            rows.push(unit);
            setPaymentDay(rows)
            setEditUnit(unit)
        }
    }

    const addEditUnit = async () => {
        const unit = editUnit;

        if (unit.dates[0].date == null) {
            Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Deve inserir a data.'
            });
            return
        }

        if (unit.dates[0].originalId != null) {
            DeletePaymentDay(unit.dates[0].originalId);
            unit.dates[0].originalId = null;
        }
        unit.dates[0].id = null;
        await CreatePaymentDay(unit.dates[0]);
        setEditUnit(null);
        getAllPaymentDaysGrouped();
    }

    const deleteUnit = async id => {
        await DeletePaymentDay(id);
        getAllPaymentDaysGrouped();
    }

    const goBack = () => {
        fromReserveAgenda
            ? history.push('/reserves-agenda')
            : fromReservePriceAgenda
                ? history.push('/reserves-prices')
                : isEmployeeOrChief() || isCurrentUserHumanResources()
                    ? history.push('/calendar')
                    : history.push('/weddings-calendar');
    }

    const onSelectCalendarMenu = (value) => {
        const itemSelected = parseInt(value.key);
        if (itemSelected === 1) {
            const url = fromReservePriceAgenda
                ? '/rest-days?page=RP'
                : fromReserveAgenda
                    ? '/rest-days?page=RA'
                    : '/rest-days';
            history.push(url);
        } else if (itemSelected === 2) {
            const url = fromReservePriceAgenda
                ? '/payment-days?page=RP'
                : fromReserveAgenda
                    ? '/payment-days?page=RA'
                    : '/payment-days';
            history.push(url);
        } else if (itemSelected === 3) {
            const url = fromReservePriceAgenda
                ? '/couple-payment-days?page=RP'
                : fromReserveAgenda
                    ? '/couple-payment-days?page=RA'
                    : '/couple-payment-days';
            history.push(url);
        } else goBack();
    }

    const menu = (
        <Menu onClick={onSelectCalendarMenu}>
            {!isRestDays && <Menu.Item key={1}>
                <Icon type="calendar" />
                Dias de Descanso
            </Menu.Item>}
            {!isPaymentDays && <Menu.Item key={2}>
                <Icon type="euro" />
                Dias de Pagamento
            </Menu.Item>}
            {!isCouplePaymentDays && <Menu.Item key={3} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                <Icon type="sketch" />
                <span style={{ display: 'flex', flexDirection: 'column', alignContent: 'flex-start', alignItems: 'flex-start' }}>
                    <span>Dias de Exceção</span>
                    <span>Pagamento Noivos</span>
                </span>
            </Menu.Item>}
            <Menu.Item key={4}>
                <Icon type="arrow-left" />
                Voltar
            </Menu.Item>
        </Menu>
    );

    return (
        <React.Fragment>
            <HeaderContainer>
                <HeaderTitle buttons={1}>
                    <PageTitle>Calendário</PageTitle>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <a href={goBack}>
                                {fromReserveAgenda ? 'Agenda de Reservas' : fromReservePriceAgenda ? 'Agenda de Preços das Reservas' : 'Calendário'}
                            </a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Dias de Pagamento
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderTitle>
                <HeaderButtonsContainer buttons={1}>
                    <Dropdown overlay={menu} trigger={['click']}>
                        <BaseButton
                            type={'primary'}
                            icon={'down'}
                            text={'Opções'}
                        />
                    </Dropdown>
                </HeaderButtonsContainer>
            </HeaderContainer>
            <PageContainer buttons={1}>
                <TableFilterSection>
                    <Row gutter={12}>
                        <Col md={4}>
                            <label>Data Inicial</label>
                            <DatePicker value={date} format={'DD/MM/YYYY'} placeholder='Selecione Data' onChange={(e) => { setDate(e) }} style={{ width: '100%', marginTop: 5 }} />
                        </Col>
                        <Col md={4}>
                            <label>Data Final</label>
                            <DatePicker value={date2} format={'DD/MM/YYYY'} placeholder='Selecione Data' onChange={(e) => { setDate2(e) }} style={{ width: '100%', marginTop: 5 }} />
                        </Col>
                        {/* <Col md={4}>
                            <div className="sc-dxgOiQ cGTQcX">
                                <Checkbox style={{ marginTop: 28 }} checked={allDay} onChange={(e) => { setAllDay(e.target.checked) }}>Todo o Dia</Checkbox>
                            </div>
                        </Col> */}
                        <Col md={16}>
                            <BaseButton
                                type="primary"
                                icon="plus"
                                text={"Adicionar Dia de Pagamento"}
                                style={{ marginTop: 18 }}
                                onClick={setAddForm}
                            />
                        </Col>
                    </Row>
                </TableFilterSection>
                <Table
                    columns={columns}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    defaultPageSize={pageSize}
                    loading={loading}
                    rows={paymentDay}
                    showHeader={true}
                    emptyIcon="euro"
                    emptyText={'Não existem dias de pagamento'}
                    total={paymentDay.length}
                    rowKey={'_id'}
                    hasPagination={true}
                    hasPageSize={false}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </PageContainer>
        </React.Fragment>
    );
};

export default withRouter(PaymentDays);