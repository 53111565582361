import client from '../config/AxiosConfig';

// export const GetUpgrades = async id => client.get('/upgrades');
export const GetUpgrades = async () => client.get('/upgrades');

export const GetUpgrade = async id => client.get(`/upgrades/${id}`);

export const CreateUpgrade = async data => client.post('/upgrades', data);

export const UpdateUpgrade = async (id, data) =>
  client.put(`/upgrades/${id}`, data);

export const ToggleUpgradeOptions = async (id, data) =>
  client.put(`/upgrades/${id}/toggleOptions`, data);

export const ToggleUpgrade = async (id, data) =>
  client.put(`/upgrades/${id}/toggle`, data);

export const DeleteUpgrade = async (id, data) =>
  client.delete(`/upgrades/${id}`, data);

export const SaveImage = async (id, data) =>
  client.put(`/upgrades/${id}/image`, data);

export const DeleteImage = async (id, img_id) =>
  client.delete(`/upgrades/${id}/image/${img_id}`);

export const SaveSection = async data => client.post('/upgrade/sections', data);

export const EditSection = async (id, data) =>
  client.put(`/upgrade/sections/${id}`, data);

export const DeleteSection = async id =>
  client.delete(`/upgrade/sections/${id}`);

export const OrderSections = async data =>
  client.post('/upgrade/sections/order', data);
