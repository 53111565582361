import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import moment from 'moment';
import 'moment/locale/pt';
import {
  getWeddingName,
  getLocal,
  getLocalColor
} from '../../infra/services/wedding/weddingUtils';
import { Col, Row } from 'antd';
import BasicInfoComponent from '../../process/dashboard/components/BasicInfoComponent';
import { BasicInfoSection, InfoText, PrivateNote, SectionCTA, SectionTitle } from '../../process/dashboard/ProcessStyles';
import PrivateNotesComponent from '../../process/dashboard/components/PrivateNotesComponent';
import ImageComponent from '../../components/images/ImageComponent';
import styledComponents from 'styled-components';
import WeddingIcon from './../../assets/menu/ic_wedding.svg';
import BaseButton from '../../components/buttons/BaseButton';
import SectionPrivateNotes from '../../process/dashboard/components/SectionPrivateNotes';
import PrivateNotesModalComponent from '../../process/dashboard/components/PrivateNotesModalComponent';
import { UpdatePrivateNotes, UpdateWeddingDetails } from '../../infra/requests/WeddingProcessRequests';
import Alerts from '../../components/alert/Alert';
import WeddingModal from './WeddingModal';
import { CreateSetting, GetSettingsByType } from '../../infra/requests/SettingRequests';

const CouplePhoto = styledComponents.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  text-align: center;
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.inputBorderColor};
`;

const CoupleInfo = styledComponents.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const BrideGromInfo = styledComponents.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  align-items: flex-start;
  width: 50%;
`;

const SectionContainer = styledComponents.pre`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 25px;
`;

export const Label = styledComponents.div`
  display: inline-block;
  font-size: ${({ small }) => (small ? '12px' : '16px')};
  width: 100%;
  ${({ error }) => error && 'color: red'};
  margin-top: ${({ sublabel }) => (sublabel ? '10px' : 0)};
  font-weight: 700;
`;

const WeddingDetail = ({ wedding, colors, dispatch, onChange }) => {

  const [showNotesModal, toggleNotesModal] = useState(false);
  const [showModal, toggleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [graphics, setGraphics] = useState([]);
  const [dataModal, setDataModal] = useState(null);
  const [loadingModal, toogleLoadingModal] = useState(false);

  useEffect(() => {
    getGraphics();
  }, []);

  const getGraphics = async () => {
    const result = await GetSettingsByType(6);
    if (result?.success && result?.data) setGraphics(result?.data);
    toogleLoadingModal(false);
  }

  const closeNotesModal = () => {
    toggleNotesModal(false);
  };

  const openNotesModal = () => {
    dispatch(initialize('private_notes_modal_form', { private_notes: wedding?.private_notes }));
    toggleNotesModal(true);
  };

  const closeModal = () => {
    toggleModal(false);
  };

  const openModal = () => {
    setDataModal({
      environments: wedding?.environments,
      proofs: wedding?.proofs,
      meetings: wedding?.meetings,
      graphics: wedding?.graphics && wedding?.graphics?._id ? wedding?.graphics?._id : wedding?.graphics,
    });
    toggleModal(true);
  };

  const onNotesSubmit = async values => {
    try {
      setLoading(true);

      const { success, data } = await UpdatePrivateNotes(wedding?._id, values);

      if (success) {
        Alerts.new({
          type: 'success',
          title: 'Gravado com sucesso',
          text: 'A informação foi gravada com sucesso!'
        });
        onChange(data);
      }

      setLoading(false);
      closeNotesModal();
    } catch (e) {
      console.error(e);
      setLoading(false);
      closeNotesModal();
    }
  };

  const onModalSubmit = async values => {
    try {
      setLoading(true);

      const { success, data } = await UpdateWeddingDetails(wedding?._id, values);

      if (success) {
        Alerts.new({
          type: 'success',
          title: 'Gravado com sucesso',
          text: 'A informação foi gravada com sucesso!'
        });
        onChange(data);
      }

      setLoading(false);
      closeModal();
    } catch (e) {
      console.error(e);
      setLoading(false);
      closeModal();
    }
  };

  const addGraphic = async (value, form) => {
    toogleLoadingModal(true);

    const graphic = {
      name: value,
      entity: false,
      paymentMethod: false,
      invoicingType: false,
      priceUnits: false,
      graphics: true,
    };

    const result = await CreateSetting(graphic);
    // console.warn('result', result);

    if (result?.success) {
      setDataModal({
        environments: form?.environments,
        proofs: form?.proofs,
        meetings: form?.meetings,
        graphics: result?.data && result?.data?._id ? result?.data?._id : null,
      });
      await getGraphics();
    } else toogleLoadingModal(false);
  }

  const local = getLocalColor(wedding, colors);

  const getWeddingDate = () => {
    return wedding?.date ? moment.utc(wedding?.date).format('DD-MM-YYYY') : '';
  }

  return (
    <div>
      <InfoText noTop>
        <span>{getWeddingDate()}</span>
        <span>{local ? ` | ${local.name}` : ''}</span>
      </InfoText>
      <CoupleInfo>
        <BrideGromInfo>
          <b>{wedding?.bride?.name}</b>
          <span><b>Email:</b> {wedding?.bride?.email}</span>
          {/* <span><b>Contacto:</b> {wedding?.bride?.contact || 'Não registado'}</span> */}
        </BrideGromInfo>
        <BrideGromInfo>
          <b>{wedding?.groom?.name}</b>
          <span><b>Email:</b> {wedding?.groom?.email}</span>
          {/* <span><b>Contacto:</b> {wedding?.groom?.contact || 'Não registado'}</span> */}
        </BrideGromInfo>
      </CoupleInfo>
    </div>
    // <Row gutter={[12, 12]}>
    //   <Col xs={24} sm={24} md={24} lg={24}>
    //     <div style={{ display: 'flex', flexDirection: 'row' }}>
    //       <CouplePhoto src={wedding?.photo ? wedding?.photo?.url : WeddingIcon} />
    //       <CoupleInfo>
    //         <BrideGromInfo>
    //           <InfoText isTitle noTop>{wedding?.bride?.name}</InfoText>
    //           <InfoText>Email: {wedding?.bride?.email}</InfoText>
    //           <InfoText>Contacto: {wedding?.bride?.contact || 'Não registado'}</InfoText>
    //         </BrideGromInfo>
    //         <BrideGromInfo>
    //           <InfoText isTitle noTop>{wedding?.groom?.name}</InfoText>
    //           <InfoText>Email: {wedding?.groom?.email}</InfoText>
    //           <InfoText>Contacto: {wedding?.groom?.contact || 'Não registado'}</InfoText>
    //         </BrideGromInfo>
    //       </CoupleInfo>
    //     </div>
    //   </Col>
    //   <Col xs={24} sm={24} md={24} lg={24}>
    //     <BasicInfoSection>
    //       <SectionTitle darkBg={true} insideTable={true}>
    //         Pendentes e Orçamento
    //         <SectionCTA>
    //           <BaseButton
    //             type={'default'}
    //             icon={'arrow-right'}
    //             text={'Editar'}
    //             onClick={() => openNotesModal()}
    //           />
    //         </SectionCTA>
    //       </SectionTitle>
    //       {/* <SectionPrivateNotes note={wedding?.private_notes} /> */}
    //       <SectionContainer dangerouslySetInnerHTML={{
    //         __html: wedding?.private_notes || 'Não existe nenhuma nota registada!'
    //       }} />
    //       {
    //         showNotesModal &&
    //         <PrivateNotesModalComponent
    //           open={showNotesModal}
    //           onSubmit={onNotesSubmit}
    //           loading={loading}
    //           closeModal={closeNotesModal}
    //         />
    //       }
    //     </BasicInfoSection>
    //   </Col>
    //   <Col xs={24} sm={24} md={24} lg={24}>
    //     <BasicInfoSection>
    //       <SectionTitle darkBg={true} insideTable={true}>
    //         Detalhes
    //         <SectionCTA>
    //           <BaseButton
    //             type={'default'}
    //             icon={'arrow-right'}
    //             text={'Editar'}
    //             onClick={() => openModal()}
    //           />
    //         </SectionCTA>
    //       </SectionTitle>
    //       <Label>Ambientes</Label>
    //       <SectionContainer>{wedding?.environments || 'Sem Informação'}</SectionContainer>

    //       <Label>Provas</Label>
    //       <SectionContainer>{wedding?.proofs || 'Sem Informação'}</SectionContainer>

    //       <Label>Reuniões com o Organizador</Label>
    //       <SectionContainer>{wedding?.meetings || 'Sem Informação'}</SectionContainer>

    //       <Label>Grafismo</Label>
    //       <SectionContainer>{wedding?.graphics?.name || 'Sem Informação'}</SectionContainer>

    //       {showModal &&
    //         <WeddingModal
    //           isOpen={showModal}
    //           onSubmit={onModalSubmit}
    //           loading={loadingModal}
    //           onClose={closeModal}
    //           initialValues={dataModal}
    //           graphics={graphics}
    //           addGraphic={addGraphic}
    //         />}
    //     </BasicInfoSection>
    //   </Col>
    // </Row>
  );
};

export default connect()(WeddingDetail);