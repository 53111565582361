import styled from 'styled-components';
import {Collapse} from 'antd';
const { Panel } = Collapse;

export const RegisterBox = styled.div`
  display: block;
  width: 100%;
  max-width: 600px;
  padding: 15px;
  background: #faf9f9;
  margin-bottom: 20px;
  text-align: left;
  position: relative;
`;

export const Title = styled.div`
  color: #000000;
  font-size: 18px;
  font-weight: bold;
`;

export const Description = styled.div`
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
`;
export const Value = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
`;
export const Date = styled.div`
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
`;

export const OptionButton = styled.div`
  cursor: pointer;
  position: absolute;
  background: white;
  padding: 5px;
  top: -5px;
  right: ${({number}) => -5 + number * 30}px;
  box-shadow: 1px 1px 3px #ccc;
`;

export const DetailedLine = styled.div`
  display: inline-block;
  width: 100%;
  padding: 5px 0;
  text-align: left;
  font-weight: bold;
`;

export const CollapseContainer = styled(Collapse)`
  &&& {
    background-color: #ffffff;
    border: 0;

    & .ant-collapse-item {
      border-bottom: 1px solid #cacaca;
    }

    & .ant-collapse-header {
      padding: 0px;
    }

    & .ant-collapse-content {
      border-top: 0;

      & .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
`;

export const PanelContainer = styled(Panel)`

`;

export const NoRecords = styled.div`
  font-size: 13px;
  padding: 10px 0px;
`;

export const BudgetGuestsNote = styled.div`
  padding: 5px;
  color: #000000;
  background-color: #E0E0E0;
  margin-bottom: 10px;
  text-align: left;
`;