import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import YearInput from '../../../components/inputs/YearInput';
import XpertGoDateInput from '../../../components/inputs/XpertGoDateInput';
import { TableFilterSection } from '../../../styles/BasicStyles';
import BaseButton from '../../../components/buttons/BaseButton';
import moment from 'moment';

class PaymentAccountsFilters extends Component {
  timeout = 0;
  state = {
    startDate: '',
    endDate: '',
    // dates: [],
    year: moment.utc().startOf('year').format('YYYY'),
    loading: false,
    adding: false,
  };

  componentDidMount = async () => {
  };

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }

    if (prevProps.adding !== this.props.adding) {
      this.setState({ adding: this.props.adding });
    }
  }

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map(key => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(query);
  };

  render() {
    const { loading, adding } = this.state;
    const { startDate, endDate, dates, year } = this.state;

    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={5} lg={4} xl={3}>
            <XpertGoDateInput
              label="Data inicial"
              disabled={loading}
              placeholder="Escolher data"
              input={{
                value: startDate,
                onChange: value => {
                  this.onInputChange('startDate', value);
                  this.onInputChange('year', null);
                }
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} sm={12} md={5} lg={4} xl={3}>
            <XpertGoDateInput
              label="Data final"
              disabled={loading}
              placeholder="Escolher data"
              input={{
                value: endDate,
                onChange: value => {
                  this.onInputChange('endDate', value);
                  this.onInputChange('year', null);
                }
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} sm={12} md={5} lg={3} xl={2}>
            <YearInput
              label="Ano"
              disabled={loading}
              placeholder="Escolher ano"
              input={{
                value: year,
                onChange: value => {
                  this.onInputChange('startDate', null);
                  this.onInputChange('endDate', null);
                  this.onInputChange('year', value);
                }
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} sm={12} md={16} lg={13} xl={16} className='setting-col-btn'>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              loading={adding}
              text={'Adicionar conta'}
              onClick={() => this.props.add()}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}


export default (PaymentAccountsFilters);
