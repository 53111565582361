import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, message, Input, Popconfirm, Tooltip } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector, change } from 'redux-form';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { BaseForm, SpinLoading } from '../../styles/BasicStyles';
import PropTypes from 'prop-types';
import DraftInput from '../../components/inputs/DraftInput';
import SelectInput from '../../components/inputs/SelectInput';
import { isCurrentUserAdmin } from '../../infra/helpers/Helpers';
import { GetAttendingEmployees, GetAttendingList, GetAvailableAttendingList, GetWeddingGuests } from '../../infra/requests/UsersRequests';
import TextInput from '../../components/inputs/TextInput';
import SelectCompactInput from '../../components/inputs/SelectCompactInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import { GenerateAccessCode, GetConfirmedWeddings, GetWedding, GetWeddingsSelect, MngGuestAccess, NotifyWeddingGuest, UpdateCreateWeddingGuest } from '../../infra/requests/WeddingRequests';
import Alerts from '../../components/alert/Alert';
import { CodeInput, CodeP } from '../../process/ChoicesPage/SectionStyles';
import BaseButton from '../../components/buttons/BaseButton';
import { bindActionCreators } from 'redux';
import { getWeddingFullName, getWeddingName, getWeddingTitle } from '../../infra/services/wedding/weddingUtils';
import styledComponents from 'styled-components';
import { CheckSchedule, GetSchedule } from '../../infra/requests/SchedulesRequests';
import { GetAttendingTypes } from '../../infra/requests/AttendingTypeRequests';
import { visitedLocals } from './SchedulesFilters';
import { TimeInput } from '../../components/inputs/InputStyles';
import XpertGoTimeInput from '../../components/inputs/XpertGoTimeInput';
import DateInput from '../../components/inputs/DateInput';
import momentTimezone from 'moment-timezone';
import moment from 'moment';
const { confirm } = Modal;

const validations = (values) => {
    let errors = {};

    errors = FormValidator.make({
        date: 'required',
        weddingPlace: 'required',
    })(values);

    return errors;
};

class UnavailableModal extends React.Component {
    state = {
        loading: false,
        createAction: false,
        disabled: false,
    }

    componentDidMount() {
        const { dispatch } = this.props;
        const { initialValues } = this.props;

        this.setState({
            createAction: initialValues?.createAction,
            disabled: initialValues?.weddingPlace ? true : false
        });

        dispatch(
            initialize('unavailable_form', {
                createAction: initialValues?.createAction,
                date: initialValues?.date || null,
                weddingPlace: initialValues?.weddingPlace || null,
            })
        );
    }

    render() {
        const { isOpen } = this.props;
        const { handleSubmit, onClose, onSubmit, invalid, change, submitFailed, saving, confirming } = this.props;
        const { loading, createAction, disabled } = this.state;

        return (
            <Modal
                visible={isOpen}
                title={createAction ? 'Marcar local como fechado' : 'Marcar local como aberto'}
                maskClosable
                onCancel={onClose}
                footer={[
                    <Button
                        key='cancel'
                        type='default'
                        onClick={onClose}>
                        Fechar
                    </Button>,
                    <Button
                        key='submit'
                        type='primary'
                        loading={saving}
                        disabled={loading}
                        onClick={handleSubmit(onSubmit)}>
                        Gravar
                    </Button>
                ]} >

                {
                    loading
                        ? <SpinLoading />
                        : <BaseForm onSubmit={handleSubmit(onSubmit)}>
                            <Field
                                component={DateInput}
                                name="date"
                                label="Data *"
                                placeholder="Selecione a data"
                                disabled={true}
                            />
                            <Field
                                component={SelectInput}
                                name={'weddingPlace'}
                                label={`Selecione o local`}
                                data={visitedLocals}
                                disabled={disabled}
                            />
                        </BaseForm >}
            </Modal >
        );
    }
};

UnavailableModal = reduxForm({
    form: 'unavailable_form',
    // enableReinitialize: true,
    validate: validations,
})(UnavailableModal);

const selector = formValueSelector('unavailable_form');

const mapStateToProps = state => ({
    scheduleForm: {
        date: selector(state, 'date'),
        weddingPlace: selector(state, 'weddingPlace'),
    }
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ change }, dispatch);
}

UnavailableModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(UnavailableModal)