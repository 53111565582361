import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../components/inputs/TextInput';
import FormValidator from '../../infra/services/validations/FormValidator';
import BaseButton from '../../components/buttons/BaseButton';
import Logo from '../../assets/icons/logo.png';
import {
  LoginContainer,
  LoginCard,
  LoginForm,
  LogoImg,
  LogoSection,
  LoginButtonDiv,
  FormTitle,
  BaseLink
} from './LoginPageStyles';
import { Icon, Result } from 'antd';
import { Login } from '../../infra/requests/AuthRequests';
import { SaveUser } from '../../redux/User/user.actions';
import { auth_token_key } from '../../infra/config/LocalStorageKeys';
import { GetEmployeeByUser } from '../../infra/requests/EmployeeRequests';
import { getUserId, isCurrentUserChief, isCurrentUserEmployee, isCurrentUserEmployeeAttending, isCurrentUserHumanResources } from '../../infra/helpers/Helpers';

const formRules = FormValidator.make({
  email: 'required|email',
  password: 'required|min:6'
});

class LoginPage extends Component {
  onSubmit = async values => {
    try {
      const { SaveUser, history } = this.props;
      const result = await Login(values);
      if (result.success) {
        localStorage.setItem(auth_token_key, result.data.token);
        localStorage.setItem('user', JSON.stringify(result.data.user));

        SaveUser(result.data.user);

        if (isCurrentUserEmployee() || isCurrentUserChief() || isCurrentUserHumanResources() || isCurrentUserEmployeeAttending()) {
          const resultEmployee = await GetEmployeeByUser(result.data.user._id);
          if (this.props.location.pathname !== '/login') history.push(this.props.location.pathname);
          else if (resultEmployee.data.active && isCurrentUserEmployeeAttending()) history.push('/home-attending');
          else if (resultEmployee.data.active) history.push('/home');
          else history.push('/profile');
        }
        else if (this.props.location.pathname !== '/login') history.push(this.props.location.pathname);
        else history.push('/');
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit(this.onSubmit)}>
          <LoginCard>
            <LogoSection>
              <LogoImg src={Logo} alt="logo" />
            </LogoSection>
            <FormTitle>Login</FormTitle>
            <Field
              component={TextInput}
              name="email"
              type="email"
              label="Email"
              placeholder="Insert your email"
              prefix={<Icon type="mail" />}
            />
            <Field
              component={TextInput}
              name="password"
              type="password"
              label="Password"
              placeholder="Insert your password"
              prefix={<Icon type="lock" />}
            />
            <BaseLink to="/recover-account">Recover Account</BaseLink>
            <LoginButtonDiv>
              <BaseButton
                variant="raised"
                htmlType="submit"
                type="primary"
                loading={submitting}
                text="login"
              />
            </LoginButtonDiv>
          </LoginCard>
        </LoginForm>
      </LoginContainer>
    );
  }
}

LoginPage = reduxForm({
  form: 'login',
  validate: formRules
})(LoginPage);

const mapStateToProps = (state, ownProps) => ({});

const mapActionToProps = dispatch => bindActionCreators({ SaveUser }, dispatch);

export default connect(
  mapStateToProps,
  mapActionToProps
)(LoginPage);
