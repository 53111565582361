import React, { useEffect, useState } from "react";
import { Modal, Button, Icon } from 'antd';
import styled from 'styled-components';
import { GetDynamicPage } from "../../infra/requests/BaseRequests";
import { SpinLoading } from "../../styles/BasicStyles";
import { AgendaType } from "../reserves/ReserveAgenda";
import { AgendaAttendingType } from "../reservesAttending/ReservesAttending";

export const StyledDraftJS = styled.div`
  display: inline-block;
  width: 100%;
  height: calc(100vh - 250px);
  padding: 24px;
  overflow: auto;
  text-align: left;
  white-space: pre-line;
`;

let WaitingInterestPolicyModal = ({ openModal, closeModal, agendaType }) => {

  const [content, setContent] = useState({});
  const [loading, toogleLoading] = useState(true);

  useEffect(() => {
    async function getContent() {
      toogleLoading(true);
      const pageId = (agendaType === AgendaType.WAITING_DATE || agendaType === AgendaAttendingType.WAITING_DATE)
        ? '6516c9d5c8406436b0cd6677'
        : '6538e582b1c5920de5ac8013';
      const { data } = await GetDynamicPage(pageId);
      setContent(data);
      toogleLoading(false);
    }
    getContent();
    document.body.style.overflowY = 'auto';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      visible={openModal}
      title="Termos e Condições"
      maskClosable
      onCancel={closeModal}
      className="policy-modal"
      footer={[,
        <Button
          key='submit'
          loading={loading}
          type='primary'
          onClick={() => closeModal()}>
          OK
        </Button>
      ]}
    >
      {loading
        ? <SpinLoading />
        : <StyledDraftJS
          dangerouslySetInnerHTML={{
            __html: content?.description?.pt || ''
          }}
        />
      }
    </Modal>
  );
};

export default WaitingInterestPolicyModal;