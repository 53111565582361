import React from 'react';
import styled from 'styled-components';
import { Field, reduxForm } from 'redux-form';
import DraftInput from '../inputs/DraftInput';
import { BaseButton } from '../buttons/BaseButton';
import { Prompt } from "react-router";

const NotesForm = styled.form`
  margin: 0;
  padding: 0 0 60px 0;
`;

const ActionBar = styled.div`
  margin-top: 20px;
  padding: 15px 0px;
  background: white;
  border-top: 1px solid #e8e8e8;
`;

const DrawerFixed = styled.div`
  display: ${({ open }) => open ? 'inline-block' : 'none'};
  width: 300px;
  height: ${({ printed }) => printed ? 'calc(100vh - 195px)' : 'calc(100vh - 146px)'};
  padding: 0 15px;
  text-align: left;
  overflow-y: auto;
  position: relative;
  border-left: 1px solid #d9d9d9;
  vertical-align: top;
`;

let CommentsDrawerFixed = ({
  handleSubmit,
  open,
  onClose,
  saveNotes,
  loading,
  printed,
  dirty
}) => {
  return (
    <DrawerFixed open={open} printed={printed}>
      <Prompt
        when={dirty}
        message='Está prestes a sair sem guardar as alterações. Deseja continuar?'
      />
      {
        open && 
        <NotesForm onSubmit={handleSubmit(saveNotes)}>
          <Field
            name="notes"
            component={DraftInput}
            minRows={10}
            label="Notas Publicas"
            placeholder="Adicione aqui as notas para partilhar"
            toolbarOnFocus={false}
            toolbar='notes'
          />
          <Field
            name="private"
            component={DraftInput}
            minRows={10}
            label="Notas Privadas"
            placeholder="Adicione aqui as suas notas privadas"
            toolbarOnFocus={false}
            toolbar='notes'
          />
          <ActionBar>
            <BaseButton
              loading={loading}
              type={'primary'}
              icon={'save'}
              htmlType="submit"
              align="right"
              text={'Gravar'}
            />
            <BaseButton
              type={'default'}
              icon={'close'}
              htmlType="button"
              align="right"
              text={'Fechar'}
              onClick={handleSubmit((values) => onClose(values))}
              style={{ marginRight: '5px' }}
            />
          </ActionBar>
        </NotesForm>
      }
    </DrawerFixed>
  );
};

CommentsDrawerFixed = reduxForm({
  form: 'wedding_notes_section_form'
})(CommentsDrawerFixed);

export default CommentsDrawerFixed;