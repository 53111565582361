export const PermissionsEnum = {
  //Management
  MANAGEMENTS: 'MANAGEMENTS',
  MNG_CONFIGS: 'MNG_CONFIGS',
  MNG_READ_INFO: 'MNG_READ_INFO',
  MNG_EDIT_INFO: 'MNG_EDIT_INFO',
  MNG_APPOINTMENTS: 'MNG_APPOINTMENTS',
  MNG_CONTACTS: 'MNG_CONTACTS',
  MNG_BANNERS: 'MNG_BANNERS',
  CREATE_BANNER: 'CREATE_BANNER',
  LIST_BANNER: 'LIST_BANNER',
  EDIT_BANNER: 'EDIT_BANNER',
  REMOVE_BANNER: 'REMOVE_BANNER',
  MNG_IMAGE_PORTFOLIO: 'MNG_IMAGE_PORTFOLIO',
  CREATE_IMAGE_PORTFOLIO: 'CREATE_IMAGE_PORTFOLIO',
  LIST_IMAGE_PORTFOLIO: 'LIST_IMAGE_PORTFOLIO',
  EDIT_IMAGE_PORTFOLIO: 'EDIT_IMAGE_PORTFOLIO',
  // REMOVE_IMAGE_PORTFOLIO: 'REMOVE_IMAGE_PORTFOLIO',
  MNG_VIDEO_PORTFOLIO: 'MNG_VIDEO_PORTFOLIO',
  CREATE_VIDEO_PORTFOLIO: 'CREATE_VIDEO_PORTFOLIO',
  LIST_VIDEO_PORTFOLIO: 'LIST_VIDEO_PORTFOLIO',
  EDIT_VIDEO_PORTFOLIO: 'EDIT_VIDEO_PORTFOLIO',
  // REMOVE_VIDEO_PORTFOLIO: 'REMOVE_VIDEO_PORTFOLIO',
  MNG_REVIEWS: 'MNG_REVIEWS',
  CREATE_REVIEW: 'CREATE_REVIEW',
  LIST_REVIEW: 'LIST_REVIEW',
  EDIT_REVIEW: 'EDIT_REVIEW',
  // REMOVE_REVIEW: 'REMOVE_REVIEW',
  MNG_RESERVATIONS: 'MNG_RESERVATIONS',
  CREATE_RESERVATION: 'CREATE_RESERVATION',
  LIST_RESERVATION: 'LIST_RESERVATION',
  EDIT_RESERVATION: 'EDIT_RESERVATION',
  // REMOVE_RESERVATION: 'REMOVE_RESERVATION',
  MNG_METATAGS: 'MNG_METATAGS',
  LIST_MNG_METATAG: 'LIST_MNG_METATAG',
  EDIT_MNG_METATAG: 'EDIT_MNG_METATAG',

  // Weddings
  WEDDINGS: 'WEDDINGS',
  CREATE_WEDDING: 'CREATE_WEDDING',
  LIST_WEDDING: 'LIST_WEDDING',
  READ_WEDDING: 'READ_WEDDING',
  EDIT_WEDDING: 'EDIT_WEDDING',
  REMOVE_WEDDING: 'REMOVE_WEDDING',
  LOGS: 'LOGS',
  WEDDING_PAYMENTS: 'WEDDING_PAYMENTS',
  // Archive
  ARCHIVES: 'ARCHIVES',
  CREATE_ARCHIVE: 'CREATE_ARCHIVE',
  LIST_ARCHIVE: 'LIST_ARCHIVE',
  READ_ARCHIVE: 'READ_ARCHIVE',

  //Wedding sections
  WEDDING_SUMMARY: 'WEDDING_SUMMARY',
  WEDDING_MENU: 'WEDDING_MENU',
  WEDDING_BUDGET: 'WEDDING_BUDGET',
  WEDDING_ABOUT_US: 'WEDDING_ABOUT_US',
  WEDDING_STYLE: 'WEDDING_STYLE',
  WEDDING_DAY: 'WEDDING_DAY',
  WEDDING_CEREMONY: 'WEDDING_CEREMONY',
  WEDDING_STAFF: 'WEDDING_STAFF',
  WEDDING_GUEST: 'WEDDING_GUEST',
  WEDDING_GUEST_MAP: 'WEDDING_GUEST_MAP',
  WEDDING_ROOM_PLAN: 'WEDDING_ROOM_PLAN',
  WEDDING_FOOD: 'WEDDING_FOOD',
  WEDDING_DRINK: 'WEDDING_DRINK',
  WEDDING_DECOR: 'WEDDING_DECOR',
  WEDDING_PARTY: 'WEDDING_PARTY',
  WEDDING_UPGRADE: 'WEDDING_UPGRADE',
  WEDDING_LOGS: 'WEDDING_LOGS',

  // Team bookins
  TEAM_BOOKING: 'TEAM_BOOKING',

  //Wedding teams
  WEDDING_TEAMS: 'WEDDING_TEAMS',

  //Calendar
  CALENDAR: 'CALENDAR',
  CALENDAR_WEDDING: 'CALENDAR_WEDDING',
  CALENDAR_YEAR_WEDDING: 'CALENDAR_YEAR_WEDDING',
  REST_DAYS: 'REST_DAYS',
  PAYMENT_DAYS: 'PAYMENT_DAYS',
  COUPLE_PAYMENT_DAYS: 'COUPLE_PAYMENT_DAYS',

  //Users
  USERS: 'USERS',
  LIST_USER: 'LIST_USER',
  RESEND_EMAIL_USER: 'RESEND_EMAIL_USER',

  //Reserves
  RESERVES: 'RESERVES',
  AGENDA: 'AGENDA',
  RESERVES_AGENDA: 'RESERVES_AGENDA',
  INTEREST_AGENDA: 'INTEREST_AGENDA',
  WAITING_LIST_AGENDA: 'WAITING_LIST_AGENDA',
  RESERVE_PRICES_AGENDA: 'RESERVE_PRICES_AGENDA',
  LIST_RESERVE: 'LIST_RESERVE',
  LIST_WAITING_DATES: 'LIST_WAITING_DATES',
  LIST_INTEREST_DATES: 'LIST_INTEREST_DATES',
  SCHEDULES: 'SCHEDULES',
  CREATE_SCHEDULE: 'CREATE_SCHEDULE',
  CREATE_INTERNAL_MEETING: 'CREATE_INTERNAL_MEETING',
  LIST_SCHEDULE: 'LIST_SCHEDULE',
  EDIT_SCHEDULE: 'EDIT_SCHEDULE',
  REMOVE_SCHEDULE: 'REMOVE_SCHEDULE',
  CONFIRM_SCHEDULE: 'CONFIRM_SCHEDULE',
  PRENSENCE_SCHEDULE: 'PRENSENCE_SCHEDULE',
  ORGANIZER_SCHEDULE_SERVICES: 'ORGANIZER_SCHEDULE_SERVICES',
  RESERVES_LOGS: 'RESERVES_LOGS',

  //Categories
  CATEGORIES: 'CATEGORIES',
  CREATE_CATEGORY: 'CREATE_CATEGORY',
  LIST_CATEGORY: 'LIST_CATEGORY',
  EDIT_CATEGORY: 'EDIT_CATEGORY',
  REMOVE_CATEGORY: 'REMOVE_CATEGORY',
  CATEGORIES_QUESTIONS: 'CATEGORIES_QUESTIONS',
  LIST_CATEGORY_QUESTIONS: 'LIST_CATEGORY_QUESTIONS',
  EDIT_CATEGORY_QUESTIONS: 'EDIT_CATEGORY_QUESTIONS',
  CATEGORIES_OPTIONS: 'CATEGORIES_OPTIONS',
  LIST_CATEGORY_OPTIONS: 'LIST_CATEGORY_OPTIONS',
  EDIT_CATEGORY_OPTIONS: 'EDIT_CATEGORY_OPTIONS',

  //Ingredients
  INGREDIENTS: 'INGREDIENTS',
  CREATE_INGREDIENT: 'CREATE_INGREDIENT',
  LIST_INGREDIENT: 'LIST_INGREDIENT',
  EDIT_INGREDIENT: 'EDIT_INGREDIENT',
  REMOVE_INGREDIENT: 'REMOVE_INGREDIENT',

  // Staff meals
  STAFF_MEALS: 'STAFF_MEALS',

  //Suppliers
  SUPPLIERS: 'SUPPLIERS',
  CREATE_SUPPLIER: 'CREATE_SUPPLIER',
  LIST_SUPPLIER: 'LIST_SUPPLIER',
  EDIT_SUPPLIER: 'EDIT_SUPPLIER',
  REMOVE_SUPPLIER: 'REMOVE_SUPPLIER',

  //Color Flowers
  COLOR_FLOWERS: 'COLOR_FLOWERS',
  CREATE_COLOR_FLOWER: 'CREATE_COLOR_FLOWER',
  LIST_COLOR_FLOWER: 'LIST_COLOR_FLOWER',
  EDIT_COLOR_FLOWER: 'EDIT_COLOR_FLOWER',
  // REMOVE_COLOR_FLOWER: 'REMOVE_COLOR_FLOWER',

  // Orders
  ORDERS: 'ORDERS',
  LIST_ORDER: 'LIST_ORDER',

  //Sections
  SECTIONS: 'SECTIONS',
  LIST_SECTION: 'LIST_SECTION',
  SECTION_ABOUT_US: 'SECTION_ABOUT_US',
  SECTION_STYLE: 'SECTION_STYLE',
  SECTION_DAY: 'SECTION_DAY',
  SECTION_CEREMONY: 'SECTION_CEREMONY',
  SECTION_STAFF: 'SECTION_STAFF',
  SECTION_GUEST: 'SECTION_GUEST',
  SECTION_FOOD: 'SECTION_FOOD',
  SECTION_DRINK: 'SECTION_DRINK',
  SECTION_DECOR: 'SECTION_DECOR',
  SECTION_PARTY: 'SECTION_PARTY',
  SECTION_UPGRADE: 'SECTION_UPGRADE',
  EDIT_SECTION_UPGRADE: 'EDIT_SECTION_UPGRADE',
  SUB_SECTION_UPGRADE: 'SUB_SECTION_UPGRADE',
  CREATE_SUB_SECTION_UPGRADE: 'CREATE_SUB_SECTION_UPGRADE',
  EDIT_SUB_SECTION_UPGRADE: 'EDIT_SUB_SECTION_UPGRADE',
  REMOVE_SUB_SECTION_UPGRADE: 'REMOVE_SUB_SECTION_UPGRADE',
  ACTIVATE_SUB_SECTION_UPGRADE: 'ACTIVATE_SUB_SECTION_UPGRADE',
  QUESTIONS: 'QUESTIONS',
  CREATE_QUESTION: 'CREATE_QUESTION',
  LIST_QUESTION: 'LIST_QUESTION',
  EDIT_QUESTION: 'EDIT_QUESTION',
  REMOVE_QUESTION: 'REMOVE_QUESTION',
  ORDER_QUESTION: 'ORDER_QUESTION',
  OPTIONS: 'OPTIONS',
  CREATE_OPTION: 'CREATE_OPTION',
  LIST_OPTION: 'LIST_OPTION',
  EDIT_OPTION: 'EDIT_OPTION',
  REMOVE_OPTION: 'REMOVE_OPTION',
  ORDER_OPTION: 'ORDER_OPTION',

  //Catering
  MNG_FOOD: 'MNG_FOOD',
  LIST_MNG_FOOD: 'LIST_MNG_FOOD',
  FOOD_CATEGORY: 'FOOD_CATEGORY',
  CREATE_FOOD_CATEGORY: 'CREATE_FOOD_CATEGORY',
  EDIT_FOOD_CATEGORY: 'EDIT_FOOD_CATEGORY',
  REMOVE_FOOD_CATEGORY: 'REMOVE_FOOD_CATEGORY',
  ORDER_FOOD_CATEGORY: 'ORDER_FOOD_CATEGORY',

  //Inspirations
  INSPIRATIONS: 'INSPIRATIONS',
  CREATE_INSPIRATION: 'CREATE_INSPIRATION',
  LIST_INSPIRATION: 'LIST_INSPIRATION',
  EDIT_INSPIRATION: 'EDIT_INSPIRATION',
  // REMOVE_INSPIRATION: 'REMOVE_INSPIRATION',

  //Information
  INFORMATIONS: 'INFORMATIONS',
  CREATE_INFORMATION_CATEGORY: 'CREATE_INFORMATION_CATEGORY',
  EDIT_INFORMATION_CATEGORY: 'EDIT_INFORMATION_CATEGORY',
  REMOVE_INFORMATION_CATEGORY: 'REMOVE_INFORMATION_CATEGORY',
  LIST_INFORMATION: 'LIST_INFORMATION',
  EDIT_INFORMATION: 'EDIT_INFORMATION',

  //News
  NEWS: 'NEWS',
  CREATE_NEW: 'CREATE_NEW',
  LIST_NEW: 'LIST_NEW',
  EDIT_NEW: 'EDIT_NEW',
  REMOVE_NEW: 'REMOVE_NEW',
  ACTIVATE_NEW: 'ACTIVATE_NEW',

  //Colors
  COLORS: 'COLORS',
  ORDER_COLOR: 'ORDER_COLOR',
  CREATE_COLOR: 'CREATE_COLOR',
  LIST_COLOR: 'LIST_COLOR',
  EDIT_COLOR: 'EDIT_COLOR',
  REMOVE_COLOR: 'REMOVE_COLOR',

  //Admins
  ADMINS: 'ADMINS',
  CREATE_ADMIN: 'CREATE_ADMIN',
  LIST_ADMIN: 'LIST_ADMIN',
  EDIT_ADMIN: 'EDIT_ADMIN',
  REMOVE_ADMIN: 'REMOVE_ADMIN',
  MNG_PERMISSIONS: 'MNG_PERMISSIONS',

  // Dynamic Pages
  DYNAMIC_PAGES: 'DYNAMIC_PAGES',
  LIST_DYNAMIC_PAGE: 'LIST_DYNAMIC_PAGE',
  EDIT_DYNAMIC_PAGE: 'EDIT_DYNAMIC_PAGE',

  // Translations
  TRANSLATIONS: 'TRANSLATIONS',
  CREATE_TRANSLATION: 'CREATE_TRANSLATION',
  LIST_TRANSLATION: 'LIST_TRANSLATION',
  EDIT_TRANSLATION: 'EDIT_TRANSLATION',
  REMOVE_TRANSLATION: 'REMOVE_TRANSLATION',

  //Settings
  SETTINGS: 'SETTINGS',
  CREATE_EDIT_SETTING: 'CREATE_EDIT_SETTING',
  LIST_SETTING: 'LIST_SETTING',
  REMOVE_SETTING: 'REMOVE_SETTING',
  EMPLOYEE_CATEGORIES: 'EMPLOYEE_CATEGORIES',
  CREATE_EMPLOYEE_CATEGORY: 'CREATE_EMPLOYEE_CATEGORY',
  EDIT_EMPLOYEE_CATEGORY: 'EDIT_EMPLOYEE_CATEGORY',
  REMOVE_EMPLOYEE_CATEGORY: 'REMOVE_EMPLOYEE_CATEGORY',
  BASE_ROOM_PLAN: 'BASE_ROOM_PLAN',
  CREATE_BASE_ROOM_PLAN: 'CREATE_BASE_ROOM_PLAN',
  READ_BASE_ROOM_PLAN: 'READ_BASE_ROOM_PLAN',
  EDIT_BASE_ROOM_PLAN: 'EDIT_BASE_ROOM_PLAN',
  REMOVE_BASE_ROOM_PLAN: 'REMOVE_BASE_ROOM_PLAN',

  //Employees
  EMPLOYEES: 'EMPLOYEES',
  CREATE_EMPLOYEE: 'CREATE_EMPLOYEE',
  LIST_EMPLOYEE: 'LIST_EMPLOYEE',
  EDIT_EMPLOYEE: 'EDIT_EMPLOYEE',
  REMOVE_EMPLOYEE: 'REMOVE_EMPLOYEE',

  //Payments
  PAYMENTS: 'PAYMENTS',
  CREATE_PAYMENT: 'CREATE_PAYMENT',
  LIST_PAYMENT: 'LIST_PAYMENT',
  READ_PAYMENT: 'READ_PAYMENT',
  UNDO_PAYMENT: 'UNDO_PAYMENT',
  APPROVE_PAYMENT_ACCOUNT: 'APPROVE_PAYMENT_ACCOUNT',
  ACCEPT_PAYMENT_DECLARATION: 'ACCEPT_PAYMENT_DECLARATION',

  //Receipts
  RECEIPTS: 'RECEIPTS',
  LIST_RECEIPT: 'LIST_RECEIPT',
  CHECK_PAYED_RECEIPT: 'CHECK_PAYED_RECEIPT',
}

export const permissionList = [
  // Management
  {
    _id: PermissionsEnum.MANAGEMENTS,
    label: 'Gerir Websites',
    children: [
      {
        _id: PermissionsEnum.MNG_CONFIGS,
        label: 'Informação geral',
        children: [
          { _id: PermissionsEnum.MNG_READ_INFO, label: 'Listagem' },
          { _id: PermissionsEnum.MNG_EDIT_INFO, label: 'Edição' },
        ]
      },
      { _id: PermissionsEnum.MNG_APPOINTMENTS, label: 'Listagem de agendamentos' },
      { _id: PermissionsEnum.MNG_CONTACTS, label: 'Listagem de contactos' },
      {
        _id: PermissionsEnum.MNG_BANNERS,
        label: 'Banners',
        children: [
          { _id: PermissionsEnum.CREATE_BANNER, label: 'Criação' },
          { _id: PermissionsEnum.LIST_BANNER, label: 'Listagem' },
          { _id: PermissionsEnum.EDIT_BANNER, label: 'Edição' },
          { _id: PermissionsEnum.REMOVE_BANNER, label: 'Remoção' },
        ]
      },
      {
        _id: PermissionsEnum.MNG_IMAGE_PORTFOLIO,
        label: 'Portfólio de imagens',
        children: [
          { _id: PermissionsEnum.CREATE_IMAGE_PORTFOLIO, label: 'Criação' },
          { _id: PermissionsEnum.LIST_IMAGE_PORTFOLIO, label: 'Listagem' },
          { _id: PermissionsEnum.EDIT_IMAGE_PORTFOLIO, label: 'Edição' },
          // { _id: PermissionsEnum.REMOVE_IMAGE_PORTFOLIO, label: 'Remoção' },
        ]
      },
      {
        _id: PermissionsEnum.MNG_VIDEO_PORTFOLIO,
        label: 'Portfólio de vídeos',
        children: [
          { _id: PermissionsEnum.CREATE_VIDEO_PORTFOLIO, label: 'Criação' },
          { _id: PermissionsEnum.LIST_VIDEO_PORTFOLIO, label: 'Listagem' },
          { _id: PermissionsEnum.EDIT_VIDEO_PORTFOLIO, label: 'Edição' },
          // { _id: PermissionsEnum.REMOVE_VIDEO_PORTFOLIO, label: 'Remoção' },
        ]
      },
      {
        _id: PermissionsEnum.MNG_REVIEWS,
        label: 'Avaliações',
        children: [
          { _id: PermissionsEnum.CREATE_REVIEW, label: 'Criação' },
          { _id: PermissionsEnum.LIST_REVIEW, label: 'Listagem' },
          { _id: PermissionsEnum.EDIT_REVIEW, label: 'Edição' },
          // { _id: PermissionsEnum.REMOVE_REVIEW, label: 'Remoção' },
        ]
      },
      {
        _id: PermissionsEnum.MNG_RESERVATIONS,
        label: 'Reservas',
        children: [
          { _id: PermissionsEnum.CREATE_RESERVATION, label: 'Criação' },
          { _id: PermissionsEnum.LIST_RESERVATION, label: 'Listagem' },
          { _id: PermissionsEnum.EDIT_RESERVATION, label: 'Edição' },
          // { _id: PermissionsEnum.REMOVE_RESERVATION, label: 'Remoção' },
        ]
      },
      {
        _id: PermissionsEnum.MNG_METATAGS,
        label: 'Metatags',
        children: [
          { _id: PermissionsEnum.LIST_MNG_METATAG, label: 'Listagem' },
          { _id: PermissionsEnum.EDIT_MNG_METATAG, label: 'Edição' },
        ]
      },
    ]
  },
  // Weddings
  {
    _id: PermissionsEnum.WEDDINGS,
    label: 'Casamentos',
    children: [
      { _id: PermissionsEnum.CREATE_WEDDING, label: 'Criação' },
      { _id: PermissionsEnum.LIST_WEDDING, label: 'Listagem' },
      {
        _id: PermissionsEnum.READ_WEDDING,
        label: 'Processo casamento',
        children: [
          { _id: PermissionsEnum.WEDDING_SUMMARY, label: 'Resumo' },
          { _id: PermissionsEnum.WEDDING_MENU, label: 'Menu do casamento' },
          { _id: PermissionsEnum.WEDDING_UPGRADE, label: 'Upgrade' },
          { _id: PermissionsEnum.WEDDING_BUDGET, label: 'Orçamento' },
          { _id: PermissionsEnum.WEDDING_ABOUT_US, label: 'Sobre nós' },
          { _id: PermissionsEnum.WEDDING_STYLE, label: 'O nosso estilo' },
          { _id: PermissionsEnum.WEDDING_DAY, label: 'O nosso dia' },
          { _id: PermissionsEnum.WEDDING_CEREMONY, label: 'Cerimónia' },
          { _id: PermissionsEnum.WEDDING_STAFF, label: 'Staff' },
          { _id: PermissionsEnum.WEDDING_GUEST, label: 'Convidados' },
          { _id: PermissionsEnum.WEDDING_GUEST_MAP, label: 'Distribuição de convidados' },
          { _id: PermissionsEnum.WEDDING_ROOM_PLAN, label: 'O vosso plano de sala' },
          { _id: PermissionsEnum.WEDDING_FOOD, label: 'Food selection' },
          { _id: PermissionsEnum.WEDDING_DRINK, label: 'Drink selection' },
          { _id: PermissionsEnum.WEDDING_DECOR, label: 'Decoração' },
          { _id: PermissionsEnum.WEDDING_PARTY, label: 'Party selection' },
          { _id: PermissionsEnum.WEDDING_LOGS, label: 'Histórico de ações' },
        ]
      },
      { _id: PermissionsEnum.EDIT_WEDDING, label: 'Edição' },
      { _id: PermissionsEnum.REMOVE_WEDDING, label: 'Remoção' },
      { _id: PermissionsEnum.WEDDING_PAYMENTS, label: 'Listagem pagamentos às equipas' },
      {
        _id: PermissionsEnum.ARCHIVES,
        label: 'Arquivo',
        children: [
          { _id: PermissionsEnum.CREATE_ARCHIVE, label: 'Arquivamento' },
          { _id: PermissionsEnum.LIST_ARCHIVE, label: 'Listagem' },
          { _id: PermissionsEnum.READ_ARCHIVE, label: 'Processo arquivado' },
        ]
      },
      { _id: PermissionsEnum.LOGS, label: 'Histórico de Ações' },
      { _id: PermissionsEnum.TEAM_BOOKING, label: 'Encomendas de equipa' },
      { _id: PermissionsEnum.WEDDING_TEAMS, label: 'Equipas de casamento' },
    ]
  },
  // Calendar
  {
    _id: PermissionsEnum.CALENDAR,
    label: 'Calendário',
    children: [
      { _id: PermissionsEnum.CALENDAR_WEDDING, label: 'Calendário casamentos' },
      { _id: PermissionsEnum.CALENDAR_YEAR_WEDDING, label: 'Calendário anual de serviços' },
      { _id: PermissionsEnum.REST_DAYS, label: 'Dias de descanso' },
      { _id: PermissionsEnum.PAYMENT_DAYS, label: 'Dias de pagamento' },
      { _id: PermissionsEnum.COUPLE_PAYMENT_DAYS, label: 'Dias de exceção para casais' },
    ]
  },
  // Users (Weddings)
  {
    _id: PermissionsEnum.USERS,
    label: 'Utilizadores',
    children: [
      { _id: PermissionsEnum.LIST_USER, label: 'Listagem' },
      { _id: PermissionsEnum.RESEND_EMAIL_USER, label: 'Reenvio de email' },
    ]
  },
  // Reserves
  {
    _id: PermissionsEnum.RESERVES,
    label: 'Reservas',
    children: [
      {
        _id: PermissionsEnum.AGENDA,
        label: 'Agenda',
        children: [
          { _id: PermissionsEnum.RESERVES_AGENDA, label: 'Reservas' },
          { _id: PermissionsEnum.INTEREST_AGENDA, label: 'Datas de interesse' },
          { _id: PermissionsEnum.WAITING_LIST_AGENDA, label: 'Lista de espera' },
        ]
      },
      { _id: PermissionsEnum.RESERVE_PRICES_AGENDA, label: 'Agenda de preços' },
      { _id: PermissionsEnum.LIST_RESERVE, label: 'Listagem de reservas' },
      { _id: PermissionsEnum.LIST_WAITING_DATES, label: 'Listagem de espera' },
      { _id: PermissionsEnum.LIST_INTEREST_DATES, label: 'Listagem de datas de interesse' },
      {
        _id: PermissionsEnum.SCHEDULES,
        label: 'Listagem de atendimentos',
        children: [
          { _id: PermissionsEnum.CREATE_SCHEDULE, label: 'Criação de atendimento' },
          { _id: PermissionsEnum.CREATE_INTERNAL_MEETING, label: 'Criação de reunião interna' },
          { _id: PermissionsEnum.LIST_SCHEDULE, label: 'Listagem' },
          { _id: PermissionsEnum.EDIT_SCHEDULE, label: 'Edição' },
          { _id: PermissionsEnum.REMOVE_SCHEDULE, label: 'Remoção' },
          { _id: PermissionsEnum.CONFIRM_SCHEDULE, label: 'Confirmação' },
          { _id: PermissionsEnum.PRENSENCE_SCHEDULE, label: 'Registo de presença/falta' },
        ]
      },
      { _id: PermissionsEnum.ORGANIZER_SCHEDULE_SERVICES, label: 'Horários de organizadores' },
      { _id: PermissionsEnum.RESERVES_LOGS, label: 'Histórico de ações' },
    ]
  },
  //Categories
  {
    _id: PermissionsEnum.CATEGORIES,
    label: 'Categorias de encomenda',
    children: [
      { _id: PermissionsEnum.CREATE_CATEGORY, label: 'Criação' },
      { _id: PermissionsEnum.LIST_CATEGORY, label: 'Listagem' },
      { _id: PermissionsEnum.EDIT_CATEGORY, label: 'Edição' },
      { _id: PermissionsEnum.REMOVE_CATEGORY, label: 'Remoção' },
      {
        _id: PermissionsEnum.CATEGORIES_QUESTIONS,
        label: 'Perguntas',
        children: [
          { _id: PermissionsEnum.LIST_CATEGORY_QUESTIONS, label: 'Listagem' },
          { _id: PermissionsEnum.EDIT_CATEGORY_QUESTIONS, label: 'Edição' },
          {
            _id: PermissionsEnum.CATEGORIES_OPTIONS,
            label: 'Opções',
            children: [
              { _id: PermissionsEnum.LIST_CATEGORY_OPTIONS, label: 'Listagem' },
              { _id: PermissionsEnum.EDIT_CATEGORY_OPTIONS, label: 'Edição' },
            ]
          },
        ]
      },
    ]
  },
  //Ingredients
  {
    _id: PermissionsEnum.INGREDIENTS,
    label: 'Ingredientes',
    children: [
      { _id: PermissionsEnum.CREATE_INGREDIENT, label: 'Criação' },
      { _id: PermissionsEnum.LIST_INGREDIENT, label: 'Listagem' },
      { _id: PermissionsEnum.EDIT_INGREDIENT, label: 'Edição' },
      { _id: PermissionsEnum.REMOVE_INGREDIENT, label: 'Remoção' },
    ]
  },
  //Staff meals
  { _id: PermissionsEnum.STAFF_MEALS, label: 'Refeições do Staff' },
  //Suppliers
  {
    _id: PermissionsEnum.SUPPLIERS,
    label: 'Fornecedores de encomenda',
    children: [
      { _id: PermissionsEnum.CREATE_SUPPLIER, label: 'Criação' },
      { _id: PermissionsEnum.LIST_SUPPLIER, label: 'Listagem' },
      { _id: PermissionsEnum.EDIT_SUPPLIER, label: 'Edição' },
      { _id: PermissionsEnum.REMOVE_SUPPLIER, label: 'Remoção' },
    ]
  },
  //Color Flowers
  {
    _id: PermissionsEnum.COLOR_FLOWERS,
    label: 'Cores e flores',
    children: [
      { _id: PermissionsEnum.CREATE_COLOR_FLOWER, label: 'Criação' },
      { _id: PermissionsEnum.LIST_COLOR_FLOWER, label: 'Listagem' },
      { _id: PermissionsEnum.EDIT_COLOR_FLOWER, label: 'Edição' },
      // { _id: PermissionsEnum.REMOVE_COLOR_FLOWER, label: 'Remoção' },
    ]
  },
  //Orders
  {
    _id: PermissionsEnum.ORDERS,
    label: 'Encomendas',
    children: [
      { _id: PermissionsEnum.LIST_ORDER, label: 'Listagem' },
    ]
  },
  // Sections
  {
    _id: PermissionsEnum.SECTIONS,
    label: 'Secções do processo de casamento',
    children: [
      { _id: PermissionsEnum.LIST_SECTION, label: 'Listagem' },
      { _id: PermissionsEnum.SECTION_ABOUT_US, label: 'Sobre nós' },
      { _id: PermissionsEnum.SECTION_STYLE, label: 'O nosso estilo' },
      { _id: PermissionsEnum.SECTION_DAY, label: 'O nosso dia' },
      { _id: PermissionsEnum.SECTION_CEREMONY, label: 'Cerimónia' },
      { _id: PermissionsEnum.SECTION_STAFF, label: 'Staff' },
      { _id: PermissionsEnum.SECTION_GUEST, label: 'Convidados' },
      { _id: PermissionsEnum.SECTION_FOOD, label: 'Food selection' },
      { _id: PermissionsEnum.SECTION_DRINK, label: 'Drink selection' },
      { _id: PermissionsEnum.SECTION_DECOR, label: 'Decoração' },
      { _id: PermissionsEnum.SECTION_PARTY, label: 'Party selection' },
      {
        _id: PermissionsEnum.SECTION_UPGRADE,
        label: 'Upgrade',
        children: [
          { _id: PermissionsEnum.EDIT_SECTION_UPGRADE, label: 'Edição' },
          {
            _id: PermissionsEnum.SUB_SECTION_UPGRADE,
            label: 'List de upgrades',
            children: [
              { _id: PermissionsEnum.CREATE_SUB_SECTION_UPGRADE, label: 'Criação' },
              { _id: PermissionsEnum.EDIT_SUB_SECTION_UPGRADE, label: 'Edição' },
              { _id: PermissionsEnum.REMOVE_SUB_SECTION_UPGRADE, label: 'Remoção' },
              { _id: PermissionsEnum.ACTIVATE_SUB_SECTION_UPGRADE, label: 'Ativação/desativação' },
            ]
          },
        ]
      },
      {
        _id: PermissionsEnum.QUESTIONS,
        label: 'Perguntas',
        children: [
          { _id: PermissionsEnum.CREATE_QUESTION, label: 'Criação' },
          { _id: PermissionsEnum.LIST_QUESTION, label: 'Listagem' },
          { _id: PermissionsEnum.EDIT_QUESTION, label: 'Edição' },
          { _id: PermissionsEnum.REMOVE_QUESTION, label: 'Remoção' },
          { _id: PermissionsEnum.ORDER_QUESTION, label: 'Ordenação' },
          {
            _id: PermissionsEnum.OPTIONS,
            label: 'Gerir opções',
            children: [
              { _id: PermissionsEnum.CREATE_OPTION, label: 'Criação' },
              { _id: PermissionsEnum.LIST_OPTION, label: 'Listagem' },
              { _id: PermissionsEnum.EDIT_OPTION, label: 'Edição' },
              { _id: PermissionsEnum.REMOVE_OPTION, label: 'Remoção' },
              { _id: PermissionsEnum.ORDER_OPTION, label: 'Ordenação' },
            ]
          },

        ]
      },
    ]
  },
  //Catering
  {
    _id: PermissionsEnum.MNG_FOOD,
    label: 'Catering',
    children: [
      { _id: PermissionsEnum.LIST_MNG_FOOD, label: 'Listagem' },
      {
        _id: PermissionsEnum.FOOD_CATEGORY,
        label: 'Tipos de catering',
        children: [
          { _id: PermissionsEnum.CREATE_FOOD_CATEGORY, label: 'Criação' },
          { _id: PermissionsEnum.EDIT_FOOD_CATEGORY, label: 'Edição' },
          { _id: PermissionsEnum.REMOVE_FOOD_CATEGORY, label: 'Remoção' },
          { _id: PermissionsEnum.ORDER_FOOD_CATEGORY, label: 'Ordenação' },

        ]
      },
    ]
  },
  // Inspirations
  {
    _id: PermissionsEnum.INSPIRATIONS,
    label: 'Inspirações',
    children: [
      { _id: PermissionsEnum.CREATE_INSPIRATION, label: 'Criação' },
      { _id: PermissionsEnum.LIST_INSPIRATION, label: 'Listagem' },
      { _id: PermissionsEnum.EDIT_INSPIRATION, label: 'Edição' },
      // { _id: PermissionsEnum.REMOVE_INSPIRATION, label: 'Remoção' },
    ]
  },
  // Informations
  {
    _id: PermissionsEnum.INFORMATIONS,
    label: 'Informações',
    children: [
      { _id: PermissionsEnum.CREATE_INFORMATION_CATEGORY, label: 'Criação de categoria' },
      { _id: PermissionsEnum.EDIT_INFORMATION_CATEGORY, label: 'Edição de categoria' },
      { _id: PermissionsEnum.REMOVE_INFORMATION_CATEGORY, label: 'Remoção de categoria' },
      { _id: PermissionsEnum.LIST_INFORMATION, label: 'Listagem' },
      { _id: PermissionsEnum.EDIT_INFORMATION, label: 'Edição' },
    ]
  },
  // News
  {
    _id: PermissionsEnum.NEWS,
    label: 'Notícias',
    children: [
      { _id: PermissionsEnum.CREATE_NEW, label: 'Criação de categoria' },
      { _id: PermissionsEnum.LIST_NEW, label: 'Listagem' },
      { _id: PermissionsEnum.EDIT_NEW, label: 'Edição' },
      { _id: PermissionsEnum.REMOVE_NEW, label: 'Remoção' },
      { _id: PermissionsEnum.ACTIVATE_NEW, label: 'Ativação/desativação' },
    ]
  },
  // Colors
  {
    _id: PermissionsEnum.COLORS,
    label: 'Palete de cores',
    children: [
      { _id: PermissionsEnum.ORDER_COLOR, label: 'Ordenação' },
      { _id: PermissionsEnum.CREATE_COLOR, label: 'Criação' },
      { _id: PermissionsEnum.LIST_COLOR, label: 'Listagem' },
      { _id: PermissionsEnum.EDIT_COLOR, label: 'Edição' },
      { _id: PermissionsEnum.REMOVE_COLOR, label: 'Remoção' },
    ]
  },
  //Admins
  {
    _id: PermissionsEnum.ADMINS,
    label: 'Administradores',
    children: [
      { _id: PermissionsEnum.CREATE_ADMIN, label: 'Criação' },
      { _id: PermissionsEnum.LIST_ADMIN, label: 'Listagem' },
      { _id: PermissionsEnum.EDIT_ADMIN, label: 'Edição' },
      { _id: PermissionsEnum.REMOVE_ADMIN, label: 'Remoção' },
      { _id: PermissionsEnum.MNG_PERMISSIONS, label: 'Gerir permissões' },
    ]
  },
  //Dynamic Pages
  {
    _id: PermissionsEnum.DYNAMIC_PAGES,
    label: 'Páginas dinâmicas',
    children: [
      { _id: PermissionsEnum.LIST_DYNAMIC_PAGE, label: 'Listagem' },
      { _id: PermissionsEnum.EDIT_DYNAMIC_PAGE, label: 'Edição' },
    ]
  },
  //Translations
  {
    _id: PermissionsEnum.TRANSLATIONS,
    label: 'Traduções',
    children: [
      { _id: PermissionsEnum.CREATE_TRANSLATION, label: 'Criação' },
      { _id: PermissionsEnum.LIST_TRANSLATION, label: 'Listagem' },
      { _id: PermissionsEnum.EDIT_TRANSLATION, label: 'Edição' },
      { _id: PermissionsEnum.REMOVE_TRANSLATION, label: 'Remoção' },
    ]
  },
  //Settings
  {
    _id: PermissionsEnum.SETTINGS,
    label: 'Configurações',
    children: [
      { _id: PermissionsEnum.CREATE_EDIT_SETTING, label: 'Criação/edição' },
      { _id: PermissionsEnum.LIST_SETTING, label: 'Listagem' },
      { _id: PermissionsEnum.REMOVE_SETTING, label: 'Remoção' },
      {
        _id: PermissionsEnum.EMPLOYEE_CATEGORIES,
        label: 'Categorias de funcionários',
        children: [
          { _id: PermissionsEnum.CREATE_EMPLOYEE_CATEGORY, label: 'Criação' },
          { _id: PermissionsEnum.EDIT_EMPLOYEE_CATEGORY, label: 'Edição' },
          { _id: PermissionsEnum.REMOVE_EMPLOYEE_CATEGORY, label: 'Remoção' },
        ]
      },
      {
        _id: PermissionsEnum.BASE_ROOM_PLAN,
        label: 'Planos base',
        children: [
          { _id: PermissionsEnum.CREATE_BASE_ROOM_PLAN, label: 'Criação' },
          { _id: PermissionsEnum.READ_BASE_ROOM_PLAN, label: 'Pré-visualização' },
          { _id: PermissionsEnum.EDIT_BASE_ROOM_PLAN, label: 'Edição' },
          { _id: PermissionsEnum.REMOVE_BASE_ROOM_PLAN, label: 'Remoção' },
        ]
      },
    ]
  },
  //Employees
  {
    _id: PermissionsEnum.EMPLOYEES,
    label: 'Funcionários',
    children: [
      { _id: PermissionsEnum.CREATE_EMPLOYEE, label: 'Criação' },
      { _id: PermissionsEnum.LIST_EMPLOYEE, label: 'Listagem' },
      { _id: PermissionsEnum.EDIT_EMPLOYEE, label: 'Edição' },
      { _id: PermissionsEnum.REMOVE_EMPLOYEE, label: 'Eliminação/Recuperação' },
      // { _id: PermissionsEnum.MNG_PERMISSIONS_EMPLOYEE, label: 'Gerir permissões' },
    ]
  },
  //Payments
  {
    _id: PermissionsEnum.PAYMENTS,
    label: 'Pagamentos',
    children: [
      { _id: PermissionsEnum.CREATE_PAYMENT, label: 'Criação' },
      { _id: PermissionsEnum.LIST_PAYMENT, label: 'Listagem' },
      { _id: PermissionsEnum.READ_PAYMENT, label: 'Detalhe do pagamento' },
      { _id: PermissionsEnum.UNDO_PAYMENT, label: 'Retroceder pagamento' },
      { _id: PermissionsEnum.APPROVE_PAYMENT_ACCOUNT, label: 'Aprovação/Reprovação ' },
      { _id: PermissionsEnum.ACCEPT_PAYMENT_DECLARATION, label: 'Aceitar declaração' },
    ]
  },
  //Receipts
  {
    _id: PermissionsEnum.RECEIPTS,
    label: 'Recibos',
    children: [
      { _id: PermissionsEnum.LIST_RECEIPT, label: 'Listagem' },
      { _id: PermissionsEnum.CHECK_PAYED_RECEIPT, label: 'Marcação como pago' },
    ]
  },
];