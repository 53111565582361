import React, { Component, Fragment } from 'react';
import Table from '../../../components/table/Table';
import {
    Row,
    Col,
    Popconfirm,
    Icon,
} from 'antd';
import {
    TableButton,
    SectionTitle,
    TableFilterSection
} from '../../../styles/BasicStyles';
import BaseButton from '../../../components/buttons/BaseButton';
import TextInput from '../../../components/inputs/TextInput';
import { withLocalize } from 'react-localize-redux';
import {
    GetSettingsByType,
    UpdateSetting,
    DeleteSetting,
    CreateSetting
} from '../../../infra/requests/SettingRequests'
import Alert from "../../../components/alert/Alert";

const RemoveMessage = (
    <div>
        <div>Tem a certeza que quer remover este tipo de faturação? </div>
        <div>
            Toda a informação do tipo de faturação será removido da plataforma e não
            conseguirá ser recuperado.
        </div>
    </div>
);


class InvoicingTypeTable extends Component {
    state = {
        pageSize: 15,
        currentPage: 1,
        loading: false,
        search: '',
        editInvoicingType: null,
        columns: [
            {
                title: 'Nome',
                key: 'name',
                width: '150px',
                render: data => (
                    <Fragment>
                        {!this.isEditing(data) ? (<div>{data.name}</div>) :
                            (<div style={{ marginTop: '-30px' }}>
                                <TextInput
                                    input={{
                                        value: this.state.editInvoicingType.name,
                                        onChange: event => {
                                            let x = this.state.editInvoicingType;
                                            x.name = event.target.value;
                                            this.setState({ editInvoicingType: x })
                                        }
                                    }}
                                    meta={{}}
                                    type="text"
                                    placeholder="Insira o nome do tipo de faturação"
                                />
                            </div>)
                        }
                    </Fragment>
                )
            },
            {
                title: 'Acções',
                width: '120px',
                render: data => (
                    <Fragment>
                        {!this.isEditing(data) ? (<TableButton onClick={e => this.setEditForm(data)}>
                            <Icon type="edit" />
                            {' Editar'}
                        </TableButton>) : (<TableButton onClick={e => this.addEditInvoicingType()}>
                            <Icon type="save" />
                            {' Guardar'}
                        </TableButton>)
                        }

                        {!this.isEditing(data) ? (data?.canBeDeleted && <TableButton onClick={e => e.stopPropagation()}>
                            <Popconfirm
                                placement="topRight"
                                title={RemoveMessage}
                                okText="Remover"
                                onConfirm={async () => { await this.deleteInvoicingType(data.id) }}>
                                <Icon type="delete" />
                                {' Remover'}
                            </Popconfirm>
                        </TableButton>) : (<TableButton onClick={e => {
                            if (this.state.editInvoicingType.id === null) {
                                let rows = this.state.rows.filter(f => f.id !== null);
                                this.setState({ editInvoicingType: null, rows: rows });
                            } else this.setState({ editInvoicingType: null });

                        }}>
                            <Icon type="close" />
                            {' Cancelar'}
                        </TableButton>)
                        }
                    </Fragment>
                )
            }
        ],
        rows: [],
        total: 0
    };

    componentDidMount = async () => {
        await this.updateDataTable();
    };

    isEditing = (invoicingType) => {
        const { editInvoicingType } = this.state;
        return editInvoicingType ? editInvoicingType.id === invoicingType.id : false;
    }

    /**
     * @description Update data on datatable
     */
    updateDataTable = async () => {
        this.setState({ loading: true });
        try {
            const result = await GetSettingsByType(3);

            this.setState({
                rows: result.data ? result.data : [],
                total: result.data ? result.data.length : 0,
                loading: false
            });
        } catch (e) {
            this.setState({ loading: false });
            console.error(e);
        }
    }

    /**
     * @description Show editing row with invoicing type values
     * @param {*} invoicingType 
     */
    setEditForm = (invoicingType) => {
        let x = invoicingType ? JSON.parse(JSON.stringify(invoicingType)) : {
            name: ''
        };
        this.setState({ editInvoicingType: x });
    }

    /**
    * @description Show add row
    */
    setAddForm = () => {
        const { editInvoicingType, rows } = this.state;

        const invoicingType = {
            id: null,
            name: '',
            entity: false,
            paymentMethod: false,
            invoicingType: true,
            priceUnits: false,
        };
        if (!editInvoicingType) {
            rows.push(invoicingType);
            this.setState({ rows: rows, editInvoicingType: invoicingType });
        }
    }

    /**
     * @description Create/Update invoicing type
     * @returns datatable invoicing type updated
     */
    addEditInvoicingType = async () => {
        const { editInvoicingType } = this.state;

        if (editInvoicingType.name === '') {
            Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Deve inserir o tipo de faturação'
            });
        } else if (editInvoicingType.id === null) {
            await CreateSetting(editInvoicingType);
            this.setState({ editInvoicingType: null });
            return this.updateDataTable();
        } else {
            await UpdateSetting(editInvoicingType);
            this.setState({ editInvoicingType: null });
            return this.updateDataTable();
        }
    }

    /**
     * @description Delete invoicing type
     * @param {*} id - invoicing type id
     * @returns datatable invoicing type updated
     */
    deleteInvoicingType = async id => {
        await DeleteSetting(id);
        return this.updateDataTable();
    }

    handleChangePage = currentPage => {
        this.setState({ currentPage }, this.updateDataTable);
    };

    handleChangeRowsPerPage = (currentSize, pageSize) => {
        this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
    };

    render() {
        const { loading } = this.state;
        // const { currentPage, pageSize } = this.state;
        const { columns, rows, total, search } = this.state;

        return (
            <React.Fragment>
                <SectionTitle style={{ marginTop: '3rem' }} >Tipo de Faturação</SectionTitle>

                <TableFilterSection>
                    <Row gutter={24}>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <div className='settings-search'>
                                <TextInput
                                    type="text"
                                    label="Pesquisa"
                                    placeholder="Pesquise por tipo de faturação"
                                    input={{
                                        value: search,
                                        onChange: event => this.setState({ search: event.target.value })
                                    }}
                                    meta={{}}
                                />
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12} xs={24} className='setting-col-btn'>
                            <BaseButton
                                type={'primary'}
                                icon={'plus'}
                                text={'Adicionar Tipo'}
                                onClick={() => this.setAddForm()}
                            />
                        </Col>
                    </Row>
                </TableFilterSection>

                <div className='table-responsive'>
                    <Table
                        columns={columns}
                        // currentPage={currentPage}
                        // pageSize={pageSize}
                        // defaultPageSize={pageSize}
                        loading={loading}
                        rows={search.trim() === "" ? rows : rows.filter(f => f?.name.toLowerCase().includes(search.toLowerCase()))}
                        showHeader={true}
                        emptyIcon="setting"
                        emptyText={'Não existem tipos de faturação'}
                        total={search.trim() === "" ? total : rows.filter(f => f?.name.toLowerCase().includes(search.toLowerCase())).length}
                        rowKey={'_id'}
                        hasPagination={false}
                    // handleChangePage={this.handleChangePage}
                    // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default withLocalize(InvoicingTypeTable);