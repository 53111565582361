import React, { Component, Fragment } from 'react';
import { Button, Checkbox, Col, DatePicker, Dropdown, Icon, InputNumber, Menu, Modal, Popconfirm, Radio, Row, Tag, Tooltip } from 'antd';
import Table from '../../components/table/Table';
import {
    PageContainer,
    PageTitle,
    HeaderContainer,
    HeaderTitle,
    HeaderButtonsContainer,
    TableButton,
    TableFilterSection,
    SpinLoading
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import moment from 'moment';
import { connect } from 'react-redux';
import { GetColorSystemByType, GetVenueColors } from '../../infra/requests/ColorSystemRequests';
import CurrencyComponent from '../../components/currency/CurrencyComponent';
import { CreateReservePrice, DeleteReservePrice, GetReservePriceByDateAndLocal, GetReservePriceByMonth, UpdateMultipleReservePrice, UpdateReservePrice } from '../../infra/requests/ReservePriceRequests';
import NumberInput from '../../components/inputs/NumberInput';
import debounce from 'lodash.debounce';
import { getUserId, isCurrentUserEmployeeAttending } from '../../infra/helpers/Helpers';
import { ColorBox, InputGroupField, InputNumberField, RoundColorBox, WaitingIcon } from '../../components/inputs/InputStyles';
import SelectInput from '../../components/inputs/SelectInput';
import DateInput from '../../components/inputs/DateInput';
import Alert from '../../components/alert/Alert';
import MonthInput from '../../components/inputs/MonthInput';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import styled from 'styled-components';
import YearView from '../../components/YearView/YearView';
import { CheckDatesAvailable, GetReserve, GetReserveAgenda, GetReservesByWedding, GetReservesWithOrder, MngMultipleReserves, UpdateReserveRelatedInfo } from '../../infra/requests/ReserveRequests';
import { CalendarBox, CalendarPicker, FrameToExport, LinkAcceptPolicy, RadioPlace, RadioYear } from '../../components/YearView/CalendarStyles';
import Alerts from '../../components/alert/Alert';
import YearInput from '../../components/inputs/YearInput';
import Bookmark from "../../assets/icons/bookmark.svg";
import Star from "../../assets/icons/star.svg";
import { VenueColors, getLocal, getWeddingName, getWeddingTitle } from '../../infra/services/wedding/weddingUtils';
import YearWeddingsView from '../../components/YearView/YearWeddingsView';
import { ExportWeddingsAgenda, GetWeddingsAgenda } from '../../infra/requests/WeddingRequests';
import html2pdf from 'html2pdf.js';

export const Legend = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  font-size: 16px;
  justify-content: center;
  margin-top: 5px;
  flex-wrap: wrap;
  padding-inline-start: 0;
`;

export const LegendItem = styled.li`
  padding: 10px;
  display: flex;
  align-content: center;
  align-items: center;

  b, div {
    margin-right: 5px;
  }
`;

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer
localizer.formats.yearHeaderFormat = 'YYYY'
// localizer.messages.year = 'Year'

export const locals = [
    { _id: 3, name: 'Ambos' },
    { _id: 1, name: 'Solar da Levada' },
    { _id: 2, name: 'Quinta Lago dos Cisnes' },
];

class YearWeddingsCalendar extends Component {
    state = {
        loading: false,
        year: moment().year(),
        yearFilter: null,
        local: 3,
        dataSource: [],
        exporting: false,
        colorsLoading: false,
        colors: VenueColors,
    };

    componentDidMount = async () => {
        await this.getAgenda();
        await this.getColors();
    };

    getColors = async () => {
        let { colors } = this.state;
        this.setState({ colorsLoading: true });

        const result = await GetVenueColors();
        const resultColors = result?.success && result?.data ? result?.data : null;
        if (resultColors) {
            colors.solar = resultColors.solar;
            colors.lago = resultColors.lago;
            colors.both = resultColors.both;
        }

        this.setState({
            colors,
            colorsLoading: false
        });
    }

    getAgenda = async () => {
        const { year, local } = this.state;

        this.setState({ loading: true });

        try {
            const filters = { year, local };
            const result = await GetWeddingsAgenda(filters);
            const data = result && result?.data ? result.data : [];

            this.setState({
                dataSource: data,
                loading: false,
            });
        } catch (e) {
            this.setState({ loading: false, reservesLoading: false });
            console.error(e);
        }
    };

    changeTab = (event) => {
        this.setState({ local: event?.target?.value }, this.getAgenda);
    }

    changeYear = (event) => {
        const { year } = this.state;
        this.setState({ year: year + parseInt(event?.target?.value) }, this.getAgenda);
    }

    export = async () => {
        this.setState({ exporting: true });

        // const filters = { year, local };
        // const result = await ExportWeddingsAgenda(filters);

        const element = document.getElementById('divToExport');

        // Check if the div exists
        if (!element) {
            console.error(`Não encontrou o componente do calendário`);
            return;
        }

        // Options for html2pdf
        const options = {
            margin: 10,
            filename: `calendario_anual_${moment.utc().format('DDMMYYYY_HHmm')}.pdf`,
            size: 10,
            title: 'Calendário', // Add a title here
            image: { type: 'png', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', size: 10, format: 'a4', orientation: 'portrait' },
        };

        // Use html2pdf to export the div as a PDF
        html2pdf().from(element).set(options).save();
        this.setState({ exporting: false });
    }

    render() {
        const { history } = this.props;
        const { loading, year, yearFilter, local, dataSource, exporting, colors, colorsLoading } = this.state;

        return (
            <React.Fragment>
                <HeaderContainer>
                    <HeaderTitle buttons={2}>
                        <PageTitle>Calendário Anual</PageTitle>
                        Férias / Serviços
                    </HeaderTitle>
                    <HeaderButtonsContainer buttons={2}>
                        <BaseButton
                            type="primary"
                            icon="calendar"
                            text="Exportar"
                            loading={exporting}
                            disabled={loading}
                            onClick={() => this.export()}
                        />
                        <BaseButton
                            type="default"
                            icon="close"
                            text="Cancelar"
                            onClick={() => history.push('/weddings-calendar')}
                        />
                    </HeaderButtonsContainer>
                </HeaderContainer>
                <PageContainer style={{ padding: '0 20px 20px 20px' }} buttons={2}>
                    <Row gutter={[12, 12]} type='flex'>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <CalendarBox reserves={false}>
                                <Row gutter={[12, 12]}>
                                    <Col xs={24} md={12} lg={10} xl={8} xxl={8}>
                                        <RadioPlace>
                                            <Radio.Group value={local} disabled={loading} onChange={(event) => this.changeTab(event)}>
                                                {locals.map((local, index) => (
                                                    <Radio.Button key={index} value={local._id}>
                                                        {local.name}
                                                    </Radio.Button>
                                                ))}
                                            </Radio.Group>
                                        </RadioPlace>
                                    </Col>
                                    <Col xs={24} md={7} lg={10} xl={10} xxl={10}>
                                        <RadioYear>
                                            <Radio.Group value={0} disabled={loading} onChange={(event) => this.changeYear(event)}>
                                                <Radio.Button value={-1} disabled={year <= moment().year()}>
                                                    <Tooltip className='iconPrevious' title={'Ano Anterior'}>
                                                        <Icon type="left" />
                                                    </Tooltip>

                                                    <span className='spanPrevious'>
                                                        <Icon type="left" />
                                                        {'Ano Anterior'}
                                                    </span>
                                                </Radio.Button>
                                                <Radio.Button value={0}>{year}</Radio.Button>
                                                <Radio.Button value={1}>
                                                    <Tooltip className='iconNext' title={'Ano Seguinte'}>
                                                        <Icon type="right" />
                                                    </Tooltip>

                                                    <span className='spanNext'>
                                                        {'Ano Seguinte'}
                                                        <Icon type="right" />
                                                    </span>
                                                </Radio.Button>
                                            </Radio.Group>
                                        </RadioYear>
                                    </Col>
                                    <Col xs={24} md={5} lg={4} xl={6} xxl={6}>
                                        <CalendarPicker>
                                            <YearInput
                                                placeholder="Selecione o ano"
                                                input={{
                                                    value: yearFilter,
                                                    onChange: (value) => {
                                                        this.setState({
                                                            yearFilter: value,
                                                            year: value !== '' ? parseInt(value) : moment().year()
                                                        }, this.getAgenda);
                                                    }
                                                }}
                                                disabled={loading}
                                                allowClear={true}
                                                meta={{}} />
                                        </CalendarPicker>
                                    </Col>
                                </Row>
                                <Row>
                                    <div style={{ position: 'relative' }}>
                                        {loading || colorsLoading ?
                                            <SpinLoading /> :
                                            <React.Fragment>
                                                {loading && <div className='backdrop'>
                                                    <SpinLoading style={{ position: 'absolute', minHeight: 30 }} />
                                                </div>}
                                                <Calendar
                                                    loading={loading}
                                                    localizer={localizer}
                                                    defaultDate={moment().set('year', year)}
                                                    events={dataSource}
                                                    toolbar={false}
                                                    defaultView='year'
                                                    views={{
                                                        // day: true,
                                                        // week: true,
                                                        // month: true,
                                                        year: YearWeddingsView
                                                    }}
                                                    messages={{ year: 'Year' }}
                                                />
                                                <Legend>
                                                    {(local == 1 || local == 3) && <LegendItem><ColorBox color={colors?.solar} /> Evento SLevada</LegendItem>}
                                                    {(local == 2 || local == 3) && <LegendItem><ColorBox color={colors?.lago} /> Evento QL Cisnes</LegendItem>}
                                                    <LegendItem><ColorBox color={'#cbb685'} /> Data Indisponível</LegendItem>
                                                    {local == 3 && <LegendItem><ColorBox color={'linear-gradient(90deg, #ffffff, #cbb685)'} /> Data Disponível SLE</LegendItem>}
                                                    {local == 3 && <LegendItem><ColorBox color={'linear-gradient(90deg, #cbb685, #ffffff)'} /> Data Disponível QLC</LegendItem>}
                                                    <LegendItem><b>F:</b> Feriado Português</LegendItem>
                                                    <LegendItem><b>FM:</b> Feriado Municipal Português</LegendItem>
                                                </Legend>
                                            </React.Fragment>
                                        }
                                    </div>
                                </Row>
                            </CalendarBox>
                        </Col>
                    </Row>
                </PageContainer>

                <div style={{ zIndex: -10, backgroundColor: 'white', position: 'absolute', top: 0, height: '100%', width: '100%', cursor: 'not-allowed' }}>
                    <FrameToExport id='divToExport'>
                        <div className='yearTitle'>{year}</div>
                        <div className='title'>Calendário {local == 2 ? 'Lago dos Cisnes' : local == 1 ? 'Solar da Levada' : ''}</div>
                        <div className='subtitle'>Indisponibilidades / Serviços</div>
                        <CalendarBox exports={true} reserves={false}>
                            <div style={{ position: 'relative' }}>
                                {loading ?
                                    <SpinLoading /> :
                                    <React.Fragment>
                                        {loading && <div className='backdrop'>
                                            <SpinLoading style={{ position: 'absolute', minHeight: 30 }} />
                                        </div>}
                                        <Calendar
                                            loading={loading}
                                            localizer={localizer}
                                            defaultDate={moment().set('year', year)}
                                            events={dataSource}
                                            toolbar={false}
                                            defaultView='year'
                                            export={true}
                                            views={{
                                                // day: true,
                                                // week: true,
                                                // month: true,
                                                year: YearWeddingsView
                                            }}
                                            messages={{ year: 'Year' }}
                                        />
                                        <Legend>
                                            {(local == 1 || local == 3) && <LegendItem><ColorBox color={colors?.solar} /> Evento SLevada</LegendItem>}
                                            {(local == 2 || local == 3) && <LegendItem><ColorBox color={colors?.lago} /> Evento QL Cisnes</LegendItem>}
                                            <LegendItem><ColorBox color={'#cbb685'} /> Data Indisponível</LegendItem>
                                            {local == 3 && <LegendItem><ColorBox color={'linear-gradient(90deg, #ffffff, #cbb685)'} /> Data Disponível SLE</LegendItem>}
                                            {local == 3 && <LegendItem><ColorBox color={'linear-gradient(90deg, #cbb685, #ffffff)'} /> Data Disponível QLC</LegendItem>}
                                            <LegendItem><b>F:</b> Feriado Português</LegendItem>
                                            <LegendItem><b>FM:</b> Feriado Municipal Português</LegendItem>
                                        </Legend>
                                    </React.Fragment>
                                }
                            </div>
                        </CalendarBox>
                    </FrameToExport>
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(YearWeddingsCalendar);
