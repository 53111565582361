import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Row, Checkbox } from 'antd';
import { BaseForm } from '../../styles/BasicStyles';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import FormValidator from '../../infra/services/validations/FormValidator';
import moment from 'moment';

let ModalPaymentDeclaration = (props): React$Element<React$FragmentType> => {
    let month = moment.utc().format('MM');
    let year = parseInt(month) >= 12 ? (parseInt(moment.utc().format('YYYY')) + 1).toString() : moment.utc().format('YYYY');
    const [paymentAccount, setPaymentAccount] = React.useState(null);

    useEffect(() => {
        setPaymentAccount(JSON.parse(JSON.stringify(props.initialValues)));
    }, [props.initialValues]);

    const footer = [
        <Button
            key='cancel'
            onClick={() => props.onClose()}>
            Cancelar
        </Button>,
        <Button
            key='submit'
            type='primary'
            disabled={!paymentAccount?.declaration}
            onClick={() => props.onSubmit(paymentAccount)}>
            Gravar
        </Button>
    ];
    return (
        <Modal
            visible={props.isOpen}
            title={'Declaração de Emissão do Ato Isolado - ' + year}
            centered={true}
            maskClosable
            onCancel={() => props.onClose()}
            footer={!props.blocked ? footer : null}
        >
            <Row>
                <p style={{ lineHeight: '1.5rem' }}>{props.declaration}</p>
                <span style={{ lineHeight: '.5rem', margin: '1rem 0', color: 'lightgrey' }}>Atenção que os dados que fornece agora serão os dados sobre os quais será emitida declaração mais tarde para efeitos de IRS</span>
            </Row>

            <Row style={{ marginTop: '1rem' }}>
                <div className="sc-dxgOiQ fWHHkk">
                    <Checkbox
                        name="declaration"
                        disabled={props.blocked}
                        checked={paymentAccount?.declaration}
                        onChange={e => {
                            paymentAccount.declaration = e.target.checked;
                            setPaymentAccount(JSON.parse(JSON.stringify(paymentAccount)));
                        }}>
                        Aceitar Declaração
                    </Checkbox>
                </div>
            </Row>
        </Modal>
    );
};

export default ModalPaymentDeclaration;