import {SAVE_INFO, UPDATE_PLAN_ROOM} from '../ActionsType';

export function SaveInfo(info) {
  return {
    type: SAVE_INFO,
    info
  };
}

export function updateRoomPlan(id, config) {
  return {
    type: UPDATE_PLAN_ROOM,
    id,
    config
  };
}

export function updateBackgroundRoomPlan(id, config) {
  return {
    type: UPDATE_PLAN_ROOM,
    id,
    config
  };
}
