import client from '../config/AxiosConfig';

export const GetQuestion = async id => client.get(`/questions/${id}`);

export const GetQuestionForBudget = async (weddingId) => client.get(`/questions/budget/${weddingId}`);

export const CreateQuestion = async data => client.post('/questions', data);

export const UpdateQuestion = async (id, data) =>
  client.put(`/questions/${id}`, data);

export const UpdateQuestionOrder = async data =>
  client.post('/questions/order', data);

export const DeleteQuestion = async (id, data) =>
  client.delete(`/questions/${id}`, data);

export const GetQuestionList = async () =>
  client.get('/questions/list');

export const GetQuestionLogsList = async (filters) =>
  client.get(`/questions/logs/list?filter=${filters}`);


/* Category Orders */

export const GetQuestionsPagination = async (page, limit, filter = "") => client.get(`/questions/orders/${page}/${limit}?filter=${filter}`);

export const GetQuestionOrders = async id => client.get(`/questions/orders/${id}`);

export const UpdateQuestionOrders = async (id, data) => client.put(`/questions/orders/${id}`, data);