import React, { Component, Fragment } from 'react';
import { Popconfirm, Icon } from 'antd';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton
} from '../../styles/BasicStyles';
import {
  GetIngredientsPagination,
  DeleteIngredient
} from '../../infra/requests/IngredientsRequests';
import {
  GetAllSupplierCategories
} from '../../infra/requests/SupplierRequests';
import BaseButton from '../../components/buttons/BaseButton';
import IngredientsFilters from './IngredientsFilters';
import DeleteConfirmModal from './DeleteConfirmModal';

class IngredientsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    filters: '',
    columns: [
      {
        title: 'Nome',
        dataIndex: 'name',
        render: value => value,
        width: '35%'
      },
      {
        title: 'Categoria de Encomenda',
        key: 'supplier',
        render: data => {
          return data?.supplier_category && Array.isArray(data?.supplier_category) && data?.supplier_category
            ? data?.supplier_category.map(m => m?.name).toString()
            : '';
          // data?.supplier_category?.name
        },
        width: '45%'
      },
      {
        title: 'Acções',
        render: data => (
          <Fragment>
            {!data.system && (
              <TableButton onClick={e => e.stopPropagation()}>
                {
                  (data?.supplier_category && Array.isArray(data?.supplier_category) && data?.supplier_category.length > 0)
                    ? <div onClick={() => this.setState({ openDeleteConfirmModal: true, deleteOptionId: data._id })}>
                      <Icon type="delete" />
                      {' Apagar'}
                    </div>
                    : <Popconfirm
                      placement="topRight"
                      title="Tem a certeza que quer apagar este ingrediente?"
                      onConfirm={() => this.deleteIngredient(data._id)}>
                      <Icon type="delete" />
                      {' Apagar'}
                    </Popconfirm>
                }
              </TableButton>
            )}
          </Fragment>
        )
      }
    ],
    rows: [],
    total: 0,
    supplierCategories: [],
    openDeleteConfirmModal: false,
    deleteOptionId: ''
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  openRow = record => {
    const { history } = this.props;
    history.push(`/ingredients/${record._id}`);
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetIngredientsPagination(currentPage, pageSize, filters);
      const supplierCategories = await GetAllSupplierCategories();

      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false,
        supplierCategories: supplierCategories?.data || []
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = filters => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  deleteIngredient = async id => {
    await DeleteIngredient(id);
    await this.updateDataTable();
  };

  closeDeleteConfirmModal = () => {
    this.setState({
      openDeleteConfirmModal: false,
      deleteOptionId: ''
    });
  };

  onSubmitDeleteConfirm = async () => {
    const { deleteOptionId } = this.state;
    await DeleteIngredient(deleteOptionId);
    await this.updateDataTable();
    this.setState({
      openDeleteConfirmModal: false,
      deleteOptionId: ''
    });
  }

  render() {
    const { history } = this.props;
    const { columns, rows, total, loading, currentPage, pageSize, supplierCategories, openDeleteConfirmModal } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Ingredientes</PageTitle>
            Lista de Ingredientes
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              block
              text={'Novo Ingrediente'}
              onClick={() => history.push('/ingredients/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={1}>
          <IngredientsFilters queryChange={this.handleFilterChange} supplierCategories={supplierCategories} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="database"
            emptyText={'Não existem ingredientes!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.openRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
        <DeleteConfirmModal
          open={openDeleteConfirmModal}
          loading={loading}
          onSubmit={this.onSubmitDeleteConfirm}
          closeModal={this.closeDeleteConfirmModal}
        />
      </React.Fragment>
    );
  }
}

export default IngredientsPage;