/*eslint no-useless-escape: "off"*/

import React from 'react';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled from 'styled-components';
import { Editor } from '@tinymce/tinymce-react';
import { InputLabelDiv } from './InputStyles';
import { SpinLoading } from '../../styles/BasicStyles';
import client from '../../infra/config/AxiosConfig';

export const TinyEditor = styled.div`
  position: relative;

  .backdrop {
    z-index: 9;
    background-color: black;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.4;
    border-radius: 5px;
  }

  ${({ showError }) => showError ? `
  .tox-tinymce {
    border: 1px solid red;
  }
  ` : null}
`;

class TinyInput extends React.Component {

  state = {
    id: 'editor',
    loading: true,
    initialValue: '',
    language: 'pt_PT',
    languageUrl: '/langs/pt_PT.js',
    text: '',
    html: ''
  };

  componentDidMount() {
    this.setState({
      initialValue: this.props.input.value,
    });
  }

  componentWillUnmount() {
    // window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.langKey !== this.props.langKey) {
      if (this.props && this.props.input && this.props.input.value) {
        // console.warn('val', this.props.input.value);
        this.setState({
          id: this.props.input.id,
          initialValue: this.props.input.value,
          language: this.props.input.language,
          languageUrl: this.props.input.languageUrl,
        });
      }
    }
  }

  render() {
    //  const { id, initialValue, language, languageUrl, text, html } = this.state;
    const { loading } = this.state;
    const { label, meta, placeholder, disabled, noLabel, id, input } = this.props;
    // console.warn('Props', this.props);

    const { invalid, submitFailed } = meta;
    const showError = invalid && submitFailed ? 1 : 0;

    return (
      <React.Fragment>
        {!noLabel && <InputLabelDiv>{label}</InputLabelDiv>}
        {/* <div className={showError ? 'editor-error' : ''}> */}
          <TinyEditor showError={showError}>
            {loading && <div className='backdrop'>
              <SpinLoading style={{ position: 'absolute', minHeight: 30 }} />
            </div>}
            <Editor
              id={input.id}
              apiKey={process.env.REACT_APP_TINY_API_KEY}
              value={input.value}
              init={{
                block_unsupported_drop: false,
                selector: 'textarea',
                height: 800,
                menubar: true,
                language: 'pt_PT',
                // language_url: '/langs/pt_PT.js',
                toolbar_mode: 'wrap',
                plugins: [
                  'advlist', 'autolink', 'lists', 'link', 'image', 'emoticons', 'charmap', 'preview',
                  'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                  'insertdatetime', 'media', 'table', 'help', 'wordcount'
                ],
                toolbar: 'undo redo | bold italic underline | fontselect fontsizeselect formatselect | ' +
                  'forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist table | ' +
                  'link media image emoticons | help',
                // fontawesomePro: false,
                // fontawesomeUrl: 'https://www.unpkg.com/@fortawesome/fontawesome-free@5.14.0/css/all.min.css',
                // external_plugins: {
                //   //  url
                //   fontawesomepicker: 'https://www.unpkg.com/tinymce-fontawesomepicker/fontawesomepicker/plugin.min.js'
                // },
                file_picker_types: "image media",
                image_title: true,
                automatic_uploads: true,
                // file_picker_callback: function (cb, value, meta) {
                //   var input = document.createElement('input');
                //   input.setAttribute('type', 'file');
                //   input.setAttribute('accept', 'image/*');

                //   input.onchange = function () {
                //     var file = this.files[0];

                //     var reader = new FileReader();
                //     reader.onload = function () {
                //       var id = 'blobid' + (new Date()).getTime();
                //       var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                //       var base64 = reader.result.split(',')[1];
                //       var blobInfo = blobCache.create(id, file, base64);
                //       blobCache.add(blobInfo);
                //       cb(blobInfo.blobUri(), { title: file.name });
                //     };
                //     reader.readAsDataURL(file);
                //   };

                //   input.click();
                // },
                images_upload_handler: async function (blobInfo, progress) {
                  var image_size = blobInfo.blob().size / 1000;  // image size in kbytes
                  var max_size = 6144; // max size in kbytes
                  if (image_size > max_size) {
                    return Promise.reject({message: 'O ficheiro é inválido ou tem um tamanho superior a 6Mb', remove: true});
                  } else {
                    let data = new FormData();
                    data.append('file', blobInfo.blob());
                    const res = await client.post('/images/draft', data);
                    if (res?.success) {
                      // console.warn('res', res);
                      return res.data;
                    } else {
                      // console.log('error', res);
                      return Promise.reject({message: res, remove: true});
                    }
                  }
                }
              }}
              onEditorChange={(content, editor) => {
                this.props.input.onChange(content);
              }}
              onInit={(event) => {
                if (event?.type == 'init') this.setState({ loading: false });
              }}
            ></Editor>
          </TinyEditor>
        {/* </div> */}
      </React.Fragment>
    );
  }
}

export default TinyInput;
