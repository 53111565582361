import React, {Component, Fragment} from 'react';
import {initialize} from 'redux-form';
import {Popconfirm, Icon, Row, Col} from 'antd';
import {connect} from 'react-redux';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton
} from '../../styles/BasicStyles';
import {GetChapter} from '../../infra/requests/ChapterRequests';
import {
  CreateQuestion,
  UpdateQuestion,
  DeleteQuestion,
  UpdateQuestionOrder
} from '../../infra/requests/QuestionsRequests';
import {GetAllSupplierCategories} from '../../infra/requests/SupplierRequests';
import BaseButton from '../../components/buttons/BaseButton';
import SectionNames from '../../infra/services/sections/SectionNames';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import {
  GetQuestionType,
  QuestionHaveOptions
} from '../../infra/services/questions/QuestionTypes';
import ManageQuestionModal from './ManageQuestionModal';
import {FlattenToFormData} from '../../infra/services/formdata/TransformToFormData';
import {AsyncForEach} from '../../infra/services/async/AsyncFunctions';
import OrderQuestions from './OrderQuestions';
import {
  EnableDelete,
  EnableNewQuestions
} from '../../infra/services/disabled/DisabledInformation';

class ChapterQuestionsPage extends Component {
  state = {
    chapter: {},
    columns: [
      {
        title: 'Titulo',
        dataIndex: 'title',
        render: value => TranslateValue(value)
      },
      {
        title: 'Tipo de pergunta',
        dataIndex: 'type',
        render: value => GetQuestionType(value)
      },
      {
        title: 'Observações',
        dataIndex: 'observations',
        render: value => (value ? 'Sim' : 'Não')
      },
      {
        title: 'Opções disponiveis',
        render: data => this.renderOptions(data)
      },
      {
        title: 'Ações',
        render: data =>
          EnableDelete(data._id) && (
            <TableButton onClick={e => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title="De certeza que quer apagar esta pergunta? Não conseguirá recupera-la."
                onConfirm={() => this.removeQuestion(data._id)}>
                <Icon type="delete" />
                {' Apagar'}
              </Popconfirm>
            </TableButton>
          )
      }
    ],
    rows: [],
    loading: false,
    openModal: false,
    orderModal: false,
    supplierCategories: [],
    question: undefined
  };

  componentDidMount() {
    this.getChapter();
  }

  getChapter = async () => {
    const {
      match: {params}
    } = this.props;
    this.setState({loading: true});
    try {
      const {data} = await GetChapter(params.id);
      const supplierCategories = await GetAllSupplierCategories();
      this.setState({
        chapter: data,
        rows: data?.questions || [],
        loading: false,
        supplierCategories: supplierCategories?.data || []
      });
    } catch (e) {
      this.setState({loading: false});
    }
  };

  openModal = (data = {}) => {
    const {dispatch} = this.props;
    dispatch(initialize('manage_question_form', data));
    this.setState({openModal: true, question: data, edit: data._id});
  };

  onModalSubmit = async values => {
    const {
      match: {params}
    } = this.props;
    this.setState({loading: true});

    const images = values.images || [];
    delete values.images;
    const payload = FlattenToFormData({
      ...values,
      chapter: params.id
    });

    await AsyncForEach(images, (image, index) => {
      if (image.blob) payload.append(`images[${index}]`, image.blob);
      if (image._id) payload.append(`images[${index}]`, image._id);
    });

    values._id
      ? await UpdateQuestion(values._id, payload)
      : await CreateQuestion(payload);
    this.closeModal();
    await this.getChapter();
  };

  removeQuestion = async id => {
    await DeleteQuestion(id);
    await this.getChapter();
  };

  openOptions = (e, id) => {
    e.stopPropagation();
    const {history} = this.props;
    history.push(`/question/${id}`);
  };

  closeModal = () => {
    const {dispatch} = this.props;
    dispatch(initialize('manage_question_form', {}));
    this.setState({openModal: false, edit: false});
  };

  closeOrderModal = () => {
    this.setState({orderModal: false});
  };

  openOrderModal = questions => {
    const {dispatch} = this.props;
    dispatch(initialize('manage_orderquestions_form', {questions: [...questions]}, false));
    this.setState({orderModal: true});
  };

  onSubmitOrder = async values => {
    try {
      this.setState({loading: true});

      if (values.questions.length > 0) {
        let orderedList = [];
        Object.keys(values.questions).forEach(function(key) {
          let obj = {_id: values.questions[key]._id, order: key};

          orderedList.push(obj);
        });

        if (orderedList.length > 0) {
          const res = await UpdateQuestionOrder({order: orderedList});

          if (res.success) {
            this.getChapter();
          }
        }
      }

      this.setState({loading: false});
      this.closeOrderModal();
    } catch (e) {
      this.setState({loading: false});
    }
  };

  renderOptions = data => {
    if (QuestionHaveOptions(data.type))
      return (
        <Fragment>
          <TableButton onClick={e => this.openOptions(e, data._id)}>
            <Icon type="folder-open" />
            {' Abrir Opções'}
          </TableButton>
          <span style={{marginLeft: '30px'}}>Total: {data.options.length}</span>
        </Fragment>
      );
    return <span style={{marginLeft: '10px'}}>Não tem opções</span>;
  };

  render() {
    const {history} = this.props;
    const {
      chapter,
      rows,
      columns,
      loading,
      openModal,
      edit,
      question,
      orderModal,
      supplierCategories
    } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={EnableNewQuestions(chapter?._id) ? 3 : 1}>
            <PageTitle>
              {SectionNames[chapter?.section?.tag || undefined]}
            </PageTitle>
            {TranslateValue(chapter.name)}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={EnableNewQuestions(chapter?._id) ? 3 : 1}>
            {EnableNewQuestions(chapter?._id) && (
              <Fragment>
                <BaseButton
                  type={'primary'}
                  icon={'plus'}
                  text={'Nova pergunta'}
                  onClick={() => this.openModal()}
                />
                <BaseButton
                  type="default"
                  icon="retweet"
                  text="Editar Ordem"
                  onClick={() => this.openOrderModal(rows)}
                />
              </Fragment>
            )}
            <BaseButton
              type="default"
              icon="arrow-left"
              text="Voltar"
              onClick={() =>
                history.push(`/sections/${chapter?.section?.tag.toLowerCase()}`)
              }
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={EnableNewQuestions(chapter?._id) ? 3 : 1}>
          <Row gutter={[12, 12]} type="flex" justify="center">
            <Col xs={16} style={{textAlign: 'center', marginBottom: '30px'}}>
              {TranslateValue(chapter?.description)}
            </Col>
          </Row>
          <Table
            columns={columns}
            currentPage={1}
            pageSize={rows.length}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="message"
            emptyText={'There are no questions'}
            total={rows.length}
            rowKey={'_id'}
            hasPagination={false}
            onPressRow={this.openModal}
          />
        </PageContainer>
        <ManageQuestionModal
          open={openModal}
          edit={edit}
          loading={loading}
          onSubmit={this.onModalSubmit}
          closeModal={this.closeModal}
          supplierCategories={supplierCategories}
          question={question}
        />
        <OrderQuestions
          open={orderModal}
          loading={loading}
          onSubmit={this.onSubmitOrder}
          closeModal={this.closeOrderModal}
        />
      </React.Fragment>
    );
  }
}

export default connect()(ChapterQuestionsPage);
