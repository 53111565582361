import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Icon, Row, Col, Steps, TimePicker, Checkbox } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import FormValidator from '../../../infra/services/validations/FormValidator';
import CheckboxInput from '../../../components/inputs/CheckboxInput';
import SelectInput from '../../../components/inputs/SelectInput';
import { BaseForm, SpinLoading } from '../../../styles/BasicStyles';
import PropTypes from 'prop-types';
import DateInput from '../../../components/inputs/DateInput';
import { TimeInput } from '../../../components/inputs/InputStyles';
import { GetAvailableEmployeesAttending } from '../../../infra/requests/UsersRequests';
import XpertGoTimeInput from '../../../components/inputs/XpertGoTimeInput';
import TextInput from '../../../components/inputs/TextInput';
import CheckboxGroup from 'antd/lib/checkbox/Group';
import moment from 'moment';
import Alerts from '../../../components/alert/Alert';
import TitleInput from '../../../components/inputs/TitleInput';
import TagInput from '../../../components/inputs/TagInput';
import LanguagesInput from '../../../components/inputs/LanguagesInput';
import TagTranslateInput from '../../../components/inputs/TagTranslateInput';
import { visitedLocals } from '../../schedules/SchedulesFilters';

export const RoomPlanTypes = {
    NORMAL: 'NORMAL',
    XL: 'XL',
    BOTH: 'BOTH',
}

const validations = (values) => {
    let errors = {};

    errors = FormValidator.make({
        designation: 'required|languages',
        weddingPlace: 'required',
    })(values);

    return errors;
};

let TableTypeModal = ({
    isOpen,
    handleSubmit,
    initialValues,
    onSubmit,
    onClose,
    onDelete,
    typeForm
}) => {
    useEffect(() => {
    }, []);

    return (
        <Modal
            visible={isOpen}
            title={'Editar tipo de tabela'}
            maskClosable={false}
            onCancel={onClose}
            footer={<React.Fragment>
                <Button
                    key='cancel'
                    type='default'
                    onClick={onClose}>
                    Cancelar
                </Button>
                <Button
                    key='submit'
                    type='primary'
                    onClick={handleSubmit(onSubmit)}>
                    Gravar
                </Button>
            </React.Fragment>}>

            <BaseForm onSubmit={handleSubmit(onSubmit)}>
                <Row gutter={[12, 12]}>
                    <Col xs={24} md={24} lg={24} xl={24}>
                        <Field
                            component={TitleInput}
                            name={'designation'}
                            type="text"
                            label={'Designação *'}
                            placeholder={'Legenda da restrição'}
                        />
                    </Col>
                    <Col xs={24} md={24} lg={24} xl={24}>
                        <Field
                            component={SelectInput}
                            name={'weddingPlace'}
                            label={'Local *'}
                            data={visitedLocals}
                        />
                    </Col>
                    <Col xs={24} md={24} lg={24} xl={24}>
                        <Field
                            component={SelectInput}
                            name={'roomPlan'}
                            label={'Selecione o plano'}
                            data={[
                                {_id: RoomPlanTypes.NORMAL, name: 'Normal'},
                                {_id: RoomPlanTypes.XL, name: 'XL'},
                                {_id: RoomPlanTypes.BOTH, name: 'Ambos'},
                              ]}
                        />
                    </Col>
                </Row>
            </BaseForm>
        </Modal>
    );
};

TableTypeModal = reduxForm({
    form: 'typeForm',
    enableReinitialize: true,
    validate: validations,
})(TableTypeModal);

const selector = formValueSelector('typeForm');

const mapStateToProps = state => ({
    restrictionForm: {
        designation: selector(state, 'designation'),
        weddingPlace: selector(state, 'weddingPlace'),
        availableForSelect: selector(state, 'availableForSelect'),
        coupleTable: selector(state, 'coupleTable'),
        roomPlan: selector(state, 'roomPlan'),
    }
});

TableTypeModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(TableTypeModal)