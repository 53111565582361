import client from '../config/AxiosConfig';

export const GetYearNumbers = async (year, local) => client.get(`/reports/year/${year}/${local}`);

// Weddingg Confirmed Orders
export const GetGenericOrderWeddings = async filters => client.get(`/reports/orders/generic?filter=${filters}`);

export const GetFlowersOrderWeddings = async filters => client.get(`/reports/orders/flowers?filter=${filters}`);

export const GetStaffOrderWeddings = async filters => client.get(`/reports/orders/staff?filter=${filters}`);

export const GetCakeOrderWeddings = async filters => client.get(`/reports/orders/cake?filter=${filters}`);

// Archived Wedding Orders
export const GetGenericOrderArchives = async filters => client.get(`/reports/orders/archive/generic?filter=${filters}`);

export const GetFlowersOrderArchives = async filters => client.get(`/reports/orders/archive/flowers?filter=${filters}`);

export const GetStaffOrderArchives = async filters => client.get(`/reports/orders/archive/staff?filter=${filters}`);

export const GetCakeOrderArchive = async filters => client.get(`/reports/orders/archive/cake?filter=${filters}`);

// Orders Files
export const SendOrderRequest = async data => client.post('/reports/orders/send', data);

export const DownloadOrder = async data => {
  return client.post('/reports/orders/download', data, {
    responseType: 'blob'
  }).then(response => {
    return response;
  })
  //   fetch(`${process.env.REACT_APP_API}/reports/orders/download`, { 
  //     method: 'POST', 
  //     body: JSON.stringify(data), 
  //     headers: { 'Content-Type': 'application/json' } 
  //   })
  //   .then(response => {
  //     return response.blob().then(blob => {
  //       return {
  //         headers: response.headers,
  //         blob
  //       }
  //     })
  //   })
  //   .then(data => accept({ headers: data.headers, blob: data.blob }))
  //   .catch(error => reject(error))
  // });
}

export const UploadToDrive = async (weddingId, filters) =>
  client.get(`/reports/process/${weddingId}/uploadDrive?filter=${filters}`);

export const UploadMultipleToDrive = async (weddingId, filters) =>
  client.get(`/reports/process/${weddingId}/uploadMultipleDrive?filter=${filters}`);

export const DownloadRoomPlan = async (weddingId, filters = {}) =>
  client.get(`/reports/process/${weddingId}/roomPlan?filter=${JSON.stringify(filters)}`);


export const UploadRoomPlanToDrive = async (weddingId, filters) =>
  client.get(`/reports/process/${weddingId}/uploadRoomPlanDrive?filter=${JSON.stringify(filters)}`);

export const DownloadGuestPlan = async (weddingId, filters = {}) =>
  client.get(`/reports/process/${weddingId}/guestPlan?filter=${JSON.stringify(filters)}`);

export const DownloadGuestFlyers = async (weddingId, filters = {}) =>
  client.get(`/reports/process/${weddingId}/guestFlyers?filter=${JSON.stringify(filters)}`);

export const UploadGuestFlyersToDrive = async (weddingId, filters) =>
  client.get(`/reports/process/${weddingId}/uploadGuestFlyersDrive?filter=${JSON.stringify(filters)}`);

export const AuthGoogleDrive = async () =>
  client.get(`/reports/auth/google`);

