import client from '../config/AxiosConfig';

export const AddContentByCategory = async data =>
  await client.post('/content', data);

export const GetContentByCategory = async category =>
  await client.get(`/content/category/${category}`);

export const EditContentByCategoryId = async (id, data) =>
  await client.put(`/content/${id}`, data);

// Link Files (Information Book/Complements Document)
export const AddFileLink = async (id, lang, file) =>
  await client.put(`/content/${id}/fileLink/${lang}`, file);

export const RemoveFileLink = async (id, file, lang) =>
  await client.delete(`/content/${id}/fileLink/${file}/${lang}`);

  export const AddFile = async (id, file) =>
  await client.put(`/content/${id}/file`, file);

export const RemoveFile = async (id, file) =>
  await client.delete(`/content/${id}/file/${file}`);

export const SaveImage = async (id, file) =>
  await client.put(`/content/${id}/image`, file);

export const DeleteImage = async (id, file) =>
  await client.delete(`/content/${id}/image/${file}`);
