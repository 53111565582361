import { checkOption, checkFoodOption, checkColorOption } from '../options/Options';

export const findUnansweredAboutUs = (section, weddingInfo) => {
  const questions = [];
  let chapter = undefined, question = undefined, hasAnswer = undefined;

  //If there's no wedding day language, add to array
  if(!weddingInfo.weddingDayLanguage || weddingInfo?.weddingDayLanguage == '') {
    questions.push({
      _id: 'weddingDayLanguage',
      title: {
        pt: 'Idioma no dia do casamento'
      }
    });
  }

  //If there's no organizer language, add to array
  // if(!weddingInfo.organizerLanguage || weddingInfo?.organizerLanguage == '') {
  //   questions.push({
  //     _id: 'weddingDayLanguage',
  //     title: {
  //       pt: 'Preferência de idioma nas comunicações (email e reunião com organizador)'
  //     }
  //   });
  // }


  //If there's no Organizer, add to array
  if(!weddingInfo.organizer) {
    questions.push({
      _id: 'organizer',
      title: {
        pt: 'Organizador'
      }
    });
  }

  //If there's no Bride name or phone, add to array
  if(!weddingInfo.bride || !weddingInfo.bride.name) {
    questions.push({
      _id: 'bride_info',
      title: {
        pt: 'Nome da Noiva'
      }
    });
  }
  if(!weddingInfo.bride || !weddingInfo.bride.contact) {
    questions.push({
      _id: 'bride_info',
      title: {
        pt: 'Contacto da Noiva'
      }
    });
  }

  //If there's no Groom name or phone, add to array
  if(!weddingInfo.groom || !weddingInfo.groom.name) {
    questions.push({
      _id: 'groom_info',
      title: {
        pt: 'Nome do Noivo'
      }
    });
  }
  if(!weddingInfo.groom || !weddingInfo.groom.contact) {
    questions.push({
      _id: 'groom_info',
      title: {
        pt: 'Contacto do Noivo'
      }
    });
  }

  //If there's no Alternative Contact name or phone, add to array
  if(!weddingInfo.alternative || !weddingInfo.alternative.name) {
    questions.push({
      _id: 'alternative_info',
      title: {
        pt: 'Nome do Contacto Alternativo'
      }
    });
  }
  if(!weddingInfo.alternative || !weddingInfo.alternative.contact) {
    questions.push({
      _id: 'alternative_info',
      title: {
        pt: 'Contacto Alternativo'
      }
    });
  }

  //If there's no Alternative Contact2 name or phone, add to array
  if(!weddingInfo.alternative2 || !weddingInfo.alternative2.name) {
    questions.push({
      _id: 'alternative_info',
      title: {
        pt: 'Nome do 2º Contacto Alternativo'
      }
    });
  }
  if(!weddingInfo.alternative2 || !weddingInfo.alternative2.contact) {
    questions.push({
      _id: 'alternative_info',
      title: {
        pt: '2º Contacto Alternativo'
      }
    });
  }

  //Check if there are any billing information. If not, add to array
  // if(!weddingInfo.billings || (weddingInfo.billings && weddingInfo.billings.length === 0)) {
  //   questions.push({
  //     _id: 'billing',
  //     title: {
  //       pt: 'Dados para faturação'
  //     }
  //   });
  // }

  if(section.chapters.length > 0) {
    for(let i = 0; i < section.chapters.length; i++) {
      chapter = section.chapters[i];

      //Dados para faturação e informação de pagamentos
      if(chapter._id === '5e735eeb29196b814fb983b6') {
        if(chapter.questions.length > 0) {
          for(let j = 0; j < chapter.questions.length; j++) {
            question = chapter.questions[j];

            if(
              !question.answer || 
              (question.answer && !question.answer.response) ||
              (question.answer && question.answer.response && question.answer.response.length === 0)
            ) {
              questions.push(question);
            }
            else if(question.answer && question.answer.response && question.answer.response.length > 0) {
              //Default: has no valid answer
              hasAnswer = false;

              //We need to check every option to see if it still exists or not. There can be invalid answers over the time
              for(let h = 0; h < question.answer.response.length; h++) {
                //If we found a valid answer, exit right away
                if(checkOption(question.answer.response[h], question, weddingInfo.wedding_place)) {
                  hasAnswer = true;
                  break;
                }
              }

              //If there's no answer, add the question to the array
              if(!hasAnswer) {
                questions.push(question);
              }
            }
          }
        }
      }
    }
  }

  return questions;
}

export const findUnansweredStaff = (section, weddingPlace) => {
  const questions = [];
  let chapter = undefined, question = undefined, hasAnswer = undefined;

  if(section.chapters.length > 0) {
    for(let i = 0; i < section.chapters.length; i++) {
      chapter = section.chapters[i];

      //Internal Staff
      if(chapter._id === '5e3181e69621e5d6a3d89740') {
        if(chapter.questions.length > 0) {
          for(let j = 0; j < chapter.questions.length; j++) {
            question = chapter.questions[j];

            //Ignore questions "Organizador" && "Coordenador" 
            if(question._id !== '5e3184829621e5d6a3d89742' && question._id !== '5e3184939621e5d6a3d89743') {
              if(
                !question.answer || 
                (question.answer && !question.answer.response) ||
                (question.answer && question.answer.response && question.answer.response.length === 0)
              ) {
                questions.push(question);
              }
              else if(question.answer && question.answer.response && question.answer.response.length > 0) {
                //Default: has no valid answer
                hasAnswer = false;
  
                //We need to check every option to see if it still exists or not. There can be invalid answers over the time
                for(let h = 0; h < question.answer.response.length; h++) {
                  //If we found a valid answer, exit right away
                  if(checkOption(question.answer.response[h], question, weddingPlace)) {
                    hasAnswer = true;
                    break;
                  }
                }
  
                //If there's no answer, add the question to the array
                if(!hasAnswer) {
                  questions.push(question);
                }
              }
            }
          }
        }
      }
    }
  }

  return questions;
}

export const findUnansweredGuests = (section, guestsInfo) => {
  const questions = [];
  let chapter = undefined, question = undefined;

  if(section.chapters.length > 0) {
    for(let i = 0; i < section.chapters.length; i++) {
      chapter = section.chapters[i];

      //Provisory List or Room Plan
      if(chapter._id === '5e2ada5d47f38787fd9e8423' || chapter._id === '5e2adaa747f38787fd9e8424') {
        if(chapter.questions.length > 0) {
          for(let j = 0; j < chapter.questions.length; j++) {
            question = chapter.questions[j];

            //Provisory List
            if(chapter._id === '5e2ada5d47f38787fd9e8423') {
              //Provisory number of guests
              if(question._id === '5e2adf9947f38787fd9e8427' && (!guestsInfo || !guestsInfo.provisory_guests > 0)) {
                questions.push(question);
              }
              //Provisory number of guests
              else if(question._id === '5e2adfbf47f38787fd9e8428' && (!guestsInfo || !guestsInfo.guests_location)) {
                questions.push(question);
              }
            }
            //Room Plan
            else if(chapter._id === '5e2adaa747f38787fd9e8424') {
              if(question._id === '5e2ae0b247f38787fd9e842b' && (!guestsInfo || !guestsInfo.room_plan || (guestsInfo.room_plan && !guestsInfo.room_plan.filename))) {
                questions.push(question);
              }
            }
          }
        }
      }
    }
  }

  return questions;
}

export const findUnansweredQuestions = (section, food, colors, weddingPlace) => {
  const questions = [];
  let question = undefined, hasAnswer = undefined, foodType = undefined;

  if(section.chapters.length > 0) {
    for(let i = 0; i < section.chapters.length; i++) {
      if(section.chapters[i].questions.length > 0) {
        for(let j = 0; j < section.chapters[i].questions.length; j++) {
          question = section.chapters[i].questions[j];

          if(
            question.type === 1 || 
            question.type === 2 || 
            question.type === 3
          ) {
            if(
              !question.answer || 
              (question.answer && !question.answer.response)
            ) {
              questions.push(question);
            }
          }
          else if(
            question.type === 4 || 
            question.type === 5 || 
            question.type === 6
          ) {
            if(
              !question.answer || 
              (question.answer && !question.answer.response) ||
              (question.answer && question.answer.response && question.answer.response.length === 0)
            ) {
              questions.push(question);
            }
            else if(question.answer && question.answer.response && question.answer.response.length > 0) {
              //Default: has no valid answer
              hasAnswer = false;

              //We need to check every option to see if it still exists or not. There can be invalid answers over the time
              for(let h = 0; h < question.answer.response.length; h++) {
                //If we found a valid answer, exit right away
                if(checkOption(question.answer.response[h], question, weddingPlace)) {
                  hasAnswer = true;
                  break;
                }
              }

              //If there's no answer, add the question to the array
              if(!hasAnswer) {
                questions.push(question);
              }
            }
          }
          else if(
            question.type === 7 || 
            question.type === 8
          ) {
            if(
              !question.answer || 
              (question.answer && !question.answer.response) ||
              (question.answer && question.answer.response && !question.answer.response.filename)
            ) {
              questions.push(question);
            }
          }
          else if(
            question.type === 10
          ) {
            //Check field "qtd" instead of "id" as it can be true / false and create problems when checking if it exists
            if(
              !question.answer || 
              (question.answer && !question.answer.response) ||
              (question.answer && question.answer.response && !question.answer.response[0].qtd)
            ) {
              questions.push(question);
            }
          }
          else if(
            question.type === 11
          ) {
            if(
              !question.answer || 
              (question.answer && !question.answer.response) ||
              (question.answer && question.answer.response && !question.answer.response.length === 0)
            ) {
              questions.push(question);
            }
            else if(question.answer && question.answer.response && question.answer.response.length > 0) {
              //Default: has no valid answer
              hasAnswer = false;

              //We need to check every option to see if it still exists or not. There can be invalid answers over the time
              for(let h = 0; h < question.answer.response.length; h++) {
                //If we found a valid answer, exit right away
                if(checkColorOption(question.answer.response[h], colors)) {
                  hasAnswer = true;
                  break;
                }
              }

              //If there's no answer, add the question to the array
              if(!hasAnswer) {
                questions.push(question);
              }
            }
          }
          else if(
            question.type === 12 ||
            question.type === 13 ||
            question.type === 14 ||
            question.type === 15 ||
            question.type === 16 ||
            question.type === 17
          ) {
            if(
              !question.answer || 
              (question.answer && !question.answer.response) ||
              (question.answer && question.answer.response && !question.answer.response.length === 0)
            ) {
              questions.push(question);
            }
            else if(question.answer && question.answer.response && question.answer.response.length > 0) {
              //Default: has no valid answer
              hasAnswer = false;

              foodType = undefined;
              if(question.type === 12) foodType = food.entrees;
              else if(question.type === 13) foodType = food.meat;
              else if(question.type === 14) foodType = food.fish;
              else if(question.type === 15) foodType = food.dessert;
              else if(question.type === 16) foodType = food.cake;
              else if(question.type === 17) foodType = food.supper;

              if(foodType) {
                //We need to check every option to see if it still exists or not. There can be invalid answers over the time
                for(let h = 0; h < question.answer.response.length; h++) {
                  //If we found a valid answer, exit right away
                  if(checkFoodOption(question.answer.response[h], foodType)) {
                    hasAnswer = true;
                    break;
                  }
                }

                //If there's no answer, add the question to the array
                if(!hasAnswer) {
                  questions.push(question);
                }
              }
            }
          }
        }
      }
    }
  }

  return questions;
}