import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { initialize, submit } from 'redux-form';
import { Row, Col, Dropdown, Button, Icon, Modal, Menu, Tooltip } from 'antd';
import { updateBackgroundRoomPlan } from '../../redux/Info/info.actions';
import { SpinLoading } from '../../styles/BasicStyles';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import { Toolbar, RoomEditor, RoomEditorContainer, ImageBgWarning } from './RoomPlanStyles';
import { RoomPlanTypes } from '../../weddingday/settings/settingsRoomPlan/TableTypeModal';
import { auth_token_key } from '../../infra/config/LocalStorageKeys';
import Alerts from '../../components/alert/Alert';
import BaseButton from '../../components/buttons/BaseButton';
import { ActivateRoomPlan, AnswerQuestion, ApproveRoomPlan, ChangeRoomPlanTemplate, CheckUpdateCoupleTable, DisapproveRoomPlan, GetCoupleTableEditor, GetOurGuests, GetOurGuestsEditor, SaveOurGuestsEditor, SaveRoomPlanImage, SaveRoomPlanType, SetRoomPlanTemplate, SubmitRoomPlan, UndoSubmitRoomPlan, ValidateRoomPlan } from '../../infra/requests/WeddingProcessRequests';
import NormalPlanIcon from "../../assets/icons/normalRoomPlan.svg";
import XLPlanIcon from "../../assets/icons/xlRoomPlan.svg";
import PencilIcon from '../../assets/icons/pencil.svg';
import PencilBlockedIcon from '../../assets/icons/pencil-blocked.svg';
import { GetRoomPlanConfigByPlace, GetRoomPlanConfigByPlaceWithDeleted, GetRoomPlanConfigByType, GetRoomPlanConfigByTypeForEditor, GetRoomPlanConfigForEditor, UpdateEditorRoomPlanConfig, UpdateRoomPlanConfig, UploadBackgroundRoomPlan } from '../../infra/requests/RoomPlanConfigRequests';
import { GetConfigByPlace } from '../../infra/requests/ConfigsRequests';
import { GetTableTypeList } from '../../infra/requests/TableTypeRequests';
import { GetFoodRestrictionList } from '../../infra/requests/FoodRestrictionsRequests';
import { ButtonBgUpdaload } from '../../components/roomPlans/RoomPlanStyles';
import Dropzone from 'react-dropzone';
import { getAnswerPrintedConfirmation } from '../../infra/services/wedding/printedUtils';
import { correctResponseValues } from '../ChoicesPage/components/OptionUtils';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import { updateQuestionAction } from '../../redux/wedding/wedding.actions';
import SectionModal from '../ChoicesPage/SectionModal';
import { Legend, LegendItem } from '../../weddingday/weddings/YearCalendar';
import ReactSVG from 'react-svg';
import XpertGoLeavePageBlocker from '../../weddingday/reservesAttending/XpertGoLeavePageBlocker';
import { getRoomPlanPrintedConfirmation } from '../../infra/services/wedding/printedUtils';
import { AuthGoogleDrive, UploadGuestFlyersToDrive, UploadRoomPlanToDrive } from '../../infra/requests/ReportsRequests';
import { checkCoupleTableAnswer, removeNotExistingOptions } from '../../infra/services/options/Options';
import { TablePurposeEnum } from '../../components/guestMap/utilGuest';

const REACT_APP_API = process.env.REACT_APP_API;

const { confirm } = Modal;

export const zoomOptions = [
  { _id: 50, name: '50%' },
  { _id: 80, name: '80%' },
  { _id: 100, name: '100%' },
  { _id: 150, name: '150%' }
];

class RoomPlanEditor extends Component {
  timeout = 0;
  roomEditor = null;

  state = {
    ready: false,
    loading: false,
    loadingBg: false,

    weddingId: null,
    basePlan: false,
    dataBasePlan: {},

    // zoom: 100,

    roomPlanReady: false,
    blockReroute: true,
    checkedChanges: false,
    roomPlanConfigId: null,
    roomPlanConfig: {},
    roomPlanOptions: [],
    ourGuest: {},
    answerSeatId: false,

    tables: [],
    objectTypes: [],
    foodRestrictions: [],

    sectionGuests: {},
    sectionDecor: {},

    exporting: false,
    changingPlan: false,
    changedPlan: false,
    validating: false,
    saving: false,
    submitting: false,

    coupleTableQuestion: {},
    showModal: false,
    savingCoupleTable: false
  };

  componentDidMount = async () => {
    this.roomEditor = new window.RoomEditor('editor', 'pt');
    this.roomEditor.translationSystem.setActiveLanguage("pt");
    this.roomEditor.mouseManager.setChangeCoupleEvent(() => {
      this.openModal();
    });
    this.roomEditor.mouseManager.onGuestChangeError(() => {
      return Alerts.new({
        type: 'error',
        title: 'Erro',
        text: 'Não existem lugares suficientes para trocar os convidados de mesa.'
      });
    });
    this.roomEditor.onExtraCostChanged(() => {
      return Alerts.new({
        type: 'warning',
        title: 'Atenção',
        text: 'A mesa ativada poderá implicar um custo extra.'
      });
    });

    this.setState({
      ready: true,
      weddingId: this.props.weddingId || null,
      basePlan: this.props.basePlan || false,
      roomPlanConfigId: this.props.roomPlanConfigId || null,
    }, () => this.getInformation());

    // const script = document.createElement("script");
    // script.src = "https://xgo-leandrosa.github.io/room-editor/room-editor.js";
    // document.body.appendChild(script);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.ready != this.props.ready) {
      this.setState({
        ready: true,
        weddingId: this.props.weddingId || null,
        basePlan: this.props.basePlan || false,
        roomPlanConfigId: this.props.roomPlanConfigId || null,
      }, () => this.getInformation());
    }
  }

  componentWillUnmount() {
    if (this.roomEditor) {
      this.roomEditor.destroy();
    }
  }

  getInformation = async () => {
    const { structureInfo, configs, wedding } = this.props;
    const { weddingId, basePlan, dataBasePlan, roomPlanConfigId } = this.state;

    this.setState({
      loading: true,
      roomPlanReady: false
    });

    const filterObjects = {};
    const dataRoomEditor = {
      roomPlan: {
        imageSrc: null,
        x: 0,
        y: 0,
        scale: 0
      },
      kitchenNotes: '',
      tables: [],
      foodRestrictions: [],
    };
    let answerSeatId = false;
    let ourGuest = null;

    if (basePlan) {
      if (!roomPlanConfigId) return;
      const result = await GetRoomPlanConfigForEditor(roomPlanConfigId);
      const roomPlanConfig = result?.data?.roomConfig || null;
      const tables = result?.data?.tables || null;
      filterObjects['weddingPlace'] = roomPlanConfig.weddingPlace;

      // Set data for room editor
      dataRoomEditor.roomPlan = {
        imageSrc: roomPlanConfig?.bgRoomPlan ? `${process.env.REACT_APP_IMAGES_URL}${roomPlanConfig?.bgRoomPlan?._id}` : null,
        x: roomPlanConfig?.position?.x || 0,
        y: roomPlanConfig?.position?.y || 0,
        scale: roomPlanConfig?.position?.scale || 0,
        horizontalCenterOfRoom: roomPlanConfig?.horizontalCenterOfRoom || 0,
        constraintPoints: roomPlanConfig?.constraintPoints || [],
        zones: roomPlanConfig?.zones || [],
        spaceBetweenTables: result?.data?.spaceBetweenTables || 0,
      };
      dataRoomEditor.tables = tables || [];

      if (this.roomEditor) {
        if (!roomPlanConfig?.deleted && roomPlanConfig?.bgRoomPlan) this.roomEditor.setMode("ROOM_PLAN");
        else this.roomEditor.setMode("READ_ONLY");
      }

      this.setState({
        roomPlanConfig,
        tables,
      });
    } else {
      const sectionGuests = structureInfo.find(s => s.tag === 'GUESTS');
      const sectionDecor = structureInfo.find(s => s.tag === 'DECORATION');
      const chapterTables = sectionDecor?.chapters.find(f => f._id === '5e0ddbbfc8bb4f27117dc68d');
      const question = chapterTables.questions.find(f => f?._id === '5e18723686fdaa6d7aeedd91');
      const { data } = await GetOurGuestsEditor(weddingId);
      ourGuest = data?.ourGuest || null;

      filterObjects['weddingPlace'] = wedding?.wedding?.wedding_place;

      if (this.roomEditor) {
        this.roomEditor.setWeddingPlace(wedding?.wedding?.wedding_place);
      }

      // Set data for room editor
      dataRoomEditor.roomPlan = {
        imageSrc: `${process.env.REACT_APP_IMAGES_URL}${data?.ourGuest?.bgRoomPlan?._id}`,
        x: data?.ourGuest?.roomPlanPosition?.x || 0,
        y: data?.ourGuest?.roomPlanPosition?.y || 0,
        scale: data?.ourGuest?.roomPlanPosition?.scale || 0,
        horizontalCenterOfRoom: data?.ourGuest?.roomPlanHorizontalCenterOfRoom || 0,
        constraintPoints: data?.ourGuest?.roomPlanConstraintPoints || [],
        zones: data?.ourGuest?.roomPlanZones || [],
        spaceBetweenTables: data?.spaceBetweenTables || 0,
      };
      dataRoomEditor.kitchenNotes = ourGuest?.kitchenNotes || '';
      dataRoomEditor.tables = data?.tables || [];
      answerSeatId = data?.answerSeatId;

      if (this.roomEditor) this.roomEditor.setMode("ADMINISTRATOR");

      this.setState({
        sectionGuests,
        sectionDecor,
        coupleTableQuestion: question,
        ourGuest: data?.ourGuest || {},
        tables: data?.tables || [],
        answerSeatId,
        showModal: false,
      });
    }

    // Room plan configs
    if (filterObjects?.weddingPlace) {
      const resultRoomPlanConfigs = await GetRoomPlanConfigByPlace(filterObjects?.weddingPlace);
      if (resultRoomPlanConfigs?.success) {
        let roomPlans = resultRoomPlanConfigs?.data || [];

        if (!basePlan) {
          roomPlans = roomPlans.filter(f => !f?.deleted || (f?.deleted && f?._id == ourGuest?.roomPlanTemplate));
        }
        this.setState({ roomPlanOptions: roomPlans });
      } else {
        return Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'Erro ao carregar planos!'
        });
      }
    }

    // Table types
    const resultObjects = await GetTableTypeList(filterObjects);
    if (resultObjects?.success) {
      this.setState({ objectTypes: resultObjects?.data || [] });
      const coupleTable = resultObjects?.data?.filter(f => f?.coupleTable);
      const guestsTable = resultObjects?.data?.filter(f => !f?.coupleTable && !f?.increasedTable);

      if (this.roomEditor) {
        this.roomEditor.activeCoupleTables(coupleTable.map(m => m?.tableClass));
        this.roomEditor.activeGuestTables(guestsTable.map(m => m?.tableClass));
      }
    } else {
      return Alerts.new({
        type: 'error',
        title: 'Erro',
        text: 'Erro ao carregar mesas!'
      });
    }

    // Food restrictions
    if (!basePlan) {
      const resultFoodRestrictions = await GetFoodRestrictionList();
      if (resultFoodRestrictions?.success) {
        dataRoomEditor.foodRestrictions = resultFoodRestrictions?.data || [];
        this.setState({ foodRestrictions: resultFoodRestrictions?.data || [] });
      } else {
        return Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'Erro ao carregar restrições alimentares!'
        });

      }
    }

    if (dataRoomEditor.tables?.length > 0) {
      dataRoomEditor.tables = dataRoomEditor.tables.map(m => ({ ...m, tableType: m?.tableClass }));
    }
    console.warn('dataRoomEditor', dataRoomEditor);
    if (this.roomEditor) this.roomEditor.deserializeEditor(dataRoomEditor);

    this.setState({ loading: false }, () => {
      setTimeout(() => {
        if (answerSeatId) {
          if (this.roomEditor) this.roomEditor.setGuestsMode('SEAT');
        } else {
          if (this.roomEditor) this.roomEditor.setGuestsMode('TABLE');
        }

        this.setState({ roomPlanReady: true });
      }, 1500);
    });
  };

  confirmChangeRoomPlan = async (value) => {
    const { roomPlanOptions } = this.state;

    const roomPlanTemplate = roomPlanOptions.find(f => f?._id == value);
    if (!roomPlanTemplate) return;

    confirm({
      title: `Pretende alterar para o plano ${roomPlanTemplate?.name}?`,
      content: (<div>
        <p>A alteração do plano de sala poderá implicar alterar a orientação das mesas apresentadas no plano.</p>
      </div>
      ),
      okText: 'Alterar',
      cancelText: 'Cancelar',
      onOk: () => {
        this.changeRoomPlan(value);
      },
      onCancel: () => null,
    });
  }

  changeRoomPlan = async (value) => {
    const { weddingId, foodRestrictions } = this.state;
    this.setState({ changingPlan: true, loading: true });
    const data = { roomPlanTemplate: value };
    const result = await ChangeRoomPlanTemplate(weddingId, data);

    if (result?.success) {
      const dataRoomEditor = {
        roomPlan: {
          imageSrc: null,
          x: 0,
          y: 0,
          scale: 0
        },
        kitchenNotes: '',
        tables: [],
        foodRestrictions: [],
      };

      dataRoomEditor.roomPlan = {
        imageSrc: `${process.env.REACT_APP_IMAGES_URL}${result?.data?.ourGuest?.bgRoomPlan?._id}`,
        x: result?.data?.ourGuest?.roomPlanPosition?.x || 0,
        y: result?.data?.ourGuest?.roomPlanPosition?.y || 0,
        scale: result?.data?.ourGuest?.roomPlanPosition?.scale || 0,
        horizontalCenterOfRoom: result?.data?.ourGuest?.roomPlanHorizontalCenterOfRoom || 0,
        constraintPoints: result?.data?.ourGuest?.roomPlanConstraintPoints || [],
        zones: result?.data?.ourGuest?.roomPlanZones || [],
        spaceBetweenTables: result?.data?.spaceBetweenTables || 0,
      };
      dataRoomEditor.kitchenNotes = result?.data?.ourGuest?.kitchenNotes || '';
      dataRoomEditor.foodRestrictions = foodRestrictions || [];
      dataRoomEditor.tables = result?.data?.tables || [];
      if (dataRoomEditor.tables?.length > 0) {
        dataRoomEditor.tables = dataRoomEditor.tables.map(m => ({ ...m, tableType: m?.tableClass }));
      }

      let answerSeatId = result?.data?.answerSeatId;

      console.warn('changedPlan dataRoomEditor', dataRoomEditor);
      if (this.roomEditor) this.roomEditor.deserializeEditor(dataRoomEditor);

      this.setState({
        changingPlan: false,
        loading: false,
        changedPlan: true,
        ourGuest: result?.data?.ourGuest || {},
        tables: result?.data?.tables || [],
        answerSeatId,
        roomPlanConfigId: result?.data?.roomPlanConfigId,
      });
    } else {
      this.setState({
        changingPlan: false,
        loading: false
      });
    }
  }

  confirmActivateRoomPlan = () => {
    confirm({
      title: `Tem certeza que pretende ativar o plano sala?`,
      content: <div>
        <p>Ao ativar o plano de sala irá permitir ao casal e ao convidado (caso exista) editarem o plano de sala.</p>
      </div>,
      okType: 'primary',
      okText: 'Ativar',
      cancelText: 'Cancelar',
      onOk: () => { this.activateRoomPlan(); },
      onCancel: () => null,
    });
  }

  activateRoomPlan = async () => {
    const { weddingId } = this.state;
    this.setState({ activating: true });
    const result = await ActivateRoomPlan(weddingId);
    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: `Lista de convidados ativa.`
      });
      this.setState({ activating: false }, () => this.getInformation());
    } else {
      this.setState({ activating: false });
    }
  };

  checkHasChangesToSave = () => {
    const { roomPlanReady, blockReroute } = this.state;

    let hasChanges = false;
    if (this.roomEditor && roomPlanReady) {
      hasChanges = this.roomEditor?.hasChanges();
    } else {
      hasChanges = false;
    }

    if (hasChanges !== blockReroute) {
      this.setState({
        blockReroute: hasChanges,
        checkedChanges: hasChanges ? true : false,
      });
    }

    return hasChanges;
  };

  confirmSaveRoomPlan = () => {
    const { ourGuest } = this.state;

    if (ourGuest?.validatedRoomPlan) {
      confirm({
        ...getRoomPlanPrintedConfirmation(),
        onOk: () => { this.saveRoomPlan(); },
        onCancel: () => { },
      });
    } else this.saveRoomPlan();
  }

  saveRoomPlan = async () => {
    const { dispatch } = this.props;
    const { weddingId, changedPlan, roomPlanConfigId, ourGuest } = this.state;
    this.setState({
      saving: true,
      loading: true
    });

    if (this.roomEditor) {
      // const valid = this.roomEditor?.valid();
      // console.warn('valid', valid);
      // if (valid?.status == 'INVALID') {
      //   Alerts.new({
      //     type: 'warning',
      //     title: 'Atenção',
      //     text: 'Plano de sala inválido! Verifique as mesas!'
      //   });
      //   this.setState({
      //     saving: false,
      //     loading: false,
      //   });
      //   return;
      // }

      const values = this.roomEditor.serializeEditor();
      if (changedPlan && values?.roomPlan) values.roomPlan['roomPlanTemplate'] = roomPlanConfigId;
      const data = {
        ...values,
        roomPlanVersion: ourGuest?.roomPlanVersion,
      }
      const result = await SaveOurGuestsEditor(weddingId, data);

      if (result?.success) {
        Alerts.new({
          type: 'success',
          title: 'Sucesso',
          text: 'Plano de sala alterado!'
        });

        if (result?.data?.answerRestriction) {
          dispatch(updateQuestionAction('FOOD', result?.data?.answerRestriction));
        }

        this.setState({
          saving: false,
          changedPlan: false,
          roomPlanConfigId: null,
        }, () => this.getInformation());
      } else {
        this.setState({
          saving: false,
          loading: false
        }, () => {
          if (result?.message == 'NOT_AUTHORIZED' || result?.message == 'OLD_ROOM_PLAN_VERSION') {
            this.getInformation();
          }
        });
      }
    }
  }

  confirmSubmit = () => {
    const { ourGuest } = this.state;

    const title = ourGuest?.submittedRoomPlan
      ? 'Tem a certeza que pretende remover a comunicação da lista?'
      : `Tem certeza que pretende comunicar a lista?`;

    const content = ourGuest?.submittedRoomPlan
      ? <div>
        <p>Ao remover a comunicação da lista permitirá ao casal proceder a uma nova comunicação para o SLevada & QLCisnes</p>
      </div>
      : <div>
        <p>Ao comunicar a lista informará o SLevada & QLCisnes de que a vossa lista de convidados está pronta para encomendas. </p>
        <p> Será com base neste número mínimo de pax que todas as encomendas serão realizadas, não sendo possível a não contabilização de pax em falta a partir deste momento. </p>
      </div>;

    confirm({
      title,
      content,
      okType: 'primary',
      okText: ourGuest?.submittedRoomPlan ? 'Remover comunicação' : 'Comunicar lista',
      cancelText: 'Cancelar',
      onOk: () => this.submitRoomPlan(),
      onCancel: () => null,
    });
  }

  submitRoomPlan = async () => {
    const { weddingId, changedPlan, roomPlanConfigId, ourGuest } = this.state;

    this.setState({
      saving: true,
      submiting: true,
      loading: true
    });

    if (this.roomEditor) {
      // const valid = this.roomEditor?.valid();
      // console.warn('valid', valid);
      // if (valid?.status == 'INVALID') {
      //   Alerts.new({
      //     type: 'warning',
      //     title: 'Atenção',
      //     text: 'Plano de sala inválido! Verifique as mesas!'
      //   });
      //   this.setState({
      //     saving: false,
      //     submiting: false,
      //     loading: false,
      //   });
      //   return;
      // }

      const values = this.roomEditor.serializeEditor();
      if (changedPlan && values?.roomPlan) values.roomPlan['roomPlanTemplate'] = roomPlanConfigId;
      const data = {
        ...values,
        roomPlanVersion: ourGuest?.roomPlanVersion,
      }
      const resultSave = await SaveOurGuestsEditor(weddingId, data);

      if (resultSave?.success) {
        this.setState({
          saving: false,
          ourGuest: resultSave?.data?.ourGuest || {},
          tables: resultSave?.data?.tables || []
        });

        const resultSubmit = ourGuest?.submittedRoomPlan
          ? await UndoSubmitRoomPlan(weddingId)
          : await SubmitRoomPlan(weddingId);

        if (resultSubmit?.success) {
          Alerts.new({
            type: 'success',
            title: 'Sucesso',
            text: ourGuest?.submittedRoomPlan ? 'Removida a comunicação da lista!' : 'Lista comunicada como concluída!'
          });
          this.setState({
            submiting: false,
            loading: false,
          }, () => this.getInformation());
        } else {
          this.setState({
            submiting: false,
            loading: false,
          }, () => this.getInformation());
        }
      } else {
        this.setState({
          saving: false,
          submiting: false,
          loading: false
        }, () => {
          if (resultSave?.message == 'NOT_AUTHORIZED' || resultSave?.message == 'OLD_ROOM_PLAN_VERSION') {
            this.getInformation();
          }
        });
      }
    }

  }

  confirmValidatingRoomPlan = () => {
    const { ourGuest } = this.state;

    const title = ourGuest?.validatedRoomPlan
      ? `Tem a certeza que pretende desbloquear o plano de sala?`
      : `Tem a certeza que pretende bloquear o plano de sala?`;
    const content = ourGuest?.validatedRoomPlan
      ? `A partir deste momento será desbloqueada a edição do plano de sala e a distribuição de convidados para o casal e/ou convidado`
      : `A partir deste momento será bloqueada a edição do plano de sala e a distribuição de convidados para o casal e/ou convidado.`;
    confirm({
      title,
      content: (<div>
        <p>{content}</p>
      </div>
      ),
      okType: ourGuest?.validatedRoomPlan ? 'danger' : 'primary',
      okText: ourGuest?.validatedRoomPlan ? 'Desbloquear' : 'Bloquear',
      cancelText: 'Cancelar',
      onOk: () => this.validateRoomPlan(),
      onCancel: () => null,
    });

  }

  validateRoomPlan = async () => {
    const { weddingId, changedPlan, roomPlanConfigId, ourGuest } = this.state;

    this.setState({
      saving: true,
      validating: true,
      loading: true
    });

    if (this.roomEditor) {
      // const valid = this.roomEditor?.valid();
      // console.warn('valid', valid);
      // if (valid?.status == 'INVALID') {
      //   Alerts.new({
      //     type: 'warning',
      //     title: 'Atenção',
      //     text: 'Plano de sala inválido! Verifique as mesas!'
      //   });
      //   this.setState({
      //     saving: false,
      //     validating: false,
      //     loading: false,
      //   });
      //   return;
      // }

      const values = this.roomEditor.serializeEditor();
      if (changedPlan && values?.roomPlan) values.roomPlan['roomPlanTemplate'] = roomPlanConfigId;
      const data = {
        ...values,
        roomPlanVersion: ourGuest?.roomPlanVersion,
      }
      const resultSave = await SaveOurGuestsEditor(weddingId, data);

      if (resultSave?.success) {
        this.setState({
          saving: false,
          ourGuest: resultSave?.data?.ourGuest || {},
          tables: resultSave?.data?.tables || []
        });

        const result = ourGuest?.validatedRoomPlan
          ? await DisapproveRoomPlan(weddingId)
          : await ApproveRoomPlan(weddingId);
        // console.warn('result', result);

        if (result?.success) {
          Alerts.new({
            type: 'success',
            title: 'Sucesso',
            text: ourGuest?.validatedRoomPlan ? 'Plano de sala reprovado! Edição do casal desbloqueada!' : 'Plano de sala aprovado! Edição do casal bloqueada!'
          });
          this.setState({
            validating: false,
            loading: false,
          }, () => this.getInformation());
        } else {
          this.setState({
            validating: false,
            loading: false
          }, () => this.getInformation());
        }
      } else {
        this.setState({
          saving: false,
          validating: false,
          loading: false
        }, () => {
          if (resultSave?.message == 'NOT_AUTHORIZED' || resultSave?.message == 'OLD_ROOM_PLAN_VERSION') {
            this.getInformation();
          }
        });
      }
    }
  }

  // Couple table 
  openModal = async () => {
    const { dispatch } = this.props;
    const { weddingId, changedPlan, roomPlanConfigId, ourGuest } = this.state;
    const { coupleTableQuestion } = this.state;

    if (this.roomEditor) {
      this.setState({
        loading: true,
        saving: true
      });
      const values = this.roomEditor.serializeEditor();
      if (changedPlan && values?.roomPlan) values.roomPlan['roomPlanTemplate'] = roomPlanConfigId;
      const data = {
        ...values,
        roomPlanVersion: ourGuest?.roomPlanVersion,
      }
      const result = await SaveOurGuestsEditor(weddingId, data);

      if (result?.success) {
        Alerts.new({
          type: 'success',
          title: 'Sucesso',
          text: 'Plano de sala alterado!'
        });
        dispatch(initialize('manage_answers_form', {}));

        if (coupleTableQuestion?.answer) {
          dispatch(
            initialize('manage_answers_form', {
              ...coupleTableQuestion?.answer,
              question_obs: coupleTableQuestion?.answer?.observations !== ''
            })
          );
        }

        this.setState({
          saving: false,
          loading: false,
          ourGuest: result?.data?.ourGuest || {},
          tables: result?.data?.tables || [],
          showModal: true,
        });

      } else {
        this.setState({
          loading: false,
          saving: false,
        }, () => {
          if (result?.message == 'NOT_AUTHORIZED' || result?.message == 'OLD_ROOM_PLAN_VERSION') {
            this.getInformation();
          }
        });
      }
    }
  };

  onModalSubmit = async (values) => {
    const { wedding } = this.props;
    if (wedding?.wedding?.printed) {
      confirm({
        ...getAnswerPrintedConfirmation(),
        onOk: () => this.confirmSubmitModal(values),
        onCancel: () => this.setState({ showModal: false }),
      });
    } else this.confirmSubmitModal(values);
  };

  confirmSubmitModal = async (values) => {
    const { wedding } = this.props;
    const { coupleTableQuestion } = this.state;

    const data = checkCoupleTableAnswer(values, coupleTableQuestion, wedding.wedding.wedding_place);
    const resultCheck = await CheckUpdateCoupleTable(wedding.wedding._id, data);

    if (resultCheck?.success) {
      if (resultCheck?.data?.showWarning) {
        const ovalLWarning = resultCheck?.data?.showOvalLWarning
          ? resultCheck?.data?.ovalLWarning == 'TO_REMOVE_TABLES_BY_OVALL'
            ? `Serão removidas as mesas ${resultCheck?.data?.tablesToRemove} devido ao espaço ocupado pela nova mesa.`
            : resultCheck?.data?.ovalLWarning == 'TO_RESTORE_TABLES_BY_OVALL'
              ? `Serão recuperadas as mesas ${resultCheck?.data?.tablesToRemove} devido ao espaço ocupado pela nova mesa.`
              : null
          : null;

        confirm({
          title: 'Têm a certeza que pretende alterar a mesa do casal?',
          content: (<div>
            <p>A nova mesa não têm o mesmo número de lugares, por isso serão removidos os convidados.</p>
            {resultCheck?.data?.showOvalLWarning && ovalLWarning && <p><b>{ovalLWarning}</b></p>}
          </div>
          ),
          okText: 'Alterar mesa',
          cancelText: 'Cancelar',
          onOk: () => {
            this.saveModal(values);
          },
          onCancel: () => null,
        });
      } else this.saveModal(values);
    } else return;
  }

  saveModal = async (values) => {
    const { wedding, dispatch } = this.props;
    const { coupleTableQuestion, sectionDecor, objectTypes } = this.state;

    this.setState({ savingCoupleTable: true });

    values = removeNotExistingOptions(values, coupleTableQuestion, wedding?.wedding?.wedding_place);
    values = correctResponseValues(values);
    const payload = FlattenToFormData({ ...values, question: coupleTableQuestion._id });

    const { data, success } = await AnswerQuestion(wedding.wedding._id, payload);

    if (success) {
      const resultCouple = await GetCoupleTableEditor(wedding.wedding._id);

      if (resultCouple?.success) {
        const coupleTable = resultCouple?.data?.tableType?.tableClass;
        if (coupleTable) this.roomEditor.mouseManager.changeCoupleTable(coupleTable);
      }

      dispatch(updateQuestionAction(sectionDecor.tag, data));
      this.setState({
        savingCoupleTable: false,
        showModal: false
      }, () => this.saveRoomPlan());

    } else this.setState({ savingCoupleTable: false });;
  };

  // Export Methods
  exportRoomPlan = async () => {
    const { wedding } = this.props;
    if (this.roomEditor) {
      this.setState({
        exporting: true,
        loading: true
      });
      // const result = DownloadGuestPlan(wedding.wedding._id);
      const authToken = localStorage.getItem(auth_token_key);
      let filename = 'Distribuição de Convidados e Plano de Sala';

      try {
        const image = await this.roomEditor.getImage();
        const result = await SaveRoomPlanImage(wedding.wedding._id, { image });

        if (result?.success) {
          fetch(
            `${REACT_APP_API}/reports/process/${wedding.wedding._id}/roomPlan?filter=${JSON.stringify({})}`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${authToken}`
              }
            }
          )
            .then(resp => {
              if (resp.status == 200) {
                const header = resp.headers.get('Content-Disposition');
                filename = header ? header.split('=')[1] : '';
                // Decode filename
                filename = decodeURI(filename);

                return resp.blob().then(blob => {
                  const url = window.URL.createObjectURL(blob);
                  const a = document.createElement('a');
                  a.style.display = 'none';
                  a.href = url;

                  // Correct name for file
                  let name = filename.replace(/#|"/g, '').replace(/_/g, ' ')?.trim().replace('.pdf', '');
                  name.concat('.pdf');

                  a.setAttribute('download', name);
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(url);
                  this.setState({
                    exporting: false,
                    loading: false
                  });
                });
              } else {
                resp.json().then(obj => {
                  this.setState({
                    exporting: false,
                    loading: false
                  });
                  return Alerts.new({
                    type: 'error',
                    title: 'Erro de servidor',
                    text: obj.message
                  });
                });
              }
            })
            .catch(e => {
              console.error(e);
              this.setState({
                exporting: false,
                loading: false
              });
            });
        } else {
          this.setState({
            exporting: false,
            loading: false
          });
          if (result?.data != 'ROOM_PLAN_NOT_VALIDATED') {
            return Alerts.new({
              type: 'error',
              title: 'Erro de servidor',
              text: result?.data || 'Tente novamente mais tarde.'
            });
          }
        }

      } catch (error) {
        this.setState({
          exporting: false,
          loading: false
        });
        return Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'O editor ainda está a carregar. Aguarde e tente novamente.'
        });
      }
    } else {
      console.error('editor not found');
    }
  }

  uploadRoomPlanToDrive = async () => {
    const { wedding } = this.props;

    if (this.roomEditor) {
      this.setState({
        exporting: true,
        loading: true
      });

      const resultAuthDrive = await AuthGoogleDrive();
      // console.warn('resultAuthDrive', resultAuthDrive);

      if (!resultAuthDrive?.data?.active) {
        localStorage.setItem('googleReturnUrl', window.location.pathname);
        const newWindow = window.open(resultAuthDrive?.data.authUrl, '_self', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null;
        this.setState({
          exporting: false,
          loading: false
        });
        return;
      }

      try {
        const image = await this.roomEditor.getImage();
        const result = await SaveRoomPlanImage(wedding.wedding._id, { image });

        if (result?.success) {
          const resultUpload = await UploadRoomPlanToDrive(wedding.wedding._id, JSON.stringify({}));
          // console.warn('resultUpload', resultUpload);

          if (resultUpload?.success) {
            this.setState({
              exporting: false,
              loading: false
            });
            return Alerts.new({
              type: 'success',
              title: 'Sucesso',
              text: 'Upload com sucesso para o Google Drive!'
            });
          } else {
            this.setState({
              exporting: false,
              loading: false
            });
          }
        } else {
          this.setState({
            exporting: false,
            loading: false
          });
          if (result?.data != 'ROOM_PLAN_NOT_VALIDATED') {
            return Alerts.new({
              type: 'error',
              title: 'Erro de servidor',
              text: result?.data || 'Tente novamente mais tarde.'
            });
          }
        }
      } catch (error) {
        this.setState({
          exporting: false,
          loading: false
        });
        return Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'O editor ainda está a carregar. Aguarde e tente novamente.'
        });
      }
    } else {
      console.error('editor not found');
    }
  }

  exportGuest = async () => {
    const { wedding } = this.props;
    // const result = DownloadGuestPlan(wedding.wedding._id);
    this.setState({
      exporting: true,
      loading: true
    });
    const authToken = localStorage.getItem(auth_token_key);
    let filename = 'Distribuição de Convidados';

    fetch(
      `${REACT_APP_API}/reports/process/${wedding.wedding._id}/guestPlan?filter=${JSON.stringify({})}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then(resp => {
        if (resp.status == 200) {
          const header = resp.headers.get('Content-Disposition');
          filename = header ? header.split('=')[1] : '';
          // Decode filename
          filename = decodeURI(filename)

          return resp.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            // Correct name for file
            let name = filename.replace(/#|"/g, '').replace(/_/g, ' ')?.trim().replace('.pdf', '');
            name.concat('.pdf');

            a.setAttribute('download', name);
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            this.setState({
              exporting: false,
              loading: false
            });
          });
        } else {
          resp.json().then(obj => {
            this.setState({
              exporting: false,
              loading: false
            });
            return Alerts.new({
              type: 'error',
              title: 'Erro de servidor',
              text: obj.message
            });
          });
        }
      })
      .catch(e => {
        console.error(e);
        this.setState({
          exporting: false,
          loading: false
        });
      });
  }

  exportFlyers = async () => {
    const { wedding } = this.props;
    this.setState({
      exporting: true,
      loading: true
    });
    // const result = DownloadGuestFlyers(wedding.wedding._id);
    const authToken = localStorage.getItem(auth_token_key);
    let filename = 'Folhetos de Mesa';

    fetch(
      `${REACT_APP_API}/reports/process/${wedding.wedding._id}/guestFlyers?filter=${JSON.stringify({})}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then(resp => {
        if (resp.status == 200) {
          const header = resp.headers.get('Content-Disposition');
          filename = header ? header.split('=')[1] : '';
          if (filename.split(';')?.length > 0) {
            filename = filename.split(';')[0];
          }
          // Decode filename
          filename = decodeURI(filename);

          return resp.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            // Correct name for file
            let name = filename.replace(/#|"/g, '').replace('.xls', '');
            name.concat('.xls');

            a.setAttribute('download', name);
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            this.setState({
              exporting: false,
              loading: false
            });
          });
        } else {
          resp.json().then(obj => {
            this.setState({
              exporting: false,
              loading: false
            });
            return Alerts.new({
              type: 'error',
              title: 'Erro de servidor',
              text: obj.message
            });
          });
        }
      })
      .catch(e => {
        console.error(e);
        this.setState({
          exporting: false,
          loading: false
        });
      });
  }

  // uploadFlyersToDrive = async () => {
  //   const { wedding } = this.props;
  //   this.setState({
  //     exporting: true,
  //     loading: true
  //   });

  //   const resultAuthDrive = await AuthGoogleDrive();
  //   // console.warn('resultAuthDrive', resultAuthDrive);

  //   if (!resultAuthDrive?.data?.active) {
  //     localStorage.setItem('googleReturnUrl', window.location.pathname);
  //     const newWindow = window.open(resultAuthDrive?.data.authUrl, '_self', 'noopener,noreferrer')
  //     if (newWindow) newWindow.opener = null;
  //     this.setState({
  //       exporting: false,
  //       loading: false
  //     });
  //     return;
  //   }

  //   const resultUpload = await UploadGuestFlyersToDrive(wedding.wedding._id, JSON.stringify({}));
  //   // console.warn('resultUpload', resultUpload);

  //   if (resultUpload?.success) {
  //     this.setState({
  //       exporting: false,
  //       loading: false
  //     });
  //     return Alerts.new({
  //       type: 'success',
  //       title: 'Sucesso',
  //       text: 'Upload com sucesso para o Google Drive!'
  //     });
  //   } else {
  //     this.setState({
  //       exporting: false,
  //       loading: false
  //     });
  //   }
  // }

  onSelectExportMenu = (value) => {
    const itemSelected = parseInt(value.key);

    // Export room plan
    if (itemSelected === 1) this.exportRoomPlan();
    // Upload room plan
    else if (itemSelected === 2) this.uploadRoomPlanToDrive();
    // Export guest map
    else if (itemSelected === 3) this.exportGuest();
    // Export flyers
    else if (itemSelected === 4) this.exportFlyers();
    // Upload flyers
    // else if (itemSelected === 5) this.uploadFlyersToDrive();
  }

  exportMenu = (
    <Menu onClick={this.onSelectExportMenu}>
      <Menu.Item key={1}>
        <Icon type="download" />
        Dist. Convidados + Plano
      </Menu.Item>
      <Menu.Item key={2}>
        <Icon type="cloud-upload" />
        Dist. Convidados + Plano
      </Menu.Item>
      <Menu.Item key={3}>
        <Icon type="download" />
        Dist. Convidados
      </Menu.Item>
      <Menu.Item key={4}>
        <Icon type="download" />
        Folhetos de Mesa
      </Menu.Item>
      {/* <Menu.Item key={5}>
        <Icon type="cloud-upload" />
        Folhetos de Mesa
      </Menu.Item> */}
    </Menu>
  );

  // Guest map
  goToGuestMap = () => {
    const { weddingId } = this.state;
    this.props.history.push(`/wedding/${weddingId}/guestMap`);
  };

  // Custom select
  costumeSelectPlace = (value) => {
    return <div>
      <Icon type="environment" />
      <span style={{ marginLeft: 5 }}>{value?.name || ''}</span>
    </div>
  }
  costumeSelectRoomPlan = (value) => {
    return <div className='svg-input'>
      <ReactSVG className='svg-icon' src={NormalPlanIcon} />
      <span style={{ marginLeft: 5 }}>{value?.name || ''}</span>
    </div>
  }

  // Base Plan
  // changeLocalBaseRoomPlan = (value) => {
  //   const { dataBasePlan } = this.state;
  //   this.props.history.push(`/settings/room-plan/${dataBasePlan.type}/${value}`);
  // }

  changeBaseRoomPlan = (event) => {
    const { dataBasePlan } = this.state;
    this.props.history.push(`/settings/room-plan/${event}`);
  }

  handleDrop = async (id, accepted) => {
    if (accepted[0]) {
      this.setState({ loadingBg: true });
      const payload = new FormData();
      payload.append('file', accepted[0]);
      const { success, data } = await UploadBackgroundRoomPlan(id, payload);
      if (success) {
        Alerts.new({
          type: 'success',
          title: 'Sucesso',
          text: 'Fundo alterado!'
        });
        this.setState({ loadingBg: false }, () => this.getInformation());
      } else {
        this.setState({ loadingBg: false });
      }
    }
  };

  saveBaseRoomPlan = async () => {
    const { roomPlanConfig } = this.state;

    if (roomPlanConfig?.deleted) return;

    const valid = this.roomEditor?.valid();
    console.warn('valid', valid);
    if (valid?.status == 'INVALID') {
      return Alerts.new({
        type: 'warning',
        title: 'Atenção',
        text: 'Plano base inválido! Verifique as mesas!'
      });
    }

    this.setState({
      saving: true,
      loading: true
    });

    const values = this.roomEditor.serializeEditor();

    // Check that center of roomPlan is defined and valid
    if (!values?.roomPlan?.horizontalCenterOfRoom || typeof values?.roomPlan?.horizontalCenterOfRoom == 'object'
      || values?.roomPlan?.horizontalCenterOfRoom == 0) {
      Alerts.new({
        type: 'warning',
        title: 'Atenção',
        text: 'Defina o centro da sala!'
      });
      this.setState({
        saving: false,
        loading: false
      });
      return;
    }

    if (!values?.tables || values?.tables?.length == 0 || (values?.tables?.length > 0 && !values?.tables?.find(f => f?.tablePurpose == TablePurposeEnum.COUPLE))) {
      Alerts.new({
        type: 'warning',
        title: 'Atenção',
        text: 'Falta a mesa de casal!'
      });
      this.setState({
        saving: false,
        loading: false
      });
      return;
    }

    const data = {
      ...values,
      version: roomPlanConfig?.version
    };
    const result = await UpdateEditorRoomPlanConfig(roomPlanConfig?._id, data);
    // console.warn('reuslt', result);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Plano de sala alterado!'
      });
      this.setState({
        saving: false,
        loading: false,
      }, () => this.getInformation());
    } else {
      this.setState({
        saving: false,
        loading: false
      }, () => {
        if (result?.message == 'NOT_AUTHORIZED' || result?.message == 'OLD_ROOM_PLAN_VERSION') {
          this.getInformation();
        }
      });
    }
  }

  render() {
    const { wedding } = this.props;
    const { changedPlan } = this.state;
    const { loading, exporting, changingPlan, validating, activating, saving, submitting } = this.state;
    const { ready, loadingBg } = this.state;
    const { ourGuest, roomPlanOptions } = this.state;
    const { basePlan, roomPlanConfig, config } = this.state;
    const { showModal, coupleTableQuestion, savingCoupleTable } = this.state;
    const { blockReroute, checkedChanges } = this.state;
    // loading || exporting || changingPlan || validating || activating || saving || submitting

    return (
      <React.Fragment>
        <XpertGoLeavePageBlocker
          when={changedPlan || blockReroute}
          checkedChanges={checkedChanges}
          onAttemptedRouteChange={this.checkHasChangesToSave}
          message={'Pretende sair sem guardar as alterações?'}
        />
        {basePlan
          ? <Row gutter={12}>
            <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
              <Toolbar>
                <div className='toolbar-input' style={{ width: 150 }}>
                  <SelectInput
                    // label="Tamanho:"
                    horizontal={true}
                    allowClear={false}
                    placeholder="Escolher tamanho"
                    data={roomPlanOptions}
                    disabled={loading || !ready || changingPlan}
                    input={{
                      value: roomPlanConfig?._id,
                      onChange: value => this.changeBaseRoomPlan(value)
                    }}
                    costumeLabel={this.costumeSelectRoomPlan}
                    meta={{ valid: true }}
                  />
                </div>
                {/* {!roomPlanConfig?.deleted && <React.Fragment>
                  <div className='toolbar-input zoom-icons'>
                    <Icon type="zoom-out" />
                    <Icon type="zoom-in" />
                  </div>
                  <div className='toolbar-input' style={{ width: 75 }}>
                    <SelectInput
                      noBorder={true}
                      allowClear={false}
                      placeholder="Escolher o zoom"
                      data={zoomOptions}
                      disabled={loading}
                      input={{
                        value: zoom,
                        onChange: value => this.changeZoom(value)
                      }}
                      meta={{ valid: true }}
                    />
                  </div>
                </React.Fragment>} */}
              </Toolbar>
            </Col>
            <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
              <Toolbar right={true}>
                {/* <div className='toolbar-input'>
                  <SelectInput
                    // label="Local:"
                    horizontal={true}
                    allowClear={false}
                    placeholder="Escolher local"
                    data={locals}
                    // disabled={loading || !ready}
                    disabled={true}
                    input={{
                      value: roomPlanConfig?.weddingPlace,
                      onChange: value => this.changeLocalBaseRoomPlan(value)
                    }}
                    costumeLabel={this.costumeSelectPlace}
                    meta={{ valid: true }}
                  />
                </div> */}
                {roomPlanConfig?._id && !roomPlanConfig?.deleted && <div className='toolbar-input'>
                  <ButtonBgUpdaload
                    type="default"
                    loading={loadingBg}
                    disabled={loading || loadingBg}>
                    <Dropzone
                      disabled={loadingBg}
                      onDrop={(accepted, rejected) =>
                        this.handleDrop(roomPlanConfig._id, accepted, rejected)
                      }>
                      {({ getRootProps, getInputProps }) => (
                        <div style={{ display: 'flex', alignItems: 'center' }} {...getRootProps()}>
                          <input {...getInputProps()} />
                          {!loadingBg && <i className="fa-regular fa-image"></i>}
                          <span style={{ marginLeft: 5 }}>{roomPlanConfig?.bgRoomPlan ? 'Alterar fundo' : 'Adicionar fundo'}</span>
                        </div>
                      )}
                    </Dropzone>
                  </ButtonBgUpdaload>
                </div>}
                <div className='toolbar-input'>
                  <BaseButton
                    type={'primary'}
                    icon={'save'}
                    text={'Guardar'}
                    disabled={roomPlanConfig?.deleted || loading || saving}
                    onClick={this.saveBaseRoomPlan}
                  />
                </div>
              </Toolbar>
            </Col>
          </Row>
          : <Row gutter={12}>
            <Col xs={24} md={24} lg={16} xl={12} xxl={12}>
              <Toolbar>
                {/* <div className='toolbar-input' style={{ width: 200 }}>
                  <TextInput
                    input={{
                      value: search,
                      onChange: event => this.onInputChange('search', event.target.value)
                    }}
                    prefix={<Icon type={searching ? 'loading' : "search"} style={{ color: 'rgba(0,0,0,.25)' }} />}
                    meta={{ valid: true }}
                    noLabel={true}
                    search={true}
                    type="text"
                    placeholder="Procurar convidado"
                  />
                </div> */}
                <div className='toolbar-input'>
                  <Dropdown
                    disabled={loading || exporting || changingPlan || validating || activating || saving || submitting}
                    overlay={this.exportMenu}
                    trigger={['click']}>
                    <Button type='default'>
                      {exporting ? <Icon type="loading" /> : <Icon type="file-protect" />}
                      Exportar
                      <Icon type="down" />
                    </Button>
                  </Dropdown>
                </div>
                <div className='toolbar-input' style={{ width: 170 }}>
                  <SelectInput
                    // label="Tamanho:"
                    horizontal={true}
                    allowClear={false}
                    placeholder="Escolher tamanho"
                    data={roomPlanOptions}
                    disabled={loading || exporting || changingPlan || validating || activating || saving || submitting}
                    input={{
                      value: ourGuest?.roomPlanTemplate,
                      onChange: value => this.confirmChangeRoomPlan(value)
                    }}
                    costumeLabel={this.costumeSelectRoomPlan}
                    meta={{ valid: true }}
                  />
                </div>
                {/* <div className='zoom-icons toolbar-input'>
                  <Icon type="zoom-out" />
                  <Icon type="zoom-in" />
                </div>
                <div className='toolbar-input' style={{ width: 75 }}>
                  <SelectInput
                    noBorder={true}
                    allowClear={false}
                    placeholder="Escolher o zoom"
                    data={zoomOptions}
                    disabled={loading}
                    input={{
                      value: zoom,
                      onChange: value => this.changeZoom(value)
                    }}
                    meta={{ valid: true }}
                  />
                </div>*/}
              </Toolbar>
            </Col>
            <Col xs={24} md={24} lg={8} xl={12} xxl={12}>
              <Toolbar right={true}>
                <div className='toolbar-input'>
                  <BaseButton
                    type={'default'}
                    text={'Distribuição de Convidados'}
                    onClick={() => this.goToGuestMap()}
                  />
                </div>
                {!ourGuest?.roomPlanActive && <div className='toolbar-input'>
                  <BaseButton
                    type={'default'}
                    icon={'eye'}
                    text={'Ativar'}
                    loading={activating}
                    disabled={!ourGuest || loading || exporting || changingPlan || validating || saving || submitting}
                    onClick={() => this.confirmActivateRoomPlan()}
                  />
                </div>}
                <div className='toolbar-input'>
                  <BaseButton
                    type={'default'}
                    icon={'mail'}
                    text={ourGuest?.submittedRoomPlan ? 'Remover comunicar lista' : 'Comunicar lista'}
                    loading={submitting}
                    disabled={loading || exporting || changingPlan || validating || activating || saving}
                    onClick={this.confirmSubmit}
                  />
                </div>
                <div className='toolbar-input'>
                  {/* <BaseButton
                    type={ourGuest?.validatedRoomPlan ? 'danger' : 'success'}
                    icon={ourGuest?.validatedRoomPlan ? 'close' : 'check'}
                    filled={true}
                    text={ourGuest?.validatedRoomPlan ? 'Reprovar' : 'Aprovar'}
                    loading={validating}
                    disabled={loading || saving || exporting}
                    onClick={this.confirmValidatingRoomPlan}
                  /> */}
                  <BaseButton
                    type={ourGuest?.validatedRoomPlan ? 'danger' : 'default'}
                    disabled={loading || exporting || changingPlan || activating || saving || submitting}
                    onClick={this.confirmValidatingRoomPlan}
                    ghost={ourGuest?.validatedRoomPlan ? true : false}
                    text={
                      <div className='svg-input'>
                        {validating ? <Icon type="loading" /> : <ReactSVG className='svg-icon' src={ourGuest?.validatedRoomPlan ? PencilBlockedIcon : PencilIcon} />}
                        <span style={{ marginLeft: 4 }}>{ourGuest?.validatedRoomPlan ? 'Desbloquear' : 'Bloquear'}</span>
                      </div>
                    }
                  />
                </div>
                <div className='toolbar-input'>
                  <BaseButton
                    type={'primary'}
                    icon={'save'}
                    text={'Guardar'}
                    loading={saving}
                    disabled={loading || exporting || changingPlan || validating || activating || submitting}
                    onClick={this.confirmSaveRoomPlan}
                  />
                </div>
              </Toolbar>
            </Col>
          </Row>}
        <Row gutter={12} style={{ marginTop: 10 }}>
          <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
            <RoomEditorContainer>
              {(loading || !ready) && <div className='backdrop'>
                <SpinLoading style={{ position: 'absolute', minHeight: 30 }} />
              </div>}
              {(basePlan && !roomPlanConfig?.bgRoomPlan) && <div className='backdrop'>
                <ImageBgWarning>Adicione uma imagem de fundo para o plano</ImageBgWarning>
              </div>}
              <RoomEditor id='editor'></RoomEditor>
            </RoomEditorContainer>
            {/* <Legend>
              <LegendItem><ColorBox color={'#D1BB80'} /> Casal</LegendItem>
              <LegendItem><ColorBox color={'#95B1B0'} /> Staff</LegendItem>
              <LegendItem><ColorBox color={'#AEE4F5'} /> Crianças</LegendItem>
              <LegendItem><ColorBox color={'#d1cece'} /> Convidados</LegendItem>
            </Legend> */}
          </Col>
        </Row>

        {
          showModal &&
          <SectionModal
            open={showModal}
            loading={savingCoupleTable}
            onSubmit={this.onModalSubmit}
            closeModal={() => this.setState({ showModal: false })}
            question={coupleTableQuestion}
          />
        }
      </React.Fragment >
    );
  }
}

const mapStateToProps = state => ({
  structureInfo: state.wedding.structure,
  wedding: state.wedding,
  configs: state.info.configs
});

const mapActionToProps = dispatch =>
  bindActionCreators({ updateBackgroundRoomPlan, updateQuestionAction, dispatch }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(RoomPlanEditor));
