import styled from 'styled-components';
import { PrimaryColour, LightGrey, WarnColor } from '../../styles/Colours';
import { device, size } from '../../styles/Responsive';

// .month {
//   margin: 5px 10px 15px 10px;
// }

export const FrameToExport = styled.div`
  height: 100%;
  text-align: center;

  .yearTitle {
    font-size: 60px;
    font-weight: bolder;
    font-family: sans-serif;
    line-height: 60px;
    margin-bottom: 5px;
  }

  .title {
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 35px;
    letter-spacing: 3px;
  }

  .subtitle {
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 25px;
    letter-spacing: 1px;
    margin-bottom: 40px;
  }
`;

export const RadioPlace = styled.div`
  @media (max-width: 767px) {
      text-align: center;
  }

  @media (max-width: ${size.mobileM}) {
    text-align: center;
    justify-content: center;
  }

  @media (min-width: ${size.tablet}) and (max-width: ${size.laptopL}) {
    text-align: start;
  }

  @media (min-width: ${size.laptopL}) {
    text-align: start;
  }
`;

export const RadioYear = styled.div`
  @media (max-width: 767px) { 
    text-align: center;

    .iconPrevious, .iconNext {
      display: none;
    }
  }

  @media (max-width: ${size.mobileM}) {
    text-align: center;

    .iconPrevious, .iconNext {
      display: inherit;
    }

    .spanPrevious, .spanNext {
      display: none;
    }
  }

  @media (min-width: ${size.tablet}) and (max-width: ${size.laptopL}) {
    text-align: center;

    .iconPrevious, .iconNext {
      display: inherit;
    }
  
    .spanPrevious, .spanNext {
      display: none;
    }
  }

  @media (min-width: ${size.laptopL}) {
    text-align: center;

    .iconPrevious, .iconNext {
      display: none;
    }

    .spanPrevious, .spanNext {
      display: inherit;
    }
  }
`;

export const CalendarPicker = styled.div`
  @media (max-width: 767px) {
    width: 200px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  @media (max-width: ${size.mobileM}) {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  @media (min-width: ${size.tablet}) and (max-width: ${size.laptopL}) {
    width: 100%;
    text-align: end;
    display: flex;
    justify-content: flex-end;
  }

  @media (min-width: ${size.laptopL}) {
    width: 50%;
    margin: 0 0 0 auto;
    text-align: end;
    display: flex;
    justify-content: flex-end;
  }

  @media (min-width: ${size.desktopL}) {
    width: 50%;
  }
`;

export const LinkAcceptPolicy = styled.div`
  cursor: pointer !important;
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.65);
  text-align: initial;
`;

export const CalendarBox = styled.div`
  margin: ${({ exports }) => exports ? '0' : '20px auto 0 auto'};

  .backdrop {
    z-index: 9;
    background-color: black;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.4;
    border-radius: 5px;
    cursor: not-allowed;
  }
  
  .year {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
  }
  
  .month-name {
    color: #bc9222;
    font-weight: bold;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
  }

  .month-name-weddings {
    color: #bc9222;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    margin-top: ${({exports}) => exports ? '0' : '10px'};
    margin-bottom: 5px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
  }

  .month-days {
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 1px 0;
  }

  .waiting-weekday {
    width: 35px;
    height: auto;
    background: white;
    border-radius: 4px;
    border: none;
    outline: none;
    color: black;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    line-height: 18px;
  }

  .waiting-weekday img {
    padding-top: 2px;
  }

  .waiting-weekday span {
    text-align: center;
    color: #5a5a5a;
    font-size: 15px;
    font-weight: 600;
  }

  .waiting-weekday:hover {
    cursor: pointer;
    background: #dfdace;
  }

  .weekdays {
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: center;
  }
  
  .weekday {
    display: inline-block;
    width: 35px;
    height: auto;
    text-align: center;
    line-height: 30px;
    color: #5a5a5a;
    font-size: 15px;
    font-weight: 600;
    margin: 1px;
  }

  .weekday-weddings {
    display: inline-block;
    width: ${({exports}) => exports ? '32px' : '37px'};
    height: auto;
    text-align: center;
    line-height: ${({exports}) => exports ? '15px' : '30px'};
    color: #5a5a5a;
    font-size: ${({ exports }) => exports ? '13px' : '15px'};
    font-weight: bold;
    padding: 1px;
  }
  
  .priceWeekDay {
    display: inline-block;
    width: 70px;
    height: 35px;
    text-align: center;
    line-height: 30px;
    color: #5a5a5a;
    font-size: 15px;
    font-weight: 600;
  }
  
  .date, .waitingDate {
    width: ${({ exports }) => exports ? '30px' : '35px'};
    height: ${({ exports }) => exports ? '18px' : '25px'};
    background: white;
    border-radius: 4px;
    border: none;
    outline: none;
    color: black;
    font-size: ${({ exports }) => exports ? '13px' : '15px'};
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin: 1px;
  }

  .date .date-round {
    border-radius: 50%;
    background: #bc9222 !important;
    color: #fff !important;
    width: ${({exports}) => exports ? '30px' : '35px'};
    height: ${({exports}) => exports ? '18px' : '21px'};
    font-size: ${({ exports }) => exports ? '11px' : '13px'};
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .date .waiting-date .ant-scroll-number-custom-component {
    top: -1px !important;
    -webkit-transform: translate(70%, -60%) !important;
    width: 13px;
    height: 12px;
  }

  .date .interest-date .ant-scroll-number-custom-component {
    top: 1px !important;
    -webkit-transform: translate(80%, -60%) !important;
    width: 13px;
    height: 12px;
  }

  .waitingDate:hover {
    cursor: pointer;
    background: #ccbe88;
  }

  .price {
    width: 70px;
    height: 25px;
    background: white;
    border: 1px solid #d3d3d3;
    outline: none;
    color: black;
    font-size: 15px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .noPrice {
    background: rgb(255 107 115 / 65%);
    color: white;
  }

  .price.prev-month, .price.next-month {
    border: unset;
  }
  
  .selected {
    background: #ccbe88 !important;
    color: #fff !important;
  }

  .edit {
    background: #bc9222 !important;
    color: #fff !important;
  }

  .date.in-month:hover {
    cursor: pointer;
    background: #ccbe88;
  }
  
  .date.disabled:hover {
    cursor: default !important;
    background: unset !important;
  }

  .today {
    background: #d3d3d3;
  }

  .occupied {
    color: #bc9222;
    text-decoration: line-through;
    cursor: pointer;
  }
  
  .date.prev-month,
  .date.next-month {
    color: lightgray;
  }

  .spanPrevious .anticon, .spanNext .anticon {
    padding: 0 5px;
  }

  @media (max-width: 767px) {
    .price {
      width: 50px;
    }

    .price, .price b, .price span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;