import moment from "moment";
import { timeFormat } from "../../../infra/helpers/Helpers";

export const checkTimeChangeInRangeDates = (values) => {
    const startDate = moment.utc(values.startDate).startOf('day');
    const endDate = moment.utc(values.endDate).startOf('day');

    const isDSTDifferent = startDate.isDST() !== endDate.isDST();

    if (isDSTDifferent) {
        const diffDays = endDate.diff(startDate, 'days');
        let dateChangedDST = null;

        for (let index = 0; index <= diffDays; index++) {
            const dateToCheck = index === 0
                ? moment.utc(startDate).startOf('day')
                : moment.utc(startDate).add(index, 'days').startOf('day');

            const oldDateToCheck = (index - 1) < 0
                ? null
                : (index - 1) === 0
                    ? moment.utc(startDate).startOf('day')
                    : moment.utc(startDate).add((index - 1), 'days').startOf('day');

            if (oldDateToCheck) {
                dateChangedDST = getDateDSTChanged(dateToCheck, oldDateToCheck, values.time);
                if (dateChangedDST) break;
            }
        }

        if (dateChangedDST) {
            const previowsDataChangedDST = dateChangedDST.subtract(1, 'day').startOf('day');
            const values1 = {
                startDate: startDate,
                endDate: previowsDataChangedDST,
                time: values?.time
            }

            const values2 = {
                startDate: previowsDataChangedDST,
                endDate: endDate,
                time: values?.time
            }
        }
    }
}

const getDateDSTChanged = (startDt, endDt, timeUtc) => {
    const startDate = moment.utc(timeUtc, timeFormat)
        .set('year', startDt.year())
        .set('month', startDt.month())
        .set('date', startDt.date());
    const endDate = moment.utc(timeUtc, timeFormat)
        .set('year', endDt.year())
        .set('month', endDt.month())
        .set('date', endDt.date());

    return startDate.isDST() !== endDate.isDST()
        ? endDate
        : null;
}
export const checkDSTChanged = (startDt, endDt, timeUtc) => {
    const startDate = moment(timeUtc, timeFormat)
        .set('year', startDt.year())
        .set('month', startDt.month())
        .set('date', startDt.date()).utc();
    const endDate = moment(timeUtc, timeFormat)
        .set('year', endDt.year())
        .set('month', endDt.month())
        .set('date', endDt.date()).utc();

    return isDST(startDate) !== isDST(endDate) ? true : false;
}

export const getDSTOffset = () => {
    const now = new Date();

    // Verifica se o horário de verão está em vigor atualmente
    const isDST = now.getTimezoneOffset() < Math.max(
        new Date(now.getFullYear(), 0, 1).getTimezoneOffset(),
        new Date(now.getFullYear(), 6, 1).getTimezoneOffset()
    );

    // Calcular o horário de verão e o horário de inverno
    const winterOffset = Math.max(
        new Date(now.getFullYear(), 0, 1).getTimezoneOffset(),
        new Date(now.getFullYear(), 6, 1).getTimezoneOffset()
    );

    const summerOffset = Math.min(
        new Date(now.getFullYear(), 0, 1).getTimezoneOffset(),
        new Date(now.getFullYear(), 6, 1).getTimezoneOffset()
    );

    const offsetDifference = (winterOffset - summerOffset) / 60;

    return offsetDifference
}

export const isDST = (dateTime) => {
    const date = new Date(dateTime);
    // Get the timezone offset for the given date
    const currentOffset = date.getTimezoneOffset();

    // Create a date for January 1st of the same year to get the standard time offset
    const standardDate = new Date(date.getFullYear(), 0, 1);
    const standardOffset = standardDate.getTimezoneOffset();

    // If the current offset is different from the standard offset, it means the date is in DST
    return currentOffset < standardOffset;
}