import styledComponents from 'styled-components';

export const DateColumn = styledComponents.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  .date {
    font-size: inherit;
    font-weigth: bold;
  }

  .weekdayName {
    font-size: 11px;
    color: light-gray;
  }
`;

export const SpanNotes = styledComponents.div`
  width: 100%;
  height: 110px;
  max-height: 110px;
  justify-content: center;
  overflow: auto;

  p {
    margin-bottom: 0;
  }
`;

export const SpanDate = styledComponents.div`
  width: 100%;
  height: 110px;
  max-height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpanAttended = styledComponents.div`
  width: 100%;
  height: 110px;
  max-height: 110px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

export const SchedulesSummary = styledComponents.div`
  line-height: 12px;
  height: 200px;
  width: 100%;
  overflow: auto;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid ${p => p.theme.inputBorderColor};
  border-radius: 4px;
  padding: 4px 11px;
  background-color: #f5f5f5;

  &:focus-within,
  &:hover {
    border: 1px solid ${p => p.theme.inputFocusColor};
  }
`;