import React from 'react';
import {Modal, Button} from 'antd';
import {
  StaffContainer,
  StaffContent,
  StaffInfo,
  StaffLabel,
  StaffValue
} from '../ProcessStyles';

let StaffModalComponent = ({
  open,
  closeModal,
  staff
}) => {
  return (
    <Modal
      visible={open}
      title="Staff Externo"
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Fechar
        </Button>
      ]}>
      <StaffContainer>
        {
          staff?.length > 0 ?
          staff.map((elem, index) =>
            <StaffContent key={index}>
              <StaffInfo>
                <StaffLabel>Categoria:</StaffLabel>
                <StaffValue>{elem.category}</StaffValue>
              </StaffInfo>
              <StaffInfo>
                <StaffLabel>Nome:</StaffLabel>
                <StaffValue>{elem.name}</StaffValue>
              </StaffInfo>
              {
                elem.email &&
                <StaffInfo>
                  <StaffLabel>Email:</StaffLabel>
                  <StaffValue>{elem.email}</StaffValue>
                </StaffInfo>
              }
              {
                elem.contact &&
                <StaffInfo>
                  <StaffLabel>Telefone:</StaffLabel>
                  <StaffValue>{elem.contact}</StaffValue>
                </StaffInfo>
              }
              {
                elem.observations &&
                <StaffInfo>
                  <StaffLabel>Observações:</StaffLabel>
                  <StaffValue>{elem.observations}</StaffValue>
                </StaffInfo>
              }
            </StaffContent>
          )
          :
          <div>Não existem registos para apresentar!</div>
        }
      </StaffContainer>
    </Modal>
  );
};

export default StaffModalComponent;