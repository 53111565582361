import React, { useEffect } from 'react';
import { Modal, Row, Icon, Col, Popconfirm } from 'antd';
import NumberInput from '../../components/inputs/NumberInput';
import TextInput from '../../components/inputs/TextInput';
import { TableButton } from '../../styles/BasicStyles';
import { getUserId } from '../../infra/helpers/Helpers';
import { CreateDEYear, DeleteDEYear, GetAllDEYearByType, UpdateDEYear } from '../../infra/requests/DynamicEmailYearRequests';
import Table from '../../components/table/Table';
import BaseButton from '../../components/buttons/BaseButton';
import Alerts from '../../components/alert/Alert';

const emailType = [
    { _id: 'NR', name: 'Reserva - Novos Utilizadores' }, // NR: new user reserve
    { _id: 'AR', name: 'Reserva - Agendamento' }, // AR: agending reserve
    { _id: 'CR', name: 'Reserva - Confirmação' }, // CR: confirmation reserve
    { _id: 'RDO', name: 'Reserva - Data Ocupada' }, // OD: reserve date occupied
    { _id: 'RR', name: 'Reserva - Reativação' },// RR: reserve reactivation
    { _id: 'PP', name: 'Fases de Pagamento' }, // PP: payment phases
    { _id: 'AB', name: 'Visitas - Agendamento' }, // AB: agending bookings
];

const RemoveMessage = (
    <div>
        <div>Tem a certeza que quer remover este ano? </div>
        <div>
            Toda a informação desse ano será removida da plataforma e não
            conseguirá ser recuperado.
        </div>
    </div>
);

let ModalYearEmail = (props): React$Element<React$FragmentType> => {

    const [loading, setLoading] = React.useState(false);
    let [rows, setRows] = React.useState([]);
    const [type, setType] = React.useState('');
    const [actual, setActual] = React.useState(true);
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [submitted, setSubmitted] = React.useState(false);

    useEffect(() => {
        setSubmitted(false);
        setSelectedIndex(null);
        setType(props.type);
    }, [props.type]);

    useEffect(() => {
        if (type) updateDataTable();
    }, [type]);

    const updateDataTable = async () => {
        setLoading(true);
        try {
            const result = await GetAllDEYearByType(type, actual);
            // console.warn('Res', result);

            const data = result.data ? result.data : [];
            setRows(data.map((m: any, index: number) => ({ ...m, index })));
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.error(e);
        }
    }

    const columns: any[] = [
        {
            title: 'Ano',
            width: '90px',
            render: data => (
                <React.Fragment>
                    {selectedIndex !== null && selectedIndex == data.index ?
                        <NumberInput
                            min={0}
                            onInput="validity.valid || (value = '')"
                            step={1}
                            input={{
                                value: data.year,
                                onChange: (value) => {
                                    rows[selectedIndex].year = value;
                                    setRows(JSON.parse(JSON.stringify(rows)));
                                }
                            }}
                            meta={{
                                invalid: submitted && (data.year === 0 || data.year === '') ? true : false,
                                submitFailed: submitted && (data.year === 0 || data.year === '') ? true : false,
                            }}
                            type="number"
                            placeholder="Insira o ano"
                        /> : data.year}
                </React.Fragment>
            )
        },
        {
            title: 'Complementos (Português)',
            width: '150px',
            render: data => (
                <React.Fragment>
                    {selectedIndex !== null && selectedIndex == data.index ?
                        <TextInput
                            noLabel={true}
                            input={{
                                value: data.linkComplementsPT,
                                onChange: (e) => {
                                    rows[selectedIndex].linkComplementsPT = e.target.value;
                                    setRows(JSON.parse(JSON.stringify(rows)));
                                }
                            }}
                            meta={{}}
                            requiredError={submitted && data.linkComplementsPT === '' ? true : false}
                            type="text"
                            placeholder="Insira o link do complemento"
                        /> :
                        <a href={data.linkComplementsPT} target="_blank" rel="noopener noreferrer">
                            Português
                        </a>
                    }
                </React.Fragment>
            )
        },
        {
            title: 'Complementos (Inglês)',
            width: '150px',
            render: data => (
                <React.Fragment>
                    {selectedIndex !== null && selectedIndex == data.index ?
                        <TextInput
                            noLabel={true}
                            input={{
                                value: data.linkComplementsEN,
                                onChange: (e) => {
                                    rows[selectedIndex].linkComplementsEN = e.target.value;
                                    setRows(JSON.parse(JSON.stringify(rows)));
                                }
                            }}
                            meta={{}}
                            type="text"
                            placeholder="Insira o link do complemento"
                        /> : data.linkComplementsEN && data.linkComplementsEN !== '' ?
                            <a href={data.linkComplementsPT} target="_blank" rel="noopener noreferrer">
                                Inglês
                            </a> : data.linkComplementsEN
                    }
                </React.Fragment>
            )
        },
        {
            title: 'Complementos (Francês)',
            width: '150px',
            render: data => (
                <React.Fragment>
                    {selectedIndex !== null && selectedIndex == data.index ?
                        <TextInput
                            noLabel={true}
                            input={{
                                value: data.linkComplementsFR,
                                onChange: (e) => {
                                    rows[selectedIndex].linkComplementsFR = e.target.value;
                                    setRows(JSON.parse(JSON.stringify(rows)));
                                }
                            }}
                            meta={{}}
                            type="text"
                            placeholder="Insira o link do complemento"
                        /> : data.linkComplementsFR && data.linkComplementsFR !== '' ?
                            <a href={data.linkComplementsFR} target="_blank" rel="noopener noreferrer">
                                Francês
                            </a> : data.linkComplementsFR}
                </React.Fragment>
            )
        },
        {
            title: 'Complementos (Espanhol)',
            width: '150px',
            render: data => (
                <React.Fragment>
                    {selectedIndex !== null && selectedIndex == data.index ?
                        <TextInput
                            noLabel={true}
                            input={{
                                value: data.linkComplementsES,
                                onChange: (e) => {
                                    rows[selectedIndex].linkComplementsES = e.target.value;
                                    setRows(JSON.parse(JSON.stringify(rows)));
                                }
                            }}
                            meta={{}}
                            type="text"
                            placeholder="Insira o link do complemento"
                        /> : data.linkComplementsES && data.linkComplementsES !== '' ?
                            <a href={data.linkComplementsES} target="_blank" rel="noopener noreferrer">
                                Espanhol
                            </a> : data.linkComplementsES}
                </React.Fragment>
            )
        },
        {
            title: 'Acções',
            width: '120px',
            render: data => (
                <React.Fragment>
                    {/* Edit */}
                    {selectedIndex === null ? <TableButton onClick={e => setSelectedIndex(data.index)}>
                        <Icon type="edit" />
                        {' Editar'}
                    </TableButton> : null}

                    {/* Save */}
                    {selectedIndex !== null && selectedIndex == data.index ?
                        <TableButton onClick={e => editRow()}>
                            <Icon type="save" />
                            {' Guardar'}
                        </TableButton> : null}

                    {/* Cancel */}
                    {selectedIndex !== null && selectedIndex == data.index ?
                        <TableButton onClick={e => setSelectedIndex(null)}>
                            <Icon type="close" />
                            {' Cancelar'}
                        </TableButton> : null}

                    {/* Delete */}
                    {selectedIndex === null ? <TableButton onClick={e => e.stopPropagation()}>
                        <Popconfirm
                            placement="topRight"
                            title={RemoveMessage}
                            okText="Remover"
                            onConfirm={() => deleteRow(data)}>
                            <Icon type="delete" />
                            {' Remover'}
                        </Popconfirm>
                    </TableButton> : null}
                </React.Fragment >
            )
        }
    ];

    const addRow = async () => {
        const data = {
            id: null,
            _id: null,
            index: rows.length,
            type: type,
            year: 0,
            linkComplementsPT: '',
            linkComplementsEN: '',
            linkComplementsFR: '',
            linkComplementsES: '',
        };
        setSelectedIndex(data.index);
        rows.push(data);
        setRows(JSON.parse(JSON.stringify(rows)));
    }

    const editRow = async (close: boolean = false) => {
        setSubmitted(true);
        const row = rows[selectedIndex];
        if (close === false && ((row.year === '' || row.year === 0) || row.linkComplementsPT === '')) {
            Alerts.new({
                type: 'error',
                title: 'Atenção!',
                text: 'Dados em falta!'
            });
            return;
        } else if (close === true && ((row.year === '' || row.year === 0) && row.linkComplementsPT === '')) return;

        row.user = getUserId();
        const result = row.id ? await UpdateDEYear(row) : await CreateDEYear(row);
        // console.warn('Res', result);

        if (result.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso!',
                text: row.id ? 'Ano atualizado!' : 'Ano adicionado!'
            });
            setSelectedIndex(null);
            setSubmitted(false);
            updateDataTable();
        }
    }

    const deleteRow = async (row: any) => {
        if (row.id) {
            const result = await DeleteDEYear(row.id);
            // console.warn('Res', result);

            if (result.success) {
                Alerts.new({
                    type: 'success',
                    title: 'Eliminado',
                    text: 'Ano eliminado'
                });
                updateDataTable();
            }
        } else {
            setRows(JSON.parse(JSON.stringify(rows.splice(row.index, 1))));
        }
    }

    const closeModal = () => {
        if (selectedIndex) {
            editRow(true);
        }
        props.onClose();
    }

    return (
        <Modal
            visible={props.isOpen}
            title={'Gestão dos Anos'}
            centered={true}
            width={1000}
            onCancel={() => closeModal()}
            footer={null}
        >
            <Row gutter={24} style={{ marginBottom: 10 }}>
                <Col lg={24} md={24} sm={24} xs={24} className='setting-col-btn'>
                    <BaseButton
                        type={'primary'}
                        icon={'plus'}
                        text={'Adicionar Ano'}
                        onClick={() => addRow()}
                    />
                </Col>
            </Row>

            <div className='table-responsive'>
                <Table
                    columns={columns}
                    loading={loading}
                    rows={rows}
                    showHeader={true}
                    emptyIcon="setting"
                    emptyText={'Não existem emails'}
                    rowKey={'index'}
                    hasPagination={false} />
            </div>
        </Modal >
    );
};

export default ModalYearEmail;