import client from '../config/AxiosConfig';

export const CreateReservePrice = async (data) =>
client.put(`/reservePrice/create`, data);

export const GetReservePriceByDateAndLocal = async (date, local) =>
  client.get(`/reservePrice/findByDateAndLocal/${date}/${local}`);

export const UpdateReservePrice = async (data) =>
  client.post(`/reservePrice/update`, data);

export const UpdateMultipleReservePrice = async (edit, year, local, userId, reserveList) =>
  client.post(`/reservePrice/updateMany`, {edit, year, local, userId, reserveList});

export const DeleteReservePrice = async id =>
  client.delete(`/reservePrice/remove/${id}`);

export const GetReservePriceByMonth = async (filter) =>
  client.post(`/reservePrice/getAllByMonth`, filter);