import React, { Component, Fragment } from "react";
import { reduxForm, Field, initialize, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import BaseButton from "../../components/buttons/BaseButton";
import {
  FormContainer,
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  BaseForm,
  HeaderButtonsContainer,
  SpinLoading,
  DefaultLanguageTab
} from "../../styles/BasicStyles";
import { Tabs, Row, Col, notification, Modal, Radio, Alert, Menu, Icon, Dropdown, Button } from "antd";
import { withLocalize } from "react-localize-redux";
import moment from "moment";
import { ActivateRoomPlan, GetOurGuests, SaveGuests, SaveSectionNotes } from "../../infra/requests/WeddingProcessRequests";
import { SaveGraphicMenu } from "../../redux/wedding/wedding.actions";
import { bindActionCreators } from "redux";
import { DecorContainer, PageContainer } from "../decoration/DecorationStyles";
import CommentsDrawerFixed from "../../components/comments/CommentsDrawerFixed";
import { getPrintedConfirmation } from '../../infra/services/wedding/printedUtils';
import { hasNotes } from "../../infra/services/notes/FindNotes";
import NotesConfirmModal from '../../components/notesModal/NotesModal';
import { SaveNotes } from '../../redux/wedding/wedding.actions';
import ToolbarRoomPlanEditor from "./RoomPlanEditor";
import RoomPlanEditor from "./RoomPlanEditor";
import Alerts from "../../components/alert/Alert";
import PrintedBar from '../dashboard/components/PrintedBar';
import { PrintedBarContent, PrintedMessage } from "../dashboard/ProcessStyles";
import { RoomPlanContentStyle } from "./RoomPlanStyles";
import { inRoomPlanTest } from "../../infra/helpers/Helpers";

const { confirm } = Modal;

const Questions = {
  roomPlanType: '6579de7741f842ab6e10b56c',
  roomPlanActive: '657a0f2e41f842ab6e10b571',
};

class ManageWeddingRoomPlanPage extends Component {
  roomEditor = null;

  state = {
    loading: false,
    ready: false,
    saving: false,
    ourGuest: null,
    section: null,
    currentChapter: {},

    activating: false,
    searchGuest: '',
    searching: false,

    blockReroute: true,
  };

  componentDidMount = async () => {
    const { history } = this.props;
    if (!inRoomPlanTest()) {
      return history.goBack();
    }

    this.getInformation();
  }

  getInformation = async () => {
    const { structureInfo } = this.props;
    const {
      match: { params },
    } = this.props;
    this.setState({ loading: true });

    const { data } = await GetOurGuests(params.wedding);
    const section = structureInfo.find(s => s.tag === 'GUESTS');
    const currentChapter = section.chapters.find(f => f?._id === '65afd1a7b42ba97e4a0db4b3');

    this.setState({
      ready: true,
      loading: false,
      section,
      currentChapter,
      ourGuest: data || {}
    });
  };


  // Notes
  openNotes = () => {
    const { wedding, dispatch } = this.props;
    const { openDrawer } = this.state;
    if (!openDrawer) {
      const notes = wedding.notes.find(note => note.section === 'GUESTS');
      dispatch(initialize('wedding_notes_section_form', notes));
      this.setState({ openDrawer: true });
    }
  };

  closeNotes = () => {
    const { wedding, dispatch } = this.props;
    const notes = wedding.notes.find(note => note.section === 'GUESTS');
    dispatch(initialize('wedding_notes_section_form', notes));
    this.setState({ openDrawer: false, openNotesConfirm: false });
  };

  checkBeforeCloseNotes = (values) => {
    const { wedding, dispatch } = this.props;
    const notes = wedding.notes.find(note => note.section === 'GUESTS');

    //If theres any change to the notes, ask before closing if we want to save the changes
    if (values && (notes?.notes !== values.notes || notes?.private !== values.private)) {
      dispatch(initialize('notes_modal_confirm_form', values));
      this.setState({ openNotesConfirm: true });
    }
    //Otherwise, just the close the notes
    else {
      this.setState({ openDrawer: false });
    }
  }

  saveNotes = async values => {
    const { wedding } = this.props;

    if (wedding?.wedding?.printed) {
      confirm({
        ...getPrintedConfirmation(),
        onOk: () => { this.updateNotes(values); },
        onCancel: () => { this.closeNotes(); },
      });
    } else this.updateNotes(values);
  };

  updateNotes = async values => {
    const { wedding, saveNotesOnReducer, dispatch } = this.props;
    this.setState({ loadNotes: true });
    const { data } = await SaveSectionNotes(wedding.wedding._id, {
      ...values,
      section: 'GUESTS'
    });

    dispatch(initialize('wedding_notes_section_form', data));
    saveNotesOnReducer(data);
    this.setState({ loadNotes: false, openDrawer: false, openNotesConfirm: false });
  };

  handleOnCancel = async () => {
    try {
      const {
        match: { params },
        history
      } = this.props;
      return history.push(`/wedding/${params.wedding}/guests`);
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const {
      match: { params },
    } = this.props;
    const { wedding } = this.props;
    const { openDrawer, loadNotes, openNotesConfirm } = this.state;
    const { loading, ready, saving } = this.state;
    const { ourGuest, currentChapter } = this.state;
    const countBtns = wedding?.wedding?.printed ? 3 : 2;

    if (!ready) return <SpinLoading />;
    return (
      <Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={countBtns}>
            <PageTitle>{currentChapter?.name?.pt || 'O vosso plano de sala'}</PageTitle>
            {wedding?.wedding?.printed && <PrintedMessage>Processo impresso</PrintedMessage>}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={countBtns}>
            {/* {wedding?.wedding?.printed && <PrintedBarContent small={true} btn={true}>
              <div className="row-btn">
                <Icon type="warning" />
                Processo impresso
              </div>
            </PrintedBarContent>} */}
            {ourGuest?.validatedRoomPlan && <PrintedBarContent small={true} btn={true}>
              <div className="row-btn">
                <Icon type="warning" />
                Lista de convidados encerrada
              </div>
            </PrintedBarContent>}
            <BaseButton
              type={'default'}
              icon={'read'}
              text={'Notas'}
              onClick={this.openNotes}
              notes={hasNotes(wedding?.notes, 'GUESTS')}
            />
            <BaseButton
              type="default"
              icon="arrow-left"
              text="Voltar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        {/* {(wedding?.wedding?.printed || true) && <PrintedBar small={true} />} */}
        <DecorContainer printed={false}>
          <RoomPlanContentStyle printed={ourGuest?.validatedRoomPlan} notesOpen={openDrawer}>
            <RoomPlanEditor
              ready={loading}
              weddingId={params.wedding}
              history={this.props.history}
            />
          </RoomPlanContentStyle>
          <CommentsDrawerFixed
            open={openDrawer}
            loading={loadNotes}
            onClose={this.checkBeforeCloseNotes}
            saveNotes={this.saveNotes}
            printed={wedding?.wedding?.printed}
          />
        </DecorContainer>
        <NotesConfirmModal
          open={openNotesConfirm}
          loading={loadNotes}
          onSubmit={this.saveNotes}
          closeModal={() => { this.setState({ openNotesConfirm: false }) }}
          exitWithoutSaving={this.closeNotes}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  structureInfo: state.wedding.structure,
  wedding: state.wedding,
  notes: state?.wedding?.notes,
});

const mapActionToProps = dispatch =>
  bindActionCreators({ saveNotesOnReducer: SaveNotes, dispatch }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageWeddingRoomPlanPage));
