import React, { Component } from 'react';
import { Row, Col, Pagination, message } from 'antd';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import { GetVideos, DeleteVideo } from '../../infra/requests/VideoRequests';
import BaseButton from '../../components/buttons/BaseButton';
import VideoFilters from './VideoFilters';
import VideoComponent from './VideoComponent';

class VideosPage extends Component {
  state = {
    pageSize: 8,
    currentPage: 1,
    loading: false,
    filters: '',
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetVideos(currentPage, pageSize, filters);
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  deleteVideo = async id => {
    try {
      await DeleteVideo(id);
      await this.updateDataTable();
      message.success('Video Eliminado');
    } catch (err) {
      console.error(err);
    }
  };

  handleFilterChange = filters => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const { history } = this.props;
    const { rows, total, currentPage, pageSize } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Portfolio de Videos</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              block
              text={'Criar'}
              onClick={() => history.push('/management/videos/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={1}>
          <Row gutter={[12, 12]}>
            <Col xs={24}>
              <VideoFilters queryChange={this.handleFilterChange} />
            </Col>
            {rows.map(video => (
              <Col key={video._id} xs={24} sm={12}>
                <VideoComponent video={video} deleteVideo={this.deleteVideo} />
              </Col>
            ))}
            <Col xs={24}>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                defaultPageSize={pageSize}
                total={total}
                onChange={this.handleChangePage}
              />
            </Col>
          </Row>
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default VideosPage;
