import client from '../config/AxiosConfig';

export const CreateWeddingTeam = async (data) =>
  client.put(`/weddingTeam/create`, data);

export const GetWeddingTeamByWeddingAndEmployee = async (weddingId, employeeId) =>
  client.get(`/weddingTeam/findAllByWeddingAndEmployee/${weddingId}/${employeeId}`);

export const GetWeddingTeamByWeddingAndEmployeeAndSubcategory = async (weddingId, employeeId, subcategoryId) =>
  client.get(`/weddingTeam/findAllByWeddingAndEmployeeAndSubcategory/${weddingId}/${employeeId}/${subcategoryId}`);

export const GetWeddingTeamByWeddingAndSubcategory = async (weddingId, subcategoryId) =>
  client.get(`/weddingTeam/findAllByWeddingAndSubcategory/${weddingId}/${subcategoryId}`);

export const GetWeddingTeamByWedding = async (weddingId) =>
  client.get(`/weddingTeam/findAllByWedding/${weddingId}`);

export const UpdateWeddingTeam = async (data) =>
  client.post(`/weddingTeam/update`, data);

export const DeleteWeddingTeam = async id =>
  client.delete(`/weddingTeam/remove/${id}`);

export const SaveTeamInfo = async (data) =>
  client.post(`/weddingTeam/saveTeamInfo`, data);

export const PublishWeddingTeam = async (wedding) =>
  client.post(`/weddingTeam/publishByWedding`, wedding);

export const NotifyWeddingTeam = async (data) =>
  client.post(`/weddingTeam/notifyWeddingTeam`, data);

export const ConfirmWeddingTeamByemployee = async (data) =>
  client.post(`/weddingTeam/confirmWeddingTeam`, data);

export const GetWeddingTeamRows = async (weddingId, data = {}) =>
  client.post(`/weddingTeam/getWeddingTeamRows/${weddingId}`, data);

export const GetRowsEmpCharge = async (weddingId, data) =>
  client.post(`/weddingTeam/getRowsEmployeesInCharge/${weddingId}`, data);