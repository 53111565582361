export default {
  //colors 
  primaryColor: '#bc9222',
  secondaryColor: '#DFE0DF',
  thirdColor: '#a2a2a2',

  inputBorderColor: '#DFE0DF',
  inputFocusColor: '#bc9222',
  inputErrorColor: '#f5222d',
  inputSuccessColor: '#80B99E',

  primaryBackgroundColor: '#ffffff',
  secondaryBackgroundColor: '#f3f3f3',
  
  tableSecondaryBg: '#f7f6f6',
  tableSecondaryPadding: '2px 5px',
  tableSecondaryLineHeight: '14px',
  tableSecondarySize: '13px',
  tableBorder: '#cecece',

  primaryLighter: '#FBF6EA',

  iconSvgSize: '15px',

  //text size
  titleSize: '30px',
  sectionSize: '24px',
  textSize: '14px',
  textColor: '#000'
  
};
