import React, { Component } from 'react';
import Logo from '../../assets/icons/logo_white.svg';
import ReactSVG from 'react-svg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Layout, Icon } from 'antd';
import ManagementMenu from './ManagementMenu';
import MenuItems from './MenuItems';
import LogoutIcon from '../../assets/menu/ic_logout.svg';
import UserIcon from '../../assets/menu/ic_user2.svg';
import { DeleteUser } from '../../redux/User/user.actions';
import { withRouter } from 'react-router-dom';
import {
  StyledMenu,
  StyledMenuItem,
  StyledLogo,
  StyledNavLink,
  StyledSider,
  StyledContent,
  TransitionLayout,
  MenuSVG,
  LogoutLink,
  StyledSliderHeader,
  StyledSubMenu
} from './LayoutStyles';
import HeaderComponent from './HeaderComponent';
import MenuEmployee from './MenuEmployee';
import { hasPermissions, isCurrentUserChief, isCurrentUserEmployee, isCurrentUserEmployeeAttending, isCurrentUserHumanResources, isCurrentUserOrganizer, isCurrentUserServiceAssociate } from '../../infra/helpers/Helpers';
import { GetEmployeeByUser } from '../../infra/requests/EmployeeRequests';
import MenuEmployeeAttending from './MenuEmployeeAttending';

class ResponsiveSider extends Component {
  state = {
    collapsed: true,
    user: null,
    employee: null
  };

  componentDidMount = async () => {
    const localUser = JSON.parse(localStorage.user);
    this.setState({ uwser: localUser });
    if (isCurrentUserEmployee() || isCurrentUserChief() || isCurrentUserHumanResources()) {
      const resultEmployee = await GetEmployeeByUser(localUser._id);
      this.setState({ employee: resultEmployee.success ? resultEmployee.data : null });
    }
    this.mounted = true;
  };

  handleSider = () => this.setState({ collapsed: !this.state.collapsed });
  handleSiderClose = () => this.setState({ collapsed: true });

  logOut = () => {
    const { DeleteUser, history } = this.props;
    localStorage.clear();
    DeleteUser();
    history.push('/login');
  };

  getMenu = () => {
    const { employee } = this.state;
    const { location } = this.props;

    let list = [];

    if (location.pathname.indexOf('/management') === 0) list = ManagementMenu;
    else if (localStorage?.user !== undefined && (isCurrentUserEmployee() || isCurrentUserChief() || isCurrentUserHumanResources() || isCurrentUserEmployeeAttending())) {
      if (employee && !employee?.active) {
        list = [{
          type: 'menu',
          to: '/profile',
          external_icon: true,
          size: 20,
          icon: UserIcon,
          name: 'Perfil',
          exact: true
        }];
      }
      else if (isCurrentUserEmployeeAttending()) {
        //set menu for service associate
        list = MenuEmployeeAttending;
      }
      else list = MenuEmployee;
    } else list = MenuItems;

    list = this.checkMenuPermissions(list);
    return list;
  }

  checkMenuPermissions = (itens) => {
    for (let item of itens) {
      if (item.type === 'submenu' && Array.isArray(item?.to) && item?.to?.length > 0) {
        item.to = this.checkMenuPermissions(item.to);
        item.disabled = item?.to?.find(f => !f?.disabled) ? false : true;
      } else {
        if (item?.permissions && item?.permissions?.length > 0) {
          if (item?.to == '/schedule-agenda') {
            item.disabled = isCurrentUserOrganizer() && hasPermissions(item.permissions) ? false : true;
          } else if (item?.to == '/payments') {
            item.disabled = isCurrentUserHumanResources() && !isCurrentUserEmployee() ? true : false;
          } else {
            item.disabled = hasPermissions(item.permissions) ? false : true;
          }
        } else {
          if (item?.to == '/schedule-agenda') {
            item.disabled = isCurrentUserOrganizer() ? false : true;
          } else if (item?.to == '/payments') {
            item.disabled = isCurrentUserHumanResources() && !isCurrentUserEmployee() ? true : false;
          }
        }
      }
    }

    return itens;
  }

  renderMenuList = (item, index) => {
    const { user } = this.props;
    if (!user || user.role > item.permission_type) return null;

    if (item.type === 'submenu')
      return (
        <StyledSubMenu
          key={index}
          disabled={item?.disabled}
          title={
            <>
              {item.external_icon ? (
                <MenuSVG className="anticon dropdown-icon" size={item.size}>
                  <ReactSVG src={item.icon} />
                </MenuSVG>
              ) : (
                <Icon
                  style={{ fontSize: item.size ? item.size : '20px' }}
                  type={item.icon}
                />
              )}
              <span>{item.name}</span>
            </>
          }>
          {item.to.map(this.renderMenuList)}
        </StyledSubMenu>
      );

    return (
      <StyledMenuItem key={index} disabled={item?.disabled || false}>
        <StyledNavLink
          to={item.to}
          exact={item.exact}
          disabled={item?.disabled || false}
          activeClassName="activated">
          {item.external_icon ? (
            <MenuSVG className="anticon" size={item.size}>
              <ReactSVG src={item.icon} />
            </MenuSVG>
          ) : (
            <Icon type={item.icon} />
          )}
          <span>{item.name}</span>
        </StyledNavLink>
      </StyledMenuItem>
    );
  };

  render() {
    const { collapsed, user, employee } = this.state;
    const { location } = this.props;
    const list = this.getMenu();

    return (
      <Layout>
        <StyledSider
          collapsible
          collapsed={collapsed}
          onCollapse={this.handleSider}>
          <StyledSliderHeader collapsed={collapsed}>
            <StyledLogo src={Logo} onClick={this.handleSider} />
          </StyledSliderHeader>
          <StyledMenu mode="inline">
            {list.map(this.renderMenuList)}
            <StyledMenuItem key={10}>
              <LogoutLink onClick={this.logOut}>
                <MenuSVG className="anticon" size={20}>
                  <ReactSVG src={LogoutIcon} />
                </MenuSVG>
                <span>{'Logout'}</span>
              </LogoutLink>
            </StyledMenuItem>
          </StyledMenu>
        </StyledSider>
        <TransitionLayout collapsed={collapsed}>
          <HeaderComponent />
          <StyledContent>{this.props.children}</StyledContent>
        </TransitionLayout>
      </Layout>
    );
  }
}

ResponsiveSider.propTypes = {};

const mapStateToProps = state => ({ user: state.user });

const mapActionToProps = dispatch => bindActionCreators({ DeleteUser }, dispatch);

ResponsiveSider = connect(mapStateToProps, mapActionToProps)(ResponsiveSider);

export default withRouter(ResponsiveSider);
