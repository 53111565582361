import {GetInfo} from '../../infra/requests/BaseRequests';
import {SaveInfo} from '../Info/info.actions';
import {initialize} from 'react-localize-redux';
import {renderToStaticMarkup} from 'react-dom/server';
import {SaveUser} from '../User/user.actions';

const getName = code => {
  if (code === 'pt') return 'Português';
  if (code === 'en') return 'Inglês';
  if (code === 'es') return 'Espanhol';
  if (code === 'fr') return 'Francês';
  return 'Lingua não definida';
};

const defineLangNames = (list = []) => {
  return list.map(lang => ({code: lang, name: getName(lang)}));
};

export default function start() {
  return async dispatch => {
    const info = await GetInfo();
    dispatch(SaveUser(info?.data?.user));
    dispatch(SaveInfo(info?.data));
    dispatch(
      initialize({
        languages: defineLangNames(info?.data?.languages),
        translation: [],
        options: {
          renderToStaticMarkup,
          defaultLanguage: info?.data?.default_language
        }
      })
    );
  };
}
