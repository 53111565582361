import React from 'react';
import {ResponsePill, ImagePill, DeleteButton} from '../SectionStyles';
import {Icon, Popconfirm} from 'antd';

const PrintedDelete = <div>
  <div>Tem a certeza que quer remover esta imagem?</div>
  <b>Este processo de casamento já foi impresso!</b>
</div>

const Delete = <div>
  <div>Tem a certeza que quer remover esta imagem?</div>
</div>


const {REACT_APP_IMAGES_URL} = process.env;

const ImageOption = ({image, question, handleDelete, printed}) => {
  const openFile = id => window.open(`${REACT_APP_IMAGES_URL}${id}`, '_blank');
  if (image) {
    return (
      <ResponsePill transparent>
        <ImagePill src={`${REACT_APP_IMAGES_URL}${image._id}`} onClick={() => openFile(image._id)}/>
        {
          handleDelete &&
          <DeleteButton>
            <Popconfirm
              placement={'topRight'}
              title={printed ? PrintedDelete : Delete}
              onConfirm={() => handleDelete(question)}>
              <Icon type="delete" />
            </Popconfirm>
          </DeleteButton>
        }
      </ResponsePill>
    );
  }
  return <ResponsePill>Imagem inválida</ResponsePill>;
};

export default ImageOption;
