export const WeddingsCostumizations = {
  complete: {
    date: true,
    weddingPlace: true,
    coupleNames: true,
    coupleEmails: true,
    coupleContacts: true,
    price: true,
    minPax: true,
    expectedPax: true,
    language: true,
    weddingDayLanguage: true,
    organizerLanguage: true,
    organizers: true,
    payed: true,
    payedPercentage: true,
    paymentNotes: true,
    reserveNotes: true,
    proofs: true,
    meetings: true,
    printed: true,
    arrivingTime: false,
  },
  original: {
    date: true,
    weddingPlace: true,
    coupleNames: true,
    coupleEmails: true,
    coupleContacts: true,
    price: false,
    minPax: false,
    expectedPax: false,
    language: false,
    weddingDayLanguage: false,
    organizerLanguage: false,
    organizers: false,
    payed: false,
    payedPercentage: false,
    paymentNotes: false,
    reserveNotes: false,
    proofs: false,
    meetings: false,
    printed: false,
    arrivingTime: false,
  },
  payment: {
    date: true,
    weddingPlace: true,
    coupleNames: true,
    coupleEmails: true,
    coupleContacts: false,
    price: false,
    minPax: false,
    expectedPax: false,
    language: false,
    weddingDayLanguage: false,
    organizerLanguage: true,
    organizers: false,
    payed: true,
    payedPercentage: true,
    paymentNotes: true,
    reserveNotes: false,
    proofs: false,
    meetings: false,
    printed: false,
    arrivingTime: false,
  },
  coordinator: {
    date: true,
    weddingPlace: false,
    coupleNames: false,
    coupleEmails: false,
    coupleContacts: false,
    price: false,
    minPax: false,
    expectedPax: false,
    language: false,
    weddingDayLanguage: true,
    organizerLanguage: false,
    organizers: false,
    payed: false,
    payedPercentage: false,
    paymentNotes: false,
    reserveNotes: false,
    proofs: false,
    meetings: false,
    printed: false,
    arrivingTime: true,
  },
  host: {
    date: true,
    weddingPlace: false,
    coupleNames: false,
    coupleEmails: false,
    coupleContacts: false,
    price: false,
    minPax: false,
    expectedPax: false,
    language: false,
    weddingDayLanguage: true,
    organizerLanguage: false,
    organizers: false,
    payed: false,
    payedPercentage: false,
    paymentNotes: false,
    reserveNotes: false,
    proofs: false,
    meetings: false,
    printed: false,
    arrivingTime: true,
  },
  wcAssistant: {
    date: true,
    weddingPlace: false,
    coupleNames: false,
    coupleEmails: false,
    coupleContacts: false,
    price: false,
    minPax: false,
    expectedPax: false,
    language: false,
    weddingDayLanguage: true,
    organizerLanguage: false,
    organizers: false,
    payed: false,
    payedPercentage: false,
    paymentNotes: false,
    reserveNotes: false,
    proofs: false,
    meetings: false,
    printed: false,
    arrivingTime: true,
  },
};

export const WeddingsDefaultCustomizations = {
  date: false,
  weddingPlace: false,
  coupleNames: false,
  coupleEmails: false,
  coupleContacts: false,
  price: false,
  minPax: false,
  expectedPax: false,
  language: false,
  weddingDayLanguage: false,
  organizerLanguage: false,
  organizers: false,
  payed: false,
  payedPercentage: false,
  paymentNotes: false,
  reserveNotes: false,
  proofs: false,
  meetings: false,
  printed: false,
  arrivingTime: false,
};

export const WeddingsCostumeDefinitions = [
  { _id: 'complete', name: 'Completo', drive: false },
  { _id: 'original', name: 'Original', drive: false },
  { _id: 'payment', name: 'Pagamentos', drive: false },
  { _id: 'coordinator', name: 'Coordenadores', drive: false },
  { _id: 'host', name: 'Hospedeiras', drive: false },
  { _id: 'wcAssistant', name: 'Assistentes WC', drive: false },
];

export const WeddingsCustomizationsForm = [
  'date',
  'weddingPlace',
  'coupleNames',
  'coupleEmails',
  'coupleContacts',
  'price',
  'minPax',
  'expectedPax',
  'language',
  'weddingDayLanguage',
  'organizerLanguage',
  'organizers',
  'payed',
  'payedPercentage',
  'paymentNotes',
  'reserveNotes',
  'proofs',
  'meetings',
  'printed',
  'arrivingTime'
];