import React, { useState } from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import {Row, Col, Modal} from 'antd';
import {
  BasicInfoSection,
  SectionTitle,
  //SectionInformation,
  InfoText,
  Moment,
  MomentHour,
  SectionCTA
} from '../ProcessStyles';
import {categories, getNamesPT} from '../../our_day/Categories';
import SectionNotes from './SectionNotes';
import {BaseButton} from '../../../components/buttons/BaseButton';
import CommentsDrawer from '../../../components/comments/CommentsDrawer';
import { SaveSectionNotes } from '../../../infra/requests/WeddingProcessRequests';
import {connect} from 'react-redux';
import {initialize} from 'redux-form';
import {bindActionCreators} from 'redux';
import {SaveNotes} from '../../../redux/wedding/wedding.actions';
import NotesConfirmModal from '../../../components/notesModal/NotesModal';
import { hasNotes } from '../../../infra/services/notes/FindNotes';
import { getPrintedConfirmation } from '../../../infra/services/wedding/printedUtils';

const {confirm} = Modal;

const sortHour = (a, b) =>
  moment(a.time, 'HH:mm').isAfter(moment(b.time, 'HH:mm')) ? 1 : -1;

const sortArrayByHour = (list = []) => {
  const start = moment().set('hour', 5).set('minutes', 0);
  const beforeArray = [];
  const afterArray = [];
  list.forEach(elem => {
    if (moment(elem.time, 'HH:mm').isBefore(start)) {
      beforeArray.push(elem);
    } else afterArray.push(elem);
  });
  beforeArray.sort(sortHour);
  afterArray.sort(sortHour);
  return [...afterArray, ...beforeArray];
};

const getMissingMoments = (list = []) => {
  const missingMoments = [];

  const finalList = list.map(function (obj) {
    return obj.name;
  });

  for (let i = 0; i < categories.length; i++) {
    if (!finalList.includes(categories[i].name.pt)) {
      missingMoments.push({
        name: categories[i].name.pt,
        required: categories[i].required,
        requiredOrderModule: categories[i].requiredOrderModule
      });
    }
  }

  return missingMoments;
};

const OurDayComponent = ({
  weddingInfo,
  archived,
  day,
  dayInformation,
  history,
  match: {params},
  notes = [],
  dispatch,
  saveNotesOnReducer
}) => {
  const [drawer, setDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openNotesConfirm, setNotesConfirm] = useState(false);

  let list = sortArrayByHour(day);
  //Convert all moment names to PT (just in case)
  list = getNamesPT(list);
  const missingMoments = getMissingMoments(list);
  const note = notes.find(note => note.section === 'DAY');

  const openNotes = () => {
    dispatch(initialize('wedding_notes_section_form', note));
    setDrawer(true);
  };

  const closeNotes = () => {
    dispatch(initialize('wedding_notes_section_form', note));
    setDrawer(false); 
    setNotesConfirm(false);
  };

  const checkBeforeCloseNotes = (values) => {
    //If theres any change to the notes, ask before closing if we want to save the changes
    if(values && (note?.notes !== values.notes || note?.private !== values.private)) {
      dispatch(initialize('notes_modal_confirm_form', values));
      setNotesConfirm(true);
    }
    //Otherwise, just the close the notes
    else {
      setDrawer(false);
    }
  }

  const saveNotes = async values => {
    if (weddingInfo?.printed) {
      confirm({
        ...getPrintedConfirmation(),
        onOk() { updateNotes(values); },
        onCancel() { closeNotes(); },
      });
    } else updateNotes(values);
  };

  const updateNotes = async (values) => {
    setLoading(true);

    const {data} = await SaveSectionNotes(weddingInfo._id, {
      ...values,
      section: 'DAY'
    });

    dispatch(initialize('wedding_notes_section_form', data));
    saveNotesOnReducer(data);
    setLoading(false);
    setDrawer(false);
    setNotesConfirm(false);

    document.body.style.overflowY = 'auto';
  };

  return (
    <BasicInfoSection>
      <SectionTitle>
        O Nosso Dia
        <SectionCTA>
          <BaseButton
            type={'default'}
            icon={'read'}
            text={'Notas'}
            onClick={openNotes}
            notes={hasNotes(notes, 'DAY')}
          />
          {!archived && (
            <BaseButton
              type={'default'}
              icon={'arrow-right'}
              text={'Abrir Timeline'}
              onClick={() => history.push(`/wedding/${params.wedding}/day`)}
            />
          )}
        </SectionCTA>
      </SectionTitle>
      <SectionNotes title="Notas Públicas:" note={note?.notes} />
      <SectionNotes title="Notas Privadas:" note={note?.private} />
      { /*Client does not want this Our Day text to appear in the process page
        dayInformation?.information && <SectionInformation dangerouslySetInnerHTML={{__html: dayInformation.information.pt}}/>*/
      }
      <Row>
        <Col xs={12}>
          <InfoText isTitle>Momentos registados</InfoText>
          {list?.map(moment => (
            <Moment 
              key={moment._id}
              required={moment.required} 
              requiredOrderModule={moment.requiredOrderModule}
            >
              <MomentHour>{moment.time}</MomentHour>
              {moment.name} {moment.required || moment.requiredOrderModule ? '*' : ''}
            </Moment>
          ))}
        </Col>
        <Col xs={12}>
          <InfoText isTitle>Momentos em falta</InfoText>
          {missingMoments.map((elem, index) => (
            <Moment 
              key={index} 
              required={elem.required} 
              requiredOrderModule={elem.requiredOrderModule}
            >
              {elem.name} {elem.required || elem.requiredOrderModule ? '*' : ''}
            </Moment>
          ))}
        </Col>
      </Row>
      <CommentsDrawer
        title="Notas De O Nosso Dia"
        open={drawer}
        loading={loading}
        onClose={checkBeforeCloseNotes}
        saveNotes={saveNotes}
        archived={archived}
      />
      <NotesConfirmModal
        open={openNotesConfirm}
        loading={loading}
        onSubmit={saveNotes}
        closeModal={() => setNotesConfirm(false)}
        exitWithoutSaving={closeNotes}
      />
    </BasicInfoSection>
  );
};

const mapActionToProps = dispatch =>
  bindActionCreators(
    {saveNotesOnReducer: SaveNotes, dispatch},
    dispatch
  );

export default withRouter(connect(null, mapActionToProps)(OurDayComponent));
