import React, { Component, Fragment } from 'react';
import Table from '../../components/table/Table';
import {
    Row,
    Col,
    Popconfirm,
    Icon,
} from 'antd';
import {
    TableButton,
    SectionTitle,
    TableFilterSection,
    SpinLoading
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import {
    GetCategories,
    UpdateCategory,
    DeleteCategory
} from '../../infra/requests/CategoryRequests';
import moment from 'moment';
import TextInput from '../../components/inputs/TextInput';
import { withRouter } from 'react-router-dom';
import CurrencyInput from '../../components/inputs/CurrencyInput';
import { EmailYearType, GetDEYearByType, UpdateDEYear } from '../../infra/requests/DynamicEmailYearRequests';
import Alerts from '../../components/alert/Alert';

const RemoveMessage = (
    <div>
        <div>Tem a certeza que quer remover esta categoria? </div>
        <div>
            Toda a informação da categoria será removida da plataforma e não
            conseguirá ser recuperada.
        </div>
    </div>
);


class CategoryTable extends Component {
    state = {
        loading: false,
        loadingCat: false,

        pageSize: 15,
        currentPage: 1,
        search: '',
        category: null,

        columns: [
            {
                title: 'Ordenar',
                key: 'orderBtn',
                render: data => (
                    <Fragment>
                        {this.state.rows.findIndex(c => c.id === data.id) > 0 ? (<TableButton onClick={e => this.order(0, data)}>
                            <Icon type="up" />
                            {' Cima'}
                        </TableButton>) : null
                        }

                        {this.state.rows.findIndex(c => c.id === data.id) < (this.state.total - 1) ? (<TableButton onClick={e => this.order(1, data)}>
                            <Icon type="down" />
                            {' Baixo'}
                        </TableButton>) : null
                        }
                    </Fragment>
                )
            },
            {
                title: 'Ordem',
                dataIndex: 'order',
            },
            {
                title: 'Categoria',
                dataIndex: 'name',
            },
            {
                title: 'Horário de Entrada',
                render: data => (
                    <div>
                        {new Date(data?.startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    </div>
                    // <div>{new Date(data?.startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    //     &nbsp;-&nbsp;{new Date(data?.endTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    // </div>
                )
            },
            {
                title: 'Subcategoria',
                render: data => (
                    <div>

                        {data?.subcategories.map(subcategory => {
                            return <div key={subcategory.id}>{subcategory.name}</div>
                        })}
                    </div>
                )
            },
            {
                title: 'Acções',
                width: '120px',
                render: data => (
                    <Fragment>
                        <TableButton onClick={() => this.props.history.push(`/settings/category/${data.id}`)}>
                            <Icon type="edit" />
                            {' Editar'}
                        </TableButton>
                        {!data?.blockDelete && <TableButton onClick={e => e.stopPropagation()}>
                            <Popconfirm
                                placement="topRight"
                                title={RemoveMessage}
                                okText="Remover"
                                onConfirm={async () => { await this.deleteCategory(data) }}>
                                <Icon type="delete" />
                                {' Remover'}
                            </Popconfirm>
                        </TableButton>}
                    </Fragment>
                )
            }
        ],
        rows: [],
        total: 0,

        submitted: false,
        saving: false,
        canceling: false,
    };

    componentDidMount = async () => {
        await this.getGeneralConfigs();
        await this.updateDataTable();
    };

    // General Configs
    getGeneralConfigs = async () => {
        this.setState({ loading: true });

        try {
            const result = await GetDEYearByType(EmailYearType.BASE_PRICE_TRAINEE);
            // console.warn('Res', result);

            this.setState({
                basePriceTrainee: result?.success && result?.data ? result?.data : null,
                loading: false
            });
        } catch (e) {
            this.setState({ loading: false });
            console.error(e);
        }
    }

    submitGeneralConfigs = async () => {
        const { basePriceTrainee } = this.state;
        this.setState({ submitted: true });

        if (basePriceTrainee?.value === null || basePriceTrainee?.value === undefined
            || basePriceTrainee?.value === '' || basePriceTrainee?.value < 0) {
            return;
        }

        this.setState({ saving: true });

        const result = await UpdateDEYear(basePriceTrainee);
        // console.warn('Res', result);

        if (result?.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso!',
                text: 'Configurações atualizadas!'
            });
            this.setState({
                saving: false,
                submitted: false
            });
        } else {
            this.setState({
                saving: false,
                submitted: false
            }, () => this.getGeneralConfigs());
        }
    }

    // Categories
    updateDataTable = async () => {
        this.setState({ loadingCat: true });
        try {
            const result = await GetCategories();

            this.setState({
                rows: result.data,
                total: result.data.length,
                loadingCat: false
            });
        } catch (e) {
            this.setState({ loadingCat: false });
            console.error(e);
        }
    }

    /**
     * @description Open Modal to add/edit category
     * @param {*} category 
     */
    addEditCategory = (category) => {
        let x = category ? JSON.parse(JSON.stringify(category)) : {
            startTime: null,
            endTime: null
        };

        x.startTime = category ? moment(new Date(category.startTime)) : moment();
        x.endTime = category ? moment(new Date(category?.endTime)) : moment();

        this.setState({
            openModal: true,
            category: x,
            editable: true
        })
    }

    /**
     * @description Delete category and reorder datatable
     * @param {*} category - category to delete
     * @returns datatable categories updated
     */
    deleteCategory = async category => {
        const total = this.state.total;
        const categoryIndex = this.state.rows.findIndex(c => c.id === category.id);

        if (categoryIndex < total) {
            for (let index = categoryIndex + 1; index < total; index++) {
                const element = this.state.rows[index];
                element.order = index + 1;
                await UpdateCategory(element)
            }
        }

        await DeleteCategory(category.id);
        return this.updateDataTable();
    }

    /**
     * @description Order rows from category table
     * @param {number} type 
     * @param {object} category 
     * @returns category table with reordered rows
     */
    order = async (type, category) => {
        let categoryChange;
        let orderChange;
        categoryChange = this.state.rows[this.state.rows.findIndex(c => c.id === category.id) + (1 * (type === 1 ? 1 : -1))];

        orderChange = categoryChange.order;
        categoryChange.order = category.order;
        category.order = orderChange;
        await UpdateCategory(category);
        await UpdateCategory(categoryChange);
        return this.updateDataTable();
    }

    handleChangePage = currentPage => {
        this.setState({ currentPage }, this.updateDataTable);
    };

    handleChangeRowsPerPage = (currentSize, pageSize) => {
        this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
    };

    changeInitialValues = async values => {
        this.setState({ modalValues: values })
    };

    render() {
        const { history } = this.props;
        const { loading, loadingCat } = this.state;
        // const { currentPage, pageSize } = this.state;
        const { columns, rows, total, search } = this.state;
        const { basePriceTrainee, submitted, saving, canceling } = this.state;
        const invalidBPT = submitted && (basePriceTrainee?.value === null || basePriceTrainee?.value === undefined || basePriceTrainee?.value === '' || basePriceTrainee?.value < 0) ? true : false;

        return (
            <React.Fragment>
                <SectionTitle>Configurações Funcionários</SectionTitle>
                {loading
                    ? <SpinLoading />
                    : <Row gutter={[12, 12]}>
                        <Col xl={3} lg={4} md={6} sm={14} xs={24}>
                            <CurrencyInput
                                label={'Valor base estagiário'}
                                first={true}
                                min={0}
                                input={{
                                    value: basePriceTrainee?.value,
                                    onChange: (value) => {
                                        basePriceTrainee.value = value || '';
                                        this.setState({ basePriceTrainee });
                                    }
                                }}
                                meta={{
                                    invalid: invalidBPT,
                                    submitFailed: invalidBPT,
                                }}
                                placeholder="Insira o preço"
                            />
                        </Col>
                        <Col xl={5} lg={5} md={4} sm={10} xs={24} style={{ height: 66, display: 'flex', alignItems: 'flex-end' }}>
                            <BaseButton
                                type={'primary'}
                                icon={'save'}
                                text={'Guardar'}
                                disabled={loading}
                                loading={saving}
                                onClick={() => this.submitGeneralConfigs()}
                            />
                            <BaseButton
                                style={{ marginLeft: 10 }}
                                type={'default'}
                                icon={'close'}
                                text={'Cancelar'}
                                disabled={loading}
                                loading={canceling}
                                onClick={() => this.setState({ canceling: false }, () => this.getGeneralConfigs())}
                            />
                        </Col>
                    </Row>}

                <SectionTitle style={{ marginTop: '3rem' }}>Categorias</SectionTitle>

                <TableFilterSection>
                    <Row gutter={24}>
                        <Col lg={8} md={8} sm={12} xs={24}>
                            <div className='settings-search'>
                                <TextInput
                                    type="text"
                                    label="Pesquisa"
                                    placeholder="Pesquise por categoria ou subcategoria"
                                    input={{
                                        value: search,
                                        onChange: event => this.setState({ search: event.target.value })
                                    }}
                                    meta={{}}
                                />
                            </div>
                        </Col>

                        <Col lg={16} md={16} sm={12} xs={24} className='setting-col-btn'>
                            <BaseButton
                                type={'primary'}
                                icon={'plus'}
                                text={'Adicionar Categoria'}
                                onClick={() => history.push('/settings/category/0')}
                            />
                        </Col>
                    </Row>
                </TableFilterSection>
                <div className='table-responsive'>
                    <Table
                        columns={columns}
                        //currentPage={currentPage}
                        //pageSize={pageSize}
                        //defaultPageSize={pageSize}
                        loading={loadingCat}
                        rows={search.trim() === "" ? rows : rows.filter(f => f?.name.toLowerCase().includes(search.toLowerCase()) || f?.subcategories.filter(s => s?.name.toLowerCase().includes(search.toLowerCase())).length > 0)}
                        showHeader={true}
                        emptyIcon="setting"
                        emptyText={'Não existem categorias'}
                        total={search.trim() === "" ? total : rows.filter(f => f?.name.toLowerCase().includes(search.toLowerCase()) || f?.subcategories.filter(s => s?.name.toLowerCase().includes(search.toLowerCase())).length > 0).length}
                        rowKey={'_id'}
                        hasPagination={false}
                    //handleChangePage={this.handleChangePage}
                    //handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(CategoryTable);