import React, { useState } from 'react';
import { BasicInfoSection, SectionTitle, SectionCTA } from '../ProcessStyles';
import {
  QuestionTitleContainer,
  QuestionTitle
} from '../../ChoicesPage/SectionStyles';
import { findPageByTag } from '../../../infra/services/sections/Sections';
import { findAnsweredAboutUs } from '../../../infra/services/questions/AnsweredQuestions';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import SectionResponse from '../../ChoicesPage/SectionResponse';
import SectionNotes from './SectionNotes';
import { BaseButton } from '../../../components/buttons/BaseButton';
import CommentsDrawer from '../../../components/comments/CommentsDrawer';
import { SaveSectionNotes } from '../../../infra/requests/WeddingProcessRequests';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { bindActionCreators } from 'redux';
import { SaveNotes } from '../../../redux/wedding/wedding.actions';
import NotesConfirmModal from '../../../components/notesModal/NotesModal';
import { hasNotes } from '../../../infra/services/notes/FindNotes';
import { Modal } from 'antd';
import { getPrintedConfirmation } from '../../../infra/services/wedding/printedUtils';

const { confirm } = Modal;

const AboutUsComponent = ({ structureInfo, weddingInfo, notes = [], dispatch, saveNotesOnReducer, archived}) => {
  const [drawer, setDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openNotesConfirm, setNotesConfirm] = useState(false);

  let section = findPageByTag(structureInfo, 'ABOUT_US');
  const aboutUs = findAnsweredAboutUs(section, weddingInfo);
  const note = notes.find(note => note.section === 'ABOUT_US');

  const openNotes = () => {
    dispatch(initialize('wedding_notes_section_form', note));
    setDrawer(true);
  };

  const closeNotes = () => {
    dispatch(initialize('wedding_notes_section_form', note));
    setDrawer(false);
    setNotesConfirm(false);
  };

  const checkBeforeCloseNotes = (values) => {
    //If theres any change to the notes, ask before closing if we want to save the changes
    if (values && (note?.notes !== values.notes || note?.private !== values.private)) {
      dispatch(initialize('notes_modal_confirm_form', values));
      setNotesConfirm(true);
    }
    //Otherwise, just the close the notes
    else {
      setDrawer(false);
    }
  }

  const saveNotes = async values => {
    if (weddingInfo?.printed) {
      confirm({
        ...getPrintedConfirmation(),
        onOk() { updateNotes(values); },
        onCancel() { closeNotes(); },
      });
    } else updateNotes(values);
  };

  const updateNotes = async (values) => {
    setLoading(true);

    const { data } = await SaveSectionNotes(weddingInfo._id, {
      ...values,
      section: 'ABOUT_US'
    });

    dispatch(initialize('wedding_notes_section_form', data));
    saveNotesOnReducer(data);
    setLoading(false);
    setDrawer(false);
    setNotesConfirm(false);

    document.body.style.overflowY = 'auto';
  }

  return (
    <BasicInfoSection>
      <SectionTitle>
        Sobre nós
        <SectionCTA>
          <BaseButton
            type={'default'}
            icon={'read'}
            text={'Notas'}
            onClick={openNotes}
            notes={hasNotes(notes, 'ABOUT_US')}
          />
        </SectionCTA>
      </SectionTitle>
      <SectionNotes title="Notas Públicas:" note={note?.notes} />
      <SectionNotes title="Notas Privadas:" note={note?.private} />
      {aboutUs?.length > 0 ? (
        aboutUs?.map((question, index) => (
          <div key={index}>
            <QuestionTitleContainer>
              <QuestionTitle>{TranslateValue(question.title)}</QuestionTitle>
            </QuestionTitleContainer>
            <SectionResponse
              weddingPlace={weddingInfo?.wedding_place}
              upgrade={weddingInfo?.upgrade}
              question={question}
              printed={weddingInfo?.printed}
            />
          </div>
        ))
      ) : (
        <div>Não existem registos para apresentar!</div>
      )}
      <CommentsDrawer
        title="Notas De Sobre Nós"
        open={drawer}
        loading={loading}
        onClose={checkBeforeCloseNotes}
        saveNotes={saveNotes}
        archived={archived}
      />
      <NotesConfirmModal
        open={openNotesConfirm}
        loading={loading}
        onSubmit={saveNotes}
        closeModal={() => setNotesConfirm(false)}
        exitWithoutSaving={closeNotes}
      />
    </BasicInfoSection>
  );
};

const mapActionToProps = dispatch =>
  bindActionCreators(
    { saveNotesOnReducer: SaveNotes, dispatch },
    dispatch
  );

export default connect(null, mapActionToProps)(AboutUsComponent);