import React from 'react';
import { Row, Col } from 'antd';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, Button } from 'antd';
import { BaseForm } from '../../styles/BasicStyles';
import TitleInput from '../../components/inputs/TitleInput';
import ImageInput from '../../components/inputs/ImageInput';
import SelectInput from '../../components/inputs/SelectInput';
import DateInput from '../../components/inputs/DateInput';
import FormValidator from '../../infra/services/validations/FormValidator';
import { GetCategoryOptions } from '../../infra/services/NewsCategories/NewsCategories';
import { NewsPlatform } from './NewsPage';

const validations = (values) => {
  let errors = {};

  const rules = {
    title: 'required|languages',
    image: 'required',
    category: 'required',
    date: 'required',
    platform: 'required',
  };
  
  if (values?.platform && (values?.platform === NewsPlatform.CONFIRMED || values?.platform === NewsPlatform.BOTH)) {
    rules['weddingPlace'] = 'required';
  }
  errors = FormValidator.make(rules)(values);

  return errors;
};

let NewsModal = ({
  open,
  loading,
  handleSubmit,
  onSubmit,
  closeModal,
  change,
  questionType,
  newsForm
}) => {
  return (
    <Modal
      visible={open}
      title="Nova Notícia"
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      <BaseForm onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={[12, 12]}>
          <Col xs={12} offset={6}>
            <Field
              name="image"
              label="Imagem de capa *"
              component={ImageInput}
              ratio={0.6}
            />
          </Col>
          <Col xs={24}>
            <Field
              component={TitleInput}
              name={'title'}
              type="text"
              label={'Título *'}
              placeholder={'Título da notícia'}
            />
          </Col>
          <Col xs={12}>
            <Field
              component={DateInput}
              name="date"
              label="Data *"
              placeholder="Data da notícia"
            />
          </Col>
          <Col xs={12}>
            <Field
              component={SelectInput}
              name={'category'}
              label={'Categoria *'}
              placeholder={'Escolha a categoria'}
              type="select"
              data={GetCategoryOptions()}
              dataKey={'_id'}
              dataLabel={'name'}
            />
          </Col>
          <Col xs={12}>
            <Field
              component={SelectInput}
              data={[
                { _id: NewsPlatform.CONFIRMED, name: 'Noivos' },
                { _id: NewsPlatform.RESERVE, name: 'Reservas Não Confirmadas' },
                { _id: NewsPlatform.BOTH, name: 'Ambas' }
              ]}
              label='Plataforma *'
              name={`platform`}
              placeholder={'Selecione a plataforma'}
              onChange={(value) => {
                if(value === NewsPlatform.RESERVE) {
                  change('weddingPlace', 3);
                }
              }}
            />
          </Col>
          <Col xs={12}>
            <Field
              component={SelectInput}
              data={[
                { _id: 1, name: 'Solar da Levada' },
                { _id: 2, name: 'Quinta Lago dos Cisnes' },
                { _id: 3, name: 'Ambas' }
              ]}
              label='Local'
              name={`weddingPlace`}
              placeholder={'Selecione o local'}
              disabled={newsForm?.platform && newsForm?.platform === NewsPlatform.RESERVE}
            />
          </Col>
      </Row>
    </BaseForm>
    </Modal >
  );
};

NewsModal = reduxForm({
  form: 'manage_new_news_form',
  validate: validations
})(NewsModal);

const selector = formValueSelector('manage_new_news_form');

const mapStateToProps = state => ({
  newsForm: {
    title: selector(state, 'title'),
    date: selector(state, 'date'),
    category: selector(state, 'category'),
    platform: selector(state, 'platform'),
    weddingPlace: selector(state, 'weddingPlace'),
  }
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ change }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsModal);
