import React, { useState } from 'react';
import {BasicInfoSection, Image, SectionTitle, SectionCTA} from '../ProcessStyles';
import StackGrid from 'react-stack-grid';
import SectionNotes from './SectionNotes';
import {BaseButton} from '../../../components/buttons/BaseButton';
import CommentsDrawer from '../../../components/comments/CommentsDrawer';
import { SaveSectionNotes } from '../../../infra/requests/WeddingProcessRequests';
import {connect} from 'react-redux';
import {initialize} from 'redux-form';
import {bindActionCreators} from 'redux';
import {SaveNotes} from '../../../redux/wedding/wedding.actions';
import NotesConfirmModal from '../../../components/notesModal/NotesModal';
import { hasNotes } from '../../../infra/services/notes/FindNotes';
import { getPrintedConfirmation } from '../../../infra/services/wedding/printedUtils';
import { Modal } from 'antd';

const {confirm} = Modal;

const StyleComponent = ({weddingInfo, style = {}, notes = [], archived = false, dispatch, saveNotesOnReducer}) => {
  const [drawer, setDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openNotesConfirm, setNotesConfirm] = useState(false);

  const width = window.innerWidth;

  const images = [
    ...(style.images || []),
    ...(style?.inspirations?.map(inspiration => inspiration.image) || [])
  ];
  const note = notes.find(note => note.section === 'STYLE');

  const openNotes = () => {
    dispatch(initialize('wedding_notes_section_form', note));
    setDrawer(true);
  };

  const closeNotes = () => {
    dispatch(initialize('wedding_notes_section_form', note));
    setDrawer(false); 
    setNotesConfirm(false);
  };

  const checkBeforeCloseNotes = (values) => {
    //If theres any change to the notes, ask before closing if we want to save the changes
    if(values && (note?.notes !== values.notes || note?.private !== values.private)) {
      dispatch(initialize('notes_modal_confirm_form', values));
      setNotesConfirm(true);
    }
    //Otherwise, just the close the notes
    else {
      setDrawer(false);
    }
  }

  const saveNotes = async (values) => {
    if (weddingInfo?.printed) {
      confirm({
        ...getPrintedConfirmation(),
        onOk() { updateNotes(values); },
        onCancel() { closeNotes(); },
      });
    } else updateNotes(values);
  };

  const updateNotes = async (values) => {
    setLoading(true);

    const {data} = await SaveSectionNotes(weddingInfo._id, {
      ...values,
      section: 'STYLE'
    });

    dispatch(initialize('wedding_notes_section_form', data));
    saveNotesOnReducer(data);
    setLoading(false);
    setDrawer(false);
    setNotesConfirm(false);

    document.body.style.overflowY = 'auto';
  };

  return (
    <BasicInfoSection>
      <SectionTitle subsection>
        Estilo
        <SectionCTA>
          <BaseButton
            type={'default'}
            icon={'read'}
            text={'Notas'}
            onClick={openNotes}
            notes={hasNotes(notes, 'STYLE')}
          />
        </SectionCTA>
      </SectionTitle>
      <SectionNotes title="Notas Públicas:" note={note?.notes} />
      <SectionNotes title="Notas Privadas:" note={note?.private} />
      {images?.length > 0 ? (
        <StackGrid
          columnWidth={width <= 450 ? '100%' : width <= 768 ? '25%' : '16.66%'}
          gutterWidth={0}
          gutterHeight={0}
          monitorImagesLoaded={true}
          duration={0}>
          {images.map(image => (
            <Image key={image?._id} src={image?.url} alt={image?.file} />
          ))}
        </StackGrid>
      ) : (
        <div>Não existem registos para apresentar!</div>
      )}
      <CommentsDrawer
        title="Notas De O Nosso Estilo"
        open={drawer}
        loading={loading}
        onClose={checkBeforeCloseNotes}
        saveNotes={saveNotes}
        archived={archived}
      />
      <NotesConfirmModal
        open={openNotesConfirm}
        loading={loading}
        onSubmit={saveNotes}
        closeModal={() => setNotesConfirm(false)}
        exitWithoutSaving={closeNotes}
      />
    </BasicInfoSection>
  );
};

const mapActionToProps = dispatch =>
  bindActionCreators(
    {saveNotesOnReducer: SaveNotes, dispatch},
    dispatch
  );

export default connect(null, mapActionToProps)(StyleComponent);