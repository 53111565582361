import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Element } from 'react-scroll';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import SectionResponse from '../ChoicesPage/SectionResponse';
import ExternalStaff from './ExternalStaff';
import { bindActionCreators } from 'redux';
import { change, initialize } from 'redux-form';
import { connect } from 'react-redux';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import {
  SectionContentStyle,
  SectionTitle,
  Counting,
  QuestionTitle,
  QuestionTitleContainer,
  QuestionIcon
} from '../ChoicesPage/SectionStyles';
import SectionModal from '../ChoicesPage/SectionModal';
import { AnswerQuestion } from '../../infra/requests/WeddingProcessRequests';
import { updateQuestionAction } from '../../redux/wedding/wedding.actions';
import ManageOptionModal from '../../weddingday/questionOptions/ManageOptionModal';
import ManageItemModal from '../../weddingday/questionOptions/ManageItemModal';
import { GetAllSupplierCategories } from '../../infra/requests/SupplierRequests';
import { CreateOption, CreateOptionAndUpdateAnswer, UpdateOption } from '../../infra/requests/OptionsRequests';
import ManageItemByWeddingModal from '../../weddingday/questionOptions/ManageItemByWeddingModal';
import { correctResponseValues, isString } from '../ChoicesPage/components/OptionUtils';
import { getAnswerPrintedConfirmation } from '../../infra/services/wedding/printedUtils';
import { Modal } from 'antd';

const { confirm } = Modal;

const StaffContent = ({ printed, section = { chapters: [] }, external, dispatch, wedding, editStaff, deleteStaff, location: { hash }, notesOpen }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, toggleModal] = useState(false);
  const [editQuestion, setEditQuestion] = useState(undefined);
  const [showOptionModal, toggleOptionModal] = useState(false);
  const [editOption, setEditOption] = useState(undefined);
  const [supplierCategories, setSupplierCategories] = useState([]);

  useEffect(() => {
    //If there's a ID, we have to scroll to that question
    if (hash) {
      const questionID = hash.substr(1);

      if (questionID) {
        const questionTop = document.getElementById(questionID) ? document.getElementById(questionID).scrollIntoView(true) : 0;
        window.scrollTo({ top: questionTop, behavior: 'smooth' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // getSupplierCategories();
  }, []);

  const getSupplierCategories = async () => {
    const result = await GetAllSupplierCategories();
    if (result.success && result.data) setSupplierCategories(result.data);
  }

  const openModal = (question) => {
    dispatch(initialize('manage_answers_form', {}));

    if (question?.answer) {
      dispatch(
        initialize('manage_answers_form', {
          ...question?.answer,
          question_obs: question?.answer?.observations !== ""
        })
      );
    }

    setEditQuestion(question);
    toggleModal(true);
  };

  const closeModal = () => {
    toggleModal(false);
    setEditQuestion(undefined);
  };

  const onModalSubmit = async values => {
    if (printed) {
      confirm({
        ...getAnswerPrintedConfirmation(),
        onOk: () => { saveModal(values); },
        onCancel: () => { closeModal(); },
      });
    } else saveModal(values);
  }

  const saveModal = async values => {
    setLoading(true);
    values = correctResponseValues(values);
    const payload = FlattenToFormData({ ...values, question: editQuestion._id });

    const { data, success } = await AnswerQuestion(wedding.wedding._id, payload);

    if (success) {
      dispatch(updateQuestionAction(section.tag, data));
    }

    setLoading(false);
    closeModal();
  };

  const openOptionModal = (values, question) => {
    setEditOption(values);
    setEditQuestion(question);
    toggleOptionModal(true);
  };

  const onOptionModalSubmit = async (values) => {
    const optionOriginal = editQuestion.options.find(f => f._id === values._id);

    // If cost_price changed from default option, then create an copy based on default option
    if (optionOriginal && values.cost_price !== optionOriginal.cost_price) {
      const payload = FlattenToFormData({
        ...values,
        question: editQuestion.id,
        defaultOption: values.defaultOption ? values.defaultOption : values._id,
        wedding: wedding.wedding._id,
      });

      const resultQuestion = await CreateOptionAndUpdateAnswer(payload);
      // console.warn('resultQuestion', resultQuestion);

      if (resultQuestion.success && resultQuestion.data) {
        const question = { ...resultQuestion.data.question, answer: resultQuestion.data.answer };

        const chapterIndex = section.chapters.findIndex(f => f._id === editQuestion.chapter);
        if (chapterIndex >= 0) {
          const questionIndex = section.chapters[chapterIndex].questions.findIndex(f => f.id === editQuestion.id);
          if (questionIndex >= 0) {
            section.chapters[chapterIndex].questions.splice(questionIndex, 1, question);
          }
        }

        if (question?.answer) {
          dispatch(
            initialize('manage_answers_form', {
              ...question?.answer,
              question_obs: question?.answer?.observations !== ""
            })
          );
        }

        setEditQuestion(question);
        closeOptionModal();
      }
    } else closeOptionModal();
  }

  const closeOptionModal = () => {
    toggleOptionModal(false);
    setEditOption(undefined);
  };

  return (
    <SectionContentStyle id="section-container" name="section-container" printed={printed} notesOpen={notesOpen}>
      {section.chapters.map((chapter, cIndex) => (
        <Element key={`anchor-${cIndex}`} name={`anchor-${cIndex}`}>
          <SectionTitle>
            {TranslateValue(chapter.name)}
            <Counting>
              {cIndex + 1} de {section.chapters.length}
            </Counting>
          </SectionTitle>
          {cIndex === 0 ? (
            chapter.questions.map((question, qIndex) =>
              qIndex > 1 ? (
                <Element
                  key={`anchor-${cIndex}-${qIndex}`}
                  name={`anchor-${cIndex}-${qIndex}`}
                  id={question._id}
                >
                  <QuestionTitleContainer>
                    <QuestionTitle>{TranslateValue(question.title)}</QuestionTitle>
                    {
                      (question.type !== 9 || question.observations) &&
                      <QuestionIcon type="edit" title="Editar resposta" onClick={() => openModal(question)} />
                    }
                  </QuestionTitleContainer>
                  <SectionResponse
                    upgrade={wedding.wedding.upgrade}
                    weddingPlace={wedding.wedding.wedding_place}
                    question={question}
                    printed={printed}
                  />
                </Element>
              ) : null
            )
          ) : (
            <ExternalStaff
              staff={external}
              editStaff={editStaff}
              deleteStaff={deleteStaff}
              printed={printed}
            />
          )}
        </Element>
      ))}
      {
        showModal &&
        <SectionModal
          open={showModal}
          loading={loading}
          onSubmit={onModalSubmit}
          closeModal={closeModal}
          question={editQuestion}
          editOption={openOptionModal}
        />
      }
      {/* {
        showOptionModal &&
        <ManageItemByWeddingModal
          open={showOptionModal}
          initialValues={editOption}
          // loading={loading}
          onSubmit={onOptionModalSubmit}
          closeModal={closeOptionModal}
        />
      } */}
    </SectionContentStyle>
  );
};

const mapStateToProps = state => ({
  wedding: state.wedding
});

const mapActionToProps = dispatch => bindActionCreators({ updateQuestionAction, dispatch }, dispatch);

export default withRouter(connect(mapStateToProps, mapActionToProps)(StaffContent));