import React, { Component, Fragment } from 'react';
import { Checkbox, Icon, Popconfirm, Row, Col, Modal } from 'antd';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton,
  TableFilterSection
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import moment from 'moment';
import { connect } from 'react-redux';
import { ClearWedding } from '../../redux/wedding/wedding.actions';
import { bindActionCreators } from 'redux';
import { getCurrentUser, isCurrentUserAdmin, isEmployeeOrChief } from '../../infra/helpers/Helpers';
import { GetEmployeeByUser, UncheckUpdatedIbanByEmployees, UpdateEmployee } from '../../infra/requests/EmployeeRequests';
import { DownloadPendingApprovedPayments, GetPaymentsGrouped, GetPaymentsPaginate, UpdateStatusAndEndDate } from '../../infra/requests/PaymentRequests';
import TextInput from '../../components/inputs/TextInput';
import DateInput from '../../components/inputs/DateInput';
import SelectInput from '../../components/inputs/SelectInput';
import PaymentTotals from './PaymentTotals';
import ModalPDF from '../employees/ModalPDF';
import { withRouter } from 'react-router-dom';
import { DisapprovePaymentAccounts, GetPaymentAccount, GetPaymentAccountNumbers, MarkPaymentAccountAsPaid, UpdatePaymentAccountStatusAndEndDate } from '../../infra/requests/PaymentAccountRequests';
import Alert from '../../components/alert/Alert';
import ReactExport from 'react-export-excel';
import { getCurrencyNumber, getLocalColor } from '../../infra/services/wedding/weddingUtils';
import { LocalLabel } from '../weddings/Styles';
import { GetColorSystemByType } from '../../infra/requests/ColorSystemRequests';
import { GetSettingsByType } from '../../infra/requests/SettingRequests';
import { GetTextColor } from '../../infra/services/text/textUtils';
import { ReceiptName } from './PendingPayments';

const { confirm } = Modal;

const REACT_APP_IMAGES_URL = process.env.REACT_APP_IMAGES_URL;

const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const ExcelFile = ReactExport.ExcelFile;

class ReceiptsTable extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    disapproving: false,
    status: 2,

    showPreviewPDF: false,
    showPreviewExcel: false,

    datasetExcel: [],

    searchNomeColaborador: '',
    searchDataInicial: null,
    searchDataFinal: null,
    searchEntities: [],
    searchEntity: null,
    accountList: [],
    searchAccountNumber: null,

    selectAllRows: false,
    exporting: false,

    columns: [
      {
        title: ' ',
        key: 'select',
        width: '30px',
        render: data =>
          <div className="sc-dxgOiQ fWHHkk" style={{ textAlign: 'center' }}>
            <Checkbox
              checked={data.checked}
              onChange={(e) => {
                let element = data;
                element.checked = !element.checked;
                let elements = this.state.rows;
                let index = elements.findIndex(x => x._id == element._id);
                if (index > -1) {
                  elements[index] = element;
                  this.setState({ rows: elements })
                }
              }}>
            </Checkbox>
          </div>
      },
      {
        title: 'Nº Conta',
        key: 'accountNumber',
        width: '90px',
        render: data => data?.paymentAccount.accountNumber
      },
      {
        title: 'Funcionário',
        key: 'employee',
        render: data => data?.employee.name
      },
      {
        title: 'Entidade',
        key: 'entity',
        render: value => {
          const localWedding = value?.paymentAccount.entityValue;
          const local = getLocalColor(null, this.state.colors, localWedding);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null
        }
      },
      {
        title: 'Intervalo de Datas',
        key: 'dataInterval',
        width: '120px',
        render: data => {
          const dateInterval = data.dateInterval;
          const dtFormat = 'DD-MM-YYYY';
          const start = moment.utc(dateInterval.start).format(dtFormat);
          const end = moment.utc(dateInterval.end).format(dtFormat);
          if (start === end) return start;
          else return start + ' / ' + end;
        }
      },
      {
        title: 'Oficial',
        render: data => {
          const officialPercentage = data.paymentAccount.officialPercentage;
          const value = Math.round((data.paymentAccount.value + data.paymentAccount.bonus) * officialPercentage);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (officialPercentage * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Bónus',
        render: data => {
          const notOfficialPercentage = 1 - data.paymentAccount.officialPercentage;
          const value = Math.round((data.paymentAccount.value + data.paymentAccount.bonus) * notOfficialPercentage);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {'(' + (notOfficialPercentage * 100).toFixed(2) + '%) ' + getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Total',
        render: data => {
          const value = Math.round(data.paymentAccount.value + data.paymentAccount.bonus);
          return <div style={{ textAlign: 'right', width: '100%', height: '100%' }}>
            {getCurrencyNumber(value)}
          </div>
        },
      },
      {
        title: 'Recibo',
        key: 'receipt',
        width: '200px',
        render: data => !data?.paymentAccount.declaration ? <div className="sc-dxgOiQ cGTQcX">
          <div style={{ height: 32, padding: '4px 11px', color: 'rgba(0,0,0, 0.65)', backgroundColor: '#fff', border: '1px solid #d9d9d9', borderRadius: '4px', transition: 'all 0.3s', position: 'relative' }}>
            <ReceiptName>
              <span className='input' onClick={e => this.onPreviewPDF(data)}>{data?.paymentAccount.receiptName}</span>
            </ReceiptName>
          </div>
        </div> : null //data?.paymentAccount.receipt
      },
      {
        title: 'Acções',
        width: '120px',
        render: data => (
          <Fragment>
            {/* <TableButton onClick={e => {
              //this.setState({ openModal: true, modalValues: data })
              const { history } = this.props;
              const { status } = this.state;
              history.push(`/payment/${data.employee.id}/${data.paymentAccount.id}/${status}`);
            }}>
              <Icon type="info" />
              {' Detalhe'}
            </TableButton> */}
            {data.employee.updatedIban ? <Icon type='warning' style={{ color: 'red' }} /> : null}
            {data.employee.updatedIban ? ' IBAN Alterado' : null}
          </Fragment>
        )
      }
    ],
    rows: [],
    total: 0,
    totalValue: 0,
    totalBonus: 0,

    colors: {
      colorSys1: null,
      colorSys2: null
    }
  };

  componentDidMount = async () => {
    const localUser = getCurrentUser();
    const resEmployee = isEmployeeOrChief()
      ? await GetEmployeeByUser(localUser._id)
      : null;
    this.setState({ currentEmployee: resEmployee && resEmployee.data ? resEmployee.data : null });
    this.getLocalColors();
    this.getEntities();
    this.getPaymentAccountNumbers();
    await this.updateDataTable();
  };

  getLocalColors = async () => {
    const resColorSys1 = await GetColorSystemByType(4);
    const colorSys1 = resColorSys1.success && resColorSys1.data ? resColorSys1.data.color : '#f7b5d6';
    const resColorSys2 = await GetColorSystemByType(5);
    const colorSys2 = resColorSys2.success && resColorSys2.data ? resColorSys2.data.color : '#b6d7a9';
    this.setState({ colors: { colorSys1, colorSys2 } });
  }

  getEntities = async () => {
    const result = await GetSettingsByType(1);

    if (result.success) {
      let list = [];
      result.data.forEach(element => {
        if (element.entityValue !== 3) list.push({ _id: element.entityValue, name: element.name });
      });
      this.setState({ searchEntities: list });
    } else console.error(result.message);
  }

  getPaymentAccountNumbers = async () => {
    const { status } = this.state;
    const result = await GetPaymentAccountNumbers(status, null, true);
    // console.warn('Result', result);
    if (result.success && result.data) {
      const list = result.data.map(m => {
        return { _id: m, name: m }
      });
      this.setState({ accountList: list });
    }
  }

  updateDataTable = async () => {
    let { status, totalValue, totalBonus, currentPage, pageSize } = this.state;
    const { searchNomeColaborador, searchEntity, searchAccountNumber, searchDataInicial, searchDataFinal } = this.state;
    this.setState({ loading: true });

    try {
      let filter = { status: status, approved: true }
      if (searchNomeColaborador !== '') filter['name'] = searchNomeColaborador;
      if (searchEntity !== null) filter['entity'] = searchEntity;
      if (searchAccountNumber !== null) filter['accountNumber'] = searchAccountNumber;
      // if (searchDataInicial !== null) filter['startDate'] = searchDataInicial;
      // if (searchDataFinal !== null) filter['endDate'] = searchDataFinal;

      let result = await GetPaymentsPaginate(currentPage, pageSize, JSON.stringify(filter));
      // console.warn('Result', result);

      if (result.success && result.data.items.length > 0) {
        result.data.items = result.data.items.map((element) => {
          return {
            ...element,
            checked: false
          }
        }).sort((a, b) => a.paymentAccount.accountNumber > b.paymentAccount.accountNumber ? 1 : (b.paymentAccount.accountNumber > a.paymentAccount.accountNumber) ? -1 : 0)

        for (let i = 0; i < result.data.items.length; i++) {
          if (!isCurrentUserAdmin()) {
            totalValue = totalValue + result.data.items[i].paymentAccount.value;
            totalBonus = totalBonus + result.data.items[i].paymentAccount.bonus;
          }
        }
      }

      this.setState({
        rows: result.data.items,
        total: result.data.total,
        pageSize: result.data.limit,
        totalValue,
        totalBonus,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  onPreviewPDF = async (row) => {
    this.setState({ loading: true });
    const resultPA = await GetPaymentAccount(row.paymentAccount._id);

    if (resultPA?.success) {
      row.paymentAccount.receipt = `${REACT_APP_IMAGES_URL}${resultPA?.data?.receipt}`;
      this.setState({ selectedRow: row }, () => {
        this.setState({
          showPreviewPDF: true,
          loading: false
        })
      });
    } else {
      this.setState({ loading: false });
    }

  }

  closeModal = () => {
    this.setState({ showPreviewPDF: false, showPreviewExcel: false })
  }

  exportPayments = async () => {
    const { rows, status } = this.state;
    const { searchNomeColaborador, searchEntity, searchAccountNumber, searchDataInicial, searchDataFinal } = this.state;

    this.setState({
      exporting: true,
      loading: true
    });

    let filters = {
      status: status,
      approved: true
    }
    if (searchNomeColaborador !== '') filters['name'] = searchNomeColaborador;
    if (searchEntity !== null) filters['entity'] = searchEntity;
    if (searchAccountNumber !== null) filters['accountNumber'] = searchAccountNumber;
    // if (searchDataInicial !== null) filters['startDate'] = searchDataInicial;
    // if (searchDataFinal !== null) filters['endDate'] = searchDataFinal;

    let selectedPayments = rows?.length > 0
      ? rows.filter(x => x.checked === true)
      : [];

    // Export file
    let result = await DownloadPendingApprovedPayments(JSON.stringify(filters), { selected: selectedPayments });

    if (result) {
      let filename = 'Pagamentos_' + moment().format('YYYYMMDD') + '.xlsx';

      if (result.headers) {
        const contentDisposition = result.headers.get('content-disposition');
        filename = contentDisposition.split('filename="')[1].split('"')[0];
      }

      //Create blob link to download
      const url = window.URL.createObjectURL(new Blob([result])); //result.blob
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);

      //Append to html link element page
      document.body.appendChild(link);

      //Start download
      link.click();

      //Clean up and remove the link
      link.parentNode.removeChild(link);
    }

    this.setState({
      exporting: false,
      loading: false
    });
  };

  exportExcel = () => {
    let selectedPayments = this.state.rows.filter(x => x.checked === true);
    if (selectedPayments.length === 0) {
      Alert.new({
        type: 'error',
        title: 'Atenção',
        text: 'É necessário selecionar pelo menos um pagamento.'
      });
      return;
    }

    const getDates = (dateInterval) => {
      const dtFormat = 'DD-MM-YYYY';
      const start = moment.utc(dateInterval.start).format(dtFormat);
      const end = moment.utc(dateInterval.end).format(dtFormat);
      if (start === end) return start;
      else return start + ' / ' + end;
    }

    const dataSet1 = selectedPayments.map(sp => {
      return {
        paymentNumber: sp.paymentAccount.accountNumber,
        person: sp.employee.name,
        entity: sp.paymentAccount.entity, //sp.employee.entity.name,
        iban: sp.employee.iban,
        dates: getDates(sp.dateInterval),
        perc60: getCurrencyNumber(((sp.paymentAccount.value + sp.paymentAccount.bonus) * sp.paymentAccount.officialPercentage).toFixed(2)),
        bonus: getCurrencyNumber(((sp.paymentAccount.value + sp.paymentAccount.bonus) * (1 - sp.paymentAccount.officialPercentage)).toFixed(2)),
        perc100: getCurrencyNumber(parseFloat(sp.paymentAccount.value + sp.paymentAccount.bonus).toFixed(2))
      }
    });

    const dataset = <ExcelSheet data={dataSet1} name="dataset">
      <ExcelColumn label="Nº Pagamento" value="paymentNumber" />
      <ExcelColumn label="Funcionário" value="person" />
      <ExcelColumn label="Entidade" value="entity" />
      <ExcelColumn label="IBAN" value="iban" />
      <ExcelColumn label="Intervalo de Datas" value="dates" />
      <ExcelColumn label="Oficial" value="perc60" />
      <ExcelColumn label="Bónus" value="bonus" />
      <ExcelColumn label="Total" value="perc100" />
    </ExcelSheet>
    this.setState({ showPreviewExcel: true, datasetExcel: dataset }, () => {
      this.downloadDataButton.click()
    })
  }

  confirmRepprovePayments = () => {
    const { rows } = this.state;
    let selectedPayments = rows.filter(x => x?.checked === true);
    if (selectedPayments?.length == 0) {
      Alert.new({
        type: 'error',
        title: 'Atenção',
        text: 'É necessário selecionar pelo menos um pagamento.'
      });
      return;
    }

    confirm({
      title: 'Tem a certeza que pretende reprovar as contas de pagamentos?',
      content: (<div>
        <p>As contas selecionadas serão reprovadas e serão listadas em "Pagamentos pendentes".</p>
      </div>
      ),
      okType: 'danger',
      okText: 'Reprovar',
      cancelText: 'Cancelar',
      onOk: () => this.reprovePaymentAccounts(),
      onCancel: () => null,
    });
  }

  reprovePaymentAccounts = async () => {
    const { rows } = this.state;
    this.setState({
      loading: true,
      disapproving: true
    });

    let selectedPayments = rows.filter(x => x?.checked === true);
    const paymentAccounts = selectedPayments?.length > 0
      ? selectedPayments?.map(m => m?.paymentAccount)
      : [];

    const result = await DisapprovePaymentAccounts(paymentAccounts);
    if (result?.success) {
      Alert.new({
        type: "success",
        title: "Successo",
        text: "Contas de pagamento reprovadas!"
      });
      this.setState({ disapproving: false }, () => this.updateDataTable());
    } else {
      this.setState({
        loading: false,
        disapproving: false
      });
    }
  }

  markAsPaid = async () => {
    let selectedPayments = this.state.rows.filter(x => x?.checked === true);
    if (selectedPayments?.length === 0) {
      Alert.new({
        type: 'error',
        title: 'Atenção',
        text: 'É necessário selecionar pelo menos um pagamento.'
      });
      return;
    }

    if (selectedPayments.filter(x => x?.paymentAccount?.declaration == false && (x?.paymentAccount?.receipt == null || x?.paymentAccount?.receipt == '')).length > 0) {
      Alert.new({
        type: 'error',
        title: 'Atenção',
        text: 'Para marcar como pagos, é necessário aceitar a declaração ou fornecer o recibo.'
      });
      return;
    }

    const employees = selectedPayments?.length > 0 && selectedPayments?.filter(f => f?.employee?.updatedIban)?.length > 0
      ? selectedPayments?.filter(f => f?.employee?.updatedIban)?.map(m => m?.employee)
      : [];
    if (employees?.length > 0) {
      const resultEmployees = await UncheckUpdatedIbanByEmployees();
    }

    const paymentAccounts = selectedPayments?.length > 0
      ? selectedPayments?.map(m => m?.paymentAccount)
      : [];
    const result = await MarkPaymentAccountAsPaid(paymentAccounts);
    if (result?.success) {
      Alert.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Contas registadas como pagas!'
      });
      return this.updateDataTable();
    }
  }

  render() {
    const { history } = this.props;
    const { loading, currentPage, pageSize, showPreviewPDF, showPreviewExcel, exporting } = this.state;
    const { columns, rows, total, selectAllRows, datasetExcel } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={isCurrentUserAdmin() ? 3 : 1}>
            <PageTitle>Recibos</PageTitle>
            Lista de Recibos
          </HeaderTitle>
          <HeaderButtonsContainer buttons={isCurrentUserAdmin() ? 3 : 1}>
            <BaseButton
              type={'default'}
              icon={'file-excel'}
              text={'Exportar'}
              loading={exporting}
              onClick={() => this.exportPayments()}
            />
            {isCurrentUserAdmin() && <BaseButton
              type={'default'}
              icon={'undo'}
              text={'Reprovar pagamentos'}
              onClick={() => this.confirmRepprovePayments()}
            />}
            {isCurrentUserAdmin() && <BaseButton
              type={'primary'}
              icon={'check'}
              text={'Marcar como Pago'}
              onClick={() => this.markAsPaid()}
            />}
          </HeaderButtonsContainer>
        </HeaderContainer>

        <PageContainer buttons={isCurrentUserAdmin() ? 3 : 1}>

          <TableFilterSection>
            <Row gutter={24}>
              <Col lg={6} md={6} sm={8} xs={24}>
                <TextInput
                  type="text"
                  label="Pesquisa"
                  placeholder="Pesquisa pelo nome do funcionário"
                  input={{
                    value: this.state.searchNomeColaborador,
                    onChange: (event) => { this.setState({ searchNomeColaborador: event.target.value }); this.updateDataTable(); }
                  }}
                  meta={{}}
                />
              </Col>
              {/* <Col xs={12} md={4}>
                <DateInput
                  label="Data Inicial"
                  placeholder="Selecionar data"
                  input={{
                    value: this.state.searchDataInicial,
                    onChange: (event) => { this.setState({ searchDataInicial: event }); this.updateDataTable(); }
                  }}
                  meta={{ valid: true }}
                />
              </Col>
              <Col xs={12} md={4}>
                <DateInput
                  label="Data Final"
                  placeholder="Selecionar data"
                  input={{
                    value: this.state.searchDataFinal,
                    onChange: (event) => { this.setState({ searchDataFinal: event }); this.updateDataTable(); }
                  }}
                  meta={{ valid: true }}
                />
              </Col> */}
              <Col lg={4} md={6} sm={8} xs={24}>
                <SelectInput
                  label="Entidade"
                  allowClear
                  placeholder="Escolher entidade"
                  data={this.state.searchEntities}
                  input={{
                    value: this.state.searchEntity,
                    onChange: (event) => { this.setState({ searchEntity: event == '' ? null : event }); this.updateDataTable(); }
                  }}
                  meta={{ valid: true }}
                />
              </Col>
              <Col xs={24} sm={8} md={6} lg={4}>
                <SelectInput
                  label="Nº de Conta"
                  allowClear
                  placeholder="Escolher conta"
                  data={this.state.accountList}
                  input={{
                    value: this.state.searchAccountNumber,
                    onChange: (event) => { this.setState({ searchAccountNumber: event !== '' ? event : null }, () => this.updateDataTable()) }
                  }}
                  meta={{ valid: true }}
                />
              </Col>
            </Row>
          </TableFilterSection>

          <div className='payment-table-container'>
            <div className="sc-dxgOiQ fWHHkk checkbox-simple-payment">
              <Checkbox
                checked={selectAllRows}
                onChange={(e) => {
                  rows.forEach(r => {
                    r.checked = e.target.checked;
                  });
                  this.setState({ selectAllRows: e.target.checked });
                }}>
              </Checkbox>
            </div>

            <Table
              columns={columns}
              currentPage={currentPage}
              pageSize={pageSize}
              defaultPageSize={pageSize}
              loading={loading}
              rows={rows}
              showHeader={true}
              emptyIcon="euro"
              emptyText={'Não existem recibos'}
              total={total}
              rowKey={'paymentAccount.id'}
              hasPagination={false}
              //onPressRow={this.openProcess}
              handleChangePage={currentPage => this.setState({ currentPage }, this.updateDataTable)}
              handleChangeRowsPerPage={(pageSize) => this.setState({ pageSize, currentPage: 1 }, this.updateDataTable)}
            />
          </div>

          <PaymentTotals
            hasSelection={true}
            receipts={true}
            value={rows.filter(x => x.checked == true).reduce((a, b) => a + ((b['paymentAccount'].value + b['paymentAccount'].bonus) * b['paymentAccount'].officialPercentage || 0), 0)}
            bonus={rows.filter(x => x.checked == true).reduce((a, b) => a + ((b['paymentAccount'].value + b['paymentAccount'].bonus) * (1 - b['paymentAccount'].officialPercentage) || 0), 0)}
            total={rows.filter(x => x.checked == true).reduce((a, b) => a + (b['paymentAccount'].value + b['paymentAccount'].bonus || 0), 0)}
          />

          <ModalPDF
            openModal={showPreviewPDF}
            closeModal={this.closeModal}
            title='Recibo'
            file={this.state.selectedRow?.paymentAccount.receipt}
          />
          <div style={{ display: 'none' }}>
            <ExcelFile element={<button ref={input => this.downloadDataButton = input}>Download Data</button>}>
              {datasetExcel}
            </ExcelFile>
          </div>
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default withRouter(ReceiptsTable);
