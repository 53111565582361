import React from 'react';
import {connect} from 'react-redux';
import {Modal, Button} from 'antd';
import {
  GraphicsContainer,
  GraphicsTitle
} from '../ProcessStyles';
import {
  QuestionTitleContainer,
  QuestionTitle
} from '../../ChoicesPage/SectionStyles';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import SectionResponse from '../../ChoicesPage/SectionResponse';

let DecorationGraphicsModalComponent = ({
  open,
  closeModal,
  decoration,
  topper,
  partyItems,
  weddingInfo
}) => {
  //Get only the answers from chapter "Graphics" (ID: 5e0f1f366a0f5556c81168e6)
  const graphics = [];
  if(decoration.length > 0) {
    for(let i = 0; i < decoration.length; i++) {
      if(decoration[i].chapter === '5e0f1f366a0f5556c81168e6') {
        graphics.unshift(decoration[i]);
      }
    }
  }

  return (
    <Modal
      visible={open}
      title="Grafismos"
      maskClosable={false}
      onCancel={closeModal}
      width={600}
      footer={[
        <Button key="back" onClick={closeModal}>
          Fechar
        </Button>
      ]}>
      <GraphicsContainer>
        <GraphicsTitle>Party Selection</GraphicsTitle>
        {
          partyItems ?
          <div>
            <QuestionTitleContainer>
              <QuestionTitle>{TranslateValue(partyItems.title)}</QuestionTitle>
            </QuestionTitleContainer>
            <SectionResponse
              weddingPlace={weddingInfo.wedding_place}
              upgrade={weddingInfo.upgrade}
              question={partyItems}
              printed={weddingInfo?.printed}
            />
          </div>
          :
          <div>Não existem registos para apresentar!</div>
        }
        <GraphicsTitle>Food Selection</GraphicsTitle>
        {
          topper ?
          <div>
            <QuestionTitleContainer>
              <QuestionTitle>{TranslateValue(topper.title)}</QuestionTitle>
            </QuestionTitleContainer>
            <SectionResponse
              weddingPlace={weddingInfo.wedding_place}
              upgrade={weddingInfo.upgrade}
              question={topper}
              printed={weddingInfo?.printed}
            />
          </div>
          :
          <div>Não existem registos para apresentar!</div>
        }
        <GraphicsTitle>Decoração</GraphicsTitle>
        {
          graphics?.length > 0 ?
          graphics.map((question, index) =>
            <div key={index}>
              <QuestionTitleContainer>
                <QuestionTitle>{TranslateValue(question.title)}</QuestionTitle>
              </QuestionTitleContainer>
              <SectionResponse
                weddingPlace={weddingInfo.wedding_place}
                upgrade={weddingInfo.upgrade}
                question={question}
                printed={weddingInfo?.printed}
              />
            </div>
          )
          :
          <div>Não existem registos para apresentar!</div>
        }
      </GraphicsContainer>
    </Modal>
  );
};

const mapStateToProps = state => ({
  weddingInfo: state.wedding.wedding
});

export default connect(mapStateToProps)(DecorationGraphicsModalComponent);