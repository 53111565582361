import React from 'react';
import { Modal, Button } from 'antd';
import {
  ModalTextTitle,
  ModalTextLine
} from './Styles';
let DateWarningModal = ({ openModal, closeModal, onSubmit, loading, initialValues }) => {
  return (
    <Modal
      visible={openModal}
      title='Criar Casamento'
      maskClosable
      onCancel={closeModal}
      footer={[
        <Button
          key='cancel'
          onClick={() => closeModal()}
        >
          Cancelar
        </Button>,
        <Button
          key='submit'
          loading={loading}
          type='primary'
          onClick={() => onSubmit()}
        >
          Gravar
        </Button>
      ]}
    >
      <div>
        <ModalTextTitle>Atenção!</ModalTextTitle>
        <ModalTextLine>{initialValues}</ModalTextLine>
        <ModalTextLine>Tem a certeza que pretende continuar?</ModalTextLine>
      </div>
    </Modal>
  );
};
export default DateWarningModal;