import React from 'react';
import {Row, Col} from 'antd';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  DefaultSectionOption,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import EntreesIcon from '../../assets/icons/ic_entrees.svg';
import CakeIcon from '../../assets/icons/ic_cake.svg';
import WaiterIcon from '../../assets/icons/waiter.svg';
import BaseButton from '../../components/buttons/BaseButton';

const FoodInternal = ({history}) => {
  return (
    <React.Fragment>
      <HeaderContainer>
        <HeaderTitle buttons={1}>
          <PageTitle>Ementas Internas</PageTitle>
        </HeaderTitle>
        <HeaderButtonsContainer buttons={1}>
          <BaseButton
            type="default"
            icon="arrow-left"
            text="Voltar"
            block
            onClick={() => history.push('/food')}
          />
        </HeaderButtonsContainer>
      </HeaderContainer>
      <PageContainer buttons={1}>
        <Row gutter={[24, 24]}>
          <Col xs={6}>
            <DefaultSectionOption onClick={() => history.push('/food/internal_staff')}>
              <img src={WaiterIcon} alt="icon" />
              <span>Refeições do Staff</span>
            </DefaultSectionOption>
          </Col>
          <Col xs={6}>
            <DefaultSectionOption onClick={() => history.push('/food/internal_appetizers/60881817a60b270dabff8094')}>
              <img src={EntreesIcon} alt="icon" />
              <span>Aperitivos</span>
            </DefaultSectionOption>
          </Col>
          <Col xs={6}>
            <DefaultSectionOption onClick={() => history.push('/food/internal_buffets/6088183aa60b270dabff8095')}>
              <img src={CakeIcon} alt="icon" />
              <span>Buffet de Doces</span>
            </DefaultSectionOption>
          </Col>
        </Row>
      </PageContainer>
    </React.Fragment>
  );
};

export default FoodInternal;