import React, { Component } from "react";
import { Row, Col } from "antd";
import { GetConfigs } from "../../infra/requests/ConfigsRequests";
import ImageComponent from "../../components/images/ImageComponent";
import BaseButton from "../../components/buttons/BaseButton";
import CollapseComponent from "./components/CollapseGroup";
import InfoSection from "./components/InfoSection";
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle
} from "../../styles/BasicStyles";
import { HeaderSection, SectionTitle, SectionAction } from "./ConfigsStyles";

class ConfigsPage extends Component {
  state = {
    configs_solar: {},
    configs_lago: {}
  };

  componentDidMount = async () => {
    await this.getConfigurations();
  };

  getConfigurations = async () => {
    await this.setState({ loading: true });
    try {
      const result = await GetConfigs();
      this.setState({
        configs_solar: result.data[0],
        configs_lago: result.data[1],
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  render() {
    const { history } = this.props;
    const { configs_solar, configs_lago } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Configurations</PageTitle>
            Basic website configurations
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer buttons={0}>
          <Row gutter={24}>
            <Col xs={24} md={12}>
              <HeaderSection>
                <SectionTitle>Informação Geral - Solar da Levada</SectionTitle>
                <SectionAction>
                  <BaseButton
                    type={"primary"}
                    icon={"edit"}
                    text={"Edit"}
                    onClick={() =>
                      history.push(`management/configs/${configs_solar._id}`)
                    }
                  />
                </SectionAction>
              </HeaderSection>
              <InfoSection title={"Email"} text={configs_solar.email} />
              <InfoSection
                title={"Contacto"}
                text={configs_solar.mobile_phone}
              />
              <InfoSection
                title={"Morada - linha 1"}
                text={configs_solar.address_line_1}
              />
              <InfoSection
                title={"Morada - linha 2"}
                text={configs_solar.address_line_2}
              />
              <InfoSection
                title={"Localização do espaço (link)"}
                text={configs_solar?.venueLinkLocation}
              />
              <InfoSection
                title={"Direções para o espaço"}
              text={configs_solar?.venueDirections?.pt || ''}
              />
              <InfoSection
                title={"Ponto de encontro para visitas (direções)"}
                text={configs_solar?.visitMeetingPointDirections?.pt || ''}
              />
              <InfoSection
                title={"Ponto de encontro para reuniões (direções)"}
                text={configs_solar?.organizationMeetingPointDirections?.pt || ''}
              />
              <InfoSection
                title={"Facebook"}
                text={configs_solar.facebook_page}
              />
              <InfoSection
                title={"Instagram"}
                text={configs_solar.instagram_page}
              />
              <InfoSection
                title={"Pinterest"}
                text={configs_solar.pinterest_page}
              />
              <InfoSection
                title={"Nome da Empresa (para pagamentos)"}
                text={configs_solar.payment_company}
              />
              <InfoSection
                title={"IBAN"}
                text={configs_solar.iban}
              />
              <InfoSection
                title={"BIC/SWIFT"}
                text={configs_solar.swift}
              />
              <InfoSection isPrimaryTitle title={"Images"} />
              <CollapseComponent
                title={"Homepage - Reservas (imagem esquerda)"}
                panelKey={"1"}
                content={
                  <ImageComponent
                    url={configs_solar?.homepage_left_image?.url}
                    color={configs_solar?.homepage_left_image?.color}
                    ratio={1 / 1.7}
                    height="auto"
                  />
                }
                isPrimaryTitle
              />
              <CollapseComponent
                title={"Homepage - Reservas (imagem direita)"}
                panelKey={"2"}
                content={
                  <ImageComponent
                    url={configs_solar?.homepage_right_image?.url}
                    color={configs_solar?.homepage_right_image?.color}
                    ratio={1 / 1.7}
                    height="auto"
                  />
                }
                isPrimaryTitle
              />
              <CollapseComponent
                title={"Imagem - Página de Agendamento"}
                panelKey={"3"}
                content={
                  <ImageComponent
                    url={configs_solar?.booking_image?.url}
                    color={configs_solar?.booking_image?.color}
                    ratio={0.5}
                    width="50%"
                  />
                }
                isPrimaryTitle
              />
              <CollapseComponent
                title={"Imagem - Página de Contactos"}
                panelKey={"4"}
                content={
                  <ImageComponent
                    url={configs_solar?.contact_image?.url}
                    color={configs_solar?.contact_image?.color}
                    ratio={0.5}
                    width="50%"
                  />
                }
                isPrimaryTitle
              />
            </Col>
            <Col xs={24} md={12}>
              <HeaderSection>
                <SectionTitle>
                  Informação Geral - Quinta Lago dos Cisnes
                </SectionTitle>
                <SectionAction>
                  <BaseButton
                    type={"primary"}
                    icon={"edit"}
                    text={"Edit"}
                    onClick={() =>
                      history.push(`management/configs/${configs_lago._id}`)
                    }
                  />
                </SectionAction>
              </HeaderSection>
              <InfoSection title={"Email"} text={configs_lago.email} />
              <InfoSection
                title={"Contacto"}
                text={configs_lago.mobile_phone}
              />
              <InfoSection
                title={"Morada - linha 1"}
                text={configs_lago.address_line_1}
              />
              <InfoSection
                title={"Morada - linha 2"}
                text={configs_lago.address_line_2}
              />
              <InfoSection
                title={"Localização do espaço (link)"}
                text={configs_lago.venueLinkLocation}
              />
              <InfoSection
                title={"Direções para o espaço"}
                text={configs_lago?.venueDirections?.pt || ''}
              />
              <InfoSection
                title={"Ponto de encontro para visitas (direções)"}
                text={configs_lago?.visitMeetingPointDirections?.pt || ''}
              />
              <InfoSection
                title={"Ponto de encontro para reuniões (direções)"}
                text={configs_lago?.organizationMeetingPointDirections?.pt || ''}
              />
              <InfoSection
                title={"Facebook"}
                text={configs_lago.facebook_page}
              />
              <InfoSection
                title={"Instagram"}
                text={configs_lago.instagram_page}
              />
              <InfoSection
                title={"Pinterest"}
                text={configs_lago.pinterest_page}
              />
              <InfoSection
                title={"Nome da Empresa (para pagamentos)"}
                text={configs_lago.payment_company}
              />
              <InfoSection
                title={"IBAN"}
                text={configs_lago.iban}
              />
              <InfoSection
                title={"BIC/SWIFT"}
                text={configs_lago.swift}
              />
              <InfoSection isPrimaryTitle title={"Images"} />
              <CollapseComponent
                title={"Homepage - Reservas (imagem esquerda)"}
                panelKey={"1"}
                content={
                  <ImageComponent
                    url={configs_lago?.homepage_left_image?.url}
                    color={configs_lago?.homepage_left_image?.color}
                    ratio={1 / 1.7}
                    height="auto"
                  />
                }
                isPrimaryTitle
              />
              <CollapseComponent
                title={"Homepage - Reservas (imagem direita)"}
                panelKey={"2"}
                content={
                  <ImageComponent
                    url={configs_lago?.homepage_right_image?.url}
                    color={configs_lago?.homepage_right_image?.color}
                    ratio={1 / 1.7}
                    height="auto"
                  />
                }
                isPrimaryTitle
              />
              <CollapseComponent
                title={"Imagem - Página de Agendamento"}
                panelKey={"3"}
                content={
                  <ImageComponent
                    url={configs_lago?.booking_image?.url}
                    color={configs_lago?.booking_image?.color}
                    ratio={0.5}
                    width="50%"
                  />
                }
                isPrimaryTitle
              />
              <CollapseComponent
                title={"Imagem - Página de Contactos"}
                panelKey={"4"}
                content={
                  <ImageComponent
                    url={configs_lago?.contact_image?.url}
                    color={configs_lago?.contact_image?.color}
                    ratio={0.5}
                    width="50%"
                  />
                }
                isPrimaryTitle
              />
            </Col>
          </Row>
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default ConfigsPage;
