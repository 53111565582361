import React, { Component } from 'react';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle
} from '../../styles/BasicStyles';
import {
  GetOptionsPagination
} from '../../infra/requests/OptionsRequests';
import {
  GetAllSupplierCategories
} from '../../infra/requests/SupplierRequests';
import {
  GetChapterList
} from '../../infra/requests/ChapterRequests';
import {
  GetQuestionList
} from '../../infra/requests/QuestionsRequests';
import OptionsFilters from './OptionsFilters';
import SectionNames from '../../infra/services/sections/SectionNames';

class OptionsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    filters: '',
    columns: [
      {
        title: 'Secção',
        key: 'section',
        render: data => this.renderSection(data?.section?.tag),
        width: '20%'
      },
      {
        title: 'Capítulo',
        key: 'chapter',
        render: data => data?.chapter?.name?.pt,
        width: '20%'
      },
      {
        title: 'Pergunta',
        key: 'question',
        render: data => data?.question?.title?.pt,
        width: '20%'
      },
      {
        title: 'Opção',
        key: 'option',
        render: data => data?.title?.pt,
        width: '20%'
      },
      {
        title: 'Categoria de Encomenda',
        key: 'supplier',
        render: data => {
          return data?.supplier_category && Array.isArray(data?.supplier_category) && data?.supplier_category
            ? data?.supplier_category.map(m => m?.name).toString()
            : '';
          // data?.supplier_category?.name
        },
        width: '20%'
      }
    ],
    rows: [],
    total: 0,
    supplierCategories: [],
    chapters: [],
    questions: []
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  openRow = record => {
    const { history } = this.props;
    history.push(`/order-options/${record._id}`);
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetOptionsPagination(currentPage, pageSize, filters);

      const supplierCategories = await GetAllSupplierCategories();
      const chaptersList = await GetChapterList();
      const questionsList = await GetQuestionList();

      //Chapter list for filters
      let chapters = [];
      if (chaptersList?.data?.length > 0) {
        chaptersList.data.forEach(chapter => {
          const sectionName = SectionNames[chapter.section?.tag] || '';

          chapters.push({
            id: chapter._id,
            name: `${sectionName} » ${chapter.name?.pt}`
          });
        });
      }

      //Question list for filters
      let questions = [];
      if (questionsList?.data?.length > 0) {
        questionsList.data.forEach(question => {
          const sectionName = SectionNames[question.section?.tag] || '';
          const chapterName = question.chapter?.name?.pt || '';

          questions.push({
            id: question._id,
            name: `${sectionName} » ${chapterName} » ${question.title?.pt}`
          });
        });
      }

      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false,
        supplierCategories: supplierCategories?.data || [],
        chapters,
        questions
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = filters => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  renderSection = (tag) => {
    return SectionNames[tag];
  }

  render() {
    const { columns, rows, total, loading, currentPage, pageSize, supplierCategories, chapters, questions } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Categorias: Opções</PageTitle>
            Lista de Opções
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer buttons={0}>
          <OptionsFilters
            queryChange={this.handleFilterChange}
            supplierCategories={supplierCategories}
            chapters={chapters}
            questions={questions}
          />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="database"
            emptyText={'Não existem opções!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.openRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default OptionsPage;