import client from '../config/AxiosConfig';

export const CreateRoomPlanConfig = async (data) =>
  client.post(`/roomPlanConfig`, data);

export const GetRoomPlanConfig = async id =>
  client.get(`/roomPlanConfig/${id}`);

export const GetRoomPlanConfigByPlace = async (weddingPlace) =>
  client.get(`/roomPlanConfig/findByPlace/${weddingPlace}`);

export const GetRoomPlanConfigByPlaceWithDeleted = async (weddingPlace) =>
  client.get(`/roomPlanConfig/findByPlaceWithDeleted/${weddingPlace}`);

export const GetRoomPlanConfigByWedding = async (weddingId) =>
  client.get(`/roomPlanConfig/findByWedding/${weddingId}`);

export const GetRoomPlanConfigByType = async (type, weddingPlace) =>
  client.get(`/roomPlanConfig/findByType/${type}/${weddingPlace}`);

export const GetRoomPlanConfigByTypeForEditor = async (type, weddingPlace) =>
  client.get(`/roomPlanConfig/findByTypeEditor/${type}/${weddingPlace}`);

export const GetRoomPlanConfigForEditor = async (id) =>
  client.get(`/roomPlanConfig/findForEditor/${id}`);

export const GetRoomPlanConfigs = async () =>
  client.get(`/roomPlanConfig`);

export const UpdateRoomPlanConfig = async (id, data) =>
  client.put(`/roomPlanConfig/${id}`, data);

export const UploadBackgroundRoomPlan = async (id, data) =>
  client.put(`/roomPlanConfig/${id}/bgRoomPlan`, data);

export const UpdateEditorRoomPlanConfig = async (id, data) =>
  client.put(`/roomPlanConfig/${id}/editor`, data);

export const DeleteRoomPlan = async id =>
  client.delete(`/roomPlanConfig/${id}`);

export const RestoreRoomPlan = async id =>
  client.post(`/roomPlanConfig/${id}`);