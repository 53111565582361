import TextInput from '../../components/inputs/TextInput';
import PhoneInput from 'react-phone-number-input/input';

export const brideFields = [
  {
    name: 'name',
    type: 'text',
    label: 'Nome *',
    placeholder: 'Nome completo',
    component: TextInput
  },
  {
    name: 'email',
    type: 'email',
    label: 'Email',
    placeholder: 'Contacto de email',
    component: TextInput,
    disabled: true
  },
  {
    name: 'contact',
    type: 'tel',
    label: 'Contacto de telefone * (ex: +351911111111)',
    placeholder: '+351911111111',
    component: TextInput
  },
  {
    name: 'address',
    type: 'text',
    label: 'Morada',
    placeholder: 'Morada completa',
    component: TextInput
  },
  {
    name: 'profession',
    type: 'text',
    label: 'Profissão',
    placeholder: 'Profissão',
    component: TextInput
  }
];

export const groomFields = [
  {
    name: 'name',
    type: 'text',
    label: 'Nome *',
    placeholder: 'Nome completo',
    component: TextInput
  },
  {
    name: 'email',
    type: 'email',
    label: 'Email',
    placeholder: 'Contacto de email',
    component: TextInput,
    disabled: true
  },
  {
    name: 'contact',
    type: 'tel',
    label: 'Contacto de telefone * (ex: +351911111111)',
    placeholder: '+351911111111',
    component: TextInput
  },
  {
    name: 'address',
    type: 'text',
    label: 'Morada',
    placeholder: 'Morada completa',
    component: TextInput
  },
  {
    name: 'profession',
    type: 'text',
    label: 'Profissão',
    placeholder: 'Profissão',
    component: TextInput
  }
];

export const contactFields = [
  {
    name: 'name',
    type: 'text',
    label: 'Nome',
    placeholder: 'Nome completo',
    component: TextInput,
  },
  {
    name: 'email',
    type: 'email',
    label: 'Email',
    placeholder: 'Contacto de email',
    component: TextInput
  },
  {
    name: 'contact',
    type: 'tel',
    label: 'Contacto de telefone (ex: +351911111111)',
    placeholder: '+351911111111',
    component: TextInput,
    disabled: false,
  },
  {
    name: 'address',
    type: 'text',
    label: 'Morada',
    placeholder: 'Morada completa',
    component: TextInput
  }
];

export const guestFields = [
  {
    name: 'name',
    type: 'text',
    label: 'Nome',
    placeholder: 'Nome completo',
    component: TextInput,
  },
  {
    name: 'email',
    type: 'email',
    label: 'Email',
    placeholder: 'Contacto de email',
    component: TextInput,
    disabled: true
  },
  {
    name: 'contact',
    type: 'tel',
    label: 'Contacto de telefone (ex: +351911111111)',
    placeholder: '+351911111111',
    component: TextInput,
    disabled: false,
  },
];