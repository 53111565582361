import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Checkbox } from 'antd';
import {
  BaseForm,
} from '../../styles/BasicStyles';
import {
  Row,
  Col,
} from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector, change } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextInput from '../../components/inputs/TextInput';
import TagInput from '../../components/inputs/TagInput';
import { CheckboxField, InputGroupField } from '../../components/inputs/InputStyles';
import SimpleCheckboxInput from '../../components/inputs/SimpleCheckboxInput';
import NumberInput from '../../components/inputs/NumberInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import SelectInput from '../../components/inputs/SelectInput';
import DateInput from '../../components/inputs/DateInput';
import XpertGoDateInput from '../../components/inputs/XpertGoDateInput';
import moment from 'moment';
import XpertGoTimeInput from '../../components/inputs/XpertGoTimeInput';
import XpertGoDateTimeInput from '../../components/inputs/XpertGoDateTimeInput';
import { bindActionCreators } from 'redux';


const validations = (values) => {
  let errors = {};

  const validate = {
    startDate: 'required',
    endDate: 'required',
  };

  // if (values?.allDay) {
  //   validate['startTime'] = 'required';
  //   validate['endTime'] = 'required';
  // }
  errors = FormValidator.make(validate)(values);

  return errors;
};

let MultipleRestDayModal = ({
  isOpen,
  availableRegisters,
  handleSubmit,
  onSubmit,
  saving,
  onClose,
  dispatch,
  change,
  restDayForm
}) => {

  const [submitting, setSubmitting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
  }, [isOpen]);

  useEffect(() => {
    setSubmitting(saving);
  }, [saving])

  // Disable dates
  const disabledDate = (current) => {
    return current && current.isBefore(moment.utc().startOf('day'), 'day');
  }

  const disabledEndDate = (current) => {
    const startDate = restDayForm?.startDate
      ? moment.utc(restDayForm?.startDate)
      : null;
    return startDate
      ? current && current.isBefore(startDate.startOf('day'), 'day')
      : current && current.isBefore(moment.utc().startOf('day'), 'day');
  }

  return (
    <Modal
      visible={isOpen}
      title={availableRegisters ? 'Gerir disponibilidades' : 'Gerir indisponibilidades'}
      maskClosable
      onCancel={onClose}
      footer={[
        <Button
          key='cancel'
          onClick={() => onClose()}>
          Cancelar
        </Button>,
        <Button
          key='submit'
          type='primary'
          loading={submitting}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}
    >

      <BaseForm onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={12}>
          {/* Range Date Selection */}
          <Col lg={12} md={12} sm={12} xs={24}>
            <Field
              component={XpertGoDateInput}
              name="startDate"
              label="Data inicial"
              placeholder="Selecione a data inicial"
              disabledDate={disabledDate}
              onChange={(e) => {
                const date = e && moment.utc(e).isValid() ? e : null;
                setStartDate(date);

                const startTime = restDayForm?.startTime && moment.utc(restDayForm.startTime).isValid() && date
                  ? moment.utc(restDayForm.startTime).set('year', date.year()).set('month', date.month()).set('date', date.date())
                  : null;

                change('startTime', startTime);
              }}
            />
          </Col>
          <Col lg={12} md={12} sm={12} xs={24}>
            <Field
              component={XpertGoDateInput}
              name="endDate"
              label="Data final"
              placeholder="Selecione a data final"
              disabledDate={disabledEndDate}
              onChange={(e) => {
                const date = e && moment.utc(e).isValid() ? e : null;
                setEndDate(date);

                const endTime = restDayForm?.endTime && moment.utc(restDayForm.endTime).isValid() && date
                  ? moment.utc(restDayForm.endTime).set('year', date.year()).set('month', date.month()).set('date', date.date())
                  : null;

                change('endTime', endTime);
              }}
            />
          </Col>

          {/* <Col lg={24} md={24} sm={24} xs={24}>
            <Field
              component={CheckboxInput}
              name="allDay"
              label="Todo o dia?"
              positiveLabel="Sim"
              disabled={true}
            />
          </Col>
          {!restDayForm?.allDay && <React.Fragment>
            <Col lg={12} md={12} sm={12} xs={24}>
              <Field
                component={XpertGoDateTimeInput}
                name="startTime"
                label="Horário de início"
                allowClear={true}
                dateRef={startDate}
              />
            </Col>
            <Col lg={7} md={7} sm={7} xs={12}>
              <Field
                component={XpertGoDateTimeInput}
                name="endTime"
                label="Horário de fim"
                allowClear={true}
                dateRef={endDate}
              />
            </Col>
          </React.Fragment>} */}
        </Row>
      </BaseForm>
    </Modal>
  );
};

const selector = formValueSelector('rest_day_form');

MultipleRestDayModal = reduxForm({
  form: 'rest_day_form',
  enableReinitialize: true,
  validate: validations
})(MultipleRestDayModal);

MultipleRestDayModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  initialValues: { allDay: true },
  restDayForm: {
    startDate: selector(state, 'startDate'),
    endDate: selector(state, 'endDate'),
    allDay: selector(state, 'allDay'),
    startTime: selector(state, 'startTime'),
    endTime: selector(state, 'endTime'),
  }
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ change }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MultipleRestDayModal);