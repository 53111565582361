import DynamicPages from "../../pages/dynamicPages/DynamicPages";
import ManageDynamicPages from "../../pages/dynamicPages/ManageDynamicPages";
import ConfigsPage from "../../pages/configs/ConfigsPage";
import ManageConfigsPage from "../../pages/configs/ManageConfigsPage";
import AppointmentsPage from "../../pages/appointments/AppointmentsPage";
import ContactsPage from "../../pages/contacts/ContactsPage";
import BannersPage from "../../pages/banners/BannersPage";
import ManageBannersPage from "../../pages/banners/ManageBannersPage";
import PortfolioPage from "../../pages/portfolio/PortfolioPage";
import ManagePortfolioPage from "../../pages/portfolio/ManagePortfolioPage";
import VideosPage from "../../pages/videos/VideosPage";
import ManageVideoPage from "../../pages/videos/ManageVideoPage";
import ReviewsPage from "../../pages/reviews/ReviewsPage";
import ManageReviewsPage from "../../pages/reviews/ManageReviewsPage";
import ReservationsPage from "../../pages/reservations/ReservationsPage";
import ManageReservationPage from "../../pages/reservations/ManageReservationPage";
import TranslationsPages from "../../pages/translations/TranslationsPage";
import MetatagsPages from "../../pages/metatags/Metatags";
import ManageMetatagsPage from "../../pages/metatags/ManageMetatagsPage";
import { PermissionsEnum } from "../../infra/helpers/Permissions";

export default [
  {
    path: "/management",
    component: ConfigsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.MNG_READ_INFO],
  },
  {
    path: "/management/configs/:id",
    component: ManageConfigsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.MNG_EDIT_INFO],
  },
  {
    path: "/management/appointments",
    component: AppointmentsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.MNG_APPOINTMENTS],
  },
  {
    path: "/management/contacts",
    component: ContactsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.MNG_CONTACTS],
  },
  {
    path: "/management/banners",
    component: BannersPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.LIST_BANNER],
  },
  {
    path: "/management/banners/add",
    component: ManageBannersPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.CREATE_BANNER],
  },
  {
    path: "/management/banners/:id",
    component: ManageBannersPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.EDIT_BANNER],
  },
  {
    path: "/management/portfolio",
    component: PortfolioPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.LIST_IMAGE_PORTFOLIO],
  },
  {
    path: "/management/portfolio/add",
    component: ManagePortfolioPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.CREATE_IMAGE_PORTFOLIO],
  },
  {
    path: "/management/portfolio/:id",
    component: ManagePortfolioPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.EDIT_IMAGE_PORTFOLIO],
  },
  {
    path: "/management/videos",
    component: VideosPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.LIST_VIDEO_PORTFOLIO],
  },
  {
    path: "/management/videos/add",
    component: ManageVideoPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.CREATE_VIDEO_PORTFOLIO],
  },
  {
    path: "/management/videos/:id",
    component: ManageVideoPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.EDIT_VIDEO_PORTFOLIO],
  },
  {
    path: "/management/reviews",
    component: ReviewsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.LIST_REVIEW],
  },
  {
    path: "/management/reviews/add",
    component: ManageReviewsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.CREATE_REVIEW],
  },
  {
    path: "/management/reviews/:id",
    component: ManageReviewsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.EDIT_REVIEW],
  },
  {
    path: "/management/reservations",
    component: ReservationsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.LIST_RESERVATION],
  },
  {
    path: "/management/reservations/add",
    component: ManageReservationPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.CREATE_RESERVATION],
  },
  {
    path: "/management/reservations/:id",
    component: ManageReservationPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.EDIT_RESERVATION],
  },
  {
    path: "/management/dynamic-pages",
    component: DynamicPages,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.LIST_DYNAMIC_PAGE],
  },
  {
    path: "/management/dynamic-pages/:id",
    component: ManageDynamicPages,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.EDIT_DYNAMIC_PAGE],
  },
  {
    path: "/management/translations",
    component: TranslationsPages,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.LIST_TRANSLATION],
  },
  {
    path: "/management/metatags",
    component: MetatagsPages,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.LIST_MNG_METATAG],
  },
  {
    path: "/management/metatags/:id",
    component: ManageMetatagsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false, 
    permissions: [PermissionsEnum.EDIT_MNG_METATAG],
  }
];
