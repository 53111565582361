import React, { Component, Fragment } from 'react';
import {
    Popconfirm,
    Icon,
    Collapse,
    Row,
    Col,
    Tag,
} from 'antd';
import {
    TableButton,
    SectionTitle,
    TableFilterSection,
    RoomPlanOption
} from '../../../styles/BasicStyles';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { CreateFoodRestriction, DeleteFoodRestriction, GetFoodRestrictions, UpdateFoodRestriction } from '../../../infra/requests/FoodRestrictionsRequests';
import Alerts from '../../../components/alert/Alert';
import FoodRestrictionModal from './FoodRestrictionModal';
import Table from '../../../components/table/Table';
import TextInput from '../../../components/inputs/TextInput';
import BaseButton from '../../../components/buttons/BaseButton';
import FoodRestrictionFilters from './FoodRestrictionFilters';
import { EmailYearType, GetDEYearByType, UpdateDEYear } from '../../../infra/requests/DynamicEmailYearRequests';
import NumberInput from '../../../components/inputs/NumberInput';
import { VenueColors, getVenueColor } from '../../../infra/services/wedding/weddingUtils';
import { GetTextColor } from '../../../infra/services/text/textUtils';
import { LocalLabel } from '../../weddings/Styles';
import { DeleteTableType, GetTableTypes, RestoreTableType, UpdateTableType } from '../../../infra/requests/TableTypeRequests';
import TableTypeFilters from './TableTypeFilters';
import CanvasComponent from '../../../components/canvas/CanvasComponent';
import TableTypeModal, { RoomPlanTypes } from './TableTypeModal';
import { OptionBox } from '../../food/FoodStyles';
import { FloatButton } from '../../../components/buttons/ButtonsStyles';
import { GetRoomPlan, GetRoomPlanConfigs, GetRoomPlans } from '../../../infra/requests/RoomPlanConfigRequests';
import CurrencyInput from '../../../components/inputs/CurrencyInput';
import ManageBackgroundRoomPlans from './ManageBackgroundRoomPlans';
import { GuestAgeLabel } from '../../../components/guestMap/utilGuest';

const RemoveMessageRestriction = (
    <div>
        <div>Tem a certeza que quer remover este tipo? </div>
        <div>
            Toda a informação do tipo de atendimento será removido da plataforma e não
            conseguirá ser recuperado.
        </div>
    </div>
);

const RemoveMessageTableType = (
    <div>
        <div>Tem a certeza que quer remover este tipo de mesa? </div>
        <div>
            O tipo de mesa deixará de ser apresentado no plano de sala.
        </div>
    </div>
);

const RestoreMessageTableType = (
    <div>
        <div>Tem a certeza que quer recuperar este tipo de mesa? </div>
        <div>
            Este tipo de mesa voltará a ser apresentado no plano de sala e poderá ser novamente adicionado ao plano.
        </div>
    </div>
);
class RoomPlanSetting extends Component {
    state = {
        loading: false,
        saving: false,
        canceling: false,
        passageSpace: null,
        costExtraTable: null,
        submitted: false,

        filtersR: {},
        needRefreshR: false,
        loadingR: false,
        pageSizeR: 15,
        currentPageR: 1,
        rowsR: [],
        totalR: 0,
        columnsR: [
            {
                title: 'Sigla',
                width: '150px',
                render: data => data?.acronym?.pt || ''
            },
            {
                title: 'Legenda',
                width: '150px',
                render: data => data?.subtitle?.pt || ''
            },
            {
                title: 'Descrição',
                width: '150px',
                render: data => data?.description?.pt || ''
            },
            {
                title: 'Requer Observações',
                width: '150px',
                render: data => {
                    return data?.notesRequired
                        ? <Tag color="green">Sim</Tag>
                        : <Tag color="red">Não</Tag>;
                }
            },
            {
                title: 'Escolha do Chefe',
                width: '150px',
                render: data => {
                    return data?.chefChoice
                        ? <Tag color="green">Sim</Tag>
                        : <Tag color="red">Não</Tag>;
                }
            },
            {
                title: 'Encomendas Pastelaria',
                width: '150px',
                render: data => {
                    return data?.pastryOrder
                        ? <Tag color="green">Sim</Tag>
                        : <Tag color="red">Não</Tag>;
                }
            },
            {
                title: 'Idades dos convidados',
                width: '150px',
                render: data => {
                    return data?.guestAge?.length > 0
                        ? data?.guestAge?.map(m => GuestAgeLabel[m]).join(', ')
                        : '';
                }
            },
            {
                title: 'Acções',
                width: '120px',
                render: data => (
                    <Fragment>
                        <TableButton onClick={e => this.showFoodRestriction(e, data)}>
                            <Icon type="edit" />
                            {' Editar'}
                        </TableButton>
                        <TableButton onClick={e => e.stopPropagation()}>
                            <Popconfirm
                                placement="topRight"
                                title={RemoveMessageRestriction}
                                okText="Remover"
                                onConfirm={async () => { await this.deleteFoodRestriction(data) }}>
                                <Icon type="delete" />
                                {' Remover'}
                            </Popconfirm>
                        </TableButton>
                    </Fragment>
                )
            }
        ],

        showRestrictionModal: false,
        restrictionValues: null,
        savingR: false,

        filtersTT: {},
        needRefreshTT: false,
        loadingTT: false,
        pageSizeTT: 15,
        currentPageTT: 1,
        rowsTT: [],
        totalTT: 0,
        columnsTT: [
            {
                title: 'Designação',
                width: '150px',
                render: data => data?.designation?.pt || ''
            },
            {
                title: 'Dimensões',
                width: '150px',
                render: data => `${data?.width}x${data?.height}cm`
            },
            {
                title: 'Nº lugares (máximo)',
                width: '150px',
                render: data => data?.maxSeats || 0
            },
            {
                title: 'Local',
                width: '50px',
                className: 'align-center',
                render: data => {
                    const local = getVenueColor(data, this.state.colors);
                    return local
                        ? <LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.initials}</LocalLabel>
                        : null;
                }
            },
            {
                title: 'Pode ser unida',
                width: '150px',
                render: data => {
                    return data?.canBeJoin
                        ? <Tag color="green">Sim</Tag>
                        : <Tag color="red">Não</Tag>
                }
            },
            {
                title: 'Presidência',
                width: '150px',
                render: data => {
                    return data?.coupleTable
                        ? <Tag color="green">Sim</Tag>
                        : <Tag color="red">Não</Tag>
                }
            },
            {
                title: 'Acções',
                width: '120px',
                render: data => (
                    <Fragment>
                        <TableButton onClick={e => this.showTableType(e, data)}>
                            <Icon type="edit" />
                            {' Editar'}
                        </TableButton>
                        {data?.deleted
                            ? <TableButton onClick={e => e.stopPropagation()}>
                                <Popconfirm
                                    placement="topRight"
                                    title={RestoreMessageTableType}
                                    okText="Recuperar"
                                    onConfirm={async () => { await this.restoreTableType(data) }}>
                                    <Icon type="undo" />
                                    {' Recuperar'}
                                </Popconfirm>
                            </TableButton>
                            : <TableButton onClick={e => e.stopPropagation()}>
                                <Popconfirm
                                    placement="topRight"
                                    title={RemoveMessageTableType}
                                    okText="Remover"
                                    onConfirm={async () => { await this.deleteTableType(data) }}>
                                    <Icon type="delete" />
                                    {' Remover'}
                                </Popconfirm>
                            </TableButton>}
                    </Fragment>
                )
            }
        ],

        showTableTypeModal: false,
        tableTypeValues: null,
        savingTT: false,

        colors: VenueColors
    };

    componentDidMount = async () => {
        await this.getGeneralConfigs();
        await this.updateRestrictionDataTable();
        await this.updateTableTypeDataTable();
    };

    // General Configs
    getGeneralConfigs = async () => {
        this.setState({ loading: true });

        try {
            const result = await GetDEYearByType(EmailYearType.PASSAGE_SPACE_BET_TABLES);
            // console.warn('Res', result);
            const resultCostExtraTable = await GetDEYearByType(EmailYearType.COST_EXTRA_TABLE);

            this.setState({
                passageSpace: result?.success && result?.data ? result?.data : null,
                costExtraTable: resultCostExtraTable?.success && resultCostExtraTable?.data ? resultCostExtraTable?.data : null,
                loading: false
            });
        } catch (e) {
            this.setState({ loading: false });
            console.error(e);
        }
    }

    submitGeneralConfigs = async () => {
        const { passageSpace, costExtraTable } = this.state;
        this.setState({ submitted: true });

        if (passageSpace?.value === null || passageSpace?.value === undefined
            || passageSpace?.value === '' || passageSpace?.value < 0
            || costExtraTable?.value === null || costExtraTable?.value === undefined
            || costExtraTable?.value === '' || costExtraTable?.value < 0) {
            return;
        }

        this.setState({ saving: true });

        const result = await UpdateDEYear(passageSpace);

        const resultCostExtra = await UpdateDEYear(costExtraTable);
        // console.warn('Res', result);

        if (result?.success && resultCostExtra?.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso!',
                text: 'Configurações atualizadas!'
            });
            this.setState({
                saving: false,
                submitted: false
            });
        } else {
            this.setState({
                saving: false,
                submitted: false
            }, () => this.getGeneralConfigs());
        }
    }

    // Food Restrictions
    updateRestrictionDataTable = async () => {
        const { pageSizeR, currentPageR, filtersR, loadingR } = this.state;

        if (loadingR) {
            this.setState({ needRefreshR: true });
            return;
        }

        this.setState({ loadingR: true });
        try {
            const result = await GetFoodRestrictions(currentPageR, pageSizeR, filtersR);

            this.setState({
                rowsR: result?.data?.items ? result.data?.items : [],
                totalR: result.data?.total ? result.data.total : 0,
                loadingR: false
            }, () => {
                if (this.state.needRefreshR) {
                    this.setState({ needRefreshR: false }, () => this.updateRestrictionDataTable());
                }
            });
        } catch (e) {
            this.setState({ loadingR: false });
            console.error(e);
        }
    }

    showFoodRestriction = ($event, foodRestriction) => {
        $event.stopPropagation();

        this.setState({
            showRestrictionModal: true,
            restrictionValues: foodRestriction,
        });
    }

    submitFoodRestriction = async (values) => {
        this.setState({ savingR: true });

        const result = values?._id
            ? await UpdateFoodRestriction(values?._id, values)
            : await CreateFoodRestriction(values);

        if (result?.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: values?._id ? 'Restrição alimentar atualizada!' : 'Restrição alimentar adicionada!'
            });
            this.setState({
                savingR: false,
                showRestrictionModal: false,
                restrictionValues: null
            }, () => this.updateRestrictionDataTable());
        } else {
            this.setState({ savingR: false });
        }
    }

    deleteFoodRestriction = async (foodRestriction) => {
        const result = await DeleteFoodRestriction(foodRestriction?._id);

        if (result?.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: 'Restrição alimentar eliminada!'
            });
            this.updateRestrictionDataTable();
        }
    }

    // Table Types
    updateTableTypeDataTable = async () => {
        const { pageSizeTT, currentPageTT, filtersTT, loadingTT } = this.state;
        if (loadingTT) {
            this.setState({ needRefreshTT: true });
            return;
        }

        this.setState({ loadingTT: true });
        try {
            const result = await GetTableTypes(currentPageTT, pageSizeTT, filtersTT);

            this.setState({
                rowsTT: result?.data?.items ? result.data?.items : [],
                totalTT: result.data?.total ? result.data.total : 0,
                loadingTT: false
            }, () => {
                if (this.state.needRefreshTT) {
                    this.setState({ needRefreshTT: false }, () => this.updateTableTypeDataTable());
                }
            });
        } catch (e) {
            this.setState({ loadingTT: false });
            console.error(e);
        }
    }

    showTableType = ($event, tableType) => {
        $event.stopPropagation();

        this.setState({
            showTableTypeModal: true,
            tableTypeValues: tableType,
        });
    }

    submitTableType = async (values) => {
        this.setState({ savingTT: true });

        const result = await UpdateTableType(values?._id, values)

        if (result?.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: 'Tipo de mesa atualizado!'
            });
            this.setState({
                savingTT: false,
                showTableTypeModal: false,
                tableTypeValues: null
            }, () => this.updateTableTypeDataTable());
        } else {
            this.setState({ savingTT: false });
        }
    }

    deleteTableType = async (tableType) => {
        const result = await DeleteTableType(tableType?._id);

        if (result?.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: 'Tipo de tabela eliminado!'
            });
            this.updateTableTypeDataTable();
        }
    }

    restoreTableType = async (tableType) => {
        const result = await RestoreTableType(tableType?._id);

        if (result?.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: 'Tipo de tabela recuperado!'
            });
            this.updateTableTypeDataTable();
        }
    }

    // Datatable Aux
    handleChangePage = (currentPage, type) => {
        if (type == 'R') {
            this.setState({ currentPageR: currentPage }, this.updateRestrictionDataTable);
        } else if (type == 'TT') {
            this.setState({ currentPageTT: currentPage }, this.updateTableTypeDataTable);
        }
    };

    handleChangeRowsPerPage = (currentSize, pageSize, type) => {
        if (type == 'R') {
            this.setState({ pageSizeR: pageSize, currentPageR: 1 }, this.updateRestrictionDataTable);
        } else if (type == 'TT') {
            this.setState({ pageSizeTT: pageSize, currentPageTT: 1 }, this.updateTableTypeDataTable);
        }
    };

    handleRestrictionFilterChange = (filters, type) => {
        if (type == 'R') {
            this.setState({ filtersR: filters, currentPageR: 1 }, this.updateRestrictionDataTable);
        } else if (type == 'TT') {
            this.setState({ filtersTT: filters, currentPageTT: 1 }, this.updateTableTypeDataTable);
        }
    };

    render() {
        const { history } = this.props;
        const { loading, saving, canceling, submitted } = this.state;
        const { loadingR, filtersR, pageSizeR, currentPageR, rowsR, totalR, columnsR } = this.state;
        const { filtersTT, loadingTT, pageSizeTT, currentPageTT } = this.state;
        const { showRestrictionModal, restrictionValues, savingR, rowsTT, totalTT, columnsTT } = this.state;
        const { showTableTypeModal, tableTypeValues, savingTT } = this.state;
        let { passageSpace, costExtraTable } = this.state;
        const invalidPS = submitted && (passageSpace?.value === null || passageSpace?.value === undefined || passageSpace?.value === '' || passageSpace?.value < 0) ? true : false;
        const invalidCET = submitted && (costExtraTable?.value === null || costExtraTable?.value === undefined || costExtraTable?.value === '' || costExtraTable?.value < 0) ? true : false;

        return (
            <React.Fragment>
                <SectionTitle>Configurações</SectionTitle>
                <Row gutter={[12, 12]}>
                    <Col xl={5} lg={7} md={10} sm={14} xs={24}>
                        <NumberInput
                            label={'Espaço de passagem entre mesas (cm)'}
                            first={true}
                            min={0}
                            input={{
                                value: passageSpace?.value,
                                onChange: (value) => {
                                    passageSpace.value = value != null && value != undefined ? value : 0;
                                    this.setState({ passageSpace });
                                }
                            }}
                            meta={{
                                invalid: invalidPS,
                                submitFailed: invalidPS,
                            }}
                            placeholder="Insira o espaço (cm)"
                        />
                    </Col>
                    <Col xl={3} lg={4} md={6} sm={14} xs={24}>
                        <CurrencyInput
                            label={'Custo extra por mesas'}
                            first={true}
                            min={0}
                            input={{
                                value: costExtraTable?.value,
                                onChange: (value) => {
                                    costExtraTable.value = value || '';
                                    this.setState({ costExtraTable });
                                }
                            }}
                            meta={{
                                invalid: invalidCET,
                                submitFailed: invalidCET,
                            }}
                            placeholder="Insira o custo"
                        />
                    </Col>
                    <Col xl={5} lg={5} md={4} sm={10} xs={24} style={{ height: 66, display: 'flex', alignItems: 'flex-end' }}>
                        <BaseButton
                            type={'primary'}
                            icon={'save'}
                            text={'Guardar'}
                            disabled={loading}
                            loading={saving}
                            onClick={() => this.submitGeneralConfigs()}
                        />
                        <BaseButton
                            style={{ marginLeft: 10 }}
                            type={'default'}
                            icon={'close'}
                            text={'Cancelar'}
                            disabled={loading}
                            loading={canceling}
                            onClick={() => this.setState({ canceling: false }, () => this.getGeneralConfigs())}
                        />
                    </Col>
                </Row>

                <Row>
                    <SectionTitle style={{ marginTop: '3rem' }}>Planos base</SectionTitle>
                    <ManageBackgroundRoomPlans history={this.props.history} />
                </Row>

                <Row>
                    <SectionTitle style={{ marginTop: '3rem' }}>Restrições Alimentares</SectionTitle>

                    <FoodRestrictionFilters loading={loadingR} queryChange={(filters) => this.handleRestrictionFilterChange(filters, 'R')} addAction={($e) => this.showFoodRestriction($e, null)} />

                    <div className='table-responsive'>
                        <Table
                            columns={columnsR}
                            currentPage={currentPageR}
                            pageSize={pageSizeR}
                            defaultPageSize={pageSizeR}
                            loading={loadingR}
                            rows={rowsR}
                            showHeader={true}
                            emptyIcon="setting"
                            emptyText={'Não existem restrições'}
                            total={totalR}
                            rowKey={'_id'}
                            hasPagination={true}
                            handleChangePage={(currentPage) => this.handleChangePage(currentPage, 'R')}
                            handleChangeRowsPerPage={(currentSize, pageSize) => this.handleChangeRowsPerPage(currentSize, pageSize, 'R')}
                        />
                    </div>
                </Row>

                <Row>
                    <SectionTitle style={{ marginTop: '3rem' }} >Tipos de mesas</SectionTitle>

                    <TableTypeFilters loading={loadingR} queryChange={(filters) => this.handleRestrictionFilterChange(filters, 'TT')} />

                    <div className='table-responsive'>
                        <Table
                            columns={columnsTT}
                            currentPage={currentPageTT}
                            pageSize={pageSizeTT}
                            defaultPageSize={pageSizeTT}
                            loading={loadingTT}
                            rows={rowsTT}
                            showHeader={true}
                            emptyIcon="setting"
                            emptyText={'Não existem tipos de mesas'}
                            total={totalTT}
                            rowKey={'_id'}
                            hasPagination={true}
                            handleChangePage={(currentPage) => this.handleChangePage(currentPage, 'TT')}
                            handleChangeRowsPerPage={(currentSize, pageSize) => this.handleChangeRowsPerPage(currentSize, pageSize, 'TT')}
                        />
                    </div>
                </Row>

                {showRestrictionModal &&
                    <FoodRestrictionModal
                        isOpen={showRestrictionModal}
                        loading={savingR}
                        onSubmit={this.submitFoodRestriction}
                        onClose={() => this.setState({ showRestrictionModal: false, restrictionValues: null })}
                        initialValues={restrictionValues}
                    />}

                {showTableTypeModal &&
                    <TableTypeModal
                        isOpen={showTableTypeModal}
                        loading={savingR}
                        onSubmit={this.submitTableType}
                        onClose={() => this.setState({ showTableTypeModal: false, tableTypeValues: null })}
                        initialValues={tableTypeValues}
                    />}
            </React.Fragment>
        );
    }
}

export default withRouter(RoomPlanSetting);