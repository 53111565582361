import React from 'react';
import { Modal, Button, Row, Icon } from 'antd';
import { ModalTextLine, ModalTextTitle } from '../weddings/Styles';

let FoodWarningModal = ({ openModal, closeModal, onSubmit, loading, initialValues }) => {
  return (
    <Modal
      visible={openModal}
      title='Aviso'
      maskClosable
      onCancel={closeModal}
      footer={[
        <Button
          key='cancel'
          onClick={() => closeModal()}
        >
          Cancelar
        </Button>,
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => onSubmit()}
        >
          Gravar
        </Button>
      ]}
    >
      <ModalTextTitle>Atenção!</ModalTextTitle>
      <div style={{ display: 'inline-flex', marginTop: 10 }}>
        <Icon type='warning' style={{ color: 'orange', fontSize: '2rem', margin: 'auto 10px auto 0' }}></Icon>
        <div>
          <ModalTextLine>Houve alterações no formulário.</ModalTextLine>
          <ModalTextLine>Pretende guardar antes de continuar?</ModalTextLine>
        </div>
      </div>
    </Modal>
  );
};

export default FoodWarningModal;