import styled from 'styled-components';
import { Spin, Form as AntForm, Form, Button, Modal } from 'antd';
export const ReserveInfoModal = styled(Modal)`
    width: 1000px;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

// color: #bc9222;
export const Title = styled.div`
  font-family: 'Titillium Web', sans-serif;
  font-weight: inherit;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 15px 0 0 0;
  border-bottom: 1px solid #bc9222
`

export const ErroSpan = styled.span`
  font-family: 'Titillium Web', sans-serif;
  color: red;
  font-size: 11px;
  line-height: 39px;
  margin: 0;
  padding: 0;
`

export const FormContainer = styled(Form)`
  width: 100%;
`;

export const TableContent = styled.div`
    width: 100%;
    marginTop: ${({ first }) => (first ? '0px' : '15px')}

    table tr th {
        color: black !important;
    }

    .ant-table {
        overflow: auto;
    }

    .ant-table table {
        border-radius: 0 !important;
    }

    .ant-table-thead > tr:first-child > th:first-child {
        border-top-left-radius: 0px !important;
    }

    .ant-table-thead > tr:first-child > th:last-child {
        border-top-right-radius: 0px !important;
    }

    .ant-empty-image {
        height: 0 !important;
        margin-bottom: 0 !important;
    }

    .ant-table-placeholder {
        padding: 10px 16px !important;
    }
`;

export const PrevButton = styled(Button)`
    background-color: #dcdcdc !important;
    border: 1px solid #dcdcdc !important;

    &:hover, &:focus {
        color: #fff !important;
        background-color: #929292 !important;
        border-color: #929292 !important;
    }
`;

export const StyledSteps = styled.ul`
    margin-inline-start: 0;
    margin-block-end: 0;
    margin-block-start: 0;
    list-style: none;
    padding-inline-start: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
`;

export const StyledStep = styled.li`
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        border-bottom: 1px solid #bc9222;
        left: 0;
        transform: translate(-58px, 0px);
        z-index: 0;
        width: 80%;
    }

    &:first-child:before {
        content:none;
    }

    .anticon {
        font-size: 25px;
        margin-right: 5px;
        color: ${({ active }) => (active ? '#bc9222' : 'inherit')}
    }

    span {
        font-size: 16px
    }
`;