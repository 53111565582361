import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import { BaseForm, SpinLoading } from '../../styles/BasicStyles';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import FormValidator from '../../infra/services/validations/FormValidator';
import NumberInput from '../../components/inputs/NumberInput';

const validations = FormValidator.make({
  minPax: 'required|isInteger|minNumber:1',
  minPrice: 'required|minNumber:0.5',
});

let ReservePriceModal = (props): React$Element<React$FragmentType> => {
  const form = useSelector(state => {
    // console.warn('Val', state.form.price_form?.values);
    return state.form.price_form?.values
  });

  const [initialValues, setInitialValues] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    setInitialValues(JSON.parse(JSON.stringify(props.initialValues)));
  }, [props.initialValues])

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading])

  return (
    <Modal
      visible={props.isOpen}
      title={initialValues?.id === null ? 'Adicionar Preço' : 'Editar Preço'}
      maskClosable
      onCancel={props.onClose}
      footer={[
        <Button
          key='cancel'
          onClick={() => props.onClose()}>
          Cancelar
        </Button>,
        <Button
          key='submit'
          type='primary'
          onClick={props.handleSubmit(props.onSubmit)}>
          Gravar
        </Button>
      ]}
    >
      {loading ?
        <SpinLoading></SpinLoading> :
        <BaseForm onSubmit={props.handleSubmit(props.onSubmit)}>
          <Row gutter={12}>
            <Col lg={12} md={12} xs={24}>
              <Field
                component={NumberInput}
                name="minPax"
                label="Pax Mínimo"
                // placeholder="Insira o nome da subcategoria"
                min={0}
                step={1}
                type="number"
                
              />
            </Col>

            <Col lg={12} md={12} xs={24}>
              <Field
                component={NumberInput}
                name="minPrice"
                label="Preço Mínimo"
                min={0}
                step={1}
                // placeholder="Insira o acrónimo da categoria"
                type="number"
              />
            </Col>
          </Row>
        </BaseForm>
      }
    </Modal>
  );
};

const selector = formValueSelector('price_form');

ReservePriceModal = reduxForm({
  form: 'price_form',
  enableReinitialize: true,
  validate: validations
})(ReservePriceModal);

ReservePriceModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default withLocalize(ReservePriceModal);