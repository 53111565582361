import client from '../config/AxiosConfig';

export const CreateWeddingInterest = async (data) =>
  client.put(`/weddingInterest/create`, data);

export const GetWeddingInterestByWeddingAndEmployee = async (weddingId, employeeId) =>
   client.get(`/weddingInterest/findAllByWeddingAndEmployee/${weddingId}/${employeeId}`);

export const GetWeddingInterestByWeddingAndEmployeeAndSubcategory = async (weddingId, employeeId, subcategoryId) =>
  client.get(`/weddingInterest/findAllByWeddingAndEmployeeAndSubcategory/${weddingId}/${employeeId}/${subcategoryId}`);

export const GetWeddingInterestByWeddingAndSubcategory = async (weddingId, subcategoryId) =>
  client.get(`/weddingInterest/findAllByWeddingAndSubcategory/${weddingId}/${subcategoryId}`);

// export const GetWeddingInterestByWedding = async (weddingId) =>
//   client.get(`/weddingInterest/findAllByWedding/${weddingId}`);

export const UpdateWeddingInterest = async (data) =>
  client.post(`/weddingInterest/update`, data);

export const DeleteWeddingInterest = async id =>
  client.delete(`/weddingInterest/remove/${id}`);