import PropTypes from 'prop-types';
import React from 'react';
import { InputDiv, InputLabelDiv, CheckboxField } from './InputStyles';
import { Radio } from 'antd';

const RadioGroup = Radio.Group;

const RadioButtons = ({ input, meta, label, first, options, disabled }) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  return (
    <InputDiv first={first}>
      {label && <InputLabelDiv>{label}</InputLabelDiv>}
      <RadioGroup
        disabled={disabled}
        options={options}
        onChange={input.onChange}
        value={input.value}
      />
    </InputDiv>
  );
};

RadioButtons.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired
};

export default RadioButtons;
