import client from '../config/AxiosConfig';

export const CreateSetting = async (data) =>
  client.put(`/setting/create`, data);

export const GetSetting = async id =>
  client.get(`/setting/find/${id}`);

  export const GetSettingsByType = async type =>
  client.get(`/setting/findByType/${type}`);

export const UpdateSetting = async (data) =>
  client.post(`/setting/update`, data);

export const DeleteSetting = async id =>
  client.delete(`/setting/remove/${id}`);

  export const UpdateGraphicsOrder = async data =>
  client.post(`/setting/graphics/order`, data);