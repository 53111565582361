import React, { useState, useEffect, useRef } from 'react';
import { PageTitle, HeaderContainer, HeaderButtonsContainer, HeaderTitle, BaseForm, FormContainer, SectionTitle } from '../../styles/BasicStyles';
import { Row, Col, Avatar, Breadcrumb, DatePicker, Checkbox, Icon, Popover } from 'antd';
import { GetSubcategories } from '../../infra/requests/SubcategoryRequests';
import BaseButton from '../../components/buttons/BaseButton';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import NoAvatar from '../../assets/no-avatar.png';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import FormValidator from '../../infra/services/validations/FormValidator';
import { Tabs } from 'antd';
import { GetEmployeeByUser, NotifyInsuranceFile, UpdateEmployee } from '../../infra/requests/EmployeeRequests';
import moment from 'moment';
import ModalPDF from './ModalPDF';
import { withLocalize } from 'react-localize-redux';
import { UpdateUser } from '../../infra/requests/UsersRequests';
import Alert from "../../components/alert/Alert";
import { getUserId, isCurrentUserAdmin, isCurrentUserEmployeeAttending, isCurrentUserHumanResources, isEmployeeOrChief } from '../../infra/helpers/Helpers';
import { GetSettingsByType } from '../../infra/requests/SettingRequests';
import styled from 'styled-components';
import { WarnColor } from '../../styles/Colours';
import { createNumberMask, createTextMask } from 'redux-form-input-masks';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const AtetionBar = styled.div`
position: relative;
width: 100%;
z-index: 49;
background-color: #ffffff;
`;
//top: ${({ small }) => small ? '138px' : '64px'};

export const AtetionBarContent = styled.div`
background-color: ${WarnColor};
color: #ffffff;
text-transform: inherit;
font-size: 16px;
text-align: center;
width: 100%;
padding: ${({ small }) => small ? '5px' : '10px'};
`;

const regexZipCode = /^\d{4}-\d{3}?$/;
const regexEmail = /^(([^<>()\\[\]\\.,;:\s@\\"]+(\.[^<>()\\[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;
const regexMobilePhoneNumber = /^ $|^2\d{8}$|^9(1|2|3|6)\d{7}$/;

const validations = values => {
    let errors = {};
    let validations = {
        name: 'required',
        citizenNumber: 'required|min:7|max:8',
        address: 'required',
        zipCode: 'required|zipCode',
        locality: 'required',
        email: 'required|email',
        notificationEmail: 'required|email',
        mobileNumber: 'required',
        emergencyNumber: 'required',
        nameInvoicing: 'required',
        nifInvoicing: 'required|min:9|max:9',
        //invoicingType: 'required',
        iban: 'required|min:25|max:25'
    };
    errors = FormValidator.make(validations)(values);

    if (values.mobileNumber && !isValidPhoneNumber(values.mobileNumber)) {
        errors.mobileNumber = 'Número de telemóvel inválido';
    }

    if (values.emergencyNumber && !isValidPhoneNumber(values.emergencyNumber)) {
        errors.emergencyNumber = 'Número de emergência inválido';
    }

    return errors;
};

// main component
let EmployeePage = (props): React$Element<React$FragmentType> => {

    const [loading, setLoading] = useState(true);

    const [showProfileError, setShowProfileError] = useState(false);
    const [showInvoicingError, setShowInvoicingError] = useState(false);

    const [profileErrorContent, setProfileErrorContent] = useState([]);
    const [invoicingErrorContent, setInvoicingErrorContent] = useState([]);

    const [employee, setEmployee] = useState(null);
    const [favoriteSubcategories, setFavoriteSubcategories] = useState([]);
    const [selectedFavoriteSubcategory, setSelectedFavoriteSubcategory] = useState([]);
    const [picture, setPicture] = useState('');
    const [pictureName, setPictureName] = useState('');
    const [insurance, setInsurance] = useState(false);
    const [insuranceValidity, setInsuranceValidity] = useState(null);
    const [insuranceFile, setInsuranceFile] = useState('');
    const [insuranceFileName, setInsuranceFileName] = useState('');
    const [showPreviewPDF, setShowPreviewPDF] = useState(false);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [invoicingTypes, setInvoicingTypes] = useState([]);
    const [selectedInvoicingType, setSelectedInvoicingType] = useState(null);
    const [updateInsurance, setUpdateInsurance] = useState(false);

    const {
        dispatch,
        handleSubmit,
        history
    } = props;

    useEffect(() => {
        if (isCurrentUserAdmin()) return this.props.history.push('/404');

        getCurrentEmployee();
        obterSubcategoriasPreferidas();
        getInvoicingTypes();
    }, [])

    useEffect(() => {
        // console.warn('EmployeeForm', props.employeeForm);

        let nameText = 'Nome';
        if (props.employeeForm.name === undefined || props.employeeForm.name === '') {
            setContentError(0, true, nameText);
        } else setContentError(0, false, nameText);

        let citizenNumberText = 'Cartão de Cidadão';
        if (props.employeeForm.citizenNumber === undefined || props.employeeForm.citizenNumber.length < 7 || props.employeeForm.citizenNumber.length > 8) {
            setContentError(0, true, citizenNumberText);
        } else setContentError(0, false, citizenNumberText);

        let addressText = 'Morada';
        if (props.employeeForm.address === undefined || props.employeeForm.address === '') {
            setContentError(0, true, addressText);
        } else setContentError(0, false, addressText);

        let zipCodeText = 'Código-postal';
        if (props.employeeForm.zipCode === undefined || props.employeeForm.zipCode === '' || !regexZipCode.test(props.employeeForm.zipCode)) {
            setContentError(0, true, zipCodeText);
        } else setContentError(0, false, zipCodeText);

        let localityText = 'Localidade';
        if (props.employeeForm.locality === undefined || props.employeeForm.locality == '') {
            setContentError(0, true, localityText);
        } else setContentError(0, false, localityText);

        let emailText = 'E-mail';
        if (props.employeeForm.email === undefined || props.employeeForm.email == '' || !regexEmail.test(props.employeeForm.email)) {
            setContentError(0, true, emailText);
        } else setContentError(0, false, emailText);

        let notificationEmailText = 'E-mail para notificações';
        if (props.employeeForm.notificationEmail === undefined || props.employeeForm.notificationEmail == '' || !regexEmail.test(props.employeeForm.notificationEmail)) {
            setContentError(0, true, notificationEmailText);
        } else setContentError(0, false, notificationEmailText);

        const mobileText = 'Telemóvel';
        // if (props.employeeForm.mobileNumber === undefined || props.employeeForm.mobileNumber == '' || !isValidPhoneNumber(props.employeeForm.mobileNumber)) {
        if (props.employeeForm.mobileNumber === undefined || props.employeeForm.mobileNumber == '' || !regexMobilePhoneNumber.test(props.employeeForm.mobileNumber)) {
            setContentError(0, true, mobileText);
        } else setContentError(0, false, mobileText);

        const emergencyText = 'Contacto de Emergência';
        // if (props.employeeForm.emergencyNumber === undefined || props.employeeForm.emergencyNumber == '' || !isValidPhoneNumber(props.employeeForm.emergencyNumber)) {
        if (props.employeeForm.emergencyNumber === undefined || props.employeeForm.emergencyNumber == '' || !regexMobilePhoneNumber.test(props.employeeForm.emergencyNumber)) {
            setContentError(0, true, emergencyText);
        } else setContentError(0, false, emergencyText);

        if (props.employeeForm.name === undefined || props.employeeForm.name === '' ||
            props.employeeForm.citizenNumber === undefined || props.employeeForm.citizenNumber.length < 7 || props.employeeForm.citizenNumber.length > 8 ||
            props.employeeForm.address === undefined || props.employeeForm.address == '' ||
            props.employeeForm.zipCode === undefined || props.employeeForm.zipCode === '' || !regexZipCode.test(props.employeeForm.zipCode) ||
            props.employeeForm.locality === undefined || props.employeeForm.locality == '' ||
            props.employeeForm.email === undefined || props.employeeForm.email == '' || !regexEmail.test(props.employeeForm.email) ||
            props.employeeForm.notificationEmail === undefined || props.employeeForm.notificationEmail == '' || !regexEmail.test(props.employeeForm.notificationEmail) ||
            props.employeeForm.mobileNumber === undefined || props.employeeForm.mobileNumber == '' || !regexMobilePhoneNumber.test(props.employeeForm.mobileNumber) ||
            props.employeeForm.emergencyNumber === undefined || props.employeeForm.emergencyNumber == '' || !regexMobilePhoneNumber.test(props.employeeForm.emergencyNumber)) {
            setShowProfileError(true);
        } else setShowProfileError(false);


        // let invoicingTypeText = 'Tipo de Faturação';
        // if (props.employeeForm.invoicingType === undefined || props.employeeForm.invoicingType === null || props.employeeForm.invoicingType === '') {
        //     setContentError(1, true, invoicingTypeText);
        // } else setContentError(1, false, invoicingTypeText);

        let invoicingNameText = 'Nome';
        if (props.employeeForm.nameInvoicing === undefined || props.employeeForm.nameInvoicing === null || props.employeeForm.nameInvoicing === '') {
            setContentError(1, true, invoicingNameText);
        } else setContentError(1, false, invoicingNameText);

        let nifText = 'NIF';
        if (props.employeeForm.nifInvoicing === undefined || props.employeeForm.nifInvoicing === null || props.employeeForm.nifInvoicing === '' || props.employeeForm.nifInvoicing === NaN || props.employeeForm.nifInvoicing.length !== 9) {
            setContentError(1, true, nifText);
        } else setContentError(1, false, nifText);

        let ibanText = 'IBAN';
        if (props.employeeForm.iban === undefined || props.employeeForm.iban === null || props.employeeForm.iban === '' || props.employeeForm.iban.includes('PT50') && props.employeeForm.iban.length !== 25 || !props.employeeForm.iban.includes('PT50') && ('PT50' + props.employeeForm.iban).length !== 25) {
            setContentError(1, true, ibanText);
        } else setContentError(1, false, ibanText);

        // props.employeeForm.entity === undefined || props.employeeForm.entity === null || props.employeeForm.entity === '' ||

        if (props.employeeForm.nameInvoicing === undefined || props.employeeForm.nameInvoicing === null || props.employeeForm.nameInvoicing === '' ||
            props.employeeForm.nifInvoicing === undefined || props.employeeForm.nifInvoicing === null || props.employeeForm.nifInvoicing === '' || props.employeeForm.nifInvoicing === NaN || props.employeeForm.nifInvoicing.length !== 9 ||
            props.employeeForm.iban === undefined || props.employeeForm.iban === null || props.employeeForm.iban === '' || props.employeeForm.iban.includes('PT50') && props.employeeForm.iban.length !== 25 || !props.employeeForm.iban.includes('PT50') && ('PT50' + props.employeeForm.iban).length !== 25) {
            setShowInvoicingError(true);
        } else setShowInvoicingError(false);
    }, [props.employeeForm])

    useEffect(() => {
        getProfileErrorContent();
    }, [profileErrorContent]);

    useEffect(() => {
        getInvoicingErrorContent();
    }, [invoicingErrorContent]);

    const setContentError = (type: number, add: boolean, text: string) => {
        if (type === 0) {
            if (add) {
                const index = profileErrorContent.findIndex(f => f === text);
                if (index === -1) {
                    profileErrorContent.push(text);
                    setProfileErrorContent([...profileErrorContent]);
                }
            } else {
                const index = profileErrorContent.findIndex(f => f === text);
                if (index > -1) {
                    profileErrorContent.splice(index, 1);
                    setProfileErrorContent([...profileErrorContent]);
                }
            }
        } else if (type === 1) {
            if (add) {
                const index = invoicingErrorContent.findIndex(f => f === text);
                if (index === -1) {
                    invoicingErrorContent.push(text);
                    setInvoicingErrorContent([...invoicingErrorContent]);
                }
            } else {
                const index = invoicingErrorContent.findIndex(f => f === text);
                if (index > -1) {
                    invoicingErrorContent.splice(index, 1);
                    setInvoicingErrorContent([...invoicingErrorContent]);
                }
            }
        }
    }

    const getProfileErrorContent = () => {
        return profileErrorContent.map(ir => <p style={{ marginBottom: 0 }}>{ir}</p>)
    }

    const getInvoicingErrorContent = () => {
        return invoicingErrorContent.map(ir => <p style={{ marginBottom: 0 }}>{ir}</p>)
    }

    const getInvoicingTypes = async () => {
        const result = await GetSettingsByType(3);

        if (result.success) setInvoicingTypes(result.data);
        else console.error(result.message);
    }

    const getCurrentEmployee = async () => {
        //const employeeId = props.match.params.id;

        //if (employeeId) {
        const result = await GetEmployeeByUser(getUserId());
        //console.warn('Emp', result.data);

        if (result.success && result.data) {
            if (!result.data.startDate) result.data.startDate = parseInt(moment().format('YYYY'));
            if (!result.data.iban) result.data.iban = 'PT50';

            setEmployee(result.data);
            setPicture(result.data.picture);
            setPictureName(result.data.pictureName);
            setInsurance(result.data.insurance);
            setInsuranceValidity(moment(new Date(result.data.insuranceValidity)));
            setInsuranceFile(result.data.insuranceFile);
            setInsuranceFileName(result.data.insuranceFileName);
            if (result?.data?.invoicingType) setSelectedInvoicingType(result.data.invoicingType.id);
            setSelectedLanguages(result.data.languages)
            setSelectedFavoriteSubcategory(result.data.favoriteSubcategories?.length > 0 ? result.data.favoriteSubcategories?.filter(x => x?.id)?.map(x => x?.id) : [])

            dispatch(
                initialize('employee_form', {
                    ...result.data
                })
            );
        }

        setLoading(false);
        //} else setLoading(true);
    }

    const obterSubcategoriasPreferidas = async () => {
        const result = await GetSubcategories();
        setFavoriteSubcategories(result.data)
    }

    const inputFileAvatar = useRef(null);
    const inputFileInsurance = useRef(null);

    //Upload File
    const onUploadButtonClick = (isAvatar) => {
        isAvatar ? inputFileAvatar.current.click() : inputFileInsurance.current.click();
    };

    const onChangeFile = (isAvatar, e) => {
        e.stopPropagation();
        e.preventDefault();
        let file = e.target.files[0];

        var reader = new FileReader();
        reader.fileName = file.name
        reader.readAsDataURL(file);
        reader.onload = function () {
            if (isAvatar) {
                setPicture(reader.result);
                setPictureName(reader.fileName);
            } else {
                setInsuranceFile(reader.result);
                setInsuranceFileName(reader.fileName);
                setUpdateInsurance(true);
            }
        };

        e.target.value = '';
    }

    //Present Insurance File
    const onPreviewPDF = () => {
        setShowPreviewPDF(true);
    }

    const closeModal = () => {
        setShowPreviewPDF(false);
    }

    //Form
    const onSubmit = async (values) => {
        //Form Validations
        if (insurance && (values.insuranceValidity === undefined)) {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Falta a validade do seguro.'
            });
        }

        if (values.nameInvoicing === undefined || values.nifInvoicing === undefined) {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Faltam dados na secção Faturação'
            });
        }

        let invoicingType;
        if (selectedInvoicingType === '' || selectedInvoicingType === null || selectedInvoicingType === undefined) {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Falta o tipo de faturação'
            });
        }
        invoicingType = invoicingTypes.find(f => f.id === selectedInvoicingType);

        if (values.iban === '' || values.iban === null) {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Falta indicar o IBAN'
            });
        }

        if (values.iban !== '' && !values.iban.includes('PT') && values.iban.length !== 25) {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'IBAN incorreto'
            });
        }

        let resultUser, resultEmployee;
        const user = {
            name: values.name,
            email: values.email,
            notificationEmail: values.notificationEmail,
            admin: employee.user.admin,
            employee: employee.user.employee,
            chief: employee.user.chief,
            humanResources: employee.user.humanResources,
            organizer: employee.user.organizer
        };

        resultUser = await UpdateUser(employee.user._id, user);
        if (!resultUser.success) console.error(resultUser.message);
        else {
            const employeeValues = {
                id: employee.id,
                favoriteSubcategories: selectedFavoriteSubcategory,
                name: values.name,
                citizenNumber: values.citizenNumber,
                address: values.address,
                zipCode: values.zipCode,
                locality: values.locality,
                email: values.email,
                notificationEmail: values.notificationEmail,
                phoneNumber: null,
                emergencyNumber: values.emergencyNumber,
                mobileNumber: values.mobileNumber,
                startDate: employee.startDate,
                fixedEmployee: employee.fixedEmployee,
                insurance: insurance,
                insuranceValidity: insuranceValidity,
                insuranceFile: insuranceFile,
                insuranceFileName: insuranceFileName,
                insuranceDetail: employee.insuranceDetail,
                iban: values.iban ? values.iban.includes('PT50') ? values.iban : ('PT50' + values.iban) : null,
                updatedIban: employee.iban !== values.iban ? true : false,
                nameInvoicing: values.nameInvoicing ? values.nameInvoicing : null,
                nifInvoicing: values.nifInvoicing ? values.nifInvoicing : null,
                invoicingNotes: employee.invoicingNotes,
                picture: picture,
                pictureName: pictureName,
                entity: employee.entity,
                invoicingType: invoicingType ? invoicingType : selectedInvoicingType,
                paymentMethod: employee.paymentMethod,
                user: resultUser.data,
                active: employee.active,
                currentUser: getUserId(),
                languages: selectedLanguages
            };

            resultEmployee = await UpdateEmployee(employeeValues);
            if (!resultEmployee.success) {
                console.error(resultEmployee.message);
                const oldUser = {
                    name: employee.user.name,
                    email: employee.user.email,
                    notificationEmail: employee.user.notificationEmail,
                    admin: employee.user.admin,
                    employee: employee.user.employee,
                    chief: employee.user.chief,
                    humanResources: employee.user.humanResources,
                    organizer: employee.user.organizer
                };
                const resultOldUser = await UpdateUser(employee.user._id, oldUser);
                if (!resultOldUser.success) console.error(resultOldUser.message);
            }

            if (updateInsurance) {
                //console.warn('Send email notifying the upload of insurance file');
                const data = {
                    userId: employee.user._id,
                    employeeId: employee.id,
                    name: values.name,
                    email: values.email,
                    notificationEmail: values.notificationEmail
                }
                await NotifyInsuranceFile(data);
                setUpdateInsurance(false);
                //console.warn('Sent');
            }
        }

        if (resultUser.success && resultEmployee.success) {
            return Alert.new({
                type: "success",
                title: "Sucesso!",
                text: 'Perfil atualizado com sucesso.'
            });
        }
    }

    const cancel = () => {
        if (employee.active) {
            if (isCurrentUserEmployeeAttending()) history.push('/home-attending');
            else history.push('/home');
        } else {
            //console.warn('Storage', localStorage);
            localStorage.clear();
            //DeleteUser();
            history.push('/login');
        }
    }

    const { TabPane } = Tabs;

    const ibanMask = createTextMask({
        pattern: 'PT50999999999999999999999',
    });

    return (
        <React.Fragment>
            <HeaderContainer>
                <HeaderTitle buttons={0}>
                    <PageTitle>Perfil</PageTitle>
                </HeaderTitle>
                <HeaderButtonsContainer buttons={2}>
                    <BaseButton
                        type="primary"
                        icon="save"
                        text="Gravar"
                        onClick={handleSubmit((values) => onSubmit(values))}
                    />
                    <BaseButton
                        type="default"
                        icon="close"
                        text="Cancelar"
                        onClick={() => cancel()}
                    />
                </HeaderButtonsContainer>
            </HeaderContainer>

            <AtetionBar small={false} className='profile-atention-bar'>
                <AtetionBarContent small={false}>
                    ATENÇÃO: Deve preencher os campos obrigatórios de todos os separadores
                </AtetionBarContent>
            </AtetionBar>

            <FormContainer style={{ marginTop: '1rem' }}>
                <BaseForm onSubmit={handleSubmit(onSubmit)}>
                    <Tabs tabPosition="left">
                        <TabPane tab={
                            showProfileError ?
                                <Popover content={getProfileErrorContent()} title={<b style={{ color: 'red' }}>Erro</b>}>
                                    <span style={{ color: 'red' }}>
                                        <Icon type="user" />
                                        <span className='tab-name'>Perfil</span>
                                    </span>
                                </Popover> :
                                <span>
                                    <Icon type="user" />
                                    <span className='tab-name'>Perfil</span>
                                </span>
                        } key="1">
                            <div style={{ width: '100%', margin: 'auto' }}>
                                <SectionTitle>Perfil</SectionTitle>
                                <Row>
                                    <Col style={{ textAlign: 'center' }}>
                                        <Avatar size={96} onClick={e => onUploadButtonClick(true)} name="picture" alt="" style={{ cursor: 'pointer' }} src={picture == '' ? NoAvatar : picture} />
                                        {picture !== '' ? <p onClick={() => { setPicture('') }} style={{ userSelect: 'none', cursor: 'pointer', marginTop: 5, marginBottom: -10 }}>Remover Imagem de Perfil</p> : null}
                                        <input type='file' id='file' ref={inputFileAvatar} style={{ display: 'none' }} accept="image/*" onChange={e => onChangeFile(true, e)} />
                                    </Col>
                                </Row>
                                <Row gutter={12}>
                                    <Col sm={12} md={12}>
                                        <Field
                                            component={TextInput}
                                            name="name"
                                            label="Nome Completo *"
                                            placeholder="Insira o nome"
                                            type="text"
                                            requiredError={profileErrorContent.find(f => f === 'Nome')}
                                        />
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <Field
                                            component={TextInput}
                                            name="citizenNumber"
                                            label="Cartão de Cidadão *"
                                            placeholder="ex: 11111111"
                                            type="number"
                                            maxLength={8}
                                            requiredError={profileErrorContent.find(f => f === 'Cartão de Cidadão')}
                                        />
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <Field
                                            component={TextInput}
                                            name="address"
                                            label="Morada Completa *"
                                            placeholder="Insira a morada"
                                            type="text"
                                            requiredError={profileErrorContent.find(f => f === 'Morada')}
                                        />
                                    </Col>
                                    <Col sm={6} md={5}>
                                        <Field
                                            component={TextInput}
                                            name="zipCode"
                                            label="Código-Postal *"
                                            placeholder="ex: 0000-000"
                                            type="text"
                                            maxLength={8}
                                            requiredError={profileErrorContent.find(f => f === 'Código-postal')}
                                        />
                                    </Col>
                                    <Col sm={6} md={7}>
                                        <Field
                                            component={TextInput}
                                            name="locality"
                                            label="Localidade *"
                                            placeholder="Insira a localidada"
                                            type="text"
                                            requiredError={profileErrorContent.find(f => f === 'Localidade')}
                                        />
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <Field
                                            component={TextInput}
                                            name="email"
                                            inputMode="email"
                                            label="E-mail *"
                                            placeholder="Insira o e-mail"
                                            type="email"
                                            disabled={true}
                                            requiredError={profileErrorContent.find(f => f === 'Email')}
                                        />
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <Field
                                            component={TextInput}
                                            name="notificationEmail"
                                            inputMode="email"
                                            label="E-mail para notificações *"
                                            placeholder="Insira o e-mail"
                                            type="email"
                                            requiredError={profileErrorContent.find(f => f === 'E-mail para notificações')}
                                        />
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <Field
                                            component={TextInput}
                                            name="mobileNumber"
                                            label="Telemóvel *"
                                            placeholder="ex: 911111111"
                                            type="tel"
                                            requiredError={profileErrorContent.find(f => f === 'Telemóvel')}
                                        />
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <Field
                                            component={TextInput}
                                            name="emergencyNumber"
                                            label="Contacto de Emergência *"
                                            placeholder="ex: 911111111"
                                            type="tel"
                                            requiredError={profileErrorContent.find(f => f === 'Contacto de Emergência')}
                                        />
                                    </Col>
                                    {!isCurrentUserEmployeeAttending() &&
                                        <Col sm={12} md={12}>
                                            <Field
                                                component={SelectInput}
                                                mode="multiple"
                                                allowClear
                                                name="favoriteSubcategories"
                                                data={favoriteSubcategories}
                                                required
                                                input={{
                                                    value: selectedFavoriteSubcategory, onChange: event => setSelectedFavoriteSubcategory(event)
                                                }}
                                                label="Posições de Preferência (seleção múltipla)"
                                                placeholder="Seleciona subcategorias"
                                            />
                                        </Col>}
                                    <Col sm={12} md={12}>
                                        <Field
                                            component={SelectInput}
                                            mode="multiple"
                                            allowClear
                                            name="languages"
                                            data={[
                                                { name: 'Português', _id: 'portuguese' },
                                                { name: 'Espanhol', _id: 'spanish' },
                                                { name: 'Inglês', _id: 'english' },
                                                { name: 'Francês', _id: 'french' },
                                                { name: 'Alemão', _id: 'deutch' }
                                            ]}
                                            required
                                            input={{
                                                value: selectedLanguages, onChange: event => setSelectedLanguages(event)
                                            }}
                                            label="Idioma (seleção múltipla)"
                                            placeholder="Selecione os idiomas"
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </TabPane>

                        <TabPane tab={
                            <span>
                                <Icon type="safety" />
                                <span className='tab-name'>Seguro</span>
                            </span>
                        } key="2">
                            <div style={{ width: '100%', margin: 'auto' }}>
                                <SectionTitle>Seguro</SectionTitle>
                                {/* <Row style={{ display: 'inline-flex', width: '100%' }}>
                                    <div className="sc-hMqMXs liDmfg" style={{ width: '160px' }}>Funcionário tem seguro?</div>
                                    <Checkbox style={{ marginTop: 5 }} checked={insurance} onChange={(e) => { setInsurance(e.target.checked) }}>Sim</Checkbox>
                                </Row> */}
                                <Row gutter={12}>
                                    <Col sm={12} md={12}>
                                        <div className="sc-dxgOiQ cGTQcX">
                                            <div className="sc-hMqMXs liDmfg">Data de Validade</div>
                                            <DatePicker value={insuranceValidity} placeholder='Selecione Data' onChange={(e) => { setInsuranceValidity(e) }} style={{ width: '100%' }} />
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <input type='file' id='fileInsurance' ref={inputFileInsurance} accept="application/pdf" style={{ display: 'none' }} onChange={e => onChangeFile(false, e)} />

                                        <div className="sc-dxgOiQ cGTQcX">
                                            <div className="sc-hMqMXs liDmfg">Documento do Seguro</div>
                                            <div style={{ height: 32, padding: '4px 11px', color: 'rgba(0,0,0, 0.65)', backgroundColor: '#fff', border: '1px solid #d9d9d9', borderRadius: '4px', transition: 'all 0.3s', position: 'relative' }}>
                                                {insuranceFile === '' ? <Icon onClick={e => onUploadButtonClick(false)} style={{ position: 'absolute', right: 11, top: 8 }} type={'upload'} /> :
                                                    (<div style={{ position: 'relative', paddingRight: 14 }}>
                                                        <span style={{ display: 'block', overflowWrap: 'break-word' }} onClick={e => onPreviewPDF()}>{insuranceFileName}</span>
                                                        <Icon type={'close-circle'} style={{ position: 'absolute', right: 0, top: 4 }} onClick={e => setInsuranceFile('')} />
                                                    </div>)}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </TabPane>

                        <TabPane tab={
                            showInvoicingError ?
                                <Popover content={getInvoicingErrorContent()} title={<b style={{ color: 'red' }}>Erro</b>}>
                                    <span style={{ color: 'red' }}>
                                        <Icon type="user" />
                                        <span className='tab-name'>Faturação</span>
                                    </span>
                                </Popover> :
                                <span>
                                    <Icon type="euro" />
                                    <span className='tab-name'>Faturação</span>
                                </span>
                        } key="3">
                            <div style={{ width: '100%', margin: 'auto' }}>
                                <SectionTitle>Faturação</SectionTitle>
                                <Row gutter={12}>
                                    <Col lg={12} md={12} sm={12} xs={24}>
                                        <Field
                                            component={SelectInput}
                                            name="invoicingType"
                                            // allowClear
                                            label="Tipo de Faturação *"
                                            placeholder="Selecione o tipo"
                                            data={invoicingTypes}
                                            input={{
                                                value: selectedInvoicingType,
                                                onChange: event => {
                                                    setSelectedInvoicingType(event);
                                                }
                                            }}
                                            requiredError={invoicingErrorContent.find(f => f === 'Tipo de Faturação')}
                                        />
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={24}>
                                        <Field
                                            component={TextInput}
                                            name="nameInvoicing"
                                            label="Nome Completo *"
                                            placeholder="Insira o nome"
                                            type="text"
                                            requiredError={invoicingErrorContent.find(f => f === 'Nome')}
                                        />
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={24}>
                                        <Field
                                            component={TextInput}
                                            name="nifInvoicing"
                                            label="NIF *"
                                            placeholder="ex: 111111111"
                                            type="number"
                                            maxLength={9}
                                            requiredError={invoicingErrorContent.find(f => f === 'NIF')}
                                        />
                                    </Col>
                                    <Col lg={24} md={24} sm={24} xs={24}>
                                        <Field
                                            component={TextInput}
                                            name="iban"
                                            label="IBAN *"
                                            placeholder="ex: PT50111111111111111111111"
                                            type="text"
                                            maxLength={25}
                                            requiredError={invoicingErrorContent.find(f => f === 'IBAN')}
                                        // {...ibanMask}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </TabPane>
                    </Tabs>
                </BaseForm>
            </FormContainer>

            {showPreviewPDF && <ModalPDF
                openModal={showPreviewPDF}
                closeModal={closeModal}
                title='Documento do Seguro'
                file={insuranceFile}
            />}
        </React.Fragment>
    );
};

const selector = formValueSelector('employee_form');

EmployeePage = reduxForm({
    form: 'employee_form',
    validate: validations
})(EmployeePage);

const mapStateToProps = state => ({
    employeeForm: {
        name: selector(state, 'name'),
        citizenNumber: selector(state, 'citizenNumber'),
        address: selector(state, 'address'),
        zipCode: selector(state, 'zipCode'),
        locality: selector(state, 'locality'),
        email: selector(state, 'email'),
        notificationEmail: selector(state, 'notificationEmail'),
        mobileNumber: selector(state, 'mobileNumber'),
        favoriteSubcategories: selector(state, 'favoriteSubcategories'),
        emergencyNumber: selector(state, 'emergencyNumber'),
        languages: selector(state, 'languages'),
        startDate: selector(state, 'startDate'),
        insuranceDetail: selector(state, 'insuranceDetail'),
        invoicingType: selector(state, 'invoicingType'),
        nameInvoicing: selector(state, 'nameInvoicing'),
        entity: selector(state, 'entity'),
        nifInvoicing: selector(state, 'nifInvoicing'),
        iban: selector(state, 'iban'),
        paymentMethod: selector(state, 'paymentMethod'),
        paymentValue: selector(state, 'paymentValue'),
        invoicingNotes: selector(state, 'invoicingNotes'),
    }
});

export default withLocalize(connect(mapStateToProps)(EmployeePage));