import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Modal } from 'antd';
import {
  Field,
  reduxForm,
  initialize,
  change as ChangeField,
  formValueSelector,
  FormSection
} from 'redux-form';
import { ModalButton, Form } from './ExportWeddingStyles';
import { WeddingsCostumizations, WeddingsCostumeDefinitions, WeddingsDefaultCustomizations, WeddingsCustomizationsForm } from './WeddingsCostumizations';
import { DownloadWeddings } from '../../../infra/requests/WeddingRequests';
import moment from 'moment';
import Alerts from '../../../components/alert/Alert';
import CheckboxInput from '../../../components/inputs/CheckboxInput';
import SelectInput from '../../../components/inputs/SelectInput';
import FormValidator from '../../../infra/services/validations/FormValidator';
import TextInput from '../../../components/inputs/TextInput';
import XpertGoDateInput from '../../../components/inputs/XpertGoDateInput';
import XpertGoRangePickerInput from '../../../components/inputs/XpertGoRangePickerInput';
import { WeddingStatus } from '../../reserves/ReserveAgenda';
import { printedOptions, weddingStatus } from '../WeddingsFilters';
import MonthInput from '../../../components/inputs/MonthInput';

const validations = (values) => {
  let errors = {};
  const validate = {};

  if (values?.definition == 'coordinator'
    || values?.definition == 'host'
    || values?.definition == 'wcAssistant') {
    validate['month'] = 'required';
  }
  errors = FormValidator.make(validate)(values);

  return errors;
};

let ExportWeddingsModal = ({
  isOpen,
  handleSubmit,
  submit,
  onClose,
  dispatch,
  definition,
  search,
  date,
  dates,
  month,
  wedding_place,
  status,
  printed,
  locals
}) => {
  const [download, setDownload] = useState(false);

  const onSubmit = async (values) => {
    setDownload(true);

    const data = { ...values };
    if (data?.dates && Array.isArray(data?.dates) && data?.dates?.length == 0) {
      delete data.dates;
    }

    const fieldsToCheck = WeddingsCustomizationsForm;
    if (!data?.columns || fieldsToCheck.every((field) => !data?.columns[field])) {
      setDownload(false);
      return Alerts.new({
        type: 'warning',
        title: 'Atenção',
        text: 'Selecione uma coluna',
      });
    }

    if (data?.columns?.payed || data?.columns?.payedPercentage) {
      if (!data?.date && !data?.month && (!data?.dates || (Array.isArray(data?.dates) && data?.dates?.length == 0))) {
        setDownload(false);
        return Alerts.new({
          type: 'warning',
          title: 'Atenção',
          text: 'Deve selecionar uma data, mês ou intervalo de datas!'
        });
      } else if (!data?.date && !data?.month && Array.isArray(data?.dates)
        && moment.utc(data?.dates[1]).diff(moment.utc(data?.dates[0]), 'days') > 30) {
        setDownload(false);
        return Alerts.new({
          type: 'warning',
          title: 'Atenção',
          text: 'Deve selecionar um intervalo de datas até 1 mês!'
        });
      }
    }

    // Export all reserves information
    if (data?.search == null || data?.search == '') delete data.search;
    if (data?.date == null || data?.date == '') delete data.date;
    if (data?.dates == null || data?.dates?.length == 0) delete data.dates;
    if (data?.month == null || data?.month == '') delete data.month;
    if (data?.wedding_place == null || data?.wedding_place == '') delete data.wedding_place;
    if (data?.status == null || data?.status == '') delete data.status;
    if (data?.printed == null) delete data.printed;
    const result = await DownloadWeddings(JSON.stringify(data));

    if (result) {
      let filename = 'Casamentos_' + moment().format('YYYYMMDD') + '.xlsx';

      if (result?.headers) {
        const contentDisposition = result.headers.get('content-disposition');
        filename = contentDisposition.split('filename="')[1].split('"')[0];
      }

      //Create blob link to download
      const url = window.URL.createObjectURL(new Blob([result])); //result.blob
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);

      //Append to html link element page
      document.body.appendChild(link);

      //Start download
      link.click();

      //Clean up and remove the link
      link.parentNode.removeChild(link);
      onClose();
    }

    setDownload(false);
  };

  const handleCheckChange = () => {
    if (definition) {
      dispatch(ChangeField('export_weddings_form', 'definition', ''));
    }
  };

  const handleCostumeChange = (option) => {
    let values = { ...WeddingsDefaultCustomizations };

    if (option) {
      const data = WeddingsCostumizations[option];

      values['date'] = values?.date || data?.date ? true : false;
      values['weddingPlace'] = values?.weddingPlace || data?.weddingPlace ? true : false;
      values['coupleNames'] = values?.coupleNames || data?.coupleNames ? true : false;
      values['coupleEmails'] = values?.coupleEmails || data?.coupleEmails ? true : false;
      values['coupleContacts'] = values?.coupleContacts || data?.coupleContacts ? true : false;
      values['price'] = values?.price || data?.price ? true : false;
      values['minPax'] = values?.minPax || data?.minPax ? true : false;
      values['expectedPax'] = values?.expectedPax || data?.expectedPax ? true : false;
      values['language'] = values?.language || data?.language ? true : false;
      values['weddingDayLanguage'] = values?.weddingDayLanguage || data?.weddingDayLanguage ? true : false;
      values['organizerLanguage'] = values?.organizerLanguage || data?.organizerLanguage ? true : false;
      values['organizers'] = values?.organizers || data?.organizers ? true : false;
      values['payed'] = values?.payed || data?.payed ? true : false;
      values['payedPercentage'] = values?.payedPercentage || data?.payedPercentage ? true : false;
      values['paymentNotes'] = values?.paymentNotes || data?.paymentNotes ? true : false;
      values['reserveNotes'] = values?.reserveNotes || data?.reserveNotes ? true : false;
      values['proofs'] = values?.proofs || data?.proofs ? true : false;
      values['meetings'] = values?.meetings || data?.meetings ? true : false;
      values['printed'] = values?.printed || data?.printed ? true : false;
      values['arrivingTime'] = values?.arrivingTime || data?.arrivingTime ? true : false;
    }

    // console.warn('val end', values);
    const resetFilters = option == 'coordinator'
      || option == 'host'
      || option == 'wcAssistant'
      ? true
      : false;
    dispatch(
      initialize('export_weddings_form', {
        search: resetFilters ? '' : search,
        date: resetFilters ? null : date,
        dates: resetFilters ? [] : dates,
        month: resetFilters ? null : month,
        wedding_place: resetFilters ? null : wedding_place,
        status: resetFilters ? null : status,
        printed: resetFilters ? null : printed,
        definition: option,
        columns: values,
      })
    );
  };

  return (
    <Modal
      visible={isOpen}
      title="Exportar casamentos"
      // maskClosable
      onCancel={() => download ? null : onClose()}
      footer={[
        <Button
          key='cancel'
          type='default'
          disabled={download}
          onClick={() => onClose()}>
          Cancelar
        </Button>,
        <Button
          key='submit'
          type='primary'
          loading={download}
          disabled={download}
          onClick={handleSubmit(onSubmit)}>
          Exportar
        </Button>
      ]}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Field
          component={SelectInput}
          name={'definition'}
          label={'Pré-definidas'}
          placeholder="Customizado"
          data={WeddingsCostumeDefinitions}
          onChange={handleCostumeChange}
          allowClear={true}
        />
        <FormSection name="columns">
          <Field
            component={CheckboxInput}
            name="date"
            label="Colunas"
            onChange={handleCheckChange}
            positiveLabel="Data"
          />
          <Field
            component={CheckboxInput}
            name="weddingPlace"
            onChange={handleCheckChange}
            positiveLabel="Local"
          />
          <Field
            component={CheckboxInput}
            name="coupleNames"
            onChange={handleCheckChange}
            positiveLabel="Nome do casal"
          />
          <Field
            component={CheckboxInput}
            name="coupleEmails"
            onChange={handleCheckChange}
            positiveLabel="E-mails do casal"
          />
          <Field
            component={CheckboxInput}
            name="coupleContacts"
            positiveLabel="Contactos do casal"
            onChange={handleCheckChange}
          />
          <Field
            component={CheckboxInput}
            name="price"
            positiveLabel="Preço p/PAX"
            onChange={handleCheckChange}
          />
          <Field
            component={CheckboxInput}
            name="minPax"
            positiveLabel="PAX mínimo"
            onChange={handleCheckChange}
          />
          <Field
            component={CheckboxInput}
            name="expectedPax"
            positiveLabel="PAX previsto"
            onChange={handleCheckChange}
          />
          <Field
            component={CheckboxInput}
            name="language"
            onChange={handleCheckChange}
            positiveLabel="Idioma de comunicação (plataforma)"
          />
          <Field
            component={CheckboxInput}
            name="weddingDayLanguage"
            onChange={handleCheckChange}
            positiveLabel="Idioma no dia do casamento"
          />
          <Field
            component={CheckboxInput}
            name="organizerLanguage"
            onChange={handleCheckChange}
            positiveLabel="Idioma de comunicação (organizador)"
          />
          <Field
            component={CheckboxInput}
            name="organizers"
            onChange={handleCheckChange}
            positiveLabel="Organizador(es)"
          />
          <Field
            component={CheckboxInput}
            name="payed"
            onChange={handleCheckChange}
            positiveLabel="Valor pago"
          />
          <Field
            component={CheckboxInput}
            name="payedPercentage"
            onChange={handleCheckChange}
            positiveLabel="Valor pago (%)"
          />
          <Field
            component={CheckboxInput}
            name="paymentNotes"
            onChange={handleCheckChange}
            positiveLabel="Notas de pagamento"
          />
          <Field
            component={CheckboxInput}
            name="reserveNotes"
            onChange={handleCheckChange}
            positiveLabel="Notas de reserva"
          />
          <Field
            component={CheckboxInput}
            name="proofs"
            onChange={handleCheckChange}
            positiveLabel="Provas"
          />
          <Field
            component={CheckboxInput}
            name="meetings"
            onChange={handleCheckChange}
            positiveLabel="Reuniões"
          />
          <Field
            component={CheckboxInput}
            name="printed"
            onChange={handleCheckChange}
            positiveLabel="Processo impresso"
          />
          <Field
            component={CheckboxInput}
            name="arrivingTime"
            onChange={handleCheckChange}
            positiveLabel="Chegada à quinta"
          />
        </FormSection>

        {definition != 'coordinator'
          && definition != 'host'
          && definition != 'wcAssistant'
          && <React.Fragment>
            <Field
              component={TextInput}
              name={'search'}
              label="Pesquisar"
              placeholder="Procurar por nomes ou emails"
            />
            <Field
              component={XpertGoDateInput}
              name={'date'}
              label="Data"
              placeholder="Escolher data"
              onChange={(value) => {
                dispatch(ChangeField('export_weddings_form', 'dates', []));
                dispatch(ChangeField('export_weddings_form', 'month', null));
              }}
            />
            <Field
              component={XpertGoRangePickerInput}
              name={'dates'}
              label="Intervalo de datas"
              placeholder="Escolher datas"
              onChange={(value) => {
                dispatch(ChangeField('export_weddings_form', 'date', null));
                dispatch(ChangeField('export_weddings_form', 'month', null));
              }}
            />
          </React.Fragment>}
        {(definition == 'coordinator'
          || definition == 'host'
          || definition == 'wcAssistant')
          && <Field
            component={MonthInput}
            name={'month'}
            label="Mês"
            placeholder="Escolher mês"
            onChange={(value) => {
              dispatch(ChangeField('export_weddings_form', 'date', null));
              dispatch(ChangeField('export_weddings_form', 'dates', []));
            }}
          />}
        {definition != 'coordinator'
          && definition != 'host'
          && definition != 'wcAssistant'
          && <React.Fragment>
            <Field
              component={SelectInput}
              name={'wedding_place'}
              label={'Local'}
              placeholder="Escolher local"
              data={locals}
              allowClear={true}
            />
            <Field
              component={SelectInput}
              name={'status'}
              label={'Estado'}
              placeholder="Escolher estado"
              data={weddingStatus}
              dataKey='value'
              allowClear={true}
            />
            <Field
              component={SelectInput}
              name={'printed'}
              label={'Impresso'}
              placeholder="Escolher opção"
              data={printedOptions}
              dataKey='value'
              allowClear={true}
            />
          </React.Fragment>}
      </Form>
    </Modal >
  );
};

ExportWeddingsModal = reduxForm({
  form: 'export_weddings_form',
  validate: validations
})(ExportWeddingsModal);

const selector = formValueSelector('export_weddings_form');

const mapStateToProps = state => ({
  search: selector(state, 'search'),
  date: selector(state, 'date'),
  dates: selector(state, 'dates'),
  month: selector(state, 'month'),
  wedding_place: selector(state, 'wedding_place'),
  status: selector(state, 'status'),
  printed: selector(state, 'printed'),
  definition: selector(state, 'definition'),
  locals: state.locals
});

export default connect(mapStateToProps)(ExportWeddingsModal);
