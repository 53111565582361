import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector, change } from 'redux-form';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { BaseForm, SpinLoading } from '../../styles/BasicStyles';
import PropTypes from 'prop-types';
import DraftInput from '../../components/inputs/DraftInput';
import SelectInput from '../../components/inputs/SelectInput';
import { isCurrentUserAdmin } from '../../infra/helpers/Helpers';
import { GetAttendingEmployees, GetWeddingGuests } from '../../infra/requests/UsersRequests';
import TextInput from '../../components/inputs/TextInput';
import SelectCompactInput from '../../components/inputs/SelectCompactInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';

const validations = (values) => {
    let errors = {};

    errors = FormValidator.make({
        guestName: 'required',
        guestEmail: 'required|email',
    })(values);

    return errors;
};

let WeddingGuestModal = ({
    isOpen,
    saving,
    handleSubmit,
    initialValues,
    onSubmit,
    onClose,
    guestForm,
    change
}) => {
    const [loadingGuests, toggleLoadingGuests] = useState(false);
    const [guests, setGuests] = useState([]);

    useEffect(() => {
        getWeddingGuests();
    }, [isOpen]);

    const getWeddingGuests = async () => {
        toggleLoadingGuests(true);
        const result = await GetWeddingGuests();
        let data = result?.success && result?.data ? result.data : [];
        if (Array.isArray(data) && data.length > 0) {
            data = data
                .filter(f => f?.email !== initialValues?.alternativeGuest?.email)
                .map(m => ({ ...m, label: `${m?.name} (${m?.email})` }))
        }
        setGuests(data);
        toggleLoadingGuests(false);
    }

    return (
        <Modal
            visible={isOpen}
            title='Alterar ou Criar Utilizador Convidado'
            maskClosable
            onCancel={onClose}
            footer={[
                <Button
                    key='cancel'
                    type='default'
                    onClick={onClose}>
                    Cancelar
                </Button>,
                <Button
                    key='submit'
                    type='primary'
                    loading={saving}
                    onClick={handleSubmit(onSubmit)}>
                    Gravar
                </Button>
            ]}>

            {loadingGuests
                ? <SpinLoading />
                : <BaseForm onSubmit={handleSubmit(onSubmit)}>
                    <Field
                        component={SelectInput}
                        name={'guest'}
                        label={'Utilizador Convidado'}
                        placeholder={'Selecione o convidado'}
                        allowClear={true}
                        type='select'
                        data={guests || []}
                        dataLabel='label'
                        onChange={(e) => {
                            change('guest', e);
                            change('guestExisted', e && e !== '' ? true : false);
                            if (e && e !== '') {
                                const guestSelected = guests.find(f => f?._id == e);
                                change('guestName', guestSelected?.name || '');
                                change('guestEmail', guestSelected?.email || '');
                            } else {
                                change('guestName', '');
                                change('guestEmail', '');
                            }
                        }}
                    />

                    {/* <Field
                        component={CheckboxInput}
                        name={'guestExisted'}
                        label={'Utilizador já existe'}
                    /> */}

                    <Field
                        component={TextInput}
                        name={'guestName'}
                        type="text"
                        label={'Nome do Convidado'}
                        placeholder={'Insira o nome convidado'}
                    />
                    <Field
                        component={TextInput}
                        name={'guestEmail'}
                        type="email"
                        label={'Email do Convidado'}
                        placeholder={'Email de acesso convidado'}
                        disabled={guestForm?.guest && guestForm?.guest !== '' ? true : false}
                    />
                </BaseForm>}
        </Modal>
    );
};

WeddingGuestModal = reduxForm({
    form: 'guest_form',
    enableReinitialize: true,
    validate: validations,
})(WeddingGuestModal);

const selector = formValueSelector('guest_form');

const mapStateToProps = state => ({
    guestForm: {
        guest: selector(state, 'guest'),
        guestExisted: selector(state, 'guestExisted'),
        guestName: selector(state, 'guestName'),
        guestEmail: selector(state, 'guestEmail'),
    }
});

WeddingGuestModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(WeddingGuestModal)