import client from '../config/AxiosConfig';

export const CreateTableType = async (data) =>
  client.post(`/tableType`, data);

export const GetTableType = async id =>
  client.get(`/tableType/${id}`);

export const GetTableTypeList = async (filters) =>
  client.get(`/tableType/list?filter=${JSON.stringify(filters)}`);

export const GetTableTypes = async (currentPage, limit, filters) =>
  client.get(`/tableType/${currentPage}/${limit}?filter=${JSON.stringify(filters)}`);

export const UpdateTableType = async (id, data) =>
  client.put(`/tableType/${id}`, data);

export const RestoreTableType = async id =>
  client.put(`/tableType/${id}/restore`);

export const DeleteTableType = async id =>
  client.delete(`/tableType/${id}`);