import React, {Fragment} from 'react';
import Routes from './routes/routes';
import {BasicStyles} from './styles/BasicStyles';
import { ResponsiveStyles } from './styles/ResponsiveStyles';

const App = () => {
  return (
    <Fragment>
      <Routes />
      <BasicStyles />
      <ResponsiveStyles />
    </Fragment>
  );
};

export default App;
