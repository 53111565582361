import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Form as AntForm, Modal } from 'antd';
import {
  Field,
  reduxForm,
  formValueSelector,
  change as ChangeField
} from 'redux-form';
import { Element } from 'react-scroll';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import FormValidator from '../../infra/services/validations/FormValidator';
import NumberInput from '../../components/inputs/NumberInput';
import { BaseButton } from '../../components/buttons/BaseButton';
import { getGuestPrintedConfirmation } from '../../infra/services/wedding/printedUtils';
import { inRoomPlanTest } from '../../infra/helpers/Helpers';

const { confirm } = Modal;

const Form = styled(AntForm)`
  display: inline-block;
  width: 100%;
  margin: 30px 0;
`;

const SubmitSection = styled.div`
  margin: 30px 0;
  float: right;
  width: 150px;
`;

const Title = styled.div`
  display: inline-block;
  width: 100%;
  font-size: 28px;
  margin-top: 30px;
`;

const Message = styled.div`
  display: inline-block;
  width: 100%;
`;

const validations = FormValidator.make({
  guests: 'isInteger',
  children: 'isInteger',
  staff: 'isInteger',
  babies: 'isInteger',
  places: 'isInteger',
  tables: 'isInteger'
});

const fieldNames = [
  'guests',
  'children',
  'staff',
  'babies',
  'pax',
  'places',
  'tables'
];

let GuestsForm = ({
  questions = [],
  handleSubmit,
  dispatch,
  submitGuests,
  loading,
  guests = 0,
  children = 0,
  staff = 0,
  babies = 0,
  guestsInfo,
  printed
}) => {
  //Trick to disable scroll on "number" inputs
  document.addEventListener('wheel', function (e) {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  });

  const handleBlur = index => {
    if (
      index < 4 &&
      Number.isInteger(parseInt(guests)) &&
      Number.isInteger(parseInt(children)) &&
      Number.isInteger(parseInt(staff)) &&
      Number.isInteger(parseInt(babies))
    ) {
      const pax = parseInt(guests) + (parseInt(children) / 2) + (parseInt(staff) / 2);
      const places = parseInt(guests) + parseInt(children) + parseInt(staff) + parseInt(babies);
      dispatch(ChangeField('manage_guests_form', 'pax', pax));
      dispatch(ChangeField('manage_guests_form', 'places', places));

      if (!guestsInfo.completed) {
        const tables = Math.ceil((parseInt(guests) + parseInt(children)) / 10);
        dispatch(ChangeField('manage_guests_form', 'tables', tables));
      }
    }
  };

  const submitForm = (values) => {
    if (printed) {
      confirm({
        ...getGuestPrintedConfirmation(),
        onOk: () => { submitGuests(values); },
        onCancel: () => { },
      });
    } else submitGuests(values);
  }

  const chapterIndex = inRoomPlanTest() ? 4 : 2;
  return (
    // <Form onSubmit={handleSubmit(submitGuests)}>
    <Form onSubmit={handleSubmit(submitForm)}>
      {questions.map((question, qIndex) => (
        <Element key={`anchor-${chapterIndex}-${qIndex}`} name={`anchor-${chapterIndex}-${qIndex}`}>
          {qIndex === chapterIndex && (
            <Fragment>
              <Title>Calculos automáticos</Title>
              <Message>
                Da primeira vez que editar os campos acima, esta secção vai calcular automaticamente. Após isso apenas o campo "Número de mesas" NÃO irá ser calculado automaticamente.
                Podem editar estes campos em qualquer altura.
              </Message>
            </Fragment>
          )}
          <Field
            component={NumberInput}
            name={fieldNames[qIndex]}
            min={0}
            precision={question._id === '5e2ae16b47f38787fd9e8430' ? 1 : 0}
            step={question._id === '5e2ae16b47f38787fd9e8430' ? '0.5' : 1}
            label={TranslateValue(question.title)}
            placeholder={TranslateValue(question.title)}
            onBlur={() => inRoomPlanTest() ? null : handleBlur(qIndex)}
            disabled={inRoomPlanTest() ? true : false}
          />
        </Element>
      ))}

      {!inRoomPlanTest() && <SubmitSection>
        <BaseButton
          type={'primary'}
          block
          icon={'save'}
          loading={loading}
          htmlType="submit"
          text={'Gravar'}
        />
      </SubmitSection>}
    </Form>
  );
};

const selector = formValueSelector('manage_guests_form');

GuestsForm = reduxForm({
  form: 'manage_guests_form',
  validate: validations
})(GuestsForm);

const mapStateToProps = state => ({
  guests: selector(state, 'guests'),
  children: selector(state, 'children'),
  staff: selector(state, 'staff'),
  babies: selector(state, 'babies')
});

export default connect(mapStateToProps)(GuestsForm);
