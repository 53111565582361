import client from '../config/AxiosConfig';

export const GetScheduleService = async id =>
  client.get(`/scheduleService/${id}`);

export const GetScheduleServices = async (currentPage, limit, filters) =>
  client.get(`/scheduleService/${currentPage}/${limit}?filter=${filters}`);

export const GetCalendarScheduleServices = async (filters) =>
  client.get(`/scheduleService/calendar?filter=${filters}`);

export const GetScheduleServicesList = async (filters) =>
  client.get(`/scheduleService?filter=${JSON.stringify(filters)}`);

export const UpdateScheduleService = async (id, data) =>
  client.put(`/scheduleService/${id}`, data);

export const CreateScheduleService = async data =>
  client.post('/scheduleService', data);

export const CreateRecursiveScheduleService = async data =>
  client.post('/scheduleService/recursive', data);

export const UpdateRecursiveScheduleService = async (id, data) =>
  client.put(`/scheduleService/recursive/${id}`, data);

// export const CheckAppointment = async data =>
//   client.post('/scheduleService/check', data);

export const DeleteScheduleService = async (id, data) =>
  client.post(`/scheduleService/delete/${id}`, data);
