import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import SelectInput from '../../components/inputs/SelectInput';
import RangePickerInput from '../../components/inputs/RangePickerInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
class OrdersFilters extends Component {
  state = {
    wedding_place: '',
    dates: [moment().utc(true), moment().utc(true).add(1, 'week')],
    category: '',
    archived: false,
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map(key => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const { locals, categories } = this.props;
    const { category, wedding_place, dates, archived } = this.state;
    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={5}>
            <SelectInput
              label="Categoria"
              allowClear
              placeholder="Escolher categoria da encomenda"
              data={categories}
              input={{
                value: category,
                onChange: value => this.onInputChange('category', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} md={6}>
            <RangePickerInput
              label="Intervalo de datas"
              allowClear={false}
              placeholder="Escolher datas para exportar"
              input={{
                value: dates,
                onChange: value => this.onInputChange('dates', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} md={5}>
            <SelectInput
              label="Local"
              allowClear
              placeholder="Escolher local do casamento"
              data={locals}
              input={{
                value: wedding_place,
                onChange: value => this.onInputChange('wedding_place', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} md={2}>
            <CheckboxInput
              label='Arquivado'
              positiveLabel='Sim'
              input={{
                value: archived,
                onChange: e => this.onInputChange('archived', e.target.checked)
              }}
              meta={{}} />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

const mapStateToProps = state => ({
  locals: state.locals
});

export default connect(mapStateToProps)(OrdersFilters);