import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Modal, Button, Row, Col } from 'antd';
import { BaseForm } from '../../styles/BasicStyles';
import TitleInput from '../../components/inputs/TitleInput';
import CurrencyInput from '../../components/inputs/CurrencyInput';
import SelectInput from '../../components/inputs/SelectInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import FormValidator from '../../infra/services/validations/FormValidator';
import { CostTypeOptions } from '../../infra/services/options/Options';

const validations = values => {
  if (values.cost) {
    return FormValidator.make({
      title: 'required|languages',
      cost_price: 'minNumber:0',
      cost_type: 'required'
    })(values);
  }
  return FormValidator.make({
    title: 'required|languages'
  })(values);
};

const Locals = [
  { _id: 1, name: 'Solar da Levada' },
  { _id: 2, name: 'Quinta Lago dos Cisnes' }
];

let ManageItemModal = ({
  open,
  edit,
  loading,
  handleSubmit,
  onSubmit,
  closeModal,
  questionType,
  hasCost,
  isPremium,
  supplierCategories
}) => {
  return (
    <Modal
      visible={open}
      title={edit ? 'Editar opção' : 'Adicionar opção'}
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      {open && (
        <BaseForm onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={[12, 12]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Field
                component={TitleInput}
                name={'title'}
                type="text"
                label={'Opção'}
                placeholder={'Nome da opção'}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} style={{ padding: 0 }}>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Field
                  component={CheckboxInput}
                  name={'cost'}
                  type="text"
                  label={'Tem custo acrescido?'}
                  positiveLabel={'Sim'}
                />
              </Col>
              {hasCost && (
                <Fragment>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <Field
                      component={SelectInput}
                      name={'cost_type'}
                      label={'Tipo de custo'}
                      placeholder={'Escolha o tipo de custo'}
                      type="select"
                      data={CostTypeOptions}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <Field
                      component={CurrencyInput}
                      name={'cost_price'}
                      label={'Preço'}
                      placeholder={'Insira o preço para o tipo de custo'}
                    />
                  </Col>
                </Fragment>
              )}
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} style={{ padding: 0 }}>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Field
                  component={CheckboxInput}
                  name={'onlyOneAvailable'}
                  type="text"
                  label={'Limitado por local e data?'}
                  positiveLabel={'Sim, limitado a 1 local e data'}
                />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Field
                  component={CheckboxInput}
                  name={'isPremiumOption'}
                  type="text"
                  label={'Opção do Serviço Premium?'}
                  positiveLabel={'Sim'}
                />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Field
                  component={CheckboxInput}
                  name={'isBreakfast'}
                  type="text"
                  label={'Opção de pequeno-almoço?'}
                  positiveLabel={'Sim'}
                />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Field
                  component={CheckboxInput}
                  name={'hasHost'}
                  type="text"
                  label={'Requisitar hospedeira?'}
                  positiveLabel={'Sim'}
                />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Field
                  component={CheckboxInput}
                  name={'isSeatNumber'}
                  label={'Identificador de mesa?'}
                  positiveLabel={'Sim'}
                />
              </Col>
              {/* {question?.isCoupleTable && <Col xs={24} sm={24} md={8} lg={8}>
                <Field
                  component={CheckboxInput}
                  name={'blockForCouple'}
                  label={'Bloquear para os noivos?'}
                  positiveLabel={'Sim'}
                />
              </Col>} */}
              <Col xs={24} sm={24} md={8} lg={8}>
                <Field
                  component={CheckboxInput}
                  name={'distinguishBudget'}
                  label={'Distinguir no orçamento?'}
                  positiveLabel={'Sim'}
                />
              </Col>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Field
                component={SelectInput}
                name={'local'}
                label={'Local'}
                allowClear
                placeholder={'Restrito apenas a um local'}
                type="select"
                data={Locals}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Field
                component={SelectInput}
                name={'supplier_category'}
                label={'Categoria de Encomenda'}
                placeholder={'Escolha uma categoria'}
                type="select"
                data={supplierCategories}
                allowClear
              />
            </Col>
          </Row>
        </BaseForm>
      )}
    </Modal>
  );
};

ManageItemModal = reduxForm({
  form: 'manage_option_form',
  validate: validations
})(ManageItemModal);

const selector = formValueSelector('manage_option_form');

const mapStateToProps = state => ({
  hasCost: selector(state, 'cost'),
  isPremium: selector(state, 'isPremiumOption'),
});

export default connect(mapStateToProps)(ManageItemModal);
