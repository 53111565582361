import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Icon, Modal, Popconfirm, notification } from 'antd';
import {
  InputDiv,
  InputLabelDiv,
  ImageInputSection,
  ImageIcon,
  ImageMessage,
  DynamicImage,
  FavoriteImage
} from './InputStyles';

class ImageInput extends Component {
  state = {
    openModal: false,
    uploaded: undefined
  };

  onCancel = () => {
    this.setState({ openModal: false });
  };

  buildImageObject = blob => ({
    preview: URL.createObjectURL(blob),
    size: blob.size,
    type: blob.type,
    blob: blob
  });

  handleImageDrop = (accepted, rejected) => {
    const { input, noCrop } = this.props;

    if (accepted.length <= 0 || (rejected && rejected.length > 0)) {
      return notification.error({
        message: 'Ficheiro Inválido',
        description: 'O ficheiro é inválido ou tem um tamanho superior a 6Mb'
      });
    }

    const uploaded = this.buildImageObject(accepted[0]);

    if (noCrop) {
      input.onChange(uploaded);
    }
    else {
      this.setState({ openModal: true, uploaded });
    }
  };

  onCropComplete = () => {
    const { input } = this.props;
    this.refs.cropper.getCroppedCanvas().toBlob(blob => {
      const image = this.buildImageObject(blob);
      input.onChange(image);
      this.setState({ openModal: false, uploaded: undefined });
    });
  };

  renderGalleryButton = () => {
    const { selectToGallery, handleSelectToCoverImage } = this.props;
    return (selectToGallery &&
      <FavoriteImage onClick={e => e.stopPropagation()}>
        <Popconfirm
          placement="topRight"
          title={'Tem a certeza que pretende adicionar esta imagem à galeria?'}
          onConfirm={() => handleSelectToCoverImage()}>
          <Icon type="picture" />
        </Popconfirm>
      </FavoriteImage>
    );
  }

  renderSelectedMedia = ({ getRootProps, getInputProps }) => {
    const { input, meta, selectToGallery, disabled } = this.props;
    const { invalid, submitFailed } = meta;
    const showError = invalid && submitFailed ? 1 : 0;

    if (input?.value?.preview) {
      return (
        <ImageInputSection auto error={showError} {...getRootProps()}>
          <input {...getInputProps()} />
          {this.renderGalleryButton()}
          <DynamicImage src={input.value.preview} alt="uploaded" />
        </ImageInputSection>
      );
    }

    if (input?.value?.url) {
      return (
        <ImageInputSection auto error={showError} {...getRootProps()}>
          <input {...getInputProps()} />
          {this.renderGalleryButton()}
          <DynamicImage src={input.value.url} alt="uploaded" />
        </ImageInputSection>
      );
    }

    if (disabled && selectToGallery) {
      return (
        <ImageInputSection error={showError}>
          <ImageIcon error={showError} type="file-image" />
          <ImageMessage error={showError}>Selecione a imagem de capa a partir da galeria</ImageMessage>
        </ImageInputSection>
      );
    }

    return (
      <ImageInputSection error={showError} {...getRootProps()}>
        <input {...getInputProps()} />
        <ImageIcon error={showError} type="file-image" />
        <ImageMessage error={showError}>Clique para adicionar ou arraste uma imagem</ImageMessage>
      </ImageInputSection>
    );
  };

  render() {
    const { label, ratio, disabled } = this.props;
    const { openModal, uploaded } = this.state;
    return (
      <InputDiv>
        {label && <InputLabelDiv>{label}</InputLabelDiv>}
        <Dropzone
          multiple={false}
          onDrop={this.handleImageDrop}
          accept="image/jpeg, image/png"
          maxSize={60000000}
          disabled={disabled}
        >
          {this.renderSelectedMedia}
        </Dropzone>
        <Modal
          maskClosable={false}
          closable={false}
          title="Recorte a imagem se pretender"
          visible={openModal}
          onOk={this.onCropComplete}
          onCancel={this.onCancel}
          bodyStyle={{ padding: 0 }}
          width="600px">
          <Cropper
            ref="cropper"
            viewMode={2}
            autoCropArea={1}
            aspectRatio={1 / ratio}
            style={{ height: 400, width: '100%' }}
            guides={true}
            src={uploaded ? uploaded.preview : ''}
          />
        </Modal>
      </InputDiv>
    );
  }
}

ImageInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

export default ImageInput;
