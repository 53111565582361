import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const CanvasContainer = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const Canvas = styled.div`
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
`;

const CanvasComponent = ({ width, height, maxSeats, seatsComplete, seatsPosition }) => {
  const canvasRef = useRef(null);
  const canvasWidth = 300;
  const canvasHeight = 150;
  const canvasFactor = canvasWidth / canvasHeight;

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    context.fillStyle = 'blue';
    context.fillRect(0, 0, 20, 20);

    context.fillStyle = 'red';
    const drawWidth = width - 50;
    const drawHeight = height - 50;
    const widthRatio = canvasWidth / drawWidth;    // ratio container width to rect width
    const heightRatio = canvasHeight / drawHeight; // ratio container height to rect height

    const ratio = Math.min(widthRatio, heightRatio); // take the smaller ratio

    const tableWidth = drawWidth * ratio;
    const tableHeight = drawWidth * ratio;
    context.fillRect(25, 25, tableWidth, tableHeight);
  }, []);

  return (
    <CanvasContainer>
      <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight} />
    </CanvasContainer>
  );
};

CanvasComponent.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  maxSeats: PropTypes.number,
  seatsComplete: PropTypes.bool,
  seatsPosition: PropTypes.object
};
export default CanvasComponent;
