import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Modal } from 'antd';
import {
  BasicInfoSection,
  SectionTitle,
  InfoText,
  SectionCTA
} from '../ProcessStyles';
import { BaseButton } from '../../../components/buttons/BaseButton';
import moment from 'moment';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import { ResponsePill, ExtraPill } from '../../ChoicesPage/SectionStyles';
import SectionNotes from './SectionNotes';
import CommentsDrawer from '../../../components/comments/CommentsDrawer';
import { SaveSectionNotes } from '../../../infra/requests/WeddingProcessRequests';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { bindActionCreators } from 'redux';
import { SaveNotes } from '../../../redux/wedding/wedding.actions';
import NotesConfirmModal from '../../../components/notesModal/NotesModal';
import { hasNotes } from '../../../infra/services/notes/FindNotes';
import { getPrintedConfirmation } from '../../../infra/services/wedding/printedUtils';

const { confirm } = Modal;

const { REACT_APP_IMAGES_URL } = process.env;

const GuestsComponent = ({
  weddingInfo,
  guests,
  minPeople,
  history,
  match: { params },
  notes = [],
  archived,
  dispatch,
  saveNotesOnReducer,
}) => {
  const [drawer, setDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openNotesConfirm, setNotesConfirm] = useState(false);

  const openFile = id => window.open(`${REACT_APP_IMAGES_URL}${id}`, '_blank');
  const note = notes.find(note => note.section === 'GUESTS');

  const openNotes = () => {
    dispatch(initialize('wedding_notes_section_form', note));
    setDrawer(true);
  };

  const closeNotes = () => {
    dispatch(initialize('wedding_notes_section_form', note));
    setDrawer(false);
    setNotesConfirm(false);
  };

  const checkBeforeCloseNotes = (values) => {
    //If theres any change to the notes, ask before closing if we want to save the changes
    if (values && (note?.notes !== values.notes || note?.private !== values.private)) {
      dispatch(initialize('notes_modal_confirm_form', values));
      setNotesConfirm(true);
    }
    //Otherwise, just the close the notes
    else {
      setDrawer(false);
    }
  }

  const saveNotes = async values => {
    if (weddingInfo?.printed) {
      confirm({
        ...getPrintedConfirmation(),
        onOk() { updateNotes(values); },
        onCancel() { closeNotes(); },
      });
    } else updateNotes(values);
  };

  const updateNotes = async (values) => {
    setLoading(true);

    const { data } = await SaveSectionNotes(weddingInfo._id, {
      ...values,
      section: 'GUESTS'
    });

    dispatch(initialize('wedding_notes_section_form', data));
    saveNotesOnReducer(data);
    setLoading(false);
    setDrawer(false);
    setNotesConfirm(false);

    document.body.style.overflowY = 'auto';
  };

  return (
    <BasicInfoSection>
      <SectionTitle>
        Convidados
        <SectionCTA>
          <BaseButton
            type={'default'}
            icon={'read'}
            text={'Notas'}
            onClick={openNotes}
            notes={hasNotes(notes, 'GUESTS')}
          />
          {archived ? <React.Fragment>
            {(guests?.bgRoomPlan && guests?.roomPlanTemplate) && <BaseButton
              type={'default'}
              icon={'arrow-right'}
              text={'Dist. convidados'}
              onClick={() => history.push(`/archived/${weddingInfo._id}/guestMap`)}
            />}
            {(guests?.bgRoomPlan && guests?.roomPlanTemplate) && <BaseButton
              type={'default'}
              icon={'arrow-right'}
              text={'Plano de sala'}
              onClick={() => history.push(`/archived/${weddingInfo._id}/roomPlan`)}
            />}
          </React.Fragment>
            : <BaseButton
              type={'default'}
              icon={'arrow-right'}
              text={'Abrir Convidados'}
              onClick={() => history.push(`/wedding/${params.wedding}/guests`)}
            />}
        </SectionCTA>
      </SectionTitle>
      <SectionNotes title="Notas Públicas:" note={note?.notes} />
      <SectionNotes title="Notas Privadas:" note={note?.private} />
      {guests ? (
        <Row>
          <Col xs={24} md={12}>
            {guests.completed ? (
              <React.Fragment>
                <Row>
                  <Col xs={24}>
                    <InfoText>
                      Convidados com + de 8 anos: {guests.guests}
                    </InfoText>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <InfoText>
                      Crianças (3 a 7 anos): {guests.children}
                    </InfoText>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <InfoText>Staff dos noivos: {guests.staff}</InfoText>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <InfoText>
                      Bébes (6 meses a 2 anos): {guests.babies}
                    </InfoText>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <InfoText>
                      Pax = Convidados com +8 anos + (Crianças / 2) + (Staff / 2): {`${guests.pax} Pax`}
                    </InfoText>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <InfoText>
                      Lugares montados = Convidados com +8 anos + Crianças + Staff + Bebés: {`${guests.places} Lugares Montados`}
                    </InfoText>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <InfoText>
                      Numero de mesas = (Convidados com +8 anos + Crianças) / 10: {`${guests.tables} Mesas`}
                    </InfoText>
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Row>
                  <Col xs={24}>
                    <InfoText>
                      Número mínimo de convidados: {`${minPeople} Convidados`}
                    </InfoText>
                  </Col>
                </Row>
                {guests.provisory_guests > 0 && (
                  <Row>
                    <Col xs={24}>
                      <InfoText>
                        Número previsto de convidados:{' '}
                        {`${guests.provisory_guests} Convidados`}
                      </InfoText>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={24}>
                    <InfoText>
                      Número estimado de mesas:{' '}
                      {`${Math.ceil(
                        (guests.provisory_guests &&
                          guests.provisory_guests > minPeople
                          ? guests.provisory_guests
                          : minPeople) / 10
                      )} Mesas`}
                    </InfoText>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </Col>
          <Col xs={24} md={12}>
            {guests?.room_plan && guests?.room_plan.filename && (
              <React.Fragment>
                <Row>
                  <Col xs={24}>
                    <InfoText>Plano de Sala:</InfoText>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} style={{ display: 'flex' }}>
                    <ResponsePill single={false}>
                      {TranslateValue(guests.room_plan.filename)}
                    </ResponsePill>
                    <ExtraPill>
                      {(guests.room_plan.length / (1024 * 1024)).toFixed(2)}MB
                    </ExtraPill>
                    <ExtraPill>
                      {moment(guests.room_plan.uploadDate).format('DD/MM/YYYY')}
                    </ExtraPill>
                    <ExtraPill
                      last
                      link
                      onClick={() => openFile(guests.room_plan._id)}>
                      Abrir ficheiro
                    </ExtraPill>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </Col>
          <Col xs={24} md={12}>
            {guests?.roomPlanImage && guests?.roomPlanImage?.filename && (
              <React.Fragment>
                <Row>
                  <Col xs={24}>
                    <InfoText>Plano de Sala (Editor):</InfoText>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} style={{ display: 'flex' }}>
                    <ResponsePill single={false}>
                      {TranslateValue(guests.roomPlanImage.filename)}
                    </ResponsePill>
                    <ExtraPill>
                      {(guests.roomPlanImage.length / (1024 * 1024)).toFixed(2)}MB
                    </ExtraPill>
                    <ExtraPill>
                      {moment(guests.roomPlanImage.uploadDate).format('DD/MM/YYYY')}
                    </ExtraPill>
                    <ExtraPill
                      last
                      link
                      onClick={() => openFile(guests.roomPlanImage._id)}>
                      Abrir ficheiro
                    </ExtraPill>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </Col>
        </Row>
      ) : (
        <div>Não existem registos para apresentar!</div>
      )}
      <CommentsDrawer
        title="Notas De Convidados"
        open={drawer}
        loading={loading}
        onClose={checkBeforeCloseNotes}
        saveNotes={saveNotes}
        archived={archived}
      />
      <NotesConfirmModal
        open={openNotesConfirm}
        loading={loading}
        onSubmit={saveNotes}
        closeModal={() => setNotesConfirm(false)}
        exitWithoutSaving={closeNotes}
      />
    </BasicInfoSection>
  );
};

const mapActionToProps = dispatch =>
  bindActionCreators(
    { saveNotesOnReducer: SaveNotes, dispatch },
    dispatch
  );

export default withRouter(connect(null, mapActionToProps)(GuestsComponent));