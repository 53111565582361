import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {Modal, Button} from 'antd';
import {BaseForm} from '../../styles/BasicStyles';
import LanguagesInput from '../../components/inputs/LanguagesInput';
import TitleInput from '../../components/inputs/TitleInput';
import FormValidator from '../../infra/services/validations/FormValidator';

const validations = FormValidator.make({
  name: 'required|languages',
  description: 'required|languages'
});

let ChaptersComponent = ({
  open,
  loading,
  handleSubmit,
  onSubmit,
  closeModal
}) => {
  return (
    <Modal
      visible={open}
      title="Capítulo"
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      {open && (
        <BaseForm onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={TitleInput}
            name={'name'}
            type="text"
            label={'Nome'}
            placeholder={'Nome do capítulo'}
          />
          <Field
            component={LanguagesInput}
            name={'description'}
            type="text"
            label={'Descrição'}
            placeholder={'Descrição do capítulo'}
          />
        </BaseForm>
      )}
    </Modal>
  );
};

ChaptersComponent = reduxForm({
  form: 'manage_newchapter_form',
  validate: validations
})(ChaptersComponent);

export default connect()(ChaptersComponent);
