import client from '../config/AxiosConfig';

export const CreateColorSystem = async (data) =>
  client.put(`/colorSystem/create`, data);

export const GetColorSystem = async id =>
  client.get(`/colorSystem/find/${id}`);

export const GetAllColorSystem = async () =>
  client.get(`/colorSystem/findAll`);

export const GetColorSystemByType = async type =>
  client.get(`/colorSystem/findByType/${type}`);

export const GetVenueColors = async () =>
  client.get(`/colorSystem/venue`);

export const UpdateColorSystem = async (data) =>
  client.post(`/colorSystem/update`, data);

export const DeleteColorSystem = async id =>
  client.delete(`/colorSystem/remove/${id}`);